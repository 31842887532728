import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ContentListBanks } from './components';
import { ContentFilters } from './components/ContentFilters';
import { listBanksIntermediate } from '../../../store/ducks/banks/listBanksIntermediate/actions';
import { disableLastMonth } from 'helpers/disableDates';
import { useResetFilters } from 'utils/hooks/useResetFilters';
import { useRangePickerChange } from 'utils/hooks/useRangePickerChange';
import {
  banksDetailsFiltersZeroLength,
  updateBanksDetailsFilters,
} from 'store/ducks/filters/details/banksDetailsFilters/actions';

const BanksIntermediate = () => {
  const dispatch = useDispatch();

  const startDate = useSelector(
    (state) => state.updateScreenToScreenDate.startDate
  );
  const endDate = useSelector(
    (state) => state.updateScreenToScreenDate.endDate
  );

  const banksGrossAmount = useSelector(
    (state) => state.banksIntermediate.grossAmount
  );
  const baksIntermediateLoading = useSelector(
    (state) => state.banksIntermediate.loading
  );
  const isBanksError = useSelector((state) => state.banksIntermediate.isError);
  const banks = useSelector((state) => state.banksIntermediate.banks);

  const [filteredBanks, setFilteredBanks] = useState(banks || []);

  const loading = baksIntermediateLoading;

  const [calendarIsOpen, setCalendarIsOpen] = useState(false);
  const [sort, setSort] = useState('DESC');

  const { onRangePickerChange } = useRangePickerChange();

  const filterByBank = (bank) => {
    const typedBank = bank?.target?.value;
    let _filteredBanks = [];
    _filteredBanks = JSON.parse(JSON.stringify(banks))?.filter((b) =>
      b?.name
        ?.toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .includes(
          typedBank
            .toLowerCase()
            ?.normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
        )
    );
    if (bank === '' || bank === ' ') {
      setFilteredBanks(banks);
    } else {
      setFilteredBanks(_filteredBanks);
    }
  };

  const filterByBranch = (branch) => {
    const typedBranch = branch?.target?.value;
    let _filteredBanks = [];

    _filteredBanks = JSON.parse(JSON.stringify(banks))?.filter((b) => {
      let foundBranch = false;
      const paymentData = b?.payment_data;
      paymentData.forEach((payment) => {
        if (
          payment?.bank_agency
            ?.toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .includes(
              typedBranch
                ?.toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
            )
        ) {
          foundBranch = true;
        }
      });
      return foundBranch;
    });
    _filteredBanks.forEach((b) => {
      const paymentData = b?.payment_data.filter((p) =>
        p?.bank_agency
          ?.toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .includes(
            typedBranch
              ?.toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
          )
      );
      b.payment_data = paymentData; // eslint-disable-line no-param-reassign
    });
    if (branch === '' || branch === ' ') {
      setFilteredBanks(banks);
    } else {
      setFilteredBanks(_filteredBanks);
    }
  };

  const filterByAccount = (account) => {
    const typedAccount = account?.target?.value;
    let _filteredBanks = [];

    // deep copying with JSON.parse
    _filteredBanks = JSON.parse(JSON.stringify(banks))?.filter((b) => {
      let foundAccount = false;
      b.payment_data.forEach((payment) => {
        if (
          payment?.bank_account
            ?.toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .includes(
              typedAccount
                ?.toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
            )
        ) {
          foundAccount = true;
        }
      });
      return foundAccount;
    });
    _filteredBanks.forEach((b) => {
      const paymentData = b?.payment_data.filter((p) =>
        p?.bank_account
          ?.toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .includes(
            typedAccount
              ?.toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
          )
      );
      b.payment_data = paymentData; // eslint-disable-line no-param-reassign
    });
    if (account === '' || account === ' ') {
      setFilteredBanks(banks);
    } else {
      setFilteredBanks(_filteredBanks);
    }
  };

  const filterByAcquirer = (acquirer) => {
    const typedAcquirer = acquirer?.target?.value;
    let _filteredBanks = [];

    // deep copying with JSON.parse
    _filteredBanks = JSON.parse(JSON.stringify(banks))?.filter((b) => {
      let foundAccount = false;
      const paymentData = b?.payment_data;
      paymentData.forEach((payment) => {
        if (
          payment?.acquirer_name
            ?.toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .includes(
              typedAcquirer
                ?.toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
            )
        ) {
          foundAccount = true;
        }
      });
      return foundAccount;
    });
    _filteredBanks.forEach((b) => {
      const paymentData = b?.payment_data.filter((p) =>
        p?.acquirer_name
          ?.toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .includes(
            typedAcquirer
              ?.toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
          )
      );
      b.payment_data = paymentData; // eslint-disable-line no-param-reassign
    });
    if (acquirer === '' || acquirer === ' ') {
      setFilteredBanks(banks);
    } else {
      setFilteredBanks(_filteredBanks);
    }
  };

  const onRangePickerOpenOrCloseChange = (open) => {
    setCalendarIsOpen(open);
  };

  const { resetFilters } = useResetFilters();

  useEffect(() => {
    dispatch(listBanksIntermediate({ startDate, endDate, sort }));
  }, [dispatch, startDate, endDate, sort]);

  useEffect(() => {
    setFilteredBanks(banks);
  }, [banks]);

  useEffect(() => {
    dispatch(banksDetailsFiltersZeroLength());
    resetFilters(updateBanksDetailsFilters);
  }, [dispatch, resetFilters]);

  return (
    <>
      <ContentFilters
        onRangePickerChange={(dates) =>
          onRangePickerChange(dates, calendarIsOpen)
        }
        dateDefaultValue={[startDate, endDate]}
        disabledDate={disableLastMonth}
        loading={loading}
        filterByBank={filterByBank}
        filterByBranch={filterByBranch}
        filterByAccount={filterByAccount}
        filterByAcquirer={filterByAcquirer}
        onRangePickerOpenOrCloseChange={onRangePickerOpenOrCloseChange}
        calendarIsOpen={calendarIsOpen}
        setCalendarIsOpen={setCalendarIsOpen}
        sort={sort}
        setSort={setSort}
      />
      <ContentListBanks
        isBanksError={isBanksError}
        banksLoading={loading}
        banksGrossAmount={banksGrossAmount?.toString()}
        banks={filteredBanks}
      />
    </>
  );
};

export default BanksIntermediate;
