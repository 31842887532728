import { lambdaApi } from '../api';

const transformer = (apiResponse) => {
  const data = {
    details: apiResponse.data.rules,
    meta: apiResponse.meta,
  };
  return data;
};

const getListReconciliationRulesFromAPI = async ({
  sortFields,
  page,
  perPage,
  q,
}) => {
  const config = {
    params: {
      page: page,
      per_page: perPage,
      sort_fields: JSON.stringify(sortFields),
      q: JSON.stringify(q),
    },
  };

  const response = await lambdaApi().get('/conciliation/rules', config);
  return transformer(response.data);
};

export default getListReconciliationRulesFromAPI;
