// TODO: Remove camelcase eslint rule
/* eslint-disable camelcase */
import { call, put } from 'redux-saga/effects';
import errorHandler from 'helpers/errorHandler';
import {
  updateStoreSuccess,
  updateStoreError,
} from 'store/ducks/cnpjMaintenance/updateStore/actions';
import updateStore from 'services/cnpjMaintenance/updateStore';
import { notification } from 'antd';

export function* updateStoreSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(updateStore, payload);
    yield put(updateStoreSuccess(apiData));
    notification.success({
      message: 'Atualização do cadastro da loja',
      description:
        'Sua solicitação está em andamento. Assim que for concluída, você será notificado na área de notificações.',
    });
  } catch (err) {
    yield put(updateStoreError('Ocorreu um erro:', err));
    if (err?.response?.data?.message) {
      yield put(
        updateStoreError(err.response.data.message, err.response.data.error)
      );
      errorHandler(err.response.data.message, err.response.data.error);
    } else {
      yield put(updateStoreError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
      errorHandler(err.response.data.message, err.response.data.error);
    }
  }
}
