import React from 'react';
import { CreatedAt } from '../CreatedAt';
import { TableStatus } from 'commons/TableStatus';
import dayjs from 'dayjs';
import { colors } from 'styles/colors';

const formatDate = (date) => dayjs(date).format('DD/MM/YYYY');

const typesOfConciliation = {
  sale: 'Conciliação de Vendas',
  payment: 'Conciliação de Pagamentos',
};

const handleStatusFlags = (flagProcessed) => {
  const result = {
    N: {
      text: 'Aguardando',
      backgroundColor: colors.primary1,
      borderColor: colors.primary3,
      textColor: colors.primary6,
    },
    Y: {
      text: 'Finalizado',
      backgroundColor: colors.green1,
      borderColor: colors.green3,
      textColor: colors.green6,
    },
    S: {
      text: 'Finalizado',
      backgroundColor: colors.gray1,
      borderColor: colors.green3,
      textColor: colors.green6,
    },
    P: {
      text: 'Iniciado',
      backgroundColor: colors.gray2,
      borderColor: colors.gray5,
      textColor: colors.gray9,
    },
    E: {
      text: 'Erro',
      backgroundColor: colors.red1,
      borderColor: colors.red3,
      textColor: colors.red6,
    },
  };

  return result[flagProcessed] || {};
};

export const columns = [
  {
    title: 'Data da solicitação',
    dataIndex: 'created_at',
    onFilter: (value, record) => record.request_date.indexOf(value) === 0,
    sorter: {
      multiple: 1,
    },
    width: 150,
    render: (_, { created_at: createdAt }) => (
      <CreatedAt created_at={createdAt} />
    ),
  },
  {
    title: 'Data de início',
    dataIndex: 'start_date',
    onFilter: (value, record) => record.start_date.indexOf(value) === 0,
    sorter: {
      multiple: 1,
    },
    width: 150,
    render: (_, { start_date: startDate }) => formatDate(startDate),
  },
  {
    title: 'Data de fim',
    dataIndex: 'end_date',
    onFilter: (value, record) => record.end_date.indexOf(value) === 0,
    sorter: {
      multiple: 1,
    },
    width: 150,
    render: (_, { end_date: endDate }) => formatDate(endDate),
  },
  {
    title: 'ID',
    dataIndex: 'retroactive_id',
    onFilter: (value, record) => record.client_id.indexOf(value) === 0,
    sorter: {
      multiple: 1,
    },
    width: 150,
  },
  {
    title: 'Tipo de Conciliação',
    dataIndex: 'recon_type',
    onFilter: (value, record) => record.recon_type.indexOf(value) === 0,
    sorter: {
      multiple: 1,
    },
    width: 150,
    render: (_, { recon_type: reconType }) =>
      typesOfConciliation[reconType] || '',
  },
  {
    title: 'Usuário',
    dataIndex: 'created_by',
    onFilter: (value, record) => record.email.indexOf(value) === 0,
    sorter: {
      multiple: 1,
    },
    width: 150,
  },
  {
    title: 'Status',
    dataIndex: 'flag_processed',
    onFilter: (value, record) => record.status.indexOf(value) === 0,
    sorter: {
      multiple: 1,
    },
    width: 150,
    render: (_, { flag_processed: flagProcessed }) => {
      const { backgroundColor, borderColor, textColor, text } =
        handleStatusFlags(flagProcessed);

      return (
        <TableStatus
          backgroundColor={backgroundColor}
          borderColor={borderColor}
          textColor={textColor}
        >
          {text || 'Status desconhecido'}
        </TableStatus>
      );
    },
  },
];
