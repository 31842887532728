import styled from 'styled-components';

import { Typography, Tag } from 'antd';
import { colors } from 'styles/colors';

export const StPartnerText = styled(Typography.Text)`
  &&& {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: ${colors.gray8};
  }
`;

export const StPartnerTag = styled(Tag)`
  &&& {
    border: 1px solid;
    box-sizing: border-box;
    border-radius: 2px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: ${({ labelColor }) => labelColor};
    border-color: ${({ bdColor }) => bdColor};
    background: ${({ bgColor }) => bgColor};
  }
`;

export const StLink = styled(Typography.Link)`
  &&& {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: ${colors.primary6} !important;
  }
`;
