export const onSorter = (sort, setSort) => () =>
  sort === 'ASC' ? setSort('DESC') : setSort('ASC');

export const onColumnSort = (sorter, setSortFields) => {
  const hasMultipleColumnSorting = Array.isArray(sorter);
  if (hasMultipleColumnSorting) {
    let sortFields = {};
    sorter.forEach((column) => {
      sortFields = {
        ...sortFields,
        [column.field]: column.order,
      };
    });
    setSortFields(sortFields);
  } else {
    setSortFields({ [sorter.field]: sorter.order });
  }
};
