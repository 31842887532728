import { action } from 'typesafe-actions';
import { actionTypes } from 'store/ducks/credit/creditListPartners/types';

export const creditListPartnersStart = () =>
  action(actionTypes.CREDIT_LIST_PARTNERS_START);
export const creditListPartnersSuccess = (data) =>
  action(actionTypes.CREDIT_LIST_PARTNERS_SUCCESS, data);
export const creditListPartnersError = (errorMessage, error) =>
  action(actionTypes.CREDIT_LIST_PARTNERS_ERROR, { errorMessage }, undefined, {
    error,
  });
