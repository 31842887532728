import React from 'react';
import { radarTypes } from './radarTypes';
import { Tag } from './styled';

const RadarTypeTag = ({ type = '', ...others }) => {
  if (!type) return null;

  const radarType = radarTypes[type] || {};
  const { fontColor, borderColor, backgroundColor } = radarType;

  if (!fontColor || !borderColor || !backgroundColor) return null;

  return (
    <Tag
      fontColor={fontColor}
      borderColor={borderColor}
      backgroundColor={backgroundColor}
      {...others}
    >
      {type}
    </Tag>
  );
};

export default RadarTypeTag;
