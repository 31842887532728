import { useCallback, useEffect, useState } from 'react';
import { useDebounce } from 'utils/hooks/useDebounce';
import errorHandler from 'helpers/errorHandler';
import getFeeStoresFilter from 'services/fees/getFeeStoresFilter';

export const useFeesStoresFilters = (searchFilterInput, page, perPage) => {
  const [isError, setIsError] = useState(false);
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [storeFilterOptions, setStoreFilterOptions] = useState([]);
  const [storeFilterOptionsData, setStoreFilterOptionsData] = useState([]);
  const [storeFilterMeta, setStoreFilterMeta] = useState({});
  const queryWithDebounce = useDebounce(searchFilterInput, 500);
  const transformSearchFilter = (search) => {
    const query = {
      filter: [
        {
          name: 'store_identifier',
          val: search || '',
        },
      ],
    };
    return query || null;
  };

  const fetchStoreFilter = useCallback(
    (pageNumber) => {
      setIsLoadingSearch(true);
      setIsError(false);
      return getFeeStoresFilter({
        page: pageNumber === 0 ? 0 : page,
        perPage,
        filter: searchFilterInput
          ? transformSearchFilter(searchFilterInput)
          : null,
      })
        .then(({ stores: data = [], meta = {} }) => {
          setStoreFilterOptionsData(data);
          setStoreFilterMeta(meta);
        })
        .catch((err) => {
          if (err) {
            errorHandler('Ocorreu um erro:', err);
          }
          setIsError(true);
          setIsLoadingSearch(false);
        })
        .finally(() => setIsLoadingSearch(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryWithDebounce, page]
  );

  const refreshFilters = () => {
    setStoreFilterOptions([]);
    fetchStoreFilter(0);
  };

  useEffect(() => {
    setStoreFilterOptions([]);
    fetchStoreFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryWithDebounce]);

  useEffect(() => {
    if (page > 1) {
      fetchStoreFilter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    setStoreFilterOptions((prev) => {
      return [...new Set([...prev, ...storeFilterOptionsData])];
    });
  }, [storeFilterOptionsData]);

  return {
    isLoadingSearch,
    isError,
    storeFilterOptions,
    storeFilterMeta,
    refreshFilters,
  };
};
