import React from 'react';
import PropTypes from 'prop-types';
import { List, Divider, Typography } from 'antd';
import customHistory from 'helpers/history';
import { MonthlyBarChart } from 'commons/MonthlyBarChart';
import { LabelAndValue } from 'commons/LabelAndValue';
import { StSeeDetailsButton } from 'components/Intermediate/styled';
import { StIntermediateCard } from 'commons/Intermediate/styled';
import { updateAmountsReceivableDetailsFilters } from 'store/ducks/filters/details/amountsReceivableDetailsFilters/actions';
import { useDispatch } from 'react-redux';
import { shortId } from 'helpers/shortId';

// eslint-disable-next-line no-unused-vars
const ContentCards = ({ data, loading, activeTab }) => {
  const dispatch = useDispatch();

  const transformItemToFilter = (item) => {
    const filter = [];
    if (activeTab === 'branches') {
      filter.push({
        name: 'store_name',
        val: [item],
      });
    } else if (activeTab === 'acquirers') {
      filter.push({
        name: 'acquirer_name',
        val: [item],
      });
    } else if (activeTab === 'products') {
      filter.push({
        name: 'product_code',
        val: [item],
      });
    } else if (activeTab === 'brands') {
      filter.push({
        name: 'brand_code',
        val: [item],
      });
    }
    return filter;
  };

  const goToReports = (item) => {
    dispatch(
      updateAmountsReceivableDetailsFilters(transformItemToFilter(item))
    );
    customHistory.push('/valores-a-receber-relatorios');
  };

  return (
    <List
      key={shortId()}
      grid={{
        xxl: 3,
        xl: 2,
        lg: 2,
        md: 1,
        sm: 1,
        xs: 1,
        gutter: 16,
      }}
      dataSource={data}
      renderItem={(item) => (
        <List.Item key={shortId()}>
          <StIntermediateCard
            key={shortId()}
            actions={[
              <div key={shortId()} style={{ textAlign: 'end' }}>
                <StSeeDetailsButton
                  type="link"
                  size="small"
                  fontSize="1.5rem"
                  onClick={() => {
                    goToReports(item.title);
                  }}
                >
                  Ver relatório
                </StSeeDetailsButton>
              </div>,
            ]}
          >
            <Typography.Text
              style={{ fontSize: '1.75rem', fontWeight: 'bold' }}
              key={shortId()}
            >
              {item?.title}
            </Typography.Text>
            <Divider key={shortId()} />
            <LabelAndValue
              label="Total a receber no período"
              labelColor="rgba(0,0,0,0.45)"
              value={new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              }).format(item?.value)}
              valueTextSize="medium"
              textAlign="end"
            />
            <MonthlyBarChart
              key={shortId()}
              data={item?.graph}
              dataKeyAxis="month"
              dataKeyBar="value"
              loading={false}
              fontSize={10}
            />
          </StIntermediateCard>
        </List.Item>
      )}
    />
  );
};

ContentCards.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.number,
      graph: PropTypes.arrayOf(
        PropTypes.shape({
          month: PropTypes.string,
          value: PropTypes.number,
        })
      ),
    })
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  activeTab: PropTypes.string.isRequired,
};

export default ContentCards;
