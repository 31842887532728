import { notification } from 'antd';
import errorHandler from 'helpers/errorHandler';
import { call, put } from 'redux-saga/effects';
import postReconciliationRules from 'services/reconciliationRules/postReconciliationRules';
import store from 'store';
import { listReconciliationRulesStart } from 'store/ducks/reconciliationRules/listReconciliationRules/actions';
import {
  postReconciliationRulesError,
  postReconciliationRulesSuccess,
} from 'store/ducks/reconciliationRules/postReconciliationRules/actions';

export function* postReconciliationRulesSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(postReconciliationRules, payload);

    yield put(postReconciliationRulesSuccess(apiData));
    notification.success({ message: 'Regra adicionada com sucesso!' });
    store.dispatch(
      listReconciliationRulesStart({
        sortFields: {},
        page: 1,
        perPage: 10,
        q: {
          filter: [],
        },
      })
    );
  } catch (err) {
    if (err?.response?.data?.data?.error) {
      yield put(
        postReconciliationRulesError(
          err.response.data.data.error,
          err.response.data.error
        )
      );
      errorHandler(err.response.data.data.error, err.response.data.error);
    }
  }
}
