// TODO: Remove camelcase eslint rule
/* eslint-disable camelcase,array-callback-return,no-param-reassign */
import { api } from '../api';
import dayjs from 'dayjs';

const transformer = (apiResponse) => {
  const { banks } = apiResponse.data;
  const { start_date, end_date, total_receipt_amount } = apiResponse.meta;
  return {
    banks: banks.sort(),
    totalAmount: total_receipt_amount,
    startDate: dayjs(start_date, 'YYYY-MM-DD'),
    endDate: dayjs(end_date, 'YYYY-MM-DD'),
  };
};

const getPaymentsByBanksIntermediateAPI = async ({
  startDate,
  endDate,
  sort,
}) => {
  const config = {
    params: {
      start_date: startDate.format('YYYY-MM-DD'),
      end_date: endDate.format('YYYY-MM-DD'),
      client_id: localStorage.getItem('concil-card-clientId'),
      order_by: sort,
    },
  };
  const response = await api().get('/bank/payments', config);
  return transformer(response.data);
};

export default getPaymentsByBanksIntermediateAPI;
