import { action } from 'typesafe-actions';
import { actionTypes } from './types';

export const reconciledBankDetailsFiltersStart = (data) =>
  action(actionTypes.RECONCILED_BANK_DETAILS_FILTERS_START, data);
export const reconciledBankDetailsFiltersSuccess = (data) =>
  action(actionTypes.RECONCILED_BANK_DETAILS_FILTERS_SUCCESS, data);
export const reconciledBankDetailsFiltersError = (errorMessage, error) =>
  action(
    actionTypes.RECONCILED_BANK_DETAILS_FILTERS_ERROR,
    { errorMessage },
    undefined,
    { error }
  );
export const reconciledBankDetailsFiltersZeroLength = () =>
  action(actionTypes.RECONCILED_BANK_DETAILS_FILTERS_ZERO_LENGTH);
export const updateReconciledBankDetailsFilters = (data) =>
  action(actionTypes.UPDATE_RECONCILED_BANK_DETAILS_FILTERS, data);
