import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Typography } from 'antd';
import { useAcquirer } from 'utils/hooks/useAcquirer';
import { conciliationTypes } from '../TopForm/conciliationTypes';
import { postRetroactiveConciliationStart } from 'store/ducks/retroactiveConciliation/actions';
import { BoldText } from '../TopForm/styled';
import * as St from './styled';
import dayjs from 'dayjs';

const ConfirmationModal = ({
  isModalVisible,
  setIsModalVisible,
  type,
  client,
  startDate,
  endDate,
  acquirer,
}) => {
  const dispatch = useDispatch();
  const { Text } = Typography;
  const { findAcquirerNameById } = useAcquirer();
  const loading = useSelector((state) => state.retroactiveConciliation.loading);

  const formatDate = (date) => date && dayjs(date).format('DD/MM/YYYY');

  const onOk = () => {
    dispatch(
      postRetroactiveConciliationStart({
        client,
        conciliationType: type,
        acquirerId: acquirer,
        period: [startDate, endDate],
      })
    );
  };

  const onCancel = () => {
    if (!loading) setIsModalVisible(!isModalVisible);
  };

  return (
    <Modal
      open={isModalVisible}
      okText="Sim"
      cancelText="Voltar"
      closable={false}
      centered
      onCancel={onCancel}
      onOk={onOk}
      cancelButtonProps={{
        disabled: loading,
      }}
      width={483}
      confirmLoading={loading}
    >
      <St.ModalWrapper>
        <St.InfoIcon />
        <St.ModalSecondWrapper>
          <St.ModalTitle>Solicitar Conciliação</St.ModalTitle>
          <Text>
            Você deseja solicitar a{' '}
            <BoldText>{conciliationTypes[type]}</BoldText> para o cliente{' '}
            <BoldText>{client.name}</BoldText> no período{' '}
            <BoldText>
              {formatDate(startDate)} a {formatDate(endDate)}
            </BoldText>{' '}
            e para a adquirente{' '}
            <BoldText>{findAcquirerNameById(acquirer)}</BoldText>?
          </Text>
        </St.ModalSecondWrapper>
      </St.ModalWrapper>
    </Modal>
  );
};

export default ConfirmationModal;
