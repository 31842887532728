import styled from 'styled-components';
import { Typography } from 'antd';
import { colors } from 'styles/colors';

const { Link } = Typography;

export const StLink = styled(Link)`
  &&& {
    color: ${({ status, account_id: accountId }) =>
      status !== 'IMPORTADO' || accountId === null
        ? '#8E8E8E !important'
        : `${colors.blue6} !important`};
  }
`;
