export const actionTypes = {
  TOKEN_PROD_POST_START: '@tokenProd/TOKEN_PROD_POST_START',
  TOKEN_PROD_POST_SUCCESS: '@tokenProd/TOKEN_PROD_POST_SUCCESS',
  TOKEN_PROD_POST_ERROR: '@tokenProd/TOKEN_PROD_POST_ERROR',
  TOKEN_PROD_DELETE_START: '@tokenProd/TOKEN_PROD_DELETE_START',
  TOKEN_PROD_DELETE_SUCCESS: '@tokenProd/TOKEN_PROD_DELETE_SUCCESS',
  TOKEN_PROD_DELETE_ERROR: '@tokenProd/TOKEN_PROD_DELETE_ERROR',
  TOKEN_PROD_GET_START: '@tokenProd/TOKEN_PROD_GET_START',
  TOKEN_PROD_GET_SUCCESS: '@tokenProd/TOKEN_PROD_GET_SUCCESS',
  TOKEN_PROD_GET_ERROR: '@tokenProd/TOKEN_PROD_GET_ERROR',
  TOKEN_PROD_PUT_START: '@tokenProd/TOKEN_PROD_PUT_START',
  TOKEN_PROD_PUT_SUCCESS: '@tokenProd/TOKEN_PROD_PUT_SUCCESS',
  TOKEN_PROD_PUT_ERROR: '@tokenProd/TOKEN_PROD_PUT_ERROR',
  TOKEN_PROD_DISABLE_TOKEN_START: '@tokenProd/TOKEN_PROD_DISABLE_TOKEN_START',
  TOKEN_PROD_DISABLE_TOKEN_SUCCESS:
    '@tokenProd/TOKEN_PROD_DISABLE_TOKEN_SUCCESS',
  TOKEN_PROD_DISABLE_TOKEN_ERROR: '@tokenProd/TOKEN_PROD_DISABLE_TOKEN_ERROR',
  ROW_CLIENT_ID_PROD_START: '@tokenProd/ROW_CLIENT_ID_PROD_START',
  ROW_CLIENT_ID_PROD_SUCCESS: '@tokenProd/ROW_CLIENT_ID_PROD_SUCCESS',
  ROW_CLIENT_ID_PROD_ERROR: '@tokenProd/ROW_CLIENT_ID_PROD_ERROR',
};
