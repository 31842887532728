/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { shortId } from 'helpers/shortId';
import {
  reconciledBankDetailsFiltersZeroLength,
  updateReconciledBankDetailsFilters,
} from 'store/ducks/filters/details/reconciledBankDetailsFilters/actions';
import { columns } from 'components/Reports/BanksReconciliationReports/utils';
import { Filters } from 'commons/Intermediate/Filters';
import { Tables } from 'commons/Tables';
import { generateFilterDataStructure } from 'helpers/tableService';
import { updateScreenToScreenDateStart } from 'store/ducks/updateScreenToScreenDate/actions';
import { createNotificationStart } from 'store/ducks/notifications/actions';
import {
  getSessionClientId,
  getSessionClientName,
} from 'helpers/sessionService';
import { MAX_CSV_ROWS, MAX_EXCEL_ROWS } from 'constants/general';
import { reconciledReceiptDetailsStart } from 'store/ducks/details/reconciledReceiptDetails/actions';
import { disableLastMonth } from 'helpers/disableDates';
import { useResetFilters } from 'utils/hooks/useResetFilters';
import { exportFileParser } from 'utils/parsers/exportFileParser';
import { perPageOptions, showTotalPage } from 'constants/perPageOptions';
import { onColumnSort } from 'helpers/sorter';
import dayjs from 'dayjs';
import { resetUpdateFilteredColumnsStart } from 'store/ducks/updateFilteredColumns/actions';
import { MobileCards } from './components';

const BanksReconciliationReports = () => {
  const dispatch = useDispatch();
  const permissionsData = useSelector((state) => state.permissions.data);
  const clientId = getSessionClientId();
  const clientName = getSessionClientName();
  const {
    user_id: userId,
    scope_id: scopeId,
    profile_id: profileId,
  } = permissionsData;

  const [dropdownOption, setDropdownOption] = useState('');

  const startDate = useSelector(
    (state) => state.updateScreenToScreenDate.startDate
  );
  const endDate = useSelector(
    (state) => state.updateScreenToScreenDate.endDate
  );

  const [filtersDrawer, toggleFiltersDrawer] = useState(false);

  const [sortFields, setSortFields] = useState({});

  const [perPage, setPerPage] = useState(10);
  const [pageTotal, setPageTotal] = useState(100);
  const [pageCurrent, setPageCurrent] = useState(1);
  const [disableExcel, setDisableExcel] = useState(false);
  const [disableCSV, setDisableCSV] = useState(false);

  const toParamsFilters = useSelector(
    (state) => state.reconciledBankDetailsFilters.toParamsFilters
  );

  const reconciledReceiptDetails = useSelector(
    (state) => state.reconciledReceiptDetails?.details
  );

  const reconciledReceiptTotalizers = useSelector(
    (state) => state.reconciledReceiptDetails?.totalizers
  );

  const reconciledReceiptLoading = useSelector(
    (state) => state.reconciledReceiptDetails.loading
  );

  const reconciledReceiptMeta = useSelector(
    (state) => state.reconciledReceiptDetails.meta
  );

  const reconciledReceiptError = useSelector(
    (state) => state.reconciledReceiptDetails.isError
  );

  const filtersData = useSelector(
    (state) => state.reconciledBankDetailsFilters.filters
  );

  const [calendarIsOpen, setCalendarIsOpen] = useState(false);

  const { acquirer, customer } =
    reconciledReceiptTotalizers && reconciledReceiptTotalizers;

  const [columnNames] = useState([
    'Adquirente',
    'Estabelecimento',
    'Data do Pagamento',
    'Origem',
    'Histórico',
    'Valor liquido previsto (R$)',
    'Valor liquido pago (R$)',
    'Banco',
    'Agência',
    'Conta',
    'Resumo operacional',
    'Resumo operacional Cielo',
  ]);

  const dataSource = reconciledReceiptDetails?.map((obj) => ({
    ...obj,
    key: shortId(),
  }));

  useEffect(() => {
    dispatch(
      reconciledReceiptDetailsStart({
        startDate,
        endDate,
        sortFields,
        page: pageCurrent,
        perPage,
        q: {
          filter: toParamsFilters,
        },
      })
    );
  }, [
    dispatch,
    pageCurrent,
    perPage,
    sortFields,
    toParamsFilters,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    setPageTotal(reconciledReceiptMeta.num_results);
    if (reconciledReceiptMeta.total_items > MAX_EXCEL_ROWS) {
      setDisableExcel(true);
    } else {
      setDisableExcel(false);
    }
    if (reconciledReceiptMeta.total_items > MAX_CSV_ROWS) {
      setDisableCSV(true);
    } else {
      setDisableCSV(false);
    }
  }, [reconciledReceiptMeta]);

  const onRangePickerChange = (dates) => {
    if (dates?.length) {
      if (
        calendarIsOpen &&
        (!dates[1] || dates[1]?.diff(dates[0], 'days') > 31)
      ) {
        dispatch(
          updateScreenToScreenDateStart({
            startDate: dates[0],
            endDate: dayjs(dates[0]).add(31, 'days'),
          })
        );
        dispatch(reconciledBankDetailsFiltersZeroLength());
      } else if (
        calendarIsOpen &&
        (!dates[1] || dates[1]?.diff(dates[0], 'days') <= 31)
      ) {
        dispatch(
          updateScreenToScreenDateStart({
            startDate: dates[0],
            endDate: dates[1],
          })
        );
        dispatch(reconciledBankDetailsFiltersZeroLength());
      }
    } else {
      dispatch(
        updateScreenToScreenDateStart({
          startDate: dayjs().subtract(32, 'days'),
          endDate: dayjs().subtract(1, 'day'),
        })
      );
      dispatch(reconciledBankDetailsFiltersZeroLength());
    }
  };

  const onRangePickerOpenOrCloseChange = (open) => {
    setCalendarIsOpen(open);
  };

  const { resetFilters } = useResetFilters();

  useEffect(() => {
    resetFilters(updateReconciledBankDetailsFilters);
    setPageCurrent(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onTablePaginationOrFiltersOrSorterChange = (
    pagination,
    tableFilters,
    sorter
  ) => {
    const filterDataToDispatch = generateFilterDataStructure(tableFilters);

    onColumnSort(sorter, setSortFields);
    dispatch(updateReconciledBankDetailsFilters(filterDataToDispatch));
    setPerPage(pagination.pageSize);
    setPageTotal(reconciledReceiptMeta.num_results);
    setPageCurrent(pagination.current);
  };

  const onPageMobileChange = (page, size) => {
    setPageCurrent(page);
    setPerPage(size);
  };

  const onPageSizeMobileChange = (current, size) => {
    setPageCurrent(current);
    setPerPage(size);
  };

  const fileName =
    'relatorio_conciliacao_bancaria.' +
    (dropdownOption === 'excel' ? 'xlsx' : 'csv');

  const format = 'YYYY-MM-DD';

  const notificationConfig = {
    clientId,
    userId,
    status: 'PREPARANDO',
    title: 'Preparando arquivo',
    isRead: false,
    isSendEmail: false,
    fileName,
    isExpired: false,
    createdBy: userId,
    updatedBy: userId,
    message: `Estamos preparando seu arquivo ${fileName} para o download.`,
    table: 'bank-reconciled',
    fileType: dropdownOption === 'excel' ? 'xlsx' : 'csv',
    scopeId,
    profileId,
    startDate: dayjs(startDate).format(format),
    endDate: dayjs(endDate).format(format),
    q: {
      filter: exportFileParser(toParamsFilters),
    },
    sortFields: '',
    clientName,
  };

  const [showTable, setShowTable] = useState(true);

  const resetFiltersStates = () => {
    dispatch(reconciledBankDetailsFiltersZeroLength());
    dispatch(updateReconciledBankDetailsFilters([]));
    dispatch(resetUpdateFilteredColumnsStart());
    setSortFields({});
    setPageCurrent(1);
  };

  const handleResetFilters = () => {
    setShowTable(false);
    resetFiltersStates();
  };

  useEffect(() => {
    setShowTable(true);
  }, [showTable]);

  return (
    <>
      <Filters
        title="Conciliação bancária"
        subtitle="Verifique seus recebimentos conciliados e não conciliados nas adquirentes e nos extratos"
        onRangePickerOpenOrCloseChange={onRangePickerOpenOrCloseChange}
        hasRangePicker
        hasSelect={false}
        hasSorter={false}
        dateDefaultValue={[startDate, endDate]}
        onRangePickerChange={onRangePickerChange}
        loading={reconciledReceiptLoading}
        filtersDrawer={filtersDrawer}
        onCloseFiltersDrawer={() => toggleFiltersDrawer(false)}
        hasMobileFiltersButton={false}
        disabledDate={disableLastMonth}
        calendarIsOpen={calendarIsOpen}
        setCalendarIsOpen={setCalendarIsOpen}
      />
      <MobileCards
        data={dataSource}
        loading={reconciledReceiptLoading}
        pagination={{
          current: pageCurrent,
          pageSize: perPage,
          total: pageTotal,
          pageSizeOptions: perPageOptions,
          showTotal: showTotalPage,
        }}
        error={reconciledReceiptError}
        onPageSizeChange={onPageSizeMobileChange}
        onPageChange={onPageMobileChange}
      />
      {showTable && (
        <Tables
          reconciliationType="bank"
          dataSource={dataSource}
          columns={columns(filtersData, toParamsFilters)}
          toParamsFilters={toParamsFilters}
          localFilters={[]}
          loading={reconciledReceiptLoading}
          error={reconciledReceiptError}
          leftColReconciledText="Adquirente"
          leftColReconciledAmount={acquirer}
          rightColReconciledText="Estabelecimento"
          rightColReconciledAmount={customer}
          createNotificationAction={createNotificationStart}
          notificationConfig={notificationConfig}
          dropdownOption={dropdownOption}
          setDropdownOption={setDropdownOption}
          pagination={{
            current: pageCurrent,
            pageSize: perPage,
            total: pageTotal,
            pageSizeOptions: perPageOptions,
            showTotal: showTotalPage,
          }}
          onChange={onTablePaginationOrFiltersOrSorterChange}
          defaultSelectedColumns={columnNames}
          disableExcel={disableExcel}
          disableCSV={disableCSV}
          handleResetFilters={handleResetFilters}
        />
      )}
    </>
  );
};

export default BanksReconciliationReports;
