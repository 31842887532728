export const actionTypes = {
  UPDATE_FILTERED_COLUMNS_START:
    '@updateFilteredColumns/UPDATE_FILTERED_COLUMNS_START',
  UPDATE_FILTERED_COLUMNS_SUCCESS:
    '@updateFilteredColumns/UPDATE_FILTERED_COLUMNS_SUCCESS',
  UPDATE_FILTERED_COLUMNS_ERROR:
    '@updateFilteredColumns/UPDATE_FILTERED_COLUMNS_ERROR',
  RESET_UPDATE_FILTERED_COLUMNS_START:
    '@updateFilteredColumns/RESET_UPDATE_FILTERED_COLUMNS_START',
  RESET_UPDATE_FILTERED_COLUMNS_SUCCESS:
    '@updateFilteredColumns/RESET_UPDATE_FILTERED_COLUMNS_SUCCESS',
  RESET_UPDATE_FILTERED_COLUMNS_ERROR:
    '@updateFilteredColumns/RESET_UPDATE_FILTERED_COLUMNS_ERROR',
};
