import styled from 'styled-components';
import { Typography } from 'antd';

const { Title, Paragraph } = Typography;

export const Wrapper = styled.div`
  text-align: center;
`;

export const ErrorTitle = styled(Title)`
  &&& {
    font-size: 16px;
  }
`;

export const ErrorParagraph = styled(Paragraph)`
  &&& {
    font-size: 12px;
  }
`;
