import React from 'react';
import { Grid, List, Card, Typography, Pagination, Row, Col } from 'antd';
import PropTypes from 'prop-types';

import { ErrorCard } from 'commons/ErrorCard';
import { StContainer, StLimiter } from './styled';
import { StGeneralReportsTitle } from 'components/Reports/styled';

const MobileCards = ({
  data,
  loading,
  pagination,
  onPageChange,
  onPageSizeChange,
  error,
}) => {
  const { useBreakpoint } = Grid;
  const breakpoint = useBreakpoint();

  if (error) {
    return (
      <StContainer>
        <StLimiter $breakpoint={breakpoint}>
          <Row style={{ marginTop: '2rem' }}>
            <Col lg={10} md={18}>
              <Card style={{ borderRadius: 4 }} loading={loading}>
                <ErrorCard
                  title="Dados indisponíveis"
                  reason="Não conseguimos buscar os dados, mas já estamos trabalhando para normalizar a situação."
                  recommendation="Tente atualizar a página em alguns instantes."
                />
              </Card>
            </Col>
          </Row>
        </StLimiter>
      </StContainer>
    );
  }

  if (
    !breakpoint?.md &&
    !breakpoint.lg &&
    !breakpoint.xl &&
    !breakpoint.xxl &&
    !error
  ) {
    return (
      <StContainer>
        <StLimiter $breakpoint={breakpoint}>
          <StGeneralReportsTitle>Relatório Geral</StGeneralReportsTitle>
          <List
            grid={{ column: 1 }}
            dataSource={data}
            loading={loading}
            renderItem={(item) => (
              <List.Item>
                <Card loading={loading}>
                  <div>
                    <Typography.Text>Filial: </Typography.Text>
                    <br />
                    <Typography.Text>{item.store_name}</Typography.Text>
                  </div>
                  <div style={{ marginTop: '2rem' }}>
                    <Typography.Text>Data do pagamento: </Typography.Text>
                    <br />
                    <Typography.Text>{item.transaction_date}</Typography.Text>
                  </div>
                  <div style={{ marginTop: '2rem' }}>
                    <Typography.Text>Adquirente: </Typography.Text>
                    <br />
                    <Typography.Text>{item.acquirer_name}</Typography.Text>
                  </div>
                  <div style={{ marginTop: '2rem' }}>
                    <Typography.Text>Produto: </Typography.Text>
                    <br />
                    <Typography.Text>{item.product_code}</Typography.Text>
                  </div>
                  <div style={{ marginTop: '2rem' }}>
                    <Typography.Text>Bandeira: </Typography.Text>
                    <br />
                    <Typography.Text>{item.brand_code}</Typography.Text>
                  </div>
                  <div style={{ marginTop: '2rem' }}>
                    <Typography.Text>Valor líquido (R$): </Typography.Text>
                    <br />
                    <Typography.Text>
                      {item.sum_adjustment_net_amount}
                    </Typography.Text>
                  </div>
                  <div style={{ marginTop: '2rem' }}>
                    <Typography.Text>Tipo da transação: </Typography.Text>
                    <br />
                    <Typography.Text>{item.transaction_type}</Typography.Text>
                  </div>
                </Card>
              </List.Item>
            )}
          />
          <Pagination
            pageSize={pagination.pageSize}
            pageSizeOptions={pagination.pageSizeOptions}
            onChange={onPageChange}
            onShowSizeChange={onPageSizeChange}
            total={pagination.total}
            current={pagination.current}
            showTotal={pagination.showTotal}
            showSizeChanger
            responsive
          />
        </StLimiter>
      </StContainer>
    );
  }
  return '';
};

MobileCards.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      store_name: PropTypes.string.isRequired,
      transaction_date: PropTypes.string.isRequired,
      acquirer_name: PropTypes.string.isRequired,
      product_code: PropTypes.string.isRequired,
      brand_code: PropTypes.string.isRequired,
      sum_adjustment_net_amount: PropTypes.string,
      sum_cancel_gross_amount: PropTypes.string,
      sum_cancel_net_amount: PropTypes.string,
      quantity: PropTypes.number,
      transaction_type: PropTypes.string.isRequired,
    })
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  onPageChange: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
};
export default MobileCards;
