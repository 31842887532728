import React, { createContext } from 'react';
import { Grid } from 'antd';
import { useOnboardingManagement } from './hooks/useOnboardingManagement';
import { Filters } from './components/Filters/Filters';
import { Table } from './components/Table/Table';
import * as St from './styled';

export const OnboardingManagementContext = createContext({});

export function OnboardingManagement() {
  const { useBreakpoint } = Grid;
  const breakpoint = useBreakpoint();
  const hookValues = useOnboardingManagement();

  return (
    <OnboardingManagementContext.Provider value={hookValues}>
      <St.Container $breakpoint={breakpoint}>
        <Filters />
      </St.Container>

      <Table />
    </OnboardingManagementContext.Provider>
  );
}
