import { lambdaApi } from '../api';

const transformer = (apiResponse) => {
  return {
    clients: apiResponse?.data?.users,
    concilers: apiResponse?.data?.conciliers,
  };
};

export const getEmails = async ({ clientId }) => {
  const response = await lambdaApi().get(
    `/radar/process/users?client_id=${clientId}`
  );
  return transformer(response.data);
};
