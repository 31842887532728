import { action } from 'typesafe-actions';
import { actionTypes } from 'store/ducks/credit/creditListPartnersAvailable/types';

export const creditListPartnersAvailableStart = (data) =>
  action(actionTypes.CREDIT_LIST_PARTNERS_AVAILABLE_START, data);
export const creditListPartnersAvailableSuccess = (data) =>
  action(actionTypes.CREDIT_LIST_PARTNERS_AVAILABLE_SUCCESS, data);
export const creditListPartnersAvailableError = (errorMessage, error) =>
  action(
    actionTypes.CREDIT_LIST_PARTNERS_AVAILABLE_ERROR,
    { errorMessage },
    undefined,
    {
      error,
    }
  );
