import { api } from '../api';

const transformer = (apiResponse) => {
  return {
    urls: apiResponse.data.urls,
  };
};

const getOptinUrlsFromAPI = async ({ acquirer }) => {
  const response = await api().get(`/integrations/optin/urls/${acquirer}`);
  return transformer(response.data);
};

export default getOptinUrlsFromAPI;
