/* eslint-disable no-shadow */
/* eslint-disable camelcase */
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Spin,
  Typography,
} from 'antd';
import { isSuperAdmin } from 'helpers/authorizationService';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { shortId } from 'helpers/shortId';
import { getAcquirersByClientIdStart } from 'store/ducks/reconciliationRules/getAcquirersByClientId/actions';
import {
  getNextOrderByClientIdStart,
  resetNextOrderByClientIdStart,
} from 'store/ducks/reconciliationRules/getNextOrderByClientId/actions';
import { postReconciliationRulesStart } from 'store/ducks/reconciliationRules/postReconciliationRules/actions';
import { putReconciliationRulesStart } from 'store/ducks/reconciliationRules/putReconciliationRules/actions';
import { colors } from 'styles/colors';
import { InputAmount } from './components';
import {
  handleBrNames,
  handleButtonText,
  handleModalTitle,
} from './utils/handle';

// TODO: this component should be refactored to use camelcase
const ModalForm = forwardRef(
  // eslint-disable-next-line no-unused-vars
  ({ visibility, closeModal, modalType, currentRow, isDuplicating }, ref) => {
    const dispatch = useDispatch();
    const { Option } = Select;
    const { TextArea } = Input;
    const childRef = useRef();
    const [form] = Form.useForm();
    const postReconciliationRulesLoading = useSelector(
      (state) => state.postReconciliationRules.loading
    );

    const ruleHasBeenAdded = useSelector(
      (state) => state.postReconciliationRules.ruleHasBeenAdded
    );

    const putReconciliationRulesLoading = useSelector(
      (state) => state.putReconciliationRules.loading
    );
    const acquirersByClientIdFromAPI = useSelector(
      (state) => state.getAcquirersByClientId.acquirers
    );
    const loadingAcquirersByClientIdFromAPI = useSelector(
      (state) => state.getAcquirersByClientId.loading
    );
    const nextOrderByClientIdFromAPI = useSelector(
      (state) => state.getNextOrderByClientId.executionSequence
    );
    const loadingNextOrderByClientIdFromAPI = useSelector(
      (state) => state.getNextOrderByClientId.loading
    );
    const isErrorNextOrderByClientIdFromAPI = useSelector(
      (state) => state.getNextOrderByClientId.isError
    );
    const isErrorGetAcquirersByClientIdFromAPI = useSelector(
      (state) => state.getAcquirersByClientId.isError
    );

    const listOfClients = useSelector(
      (state) => state.listOfClients?.allClients
    );
    const listOfClientsConverted = listOfClients?.map(
      ({ client_code, name, client_id }) => {
        return {
          client_code,
          name,
          client_id,
        };
      }
    );

    const [addButtonIsDisabled, setAddButtonIsDisabled] = useState(true);

    // dados mockados (virão da api)
    const groupOfRulesFiltersToSelect = ['Vendas', 'Pagamentos'];
    const groupTypeOfRulesFiltersToSelect = ['1-1', 'N-N'];
    const groupOfStatusFiltersToSelect = ['Ativo', 'Inativo'];
    const groupOfCategoriesFiltersToSelect = ['Personalizada', 'Padrão'];

    const groupOfRequiredCriteriaFiltersToSelect = ['Autorização', 'NSU'];
    const groupOfOptionalCriteriaFiltersToSelect = [
      'Adquirente',
      'Bandeira',
      'Data da transação',
      'Estabelecimento',
      'Filial',
      'Parcelas',
      'Produto',
      // 'Nota/Pedido',
      'TID',
    ];

    const {
      rule_id,
      description,
      group_type,
      recon_type,
      category,
      is_active,
      acquirer_name,
      optional_criteria,
      required_criteria,
      client_name_code,
      execution_sequence,
      floating_date,
      floating_value,
      client_id,
    } = currentRow;

    const brReconType = handleBrNames(recon_type);
    const brGroupType = handleBrNames(group_type);
    const brIsActive = handleBrNames(is_active);
    const brCategory = handleBrNames(category);

    // valores selecionados do form
    const [valueGroupRule, setValueGroupRule] = useState(brReconType);
    const [, setValueTypeRule] = useState(brGroupType);
    const [, setValueStatusRule] = useState(brIsActive);
    const [valueCategorySelected, setValueCategorySelected] =
      useState(brCategory);
    const [valueDaysOfFloat, setValueDaysOfFloat] = useState(
      floating_date || 0
    );
    const [valueReconciliationDiff, setValueReconciliationDiff] = useState(
      floating_value || 0
    );
    const [valueOrderRule, setValueOrderRule] = useState(
      0 || execution_sequence
    );
    const [valueAcquirerRule, setValueAcquirerRule] = useState();
    const [valueAcquirerRuleName, setValueAcquirerRuleName] = useState(
      '' || acquirer_name
    );
    const [valueRequiredCriteria, setValueRequiredCriteria] =
      useState(required_criteria);
    const [valueOptionalCriteria, setValueOptionalCriteria] =
      useState(optional_criteria);

    const [descriptionTransformed, setDescriptionTransformed] = useState(
      description || ''
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const descriptionRule = {
      acquirer: valueAcquirerRuleName,
      days_of_float: `DF_Dia(-${valueDaysOfFloat},${valueDaysOfFloat})`,
      reconciliation_diff: `DF_Valor(-${valueReconciliationDiff},${valueReconciliationDiff})`,
      required_criteria: `(${valueRequiredCriteria})`,
      optional_criteria: `(${valueOptionalCriteria})`,
    };

    useEffect(() => {
      const _description = [];
      Object.keys(descriptionRule).forEach((key) => {
        _description.push(descriptionRule[key]);

        setDescriptionTransformed(_description.join(' ').toUpperCase());
      });
    }, [descriptionRule]);

    useEffect(() => {
      form.setFieldsValue({
        description_rule: descriptionTransformed
          .replace('()', '')
          .replace('()', '')
          .replace('UNDEFINED', '')
          .replace('UNDEFINED', ''),
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [descriptionTransformed]);

    const handleCriteria = (array, type) => {
      const optionalArray = [];
      const requiredArray = [];

      const result = {
        ACQUIRER_NAME: 'Adquirente',
        BRAND_CODE: 'Bandeira',
        TRANSACTION_DATE: 'Data da transação',
        MERCHANT_CODE: 'Estabelecimento',
        STORE_CODE: 'Filial',
        PRODUCT_CODE: 'Produto',
        // CLIENT_CODE: 'Nota/Pedido',
        TID: 'TID',
        NSU: 'NSU',
        ACQUIRE_AUTHORIZATION: 'Autorização',
        INSTALLMENT: 'Parcelas',
      };

      if (type === 'required') {
        array.forEach((item) => requiredArray.push(result[item]));
        setValueRequiredCriteria(requiredArray);
        return form.setFieldsValue({
          required_criteria: requiredArray,
        });
      }

      array.forEach((item) => optionalArray.push(result[item]));
      setValueOptionalCriteria(optionalArray);
      return form.setFieldsValue({
        optional_criteria: optionalArray,
      });
    };

    const handleChange = (event, value) => {
      event.preventDefault();
      setValueReconciliationDiff(value);
      form.setFieldsValue({
        value_reconciliation_diff: value,
      });
    };

    const handleCloseModal = () => {
      form.resetFields();
      form.setFieldsValue({
        days_float: 0,
        value_reconciliation_diff: 0,
        order_rule: execution_sequence || 0,
      });
      setValueAcquirerRuleName('');
      setValueCategorySelected('');
      setValueDaysOfFloat(0);
      setValueReconciliationDiff(0);
      setValueOrderRule(form.getFieldValue('order_rule'));
      setValueRequiredCriteria([]);
      setValueOptionalCriteria([]);
      dispatch(resetNextOrderByClientIdStart({ nextOrder: '' }));
      closeModal();
    };

    useEffect(() => {
      if (ruleHasBeenAdded) {
        form.resetFields();
        form.setFieldsValue({
          days_float: 0,
          value_reconciliation_diff: 0,
          order_rule: execution_sequence || 0,
        });
        setValueAcquirerRuleName('');
        setValueDaysOfFloat(0);
        setValueReconciliationDiff(0);
        setValueOrderRule(form.getFieldValue('order_rule'));
        setValueRequiredCriteria([]);
        setValueOptionalCriteria([]);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ruleHasBeenAdded]);

    const handleAcquirerName = (rowAcquirer) => {
      let _foundName;
      let formattedId;

      if (rowAcquirer) {
        const foundAcquirer = Object.entries(acquirersByClientIdFromAPI).find(
          ([, value]) => {
            if (typeof rowAcquirer === 'string') {
              return rowAcquirer === value;
            }
            if (typeof rowAcquirer === 'object') {
              return rowAcquirer.label === value;
            }
            return false;
          }
        );
        if (foundAcquirer) {
          const [foundId, foundName] = foundAcquirer;
          formattedId = Number(foundId);

          setValueAcquirerRule(formattedId);
          setValueAcquirerRuleName(foundName);
          form.setFieldsValue({
            acquirer_rule: {
              label: foundName,
              value: formattedId,
            },
          });
          // eslint-disable-next-line no-return-assign
          return (_foundName = foundName);
        }
      } else {
        setValueAcquirerRule(null);
        setValueAcquirerRuleName('');
        form.setFieldsValue({
          acquirer_rule: {
            label: '',
            value: null,
          },
        });
      }

      return _foundName;
    };

    const handleFormFields = () => {
      const {
        group_rule,
        type_rule,
        status_rule,
        category_rule,
        client_rule,
        order_rule,
        required_criteria,
        optional_criteria,
        acquirer_rule,
      } = form.getFieldsValue();

      if (
        modalType === 'addRule' ||
        (modalType === 'editRule' && isDuplicating)
      ) {
        if (acquirer_rule) {
          setValueAcquirerRule(Number(acquirer_rule.value));
          setValueAcquirerRuleName(acquirer_rule.label);
          form.setFieldsValue({
            acquirer_rule: {
              label: acquirer_rule.label,
              value: Number(acquirer_rule.value),
            },
          });
        } else {
          setValueAcquirerRule(null);
          setValueAcquirerRuleName('');
          form.setFieldsValue({
            acquirer_rule: {
              label: '',
              value: null,
            },
          });
        }
      }

      if (modalType === 'editRule' && !isDuplicating) {
        if (acquirer_rule === undefined) {
          handleAcquirerName(acquirer_name);
        } else {
          handleAcquirerName(acquirer_rule);
        }
      } else if (modalType === 'editRule' && isDuplicating) {
        if (acquirer_rule === undefined) {
          setValueAcquirerRule(null);
          setValueAcquirerRuleName('');
          form.setFieldsValue({
            acquirer_rule: {
              label: '',
              value: null,
            },
          });
        } else {
          handleAcquirerName(valueAcquirerRuleName);
        }
      }

      const validateForm =
        // prettier-ignore
        !!group_rule &&
          !!type_rule &&
          !!status_rule &&
          !!category_rule &&
          (!!order_rule ||
          order_rule >= 0) &&
          required_criteria.length >= 1 &&
          optional_criteria.length >= 3 &&
          valueReconciliationDiff >= 0 &&
          ((group_rule === 'Vendas' &&
          !!client_rule) || (group_rule === 'Vendas' && category_rule === 'Padrão' && client_rule === '') || (group_rule === 'Pagamentos' && client_rule === ''));

      setAddButtonIsDisabled(!validateForm);
    };

    const handleOnFinish = (values) => {
      let formattedValues = {};
      let formattedAcquirerId;
      let formattedClientId;

      Object.entries(values).forEach(([key, value]) => {
        if (key === 'value_reconciliation_diff') {
          formattedValues = {
            ...values,
            [key]: valueReconciliationDiff,
          };
        }
        if (key === 'acquirer_rule') {
          if (typeof value.label === 'string') {
            const matchAcquirer = Object.entries(
              acquirersByClientIdFromAPI
            ).find(([, _value]) => _value === value.label);

            if (matchAcquirer) {
              const formattedId = Number(matchAcquirer[0]);
              formattedValues = {
                ...values,
                [key]: formattedId,
              };
            } else {
              formattedValues = {
                ...values,
                [key]: null,
              };
            }
          }
        }
        if (
          key === 'client_rule' &&
          value !== '' &&
          typeof value !== 'number'
        ) {
          const teste = listOfClientsConverted.find(
            (item) => value === `${item.client_code} - ${item.name}`
          );
          const { client_id } = teste;
          const _formattedClientId = Number(client_id);
          formattedValues = {
            ...values,
            client_rule: _formattedClientId,
          };
          form.setFieldsValue({
            client_rule: _formattedClientId,
          });
          if (modalType === 'editRule') {
            formattedClientId = _formattedClientId;
          }
        }
        if (
          key === 'client_rule' &&
          value !== '' &&
          typeof value === 'number'
        ) {
          formattedClientId = value;
          return formattedClientId;
        }

        return formattedValues;
      });

      const {
        id_rule,
        group_rule,
        type_rule,
        status_rule,
        category_rule,
        client_rule,
        days_float,
        value_reconciliation_diff,
        order_rule,
        acquirer_rule,
        required_criteria,
        optional_criteria,
        description_rule,
      } = formattedValues;

      if (typeof acquirer_rule === 'number') {
        formattedAcquirerId = acquirer_rule;
      }

      if (
        typeof acquirer_rule === 'object' &&
        typeof acquirer_rule !== 'number' &&
        acquirer_rule !== null &&
        acquirer_rule !== undefined
      ) {
        const { key } = acquirer_rule;
        formattedAcquirerId = Number(key);
      } else if (typeof acquirer_rule !== 'number') {
        formattedAcquirerId = null;
      }

      if (modalType === 'editRule' && !isDuplicating) {
        dispatch(
          putReconciliationRulesStart({
            ruleId: id_rule,
            description: description_rule,
            clientId:
              client_rule === '' || formattedClientId === ''
                ? null
                : formattedClientId,
            acquirerId: formattedAcquirerId,
            groupType: type_rule,
            reconType: group_rule,
            category: category_rule,
            executionSequence: order_rule,
            isActive: status_rule,
            floatingValue: value_reconciliation_diff,
            floatingDate: days_float,
            optionalCriteria: optional_criteria,
            requiredCriteria: required_criteria,
          })
        );
      }

      if (
        (modalType === 'addRule' && !isDuplicating) ||
        (modalType === 'editRule' && isDuplicating)
      ) {
        dispatch(
          postReconciliationRulesStart({
            description: description_rule,
            clientId:
              client_rule === ''
                ? null
                : modalType === 'editRule' && isDuplicating
                  ? formattedClientId
                  : client_rule,
            acquirerId: formattedAcquirerId,
            groupType: type_rule,
            reconType: group_rule,
            category: category_rule,
            executionSequence: order_rule,
            isActive: status_rule,
            floatingValue: value_reconciliation_diff,
            floatingDate: days_float,
            optionalCriteria: optional_criteria,
            requiredCriteria: required_criteria,
          })
        );
      }
    };

    useEffect(() => {
      if (modalType === 'editRule') {
        handleCriteria(required_criteria, 'required');
        handleCriteria(optional_criteria, '');
        handleAcquirerName(acquirer_name);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalType]);

    useEffect(() => {
      if (
        (modalType === 'addRule' && visibility === true && valueGroupRule) ||
        (modalType === 'editRule' && visibility === true && client_id === null)
      ) {
        dispatch(
          getAcquirersByClientIdStart({
            clientId: '',
            reconType: valueGroupRule,
          })
        );
      }
      if (
        (modalType === 'addRule' && visibility === true) ||
        (modalType === 'editRule' &&
          visibility === true &&
          client_id === null &&
          isDuplicating)
      ) {
        dispatch(getNextOrderByClientIdStart(''));
      }
      if (
        modalType === 'editRule' &&
        visibility === true &&
        client_id &&
        isDuplicating
      ) {
        dispatch(getNextOrderByClientIdStart(client_id));
      }
      if (
        modalType === 'editRule' &&
        visibility === true &&
        client_id !== null &&
        valueGroupRule
      ) {
        dispatch(
          getAcquirersByClientIdStart({
            clientId: client_id,
            reconType: valueGroupRule,
          })
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visibility, valueGroupRule]);

    useEffect(() => {
      if (modalType === 'addRule' && visibility === true) {
        form.setFieldsValue({ order_rule: nextOrderByClientIdFromAPI });
      }
      if (modalType === 'editRule' && visibility === true) {
        if (
          nextOrderByClientIdFromAPI &&
          nextOrderByClientIdFromAPI !== execution_sequence
        ) {
          form.setFieldsValue({ order_rule: nextOrderByClientIdFromAPI });
          // setValueOrderRule(nextOrderByClientIdFromAPI);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nextOrderByClientIdFromAPI]);

    return (
      <Modal
        onCancel={handleCloseModal}
        destroyOnClose
        open={visibility}
        title={handleModalTitle(modalType, isDuplicating)}
        centered
        width={900}
        maskClosable
        footer={false}
      >
        <Form
          requiredMark={false}
          layout="vertical"
          style={{ overflowWrap: 'break-word' }}
          name="add_reconciliation_rule"
          form={form}
          onFieldsChange={handleFormFields}
          onFinish={handleOnFinish}
          initialValues={{
            days_float: valueDaysOfFloat,
            value_reconciliation_diff: valueReconciliationDiff,
            group_rule: '' || brReconType,
            type_rule: '' || brGroupType,
            status_rule: '' || brIsActive,
            category_rule: '' || brCategory,
            client_rule: '' || client_name_code,
            id_rule: '' || rule_id,
            order_rule: 0 || valueOrderRule,
            acquirer_rule: modalType === 'editRule' ? valueAcquirerRule : '',
            required_criteria: [] || required_criteria,
            optional_criteria: [] || optional_criteria,
            description_rule: '' || description,
          }}
        >
          <Row gutter={[0, 0]}>
            <Col span={24}>
              <Row gutter={[0, 8]}>
                <Col span={24}>
                  <Typography.Text style={{ fontSize: 14, fontWeight: 500 }}>
                    Aplicação da regra
                  </Typography.Text>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Row gutter={[16, 0]}>
                    <Col span={8}>
                      <Form.Item
                        name="group_rule"
                        label="Grupo"
                        rules={[
                          { required: true, message: 'Campo obrigatório !' },
                        ]}
                      >
                        <Select
                          autoFocus
                          name="group_rule"
                          showSearch
                          allowClear
                          onChange={(value) => {
                            setValueGroupRule(value);
                            if (value === 'Pagamentos') {
                              setValueCategorySelected('Padrão');
                              form.setFieldsValue({
                                category_rule: 'Padrão',
                                client_rule: '',
                              });
                            }
                          }}
                          style={{ width: '100%' }}
                          placeholder="Selecione o grupo"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {groupOfRulesFiltersToSelect.map((item) => (
                            <Option key={shortId()} value={item}>
                              {item}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name="type_rule"
                        label="Tipo"
                        rules={[
                          { required: true, message: 'Campo obrigatório !' },
                        ]}
                      >
                        <Select
                          name="type_rule"
                          showSearch
                          allowClear
                          onChange={(value) => setValueTypeRule(value)}
                          disabled={valueGroupRule === 'Pagamentos'}
                          value={
                            valueGroupRule === 'Pagamentos' &&
                            form.setFieldsValue({
                              type_rule: '1-1',
                            })
                          }
                          style={{ width: '100%' }}
                          placeholder="Selecione o tipo"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {groupTypeOfRulesFiltersToSelect.map((item) => (
                            <Option key={shortId()} value={item}>
                              {item}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name="status_rule"
                        label="Status"
                        rules={[
                          { required: true, message: 'Campo obrigatório !' },
                        ]}
                      >
                        <Select
                          name="status_rule"
                          showSearch
                          allowClear
                          onChange={(value) => setValueStatusRule(value)}
                          disabled={modalType === 'editRule' && !isSuperAdmin()}
                          style={{ width: '100%' }}
                          placeholder="Selecione o status"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {groupOfStatusFiltersToSelect.map((item) => (
                            <Option key={shortId()} value={item}>
                              {item}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Row gutter={[16, 0]}>
                    <Col span={8}>
                      <Form.Item
                        name="category_rule"
                        label="Categoria"
                        rules={[
                          { required: true, message: 'Campo obrigatório !' },
                        ]}
                      >
                        <Select
                          name="category_rule"
                          showSearch
                          allowClear
                          style={{ width: '100%' }}
                          onChange={(e) => setValueCategorySelected(e)}
                          disabled={valueGroupRule === 'Pagamentos'}
                          placeholder="Selecione a categoria"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {!isSuperAdmin() ? (
                            <Option
                              key={shortId()}
                              value={groupOfCategoriesFiltersToSelect[0]}
                            >
                              {groupOfCategoriesFiltersToSelect[0]}
                            </Option>
                          ) : (
                            <>
                              {groupOfCategoriesFiltersToSelect.map((item) => (
                                <Option key={shortId()} value={item}>
                                  {item}
                                </Option>
                              ))}
                            </>
                          )}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col
                      span={
                        modalType === 'editRule' && !isDuplicating ? 10 : 16
                      }
                    >
                      <Form.Item
                        name="client_rule"
                        label="Nome ou código da empresa"
                        rules={[
                          {
                            required:
                              // prettier-ignore
                              // eslint-disable-next-line no-unneeded-ternary
                              valueGroupRule === 'Pagamentos' || (valueGroupRule === 'Vendas' && valueCategorySelected === 'Padrão') ? false : true,
                            message: 'Campo obrigatório !',
                          },
                        ]}
                      >
                        <Select
                          name="client_rule"
                          showSearch
                          allowClear
                          disabled={
                            (valueCategorySelected === 'Padrão' &&
                              valueGroupRule === 'Pagamentos') ||
                            valueCategorySelected === 'Padrão'
                          }
                          value={
                            (valueCategorySelected === 'Padrão' &&
                              valueGroupRule === 'Pagamentos') ||
                            (valueCategorySelected === 'Padrão' &&
                              form.setFieldsValue({
                                client_rule: '',
                              }))
                          }
                          style={{ width: '100%' }}
                          placeholder="Selecione a empresa"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onSelect={(clientId) => {
                            setValueAcquirerRule(null);
                            setValueAcquirerRuleName('');
                            form.setFieldsValue({ acquirer_rule: '' });
                            if (valueCategorySelected !== 'Personalizada') {
                              dispatch(getAcquirersByClientIdStart(clientId));
                            }
                            dispatch(getNextOrderByClientIdStart(clientId));
                          }}
                          onChange={(value, option) => {
                            if (value === undefined && option === undefined) {
                              if (valueCategorySelected !== 'Personalizada') {
                                dispatch(getAcquirersByClientIdStart(''));
                              }
                              dispatch(getNextOrderByClientIdStart(''));
                            }
                          }}
                        >
                          {listOfClientsConverted.map(
                            ({ client_code, name, client_id }) => (
                              <Option key={shortId()} value={client_id}>
                                {`${client_code} - ${name}`}
                              </Option>
                            )
                          )}
                        </Select>
                      </Form.Item>
                    </Col>
                    {modalType === 'editRule' && !isDuplicating && (
                      <Col span={6}>
                        <Form.Item name="id_rule" label="ID">
                          <Input
                            name="id_rule"
                            showSearch
                            allowClear
                            style={{ width: '100%' }}
                            disabled={modalType === 'editRule'}
                            optionFilterProp="children"
                          />
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Divider style={{ height: 3, margin: 0 }} />
                </Col>
              </Row>
              <Row gutter={[0, 8]}>
                <Col span={24} style={{ marginTop: 20 }}>
                  <Typography.Text
                    style={{
                      fontSize: 14,
                      fontWeight: 500,
                    }}
                  >
                    Critérios de criação da regra
                  </Typography.Text>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Row gutter={[16, 0]}>
                    <Col span={6}>
                      <Form.Item
                        name="days_float"
                        label="Período (Dias)"
                        rules={[
                          {
                            validator(_, value) {
                              if (value >= 0) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error('Preencha o campo acima.')
                              );
                            },
                          },
                        ]}
                      >
                        <InputNumber
                          name="days_float"
                          style={{ width: '100%' }}
                          type="number"
                          placeholder="Insira o número de dias"
                          onChange={(value) => setValueDaysOfFloat(value)}
                          min={0}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        name="value_reconciliation_diff"
                        label="Diferença de conciliação"
                      >
                        <InputAmount ref={childRef} onChange={handleChange} />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item
                        name="order_rule"
                        label="Ordem de execução da regra"
                        style={{ marginBottom: 0 }}
                        rules={[
                          {
                            validator(_, value) {
                              if (value >= 0) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error('Preencha o campo acima.')
                              );
                            },
                          },
                        ]}
                      >
                        {!loadingNextOrderByClientIdFromAPI ? (
                          <InputNumber
                            name="order_rule"
                            style={{ width: '100%' }}
                            type="number"
                            placeholder="Insira a ordem"
                            onChange={(value) => setValueOrderRule(value)}
                            min={0}
                          />
                        ) : (
                          <Spin />
                        )}
                      </Form.Item>
                      {isErrorNextOrderByClientIdFromAPI && (
                        <Typography.Text
                          style={{
                            color: colors.red5,
                            wordBreak: 'break-all',
                            fontSize: '12px',
                          }}
                        >
                          Selecione a empresa novamente.
                        </Typography.Text>
                      )}
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        name="acquirer_rule"
                        label="Adquirente"
                        style={{ marginBottom: 0 }}
                      >
                        {!loadingAcquirersByClientIdFromAPI ? (
                          <Select
                            labelInValue
                            name="acquirer_rule"
                            showSearch
                            allowClear
                            defaultValue={{
                              value: valueAcquirerRule,
                              label: valueAcquirerRuleName,
                            }}
                            style={{ width: '100%' }}
                            placeholder="Selecione a adquirente"
                            disabled={valueCategorySelected === 'Personalizada'}
                            value={
                              valueCategorySelected === 'Personalizada' &&
                              form.setFieldsValue({
                                acquirer_rule: '',
                              })
                            }
                          >
                            {Object.entries(acquirersByClientIdFromAPI).map(
                              ([key, value]) => (
                                <Option key={shortId()} value={key}>
                                  {value}
                                </Option>
                              )
                            )}
                          </Select>
                        ) : (
                          <Spin />
                        )}
                      </Form.Item>
                      {isErrorGetAcquirersByClientIdFromAPI && (
                        <Typography.Text
                          style={{
                            color: colors.red5,
                            wordBreak: 'break-all',
                            fontSize: '12px',
                          }}
                        >
                          Selecione a empresa novamente.
                        </Typography.Text>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Row gutter={[16, 0]}>
                    <Col span={10}>
                      <Form.Item
                        name="required_criteria"
                        label={
                          <div style={{ textAlign: 'center' }}>
                            Parâmetros obrigatórios{' '}
                            <Typography.Text
                              style={{
                                fontWeight: 400,
                                fontSize: 12,
                                color: colors.gray7,
                                textAlign: 'center',
                              }}
                            >
                              (Selecione pelo menos 1)
                            </Typography.Text>
                          </div>
                        }
                        rules={[
                          { required: true, message: '' },

                          {
                            validator(_, value) {
                              if (!value || value.length < 1) {
                                // eslint-disable-next-line prefer-promise-reject-errors
                                return Promise.reject(
                                  'Selecione pelo menos 1 parâmetros obrigatório!'
                                );
                              }

                              return Promise.resolve();
                            },
                          },
                        ]}
                      >
                        <Select
                          name="required_criteria"
                          mode="multiple"
                          showSearch
                          allowClear
                          style={{ width: '100%' }}
                          placeholder="Código de lançamento"
                          onChange={(value) => setValueRequiredCriteria(value)}
                          value={valueRequiredCriteria}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {groupOfRequiredCriteriaFiltersToSelect.map(
                            (reqCriteria) => (
                              <Option key={shortId()} value={reqCriteria}>
                                {reqCriteria}
                              </Option>
                            )
                          )}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={14}>
                      <Form.Item
                        name="optional_criteria"
                        label={
                          <div style={{ textAlign: 'center' }}>
                            Parâmetros adicionais{' '}
                            <Typography.Text
                              style={{
                                fontWeight: 400,
                                fontSize: 12,
                                color: colors.gray7,
                                textAlign: 'center',
                              }}
                            >
                              (Selecione pelo menos 3)
                            </Typography.Text>
                          </div>
                        }
                        rules={[
                          { required: true, message: '' },

                          {
                            validator(_, value) {
                              if (!value || value.length < 3) {
                                // eslint-disable-next-line prefer-promise-reject-errors
                                return Promise.reject(
                                  'Selecione pelo menos 3 parâmetros adicionais!'
                                );
                              }

                              return Promise.resolve();
                            },
                          },
                        ]}
                        style={{
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        <Select
                          name="optional_criteria"
                          mode="multiple"
                          showSearch
                          allowClear
                          style={{ width: '100%' }}
                          placeholder="Código de lançamento"
                          onChange={(value) => setValueOptionalCriteria(value)}
                          value={valueOptionalCriteria}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {groupOfOptionalCriteriaFiltersToSelect.map(
                            (optCriteria) => (
                              <Option key={shortId()} value={optCriteria}>
                                {optCriteria}
                              </Option>
                            )
                          )}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row style={{ background: colors.gray2 }}>
                <Col span={24}>
                  <Row gutter={[0, 8]}>
                    <Col span={24} style={{ marginTop: 20 }}>
                      <Typography.Text
                        style={{
                          fontSize: 14,
                          fontWeight: 500,
                        }}
                      >
                        Descrição da regra
                      </Typography.Text>
                    </Col>
                  </Row>
                  <Row gutter={[0, 8]}>
                    <Col span={24}>
                      <Form.Item name="description_rule">
                        <TextArea
                          name="description_rule"
                          disabled
                          defaultValue={descriptionTransformed}
                          style={{ width: '100%' }}
                          autoSize={{ minRows: 2, maxRows: 5 }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col
                  span={24}
                  style={{ display: 'flex', justifyContent: 'end' }}
                >
                  <Button
                    key={shortId()}
                    onClick={handleCloseModal}
                    style={{ marginRight: '8px' }}
                    disabled={
                      postReconciliationRulesLoading ||
                      putReconciliationRulesLoading
                    }
                    loading={
                      postReconciliationRulesLoading ||
                      putReconciliationRulesLoading
                    }
                  >
                    Cancelar
                  </Button>
                  <Button
                    key={shortId()}
                    type="primary"
                    htmlType="submit"
                    disabled={
                      addButtonIsDisabled ||
                      postReconciliationRulesLoading ||
                      putReconciliationRulesLoading
                    }
                    loading={
                      postReconciliationRulesLoading ||
                      putReconciliationRulesLoading
                    }
                  >
                    {handleButtonText(modalType, isDuplicating)}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
);
export default ModalForm;
