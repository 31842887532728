import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Select, Spin, notification } from 'antd';
import useRadarEmails from '../hooks/useRadarEmails';
import { SelectInfiniteScroll } from 'commons/SelectInfiniteScroll';
import { useRadarClientsList } from '../hooks/useRadarClientsList';

export const TestRadarModal = ({
  isTestRadarModalVisible,
  handleOk,
  setIsTestRadarModalVisible,
  runRadarTestLoading,
  testRadarHasBeenSent,
}) => {
  const { Group } = Input;
  const [form] = Form.useForm();
  const { Item } = Form;
  const { Option } = Select;
  const {
    clientsEmails,
    concilersEmails,
    loading: loadingEmails,
    fetchRadarEmails,
  } = useRadarEmails();

  const [clientIdSelected, setClientIdSelected] = useState(undefined);
  const [page, setPage] = useState(1);
  const [searchFilter, setSearchFilter] = useState('');
  const { isLoading, clients, hasMoreClients } = useRadarClientsList(
    searchFilter,
    page
  );

  const isSelectEmailsDisabled = () => {
    return (
      !clientIdSelected ||
      loadingEmails ||
      (runRadarTestLoading && !testRadarHasBeenSent)
    );
  };

  const clearSelectedEmails = () => {
    form.setFieldsValue({
      selectedClientsEmails: [],
      selectedConcilersEmails: [],
    });
  };

  const onClientChange = ({ value }) => {
    clearSelectedEmails();

    if (value) {
      setClientIdSelected(value);
      fetchRadarEmails({ clientId: value });
    } else {
      setClientIdSelected(undefined);
    }
  };

  const isSubmitButtonDisabled = () => {
    return runRadarTestLoading || testRadarHasBeenSent || !clientIdSelected;
  };

  const onFinish = (data = {}) => {
    const { selectedClientsEmails = [], selectedConcilersEmails = [] } = data;

    if (
      selectedClientsEmails.length === 0 &&
      selectedConcilersEmails.length === 0
    ) {
      return notification.error({
        message: 'Selecione um e-mail para o envio',
      });
    }

    return handleOk({ ...data, clientId: clientIdSelected });
  };

  const onCancel = () => {
    setIsTestRadarModalVisible(!isTestRadarModalVisible);
  };

  useEffect(() => {
    setPage(1);
    setClientIdSelected(undefined);
    clearSelectedEmails();
    // eslint-disable-next-line
  }, [searchFilter]);

  return (
    <Modal
      title="Teste de envio"
      okText="Enviar"
      open={
        isTestRadarModalVisible ||
        (!runRadarTestLoading && testRadarHasBeenSent)
      }
      centered
      footer={false}
      closable={false}
    >
      <Group>
        <Form
          form={form}
          name="testRadarForm"
          layout="vertical"
          onFinish={onFinish}
          requiredMark={false}
          initialValues={{
            selectedClientsEmails: [],
            selectedConcilersEmails: [],
          }}
        >
          <Item
            label="PFJ"
            style={{ margin: '0 0 8px' }}
            rules={[
              {
                required: true,
                message: 'Selecione o PFJ',
              },
            ]}
          >
            <SelectInfiniteScroll
              hasMoreItems={hasMoreClients}
              loadingItems={isLoading}
              placeholder="Selecione o PFJ"
              setPage={setPage}
              setSearchFilter={setSearchFilter}
              options={clients}
              onOptionClick={onClientChange}
              disabled={runRadarTestLoading && !testRadarHasBeenSent}
            />
          </Item>
          <Item
            label="Tipo"
            name="type"
            style={{ margin: '0 0 8px' }}
            rules={[
              {
                required: true,
                message: 'Selecione o tipo de envio',
              },
            ]}
          >
            <Select
              placeholder="Selecione o tipo de envio"
              dropdownMatchSelectWidth
              style={{ width: '100%' }}
              disabled={runRadarTestLoading && !testRadarHasBeenSent}
            >
              <Option value="weekly">Semanal</Option>
              <Option value="monthly">Mensal</Option>
            </Select>
          </Item>
          <Item
            label="E-mails de clientes vinculados ao PFJ"
            name="selectedClientsEmails"
            style={{ margin: '0 0 8px' }}
          >
            <Select
              allowClear
              mode="multiple"
              loading={loadingEmails}
              style={{ width: '100%' }}
              placeholder="Selecione um e-mail"
              optionLabelProp="label"
              disabled={isSelectEmailsDisabled()}
            >
              {clientsEmails.map(
                (clientEmail) =>
                  clientEmail && (
                    <Option
                      key={clientEmail}
                      value={clientEmail}
                      label={clientEmail}
                    />
                  )
              )}
            </Select>
          </Item>
          <Item
            label="E-mails Concilers"
            name="selectedConcilersEmails"
            style={{ margin: '0 0 8px' }}
          >
            <Select
              allowClear
              mode="multiple"
              loading={loadingEmails}
              style={{ width: '100%' }}
              placeholder="Selecione um e-mail @concil que receberá o teste"
              optionLabelProp="label"
              disabled={isSelectEmailsDisabled()}
            >
              {concilersEmails.map(
                (concilerEmail) =>
                  concilerEmail && (
                    <Option
                      key={concilerEmail}
                      value={concilerEmail}
                      label={concilerEmail}
                    />
                  )
              )}
            </Select>
          </Item>
          <Item style={{ textAlign: 'right', margin: '20px 0 0' }}>
            {!runRadarTestLoading ? (
              <>
                <Button
                  type="default"
                  htmlType="button"
                  style={{ marginRight: '8px' }}
                  onClick={onCancel}
                  disabled={runRadarTestLoading || testRadarHasBeenSent}
                >
                  Cancelar
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={isSubmitButtonDisabled()}
                >
                  Enviar
                </Button>
              </>
            ) : (
              <Spin style={{ marginTop: '5px' }} />
            )}
          </Item>
        </Form>
      </Group>
    </Modal>
  );
};
