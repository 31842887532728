import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Tabs, Row, Col, Skeleton } from 'antd';
import {
  ContentCards,
  SummaryCard,
} from 'components/Intermediate/AmountsReceivable/components';
import { StLimiter, StContainer } from './styled';
import { marginMainContainer } from 'helpers/style';
import { colors } from 'styles/colors';
import dayjs from 'dayjs';

const ContentTab = ({
  startDate,
  endDate,
  infoData,
  isInfoError,
  infoLoading,
  activeTab,
  infoFilter,
  summary,
  amountsReceivableLoading,
  amountsReceivableIsError,
}) => {
  return (
    <>
      <Row style={{ marginTop: '2rem' }}>
        <Col xxl={8} xl={12} lg={12} md={18} sm={24} xs={24}>
          <Skeleton loading={infoLoading} active paragraph={{ rows: 6 }}>
            <SummaryCard
              summary={summary}
              startDate={startDate}
              endDate={endDate}
              isError={amountsReceivableIsError}
              loading={amountsReceivableLoading}
            />
          </Skeleton>
        </Col>
      </Row>
      <br />
      <Skeleton loading={infoLoading} active paragraph={{ rows: 10 }}>
        <ContentCards
          data={infoData}
          loading={infoLoading}
          error={isInfoError}
          startDate={startDate}
          endDate={endDate}
          activeTab={activeTab}
          infoFilter={infoFilter}
        />
      </Skeleton>
    </>
  );
};

const ContentTabs = ({
  startDate,
  endDate,
  infoData,
  isInfoError,
  infoLoading,
  onTabClick,
  activeTab,
  infoFilter,
  summary,
  amountsReceivableLoading,
  amountsReceivableIsError,
}) => {
  const { useBreakpoint } = Grid;
  const breakpoint = useBreakpoint();

  const items = [
    {
      label: 'Filiais',
      key: 'branches',
      children: (
        <StLimiter $breakpoint={breakpoint}>
          {activeTab === 'branches' && (
            <ContentTab
              startDate={startDate}
              endDate={endDate}
              infoData={infoData}
              isInfoError={isInfoError}
              infoLoading={infoLoading}
              onTabClick={onTabClick}
              activeTab={activeTab}
              infoFilter={infoFilter}
              summary={summary}
              amountsReceivableLoading={amountsReceivableLoading}
              amountsReceivableIsError={amountsReceivableIsError}
            />
          )}
        </StLimiter>
      ),
    },
    {
      label: 'Adquirentes',
      key: 'acquirers',
      children: (
        <StLimiter $breakpoint={breakpoint}>
          {activeTab === 'acquirers' && (
            <ContentTab
              startDate={startDate}
              endDate={endDate}
              infoData={infoData}
              isInfoError={isInfoError}
              infoLoading={infoLoading}
              onTabClick={onTabClick}
              activeTab={activeTab}
              infoFilter={infoFilter}
              summary={summary}
              amountsReceivableLoading={amountsReceivableLoading}
              amountsReceivableIsError={amountsReceivableIsError}
            />
          )}
        </StLimiter>
      ),
    },
    {
      label: 'Produtos',
      key: 'products',
      children: (
        <StLimiter $breakpoint={breakpoint}>
          {activeTab === 'products' && (
            <ContentTab
              startDate={startDate}
              endDate={endDate}
              infoData={infoData}
              isInfoError={isInfoError}
              infoLoading={infoLoading}
              onTabClick={onTabClick}
              activeTab={activeTab}
              infoFilter={infoFilter}
              summary={summary}
              amountsReceivableLoading={amountsReceivableLoading}
              amountsReceivableIsError={amountsReceivableIsError}
            />
          )}
        </StLimiter>
      ),
    },
    {
      label: 'Bandeiras',
      key: 'brands',
      children: (
        <StLimiter $breakpoint={breakpoint}>
          {activeTab === 'brands' && (
            <ContentTab
              startDate={startDate}
              endDate={endDate}
              infoData={infoData}
              isInfoError={isInfoError}
              infoLoading={infoLoading}
              onTabClick={onTabClick}
              activeTab={activeTab}
              infoFilter={infoFilter}
              summary={summary}
              amountsReceivableLoading={amountsReceivableLoading}
              amountsReceivableIsError={amountsReceivableIsError}
            />
          )}
        </StLimiter>
      ),
    },
  ];

  return (
    <StContainer>
      <Tabs
        items={items}
        onTabClick={onTabClick}
        tabBarStyle={{
          backgroundColor: colors.gray1,
          marginTop: '160px',
          padding: marginMainContainer(breakpoint),
          fontSize: '12px',
          position: 'fixed',
          zIndex: '90',
          width: '100%',
        }}
      />
    </StContainer>
  );
};

ContentTabs.propTypes = {
  infoData: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.number,
      graph: PropTypes.arrayOf(
        PropTypes.shape({
          month: PropTypes.string,
          value: PropTypes.number,
        })
      ),
    })
  ).isRequired,
  onTabClick: PropTypes.func.isRequired,
  infoLoading: PropTypes.bool.isRequired,
  activeTab: PropTypes.string.isRequired,
  isInfoError: PropTypes.shape({
    type: PropTypes.string,
    message: PropTypes.string,
    code: PropTypes.number,
    field: PropTypes.string,
  }).isRequired,
  startDate: PropTypes.instanceOf(dayjs).isRequired,
  endDate: PropTypes.instanceOf(dayjs).isRequired,
  summary: PropTypes.shape({
    value: PropTypes.number,
  }).isRequired,
  amountsReceivableLoading: PropTypes.bool.isRequired,
  amountsReceivableIsError: PropTypes.bool.isRequired,
};

export default ContentTabs;
