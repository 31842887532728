import { actionTypes } from './types';

const INITIAL_STATE = {
  store_name: false,
  sale_date: false,
  forecast_date: false,
  acquirer_name: false,
  brand_code: false,
  nsu: false,
  plan_installments: false,
  installment_gross_amount: false,
  adm_fee_percent: false,
  installment_adm_fee_amount: false,
  installment_net_amount: false,
  product_code: false,
  authorization: false,
  card_number: false,
  bank_code: false,
  bank_branch: false,
  bank_account: false,
  capture_method_code: false,
  merchant_code: false,
  summary: false,
  summary_cielo: false,
  sale_time: false,
  tid: false,
  pos_number: false,
  installment_adq_fare_amount: false,
  inst_schemefee_fee_amount: false,
  inst_interchange_fee_amount: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case actionTypes.UPDATE_VISIBLE_DROPDOWN_FILTERS:
    return {
      ...state,
      [action.payload]: true,
    };
  default:
    return state;
  }
};

export default reducer;
