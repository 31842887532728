import { Col, Row, Spin, Typography } from 'antd';
import React from 'react';
import { colors } from 'styles/colors';

const TableFooter = ({
  itemCounter = 0,
  totalCount = 0,
  isLoadingItems = false,
}) => {
  return (
    <Row
      style={{
        padding: '8px 0',
        backgroundColor: colors.gray2,
      }}
    >
      <Col
        span={24}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {isLoadingItems ? (
          <Spin size="small" />
        ) : (
          <Typography.Text>
            {`Exibindo ${itemCounter} de ${totalCount} ${
              totalCount === 1 || totalCount === 0 ? 'item' : 'itens'
            }.`}
          </Typography.Text>
        )}
      </Col>
    </Row>
  );
};

export default TableFooter;
