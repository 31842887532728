import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Button, Col, Row, Form, Grid } from 'antd';
import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
import { authRequest } from 'store/ducks/auth/actions';
import { StSpace, StRow, StFormItem } from './styled';
import { dataTestIdHandler } from 'helpers/dataTestIdHandler';
import customHistory from 'helpers/history';
import {
  getSessionAccessToken,
  setSessionUsername,
} from 'helpers/sessionService';

const AuthForm = ({ ...others }) => {
  const [userEmail, setUserEmail] = useState('');
  const { useBreakpoint } = Grid;
  const breakpoint = useBreakpoint();
  const dispatch = useDispatch();
  const sessionData = useSelector((state) => state.auth?.sessionData);
  const dataTestPrefix = 'login';
  const baseURL = process.env.REACT_APP_AWS_V4_ASSETS;
  const logo = `${baseURL}/login/logo.png`;
  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };
  const tailLayout = {
    wrapperCol: { span: 24 },
  };

  useEffect(() => {
    if (getSessionAccessToken() && sessionData?.is_valid) {
      if (window.location.pathname === '/') {
        customHistory.push('/dashboard');
      } else {
        customHistory.push(window.location.pathname);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = (values) => {
    const { email } = values;
    const { password } = values;
    setSessionUsername(email);
    dispatch(authRequest({ email, password, source: 'WEB' }));
  };

  const goToForgotPassword = () => {
    setSessionUsername(userEmail);
    customHistory.push('/esqueci-a-senha');
  };
  return (
    <StRow $breakpoint={breakpoint} align="middle" justify="center" {...others}>
      <Col>
        <StSpace direction="vertical" align="center" $breakpoint={breakpoint}>
          <Row>
            <Col>
              <img
                src={logo}
                alt="Logo Concil Card"
                style={{ marginTop: '1rem' }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form
                requiredMark={false}
                name="login_form"
                role="form"
                {...layout}
                onFinish={onFinish}
                style={{
                  marginTop: '10rem',
                }}
              >
                <StFormItem
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor insira o email!',
                    },
                  ]}
                >
                  <Input
                    type="email"
                    placeholder="Insira o email"
                    onChange={(e) => setUserEmail(e.target.value)}
                    {...dataTestIdHandler(dataTestPrefix, 'email')}
                  />
                </StFormItem>
                <StFormItem
                  label="Senha"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor insira a senha!',
                    },
                  ]}
                  extra={
                    <Button
                      onClick={goToForgotPassword}
                      type="link"
                      style={{ padding: 0, marginTop: '1rem' }}
                      {...dataTestIdHandler(dataTestPrefix, 'forgot-password')}
                    >
                      Esqueceu a senha?
                    </Button>
                  }
                >
                  <Input.Password
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                    placeholder="Insira a senha"
                    {...dataTestIdHandler(dataTestPrefix, 'password')}
                  />
                </StFormItem>
                <StFormItem {...tailLayout}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: '100%', marginTop: '2rem' }}
                    {...dataTestIdHandler(dataTestPrefix, 'submit')}
                  >
                    Entrar
                  </Button>
                </StFormItem>
              </Form>
            </Col>
          </Row>
        </StSpace>
      </Col>
    </StRow>
  );
};

export default AuthForm;
