import { getSessionClientId } from 'helpers/sessionService';
import { bankingApi } from '../api';

const transformer = (apiResponse) => {
  return {
    data: apiResponse?.payload,
  };
};

const HARDCODED_CREDIT_PARTNER_ID = 2;

const postPartnerOptinRequest = async ({ userId }) => {
  const config = {
    creditPartnerId: HARDCODED_CREDIT_PARTNER_ID,
    clientId: getSessionClientId(),
    userId,
  };
  const response = await bankingApi().post('/credit/partner/optin', config);
  return transformer(response.data);
};

export default postPartnerOptinRequest;
