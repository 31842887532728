import { call, put } from 'redux-saga/effects';

import errorHandler from 'helpers/errorHandler';

import deleteUser from '../../../services/deleteUser';
import { deleteUserError, deleteUserSuccess } from './actions';

export function* deleteUserSaga(action) {
  try {
    const { payload } = action;

    const apiData = yield call(deleteUser, payload);

    yield put(deleteUserSuccess(apiData));
  } catch (err) {
    yield put(deleteUserError('Ocorreu um erro:', err));
    errorHandler('Ocorreu um erro:', err);
  }
}
