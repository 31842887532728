import React from 'react';
import { Tooltip } from 'antd';
import { RadarStatusTag } from '../components/RadarStatusTag';
import store from 'store';
import { onColumnFilter, columnFilters } from 'helpers/generalService';
import {
  radarDetailsFiltersStart,
  radarDetailsFiltersZeroLength,
} from 'store/ducks/filters/radar/details/actions';
import { clearFiltersFunctions } from 'helpers/clearFiltersFunctions';
import { CustomFilterDropdown } from 'commons/Table/components/CustomFilterDropdown';
import { customUpdateFilteredColumnsStart } from 'store/ducks/customUpdateFilteredColumns/actions';

export const radarEmailsColumns = ({
  radarId,
  filtersData: apiFilters,
  toParamsFilters,
  perPage,
}) => [
  {
    title: 'ID de envio',
    dataIndex: 'idt_radar_detail',
    filters:
      apiFilters.length > 0
        ? columnFilters(apiFilters, 'idt_radar_detail')
        : [],
    onFilter: (value, record) =>
      onColumnFilter(value, record, 'idt_radar_detail'),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // This "zero length" dispatch is utterly important,
        // in order to keep each column state independent (Not passing data from one column to the next)
        store.dispatch(radarDetailsFiltersZeroLength());
        store.dispatch(
          radarDetailsFiltersStart({
            otherParams: { radarId },
            columnName: 'idt_radar_detail',
            offset: 0,
            limit: perPage,
            q:
              toParamsFilters.length !== 0
                ? {
                    filter: toParamsFilters,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.storeName = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <CustomFilterDropdown
          otherParams={{ radarId }}
          key="idt_radar_detail"
          columnName="idt_radar_detail"
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={radarDetailsFiltersStart}
          filteredColumns={toParamsFilters}
          clearAction={radarDetailsFiltersZeroLength}
          storeName="radarDetailsFilters"
          updatedFilteredColumns={customUpdateFilteredColumnsStart}
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: 170,
    align: 'left',
  },
  {
    title: 'PFJ',
    dataIndex: 'client_code',
    filters:
      apiFilters.length > 0 ? columnFilters(apiFilters, 'client_code') : [],
    onFilter: (value, record) => onColumnFilter(value, record, 'client_code'),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // This "zero length" dispatch is utterly important,
        // in order to keep each column state independent (Not passing data from one column to the next)
        store.dispatch(radarDetailsFiltersZeroLength());
        store.dispatch(
          radarDetailsFiltersStart({
            otherParams: { radarId },
            columnName: 'client_code',
            offset: 0,
            limit: perPage,
            q:
              toParamsFilters.length !== 0
                ? {
                    filter: toParamsFilters,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.storeName = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <CustomFilterDropdown
          otherParams={{ radarId }}
          key="client_code"
          columnName="client_code"
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={radarDetailsFiltersStart}
          filteredColumns={toParamsFilters}
          clearAction={radarDetailsFiltersZeroLength}
          storeName="radarDetailsFilters"
          updatedFilteredColumns={customUpdateFilteredColumnsStart}
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: 180,
    align: 'left',
  },
  {
    title: 'E-mail',
    dataIndex: 'email_recipient',
    filters:
      apiFilters.length > 0 ? columnFilters(apiFilters, 'email_recipient') : [],
    onFilter: (value, record) =>
      onColumnFilter(value, record, 'email_recipient'),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // This "zero length" dispatch is utterly important,
        // in order to keep each column state independent (Not passing data from one column to the next)
        store.dispatch(radarDetailsFiltersZeroLength());
        store.dispatch(
          radarDetailsFiltersStart({
            otherParams: { radarId },
            columnName: 'email_recipient',
            offset: 0,
            limit: perPage,
            q:
              toParamsFilters.length !== 0
                ? {
                    filter: toParamsFilters,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.storeName = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <CustomFilterDropdown
          otherParams={{ radarId }}
          key="email_recipient"
          columnName="email_recipient"
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={radarDetailsFiltersStart}
          filteredColumns={toParamsFilters}
          clearAction={radarDetailsFiltersZeroLength}
          storeName="radarDetailsFilters"
          updatedFilteredColumns={customUpdateFilteredColumnsStart}
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: 250,
    align: 'left',
  },
  {
    title: 'Data de envio',
    dataIndex: 'sent_at',
    filters: apiFilters.length > 0 ? columnFilters(apiFilters, 'sent_at') : [],
    onFilter: (value, record) => onColumnFilter(value, record, 'sent_at'),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // This "zero length" dispatch is utterly important,
        // in order to keep each column state independent (Not passing data from one column to the next)
        store.dispatch(radarDetailsFiltersZeroLength());
        store.dispatch(
          radarDetailsFiltersStart({
            otherParams: { radarId },
            columnName: 'sent_at',
            offset: 0,
            limit: perPage,
            q:
              toParamsFilters.length !== 0
                ? {
                    filter: toParamsFilters,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.storeName = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <CustomFilterDropdown
          otherParams={{ radarId }}
          key="sent_at"
          columnName="sent_at"
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={radarDetailsFiltersStart}
          filteredColumns={toParamsFilters}
          clearAction={radarDetailsFiltersZeroLength}
          storeName="radarDetailsFilters"
          updatedFilteredColumns={customUpdateFilteredColumnsStart}
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: 200,
    align: 'left',
  },
  {
    title: 'Status do envio',
    dataIndex: 'status',
    filters: apiFilters.length > 0 ? columnFilters(apiFilters, 'status') : [],
    onFilter: (value, record) => onColumnFilter(value, record, 'status'),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // This "zero length" dispatch is utterly important,
        // in order to keep each column state independent (Not passing data from one column to the next)
        store.dispatch(radarDetailsFiltersZeroLength());
        store.dispatch(
          radarDetailsFiltersStart({
            otherParams: { radarId },
            columnName: 'status',
            offset: 0,
            limit: perPage,
            q:
              toParamsFilters.length !== 0
                ? {
                    filter: toParamsFilters,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.storeName = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <CustomFilterDropdown
          otherParams={{ radarId }}
          key="status"
          columnName="status"
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={radarDetailsFiltersStart}
          filteredColumns={toParamsFilters}
          clearAction={radarDetailsFiltersZeroLength}
          storeName="radarDetailsFilters"
          updatedFilteredColumns={customUpdateFilteredColumnsStart}
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: 200,
    align: 'left',
    render: (status, items) => (
      <Tooltip title={items?.status_message}>
        <RadarStatusTag status={status} />
      </Tooltip>
    ),
  },
  {
    title: 'Aberto',
    dataIndex: 'flg_has_opened',
    filters:
      apiFilters.length > 0 ? columnFilters(apiFilters, 'flg_has_opened') : [],
    onFilter: (value, record) =>
      onColumnFilter(value, record, 'flg_has_opened'),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // This "zero length" dispatch is utterly important,
        // in order to keep each column state independent (Not passing data from one column to the next)
        store.dispatch(radarDetailsFiltersZeroLength());
        store.dispatch(
          radarDetailsFiltersStart({
            otherParams: { radarId },
            columnName: 'flg_has_opened',
            offset: 0,
            limit: perPage,
            q:
              toParamsFilters.length !== 0
                ? {
                    filter: toParamsFilters,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.storeName = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <CustomFilterDropdown
          otherParams={{ radarId }}
          key="flg_has_opened"
          columnName="flg_has_opened"
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={radarDetailsFiltersStart}
          filteredColumns={toParamsFilters}
          clearAction={radarDetailsFiltersZeroLength}
          storeName="radarDetailsFilters"
          updatedFilteredColumns={customUpdateFilteredColumnsStart}
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: 100,
    align: 'left',
  },
];
