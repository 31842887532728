import { getSessionClientId } from 'helpers/sessionService';
import { api } from './api';

const transformer = ({ data }) => {
  return {
    users: data.associated_users,
    allUsers: data.all_users,
  };
};

const getListUsers = async (scopeId) => {
  const config = {
    params: {
      client_id: getSessionClientId(),
      scope_id: scopeId,
    },
  };

  const response = await api().get('/associated/users', config);
  return transformer(response.data);
};

export default getListUsers;
