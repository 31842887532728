import { call, put } from 'redux-saga/effects';

import errorHandler from 'helpers/errorHandler';

import {
  exportDuedateDetailsSuccess,
  exportDuedateDetailsError,
} from 'store/ducks/details/export/duedateDetails/actions';
import getExportDuedateDetailsFromAPI from 'services/details/export/duedateDetails';

export function* exportDuedateDetailsSaga(action) {
  try {
    const { payload } = action;

    const apiData = yield call(getExportDuedateDetailsFromAPI, payload);

    yield put(exportDuedateDetailsSuccess(apiData));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        exportDuedateDetailsError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    }
  }
}
