/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import customHistory from 'helpers/history';
import { CardResume } from '../../../../commons/CardResume';
import { updateScreenToScreenDateStart } from 'store/ducks/updateScreenToScreenDate/actions';
import dayjs from 'dayjs';

const AdvancementsCard = ({
  startDateAdvancements,
  endDateAdvancements,
  setStartDateAdvancements,
  setEndDateAdvancements,
  isDuedate,
  isAdvancements,
  isAdjustments,
  isRefunds,
}) => {
  const dispatch = useDispatch();
  const advancementsCardProps = {
    startDateAdvancements,
    endDateAdvancements,
    setStartDateAdvancements,
    setEndDateAdvancements,
    isDuedate,
    isAdvancements,
    isAdjustments,
    isRefunds,
  };

  const advancementPaymentsData = useSelector(
    (store) => store.advancementPaymentsDash.payments
  );
  const advancementPaymentsLoading = useSelector(
    (store) => store.advancementPaymentsDash.loading
  );

  const dataTestPrefix = 'advancements-card';

  const goToAdvancements = () => {
    customHistory.push('/antecipacao-intermediario');
    dispatch(
      updateScreenToScreenDateStart({
        startDate: startDateAdvancements,
        endDate: endDateAdvancements,
      })
    );
  };

  return (
    <CardResume
      titleCard="Antecipações"
      goToUrl={goToAdvancements}
      dataCard={advancementPaymentsData}
      loading={advancementPaymentsLoading}
      dataTestPrefix={dataTestPrefix}
      {...advancementsCardProps}
    />
  );
};

AdvancementsCard.propTypes = {
  startDateAdvancements: PropTypes.instanceOf(dayjs).isRequired,
  endDateAdvancements: PropTypes.instanceOf(dayjs).isRequired,
  setStartDateAdvancements: PropTypes.func.isRequired,
  setEndDateAdvancements: PropTypes.func.isRequired,
};

export default AdvancementsCard;
