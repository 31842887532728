import styled, { css } from 'styled-components';
import { Button, Spin, Table } from 'antd';
import { colors } from 'styles/colors';
import {
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
} from '@ant-design/icons';

export const StTable = styled(Table)`
  &&& {
    .ant-table-cell.ant-table-cell.ant-table-cell {
      border: none;
    }

    .ant-table-column-sorters {
      display: inline-flex;
    }

    .fee-table-cell {
      min-width: 230px;
      padding: 5px 10px;
    }

    .fee-table-cell-actions {
      min-width: 100px;
    }
  }
`;

export const StLoadingOutlined = styled(LoadingOutlined)`
  &&& {
    font-size: 24px;
    margin-left: 10px;
  }
`;

export const StEditOutlined = styled(EditOutlined)`
  margin-right: 20px;
  font-size: 16px;
  color: ${({ disabled }) => (disabled ? colors.gray6 : colors.primary6)};
  cursor: ${({ disabled }) => (disabled ? 'default !important' : 'pointer')};
`;

export const StDeleteOutlined = styled(DeleteOutlined)`
  font-size: 16px;
  color: ${({ disabled }) => (disabled ? colors.gray6 : colors.red5)};
  cursor: ${({ disabled }) => (disabled ? 'default !important' : 'pointer')};
`;

export const StActionButtonsContainer = styled.div`
  display: flex;
`;

const actionButton = css`
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;

  &:hover {
    background: none;
  }
`;

export const StButtonSaveEdition = styled(Button)`
  ${actionButton};
  margin-right: 8px;
  color: ${colors.primary6};
`;

export const StButtonCancelEdition = styled(Button)`
  ${actionButton};
  color: ${colors.red5};
`;

export const StUpdateSpin = styled(Spin)`
  margin-left: 5px;
`;
