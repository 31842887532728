import React from 'react';
import {
  StNotificationButton,
  StNotificationContainer,
  StNotificationDot,
} from './styled';
import { BellOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { dataTestIdHandler } from 'helpers/dataTestIdHandler';

export function NotificationButton({
  hasUnreadNotifications,
  notificationsAreVisible,
  setNotificationsAreVisible,
  dataTestPrefix,
  ...rest
}) {
  return (
    <StNotificationContainer
      notificationsAreVisible={notificationsAreVisible}
      {...rest}
    >
      <Tooltip
        title="Acompanhe aqui o andamento das suas solicitações e relatórios."
        placement="bottomRight"
        style={{
          position: 'relative',
        }}
      >
        <StNotificationButton
          {...dataTestIdHandler(dataTestPrefix, 'notification-button')}
          icon={
            <BellOutlined
              style={{
                fontSize: '2rem',
                width: '100%',
                height: '100%',
              }}
            />
          }
          type="link"
          onClick={() => setNotificationsAreVisible(!notificationsAreVisible)}
        />
        {hasUnreadNotifications && <StNotificationDot />}
      </Tooltip>
    </StNotificationContainer>
  );
}
