import { actionTypes } from 'store/ducks/cnpjMaintenance/putStores/types';

const INITIAL_STATE = {
  error: undefined,
  errorMessage: undefined,
  loading: false,
  isError: false,
  success: false,
  storesLoading: [],
};
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.PUT_STORES_START:
      return {
        ...state,
        loading: true,
        error: undefined,
        errorMessage: undefined,
        success: false,
        isError: false,
        storesLoading: [action.payload.storeId],
      };
    case actionTypes.PUT_STORES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        isError: false,
        success: true,
        errorMessage: undefined,
        storesLoading: [],
      };
    case actionTypes.PUT_STORES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error.error,
        errorMessage: action.payload,
        isError: true,
        success: false,
        details: undefined,
        storesLoading: [],
      };
    default:
      return state;
  }
};
export default reducer;
