/* eslint-disable no-plusplus */
/* eslint-disable consistent-return */
import { api } from './api';
import { notification } from 'antd';

const deleteUser = async (payload) => {
  const promises = [];

  for (let i = 0; i < payload?.data?.users.length; i++) {
    promises.push(api().delete(`/manager/user/${payload?.data?.users[i]}`));
  }

  await Promise.all(promises)
    .then((values) => {
      values.forEach((value) => {
        return Promise.resolve(value);
      });

      if (values.length !== 0 && values.length > 1) {
        notification.success({
          message: 'Os usuários selecionados foram removidos com sucesso!',
        });
      } else if (values.length === 1) {
        notification.success({
          message: 'O usuário selecionado foi removido com sucesso!',
        });
        return Promise.resolve(values[0]);
      } else {
        return Promise.resolve(values);
      }
    })
    .catch((error) => {
      return Promise.reject(new Error(error?.response?.data?.message));
    });
};

export default deleteUser;
