import styled from 'styled-components';
import { Button, Col, Row } from 'antd';
import { colors } from 'styles/colors';

export const StContainer = styled.div`
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  background-color: ${colors.gray1};
  border-radius: 4px;
  cursor: pointer;
`;

export const StLimiter = styled.div`
  padding: 16px;
`;

export const StButton = styled(Button)`
  color: #1790ff !important;
  position: absolute;
  bottom: 24px;
`;

export const StBankLegendGridRow = styled(Row)`
  display: flex;
  justify-content: center;
  margin-top: 12px;
`;

export const StBankLegendGridCol = styled(Col)`
  
`;

export const StBankColor = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(props) => `${props.color}`};
`;
