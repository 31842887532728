import { bankingApi } from '../api';

const transformer = (apiResponse) => {
  return {
    data: apiResponse?.payload,
  };
};

const postInteractRetryApprovalRequest = async ({ creditId, userId }) => {
  const config = {
    creditId,
    userId,
  };
  const response = await bankingApi().post(
    '/credit/interact/retry/approval',
    config
  );
  return transformer(response.data);
};

export default postInteractRetryApprovalRequest;
