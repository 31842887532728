import { action } from 'typesafe-actions';
import { actionTypes } from 'store/ducks/reconciled/payments/types';

export const paymentsReconciledStart = (data) =>
  action(actionTypes.PAYMENTS_RECONCILED_START, data);
export const paymentsReconciledSuccess = (data) =>
  action(actionTypes.PAYMENTS_RECONCILED_SUCCESS, data);
export const paymentsReconciledError = (errorMessage, error) =>
  action(actionTypes.PAYMENTS_RECONCILED_ERROR, { errorMessage }, undefined, {
    error,
  });
