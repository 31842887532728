import React, { useContext, useEffect, useState } from 'react';
import { StContainer } from './styled';
import { SelectInfiniteScroll } from 'commons/SelectInfiniteScroll';
import { Col, Row } from 'antd';
import FeeHeader from '../FeeHeader/FeeHeader';
import { ExportButton } from './components/ExportButton';
import { useFeesStoresFilters } from 'components/Fees/hooks/useFeesStoresFilters';
import { FeesContext } from 'components/Fees/Fees';

const Header = () => {
  const { setSelectedStore } = useContext(FeesContext);

  const [page, setPage] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [perPage, setPerPage] = useState(10);
  const [searchStoreFilterInput, setSearchStoreFilterInput] = useState('');

  // in future we can use to clear filters when user changes tabs
  // eslint-disable-next-line no-unused-vars
  const [isClearFilterStore, setIsClearFilterStore] = useState(false);

  const transformListOfStoresFilter = (_listOfStoresFilter = []) =>
    _listOfStoresFilter.map(({ id: storeId, value: store }) => ({
      value: storeId,
      label: store,
    }));

  const { isLoadingSearch, storeFilterOptions, storeFilterMeta } =
    useFeesStoresFilters(searchStoreFilterInput, page, perPage);
  const { has_next: hasMoreStores } = storeFilterMeta;

  const onStoreSelected = ({ value, label }) => {
    if (!value || value === undefined || value === '') {
      setSearchStoreFilterInput('');
      setSelectedStore({});
      return;
    }
    setSearchStoreFilterInput(label);
    setSelectedStore({
      value,
      label,
    });
    setPage(1);
  };

  useEffect(() => {
    setPage(1);
  }, [searchStoreFilterInput]);

  return (
    <StContainer>
      <FeeHeader>
        <ExportButton />
      </FeeHeader>
      <Col style={{ width: 340 }}>
        <Row style={{ width: '100%' }}>
          <SelectInfiniteScroll
            placeholder="Buscar CNPJ ou apelido da loja"
            options={transformListOfStoresFilter(storeFilterOptions)}
            hasMoreItems={hasMoreStores}
            loadingItems={isLoadingSearch}
            searchFilterInput={searchStoreFilterInput}
            setSearchFilter={setSearchStoreFilterInput}
            onOptionClick={onStoreSelected}
            setPage={setPage}
            isClear={isClearFilterStore}
            disabled={isLoadingSearch}
          />
        </Row>
      </Col>
    </StContainer>
  );
};

export default Header;
