import { actionTypes } from './types';

const INITIAL_STATE = {
  error: false,
  loading: false,
  errorMessage: undefined,
  message: '',
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case actionTypes.CREATE_PASSWORD_START:
    return { ...state, loading: true, error: false };
  case actionTypes.CREATE_PASSWORD_SUCCESS:
    return {
      ...state,
      loading: false,
      error: false,
      message: action.payload.message,
    };
  case actionTypes.CREATE_PASSWORD_ERROR:
    return { ...state, loading: false, error: action.error };
  default:
    return state;
  }
};

export default reducer;
