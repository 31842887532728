import {
  parseListOfClientsFiltersToWeb,
  parseListOfClientsQToAPI,
} from 'utils/parsers/listOfClientsParser';
import { lambdaApi } from '../api';

const transform = (response, columnName) => {
  const filters = {
    results: [
      {
        text: columnName,
        values: parseListOfClientsFiltersToWeb(
          response?.data?.results,
          columnName
        ),
      },
    ],
    hasMore: response?.meta?.has_next,
    offset: response?.meta?.offset,
    limit: response?.meta?.limit,
    column: response?.meta?.column,
  };

  return filters;
};

const getListOfClientsFiltersFromAPI = async ({
  q,
  sortFields,
  offset,
  columnName,
  filter,
}) => {
  const allowedColumns = [
    'is_implanted',
    'client_code_name',
    'created_at',
    'is_api_connected',
    'is_sandbox_api_active',
    'is_production_api_active',
  ];

  const parsedQ = parseListOfClientsQToAPI(q);

  const filters =
    parsedQ.length > 0
      ? parsedQ.filter(({ name }) => allowedColumns.includes(name))
      : [];

  const config = {
    params: {
      limit: 10,
      offset,
      column: columnName,
      filter,
      q: JSON.stringify({ filter: filters }),
      sort_fields: JSON.stringify(sortFields),
    },
  };

  const response = await lambdaApi().get('/clients/filters', config);
  return transform(response.data, columnName);
};

export default getListOfClientsFiltersFromAPI;
