import { Button, Col, Row, Typography } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { updateBanksDetailsFilters } from 'store/ducks/filters/details/banksDetailsFilters/actions';
import { colors } from 'styles/colors';
import { TitleAndIcon } from '../../../../../commons/TitleAndIcon';
import customHistory from '../../../../../helpers/history';
import { CardStyle } from './styled';
import { updateFilteredColumnsStart } from 'store/ducks/updateFilteredColumns/actions';

const ItemCardList = ({ item, name, bank_code: bankCode }) => {
  const dispatch = useDispatch();
  const goToReports = () => {
    dispatch(
      updateBanksDetailsFilters([
        {
          name: 'bank_code',
          val: [bankCode],
        },
        {
          name: 'bank_branch',
          val: [item.bank_agency],
        },
        {
          name: 'bank_account',
          val: [item.bank_account],
        },
      ])
    );
    dispatch(
      updateFilteredColumnsStart({
        name: 'bank_code',
        val: [bankCode],
      })
    );
    dispatch(
      updateFilteredColumnsStart({
        name: 'bank_branch',
        val: [item.bank_agency],
      })
    );
    dispatch(
      updateFilteredColumnsStart({
        name: 'bank_account',
        val: [item.bank_account],
      })
    );
    customHistory.push('/bancos-relatorios');
  };

  return (
    <CardStyle
      bordered={false}
      title={<TitleAndIcon titleText={name} titleFontSize="1.7rem" />}
      actions={[
        <div style={{ textAlign: 'end' }}>
          <Button
            type="link"
            size="small"
            onClick={goToReports}
            style={{ color: colors.blue6 }}
          >
            Ver relatório
          </Button>
        </div>,
      ]}
      style={{ marginBottom: 30, marginRight: 8 }}
    >
      <Row align="start" style={{ marginBottom: '10px' }}>
        <Col>
          <Typography.Text style={{ fontSize: '1.5rem' }} strong>
            {item.acquirer_name}
          </Typography.Text>
        </Col>
      </Row>
      <Row align="start">
        <Col>
          <Typography.Text
            style={{ fontSize: '1.5rem', color: 'rgba(0,0,0,0.45)' }}
          >
            Agência
          </Typography.Text>
          <br />
          <Typography.Text style={{ fontSize: '1.5rem' }} strong>
            {item.bank_agency}
          </Typography.Text>
        </Col>
        <Col style={{ paddingLeft: '2rem' }}>
          <Typography.Text
            style={{ fontSize: '1.5rem', color: 'rgba(0,0,0,0.45)' }}
          >
            Conta
          </Typography.Text>
          <br />
          <Typography.Text style={{ fontSize: '1.5rem' }} strong>
            {item.bank_account}
          </Typography.Text>
        </Col>
      </Row>
      <Row align="start" style={{ marginTop: '10px' }}>
        <Col>
          <Typography.Text
            style={{ fontSize: '1.5rem', color: 'rgba(0,0,0,0.45)' }}
          >
            Total de recebimentos
          </Typography.Text>
          <br />
          <Typography.Text style={{ fontSize: '1.5rem' }} strong>
            {new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(item.total_receipt_amount)}
          </Typography.Text>
        </Col>
      </Row>
    </CardStyle>
  );
};

ItemCardList.propTypes = {
  item: PropTypes.shape({
    total_receipt_amount: PropTypes.number,
    bank_account: PropTypes.string,
    bank_agency: PropTypes.string,
  }).isRequired,
  name: PropTypes.string.isRequired,
  bank_code: PropTypes.string.isRequired,
};

export default ItemCardList;
