import { getAccessRoutes, getDefaultRoute } from 'helpers/getContextRoutes';

export const useUserRoutes = ({
  planId = undefined,
  scopeId = undefined,
  skinId = undefined,
}) => {
  const accessRoutes = getAccessRoutes({ planId, scopeId, skinId });
  const defaultRoute = getDefaultRoute({ planId });

  const hasPermission = ({ pathname }) => {
    return accessRoutes.includes(pathname);
  };

  return {
    accessRoutes,
    defaultRoute,
    hasPermission,
  };
};
