import styled from 'styled-components';
import { Col, Row, Table, Typography } from 'antd';
import { marginMainContainer } from 'helpers/style';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { colors } from 'styles/colors';

export const Container = styled.div`
  margin: 22rem 5rem 0;

  .cr-right-title {
    font-size: 20px !important;
  }
  .cr-left-border-title {
    border-left: 1px solid ${colors.gray5};
    font-size: 20px !important;
  }
  .cr-left-border {
    border-left: 1px solid ${colors.gray5};
  }

  .ant-table-body {
    overflow-x: scroll;
  }
`;

export const Limiter = styled.div`
  margin: ${(props) => marginMainContainer(props.$breakpoint)};
`;

export const AnalyzeLink = styled(Typography.Link)`
  ${({ disabled }) =>
    disabled &&
    `
    color: gray !important;
  `}
`;

export const FirstRow = styled(Row)`
  &&& {
    display: flex;
    align-items: center;
  }
`;

export const SecondRow = styled(Row)`
  &&& {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
`;

export const TableContainer = styled.div`
  margin-top: 1.75rem;
`;

export const Title = styled(Typography.Title)`
  &&& {
    margin-top: 2rem;
    font-size: 20px;
    font-weight: 500;
  }
`;

export const Subtitle = styled(Typography.Text)`
  &&& {
    font-size: 14px;
    color: ${colors.gray8};
  }
`;

export const ConciliationTable = styled(Table)`
  &&& {
    .ant-pagination-options::before {
      content: 'Itens por página';
      margin-right: 10px;
    }

    .ant-table-thead > tr > th {
      background-color: ${colors.gray2} !important;
    }

    .ant-table table {
      border-collapse: unset !important;
    }
  }
`;

export const NotificationColumn = styled(Col)`
  &&& {
    display: flex;
    align-items: center;
    margin-top: 16px;
    border: 1px solid ${colors.gold3};
    background-color: ${colors.gold1};
  }
`;

export const NotificationIcon = styled(ExclamationCircleOutlined)`
  &&& {
    width: 10.5px;
    color: ${colors.gold6};
    margin-left: 12px;
  }
`;

export const NotificationText = styled(Typography.Text)`
  color: ${colors.gold6};
  margin-left: 8px;
  margin-top: 2px;
`;
