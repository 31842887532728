import dayjs from 'dayjs';
import { lambdaApi } from '../../api';
import { parseCurrency } from 'helpers/currency';

const transformer = ({ data }) => {
  const transformedResponse = {
    acquirer: {
      totalAmount: parseCurrency(data?.acquirer_total),
      rawTotalAmount: data?.acquirer_total,
    },
    customer: {
      totalAmount: parseCurrency(data?.customer_total),
      rawTotalAmount: data?.customer_total,
    },
    difference: parseCurrency(data?.difference),
  };

  return transformedResponse;
};

const getBankSummary = async ({ startDate, endDate }) => {
  const config = {
    params: {
      start_date: dayjs(startDate).format('YYYY-MM-DD'),
      end_date: dayjs(endDate).format('YYYY-MM-DD'),
    },
  };

  const response = await lambdaApi().get('/summary/bank/reconciled', config);
  return transformer(response.data);
};

export default getBankSummary;
