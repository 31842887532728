import { action } from 'typesafe-actions';
import { actionTypes } from 'store/ducks/tokenSandbox/types';

export const tokenSandboxPostStart = (data) =>
  action(actionTypes.TOKEN_SANDBOX_POST_START, data);
export const tokenSandboxPostSuccess = (data) =>
  action(actionTypes.TOKEN_SANDBOX_POST_SUCCESS, data);
export const tokenSandboxPostError = (errorMessage, error) =>
  action(actionTypes.TOKEN_SANDBOX_POST_ERROR, { errorMessage }, undefined, {
    error,
  });

export const tokenSandboxDeleteStart = (data) =>
  action(actionTypes.TOKEN_SANDBOX_DELETE_START, data);
export const tokenSandboxDeleteSuccess = (data) =>
  action(actionTypes.TOKEN_SANDBOX_DELETE_SUCCESS, data);
export const tokenSandboxDeleteError = (errorMessage, error) =>
  action(actionTypes.TOKEN_SANDBOX_DELETE_ERROR, { errorMessage }, undefined, {
    error,
  });

export const tokenSandboxGetStart = (data) =>
  action(actionTypes.TOKEN_SANDBOX_GET_START, data);
export const tokenSandboxGetSuccess = (data) =>
  action(actionTypes.TOKEN_SANDBOX_GET_SUCCESS, data);
export const tokenSandboxGetError = (errorMessage, error) =>
  action(actionTypes.TOKEN_SANDBOX_GET_ERROR, { errorMessage }, undefined, {
    error,
  });

export const tokenSandboxPutStart = (data) =>
  action(actionTypes.TOKEN_SANDBOX_PUT_START, data);
export const tokenSandboxPutSuccess = (data) =>
  action(actionTypes.TOKEN_SANDBOX_PUT_SUCCESS, data);
export const tokenSandboxPutError = (errorMessage, error) =>
  action(actionTypes.TOKEN_SANDBOX_PUT_ERROR, { errorMessage }, undefined, {
    error,
  });

export const tokenSandboxDisableTokenStart = (data) =>
  action(actionTypes.TOKEN_SANDBOX_DISABLE_TOKEN_START, data);
export const tokenSandboxDisableTokenSuccess = (data) =>
  action(actionTypes.TOKEN_SANDBOX_DISABLE_TOKEN_SUCCESS, data);
export const tokenSandboxDisableTokenError = (errorMessage, error) =>
  action(
    actionTypes.TOKEN_SANDBOX_DISABLE_TOKEN_ERROR,
    { errorMessage },
    undefined,
    {
      error,
    }
  );

export const rowClientIdSandboxStart = (data) =>
  action(actionTypes.ROW_CLIENT_ID_SANDBOX_START, data);
export const rowClientIdSandboxSuccess = (data) =>
  action(actionTypes.ROW_CLIENT_ID_SANDBOX_SUCCESS, data);
export const rowClientIdSandboxError = (errorMessage, error) =>
  action(actionTypes.ROW_CLIENT_ID_SANDBOX_ERROR, { errorMessage }, undefined, {
    error,
  });
