import { actionTypes } from 'store/ducks/details/export/duedateDetails/types';

const INITIAL_STATE = {
  error: undefined,
  errorMessage: undefined,
  loading: false,
  isError: false,
  meta: {
    current_page: 1,
    num_results: 0,
    results_per_page: 10,
    total_pages: 100,
  },
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case actionTypes.EXPORT_DUEDATE_DETAILS_START:
    return {
      ...state,
      loading: true,
      error: undefined,
      errorMessage: undefined,
      isError: false,
    };
  case actionTypes.EXPORT_DUEDATE_DETAILS_SUCCESS:
    return {
      ...state,
      loading: false,
      error: undefined,
      meta: action.payload.meta,
      isError: false,
      errorMessage: undefined,
    };
  case actionTypes.EXPORT_DUEDATE_DETAILS_ERROR:
    return {
      ...state,
      loading: false,
      error: action.error.error,
      errorMessage: action.payload,
      isError: true,
      summary: undefined,
    };
  default:
    return state;
  }
};

export default reducer;
