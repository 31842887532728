import { call, put } from 'redux-saga/effects';
import errorHandler from 'helpers/errorHandler';
import { errorToShow } from 'store/ducks/errorToShow/actions';

import {
  duedatePaymentsDashError,
  duedatePaymentsDashSuccess,
} from './actions';
import getDuedatePayments from '../../../../services/payments/duedatePayments';

export function* duedatePaymentsDashSaga(actions) {
  try {
    const apiData = yield call(getDuedatePayments, actions.payload);
    yield put(duedatePaymentsDashSuccess(apiData));
  } catch (err) {
    if (
      err?.response?.data?.error &&
      err?.response?.data?.error?.type &&
      err?.response?.data?.error?.message
    ) {
      yield put(
        errorToShow({
          message: err.response.data.error.message,
          type: err.response.data.error.type,
        })
      );
      yield put(
        duedatePaymentsDashError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    }
  }
}
