/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable quotes */
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { columns } from './utils';
import { FiltersContainer } from '../../../commons/FiltersContainer';
import { LabelAndFilter } from '../../../commons/LabelAndFilter';
import DatePicker from 'commons/DatePicker/DatePicker';
import { banksReportsStart } from '../../../store/ducks/banks/listBanksReports/actions';
import { Tables } from 'commons/Tables';
import { createNotificationStart } from 'store/ducks/notifications/actions';
import { updateScreenToScreenDateStart } from 'store/ducks/updateScreenToScreenDate/actions';
import {
  banksDetailsFiltersZeroLength,
  updateBanksDetailsFilters,
} from 'store/ducks/filters/details/banksDetailsFilters/actions';
import { generateFilterDataStructure } from 'helpers/tableService';
import {
  getSessionClientId,
  getSessionClientName,
} from 'helpers/sessionService';
import { MAX_CSV_ROWS, MAX_EXCEL_ROWS } from 'constants/general';
import { disableLastMonth } from 'helpers/disableDates';
import { exportFileParser } from 'utils/parsers/exportFileParser';
import { perPageOptions, showTotalPage } from 'constants/perPageOptions';
import dayjs from 'dayjs';
import { shortId } from 'helpers/shortId';
import { resetUpdateFilteredColumnsStart } from 'store/ducks/updateFilteredColumns/actions';
import { setReportsCustomColumns } from 'store/ducks/reportsCustomColumns/actions';
import { MobileCards } from './components/MobileCards';

// eslint-disable-next-line react/prop-types
const BanksReports = () => {
  const dispatch = useDispatch();
  const { RangePicker } = DatePicker;

  const rangePickerRef = useRef(null);

  const permissionsData = useSelector((state) => state.permissions.data);
  const clientId = getSessionClientId();
  const clientName = getSessionClientName();
  const {
    scope_id: scopeId,
    profile_id: profileId,
    user_id: userId,
  } = permissionsData;

  const [localFilters, setLocalFilters] = useState([]);
  const [dropdownOption, setDropdownOption] = useState('');

  const filtersData = useSelector((state) => state.banksDetailsFilters.filters);

  const startDate = useSelector(
    (state) => state.updateScreenToScreenDate.startDate
  );
  const endDate = useSelector(
    (state) => state.updateScreenToScreenDate.endDate
  );

  const [sortFields, setSortFields] = useState({});

  const [perPage, setPerPage] = useState(10);
  const [pageTotal, setPageTotal] = useState(100);
  const [pageCurrent, setPageCurrent] = useState(1);
  const [disableExcel, setDisableExcel] = useState(false);
  const [disableCSV, setDisableCSV] = useState(false);

  const toParamsFilters = useSelector(
    (state) => state.banksDetailsFilters?.toParamsFilters
  );

  const banksLoading = useSelector((state) => state.banksReports.loading);
  const banksDetails = useSelector((state) => state.banksReports.details);
  const banksMeta = useSelector((state) => state.banksReports.meta);
  const banksError = useSelector((state) => state.banksReports.isError);
  const banksNetAmount = useSelector((state) => state.banksReports.netAmount);

  const [calendarIsOpen, setCalendarIsOpen] = useState(false);
  const dateHasBeenUpdated = useSelector(
    (state) => state.updateScreenToScreenDate.dateHasBeenUpdated
  );
  const [dates, setDates] = useState([null]);
  const [hackValue, setHackValue] = useState(null);

  const columnNames = [
    'Filial',
    'Data do pagamento',
    'Adquirente',
    'Valor líquido (R$)',
    'Banco',
    'Agência',
    'Conta',
  ];

  const dataSource = banksDetails.map((obj) => ({
    ...obj,
    key: shortId(),
  }));

  useEffect(() => {
    return () => {
      dispatch(resetUpdateFilteredColumnsStart());
      dispatch(updateBanksDetailsFilters([]));
      dispatch(setReportsCustomColumns([]));
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      banksReportsStart({
        startDate,
        endDate,
        sortFields,
        page: pageCurrent,
        perPage,
        q: {
          filter: toParamsFilters,
        },
      })
    );
  }, [
    dispatch,
    startDate,
    endDate,
    sortFields,
    pageCurrent,
    perPage,
    toParamsFilters,
    dateHasBeenUpdated,
  ]);

  useEffect(() => {
    setPageTotal(banksMeta.num_results);
    if (banksMeta.total_items > MAX_EXCEL_ROWS) {
      setDisableExcel(true);
    } else {
      setDisableExcel(false);
    }
    if (banksMeta.total_items > MAX_CSV_ROWS) {
      setDisableCSV(true);
    } else {
      setDisableCSV(false);
    }
  }, [banksMeta]);

  const onRangePickerOpenOrCloseChange = (open) => {
    setCalendarIsOpen(open);
  };

  const onRangePickerChange = (_dates) => {
    if (_dates?.length) {
      if (
        calendarIsOpen &&
        (!_dates[1] || _dates[1]?.diff(_dates[0], 'days') > 31)
      ) {
        dispatch(
          updateScreenToScreenDateStart({
            startDate: _dates[0],
            endDate: dayjs(_dates[0]).add(31, 'days'),
          })
        );
      } else if (
        calendarIsOpen &&
        (!_dates[1] || _dates[1]?.diff(_dates[0], 'days') <= 31)
      ) {
        dispatch(
          updateScreenToScreenDateStart({
            startDate: _dates[0],
            endDate: _dates[1],
          })
        );
      }
    } else {
      dispatch(
        updateScreenToScreenDateStart({
          startDate: dayjs().subtract(32, 'days'),
          endDate: dayjs().subtract(1, 'day'),
        })
      );
    }
  };

  const onTablePaginationOrFiltersOrSorterChange = async (
    pagination,
    tableFilters,
    sorter
  ) => {
    const hasMultipleColumnSorting = Array.isArray(sorter);
    if (hasMultipleColumnSorting) {
      let _sortFields = {};
      sorter.forEach((column) => {
        _sortFields = {
          ..._sortFields,
          [column.field]: column.order,
        };
      });
      setSortFields(_sortFields);
    } else {
      setSortFields({ [sorter.field]: sorter.order });
    }

    const filterDataToDispatch = generateFilterDataStructure(tableFilters);

    setLocalFilters(tableFilters);
    dispatch(updateBanksDetailsFilters(filterDataToDispatch));
    setPerPage(pagination.pageSize);
    setPageTotal(banksMeta.num_results);
    setPageCurrent(pagination.current);
  };

  const fileName =
    'relatorio_bancos.' + (dropdownOption === 'excel' ? 'xlsx' : 'csv');

  const format = 'YYYY-MM-DD';

  const notificationConfig = {
    clientId,
    userId,
    status: 'PREPARANDO',
    title: 'Preparando arquivo',
    isRead: false,
    isSendEmail: false,
    fileName,
    isExpired: false,
    createdBy: userId,
    updatedBy: userId,
    message: `Estamos preparando seu arquivo ${fileName} para o download.`,
    table: 'bank',
    fileType: dropdownOption === 'excel' ? 'xlsx' : 'csv',
    scopeId,
    profileId,
    startDate: dayjs(startDate).format(format),
    endDate: dayjs(endDate).format(format),
    q: {
      filter: exportFileParser(toParamsFilters),
    },
    sortFields: '',
    clientName,
  };

  const clearDates = () => {
    setDates([null, null]);
  };

  const antInputClearBtn =
    document.getElementsByClassName('ant-picker-clear')[0];

  useEffect(() => {
    const antInput = document.getElementsByClassName('ant-picker-input');

    antInput[0]?.addEventListener('click', clearDates);

    return () => {
      antInput[0]?.removeEventListener('onclick', clearDates);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const _antInputClearBtn =
        document.getElementsByClassName('ant-picker-clear')[0];
      // eslint-disable-next-line no-unused-expressions
      _antInputClearBtn?.addEventListener('click', (e) => {
        clearDates();
        e.stopPropagation();
      });
    }, 100);
  }, [antInputClearBtn]);

  useEffect(() => {
    const antInput = document.getElementsByClassName('ant-picker-input');
    const handleDates = () => setDates([startDate, null]);
    antInput[1]?.addEventListener('click', handleDates);

    return () => {
      antInput[1]?.removeEventListener('onclick', handleDates);
    };
  }, [startDate]);

  const onOpenChange = (open) => {
    onRangePickerOpenOrCloseChange(open);

    if (open) {
      setHackValue([startDate, null]);
    } else {
      setHackValue(null);
    }
  };

  const [showTable, setShowTable] = useState(true);

  const resetFiltersStates = () => {
    dispatch(banksDetailsFiltersZeroLength());
    dispatch(updateBanksDetailsFilters([]));
    dispatch(resetUpdateFilteredColumnsStart());
    dispatch(setReportsCustomColumns([]));
    setSortFields({});
    setPageCurrent(1);
  };

  const handleResetFilters = () => {
    setShowTable(false);
    resetFiltersStates();
  };

  useEffect(() => {
    setShowTable(true);
  }, [showTable]);

  const onPageMobileChange = (page, size) => {
    setPageCurrent(page);
    setPerPage(size);
  };

  const onPageSizeMobileChange = (current, size) => {
    setPageCurrent(current);
    setPerPage(size);
  };

  return (
    <>
      <FiltersContainer
        title="Bancos"
        subTitle="Total recebido por banco, agência e conta"
      >
        <LabelAndFilter label="Calendário">
          <RangePicker
            ref={rangePickerRef}
            format="DD/MM/YYYY"
            onOpenChange={onOpenChange}
            onCalendarChange={(val) => {
              setHackValue(val);
              setDates(val);
              if (val?.every((v) => v)) {
                setCalendarIsOpen(!calendarIsOpen);
              }
            }}
            disabled={banksLoading}
            disabledDate={(current) => {
              if (dates) {
                return disableLastMonth(current, ...dates);
              }
              return disableLastMonth(current, startDate, endDate);
            }}
            open={calendarIsOpen}
            value={hackValue || [startDate, endDate]}
            onChange={onRangePickerChange}
          />
        </LabelAndFilter>
      </FiltersContainer>
      <MobileCards
        data={dataSource}
        loading={banksLoading}
        pagination={{
          current: pageCurrent,
          pageSize: perPage,
          total: pageTotal,
          pageSizeOptions: perPageOptions,
          showTotal: showTotalPage,
        }}
        error={banksError}
        onPageSizeChange={onPageSizeMobileChange}
        onPageChange={onPageMobileChange}
      />
      {showTable && (
        <Tables
          dataSource={dataSource}
          totalText="Total líquido de bancos: "
          columns={columns(filtersData, toParamsFilters)}
          toParamsFilters={toParamsFilters}
          localFilters={localFilters}
          loading={banksLoading}
          error={banksError}
          headerNetAmount={banksNetAmount}
          createNotificationAction={createNotificationStart}
          notificationConfig={notificationConfig}
          dropdownOption={dropdownOption}
          setDropdownOption={setDropdownOption}
          pagination={{
            current: pageCurrent,
            pageSize: perPage,
            total: pageTotal,
            pageSizeOptions: perPageOptions,
            showTotal: showTotalPage,
          }}
          onChange={onTablePaginationOrFiltersOrSorterChange}
          defaultSelectedColumns={columnNames}
          disableExcel={disableExcel}
          disableCSV={disableCSV}
          handleResetFilters={handleResetFilters}
        />
      )}
    </>
  );
};

export default BanksReports;
