/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reconciledSalesDetailsStart } from 'store/ducks/details/reconciledSalesDetails/actions';
import {
  reconciledSalesDetailsFiltersZeroLength,
  updateReconciledSalesDetailsFilters,
} from 'store/ducks/filters/details/reconciledSalesDetailsFilters/actions';
import { columns } from 'components/Reports/SalesReconciliationReports/utils';
import { MobileCards } from 'components/Reports/SalesReconciliationReports/components';
import { Filters } from 'commons/Intermediate/Filters';
import { Tables } from 'commons/Tables';
import { generateFilterDataStructure } from 'helpers/tableService';
import { updateScreenToScreenDateStart } from 'store/ducks/updateScreenToScreenDate/actions';
import { createNotificationStart } from 'store/ducks/notifications/actions';
import {
  getSessionClientId,
  getSessionClientName,
} from 'helpers/sessionService';
import { MAX_CSV_ROWS, MAX_EXCEL_ROWS } from 'constants/general';
import { disableLastMonth } from 'helpers/disableDates';
import { exportFileParser } from 'utils/parsers/exportFileParser';
import { perPageOptions, showTotalPage } from 'constants/perPageOptions';
import { onColumnSort } from 'helpers/sorter';
import dayjs from 'dayjs';
import { shortId } from 'helpers/shortId';
import { resetUpdateFilteredColumnsStart } from 'store/ducks/updateFilteredColumns/actions';
import { setReportsCustomColumns } from 'store/ducks/reportsCustomColumns/actions';

const SalesReconciliationReports = () => {
  const dispatch = useDispatch();
  const permissionsData = useSelector((state) => state.permissions.data);
  const clientId = getSessionClientId();
  const clientName = getSessionClientName();
  const {
    user_id: userId,
    scope_id: scopeId,
    profile_id: profileId,
  } = permissionsData;

  const [localFilters, setLocalFilters] = useState([]);
  const [dropdownOption, setDropdownOption] = useState('');

  const startDate = useSelector(
    (state) => state.updateScreenToScreenDate.startDate
  );
  const endDate = useSelector(
    (state) => state.updateScreenToScreenDate.endDate
  );

  const [filtersDrawer, toggleFiltersDrawer] = useState(false);

  const [sortFields, setSortFields] = useState({});

  const [perPage, setPerPage] = useState(10);
  const [pageTotal, setPageTotal] = useState(100);
  const [pageCurrent, setPageCurrent] = useState(1);
  const [disableExcel, setDisableExcel] = useState(false);
  const [disableCSV, setDisableCSV] = useState(false);

  const toParamsFilters = useSelector(
    (state) => state.reconciledSalesDetailsFilters.toParamsFilters
  );

  const reconciledSalesLoading = useSelector(
    (state) => state.reconciledSalesDetails.loading
  );
  const reconciledSalesDetails = useSelector(
    (state) => state.reconciledSalesDetails.details
  );
  const reconciledSalesMeta = useSelector(
    (state) => state.reconciledSalesDetails.meta
  );
  const reconciledSalesError = useSelector(
    (state) => state.reconciledSalesDetails.isError
  );
  const reconciledSalesGrossAmount = useSelector(
    (state) => state.reconciledSalesDetails.grossAmount
  );

  const acquirerReconciledAmount = useSelector(
    (state) => state.reconciledSalesDetails.acquirerReconciledAmount
  );

  const acquirerPendentAmount = useSelector(
    (state) => state.reconciledSalesDetails.acquirerPendentAmount
  );

  const customerReconciledAmount = useSelector(
    (state) => state.reconciledSalesDetails.customerReconciledAmount
  );

  const customerPendentAmount = useSelector(
    (state) => state.reconciledSalesDetails.customerPendentAmount
  );

  const filtersData = useSelector(
    (state) => state.reconciledSalesDetailsFilters.filters
  );

  const [calendarIsOpen, setCalendarIsOpen] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [columnNames, setColumnNames] = useState([
    'Status',
    'Filial',
    'Data da venda',
    'Adquirente',
    'Produto',
    'Bandeira',
    'Valor bruto estabelecimento (R$)',
    'Valor bruto adquirente (R$)',
    'Total conciliado estabelecimento (R$)',
    'Total não conciliado estabelecimento (R$)',
    'Total conciliado adquirente (R$)',
    'Total não conciliado adquirente (R$)',
  ]);

  const dataSource = reconciledSalesDetails?.map((obj) => ({
    ...obj,
    key: shortId(),
  }));

  useEffect(() => {
    return () => {
      dispatch(resetUpdateFilteredColumnsStart());
      dispatch(updateReconciledSalesDetailsFilters([]));
      dispatch(setReportsCustomColumns([]));
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      reconciledSalesDetailsStart({
        startDate,
        endDate,
        sortFields,
        page: pageCurrent,
        perPage,
        q: {
          filter: toParamsFilters,
        },
      })
    );
  }, [
    dispatch,
    endDate,
    pageCurrent,
    perPage,
    sortFields,
    startDate,
    toParamsFilters,
  ]);

  useEffect(() => {
    setPageTotal(reconciledSalesMeta.num_results);
    if (reconciledSalesMeta.total_items > MAX_EXCEL_ROWS) {
      setDisableExcel(true);
    } else {
      setDisableExcel(false);
    }
    if (reconciledSalesMeta.total_items > MAX_CSV_ROWS) {
      setDisableCSV(true);
    } else {
      setDisableCSV(false);
    }
  }, [reconciledSalesMeta]);

  const onRangePickerChange = (dates) => {
    if (dates?.length) {
      if (
        calendarIsOpen &&
        (!dates[1] || dates[1]?.diff(dates[0], 'days') > 31)
      ) {
        dispatch(
          updateScreenToScreenDateStart({
            startDate: dates[0],
            endDate: dayjs(dates[0]).add(31, 'days'),
          })
        );
        dispatch(reconciledSalesDetailsFiltersZeroLength());
      } else if (
        calendarIsOpen &&
        (!dates[1] || dates[1]?.diff(dates[0], 'days') <= 31)
      ) {
        dispatch(
          updateScreenToScreenDateStart({
            startDate: dates[0],
            endDate: dates[1],
          })
        );
        dispatch(reconciledSalesDetailsFiltersZeroLength());
      }
    } else {
      dispatch(
        updateScreenToScreenDateStart({
          startDate: dayjs().subtract(32, 'days'),
          endDate: dayjs().subtract(1, 'day'),
        })
      );
      dispatch(reconciledSalesDetailsFiltersZeroLength());
    }
  };

  const onRangePickerOpenOrCloseChange = (open) => {
    setCalendarIsOpen(open);
  };

  const onTablePaginationOrFiltersOrSorterChange = (
    pagination,
    tableFilters,
    sorter
  ) => {
    const filterDataToDispatch = generateFilterDataStructure(tableFilters);

    onColumnSort(sorter, setSortFields);
    setLocalFilters(tableFilters);
    dispatch(updateReconciledSalesDetailsFilters(filterDataToDispatch));
    setPerPage(pagination.pageSize);
    setPageTotal(reconciledSalesMeta.num_results);
    setPageCurrent(pagination.current);
  };

  const onPageMobileChange = (page, size) => {
    setPageCurrent(page);
    setPerPage(size);
  };

  const onPageSizeMobileChange = (current, size) => {
    setPageCurrent(current);
    setPerPage(size);
  };

  const fileName =
    'relatorio_conciliacao_vendas.' +
    (dropdownOption === 'excel' ? 'xlsx' : 'csv');

  const format = 'YYYY-MM-DD';

  const notificationConfig = {
    clientId,
    userId,
    status: 'PREPARANDO',
    title: 'Preparando arquivo',
    isRead: false,
    isSendEmail: false,
    fileName,
    isExpired: false,
    createdBy: userId,
    updatedBy: userId,
    message: `Estamos preparando seu arquivo ${fileName} para o download.`,
    table: 'sale-reconciled',
    fileType: dropdownOption === 'excel' ? 'xlsx' : 'csv',
    scopeId,
    profileId,
    startDate: dayjs(startDate).format(format),
    endDate: dayjs(endDate).format(format),
    q: {
      filter: exportFileParser(toParamsFilters),
    },
    sortFields: '',
    clientName,
  };

  const [showTable, setShowTable] = useState(true);

  const resetFiltersStates = () => {
    dispatch(reconciledSalesDetailsFiltersZeroLength());
    dispatch(updateReconciledSalesDetailsFilters([]));
    dispatch(resetUpdateFilteredColumnsStart());
    dispatch(setReportsCustomColumns([]));
    setSortFields({});
    setPageCurrent(1);
  };

  const handleResetFilters = () => {
    setShowTable(false);
    resetFiltersStates();
  };

  useEffect(() => {
    setShowTable(true);
  }, [showTable]);

  return (
    <>
      <Filters
        title="Conciliação de vendas"
        subtitle="Verifique suas vendas conciliadas e não conciliadas nas adquirentes e nos estabelecimentos"
        onRangePickerOpenOrCloseChange={onRangePickerOpenOrCloseChange}
        hasRangePicker
        hasSelect={false}
        hasSorter={false}
        dateDefaultValue={[startDate, endDate]}
        onRangePickerChange={onRangePickerChange}
        loading={reconciledSalesLoading}
        filtersDrawer={filtersDrawer}
        onCloseFiltersDrawer={() => toggleFiltersDrawer(false)}
        sorterButtonText="Vendas"
        hasMobileFiltersButton={false}
        disabledDate={disableLastMonth}
        calendarIsOpen={calendarIsOpen}
        setCalendarIsOpen={setCalendarIsOpen}
      />
      <MobileCards
        data={dataSource}
        loading={reconciledSalesLoading}
        pagination={{
          current: pageCurrent,
          pageSize: perPage,
          total: pageTotal,
          pageSizeOptions: perPageOptions,
          showTotal: showTotalPage,
        }}
        error={reconciledSalesError}
        onPageSizeChange={onPageSizeMobileChange}
        onPageChange={onPageMobileChange}
      />
      {showTable && (
        <Tables
          totalText="Total bruto divergente:"
          reconciliationType="sales"
          dataSource={dataSource}
          columns={columns(
            filtersData,
            localFilters,
            toParamsFilters,
            sortFields,
            pageCurrent,
            perPage,
            startDate,
            endDate
          )}
          toParamsFilters={toParamsFilters}
          localFilters={localFilters}
          loading={reconciledSalesLoading}
          error={reconciledSalesError}
          headerNetAmount={reconciledSalesGrossAmount}
          leftColReconciledText="Estabelecimentos"
          leftColReconciledAmount={customerReconciledAmount}
          leftColPendentAmount={customerPendentAmount}
          rightColReconciledText="Adquirentes"
          rightColReconciledAmount={acquirerReconciledAmount}
          rightColPendentAmount={acquirerPendentAmount}
          createNotificationAction={createNotificationStart}
          notificationConfig={notificationConfig}
          dropdownOption={dropdownOption}
          setDropdownOption={setDropdownOption}
          pagination={{
            current: pageCurrent,
            pageSize: perPage,
            total: pageTotal,
            pageSizeOptions: perPageOptions,
            showTotal: showTotalPage,
          }}
          onChange={onTablePaginationOrFiltersOrSorterChange}
          defaultSelectedColumns={columnNames}
          disableExcel={disableExcel}
          disableCSV={disableCSV}
          handleResetFilters={handleResetFilters}
        />
      )}
    </>
  );
};

export default SalesReconciliationReports;
