import React from 'react';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { Button, Spin } from 'antd';
import {
  HeaderContainer,
  ModalContent,
  StButtonContainer,
  StContentContainer,
  StDescriptionContainer,
  StIconContainer,
  StModal,
  StSpinContainer,
} from './styled';
import { useNavigate } from 'react-router-dom';

export const SpecialistContactModal = ({
  isModalOpen,
  handleOk,
  handleCancel,
  modalInfo = {},
  isLoading = true,
  handleSpecialistContact = () => {},
  ...rest
}) => {
  const { title, description, modalType } = modalInfo;
  const navigate = useNavigate();

  const onClickGoHome = () => {
    handleOk();
    navigate('/dashboard');
  };

  const renderIcon = () => {
    if (modalType === 'success') {
      return (
        <StIconContainer $modalType={modalType}>
          <CheckCircleOutlined />
        </StIconContainer>
      );
    }

    if (modalType === 'warning') {
      return (
        <StIconContainer $modalType={modalType}>
          <ExclamationCircleOutlined />
        </StIconContainer>
      );
    }

    if (modalType === 'error') {
      return (
        <StIconContainer $modalType={modalType}>
          <CloseCircleOutlined />
        </StIconContainer>
      );
    }

    return null;
  };

  const renderButtons = () => {
    if (modalType === 'success') {
      return (
        <>
          <Button onClick={handleCancel}>Fechar</Button>
          <Button type="primary" onClick={onClickGoHome}>
            Ir para home
          </Button>
        </>
      );
    }

    if (modalType === 'warning') {
      return (
        <Button
          type="primary"
          onClick={() => {
            handleOk();
          }}
        >
          Fechar
        </Button>
      );
    }

    if (modalType === 'error') {
      return (
        <>
          <Button onClick={handleCancel}>Cancelar</Button>
          <Button
            type="primary"
            onClick={() => {
              handleSpecialistContact();
            }}
          >
            Tentar novamente
          </Button>
        </>
      );
    }
    return null;
  };

  return (
    <StModal
      width={536}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      closable={false}
      centered
      maskClosable={false}
      footer={null}
      {...rest}
    >
      <ModalContent>
        {!isLoading && (
          <StContentContainer>
            <HeaderContainer>
              {renderIcon()}

              <span>{title}</span>
            </HeaderContainer>

            <StDescriptionContainer>
              <span>{description}</span>
            </StDescriptionContainer>

            <StButtonContainer>{renderButtons()}</StButtonContainer>
          </StContentContainer>
        )}

        {isLoading && (
          <StSpinContainer>
            <Spin />
          </StSpinContainer>
        )}
      </ModalContent>
    </StModal>
  );
};
