import { action } from 'typesafe-actions';
import { actionTypes } from 'store/ducks/credit/creditPartnerOptout/types';

export const creditPartnerOptoutStart = (data) =>
  action(actionTypes.CREDIT_PARTNER_OPTOUT_START, data);
export const creditPartnerOptoutSuccess = (data) =>
  action(actionTypes.CREDIT_PARTNER_OPTOUT_SUCCESS, data);
export const creditPartnerOptoutError = (errorMessage, error) =>
  action(actionTypes.CREDIT_PARTNER_OPTOUT_ERROR, { errorMessage }, undefined, {
    error,
  });
