import { bankingApi } from '../api';

const transformer = (apiResponse) => {
  return {
    meta: apiResponse?.pagination,
    data: apiResponse?.payload,
  };
};

const getCreditListFromAPI = async ({ clientId, page, perPage }) => {
  const config = {
    params: {
      clientId,
      page,
      perPage,
    },
  };
  const response = await bankingApi().get('/credit/list', config);
  return transformer(response.data);
};

export default getCreditListFromAPI;
