import { action } from 'typesafe-actions';
import { actionTypes } from 'store/ducks/credit/creditInteractContract/types';

export const creditInteractContractStart = (data) =>
  action(actionTypes.CREDIT_INTERACT_CONTRACT_START, data);
export const creditInteractContractSuccess = (data) =>
  action(actionTypes.CREDIT_INTERACT_CONTRACT_SUCCESS, data);
export const creditInteractContractError = (errorMessage, error) =>
  action(actionTypes.CREDIT_INTERACT_CONTRACT_ERROR, { errorMessage }, undefined, {
    error,
  });
