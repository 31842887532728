import { call, put } from 'redux-saga/effects';

import { getSkinsFromAPI } from 'services/skins';

import { skinsSuccess, skinsError } from 'store/ducks/skins/actions';
import errorHandler from 'helpers/errorHandler';

export function* skinsSaga() {
  try {
    const { skins } = yield call(getSkinsFromAPI);
    yield put(skinsSuccess(skins));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        skinsError(err.response.data.error.message, err.response.data.error)
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(skinsError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}
