import { api } from '../../api';

const transformer = (apiResponse) => {
  const data = {
    details: apiResponse.data.sales,
    meta: apiResponse.meta,
    grossAmount: apiResponse.data.total_gross_amount,
    acquirerReconciledAmount: apiResponse.data.acquirer_reconciled_amount,
    acquirerPendentAmount: apiResponse.data.acquirer_pendent_amount,
    customerReconciledAmount: apiResponse.data.customer_reconciled_amount,
    customerPendentAmount: apiResponse.data.customer_pendent_amount,
  };
  return data;
};

const getReconciledSalesDetailsFromAPI = async ({
  startDate,
  endDate,
  sortFields,
  page,
  perPage,
  q,
}) => {
  const config = {
    params: {
      start_date: startDate.format('YYYY-MM-DD'),
      end_date: endDate.format('YYYY-MM-DD'),
      page,
      per_page: perPage,
      sort_fields: JSON.stringify(sortFields),
      q: JSON.stringify(q),
    },
  };
  const response = await api().get('/detail/sales/reconciled', config);
  return transformer(response.data);
};

export default getReconciledSalesDetailsFromAPI;
