import { useCallback, useState } from 'react';
import { getEmails } from 'services/radar/getEmails';
import errorHandler from 'helpers/errorHandler';

const useRadarEmails = () => {
  const [loading, setLoading] = useState(false);
  const [clientsEmails, setClientsEmails] = useState([]);
  const [concilersEmails, setConcilersEmails] = useState([]);

  const fetchRadarEmails = useCallback(({ clientId }) => {
    setLoading(true);

    getEmails({ clientId })
      .then(({ clients = [], concilers = [] }) => {
        setClientsEmails(clients);
        setConcilersEmails(concilers);
      })
      .catch(() =>
        errorHandler(
          'Ocorreu um erro ao buscar os e-mails, por favor, tente novamente'
        )
      )
      .finally(() => setLoading(false));
  }, []);

  return {
    loading,
    clientsEmails,
    concilersEmails,
    fetchRadarEmails,
  };
};

export default useRadarEmails;
