import { lambdaApi } from 'services/api';

const transformer = (apiResponse) => {
  const filters = {
    stores: apiResponse.data.stores,
    meta: apiResponse.meta,
  };
  return filters;
};
const getFeeStoresFilter = async ({ page, perPage, filter }) => {
  const q = filter ? { q: JSON.stringify(filter) } : [];
  const config = {
    params: {
      page,
      per_page: perPage,
      ...q,
    },
  };
  const response = await lambdaApi().get(
    '/fees/consolidated/stores/filter',
    config
  );
  return transformer(response.data);
};
export default getFeeStoresFilter;
