import { useDispatch } from 'react-redux';
import { resetUpdateFilteredColumnsStart } from 'store/ducks/updateFilteredColumns/actions';

export const useResetFilters = () => {
  const dispatch = useDispatch();

  const resetFilters = (updateFiltersAction) => {
    dispatch(resetUpdateFilteredColumnsStart());
    dispatch(updateFiltersAction([]));
  };

  return {
    resetFilters,
  };
};
