import { CloseOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import styled from 'styled-components';
import { colors } from 'styles/colors';

export const StCover = styled.div`
  position: relative;
  width: 100%;
  min-height: 270px;
  padding-right: 24px;
  border-radius: 4px;
  background-color: ${({ backgroundColor = colors.gray1 }) => backgroundColor};
  box-shadow: 0px 3px 6px 0px #0000000a;
  cursor: pointer;
  overflow: hidden;
`;

export const StBack = styled.div`
  position: relative;
  width: 100%;
  min-height: 270px;
  padding: 24px 40px 24px 24px;
  border-radius: 4px;
  border: 1px solid ${colors.primary2};
  background-color: ${({ backgroundColor = colors.gray1 }) => backgroundColor};
  box-shadow: 0px 3px 6px 0px #0000000a;
  overflow: hidden;
`;

export const StBorder = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 100%;
  background-color: ${colors.primary1};
  cursor: pointer;
`;

export const StInfoCircleOutlined = styled(InfoCircleOutlined)`
  color: ${colors.primary6};
`;

export const StCloseOutlined = styled(CloseOutlined)`
  color: ${colors.primary6};
`;

export const StBackDescription = styled.div`
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  padding-right: 40px;
  color: ${colors.gray8};
`;

export const StHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

export const StTitle = styled.h1`
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  margin: 0;
  color: ${colors.gray8};
`;

export const StButton = styled(Button)`
  position: absolute;
  bottom: 24px;
  left: 24px;
`;
