/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable quotes */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { refundsDetailsStart } from 'store/ducks/details/refundsDetails/actions';
import {
  refundDetailsFiltersZeroLength,
  updateRefundDetailsFilters,
} from 'store/ducks/filters/details/refundDetailsFilters/actions';
import { columns } from 'components/Reports/RefundsReports/utils';
import { Filters } from 'commons/Intermediate/Filters';
import { Tables } from 'commons/Tables';
import { createNotificationStart } from 'store/ducks/notifications/actions';
import { updateScreenToScreenDateStart } from 'store/ducks/updateScreenToScreenDate/actions';
import { generateFilterDataStructure } from 'helpers/tableService';
import {
  getSessionClientId,
  getSessionClientName,
} from 'helpers/sessionService';
import { MAX_CSV_ROWS, MAX_EXCEL_ROWS } from 'constants/general';
import { disableLastMonth } from 'helpers/disableDates';
import { exportFileParser } from 'utils/parsers/exportFileParser';
import { perPageOptions, showTotalPage } from 'constants/perPageOptions';
import dayjs from 'dayjs';
import { shortId } from 'helpers/shortId';
import { resetUpdateFilteredColumnsStart } from 'store/ducks/updateFilteredColumns/actions';
import { setReportsCustomColumns } from 'store/ducks/reportsCustomColumns/actions';
import { MobileCards } from './components';

const RefundsReports = () => {
  const dispatch = useDispatch();

  const permissionsData = useSelector((state) => state.permissions.data);
  const clientId = getSessionClientId();
  const clientName = getSessionClientName();
  const {
    scope_id: scopeId,
    profile_id: profileId,
    user_id: userId,
  } = permissionsData;

  const [localFilters, setLocalFilters] = useState([]);
  const [dropdownOption, setDropdownOption] = useState('');

  const startDate = useSelector(
    (state) => state.updateScreenToScreenDate.startDate
  );
  const endDate = useSelector(
    (state) => state.updateScreenToScreenDate.endDate
  );

  const [filtersDrawer, toggleFiltersDrawer] = useState(false);

  const [sortFields, setSortFields] = useState({});

  const [perPage, setPerPage] = useState(10);
  const [pageTotal, setPageTotal] = useState(100);
  const [pageCurrent, setPageCurrent] = useState(1);
  const [disableExcel, setDisableExcel] = useState(false);
  const [disableCSV, setDisableCSV] = useState(false);

  const toParamsFilters = useSelector(
    (state) => state.refundDetailsFilters?.toParamsFilters
  );

  const refundsLoading = useSelector((state) => state.refundsDetails?.loading);
  const refundsDetails = useSelector((state) => state.refundsDetails?.details);
  const refundsMeta = useSelector((state) => state.refundsDetails?.meta);
  const refundsError = useSelector((state) => state.refundsDetails?.isError);
  const refundsNetAmount = useSelector(
    (state) => state.refundsDetails.netAmount
  );

  const filtersData = useSelector(
    (state) => state.refundDetailsFilters?.filters
  );

  const [calendarIsOpen, setCalendarIsOpen] = useState(false);

  const columnNames = [
    'Filial',
    'Data do pagamento',
    'Adquirente',
    'Produto',
    'Bandeira',
    'Valor bruto (R$)',
    'Valor líquido (R$)',
    'Tipo da transação',
  ];

  const dataSource = refundsDetails.map((obj) => ({
    ...obj,
    key: shortId(),
  }));

  useEffect(() => {
    return () => {
      dispatch(resetUpdateFilteredColumnsStart());
      dispatch(updateRefundDetailsFilters([]));
      dispatch(setReportsCustomColumns([]));
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      refundsDetailsStart({
        startDate,
        endDate,
        sortFields,
        page: pageCurrent,
        perPage,
        q: {
          filter: toParamsFilters,
        },
      })
    );
  }, [
    dispatch,
    pageCurrent,
    perPage,
    sortFields,
    toParamsFilters,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    setPageTotal(refundsMeta?.num_results);
    if (refundsMeta.total_items > MAX_EXCEL_ROWS) {
      setDisableExcel(true);
    } else {
      setDisableExcel(false);
    }
    if (refundsMeta.total_items > MAX_CSV_ROWS) {
      setDisableCSV(true);
    } else {
      setDisableCSV(false);
    }
  }, [refundsMeta]);

  const onRangePickerChange = (dates) => {
    if (dates?.length) {
      if (
        calendarIsOpen &&
        (!dates[1] || dates[1]?.diff(dates[0], 'days') > 31)
      ) {
        dispatch(
          updateScreenToScreenDateStart({
            startDate: dates[0],
            endDate: dayjs(dates[0]).add(31, 'days'),
          })
        );
        dispatch(refundDetailsFiltersZeroLength());
      } else if (
        calendarIsOpen &&
        (!dates[1] || dates[1]?.diff(dates[0], 'days') <= 31)
      ) {
        dispatch(
          updateScreenToScreenDateStart({
            startDate: dates[0],
            endDate: dates[1],
          })
        );
        dispatch(refundDetailsFiltersZeroLength());
      }
    } else {
      dispatch(
        updateScreenToScreenDateStart({
          startDate: dayjs().subtract(32, 'days'),
          endDate: dayjs().subtract(1, 'day'),
        })
      );
      dispatch(refundDetailsFiltersZeroLength());
    }
  };

  const onRangePickerOpenOrCloseChange = (open) => {
    setCalendarIsOpen(open);
  };

  const onTablePaginationOrFiltersOrSorterChange = async (
    pagination,
    tableFilters,
    sorter
  ) => {
    const hasMultipleColumnSorting = Array.isArray(sorter);
    if (hasMultipleColumnSorting) {
      let _sortFields = {};
      sorter.forEach((column) => {
        _sortFields = {
          ..._sortFields,
          [column.field]: column.order,
        };
      });
      setSortFields(_sortFields);
    } else {
      setSortFields({ [sorter.field]: sorter.order });
    }

    const filterDataToDispatch = generateFilterDataStructure(tableFilters);

    setLocalFilters(tableFilters);
    dispatch(updateRefundDetailsFilters(filterDataToDispatch));
    setPerPage(pagination.pageSize);
    setPageTotal(refundsMeta.num_results);
    setPageCurrent(pagination.current);
  };

  const onPageMobileChange = (page, size) => {
    setPageCurrent(page);
    setPerPage(size);
  };

  const onPageSizeMobileChange = (current, size) => {
    setPageCurrent(current);
    setPerPage(size);
  };

  const fileName =
    'relatorio_cancelamentos.' + (dropdownOption === 'excel' ? 'xlsx' : 'csv');

  const format = 'YYYY-MM-DD';

  const notificationConfig = {
    clientId,
    userId,
    status: 'PREPARANDO',
    title: 'Preparando arquivo',
    isRead: false,
    isSendEmail: false,
    fileName,
    isExpired: false,
    createdBy: userId,
    updatedBy: userId,
    message: `Estamos preparando seu arquivo ${fileName} para o download.`,
    table: 'refund',
    fileType: dropdownOption === 'excel' ? 'xlsx' : 'csv',
    scopeId,
    profileId,
    startDate: dayjs(startDate).format(format),
    endDate: dayjs(endDate).format(format),
    q: {
      filter: exportFileParser(toParamsFilters),
    },
    sortFields: '',
    clientName,
  };

  const [showTable, setShowTable] = useState(true);

  const resetFiltersStates = () => {
    dispatch(refundDetailsFiltersZeroLength());
    dispatch(updateRefundDetailsFilters([]));
    dispatch(resetUpdateFilteredColumnsStart());
    dispatch(setReportsCustomColumns([]));
    setSortFields({});
    setPageCurrent(1);
  };

  const handleResetFilters = () => {
    setShowTable(false);
    resetFiltersStates();
  };

  useEffect(() => {
    setShowTable(true);
  }, [showTable]);

  return (
    <>
      <Filters
        title="Cancelamentos e Chargebacks"
        subtitle="Total de valores cancelados e com chargeback"
        onRangePickerOpenOrCloseChange={onRangePickerOpenOrCloseChange}
        hasRangePicker
        hasSelect={false}
        hasSorter={false}
        dateDefaultValue={[startDate, endDate]}
        onRangePickerChange={onRangePickerChange}
        loading={refundsLoading}
        filtersDrawer={filtersDrawer}
        onCloseFiltersDrawer={() => toggleFiltersDrawer(false)}
        sorterButtonText="Vendas"
        hasMobileFiltersButton={false}
        disabledDate={disableLastMonth}
        calendarIsOpen={calendarIsOpen}
        setCalendarIsOpen={setCalendarIsOpen}
      />
      <MobileCards
        data={dataSource}
        loading={refundsLoading}
        pagination={{
          current: pageCurrent,
          pageSize: perPage,
          total: pageTotal,
          pageSizeOptions: perPageOptions,
          showTotal: showTotalPage,
        }}
        error={refundsError}
        onPageSizeChange={onPageSizeMobileChange}
        onPageChange={onPageMobileChange}
      />
      {showTable && (
        <Tables
          dataSource={dataSource}
          totalText="Total de cancelamentos e chargebacks: "
          columns={columns(filtersData, localFilters, toParamsFilters)}
          loading={refundsLoading}
          error={refundsError}
          headerNetAmount={refundsNetAmount}
          createNotificationAction={createNotificationStart}
          notificationConfig={notificationConfig}
          dropdownOption={dropdownOption}
          setDropdownOption={setDropdownOption}
          pagination={{
            current: pageCurrent,
            pageSize: perPage,
            total: pageTotal,
            pageSizeOptions: perPageOptions,
            showTotal: showTotalPage,
          }}
          onChange={onTablePaginationOrFiltersOrSorterChange}
          defaultSelectedColumns={columnNames}
          disableExcel={disableExcel}
          disableCSV={disableCSV}
          handleResetFilters={handleResetFilters}
        />
      )}
    </>
  );
};

export default RefundsReports;
