import styled from 'styled-components';
import { Button, Card, Col } from 'antd';

export const StContainer = styled(Card)`
  &&& {
    width: 769px;
    padding: 0;
    margin: 0;
    border-radius: 4px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  }
`;

export const StButton = styled(Button)`
  &&& {
    color: #1790ff;
  }
`;

export const StCol = styled(Col)`
  &&& {
    margin-right: ${({ $marginRight }) => $marginRight};
  }
`;
