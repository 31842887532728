import { actionTypes } from './types';

const INITIAL_STATE = {
  plans: {},
  error: undefined,
  loading: false,
  isError: false,
  success: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case actionTypes.PLANS_START:
    return {
      ...state,
      loading: true,
      error: undefined,
      isError: false,
      success: false,
    };
  case actionTypes.PLANS_SUCCESS:
    return {
      ...state,
      loading: false,
      errorMessage: undefined,
      error: undefined,
      plans: action.payload?.data,
      isError: false,
      success: true,
    };
  case actionTypes.PLANS_ERROR:
    return {
      ...state,
      loading: false,
      error: action.error.error,
      isError: true,
      success: false,
    };
  default:
    return state;
  }
};

export default reducer;
