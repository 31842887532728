import { StContainer } from 'commons/CardContainer/styled';
import React from 'react';

const CardContainer = ({
  height,
  enforceMaxHeight,
  hasFooter,
  children,
  reconciliationType,
  ...rest
}) => {
  return (
    <StContainer
      height={height}
      enforceMaxHeight={enforceMaxHeight}
      hasFooter={hasFooter}
      reconciliationType={reconciliationType}
      {...rest}
    >
      {children}
    </StContainer>
  );
};

export default CardContainer;
