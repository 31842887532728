import styled from 'styled-components';
import { Footer } from 'antd/es/layout/layout';
import { colors } from 'styles/colors';

export const StFooter = styled(Footer)`
  &&& {
    border-top: 1px solid #e8e8e8;
    position: sticky;
    z-index: 2;
    bottom: 0;
    width: 100%;
    box-shadow: 10px 10px 5px #e8e8e8;
    background-color: ${colors.gray1};
  }
`;
