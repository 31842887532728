import { actionTypes } from 'store/ducks/cnpjMaintenance/merchants/deleteMerchant/types';

const INITIAL_STATE = {
  loading: false,
  error: undefined,
  errorMessage: undefined,
  success: false,
  isError: false,
  merchantLoading: [],
};
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.DELETE_MERCHANT_START:
      return {
        ...state,
        loading: true,
        error: undefined,
        errorMessage: undefined,
        success: false,
        isError: false,
        merchantLoading: [action.payload.merchantId],
      };
    case actionTypes.DELETE_MERCHANT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        errorMessage: undefined,
        success: true,
        isError: false,
        merchantLoading: [],
      };
    case actionTypes.DELETE_MERCHANT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error.error,
        errorMessage: action.payload,
        success: false,
        isError: true,
        merchantLoading: [],
      };
    default:
      return state;
  }
};
export default reducer;
