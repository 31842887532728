import { action } from 'typesafe-actions';
import { actionTypes } from './types';

export const creditTomaticoStatusStart = (data) =>
  action(actionTypes.CREDIT_TOMATICO_STATUS_START, data);
export const creditTomaticoStatusSuccess = (data) =>
  action(actionTypes.CREDIT_TOMATICO_STATUS_SUCCESS, data);
export const creditTomaticoStatusError = (errorMessage, error) =>
  action(
    actionTypes.CREDIT_TOMATICO_STATUS_ERROR,
    { errorMessage },
    undefined,
    {
      error,
    }
  );
