import { call, put } from 'redux-saga/effects';

import errorHandler from 'helpers/errorHandler';

import {
  reconciledSalesDetailsSuccess,
  reconciledSalesDetailsError,
} from 'store/ducks/details/reconciledSalesDetails/actions';
import getReconciledSalesDetailsFromAPI from 'services/reconciled/details/sales';

export function* reconciledSalesDetailsSaga(action) {
  try {
    const { payload } = action;

    const apiData = yield call(getReconciledSalesDetailsFromAPI, payload);

    yield put(reconciledSalesDetailsSuccess(apiData));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        reconciledSalesDetailsError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    }
  }
}
