import errorHandler from 'helpers/errorHandler';
import { useState, useEffect, useCallback } from 'react';
import getFeeAcquirerList from 'services/fees/getFeeAcquirerList';

function useAcquirerList(selectedStoreId, page, setPage, perPage = 10) {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [acquirers, setAcquirers] = useState([]);
  const [acquirersData, setAcquirersData] = useState([]);
  const [metaData, setMetaData] = useState({});

  const fetchAcquirers = useCallback(
    (pageNumber) => {
      setIsLoading(true);
      setIsError(false);
      return getFeeAcquirerList({
        storeId: selectedStoreId,
        page: pageNumber || page,
        perPage,
      })
        .then(({ acquirers: data = [], meta = {} }) => {
          setAcquirersData(data);
          setMetaData(meta);
        })
        .catch((err) => {
          if (err) {
            errorHandler('Ocorreu um erro:', err);
          }
          setIsError(true);
          setIsLoading(false);
        })
        .finally(() => setIsLoading(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedStoreId, page]
  );

  useEffect(() => {
    setAcquirers([]);
    fetchAcquirers(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStoreId]);

  // limpar filtro e com scroll de lojas sequencial
  useEffect(() => {
    if (!selectedStoreId && page > 1) {
      setPage(1);
      setAcquirers([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStoreId]);

  useEffect(() => {
    if (page > 1) {
      fetchAcquirers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    setAcquirers((prev) => {
      return [...new Set([...prev, ...acquirersData])];
    });
  }, [acquirersData]);

  const refreshAcquirers = () => {
    setAcquirers([]);
    fetchAcquirers(1);
  };

  return {
    isLoading,
    isError,
    acquirers,
    metaData,
    refreshAcquirers,
  };
}

export default useAcquirerList;
