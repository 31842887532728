import { all, takeLatest, takeEvery } from 'redux-saga/effects';

// SAGAS

import { authSaga, validateTokenSaga } from 'store/ducks/auth/sagas';
import { completeNewPasswordSaga } from 'store/ducks/completeNewPassword/sagas';
import { logoutSaga } from 'store/ducks/logout/sagas';
import { forgotPasswordSaga } from 'store/ducks/forgotPassword/sagas';
import { recoveryPasswordSaga } from 'store/ducks/recoveryPassword/sagas';
import { permissionsSaga } from './permissions/sagas';
import { validateAccessSaga } from './validateAccess/sagas';

import { salesSummarySaga } from 'store/ducks/salesSummary/sagas';
import { salesSummaryReconciledSaga } from 'store/ducks/reconciled/summary/sales/sagas';
import { salesReconciledSaga } from 'store/ducks/reconciled/sales/sagas';
import { salesInfoSaga } from 'store/ducks/salesInfo/sagas';
import { salesInfoFiltersSaga } from 'store/ducks/filters/salesInfoFilters/sagas';
import { reconciledSalesInfoFiltersSaga } from 'store/ducks/filters/reconciledSalesInfoFilters/sagas';
// import { reconciledPaymentsInfoFiltersSaga } from 'store/ducks/filters/reconciledPaymentsInfoFilters/sagas';
import { divergencesInfoFiltersSaga } from 'store/ducks/filters/divergencesInfoFilters/sagas';
import { amountsReceivableSummarySaga } from 'store/ducks/amountsReceivableSummary/sagas';
import { amountsReceivableInfoSaga } from 'store/ducks/amountsReceivableInfo/sagas';
import { amountsReceivableDetailsSaga } from 'store/ducks/details/amountsReceivableDetails/sagas';
import { salesDetailsSaga } from 'store/ducks/details/salesDetails/sagas';
import { reconciledSalesDetailsSaga } from 'store/ducks/details/reconciledSalesDetails/sagas';
import { reconciledPaymentsDetailsSaga } from 'store/ducks/details/reconciledPaymentsDetails/sagas';
import { reconciledReceiptDetailsSaga } from 'store/ducks/details/reconciledReceiptDetails/sagas';
import { duedateDetailsSaga } from 'store/ducks/details/duedateDetails/sagas';
import { advancementDetailsSaga } from 'store/ducks/details/advancementDetails/sagas';
import { adjustmentDetailsSaga } from 'store/ducks/details/adjustmentDetails/sagas';
import { refundsDetailsSaga } from 'store/ducks/details/refundsDetails/sagas';
import { paymentsSummaryReconciledSaga } from 'store/ducks/reconciled/summary/payments/sagas';
import { bankSummaryReconciledSaga } from 'store/ducks/reconciled/summary/bank/sagas';
import { paymentsReconciledSaga } from 'store/ducks/reconciled/payments/sagas';
import { contractFeeFiltersSaga } from 'store/ducks/filters/contractFeeFilters/sagas';
import { paymentDivergencesDetailsSaga } from './details/paymentDivergencesDetails/sagas';

import { amountsReceivableInfoFiltersSaga } from 'store/ducks/filters/amountsReceivableInfoFilters/sagas';
import { duedateInfoFiltersSaga } from 'store/ducks/filters/duedateInfoFilters/sagas';
import { advancementInfoFiltersSaga } from 'store/ducks/filters/advancementInfoFilters/sagas';
import { adjustmentInfoFiltersSaga } from 'store/ducks/filters/adjustmentInfoFilters/sagas';
import { refundInfoFiltersSaga } from 'store/ducks/filters/refundInfoFilters/sagas';
import { salesDetailsFiltersSaga } from 'store/ducks/filters/details/salesDetailsFilters/sagas';

import { ofxParametersFiltersSaga } from 'store/ducks/filters/ofxParameters/sagas';

import { reconciledSalesDetailsFiltersSaga } from 'store/ducks/filters/details/reconciledSalesDetailsFilters/sagas';
import { reconciledPaymentsDetailsFiltersSaga } from 'store/ducks/filters/details/reconciledPaymentsDetailsFilters/sagas';
import { reconciledBankDetailsFiltersSaga } from 'store/ducks/filters/details/reconciledBankDetailsFilters/sagas';
import { bankConciliationFiltersSaga } from 'store/ducks/filters/details/bankConciliationFilters/sagas';
import { amountsReceivableDetailsFiltersSaga } from 'store/ducks/filters/details/amountsReceivableDetailsFilters/sagas';
import { banksDetailsFiltersSaga } from 'store/ducks/filters/details/banksDetailsFilters/sagas';
import { duedateDetailsFiltersSaga } from 'store/ducks/filters/details/duedateDetailsFilters/sagas';
import { advancementDetailsFiltersSaga } from 'store/ducks/filters/details/advancementDetailsFilters/sagas';
import { paymentDivergencesDetailsFiltersSaga } from 'store/ducks/filters/details/paymentDivergencesDetailsFilters/sagas';

import { adjustmentDetailsFiltersSaga } from 'store/ducks/filters/details/adjustmentDetailsFilters/sagas';
import { refundDetailsFiltersSaga } from 'store/ducks/filters/details/refundDetailsFilters/sagas';
import { importFilesFiltersSaga } from 'store/ducks/filters/importFiles/sagas';
import {
  acquirerSaga,
  acquirerPointOfSaleSaga,
  acquirerVoucherSaga,
  acquirerMerchantsSaga,
  acquirerAllSaga,
} from 'store/ducks/acquirer/sagas';
import { createPasswordSaga } from 'store/ducks/createPassword/sagas';
import { listOfClientsSaga } from 'store/ducks/listOfClients/sagas';
import { listOfClientsFiltersSaga } from 'store/ducks/filters/listOfClientsFilters/sagas';
import { listOfPlansSaga } from 'store/ducks/listOfPlans/sagas';
import { unfilteredGeneralListOfClientsSaga } from 'store/ducks/unfilteredGeneralListOfClients/sagas';
import { generalListOfClientsSaga } from 'store/ducks/generalListOfClients/sagas';
import { listUsersSaga } from 'store/ducks/listUsersClient/sagas';
import { changeStoreSaga } from 'store/ducks/changeStore/sagas';
import { registerFeeSaga } from 'store/ducks/registerFees/sagas';
import { listOfClientsSelectSaga } from 'store/ducks/listClientsSelect/sagas';
import { alterProfileScopeSaga } from 'store/ducks/alterProfileScope/sagas';
import { optinSaga } from 'store/ducks/optin/optin/sagas';
import { optinRequestSaga } from 'store/ducks/optin/optinRequest/sagas';
import { optinUrlsSaga } from 'store/ducks/optin/optinUrls/sagas';
import { checkInvitationEmailSaga } from 'store/ducks/checkInvitationEmail/sagas';
import { sendInvitationSaga } from 'store/ducks/sendInvitation/sagas';
import { removeUserSaga } from 'store/ducks/removeUser/sagas';
import { deleteUserSaga } from 'store/ducks/deleteUser/sagas';
import { removeBranchSaga } from 'store/ducks/removeBranch/sagas';
import {
  listFeesVencidaSaga,
  listFeesVigenteSaga,
  putFeesSaga,
  deleteFeesSaga,
} from 'store/ducks/listFees/sagas';
import { updateBranchDataSaga } from 'store/ducks/updateBranchData/sagas';
import { createBranchSaga } from 'store/ducks/createBranch/sagas';
import {
  uploadFileSaga,
  listFilesSaga,
  removeFileSaga,
  updateFileDateSaga,
  updateCurrentPageSaga as importFilesCurrentPageSaga,
} from './importFiles/sagas';
import {
  addOFXParameterSaga,
  editOFXParameterSaga,
  removeOFXParameterSaga,
  listOFXParametersOFXSaga,
} from './ofxParameters/sagas';
import { banksDashSaga } from './banks/listBanksDash/sagas';
import { banksIntermediateSaga } from './banks/listBanksIntermediate/sagas';
import { banksReportSaga } from './banks/listBanksReports/sagas';
import {
  listBankConciliationSaga,
  listBankConciliationTransactionsClearSaga,
  listBankConciliationTransactionsSaga,
  setTransactionsSelectedSaga,
  updateBankConciliationTransactionsSaga,
  updateCounterTransactionsSaga,
  updateTransactionDateSaga,
  updateThroughImportFilesSaga,
  resetHasNoTransactionsSaga,
  updateDifferenceValueSaga,
  updateAcquirerNameSaga,
  updateCurrentPageSaga,
  updateAfterSaveSaga,
} from './bankConciliation/sagas';
import {
  getBriefingSaga,
  postBriefingSaga,
  putBriefingSaga,
} from 'store/ducks/briefing/sagas';
import {
  getUploadedLetterUrlSaga,
  deleteUploadedLetterUrlSaga,
} from 'store/ducks/uploadedLetterUrl/sagas';
import {
  getClientSaga,
  postClientSaga,
  putClientSaga,
  fileIdentificationSaga,
} from 'store/ducks/client/sagas';
import {
  getStoresSaga,
  postStoreSaga,
  putStoreSaga,
  deleteStoreSaga,
} from 'store/ducks/store/sagas';
import { getCepSaga } from 'store/ducks/viaCep/sagas';
import { getCnpjSaga } from 'store/ducks/receitaWebservice/sagas';
import {
  getClientAcquirerSaga,
  postClientAcquirerSaga,
  deleteClientAcquirerSaga,
} from 'store/ducks/clientAcquirer/sagas';
import {
  getMerchantsSaga,
  postMerchantSaga,
  deleteMerchantSaga,
} from 'store/ducks/merchant/sagas';
import {
  changeNotificationStatusSaga,
  createNotificationSaga,
  getNotificationSaga,
  getDownloadUrlSaga,
  // requestFileSaga
  markAlertAsSentSaga,
  updateNotificationPageSaga,
  updateNotificationPerPageSaga,
} from 'store/ducks/notifications/sagas';

import { listAdjustmentPaymentsDashSaga } from 'store/ducks/paymentsDash/adjustmentPayments/sagas';
import { duedatePaymentsDashSaga } from 'store/ducks/paymentsDash/duedatePayments/sagas';
import { advancementPaymentsDashSaga } from 'store/ducks/paymentsDash/advancementPayments/sagas';
import { refundsPaymentsDashSaga } from 'store/ducks/paymentsDash/refundsPayments/sagas';
import { advancementPaymentsIntermediateSaga } from 'store/ducks/paymentsIntermediate/advancementPaymentsIntermediate/sagas';
import { duedatePaymentsIntermediateSaga } from 'store/ducks/paymentsIntermediate/duedatePaymentsIntermediate/sagas';
import { adjustmentPaymentsIntermediateSaga } from 'store/ducks/paymentsIntermediate/adjustmentPaymentsIntermediate/sagas';
import { refundsPaymentsIntermediateSaga } from 'store/ducks/paymentsIntermediate/refundsPaymentsIntermediate/sagas';
import { paymentsDivergencesDashSaga } from 'store/ducks/paymentsDash/paymentsDivergences/sagas';
import { paymentsDivergencesIntermediateSaga } from 'store/ducks/paymentsIntermediate/paymentsDivergencesIntermediate/sagas';

import { visibleDropdownFiltersSaga } from 'store/ducks/filters/details/visibleDropdownFilters/sagas';

import { exportSalesDetailsSaga } from 'store/ducks/details/export/salesDetails/sagas';
import { exportPaymentDivergencesDetailsSaga } from 'store/ducks/details/export/paymentDivergencesDetails/sagas';
import { exportAmountsReceivableDetailsSaga } from 'store/ducks/details/export/amountsReceivableDetails/sagas';
import { exportBankDetailsSaga } from 'store/ducks/details/export/bankDetails/sagas';
import { exportDuedateDetailsSaga } from 'store/ducks/details/export/duedateDetails/sagas';
import { exportAdvancementDetailsSaga } from 'store/ducks/details/export/advancementDetails/sagas';
import { exportAdjustmentDetailsSaga } from 'store/ducks/details/export/adjustmentDetails/sagas';
import { exportRefundDetailsSaga } from 'store/ducks/details/export/refundDetails/sagas';
import { exportAllClientsDetailsSaga } from 'store/ducks/details/export/allClientsDetails/sagas';
import { exportReconciledSalesDetailsSaga } from 'store/ducks/details/export/reconciledSalesDetails/sagas';

import { exportBankConciliationSaga } from 'store/ducks/bankConciliation/sagas';

import { updateBriefingStepSaga } from 'store/ducks/updateBriefingStep/sagas';
import { updateScreenToScreenDateSaga } from 'store/ducks/updateScreenToScreenDate/sagas';
import {
  updateScreenToScreenSalesPeriodSaga,
  updateScreenToScreenPaymentDivergencesPeriodSaga,
  updateScreenToScreenAmountsReceivablePeriodSaga,
  updateScreenToScreenBanksPeriodSaga,
  updateScreenToScreenDuedatePeriodSaga,
  updateScreenToScreenAdvancementsPeriodSaga,
  updateScreenToScreenAdjustmentsPeriodSaga,
  updateScreenToScreenRefundsPeriodSaga,
  updateScreenToScreenSalesReconciliationPeriodSaga,
  updateScreenToScreenPaymentsReconciliationPeriodSaga,
  updateScreenToScreenBankReconciliationPeriodSaga,
} from 'store/ducks/updateScreenToScreenPeriod/sagas';
import { updateIsImplantedSaga } from 'store/ducks/updateIsImplanted/sagas';
import {
  updateFilteredColumnsSaga,
  resetUpdateFilteredColumnsSaga,
} from 'store/ducks/updateFilteredColumns/sagas';

import {
  feesUpdateFilteredColumnsSaga,
  feesResetUpdateFilteredColumnsSaga,
} from 'store/ducks/feesUpdateFilteredColumns/sagas';

import {
  customUpdateFilteredColumnsSaga,
  customResetUpdateFilteredColumnsSaga,
} from 'store/ducks/customUpdateFilteredColumns/sagas';

// Credit sagas

import { creditListSaga } from 'store/ducks/credit/creditList/sagas';
import { creditListAvailableSaga } from 'store/ducks/credit/creditListAvailable/sagas';
import { creditListPartnersSaga } from 'store/ducks/credit/creditListPartners/sagas';
import { creditListPartnersAvailableSaga } from 'store/ducks/credit/creditListPartnersAvailable/sagas';
import { creditListPartnersOptinSaga } from 'store/ducks/credit/creditListPartnersOptin/sagas';
import { creditPartnerOptinSaga } from 'store/ducks/credit/creditPartnerOptin/sagas';
import { creditPartnerOptoutSaga } from 'store/ducks/credit/creditPartnerOptout/sagas';
import { creditInteractViewSaga } from 'store/ducks/credit/creditInteractView/sagas';
import { creditInteractPreregisterSaga } from 'store/ducks/credit/creditInteractPreregister/sagas';
import { creditInteractAccessSaga } from 'store/ducks/credit/creditInteractAccess/sagas';
import { creditInteractContractSaga } from 'store/ducks/credit/creditInteractContract/sagas';
import { creditInteractRetryApprovalSaga } from 'store/ducks/credit/creditInteractRetryApproval/sagas';
import { creditInteractCloseSaga } from 'store/ducks/credit/creditInteractClose/sagas';
import {
  getLegalRepresentativeSaga,
  postLegalRepresentativeSaga,
} from 'store/ducks/credit/legalRepresentative/sagas';
import { updateSharedPartnerDataSaga } from './credit/sharedPartnerData/sagas';
import { creditTomaticoStatusSaga } from './credit/creditTomaticoStatus/sagas';

// Radar sagas
import { radarAnyRunningSaga } from 'store/ducks/radar/radarAnyRunning/sagas';
import { radarExpireSaga } from 'store/ducks/radar/radarExpire/sagas';
import { radarGetSaga } from 'store/ducks/radar/radarGet/sagas';
import { radarListSaga } from 'store/ducks/radar/radarList/sagas';
import { radarRunSaga } from 'store/ducks/radar/radarRun/sagas';
import { radarRunResumeSaga } from 'store/ducks/radar/radarRunResume/sagas';
import { radarRunTestSaga } from 'store/ducks/radar/radarRunTest/sagas';
import { radarDetailsFiltersSaga } from 'store/ducks/filters/radar/details/sagas';
import { radarListFiltersSaga } from 'store/ducks/radar/radarListFilters/sagas';

import { searchTransactionSaga } from 'store/ducks/searchTransaction/sagas';
import { searchTransactionDetailsFiltersSaga } from 'store/ducks/filters/details/searchTransactionDetailsFilters/sagas';

// Token Sandbox sagas
import {
  tokenSandboxPostSaga,
  tokenSandboxDeleteSaga,
  tokenSandboxGetSaga,
  tokenSandboxPutSaga,
  tokenSandboxDisableTokenSaga,
  rowClientIdSandboxSaga,
} from 'store/ducks/tokenSandbox/sagas';

// Token Prod sagas
import {
  tokenProdPostSaga,
  tokenProdDeleteSaga,
  tokenProdGetSaga,
  tokenProdPutSaga,
  tokenProdDisableTokenSaga,
  rowClientIdProdSaga,
} from 'store/ducks/tokenProd/sagas';

// Plans
import { plansSaga } from 'store/ducks/plans/sagas';

// Skins
import { skinsSaga } from 'store/ducks/skins/sagas';

// Reconciliation Rules
import { listReconciliationRulesSaga } from 'store/ducks/reconciliationRules/listReconciliationRules/sagas';
import { deleteReconciliationRulesSaga } from 'store/ducks/reconciliationRules/deleteReconciliationRules/sagas';
import { postReconciliationRulesSaga } from 'store/ducks/reconciliationRules/postReconciliationRules/sagas';
import { putReconciliationRulesSaga } from 'store/ducks/reconciliationRules/putReconciliationRules/sagas';
import { getAcquirersByClientIdSaga } from 'store/ducks/reconciliationRules/getAcquirersByClientId/sagas';
import {
  getNextOrderByClientIdSaga,
  resetNextOrderByClientIdSaga,
} from 'store/ducks/reconciliationRules/getNextOrderByClientId/sagas';
import { reconciliationRulesDetailsFiltersSaga } from 'store/ducks/filters/details/reconciliationRulesDetailsFilters/sagas';

// Retroactive Conciliation
import {
  listRetroactiveConciliationSaga,
  postRetroactiveConciliationSaga,
} from 'store/ducks/retroactiveConciliation/sagas';

// CnpjMaintenance Stores
import { listStoresSaga } from 'store/ducks/cnpjMaintenance/listStores/sagas';
import { deleteStoresSaga } from 'store/ducks/cnpjMaintenance/deleteStores/sagas';
import { putStoresSaga } from 'store/ducks/cnpjMaintenance/putStores/sagas';
import { updateStoreSaga } from 'store/ducks/cnpjMaintenance/updateStore/sagas';
// CnpjMaintenance Merchants
import {
  listMerchantsSaga,
  resetListMerchantsSaga,
} from 'store/ducks/cnpjMaintenance/merchants/listMerchants/sagas';
import { deleteMerchantCnpjMaintenanceSaga } from 'store/ducks/cnpjMaintenance/merchants/deleteMerchant/sagas';
import { cnpjMaintenanceInfoFiltersSaga } from 'store/ducks/filters/cnpjMaintenanceInfoFilters/sagas';

// Fees
import { feesDetailsFiltersSaga } from 'store/ducks/filters/fees/sagas';
import { feesDetailsSaga } from 'store/ducks/details/feesDetails/sagas';
import { deleteFeeSaga } from 'store/ducks/fees/deleteFee/sagas';

// TYPES

import { actionTypes as AuthTypes } from 'store/ducks/auth/types';
import { actionTypes as CompleteNewPasswordTypes } from 'store/ducks/completeNewPassword/types';
import { actionTypes as LogoutTypes } from 'store/ducks/logout/types';
import { actionTypes as ForgotPasswordTypes } from 'store/ducks/forgotPassword/types';
import { actionTypes as RecoveryPasswordTypes } from 'store/ducks/recoveryPassword/types';
import { actionTypes as PermissionsTypes } from 'store/ducks/permissions/types';
import { actionTypes as ValidateAccessTypes } from 'store/ducks/validateAccess/types';

import { actionTypes as ChangeStoreTypes } from 'store/ducks/changeStore/types';
import { actionTypes as SalesSummaryTypes } from 'store/ducks/salesSummary/types';
import { actionTypes as SalesSummaryReconciledTypes } from 'store/ducks/reconciled/summary/sales/types';
import { actionTypes as SalesReconciledTypes } from 'store/ducks/reconciled/sales/types';
import { actionTypes as SalesInfoTypes } from 'store/ducks/salesInfo/types';

import { actionTypes as AmountsReceivableSummaryTypes } from 'store/ducks/amountsReceivableSummary/types';
import { actionTypes as AmountsReceivableInfoTypes } from 'store/ducks/amountsReceivableInfo/types';

import { actionTypes as PaymentsSummaryReconciledTypes } from 'store/ducks/reconciled/summary/payments/types';
import { actionTypes as BankSummaryReconciledTypes } from 'store/ducks/reconciled/summary/bank/types';
import { actionTypes as PaymentsReconciledTypes } from 'store/ducks/reconciled/payments/types';

import { actionTypes as SalesDetailsTypes } from 'store/ducks/details/salesDetails/types';
import { actionTypes as ReconciledSalesDetailsTypes } from 'store/ducks/details/reconciledSalesDetails/types';
import { actionTypes as ReconciledPaymentsDetailsTypes } from 'store/ducks/details/reconciledPaymentsDetails/types';
import { actionTypes as ReconciledReceiptDetailsTypes } from 'store/ducks/details/reconciledReceiptDetails/types';
import { actionTypes as AmountsReceivableDetailsTypes } from 'store/ducks/details/amountsReceivableDetails/types';
import { actionTypes as DuedateDetailsTypes } from 'store/ducks/details/duedateDetails/types';
import { actionTypes as AdvancementDetailsTypes } from 'store/ducks/details/advancementDetails/types';
import { actionTypes as AdjustmentDetailsTypes } from 'store/ducks/details/adjustmentDetails/types';
import { actionTypes as RefundsDetailsTypes } from 'store/ducks/details/refundsDetails/types';
import { actionTypes as PaymentDivergencesDetailsTypes } from 'store/ducks/details/paymentDivergencesDetails/types';

import { actionTypes as SalesInfoFiltersTypes } from 'store/ducks/filters/salesInfoFilters/types';
import { actionTypes as ReconciledSalesInfoFiltersTypes } from 'store/ducks/filters/reconciledSalesInfoFilters/types';
// import { actionTypes as ReconciledPaymentsInfoFiltersTypes } from 'store/ducks/filters/reconciledPaymentsInfoFilters/types';
import { actionTypes as DivergencesInfoFiltersTypes } from 'store/ducks/filters/divergencesInfoFilters/types';
import { actionTypes as AmountsReceivableInfoFiltersTypes } from 'store/ducks/filters/amountsReceivableInfoFilters/types';
import { actionTypes as DuedateInfoFiltersTypes } from 'store/ducks/filters/duedateInfoFilters/types';
import { actionTypes as AdvancementInfoFiltersTypes } from 'store/ducks/filters/advancementInfoFilters/types';
import { actionTypes as AdjustmentInfoFiltersTypes } from 'store/ducks/filters/adjustmentInfoFilters/types';
import { actionTypes as RefundInfoFiltersTypes } from 'store/ducks/filters/refundInfoFilters/types';

import { actionTypes as BankConciliationFiltersTypes } from 'store/ducks/filters/details/bankConciliationFilters/types';
import { actionTypes as SalesDetailsFiltersTypes } from 'store/ducks/filters/details/salesDetailsFilters/types';
import { actionTypes as OfxParametersFiltersTypes } from 'store/ducks/filters/ofxParameters/types';
import { actionTypes as ReconciledSalesDetailsFiltersTypes } from 'store/ducks/filters/details/reconciledSalesDetailsFilters/types';
import { actionTypes as ReconciledBankDetailsFiltersTypes } from 'store/ducks/filters/details/reconciledBankDetailsFilters/types';
import { actionTypes as ReconciledPaymentsDetailsFiltersTypes } from 'store/ducks/filters/details/reconciledPaymentsDetailsFilters/types';
import { actionTypes as AmountsReceivableDetailsFiltersTypes } from 'store/ducks/filters/details/amountsReceivableDetailsFilters/types';
import { actionTypes as BanksDetailsFiltersTypes } from 'store/ducks/filters/details/banksDetailsFilters/types';
import { actionTypes as DuedateDetailsFiltersTypes } from 'store/ducks/filters/details/duedateDetailsFilters/types';
import { actionTypes as AdvancementDetailsFiltersTypes } from 'store/ducks/filters/details/advancementDetailsFilters/types';
import { actionTypes as ImportFilesFiltersTypes } from 'store/ducks/filters/importFiles/types';
import { actionTypes as AdjustmentDetailsFiltersTypes } from 'store/ducks/filters/details/adjustmentDetailsFilters/types';
import { actionTypes as RefundDetailsFiltersTypes } from 'store/ducks/filters/details/refundDetailsFilters/types';
import { actionTypes as ContractFeeFiltersTypes } from 'store/ducks/filters/contractFeeFilters/types';
import { actionTypes as PaymentDivergencesDetailsFiltersTypes } from 'store/ducks/filters/details/paymentDivergencesDetailsFilters/types';

import { actionTypes as Acquirer } from 'store/ducks/acquirer/types';
import { actionTypes as CreatePassword } from 'store/ducks/createPassword/types';
import { actionTypes as ListOfClientsTypes } from 'store/ducks/listOfClients/types';
import { actionTypes as ListOfClientsFiltersTypes } from 'store/ducks/filters/listOfClientsFilters/types';
import { actionTypes as ListOfPlansTypes } from 'store/ducks/listOfPlans/types';
import { actionTypes as GeneralListOfClientsTypes } from 'store/ducks/generalListOfClients/types';
import { actionTypes as UnfilteredGeneralListOfClientsTypes } from 'store/ducks/unfilteredGeneralListOfClients/types';
import { actionTypes as ListUsersTypes } from 'store/ducks/listUsersClient/types';
import { actionTypes as RegisterFeeTypes } from 'store/ducks/registerFees/types';

import { actionTypes as OptinTypes } from 'store/ducks/optin/optin/types';
import { actionTypes as OptinRequestTypes } from 'store/ducks/optin/optinRequest/types';
import { actionTypes as OptinUrlsTypes } from 'store/ducks/optin/optinUrls/types';

import { actionTypes as CheckInvitationEmailTypes } from 'store/ducks/checkInvitationEmail/types';
import { actionTypes as SendInvitationTypes } from 'store/ducks/sendInvitation/types';
import { actionTypes as ListClientSelect } from 'store/ducks/listClientsSelect/types';
import { actionTypes as AlterProfileScope } from 'store/ducks/alterProfileScope/types';
import { actionTypes as RemoveUserTypes } from 'store/ducks/removeUser/types';
import { actionTypes as DeleteUserTypes } from 'store/ducks/deleteUser/types';
import { actionTypes as RemoveBranchTypes } from 'store/ducks/removeBranch/types';
import { actionTypes as ListFees } from 'store/ducks/listFees/types';

import { actionTypes as UpdateBranchDataTypes } from 'store/ducks/updateBranchData/types';
import { actionTypes as CreateBranchTypes } from 'store/ducks/createBranch/types';

import { actionTypes as ExportSalesDetailsTypes } from 'store/ducks/details/export/salesDetails/types';
import { actionTypes as ExportReconciledSalesDetailsTypes } from 'store/ducks/details/export/reconciledSalesDetails/types';
import { actionTypes as ExportPaymentDivergencesDetailsTypes } from 'store/ducks/details/export/paymentDivergencesDetails/types';
import { actionTypes as ExportAmountsReceivableDetailsTypes } from 'store/ducks/details/export/amountsReceivableDetails/types';
import { actionTypes as ExportBankDetailsTypes } from 'store/ducks/details/export/bankDetails/types';
import { actionTypes as ExportDuedateDetailsTypes } from 'store/ducks/details/export/duedateDetails/types';
import { actionTypes as ExportAdvancementDetailsTypes } from 'store/ducks/details/export/advancementDetails/types';
import { actionTypes as ExportAdjustmentDetailsTypes } from 'store/ducks/details/export/adjustmentDetails/types';
import { actionTypes as ExportRefundDetailsTypes } from 'store/ducks/details/export/refundDetails/types';
import { actionTypes as ExportAllClientsDetailsTypes } from 'store/ducks/details/export/allClientsDetails/types';

import { actionTypes as ImportFiles } from 'store/ducks/importFiles/types';
import { actionTypes as OfxParameters } from 'store/ducks/ofxParameters/types';
import { actionTypes as BanksDash } from 'store/ducks/banks/listBanksDash/types';
import { actionTypes as BanksIntermediate } from 'store/ducks/banks/listBanksIntermediate/types';
import { actionTypes as BanksReports } from 'store/ducks/banks/listBanksReports/types';
import { actionTypes as BankConciliation } from 'store/ducks/bankConciliation/types';

import { actionTypes as Briefing } from 'store/ducks/briefing/types';
import { actionTypes as UploadedLetterUrl } from 'store/ducks/uploadedLetterUrl/types';
import { actionTypes as Client } from 'store/ducks/client/types';
import { actionTypes as Store } from 'store/ducks/store/types';
import { actionTypes as ViaCep } from 'store/ducks/viaCep/types';
import { actionTypes as ReceitaWebservice } from 'store/ducks/receitaWebservice/types';
import { actionTypes as ClientAcquirer } from 'store/ducks/clientAcquirer/types';
import { actionTypes as Merchant } from 'store/ducks/merchant/types';
import { actionTypes as Notifications } from 'store/ducks/notifications/types';

import { actionTypes as AdjustmentPaymentsDash } from 'store/ducks/paymentsDash/adjustmentPayments/types';
import { actionTypes as DuedatePaymentsDash } from 'store/ducks/paymentsDash/duedatePayments/types';
import { actionTypes as AdvancementPaymentsDash } from 'store/ducks/paymentsDash/advancementPayments/types';
import { actionTypes as RefundsPaymentsDash } from 'store/ducks/paymentsDash/refundsPayments/types';

import { actionTypes as AdvancementPaymentsIntermediate } from 'store/ducks/paymentsIntermediate/advancementPaymentsIntermediate/types';
import { actionTypes as DuedatePaymentsIntermediate } from 'store/ducks/paymentsIntermediate/duedatePaymentsIntermediate/types';
import { actionTypes as AdjustmentPaymentsIntermediate } from 'store/ducks/paymentsIntermediate/adjustmentPaymentsIntermediate/types';
import { actionTypes as RefundsPaymentsIntermediate } from 'store/ducks/paymentsIntermediate/refundsPaymentsIntermediate/types';
import { actionTypes as PaymentsDivergencesDash } from 'store/ducks/paymentsDash/paymentsDivergences/types';
import { actionTypes as PaymentsDivergencesIntermediate } from 'store/ducks/paymentsIntermediate/paymentsDivergencesIntermediate/types';

import { actionTypes as VisibleDropdownFilters } from 'store/ducks/filters/details/visibleDropdownFilters/types';

import { actionTypes as UpdateBriefingStep } from 'store/ducks/updateBriefingStep/types';
import { actionTypes as UpdateScreenToScreenDate } from 'store/ducks/updateScreenToScreenDate/types';
import { actionTypes as UpdateScreenToScreenPeriod } from 'store/ducks/updateScreenToScreenPeriod/types';
import { actionTypes as UpdateIsImplanted } from 'store/ducks/updateIsImplanted/types';
import { actionTypes as UpdateFilteredColumns } from 'store/ducks/updateFilteredColumns/types';
import { actionTypes as FeesUpdateFilteredColumns } from 'store/ducks/feesUpdateFilteredColumns/types';
import { actionTypes as CustomUpdateFilteredColumns } from 'store/ducks/customUpdateFilteredColumns/types';

import { actionTypes as SearchTransactionTypes } from 'store/ducks/searchTransaction/types';
import { actionTypes as SearchTransactionDetailsFiltersTypes } from 'store/ducks/filters/details/searchTransactionDetailsFilters/types';

// Reconciliation Rules Types
import { actionTypes as ListReconciliationRulesTypes } from 'store/ducks/reconciliationRules/listReconciliationRules/types';
import { actionTypes as DeleteReconciliationRulesTypes } from 'store/ducks/reconciliationRules/deleteReconciliationRules/types';
import { actionTypes as PostReconciliationRulesTypes } from 'store/ducks/reconciliationRules/postReconciliationRules/types';
import { actionTypes as PutReconciliationRulesTypes } from 'store/ducks/reconciliationRules/putReconciliationRules/types';
import { actionTypes as GetAcquirersByClientIdTypes } from 'store/ducks/reconciliationRules/getAcquirersByClientId/types';
import { actionTypes as GetNextOrderByClientIdTypes } from 'store/ducks/reconciliationRules/getNextOrderByClientId/types';
import { actionTypes as ReconciliationRulesDetailsFiltersTypes } from 'store/ducks/filters/details/reconciliationRulesDetailsFilters/types';

// Credit types

import { actionTypes as CreditListTypes } from 'store/ducks/credit/creditList/types';
import { actionTypes as CreditListAvailableTypes } from 'store/ducks/credit/creditListAvailable/types';
import { actionTypes as CreditListPartnersTypes } from 'store/ducks/credit/creditListPartners/types';
import { actionTypes as CreditListPartnersAvailableTypes } from 'store/ducks/credit/creditListPartnersAvailable/types';
import { actionTypes as CreditListPartnersOptinTypes } from 'store/ducks/credit/creditListPartnersOptin/types';
import { actionTypes as CreditPartnerOptinTypes } from 'store/ducks/credit/creditPartnerOptin/types';
import { actionTypes as CreditPartnerOptoutTypes } from 'store/ducks/credit/creditPartnerOptout/types';
import { actionTypes as CreditInteractViewTypes } from 'store/ducks/credit/creditInteractView/types';
import { actionTypes as CreditInteractPreregisterTypes } from 'store/ducks/credit/creditInteractPreregister/types';
import { actionTypes as CreditInteractAccessTypes } from 'store/ducks/credit/creditInteractAccess/types';
import { actionTypes as CreditInteractContractTypes } from 'store/ducks/credit/creditInteractContract/types';
import { actionTypes as CreditInteractRetryApprovalTypes } from 'store/ducks/credit/creditInteractRetryApproval/types';
import { actionTypes as CreditInteractCloseTypes } from 'store/ducks/credit/creditInteractClose/types';
import { actionTypes as LegalRepresentativeTypes } from 'store/ducks/credit/legalRepresentative/types';
import { actionTypes as SharedPartnerDataTypes } from 'store/ducks/credit/sharedPartnerData/types';
import { actionTypes as CreditTomaticoStatusTypes } from 'store/ducks/credit/creditTomaticoStatus/types';

// Radar types
import { actionTypes as RadarAnyRunningTypes } from 'store/ducks/radar/radarAnyRunning/types';
import { actionTypes as RadarExpireTypes } from 'store/ducks/radar/radarExpire/types';
import { actionTypes as RadarGetTypes } from 'store/ducks/radar/radarGet/types';
import { actionTypes as RadarListTypes } from 'store/ducks/radar/radarList/types';
import { actionTypes as RadarRunTypes } from 'store/ducks/radar/radarRun/types';
import { actionTypes as RadarRunResumeTypes } from 'store/ducks/radar/radarRunResume/types';
import { actionTypes as RadarRunTestTypes } from 'store/ducks/radar/radarRunTest/types';
import { actionTypes as RadarDetailsFiltersTypes } from 'store/ducks/filters/radar/details/types';
import { actionTypes as RadarListFiltersTypes } from 'store/ducks/radar/radarListFilters/types';

// Token Sandbox types
import { actionTypes as TokenSandboxTypes } from 'store/ducks/tokenSandbox/types';

// Token Prod types
import { actionTypes as TokenProdTypes } from 'store/ducks/tokenProd/types';

// Plans types
import { actionTypes as PlansTypes } from 'store/ducks/plans/types';

// Skins types
import { actionTypes as SkinsTypes } from 'store/ducks/skins/types';

// Retroactive Conciliation Types
import { actionTypes as RetroactiveConciliationTypes } from 'store/ducks/retroactiveConciliation/types';

// Cnpj Maintenance Stores
import { actionTypes as ListStoresTypes } from 'store/ducks/cnpjMaintenance/listStores/types';
import { actionTypes as DeleteStoresTypes } from 'store/ducks/cnpjMaintenance/deleteStores/types';
import { actionTypes as PutStoresTypes } from 'store/ducks/cnpjMaintenance/putStores/types';
import { actionTypes as UpdateStoreTypes } from 'store/ducks/cnpjMaintenance/updateStore/types';

import { actionTypes as cnpjMaintenanceInfoFiltersTypes } from 'store/ducks/filters/cnpjMaintenanceInfoFilters/types';

// Cnpj Maintenance Merchants
import { actionTypes as ListMerchantsTypes } from 'store/ducks/cnpjMaintenance/merchants/listMerchants/types';
import { actionTypes as DeleteMerchantTypes } from 'store/ducks/cnpjMaintenance/merchants/deleteMerchant/types';

// Fees types
import { actionTypes as FeesDetailsTypes } from 'store/ducks/details/feesDetails/types';
import { actionTypes as FeesDetailsFiltersTypes } from 'store/ducks/filters/fees/types';
import { actionTypes as DeleteFeeTypes } from 'store/ducks/fees/deleteFee/types';

export default function* rootSaga() {
  return yield all([
    takeLatest(AuthTypes.AUTH_START, authSaga),
    takeLatest(
      CompleteNewPasswordTypes.COMPLETE_NEW_PASSWORD_START,
      completeNewPasswordSaga
    ),
    takeLatest(AuthTypes.VALIDATE_TOKEN_START, validateTokenSaga),
    takeLatest(LogoutTypes.LOGOUT_START, logoutSaga),
    takeLatest(ForgotPasswordTypes.FORGOT_PASSWORD_START, forgotPasswordSaga),
    takeLatest(
      RecoveryPasswordTypes.RECOVERY_PASSWORD_START,
      recoveryPasswordSaga
    ),
    // TODO: Remove permissionsSaga to use hook usePermission
    takeLatest(PermissionsTypes.PERMISSIONS_START, permissionsSaga),
    takeLatest(ValidateAccessTypes.VALIDATE_ACCESS_START, validateAccessSaga),
    takeLatest(
      CheckInvitationEmailTypes.CHECK_INVITATION_EMAIL_START,
      checkInvitationEmailSaga
    ),
    takeLatest(SendInvitationTypes.SEND_INVITATION_START, sendInvitationSaga),
    takeLatest(SalesSummaryTypes.SALES_SUMMARY_START, salesSummarySaga),
    takeLatest(
      SalesSummaryReconciledTypes.SALES_SUMMARY_RECONCILED_START,
      salesSummaryReconciledSaga
    ),
    takeLatest(
      SalesReconciledTypes.SALES_RECONCILED_START,
      salesReconciledSaga
    ),
    takeLatest(SalesInfoTypes.SALES_INFO_START, salesInfoSaga),
    takeLatest(
      AmountsReceivableInfoTypes.AMOUNTS_RECEIVABLE_INFO_START,
      amountsReceivableInfoSaga
    ),
    takeLatest(SalesDetailsTypes.SALES_DETAILS_START, salesDetailsSaga),
    takeLatest(
      ReconciledSalesDetailsTypes.RECONCILED_SALES_DETAILS_START,
      reconciledSalesDetailsSaga
    ),
    takeLatest(
      ReconciledReceiptDetailsTypes.RECONCILED_RECEIPT_DETAILS_START,
      reconciledReceiptDetailsSaga
    ),
    takeLatest(
      ReconciledPaymentsDetailsTypes.RECONCILED_PAYMENTS_DETAILS_START,
      reconciledPaymentsDetailsSaga
    ),

    takeLatest(
      PaymentDivergencesDetailsTypes.PAYMENT_DIVERGENCES_DETAILS_START,
      paymentDivergencesDetailsSaga
    ),
    takeLatest(
      AmountsReceivableDetailsTypes.AMOUNTS_RECEIVABLE_DETAILS_START,
      amountsReceivableDetailsSaga
    ),
    takeLatest(DuedateDetailsTypes.DUEDATE_DETAILS_START, duedateDetailsSaga),
    takeLatest(
      AdvancementDetailsTypes.ADVANCEMENT_DETAILS_START,
      advancementDetailsSaga
    ),
    takeLatest(
      AdjustmentDetailsTypes.ADJUSTMENT_DETAILS_START,
      adjustmentDetailsSaga
    ),
    takeLatest(RefundsDetailsTypes.REFUNDS_DETAILS_START, refundsDetailsSaga),
    takeLatest(
      AmountsReceivableSummaryTypes.AMOUNTS_RECEIVABLE_SUMMARY_START,
      amountsReceivableSummarySaga
    ),
    takeLatest(
      SalesInfoFiltersTypes.SALES_INFO_FILTERS_START,
      salesInfoFiltersSaga
    ),
    takeLatest(
      ReconciledSalesInfoFiltersTypes.RECONCILED_SALES_INFO_FILTERS_START,
      reconciledSalesInfoFiltersSaga
    ),
    // takeLatest(
    //   ReconciledPaymentsInfoFiltersTypes.RECONCILED_PAYMENTS_INFO_FILTERS_START,
    //   reconciledPaymentsInfoFiltersSaga
    // ),
    takeLatest(
      DivergencesInfoFiltersTypes.DIVERGENCES_INFO_FILTERS_START,
      divergencesInfoFiltersSaga
    ),
    takeLatest(
      AmountsReceivableInfoFiltersTypes.AMOUNTS_RECEIVABLE_INFO_FILTERS_START,
      amountsReceivableInfoFiltersSaga
    ),
    takeLatest(
      DuedateInfoFiltersTypes.DUEDATE_INFO_FILTERS_START,
      duedateInfoFiltersSaga
    ),
    takeLatest(
      AdvancementInfoFiltersTypes.ADVANCEMENT_INFO_FILTERS_START,
      advancementInfoFiltersSaga
    ),
    takeLatest(
      AdjustmentInfoFiltersTypes.ADJUSTMENT_INFO_FILTERS_START,
      adjustmentInfoFiltersSaga
    ),
    takeLatest(
      RefundInfoFiltersTypes.REFUND_INFO_FILTERS_START,
      refundInfoFiltersSaga
    ),
    takeEvery(
      SalesDetailsFiltersTypes.SALES_DETAILS_FILTERS_START,
      salesDetailsFiltersSaga
    ),
    takeEvery(
      OfxParametersFiltersTypes.OFX_PARAMETERS_FILTERS_START,
      ofxParametersFiltersSaga
    ),
    takeEvery(
      ReconciledSalesDetailsFiltersTypes.RECONCILED_SALES_DETAILS_FILTERS_START,
      reconciledSalesDetailsFiltersSaga
    ),
    takeEvery(
      ReconciledBankDetailsFiltersTypes.RECONCILED_BANK_DETAILS_FILTERS_START,
      reconciledBankDetailsFiltersSaga
    ),
    takeEvery(
      ReconciledPaymentsDetailsFiltersTypes.RECONCILED_PAYMENTS_DETAILS_FILTERS_START,
      reconciledPaymentsDetailsFiltersSaga
    ),
    takeLatest(
      BankConciliationFiltersTypes.BANK_CONCILIATION_FILTERS_START,
      bankConciliationFiltersSaga
    ),
    takeEvery(
      PaymentDivergencesDetailsFiltersTypes.PAYMENT_DIVERGENCES_DETAILS_FILTERS_START,
      paymentDivergencesDetailsFiltersSaga
    ),
    takeEvery(
      AmountsReceivableDetailsFiltersTypes.AMOUNTS_RECEIVABLE_DETAILS_FILTERS_START,
      amountsReceivableDetailsFiltersSaga
    ),
    takeEvery(
      BanksDetailsFiltersTypes.BANKS_DETAILS_FILTERS_START,
      banksDetailsFiltersSaga
    ),
    takeEvery(
      DuedateDetailsFiltersTypes.DUEDATE_DETAILS_FILTERS_START,
      duedateDetailsFiltersSaga
    ),
    takeEvery(
      AdvancementDetailsFiltersTypes.ADVANCEMENT_DETAILS_FILTERS_START,
      advancementDetailsFiltersSaga
    ),
    takeEvery(
      AdjustmentDetailsFiltersTypes.ADJUSTMENT_DETAILS_FILTERS_START,
      adjustmentDetailsFiltersSaga
    ),
    takeEvery(
      ImportFilesFiltersTypes.IMPORT_FILES_FILTERS_START,
      importFilesFiltersSaga
    ),
    takeEvery(
      RefundDetailsFiltersTypes.REFUND_DETAILS_FILTERS_START,
      refundDetailsFiltersSaga
    ),
    takeLatest(
      PaymentsSummaryReconciledTypes.PAYMENTS_SUMMARY_RECONCILED_START,
      paymentsSummaryReconciledSaga
    ),
    takeLatest(
      BankSummaryReconciledTypes.BANK_SUMMARY_RECONCILED_START,
      bankSummaryReconciledSaga
    ),
    takeLatest(
      PaymentsReconciledTypes.PAYMENTS_RECONCILED_START,
      paymentsReconciledSaga
    ),
    takeEvery(
      ContractFeeFiltersTypes.CONTRACT_FEE_FILTERS_START,
      contractFeeFiltersSaga
    ),
    takeLatest(Acquirer.ACQUIRER_START, acquirerSaga),
    takeLatest(Acquirer.ACQUIRER_ALL_START, acquirerAllSaga),
    takeLatest(Acquirer.ACQUIRER_POINTOFSALE_START, acquirerPointOfSaleSaga),
    takeLatest(Acquirer.ACQUIRER_VOUCHER_START, acquirerVoucherSaga),
    takeEvery(Acquirer.ACQUIRER_MERCHANTS_START, acquirerMerchantsSaga),
    takeLatest(CreatePassword.CREATE_PASSWORD_START, createPasswordSaga),
    takeLatest(ListOfClientsTypes.LIST_OF_CLIENTS_START, listOfClientsSaga),
    takeLatest(
      ListOfClientsFiltersTypes.LIST_OF_CLIENTS_FILTERS_START,
      listOfClientsFiltersSaga
    ),
    takeLatest(ListOfPlansTypes.LIST_OF_PLANS_START, listOfPlansSaga),
    takeLatest(
      GeneralListOfClientsTypes.GENERAL_LIST_OF_CLIENTS_START,
      generalListOfClientsSaga
    ),
    takeLatest(
      UnfilteredGeneralListOfClientsTypes.UNFILTERED_GENERAL_LIST_OF_CLIENTS_START,
      unfilteredGeneralListOfClientsSaga
    ),
    takeLatest(ListUsersTypes.LIST_USERS_START, listUsersSaga),
    takeLatest(ChangeStoreTypes.CHANGE_STORE_START, changeStoreSaga),
    takeLatest(RegisterFeeTypes.REGISTER_FEE_START, registerFeeSaga),
    takeLatest(
      ListClientSelect.LIST_OF_CLIENTS_SELECT_START,
      listOfClientsSelectSaga
    ),
    takeEvery(OptinTypes.OPTIN_START, optinSaga),
    takeEvery(OptinRequestTypes.OPTIN_REQUEST_START, optinRequestSaga),
    takeLatest(OptinUrlsTypes.OPTIN_URLS_START, optinUrlsSaga),
    takeLatest(
      AlterProfileScope.ALTER_PROFILE_SCOPE_START,
      alterProfileScopeSaga
    ),
    takeLatest(RemoveUserTypes.REMOVE_USER_START, removeUserSaga),
    takeLatest(DeleteUserTypes.DELETE_USER_START, deleteUserSaga),
    takeLatest(RemoveBranchTypes.REMOVE_BRANCH_START, removeBranchSaga),
    takeLatest(ListFees.LIST_FEES_VENCIDAS_START, listFeesVencidaSaga),
    takeLatest(ListFees.LIST_FEES_VIGENTES_START, listFeesVigenteSaga),
    takeLatest(ListFees.PUT_FEES_START, putFeesSaga),
    takeLatest(ListFees.DELETE_FEES_START, deleteFeesSaga),
    takeLatest(
      UpdateBranchDataTypes.UPDATE_BRANCH_DATA_START,
      updateBranchDataSaga
    ),
    takeLatest(CreateBranchTypes.CREATE_BRANCH_START, createBranchSaga),

    // Banks
    takeLatest(BanksDash.LIST_BANKS_DASH_START, banksDashSaga),
    takeLatest(
      BanksIntermediate.LIST_BANKS_INTERMEDIATE_START,
      banksIntermediateSaga
    ),
    takeLatest(BanksReports.BANKS_REPORTS_START, banksReportSaga),

    // Import Files
    takeLatest(ImportFiles.LIST_FILES_START, listFilesSaga),
    takeLatest(ImportFiles.UPLOAD_FILE_START, uploadFileSaga),
    takeLatest(ImportFiles.REMOVE_FILES_START, removeFileSaga),
    takeLatest(ImportFiles.UPDATE_FILE_DATE_START, updateFileDateSaga),
    takeLatest(
      ImportFiles.UPDATE_CURRENT_PAGE_START,
      importFilesCurrentPageSaga
    ),

    // OFX Parameters
    takeLatest(
      OfxParameters.LIST_OFX_PARAMETERS_START,
      listOFXParametersOFXSaga
    ),
    takeLatest(OfxParameters.ADD_OFX_PARAMETER_START, addOFXParameterSaga),
    takeLatest(OfxParameters.EDIT_OFX_PARAMETER_START, editOFXParameterSaga),
    takeLatest(
      OfxParameters.REMOVE_OFX_PARAMETER_START,
      removeOFXParameterSaga
    ),

    // Bank Conciliation
    takeLatest(
      BankConciliation.SET_TRANSACTIONS_SELECTED,
      setTransactionsSelectedSaga
    ),
    takeLatest(
      BankConciliation.UPDATE_BANK_CONCILIATION_TRANSACTIONS_START,
      updateBankConciliationTransactionsSaga
    ),
    takeLatest(
      BankConciliation.UPDATE_COUNTER_TRANSACTIONS_START,
      updateCounterTransactionsSaga
    ),
    takeLatest(
      BankConciliation.UPDATE_TRANSACTION_DATE_START,
      updateTransactionDateSaga
    ),
    takeLatest(
      BankConciliation.UPDATE_THROUGH_IMPORT_FILES_START,
      updateThroughImportFilesSaga
    ),
    takeLatest(
      BankConciliation.RESET_HAS_NO_TRANSACTIONS_START,
      resetHasNoTransactionsSaga
    ),
    takeLatest(
      BankConciliation.UPDATE_DIFFERENCE_VALUE_START,
      updateDifferenceValueSaga
    ),
    takeLatest(
      BankConciliation.UPDATE_ACQUIRER_NAME_START,
      updateAcquirerNameSaga
    ),
    takeLatest(
      BankConciliation.UPDATE_CURRENT_PAGE_START,
      updateCurrentPageSaga
    ),
    takeLatest(BankConciliation.UPDATE_AFTER_SAVE, updateAfterSaveSaga),
    takeLatest(
      BankConciliation.LIST_BANK_CONCILIATION_START,
      listBankConciliationSaga
    ),
    takeLatest(
      BankConciliation.LIST_BANK_CONCILIATION_TRANSACTIONS_START,
      listBankConciliationTransactionsSaga
    ),
    takeLatest(
      BankConciliation.LIST_BANK_CONCILIATION_TRANSACTIONS_CLEAR,
      listBankConciliationTransactionsClearSaga
    ),

    // Briefing
    takeLatest(Briefing.GET_BRIEFING_START, getBriefingSaga),
    takeLatest(Briefing.POST_BRIEFING_START, postBriefingSaga),
    takeLatest(Briefing.PUT_BRIEFING_START, putBriefingSaga),
    takeEvery(
      UploadedLetterUrl.GET_UPLOADED_LETTER_URL_START,
      getUploadedLetterUrlSaga
    ),
    takeEvery(
      UploadedLetterUrl.DELETE_UPLOADED_LETTER_URL_START,
      deleteUploadedLetterUrlSaga
    ),
    takeLatest(Client.GET_CLIENT_START, getClientSaga),
    takeLatest(Client.POST_CLIENT_START, postClientSaga),
    takeLatest(Client.PUT_CLIENT_START, putClientSaga),

    takeEvery(Client.FILE_IDENTIFICATION_START, fileIdentificationSaga),

    takeEvery(Store.GET_STORES_START, getStoresSaga),
    takeLatest(Store.POST_STORE_START, postStoreSaga),
    takeEvery(Store.PUT_STORE_START, putStoreSaga),
    takeLatest(Store.DELETE_STORE_START, deleteStoreSaga),
    takeLatest(ViaCep.GET_CEP_START, getCepSaga),
    takeLatest(ReceitaWebservice.GET_CNPJ_START, getCnpjSaga),
    takeLatest(ClientAcquirer.GET_CLIENT_ACQUIRER_START, getClientAcquirerSaga),
    takeEvery(
      ClientAcquirer.POST_CLIENT_ACQUIRER_START,
      postClientAcquirerSaga
    ),
    takeLatest(
      ClientAcquirer.DELETE_CLIENT_ACQUIRER_START,
      deleteClientAcquirerSaga
    ),
    takeLatest(Merchant.GET_MERCHANTS_START, getMerchantsSaga),
    takeLatest(Merchant.POST_MERCHANT_START, postMerchantSaga),
    takeLatest(Merchant.DELETE_MERCHANT_START, deleteMerchantSaga),

    // Notifications
    takeLatest(Notifications.GET_NOTIFICATION_START, getNotificationSaga),
    takeLatest(Notifications.CREATE_NOTIFICATION_START, createNotificationSaga),
    takeLatest(
      Notifications.CHANGE_NOTIFICATION_STATUS_START,
      changeNotificationStatusSaga
    ),
    takeLatest(Notifications.GET_DOWNLOAD_URL_START, getDownloadUrlSaga),
    takeLatest(Notifications.MARK_ALERT_AS_SENT, markAlertAsSentSaga),
    takeLatest(
      Notifications.UPDATE_NOTIFICATION_PAGE,
      updateNotificationPageSaga
    ),
    takeLatest(
      Notifications.UPDATE_NOTIFICATION_PER_PAGE,
      updateNotificationPerPageSaga
    ),
    // takeLatest(Notifications.REQUEST_FILE_START, requestFileSaga),

    //Payments
    takeLatest(
      AdjustmentPaymentsDash.LIST_ADJUSTMENT_PAYMENTS_DASH_START,
      listAdjustmentPaymentsDashSaga
    ),
    takeLatest(
      DuedatePaymentsDash.LIST_RECIPTS_PAYMENTS_DASH_START,
      duedatePaymentsDashSaga
    ),
    takeLatest(
      AdvancementPaymentsDash.LIST_ADVANCEMENT_PAYMENTS_DASH_START,
      advancementPaymentsDashSaga
    ),
    takeLatest(RefundsPaymentsDash.LIST_REFUNDS_START, refundsPaymentsDashSaga),
    takeLatest(
      AdvancementPaymentsIntermediate.LIST_ADVANCEMENT_PAYMENTS_INTERMEDIATE_START,
      advancementPaymentsIntermediateSaga
    ),
    takeLatest(
      DuedatePaymentsIntermediate.LIST_DUEDATE_PAYMENTS_INTERMEDIATE_START,
      duedatePaymentsIntermediateSaga
    ),
    takeLatest(
      AdjustmentPaymentsIntermediate.LIST_ADJUSTMENT_PAYMENTS_INTERMEDIATE_START,
      adjustmentPaymentsIntermediateSaga
    ),
    takeLatest(
      RefundsPaymentsIntermediate.LIST_REFUNDS_PAYMENTS_INTERMEDIATE_START,
      refundsPaymentsIntermediateSaga
    ),
    takeLatest(
      PaymentsDivergencesDash.LIST_PAYMENTS_DIVERGENCE_DASH_START,
      paymentsDivergencesDashSaga
    ),
    takeLatest(
      PaymentsDivergencesIntermediate.LIST_PAYMENTS_DIVERGENTES_INTERMEDIATE_START,
      paymentsDivergencesIntermediateSaga
    ),
    takeLatest(
      VisibleDropdownFilters.UPDATE_VISIBLE_DROPDOWN_FILTERS,
      visibleDropdownFiltersSaga
    ),
    takeLatest(
      ExportSalesDetailsTypes.EXPORT_SALES_DETAILS_START,
      exportSalesDetailsSaga
    ),
    takeLatest(
      ExportReconciledSalesDetailsTypes.EXPORT_RECONCILED_SALES_DETAILS_START,
      exportReconciledSalesDetailsSaga
    ),
    takeLatest(
      ExportPaymentDivergencesDetailsTypes.EXPORT_PAYMENT_DIVERGENCES_DETAILS_START,
      exportPaymentDivergencesDetailsSaga
    ),
    takeLatest(
      ExportAmountsReceivableDetailsTypes.EXPORT_AMOUNTS_RECEIVABLE_DETAILS_START,
      exportAmountsReceivableDetailsSaga
    ),
    takeLatest(
      ExportBankDetailsTypes.EXPORT_BANK_DETAILS_START,
      exportBankDetailsSaga
    ),
    takeLatest(
      BankConciliation.EXPORT_BANK_CONCILIATION_START,
      exportBankConciliationSaga
    ),
    takeLatest(
      ExportDuedateDetailsTypes.EXPORT_DUEDATE_DETAILS_START,
      exportDuedateDetailsSaga
    ),
    takeLatest(
      ExportAdvancementDetailsTypes.EXPORT_ADVANCEMENT_DETAILS_START,
      exportAdvancementDetailsSaga
    ),
    takeLatest(
      ExportAdjustmentDetailsTypes.EXPORT_ADJUSTMENT_DETAILS_START,
      exportAdjustmentDetailsSaga
    ),
    takeLatest(
      ExportRefundDetailsTypes.EXPORT_REFUND_DETAILS_START,
      exportRefundDetailsSaga
    ),
    takeLatest(
      ExportAllClientsDetailsTypes.EXPORT_ALL_CLIENTS_DETAILS_START,
      exportAllClientsDetailsSaga
    ),
    takeLatest(
      UpdateBriefingStep.UPDATE_BRIEFING_STEP_START,
      updateBriefingStepSaga
    ),
    takeLatest(
      UpdateScreenToScreenDate.UPDATE_SCREEN_TO_SCREEN_DATE_START,
      updateScreenToScreenDateSaga
    ),
    takeLatest(
      UpdateScreenToScreenPeriod.UPDATE_SCREEN_TO_SCREEN_SALES_PERIOD_START,
      updateScreenToScreenSalesPeriodSaga
    ),
    takeLatest(
      UpdateScreenToScreenPeriod.UPDATE_SCREEN_TO_SCREEN_PAYMENT_DIVERGENCES_PERIOD_START,
      updateScreenToScreenPaymentDivergencesPeriodSaga
    ),
    takeLatest(
      UpdateScreenToScreenPeriod.UPDATE_SCREEN_TO_SCREEN_AMOUNTS_RECEIVABLE_PERIOD_START,
      updateScreenToScreenAmountsReceivablePeriodSaga
    ),
    takeLatest(
      UpdateScreenToScreenPeriod.UPDATE_SCREEN_TO_SCREEN_BANKS_PERIOD_START,
      updateScreenToScreenBanksPeriodSaga
    ),
    takeLatest(
      UpdateScreenToScreenPeriod.UPDATE_SCREEN_TO_SCREEN_DUEDATE_PERIOD_START,
      updateScreenToScreenDuedatePeriodSaga
    ),
    takeLatest(
      UpdateScreenToScreenPeriod.UPDATE_SCREEN_TO_SCREEN_ADVANCEMENTS_PERIOD_START,
      updateScreenToScreenAdvancementsPeriodSaga
    ),
    takeLatest(
      UpdateScreenToScreenPeriod.UPDATE_SCREEN_TO_SCREEN_ADJUSTMENTS_PERIOD_START,
      updateScreenToScreenAdjustmentsPeriodSaga
    ),
    takeLatest(
      UpdateScreenToScreenPeriod.UPDATE_SCREEN_TO_SCREEN_REFUNDS_PERIOD_START,
      updateScreenToScreenRefundsPeriodSaga
    ),
    takeLatest(
      UpdateScreenToScreenPeriod.UPDATE_SCREEN_TO_SCREEN_SALES_RECONCILIATION_PERIOD_START,
      updateScreenToScreenSalesReconciliationPeriodSaga
    ),
    takeLatest(
      UpdateScreenToScreenPeriod.UPDATE_SCREEN_TO_SCREEN_PAYMENTS_RECONCILIATION_PERIOD_START,
      updateScreenToScreenPaymentsReconciliationPeriodSaga
    ),
    takeLatest(
      UpdateScreenToScreenPeriod.UPDATE_SCREEN_TO_SCREEN_BANK_RECONCILIATION_PERIOD_START,
      updateScreenToScreenBankReconciliationPeriodSaga
    ),
    takeLatest(
      UpdateIsImplanted.UPDATE_IS_IMPLANTED_START,
      updateIsImplantedSaga
    ),
    takeLatest(
      UpdateFilteredColumns.UPDATE_FILTERED_COLUMNS_START,
      updateFilteredColumnsSaga
    ),
    takeLatest(
      UpdateFilteredColumns.RESET_UPDATE_FILTERED_COLUMNS_START,
      resetUpdateFilteredColumnsSaga
    ),

    takeLatest(
      FeesUpdateFilteredColumns.FEES_UPDATE_FILTERED_COLUMNS_START,
      feesUpdateFilteredColumnsSaga
    ),

    takeLatest(
      FeesUpdateFilteredColumns.FEES_RESET_UPDATE_FILTERED_COLUMNS_START,
      feesResetUpdateFilteredColumnsSaga
    ),

    takeLatest(
      CustomUpdateFilteredColumns.CUSTOM_UPDATE_FILTERED_COLUMNS_START,
      customUpdateFilteredColumnsSaga
    ),
    takeLatest(
      CustomUpdateFilteredColumns.CUSTOM_RESET_UPDATE_FILTERED_COLUMNS_START,
      customResetUpdateFilteredColumnsSaga
    ),
    // credit
    takeLatest(CreditListTypes.CREDIT_LIST_START, creditListSaga),
    takeLatest(
      CreditListAvailableTypes.CREDIT_LIST_AVAILABLE_START,
      creditListAvailableSaga
    ),
    takeLatest(
      CreditListPartnersTypes.CREDIT_LIST_PARTNERS_START,
      creditListPartnersSaga
    ),
    takeLatest(
      CreditListPartnersAvailableTypes.CREDIT_LIST_PARTNERS_AVAILABLE_START,
      creditListPartnersAvailableSaga
    ),
    takeLatest(
      CreditListPartnersOptinTypes.CREDIT_LIST_PARTNERS_OPTIN_START,
      creditListPartnersOptinSaga
    ),
    takeLatest(
      CreditPartnerOptinTypes.CREDIT_PARTNER_OPTIN_START,
      creditPartnerOptinSaga
    ),
    takeLatest(
      CreditPartnerOptoutTypes.CREDIT_PARTNER_OPTOUT_START,
      creditPartnerOptoutSaga
    ),
    takeLatest(
      CreditInteractViewTypes.CREDIT_INTERACT_VIEW_START,
      creditInteractViewSaga
    ),
    takeLatest(
      CreditInteractPreregisterTypes.CREDIT_INTERACT_PREREGISTER_START,
      creditInteractPreregisterSaga
    ),
    takeLatest(
      CreditInteractAccessTypes.CREDIT_INTERACT_ACCESS_START,
      creditInteractAccessSaga
    ),
    takeLatest(
      CreditInteractContractTypes.CREDIT_INTERACT_CONTRACT_START,
      creditInteractContractSaga
    ),
    takeLatest(
      CreditInteractRetryApprovalTypes.CREDIT_INTERACT_RETRY_APPROVAL_START,
      creditInteractRetryApprovalSaga
    ),
    takeLatest(
      CreditInteractCloseTypes.CREDIT_INTERACT_CLOSE_START,
      creditInteractCloseSaga
    ),
    takeLatest(
      LegalRepresentativeTypes.GET_LEGAL_REPRESENTATIVE_START,
      getLegalRepresentativeSaga
    ),
    takeLatest(
      LegalRepresentativeTypes.POST_LEGAL_REPRESENTATIVE_START,
      postLegalRepresentativeSaga
    ),
    takeLatest(
      SharedPartnerDataTypes.UPDATE_SHARED_PARTNER_DATA_START,
      updateSharedPartnerDataSaga
    ),
    takeLatest(
      CreditTomaticoStatusTypes.CREDIT_TOMATICO_STATUS_START,
      creditTomaticoStatusSaga
    ),
    // Radar
    takeLatest(
      RadarAnyRunningTypes.RADAR_ANY_RUNNING_START,
      radarAnyRunningSaga
    ),
    takeLatest(RadarExpireTypes.RADAR_EXPIRE_START, radarExpireSaga),
    takeLatest(RadarGetTypes.RADAR_GET_START, radarGetSaga),
    takeLatest(RadarListTypes.RADAR_LIST_START, radarListSaga),
    takeLatest(RadarRunResumeTypes.RADAR_RUN_RESUME_START, radarRunResumeSaga),
    takeLatest(RadarRunTestTypes.RADAR_RUN_TEST_START, radarRunTestSaga),
    takeLatest(RadarRunTypes.RADAR_RUN_START, radarRunSaga),
    takeLatest(
      RadarListFiltersTypes.RADAR_LIST_FILTERS_START,
      radarListFiltersSaga
    ),
    takeEvery(
      RadarDetailsFiltersTypes.RADAR_DETAILS_FILTERS_START,
      radarDetailsFiltersSaga
    ),
    takeLatest(
      SearchTransactionTypes.SEARCH_TRANSACTION_START,
      searchTransactionSaga
    ),
    takeEvery(
      SearchTransactionDetailsFiltersTypes.SEARCH_TRANSACTION_DETAILS_FILTERS_START,
      searchTransactionDetailsFiltersSaga
    ),
    // Token Sandbox
    takeLatest(
      TokenSandboxTypes.TOKEN_SANDBOX_POST_START,
      tokenSandboxPostSaga
    ),
    takeLatest(
      TokenSandboxTypes.TOKEN_SANDBOX_DELETE_START,
      tokenSandboxDeleteSaga
    ),
    takeLatest(TokenSandboxTypes.TOKEN_SANDBOX_GET_START, tokenSandboxGetSaga),
    takeLatest(TokenSandboxTypes.TOKEN_SANDBOX_PUT_START, tokenSandboxPutSaga),
    takeLatest(
      TokenSandboxTypes.TOKEN_SANDBOX_DISABLE_TOKEN_START,
      tokenSandboxDisableTokenSaga
    ),
    takeLatest(
      TokenSandboxTypes.ROW_CLIENT_ID_SANDBOX_START,
      rowClientIdSandboxSaga
    ),

    // Token Prod
    takeLatest(TokenProdTypes.TOKEN_PROD_POST_START, tokenProdPostSaga),
    takeLatest(TokenProdTypes.TOKEN_PROD_DELETE_START, tokenProdDeleteSaga),
    takeLatest(TokenProdTypes.TOKEN_PROD_GET_START, tokenProdGetSaga),
    takeLatest(TokenProdTypes.TOKEN_PROD_PUT_START, tokenProdPutSaga),
    takeLatest(
      TokenProdTypes.TOKEN_PROD_DISABLE_TOKEN_START,
      tokenProdDisableTokenSaga
    ),
    takeLatest(TokenProdTypes.ROW_CLIENT_ID_PROD_START, rowClientIdProdSaga),

    // Plans
    takeLatest(PlansTypes.PLANS_START, plansSaga),

    // Skins
    takeLatest(SkinsTypes.SKINS_START, skinsSaga),

    // Reconciliation Rules
    takeLatest(
      ListReconciliationRulesTypes.LIST_RECONCILIATION_RULES_START,
      listReconciliationRulesSaga
    ),
    takeLatest(
      DeleteReconciliationRulesTypes.DELETE_RECONCILIATION_RULES_START,
      deleteReconciliationRulesSaga
    ),
    takeLatest(
      PostReconciliationRulesTypes.POST_RECONCILIATION_RULES_START,
      postReconciliationRulesSaga
    ),
    takeLatest(
      PutReconciliationRulesTypes.PUT_RECONCILIATION_RULES_START,
      putReconciliationRulesSaga
    ),
    takeLatest(
      GetAcquirersByClientIdTypes.GET_ACQUIRERS_BY_CLIENT_ID_START,
      getAcquirersByClientIdSaga
    ),
    takeLatest(
      GetNextOrderByClientIdTypes.GET_NEXT_ORDER_BY_CLIENT_ID_START,
      getNextOrderByClientIdSaga
    ),
    takeLatest(
      GetNextOrderByClientIdTypes.RESET_NEXT_ORDER_BY_CLIENT_ID_START,
      resetNextOrderByClientIdSaga
    ),
    takeEvery(
      ReconciliationRulesDetailsFiltersTypes.RECONCILIATION_RULES_DETAILS_FILTERS_START,
      reconciliationRulesDetailsFiltersSaga
    ),
    // Retroactive Conciliation
    takeLatest(
      RetroactiveConciliationTypes.LIST_RETROACTIVE_CONCILIATION_START,
      listRetroactiveConciliationSaga
    ),
    takeLatest(
      RetroactiveConciliationTypes.POST_RETROACTIVE_CONCILIATION_START,
      postRetroactiveConciliationSaga
    ),
    // Cnpj Maintenance Stores
    takeLatest(ListStoresTypes.LIST_STORES_START, listStoresSaga),
    takeLatest(DeleteStoresTypes.DELETE_STORES_START, deleteStoresSaga),
    takeLatest(PutStoresTypes.PUT_STORES_START, putStoresSaga),
    takeLatest(UpdateStoreTypes.UPDATE_STORE_START, updateStoreSaga),

    takeLatest(
      cnpjMaintenanceInfoFiltersTypes.CNPJ_MAINTENANCE_INFO_FILTERS_START,
      cnpjMaintenanceInfoFiltersSaga
    ),

    // Cnpj Maintenance Merchants
    takeLatest(ListMerchantsTypes.LIST_MERCHANTS_START, listMerchantsSaga),
    takeLatest(
      DeleteMerchantTypes.DELETE_MERCHANT_START,
      deleteMerchantCnpjMaintenanceSaga
    ),
    takeLatest(
      ListMerchantsTypes.RESET_LIST_MERCHANTS_START,
      resetListMerchantsSaga
    ),

    // fees

    takeEvery(FeesDetailsTypes.FEES_DETAILS_START, feesDetailsSaga),

    takeEvery(
      FeesDetailsFiltersTypes.FEES_DETAILS_FILTERS_START,
      feesDetailsFiltersSaga
    ),

    takeEvery(DeleteFeeTypes.DELETE_FEE_START, deleteFeeSaga),
  ]);
}
