import { call, put } from 'redux-saga/effects';
import {
  getClientAcquirer,
  postClientAcquirer,
  deleteClientAcquirer,
} from '../../../services/clientAcquirers';
import {
  getClientAcquirerSuccess,
  getClientAcquirerError,
  postClientAcquirerError,
  postClientAcquirerSuccess,
  deleteClientAcquirerError,
  deleteClientAcquirerSuccess,
} from './actions';
import errorHandler from '../../../helpers/errorHandler';
import { notification } from 'antd';

export function* getClientAcquirerSaga() {
  try {
    const apiData = yield call(getClientAcquirer);
    yield put(getClientAcquirerSuccess(apiData));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        getClientAcquirerError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(getClientAcquirerError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}

export function* postClientAcquirerSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(postClientAcquirer, payload);
    yield put(postClientAcquirerSuccess(apiData));
    notification.success({ message: 'Adquirente incluída com sucesso !' });
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        postClientAcquirerError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(postClientAcquirerError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}

export function* deleteClientAcquirerSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(deleteClientAcquirer, payload);
    yield put(deleteClientAcquirerSuccess(apiData));
    notification.success({ message: 'Adquirente removida com sucesso !' });
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        deleteClientAcquirerError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(deleteClientAcquirerError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}
