import { action } from 'typesafe-actions';
import { actionTypes } from './types';

export const bankConciliationFiltersStart = (data) =>
  action(actionTypes.BANK_CONCILIATION_FILTERS_START, data);
export const bankConciliationFiltersSuccess = (data) =>
  action(actionTypes.BANK_CONCILIATION_FILTERS_SUCCESS, data);
export const bankConciliationFiltersError = (errorMessage, error) =>
  action(
    actionTypes.BANK_CONCILIATION_FILTERS_ERROR,
    { errorMessage },
    undefined,
    { error }
  );
export const bankConciliationFiltersZeroLength = () =>
  action(actionTypes.BANK_CONCILIATION_FILTERS_ZERO_LENGTH);
export const updateBankConciliationFilters = (data) =>
  action(actionTypes.UPDATE_BANK_CONCILIATION_FILTERS, data);
