export const actionTypes = {
  FEES_DETAILS_FILTERS_START: '@feesDetailsFilters/FEES_DETAILS_FILTERS_START',
  FEES_DETAILS_FILTERS_SUCCESS:
    '@feesDetailsFilters/FEES_DETAILS_FILTERS_SUCCESS',
  FEES_DETAILS_FILTERS_ERROR: '@feesDetailsFilters/FEES_DETAILS_FILTERS_ERROR',
  FEES_DETAILS_FILTERS_ZERO_LENGTH:
    '@feesDetailsFilters/FEES_DETAILS_FILTERS_ZERO_LENGTH',
  UPDATE_FEES_DETAILS_FILTERS:
    '@feesDetailsFilters/UPDATE_FEES_DETAILS_FILTERS',
};
