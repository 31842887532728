import { Space, Typography } from 'antd';
import styled from 'styled-components';
import { colors } from 'styles/colors';

const { Title, Text } = Typography;

export const StSpace = styled(Space)`
  border-radius: 5px;
  padding: 4rem 2rem;
  width: 400px;
  text-align: center;
`;

export const StTitle = styled(Title)`
  &&& {
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    color: ${colors.gray8};
    margin-bottom: 16px;
    font-weight: ${(props) => (props?.isBold ? '700' : '400')};
  }
`;

export const StText = styled(Text)`
  &&& {
    font-family: 'Roboto', sans-serif;
    font-weight: ${(props) => (props?.isBold ? '700' : '400')};
    color: ${colors.gray8};
    font-size: 14px;
  }
`;

export const StContainer = styled.div`
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 380px;
  border-radius: 5px;
  padding: 4rem 2rem;
  text-align: center;
  &&& {
    font-family: 'Roboto', sans-serif;
  }
`;
