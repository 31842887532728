import { action } from 'typesafe-actions';
import { actionTypes } from 'store/ducks/credit/sharedPartnerData/types';

export const updateSharedPartnerDataStart = (data) =>
  action(actionTypes.UPDATE_SHARED_PARTNER_DATA_START, data);
export const updateSharedPartnerDataSuccess = (data) =>
  action(actionTypes.UPDATE_SHARED_PARTNER_DATA_SUCCESS, data);
export const updateSharedPartnerDataError = (errorMessage, error) =>
  action(
    actionTypes.UPDATE_SHARED_PARTNER_DATA_ERROR,
    { errorMessage },
    undefined,
    {
      error,
    }
  );
