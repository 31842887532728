import { call, put } from 'redux-saga/effects';
import errorHandler from 'helpers/errorHandler';
import { errorToShow } from 'store/ducks/errorToShow/actions';
import {
  refundsPaymentsIntermediateSuccess,
  refundsPaymentsIntermediateError,
} from './actions';
import getRefundsPaymentsIntermediate from '../../../../services/paymentsIntermediate/refundsPaymentsIntermediate';

export function* refundsPaymentsIntermediateSaga(actions) {
  try {
    const apiData = yield call(getRefundsPaymentsIntermediate, actions.payload);
    yield put(refundsPaymentsIntermediateSuccess(apiData));
  } catch (err) {
    if (
      err?.response?.data?.error &&
      err?.response?.data?.error?.type &&
      err?.response?.data?.error?.message
    ) {
      yield put(
        errorToShow({
          message: err.response.data.error.message,
          type: err.response.data.error.type,
        })
      );
      yield put(
        refundsPaymentsIntermediateError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    }
  }
}
