import { actionTypes } from './types';

const INITIAL_STATE = {
  error: undefined,
  errorMessage: undefined,
  loading: false,
  isError: false,
  stores: [],
  deleting: false,
  deleted: false,
  storeHasBeenPosted: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_STORES_START:
    case actionTypes.POST_STORE_START:
      return {
        ...state,
        loading: true,
        error: undefined,
        errorMessage: undefined,
        isError: false,
        deleting: false,
        deleted: false,
        storeHasBeenPosted: false,
      };
    case actionTypes.GET_STORES_ERROR:
    case actionTypes.PUT_STORE_ERROR:
    case actionTypes.DELETE_STORE_START:
      return {
        ...state,
        loading: true,
        error: undefined,
        errorMessage: undefined,
        isError: false,
        deleting: true,
        deleted: false,
        storeHasBeenPosted: false,
      };
    case actionTypes.DELETE_STORE_ERROR:
    case actionTypes.POST_STORE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error.error,
        errorMessage: action.payload,
        isError: true,
        deleting: false,
        deleted: false,
      };
    case actionTypes.GET_STORES_SUCCESS:
      return {
        ...state,
        loading: false,
        stores: action.payload,
        deleting: false,
        deleted: false,
      };
    case actionTypes.POST_STORE_SUCCESS:
    case actionTypes.PUT_STORE_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
        storeHasBeenPosted: true,
      };
    case actionTypes.DELETE_STORE_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
        deleting: false,
        deleted: true,
      };
    default:
      return state;
  }
};

export default reducer;
