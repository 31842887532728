import styled from 'styled-components';
import { Typography } from 'antd';
import { colors } from 'styles/colors';

export const StTitle = styled(Typography.Text)`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  align-items: center;
  text-align: center;
  color: ${colors.gray8};
`;

export const StText = styled(Typography.Text)`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  align-items: center;
  text-align: center;
  color: ${colors.gray8};
`;
