import React from 'react';
import Actions from './Actions';
import { shortId } from 'helpers/shortId';

export const columns = () => [
  {
    title: 'CNPJ',
    dataIndex: 'br_cnpj',
    key: shortId(),
    width: 200,
  },
  {
    title: 'Razão Social',
    dataIndex: 'name',
    key: shortId(),
    width: 200,
  },
  {
    title: 'Apelido da Loja',
    dataIndex: 'short_name',
    key: shortId(),
    width: 200,
  },
  {
    title: 'Estabelecimento',
    dataIndex: 'merchant_code',
    key: shortId(),
    width: 200,
  },
  {
    title: 'Data de inclusão',
    dataIndex: 'create_datetime',
    key: shortId(),
    render: (_, record) => {
      return record.create_datetime;
    },
    width: 200,
  },
  {
    title: 'Status da autorização',
    dataIndex: 'status_authorization',
    key: shortId(),
    render: (_, record) => {
      return record.status_authorization;
    },
    width: 200,
  },
  {
    title: 'Ações',
    dataIndex: 'action',
    key: shortId(),
    render: (_, record) => {
      return <Actions currentRow={record} />;
    },
    width: 50,
    align: 'center',
  },
];
