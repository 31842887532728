import { call, put } from 'redux-saga/effects';

import errorHandler from 'helpers/errorHandler';

import {
  bankSummaryReconciledSuccess,
  bankSummaryReconciledError,
} from 'store/ducks/reconciled/summary/bank/actions';
import getBankSummary from 'services/reconciled/summary/bank';

export function* bankSummaryReconciledSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(getBankSummary, payload);

    yield put(bankSummaryReconciledSuccess(apiData));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        bankSummaryReconciledError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(bankSummaryReconciledError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}
