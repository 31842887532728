import { call, put } from 'redux-saga/effects';

import errorHandler from 'helpers/errorHandler';

import {
  creditListPartnersOptinSuccess,
  creditListPartnersOptinError,
} from 'store/ducks/credit/creditListPartnersOptin/actions';

import getCreditListPartnersOptinFromAPI from 'services/credit/listPartnersOptin';

export function* creditListPartnersOptinSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(getCreditListPartnersOptinFromAPI, payload);
    yield put(creditListPartnersOptinSuccess(apiData));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        creditListPartnersOptinError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(creditListPartnersOptinError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}
