import styled from 'styled-components';
import { colors } from 'styles/colors';

export const AcquirerImage = styled.img`
  width: 50px;
  height: 50px;
  /* margin: -10px; */
`;

export const AcquirerNameLabel = styled.label`
  display: block;
  text-align: center;
  font-size: 1.5rem;
  margin-top: 10px;
  text-transform: capitalize;
  align-items: center;
  color: ${colors.gray8};
  font-weight: 400;
`;

export const AcquirerCheckbox = styled.div`
  width: 100%;
  height: 20%;
  text-align: end;
`;

export const AcquirerCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 104px;
  height: 104px;
  border-radius: 5px;
  border: 1px solid
    ${(props) => (props.disabled ? '#eee' : props.checked ? colors.primary6 : '#ccc')};
  margin-right: 10px;
  margin-bottom: 16px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  ${AcquirerImage} {
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    filter: ${(props) => (props.disabled ? 'gray' : 'none')};
    -webkit-filter: ${(props) => (props.disabled ? 'grayscale(1)' : 'none')};
    filter: ${(props) => (props.disabled ? 'grayscale(1)' : 'none')};
  }
  ${AcquirerNameLabel} {
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    color: ${(props) => (props.disabled ? '#ddd' : colors.gray8)};
  }
`;
