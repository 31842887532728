import { call, put } from 'redux-saga/effects';
import errorHandler from 'helpers/errorHandler';
import {
  getAcquirerHasPointOfSale,
  getAcquirerList,
  getAcquirerMerchants,
  getAcquirerVoucher,
} from 'services/acquirer';
import { errorToShow } from 'store/ducks/errorToShow/actions';

import {
  acquirerSuccess,
  acquirerError,
  acquirerPointOfSaleSuccess,
  acquirerPointOfSaleError,
  acquirerVoucherSuccess,
  acquirerVoucherError,
  acquirerMerchantsSuccess,
  acquirerMerchantsError,
} from './actions';

export function* acquirerSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(getAcquirerList, payload);
    yield put(acquirerSuccess(apiData));
  } catch (err) {
    if (
      err?.response?.data?.error &&
      err?.response?.data?.error?.type &&
      err?.response?.data?.error?.message
    ) {
      yield put(
        errorToShow({
          message: err.response.data.error.message,
          type: err.response.data.error.type,
        })
      );
      yield put(
        acquirerError(err.response.data.error.message, err.response.data.error)
      );
      errorHandler(err?.response?.data?.error?.message, err);
    }
  }
}
export function* acquirerAllSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(getAcquirerList, payload);
    yield put(acquirerSuccess(apiData));
  } catch (err) {
    if (
      err?.response?.data?.error &&
      err?.response?.data?.error?.type &&
      err?.response?.data?.error?.message
    ) {
      yield put(
        errorToShow({
          message: err.response.data.error.message,
          type: err.response.data.error.type,
        })
      );
      yield put(
        acquirerError(err.response.data.error.message, err.response.data.error)
      );
      errorHandler(err?.response?.data?.error?.message, err);
    }
  }
}

export function* acquirerPointOfSaleSaga() {
  try {
    const apiData = yield call(getAcquirerHasPointOfSale);
    yield put(acquirerPointOfSaleSuccess(apiData));
  } catch (err) {
    if (
      err?.response?.data?.error &&
      err?.response?.data?.error?.type &&
      err?.response?.data?.error?.message
    ) {
      yield put(
        errorToShow({
          message: err.response.data.error.message,
          type: err.response.data.error.type,
        })
      );
      yield put(
        acquirerPointOfSaleError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    }
  }
}

export function* acquirerVoucherSaga() {
  try {
    const apiData = yield call(getAcquirerVoucher);
    yield put(acquirerVoucherSuccess(apiData));
  } catch (err) {
    if (
      err?.response?.data?.error &&
      err?.response?.data?.error?.type &&
      err?.response?.data?.error?.message
    ) {
      yield put(
        errorToShow({
          message: err.response.data.error.message,
          type: err.response.data.error.type,
        })
      );
      yield put(
        acquirerVoucherError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    }
  }
}

export function* acquirerMerchantsSaga() {
  try {
    const apiData = yield call(getAcquirerMerchants);
    yield put(acquirerMerchantsSuccess(apiData));
  } catch (err) {
    if (
      err?.response?.data?.error &&
      err?.response?.data?.error?.type &&
      err?.response?.data?.error?.message
    ) {
      yield put(
        errorToShow({
          message: err.response.data.error.message,
          type: err.response.data.error.type,
        })
      );
      yield put(
        acquirerMerchantsError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    }
  }
}
