/* eslint-disable no-plusplus */
import { api } from '../../api';

const transform = (response, columnName) => {
  const filters = {
    results: [
      {
        text: columnName,
        values: response?.data?.results,
      },
    ],
    hasMore: response?.meta?.has_next,
    offset: response?.meta?.offset,
    limit: response?.meta?.limit,
    column: response?.meta?.column,
  };
  return filters;
};

const getAdjustmentDetailsFiltersFromAPI = async ({
  startDate,
  endDate,
  columnName,
  offset,
  searchBy,
  q,
}) => {
  const config = {
    params: {
      start_date: startDate.format('YYYY-MM-DD'),
      end_date: endDate.format('YYYY-MM-DD'),
      column: columnName,
      limit: 10,
      offset,
      type: 'detail',
      search_by: searchBy,
      q: JSON.stringify(q),
    },
  };
  const response = await api().get('payment/adjustment/detail/filters', config);
  const transformedResponse = transform(response?.data, columnName);
  return transformedResponse;
};

export default getAdjustmentDetailsFiltersFromAPI;
