export const parseListOfClientsFiltersToWeb = (values, columnName) => {
  const columnsThatNeedsParsing = {
    is_implanted: (v) => (v === true ? 'Produção' : 'Implantação'),
    is_api_connected: (v) => (v === true ? 'Sim' : '-'),
    is_sandbox_api_active: (v) => (v === true ? 'Ativo' : 'Inativo'),
    is_production_api_active: (v) => (v === true ? 'Ativo' : 'Inativo'),
  };

  const columnFunction = columnsThatNeedsParsing[columnName] || ((v) => v);
  const finalValues = values.map(columnFunction);
  return finalValues;
};

export const parseListOfClientsToWeb = (clients) =>
  clients?.map(
    ({
      is_implanted: isImplanted,
      is_api_connected: isApiConnected,
      is_production_api_active: isProductionApiActive,
      is_sandbox_api_active: isSandboxApiActive,
      ...client
    }) => {
      return {
        ...client,
        is_implanted: isImplanted ? 'Produção' : 'Implantação',
        is_api_connected: isApiConnected ? 'Sim' : '-',
        is_production_api_active: isProductionApiActive ? 'Ativo' : 'Inativo',
        is_sandbox_api_active: isSandboxApiActive ? 'Ativo' : 'Inativo',
      };
    }
  );

export const parseListOfClientsQToAPI = (q) => {
  const columnsThatNeedsParsing = {
    is_implanted: (v) => {
      return v.map((_v) => _v === 'Produção');
    },
    is_api_connected: (v) => {
      return v.map((_v) => _v === 'Sim');
    },
    is_sandbox_api_active: (v) => {
      return v.map((_v) => _v === 'Ativo');
    },
    is_production_api_active: (v) => {
      return v.map((_v) => _v === 'Ativo');
    },
  };

  if (!Array.isArray(q) && q?.filter && q?.filter.length > 0) {
    return q?.filter?.map((qValues) => {
      if (columnsThatNeedsParsing[qValues.name]) {
        const parsedValue = {
          name: qValues.name,
          val: columnsThatNeedsParsing[qValues.name](qValues.val),
        };
        return parsedValue;
      }
      return qValues;
    });
  }
  return [];
};
