import { colors } from 'styles/colors';

export const hasUppercase = (value) => {
  return /(?=.*[A-Z])/.test(value);
};

export const hasLowercase = (value) => {
  return /(?=.*[a-z])/.test(value);
};

export const hasOneNumber = (value) => {
  return /(?=.*[0-9])/.test(value);
};

export const hasSpecialChar = (value) => {
  return /(?=.*[!@#$&*])/.test(value);
};

export const handleColor = (
  _validNumOfChars,
  _validUppercase,
  _validLowercase,
  _validOneNumber,
  _validSpecialChar
) => {
  if (_validNumOfChars?.length >= 8) {
    return colors.green6;
  }
  if (_validUppercase?.length > 0 && hasUppercase(_validUppercase)) {
    return colors.green6;
  }
  if (_validLowercase?.length > 0 && hasLowercase(_validLowercase)) {
    return colors.green6;
  }
  if (_validOneNumber?.length > 0 && hasOneNumber(_validOneNumber)) {
    return colors.green6;
  }
  if (_validSpecialChar?.length > 0 && hasSpecialChar(_validSpecialChar)) {
    return colors.green6;
  }
  if (
    (_validNumOfChars?.length > 0 && !hasOneNumber(_validNumOfChars)) ||
    (_validUppercase?.length > 0 && !hasUppercase(_validUppercase)) ||
    (_validLowercase?.length > 0 && !hasLowercase(_validLowercase)) ||
    (_validOneNumber?.length > 0 && !hasOneNumber(_validOneNumber)) ||
    (_validSpecialChar?.length > 0 && !hasSpecialChar(_validSpecialChar))
  ) {
    return colors.red5;
  }
  return colors.gray8;
};
