export const actionTypes = {
  SEARCH_TRANSACTION_DETAILS_FILTERS_START:
    '@searchTransactionDetailsFilters/SEARCH_TRANSACTION_DETAILS_FILTERS_START',
  SEARCH_TRANSACTION_DETAILS_FILTERS_SUCCESS:
    '@searchTransactionDetailsFilters/SEARCH_TRANSACTION_DETAILS_FILTERS_SUCCESS',
  SEARCH_TRANSACTION_DETAILS_FILTERS_ERROR:
    '@searchTransactionDetailsFilters/SEARCH_TRANSACTION_DETAILS_FILTERS_ERROR',
  SEARCH_TRANSACTION_DETAILS_FILTERS_ZERO_LENGTH:
    '@searchTransactionDetailsFilters/SEARCH_TRANSACTION_DETAILS_FILTERS_ZERO_LENGTH',
  UPDATE_SEARCH_TRANSACTION_DETAILS_FILTERS:
    '@searchTransactionDetailsFilters/UPDATE_SEARCH_TRANSACTION_DETAILS_FILTERS',
};
