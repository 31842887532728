import { call, put } from 'redux-saga/effects';
import {
  listMerchantsSuccess,
  listMerchantsError,
  resetListMerchantsSuccess,
  resetListMerchantsError,
} from 'store/ducks/cnpjMaintenance/merchants/listMerchants/actions';
import listMerchantByAcquirer from 'services/cnpjMaintenance/listMerchantByAcquirer';
import errorHandler from 'helpers/errorHandler';

export function* listMerchantsSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(listMerchantByAcquirer, payload);
    yield put(listMerchantsSuccess(apiData));
  } catch (err) {
    yield put(listMerchantsError('Ocorreu um erro:', err));
  }
}

export function* resetListMerchantsSaga(action) {
  try {
    const { payload } = action;
    yield put(resetListMerchantsSuccess(payload));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        resetListMerchantsError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(resetListMerchantsError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}
