import styled from 'styled-components';
import { colors } from 'styles/colors';
import { TrophyFilled } from '@ant-design/icons';

export const StContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 20px;
  border-radius: 4px;
  font-size: 16px;
  color: ${({ color }) => color};
  background-color: ${({ backgroundColor }) => backgroundColor};
  box-shadow: 0px 3px 6px 0px #0000000a;
`;

export const StGreetings = styled.span`
  font-weight: 600;
  margin-right: 4px;
  color: ${({ color }) => color};
`;

export const StPlanContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`;

export const StCurrentPlan = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
`;

export const StPlan = styled.div`
  margin-left: 6px;
  padding: 2px 8px;
  background-color: ${colors.gray1};
  border: 1px solid ${({ borderColor }) => borderColor};
  border-radius: 12px;
`;

export const StPlanLabel = styled.span`
  color: ${colors.gray8};
`;

export const StPlanName = styled.span`
  margin-left: 4px;
  color: ${({ color }) => color};
`;

export const StPlansCompare = styled.span`
  display: flex;
  align-items: center;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
`;

export const StCongratulationsContainer = styled.div`
  margin-top: 8px;
  font-size: 12px;
  font-weight: 500;
`;

export const StCongratulations = styled.span`
  font-weight: 800;
  margin-right: 4px;
`;

export const StTrophyFilled = styled(TrophyFilled)`
  margin-left: 5px;
`;
