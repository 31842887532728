import React, { useContext, useEffect, useState } from 'react';
import { Form, Popconfirm, Row, Spin, notification } from 'antd';
import { FeeEditableCell } from './FeeEditableCell';
import { feesColumns } from './columns';
import { FeesContext } from 'components/Fees/Fees';
import { TableFooter } from '../TableFooter';
import { useInfiniteScrollPageChange } from 'utils/hooks/useInfiniteScrollPageChange';
import { useDispatch, useSelector } from 'react-redux';
import { generateFilterDataStructure } from 'helpers/tableService';
import { updateFeesDetailsFilters } from 'store/ducks/filters/fees/actions';
import { onColumnSort } from 'helpers/sorter';
import useFeesDetail from 'components/Fees/hooks/useFeesDetail';
import updateFee from 'services/fees/updateFee';
import errorHandler from 'helpers/errorHandler';
import dayjs from 'dayjs';
import { LoadingOutlined } from '@ant-design/icons';
import {
  StActionButtonsContainer,
  StButtonCancelEdition,
  StButtonSaveEdition,
  StDeleteOutlined,
  StEditOutlined,
  StTable,
} from './styled';

const FeesTable = ({ type = 'VIGENTES' }) => {
  const dispatch = useDispatch();
  const { currentActivePanel, selectedStore } = useContext(FeesContext);
  const [sortFields, setSortFields] = useState({});
  const [page, setPage] = useState(1);
  const perPage = 10;

  const apiFilters = useSelector((state) => state.feesDetailsFilters?.filters);
  const toParamsFilters = useSelector(
    (state) => state.feesDetailsFilters?.toParamsFilters
  );

  const { isLoading, fees, metaData, onDeleteFee } = useFeesDetail({
    page,
    setPage,
    perPage,
    currentActivePanel,
    type,
    selectedStore,
    toParamsFilters,
    sortFields,
  });

  useEffect(() => {
    if (toParamsFilters) {
      setPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toParamsFilters]);

  const { total: feesTotal, has_next: hasMoreFees } = metaData;

  const { lastElementRef } = useInfiniteScrollPageChange({
    loading: isLoading,
    hasMore: hasMoreFees,
    setPage,
  });

  const [feesCount, setFeesCount] = useState(0);

  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [editingFee, setEditingFee] = useState('');
  const [isSaveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [isUpdateLoading, setUpdateLoading] = useState(false);

  const isEditingFee = (feeId) => feeId === editingFee;

  const isDisableAction = (feeId, isProcessing) =>
    !isProcessing && (isEditingFee(feeId) || editingFee === '');

  const onEditClick = (record) => () => {
    form.setFieldsValue({
      ...record,
      fee_percent_fmt: record?.fee_percent.toString(),
      end_date: dayjs(record?.end_date, 'DD/MM/YYYY'),
    });
    setSaveButtonDisabled(true);
    setEditingFee(record.fee_id);
  };

  const onCancelEdition = () => setEditingFee('');

  const [endDateHasChange, setEndDateHasChange] = useState(false);
  const [feePercentHasChange, setFeePercentHasChange] = useState(false);
  const [isInvalidValue, setIsInvalidValue] = useState(false);

  const onFormChange = () => {
    const { end_date: endDate, fee_percent_fmt: feePercentFmt } =
      form.getFieldsValue();

    if (
      endDate === '' ||
      endDate === null ||
      feePercentFmt === '' ||
      feePercentFmt === null ||
      isInvalidValue
    ) {
      setSaveButtonDisabled(true);
      return;
    }

    if (endDateHasChange || feePercentHasChange) {
      setSaveButtonDisabled(false);
    } else {
      setSaveButtonDisabled(true);
    }
  };

  const onSaveEdition = async (feeId) => {
    const payload = await form.validateFields();
    const newData = [...data];
    const index = newData.findIndex((item) => feeId === item.fee_id);

    if (index < 0) {
      newData.push(payload);
      setData(newData);
      setEditingFee('');
      return;
    }

    const item = newData[index];

    const feePercentDecimalsFormatted = Number(
      payload?.fee_percent_fmt.replace(',', '.') || 0
    ).toFixed(2);

    const feePercentFormatted = feePercentDecimalsFormatted.replace('.', ',');

    newData.splice(index, 1, {
      ...item,
      ...payload,
      end_date: dayjs(payload?.end_date).format('DD/MM/YYYY'),
      fee_percent: feePercentFormatted,
      fee_percent_fmt: `${feePercentFormatted}%`,
      is_processing: true,
    });

    const dataParsed = {
      fee_id: feeId,
      fee_percent: Number(feePercentDecimalsFormatted),
      end_date: dayjs(payload.end_date).format('YYYY-MM-DD'),
    };

    setUpdateLoading(true);
    await updateFee(dataParsed)
      .then((response) => {
        const { message = 'Solicitação de alteração realizada com sucesso' } =
          response?.data;
        notification.success({ message });
        setData(newData);
      })
      .catch(({ response = {} }) => {
        const { code, message } = response?.data?.data;
        if (code === 409) {
          return notification.warning({
            message: 'Conflito de taxas',
            description: message,
          });
        }
        return errorHandler(
          message || 'Houve um problema ao realizar a solicitação de alteração'
        );
      })
      .finally(() => {
        setUpdateLoading(false);
        setEditingFee('');
      });
  };

  const onTableFilterOrSorterChange = (_, tableFilters, sorter) => {
    const filterDataToDispatch = generateFilterDataStructure(tableFilters);

    onColumnSort(sorter, setSortFields);
    dispatch(updateFeesDetailsFilters(filterDataToDispatch));
  };

  const FeeBeingDeleted = useSelector((state) => state.deleteFee?.feeLoading);

  const isFeeDeleteLoading = (FeeArray, _FeeId) => FeeArray.includes(_FeeId);

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  const columns = [
    ...feesColumns({
      selectedStore,
      currentActivePanel,
      type,
      apiFilters,
      toParamsFilters,
    }),
    {
      className: 'fee-table-cell-actions',
      title: 'Ações',
      key: 'operation',
      dataIndex: 'operation',
      fixed: 'right',
      width: 100,
      align: 'center',
      render: (_, record) => {
        const { fee_id: feeId, is_processing: isProcessing } = record || {};
        const editable = isEditingFee(feeId);
        return editable ? (
          isUpdateLoading ? (
            <Spin
              indicator={antIcon}
              size="small"
              style={{ display: 'flex', justifyContent: 'center' }}
            />
          ) : (
            <StActionButtonsContainer>
              {isUpdateLoading || isSaveButtonDisabled ? (
                <StButtonSaveEdition
                  type="link"
                  disabled={isUpdateLoading || isSaveButtonDisabled}
                >
                  <span>Salvar</span>
                </StButtonSaveEdition>
              ) : (
                <Popconfirm
                  title="Deseja salvar as alterações?"
                  okText="Sim"
                  cancelText="Não"
                  placement="topRight"
                  disabled={isUpdateLoading}
                  onConfirm={() => onSaveEdition(feeId)}
                >
                  <StButtonSaveEdition type="link">
                    <span>Salvar</span>
                  </StButtonSaveEdition>
                </Popconfirm>
              )}

              {isUpdateLoading || isSaveButtonDisabled ? (
                <StButtonCancelEdition
                  type="link"
                  danger
                  disabled={isUpdateLoading}
                  onClick={onCancelEdition}
                >
                  Cancelar
                </StButtonCancelEdition>
              ) : (
                <Popconfirm
                  title="Deseja cancelar as alterações?"
                  okText="Sim"
                  cancelText="Não"
                  placement="topRight"
                  disabled={isUpdateLoading}
                  onConfirm={onCancelEdition}
                >
                  <StButtonCancelEdition
                    type="link"
                    danger
                    disabled={isUpdateLoading}
                  >
                    Cancelar
                  </StButtonCancelEdition>
                </Popconfirm>
              )}
            </StActionButtonsContainer>
          )
        ) : isFeeDeleteLoading(FeeBeingDeleted, feeId) ? (
          <Spin
            indicator={antIcon}
            size="small"
            style={{ display: 'flex', justifyContent: 'center' }}
          />
        ) : (
          <Row style={{ display: 'flex', justifyContent: 'center' }}>
            <StEditOutlined
              disabled={isProcessing || editingFee !== ''}
              onClick={
                isDisableAction(feeId, isProcessing)
                  ? onEditClick(record)
                  : undefined
              }
            />
            {isDisableAction(feeId, isProcessing) ? (
              <Popconfirm
                title="Tem certeza que deseja excluir a taxa?"
                okText="Sim"
                cancelText="Não"
                placement="topRight"
                disabled={isProcessing}
                onConfirm={() => onDeleteFee(feeId)}
              >
                <StDeleteOutlined />
              </Popconfirm>
            ) : (
              <StDeleteOutlined disabled />
            )}
          </Row>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'end_date' ? 'string' : 'number',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditingFee(record?.fee_id),
        setSaveButtonDisabled,
        isUpdateLoading,
        setEndDateHasChange,
        setFeePercentHasChange,
        setIsInvalidValue,
      }),
    };
  });

  const dataSource = fees.map((fee) => ({
    ...fee,
    key: fee.fee_id,
  }));

  useEffect(() => {
    setData(fees);
    setFeesCount(fees?.length);
  }, [fees]);

  return (
    <Form form={form} component={false} onFieldsChange={onFormChange}>
      <StTable
        components={{
          body: {
            cell: FeeEditableCell,
          },
        }}
        dataSource={dataSource || []}
        columns={mergedColumns}
        rowClassName="editable-row"
        scroll={data?.length >= 10 ? { x: true, y: 450 } : { x: true }}
        pagination={false}
        loading={isLoading}
        onRow={(_, index) => {
          return {
            ref: data?.length === index + 1 ? lastElementRef : null,
          };
        }}
        onChange={onTableFilterOrSorterChange}
      />
      <TableFooter
        itemCounter={feesCount}
        totalCount={feesTotal}
        isLoadingItems={isLoading}
      />
    </Form>
  );
};

export default FeesTable;
