import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Skeleton, Tabs } from 'antd';
import { SummaryCard, ContentCards } from '../index';
import { StLimiter } from './styled';
import { marginMainContainer } from 'helpers/style';
import { colors } from 'styles/colors';

const ContentTab = ({
  titleSummaryCard,
  summaryData,
  error,
  summaryLoading,
  detailRoute,
  infoData,
  infoLoading,
  activeTab,
  updateAction,
  screen,
}) => {
  return (
    <>
      <SummaryCard
        title={titleSummaryCard}
        data={summaryData}
        error={error}
        loading={summaryLoading}
        detailRoute={detailRoute}
        screen={screen}
      />
      <br />
      <Skeleton loading={infoLoading} active paragraph={{ rows: 10 }}>
        <ContentCards
          data={infoData}
          loading={infoLoading}
          error={error}
          detailRoute={detailRoute}
          activeTab={activeTab}
          updateAction={updateAction}
        />
      </Skeleton>
    </>
  );
};

const ContentTabs = ({
  titleSummaryCard,
  summaryData,
  error,
  summaryLoading,
  detailRoute,
  infoData,
  infoLoading,
  onTabClick,
  activeTab,
  updateAction,
  screen,
}) => {
  const { useBreakpoint } = Grid;
  const breakpoint = useBreakpoint();

  const items = [
    {
      label: 'Filiais',
      key: 'branches',
      children: (
        <StLimiter $breakpoint={breakpoint}>
          {activeTab === 'branches' && (
            <ContentTab
              titleSummaryCard={titleSummaryCard}
              summaryData={summaryData}
              error={error}
              summaryLoading={summaryLoading}
              detailRoute={detailRoute}
              infoData={infoData}
              infoLoading={infoLoading}
              activeTab={activeTab}
              updateAction={updateAction}
              screen={screen}
            />
          )}
        </StLimiter>
      ),
    },
    {
      label: 'Adquirentes',
      key: 'acquirers',
      children: (
        <StLimiter $breakpoint={breakpoint}>
          {activeTab === 'acquirers' && (
            <ContentTab
              titleSummaryCard={titleSummaryCard}
              summaryData={summaryData}
              error={error}
              summaryLoading={summaryLoading}
              detailRoute={detailRoute}
              infoData={infoData}
              infoLoading={infoLoading}
              activeTab={activeTab}
              updateAction={updateAction}
              screen={screen}
            />
          )}
        </StLimiter>
      ),
    },
    {
      label: 'Produtos',
      key: 'products',
      children: (
        <StLimiter $breakpoint={breakpoint}>
          {activeTab === 'products' && (
            <ContentTab
              titleSummaryCard={titleSummaryCard}
              summaryData={summaryData}
              error={error}
              summaryLoading={summaryLoading}
              detailRoute={detailRoute}
              infoData={infoData}
              infoLoading={infoLoading}
              activeTab={activeTab}
              updateAction={updateAction}
              screen={screen}
            />
          )}
        </StLimiter>
      ),
    },
    {
      label: 'Bandeiras',
      key: 'brands',
      children: (
        <StLimiter $breakpoint={breakpoint}>
          {activeTab === 'brands' && (
            <ContentTab
              titleSummaryCard={titleSummaryCard}
              summaryData={summaryData}
              error={error}
              summaryLoading={summaryLoading}
              detailRoute={detailRoute}
              infoData={infoData}
              infoLoading={infoLoading}
              activeTab={activeTab}
              updateAction={updateAction}
              screen={screen}
            />
          )}
        </StLimiter>
      ),
    },
  ];

  return (
    <div>
      <Tabs
        items={items}
        onTabClick={onTabClick}
        tabBarStyle={{
          backgroundColor: colors.gray1,
          marginTop: '160px',
          padding: marginMainContainer(breakpoint),
          fontSize: '12px',
          position: 'fixed',
          zIndex: '90',
          width: '100%',
        }}
      />
    </div>
  );
};

ContentTabs.defaultProps = {
  error: {
    has: false,
    title: '',
    reason: '',
    recommendation: '',
  },
};
ContentTabs.propTypes = {
  infoData: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          value: PropTypes.string,
        })
      ),
    }).isRequired
  ).isRequired,
  summaryData: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  onTabClick: PropTypes.func.isRequired,
  infoLoading: PropTypes.bool.isRequired,
  activeTab: PropTypes.string.isRequired,
  updateAction: PropTypes.func.isRequired,
  error: PropTypes.shape({
    has: PropTypes.bool,
    title: PropTypes.string,
    reason: PropTypes.string,
    recommendation: PropTypes.string,
  }),
  summaryLoading: PropTypes.bool.isRequired,
  detailRoute: PropTypes.string.isRequired,
  titleSummaryCard: PropTypes.string.isRequired,
};

export default ContentTabs;
