import { actionTypes } from './types';

const INITIAL_STATE = {
  loading: false,
  isError: false,
  data: undefined,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case actionTypes.GET_CEP_START:
    return {
      ...state,
      loading: true,
      isError: false,
    };
  case actionTypes.GET_CEP_ERROR:
    return {
      ...state,
      loading: false,
      isError: true,
    };
  case actionTypes.GET_CEP_SUCCESS:
    return {
      ...state,
      loading: false,
      data: action.payload,
    };
  default:
    return state;
  }
};

export default reducer;
