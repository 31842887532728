import { Button } from 'antd';
import styled from 'styled-components';
import { colors } from 'styles/colors';

export const StUserMenuButtonContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  background-color: ${({ toggleMenuRight }) =>
    toggleMenuRight ? colors.primary7 : 'transparent'};
  padding: 0 4px;
`;

export const StMenuButton = styled(Button)`
  &&& {
    border: none;
    height: 100%;
    color: ${colors.gray1};
  }
`;
