import { action } from 'typesafe-actions';
import { actionTypes } from './types';

export const contractFeeFiltersStart = (data) =>
  action(actionTypes.CONTRACT_FEE_FILTERS_START, data);
export const contractFeeFiltersSuccess = (data) =>
  action(actionTypes.CONTRACT_FEE_FILTERS_SUCCESS, data);
export const contractFeeFiltersError = (errorMessage, error) =>
  action(actionTypes.CONTRACT_FEE_FILTERS_ERROR, { errorMessage }, undefined, {
    error,
  });
export const contractFeeFiltersZeroLength = () =>
  action(actionTypes.CONTRACT_FEE_FILTERS_ZERO_LENGTH);
export const updateContractFeeFilters = (data) =>
  action(actionTypes.UPDATE_CONTRACT_FEE_FILTERS, data);
