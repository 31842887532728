export const actionTypes = {
  RECONCILIATION_RULES_DETAILS_FILTERS_START:
    '@reconciliationRulesDetailsFilters/RECONCILIATION_RULES_DETAILS_FILTERS_START',
  RECONCILIATION_RULES_DETAILS_FILTERS_SUCCESS:
    '@reconciliationRulesDetailsFilters/RECONCILIATION_RULES_DETAILS_FILTERS_SUCCESS',
  RECONCILIATION_RULES_DETAILS_FILTERS_ERROR:
    '@reconciliationRulesDetailsFilters/RECONCILIATION_RULES_DETAILS_FILTERS_ERROR',
  RECONCILIATION_RULES_DETAILS_FILTERS_ZERO_LENGTH:
    '@reconciliationRulesDetailsFilters/RECONCILIATION_RULES_DETAILS_FILTERS_ZERO_LENGTH',
  UPDATE_RECONCILIATION_RULES_DETAILS_FILTERS:
    '@reconciliationRulesDetailsFilters/UPDATE_RECONCILIATION_RULES_DETAILS_FILTERS',
};
