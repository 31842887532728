import React from 'react';
import * as St from './styled';

const CardContent = ({
  reconciliationType,
  summaryReconciledData,
  context,
  chooseWhichScreenToGo,
  reconLeftColDescription,
  reconRightColDescription,
}) => {
  const MAX_BAR_HEIGHT =
    reconciliationType === 'bank'
      ? 200
      : reconciliationType === 'payments'
      ? 100
      : 65;

  const lowestTotal = Math.min(
    summaryReconciledData?.[context?.left]?.rawTotalAmount,
    summaryReconciledData?.[context?.right]?.rawTotalAmount
  );

  const highestTotal = Math.max(
    summaryReconciledData?.[context?.left]?.rawTotalAmount,
    summaryReconciledData?.[context?.right]?.rawTotalAmount
  );

  const handleReconBarHeight = (contextSide) => {
    if (lowestTotal > 0 || highestTotal > 0) {
      if (
        highestTotal ===
        summaryReconciledData?.[context?.[contextSide]]?.rawTotalAmount
      ) {
        return MAX_BAR_HEIGHT;
      }
      return (MAX_BAR_HEIGHT * lowestTotal) / highestTotal;
    }

    return 0;
  };

  return (
    <St.Container
      reconType={reconciliationType}
      highestTotal={highestTotal}
      lowestTotal={lowestTotal}
    >
      {/* Col 1 */}
      <St.BorderColumn
        textAlign="right"
        reconType={reconciliationType}
        highestTotal={highestTotal}
        lowestTotal={lowestTotal}
      >
        {reconciliationType !== 'bank' && (
          <>
            <St.DescriptionTitle>
              {`Conciliado (${
                summaryReconciledData?.[context?.left]?.percentReconciled || 0
              }%)`}
            </St.DescriptionTitle>
            <St.ReconciledAmountText onClick={chooseWhichScreenToGo}>
              {summaryReconciledData?.[context?.left]?.reconciledAmount ||
                'Valor inválido'}
            </St.ReconciledAmountText>

            <St.DescriptionTitle>
              {`Não Conciliado (${
                summaryReconciledData?.[context?.left]?.percentPendent || 0
              }%)`}
            </St.DescriptionTitle>
            <St.PendentAmountText onClick={chooseWhichScreenToGo}>
              {summaryReconciledData?.[context?.left]?.pendentAmount ||
                'Valor inválido'}
            </St.PendentAmountText>
          </>
        )}
      </St.BorderColumn>

      {/* Col 2 */}
      <St.MiddleColumn alignItems="flex-end" marginRight={24}>
        <St.ReconColDescription onClick={chooseWhichScreenToGo}>
          {reconLeftColDescription}
        </St.ReconColDescription>

        <div style={{ textAlign: 'right' }}>
          <St.DescriptionTitle>
            {summaryReconciledData?.[context?.left]?.totalAmount}
          </St.DescriptionTitle>
          <St.ReconciliationBar
            height={handleReconBarHeight('left')}
            reconType={reconciliationType}
            color="primary"
            onClick={chooseWhichScreenToGo}
          />
        </div>
      </St.MiddleColumn>

      {/* Col 3 */}
      <St.MiddleColumn alignItems="flex-start" marginLeft={16}>
        <St.ReconColDescription onClick={chooseWhichScreenToGo}>
          {reconRightColDescription}
        </St.ReconColDescription>

        <div style={{ textAlign: 'left' }}>
          <St.DescriptionTitle>
            {summaryReconciledData?.[context?.right]?.totalAmount}
          </St.DescriptionTitle>
          <St.ReconciliationBar
            height={handleReconBarHeight('right')}
            reconType={reconciliationType}
            color="secondary"
            onClick={chooseWhichScreenToGo}
          />
        </div>
      </St.MiddleColumn>

      {/* Col 4 */}
      <St.BorderColumn
        textAlign="left"
        reconType={reconciliationType}
        highestTotal={highestTotal}
        lowestTotal={lowestTotal}
      >
        {reconciliationType !== 'bank' && (
          <>
            <St.DescriptionTitle>
              {`Conciliado (${
                summaryReconciledData?.[context?.right]?.percentReconciled || 0
              }%)`}
            </St.DescriptionTitle>
            <St.ReconciledAmountText onClick={chooseWhichScreenToGo}>
              {summaryReconciledData?.[context?.right]?.reconciledAmount ||
                'Valor inválido'}
            </St.ReconciledAmountText>
          </>
        )}

        <St.DescriptionTitle>
          {reconciliationType !== 'bank'
            ? `Não Conciliado (${
                summaryReconciledData?.[context?.right]?.percentPendent || 0
              }%)`
            : 'Diferença'}
        </St.DescriptionTitle>
        <St.PendentAmountText onClick={chooseWhichScreenToGo}>
          {reconciliationType !== 'bank'
            ? summaryReconciledData?.[context?.right]?.pendentAmount
            : summaryReconciledData?.[context?.difference] || 'Valor inválido'}
        </St.PendentAmountText>
      </St.BorderColumn>
    </St.Container>
  );
};

export default CardContent;
