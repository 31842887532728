import { call, put, delay } from 'redux-saga/effects';
import errorHandler from 'helpers/errorHandler';
import getOfxParametersFiltersFromAPI from 'services/filters/ofxParametersFilters';

import {
  ofxParametersFiltersSuccess,
  ofxParametersFiltersError,
} from './actions';

export function* ofxParametersFiltersSaga(action) {
  const { payload } = action;
  try {
    const apiData = yield call(getOfxParametersFiltersFromAPI, payload);
    yield delay(500);
    yield put(ofxParametersFiltersSuccess(apiData));
  } catch (err) {
    yield put(
      ofxParametersFiltersError(
        'Ocorreu um erro:',
        err.response.data.data.message
      )
    );
    errorHandler('Ocorreu um erro:', err);
  }
}
