import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import { Filters } from 'commons/Intermediate/Filters';
import { ContentTabs } from 'components/Intermediate/PaymentsReconciliation/components';
import { paymentsReconciledStart } from 'store/ducks/reconciled/payments/actions';
import { paymentsSummaryReconciledStart } from 'store/ducks/reconciled/summary/payments/actions';
import { disableLastMonth } from 'helpers/disableDates';
import { useResetFilters } from 'utils/hooks/useResetFilters';
import { updateReconciledPaymentsDetailsFilters } from 'store/ducks/filters/details/reconciledPaymentsDetailsFilters/actions';
import { onSorter } from 'helpers/sorter';
import { useRangePickerChange } from 'utils/hooks/useRangePickerChange';

const PaymentsReconciliationIntermediate = () => {
  const dispatch = useDispatch();

  const startDate = useSelector(
    (state) => state.updateScreenToScreenDate.startDate
  );
  const endDate = useSelector(
    (state) => state.updateScreenToScreenDate.endDate
  );

  const [sort, setSort] = useState('DESC');

  const [filtersDrawer, toggleFiltersDrawer] = useState(false);

  const [activeTab, setActiveTab] = useState('branches');

  const [filterName, setFilterName] = useState(null);

  const [paymentsReconciledInfoFilters, setPaymentsReconciledInfoFilters] =
    useState([]);

  const paymentsSummaryReconciled = useSelector(
    (state) => state.paymentsSummaryReconciled?.summary
  );
  const summaryLoading = useSelector(
    (state) => state.paymentsSummaryReconciled?.loading
  );
  const isSummaryError = useSelector(
    (state) => state.paymentsSummaryReconciled?.isError
  );

  const paymentsReconciledInfo = useSelector(
    (state) => state.paymentsReconciled?.data
  );

  const paymentsReconciledInfoLoading = useSelector(
    (state) => state.paymentsReconciled?.loading
  );
  const paymentsReconciledInfoError = useSelector(
    (state) => state.paymentsReconciled?.isError
  );

  const loading = paymentsReconciledInfoLoading || summaryLoading;

  const [calendarIsOpen, setCalendarIsOpen] = useState(false);

  const { onRangePickerChange } = useRangePickerChange();

  useEffect(() => {
    // In this particular screen the filtering process is made on the frontend
    // as instructed by the backend developer
    if (paymentsReconciledInfo) {
      if (paymentsReconciledInfo.length > 0) {
        const filteredPaymentReconciledInfo = paymentsReconciledInfo.map(
          (item) => item?.confirmation?.name
        );
        setPaymentsReconciledInfoFilters(filteredPaymentReconciledInfo);
      }
    }
  }, [paymentsReconciledInfo]);

  useEffect(() => {
    dispatch(
      paymentsReconciledStart({
        startDate,
        endDate,
        page: 1,
        type: activeTab,
        perPage: 100,
        sort,
        filter: filterName,
      })
    );
    dispatch(paymentsSummaryReconciledStart({ startDate, endDate }));
  }, [activeTab, dispatch, endDate, filterName, sort, startDate]);

  const onRangePickerOpenOrCloseChange = (open) => {
    setCalendarIsOpen(open);
  };

  const onChangeSelectFilter = (val) => {
    if (val === 'Todos' || val === 'Buscar' || val === '') {
      setFilterName(null);
    } else {
      setFilterName(val);
    }
  };

  const onTabClick = (key) => {
    setActiveTab(key);
    onChangeSelectFilter(null);
  };

  const { resetFilters } = useResetFilters();

  useEffect(() => {
    resetFilters(updateReconciledPaymentsDetailsFilters);
  }, [resetFilters]);

  return (
    <>
      <Filters
        title="Conciliação de pagamentos"
        subtitle="Verifique pagamentos previstos, realizados, conciliados e não conciliados"
        onRangePickerOpenOrCloseChange={onRangePickerOpenOrCloseChange}
        hasRangePicker
        hasSelect
        hasSorter
        dateDefaultValue={[startDate, endDate]}
        onRangePickerChange={(dates) =>
          onRangePickerChange(dates, calendarIsOpen)
        }
        onChangeSelectFilter={onChangeSelectFilter}
        loading={loading}
        sort={sort}
        sorter={onSorter(sort, setSort)}
        filtersDrawer={filtersDrawer}
        filterName={filterName}
        renderFilterButton={() => (
          <Button
            style={{ borderRadius: 4 }}
            onClick={() => toggleFiltersDrawer(!filtersDrawer)}
          >
            Filtros
          </Button>
        )}
        onCloseFiltersDrawer={() => toggleFiltersDrawer(false)}
        sorterButtonText="Pagamentos previstos"
        infoFiltersToSelect={paymentsReconciledInfoFilters}
        activeTab={activeTab}
        disabledDate={disableLastMonth}
        calendarIsOpen={calendarIsOpen}
        setCalendarIsOpen={setCalendarIsOpen}
      />
      <ContentTabs
        startDate={startDate}
        endDate={endDate}
        infoData={paymentsReconciledInfo}
        infoLoading={paymentsReconciledInfoLoading}
        isInfoError={paymentsReconciledInfoError}
        summaryData={paymentsSummaryReconciled}
        summaryLoading={summaryLoading}
        isSummaryError={isSummaryError}
        onTabClick={onTabClick}
        activeTab={activeTab}
        infoFilter={filterName}
      />
    </>
  );
};

export default PaymentsReconciliationIntermediate;
