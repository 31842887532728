import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Space,
  Spin,
  Row,
  Col,
  Checkbox,
  Typography,
  Empty,
} from 'antd';
import {
  StClearButton,
  StContainer,
  StContentContainer,
  StFilterCol,
  StSearchInput,
} from './styled';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateFilteredColumnsStart,
  resetUpdateFilteredColumnsStart,
} from 'store/ducks/updateFilteredColumns/actions';
import {
  StColumnFilterDropdownSpinnerWrapper,
  StListItem,
  StList,
} from 'components/Reports/styled';
import { useInfiniteScrollPageChange } from 'utils/hooks/useInfiniteScrollPageChange';

const FilterDropdown = ({
  apiFilters,
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  filterAction,
  filteredColumns,
  clearAction,
  columnName,
  storeName,
  disableSearchInput,
  filterBy,
}) => {
  const { Text } = Typography;
  const dispatch = useDispatch();

  const startDate = useSelector(
    (state) => state.updateScreenToScreenDate.startDate
  );
  const endDate = useSelector(
    (state) => state.updateScreenToScreenDate.endDate
  );
  const isLoading = useSelector((state) => state[storeName]?.loading);
  const hasMore = useSelector((state) => state[storeName]?.hasMore);
  const offset = useSelector((state) => state[storeName]?.offset);
  const limit = useSelector((state) => state[storeName]?.limit);
  const updatedState = useSelector((state) => state[storeName]);
  const initialOffset = 0;
  const defaultOffsetShift = 20;
  const [filteredApiFilters, setFilteredApiFilters] = useState([]);
  const [customSelectedKeys, setCustomSelectedKeys] = useState([]);
  const [clearDisabled, setClearDisabled] = useState(true);
  const [filterDisabled, setFilterDisabled] = useState(true);
  const [searchedValue, setSearchedValue] = useState('');
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const loadMoreFilters = () => {
    const config = {
      startDate,
      endDate,
      columnName: columnName,
      offset,
      limit,
      searchBy: searchedValue,
      q: {
        filter: filteredColumns,
      },
    };

    if (filterBy) {
      config.filterBy = filterBy;
    }

    dispatch(filterAction(config));
  };

  const { lastElementRef } = useInfiniteScrollPageChange({
    loading: isLoading,
    hasMore,
    setPage: loadMoreFilters,
  });

  useEffect(() => {
    if (apiFilters?.length > 0) {
      setFilteredApiFilters(updatedState?.filters[0]?.values?.filter((e) => e));
    } else {
      setFilteredApiFilters([]);
    }
    forceUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiFilters, updatedState]);

  useEffect(() => {
    forceUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedState]);

  useEffect(() => {
    if (customSelectedKeys.length > 0) {
      setClearDisabled(false);
      setFilterDisabled(false);
    } else {
      if (selectedKeys.length === 0) {
        setClearDisabled(true);
      }
      setFilterDisabled(true);
    }
    setSelectedKeys(customSelectedKeys);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customSelectedKeys]);

  useEffect(() => {
    /**
     * When the filter dropdown is reopened,
     * the customSelectedKeys must be filled with the selectedKeys data,
     * so that the checkboxes get checked "by default".
     */

    setCustomSelectedKeys(selectedKeys);
    if (selectedKeys.length > 0) {
      setClearDisabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearch = (typedValue) => {
    let _typedValue = typedValue;
    if (typedValue === '') {
      _typedValue = undefined;
    }
    const config = {
      startDate,
      endDate,
      columnName: columnName,
      offset: initialOffset,
      limit: defaultOffsetShift,
      searchBy: _typedValue,
      q: {
        filter: filteredColumns,
      },
    };
    setSearchedValue(_typedValue);
    dispatch(clearAction());
    if (filterBy) {
      config.filterBy = filterBy;
    }
    dispatch(filterAction(config));
  };

  return (
    <StContainer>
      {!disableSearchInput && (
        <StSearchInput
          placeholder="Procurar"
          onSearch={(e) => {
            onSearch(e);
          }}
          style={{ marginBottom: '8px', height: '32px' }}
        />
      )}
      <StContentContainer>
        <StList
          style={{ position: 'relative' }}
          loading={isLoading}
          dataSource={filteredApiFilters}
          renderItem={(item, i) => {
            const shouldRenderSpinner =
              filteredApiFilters?.length === i + 1 && hasMore;

            return (
              <>
                <StListItem $extraMarginBottom={shouldRenderSpinner}>
                  <Row>
                    <StFilterCol
                      span={24}
                      index={i}
                      ref={
                        filteredApiFilters?.length === i + 1
                          ? lastElementRef
                          : null
                      }
                    >
                      <Checkbox
                        checked={selectedKeys.includes(item)}
                        onChange={(e) => {
                          const checked = e?.target?.checked;
                          if (checked) {
                            setCustomSelectedKeys([
                              ...customSelectedKeys,
                              item,
                            ]);
                          } else {
                            const clonedCustomSelectedKeys = JSON.parse(
                              JSON.stringify(customSelectedKeys)
                            );
                            const index =
                              clonedCustomSelectedKeys.indexOf(item);
                            if (index > -1) {
                              clonedCustomSelectedKeys.splice(index, 1);
                            }
                            setCustomSelectedKeys(clonedCustomSelectedKeys);
                          }
                        }}
                      >
                        <Text>{!!item && item}</Text>
                      </Checkbox>
                    </StFilterCol>
                  </Row>
                </StListItem>
                {shouldRenderSpinner && (
                  <StColumnFilterDropdownSpinnerWrapper>
                    <Spin />
                  </StColumnFilterDropdownSpinnerWrapper>
                )}
              </>
            );
          }}
        >
          {/*
              This fragment is here because otherwise the list will show an useless
              "There's no data" message. The loading spinner is rendered by the parent component.
            */}
          {filteredApiFilters?.length === 0 && !isLoading && (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="Nenhum filtro encontrado"
            />
          )}
          <></>
        </StList>
      </StContentContainer>
      <Space>
        <Row gutter={[8, 0]}>
          <Col span={12}>
            <StClearButton
              onClick={() => {
                clearFilters();
                setCustomSelectedKeys([]);
                dispatch(resetUpdateFilteredColumnsStart());
                confirm();
              }}
              size="small"
              disabled={clearDisabled}
            >
              Limpar
            </StClearButton>
          </Col>
          <Col span={12}>
            <Button
              onClick={() => {
                dispatch(
                  updateFilteredColumnsStart({
                    name: columnName,
                    val: selectedKeys,
                  })
                );
                confirm();
              }}
              size="small"
              type="primary"
              disabled={filterDisabled}
            >
              Filtrar
            </Button>
          </Col>
        </Row>
      </Space>
    </StContainer>
  );
};

export default FilterDropdown;
