import React from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from 'antd';
import {
  StModal,
  StModalBodyRow,
  StModalFooterRow,
  StModalHeaderRow,
  StWarningOutlined,
  StCheckCircleOutlined,
} from './styled';

const CardModal = ({
  loading = false,
  cardType,
  onConfirm,
  onCancel,
  setIsModalOpen,
  isModalOpen,
}) => {
  const handleOk = () => {
    onConfirm();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    onCancel();
  };

  const modalHeader = (_cardType) => {
    switch (_cardType) {
      case 'finish':
        return (
          <StModalHeaderRow>
            <StCheckCircleOutlined /> Concluir cadastro
          </StModalHeaderRow>
        );
      case 'cancel':
        return (
          <StModalHeaderRow>
            <StWarningOutlined /> Cancelar cadastro
          </StModalHeaderRow>
        );
      default:
        return <></>;
    }
  };

  const modalBody = (_cardType) => {
    switch (_cardType) {
      case 'finish':
        return (
          <StModalBodyRow>
            <Typography.Text style={{ fontSize: 14 }}>
              Você tem certeza que deseja concluir o cadastro das taxas?
            </Typography.Text>
          </StModalBodyRow>
        );
      case 'cancel':
        return (
          <StModalBodyRow>
            <Typography.Text style={{ fontSize: 14 }}>
              Você tem certeza que deseja cancelar o cadastro de taxas?
            </Typography.Text>
          </StModalBodyRow>
        );
      default:
        return <></>;
    }
  };

  const modalFooter = (_cardType) => {
    switch (_cardType) {
      case 'finish':
        return (
          <StModalFooterRow>
            <Button onClick={handleCancel} disabled={loading}>
              Cancelar
            </Button>
            <Button onClick={handleOk} type="primary" loading={loading}>
              Concluir
            </Button>
          </StModalFooterRow>
        );
      case 'cancel':
        return (
          <StModalFooterRow>
            <Button onClick={handleCancel}>Voltar</Button>
            <Button onClick={handleOk} type="primary">
              Cancelar
            </Button>
          </StModalFooterRow>
        );
      default:
        return <></>;
    }
  };

  return (
    <StModal
      centered
      closable={false}
      maskClosable={false}
      width={420}
      open={isModalOpen}
      title={modalHeader(cardType)}
      bodyStyle={{ marginLeft: 38, padding: '0px 24px 24px' }}
      footer={modalFooter(cardType)}
    >
      {modalBody(cardType)}
    </StModal>
  );
};

CardModal.propTypes = {
  cardType: PropTypes.string.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
};

export default CardModal;
