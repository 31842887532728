export const actionTypes = {
  DELETE_FEES_START: '@listFees/DELETE_FEES_START',
  DELETE_FEES_SUCCESS: '@listFees/DELETE_FEES_SUCCESS',
  DELETE_FEES_ERROR: '@listFees/DELETE_FEES_ERROR',
  PUT_FEES_START: '@listFees/PUT_FEES_START',
  PUT_FEES_SUCCESS: '@listFees/PUT_FEES_SUCCESS',
  PUT_FEES_ERROR: '@listFees/PUT_FEES_ERROR',
  LIST_FEES_VENCIDAS_START: '@listFees/LIST_FEES_VENCIDAS_START',
  LIST_FEES_VENCIDAS_ALTER: '@listFees/LIST_FEES_VENCIDAS_ALTER',
  LIST_FEES_VENCIDAS_SUCCESS: '@listFees/LIST_FEES_VENCIDAS_SUCCESS',
  LIST_FEES_VENCIDAS_ERROR: '@listFees/LIST_FEES_VENCIDAS_ERROR',
  LIST_FEES_VIGENTES_START: '@listFees/LIST_FEES_VIGENTES_START',
  LIST_FEES_VIGENTES_SUCCESS: '@listFees/LIST_FEES_VIGENTES_SUCCESS',
  LIST_FEES_VIGENTES_ERROR: '@listFees/LIST_FEES_VIGENTES_ERROR',
  LIST_FEES_VIGENTES_ALTER: '@listFees/LIST_FEES_VIGENTES_ALTER',
};
