import { api, fileIdentificationApi } from './api';

const transformer = (apiResponse) => {
  return {
    client: apiResponse.data.clients,
  };
};

export const getClient = async () => {
  const config = {
    params: {
      client_id: localStorage.getItem('concil-card-clientId'),
    },
  };
  const response = await api().get('/client', config);
  return transformer(response.data);
};

export const postClient = async (payload) => {
  const response = await api().post('/client', payload);
  return response.data;
};

export const putClient = async (payload) => {
  const response = await api().put('/client', payload);
  return response.data;
};

export const fileIdentificationService = async (payload) => {
  const response = await fileIdentificationApi().post('', payload);
  return response.data;
};
