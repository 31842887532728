/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-indent */
/* eslint-disable consistent-return */
import { call, put } from 'redux-saga/effects';
import { notification } from 'antd';
import {
  getNotification,
  createNotification,
  changeNotificationStatus,
  getDownloadUrl,
} from 'services/notifications';
import {
  changeNotificationStatusError,
  changeNotificationStatusSuccess,
  createNotificationError,
  createNotificationSuccess,
  getDownloadUrlError,
  getDownloadUrlSuccess,
  getNotificationError,
  getNotificationSuccess,
  markAlertAsSent,
  updateNotificationPageSuccess,
  updateNotificationPerPageSuccess,
} from './actions';
import errorHandlerNotification from '../../../helpers/errorHandler';

export function* getNotificationSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(getNotification, payload.data);
    yield put(getNotificationSuccess(apiData));
  } catch (err) {
    yield put(getNotificationError('Ocorreu um erro:', err));
  }
}

export function* createNotificationSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(createNotification, payload.data);
    yield put(createNotificationSuccess(apiData));
    notification.success({
      message: apiData?.data?.data?.detail_title,
      duration: 8,
      description: apiData?.data?.data?.detail,
      props: {
        'data-testid': 'notification-preparing-report',
      },
    });
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        createNotificationError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandlerNotification(
        err.response.data.error.message,
        err.response.data.error
      );
    } else {
      yield put(createNotificationError('Ocorreu um erro:', err));
      errorHandlerNotification('Ocorreu um erro:', err);
    }
  }
}

export function* changeNotificationStatusSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(changeNotificationStatus, payload.data);
    yield put(changeNotificationStatusSuccess(apiData));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        changeNotificationStatusError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandlerNotification(
        err.response.data.error.message,
        err.response.data.error
      );
    } else {
      yield put(changeNotificationStatusError('Ocorreu um erro:', err));
      errorHandlerNotification('Ocorreu um erro:', err);
    }
  }
}

export function* getDownloadUrlSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(getDownloadUrl, payload.data);
    yield put(getDownloadUrlSuccess(apiData));
  } catch (err) {
    yield put(getDownloadUrlError('Ocorreu um erro:', err));
  }
}

export function* markAlertAsSentSaga(action) {
  const { payload } = action;
  yield put(markAlertAsSent(payload));
}

export function* updateNotificationPageSaga(action) {
  const { payload } = action;
  yield put(updateNotificationPageSuccess(payload?.data));
}

export function* updateNotificationPerPageSaga(action) {
  const { payload } = action;
  yield put(updateNotificationPerPageSuccess(payload?.data));
}
