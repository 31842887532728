import React, { useState } from 'react';
import dayjs from 'dayjs';
import { DatePicker, Form } from 'antd';
import { InputMaskPercent } from 'commons/InputMaskPercent';

export const FeeEditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  isUpdateLoading = false,
  setSaveButtonDisabled,
  setEndDateHasChange,
  setFeePercentHasChange,
  setIsInvalidValue,
  ...restProps
}) => {
  const format = 'DD/MM/YYYY';
  const [endDate, setEndDate] = useState(record?.end_date);
  const [feePercent, setFeePercent] = useState(record?.fee_percent);

  const handleEndDate = (dateString) => {
    if (!dateString) {
      setSaveButtonDisabled(true);
      return;
    }
    const formattedEndDate = dayjs(dateString).format(format);

    setEndDateHasChange(formattedEndDate !== record?.end_date);
    setEndDate(formattedEndDate);
  };

  // Can not select days before start date
  const disableDates = (currentDate) => {
    if (currentDate.isBefore(dayjs(record?.start_date, format))) {
      return true;
    }

    return false;
  };

  const onFeePercentChange = (value) => {
    const formattedValue = Number(value.replace(',', '.')).toFixed(2);
    const currentFeePercent = Number(
      record?.fee_percent.replace(',', '.')
    ).toFixed(2);

    if (value === '') {
      setSaveButtonDisabled(true);
      return;
    }

    setIsInvalidValue(formattedValue < 0.01);
    setFeePercentHasChange(formattedValue !== currentFeePercent);
    setFeePercent(value);
  };

  const inputNode =
    inputType === 'string' ? (
      <DatePicker
        onChange={handleEndDate}
        value={endDate}
        disabledDate={disableDates}
        disabled={isUpdateLoading}
        format={format}
      />
    ) : (
      <InputMaskPercent
        value={feePercent}
        onChange={onFeePercentChange}
        disabled={isUpdateLoading}
      />
    );

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={
            inputType !== 'string'
              ? [
                  {
                    validator(_, value) {
                      const parsedValue = parseFloat(value.replace(/,/g, '.'));
                      if (
                        value &&
                        parsedValue <= 100.0 &&
                        parsedValue >= 0.01
                      ) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error('Digite um percentual entre 0,01 e 100')
                      );
                    },
                  },
                ]
              : [
                  {
                    required: true,
                    message: 'Campo obrigatório',
                  },
                ]
          }
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
