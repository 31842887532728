import React from 'react';
import PropTypes from 'prop-types';
import { Col, Empty, Row } from 'antd';
import { StText, StTitle } from './styled';

const EmptyCard = ({ title = '', reason = '', emptyLogo }) => (
  <div
    style={{
      textAlign: 'center',
    }}
  >
    <Empty
      image={emptyLogo || Empty.PRESENTED_IMAGE_SIMPLE}
      description={
        <div
          style={{
            textAlign: 'center',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-around',
          }}
        >
          <Row>
            <Col span={24}>
              <StTitle>{title}</StTitle>
            </Col>
            <Col span={24}>
              <StText>{reason}</StText>
            </Col>
          </Row>
        </div>
      }
      imageStyle={{ height: '100%', margin: '0', padding: '0' }}
    />
  </div>
);

EmptyCard.propTypes = {
  title: PropTypes.string,
  reason: PropTypes.string,
};

EmptyCard.defaultProps = {
  title: '',
  reason: '',
};

export default EmptyCard;
