import styled from 'styled-components';
import { Col, Row, Typography } from 'antd';
import { handleColor, handleContent, handleBulletSize } from './utils';
import { CheckCircleOutlined } from '@ant-design/icons';
import { colors } from 'styles/colors';

const { Title, Text } = Typography;

export const StAlignContent = styled.div`
  border-radius: 5px;
  background-color: ${colors.gray1};
  padding: 32px;
  &&& {
    font-family: 'Roboto', sans-serif;
  }
  text-align: left;
`;

export const StCreatePasswordTitle = styled(Title)`
  &&& {
    font-size: 20px;
    color: ${colors.gray9};
    line-height: 38px;
  }
`;

export const StPasswordTextInfo = styled(Text)`
  &&& {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: ${colors.gray9};
  }
`;

export const StCustomUl = styled.ul`
  padding: 0px;
  margin: 0px 0px 0px 15px;
  display: grid;
  gap: 5px;
  color: ${colors.gray8};
  list-style: none;
`;

export const StLiContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
`;

export const StCustomLi = styled.li`
  display: flex;
  align-items: center;

  &:before {
    font-family: 'FontAwesome';
    content: ${(props) =>
  handleContent(
        props?.validNumOfChars,
        props?.validUppercase,
        props?.validLowercase,
        props?.validOneNumber,
        props?.validSpecialChar
  )}};
    margin: 0 8px 0 -15px;
    -webkit-text-fill-color: ${(props) =>
    handleColor(
        props?.validNumOfChars,
        props?.validUppercase,
        props?.validLowercase,
        props?.validOneNumber,
        props?.validSpecialChar
    )}};
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: ${(props) =>
      handleColor(
        props?.validNumOfChars,
        props?.validUppercase,
        props?.validLowercase,
        props?.validOneNumber,
        props?.validSpecialChar
      )}};
    font-size: ${(props) =>
        handleBulletSize(
        props?.validNumOfChars,
        props?.validUppercase,
        props?.validLowercase,
        props?.validOneNumber,
        props?.validSpecialChar
        )}};
  }
`;

export const StCustomIcon = styled(CheckCircleOutlined)`
  margin-right: 5px;
  color: ${(props) => props.twoToneColor};
`;

export const StExpirationDescription = styled(Text)`
  &&& {
    font-size: 11px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 16px;
  }
`;

export const StTitleRow = styled(Row)`
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: left;
  color: ${colors.gray9};

  &&& {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
  }
`;

export const StErrorCol = styled(Col)`
  &&& {
    margin-bottom: 24px;
    text-align: center;
  }
`;
