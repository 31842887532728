import { action } from 'typesafe-actions';
import { actionTypes } from './types';

export const salesDetailsFiltersStart = (data) => action(actionTypes.SALES_DETAILS_FILTERS_START, data);
export const salesDetailsFiltersSuccess = (data) =>
  action(actionTypes.SALES_DETAILS_FILTERS_SUCCESS, data);
export const salesDetailsFiltersError = (errorMessage, error) =>
  action(actionTypes.SALES_DETAILS_FILTERS_ERROR, { errorMessage }, undefined, { error });
export const salesDetailsFiltersZeroLength = () =>
  action(actionTypes.SALES_DETAILS_FILTERS_ZERO_LENGTH);
export const updateSalesDetailsFilters = (data) =>
  action(actionTypes.UPDATE_SALES_DETAILS_FILTERS, data);
