/* eslint-disable */
/* eslint-disable no-console */
import store from 'store';
import {
  getSessionFirstName,
  getSessionSurname,
  getSessionUsername,
} from 'helpers/sessionService';
import plans from 'constants/plans';

const movideskEnabled = process.env.REACT_APP_MOVIDESK_ENABLED === 'true';
const movideskChatKey = process.env.REACT_APP_MOVIDESK_KEY;
const movideskChatFreePlanKey = process.env.REACT_APP_MOVIDESK_FREE_PLAN_KEY;

export const movideskChat = () => {
  const { permissions } = store.getState();
  const planId = permissions?.data?.plan_id;

  if (!movideskEnabled) return;
  if (!planId || document.querySelector('#mdChatClient')) return;

  const isFreePlan = planId === plans.FREE;
  const chatClientKey = isFreePlan ? movideskChatFreePlanKey : movideskChatKey;

  if (!chatClientKey) return;

  const userFirstName = getSessionFirstName();
  const userSurname = getSessionSurname();
  const clientEmail = getSessionUsername();
  const scriptMdChat = document.createElement('script');
  const script = document.createElement('script');

  scriptMdChat.innerHTML = `var mdChatClient = "${chatClientKey}";`;
  scriptMdChat.setAttribute('id', 'mdChatClient');

  document.head.appendChild(scriptMdChat);

  script.src = 'https://chat.movidesk.com/Scripts/chat-widget.min.js';
  script.onload = () => {
    movideskLogin({
      name: `${userFirstName} ${userSurname}`,
      email: clientEmail,
      phoneNumber: '',
      stayConnected: false,
      emptySubject: false,
      startChat: true,
    });
  };

  document.head.appendChild(script);
  console.log('Movidesk init');
};
