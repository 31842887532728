import { actionTypes } from 'store/ducks/listClientsSelect/types';

const INITIAL_STATE = {
  error: undefined,
  errorMessage: undefined,
  loading: true,
  isError: false,
  clients: [],
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case actionTypes.LIST_OF_CLIENTS_SELECT_START:
    return {
      ...state,
      loading: true,
      error: undefined,
      errorMessage: undefined,
      isError: false,
    };
  case actionTypes.LIST_OF_CLIENTS_SELECT_SUCCESS:
    return {
      ...state,
      loading: false,
      error: undefined,
      clients: action.payload,
      isError: false,
      errorMessage: undefined,
    };
  case actionTypes.LIST_OF_CLIENTS_SELECT_ERROR:
    return {
      ...state,
      loading: false,
      error: action.error.error,
      errorMessage: action.payload,
      isError: true,
      clients: [],
    };
  default:
    return state;
  }
};

export default reducer;
