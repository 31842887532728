import { call, put } from 'redux-saga/effects';

import errorHandler from 'helpers/errorHandler';

import {
  creditListPartnersSuccess,
  creditListPartnersError,
} from 'store/ducks/credit/creditListPartners/actions';

import getCreditListPartnersFromAPI from 'services/credit/listPartners';

export function* creditListPartnersSaga() {
  try {
    const apiData = yield call(getCreditListPartnersFromAPI);
    yield put(creditListPartnersSuccess(apiData));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        creditListPartnersError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(creditListPartnersError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}
