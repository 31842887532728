import { action } from 'typesafe-actions';
import { actionTypes } from 'store/ducks/optin/optinRequest/types';

export const optinRequestStart = (data) =>
  action(actionTypes.OPTIN_REQUEST_START, data);
export const optinRequestSuccess = (data) =>
  action(actionTypes.OPTIN_REQUEST_SUCCESS, data);
export const optinRequestError = (errorMessage, error) =>
  action(actionTypes.OPTIN_REQUEST_ERROR, { errorMessage }, undefined, {
    error,
  });
