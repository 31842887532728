import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Dropdown, Checkbox, Button } from 'antd';
import { ControlOutlined } from '@ant-design/icons';
import { setReportsCustomColumns } from 'store/ducks/reportsCustomColumns/actions';

const TableCustomColumnsSelect = ({
  columns,
  defaultSelectedColumns,
  pagination,
}) => {
  const dispatch = useDispatch();
  const [columnFilters, setColumnFilters] = useState([]);
  const [visibleFilterColumnsMenu, setFilterColumnsMenu] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [checkedList, setCheckedList] = useState([]);
  const plainOptions = columns.map((item) => item.title);

  useEffect(() => {
    dispatch(
      setReportsCustomColumns(
        columnFilters
          ? columns.filter((item) =>
              columnFilters.some((el) => el === item.title)
            )
          : columns
      )
    );
  }, [dispatch, columns, columnFilters]);

  useEffect(() => {
    /*
      The default values must be set only on the first page,
      otherwise the columns will be set to the default values at each page change.
    */
    if (pagination?.current === 1) {
      setCheckedList(defaultSelectedColumns);
      setColumnFilters(defaultSelectedColumns);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSelectedColumns]);

  const selectAllItems = () => {
    setCheckedList(plainOptions);
    setCheckAll(true);
    setColumnFilters(plainOptions);
  };

  const selectItem = (checkedListParam) => {
    const scopedCheckedList = checkedListParam;
    setCheckedList(scopedCheckedList);
    setCheckAll(scopedCheckedList.length === plainOptions.length);
    setColumnFilters(scopedCheckedList);
  };

  const unselectAll = () => {
    setCheckedList([]);
    setCheckAll(false);
    setColumnFilters([]);
  };

  const handleVisibleColumnsFilterChange = (flag) => {
    setFilterColumnsMenu(flag);
  };

  const items = [
    {
      label: (
        <>
          <Button onClick={selectAllItems} checked={checkAll}>
            Selecionar todos
          </Button>
          <Button onClick={unselectAll} disabled={!columnFilters.length}>
            Limpar seleção
          </Button>
        </>
      ),
      key: 'actions-buttons',
    },
    {
      label: (
        <Checkbox.Group
          style={{ display: 'flex', flexDirection: 'column' }}
          options={plainOptions}
          value={checkedList}
          onChange={selectItem}
        />
      ),
      key: 'checkboxes',
    },
  ];

  return (
    <Dropdown
      menu={{ items }}
      trigger={['click']}
      open={visibleFilterColumnsMenu}
      onClick={() => setFilterColumnsMenu(true)}
      onOpenChange={handleVisibleColumnsFilterChange}
    >
      <Button icon={<ControlOutlined />}>Colunas</Button>
    </Dropdown>
  );
};

TableCustomColumnsSelect.propTypes = {
  defaultSelectedColumns: PropTypes.arrayOf(PropTypes.string.isRequired)
    .isRequired,
  pagination: PropTypes.shape({
    pageSize: PropTypes.number,
    total: PropTypes.number,
    current: PropTypes.number,
  }).isRequired,
};

export default TableCustomColumnsSelect;
