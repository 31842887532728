import { action } from 'typesafe-actions';
import { actionTypes } from './types';

export const ofxParametersFiltersStart = (data) =>
  action(actionTypes.OFX_PARAMETERS_FILTERS_START, data);
export const ofxParametersFiltersSuccess = (data) =>
  action(actionTypes.OFX_PARAMETERS_FILTERS_SUCCESS, data);
export const ofxParametersFiltersError = (errorMessage, error) =>
  action(
    actionTypes.OFX_PARAMETERS_FILTERS_ERROR,
    { errorMessage },
    undefined,
    { error }
  );
export const ofxParametersFiltersZeroLength = () =>
  action(actionTypes.OFX_PARAMETERS_FILTERS_ZERO_LENGTH);
export const updateOfxParametersFilters = (data) =>
  action(actionTypes.UPDATE_OFX_PARAMETERS_FILTERS, data);
