/* eslint-disable arrow-parens,jsx-a11y/alt-text */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Col, Row, BackTop, Card } from 'antd';
import { StContainer, StCountStore, StSpin } from './styled';
import { CnpjModal } from '../CnpjModal';
import { UpdateStoreModal } from './UpdateStoreModal';
import { ErrorCard } from 'commons/ErrorCard';
import { colors } from 'styles/colors';
import { StoreListItem } from './StoreListItem';
import useStoreList from 'components/CnpjMaintenance/hooks/useStoreList';
import { useDispatch, useSelector } from 'react-redux';
import { UpOutlined } from '@ant-design/icons';
import { deleteStoresStart } from 'store/ducks/cnpjMaintenance/deleteStores/actions';
import { putStoresStart } from 'store/ducks/cnpjMaintenance/putStores/actions';
import { Empty } from 'commons/Empty';
import { shortId } from 'helpers/shortId';

const CnpjStoresList = ({ searchFilter }) => {
  const [addModalVisibility, setAddModalVisibility] = useState(false);
  const [updateStoreModalVisibility, setUpdateStoreModalVisibility] =
    useState(false);
  const [page, setPage] = useState(1);
  const perPage = 10;

  const deleteStoresSuccess = useSelector(
    (state) => state.deleteStores?.success
  );
  const putStoresSuccess = useSelector((state) => state.putStores?.success);
  const updateStoreSuccess = useSelector((state) => state.updateStore?.success);

  const { isLoading, isError, stores, metaData, refreshStores } = useStoreList(
    searchFilter,
    page,
    setPage,
    perPage
  );
  const { has_next: hasMoreStores, stores_count: storeCount = 0 } = metaData;

  const storeCountFormatted = storeCount.toLocaleString('pt-BR');

  const openUpdateStoreModal = () => {
    setUpdateStoreModalVisibility(true);
  };
  const closeUpdateStoreModal = () => {
    setUpdateStoreModalVisibility(false);
  };

  const openAddModal = () => {
    setAddModalVisibility(true);
  };
  const closeAddModal = () => {
    setAddModalVisibility(false);
  };

  const observer = useRef();
  const lastStoreElementRef = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMoreStores) {
          setPage((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, hasMoreStores]
  );

  const backToTopStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 50,
    width: 50,
    lineHeight: '50px',
    borderRadius: '50%',
    backgroundColor: colors.background2,
    color: colors.gray1,
    textAlign: 'center',
    fontSize: 20,
  };
  const dispatch = useDispatch();

  const onRemoveStore = (store) => {
    dispatch(deleteStoresStart({ storeId: store?.store_id }));
  };

  const onActiveStore = (store) => {
    dispatch(putStoresStart({ storeId: store?.store_id }));
  };

  useEffect(() => {
    if (deleteStoresSuccess) {
      setPage(1);
      refreshStores();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStoresSuccess]);

  useEffect(() => {
    if (putStoresSuccess) {
      setPage(1);
      refreshStores();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [putStoresSuccess]);

  useEffect(() => {
    if (updateStoreSuccess) {
      setPage(1);
      refreshStores();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStoreSuccess]);

  // Render Error Card
  if (isError) {
    return (
      <Row style={{ marginTop: '192px', justifyContent: 'center' }}>
        <Col lg={10} md={18}>
          <Card style={{ borderRadius: 4 }} loading={isLoading}>
            <ErrorCard
              title="Dados indisponíveis"
              reason="Não conseguimos buscar os dados, mas já estamos trabalhando para normalizar a situação."
              recommendation="Tente atualizar a página em alguns instantes."
            />
          </Card>
        </Col>
      </Row>
    );
  }

  return (
    <>
      <StContainer>
        <Row
          style={{
            padding: '16px 24px',
            backgroundColor: colors.gray1,
          }}
        >
          <StCountStore>
            Total de {storeCountFormatted} CNPJ(s) cadastrado(s)
          </StCountStore>
        </Row>
        <Col
          style={{ maxHeight: 600, overflowY: 'auto', width: '100%' }}
          id="storeListContainer"
        >
          {stores?.map((store, index) => {
            return (
              <div
                key={shortId()}
                ref={stores.length === index + 1 ? lastStoreElementRef : null}
              >
                <StoreListItem
                  key={store.store_id}
                  storeDetails={store}
                  openAddModal={openAddModal}
                  openUpdateStoreModal={openUpdateStoreModal}
                  canEditShortName={store?.can_edit}
                  onClickRemove={() => onRemoveStore(store)}
                  onClickActive={() => onActiveStore(store)}
                />
              </div>
            );
          })}
        </Col>
        {!isLoading && stores.length <= 0 && (
          <Row
            style={{
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Empty
              title="Loja não encontrada."
              reason="Verifique sua pesquisa e tente novamente."
            />
          </Row>
        )}

        {isLoading && <StSpin />}

        <BackTop target={() => document.getElementById('storeListContainer')}>
          <UpOutlined style={backToTopStyle} />
        </BackTop>
      </StContainer>

      <CnpjModal
        visibility={addModalVisibility}
        screen={1}
        closeModal={closeAddModal}
      />
      <UpdateStoreModal
        visibility={updateStoreModalVisibility}
        closeModal={closeUpdateStoreModal}
      />
    </>
  );
};

export default CnpjStoresList;
