import React from 'react';
import {
  CheckboxContainer,
  HiddenCheckbox,
  StyledCheckbox,
  Icon,
  CnpjMaintenanceStyledCheckbox,
} from './styled';
import PropTypes from 'prop-types';

const RoundedCheckbox = ({
  className,
  checked,
  disabled,
  isCNPJMaintenance,
  isEmpty,
  acquirerDetails,
  ...props
}) => (
  <CheckboxContainer className={className}>
    <HiddenCheckbox defaultChecked={checked} {...props} />
    {isCNPJMaintenance ? (
      <CnpjMaintenanceStyledCheckbox
        checked={checked}
        isEmpty={isEmpty}
        {...props}
      >
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </CnpjMaintenanceStyledCheckbox>
    ) : (
      <StyledCheckbox checked={checked} disabled={disabled}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
    )}
  </CheckboxContainer>
);

RoundedCheckbox.defaultProps = {
  className: '',
  checked: false,
  props: {},
};
RoundedCheckbox.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  props: PropTypes.object,
};

export default RoundedCheckbox;
