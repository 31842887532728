import { call, put } from 'redux-saga/effects';

import errorHandler from 'helpers/errorHandler';

import {
  creditListPartnersAvailableSuccess,
  creditListPartnersAvailableError,
} from 'store/ducks/credit/creditListPartnersAvailable/actions';

import getCreditListPartnersAvailableFromAPI from 'services/credit/listPartnersAvailable';

export function* creditListPartnersAvailableSaga() {
  try {
    const apiData = yield call(getCreditListPartnersAvailableFromAPI);
    yield put(creditListPartnersAvailableSuccess(apiData));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        creditListPartnersAvailableError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(creditListPartnersAvailableError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}
