import { colors } from 'styles/colors';

export const radarTypes = {
  Mensal: {
    fontColor: colors.green13,
    borderColor: colors.green11,
    backgroundColor: colors.green12,
  },
  Semanal: {
    fontColor: colors.primary6,
    borderColor: colors.primary3,
    backgroundColor: colors.primary1,
  },
};
