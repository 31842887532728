import { action } from 'typesafe-actions';
import { actionTypes } from 'store/ducks/retroactiveConciliation/types';

export const listRetroactiveConciliationStart = (data) =>
  action(actionTypes.LIST_RETROACTIVE_CONCILIATION_START, data);
export const listRetroactiveConciliationSuccess = (data) =>
  action(actionTypes.LIST_RETROACTIVE_CONCILIATION_SUCCESS, data);
export const listRetroactiveConciliationError = (errorMessage, error) =>
  action(
    actionTypes.LIST_RETROACTIVE_CONCILIATION_ERROR,
    { errorMessage },
    undefined,
    {
      error,
    }
  );

export const postRetroactiveConciliationStart = (data) =>
  action(actionTypes.POST_RETROACTIVE_CONCILIATION_START, data);
export const postRetroactiveConciliationSuccess = (data) =>
  action(actionTypes.POST_RETROACTIVE_CONCILIATION_SUCCESS, data);
export const postRetroactiveConciliationError = (errorMessage, error) =>
  action(
    actionTypes.POST_RETROACTIVE_CONCILIATION_ERROR,
    { errorMessage },
    undefined,
    {
      error,
    }
  );
