export const actionTypes = {
  RECONCILED_BANK_DETAILS_FILTERS_START:
    '@reconciledBankDetailsFilters/RECONCILED_BANK_DETAILS_FILTERS_START',
  RECONCILED_BANK_DETAILS_FILTERS_SUCCESS:
    '@reconciledBankDetailsFilters/RECONCILED_BANK_DETAILS_FILTERS_SUCCESS',
  RECONCILED_BANK_DETAILS_FILTERS_ERROR:
    '@reconciledBankDetailsFilters/RECONCILED_BANK_DETAILS_FILTERS_ERROR',
  RECONCILED_BANK_DETAILS_FILTERS_ZERO_LENGTH:
    '@reconciledBankDetailsFilters/RECONCILED_BANK_DETAILS_FILTERS_ZERO_LENGTH',
  UPDATE_RECONCILED_BANK_DETAILS_FILTERS:
    '@reconciledBankDetailsFilters/UPDATE_RECONCILED_BANK_DETAILS_FILTERS',
};
