import React from 'react';
import {
  Row,
  Col,
  Button,
  Divider,
} from 'antd';
import { StPartnerIcon, StPartnerName } from './styled';
import customHistory from 'helpers/history';
import { useDispatch } from 'react-redux';
import { updateSharedPartnerDataStart } from 'store/ducks/credit/sharedPartnerData/actions';
import { colors } from 'styles/colors';

const PartnerHeader = ({ offerData }) => {
  const dispatch = useDispatch();

  const goToAuthPartner = () => {
    dispatch(updateSharedPartnerDataStart(offerData));
    customHistory.push('/autorizar-parceiro');
  };

  return (
    <>
      <Row
        align="middle"
        justify="space-between"
        gutter={[16, 0]}
        style={{ margin: '0px 0px 16px 0px' }}
      >
        <Col align="middle" span={20}>
          <Divider align="middle" orientation="left">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <StPartnerIcon
                textAlign="center"
                align="middle"
                src={offerData?.creditPartner?.s3LogoPath}
                style={{ marginRight: 8 }}
              />
              <StPartnerName textAlign="center" align="middle">
                {offerData?.creditPartner?.name}
              </StPartnerName>
            </div>
          </Divider>
        </Col>
        <Col align="middle" span={4}>
          {!(!offerData?.creditPartner?.active || !offerData?.active) ? (
            <Button type="primary" block onClick={goToAuthPartner}>
              Compartilhar dados
            </Button>
          ) : (
            <Button
              type="primary"
              block
              onClick={goToAuthPartner}
              style={{
                background: colors.gray1,
                color: colors.gray9,
                border: `1px solid ${colors.gray5}`,
              }}
            >
              Configurar parceiro
            </Button>
          )}
        </Col>
      </Row>
    </>
  );
};

export default PartnerHeader;
