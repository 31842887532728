/* eslint-disable no-unused-expressions */
import styled from 'styled-components';
import { Row, Typography } from 'antd';
import { colors } from 'styles/colors';
import {
  Loading3QuartersOutlined,
  UserOutlined,
  CheckOutlined,
  CloseOutlined,
  PaperClipOutlined,
  ExclamationCircleOutlined,
  WarningOutlined,
} from '@ant-design/icons';

const { Text } = Typography;

export const NotificationCardContainer = styled.div`
  margin: 5px 0;
`;

export const FirstRowContainer = styled(Row)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const AlignCenterRow = styled(Row)`
  display: flex;
  align-items: center;
`;

export const SpinnerIcon = styled(Loading3QuartersOutlined)`
  &&& {
    font-size: 20px;
    color: ${colors.primary6};
    margin: 16px;
  }
`;

export const WarningIcon = styled(WarningOutlined)`
  &&& {
    font-size: 20px;
    color: ${colors.primary6};
    margin: 16px;
  }
`;

export const CheckIcon = styled(CheckOutlined)`
  &&& {
    font-size: 20px;
    color: ${colors.primary6};
    margin: 16px;
  }
`;

export const CloseIcon = styled(CloseOutlined)`
  &&& {
    font-size: 20px;
    color: ${colors.gray8};
    margin: 16px;
  }
`;

export const ExclamationIcon = styled(ExclamationCircleOutlined)`
  &&& {
    font-size: 20px;
    color: ${colors.primary6};
    margin: 16px;
  }
`;

export const WithoutStatus = styled.div`
  margin-left: 52px;
`;

export const FirstRowText = styled(Text)`
  &&& {
    color: ${({ status }) =>
      status === 'PREPARANDO' ? colors.primary6 : colors.gray9};
    font-size: 16px;
    font-weight: 500;
    margin-left: 4px;
  }
`;

export const TimeText = styled(Text)`
  &&& {
    color: ${colors.gray7};
    font-size: 14px;
    font-weight: 400;
    margin: 16px;
  }
`;

export const SecondRowContainer = styled(Row)`
  width: 100%;
`;

export const SecondRowText = styled(Text)`
  &&& {
    margin-left: 56px;
    color: ${colors.gray8};
  }
`;

export const SecondRowResumeText = styled(Text)`
  &&& {
    margin-left: 56px;
    color: ${colors.gray8};
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
`;

export const SeeDetails = styled.span`
  &&& {
    margin-top: 5px;
    margin-left: 56px;
    color: ${colors.primary6};
    cursor: pointer;
  }
`;

export const ThirdRowContainer = styled(Row)`
  display: flex;
  flex-direction: ${({ $isResume }) => ($isResume ? 'column' : 'row')};
  align-items: flex-start;
  margin-left: 56px;
`;

export const UserIcon = styled(UserOutlined)`
  &&& {
    font-size: 16px;
    color: ${colors.gray7};
    margin: 16px 7px 16px 0;
  }
`;

export const ThirdRowText = styled(Text)`
  &&& {
    margin-right: 30px;
    color: ${colors.gray7};
    font-size: 14px;
    font-weight: 400;
  }
`;

export const FileContainer = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ isResume }) => (isResume ? '0' : '14px 0')};
  cursor: ${({ status }) => status === 'PRONTO' && 'pointer'};
`;

export const UserContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const FileIcon = styled(PaperClipOutlined)`
  &&& {
    font-size: 16px;
    color: ${colors.gray7};
    font-weight: 400;
  }
`;

export const FileText = styled(Text)`
  &&& {
    margin-left: 5px;
    color: ${({ $isExpired }) =>
      !$isExpired ? colors.primary6 : colors.gray7};
    cursor: ${({ $isExpired }) => ($isExpired ? 'not-allowed' : 'pointer')};
  }
`;
