import React from 'react';
import {
  onColumnFilter,
  columnFilters,
  getDefaultColumns,
} from 'helpers/generalService';
import { getDefaultColumnWidth } from 'helpers/tableService';
import { clearFiltersFunctions } from 'helpers/clearFiltersFunctions';
import store from 'store';
import {
  banksDetailsFiltersStart,
  banksDetailsFiltersZeroLength,
} from 'store/ducks/filters/details/banksDetailsFilters/actions';
import FilterDropdown from 'commons/Table/components/FilterDropdown';

let filteredColumns = store.getState().updateFilteredColumns?.filteredColumns;

const columns = (filtersData, toParamsFilters) => [
  {
    title: 'Filial',
    dataIndex: 'store_name',
    filteredValue: getDefaultColumns(toParamsFilters, 'store_name'),
    filters:
      filtersData.length > 0 ? columnFilters(filtersData, 'store_name') : [],
    onFilter: (value, record) => onColumnFilter(value, record, 'store_name'),
    onFilterDropdownVisibleChange: (visible) => {
      filteredColumns = store.getState().updateFilteredColumns?.filteredColumns;
      if (visible) {
        // This "zero length" dispatch is utterly important,
        // in order to keep each column state independent (Not passing data from one column to the next)
        store.dispatch(banksDetailsFiltersZeroLength());
        store.dispatch(
          banksDetailsFiltersStart({
            startDate: store.getState().updateScreenToScreenDate?.startDate,
            endDate: store.getState().updateScreenToScreenDate?.endDate,
            columnName: 'store_name',
            offset: 0,
            q:
              filteredColumns.length !== 0
                ? {
                    filter: filteredColumns,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.storeName = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <FilterDropdown
          key={filteredColumns}
          apiFilters={filtersData}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={banksDetailsFiltersStart}
          filteredColumns={
            store.getState().updateFilteredColumns?.filteredColumns
          }
          clearAction={banksDetailsFiltersZeroLength}
          columnName="store_name"
          storeName="banksDetailsFilters"
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: getDefaultColumnWidth('store_name'),
    align: 'left',
  },
  {
    title: 'Data do pagamento',
    dataIndex: 'transaction_date',
    filteredValue: getDefaultColumns(toParamsFilters, 'transaction_date'),
    filters: filtersData
      ? columnFilters(filtersData, 'transaction_date')
      : undefined,
    onFilter: (value, record) =>
      onColumnFilter(value, record, 'transaction_date'),
    onFilterDropdownVisibleChange: (visible) => {
      filteredColumns = store.getState().updateFilteredColumns?.filteredColumns;
      if (visible) {
        store.dispatch(banksDetailsFiltersZeroLength());
        store.dispatch(
          banksDetailsFiltersStart({
            startDate: store.getState().updateScreenToScreenDate?.startDate,
            endDate: store.getState().updateScreenToScreenDate?.endDate,
            columnName: 'transaction_date',
            offset: 0,
            q:
              filteredColumns.length !== 0
                ? {
                    filter: filteredColumns,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.transactionDate = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <FilterDropdown
          key={filteredColumns}
          apiFilters={filtersData}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={banksDetailsFiltersStart}
          filteredColumns={
            store.getState().updateFilteredColumns?.filteredColumns
          }
          clearAction={banksDetailsFiltersZeroLength}
          columnName="transaction_date"
          storeName="banksDetailsFilters"
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: getDefaultColumnWidth('transaction_date'),
    align: 'left',
  },
  {
    title: 'Adquirente',
    dataIndex: 'acquirer_name',
    filteredValue: getDefaultColumns(toParamsFilters, 'acquirer_name'),
    filters: filtersData
      ? columnFilters(filtersData, 'acquirer_name')
      : undefined,
    onFilter: (value, record) => onColumnFilter(value, record, 'acquirer_name'),
    onFilterDropdownVisibleChange: (visible) => {
      filteredColumns = store.getState().updateFilteredColumns?.filteredColumns;
      if (visible) {
        store.dispatch(banksDetailsFiltersZeroLength());
        store.dispatch(
          banksDetailsFiltersStart({
            startDate: store.getState().updateScreenToScreenDate?.startDate,
            endDate: store.getState().updateScreenToScreenDate?.endDate,
            columnName: 'acquirer_name',
            offset: 0,
            q:
              filteredColumns.length !== 0
                ? {
                    filter: filteredColumns,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.acquirerName = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <FilterDropdown
          key={filteredColumns}
          apiFilters={filtersData}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={banksDetailsFiltersStart}
          filteredColumns={
            store.getState().updateFilteredColumns?.filteredColumns
          }
          clearAction={banksDetailsFiltersZeroLength}
          columnName="acquirer_name"
          storeName="banksDetailsFilters"
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: getDefaultColumnWidth('acquirer_name'),
    align: 'left',
  },
  {
    title: 'Valor líquido (R$)',
    dataIndex: 'sum_receipt_amount',
    filteredValue: getDefaultColumns(toParamsFilters, 'sum_receipt_amount'),
    filters: filtersData
      ? columnFilters(filtersData, 'sum_receipt_amount')
      : undefined,
    onFilter: (value, record) =>
      onColumnFilter(value, record, 'sum_receipt_amount'),
    onFilterDropdownVisibleChange: (visible) => {
      filteredColumns = store.getState().updateFilteredColumns?.filteredColumns;
      if (visible) {
        store.dispatch(banksDetailsFiltersZeroLength());
        store.dispatch(
          banksDetailsFiltersStart({
            startDate: store.getState().updateScreenToScreenDate?.startDate,
            endDate: store.getState().updateScreenToScreenDate?.endDate,
            columnName: 'sum_receipt_amount',
            offset: 0,
            q:
              filteredColumns.length !== 0
                ? {
                    filter: filteredColumns,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.sumReceiptAmount = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <FilterDropdown
          key={filteredColumns}
          apiFilters={filtersData}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={banksDetailsFiltersStart}
          filteredColumns={
            store.getState().updateFilteredColumns?.filteredColumns
          }
          clearAction={banksDetailsFiltersZeroLength}
          columnName="sum_receipt_amount"
          storeName="banksDetailsFilters"
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: getDefaultColumnWidth('sum_receipt_amount'),
    align: 'left',
  },
  {
    title: 'Banco',
    dataIndex: 'bank_code',
    filteredValue: getDefaultColumns(toParamsFilters, 'bank_code'),
    filters: filtersData ? columnFilters(filtersData, 'bank_code') : undefined,
    onFilter: (value, record) => onColumnFilter(value, record, 'bank_code'),
    onFilterDropdownVisibleChange: (visible) => {
      filteredColumns = store.getState().updateFilteredColumns?.filteredColumns;
      if (visible) {
        store.dispatch(banksDetailsFiltersZeroLength());
        store.dispatch(
          banksDetailsFiltersStart({
            startDate: store.getState().updateScreenToScreenDate?.startDate,
            endDate: store.getState().updateScreenToScreenDate?.endDate,
            columnName: 'bank_code',
            offset: 0,
            q:
              filteredColumns.length !== 0
                ? {
                    filter: filteredColumns,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.bankCode = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <FilterDropdown
          key={filteredColumns}
          apiFilters={filtersData}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={banksDetailsFiltersStart}
          filteredColumns={
            store.getState().updateFilteredColumns?.filteredColumns
          }
          clearAction={banksDetailsFiltersZeroLength}
          columnName="bank_code"
          storeName="banksDetailsFilters"
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: getDefaultColumnWidth('bank_code'),
    align: 'left',
  },
  {
    title: 'Agência',
    dataIndex: 'bank_branch',
    filteredValue: getDefaultColumns(toParamsFilters, 'bank_branch'),
    filters: filtersData
      ? columnFilters(filtersData, 'bank_branch')
      : undefined,
    onFilter: (value, record) => onColumnFilter(value, record, 'bank_branch'),
    onFilterDropdownVisibleChange: (visible) => {
      filteredColumns = store.getState().updateFilteredColumns?.filteredColumns;
      if (visible) {
        store.dispatch(banksDetailsFiltersZeroLength());
        store.dispatch(
          banksDetailsFiltersStart({
            startDate: store.getState().updateScreenToScreenDate?.startDate,
            endDate: store.getState().updateScreenToScreenDate?.endDate,
            columnName: 'bank_branch',
            offset: 0,
            q:
              filteredColumns.length !== 0
                ? {
                    filter: filteredColumns,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.bankBranch = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <FilterDropdown
          key={filteredColumns}
          apiFilters={filtersData}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={banksDetailsFiltersStart}
          filteredColumns={
            store.getState().updateFilteredColumns?.filteredColumns
          }
          clearAction={banksDetailsFiltersZeroLength}
          columnName="bank_branch"
          storeName="banksDetailsFilters"
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: getDefaultColumnWidth('bank_branch'),
    align: 'left',
  },
  {
    title: 'Conta',
    dataIndex: 'bank_account',
    filteredValue: getDefaultColumns(toParamsFilters, 'bank_account'),
    filters: filtersData
      ? columnFilters(filtersData, 'bank_account')
      : undefined,
    onFilter: (value, record) => onColumnFilter(value, record, 'bank_account'),
    onFilterDropdownVisibleChange: (visible) => {
      filteredColumns = store.getState().updateFilteredColumns?.filteredColumns;
      if (visible) {
        store.dispatch(banksDetailsFiltersZeroLength());
        store.dispatch(
          banksDetailsFiltersStart({
            startDate: store.getState().updateScreenToScreenDate?.startDate,
            endDate: store.getState().updateScreenToScreenDate?.endDate,
            columnName: 'bank_account',
            offset: 0,
            q:
              filteredColumns.length !== 0
                ? {
                    filter: filteredColumns,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.bankAccount = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <FilterDropdown
          key={filteredColumns}
          apiFilters={filtersData}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={banksDetailsFiltersStart}
          filteredColumns={
            store.getState().updateFilteredColumns?.filteredColumns
          }
          clearAction={banksDetailsFiltersZeroLength}
          columnName="bank_account"
          storeName="banksDetailsFilters"
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: getDefaultColumnWidth('bank_account'),
    align: 'left',
  },
];

export default columns;
