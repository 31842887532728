import { action } from 'typesafe-actions';
import { actionTypes } from './types';

export const listReconciliationRulesStart = (data) =>
  action(actionTypes.LIST_RECONCILIATION_RULES_START, data);
export const listReconciliationRulesSuccess = (data) =>
  action(actionTypes.LIST_RECONCILIATION_RULES_SUCCESS, data);
export const listReconciliationRulesError = (errorMessage, error) =>
  action(
    actionTypes.LIST_RECONCILIATION_RULES_ERROR,
    { errorMessage },
    undefined,
    {
      error,
    }
  );
