import { createGlobalStyle } from 'styled-components';
import { colors } from './colors';

export const GlobalStyle = createGlobalStyle`

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}
html {
  font-size: 8px !important;
}

html,body,#root{
  height: calc(100% - 40px);
}

body {
  background-color: #f4f4f4 !important;
  color: #333;
}

p {
  word-break: break-all;
}

.ant-radio-wrapper {
    white-space: normal;
    word-break: break-word;
    text-align: left;
    margin-bottom: 40px;
  }

@media screen and (max-width: 1900px) {
  .ant-radio-wrapper {
    margin-bottom: 70px;
  }
}

.ant-btn {
  border-radius: 4px;
}

.ant-table table {
  border-collapse: collapse;
}
.ant-tabs-tab {
  font-size: 12px !important;
}

.ant-card-actions {
  margin-bottom: 4px !important;
}


.ant-tabs-nav {
  box-shadow: none;
}

.ant-table-thead > tr > th {
  color: ${colors.gray11};
  font-weight: bold;
}

a.ant-typography, .ant-typography a {
  color: #1790FF;
}

.table-row-line {
  border-left: 0px;
  border-right: 0px;
  border-bottom: 0px;
  border-top-width: 3px;
  border-bottom-width: 3px;
  border-color: #EBEBEB;
  border-style: solid;
  height: 6.75rem;
};

.month-text {
  color: red;
}

.react-resizable {
  position: relative;
  background-clip: padding-box;
}

.react-resizable-handle {
  position: absolute;
  width: 10px;
  height: 100%;
  bottom: 0;
  right: -5px;
  cursor: col-resize;
  z-index: 1;
}

/** Here is the vertical line displayed when offsetting, only the header has a vertical line;
 If you need to connect the form, please modify the height yourself, 1000 times the height of the header, and then hide something beyond the form (study yourself) */
.react-resizable-handle.active::before {
  content: '';
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;
  border-left: solid 1px ${colors.gray10};
}

.ant-table-thead {
  &&& {
    th {
      padding: 5px 0;
    }
  }
}

.ant-table-cell {
  &&& {
    height: 40px;
    padding: 5px;
    min-width: 200px;
  }
}

.ant-table-filter-trigger {
  &&& {
    margin: 0;
  }
}

.ant-table-body {
  min-height: 400px;
}

/* tile uploaded pictures */
.upload-list-inline .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-right: 8px;
}

.upload-list-inline [class*='-upload-list-rtl'] .ant-upload-list-item {
  float: right;
}

.recharts-bar-rectangle {
  cursor: pointer;
}

.ant-drawer-body {
  padding: 0 !important;
}

.ant-list {
  overflow-x: hidden !important;
}

a {
  color: #1890ff !important;
}

.ant-table-pagination.ant-pagination {
  margin: 16px 0;
}

.ant-table-pagination-right {
  justify-content: center !important;
}

.ant-pagination-item a {
  color: ${colors.gray11} !important;
}

.ant-pagination-item-active a {
  color: ${colors.primary6};
}

.ant-pagination-item-active {
  background-color: ${colors.primary6} !important;
  border-color: ${colors.primary6} !important;
}

.ant-pagination-item-active a {
  color: ${colors.gray1} !important;
}
`;
