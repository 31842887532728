import { api } from './api';

const transformer = (apiResponse) => {
  const data = {
    sales: apiResponse.data.sales,
    meta: apiResponse.meta,
  };
  return data;
};

const getSalesInfoFromAPI = async ({ startDate, endDate, type, page, perPage, sort, filter }) => {
  const config = {
    params: {
      start_date: startDate.format('YYYY-MM-DD'),
      end_date: endDate.format('YYYY-MM-DD'),
      type,
      page,
      per_page: perPage,
      order_by: sort,
      filter,
    },
  };
  const response = await api().get('/sales', config);
  return transformer(response.data);
};

export default getSalesInfoFromAPI;
