import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { StSubtitle, StTitle } from '../styled';
import { StSearchBarContainer, StSearchHeaderContainer } from './styled';
import SearchBar from './SearchBar';

const SearchHeader = ({
  onClientSearch,
  onPartnerIdSearch,
  onUserSearch,
  onClientSearchInputChange,
  onPartnerIdSearchInputChange,
  onUserSearchInputChange,
  clientSearchText,
  partnerIdSearchText,
  userSearchText,
}) => {
  return (
    <StSearchHeaderContainer>
      <Col span={24}>
        <StTitle>Campos de pesquisa</StTitle>
      </Col>
      <Col span={24}>
        <StSubtitle>
          Pesquise contas na base de dados através dos identificadores de
          empresa, parceiro e usuário
        </StSubtitle>
      </Col>
      <StSearchBarContainer>
        <Row gutter={32}>
          <Col span={8}>
            <SearchBar
              searchTopic="empresa"
              onSearch={onClientSearch}
              onChange={(searchText) => {
                onClientSearchInputChange(searchText);
              }}
              value={clientSearchText}
            />
          </Col>
          <Col span={8}>
            <SearchBar
              searchTopic="parceiro"
              onSearch={onPartnerIdSearch}
              onChange={(searchText) => {
                onPartnerIdSearchInputChange(searchText);
              }}
              value={partnerIdSearchText}
            />
          </Col>
          <Col span={8}>
            <SearchBar
              searchTopic="usuário"
              onSearch={onUserSearch}
              onChange={(searchText) => {
                onUserSearchInputChange(searchText);
              }}
              value={userSearchText}
            />
          </Col>
        </Row>
      </StSearchBarContainer>
    </StSearchHeaderContainer>
  );
};

SearchHeader.propTypes = {
  onClientSearch: PropTypes.func.isRequired,
  onPartnerIdSearch: PropTypes.func.isRequired,
  onUserSearch: PropTypes.func.isRequired,
  onClientSearchInputChange: PropTypes.func.isRequired,
  onPartnerIdSearchInputChange: PropTypes.func.isRequired,
  onUserSearchInputChange: PropTypes.func.isRequired,
  clientSearchText: PropTypes.string.isRequired,
  partnerIdSearchText: PropTypes.string.isRequired,
  userSearchText: PropTypes.string.isRequired,
};

export default SearchHeader;
