import React from 'react';

import { Tooltip } from 'antd';

const Wrapper = React.memo(
  React.forwardRef((props, ref) => <span ref={ref} {...props} />)
);

export function TooltipWithCustomRef({ children, ...rest }) {
  return (
    <Tooltip {...rest}>
      <Wrapper>{children}</Wrapper>
    </Tooltip>
  );
}
