import { call, put } from 'redux-saga/effects';

import errorHandler from 'helpers/errorHandler';

import {
  unfilteredGeneralListOfClientsSuccess,
  unfilteredGeneralListOfClientsError,
} from 'store/ducks/unfilteredGeneralListOfClients/actions';

import getGeneralListOfClientsFromAPI from 'services/generalListOfClients';

export function* unfilteredGeneralListOfClientsSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(getGeneralListOfClientsFromAPI, payload);
    yield put(unfilteredGeneralListOfClientsSuccess(apiData));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        unfilteredGeneralListOfClientsError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(unfilteredGeneralListOfClientsError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}
