export const clearFiltersFunctions = {
  storeName: () => {},
  saleDate: () => {},
  forecastDate: () => {},
  acquirerName: () => {},
  brandCode: () => {},
  nsu: () => {},
  planInstallments: () => {},
  installmentGrossAmount: () => {},
  admFeePercent: () => {},
  installmentAdmFeeAmount: () => {},
  installmentNetAmount: () => {},
  productCode: () => {},
  acquireAuthorization: () => {},
  cardNumber: () => {},
  bankCode: () => {},
  bankBranch: () => {},
  bankAccount: () => {},
  captureMethodCode: () => {},
  merchantCode: () => {},
  summary: () => {},
  summaryCielo: () => {},
  saleTime: () => {},
  tid: () => {},
  posNumber: () => {},
  installmentAdqFareAmount: () => {},
  instSchemefeeFeeAmount: () => {},
  instInterchangeFeeAmount: () => {},

  // paymentsDivergences
  reason: () => {},
  recInstallmentNetAmount: () => {},
  payInstallmentNetAmount: () => {},
  diffRecNetAmount: () => {},
  forecastInstPendentNetAmount: () => {},
  recAdmFeePercent: () => {},
  payInstAdmFeePercent: () => {},
  paymentDate: () => {},
  latePaymentsCount: () => {},
  description: () => {},
  totalRecForecastInstNetAmount: () => {},
  totalPayForecastInstNetAmount: () => {},

  // banks
  advanceFeePercent: () => {},
  instAdvanceFeeAmount: () => {},
  instAdvanceNetAmount: () => {},
  instSplitAdqFareAmount: () => {},
  instTransferAmount: () => {},
  sumReceiptAmount: () => {},

  // refunds
  cancelNetAmount: () => {},

  // search
  event: () => {},
  idFileTracking: () => {},
  fileName: () => {},

  // salesReconciliationReports
  status: () => {},
  source: () => {},
  customerInstallmentGrossAmount: () => {},
  clientSaleId: () => {},
  reconSaleCstGrossAmount: () => {},
  nrecSaleCstGrossAmount: () => {},
  reconSaleGrossAmount: () => {},
  nrecSaleGrossAmount: () => {},

  // paymentsReconciliationReport
  transactionDate: () => {},
  recInstallmentGrossAmount: () => {},
  payInstallmentGrossAmount: () => {},
  inputMode: () => {},
  forReconInstallmentNetAmount: () => {},
  payForecastInstNetAmount: () => {},
  payReconInstallmentNetAmount: () => {},
  payConfirmationInstNetAmount: () => {},
  recConfirmationInstNetAmount: () => {},

  // bankReconciliationReport
  forecastNetAmount: () => {},
  paymentNetAmount: () => {},

  // advancementsReports
  glDate: () => {},
  sumInstAdvanceNetAmount: () => {},
  sumInstAdmFareAmount: () => {},
  sumInstAdvanceFeeAmount: () => {},

  // SalesReports
  saleGrossAmount: () => {},
  saleNetAmount: () => {},

  // AdjustmentReports
  transactionType: () => {},
  sumAdjustmentNetAmount: () => {},

  // RefundsReports
  sumCancelGrossAmount: () => {},
  sumCancelNetAmount: () => {},

  // DuedateReports
  sumInstAdmFeeAmount: () => {},
  sumInstAdqFareAmount: () => {},
  sumInstSplitAdqFareAmount: () => {},
  sumInstTransferAmount: () => {},

  // Fees
  startDate: () => {},
  endDate: () => {},
  feePercent: () => {},
  feePercentFmt: () => {},
  brCnpjShortName: () => {},
  planStart: () => {},
  planEnd: () => {},
  updatedAt: () => {},
  createdAt: () => {},

  // radar
  idtRadar: () => {},
  clientCode: () => {},
  startedAt: () => {},
  endedAt: () => {},
  type: () => {},
  isTest: () => {},
  emailRequester: () => {},
  statusMessage: () => {},
};
