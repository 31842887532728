import { call, put } from 'redux-saga/effects';
import errorHandler from 'helpers/errorHandler';
import {
  listStoresSuccess,
  listStoresError,
} from 'store/ducks/cnpjMaintenance/listStores/actions';
import getListStoresFromAPI from 'services/cnpjMaintenance/listStores';

export function* listStoresSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(getListStoresFromAPI, payload);
    yield put(listStoresSuccess(apiData));
  } catch (err) {
    yield put(listStoresError('Ocorreu um erro:', err));
    if (err?.response?.data?.message) {
      yield put(
        listStoresError(err.response.data.message, err.response.data.error)
      );
      errorHandler(err.response.data.message, err.response.data.error);
    } else {
      yield put(listStoresError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
      errorHandler(err.response.data.message, err.response.data.error);
    }
  }
}
