import { action } from 'typesafe-actions';
import { actionTypes } from './types';

export const deleteUser = (data) =>
  action(actionTypes.DELETE_USER_START, { data });
export const deleteUserSuccess = (data) =>
  action(actionTypes.DELETE_USER_SUCCESS, data);
export const deleteUserError = (errorMessage, error) =>
  action(actionTypes.DELETE_USER_ERROR, { errorMessage }, undefined, { error });
export const resetSuccess = (data) => action(actionTypes.RESET_SUCCESS, data);
