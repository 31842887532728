import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import { Filters } from 'commons/Intermediate/Filters';
import { ContentTabs } from 'components/Intermediate/SalesReconciliation/components';
import { salesReconciledStart } from 'store/ducks/reconciled/sales/actions';
import { salesSummaryReconciledStart } from 'store/ducks/reconciled/summary/sales/actions';
import { reconciledSalesInfoFiltersStart } from 'store/ducks/filters/reconciledSalesInfoFilters/actions';
import { disableLastMonth } from 'helpers/disableDates';
import { useResetFilters } from 'utils/hooks/useResetFilters';
import { updateReconciledSalesDetailsFilters } from 'store/ducks/filters/details/reconciledSalesDetailsFilters/actions';
import { onSorter } from 'helpers/sorter';
import { useRangePickerChange } from 'utils/hooks/useRangePickerChange';

const SalesReconciliationIntermediate = () => {
  const dispatch = useDispatch();

  const startDate = useSelector(
    (state) => state.updateScreenToScreenDate.startDate
  );
  const endDate = useSelector(
    (state) => state.updateScreenToScreenDate.endDate
  );

  const [sort, setSort] = useState('DESC');

  const [filtersDrawer, toggleFiltersDrawer] = useState(false);

  const [activeTab, setActiveTab] = useState('branches');

  const [filterName, setFilterName] = useState(null);

  const salesSummaryReconciledLoading = useSelector(
    (state) => state?.salesSummaryReconciled?.loading
  );

  const salesSummaryReconciledData = useSelector(
    (state) => state?.salesSummaryReconciled?.summary
  );

  const salesReconciledLoading = useSelector(
    (state) => state?.salesReconciled?.loading
  );

  const salesReconciledData = useSelector(
    (state) => state?.salesReconciled?.data
  );

  const reconciledSalesInfoFilters = useSelector(
    (state) => state.reconciledSalesInfoFilters.filters
  );

  const loading = salesReconciledLoading || salesSummaryReconciledLoading;

  const [calendarIsOpen, setCalendarIsOpen] = useState(false);

  const { onRangePickerChange } = useRangePickerChange();

  useEffect(() => {
    dispatch(
      salesReconciledStart({
        type: activeTab,
        orderBy: sort,
        filter: filterName,
        startDate,
        endDate,
      })
    );
    dispatch(
      reconciledSalesInfoFiltersStart({ startDate, endDate, type: activeTab })
    );
    dispatch(
      salesSummaryReconciledStart({
        startDate,
        endDate,
      })
    );
  }, [activeTab, dispatch, endDate, filterName, sort, startDate]);

  const onRangePickerOpenOrCloseChange = (open) => {
    setCalendarIsOpen(open);
  };

  const onChangeSelectFilter = (val) => {
    if (val === 'Todos' || val === 'Buscar' || val === '') {
      setFilterName(null);
    } else {
      setFilterName(val);
    }
  };

  const onTabClick = (key) => {
    setActiveTab(key);
    onChangeSelectFilter(null);
  };

  const { resetFilters } = useResetFilters();

  useEffect(() => {
    resetFilters(updateReconciledSalesDetailsFilters);
  }, [resetFilters]);

  return (
    <>
      <Filters
        title="Conciliação de vendas"
        subtitle="Verifique suas vendas conciliadas e não conciliadas nas adquirentes e nos estabelecimentos"
        onRangePickerOpenOrCloseChange={onRangePickerOpenOrCloseChange}
        hasRangePicker
        hasSelect
        hasSorter
        dateDefaultValue={[startDate, endDate]}
        onRangePickerChange={(dates) =>
          onRangePickerChange(dates, calendarIsOpen)
        }
        onChangeSelectFilter={onChangeSelectFilter}
        loading={loading}
        sort={sort}
        sorter={onSorter(sort, setSort)}
        filtersDrawer={filtersDrawer}
        filterName={filterName}
        renderFilterButton={() => (
          <Button
            style={{ borderRadius: 4 }}
            onClick={() => toggleFiltersDrawer(!filtersDrawer)}
          >
            Filtros
          </Button>
        )}
        onCloseFiltersDrawer={() => toggleFiltersDrawer(false)}
        sorterButtonText="Vendas nos estabelecimentos"
        infoFiltersToSelect={reconciledSalesInfoFilters}
        activeTab={activeTab}
        disabledDate={disableLastMonth}
        calendarIsOpen={calendarIsOpen}
        setCalendarIsOpen={setCalendarIsOpen}
      />
      <ContentTabs
        startDate={startDate}
        endDate={endDate}
        infoData={salesReconciledData}
        infoLoading={salesReconciledLoading}
        isInfoError={false}
        infoCount={8}
        summaryData={salesSummaryReconciledData}
        summaryLoading={salesSummaryReconciledLoading}
        isSummaryError={false}
        onTabClick={onTabClick}
        activeTab={activeTab}
        infoFilter={filterName}
      />
    </>
  );
};

export default SalesReconciliationIntermediate;
