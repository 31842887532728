import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { getSessionAccessToken } from 'helpers/sessionService';

export const PublicRoute = () => {
  const isAuthenticated = getSessionAccessToken();

  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  return <Outlet />;
};
