import { lambdaApi } from '../api';

const transformer = (apiResponse) => {
  return {
    emailsCount: apiResponse?.data?.result,
  };
};

export const getEmailCount = async ({ type }) => {
  const response = await lambdaApi().get(`/radar/process/count?type=${type}`);
  return transformer(response.data);
};
