export const actionTypes = {
  CUSTOM_UPDATE_FILTERED_COLUMNS_START:
    '@customUpdateFilteredColumns/CUSTOM_UPDATE_FILTERED_COLUMNS_START',
  CUSTOM_UPDATE_FILTERED_COLUMNS_SUCCESS:
    '@customUpdateFilteredColumns/CUSTOM_UPDATE_FILTERED_COLUMNS_SUCCESS',
  CUSTOM_UPDATE_FILTERED_COLUMNS_ERROR:
    '@customUpdateFilteredColumns/CUSTOM_UPDATE_FILTERED_COLUMNS_ERROR',
  CUSTOM_RESET_UPDATE_FILTERED_COLUMNS_START:
    '@customUpdateFilteredColumns/CUSTOM_RESET_UPDATE_FILTERED_COLUMNS_START',
  CUSTOM_RESET_UPDATE_FILTERED_COLUMNS_SUCCESS:
    '@customUpdateFilteredColumns/CUSTOM_RESET_UPDATE_FILTERED_COLUMNS_SUCCESS',
  CUSTOM_RESET_UPDATE_FILTERED_COLUMNS_ERROR:
    '@customUpdateFilteredColumns/CUSTOM_RESET_UPDATE_FILTERED_COLUMNS_ERROR',
};
