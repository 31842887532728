import React from 'react';
import PropTypes from 'prop-types';
import { BarChart, Bar, XAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { MonthlyBarChartTooltip } from 'commons/MonthlyBarChart/components';
import CustomizedAxisTick from './components/CustomizedAxisTick';
import { updateScreenToScreenDateStart } from 'store/ducks/updateScreenToScreenDate/actions';
import { useDispatch } from 'react-redux';
import { convertRechartsLabelToPeriod } from 'helpers/generalService';
import customHistory from 'helpers/history';
import { colors } from 'styles/colors';

const MonthlyBarChart = ({
  data,
  dataKeyAxis,
  dataKeyBar,
  fontSize = 12,
  ...rest
}) => {
  const dispatch = useDispatch();

  return (
    <ResponsiveContainer height={215} {...rest}>
      <BarChart
        data={data}
        onClick={(e) => {
          const activeLabel = e?.activeLabel;
          if (!activeLabel) return;
          const { startDate, endDate } =
            convertRechartsLabelToPeriod(activeLabel);
          dispatch(
            updateScreenToScreenDateStart({
              startDate,
              endDate,
            })
          );
          customHistory.push('/valores-a-receber');
        }}
      >
        <XAxis
          dataKey={dataKeyAxis}
          height={32}
          minTickGap={6}
          interval={0}
          tick={<CustomizedAxisTick fontSize={fontSize} />}
        />
        <Tooltip
          content={({ active, payload, label = '' }) => (
            <MonthlyBarChartTooltip
              active={active}
              payload={payload}
              label={label}
            />
          )}
          wrapperStyle={{
            background: colors.gray11,
            borderRadius: '2px',
          }}
        />
        <Bar
          dataKey={dataKeyBar}
          fill="#75bbcb"
          background={false}
          radius={[1.83906, 1.83906, 0, 0]}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

MonthlyBarChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      month: PropTypes.string,
      value: PropTypes.number,
    })
  ).isRequired,
  dataKeyAxis: PropTypes.string.isRequired,
  dataKeyBar: PropTypes.string.isRequired,
};

export default MonthlyBarChart;
