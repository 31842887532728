import { action } from 'typesafe-actions';
import { actionTypes } from 'store/ducks/radar/radarRunTest/types';

export const updateRadarHasBeenSentStart = (data) =>
  action(actionTypes.UPDATE_RADAR_HAS_BEEN_SENT, data);
export const radarRunTestStart = (data) =>
  action(actionTypes.RADAR_RUN_TEST_START, data);
export const radarRunTestSuccess = (data) =>
  action(actionTypes.RADAR_RUN_TEST_SUCCESS, data);
export const radarRunTestError = (errorMessage, error) =>
  action(actionTypes.RADAR_RUN_TEST_ERROR, { errorMessage }, undefined, {
    error,
  });
