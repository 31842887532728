// TODO: Remove camelcase eslint rule
/* eslint-disable camelcase,array-callback-return,no-param-reassign */
import { api } from '../api';

const transformer = (apiResponse) => {
  const { data } = apiResponse;
  return {
    payments: [
      {
        title: 'Valores recebidos sem desconto',
        value: new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(data.gross_amount),
      },
      {
        title: 'Taxa administrativa',
        value: new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(data.adm_fee_amount),
      },
      {
        title: 'Total',
        value: new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(data.net_amount),
      },
      {
        title: 'Tarifa',
        value: new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(data.adq_fare_amount),
      },
      {
        title: 'Repasse',
        value: new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(data.transfer_amount),
      },
    ],
    start_date: apiResponse.meta.start_date,
    end_date: apiResponse.meta.end_date,
  };
};

const getDuedatePayments = async ({ startDate, endDate }) => {
  const config = {
    params: {
      start_date: startDate.format('YYYY-MM-DD'),
      end_date: endDate.format('YYYY-MM-DD'),
      client_id: localStorage.getItem('concil-card-clientId'),
    },
  };
  const response = await api().get('/summary/duedate/payments', config);
  return transformer(response.data);
};

export default getDuedatePayments;
