import React from 'react';
import { useDispatch } from 'react-redux';
import { Col, Input, Modal, Radio, Row, Skeleton } from 'antd';
import { changeStoreStart } from 'store/ducks/changeStore/actions';
import { StUsernameSubtitle } from '../../GeneralUserManagementHeader/components/styled';
import { EllipsisText } from 'commons/EllipsisText';
import { colors } from 'styles/colors';

export const DefaultModal = ({
  isModalVisible,
  setIsModalVisible,
  generalListOfClients,
  generalListOfClientsLoading,
  listOfClients,
  setListOfClients,
  currentRowData,
}) => {
  const dispatch = useDispatch();
  const { email, clientCode, clientName, userId } = currentRowData;

  return (
    <Modal
      title="Trocar empresa padrão"
      open={isModalVisible?.default}
      style={{ maxWidth: '30%' }}
      onCancel={() => setIsModalVisible({ default: !isModalVisible?.default })}
      footer={null}
      maskClosable
    >
      <Row>
        <Col>
          <StUsernameSubtitle>
            Selecione a empresa padrão que deseja manter para o usuário:{' '}
            <span style={{ color: colors.primary6 }}>
              <EllipsisText text={email || ''} length={30} />
            </span>
          </StUsernameSubtitle>
        </Col>
      </Row>
      <Row style={{ marginTop: '22px' }}>
        <Col>
          <Radio style={{ marginBottom: '16px' }} checked>
            {clientCode} {clientName}
          </Radio>
        </Col>
      </Row>
      <Row style={{ marginTop: '22px' }}>
        <Col span={24}>
          <Input.Search
            placeholder="Insira o Código ou nome da empresa"
            enterButton
            style={{ width: '100%' }}
            onSearch={(searchText) => {
              const list = generalListOfClients
                .filter(
                  (client, index, self) =>
                    index ===
                    self.findIndex((c) => c.client_id === client.client_id)
                )
                .filter((client) => {
                  return client.client_name
                    .toLowerCase()
                    .includes(searchText.toLowerCase());
                });
              setListOfClients(list);
            }}
          />
        </Col>
      </Row>
      <Skeleton
        loading={generalListOfClientsLoading}
        active
        paragraph={{ rows: 10 }}
      >
        <Row
          style={{
            marginTop: '22px',
            maxHeight: '400px',
            overflowY: 'scroll',
          }}
        >
          <Col>
            <Radio.Group
              onChange={(option) => {
                if (userId) {
                  dispatch(
                    changeStoreStart({
                      user_id: userId,
                      client_id: option?.target?.value?.client_id,
                      updateLocalStorage: false,
                    })
                  );
                }
              }}
            >
              {listOfClients.map((client) => {
                return (
                  <Row key={client.client_id}>
                    <Col>
                      <Radio value={client} style={{ marginBottom: '16px' }}>
                        {client.client_code} {client.client_name}
                      </Radio>
                    </Col>
                  </Row>
                );
              })}
            </Radio.Group>
          </Col>
        </Row>
      </Skeleton>
    </Modal>
  );
};
