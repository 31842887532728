import { action } from 'typesafe-actions';
import { actionTypes } from './types';

export const putStoresStart = (data) =>
  action(actionTypes.PUT_STORES_START, data);
export const putStoresSuccess = (data) =>
  action(actionTypes.PUT_STORES_SUCCESS, data);
export const putStoresError = (errorMessage, error) =>
  action(actionTypes.PUT_STORES_ERROR, { errorMessage }, undefined, {
    error,
  });
