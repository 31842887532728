import styled from 'styled-components';
import { Col, Row } from 'antd';
import { colors } from 'styles/colors';

export const DashboardFooterRow = styled(Row)`
  &&& {
    position: absolute;
    width: 100%;
    bottom: 0;
    margin-top: 18px;
    padding: 9px 16px;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
  }
`;

export const DashboardFooterCol = styled(Col)`
  &&& {
    display: flex;
    align-items: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: ${colors.blue6};
    :hover {
      text-decoration-line: underline;
      cursor: pointer;
    }
  }
`;
