import React from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import * as St from './styled';
import { dataTestIdHandler } from 'helpers/dataTestIdHandler';

function Footer({
  isFlipped,
  setIsFlipped,
  reconciliationType,
  dataTestPrefix,
}) {
  return (
    <St.DashboardFooterRow
      justify="end"
      {...dataTestIdHandler(dataTestPrefix, 'footer')}
    >
      <St.DashboardFooterCol>
        <span
          onClick={() => {
            setIsFlipped({
              ...isFlipped,
              [reconciliationType]: !isFlipped[reconciliationType],
            });
          }}
        >
          {reconciliationType === 'bank' && 'Ver bancos'}
          {reconciliationType === 'payments' && 'Ver pagamentos'}
          {reconciliationType === 'sales' && 'Ver vendas'}
          <ArrowLeftOutlined style={{ marginLeft: '8px' }} />
        </span>{' '}
      </St.DashboardFooterCol>
    </St.DashboardFooterRow>
  );
}

export default Footer;
