import { api } from '../api';

const transformer = (apiResponse, acquirerName) => {
  return {
    [acquirerName]: apiResponse.data,
  };
};

export const getUploadedLetterUrl = async ({ payload }) => {
  const acquirerName = payload?.data?.acquirerName;
  const response = await api().get(`/letter/url/${acquirerName}`);
  return transformer(response.data, acquirerName);
};

export const deleteUploadedLetterUrl = async ({ payload }) => {
  const acquirerName = payload?.data?.acquirerName;
  const urlKey = payload?.data?.urlKey;
  const config = {
    params: {
      urlKey,
    },
  };
  const response = await api().delete(`/letter/url/${acquirerName}`, config);
  return transformer(response.data, acquirerName);
};
