import React from 'react';
import { Card, Col, Row } from 'antd';
import { ErrorCard } from 'commons/ErrorCard';

function UnavailableData({ loadingList }) {
  return (
    <Row style={{ marginTop: '192px' }}>
      <Col lg={10} md={18}>
        <Card style={{ borderRadius: 4 }} loading={loadingList}>
          <ErrorCard
            title="Dados indisponíveis"
            reason="Não conseguimos buscar os dados, mas já estamos trabalhando para normalizar a situação."
            recommendation="Tente atualizar a página em alguns instantes."
          />
        </Card>
      </Col>
    </Row>
  );
}

export default UnavailableData;
