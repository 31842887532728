export const handleGroup = (value) => {
  const result = {
    sale: 'Venda',
    payment: 'Pagamento',
  };

  return result[value] || value;
};

export const handleUnderscore = (value) => value.replace('_', '-');

// export const handleHyphen = (value) => value.replace('-', '_');

export const handleReconGroupType = (value) => {
  const result = {
    '1-1': '1_1',
    'N-N': 'N_N',
  };
  return result[value] || value;
};

export const handleStatus = (value) => {
  const result = {
    true: 'Ativo',
    false: 'Inativo',
  };

  return result[value] || value;
};

export const handleCategory = (value) => {
  const result = {
    custom: 'Personalizada',
    default: 'Padrão',
  };

  return result[value] || value;
};

export const translateGroupOfRules = (rule) => {
  const result = {
    Vendas: 'sale',
    Pagamentos: 'payment',
  };

  return result[rule] || '';
};

export const handleStatusBr = (status) => {
  const result = {
    Ativo: true,
    Inativo: false,
  };

  return result[status];
};
