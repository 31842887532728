import { call, put } from 'redux-saga/effects';
import errorHandler from 'helpers/errorHandler';
import { listProcessDetail } from 'services/radar/listProcessDetail';
import {
  radarGetSuccess,
  radarGetError,
} from 'store/ducks/radar/radarGet/actions';

export function* radarGetSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(listProcessDetail, payload);
    yield put(radarGetSuccess(apiData));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        radarGetError(err.response.data.error.message, err.response.data.error)
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      errorHandler(String(err));
      yield put(radarGetError(err));
    }
  }
}
