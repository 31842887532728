import { action } from 'typesafe-actions';
import { actionTypes } from 'store/ducks/searchTransaction/types';

export const searchTransactionStart = (data) =>
  action(actionTypes.SEARCH_TRANSACTION_START, data);
export const searchTransactionSuccess = (data) =>
  action(actionTypes.SEARCH_TRANSACTION_SUCCESS, data);
export const searchTransactionError = (errorMessage, error) =>
  action(actionTypes.SEARCH_TRANSACTION_ERROR, { errorMessage }, undefined, {
    error,
  });
