import { bankingApi } from '../api';

const transformer = (apiResponse) => {
  return {
    payload: apiResponse?.payload,
  };
};

// Expira Radares executando há mais de 24 horas, este processo executa em schedule diário, não deve ser necessário acionar API
export const expire = async () => {
  const response = await bankingApi().get('/radar/expire');
  return transformer(response.data);
};
