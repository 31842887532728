// TODO: Remove camelcase eslint rule
/* eslint-disable camelcase,array-callback-return,no-param-reassign */
import { api } from '../api';

const transformer = (apiResponse) => {
  const { data } = apiResponse;
  return {
    payments: [
      {
        title: 'Ajustes a débito',
        value: new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(data.debit_adjustment_gross_amount),
      },
      {
        title: 'Ajustes a crédito',
        value: new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(data.credit_adjustment_gross_amount),
      },
      {
        title: 'Total',
        value: new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(data.total),
      },
      {
        title: 'Aluguel de POS',
        value: new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(data.rent_adjustment_gross_amount),
      },
    ],
    start_date: apiResponse.meta.start_date,
    end_date: apiResponse.meta.end_date,
  };
};

const getAdjustmentPayments = async ({ startDate, endDate }) => {
  const config = {
    params: {
      start_date: startDate.format('YYYY-MM-DD'),
      end_date: endDate.format('YYYY-MM-DD'),
      client_id: localStorage.getItem('concil-card-clientId'),
    },
  };
  const response = await api().get('/summary/adjustment/payments', config);
  return transformer(response.data);
};

export default getAdjustmentPayments;
