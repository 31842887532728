import { actionTypes } from './types';

const INITIAL_STATE = {
  error: false,
  loading: true,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case actionTypes.LOGOUT_START:
    return { ...state, loading: true, error: false };
  case actionTypes.LOGOUT_SUCCESS:
    return { ...state, loading: false, error: false };
  case actionTypes.LOGOUT_ERROR:
    return {
      ...state,
      loading: false,
      error: action.error.error,
      errorMessage: action.payload,
    };
  default:
    return state;
  }
};

export default reducer;
