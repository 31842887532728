/* eslint-disable no-param-reassign */
// TODO: Remove camelcase eslint rule
/* eslint-disable camelcase */
/* eslint-disable prefer-const */
/* eslint-disable array-callback-return */
/* eslint-disable prefer-destructuring */
import { s3File } from '../api';
import { decrypt, getHash } from 'helpers/encryptionService';
import { paginate } from 'helpers/generalService';
import store from 'store';
import dayjs from 'dayjs';

const today = new Date();
const formattedToday = dayjs(today).format('DD/MM');
const yesterday = dayjs().subtract(1, 'day').format('DD/MM');

const transformerResumeNotifications = (resumeNotifications) => {
  let resumeObject = {};
  let resumeToday = [];
  let resumeYesterday = [];
  let resumeOld = [];

  resumeNotifications.map((notification) => {
    const notificationDate = dayjs(notification.created_at).format('DD/MM');
    const notificationStatus = notification.status.trim();
    const is_expired = dayjs(notification.created_at)
      .add(7, 'days')
      .isBefore(dayjs());
    notification = {
      ...notification,
      is_expired,
      status:
        is_expired && notificationStatus === 'PRONTO'
          ? 'EXPIRADO'
          : notificationStatus,
      title:
        is_expired && notificationStatus === 'PRONTO'
          ? 'Arquivo expirado'
          : notification.title,
    };

    if (notificationDate === formattedToday) {
      resumeToday.push(notification);
    }
    if (notificationDate === yesterday) {
      resumeYesterday.push(notification);
    }
    if (notificationDate !== formattedToday && notificationDate !== yesterday) {
      resumeOld.push(notification);
    }

    resumeObject = {
      resumeToday,
      resumeYesterday,
      resumeOld,
    };
  });

  return resumeObject;
};

const transformer = (apiResponse, page, perPage) => {
  const resumeNotifications = transformerResumeNotifications(
    apiResponse.resumeNotifications
  );

  const responseNotifications = paginate(
    apiResponse?.notifications,
    page,
    perPage
  );

  let notificationsObject = {};
  let todayNotifications = [];
  let yesterdayNotifications = [];
  let oldNotifications = [];
  let hasUnreadNotifications;
  let unreadNotifications = [];
  const notificationPage = responseNotifications?.page;
  const notificationPerPage = responseNotifications?.perPage;
  const notificationTotalPages = responseNotifications?.totalPages;
  const notificationTotalItems = responseNotifications?.total;

  hasUnreadNotifications = responseNotifications?.data.some(
    (notification) => notification.is_read === false
  );

  responseNotifications.data.map((notification) => {
    const notificationDate = dayjs(notification.created_at).format('DD/MM');
    const notificationStatus = notification.status.trim();
    const is_expired = dayjs(notification.created_at)
      .add(7, 'days')
      .isBefore(dayjs());
    notification = {
      ...notification,
      is_expired,
      status:
        is_expired && notificationStatus === 'PRONTO'
          ? 'EXPIRADO'
          : notificationStatus,
      title:
        is_expired && notificationStatus === 'PRONTO'
          ? 'Arquivo expirado'
          : notification.title,
    };

    // Separates notifications by date
    if (notificationDate === formattedToday) {
      todayNotifications.push(notification);
    }
    if (notificationDate === yesterday) {
      yesterdayNotifications.push(notification);
    }
    if (notificationDate !== formattedToday && notificationDate !== yesterday) {
      oldNotifications.push(notification);
    }

    notificationsObject = {
      todayNotifications,
      yesterdayNotifications,
      oldNotifications,
      hasUnreadNotifications,
      resumeNotifications,
      unreadNotifications,
      notificationPage,
      notificationPerPage,
      notificationTotalPages,
      notificationTotalItems,
      alertHasBeenSent: {
        ...store.getState().notifications.alertHasBeenSent,
        [notification.notification_id]: true,
      },
    };
  });

  return notificationsObject;
};

// Get notification
export const getNotification = async ({
  userId,
  scopeId,
  clientId,
  page,
  perPage,
}) => {
  const hashedClientId = getHash(clientId);
  const hashedScopeId = getHash(scopeId);
  const hashedUserId = getHash(userId);

  const fileUrl = `${process.env.REACT_APP_S3_NOTIFICATIONS_URL}/${hashedClientId}/${hashedScopeId}/${hashedUserId}`;

  const response = await s3File(fileUrl).get('');
  const decryptedResponseData = decrypt(
    response?.data,
    process.env.REACT_APP_NOTIFICATION_ENCRYPTION_KEY
  );
  return transformer(decryptedResponseData, page, perPage);
};
