import React from 'react';
import { colors } from 'styles/colors';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';

export const radarStatusTypes = {
  Erro: {
    fontColor: colors.red6,
    borderColor: colors.red3,
    backgroundColor: colors.red1,
    icon: <CloseCircleOutlined />,
    iconColor: colors.red6,
  },
  Sucesso: {
    fontColor: colors.green6,
    borderColor: colors.green3,
    backgroundColor: colors.green1,
    icon: <CheckCircleOutlined />,
    iconColor: colors.green6,
  },
  Problema: {
    fontColor: colors.gold6,
    borderColor: colors.gold3,
    backgroundColor: colors.gold1,
    icon: <ExclamationCircleOutlined />,
    iconColor: colors.gold6,
  },
  Andamento: {
    fontColor: colors.primary6,
    borderColor: colors.primary3,
    backgroundColor: colors.primary1,
    icon: <SyncOutlined />,
    iconColor: colors.primary6,
  },
};
