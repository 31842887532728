/* eslint-disable no-plusplus */
import { lambdaApi } from '../../api';

const transform = (response, columnName) => {
  const filters = {
    results: [
      {
        text: columnName,
        values: response?.data?.results,
      },
    ],
    hasMore: response?.meta?.has_next,
    page: response?.meta?.page,
    perPage: response?.meta?.per_page,
    column: response?.meta?.column,
  };
  return filters;
};

const getSearchTransactionDetailsFiltersFromAPI = async ({
  searchOption,
  searchText,
  columnName,
  page,
  perPage,
  searchBy,
  q,
}) => {
  const config = {
    params: {
      field: searchOption,
      value: searchText,
      column: columnName,
      page,
      per_page: perPage,
      type: 'detail',
      search_by: searchBy,
      q,
    },
  };
  const response = await lambdaApi().get(
    '/transactions/detail/filters',
    config
  );
  const transformedResponse = transform(response?.data, columnName);
  return transformedResponse;
};

export default getSearchTransactionDetailsFiltersFromAPI;
