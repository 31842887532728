import { call, put } from 'redux-saga/effects';
import errorHandler from 'helpers/errorHandler';
import { errorToShow } from 'store/ducks/errorToShow/actions';

import {
  refundsPaymentsDashError,
  refundsPaymentsDashSuccess,
} from './actions';
import getRefundsPayments from '../../../../services/payments/refundsPayments';

export function* refundsPaymentsDashSaga(actions) {
  try {
    const apiData = yield call(getRefundsPayments, actions.payload);
    yield put(refundsPaymentsDashSuccess(apiData));
  } catch (err) {
    if (
      err?.response?.data?.error &&
      err?.response?.data?.error?.type &&
      err?.response?.data?.error?.message
    ) {
      yield put(
        errorToShow({
          message: err.response.data.error.message,
          type: err.response.data.error.type,
        })
      );
      yield put(
        refundsPaymentsDashError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    }
  }
}
