import styled from 'styled-components';
import { Typography } from 'antd';
import { handleColor } from './utils';
import { colors } from 'styles/colors';

const { Text, Title } = Typography;

export const StAlignContent = styled.div`
  border-radius: 5px;
  background-color: ${colors.gray1};
  padding: 32px;
  &&& {
    font-family: 'Roboto', sans-serif;
  }
`;

export const StCreatePasswordTitle = styled(Title)`
  &&& {
    font-size: 20px;
    color: ${colors.gray9};
    line-height: 38px;
  }
`;

export const StPasswordTextInfo = styled(Text)`
  &&& {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
  }
`;

export const StCustomUl = styled.ul`
  padding: 0px 20px;
  display: grid;
  gap: 5px;
  color: ${colors.gray8};
`;

export const StCustomLi = styled.li`
  color: ${(props) =>
    handleColor(
      props?.validNumOfChars,
      props?.validUppercase,
      props?.validLowercase,
      props?.validOneNumber,
      props?.validSpecialChar
    )};
`;
