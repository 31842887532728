import { lambdaApi } from 'services/api';

const transformer = (apiResponse) => {
  const brands = {
    brands: apiResponse.data.brands,
    meta: apiResponse.meta,
  };
  return brands;
};

const getFeeBrands = async ({ acquirerId, productId }) => {
  const response = await lambdaApi().get(
    `/fees/brands?acquirer_id=${acquirerId}&product_id=${productId}`
  );
  return transformer(response.data);
};

export default getFeeBrands;
