import { api } from '../api';

const transformer = (apiResponse) => {
  return {
    data: apiResponse.data,
  };
};

export const postOptinSchedulerRequest = async ({
  code,
  merchant,
  acquirer,
}) => {
  let config = {
    merchants: [merchant],
  };
  if (code) {
    config = {
      code,
      merchants: [merchant],
    };
  }
  const response = await api().post(`/integrations/optin/${acquirer}`, config);
  return transformer(response.data);
};
