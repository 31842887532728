import styled from 'styled-components';
import { marginMainContainer } from 'helpers/style';
import { Tabs } from 'antd';

export const StContainer = styled.div`
  overflow-y: hidden;
`;

export const StLimiter = styled.div`
  margin: ${(props) => marginMainContainer(props.$breakpoint)};
  padding-top: 206px;
`;

export const StTabs = styled(Tabs)`
  &&& {
    overflow-y: hidden;
  }
`;
