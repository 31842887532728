import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { removeOFXParameterStart } from '../../../store/ducks/ofxParameters/actions';
import { getSessionClientId } from 'helpers/sessionService';
import * as St from './styled';

const Actions = ({ currentRow, openEditModal }) => {
  const dispatch = useDispatch();
  const loadingRemove = useSelector(
    (state) => state.ofxParameters.loadingRemove
  );

  // Actions
  const onEditAction = () => {
    openEditModal(currentRow);
  };
  const onRemoveAction = () => {
    Modal.confirm({
      title: 'Tem certeza que deseja excluir?',
      icon: <ExclamationCircleOutlined />,
      content:
        'Ao confirmar a exclusão desta regra a análise de lançamentos bancários será imediatamente afetada',
      autoFocusButton: null,
      okText: 'Excluir',
      okType: 'primary',
      okButtonProps: {
        danger: true,
      },
      cancelText: 'Cancelar',
      onOk: () => {
        dispatch(
          removeOFXParameterStart({
            client: getSessionClientId(),
            id: currentRow.id,
          })
        );
      },
    });
  };

  return (
    <St.ActionsRow>
      <St.EditColumn>
        <St.EditIcon onClick={onEditAction} />
      </St.EditColumn>
      <St.DeleteColumn>
        {loadingRemove ? (
          <St.SpinIcon />
        ) : (
          <St.DeleteIcon onClick={onRemoveAction} />
        )}
      </St.DeleteColumn>
    </St.ActionsRow>
  );
};

Actions.propTypes = {};

export default Actions;
