import { call, put } from 'redux-saga/effects';

import errorHandler from 'helpers/errorHandler';

import getAmountsReceivableInfoFromAPI from 'services/amountsReceivableInfo';
import {
  amountsReceivableInfoSuccess,
  amountsReceivableInfoError,
} from 'store/ducks/amountsReceivableInfo/actions';

export function* amountsReceivableInfoSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(getAmountsReceivableInfoFromAPI, payload);

    yield put(amountsReceivableInfoSuccess(apiData));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        amountsReceivableInfoError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    }
  }
}
