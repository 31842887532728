import CryptoJS from 'crypto-js';

export const getHash = (content) => {
  return CryptoJS.MD5(content.toString()).toString();
};

export const encryptData = (content, key) => {
  return CryptoJS.AES.encrypt(JSON.stringify({ content }), key).toString();
};

export const decryptData = (crypted, key) => {
  if (!crypted) return '';

  return JSON.parse(
    CryptoJS.AES.decrypt(crypted, key).toString(CryptoJS.enc.Utf8)
  ).content;
};

export const encrypt = (content, key) => {
  CryptoJS.AES.encrypt(content, key);
};

export const decrypt = (encryptedContent, key) => {
  const ciphertext = CryptoJS.enc.Base64.parse(encryptedContent);
  const iv = ciphertext.clone();

  iv.sigBytes = 16;
  iv.clamp();
  ciphertext.words.splice(0, 4);
  ciphertext.sigBytes -= 16;
  const parsedKey = CryptoJS.enc.Utf8.parse(key);
  const decrypted = CryptoJS.AES.decrypt(
    { ciphertext: ciphertext },
    parsedKey,
    {
      iv: iv,
      mode: CryptoJS.mode.CBC,
    }
  );

  const encodedDecryption = CryptoJS.enc.Utf8.stringify(decrypted);
  const JSONParsedDecryption = JSON.parse(encodedDecryption);

  return JSONParsedDecryption;
};
