import { call, put } from 'redux-saga/effects';
import errorHandler from 'helpers/errorHandler';
import { errorToShow } from 'store/ducks/errorToShow/actions';

import {
  listBanksIntermediateError,
  listBanksIntermediateSuccess,
} from './actions';
import getPaymentsByBanksIntermediateAPI from 'services/banks/listBanksPaymentIntermediate';

export function* banksIntermediateSaga(actions) {
  try {
    const apiData = yield call(
      getPaymentsByBanksIntermediateAPI,
      actions.payload
    );
    yield put(listBanksIntermediateSuccess(apiData));
  } catch (err) {
    if (
      err?.response?.data?.error &&
      err?.response?.data?.error?.type &&
      err?.response?.data?.error?.message
    ) {
      yield put(
        errorToShow({
          message: err.response.data.error.message,
          type: err.response.data.error.type,
        })
      );
      yield put(
        listBanksIntermediateError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(listBanksIntermediateError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}
