import { lambdaApi } from '../api';

const transformer = (apiResponse) => {
  const data = {
    executionSequence: apiResponse?.execution_sequence,
  };
  return data;
};

const getNextOrderByClientIdFromAPI = async (clientId) => {
  const config = {
    params: {
      client_id: clientId,
    },
  };

  const response = await lambdaApi().get(
    '/conciliation/rules/next_order',
    config
  );
  return transformer(response.data);
};

export default getNextOrderByClientIdFromAPI;
