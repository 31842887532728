import { useCallback, useEffect, useState } from 'react';
import errorHandler from 'helpers/errorHandler';
import { useDispatch, useSelector } from 'react-redux';
import getFeesDetail from 'services/fees/getFeesDetail';
import {
  deleteFeeStart,
  resetDeleteFeeStatus,
} from 'store/ducks/fees/deleteFee/actions';

function useFeesDetail({
  page,
  setPage,
  perPage = 10,
  currentActivePanel,
  type,
  selectedStore = null,
  toParamsFilters,
  sortFields = {},
}) {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [fees, setFees] = useState([]);
  const [feesData, setFeesData] = useState([]);
  const [metaData, setMetaData] = useState({});
  const feeDeleteSuccess = useSelector((state) => state.deleteFee?.success);

  const dispatch = useDispatch();

  const fetchFeesDetail = useCallback(
    (pageNumber) => {
      if (currentActivePanel) {
        setIsLoading(true);
        setIsError(false);

        return getFeesDetail({
          acquirerId: currentActivePanel,
          page: pageNumber || page,
          perPage,
          feeStatus: type,
          selectedStore,
          toParamsFilters,
          sortFields,
        })
          .then(({ fees: data = [], meta = {} }) => {
            setFeesData(data);
            setMetaData(meta);
          })
          .catch((err) => {
            if (err) {
              errorHandler('Ocorreu um erro:', err);
            }
            setIsError(true);
            setIsLoading(false);
          })
          .finally(() => setIsLoading(false));
      }
      return true;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [type, page, toParamsFilters, sortFields]
  );

  const onDeleteFee = (feeId) => {
    if (!feeId) return;

    dispatch(deleteFeeStart({ feeId }));
  };

  // open/change panel acquirer
  useEffect(() => {
    setFees([]);
    fetchFeesDetail(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentActivePanel, toParamsFilters, sortFields]);

  useEffect(() => {
    setFees((prev) => {
      return [...new Set([...prev, ...feesData])];
    });
  }, [feesData]);

  useEffect(() => {
    if (page > 1) {
      fetchFeesDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const refreshFees = () => {
    setPage(1);
    setFees([]);
    fetchFeesDetail(1);
  };

  useEffect(() => {
    if (feeDeleteSuccess) {
      refreshFees();
      dispatch(resetDeleteFeeStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feeDeleteSuccess]);

  return {
    isError,
    isLoading,
    fees,
    metaData,
    onDeleteFee,
    refreshFees,
  };
}

export default useFeesDetail;
