export const actionTypes = {
  RECONCILED_PAYMENTS_DETAILS_FILTERS_START:
    '@reconciledSalesDetailsFilters/RECONCILED_PAYMENTS_DETAILS_FILTERS_START',
  RECONCILED_PAYMENTS_DETAILS_FILTERS_SUCCESS:
    '@reconciledSalesDetailsFilters/RECONCILED_PAYMENTS_DETAILS_FILTERS_SUCCESS',
  RECONCILED_PAYMENTS_DETAILS_FILTERS_ERROR:
    '@reconciledSalesDetailsFilters/RECONCILED_PAYMENTS_DETAILS_FILTERS_ERROR',
  RECONCILED_PAYMENTS_DETAILS_FILTERS_ZERO_LENGTH:
    '@reconciledSalesDetailsFilters/RECONCILED_PAYMENTS_DETAILS_FILTERS_ZERO_LENGTH',
  UPDATE_RECONCILED_PAYMENTS_DETAILS_FILTERS:
    '@reconciledSalesDetailsFilters/UPDATE_RECONCILED_PAYMENTS_DETAILS_FILTERS',
};
