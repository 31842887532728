import { useState } from 'react';
import errorHandler from 'helpers/errorHandler';
import getFeeBrands from 'services/fees/getFeeBrands';

export const useBrands = () => {
  const [brands, setBrands] = useState([]);
  const [meta, setMeta] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchBrands = ({ acquirerId, productId }) => {
    setLoading(true);

    getFeeBrands({ acquirerId, productId })
      .then(({ brands: brandsData = [], meta: metaData = {} }) => {
        setBrands(brandsData);
        setMeta(metaData);
      })
      .catch(() =>
        errorHandler(
          'Ocorreu um erro ao buscar as bandeiras, por favor, atualize a página'
        )
      )
      .finally(() => setLoading(false));
  };

  return {
    meta,
    brands,
    loading,
    fetchBrands,
  };
};
