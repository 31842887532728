export const actionTypes = {
  IMPORT_FILES_FILTERS_START: '@importFilesFilters/IMPORT_FILES_FILTERS_START',
  IMPORT_FILES_FILTERS_SUCCESS:
    '@importFilesFilters/IMPORT_FILES_FILTERS_SUCCESS',
  IMPORT_FILES_FILTERS_ZERO_LENGTH:
    '@importFilesFilters/IMPORT_FILES_FILTERS_ZERO_LENGTH',
  IMPORT_FILES_FILTERS_ERROR: '@importFilesFilters/IMPORT_FILES_FILTERS_ERROR',
  UPDATE_IMPORT_FILES_FILTERS:
    '@importFilesFilters/UPDATE_IMPORT_FILES_FILTERS',
};
