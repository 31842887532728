import { call, put } from 'redux-saga/effects';
import errorHandler from 'helpers/errorHandler';
import {
  deleteMerchantSuccess,
  deleteMerchantError,
} from 'store/ducks/cnpjMaintenance/merchants/deleteMerchant/actions';
import deleteMerchant from 'services/cnpjMaintenance/deleteMerchant';
import { notification } from 'antd';

export function* deleteMerchantCnpjMaintenanceSaga(action) {
  try {
    const { payload } = action;
    const { merchantId } = payload;
    const apiData = yield call(deleteMerchant, payload);
    yield put(deleteMerchantSuccess(apiData));
    notification.success({
      message: `Estabelecimento ${merchantId} inativado com sucesso!`,
    });
  } catch (err) {
    yield put(deleteMerchantError('Ocorreu um erro:', err));
    if (err?.response?.data?.message) {
      yield put(
        deleteMerchantError(err.response.data.message, err.response.data.error)
      );
      errorHandler(err.response.data.message, err.response.data.error);
    } else {
      yield put(deleteMerchantError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
      errorHandler(err.response.data.message, err.response.data.error);
    }
  }
}
