import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Select, Button, Grid, Drawer, Space, Divider } from 'antd';
import {
  SortAscendingOutlined,
  SortDescendingOutlined,
} from '@ant-design/icons';
import { FiltersContainer } from 'commons/FiltersContainer';
import { LabelAndFilter } from 'commons/LabelAndFilter';
import DatePicker from 'commons/DatePicker/DatePicker';
import dayjs from 'dayjs';
import { currentDay } from 'helpers/dates';
import { shortId } from 'helpers/shortId';

const Filters = ({
  dateDefaultValue,
  onRangePickerChange,
  onChangeSelectFilter,
  loading,
  sorter,
  sort,
  renderFilterButton,
  filtersDrawer,
  onCloseFiltersDrawer,
  sorterButtonText,
  hasRangePicker,
  hasSelect,
  hasSorter,
  onRangePickerOpenOrCloseChange,
  infoFiltersToSelect,
  activeTab,
}) => {
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const { useBreakpoint } = Grid;
  const breakpoint = useBreakpoint();
  const [tabPlaceholder, setTabPlaceholder] = useState(activeTab);
  const hasInfoFiltersToSelect = infoFiltersToSelect.length > 0;

  useEffect(() => {
    switch (activeTab) {
      case 'branches':
        setTabPlaceholder('Filial');
        break;
      case 'acquirers':
        setTabPlaceholder('Adquirente');
        break;
      case 'products':
        setTabPlaceholder('Produto');
        break;
      case 'brands':
        setTabPlaceholder('Bandeira');
        break;
      default:
        setTabPlaceholder('Filial');
    }
  }, [activeTab]);

  const disableDates = (currentDate) => {
    if (dayjs(currentDate).isBefore(currentDay)) {
      return true;
    }
    return false;
  };

  if (breakpoint && !breakpoint.md) {
    return (
      <FiltersContainer
        title="Valores a receber"
        renderFilterButton={renderFilterButton}
      >
        <>
          <Row gutter={8}>
            <Col span={hasRangePicker ? 24 : 0}>
              <LabelAndFilter label="Calendário">
                <RangePicker
                  format="DD-MM-YYYY"
                  onOpenChange={onRangePickerOpenOrCloseChange}
                  disabled={loading}
                  disabledDate={disableDates}
                  defaultValue={dateDefaultValue}
                  onChange={onRangePickerChange}
                />
              </LabelAndFilter>
            </Col>
          </Row>
          <Drawer
            open={filtersDrawer}
            onClose={onCloseFiltersDrawer}
            title="Filtros"
            width="100%"
          >
            <div
              style={{
                height: '100%',
                justifyContent: 'space-between',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Space direction="vertical">
                <Row>
                  <Col span={hasSelect ? 24 : 0}>
                    <Select
                      defaultValue="Todos"
                      placeholder="Buscar"
                      onChange={onChangeSelectFilter}
                      disabled={loading}
                    >
                      <Option value="Todos">Todos</Option>
                      {hasInfoFiltersToSelect &&
                        infoFiltersToSelect.map(
                          (item) =>
                            item && (
                              <Option key={shortId()} value={item}>
                                {item}
                              </Option>
                            )
                        )}
                    </Select>
                  </Col>
                </Row>
                <Row>
                  <Col span={hasSorter ? 24 : 0}>
                    <LabelAndFilter label="Ordenar por">
                      <Button
                        style={{ marginTop: '1rem' }}
                        disabled={loading}
                        type="dashed"
                        icon={
                          sort === 'ASC' ? (
                            <SortAscendingOutlined />
                          ) : (
                            <SortDescendingOutlined />
                          )
                        }
                        size="small"
                        onClick={sorter}
                      >
                        {sorterButtonText}
                      </Button>
                    </LabelAndFilter>
                  </Col>
                </Row>
              </Space>
              <div>
                <Divider />
                <div
                  style={{ display: 'flex', justifyContent: 'space-around' }}
                >
                  <Button onClick={onCloseFiltersDrawer}>Limpar filtros</Button>
                  <Button onClick={onCloseFiltersDrawer} type="primary">
                    Aplicar filtros
                  </Button>
                </div>
              </div>
            </div>
          </Drawer>
        </>
      </FiltersContainer>
    );
  }
  return (
    <FiltersContainer
      title="Valores a receber"
      subTitle="Visualize os valores a receber no período selecionado."
    >
      <Row gutter={8}>
        <Col
          xxl={hasRangePicker ? 4 : 0}
          xl={hasRangePicker ? 5 : 0}
          lg={hasRangePicker ? 6 : 0}
          md={hasRangePicker ? 10 : 0}
          sm={hasRangePicker ? 12 : 0}
          xs={hasRangePicker ? 24 : 0}
        >
          <LabelAndFilter label="Calendário">
            <RangePicker
              format="DD-MM-YYYY"
              onOpenChange={onRangePickerOpenOrCloseChange}
              disabled={loading}
              disabledDate={disableDates}
              defaultValue={dateDefaultValue}
              onChange={onRangePickerChange}
              style={{ width: '100%' }}
            />
          </LabelAndFilter>
        </Col>
        <Col
          xxl={hasSelect ? 4 : 0}
          xl={hasSelect ? 4 : 0}
          lg={hasSelect ? 5 : 0}
          md={hasSelect ? 6 : 0}
          sm={hasSelect ? 12 : 0}
          xs={hasSelect ? 24 : 0}
        >
          <LabelAndFilter label={tabPlaceholder}>
            <Select
              defaultValue="Todos"
              placeholder="Buscar"
              onChange={onChangeSelectFilter}
              disabled={loading}
              style={{ width: '100%' }}
            >
              <Option value="Todos">Todos</Option>
              {hasInfoFiltersToSelect &&
                infoFiltersToSelect.map(
                  (item) =>
                    item && (
                      <Option key={shortId()} value={item}>
                        {item}
                      </Option>
                    )
                )}
            </Select>
          </LabelAndFilter>
        </Col>
        <Col md={4} lg={11} xl={13} xxl={14} />
        <Col
          xxl={hasSorter ? 2 : 0}
          xl={hasSorter ? 2 : 0}
          lg={hasSorter ? 2 : 0}
          md={hasSorter ? 4 : 0}
        >
          <LabelAndFilter label="Ordenar por" align="end">
            <Button
              disabled={loading}
              type="dashed"
              icon={
                sort === 'ASC' ? (
                  <SortAscendingOutlined />
                ) : (
                  <SortDescendingOutlined />
                )
              }
              size="small"
              onClick={sorter}
            >
              {sorterButtonText}
            </Button>
          </LabelAndFilter>
        </Col>
      </Row>
    </FiltersContainer>
  );
};

Filters.defaultProps = {
  renderFilterButton: () => {},
  infoFiltersToSelect: [],
  onChangeSelectFilter: () => {},
};

Filters.propTypes = {
  onRangePickerChange: PropTypes.func.isRequired,
  onChangeSelectFilter: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  sorter: PropTypes.func.isRequired,
  sort: PropTypes.string.isRequired,
  renderFilterButton: PropTypes.func,
  filtersDrawer: PropTypes.bool.isRequired,
  onCloseFiltersDrawer: PropTypes.func.isRequired,
  sorterButtonText: PropTypes.string.isRequired,
  hasRangePicker: PropTypes.bool.isRequired,
  hasSelect: PropTypes.bool.isRequired,
  hasSorter: PropTypes.bool.isRequired,
  infoFiltersToSelect: PropTypes.arrayOf(PropTypes.string.isRequired),
  onRangePickerOpenOrCloseChange: PropTypes.func.isRequired,
  activeTab: PropTypes.string.isRequired,
};

export default Filters;
