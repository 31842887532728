import { api } from '../api';

const transformer = (apiResponse) => {
  return {
    cadastro: apiResponse.cadastro,
    senha: apiResponse.senha,
  };
};

// Trocar URL base da API da Tomatico
const tomaticoStatus = async ({ cnpj }) => {
  const response = await api().get(
    `https://tomatico.captalys.io/api/cadastro/status/${cnpj}`
  );
  return transformer(response.data);
};

export default tomaticoStatus;
