import { call, put } from 'redux-saga/effects';

import errorHandler from 'helpers/errorHandler';

import {
  paymentsSummaryReconciledSuccess,
  paymentsSummaryReconciledError,
} from 'store/ducks/reconciled/summary/payments/actions';
import getPaymentsSummary from 'services/reconciled/summary/payments';

export function* paymentsSummaryReconciledSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(getPaymentsSummary, payload);

    yield put(paymentsSummaryReconciledSuccess(apiData));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        paymentsSummaryReconciledError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(paymentsSummaryReconciledError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}
