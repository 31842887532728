import { actionTypes } from 'store/ducks/retroactiveConciliation/types';

const INITIAL_STATE = {
  error: undefined,
  errorMessage: undefined,
  loading: false,
  isError: false,
  dataHasBeenPosted: false,
  data: [],
  meta: {},
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case actionTypes.LIST_RETROACTIVE_CONCILIATION_START:
    return {
      ...state,
      data: [],
      meta: {},
      loading: true,
      error: undefined,
      errorMessage: undefined,
      isError: false,
    };
  case actionTypes.LIST_RETROACTIVE_CONCILIATION_SUCCESS:
    return {
      ...state,
      loading: false,
      error: undefined,
      data: action.payload?.data,
      meta: action.payload.meta,
      isError: false,
      errorMessage: undefined,
    };
  case actionTypes.LIST_RETROACTIVE_CONCILIATION_ERROR:
    return {
      ...state,
      data: [],
      meta: {},
      loading: false,
      error: action.error.error,
      errorMessage: action.payload,
      isError: true,
    };
  case actionTypes.POST_RETROACTIVE_CONCILIATION_START:
    return {
      ...state,
      dataHasBeenPosted: false,
      loading: true,
      error: undefined,
      errorMessage: undefined,
      isError: false,
    };
  case actionTypes.POST_RETROACTIVE_CONCILIATION_SUCCESS:
    return {
      ...state,
      dataHasBeenPosted: true,
      loading: false,
      error: undefined,
      isError: false,
      errorMessage: undefined,
    };
  case actionTypes.POST_RETROACTIVE_CONCILIATION_ERROR:
    return {
      ...state,
      dataHasBeenPosted: false,
      loading: false,
      error: action.error.error,
      errorMessage: action.payload,
      isError: true,
    };
  default:
    return state;
  }
};

export default reducer;
