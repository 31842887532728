import styled from 'styled-components';
import { colors } from 'styles/colors';

export const StBlockedTooltipContent = styled.div`
  color: ${colors.gray9};
`;

export const StBlockedTooltipTitle = styled.p`
  font-weight: 500;
`;

export const StBlockedTooltipText = styled.p`
  margin: 16px 0;
  word-break: normal;
`;
