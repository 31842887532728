import React, { useContext, useEffect, useState } from 'react';
import { StContainer, StTabs } from './styled';
import { colors } from 'styles/colors';
import { Collapse } from '../Collapse';
import { Button } from 'antd';
import { FeesTable } from '../FeesTable';
import { FeesContext } from 'components/Fees/Fees';
import { useDispatch, useSelector } from 'react-redux';
import {
  feesDetailsFiltersZeroLength,
  updateFeesDetailsFilters,
} from 'store/ducks/filters/fees/actions';
import { feesResetUpdateFilteredColumnsStart } from 'store/ducks/feesUpdateFilteredColumns/actions';
import { FeesConsolidatedTable } from '../FeesConsolidatedTable';

const Tabs = () => {
  const {
    currentActivePanel,
    activeTab,
    setActiveTab,
    activeDetailTab,
    setActiveDetailTab,
    setCurrentActivePanel,
  } = useContext(FeesContext);

  const dispatch = useDispatch();

  const [showTable, setShowTable] = useState(true);
  const toParamsFilters = useSelector(
    (state) => state.feesDetailsFilters?.toParamsFilters
  );

  const resetFiltersStates = () => {
    dispatch(feesDetailsFiltersZeroLength());
    dispatch(updateFeesDetailsFilters([]));
    dispatch(feesResetUpdateFilteredColumnsStart());
  };

  const handleResetFilters = () => {
    setShowTable(false);
    resetFiltersStates();
  };

  const isDisableClearButton = toParamsFilters.length === 0;

  const operations = (
    <Button onClick={handleResetFilters} disabled={isDisableClearButton}>
      Limpar filtros
    </Button>
  );

  const handleTabClick = (key) => {
    if (key !== activeTab) {
      setActiveTab(key);
      resetFiltersStates();
      setCurrentActivePanel('');
      setActiveDetailTab('vigentes');
    }
  };

  const handleDetailTabClick = (key) => {
    if (key !== activeDetailTab) {
      // reset filters when tab change
      resetFiltersStates();
      setActiveDetailTab(key);
    }
  };

  const subItems = [
    {
      label: 'Taxas vigentes',
      key: 'vigentes',
      children: activeDetailTab === 'vigentes' && showTable && (
        <FeesTable type="VIGENTES" />
      ),
    },
    {
      label: 'Taxas vencidas',
      key: 'vencidas',
      children: activeDetailTab === 'vencidas' && showTable && (
        <FeesTable type="VENCIDAS" />
      ),
    },
  ];

  const items = [
    {
      label: 'Resumo',
      key: 'fees-consolidated',
      children: activeTab === 'fees-consolidated' && (
        <Collapse>
          <FeesConsolidatedTable />
        </Collapse>
      ),
    },
    {
      label: 'Taxas cadastradas',
      key: 'fees-details',
      children: activeTab === 'fees-details' && (
        <Collapse>
          <StTabs
            tabBarStyle={{
              width: '100%',
              backgroundColor: colors.gray1,
              fontSize: '14px',
              color: colors.gray9,
              fontWeight: 400,
              lineHeight: '22px',
              padding: '0px 12px',
            }}
            tabBarExtraContent={operations}
            onTabClick={handleDetailTabClick}
            destroyInactiveTabPane
            items={subItems}
          />
        </Collapse>
      ),
    },
  ];

  useEffect(() => {
    setShowTable(true);
  }, [showTable]);

  useEffect(() => {
    setActiveDetailTab('vigentes');
    // reset filters when acquirer panel change
    resetFiltersStates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentActivePanel]);

  return (
    <StContainer>
      <StTabs
        tabBarStyle={{
          padding: '0 64px',
          width: '100%',
          backgroundColor: colors.gray1,
          fontSize: '14px',
          color: colors.gray9,
          fontWeight: 400,
          lineHeight: '22px',
        }}
        onTabClick={handleTabClick}
        destroyInactiveTabPane
        activeKey={activeTab}
        items={items}
      />
    </StContainer>
  );
};

export default Tabs;
