import { action } from 'typesafe-actions';
import { actionTypes } from './types';

export const listMerchantsStart = (data) =>
  action(actionTypes.LIST_MERCHANTS_START, data);
export const listMerchantsSuccess = (data) =>
  action(actionTypes.LIST_MERCHANTS_SUCCESS, data);
export const listMerchantsError = (errorMessage, error) =>
  action(actionTypes.LIST_MERCHANTS_ERROR, { errorMessage }, undefined, {
    error,
  });

export const resetListMerchantsStart = (data) =>
  action(actionTypes.RESET_LIST_MERCHANTS_START, data);
export const resetListMerchantsSuccess = (data) =>
  action(actionTypes.RESET_LIST_MERCHANTS_SUCCESS, data);
export const resetListMerchantsError = (errorMessage, error) =>
  action(actionTypes.RESET_LIST_MERCHANTS_ERROR, { errorMessage }, undefined, {
    error,
  });
