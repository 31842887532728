import { useState } from 'react';
import errorHandler from 'helpers/errorHandler';
import getFeeProducts from 'services/fees/getFeeProducts';

export const useProducts = () => {
  const [products, setProducts] = useState([]);
  const [meta, setMeta] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchProducts = ({ acquirerId }) => {
    setLoading(true);

    getFeeProducts({ acquirerId })
      .then(({ products: productsData = [], meta: metaData = {} }) => {
        setProducts(productsData);
        setMeta(metaData);
      })
      .catch(() =>
        errorHandler(
          'Ocorreu um erro ao buscar os produtos, por favor, atualize a página'
        )
      )
      .finally(() => setLoading(false));
  };

  return {
    meta,
    products,
    loading,
    fetchProducts,
  };
};
