import { action } from 'typesafe-actions';
import { actionTypes } from './types';

export const validateAccessStart = (data) =>
  action(actionTypes.VALIDATE_ACCESS_START, data);
export const validateAccessSuccess = (data) =>
  action(actionTypes.VALIDATE_ACCESS_SUCCESS, { data });
export const validateAccessFailure = (errorMessage, error) =>
  action(actionTypes.VALIDATE_ACCESS_ERROR, { errorMessage }, undefined, {
    error,
  });
