import { action } from 'typesafe-actions';
import { actionTypes } from './types';

export const radarDetailsFiltersStart = (data) =>
  action(actionTypes.RADAR_DETAILS_FILTERS_START, data);
export const radarDetailsFiltersSuccess = (data) =>
  action(actionTypes.RADAR_DETAILS_FILTERS_SUCCESS, data);
export const radarDetailsFiltersError = (errorMessage, error) =>
  action(actionTypes.RADAR_DETAILS_FILTERS_ERROR, { errorMessage }, undefined, {
    error,
  });
export const radarDetailsFiltersZeroLength = () =>
  action(actionTypes.RADAR_DETAILS_FILTERS_ZERO_LENGTH);
export const updateRadarDetailsFilters = (data) =>
  action(actionTypes.UPDATE_RADAR_DETAILS_FILTERS, data);
