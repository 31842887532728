import React from 'react';
import PlanFeature from './components/PlanFeature';
import { useNavigate } from 'react-router-dom';
import { dataTestIdHandler } from 'helpers/dataTestIdHandler';
import {
  StButton,
  StConcilBackground,
  StConcilLogo,
  StContainer,
  StImage,
  StLogoContainer,
  StPhrase,
  StPhraseContainer,
  StPhraseHighlight,
  StPlanFeatures,
} from './styled';

const assetsBaseURL = process.env.REACT_APP_AWS_V4_ASSETS;
const logoConcil = `${assetsBaseURL}/img/logoConcil.png`;
const concilCardPreview = `${assetsBaseURL}/img/concilCardPreview.png`;
const iconConcil = `${assetsBaseURL}/img/iconConcil.png`;

const features = [
  {
    label: 'Visão detalhada de pagamentos',
    tooltipText:
      'Potencialize sua análise financeira com uma visão detalhada e abrangente de todos os pagamentos, antecipações, cancelamentos, chargebacks e ajustes.',
  },
  {
    label: 'Conciliação bancária',
    tooltipText:
      'Obtenha controle total sobre suas finanças, simplificando processos, aumentando sua produtividade e garantindo uma conciliação bancária precisa.',
  },
  {
    label: 'Conciliação de pagamentos',
    tooltipText:
      'Garanta que todos os seus pagamentos foram realizados com precisão através da conciliação de pagamentos, assegurando uma gestão financeira impecável.',
  },
  {
    label: 'Auditoria de pagamentos',
    tooltipText:
      'Registre as taxas negociadas com a adquirente e tenha visão se elas estão sendo cobradas corretamente, evitando que taxas indevidas diminuam seus lucros.',
  },
  {
    label: 'Gerente de sucesso dedicado e muito mais',
    tooltipText:
      'Eleve sua jornada através do acompanhamento de um Gerente de Sucesso que oferecerá soluções específicas para suas necessidades. Exclusivo para plano Plus.',
  },
];

const PlanUpgradeCard = ({ height, ...others }) => {
  const navigate = useNavigate();
  const dataTestPrefix = 'plan-upgrade-card';

  const onPlanUpgradeClick = () => navigate('/planos');

  return (
    <StContainer height={height} {...others}>
      <StLogoContainer>
        <StConcilLogo src={logoConcil} alt="Logo Concil" />
        Card
      </StLogoContainer>
      <StPhraseContainer {...dataTestIdHandler(dataTestPrefix, 'phrase')}>
        <StPhrase>
          Faça <StPhraseHighlight>upgrade</StPhraseHighlight> de plano e
        </StPhrase>
        <StPhraseHighlight> potencialize sua experiência</StPhraseHighlight>
      </StPhraseContainer>
      <StPlanFeatures {...dataTestIdHandler(dataTestPrefix, 'features')}>
        {features.map(({ label, tooltipText }) => (
          <PlanFeature key={label} label={label} tooltipText={tooltipText} />
        ))}
      </StPlanFeatures>
      <StButton
        type="primary"
        {...dataTestIdHandler(dataTestPrefix, 'upgrade-button')}
        onClick={onPlanUpgradeClick}
      >
        Fazer upgrade de plano
      </StButton>
      <StImage
        src={concilCardPreview}
        alt="Imagem demonstrativa do Concil Card"
      />
      <StConcilBackground src={iconConcil} alt="Icone Concil Card" />
    </StContainer>
  );
};

export default PlanUpgradeCard;
