import { actionTypes } from 'store/ducks/updateBranchData/types';

const INITIAL_STATE = {
  error: undefined,
  errorMessage: undefined,
  loading: false,
  isError: false,
  branchDataHasBeenUpdated: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case actionTypes.UPDATE_BRANCH_DATA_START:
    return {
      ...state,
      loading: true,
      error: undefined,
      errorMessage: undefined,
      isError: false,
      branchDataHasBeenUpdated: false,
    };
  case actionTypes.UPDATE_BRANCH_DATA_SUCCESS:
    return {
      ...state,
      loading: false,
      error: undefined,
      isError: false,
      errorMessage: undefined,
      branchDataHasBeenUpdated: true,
    };
  case actionTypes.UPDATE_BRANCH_DATA_ERROR:
    return {
      ...state,
      loading: false,
      error: action.error.error,
      errorMessage: action.payload,
      isError: true,
      branchDataHasBeenUpdated: false,
    };
  case actionTypes.UPDATE_BRANCH_DATA_HAS_BEEN_UPDATED:
    return {
      ...state,
      loading: false,
      branchDataHasBeenUpdated: action.payload.status,
    };
  default:
    return state;
  }
};

export default reducer;
