import React from 'react';
import { Row, Col, Grid, Skeleton } from 'antd';

import PropTypes from 'prop-types';
import customHistory from 'helpers/history';
import { LabelAndValue } from 'commons/LabelAndValue';
import { TitleAndIcon } from 'commons/TitleAndIcon';
import { ErrorCard } from 'commons/ErrorCard';
import { StSeeDetailsButton } from 'components/Intermediate/styled';
import { StIntermediateCard } from 'commons/Intermediate/styled';
import { dataTestIdHandler } from 'helpers/dataTestIdHandler';

const SummaryCard = ({ data, error, loading, title, detailRoute, screen }) => {
  const { useBreakpoint } = Grid;
  const breakpoint = useBreakpoint();
  const dataTestPrefix = `summary-card-${screen}`;

  const handleTestSuffix = (str) => {
    return str
      .toLowerCase()
      .replace(/[^a-zA-ZÀ-ÿ]+/g, '-')
      .replace(/\s+/g, '-')
      .replace(/_/g, '-');
  };

  const goToReports = () => {
    customHistory.push(detailRoute);
  };
  if (error.has) {
    return (
      <Row style={{ marginTop: '2rem' }}>
        <Col lg={10} md={18}>
          <StIntermediateCard loading={loading}>
            <ErrorCard
              title={error.title}
              reason={error.reason}
              recommendation={error.recommendation}
            />
          </StIntermediateCard>
        </Col>
      </Row>
    );
  }

  const cardSpan = {
    xxl: 10,
    xl: 14,
    lg: 16,
  };

  const labelSpan = {
    xxl: 8,
    xl: 8,
    lg: 8,
  };

  if (data?.length === 4) {
    cardSpan.xxl = 10;
    cardSpan.xl = 18;
    cardSpan.lg = 22;
    labelSpan.xxl = 6;
    labelSpan.xl = 6;
    labelSpan.lg = 6;
  }

  if (data?.length === 5) {
    cardSpan.xxl = 16;
    cardSpan.xl = 18;
    cardSpan.lg = 22;
    labelSpan.xxl = 4;
    labelSpan.xl = 4;
    labelSpan.lg = 4;
  }

  if (data?.length === 6) {
    cardSpan.xxl = 16;
    cardSpan.xl = 22;
    cardSpan.lg = 24;
    labelSpan.xxl = 4;
    labelSpan.xl = 4;
    labelSpan.lg = 4;
  }

  return (
    <Row style={{ marginTop: '2rem' }}>
      <Col
        xxl={cardSpan?.xxl}
        xl={cardSpan?.xl}
        lg={cardSpan?.lg}
        md={20}
        sm={24}
        xs={24}
      >
        <Skeleton loading={loading} active paragraph={{ rows: 6 }}>
          <StIntermediateCard
            loading={loading}
            actions={[
              <div
                style={{
                  textAlign: 'end',
                  padding: '2px',
                }}
              >
                <StSeeDetailsButton
                  type="link"
                  size="small"
                  fontSize="1.75rem"
                  onClick={goToReports}
                  {...dataTestIdHandler(dataTestPrefix, 'go-to-reports')}
                >
                  Ver relatório
                </StSeeDetailsButton>
              </div>,
            ]}
          >
            <Row>
              <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                <TitleAndIcon
                  titleText={title}
                  titleFontSize="18px"
                  dataTestPrefix={dataTestPrefix}
                />
              </Col>
            </Row>
            <Row justify="space-between" style={{ marginTop: '1.5rem' }}>
              {data && data.length > 0 && (
                <>
                  <Col
                    xxl={labelSpan?.xxl}
                    xl={labelSpan?.xl}
                    lg={labelSpan?.lg}
                    md={24}
                    sm={24}
                    xs={24}
                  >
                    <LabelAndValue
                      label={data[0]?.title}
                      labelColor="rgba(0,0,0,0.45)"
                      value={data[0]?.value}
                      valueTextSize="medium"
                      dataTestPrefix={`${dataTestPrefix}-${handleTestSuffix(
                        data[0]?.title
                      )}`}
                    />
                  </Col>
                  <Col
                    xxl={labelSpan?.xxl}
                    xl={labelSpan?.xl}
                    lg={labelSpan?.lg}
                    md={24}
                    sm={24}
                    xs={24}
                  >
                    <LabelAndValue
                      label={data[1]?.title}
                      labelColor="rgba(0,0,0,0.45)"
                      value={data[1]?.value}
                      valueTextSize="medium"
                      dataTestPrefix={`${dataTestPrefix}-${handleTestSuffix(
                        data[1]?.title
                      )}`}
                    />
                  </Col>
                </>
              )}

              {data && data.length >= 4 ? (
                <Col
                  xxl={labelSpan?.xxl}
                  xl={labelSpan?.xl}
                  lg={labelSpan?.lg}
                  md={24}
                  sm={24}
                  xs={24}
                >
                  <LabelAndValue
                    label={data[3]?.title}
                    labelColor="rgba(0,0,0,0.45)"
                    value={data[3]?.value}
                    valueTextSize="medium"
                    dataTestPrefix={`${dataTestPrefix}-${handleTestSuffix(
                      data[3]?.title
                    )}`}
                  />
                </Col>
              ) : (
                <Col />
              )}
              {data && data.length >= 5 ? (
                <Col
                  xxl={labelSpan?.xxl}
                  xl={labelSpan?.xl}
                  lg={labelSpan?.lg}
                  md={24}
                  sm={24}
                  xs={24}
                >
                  <LabelAndValue
                    label={data[4]?.title}
                    labelColor="rgba(0,0,0,0.45)"
                    value={data[4]?.value}
                    valueTextSize="medium"
                    dataTestPrefix={`${dataTestPrefix}-${handleTestSuffix(
                      data[4]?.title
                    )}`}
                  />
                </Col>
              ) : (
                <Col />
              )}
              {data && data.length === 6 ? (
                <Col
                  xxl={labelSpan?.xxl}
                  xl={labelSpan?.xl}
                  lg={labelSpan?.lg}
                  md={24}
                  sm={24}
                  xs={24}
                >
                  <LabelAndValue
                    label={data[5]?.title}
                    labelColor="rgba(0,0,0,0.45)"
                    value={data[5]?.value}
                    valueTextSize="medium"
                    dataTestPrefix={`${dataTestPrefix}-${handleTestSuffix(
                      data[5]?.title
                    )}`}
                  />
                </Col>
              ) : (
                <Col />
              )}

              {data && data.length > 0 && (
                <Col
                  style={{
                    marginTop: breakpoint?.lg ? 0 : '2rem',
                  }}
                  xxl={labelSpan?.xxl}
                  xl={labelSpan?.xl}
                  lg={labelSpan?.lg}
                  md={24}
                  sm={24}
                  xs={24}
                >
                  <LabelAndValue
                    label={data[2]?.title}
                    value={data[2]?.value}
                    labelColor="rgba(0,0,0,0.45)"
                    valueTextSize="large"
                    textAlign={breakpoint?.lg ? 'end' : 'start'}
                    strong
                    dataTestPrefix={`${dataTestPrefix}-payments-amount`}
                  />
                </Col>
              )}
            </Row>
          </StIntermediateCard>
        </Skeleton>
      </Col>
    </Row>
  );
};
SummaryCard.defaultProps = {
  error: {
    has: false,
    title: '',
    reason: '',
    recommendation: '',
  },
};
SummaryCard.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  error: PropTypes.shape({
    has: PropTypes.bool,
    title: PropTypes.string,
    reason: PropTypes.string,
    recommendation: PropTypes.string,
  }),
  loading: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  detailRoute: PropTypes.string.isRequired,
};

export default SummaryCard;
