import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateBranchDataStart } from 'store/ducks/updateBranchData/actions';
import { createBranchStart } from 'store/ducks/createBranch/actions';
import { updateIsImplantedStart } from 'store/ducks/updateIsImplanted/actions';
import { getSessionUsername } from 'helpers/sessionService';
import warningHandler from 'helpers/warningHandler';
import errorHandler from 'helpers/errorHandler';
import { useBranchManagement } from 'utils/hooks/useBranchManagement';

export const useBranchDetailsForm = ({
  branchData = {},
  closeDrawer,
  isImplanted,
}) => {
  const dispatch = useDispatch();
  const userEmail = getSessionUsername();
  const { getListOfClients } = useBranchManagement();

  const [isApiConnected, setIsApiConnected] = useState('');
  const [name, setName] = useState('');
  const [skinInput, setSkinInput] = useState();
  const [planInput, setPlanInput] = useState();
  const [accessType, setAccessType] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [clientCode, setClientCode] = useState('');
  const [disableFields, setDisableFields] = useState(false);

  const plans = useSelector((state) => state.plans.plans) || {};
  const skins = useSelector((state) => state.skins.skins) || {};
  const branchDataHasBeenUpdated = useSelector(
    (state) => state.updateBranchData.branchDataHasBeenUpdated
  );

  const getPlanName = (id) => {
    const [planName, planId] = Object.entries(plans).find(([, value]) => {
      if (value === id) {
        return true;
      }
      return false;
    }) || ['Selecione', null];

    setPlanInput({
      id: planId,
      name: planName,
    });
  };

  const getSkinName = (id) => {
    const [skinName, skinId] = Object.entries(skins).find(([, value]) => {
      if (value === id) {
        return true;
      }
      return false;
    }) || ['Selecione', null];

    setSkinInput({
      id: skinId,
      name: skinName,
    });
  };

  const resetForm = () => {
    const _isImplanted = branchData.is_implanted === 'Produção';
    const _isApiConnected =
      branchData.is_api_connected === 'Ativo' ||
      branchData.is_api_connected === 'Sim' ||
      branchData.is_api_connected === true;
    const _name = branchData.name || '';
    const _clientCode = branchData.client_code || '';
    const _planId = branchData.plan_id || null;
    const _skinId = branchData.skin_id || null;
    const _isWeb = branchData?.is_web || false;
    const _isApp = branchData?.is_mobile || false;

    const defaultAccessTypes = isUpdating ? [] : ['Web', 'App'];
    if (isUpdating && _isWeb) {
      defaultAccessTypes.push('Web');
    }
    if (isUpdating && _isApp) {
      defaultAccessTypes.push('App');
    }

    setAccessType(defaultAccessTypes);
    dispatch(updateIsImplantedStart(_isImplanted));
    setIsApiConnected(_isApiConnected);
    setName(_name);
    setClientCode(_clientCode);
    getPlanName(_planId);
    getSkinName(_skinId);

    if (!isUpdating) {
      setDisableFields(false);
      closeDrawer();
    } else {
      setDisableFields(true);
    }
  };

  const setFormToBranchData = () => {
    const _isImplanted = branchData.is_implanted === 'Produção';
    const _isApiConnected =
      branchData.is_api_connected === 'Ativo' ||
      branchData.is_api_connected === 'Sim' ||
      branchData.is_api_connected === true;
    const _name = branchData.name || '';
    const _clientCode = branchData.client_code || '';
    const _planId = branchData.plan_id || null;
    const _skinId = branchData.skin_id || null;
    const _isWeb = branchData?.is_web || false;
    const _isApp = branchData?.is_mobile || false;
    const _clientId = branchData?.client_id || null;

    const defaultAccessTypes = [];
    if (_isWeb) {
      defaultAccessTypes.push('Web');
    }
    if (_isApp) {
      defaultAccessTypes.push('App');
    }

    setAccessType(defaultAccessTypes);
    dispatch(updateIsImplantedStart(_isImplanted));
    setIsApiConnected(_isApiConnected);
    setName(_name);
    setClientCode(_clientCode);
    getPlanName(_planId);
    getSkinName(_skinId);

    if (_clientId) {
      setDisableFields(true);
      setIsUpdating(true);
    } else {
      setDisableFields(false);
      setIsUpdating(false);
    }
  };

  const onFinish = () => {
    if (name.length === 0) {
      warningHandler('Por favor, preencha o campo Nome da empresa.');
      return;
    }
    if (accessType.length === 0) {
      warningHandler('Por favor, preencha o campo Tipo de acesso.');
      return;
    }
    if (planInput?.name === 'Selecione' || skinInput?.name === 'Selecione') {
      warningHandler('Por favor, selecione um plano e uma skin.');
      return;
    }
    const relevantValues = {
      is_implanted: isImplanted,
      is_api_connected: isApiConnected,
      name,
      client_code: clientCode,
      plan_id: planInput?.id,
      skin_id: skinInput?.id,
      is_web: accessType.includes('Web') || false,
      is_mobile: accessType.includes('App') || false,
    };
    if (isUpdating) {
      dispatch(updateBranchDataStart(relevantValues));
      setDisableFields(!disableFields);
    } else {
      dispatch(
        createBranchStart({
          email: userEmail,
          ...relevantValues,
        })
      );
    }
  };

  const onFinishFailed = (errorInfo) => errorHandler(String(errorInfo));

  useEffect(() => {
    setFormToBranchData();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [branchData]);

  useEffect(() => {
    if (branchDataHasBeenUpdated) {
      getListOfClients();
    }
  }, [branchDataHasBeenUpdated]);

  return {
    isApiConnected,
    setIsApiConnected,
    name,
    setName,
    isUpdating,
    setIsUpdating,
    clientCode,
    setClientCode,
    skinInput,
    setSkinInput,
    planInput,
    setPlanInput,
    accessType,
    setAccessType,
    disableFields,
    setDisableFields,
    resetForm,
    getPlanName,
    getSkinName,
    setFormToBranchData,
    onFinish,
    onFinishFailed,
    skins,
    plans,
  };
};
