import React, { useEffect } from 'react';
import { Button, Modal } from 'antd';
import {
  ButtonsContainer,
  Content,
  Icon,
  Loading,
  Title,
  TitleContainer,
} from './SendRadarConfirm.styled';
import useRadarEmailsCount from 'components/Radar/hooks/useRadarEmailsCount';

const SendRadarConfirm = ({
  typeRadarConfirm,
  onResetTypeRadarConfirm,
  handleRunRadar,
}) => {
  const { message, loading, fetchRadarEmailsCount } = useRadarEmailsCount();

  const handleOk = () => {
    handleRunRadar(typeRadarConfirm);
    onResetTypeRadarConfirm();
  };

  const handleCancel = () => {
    onResetTypeRadarConfirm();
  };

  useEffect(() => {
    if (typeRadarConfirm) {
      fetchRadarEmailsCount({ type: typeRadarConfirm });
    }
    // eslint-disable-next-line
  }, [typeRadarConfirm]);

  return (
    <Modal
      open={Boolean(typeRadarConfirm)}
      width={435}
      footer={null}
      closable={false}
    >
      {loading ? (
        <Loading />
      ) : (
        <>
          <TitleContainer>
            <Icon />
            <Title>
              Executar envio
              {typeRadarConfirm === 'weekly' ? ' semanal' : ' mensal'}
            </Title>
          </TitleContainer>
          <Content>{message}</Content>
          <ButtonsContainer>
            <Button onClick={handleCancel} disabled={loading}>
              Cancelar
            </Button>
            <Button
              type="primary"
              onClick={handleOk}
              disabled={loading}
              style={{ marginLeft: '10px' }}
            >
              Sim
            </Button>
          </ButtonsContainer>
        </>
      )}
    </Modal>
  );
};

export default SendRadarConfirm;
