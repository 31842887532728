import { Form, Space, Row } from 'antd';
import styled from 'styled-components';
import { colors } from 'styles/colors';

const { Item } = Form;

export const StSpace = styled(Space)`
  background-color: ${(props) => (props?.$breakpoint?.lg ? '' : colors.gray1)};
  padding: ${(props) => (props?.$breakpoint?.lg ? '' : '2rem')};
  border-radius: 5px;
`;

export const StRow = styled(Row)`
  height: 100%;
  background-color: ${colors.gray1};
  border-radius: 5px;
`;

export const StFormItem = styled(Item)`
  max-width: 269;
  color: ${colors.gray8};

  &&& {
    .ant-form-item-label > label {
      color: ${colors.gray8};
    }
  }
`;
