import React, { useEffect, useState } from 'react';
import { shortId } from 'helpers/shortId';
import { useNavigate } from 'react-router-dom';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { capitalizeFirstLetter } from 'helpers/capitalizeFirstLetter';
import { useProducts } from 'components/Fees/hooks/useProducts';
import { useMerchants } from 'components/Fees/hooks/useMerchants';
import { useInfiniteScrollPageChange } from 'utils/hooks/useInfiniteScrollPageChange';
import { useDebounce } from 'utils/hooks/useDebounce';
import { Empty } from 'commons/Empty';
import {
  Button,
  Checkbox,
  Col,
  Input,
  Popconfirm,
  Radio,
  Row,
  Select,
} from 'antd';
import {
  HeaderModal,
  StModal,
  StRadioGroup,
  StFormTitle,
  StForm,
  StSpin,
  StRow,
  StCheckboxSelectedAll,
  StMerchantsContainer,
  StMerchantList,
  StMerchantItem,
  StRowSpin,
  StDivider,
  StFormItem,
  StSpinContainer,
} from './styled';

const FeeModal = ({ setIsModalOpen, isModalOpen, currentActiveAcquirer }) => {
  const navigate = useNavigate();
  const { Search } = Input;
  const { acquirer, detail } = currentActiveAcquirer;
  const [radioOptionSelected, setRadioOptionSelected] = useState(null);
  const [productSelected, setProductSelected] = useState(null);
  const { products, loading: productLoading, fetchProducts } = useProducts();
  const {
    merchants,
    meta,
    page,
    loading: merchantLoading,
    setPage,
    setMerchants,
    fetchMerchants,
  } = useMerchants();
  const { lastElementRef } = useInfiniteScrollPageChange({
    loading: merchantLoading,
    hasMore: meta?.has_next,
    setPage,
  });
  const [query, setQuery] = useState('');
  const queryWithDebounce = useDebounce(query, 500);
  const [isAllMerchantSelected, setAllMerchantSelected] = useState(false);
  const [merchantsSelected, setMerchantsSelected] = useState([]);
  const mainProducts = products?.slice(0, 3);
  const otherProducts = products?.slice(3);
  const isLoading = productLoading && merchantLoading;

  const onAllMerchantChange = (e) => {
    setAllMerchantSelected(e.target.checked);
    setMerchantsSelected([]);
  };

  const isMerchantSelected = (id) => {
    if (isAllMerchantSelected) return true;
    return merchantsSelected.some(
      ({ merchant_id: merchantId }) => merchantId === id
    );
  };

  const onMerchantChange = (e, id, value) => {
    if (e.target.checked) {
      setMerchantsSelected([...merchantsSelected, { merchant_id: id, value }]);
    } else {
      setMerchantsSelected(
        merchantsSelected.filter(
          ({ merchant_id: merchantId }) => merchantId !== id
        )
      );
    }
  };

  const handleRadioOptionChange = (e) => {
    setRadioOptionSelected(e.target.value);
  };

  const onCancel = () => {
    setAllMerchantSelected(false);
    setIsModalOpen(false);
  };

  const renderBackButton = () => {
    return (
      <>
        {productSelected ||
        merchantsSelected.length > 0 ||
        isAllMerchantSelected ? (
          <Popconfirm
            title="Você tem certeza que deseja cancelar o cadastro de taxas?"
            onConfirm={onCancel}
            okText="Sim"
            cancelText="Não"
            icon={<ExclamationCircleFilled />}
          >
            <Button>Cancelar</Button>
          </Popconfirm>
        ) : (
          <Button onClick={onCancel}>Cancelar</Button>
        )}
      </>
    );
  };

  const renderNextButton = () => {
    const getSelectedProductInfo = (productId) => {
      const productFiltered = products.filter(({ id }) => id === productId);
      return productFiltered.length ? productFiltered[0] : {};
    };

    const {
      id: productId,
      value: productName,
      can_installments: canInstallments,
    } = getSelectedProductInfo(productSelected);

    const data = {
      acquirerId: detail?.acquirer_id,
      acquirerName: acquirer,
      acquirerIcon: detail?.icon,
      productId: productId === '1P' ? 1 : productId,
      productName,
      canInstallments,
      isAllMerchant: isAllMerchantSelected,
      selectedMerchants: isAllMerchantSelected ? [] : merchantsSelected,
    };

    const isNextButtonDisabled = () => {
      return !(
        productSelected &&
        (merchantsSelected.length > 0 || isAllMerchantSelected)
      );
    };

    const onNextClick = () => {
      navigate('/cadastro-taxas', { state: data });
    };

    return (
      <Button
        type="primary"
        disabled={isNextButtonDisabled()}
        onClick={onNextClick}
      >
        Avançar
      </Button>
    );
  };

  const headerModal = () => {
    return (
      <HeaderModal>
        {detail?.icon && (
          <img src={detail?.icon} alt={`Ícone da adquirente ${acquirer}`} />
        )}
        <span>{capitalizeFirstLetter(acquirer)}</span>
      </HeaderModal>
    );
  };

  const onSearchChange = (e) => {
    setQuery(e.target.value);
    setAllMerchantSelected(false);
    setPage(1);
  };

  useEffect(() => {
    if (radioOptionSelected === 'others') setProductSelected(null);
  }, [radioOptionSelected]);

  useEffect(() => {
    if (isModalOpen) {
      fetchProducts({ acquirerId: detail?.acquirer_id });
    } else {
      setMerchantsSelected([]);
      setProductSelected(null);
      setRadioOptionSelected(null);
      setAllMerchantSelected(false);
    }
    // eslint-disable-next-line
  }, [isModalOpen]);

  useEffect(() => {
    if (isModalOpen) {
      setMerchants([]);
      fetchMerchants({
        acquirerId: detail?.acquirer_id,
        query: queryWithDebounce,
      });
    }
    // eslint-disable-next-line
  }, [isModalOpen, queryWithDebounce]);

  useEffect(() => {
    if (isModalOpen && page > 1) {
      fetchMerchants({
        acquirerId: detail?.acquirer_id,
        query: queryWithDebounce,
      });
    }
    // eslint-disable-next-line
  }, [isModalOpen, page]);

  return (
    <StModal
      destroyOnClose
      centered
      title={headerModal()}
      width={584}
      open={isModalOpen}
      closable={false}
      maskClosable={false}
      footer={
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          {renderBackButton()}
          {renderNextButton()}
        </div>
      }
      bodyStyle={{
        padding: '0px 24px',
      }}
      labelCol={{
        span: 4,
      }}
      wrapperCol={{
        span: 14,
      }}
    >
      {!isLoading && (
        <StForm layout="vertical">
          <StFormTitle>Cadastrar taxas</StFormTitle>

          <>
            <StFormItem
              label="1. Selecione o produto para o qual deseja cadastrar taxas:"
              style={{ width: '100%' }}
            >
              <StRow>
                {!productLoading && products.length > 0 && (
                  <StRadioGroup onChange={(e) => handleRadioOptionChange(e)}>
                    {mainProducts &&
                      mainProducts?.map((product) => (
                        <Radio
                          key={shortId()}
                          value={product.id}
                          onChange={(e) => setProductSelected(e.target.value)}
                        >
                          {product.value}
                        </Radio>
                      ))}

                    {otherProducts && (
                      <Row style={{ display: 'flex', alignItems: 'center' }}>
                        <Radio key={shortId()} value="others">
                          Outros:
                        </Radio>
                        <Select
                          size="small"
                          placeholder="Selecionar"
                          disabled={radioOptionSelected !== 'others'}
                          style={{ width: 320 }}
                          onChange={(value) => setProductSelected(value)}
                          value={
                            radioOptionSelected === 'others'
                              ? productSelected
                              : null
                          }
                        >
                          {otherProducts &&
                            otherProducts.map((product) => (
                              <Select.Option key={shortId()} value={product.id}>
                                {product.value}
                              </Select.Option>
                            ))}
                        </Select>
                      </Row>
                    )}
                  </StRadioGroup>
                )}
                {!productLoading && products.length === 0 && (
                  <div style={{ width: '100%' }}>
                    <Empty
                      title="Os produtos não foram encontrados para essa adquirente."
                      reason="Por favor, tente novamente."
                    />
                  </div>
                )}
                {productLoading && <StSpin />}
              </StRow>
            </StFormItem>
            <StFormItem
              name="merchants"
              label="2. Selecione os CNPJs para os quais deseja cadastrar taxas:"
              style={{ width: '100%' }}
            >
              <StRow>
                <Search
                  placeholder="Buscar CNPJ ou apelido da loja"
                  onChange={onSearchChange}
                  disabled={merchantLoading || isAllMerchantSelected}
                  allowClear
                  style={{ width: '100%' }}
                />
                <StMerchantsContainer>
                  <StRow>
                    <Col span={24}>
                      {merchants.length > 0 && !query && (
                        <>
                          <StCheckboxSelectedAll
                            checked={isAllMerchantSelected}
                            onChange={(e) => onAllMerchantChange(e)}
                          >
                            Selecionar todos
                          </StCheckboxSelectedAll>
                          <StDivider />
                        </>
                      )}
                      <StMerchantList>
                        {merchants.map(({ merchant_id: id, value }, index) => {
                          return (
                            <StMerchantItem
                              key={shortId()}
                              ref={
                                merchants.length === index + 1
                                  ? lastElementRef
                                  : null
                              }
                            >
                              <Checkbox
                                checked={isMerchantSelected(id)}
                                style={{ lineHeight: '32px' }}
                                onChange={(e) => onMerchantChange(e, id, value)}
                                disabled={isAllMerchantSelected}
                              >
                                {value}
                              </Checkbox>
                            </StMerchantItem>
                          );
                        })}
                      </StMerchantList>
                      <StSpinContainer>
                        {merchantLoading && <StSpin />}
                      </StSpinContainer>
                      {!merchantLoading && merchants.length === 0 && (
                        <Empty
                          title="Os estabelecimentos não foram encontrados."
                          reason="Por favor, tente novamente."
                        />
                      )}
                    </Col>
                  </StRow>
                </StMerchantsContainer>
              </StRow>
            </StFormItem>
          </>
        </StForm>
      )}

      {isLoading && (
        <StRowSpin>
          <StSpin />
        </StRowSpin>
      )}
    </StModal>
  );
};

export default FeeModal;
