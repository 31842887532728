import { apiXLSXDownload, apiCSVDownload } from '../../api';

const getExportPaymentDivergencesDetailsFromAPI = async (payload) => {
  const config = {
    params: {
      start_date: payload.startDate.format('YYYY-MM-DD'),
      end_date: payload.endDate.format('YYYY-MM-DD'),
      sort_fields: payload.sortFields,
      page: payload.page,
      per_page: payload.perPage,
      q: payload.q,
      download_type: payload.dropdownOption,
    },
  };

  const isExcel = config.params.download_type === 'excel';

  const response = isExcel
    ? await apiXLSXDownload().get('/export/divergences/detail', config)
    : await apiCSVDownload().get('/export/divergences/detail', config);
  let url = '';
  if (isExcel) {
    url = window.URL.createObjectURL(new Blob([response.data]));
  } else {
    url = window.URL.createObjectURL(new Blob([response.data]), {
      type: 'text/csv',
    });
  }
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute(
    'download',
    `Relatório de divergência de pagamentos${isExcel ? '.xlsx' : '.csv'}`
  );
  document.body.appendChild(link);
  link.click();
  return response;
};

export default getExportPaymentDivergencesDetailsFromAPI;
