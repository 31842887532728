import { useCallback, useState } from 'react';
import { getEmailCount } from 'services/radar/getEmailCount';

const useRadarEmailsCount = () => {
  const defaultMessage =
    'Essa ação dispara o Radar para todos os usuários elegíveis. Tem certeza que deseja prosseguir?';
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(defaultMessage);

  const fetchRadarEmailsCount = useCallback(({ type }) => {
    setLoading(true);

    getEmailCount({ type })
      .then(({ emailsCount = undefined }) => {
        if (emailsCount > 0) {
          setMessage(
            `Essa ação dispara o Radar para todos os ${emailsCount} usuários elegíveis. Tem certeza que deseja prosseguir?`
          );
        }
      })
      .catch(() => setMessage(defaultMessage))
      .finally(() => setLoading(false));
  }, []);

  return {
    loading,
    message,
    fetchRadarEmailsCount,
  };
};

export default useRadarEmailsCount;
