export const actionTypes = {
  OFX_PARAMETERS_FILTERS_START:
    '@ofxParametersFilters/OFX_PARAMETERS_FILTERS_START',
  OFX_PARAMETERS_FILTERS_SUCCESS:
    '@ofxParametersFilters/OFX_PARAMETERS_FILTERS_SUCCESS',
  OFX_PARAMETERS_FILTERS_ERROR:
    '@ofxParametersFilters/OFX_PARAMETERS_FILTERS_ERROR',
  OFX_PARAMETERS_FILTERS_ZERO_LENGTH:
    '@ofxParametersFilters/OFX_PARAMETERS_FILTERS_ZERO_LENGTH',
  UPDATE_OFX_PARAMETERS_FILTERS:
    '@ofxParametersFilters/UPDATE_OFX_PARAMETERS_FILTERS',
};
