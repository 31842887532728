import { actionTypes } from './types';

const INITIAL_STATE = {
  error: undefined,
  errorMessage: undefined,
  loading: true,
  isError: false,
  client: undefined,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case actionTypes.GET_CLIENT_START:
    return {
      ...state,
      loading: true,
      error: undefined,
      errorMessage: undefined,
      isError: false,
    };
  case actionTypes.FILE_IDENTIFICATION_START:
    return {
      ...state,
      loading: true,
      error: undefined,
      errorMessage: undefined,
      isError: false,
    };
  case actionTypes.GET_CLIENT_ERROR:
  case actionTypes.POST_CLIENT_ERROR:
  case actionTypes.FILE_IDENTIFICATION_ERROR:
  case actionTypes.PUT_CLIENT_ERROR:
    return {
      ...state,
      loading: false,
      error: action.error.error,
      errorMessage: action.payload,
      isError: true,
    };
  case actionTypes.GET_CLIENT_SUCCESS:
    return {
      ...state,
      loading: false,
      client: action.payload,
    };
  case actionTypes.FILE_IDENTIFICATION_SUCCESS:
    return {
      ...state,
      loading: false,
      client: action.payload,
    };
  case actionTypes.PUT_CLIENT_SUCCESS:
    return {
      ...state,
      loading: false,
      isError: false,
    };
  default:
    return state;
  }
};

export default reducer;
