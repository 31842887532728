import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from 'antd';
import { StLimiter, StContainer } from './styled';
import { colors } from 'styles/colors';

const FiltersContainer = ({ title, subTitle, children }) => {
  const { useBreakpoint } = Grid;
  const breakpoint = useBreakpoint();

  return (
    <StContainer $breakpoint={breakpoint}>
      <StLimiter $breakpoint={breakpoint}>
        <>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography.Title
              style={{
                margin: 0,
                fontSize: '20px',
                fontWeight: '500',
                color: colors.gray11,
              }}
            >
              {title}
            </Typography.Title>
            {subTitle && (
              <Typography.Text
                style={{ marginLeft: '2rem', fontSize: 12, marginTop: 5 }}
              >
                {subTitle}
              </Typography.Text>
            )}
          </div>
          <div style={{ marginTop: '1rem' }}>{children}</div>
        </>
      </StLimiter>
    </StContainer>
  );
};

FiltersContainer.defaultProps = {
  subTitle: '',
};

FiltersContainer.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  children: PropTypes.element.isRequired,
};

export default FiltersContainer;
