import { call, put } from 'redux-saga/effects';
import authLogout from 'services/logout';
import { notification } from 'antd';

import errorHandler from 'helpers/errorHandler';
import customHistory from 'helpers/history';
import { logoutSuccess, logoutFailure } from './actions';
import {
  getSessionAccessToken,
  removeLocalStorage,
} from 'helpers/sessionService';

export function* logoutSaga() {
  try {
    if (getSessionAccessToken()) {
      removeLocalStorage();
      yield call(authLogout);
      yield put(logoutSuccess());
      notification.success({
        message: 'Você foi deslogado com sucesso!',
        props: {
          'data-testid': 'notification-logout',
        },
      });
      customHistory.push('/');
    }
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        logoutFailure(err.response.data.error.message, err.response.data.error)
      );
      errorHandler(err?.response?.data?.error?.message, err);
    }
  }
}
