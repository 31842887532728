import React from 'react';
import { BankOutlined } from '@ant-design/icons';

export const parsedDropdownKeys = {
  neste_mes: 'Neste mês',
  ultimo_mes: 'Último mês',
  ultimo_dia: 'Último dia',
};

export const reconIconUrl =
  'https://img.icons8.com/external-itim2101-lineal-itim2101/64/000000/external-payment-money-and-economy-itim2101-lineal-itim2101-1.png';

export const bankReconIcon = (
  <BankOutlined
    style={{
      fontSize: '24px',
      paddingRight: '12px',
    }}
  />
);

export const parseReconStyle = (reconType, highestTotal, lowestTotal) => {
  if (reconType === 'bank' && (highestTotal > 0 || lowestTotal > 0)) {
    return {
      height: '90%',
      justifyContent: 'space-between',
    };
  }

  if (reconType === 'bank' && highestTotal === 0 && lowestTotal === 0) {
    return {
      height: '90%',
    };
  }

  if (reconType === 'payments') {
    return {
      height: '170px',
      justifyContent: 'space-between',
    };
  }

  if (reconType !== 'bank') {
    return {
      height: '130px',
      justifyContent: 'space-between',
    };
  }

  return {
    height: '90%',
    justifyContent: 'space-between',
  };
};
