import { call, put, delay } from 'redux-saga/effects';

import errorHandler from 'helpers/errorHandler';

import { postOptinSchedulerRequest } from 'services/optin/optinRequest';

import {
  optinRequestSuccess,
  optinRequestError,
} from 'store/ducks/optin/optinRequest/actions';
import successHandler from 'helpers/successHandler';

export function* optinRequestSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(postOptinSchedulerRequest, payload);
    yield delay(1000);
    successHandler('Solicitação de optin enviada com sucesso!');
    yield put(optinRequestSuccess(apiData));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        optinRequestError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(optinRequestError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}
