/* eslint-disable prefer-const */
export const handleGroupType = (value) => {
  const result = {
    '1-1': '1_1',
    'N-N': 'N_N',
  };

  return result[value];
};

export const handleReconType = (value) => {
  const result = {
    Vendas: 'sale',
    Pagamentos: 'payment',
  };

  return result[value];
};

export const handleCategory = (value) => {
  const result = {
    Padrão: 'default',
    Personalizada: 'custom',
  };

  return result[value];
};

export const handleIsActive = (value) => {
  const result = {
    Ativo: true,
    Inativo: false,
  };

  return result[value];
};

export const handleCriteria = (array, type) => {
  let optionalArray = [];
  let requiredArray = [];

  const result = {
    Adquirente: 'ACQUIRER_NAME',
    Bandeira: 'BRAND_CODE',
    'Data da transação': 'TRANSACTION_DATE',
    Estabelecimento: 'MERCHANT_CODE',
    Filial: 'STORE_CODE',
    Parcelas: 'INSTALLMENT',
    Produto: 'PRODUCT_CODE',
    // 'Nota/Pedido': 'CLIENT_CODE',
    TID: 'TID',
    NSU: 'NSU',
    Autorização: 'ACQUIRE_AUTHORIZATION',
  };

  if (type === 'required') {
    array.forEach((item) => requiredArray.push(result[item]));
    return requiredArray;
  }

  array.forEach((item) => optionalArray.push(result[item]));
  return optionalArray;
};
