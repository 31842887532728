import { Button, Typography } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from 'styles/colors';

export const StHeaderWrapper = styled.div`
  background-color: ${colors.gray1};
  :hover {
    background-color: ${colors.primary6};
  }
  width: 100%;
  padding: 4rem 14px;
  text-align: ${(props) => (props?.$breakpoint?.lg ? 'center' : 'start')};
`;

export const StContainer = styled.div`
  /* display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  align-content: start; */
`;

export const StButton = styled(Button)`
  color: ${colors.gray8} !important;
  display: flex !important;
  justify-content: start;
  width: 100%;
  font-size: 1.5rem;
  text-align: start !important;
  /* margin: 10px 0; */
  padding: 1.5rem 14px !important;
  border-bottom: none !important;
  border-top: none !important;
  border-right: none !important;
  border-left: 6px solid ${colors.gray1} !important;
  height: 5rem !important;
  border-radius: 0 !important;
  :first-of-type {
    border-top: none;
  }

  &&& {
    :hover {
      background: ${colors.primary1} !important;
      transition: 0.2s !important;
      border-left: 6px solid ${colors.primary6} !important;
    }
  }
`;

export const StMenuButton = styled(Button)`
  &&& {
    border: none;
    height: 100%;
    color: ${colors.gray1};
    background-color: ${({ bgColor }) => bgColor && bgColor};
  }
`;

export const StBusinessButton = styled(Button)`
  width: 100%;
  cursor: default;
  background-color: ${colors.gray2};
  border-bottom: none;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  text-align: start;
  font-size: 1.5rem;
  padding: 1.5rem 14px;
  height: inherit;
  :hover {
    background-color: ${colors.gray2};
    color: ${colors.gray11};
    border-top: 1px solid;
    border-top-color: rgba(74, 74, 74, 0.2) !important;
  }
`;

export const StServiceUpgradeButton = styled(Typography)`
  &&& {
    cursor: pointer;
    color: ${colors.gray1};
    font-size: 12px;
    font-weight: 500;
    text-decoration: underline;
  }
`;

export const StExitBtnContainer = styled.div`
  width: 100%;
  height: 35px;
`;

export const StExitBtn = styled(Button)`
  width: 100%;
  text-align: start;
  border: none;
  display: flex;
`;

export const StUserMenuContainer = styled.div`
  width: 240px;
  font-size: 12px;
  position: absolute;
  top: 40px;
  right: 8px;
  background-color: ${colors.gray1};
`;

export const StUserDetailContainer = styled.div`
  padding: 16px 22px 8px;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: ${colors.gray9};
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;

  > span:nth-child(2) {
    color: ${colors.gray8};
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
  }
`;

export const StBranchDetailContainer = styled.div`
  width: 100%;
  padding: 16px 22px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StBranchName = styled.div`
  width: 100%;
  color: ${colors.gray9};
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
`;

export const StBranchLogo = styled.div`
  width: 100%;
`;

export const StUserMenuButton = styled(Button)`
  color: ${colors.gray9};
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  font-size: 14px;
  border-bottom: none;
  border-top: none;
  border-right: none;
  border-left: 6px solid ${colors.gray1};
  height: 38px;
  border-radius: 0;

  > img {
    width: 16px;
    height: 16px;
  }

  &&& {
    :hover {
      color: ${colors.gray9};
      background: ${colors.primary1};
      transition: 0.2s;
      border-left: 6px solid ${colors.primary6};
      border-radius: 0;
    }
  }
`;

export const StUserMenuLink = styled(Link)`
  color: ${colors.gray9} !important;
`;
