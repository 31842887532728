import { action } from 'typesafe-actions';
import { actionTypes } from 'store/ducks/details/export/reconciledSalesDetails/types';

export const exportReconciledSalesDetailsStart = (data) =>
  action(actionTypes.EXPORT_RECONCILED_SALES_DETAILS_START, data);
export const exportReconciledSalesDetailsSuccess = (data) =>
  action(actionTypes.EXPORT_RECONCILED_SALES_DETAILS_SUCCESS, data);
export const exportReconciledSalesDetailsError = (errorMessage, error) =>
  action(
    actionTypes.EXPORT_RECONCILED_SALES_DETAILS_ERROR,
    { errorMessage },
    undefined,
    { error }
  );
