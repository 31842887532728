import { call, put } from 'redux-saga/effects';

import errorHandler from 'helpers/errorHandler';

import {
  listOfClientsSelectSuccess,
  listOfClientsSelectError,
} from 'store/ducks/listClientsSelect/actions';

import getListOfClientsSelect from 'services/listClientSelect';

export function* listOfClientsSelectSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(getListOfClientsSelect, payload);
    yield put(listOfClientsSelectSuccess(apiData));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        listOfClientsSelectError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(listOfClientsSelectError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}
