import { call, put } from 'redux-saga/effects';
import getListUsers from 'services/listUsersClient';

import errorHandler from 'helpers/errorHandler';
import { listUsersSuccess, listUsersFailure } from './actions';

export function* listUsersSaga(actions) {
  const { scopeId } = actions.payload;
  try {
    const dataFromAPI = yield call(getListUsers, scopeId);
    yield put(listUsersSuccess(dataFromAPI));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        listUsersFailure(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    }
  }
}
