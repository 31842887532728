import { actionTypes } from 'store/ducks/radar/radarRunTest/types';

const INITIAL_STATE = {
  error: undefined,
  errorMessage: undefined,
  loading: false,
  isError: false,
  radarHasBeenSent: false,
  data: [],
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_RADAR_HAS_BEEN_SENT:
      return {
        ...state,
        radarHasBeenSent: action.payload.value,
      };
    case actionTypes.RADAR_RUN_TEST_START:
      return {
        ...state,
        loading: true,
        error: undefined,
        errorMessage: undefined,
        isError: false,
        radarHasBeenSent: false,
      };
    case actionTypes.RADAR_RUN_TEST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        radarHasBeenSent: true,
        data: action.payload?.data,
        isError: false,
        errorMessage: undefined,
      };
    case actionTypes.RADAR_RUN_TEST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error.error,
        errorMessage: action.payload,
        isError: true,
        radarHasBeenSent: false,
      };
    default:
      return state;
  }
};

export default reducer;
