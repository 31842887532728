import {
  Checkbox,
  Divider,
  Form,
  List,
  Modal,
  Radio,
  Row,
  Spin,
  Typography,
} from 'antd';
import styled from 'styled-components';
import { colors } from 'styles/colors';

export const StModal = styled(Modal)`
  .ant-modal-header,
  .ant-modal-footer {
    border: none;
  }
`;

export const HeaderModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 8px;

  .ant-modal-header {
    border-bottom: none;
  }

  img {
    width: 22px;
    height: 22px;
    margin-right: 16px;
  }

  span {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: ${colors.gray9};
  }
`;

export const StForm = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
`;

export const StRow = styled(Row)`
  width: 100%;
`;

export const StFormItem = styled(Form.Item)`
  width: 100%;
  margin-bottom: 0;
`;

export const StFormTitle = styled(Typography.Text)`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: ${colors.gray9};
`;

export const StRadioGroup = styled(Radio.Group)`
  .ant-radio-wrapper {
    margin-bottom: 0;
  }

  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StRowSpin = styled(Row)`
  width: 100%;
  height: 584px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StSpinContainer = styled.div`
  height: 20px;
  margin-top: 10px;
`;

export const StSpin = styled(Spin)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const StMerchantsContainer = styled.div`
  width: 100%;
  padding: 8px 8px;
`;

export const StMerchantList = styled(List)`
  max-height: 250px;
  overflow-y: auto;
`;

export const StMerchantItem = styled.p`
  margin: 0;
  border-bottom: 1px solid ${colors.gray4};
  line-height: 32px;
`;

export const StCheckboxSelectedAll = styled(Checkbox)`
  padding: 10px 0;
  line-height: 32px;
`;

export const StDivider = styled(Divider)`
  margin: 0;
`;
