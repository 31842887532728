/* eslint-disable quotes */
import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Grid } from 'antd';

import { useDispatch, useSelector } from 'react-redux';
import {
  listBankConciliationStart,
  updateAfterSave,
} from '../../../../store/ducks/bankConciliation/actions';
import ColumnList from './table/ColumnList';
import customHistory from '../../../../helpers/history';
import { generateFilterDataStructure } from 'helpers/tableService';
import { updateBankConciliationFilters } from 'store/ducks/filters/details/bankConciliationFilters/actions';
import * as St from './styled';
import { perPageOptions, showTotalPage } from 'constants/perPageOptions';
import { Unavailabledata } from './components/UnavailableData';
import { shortId } from 'helpers/shortId';
import { useLocation } from 'react-router-dom';

const ConciliationReport = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);

  const dispatch = useDispatch();
  const { useBreakpoint } = Grid;
  const breakpoint = useBreakpoint();

  const loadingList = useSelector((state) => state.bankConciliation.loading);
  const errorList = useSelector((state) => state.bankConciliation.isError);
  const listData = useSelector(
    (state) => state.bankConciliation.listConciliationData
  );

  const dataBeingProcessed = useSelector(
    (state) => state.bankConciliation.dataBeingProcessed
  );

  const filters = useSelector((state) => state.bankConciliationFilters.filters);
  const [toParamsFilters, setToParamsFilters] = useState(
    useSelector((state) => state.bankConciliationFilters.toParamsFilters)
  );

  const total = useSelector(
    (state) => state.bankConciliation.countConciliation
  );
  const startDate = useSelector(
    (state) => state.updateScreenToScreenDate.startDate
  );
  const endDate = useSelector(
    (state) => state.updateScreenToScreenDate.endDate
  );
  const [perPage, setPerPage] = useState(10);
  const [pageCurrent, setPageCurrent] = useState(
    parseInt(searchParams.get('page') || 1, 10)
  );
  const [columnList, setColumnList] = useState([]);
  const [sortFields, setSortFields] = useState({});

  const pagination = {
    current: pageCurrent,
    pageSize: perPage,
    total,
    pageSizeOptions: perPageOptions,
    showTotal: showTotalPage,
    showSizeChanger: Boolean(total >= 10),
  };

  const goToImportNewFile = () => {
    customHistory.push('/importacao-arquivos');
  };

  useEffect(() => {
    if (pageCurrent && perPage && startDate && endDate) {
      dispatch(
        listBankConciliationStart({
          startDate,
          endDate,
          sortFields,
          page: pageCurrent,
          perPage,
          q: {
            filter: toParamsFilters,
          },
        })
      );
    }
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, pageCurrent, perPage, sortFields, toParamsFilters, endDate]);

  // Functions
  const onTablePaginationOrFiltersOrSorterChange = async (
    paginationHandler,
    tableFilters,
    sorter
  ) => {
    const hasMultipleColumnSorting = Array.isArray(sorter);
    if (hasMultipleColumnSorting) {
      let _sortFields = {};
      sorter.forEach((column) => {
        _sortFields = {
          ..._sortFields,
          [column.field]: column.order,
        };
      });
      setSortFields(_sortFields);
    } else {
      setSortFields({ [sorter.field]: sorter.order });
    }
    const filterDataToDispatch = generateFilterDataStructure(tableFilters);
    setToParamsFilters(filterDataToDispatch);
    dispatch(updateBankConciliationFilters(filterDataToDispatch));
    setPerPage(paginationHandler.pageSize);
    setPageCurrent(paginationHandler.current);
  };

  // Build Columns
  useEffect(() => {
    setColumnList(ColumnList(filters, toParamsFilters, pageCurrent));
  }, [
    listData,
    pageCurrent,
    total,
    perPage,
    sortFields,
    toParamsFilters,
    filters,
  ]);

  useEffect(() => {
    dispatch(updateAfterSave(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToReports = () => {
    customHistory.push('/conciliacao-bancaria-relatorios');
  };

  const dataSource = listData.map((obj) => ({
    ...obj,
    key: shortId(),
  }));

  // If there's unavailable data
  if (breakpoint?.md && errorList) {
    return <Unavailabledata loadingList={loadingList} />;
  }

  return (
    <St.Container>
      <St.FirstRow>
        <St.Title strong>Tabela comparativa</St.Title>
      </St.FirstRow>

      <St.SecondRow>
        <Col>
          <St.Subtitle>
            Analise o comparativo entre os pagamentos das adquirentes e os{' '}
            <b>extratos OFX</b> importados para a plataforma
          </St.Subtitle>
        </Col>
        <Col>
          <Button
            type="primary"
            style={{
              marginRight: '16px',
            }}
            onClick={goToImportNewFile}
            ghost
          >
            Importar novo extrato
          </Button>
          <Button type="primary" onClick={goToReports}>
            Ver Relatório
          </Button>
        </Col>
      </St.SecondRow>

      {dataBeingProcessed && !loadingList && (
        <Row>
          <St.NotificationColumn span={24}>
            <St.NotificationIcon />
            <St.NotificationText>
              Ainda há dados em processamento, aguarde mais alguns minutos para
              que o comparativo seja atualizado.
            </St.NotificationText>
          </St.NotificationColumn>
        </Row>
      )}

      <St.TableContainer>
        <St.ConciliationTable
          scrollToFirstRowOnChange
          loading={loadingList}
          onChange={onTablePaginationOrFiltersOrSorterChange}
          pagination={pagination}
          dataSource={dataSource}
          customColumns={columnList}
          columns={columnList}
          defaultSelectedColumns={[]}
          showSorterTooltip={false}
          scroll={{ y: window.innerHeight - 400 }}
        />
      </St.TableContainer>
    </St.Container>
  );
};

export default ConciliationReport;
