import { Typography } from 'antd';
import { getDefaultColumnWidth } from 'helpers/tableService';
import React from 'react';
import { shortId } from 'helpers/shortId';
import Actions from '../components/Tables/Actions';
import {
  handleCategory,
  handleGroup,
  handleStatus,
  handleUnderscore,
} from './handleColumnContent';

const Text = Typography;

// eslint-disable-next-line no-unused-vars
const columns = (apiFilters, localFilters, toParamsFilters) => [
  {
    title: 'ID',
    dataIndex: 'rule_id',
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: getDefaultColumnWidth('rule_id'),
    align: 'left',
  },

  {
    title: 'Grupo',
    dataIndex: 'recon_type',
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: getDefaultColumnWidth('recon_type'),
    align: 'left',
    render: (text) => <Text>{handleGroup(text)}</Text>,
  },

  {
    title: 'Tipo',
    dataIndex: 'group_type',
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: getDefaultColumnWidth('group_type'),
    align: 'left',
    render: (text) => <Text>{handleUnderscore(text)}</Text>,
  },
  {
    title: 'Status',
    dataIndex: 'is_active',
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: getDefaultColumnWidth('is_active'),
    align: 'left',
    render: (text) => <Text>{handleStatus(text)}</Text>,
  },

  {
    title: 'Categoria',
    dataIndex: 'category',
    sorter: {
      multiple: 1,
    },
    width: getDefaultColumnWidth('category') + 20,
    align: 'left',
    render: (text) => <Text>{handleCategory(text)}</Text>,
  },

  {
    title: 'Código e nome da empresa',
    dataIndex: 'client_name_code',
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: getDefaultColumnWidth('client_name_code'),
    align: 'left',
  },
  {
    title: 'Ordem',
    dataIndex: 'execution_sequence',
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: getDefaultColumnWidth('execution_sequence'),
    align: 'left',
  },

  {
    title: 'Descrição',
    dataIndex: 'description',
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: getDefaultColumnWidth('description'),
    align: 'left',
  },

  {
    title: 'Ações',
    dataIndex: 'actions',
    key: shortId(),
    width: 100,
    render: (_, record) => {
      return <Actions currentRow={record} />;
    },
    align: 'center',
    fixed: 'right',
  },
];

export default columns;
