import React from 'react';
import { Card, Row, Col, Grid, Skeleton } from 'antd';

import PropTypes from 'prop-types';
import customHistory from 'helpers/history';

import { LabelAndValue } from 'commons/LabelAndValue';
import { TitleAndIcon } from 'commons/TitleAndIcon';
import { ErrorCard } from 'commons/ErrorCard';
import { StSeeDetailsButton } from 'components/Intermediate/styled';
import { StIntermediateCard } from 'commons/Intermediate/styled';
import { dataTestIdHandler } from 'helpers/dataTestIdHandler';

const SummaryCard = ({ data, isError, loading }) => {
  const { useBreakpoint } = Grid;
  const breakpoint = useBreakpoint();
  const dataTestPrefix = 'summary-card';

  const goToReports = () => {
    customHistory.push('/vendas-relatorios');
  };

  const convertSalesQty = (salesQty) => {
    return salesQty.toLocaleString('pt-BR');
  };

  if (isError) {
    return (
      <Row style={{ marginTop: '2rem' }}>
        <Col lg={10} md={18}>
          <Card style={{ borderRadius: 4 }} loading={loading}>
            <ErrorCard
              title="Dados indisponíveis"
              reason="Não conseguimos buscar os dados, mas já estamos trabalhando para normalizar a situação."
              recommendation="Tente atualizar a página em alguns instantes."
            />
          </Card>
        </Col>
      </Row>
    );
  }
  return (
    <Row style={{ marginTop: '2rem' }}>
      <Col xxl={8} xl={12} lg={12} md={18} sm={24} xs={24}>
        <Skeleton loading={loading} active paragraph={{ rows: 6 }}>
          <StIntermediateCard
            loading={loading}
            actions={[
              <div style={{ textAlign: 'end' }}>
                <StSeeDetailsButton
                  type="link"
                  size="small"
                  fontSize="1.75rem"
                  onClick={goToReports}
                  {...dataTestIdHandler(dataTestPrefix, 'go-to-reports')}
                >
                  Ver relatório
                </StSeeDetailsButton>
              </div>,
            ]}
          >
            <Row>
              <Col>
                <TitleAndIcon
                  // iconUri={salesSummaryIconUri}
                  titleText="Resumo total"
                  titleFontSize="18px"
                  dataTestPrefix={dataTestPrefix}
                />
              </Col>
            </Row>
            <Row justify="space-between" style={{ marginTop: '1.5rem' }}>
              <Col>
                <LabelAndValue
                  label="Quantidade de vendas"
                  labelColor="rgba(0,0,0,0.45)"
                  value={convertSalesQty(data.sales_quantity)}
                  valueTextSize="medium"
                  dataTestPrefix={`${dataTestPrefix}-sales-quantity`}
                />
              </Col>
              <Col>
                <LabelAndValue
                  label="Ticket médio"
                  labelColor="rgba(0,0,0,0.45)"
                  value={data.ticket_avarage}
                  valueTextSize="medium"
                  dataTestPrefix={`${dataTestPrefix}-ticket-average`}
                />
              </Col>
              <Col
                style={{
                  marginTop: breakpoint?.lg ? 0 : '2rem',
                }}
              >
                <LabelAndValue
                  label="Total de vendas"
                  labelColor="rgba(0,0,0,0.45)"
                  value={data.sales_amount}
                  valueTextSize="large"
                  textAlign={breakpoint?.lg ? 'end' : 'start'}
                  strong
                  dataTestPrefix={`${dataTestPrefix}-sales-amount`}
                />
              </Col>
            </Row>
          </StIntermediateCard>
        </Skeleton>
      </Col>
    </Row>
  );
};

SummaryCard.propTypes = {
  data: PropTypes.shape({
    sales_quantity: PropTypes.number,
    ticket_avarage: PropTypes.string,
    sales_amount: PropTypes.string,
  }).isRequired,
  isError: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default SummaryCard;
