import { lambdaApi } from '../api';

const transformer = (apiResponse) => {
  const { data, meta } = apiResponse;

  const fees = data.fees.map((fee) => {
    const feePercent = Number(fee.fee_percent || 0).toFixed(2);

    return {
      ...fee,
      fee_percent: String(feePercent).replace('.', ','),
    };
  });

  return {
    fees,
    meta: meta,
  };
};

const getFeesDetail = async ({
  acquirerId,
  page,
  perPage,
  feeStatus,
  selectedStore,
  toParamsFilters,
  sortFields,
}) => {
  const config = {
    params: {
      acquirer_id: acquirerId,
      page,
      per_page: perPage,
      fee_status: feeStatus,
      store_id: selectedStore?.value,
      sort_fields: JSON.stringify(sortFields),
      q: JSON.stringify({
        filter: toParamsFilters,
      }),
    },
  };

  const response = await lambdaApi().get('/fees/detail', config);

  return transformer(response.data);
};
export default getFeesDetail;
