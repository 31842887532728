import { getSessionClientId } from 'helpers/sessionService';
import { lambdaApi } from '../api';

const transformer = (apiResponse) => {
  return {
    allLegalRepresentatives: apiResponse,
    lastLegalRepresentative: apiResponse[0],
  };
};

export const getLegalRepresentative = async () => {
  const config = {
    params: {
      client_id: getSessionClientId(),
    },
  };
  const response = await lambdaApi().get('/legal/representative', config);
  return transformer(response.data);
};

export const postLegalRepresentative = async (payload) => {
  const response = await lambdaApi().post('/legal/representative', {
    client_id: getSessionClientId(),
    ...payload,
  });
  return response.data;
};
