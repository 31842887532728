import { call, put } from 'redux-saga/effects';
import errorHandler from 'helpers/errorHandler';
import getDivergencesInfoFiltersFromAPI from 'services/filters/divergencesInfoFilters';
import { divergencesInfoFiltersSuccess, divergencesInfoFiltersError } from './actions';

export function* divergencesInfoFiltersSaga(action) {
  const { payload } = action;

  try {
    const apiData = yield call(getDivergencesInfoFiltersFromAPI, payload);
    yield put(divergencesInfoFiltersSuccess(apiData));
  } catch (err) {
    if (
      err?.response?.data?.error &&
      err?.response?.data?.error?.type &&
      err?.response?.data?.error?.message
    ) {
      yield put(
        divergencesInfoFiltersError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(
        err.response.data.error.message,
        err.response.data.error
      );
    }
  }
}
