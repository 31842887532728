import styled from 'styled-components';
import { Col, Row, Typography } from 'antd';
import { colors } from 'styles/colors';

const { Text } = Typography;
const AntTitle = Typography.Title;

export const ContainerRow = styled(Row)`
  &&& {
    margin-top: 5rem;
    padding: 24px;
    background: ${colors.gray1};
  }
`;

export const MainColumn = styled(Col)`
  &&& {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
  }
`;

export const SubtitleText = styled(Text)`
  &&& {
    display: flex;
    align-items: center;
  }
`;

export const Title = styled(AntTitle)`
  &&& {
    color: ${colors.gray9};
    font-size: 30px;
    font-weight: 500;
    margin: 0;
  }
`;
