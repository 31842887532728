import { generateFilterDataStructure } from 'helpers/tableService';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updatelistOfClientsFilters } from 'store/ducks/filters/listOfClientsFilters/actions';
import { listOfClientsStart } from 'store/ducks/listOfClients/actions';
import { useResetFilters } from './useResetFilters';
import { onColumnSort } from 'helpers/sorter';

export const useBranchManagement = () => {
  const dispatch = useDispatch();
  const listOfClients = useSelector((state) => state.listOfClients.clients);
  const listOfClientsTotal = useSelector((state) => state.listOfClients.total);
  const listOfClientsLoading = useSelector(
    (state) => state.listOfClients.loading
  );
  const filtersData = useSelector(
    (state) => state.listOfClientsFilters.filters
  );
  const toParamsFilters = useSelector(
    (state) => state.listOfClientsFilters.toParamsFilters
  );
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [branchData, setBranchData] = useState({});
  const [visible, setVisible] = useState(false);
  const [pageTotal, setPageTotal] = useState();
  const [searchText, setSearchText] = useState('');
  const [sortFields, setSortFields] = useState({});

  const getListOfClients = () => {
    dispatch(
      listOfClientsStart({
        shouldReplace: true,
        filter: searchText,
        sortFields,
        page,
        perPage,
        q: toParamsFilters
          ? {
              filter: toParamsFilters,
            }
          : undefined,
      })
    );
  };

  const showDrawer = () => {
    setVisible(true);
  };
  const closeDrawer = () => {
    setVisible(false);
  };

  const onClick = () => {
    setBranchData({});
    showDrawer();
  };

  const filteredListOfClients = listOfClients.filter(
    (c) => c.name !== 'Não há mais resultados.'
  );

  const handleTableChange = (pagination, tableFilters, sorter) => {
    const filterDataToDispatch = generateFilterDataStructure(tableFilters);

    dispatch(updatelistOfClientsFilters(filterDataToDispatch));
    setPage(pagination?.current);
    setPerPage(pagination?.pageSize);
    setPageTotal(pagination?.total);
    onColumnSort(sorter, setSortFields);
  };

  useEffect(() => {
    setPageTotal(listOfClientsTotal);
  }, [listOfClientsTotal]);

  const { resetFilters } = useResetFilters();

  useEffect(() => {
    return () => {
      resetFilters(updatelistOfClientsFilters);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getListOfClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage, toParamsFilters]);

  return {
    getListOfClients,
    showDrawer,
    closeDrawer,
    onClick,
    filteredListOfClients,
    listOfClients,
    listOfClientsTotal,
    listOfClientsLoading,
    page,
    setPage,
    perPage,
    setPerPage,
    pageTotal,
    branchData,
    setBranchData,
    visible,
    setVisible,
    searchText,
    setSearchText,
    handleTableChange,
    toParamsFilters,
    filtersData,
  };
};
