import { put } from 'redux-saga/effects';

import errorHandler from 'helpers/errorHandler';

import {
  updateIsImplantedSuccess,
  updateIsImplantedError,
} from 'store/ducks/updateIsImplanted/actions';

export function* updateIsImplantedSaga(action) {
  try {
    const { payload } = action;
    yield put(updateIsImplantedSuccess(payload));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        updateIsImplantedError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(updateIsImplantedError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}
