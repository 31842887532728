export const actionTypes = {
  LIST_BANK_CONCILIATION_START:
    '@bankConciliation/LIST_BANK_CONCILIATION_START',
  LIST_BANK_CONCILIATION_SUCCESS:
    '@bankConciliation/LIST_BANK_CONCILIATION_SUCCESS',
  LIST_BANK_CONCILIATION_ERROR:
    '@bankConciliation/LIST_BANK_CONCILIATION_ERROR',
  EXPORT_BANK_CONCILIATION_START:
    '@bankConciliation/EXPORT_BANK_CONCILIATION_START',
  EXPORT_BANK_CONCILIATION_SUCCESS:
    '@bankConciliation/EXPORT_BANK_CONCILIATION_SUCCESS',
  EXPORT_BANK_CONCILIATION_ERROR:
    '@bankConciliation/EXPORT_BANK_CONCILIATION_ERROR',
  SET_TRANSACTIONS_SELECTED: '@bankConciliation/SET_TRANSACTIONS_SELECTED',
  SET_TRANSACTIONS_SELECTED_SUCCESS:
    '@bankConciliation/SET_TRANSACTIONS_SELECTED_SUCCESS',
  LIST_BANK_CONCILIATION_TRANSACTIONS_START:
    '@bankConciliation/LIST_BANK_CONCILIATION_TRANSACTIONS_START',
  LIST_BANK_CONCILIATION_TRANSACTIONS_SUCCESS:
    '@bankConciliation/LIST_BANK_CONCILIATION_TRANSACTIONS_SUCCESS',
  LIST_BANK_CONCILIATION_TRANSACTIONS_CLEAR:
    '@bankConciliation/LIST_BANK_CONCILIATION_TRANSACTIONS_CLEAR',
  LIST_BANK_CONCILIATION_TRANSACTIONS_CLEAR_DONE:
    '@bankConciliation/LIST_BANK_CONCILIATION_TRANSACTIONS_CLEAR_DONE',
  LIST_BANK_CONCILIATION_TRANSACTIONS_ERROR:
    '@bankConciliation/LIST_BANK_CONCILIATION_TRANSACTIONS_ERROR',
  UPDATE_BANK_CONCILIATION_TRANSACTIONS_START:
    '@bankConciliation/UPDATE_BANK_CONCILIATION_TRANSACTIONS_START',
  UPDATE_BANK_CONCILIATION_TRANSACTIONS_SUCCESS:
    '@bankConciliation/UPDATE_BANK_CONCILIATION_TRANSACTIONS_SUCCESS',
  UPDATE_BANK_CONCILIATION_TRANSACTIONS_ERROR:
    '@bankConciliation/UPDATE_BANK_CONCILIATION_TRANSACTIONS_ERROR',
  UPDATE_COUNTER_TRANSACTIONS_START:
    '@bankConciliation/UPDATE_COUNTER_TRANSACTIONS_START',
  UPDATE_COUNTER_TRANSACTIONS_SUCCESS:
    '@bankConciliation/UPDATE_COUNTER_TRANSACTIONS_SUCCESS',
  UPDATE_COUNTER_TRANSACTIONS_ERROR:
    '@bankConciliation/UPDATE_COUNTER_TRANSACTIONS_ERROR',
  UPDATE_TRANSACTION_DATE_START:
    '@bankConciliation/UPDATE_TRANSACTION_DATE_START',
  UPDATE_TRANSACTION_DATE_SUCCESS:
    '@bankConciliation/UPDATE_TRANSACTION_DATE_SUCCESS',
  UPDATE_TRANSACTION_DATE_ERROR:
    '@bankConciliation/UPDATE_TRANSACTION_DATE_ERROR',
  UPDATE_THROUGH_IMPORT_FILES_START:
    '@bankConciliation/UPDATE_THROUGH_IMPORT_FILES_START',
  UPDATE_THROUGH_IMPORT_FILES_SUCCESS:
    '@bankConciliation/UPDATE_THROUGH_IMPORT_FILES_SUCCESS',
  UPDATE_THROUGH_IMPORT_FILES_ERROR:
    '@bankConciliation/UPDATE_THROUGH_IMPORT_FILES_ERROR',
  RESET_HAS_NO_TRANSACTIONS_START:
    '@bankConciliation/RESET_HAS_NO_TRANSACTIONS_START',
  RESET_HAS_NO_TRANSACTIONS_SUCCESS:
    '@bankConciliation/RESET_HAS_NO_TRANSACTIONS_SUCCESS',
  RESET_HAS_NO_TRANSACTIONS_ERROR:
    '@bankConciliation/RESET_HAS_NO_TRANSACTIONS_ERROR',
  UPDATE_DIFFERENCE_VALUE_START:
    '@bankConciliation/UPDATE_DIFFERENCE_VALUE_START',
  UPDATE_DIFFERENCE_VALUE_SUCCESS:
    '@bankConciliation/UPDATE_DIFFERENCE_VALUE_SUCCESS',
  UPDATE_DIFFERENCE_VALUE_ERROR:
    '@bankConciliation/UPDATE_DIFFERENCE_VALUE_ERROR',
  UPDATE_ACQUIRER_NAME_START: '@bankConciliation/UPDATE_ACQUIRER_NAME_START',
  UPDATE_ACQUIRER_NAME_SUCCESS:
    '@bankConciliation/UPDATE_ACQUIRER_NAME_SUCCESS',
  UPDATE_ACQUIRER_NAME_ERROR: '@bankConciliation/UPDATE_ACQUIRER_NAME_ERROR',
  UPDATE_CURRENT_PAGE_START: '@bankConciliation/UPDATE_CURRENT_PAGE_START',
  UPDATE_CURRENT_PAGE_SUCCESS: '@bankConciliation/UPDATE_CURRENT_PAGE_SUCCESS',
  UPDATE_CURRENT_PAGE_ERROR: '@bankConciliation/UPDATE_CURRENT_PAGE_ERROR',
  UPDATE_AFTER_SAVE: '@bankConciliation/UPDATE_AFTER_SAVE',
  UPDATE_AFTER_SAVE_SUCCESS: '@bankConciliation/UPDATE_AFTER_SAVE_SUCCESS',
};
