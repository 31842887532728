import { actionTypes } from './types';

const INITIAL_STATE = {
  error: undefined,
  errorMessage: undefined,
  loading: true,
  success: false,
  isError: false,
  email: '',
  client_id: '',
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case actionTypes.REMOVE_USER_START:
    return {
      ...state,
      loading: true,
      success: false,
      error: undefined,
      errorMessage: undefined,
      isError: false,
    };
  case actionTypes.REMOVE_USER_SUCCESS:
    return {
      ...state,
      loading: false,
      success: true,
      error: undefined,
      email: action.payload.email,
      client_id: action.payload.client_id,
      isError: false,
      errorMessage: undefined,
    };
  case actionTypes.REMOVE_USER_ERROR:
    return {
      ...state,
      loading: false,
      success: false,
      error: action.error.error,
      errorMessage: action.payload,
      isError: true,
      summary: undefined,
    };
  default:
    return state;
  }
};

export default reducer;
