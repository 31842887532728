import { call, put } from 'redux-saga/effects';

import errorHandler from 'helpers/errorHandler';

import {
  creditPartnerOptoutSuccess,
  creditPartnerOptoutError,
} from 'store/ducks/credit/creditPartnerOptout/actions';

import postPartnerOptoutRequest from 'services/credit/partnerOptout';

export function* creditPartnerOptoutSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(postPartnerOptoutRequest, payload);
    yield put(creditPartnerOptoutSuccess(apiData));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        creditPartnerOptoutError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(creditPartnerOptoutError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}
