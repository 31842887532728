import { LockOutlined } from '@ant-design/icons';
import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';

export const StContainer = styled.div`
  position: relative;
  padding: 16px;
`;

export const StConcilBackground = styled.img`
  position: absolute;
  bottom: -60px;
  left: -76px;
  width: 170px;
  height: 100%;
  opacity: 15%;
  filter: grayscale(1);
  transform: scaleX(-1);
`;

export const StHeader = styled.div`
  display: flex;
  align-itens: center;
`;

export const StLockOutlined = styled(LockOutlined)`
  font-size: 24px;
  margin-right: 1.6rem;
  color: ${colors.gray9};
  z-index: 1;
`;

export const StTitle = styled.h1`
  margin: 0;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: ${colors.gray9};
  z-index: 1;
`;

export const StIllustrativeValues = styled.span`
  display: block;
  margin-top: 10px;
  margin-left: 36px;
  margin-bottom: 24px;
  font-size: 12px;
  font-style: italic;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: ${colors.gray10};
`;

export const StContent = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StColumnLeft = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  width: 100%;
  padding-right: 24px;
`;

export const StColumnLeftLabel = styled.span`
  display: block;
  margin-top: 16px;
  text-align: right;
`;

export const StColumnLeftValue = styled.span`
  display: block;
  text-align: right;
  font-size: 16px;
  font-weight: 500;
  color: ${({ color }) => color};
`;

export const StColumnRight = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
`;

export const StChart = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 10px;
`;

export const chart = css`
  width: 104px;
  &:hover {
    opacity: 0.8;
  }
`;

export const StBar = styled.div`
  ${chart};
  height: ${({ height }) => height}px;
  background-color: ${({ color }) => color};
  border-radius: 4px 4px 0px 0px;

  :hover {
    cursor: pointer;
  }
`;

export const StLeftChartLabel = styled.span`
  display: block;
  margin-bottom: 24px;
  font-size: 16px;
  text-align: right;
`;

export const StLeftChartValue = styled.span`
  display: block;
  text-align: right;
`;

export const StRightChartLabel = styled.span`
  display: block;
  margin-bottom: 24px;
  font-size: 16px;
  text-align: left;
`;

export const StRightChartValue = styled.span`
  display: block;
  text-align: left;
`;

export const StOverlay = styled.div`
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
  min-height: 100%;
  z-index: 0;
  opacity: 100%;
  background: linear-gradient(
    284deg,
    rgba(248, 248, 248, 0.48) -8.99%,
    rgba(255, 255, 255, 0.5) 61.47%
  );
`;
