import React, { useState } from 'react';
import { Col, Dropdown, Modal, Row } from 'antd';
import { CheckCircleTwoTone, DownOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { forgotPasswordRequest } from 'store/ducks/forgotPassword/actions';
import DeletionModal from './DeletionModal';
import { colors } from 'styles/colors';

const Actions = ({ selectedRows, showDefaultModal }) => {
  const dispatch = useDispatch();
  const [isResetModalVisible, setResetModalVisible] = useState(false);
  const [isRemoveModalVisible, setRemoveModalVisible] = useState(false);
  const {
    email,
    client_code: clientCode,
    client_name: clientName,
    user_id: userId,
  } = selectedRows[0];

  const showResetModal = () => {
    setResetModalVisible(true);
    dispatch(forgotPasswordRequest({ email: selectedRows[0]?.email }));
  };

  const showRemoveModal = () => {
    setRemoveModalVisible(true);
  };

  const items = [
    {
      label: 'Trocar Empresa Padrão',
      key: 'change-default-company',
      onClick: () =>
        showDefaultModal({ email, clientCode, clientName, userId }),
    },
    { label: 'Resetar senha', key: 'reset-password', onClick: showResetModal },
    {
      label: 'Excluir usuário',
      key: 'delete-user',
      danger: true,
      onClick: showRemoveModal,
    },
  ];

  return (
    <>
      <Dropdown menu={{ items }}>
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          Mais ações <DownOutlined />
        </a>
      </Dropdown>
      <Modal
        open={isResetModalVisible}
        style={{ maxWidth: '22%' }}
        onCancel={() => setResetModalVisible(false)}
        footer={null}
        maskClosable
      >
        <Row align="top">
          <Col span={2}>
            <CheckCircleTwoTone
              twoToneColor={colors.green6}
              style={{ marginTop: '6px', fontSize: '20px' }}
            />
          </Col>
          <Col span={22}>
            <p style={{ marginRight: '22px' }}>
              Mensagem de redefinição de senha encaminhada com sucesso ao
              usuário {selectedRows[0]?.email}
            </p>
          </Col>
        </Row>
      </Modal>
      <DeletionModal
        isRemoveModalVisible={isRemoveModalVisible}
        setRemoveModalVisible={setRemoveModalVisible}
        selectedRows={selectedRows}
      />
    </>
  );
};

Actions.propTypes = {};

export default Actions;
