import dayjs from 'dayjs';

/* **************** DIA **************** */

// Hoje
export const currentDay = dayjs();

// D-1 (ontem)
export const currentDayMinusOne = dayjs().subtract(1, 'day');

// D+1 (amanhã)
export const currentDayPlusOne = dayjs().add(1, 'day');

// 30 dias atrás
export const thirtyDaysAgo = dayjs().subtract(30, 'day');

// 45 dias atrás
export const fortyFiveDaysAgo = dayjs().subtract(45, 'day');

// 90 dias atrás
export const ninetyDaysAgoFromCurrentDay = dayjs().subtract(90, 'days');

/* **************** MÊS **************** */

// Começo do mês atual
export const startOfCurrentMonth = dayjs().startOf('month');

// Começo do mês anterior ao mês atual
export const startOfPreviousMonth = dayjs()
  .subtract(1, 'month')
  .startOf('month');

// Final do mês anterior ao mês atual
export const endOfPreviousMonth = dayjs().subtract(1, 'month').endOf('month');

/* **************** ANO *************** */

// 1 ano + 1 dia - ambos a partir da data atual
export const oneYearPlusOneDay = dayjs().add(1, 'year').add(1, 'day');

// Final do ano atual
export const endOfCurrentYear = dayjs().endOf('year');

// Começo do ano que vem
export const startOfNextYear = dayjs().add(1, 'year').startOf('year');

// Final do ano que vem
export const endOfNextYear = dayjs().add(1, 'year').endOf('year');

// Um ano a partir da data atual
export const oneYearAheadOfCurrentDay = dayjs().add(1, 'year');

// Um ano a partir de amanhã (D+1)
export const oneYearAheadOfCurrentDayPlusOne = dayjs()
  .add(1, 'day')
  .add(1, 'year');

// Um ano atrás
export const oneYearAgoFromCurrentDay = dayjs().subtract(1, 'year');

// Começo do ano anterior
export const startOfPreviousYear = dayjs().subtract(1, 'year').startOf('year');

// 18 meses atrás a partir da data atual
export const eighteenMonthsAgoFromCurrentDay = dayjs().subtract(18, 'month');

// 18 meses a partir de amanhã
export const eighteenMonthsAheadOfCurrentDayPlusOne = dayjs()
  .add(1, 'day')
  .add(18, 'month');
