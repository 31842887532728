import { getPlanNameById } from 'helpers/getPlanNameById';
import { getProfilesNameById } from 'helpers/getProfileNameById';
import { getScopesNameById } from 'helpers/getScopeNameById';
import { getSkinsNameById } from 'helpers/getSkinNameById';
import {
  getSessionClientCode,
  getSessionClientCreatedAt,
  getSessionClientId,
  getSessionClientName,
  getSessionClientPlanId,
  getSessionClientSkinId,
  getSessionFirstName,
  getSessionIsImplanted,
  getSessionProfileId,
  getSessionScopeId,
  getSessionUsername,
} from 'helpers/sessionService';
import { getUserGuidingAcquirers } from 'services/acquirer';

export const userGuidingIntegrationData = async (localUsernameIdState) => {
  const isUserGuidingEnabled =
    process.env.REACT_APP_USERGUIDING_ENABLED === 'true';
  if (localUsernameIdState === '') return;
  if (!isUserGuidingEnabled) return;

  const clientId = getSessionClientId();
  const clientCode = getSessionClientCode();
  const userEmail = getSessionUsername();
  const userFirstName = getSessionFirstName();
  const userScopeId = getSessionScopeId();
  const scopeName = getScopesNameById(userScopeId);
  const userProfileId = getSessionProfileId();
  const profileName = getProfilesNameById(userProfileId);

  const clientCreatedAt = getSessionClientCreatedAt();
  const clientName = getSessionClientName();
  const isImplanted = getSessionIsImplanted();
  const clientSkinId = getSessionClientSkinId();
  const skinName = getSkinsNameById(clientSkinId);
  const clientPlanId = getSessionClientPlanId();
  const planName = getPlanNameById(clientPlanId);

  const EnumEnvironment = {
    development: 'Desenvolvimento',
    staging: 'Homologação',
    production: 'Produção',
  };

  const getEnvironment = () => EnumEnvironment[process.env.REACT_APP_BUILD_ENV];

  try {
    const { acquirers } = await getUserGuidingAcquirers();

    if (!acquirers) return;

    const companyData = acquirers.reduce(
      (acc, curr) => {
        acc[curr.name] = curr.checked ? 'Sim' : '-';
        return acc;
      },
      {
        id: clientId,
        clientCode: clientCode,
        name: clientName,
        planId: clientPlanId,
        planName: planName,
        createdAt: clientCreatedAt,
        isImplanted: isImplanted ? 'Sim' : 'Não',
        skinId: clientSkinId,
        skinName: skinName,
        environment: getEnvironment(),
      }
    );

    if (!companyData) return;

    const userGuidingData = {
      email: userEmail,
      name: userFirstName,
      profileId: userProfileId,
      profileName: profileName,
      scopeId: userScopeId,
      scopeName: scopeName,
      environment: getEnvironment(),
      lastCompany: `${clientCode} ${clientName}`,
      company: companyData,
    };

    if (window?.userGuiding) {
      if (window?.userGuiding?.identify) {
        if (localUsernameIdState !== '') {
          // eslint-disable-next-line no-console
          console.log('user guiding data has been sent');
          window.userGuiding.identify(localUsernameIdState, {
            ...userGuidingData,
          });
        }
      }
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};
