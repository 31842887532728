import React from 'react';
import { ReconciliationCard } from '..';
import { updateScreenToScreenPaymentsReconciliationPeriodStart } from 'store/ducks/updateScreenToScreenPeriod/actions';
import { paymentsSummaryReconciledStart } from 'store/ducks/reconciled/summary/payments/actions';
import { parsedDropdownKeys, reconIconUrl } from '../utils/parser';

const PaymentsReconciliationCard = ({ dropdownProps, ...rest }) => {
  const reconProps = {
    reconUrl: '/conciliacao-pagamentos',
    reconPeriodStore: 'paymentsReconciliationPeriod',
    reconPeriodAction: updateScreenToScreenPaymentsReconciliationPeriodStart,
    reconSummaryAction: paymentsSummaryReconciledStart,
    reconSummaryStore: 'paymentsSummaryReconciled',
    reconTitle: 'Conciliação de pagamentos',
    reconIcon: reconIconUrl,
    reconLeftColDescription: 'Previsto',
    reconRightColDescription: 'Realizado',
    dropdownKey: parsedDropdownKeys,
  };

  return (
    <ReconciliationCard
      height={230}
      reconciliationType="payments"
      isFlipped={false}
      setIsFlipped={() => {}}
      dropdownProps={dropdownProps}
      reconProps={reconProps}
      context={{
        left: 'forecast',
        right: 'confirmation',
      }}
      {...rest}
    />
  );
};

export default PaymentsReconciliationCard;
