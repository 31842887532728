import { call, put } from 'redux-saga/effects';

import errorHandler from 'helpers/errorHandler';

import {
  creditInteractPreregisterSuccess,
  creditInteractPreregisterError,
} from 'store/ducks/credit/creditInteractPreregister/actions';

import postInteractPreregisterRequest from 'services/credit/interactPreregister';

export function* creditInteractPreregisterSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(postInteractPreregisterRequest, payload);
    yield put(creditInteractPreregisterSuccess(apiData));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        creditInteractPreregisterError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(creditInteractPreregisterError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}
