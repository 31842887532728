import { Button, Input, Tabs } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import styled from 'styled-components';
import { colors } from 'styles/colors';

export const StImagePreview = styled.img`
  width: 110px;
  height: 160px;
  border: 1px solid #aaa;
`;

export const StDownloadLetterButton = styled(Button)`
  &&& {
    width: 131px;
    height: 32px;
    background: ${colors.gray1};
    border: 1px solid ${colors.gray5};
    box-sizing: border-box;
    border-radius: 2px;
    color: ${colors.gray9};
    margin-top: 30px;
    position: relative;
    right: 15px;
    &:hover {
      border-color: ${colors.primary6};
    }
  }
`;

export const StDragger = styled(Dragger)`
  &&& {
    width: 187px;
    height: 126px;
  }
`;

export const StTabs = styled(Tabs)`
  &&& .ant-tabs-nav .ant-tabs-tab {
    border-bottom-color: ${colors.primary6};
  }
  &&& .ant-tabs-nav::before {
    border-color: ${colors.primary6};
  }
  &&& .ant-tabs-nav {
    margin-bottom: 0;
    box-shadow: none !important;
  }
  &&& .ant-tabs-tab-active {
    border-color: ${colors.primary6};
    border-bottom: transparent !important;
  }
  &&& .ant-tabs-content-holder {
    padding: 15px;
    border: 1px solid ${colors.primary6};
    border-top: transparent !important;
  }
`;

export const StCustomInput = styled(Input)`
  &&& {
    background-color: unset;
    color: unset;

    :hover {
      border-color: ${colors.primary5};
      border-right-width: 1px !important;
    }
  }
`;
