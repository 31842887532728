import { api } from './api';

const transformer = (apiResponse) => {
  return {
    plans: apiResponse.data.plans,
  };
};

const getListOfPlansFromAPI = async () => {
  const response = await api().get('/plans');
  return transformer(response.data);
};

export default getListOfPlansFromAPI;
