import { action } from 'typesafe-actions';
import { actionTypes } from 'store/ducks/details/feesDetails/types';

export const feesDetailsStart = (data) =>
  action(actionTypes.FEES_DETAILS_START, data);
export const feesDetailsSuccess = (data) =>
  action(actionTypes.FEES_DETAILS_SUCCESS, data);
export const feesDetailsError = (errorMessage, error) =>
  action(actionTypes.FEES_DETAILS_ERROR, { errorMessage }, undefined, {
    error,
  });
