import { api } from '../api';

const transformer = (apiResponse) => {
  const data = {
    details: apiResponse.data.payments,
    meta: apiResponse.meta,
    netAmount: apiResponse.data.total_net_amount,
  };
  return data;
};

const getAdjustmentDetailsFromAPI = async ({
  startDate,
  endDate,
  sortFields,
  page,
  perPage,
  q,
}) => {
  const config = {
    params: {
      start_date: startDate.format('YYYY-MM-DD'),
      end_date: endDate.format('YYYY-MM-DD'),
      page,
      per_page: perPage,
      sort_fields: JSON.stringify(sortFields),
      q: JSON.stringify(q),
    },
  };
  const response = await api().get('/detail/payments/adjustments', config);
  return transformer(response.data);
};

export default getAdjustmentDetailsFromAPI;
