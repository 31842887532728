import { action } from 'typesafe-actions';
import { actionTypes } from 'store/ducks/details/reconciledSalesDetails/types';

export const reconciledSalesDetailsStart = (data) =>
  action(actionTypes.RECONCILED_SALES_DETAILS_START, data);
export const reconciledSalesDetailsSuccess = (data) =>
  action(actionTypes.RECONCILED_SALES_DETAILS_SUCCESS, data);
export const reconciledSalesDetailsError = (errorMessage, error) =>
  action(
    actionTypes.RECONCILED_SALES_DETAILS_ERROR,
    { errorMessage },
    undefined,
    { error }
  );
