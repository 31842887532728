import { lambdaApi } from '../api';

const transformer = (apiResponse) => {
  const data = {
    stores: apiResponse.data.stores,
    meta: apiResponse.meta,
  };
  return data;
};
const getListStoresFromAPI = async ({ storeId, page, perPage }) => {
  const config = {
    params: {
      store_id: storeId,
      page,
      per_page: perPage,
    },
  };
  const response = await lambdaApi().get(
    '/settings/maintenance/stores',
    config
  );
  return transformer(response.data);
};
export default getListStoresFromAPI;
