import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { Col, Form, Input, Row, Typography } from 'antd';
import ptBR from 'antd/es/date-picker/locale/pt_BR';
import MaskedInput from 'antd-mask-input';
import LabelAndInputs from '../../../../commons/LabelAndInputs/LabelAndInputs';
import { HeaderRegisters } from '../../../../commons/HeaderRegisters';
import { useDispatch, useSelector } from 'react-redux';
import { Loadable } from '../../../../commons/Loadable';
import {
  getClientStart,
  putClientStart,
} from '../../../../store/ducks/client/actions';
import { cpf } from 'helpers/cpf';
import errorHandler from 'helpers/errorHandler';

const BriefingPersonalData = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const loadingClient = useSelector((state) => state.client.loading);
  const clientData = useSelector((state) => state.client.client);
  const [form] = Form.useForm();
  let client = {};
  let callAfterValidate;
  const { isLocked } = props;

  useImperativeHandle(ref, () => ({
    validateBeforeNextPage(afterValidate) {
      callAfterValidate = afterValidate;
      form.submit();
    },
  }));

  useEffect(() => {
    dispatch(getClientStart({}));
    props.setIsNextButtonLocked(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (clientData) {
    client = clientData?.client;
    form.setFieldsValue({
      contact_name: client?.contact_name,
      contact_rg: client?.contact_rg,
      contact_cpf: client?.contact_cpf,
      contact_email: client?.contact_email,
      contact_phone: client?.contact_phone,
    });
  }

  const formHasChanges = (formValues) => {
    return (
      client &&
      (formValues.contact_name !== client.contact_name ||
        formValues.contact_rg !== client.contact_rg ||
        formValues.contact_cpf !== client.contact_cpf ||
        formValues.contact_email !== client.contact_email ||
        formValues.contact_phone !== client.contact_phone)
    );
  };

  const onFinish = async (formValues) => {
    if (formValues.contact_cpf !== '' && formValues.contact_rg !== '') {
      if (formHasChanges(formValues)) {
        if (cpf.isValid(formValues.contact_cpf)) {
          const data = {
            client_id: client.id ?? client.client_id,
            ...client,
            ...formValues,
          };
          await dispatch(putClientStart(data));
          callAfterValidate(true);
        } else {
          errorHandler('CPF inválido');
        }
      } else {
        callAfterValidate(false);
      }
    } else {
      const data = {
        client_id: client.id ?? client.client_id,
        ...client,
        ...formValues,
      };
      await dispatch(putClientStart(data));
      callAfterValidate(false);
    }
  };

  const onChangeCpf = (e) => {
    if (e.target.value.length > 0) {
      const num = e.target.value.replace(/\D/g, '');
      if (num.length === 11) {
        if (!cpf.isValid(e.target.value)) {
          errorHandler('CPF inválido');
        }
      }
    }
  };

  return (
    <HeaderRegisters
      title="Dados pessoais"
      customSubTitle={
        <Typography.Text style={{ fontSize: 14 }}>
          Os dados abaixo serão usados com a única finalidade de solicitar os
          arquivos de vendas e recebimentos das adquirentes.
          <br />
          Para saber mais sobre o tratamento de dados, acesse nossa{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.concil.com.br/politicadeprivacidade/"
          >
            política de privacidade
          </a>
          .
        </Typography.Text>
      }
    >
      <Loadable loading={loadingClient}>
        <Form form={form} onFinish={onFinish}>
          <fieldset disabled={isLocked}>
            <Row gutter={[8, 8]}>
              <Col lg={10}>
                <LabelAndInputs label="Nome completo">
                  <Form.Item
                    name="contact_name"
                    rules={[{ required: true, message: 'Campo obrigatório!' }]}
                  >
                    <Input
                      placeholder="Insira seu nome completo"
                      value={client?.contact_name}
                      name="contact_name"
                      locale={ptBR}
                      style={{ width: '100%' }}
                      disabled={isLocked}
                    />
                  </Form.Item>
                </LabelAndInputs>
              </Col>
              <Col lg={4}>
                <LabelAndInputs label="RG">
                  <Form.Item
                    name="contact_rg"
                    rules={[{ required: false, message: 'Campo obrigatório!' }]}
                  >
                    <MaskedInput
                      value={client?.contact_rg}
                      name="contact_rg"
                      placeholder="00.000.000-0"
                      disabled={isLocked}
                      maskOptions={{
                        mask: '**.***.***-**',
                        lazy: true,
                      }}
                    />
                  </Form.Item>
                </LabelAndInputs>
              </Col>
              <Col lg={4}>
                <LabelAndInputs label="CPF">
                  <Form.Item
                    name="contact_cpf"
                    rules={[{ required: false, message: 'Campo obrigatório!' }]}
                  >
                    <MaskedInput
                      value={client?.contact_cpf}
                      name="contact_cpf"
                      placeholder="000.000.000-00"
                      onChange={onChangeCpf}
                      disabled={isLocked}
                      maskOptions={{
                        mask: '000.000.000-00',
                        lazy: true,
                      }}
                    />
                  </Form.Item>
                </LabelAndInputs>
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col lg={10}>
                <LabelAndInputs label="E-mail">
                  <Form.Item
                    name="contact_email"
                    rules={[{ required: true, message: 'Campo obrigatório!' }]}
                  >
                    <Input
                      value={client?.contact_email}
                      placeholder="Insira seu e-mail"
                      name="contact_email"
                      locale={ptBR}
                      style={{ width: '100%' }}
                      disabled={isLocked}
                    />
                  </Form.Item>
                </LabelAndInputs>
              </Col>
              <Col lg={4}>
                <LabelAndInputs label="Telefone para contato">
                  <Form.Item
                    name="contact_phone"
                    rules={[{ required: false, message: 'Campo obrigatório!' }]}
                  >
                    <MaskedInput
                      value={client?.contact_phone}
                      name="contact_phone"
                      disabled={isLocked}
                      maskOptions={{
                        mask: '(00) 00000-0000',
                        lazy: true,
                      }}
                    />
                  </Form.Item>
                </LabelAndInputs>
              </Col>
            </Row>
          </fieldset>
        </Form>
      </Loadable>
    </HeaderRegisters>
  );
});

export default BriefingPersonalData;
