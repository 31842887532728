export const exportFileParser = (params, additionalColumnsName = []) => {
  const baseColumnsName = [
    'acquirer_name',
    'store_name',
    'product_code',
    'brand_code',
    'sale_date',
    'transaction_date',
    'payment_date',
    'forecast_date',
  ];
  const columnsName = [...baseColumnsName, ...additionalColumnsName];

  return params.filter(({ name }) => columnsName.includes(name));
};
