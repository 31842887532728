import styled from 'styled-components';
import { colors } from 'styles/colors';

export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin: 8px 8px 0 0;
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  // Hide checkbox visually but remain accessible to screen readers.
  // Source: https://polished.js.org/docs/#hidevisually
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const Icon = styled.svg`
  fill: none;
  stroke: ${colors.gray1};
  stroke-width: 3px;
  margin-bottom: 1px;
`;

const getStyledCheckboxBackgroundColor = (props) => {
  const { checked, disabled } = props;

  if (checked) return disabled ? colors.gray7 : colors.primary6;

  return colors.gray1;
};

export const StyledCheckbox = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${(props) => getStyledCheckboxBackgroundColor(props)};
  border-radius: 50%;
  border: 1px solid #aaa;
  transition: all 150ms;
  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px pink;
  }
  ${Icon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
`;

export const CnpjMaintenanceStyledCheckbox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  background: ${(props) =>
    props.checked && props.isEmpty
      ? colors.primary6
      : props.checked && !props.isEmpty
        ? '#ccc'
        : colors.gray1};
  border-radius: 2px;
  border: 1px solid
    ${(props) =>
      props.disabled
        ? '#eee'
        : props.checked && props.isEmpty
          ? colors.primary6
          : '#ccc'};
  transition: all 150ms;
  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px pink;
  }
  ${Icon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
`;
