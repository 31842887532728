/* eslint-disable no-console */
import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Grid, Steps, Typography, Button, Modal } from 'antd';
import { BriefingPersonalData } from './Components/BriefingPersonalData';
import { BriefingStoresRegister } from './Components/BriefingStoresRegister';
import { BriefingAcquirersPointOfSale } from './Components/BriefingAcquirersPointOfSale';
import { BriefingMerchantsRegister } from './Components/BriefingMerchantsRegister';
import { BriefingAcquirersVoucher } from './Components/BriefingAcquirersVoucher';
import { BriefingLettersAndOptins } from './Components/BriefingLettersAndOptins';
import { usePlansFeaturesPermissions } from 'utils/hooks/usePlansFeaturesPermissions';
import { BriefingReview } from './Components/BriefingReview';
import {
  StBriefingTitle,
  StBriefingTitleWrapper,
  StContainer,
  StLockWrapper,
} from './styled';
import { useDispatch, useSelector } from 'react-redux';
import {
  getBriefingStart,
  postBriefingStart,
  putBriefingStart,
} from '../../store/ducks/briefing/actions';
import { Loadable } from '../../commons/Loadable';
import { closeBriefing, openBriefing } from '../../services/briefing';
import {
  CheckCircleFilled,
  LockOutlined,
  UnlockOutlined,
} from '@ant-design/icons';
import customHistory from '../../helpers/history';
import {
  isAdmin,
  isConciler,
  isSuperAdmin,
} from 'helpers/authorizationService';
import { updateBriefingStepStart } from 'store/ducks/updateBriefingStep/actions';
import { fileIdentificationStart } from 'store/ducks/client/actions';
import {
  getSessionClientCode,
  getSessionClientId,
  getSessionClientName,
} from 'helpers/sessionService';
import { colors } from 'styles/colors';
import dayjs from 'dayjs';

let briefing;

const Briefing = () => {
  const dispatch = useDispatch();
  const { isFeatureAvailable } = usePlansFeaturesPermissions();
  const briefingData = useSelector((state) => state.briefing.briefing);
  const loadingBriefing = useSelector((state) => state.briefing.loading);
  const loadingActionsBriefing = useSelector(
    (state) => state.briefing.loadingActions
  );
  const loadingClient = useSelector((state) => state.client.loading);
  const loadingStores = useSelector((state) => state.store.loading);
  const loadingAcquirers = useSelector((state) => state.acquirer.loading);
  const loadingMerchants = useSelector((state) => state.merchant.loading);
  const { useBreakpoint } = Grid;
  const breakpoint = useBreakpoint();
  const store = getSessionClientName();
  const clientCode = getSessionClientCode();
  const currentPage = useSelector((state) => state.updateBriefingStep.step);
  const [briefingStatus, setBriefingStatus] = useState('');
  const [acquirers, setAcquirers] = useState([]);
  const [isNextButtonLocked, setIsNextButtonLocked] = useState(false);
  let savedDate;
  let completedDate;
  const childRef = useRef();

  const BriefingPage = {
    PersonalData: 0,
    StoresRegister: 1,
    AcquirersPointOfSale: 2,
    AcquirersVouchers: 3,
    MerchantsRegister: 4,
    LettersAndOptins: 5,
    Review: 6,
  };

  const goToNextPage = () => {
    dispatch(updateBriefingStepStart(currentPage + 1));
  };
  const goToPreviousPage = () => {
    dispatch(updateBriefingStepStart(currentPage - 1));
  };

  const loadBriefing = () => {
    dispatch(getBriefingStart({}));
  };

  useEffect(() => {
    if (!loadingActionsBriefing) {
      loadBriefing();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, loadingActionsBriefing]);

  if (briefingData) {
    briefing = briefingData?.briefing;
    // savedDate = briefing?.update_datetime ?? briefing?.create_datetime;
    savedDate = briefing?.update_datetime;
    completedDate = briefing?.completed_datetime;
  }

  useEffect(() => {
    setBriefingStatus(briefingData?.briefing?.status);
  }, [briefingData]);

  const afterValidateNextPage = async () => {
    const data = {
      briefing_id: briefing?.briefing_id,
      origin: 'REGISTRO',
      client_id: getSessionClientId(),
    };
    if (briefing && briefing?.briefing_id) {
      dispatch(putBriefingStart(data));
    } else {
      dispatch(postBriefingStart(data));
    }

    goToNextPage();
  };

  const onFinishBriefing = async () => {
    const data = { briefing_id: briefing.briefing_id, origin: 'REGISTRO' };

    dispatch(
      fileIdentificationStart({
        client_id: Number(getSessionClientId()),
      })
    );

    closeBriefing(data).then(() => {
      if (!isFeatureAvailable({ key: 'briefing-fees-redirect' })) {
        customHistory.push('/dashboard');
        return;
      }

      Modal.confirm({
        title: 'Registro de implantação finalizado!',
        content: (
          <div>
            Agora vamos solicitar os arquivos para as adquirentes e em breve
            você poderá iniciar a gestão da sua empresa no Concil Card.
            <br />
            <br />
            Seu próximo passo é cadastrar suas taxas, para que a gente possa
            conciliar todas suas vendas e recebimentos. Você ainda poderá fazer
            isso em outro momento, se preferir.
          </div>
        ),
        icon: <CheckCircleFilled style={{ color: 'green' }} />,
        maskClosable: false,
        keyboard: false,
        okText: 'Cadastrar taxas',
        onOk: () => {
          customHistory.push('/taxas');
        },
        cancelText: 'Fechar',
        onCancel: (close) => {
          customHistory.push('/dashboard');
          close();
        },
      });
    });
  };

  // eslint-disable-next-line func-names
  const renderCurrentPage = function () {
    const isLocked = briefing && briefing.status !== 'OPENED';
    switch (currentPage) {
      case BriefingPage.PersonalData:
        return (
          <BriefingPersonalData
            ref={childRef}
            isLocked={isLocked}
            setIsNextButtonLocked={setIsNextButtonLocked}
          />
        );
      case BriefingPage.StoresRegister:
        return (
          <BriefingStoresRegister
            ref={childRef}
            isLocked={isLocked}
            setIsNextButtonLocked={setIsNextButtonLocked}
            afterValidateNextPage={afterValidateNextPage}
          />
        );
      case BriefingPage.AcquirersPointOfSale:
        return (
          <BriefingAcquirersPointOfSale
            ref={childRef}
            isLocked={isLocked}
            acquirers={acquirers}
            setAcquirers={setAcquirers}
            setIsNextButtonLocked={setIsNextButtonLocked}
          />
        );
      case BriefingPage.AcquirersVouchers:
        return (
          <BriefingAcquirersVoucher
            ref={childRef}
            isLocked={isLocked}
            acquirers={acquirers}
            setAcquirers={setAcquirers}
            setIsNextButtonLocked={setIsNextButtonLocked}
          />
        );
      case BriefingPage.MerchantsRegister:
        return (
          <BriefingMerchantsRegister
            ref={childRef}
            isLocked={isLocked}
            setIsNextButtonLocked={setIsNextButtonLocked}
          />
        );
      case BriefingPage.LettersAndOptins:
        return (
          <BriefingLettersAndOptins
            ref={childRef}
            isLocked={isLocked}
            setIsNextButtonLocked={setIsNextButtonLocked}
          />
        );
      case BriefingPage.Review:
        return <BriefingReview ref={childRef} />;
      default:
        return <Loadable loading />;
    }
  };

  const renderNextButton = () => {
    const onClick = () => {
      if (
        childRef &&
        childRef.current &&
        childRef.current.validateBeforeNextPage !== undefined
      ) {
        childRef.current.validateBeforeNextPage(afterValidateNextPage);
      } else {
        goToNextPage();
      }
    };

    if (currentPage !== BriefingPage.Review) {
      return (
        <Button
          disabled={
            loadingBriefing ||
            loadingClient ||
            loadingStores ||
            loadingAcquirers ||
            loadingMerchants ||
            isNextButtonLocked
          }
          onClick={() => onClick()}
          type="primary"
          style={{ marginTop: '10px', float: 'right', marginRight: '20px' }}
        >
          Próximo
        </Button>
      );
    }
    return '';
  };

  const renderFinishButton = () => {
    if (currentPage === BriefingPage.Review && briefing?.status === 'OPENED') {
      return (
        <Button
          disabled={
            loadingBriefing ||
            loadingClient ||
            loadingStores ||
            loadingAcquirers ||
            loadingMerchants
          }
          onClick={onFinishBriefing}
          type="primary"
          style={{ marginTop: '10px', float: 'right', marginRight: '20px' }}
        >
          Finalizar registro
        </Button>
      );
    }
    return '';
  };

  const renderBackButton = () => {
    if (currentPage !== BriefingPage.PersonalData) {
      return (
        <Button
          onClick={() => goToPreviousPage()}
          style={{ marginTop: '10px', float: 'right', marginRight: '20px' }}
        >
          Voltar
        </Button>
      );
    }
    return '';
  };

  // eslint-disable-next-line func-names
  const getStepStatus = function (stepIndex) {
    // 'wait' | 'process' | 'finish' | 'error'
    if (
      briefing?.status === 'CLOSED' ||
      briefing?.status === 'COMPLETED' ||
      stepIndex < currentPage
    ) {
      return 'finish';
    }
    if (stepIndex === currentPage) {
      return 'process';
    }

    return 'wait';
  };

  const items = [
    {
      title: 'Dados pessoais',
      status: getStepStatus(BriefingPage.PersonalData),
    },
    {
      title: 'Cadastro de CNPJs',
      status: getStepStatus(BriefingPage.StoresRegister),
    },
    {
      title: 'Selecionar máquinas',
      status: getStepStatus(BriefingPage.AcquirersPointOfSale),
    },
    {
      title: 'Selecionar benefícios',
      status: getStepStatus(BriefingPage.AcquirersVouchers),
    },
    {
      title: 'Cadastrar estabelecimentos',
      status: getStepStatus(BriefingPage.MerchantsRegister),
    },
    {
      title: 'Cartas de autorização',
      status: getStepStatus(BriefingPage.LettersAndOptins),
    },
    {
      title: 'Revisão',
      status: getStepStatus(BriefingPage.Review),
    },
  ];

  return (
    <div>
      <StBriefingTitleWrapper $breakpoint={breakpoint}>
        <div>
          <Row
            align="middle"
            justify="space-between"
            style={{ marginTop: '4rem' }}
          >
            <Col span={24}>
              <StBriefingTitle>
                <span style={{ marginRight: '20px' }}>
                  Registro de Implantação
                </span>
                {isConciler() && (isSuperAdmin() || isAdmin()) ? (
                  <StLockWrapper>
                    {briefingStatus === 'CLOSED' ||
                    briefingStatus === 'COMPLETED' ? (
                      <LockOutlined
                        style={{ fontSize: '75%' }}
                        onClick={() => {
                          const data = {
                            briefing_id: briefing.briefing_id,
                            origin: 'REGISTRO',
                          };
                          openBriefing(data).then(() => {
                            window.location.reload();
                          });
                        }}
                      />
                    ) : (
                      <UnlockOutlined
                        style={{ fontSize: '75%' }}
                        onClick={() => {
                          const data = {
                            briefing_id: briefing.briefing_id,
                            origin: 'REGISTRO',
                          };
                          closeBriefing(data).then(() => {
                            window.location.reload();
                          });
                        }}
                      />
                    )}
                  </StLockWrapper>
                ) : (
                  <></>
                )}
              </StBriefingTitle>
            </Col>
          </Row>
          <Col>{`${clientCode} ${store}`}</Col>
          <br />
        </div>
      </StBriefingTitleWrapper>

      <StContainer breakpoint={breakpoint}>
        <Row>
          <Col span={4} style={{ height: '80rem' }}>
            <div
              style={{
                backgroundColor: '#e3f0f3',
                padding: '1em',
                height: '100%',
              }}
            >
              <Steps
                direction="vertical"
                items={items}
                style={{ height: '100%', fontWeight: 'bold' }}
              />
            </div>
          </Col>
          <Col span={20} style={{ maxHeight: '630px' }}>
            <div style={{ backgroundColor: 'fff', padding: '1em' }}>
              <div style={{ backgroundColor: 'fff', padding: '1em' }}>
                <div
                  style={{
                    marginBottom: '50px',
                    paddingBottom: '50px',
                  }}
                >
                  {renderCurrentPage()}
                </div>
                <div
                  style={{
                    position: 'absolute',
                    bottom: '0',
                    height: '50px',
                    borderTop: '1px solid #ddd',
                    width: '98%',
                    zIndex: 100,
                  }}
                >
                  <Row>
                    <Col lg={24} style={{ backgroundColor: colors.gray1 }}>
                      {renderFinishButton()}
                      {renderNextButton()}
                      {renderBackButton()}
                      <>
                        {savedDate && (
                          <Typography.Text
                            style={{
                              float: 'right',
                              marginRight: '20px',
                              marginTop: '15px',
                              color: '#ccc',
                            }}
                          >
                            Salvo automaticamente em{' '}
                            {dayjs(savedDate).format('DD/MM/YYYY [às] HH:mm')}
                          </Typography.Text>
                        )}
                        {completedDate && (
                          <Typography.Text
                            style={{
                              float: 'right',
                              marginRight: '20px',
                              marginTop: '15px',
                              color: '#ccc',
                            }}
                          >
                            Registro fechado em{' '}
                            {dayjs(completedDate).format(
                              'DD/MM/YYYY [às] HH:mm'
                            )}
                          </Typography.Text>
                        )}
                      </>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </StContainer>
    </div>
  );
};

export default Briefing;
