import { Button, List, Input, Divider, Spin, Typography } from 'antd';
import styled from 'styled-components';
import { colors } from 'styles/colors';

export const Title = styled(Typography.Text)`
  font-size: 20px;
  color: ${colors.gray9};
  padding: 12px 0;
`;

export const StSearch = styled(Input.Search)`
  width: 100%;
  margin-top: 2rem;
`;

export const StListItem = styled(List.Item)`
  width: 100%;
  padding: 4px;
  background-color: ${colors.primary1};
  cursor: pointer;
`;

export const StListItemButton = styled(Button)`
  display: flex !important;
  width: 100%;
  height: 100%;
  padding: 1rem 15px;
  text-align: start;
  font-size: 1.5rem;
  background-color: ${colors.primary1};
  color: ${colors.gray11} !important;
  cursor: default;

  :hover {
    background-color: ${colors.primary1};
  }
`;

export const ItemButton = styled.span`
  overflow: hidden;
`;

export const ItemsContainer = styled.div`
  max-height: 250px;
  overflow-y: auto;
  margin-bottom: 20px;
`;

export const ItemsList = styled.div`
  padding: 1rem 15px;
  font-size: 12px;
  cursor: pointer;

  :hover {
    color: ${colors.gray8} !important;
    background-color: ${colors.primary1};
  }
`;

export const StDividerItems = styled(Divider)`
  margin: 0;
`;

export const StSpin = styled(Spin)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
