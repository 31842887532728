import { useCallback, useEffect, useState } from 'react';
import { useDebounce } from 'utils/hooks/useDebounce';
import getCnpjMaintenanceMerchantInfoFilters from 'services/cnpjMaintenance/filters/getCnpjMaintenanceMerchantInfoFilters';
import errorHandler from 'helpers/errorHandler';

export const useMerchantFilter = (searchFilterInput, offsetMerchant, limit) => {
  const [isError, setIsError] = useState(false);
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [merchantFilterOptions, setMerchantFilterOptions] = useState([]);
  const [merchantFilterOptionsData, setMerchantFilterOptionsData] = useState(
    []
  );
  const [merchantFilterMeta, setMerchantFilterMeta] = useState({});
  const queryWithDebounce = useDebounce(searchFilterInput, 500);

  const transformSearchFilter = (search) => {
    const query = {
      filter: [
        {
          name: 'merchant_code',
          val: search || '',
        },
      ],
    };

    return query;
  };

  const fetchMerchantFilter = useCallback(
    (offsetNumber) => {
      setIsLoadingSearch(true);
      setIsError(false);
      return getCnpjMaintenanceMerchantInfoFilters({
        filter: transformSearchFilter(queryWithDebounce),
        offset: offsetNumber === 0 ? 0 : offsetMerchant,
        limit,
      })
        .then(({ merchants: data = [], meta = {} }) => {
          setMerchantFilterOptionsData(data);
          setMerchantFilterMeta(meta);
        })
        .catch((err) => {
          if (err) {
            errorHandler('Ocorreu um erro:', err);
          }
          setIsError(true);
          setIsLoadingSearch(false);
        })
        .finally(() => setIsLoadingSearch(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryWithDebounce, offsetMerchant]
  );

  const refreshMerchantsFilters = () => {
    setMerchantFilterOptions([]);
    fetchMerchantFilter(0);
  };

  useEffect(() => {
    setMerchantFilterOptions([]);
    fetchMerchantFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryWithDebounce]);

  useEffect(() => {
    if (offsetMerchant > 1) {
      fetchMerchantFilter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offsetMerchant]);

  useEffect(() => {
    setMerchantFilterOptions((prev) => {
      return [...new Set([...prev, ...merchantFilterOptionsData])];
    });
  }, [merchantFilterOptionsData]);

  return {
    isLoadingSearch,
    isError,
    merchantFilterOptions,
    merchantFilterMeta,
    refreshMerchantsFilters,
  };
};
