import React from 'react';
import store from 'store';
import { Spin, Tooltip } from 'antd';
import { StLoadingOutlined } from './styled';
import { columnFilters, onColumnFilter } from 'helpers/generalService';
import { clearFiltersFunctions } from 'helpers/clearFiltersFunctions';
import { getDefaultColumnWidth } from 'helpers/tableService';
import { CustomFilterDropdown } from 'commons/Table/components/CustomFilterDropdown';
import { feesUpdateFilteredColumnsStart } from 'store/ducks/feesUpdateFilteredColumns/actions';
import {
  feesDetailsFiltersStart,
  feesDetailsFiltersZeroLength,
} from 'store/ducks/filters/fees/actions';

export const feesColumns = ({
  selectedStore,
  currentActivePanel,
  type,
  apiFilters,
  toParamsFilters,
}) => [
  {
    className: 'fee-table-cell',
    title: 'Vigência Inicial',
    dataIndex: 'start_date',
    key: 'start_date',
    editable: false,
    filters:
      apiFilters?.length > 0 ? columnFilters(apiFilters, 'start_date') : [],
    onFilter: (value, record) => onColumnFilter(value, record, 'start_date'),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // This "zero length" dispatch is utterly important,
        // in order to keep each column state independent (Not passing data from one column to the next)
        store.dispatch(feesDetailsFiltersZeroLength());
        store.dispatch(
          feesDetailsFiltersStart({
            otherParams: {
              acquirerId: currentActivePanel,
              selectedStore,
              type,
            },
            columnName: 'start_date',
            offset: 0,
            limit: store.getState().feesDetailsFilters?.offsetShift,
            q:
              toParamsFilters.length !== 0
                ? {
                    filter: toParamsFilters,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.startDate = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <CustomFilterDropdown
          otherParams={{ acquirerId: currentActivePanel, selectedStore, type }}
          key="start_date"
          columnName="start_date"
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={feesDetailsFiltersStart}
          filteredColumns={toParamsFilters}
          clearAction={feesDetailsFiltersZeroLength}
          storeName="feesDetailsFilters"
          updatedFilteredColumns={feesUpdateFilteredColumnsStart}
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: getDefaultColumnWidth('start_date'),
    align: 'left',
  },

  {
    className: 'fee-table-cell',
    title: 'Vigência Final',
    dataIndex: 'end_date',
    key: 'end_date',
    editable: true,
    filters:
      apiFilters?.length > 0 ? columnFilters(apiFilters, 'end_date') : [],
    onFilter: (value, record) => onColumnFilter(value, record, 'end_date'),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // This "zero length" dispatch is utterly important,
        // in order to keep each column state independent (Not passing data from one column to the next)
        store.dispatch(feesDetailsFiltersZeroLength());
        store.dispatch(
          feesDetailsFiltersStart({
            otherParams: {
              acquirerId: currentActivePanel,
              selectedStore,
              type,
            },
            columnName: 'end_date',
            offset: 0,
            limit: store.getState().feesDetailsFilters?.offsetShift,
            q:
              toParamsFilters.length !== 0
                ? {
                    filter: toParamsFilters,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.endDate = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <CustomFilterDropdown
          otherParams={{ acquirerId: currentActivePanel, selectedStore, type }}
          key="end_date"
          columnName="end_date"
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={feesDetailsFiltersStart}
          filteredColumns={toParamsFilters}
          clearAction={feesDetailsFiltersZeroLength}
          storeName="feesDetailsFilters"
          updatedFilteredColumns={feesUpdateFilteredColumnsStart}
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: getDefaultColumnWidth('end_date'),
    align: 'left',
  },
  {
    className: 'fee-table-cell',
    title: 'Taxa',
    dataIndex: 'fee_percent_fmt',
    key: 'fee_percent_fmt',
    editable: true,
    filters:
      apiFilters?.length > 0
        ? columnFilters(apiFilters, 'fee_percent_fmt')
        : [],
    onFilter: (value, record) =>
      onColumnFilter(value, record, 'fee_percent_fmt'),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // This "zero length" dispatch is utterly important,
        // in order to keep each column state independent (Not passing data from one column to the next)
        store.dispatch(feesDetailsFiltersZeroLength());
        store.dispatch(
          feesDetailsFiltersStart({
            otherParams: {
              acquirerId: currentActivePanel,
              selectedStore,
              type,
            },
            columnName: 'fee_percent_fmt',
            offset: 0,
            limit: store.getState().feesDetailsFilters?.offsetShift,
            q:
              toParamsFilters.length !== 0
                ? {
                    filter: toParamsFilters,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.feePercentFmt = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <CustomFilterDropdown
          otherParams={{ acquirerId: currentActivePanel, selectedStore, type }}
          key="fee_percent_fmt"
          columnName="fee_percent_fmt"
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={feesDetailsFiltersStart}
          filteredColumns={toParamsFilters}
          clearAction={feesDetailsFiltersZeroLength}
          storeName="feesDetailsFilters"
          updatedFilteredColumns={feesUpdateFilteredColumnsStart}
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: getDefaultColumnWidth('fee_percent_fmt'),
    align: 'left',
    render: (_, record) => {
      const antIcon = <StLoadingOutlined spin />;
      return (
        <div>
          <span>{record?.fee_percent_fmt}</span>
          {record?.is_processing && (
            <Tooltip title="A atualização dos dados de taxa está em andamento, poderá levar até 60 minutos para ser concluída.">
              <Spin indicator={antIcon} />
            </Tooltip>
          )}
        </div>
      );
    },
  },
  {
    className: 'fee-table-cell',
    title: 'CNPJ | Apelido da loja',
    dataIndex: 'br_cnpj_short_name',
    key: 'br_cnpj_short_name',
    editable: false,
    filters:
      apiFilters?.length > 0
        ? columnFilters(apiFilters, 'br_cnpj_short_name')
        : [],
    onFilter: (value, record) =>
      onColumnFilter(value, record, 'br_cnpj_short_name'),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // This "zero length" dispatch is utterly important,
        // in order to keep each column state independent (Not passing data from one column to the next)
        store.dispatch(feesDetailsFiltersZeroLength());
        store.dispatch(
          feesDetailsFiltersStart({
            otherParams: {
              acquirerId: currentActivePanel,
              selectedStore,
              type,
            },
            columnName: 'br_cnpj_short_name',
            offset: 0,
            limit: store.getState().feesDetailsFilters?.offsetShift,
            q:
              toParamsFilters.length !== 0
                ? {
                    filter: toParamsFilters,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.brCnpjShortName = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <CustomFilterDropdown
          otherParams={{ acquirerId: currentActivePanel, selectedStore, type }}
          key="br_cnpj_short_name"
          columnName="br_cnpj_short_name"
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={feesDetailsFiltersStart}
          filteredColumns={toParamsFilters}
          clearAction={feesDetailsFiltersZeroLength}
          storeName="feesDetailsFilters"
          updatedFilteredColumns={feesUpdateFilteredColumnsStart}
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: getDefaultColumnWidth('br_cnpj_short_name'),
    align: 'left',
  },
  {
    className: 'fee-table-cell',
    title: 'Estabelecimento',
    dataIndex: 'merchant_code',
    key: 'merchant_code',
    editable: false,
    filters:
      apiFilters?.length > 0 ? columnFilters(apiFilters, 'merchant_code') : [],
    onFilter: (value, record) => onColumnFilter(value, record, 'merchant_code'),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // This "zero length" dispatch is utterly important,
        // in order to keep each column state independent (Not passing data from one column to the next)
        store.dispatch(feesDetailsFiltersZeroLength());
        store.dispatch(
          feesDetailsFiltersStart({
            otherParams: {
              acquirerId: currentActivePanel,
              selectedStore,
              type,
            },
            columnName: 'merchant_code',
            offset: 0,
            limit: store.getState().feesDetailsFilters?.offsetShift,
            q:
              toParamsFilters.length !== 0
                ? {
                    filter: toParamsFilters,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.merchantCode = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <CustomFilterDropdown
          otherParams={{ acquirerId: currentActivePanel, selectedStore, type }}
          key="merchant_code"
          columnName="merchant_code"
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={feesDetailsFiltersStart}
          filteredColumns={toParamsFilters}
          clearAction={feesDetailsFiltersZeroLength}
          storeName="feesDetailsFilters"
          updatedFilteredColumns={feesUpdateFilteredColumnsStart}
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: getDefaultColumnWidth('merchant_code'),
    align: 'left',
  },
  {
    className: 'fee-table-cell',
    title: 'Bandeira',
    dataIndex: 'brand_code',
    key: 'brand_code',
    editable: false,
    filters:
      apiFilters?.length > 0 ? columnFilters(apiFilters, 'brand_code') : [],
    onFilter: (value, record) => onColumnFilter(value, record, 'brand_code'),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // This "zero length" dispatch is utterly important,
        // in order to keep each column state independent (Not passing data from one column to the next)
        store.dispatch(feesDetailsFiltersZeroLength());
        store.dispatch(
          feesDetailsFiltersStart({
            otherParams: {
              acquirerId: currentActivePanel,
              selectedStore,
              type,
            },
            columnName: 'brand_code',
            offset: 0,
            limit: store.getState().feesDetailsFilters?.offsetShift,
            q:
              toParamsFilters.length !== 0
                ? {
                    filter: toParamsFilters,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.brandCode = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <CustomFilterDropdown
          otherParams={{ acquirerId: currentActivePanel, selectedStore, type }}
          key="brand_code"
          columnName="brand_code"
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={feesDetailsFiltersStart}
          filteredColumns={toParamsFilters}
          clearAction={feesDetailsFiltersZeroLength}
          storeName="feesDetailsFilters"
          updatedFilteredColumns={feesUpdateFilteredColumnsStart}
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: getDefaultColumnWidth('brand_code'),
    align: 'left',
  },
  {
    className: 'fee-table-cell',
    title: 'Produto',
    dataIndex: 'product_code',
    key: 'product_code',
    editable: false,
    filters:
      apiFilters?.length > 0 ? columnFilters(apiFilters, 'product_code') : [],
    onFilter: (value, record) => onColumnFilter(value, record, 'product_code'),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // This "zero length" dispatch is utterly important,
        // in order to keep each column state independent (Not passing data from one column to the next)
        store.dispatch(feesDetailsFiltersZeroLength());
        store.dispatch(
          feesDetailsFiltersStart({
            otherParams: {
              acquirerId: currentActivePanel,
              selectedStore,
              type,
            },
            columnName: 'product_code',
            offset: 0,
            limit: store.getState().feesDetailsFilters?.offsetShift,
            q:
              toParamsFilters.length !== 0
                ? {
                    filter: toParamsFilters,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.productCode = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <CustomFilterDropdown
          otherParams={{ acquirerId: currentActivePanel, selectedStore, type }}
          key="product_code"
          columnName="product_code"
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={feesDetailsFiltersStart}
          filteredColumns={toParamsFilters}
          clearAction={feesDetailsFiltersZeroLength}
          storeName="feesDetailsFilters"
          updatedFilteredColumns={feesUpdateFilteredColumnsStart}
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: getDefaultColumnWidth('product_code'),
    align: 'left',
  },
  {
    className: 'fee-table-cell',
    title: 'Parcela Inicial',
    dataIndex: 'plan_start',
    key: 'plan_start',
    editable: false,
    filters:
      apiFilters?.length > 0 ? columnFilters(apiFilters, 'plan_start') : [],
    onFilter: (value, record) => onColumnFilter(value, record, 'plan_start'),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // This "zero length" dispatch is utterly important,
        // in order to keep each column state independent (Not passing data from one column to the next)
        store.dispatch(feesDetailsFiltersZeroLength());
        store.dispatch(
          feesDetailsFiltersStart({
            otherParams: {
              acquirerId: currentActivePanel,
              selectedStore,
              type,
            },
            columnName: 'plan_start',
            offset: 0,
            limit: store.getState().feesDetailsFilters?.offsetShift,
            q:
              toParamsFilters.length !== 0
                ? {
                    filter: toParamsFilters,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.planStart = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <CustomFilterDropdown
          otherParams={{ acquirerId: currentActivePanel, selectedStore, type }}
          key="plan_start"
          columnName="plan_start"
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={feesDetailsFiltersStart}
          filteredColumns={toParamsFilters}
          clearAction={feesDetailsFiltersZeroLength}
          storeName="feesDetailsFilters"
          updatedFilteredColumns={feesUpdateFilteredColumnsStart}
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: getDefaultColumnWidth('plan_start'),
    align: 'left',
  },
  {
    className: 'fee-table-cell',
    title: 'Parcela Final',
    dataIndex: 'plan_end',
    key: 'plan_end',
    editable: false,
    filters:
      apiFilters?.length > 0 ? columnFilters(apiFilters, 'plan_end') : [],
    onFilter: (value, record) => onColumnFilter(value, record, 'plan_end'),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // This "zero length" dispatch is utterly important,
        // in order to keep each column state independent (Not passing data from one column to the next)
        store.dispatch(feesDetailsFiltersZeroLength());
        store.dispatch(
          feesDetailsFiltersStart({
            otherParams: {
              acquirerId: currentActivePanel,
              selectedStore,
              type,
            },
            columnName: 'plan_end',
            offset: 0,
            limit: store.getState().feesDetailsFilters?.offsetShift,
            q:
              toParamsFilters.length !== 0
                ? {
                    filter: toParamsFilters,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.planEnd = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <CustomFilterDropdown
          otherParams={{ acquirerId: currentActivePanel, selectedStore, type }}
          key="plan_start"
          columnName="plan_end"
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={feesDetailsFiltersStart}
          filteredColumns={toParamsFilters}
          clearAction={feesDetailsFiltersZeroLength}
          storeName="feesDetailsFilters"
          updatedFilteredColumns={feesUpdateFilteredColumnsStart}
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: getDefaultColumnWidth('plan_end'),
    align: 'left',
  },
  {
    className: 'fee-table-cell',
    title: 'Última atualização',
    dataIndex: 'updated_at',
    key: 'updated_at',
    editable: false,
    filters:
      apiFilters?.length > 0 ? columnFilters(apiFilters, 'updated_at') : [],
    onFilter: (value, record) => onColumnFilter(value, record, 'updated_at'),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // This "zero length" dispatch is utterly important,
        // in order to keep each column state independent (Not passing data from one column to the next)
        store.dispatch(feesDetailsFiltersZeroLength());
        store.dispatch(
          feesDetailsFiltersStart({
            otherParams: {
              acquirerId: currentActivePanel,
              selectedStore,
              type,
            },
            columnName: 'updated_at',
            offset: 0,
            limit: store.getState().feesDetailsFilters?.offsetShift,
            q:
              toParamsFilters.length !== 0
                ? {
                    filter: toParamsFilters,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.updatedAt = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <CustomFilterDropdown
          otherParams={{ acquirerId: currentActivePanel, selectedStore, type }}
          key="updated_at"
          columnName="updated_at"
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={feesDetailsFiltersStart}
          filteredColumns={toParamsFilters}
          clearAction={feesDetailsFiltersZeroLength}
          storeName="feesDetailsFilters"
          updatedFilteredColumns={feesUpdateFilteredColumnsStart}
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: getDefaultColumnWidth('updated_at'),
    align: 'left',
  },
  {
    className: 'fee-table-cell',
    title: 'Data de cadastro',
    dataIndex: 'created_at',
    key: 'created_at',
    editable: false,
    filters:
      apiFilters?.length > 0 ? columnFilters(apiFilters, 'created_at') : [],
    onFilter: (value, record) => onColumnFilter(value, record, 'created_at'),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // This "zero length" dispatch is utterly important,
        // in order to keep each column state independent (Not passing data from one column to the next)
        store.dispatch(feesDetailsFiltersZeroLength());
        store.dispatch(
          feesDetailsFiltersStart({
            otherParams: {
              acquirerId: currentActivePanel,
              selectedStore,
              type,
            },
            columnName: 'created_at',
            offset: 0,
            limit: store.getState().feesDetailsFilters?.offsetShift,
            q:
              toParamsFilters.length !== 0
                ? {
                    filter: toParamsFilters,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.createdAt = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <CustomFilterDropdown
          otherParams={{ acquirerId: currentActivePanel, selectedStore, type }}
          key="created_at"
          columnName="created_at"
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={feesDetailsFiltersStart}
          filteredColumns={toParamsFilters}
          clearAction={feesDetailsFiltersZeroLength}
          storeName="feesDetailsFilters"
          updatedFilteredColumns={feesUpdateFilteredColumnsStart}
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: getDefaultColumnWidth('created_at'),
    align: 'left',
  },
];
