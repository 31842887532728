import store from 'store';
import { call, put } from 'redux-saga/effects';

import errorHandler from 'helpers/errorHandler';

import {
  tokenSandboxPostSuccess,
  tokenSandboxDeleteSuccess,
  tokenSandboxGetSuccess,
  tokenSandboxPutSuccess,
  tokenSandboxPostError,
  tokenSandboxDeleteError,
  tokenSandboxGetError,
  tokenSandboxPutError,
  tokenSandboxGetStart,
  tokenSandboxDisableTokenSuccess,
  tokenSandboxDisableTokenStart,
  tokenSandboxDisableTokenError,
  rowClientIdSandboxSuccess,
  rowClientIdSandboxError,
} from 'store/ducks/tokenSandbox/actions';
import {
  postToken,
  deleteToken,
  getToken,
  putToken,
} from 'services/token/token';
import { notification } from 'antd';

export function* tokenSandboxPostSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(postToken, payload);

    yield put(tokenSandboxPostSuccess(apiData));
    if (apiData?.message.includes('somente para')) {
      notification.info({ message: apiData?.message });
    } else {
      notification.success({ message: apiData?.message });
    }
    store.dispatch(
      tokenSandboxGetStart({
        type: 'SANDBOX',
        clientId: store.getState().tokenSandbox?.rowClientId,
        hidden: false,
      })
    );
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        tokenSandboxPostError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      errorHandler(String(err));
      yield put(tokenSandboxPostError('Ocorreu um erro:', err));
    }
  }
}
export function* tokenSandboxDeleteSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(deleteToken, payload);

    yield put(tokenSandboxDeleteSuccess(apiData));
    notification.success({
      message: apiData?.message,
    });
    store.dispatch(tokenSandboxDisableTokenStart({ value: false }));
    store.dispatch(
      tokenSandboxGetStart({
        type: 'SANDBOX',
        clientId: store.getState().tokenSandbox?.rowClientId,
        hidden: false,
      })
    );
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        tokenSandboxDeleteError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      errorHandler(String(err));
      yield put(tokenSandboxDeleteError('Ocorreu um erro:', err));
    }
  }
}
export function* tokenSandboxGetSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(getToken, payload);

    yield put(tokenSandboxGetSuccess(apiData));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        tokenSandboxGetError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      errorHandler(`Erro no processo de autenticação: ${String(err)}`);
      yield put(tokenSandboxGetError('Ocorreu um erro:', err));
    }
  }
}
export function* tokenSandboxPutSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(putToken, payload);

    yield put(tokenSandboxPutSuccess(apiData));
    notification.success({ message: apiData?.message });
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        tokenSandboxPutError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      errorHandler(String(err));
      yield put(tokenSandboxPutError('Ocorreu um erro:', err));
    }
  }
}
export function* tokenSandboxDisableTokenSaga(action) {
  try {
    const { payload } = action;

    yield put(tokenSandboxDisableTokenSuccess(payload));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        tokenSandboxDisableTokenError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    }
  }
}
export function* rowClientIdSandboxSaga(action) {
  try {
    const { payload } = action;

    yield put(rowClientIdSandboxSuccess(payload));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        rowClientIdSandboxError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    }
  }
}
