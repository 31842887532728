import { api } from './api';

const transformer = (apiResponse) => {
  return {
    email: apiResponse?.data?.meta?.email,
    client_id: apiResponse?.data?.meta?.client_id,
  };
};

const removeUser = async (payload) => {
  const config = {
    email_to_be_removed: payload.data.email,
    client_id: payload.data.client_id,
    definitely: payload.data.definitely,
  };
  const response = await api().put('/remove/access', config);
  return transformer(response);
};

export default removeUser;
