import {
  Button as AntButton,
  DatePicker,
  Form as AntForm,
  Input as AntInput,
  Select as AntSelect,
  Typography,
} from 'antd';
import { colors } from 'styles/colors';
import styled from 'styled-components';

export const Container = styled.div`
  margin: 32px 0 32px 32px;
  background-color: ${colors.gray1};
`;

export const Title = styled(Typography.Title)`
  &&& {
    color: ${colors.gray9};
    font-weight: 500;
    font-size: 30px;
    line-height: 38px;
    margin-bottom: 4px;
  }
`;

export const Subtitle = styled(Typography.Text)`
  &&& {
    color: ${colors.gray8};
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }
`;

export const Form = styled(AntForm)`
  &&& {
    margin-top: 40px;
    display: flex;
    gap: 15px;
    height: 104px;
  }
`;

export const FormItem = styled(AntForm.Item)`
  &&& {
    margin-top: 8px;
    margin-bottom: 0px;
    margin: ${({ $noMargin }) => $noMargin && 0};

    .ant-form-item-explain-error {
      margin-top: 8px;
    }

    .ant-form-item-feedback-icon-success {
      display: none;
    }
  }
`;

export const RangePicker = styled(DatePicker.RangePicker)`
  width: ${({ width }) => width && `${width}px`};

  &&& {
    .ant-picker-suffix {
      color: ${({ changeCalendarIconColor }) =>
        changeCalendarIconColor && colors.red6};
    }
  }
`;

export const FormItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap && `${gap}px`};
`;

export const RequiredMark = styled.span`
  color: ${colors.red6};
  margin-right: 4px;
`;

export const Label = styled(Subtitle)`
  &&& {
    color: ${colors.gray9};
  }
`;

export const Input = styled(AntInput)`
  width: 160px;

  &&& {
    .ant-input::placeholder {
      color: ${colors.gray7};
    }
  }
`;

export const Select = styled(AntSelect)`
  &&& {
    width: 200px;

    .ant-select-selection-placeholder {
      color: ${colors.gray9};
    }

    .ant-select-arrow {
      color: ${colors.gray9};
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: end;
  gap: 15px;
  margin-bottom: 42px;
`;

export const Button = styled(AntButton)`
  &&& {
    width: ${({ width }) => width && `${width}px`};
  }
`;
