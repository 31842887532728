import { put } from 'redux-saga/effects';

import errorHandler from 'helpers/errorHandler';

import {
  updateFilteredColumnsSuccess,
  updateFilteredColumnsError,
  resetUpdateFilteredColumnsSuccess,
  resetUpdateFilteredColumnsError,
} from 'store/ducks/updateFilteredColumns/actions';

export function* updateFilteredColumnsSaga(action) {
  try {
    const { payload } = action;
    yield put(updateFilteredColumnsSuccess(payload));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        updateFilteredColumnsError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(updateFilteredColumnsError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}

export function* resetUpdateFilteredColumnsSaga(action) {
  try {
    const { payload } = action;
    yield put(resetUpdateFilteredColumnsSuccess(payload));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        resetUpdateFilteredColumnsError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(resetUpdateFilteredColumnsError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}
