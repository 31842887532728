import { action } from 'typesafe-actions';
import { actionTypes } from './types';

export const advancementDetailsFiltersStart = (data) => action(actionTypes.ADVANCEMENT_DETAILS_FILTERS_START, data);
export const advancementDetailsFiltersSuccess = (data) =>
  action(actionTypes.ADVANCEMENT_DETAILS_FILTERS_SUCCESS, data);
export const advancementDetailsFiltersError = (errorMessage, error) =>
  action(actionTypes.ADVANCEMENT_DETAILS_FILTERS_ERROR, { errorMessage }, undefined, { error });
export const advancementDetailsFiltersZeroLength = () =>
  action(actionTypes.ADVANCEMENT_DETAILS_FILTERS_ZERO_LENGTH);
export const updateAdvancementDetailsFilters = (data) =>
  action(actionTypes.UPDATE_ADVANCEMENT_DETAILS_FILTERS, data);
