import { lambdaApi } from '../api';
import {
  handleCategory,
  handleCriteria,
  handleGroupType,
  handleIsActive,
  handleReconType,
} from './utils';

const transformer = (apiResponse) => {
  const data = {
    data: apiResponse.data,
    error: apiResponse.error,
  };
  return data;
};

const putReconciliationRules = async ({
  ruleId,
  description,
  clientId,
  acquirerId,
  groupType,
  reconType,
  category,
  executionSequence,
  isActive,
  floatingValue,
  floatingDate,
  optionalCriteria,
  requiredCriteria,
}) => {
  const config = {
    description,
    client_id: clientId,
    acquirer_id: typeof acquirerId === 'number' ? Number(acquirerId) : null,
    group_type: handleGroupType(groupType),
    recon_type: handleReconType(reconType),
    category: handleCategory(category),
    execution_sequence: executionSequence,
    is_active: handleIsActive(isActive),
    floating_value: floatingValue,
    floating_date: floatingDate,
    optional_criteria: handleCriteria(optionalCriteria, 'optional'),
    required_criteria: handleCriteria(requiredCriteria, 'required'),
  };

  const response = await lambdaApi().put(
    `/conciliation/rules/${ruleId}`,
    config
  );
  return transformer(response.data);
};

export default putReconciliationRules;
