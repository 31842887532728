import { actionTypes } from './types';

const INITIAL_STATE = {
  error: undefined,
  errorMessage: undefined,
  loading: false,
  loadingCreateNotification: false,
  isError: false,
  notifications: undefined,
  todayNotifications: undefined,
  yesterdayNotifications: undefined,
  oldNotifications: undefined,
  hasUnreadNotifications: undefined,
  resumeNotifications: undefined,
  success: false,
  successChange: false,
  notificationPage: 1,
  notificationPerPage: 10,
  notificationTotalPages: 0,
  notificationTotalItems: 0,
  url: '',
  alertHasBeenSent: {},
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_NOTIFICATION_START:
      return {
        ...state,
        loading: true,
        error: undefined,
        errorMessage: undefined,
        isError: false,
      };
    case actionTypes.GET_NOTIFICATION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error.error,
        errorMessage: action.payload,
        isError: true,
      };
    case actionTypes.GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        notifications: action.payload,
        todayNotifications: action.payload.todayNotifications,
        yesterdayNotifications: action.payload.yesterdayNotifications,
        oldNotifications: action.payload.oldNotifications,
        hasUnreadNotifications: action.payload.hasUnreadNotifications,
        resumeNotifications: action.payload.resumeNotifications,
        notificationPage: action.payload.notificationPage,
        notificationPerPage: action.payload.notificationPerPage,
        notificationTotalPages: action.payload.notificationTotalPages,
        notificationTotalItems: action.payload.notificationTotalItems,
        alertHasBeenSent: action.payload.alertHasBeenSent,
      };
    case actionTypes.CREATE_NOTIFICATION_START:
      return {
        ...state,
        success: false,
        loading: true,
        loadingCreateNotification: true,
        error: undefined,
        errorMessage: undefined,
        isError: false,
      };
    case actionTypes.CREATE_NOTIFICATION_ERROR:
      return {
        ...state,
        loading: false,
        loadingCreateNotification: false,
        error: action.error.error,
        errorMessage: action.payload,
        isError: true,
        success: false,
      };
    case actionTypes.CREATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        loadingCreateNotification: false,
        notifications: action.payload,
        todayNotifications: action.payload.todayNotifications,
        yesterdayNotifications: action.payload.yesterdayNotifications,
        oldNotifications: action.payload.oldNotifications,
        resumeNotifications: action.payload.resumeNotifications,
        success: true,
      };
    case actionTypes.CHANGE_NOTIFICATION_STATUS_START:
      return {
        ...state,
        successChange: false,
        loading: true,
        error: undefined,
        errorMessage: undefined,
        isError: false,
      };
    case actionTypes.CHANGE_NOTIFICATION_STATUS_ERROR:
      return {
        ...state,
        successChange: false,
        loading: false,
        error: action.error.error,
        errorMessage: action.payload,
        isError: true,
      };
    case actionTypes.CHANGE_NOTIFICATION_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        successChange: true,
        hasUnreadNotifications: action.payload.hasUnreadNotifications,
      };
    case actionTypes.GET_DOWNLOAD_URL_START:
      return {
        ...state,
        loading: true,
        error: undefined,
        errorMessage: undefined,
        isError: false,
      };
    case actionTypes.GET_DOWNLOAD_URL_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error.error,
        errorMessage: action.payload,
        isError: true,
      };
    case actionTypes.GET_DOWNLOAD_URL_SUCCESS:
      return {
        ...state,
        loading: false,
        url: action.payload,
      };
    case actionTypes.MARK_ALERT_AS_SENT:
      return {
        ...state,
        alertHasBeenSent: {
          ...state.alertHasBeenSent,
          [action.payload]: true,
        },
      };
    case actionTypes.UPDATE_NOTIFICATION_PAGE:
      return {
        ...state,
      };
    case actionTypes.UPDATE_NOTIFICATION_PAGE_SUCCESS:
      return {
        ...state,
        notificationPage: action.payload.pageNumber,
      };
    case actionTypes.UPDATE_NOTIFICATION_PER_PAGE:
      return {
        ...state,
      };
    case actionTypes.UPDATE_NOTIFICATION_PER_PAGE_SUCCESS:
      return {
        ...state,
        notificationPerPage: action.payload.perPage,
      };
    default:
      return state;
  }
};

export default reducer;
