import { action } from 'typesafe-actions';
import { actionTypes } from './types';

export const acquirerStart = (data) => action(actionTypes.ACQUIRER_START, data);
export const acquirerAllStart = (data) =>
  action(actionTypes.ACQUIRER_ALL_START, data);
export const acquirerSuccess = (data) =>
  action(actionTypes.ACQUIRER_SUCCESS, data);
export const acquirerError = (errorMessage, error) =>
  action(actionTypes.ACQUIRER_ERROR, { errorMessage }, undefined, { error });

export const acquirerPointOfSaleStart = (data) =>
  action(actionTypes.ACQUIRER_POINTOFSALE_START, data);
export const acquirerPointOfSaleSuccess = (data) =>
  action(actionTypes.ACQUIRER_POINTOFSALE_SUCCESS, data);
export const acquirerPointOfSaleError = (errorMessage, error) =>
  action(actionTypes.ACQUIRER_POINTOFSALE_ERROR, { errorMessage }, undefined, {
    error,
  });

export const acquirerVoucherStart = (data) =>
  action(actionTypes.ACQUIRER_VOUCHER_START, data);
export const acquirerVoucherSuccess = (data) =>
  action(actionTypes.ACQUIRER_VOUCHER_SUCCESS, data);
export const acquirerVoucherError = (errorMessage, error) =>
  action(actionTypes.ACQUIRER_VOUCHER_ERROR, { errorMessage }, undefined, {
    error,
  });

export const acquirerMerchantsStart = () =>
  action(actionTypes.ACQUIRER_MERCHANTS_START);
export const acquirerMerchantsSuccess = (data) =>
  action(actionTypes.ACQUIRER_MERCHANTS_SUCCESS, data);
export const acquirerMerchantsError = (errorMessage, error) =>
  action(actionTypes.ACQUIRER_MERCHANTS_ERROR, { errorMessage }, undefined, {
    error,
  });
