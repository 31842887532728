import { call, put } from 'redux-saga/effects';
import errorHandler from 'helpers/errorHandler';
import getAdjustmentDetailsFiltersFromAPI from 'services/filters/details/adjustmentDetailsFilters';
import { errorToShow } from 'store/ducks/errorToShow/actions';

import {
  adjustmentDetailsFiltersSuccess,
  adjustmentDetailsFiltersError,
} from './actions';

export function* adjustmentDetailsFiltersSaga(action) {
  const { payload } = action;
  try {
    const apiData = yield call(getAdjustmentDetailsFiltersFromAPI, payload);
    yield put(adjustmentDetailsFiltersSuccess(apiData));
  } catch (err) {
    if (
      err?.response?.data?.error &&
      err?.response?.data?.error?.type &&
      err?.response?.data?.error?.message
    ) {
      yield put(
        errorToShow({
          message: err.response.data.error.message,
          type: err.response.data.error.type,
        })
      );
      yield put(
        adjustmentDetailsFiltersError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    }
  }
}
