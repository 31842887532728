import styled from 'styled-components';
import { colors } from 'styles/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.primary6};
  width: 455px;
  height: 546px;
  border-radius: 0 5px 5px 0;
  line-height: 32px;
`;

export const StoresContainer = styled.div`
  position: absolute;
  top: 26%;
  left: 25%;
  display: flex;
  gap: 8px;
  justify-content: center;
`;

export const StoreLink = styled.a`
  width: 110px;
  height: 32px;
`;
