import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import { ContentTabs, Filters } from 'components/Intermediate/Sales/components';
import { salesSummaryStart } from 'store/ducks/salesSummary/actions';
import { salesInfoStart } from 'store/ducks/salesInfo/actions';
import { salesInfoFiltersStart } from 'store/ducks/filters/salesInfoFilters/actions';
import { disabledDateByPlan } from 'helpers/disableDates';
import { useResetFilters } from 'utils/hooks/useResetFilters';
import { updateSalesDetailsFilters } from 'store/ducks/filters/details/salesDetailsFilters/actions';
import { onSorter } from 'helpers/sorter';
import { useRangePickerChange } from 'utils/hooks/useRangePickerChange';

const SalesIntermediate = () => {
  const dispatch = useDispatch();

  const startDate = useSelector(
    (state) => state.updateScreenToScreenDate.startDate
  );
  const endDate = useSelector(
    (state) => state.updateScreenToScreenDate.endDate
  );

  const [sort, setSort] = useState('DESC');

  const [filtersDrawer, toggleFiltersDrawer] = useState(false);

  const [activeTab, setActiveTab] = useState('branches');

  const [filterName, setFilterName] = useState(null);

  const salesSummary = useSelector((state) => state.salesSummary.summary);
  const summaryLoading = useSelector((state) => state.salesSummary.loading);
  const isSummaryError = useSelector((state) => state.salesSummary.isError);

  const salesInfo = useSelector((state) => state.salesInfo.sales);

  const salesInfoLoading = useSelector((state) => state.salesInfo.loading);
  const salesInfoError = useSelector((state) => state.salesInfo.isError);

  const salesInfoFilters = useSelector(
    (state) => state.salesInfoFilters.filters
  );

  const loading = salesInfoLoading || summaryLoading;

  const [calendarIsOpen, setCalendarIsOpen] = useState(false);
  const dateHasBeenUpdated = useSelector(
    (state) => state.updateScreenToScreenDate.dateHasBeenUpdated
  );

  const { onRangePickerChange } = useRangePickerChange();

  useEffect(() => {
    dispatch(salesSummaryStart({ startDate, endDate }));
  }, [dispatch, startDate, endDate]);

  useEffect(() => {
    dispatch(
      salesInfoStart({
        startDate,
        endDate,
        page: 1,
        type: activeTab,
        perPage: 100,
        sort,
        filter: filterName,
      })
    );
  }, [startDate, endDate, dispatch, activeTab, sort, filterName]);

  useEffect(() => {
    dispatch(salesInfoFiltersStart({ startDate, endDate, type: activeTab }));
  }, [dispatch, startDate, endDate, activeTab]);

  useEffect(() => {
    if (dateHasBeenUpdated && !calendarIsOpen) {
      dispatch(
        salesInfoStart({
          startDate,
          endDate,
          page: 1,
          type: activeTab,
          perPage: 100,
          sort,
          filter: filterName,
        })
      );
      dispatch(salesInfoFiltersStart({ startDate, endDate, type: activeTab }));
      dispatch(salesSummaryStart({ startDate, endDate }));
    }
  }, [
    startDate,
    dispatch,
    filterName,
    endDate,
    calendarIsOpen,
    dateHasBeenUpdated,
    activeTab,
    sort,
  ]);

  const onRangePickerOpenOrCloseChange = (open) => {
    setCalendarIsOpen(open);
  };

  const onChangeSelectFilter = (val) => {
    if (val === 'Todos' || val === 'Buscar' || val === '') {
      setFilterName(null);
    } else {
      setFilterName(val);
    }
  };

  const onTabClick = (key) => {
    setActiveTab(key);
    onChangeSelectFilter(null);
  };

  const { resetFilters } = useResetFilters();

  useEffect(() => {
    resetFilters(updateSalesDetailsFilters);
  }, [resetFilters]);

  return (
    <>
      <Filters
        title="Vendas"
        subtitle="Total de vendas realizadas no período"
        onRangePickerOpenOrCloseChange={onRangePickerOpenOrCloseChange}
        hasRangePicker
        hasSelect
        hasSorter
        dateDefaultValue={[startDate, endDate]}
        onRangePickerChange={(dates) =>
          onRangePickerChange(dates, calendarIsOpen)
        }
        onChangeSelectFilter={onChangeSelectFilter}
        loading={loading}
        sort={sort}
        sorter={onSorter(sort, setSort)}
        filtersDrawer={filtersDrawer}
        filterName={filterName}
        renderFilterButton={() => (
          <Button
            style={{ borderRadius: 4 }}
            onClick={() => toggleFiltersDrawer(!filtersDrawer)}
          >
            Filtros
          </Button>
        )}
        onCloseFiltersDrawer={() => toggleFiltersDrawer(false)}
        sorterButtonText="Vendas"
        infoFiltersToSelect={salesInfoFilters}
        activeTab={activeTab}
        disabledDate={disabledDateByPlan}
        calendarIsOpen={calendarIsOpen}
        setCalendarIsOpen={setCalendarIsOpen}
      />
      <ContentTabs
        startDate={startDate}
        endDate={endDate}
        infoData={salesInfo}
        infoLoading={salesInfoLoading}
        isInfoError={salesInfoError}
        summaryData={salesSummary}
        summaryLoading={summaryLoading}
        isSummaryError={isSummaryError}
        onTabClick={onTabClick}
        activeTab={activeTab}
        infoFilter={filterName}
      />
    </>
  );
};

export default SalesIntermediate;
