import dayjs from 'dayjs';
import { lambdaApi } from '../../api';
import { parseCurrency } from 'helpers/currency';

const transformer = ({ data: { forecast, confirmation } }) => {
  const transformedResponse = {
    forecast: {
      pendentAmount: parseCurrency(forecast?.pendent_amount),
      rawPendentAmount: forecast?.pendent_amount,
      percentPendent: forecast?.percent_pendent,
      percentReconciled: forecast?.percent_reconciled,
      reconciledAmount: parseCurrency(forecast?.reconciled_amount),
      totalAmount: parseCurrency(forecast?.total_amount),
      rawTotalAmount: forecast?.total_amount,
    },
    confirmation: {
      pendentAmount: parseCurrency(confirmation?.pendent_amount),
      rawPendentAmount: confirmation?.pendent_amount,
      percentPendent: confirmation?.percent_pendent,
      percentReconciled: confirmation?.percent_reconciled,
      reconciledAmount: parseCurrency(confirmation?.reconciled_amount),
      totalAmount: parseCurrency(confirmation?.total_amount),
      rawTotalAmount: confirmation?.total_amount,
    },
  };
  return transformedResponse;
};

const getPaymentsSummary = async ({ startDate, endDate }) => {
  const config = {
    params: {
      start_date: dayjs(startDate).format('YYYY-MM-DD'),
      end_date: dayjs(endDate).format('YYYY-MM-DD'),
    },
  };
  const response = await lambdaApi().get('/summary/payment/reconciled', config);
  return transformer(response.data);
};

export default getPaymentsSummary;
