import styled from 'styled-components';
import { Button, Spin } from 'antd';
import { colors } from 'styles/colors';
import { InputMaskCpfCnpj } from 'commons/InputMaskCpfCnpj';

export const StCpfCnpj = styled(InputMaskCpfCnpj)`
  width: 100%;
  padding: 4px 11px;
  color: ${(props) => (props.disabled ? 'rgba(0, 0, 0, 0.25)' : colors.gray11)};
  border: 1px solid ${colors.gray5};
  border-radius: 2px;
  background-color: ${(props) => (props.disabled ? colors.gray3 : 'default')};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'default')};
  &:hover {
    border-color: ${(props) => (props.disabled ? 'default' : colors.primary5)};
  }
  &:focus {
    border-color: ${colors.primary5};
  }
`;

export const StNewCnpjButton = styled(Button)`
  &&& {
    margin-top: 16px;
  }
`;

export const StWarning = styled.p`
  position: absolute;
  top: 58px;
  color: ${colors.gold6};
  color: ${({ isCnpj }) => (isCnpj ? colors.red5 : colors.gold6)};
  font-weight: 400;
  z-index: 1;
`;

export const StSpin = styled(Spin)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.gray1};
  padding: 16px 0;
`;
