import store from 'store';
import { plansFeaturesPermissions } from 'constants/plansFeaturesPermissions';

const usePlansFeaturesPermissions = () => {
  // TODO: change to use usePermissions provider to set this state.
  const { permissions } = store.getState();
  const planId = permissions?.data?.plan_id;
  const features = plansFeaturesPermissions[planId] || [];

  const isFeatureAvailable = ({ key = '' }) => {
    if (!key || features.length === 0) return false;
    return features.includes(key);
  };

  return {
    isFeatureAvailable,
  };
};

export default usePlansFeaturesPermissions;
