import { lambdaApi } from 'services/api';

const parseResult = (values) => {
  const result = values.map((v, index) => {
    return {
      statusId: index,
      value: v,
    };
  });

  return result;
};

export const getStatus = async () => {
  const result = await lambdaApi().get('/onboarding/status');

  return parseResult(result?.data?.data[0]);
};
