import React from 'react';
import { Grid, Row, Col } from 'antd';
import { StContainer } from './styled';
import { LoginImage } from '../../../../commons/LoginImage';
import SendMessageDesktop from './SendMessageDesktop';

const SentMessage = () => {
  const { useBreakpoint } = Grid;
  const breakpoint = useBreakpoint();

  return (
    <StContainer $breakpoint={breakpoint}>
      <Row
        align="middle"
        justify="center"
        style={{
          height: '100%',
          width: breakpoint?.lg ? '100%' : '80%',
          margin: '0 auto',
        }}
      >
        <Col span={24}>
          <Row>
            <Col xs={0} sm={0} md={0} lg={5} xl={5} xxl={5} />
            <Col xs={24} sm={24} md={24} lg={7} xl={7} xxl={7}>
              <SendMessageDesktop />
            </Col>
            <Col xs={0} sm={0} md={0} lg={8} xl={8} xxl={8}>
              <LoginImage />
            </Col>
            <Col xs={0} sm={0} md={0} lg={4} xl={4} xxl={4} />
          </Row>
        </Col>
      </Row>
    </StContainer>
  );
};

export default SentMessage;
