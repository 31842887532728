import React, { createContext, useState } from 'react';
import { Grid } from 'antd';
import { StContainer } from 'commons/Content/styled';
import { Header } from './components/Header';
import { Tabs } from './components/Tabs';
import { colors } from 'styles/colors';

export const FeesContext = createContext({});

export const Fees = () => {
  const { useBreakpoint } = Grid;
  const breakpoint = useBreakpoint();
  const [selectedStore, setSelectedStore] = useState({});
  const [currentActivePanel, setCurrentActivePanel] = useState(null);
  const [activeDetailTab, setActiveDetailTab] = useState('vigentes');
  const [activeTab, setActiveTab] = useState('fees-consolidated');
  const [disableExcel, setDisableExcel] = useState(false);
  const [disableCSV, setDisableCSV] = useState(false);

  return (
    <FeesContext.Provider
      value={{
        selectedStore,
        setSelectedStore,
        currentActivePanel,
        setCurrentActivePanel,
        activeTab,
        setActiveTab,
        activeDetailTab,
        setActiveDetailTab,
        disableExcel,
        setDisableExcel,
        disableCSV,
        setDisableCSV,
      }}
    >
      <StContainer
        $breakpoint={breakpoint}
        style={{ margin: 0, backgroundColor: colors.background1 }}
      >
        <Header />
        <Tabs />
      </StContainer>
    </FeesContext.Provider>
  );
};
