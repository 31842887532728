/* eslint-disable consistent-return */
/* eslint-disable no-else-return */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Col, Typography, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { TableCustomColumnsSelect } from 'commons/TableCustomColumnsSelect';
import { getNotificationStart } from 'store/ducks/notifications/actions';
import { getSessionClientId } from 'helpers/sessionService';
import { clearFiltersFunctions } from 'helpers/clearFiltersFunctions';
import { resetUpdateFilteredColumnsStart } from 'store/ducks/updateFilteredColumns/actions';
import { ReconciliationHeader } from './components/ReconciliationHeader';
import { shortId } from 'helpers/shortId';
import { dataTestIdHandler } from 'helpers/dataTestIdHandler';
import { usePlansFeaturesPermissions } from 'utils/hooks/usePlansFeaturesPermissions';
import { StContainer, StLimiter, StTotalizersCol } from './styled';
import { ExportDropdown } from 'commons/ExportDropdown';

const TableHeader = ({
  title,
  totalText,
  totalValue,
  columns,
  defaultSelectedColumns,
  dropdownOption,
  pagination,
  reconciliationType,
  createNotificationAction,
  notificationConfig,
  setDropdownOption,
  disableExcel,
  disableCSV,
  leftColReconciledText,
  leftColReconciledAmount,
  leftColPendentAmount,
  rightColReconciledText,
  rightColReconciledAmount,
  rightColPendentAmount,
  totalizers,
  handleResetFilters = () => {},
}) => {
  const { useBreakpoint } = Grid;
  const breakpoint = useBreakpoint();
  const dispatch = useDispatch();
  const createNotificationSuccess = useSelector(
    (state) => state.notifications.success
  );
  const createNotificationLoading = useSelector(
    (state) => state.notifications.loading
  );
  const permissionsData = useSelector((state) => state.permissions.data);
  const { user_id: userId, scope_id: scopeId } = permissionsData;
  const clientId = getSessionClientId();
  const { isFeatureAvailable } = usePlansFeaturesPermissions();
  const dataTestPrefix = 'table-header';

  const shouldRenderReconciliationHeader =
    reconciliationType === 'payments' ||
    reconciliationType === 'sales' ||
    reconciliationType === 'bank';

  const handleClearFilters = () => {
    handleResetFilters();
    dispatch(resetUpdateFilteredColumnsStart());

    Object.keys(clearFiltersFunctions).forEach((key) => {
      setTimeout(() => {
        const {
          selectedKeys,
          clearFilters,
          confirm = () => {},
        } = clearFiltersFunctions[key];

        if (selectedKeys !== undefined && selectedKeys.length > 0) {
          clearFilters();
          confirm();
        }
      }, 100);
    });
  };

  const parseValue = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  useEffect(() => {
    if (dropdownOption === 'excel' || dropdownOption === 'csv') {
      dispatch(createNotificationAction(notificationConfig));
      setDropdownOption('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownOption]);

  useEffect(() => {
    if (createNotificationSuccess) {
      dispatch(
        getNotificationStart({
          userId,
          scopeId,
          clientId,
          page: 1,
          perPage: 10,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createNotificationSuccess]);

  return (
    <StContainer>
      <StLimiter $breakpoint={breakpoint}>
        <Row justify="space-between" align="middle">
          <Col>
            <Typography.Text
              style={{ fontSize: '20px' }}
              strong
              {...dataTestIdHandler(dataTestPrefix, 'title')}
            >
              {title}
            </Typography.Text>
          </Col>
          {shouldRenderReconciliationHeader ? (
            <ReconciliationHeader
              leftColReconciledText={leftColReconciledText}
              leftColReconciledAmount={leftColReconciledAmount}
              leftColPendentAmount={leftColPendentAmount}
              rightColReconciledText={rightColReconciledText}
              rightColReconciledAmount={rightColReconciledAmount}
              rightColPendentAmount={rightColPendentAmount}
              reconciliationType={reconciliationType}
            />
          ) : (
            <StTotalizersCol>
              {totalizers ? (
                Object.values(totalizers).map(({ name, text, amount }) => (
                  <Typography.Text
                    key={shortId()}
                    {...dataTestIdHandler(
                      `${dataTestPrefix}-totalizers-label`,
                      name
                    )}
                  >
                    <span>{text}</span>
                    <span>{parseValue(amount)}</span>
                  </Typography.Text>
                ))
              ) : (
                <Typography.Text
                  key={shortId()}
                  {...dataTestIdHandler(
                    `${dataTestPrefix}`,
                    'totalizers-label'
                  )}
                >
                  <span>{totalText} </span>
                  <span>{parseValue(totalValue)}</span>
                </Typography.Text>
              )}
            </StTotalizersCol>
          )}
          <Row justify="end" gutter={8}>
            <Col>
              <Button onClick={handleClearFilters}>Limpar filtros</Button>
            </Col>
            <Col>
              <TableCustomColumnsSelect
                columns={columns}
                defaultSelectedColumns={defaultSelectedColumns}
                pagination={pagination}
              />
            </Col>
            <Col>
              <ExportDropdown
                blocked={!isFeatureAvailable({ key: 'general-fileExport' })}
                loading={createNotificationLoading}
                setDropdownOption={setDropdownOption}
                visibleCSV={reconciliationType !== 'bank'}
                disableExcel={disableExcel}
                disableCSV={disableCSV}
                dataTestPrefix={dataTestPrefix}
                {...dataTestIdHandler(dataTestPrefix, 'export-button')}
              />
            </Col>
          </Row>
        </Row>
      </StLimiter>
    </StContainer>
  );
};

TableHeader.defaultProps = {
  totalValue: 0,
};

TableHeader.propTypes = {
  title: PropTypes.string.isRequired,
  totalText: PropTypes.string.isRequired,
  totalValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultSelectedColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
  dropdownOption: PropTypes.string.isRequired,
  pagination: PropTypes.shape({
    pageSize: PropTypes.number,
    total: PropTypes.number,
    current: PropTypes.number,
  }).isRequired,
};

export default TableHeader;
