import { action } from 'typesafe-actions';
import { actionTypes } from 'store/ducks/radar/radarAnyRunning/types';

export const radarAnyRunningStart = (data) =>
  action(actionTypes.RADAR_ANY_RUNNING_START, data);
export const radarAnyRunningSuccess = (data) =>
  action(actionTypes.RADAR_ANY_RUNNING_SUCCESS, data);
export const radarAnyRunningError = (errorMessage, error) =>
  action(actionTypes.RADAR_ANY_RUNNING_ERROR, { errorMessage }, undefined, {
    error,
  });
