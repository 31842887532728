import { Collapse, Spin, Tabs } from 'antd';
import styled from 'styled-components';
import { colors } from 'styles/colors';

export const StContainer = styled.div`
  padding: 0 24px;
`;

export const StCollapse = styled(Collapse)`
  &&& {
    .custom-collapse-panel {
      padding: 0;

      > .ant-collapse-header {
        display: flex;
        flex-direction: column;

        .ant-collapse-header-text {
          width: 100%;
          order: 2;
        }

        .ant-collapse-extra {
          order: 1;
          width: 100%;

          .panel-header {
            width: 100%;
          }
        }
      }
    }

    .ant-collapse-item:last-child {
      margin-bottom: 0 !important;
    }
  }
`;

const { Panel } = Collapse;
export const StPanel = styled(Panel)``;

export const StSpin = styled(Spin)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.gray1};
  padding: 16px 0;
`;

export const StTabs = styled(Tabs)`
  width: 100%;

  .ant-tabs-nav {
    box-shadow: none !important;
  }

  &&& {
    overflow-y: hidden;
  }
`;
