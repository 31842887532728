import styled from 'styled-components';
import { Button, Typography } from 'antd';
import { colors } from 'styles/colors';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { Title } = Typography;

export const StContainer = styled.div`
  padding: 4rem;
`;

export const StButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const StNameContainer = styled.div`
  /* display: flex; */
  /* justify-content: space-evenly; */
`;

export const StCancelButton = styled(Button)`
  width: 9.875rem;
  height: 4rem;
  margin: 0 1rem 0 0;
  padding: 0.625rem 1.5rem;
  border-radius: 4px;
  border: solid 1px ${colors.gray11};
  background-color: ${colors.gray1};
  font-size: 1.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: center;
  color: ${colors.red6};
`;

export const StInvitationButton = styled(Button)`
  width: 16.25rem;
  height: 4rem;
  margin: 0 0 0 1rem;
  padding: 0.625rem 1.5rem;
  border-radius: 4px;
  background-color: ${colors.primary6};
  font-size: 1.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: center;
  color: ${colors.gray1};
`;

export const StModalBodyWrapper = styled.div`
  padding: 5px;
`;

export const StModalHeader = styled.div`
  display: flex;
`;

export const StModalHeaderIcon = styled(ExclamationCircleOutlined)`
  &&& {
    font-size: 21px;
    color: ${colors.gold6};
  }
`;

export const StModalHeaderTitle = styled(Title)`
  &&& {
    font-size: 16px;
    font-weight: 500;
    margin-left: 17.5px;
  }
`;

export const StModalContent = styled.div`
  margin-left: 39px;
`;

export const StModalBoldText = styled.span`
  font-weight: 500;
`;
