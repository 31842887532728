import styled from 'styled-components';

export const StRect = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 7px;
  background: ${(props) => props.background};
  border: 1px solid ${(props) => props.border};
  box-sizing: border-box;
  border-radius: 2px;
  color: ${(props) => props.color};
  width: fit-content;
`;
