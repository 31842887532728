import { handleColumnValueNames } from './handleNames';

const DEFAULT_SIZE = 240;

const defaultColumnWidths = {
  store_name: 300,
  reason: 330,
  forecast_date: 300,
  adm_fee_percent: 280,
  summary_cielo: 280,
  inst_schemefee_fee_amount: 300,
  inst_interchange_fee_amount: 300,
  customer_installment_gross_amount: 330,
  acquirer_installment_net_amount: 350,
  installment_gross_amount: 290,
  file_name: 500,
  client_sale_id: 300,
  product_code: 200,
  acquire_authorization: 200,
  sale_time: 200,
  rule_id: 60,
  recon_type: 60,
  group_type: 60,
  is_active: 60,
  category: 60,
  client_name_code: 200,
  execution_sequence: 60,
  description: 300,
  forecast_net_amount: 290,
  payment_net_amount: 260,
  sale_customer_gross_amount: 350,
  sale_gross_amount: 300,
  recon_sale_cst_gross_amount: 380,
  nrec_sale_cst_gross_amount: 400,
  recon_sale_gross_amount: 350,
  nrec_sale_gross_amount: 350,
  for_recon_installment_net_amount: 300,
  pay_forecast_inst_net_amount: 350,
  pay_recon_installment_net_amount: 350,
  pay_confirmation_inst_net_amount: 350,
  rec_confirmation_inst_net_amount: 400,
  gl_date: 320,
  total_rec_forecast_inst_net_amount: 400,
  total_pay_forecast_inst_net_amount: 400,
  diff_recon_net_amount: 350,
  transaction_date: 250,
  acquirer_name: 200,
  rec_forecast_inst_net_amount: 250,
  inst_transfer_amount: 200,
  inst_advance_fee_amount: 350,
  sum_inst_advance_net_amount: 300,
  sum_inst_adm_fee_amount: 280,
  sum_inst_advance_fee_amount: 350,
  start_date: 200,
  end_date: 220,
  fee_percent: 180,
  br_cnpj_short_name: 350,
  merchant_code: 200,
  brand_code: 180,
  plan_start: 200,
  plan_end: 200,
  updated_at: 250,
  created_at: 250,
};

export const columnNamesByTable = {
  sales: [
    'store_name',
    'sale_date',
    'forecast_date',
    'acquirer_name',
    'brand_code',
    'nsu',
    'plan_installments',
    'installment_gross_amount',
    'installment_net_amount',
    'product_code',
    'acquire_authorization',
    'card_number',
    'bank_code',
    'bank_branch',
    'bank_account',
    'capture_method_code',
    'merchant_code',
    'summary',
    'summary_cielo',
    'sale_time',
    'tid',
    'pos_number',
  ],
  paymentDivergences: [
    'reason',
    'store_name',
    'acquirer_name',
    'brand_code',
    'product_code',
    'rec_installment_net_amount',
    'pay_installment_net_amount',
    'diff_pay_net_amount',
    'rec_adm_fee_percent',
    'pay_inst_adm_fee_percent',
    'sale_date',
    'forecast_date',
    'payment_date',
    'late_payments_count',
    'nsu',
    'acquire_authorization',
    'plan_installments',
    'tid',
    'merchant_code',
    'summary',
    'description',
  ],
  forecast: [
    'store_name',
    'sale_date',
    'forecast_date',
    'acquirer_name',
    'brand_code',
    'nsu',
    'plan_installments',
    'installment_gross_amount',
    'adm_fee_percent',
    'installment_adm_fee_amount',
    'installment_net_amount',
    'product_code',
    'acquire_authorization',
    'card_number',
    'description',
    'bank_code',
    'bank_branch',
    'bank_account',
    'capture_method_code',
    'merchant_code',
    'summary',
    'summary_cielo',
    'sale_time',
    'tid',
    'pos_number',
  ],
  contractFee: [
    'acquirer_name',
    'merchant_name',
    'merchant_code',
    'brand_name',
    'product_name',
    'plan_start',
    'plan_end',
    'fee_percent_fmt',
  ],
  payments: [
    'store_name',
    'sale_date',
    'payment_date',
    'acquirer_name',
    'brand_code',
    'nsu',
    'plan_installments',
    'installment_gross_amount',
    'installment_net_amount',
    'product_code',
    'acquire_authorization',
    'card_number',
    'description',
    'bank_code',
    'bank_branch',
    'bank_account',
    'capture_method_code',
    'merchant_code',
    'summary',
    'summary_cielo',
    'tid',
    'pos_number',
  ],

  reconciliationRules: [
    'rule_id',
    'recon_type',
    'group_type',
    'is_active',
    'category',
    'client_name_code',
    'execution_sequence',
    'description',
  ],
};

export const getDefaultColumnWidth = (column) => {
  if (defaultColumnWidths[column] !== undefined) {
    return defaultColumnWidths[column];
  }
  return DEFAULT_SIZE;
};

export const generateFilterDataStructure = (tableFilters) => {
  const filtersParams = [];

  if (tableFilters.length === 0) {
    return filtersParams;
  }

  Object.keys(tableFilters).forEach((tableFilterKey) => {
    if (tableFilters[tableFilterKey]) {
      filtersParams.push({
        name: tableFilterKey,
        val: [...new Set(tableFilters[tableFilterKey])],
      });
    }
  });

  return filtersParams;
};

export const generateReconciliationRulesFilterDataStructure = (
  tableFilters
) => {
  const filtersParams = [];

  if (tableFilters.length === 0) {
    return filtersParams;
  }

  // eslint-disable-next-line no-unused-vars
  Object.entries(tableFilters).forEach(([key, value]) => {
    if (value.length > 0) {
      filtersParams.push({
        name: value[0]?.name,
        val: handleColumnValueNames(value),
      });
    }
  });

  return filtersParams;
};
