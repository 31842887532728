import React from 'react';
import {
  onColumnFilter,
  columnFilters,
  getDefaultColumns,
} from 'helpers/generalService';
import { getDefaultColumnWidth } from 'helpers/tableService';
import store from 'store';
import {
  paymentDivergencesDetailsFiltersStart,
  paymentDivergencesDetailsFiltersZeroLength,
} from 'store/ducks/filters/details/paymentDivergencesDetailsFilters/actions';
import FilterDropdown from 'commons/Table/components/FilterDropdown';
import { clearFiltersFunctions } from 'helpers/clearFiltersFunctions';
import ReasonCell from 'commons/Table/components/ReasonCell';

let filteredColumns = store.getState().updateFilteredColumns?.filteredColumns;

const columns = (apiFilters, toParamsFilters) => [
  {
    title: 'Motivo',
    dataIndex: 'status',
    filteredValue: getDefaultColumns(toParamsFilters, 'status'),
    filters: apiFilters ? columnFilters(apiFilters, 'status') : undefined,
    onFilter: (value, record) => onColumnFilter(value, record, 'status'),
    onFilterDropdownVisibleChange: (visible) => {
      filteredColumns = store.getState().updateFilteredColumns?.filteredColumns;
      if (visible) {
        // This "zero length" dispatch is utterly important,
        // in order to keep each column state independent (Not passing data from one column to the next)
        store.dispatch(paymentDivergencesDetailsFiltersZeroLength());
        store.dispatch(
          paymentDivergencesDetailsFiltersStart({
            startDate: store.getState().updateScreenToScreenDate?.startDate,
            endDate: store.getState().updateScreenToScreenDate?.endDate,
            columnName: 'status',
            offset: 0,
            q:
              filteredColumns.length !== 0
                ? {
                    filter: filteredColumns,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.status = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <FilterDropdown
          key={filteredColumns}
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={paymentDivergencesDetailsFiltersStart}
          filteredColumns={
            store.getState().updateFilteredColumns?.filteredColumns
          }
          clearAction={paymentDivergencesDetailsFiltersZeroLength}
          columnName="status"
          storeName="paymentDivergencesDetailsFilters"
        />
      );
    },
    render: (status) => {
      return <ReasonCell reason={status} />;
    },
    filterMultiple: true,
    sorter: {
      multiple: 18,
    },
    width: getDefaultColumnWidth('status'),
    align: 'left',
  },
  {
    title: 'Filial',
    dataIndex: 'store_name',
    filteredValue: getDefaultColumns(toParamsFilters, 'store_name'),
    filters: apiFilters ? columnFilters(apiFilters, 'store_name') : undefined,
    onFilter: (value, record) => onColumnFilter(value, record, 'store_name'),
    onFilterDropdownVisibleChange: (visible) => {
      filteredColumns = store.getState().updateFilteredColumns?.filteredColumns;
      if (visible) {
        // This "zero length" dispatch is utterly important,
        // in order to keep each column state independent (Not passing data from one column to the next)
        store.dispatch(paymentDivergencesDetailsFiltersZeroLength());
        store.dispatch(
          paymentDivergencesDetailsFiltersStart({
            startDate: store.getState().updateScreenToScreenDate?.startDate,
            endDate: store.getState().updateScreenToScreenDate?.endDate,
            columnName: 'store_name',
            offset: 0,
            q:
              filteredColumns.length !== 0
                ? {
                    filter: filteredColumns,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.storeName = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <FilterDropdown
          key={filteredColumns}
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={paymentDivergencesDetailsFiltersStart}
          filteredColumns={
            store.getState().updateFilteredColumns?.filteredColumns
          }
          clearAction={paymentDivergencesDetailsFiltersZeroLength}
          columnName="store_name"
          storeName="paymentDivergencesDetailsFilters"
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 7,
    },
    width: getDefaultColumnWidth('store_name'),
    align: 'left',
  },
  {
    title: 'Data do pagamento',
    dataIndex: 'transaction_date',
    filteredValue: getDefaultColumns(toParamsFilters, 'transaction_date'),
    filters: apiFilters
      ? columnFilters(apiFilters, 'transaction_date')
      : undefined,
    onFilter: (value, record) =>
      onColumnFilter(value, record, 'transaction_date'),
    onFilterDropdownVisibleChange: (visible) => {
      filteredColumns = store.getState().updateFilteredColumns?.filteredColumns;
      if (visible) {
        store.dispatch(paymentDivergencesDetailsFiltersZeroLength());
        store.dispatch(
          paymentDivergencesDetailsFiltersStart({
            startDate: store.getState().updateScreenToScreenDate?.startDate,
            endDate: store.getState().updateScreenToScreenDate?.endDate,
            columnName: 'transaction_date',
            offset: 0,
            q:
              filteredColumns.length !== 0
                ? {
                    filter: filteredColumns,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.transactionDate = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <FilterDropdown
          key={filteredColumns}
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={paymentDivergencesDetailsFiltersStart}
          filteredColumns={
            store.getState().updateFilteredColumns?.filteredColumns
          }
          clearAction={paymentDivergencesDetailsFiltersZeroLength}
          columnName="transaction_date"
          storeName="paymentDivergencesDetailsFilters"
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: getDefaultColumnWidth('transaction_date'),
    align: 'left',
  },
  {
    title: 'Adquirente',
    dataIndex: 'acquirer_name',
    filteredValue: getDefaultColumns(toParamsFilters, 'acquirer_name'),
    filters: apiFilters
      ? columnFilters(apiFilters, 'acquirer_name')
      : undefined,
    onFilter: (value, record) => onColumnFilter(value, record, 'acquirer_name'),
    onFilterDropdownVisibleChange: (visible) => {
      filteredColumns = store.getState().updateFilteredColumns?.filteredColumns;
      if (visible) {
        // This "zero length" dispatch is utterly important,
        // in order to keep each column state independent (Not passing data from one column to the next)
        store.dispatch(paymentDivergencesDetailsFiltersZeroLength());
        store.dispatch(
          paymentDivergencesDetailsFiltersStart({
            startDate: store.getState().updateScreenToScreenDate?.startDate,
            endDate: store.getState().updateScreenToScreenDate?.endDate,
            columnName: 'acquirer_name',
            offset: 0,
            q:
              filteredColumns.length !== 0
                ? {
                    filter: filteredColumns,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.acquirerName = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <FilterDropdown
          key={filteredColumns}
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={paymentDivergencesDetailsFiltersStart}
          filteredColumns={
            store.getState().updateFilteredColumns?.filteredColumns
          }
          clearAction={paymentDivergencesDetailsFiltersZeroLength}
          columnName="acquirer_name"
          storeName="paymentDivergencesDetailsFilters"
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 4,
    },
    width: getDefaultColumnWidth('acquirer_name'),
    align: 'left',
  },
  {
    title: 'Produto',
    dataIndex: 'product_code',
    filteredValue: getDefaultColumns(toParamsFilters, 'product_code'),
    filters: apiFilters ? columnFilters(apiFilters, 'product_code') : undefined,
    onFilter: (value, record) => onColumnFilter(value, record, 'product_code'),
    onFilterDropdownVisibleChange: (visible) => {
      filteredColumns = store.getState().updateFilteredColumns?.filteredColumns;
      if (visible) {
        store.dispatch(paymentDivergencesDetailsFiltersZeroLength());
        store.dispatch(
          paymentDivergencesDetailsFiltersStart({
            startDate: store.getState().updateScreenToScreenDate?.startDate,
            endDate: store.getState().updateScreenToScreenDate?.endDate,
            columnName: 'product_code',
            offset: 0,
            q:
              filteredColumns.length !== 0
                ? {
                    filter: filteredColumns,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.productCode = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <FilterDropdown
          key={filteredColumns}
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={paymentDivergencesDetailsFiltersStart}
          filteredColumns={
            store.getState().updateFilteredColumns?.filteredColumns
          }
          clearAction={paymentDivergencesDetailsFiltersZeroLength}
          columnName="product_code"
          storeName="paymentDivergencesDetailsFilters"
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 6,
    },
    width: getDefaultColumnWidth('product_code'),
    align: 'left',
  },
  {
    title: 'Bandeira',
    dataIndex: 'brand_code',
    filteredValue: getDefaultColumns(toParamsFilters, 'brand_code'),
    filters: apiFilters ? columnFilters(apiFilters, 'brand_code') : undefined,
    onFilter: (value, record) => onColumnFilter(value, record, 'brand_code'),
    onFilterDropdownVisibleChange: (visible) => {
      filteredColumns = store.getState().updateFilteredColumns?.filteredColumns;
      if (visible) {
        store.dispatch(paymentDivergencesDetailsFiltersZeroLength());
        store.dispatch(
          paymentDivergencesDetailsFiltersStart({
            startDate: store.getState().updateScreenToScreenDate?.startDate,
            endDate: store.getState().updateScreenToScreenDate?.endDate,
            columnName: 'brand_code',
            offset: 0,
            q:
              filteredColumns.length !== 0
                ? {
                    filter: filteredColumns,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.brandCode = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <FilterDropdown
          key={filteredColumns}
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={paymentDivergencesDetailsFiltersStart}
          filteredColumns={
            store.getState().updateFilteredColumns?.filteredColumns
          }
          clearAction={paymentDivergencesDetailsFiltersZeroLength}
          columnName="brand_code"
          storeName="paymentDivergencesDetailsFilters"
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 5,
    },
    width: getDefaultColumnWidth('brand_code'),
    align: 'left',
  },
  {
    title: 'Valor previsto estabelecimento (R$)',
    dataIndex: 'total_rec_forecast_inst_net_amount',
    filteredValue: getDefaultColumns(
      toParamsFilters,
      'total_rec_forecast_inst_net_amount'
    ),
    filters: apiFilters
      ? columnFilters(apiFilters, 'total_rec_forecast_inst_net_amount')
      : undefined,
    onFilter: (value, record) =>
      onColumnFilter(value, record, 'total_rec_forecast_inst_net_amount'),
    onFilterDropdownVisibleChange: (visible) => {
      filteredColumns = store.getState().updateFilteredColumns?.filteredColumns;
      if (visible) {
        store.dispatch(paymentDivergencesDetailsFiltersZeroLength());
        store.dispatch(
          paymentDivergencesDetailsFiltersStart({
            startDate: store.getState().updateScreenToScreenDate?.startDate,
            endDate: store.getState().updateScreenToScreenDate?.endDate,
            columnName: 'total_rec_forecast_inst_net_amount',
            offset: 0,
            q:
              filteredColumns.length !== 0
                ? {
                    filter: filteredColumns,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.totalRecForecastInstNetAmount = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <FilterDropdown
          key={filteredColumns}
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={paymentDivergencesDetailsFiltersStart}
          filteredColumns={
            store.getState().updateFilteredColumns?.filteredColumns
          }
          clearAction={paymentDivergencesDetailsFiltersZeroLength}
          columnName="total_rec_forecast_inst_net_amount"
          storeName="paymentDivergencesDetailsFilters"
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 18,
    },
    width: getDefaultColumnWidth('total_rec_forecast_inst_net_amount'),
    align: 'left',
  },
  {
    title: 'Valor previsto adquirente (R$)',
    dataIndex: 'total_pay_forecast_inst_net_amount',
    filteredValue: getDefaultColumns(
      toParamsFilters,
      'total_pay_forecast_inst_net_amount'
    ),
    filters: apiFilters
      ? columnFilters(apiFilters, 'total_pay_forecast_inst_net_amount')
      : undefined,
    onFilter: (value, record) =>
      onColumnFilter(value, record, 'total_pay_forecast_inst_net_amount'),
    onFilterDropdownVisibleChange: (visible) => {
      filteredColumns = store.getState().updateFilteredColumns?.filteredColumns;
      if (visible) {
        store.dispatch(paymentDivergencesDetailsFiltersZeroLength());
        store.dispatch(
          paymentDivergencesDetailsFiltersStart({
            startDate: store.getState().updateScreenToScreenDate?.startDate,
            endDate: store.getState().updateScreenToScreenDate?.endDate,
            columnName: 'total_pay_forecast_inst_net_amount',
            offset: 0,
            q:
              filteredColumns.length !== 0
                ? {
                    filter: filteredColumns,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.totalPayForecastInstNetAmount = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <FilterDropdown
          key={filteredColumns}
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={paymentDivergencesDetailsFiltersStart}
          filteredColumns={
            store.getState().updateFilteredColumns?.filteredColumns
          }
          clearAction={paymentDivergencesDetailsFiltersZeroLength}
          columnName="total_pay_forecast_inst_net_amount"
          storeName="paymentDivergencesDetailsFilters"
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 18,
    },
    width: getDefaultColumnWidth('total_pay_forecast_inst_net_amount'),
    align: 'left',
  },
  {
    title: 'Valor pago (R$)',
    dataIndex: 'pay_recon_installment_net_amount',
    filteredValue: getDefaultColumns(
      toParamsFilters,
      'pay_recon_installment_net_amount'
    ),
    filters: apiFilters
      ? columnFilters(apiFilters, 'pay_recon_installment_net_amount')
      : undefined,
    onFilter: (value, record) =>
      onColumnFilter(value, record, 'pay_recon_installment_net_amount'),
    onFilterDropdownVisibleChange: (visible) => {
      filteredColumns = store.getState().updateFilteredColumns?.filteredColumns;
      if (visible) {
        store.dispatch(paymentDivergencesDetailsFiltersZeroLength());
        store.dispatch(
          paymentDivergencesDetailsFiltersStart({
            startDate: store.getState().updateScreenToScreenDate?.startDate,
            endDate: store.getState().updateScreenToScreenDate?.endDate,
            columnName: 'pay_recon_installment_net_amount',
            offset: 0,
            q:
              filteredColumns.length !== 0
                ? {
                    filter: filteredColumns,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.payReconInstallmentNetAmount = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <FilterDropdown
          key={filteredColumns}
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={paymentDivergencesDetailsFiltersStart}
          filteredColumns={
            store.getState().updateFilteredColumns?.filteredColumns
          }
          clearAction={paymentDivergencesDetailsFiltersZeroLength}
          columnName="pay_recon_installment_net_amount"
          storeName="paymentDivergencesDetailsFilters"
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 18,
    },
    width: getDefaultColumnWidth('pay_recon_installment_net_amount'),
    align: 'left',
  },
  {
    title: 'Diferença de valor (R$)',
    dataIndex: 'diff_recon_net_amount',
    filteredValue: getDefaultColumns(toParamsFilters, 'diff_recon_net_amount'),
    filters: apiFilters
      ? columnFilters(apiFilters, 'diff_recon_net_amount')
      : undefined,
    onFilter: (value, record) =>
      onColumnFilter(value, record, 'diff_recon_net_amount'),
    onFilterDropdownVisibleChange: (visible) => {
      filteredColumns = store.getState().updateFilteredColumns?.filteredColumns;
      if (visible) {
        store.dispatch(paymentDivergencesDetailsFiltersZeroLength());
        store.dispatch(
          paymentDivergencesDetailsFiltersStart({
            startDate: store.getState().updateScreenToScreenDate?.startDate,
            endDate: store.getState().updateScreenToScreenDate?.endDate,
            columnName: 'diff_recon_net_amount',
            offset: 0,
            q:
              filteredColumns.length !== 0
                ? {
                    filter: filteredColumns,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.diffReconNetAmount = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <FilterDropdown
          key={filteredColumns}
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={paymentDivergencesDetailsFiltersStart}
          filteredColumns={
            store.getState().updateFilteredColumns?.filteredColumns
          }
          clearAction={paymentDivergencesDetailsFiltersZeroLength}
          columnName="diff_recon_net_amount"
          storeName="paymentDivergencesDetailsFilters"
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 18,
    },
    width: getDefaultColumnWidth('diff_recon_net_amount'),
    align: 'left',
  },
  {
    title: 'Diferença de data',
    dataIndex: 'late_payments_count',
    filteredValue: getDefaultColumns(toParamsFilters, 'late_payments_count'),
    filters: apiFilters
      ? columnFilters(apiFilters, 'late_payments_count')
      : undefined,
    onFilter: (value, record) =>
      onColumnFilter(value, record, 'late_payments_count'),
    onFilterDropdownVisibleChange: (visible) => {
      filteredColumns = store.getState().updateFilteredColumns?.filteredColumns;
      if (visible) {
        store.dispatch(paymentDivergencesDetailsFiltersZeroLength());
        store.dispatch(
          paymentDivergencesDetailsFiltersStart({
            startDate: store.getState().updateScreenToScreenDate?.startDate,
            endDate: store.getState().updateScreenToScreenDate?.endDate,
            columnName: 'late_payments_count',
            offset: 0,
            q:
              filteredColumns.length !== 0
                ? {
                    filter: filteredColumns,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.latePaymentsCount = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <FilterDropdown
          key={filteredColumns}
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={paymentDivergencesDetailsFiltersStart}
          filteredColumns={
            store.getState().updateFilteredColumns?.filteredColumns
          }
          clearAction={paymentDivergencesDetailsFiltersZeroLength}
          columnName="late_payments_count"
          storeName="paymentDivergencesDetailsFilters"
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: getDefaultColumnWidth('late_payments_count'),
    align: 'left',
  },
  {
    title: 'Previsões em aberto (R$)',
    dataIndex: 'rec_forecast_inst_net_amount',
    filteredValue: getDefaultColumns(
      toParamsFilters,
      'rec_forecast_inst_net_amount'
    ),
    filters: apiFilters
      ? columnFilters(apiFilters, 'rec_forecast_inst_net_amount')
      : undefined,
    onFilter: (value, record) =>
      onColumnFilter(value, record, 'rec_forecast_inst_net_amount'),
    onFilterDropdownVisibleChange: (visible) => {
      filteredColumns = store.getState().updateFilteredColumns?.filteredColumns;
      if (visible) {
        store.dispatch(paymentDivergencesDetailsFiltersZeroLength());
        store.dispatch(
          paymentDivergencesDetailsFiltersStart({
            startDate: store.getState().updateScreenToScreenDate?.startDate,
            endDate: store.getState().updateScreenToScreenDate?.endDate,
            columnName: 'rec_forecast_inst_net_amount',
            offset: 0,
            q:
              filteredColumns.length !== 0
                ? {
                    filter: filteredColumns,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.recForecastInstNetAmount = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <FilterDropdown
          key={filteredColumns}
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={paymentDivergencesDetailsFiltersStart}
          filteredColumns={
            store.getState().updateFilteredColumns?.filteredColumns
          }
          clearAction={paymentDivergencesDetailsFiltersZeroLength}
          columnName="rec_forecast_inst_net_amount"
          storeName="paymentDivergencesDetailsFilters"
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 18,
    },
    width: getDefaultColumnWidth('rec_forecast_inst_net_amount'),
    align: 'left',
  },
];

export default columns;
