/* eslint-disable no-case-declarations */
/* eslint-disable no-else-return */
/* eslint-disable no-unsafe-negation */
import { actionTypes } from 'store/ducks/updateFilteredColumns/types';

const INITIAL_STATE = {
  error: undefined,
  errorMessage: undefined,
  loading: true,
  isError: false,
  filteredColumns: [],
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_FILTERED_COLUMNS_START:
      return {
        ...state,
        loading: true,
        error: undefined,
        errorMessage: undefined,
        isError: false,
      };
    case actionTypes.UPDATE_FILTERED_COLUMNS_SUCCESS:
      const filteredColumnsArray = [
        ...state.filteredColumns,
        { name: action?.payload?.name, val: action?.payload?.val },
      ].reverse();
      const placeholderArray = [];
      const newFilteredColumns = filteredColumnsArray.filter((item) => {
        if (!placeholderArray.includes(item.name)) {
          placeholderArray.push(item.name);
          return true;
        } else {
          return false;
        }
      });
      return {
        ...state,
        filteredColumns: newFilteredColumns,
        loading: false,
        error: undefined,
        isError: false,
        errorMessage: undefined,
      };
    case actionTypes.UPDATE_FILTERED_COLUMNS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error.error,
        errorMessage: action.payload,
        isError: true,
      };
    case actionTypes.RESET_UPDATE_FILTERED_COLUMNS_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.RESET_UPDATE_FILTERED_COLUMNS_SUCCESS:
      return {
        ...state,
        loading: false,
        filteredColumns: [],
      };
    case actionTypes.RESET_UPDATE_FILTERED_COLUMNS_ERROR:
      return {
        ...state,
        errorMessage: JSON.stringify(action.payload),
        isError: true,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
