import { lambdaApi } from '../api';

const transformerGet = (apiResponse) => {
  const result = {
    appKey: apiResponse?.data?.app_key,
    startDate: apiResponse?.data?.start_date,
    endDate: apiResponse?.data?.end_date,
    isActive: apiResponse?.data?.is_active,
    requestDeleteAt: apiResponse?.data?.request_delete_at,
  };
  return result;
};

// POST - This endpoint Will trigger process to sync bases between stage, production and development, also create a token access.
export const postToken = async ({ clientId, type }) => {
  const config = {
    client_id: clientId,
    type,
  };
  const response = await lambdaApi().post('/client/sync/basis', config);
  // return transformer(response.data);
  return response.data;
};

// DELETE - This endpoint Will trigger process to sync bases between stage, production and development, also inactivate a token access.
export const deleteToken = async ({ type, clientId }) => {
  const config = {
    params: {
      type,
      client_id: clientId,
    },
  };
  const response = await lambdaApi().delete('/client/sync/basis', config);
  // return transformer(response.data);
  return response.data;
};

// GET - This endpoint return the token access, by default the token string is hidden from 6th position.
export const getToken = async ({ type, clientId, hidden }) => {
  const config = {
    params: {
      type,
      client_id: clientId,
      hidden,
    },
  };
  const response = await lambdaApi().get('client/sync/basis', config);
  return transformerGet(response.data);
};

// PUT - This endpoint will generate new token, only works if token app already exist
export const putToken = async ({ type, clientId }) => {
  const config = {
    type,
    client_id: clientId,
  };
  const response = await lambdaApi().put('/client/sync/basis', config);
  // return transformer(response.data);
  return response.data;
};
