import { action } from 'typesafe-actions';
import { actionTypes } from './types';

export const getClientStart = (data) =>
  action(actionTypes.GET_CLIENT_START, { data });
export const getClientSuccess = (data) =>
  action(actionTypes.GET_CLIENT_SUCCESS, data);
export const getClientError = (errorMessage, error) =>
  action(actionTypes.GET_CLIENT_ERROR, { errorMessage }, undefined, { error });

export const postClientStart = (data) =>
  action(actionTypes.POST_CLIENT_START, data);
export const postClientSuccess = (data) =>
  action(actionTypes.POST_CLIENT_SUCCESS, data);
export const postClientError = (errorMessage, error) =>
  action(actionTypes.POST_CLIENT_ERROR, { errorMessage }, undefined, { error });

export const fileIdentificationStart = (data) =>
  action(actionTypes.FILE_IDENTIFICATION_START, data);
export const fileIdentificationSuccess = (data) =>
  action(actionTypes.FILE_IDENTIFICATION_SUCCESS, data);
export const fileIdentificationError = (errorMessage, error) =>
  action(actionTypes.FILE_IDENTIFICATION_ERROR, { errorMessage }, undefined, {
    error,
  });

export const putClientStart = (data) =>
  action(actionTypes.PUT_CLIENT_START, data);
export const putClientSuccess = (data) =>
  action(actionTypes.PUT_CLIENT_SUCCESS, data);
export const putClientError = (errorMessage, error) =>
  action(actionTypes.PUT_CLIENT_ERROR, { errorMessage }, undefined, { error });
