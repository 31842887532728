import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import { ContentTabs } from 'components/Intermediate/AmountsReceivable/components';
import { Filters } from 'commons/Intermediate/Filters';
import { amountsReceivableSummaryStart } from 'store/ducks/amountsReceivableSummary/actions';
import { amountsReceivableInfoStart } from 'store/ducks/amountsReceivableInfo/actions';
import { useDispatch, useSelector } from 'react-redux';
import { amountsReceivableInfoFiltersStart } from 'store/ducks/filters/amountsReceivableInfoFilters/actions';
import { updateScreenToScreenDateStart } from 'store/ducks/updateScreenToScreenDate/actions';
import { useResetFilters } from 'utils/hooks/useResetFilters';
import { updateAmountsReceivableDetailsFilters } from 'store/ducks/filters/details/amountsReceivableDetailsFilters/actions';
import { onSorter } from 'helpers/sorter';
import dayjs from 'dayjs';
import { eighteenMonthsAheadOfCurrentDayPlusOne } from 'helpers/dates';

const AmountsReceivableIntermediate = () => {
  const dispatch = useDispatch();

  const startDate = useSelector(
    (state) => state.updateScreenToScreenDate.forecastStartDate
  );
  const endDate = useSelector(
    (state) => state.updateScreenToScreenDate.forecastEndDate
  );

  const [sort, setSort] = useState('DESC');
  const [filterName, setFilterName] = useState(null);
  const [filtersDrawer, toggleFiltersDrawer] = useState(false);
  const [activeTab, setActiveTab] = useState('branches');

  const amountsReceivableSummaryNetValue = useSelector(
    (state) => state.amountsReceivableSummary.summary?.total_net_value
  );

  const amountsReceivableLoading = useSelector(
    (state) => state.amountsReceivableSummary.loading
  );

  const amountsReceivableIsError = useSelector(
    (state) => state.amountsReceivableSummary.isError
  );
  const amountsReceivableInfo = useSelector(
    (state) => state.amountsReceivableInfo.forecast
  );
  const amountsReceivableInfoLoading = useSelector(
    (state) => state.amountsReceivableInfo.loading
  );
  const amountsReceivableInfoError = useSelector(
    (state) => state.amountsReceivableInfo.error
  );

  const amountsReceivableInfoFilters = useSelector(
    (state) => state.amountsReceivableInfoFilters.filters
  );

  const [calendarIsOpen, setCalendarIsOpen] = useState(false);

  const onRangePickerChange = (dates) => {
    if (dates?.length) {
      dispatch(
        updateScreenToScreenDateStart({
          startDate: dates[0],
          endDate: dates[1],
        })
      );
    }
  };

  useEffect(() => {
    dispatch(
      amountsReceivableInfoStart({
        startDate,
        endDate,
        page: 1,
        type: activeTab,
        perPage: 100,
        sort,
        filter: filterName,
      })
    );
    dispatch(
      amountsReceivableInfoFiltersStart({
        startDate,
        endDate,
        type: activeTab,
        page: 1,
        perPage: 100,
      })
    );
    dispatch(amountsReceivableSummaryStart({ startDate, endDate }));
  }, [activeTab, dispatch, endDate, filterName, sort, startDate]);

  const onRangePickerOpenOrCloseChange = (open) => {
    setCalendarIsOpen(open);
  };

  const onChangeSelectFilter = (val) => {
    if (val === 'Todos' || val === 'Buscar' || val === '') {
      setFilterName(null);
    } else {
      setFilterName(val);
    }
  };

  const onTabClick = (key) => {
    setActiveTab(key);
    onChangeSelectFilter(null);
  };

  const disabledDate = (current) => {
    return (
      (current && current < dayjs().subtract(1, 'day')) ||
      (current && current > eighteenMonthsAheadOfCurrentDayPlusOne)
    );
  };

  const { resetFilters } = useResetFilters();

  useEffect(() => {
    resetFilters(updateAmountsReceivableDetailsFilters);
  }, [resetFilters]);

  return (
    <>
      <Filters
        title="Valores a receber"
        subtitle="Valores previstos a receber no período"
        onRangePickerOpenOrCloseChange={onRangePickerOpenOrCloseChange}
        hasRangePicker
        hasSelect
        hasSorter
        dateDefaultValue={[startDate, endDate]}
        onRangePickerChange={onRangePickerChange}
        onChangeSelectFilter={onChangeSelectFilter}
        loading={false}
        sort={sort}
        sorter={onSorter(sort, setSort)}
        filtersDrawer={filtersDrawer}
        filterName={filterName}
        renderFilterButton={() => (
          <Button
            style={{ borderRadius: 4 }}
            onClick={() => toggleFiltersDrawer(!filtersDrawer)}
          >
            Filtros
          </Button>
        )}
        onCloseFiltersDrawer={() => toggleFiltersDrawer(false)}
        sorterButtonText="Total a receber"
        infoFiltersToSelect={amountsReceivableInfoFilters}
        activeTab={activeTab}
        disabledDate={disabledDate}
        calendarIsOpen={calendarIsOpen}
        setCalendarIsOpen={setCalendarIsOpen}
      />
      <ContentTabs
        startDate={startDate}
        endDate={endDate}
        summary={{
          value: amountsReceivableSummaryNetValue,
        }}
        infoData={amountsReceivableInfo}
        infoLoading={amountsReceivableInfoLoading}
        isInfoError={amountsReceivableInfoError}
        onTabClick={onTabClick}
        activeTab={activeTab}
        infoFilter={filterName}
        amountsReceivableLoading={amountsReceivableLoading}
        amountsReceivableIsError={amountsReceivableIsError}
      />
    </>
  );
};

export default AmountsReceivableIntermediate;
