export const actionTypes = {
  LIST_OFX_PARAMETERS_START: '@ofxParameters/LIST_OFX_PARAMETERS_START',
  LIST_OFX_PARAMETERS_SUCCESS: '@ofxParameters/LIST_OFX_PARAMETERS_SUCCESS',
  LIST_OFX_PARAMETERS_ERROR: '@ofxParameters/LIST_OFX_PARAMETERS_ERROR',
  LIST_OFX_PARAMETERS_ALTER: '@ofxParameters/LIST_OFX_PARAMETERS_ALTER',
  ADD_OFX_PARAMETER_START: '@ofxParameters/ADD_OFX_PARAMETER_START',
  ADD_OFX_PARAMETER_SUCCESS: '@ofxParameters/ADD_OFX_PARAMETER_SUCCESS',
  ADD_OFX_PARAMETER_ERROR: '@ofxParameters/ADD_OFX_PARAMETER_ERROR',
  EDIT_OFX_PARAMETER_START: '@ofxParameters/EDIT_OFX_PARAMETER_START',
  EDIT_OFX_PARAMETER_SUCCESS: '@ofxParameters/EDIT_OFX_PARAMETER_SUCCESS',
  EDIT_OFX_PARAMETER_ERROR: '@ofxParameters/EDIT_OFX_PARAMETER_ERROR',
  REMOVE_OFX_PARAMETER_START: '@ofxParameters/REMOVE_OFX_PARAMETER_START',
  REMOVE_OFX_PARAMETER_SUCCESS: '@ofxParameters/REMOVE_OFX_PARAMETER_SUCCESS',
  REMOVE_OFX_PARAMETER_ERROR: '@ofxParameters/REMOVE_OFX_PARAMETER_ERROR',
};
