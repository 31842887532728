import styled from 'styled-components';
import { Row } from 'antd';
import { colors } from 'styles/colors';

export const StSpace = styled.div`
  background-color: ${(props) => (props?.$breakpoint?.lg ? '' : colors.gray1)};
  padding: ${(props) => (props?.$breakpoint?.lg ? '' : '2rem')};
  border-radius: 5px;
  max-width: 326px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  &&& {
    font-family: 'Roboto', sans-serif;

    /* font-style: normal; */
  }
`;

export const StRow = styled(Row)`
  /* background-color: ${colors.gray1};
  border-radius: 10px 0px 0px 10px;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  padding: 30px 30px 30px 30px; */
  height: 100%;
  background-color: ${colors.gray1};
  border-radius: ${(props) =>
    props?.$breakpoint?.lg ? '10px 0 0 10px' : '10px'};
`;

export const StContainer = styled.div`
  margin: 0 auto;
  height: 100%;
  max-width: 1360px;
  background-color: ${(props) =>
    props?.$breakpoint?.lg ? '#f4f4f4' : '#c7ddd0'};
`;
