import styled from 'styled-components';

import { Typography, Card } from 'antd';
import { colors } from 'styles/colors';

export const StShowCase = styled(Card)`
  &&& {
    border-radius: 4px;
  }
`;

export const StPartnerMsg = styled.div`
  &&& {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    border-radius: 2px;
    align-items: center;
    padding: 10px 0px 10px 10px;
    background: ${({ bgColor }) => bgColor};
  }
`;

export const StTextMsg = styled(Typography.Text)`
  &&& {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: ${colors.gray9};
    margin-left: 12px;
  }
`;
