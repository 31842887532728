import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { permissionsStart } from 'store/ducks/permissions/actions';
import {
  setSessionClientCreatedAt,
  setSessionClientPlanId,
  setSessionClientSkinId,
  setSessionFirstName,
  setSessionProfileId,
  setSessionScopeId,
  setSessionSurname,
} from 'helpers/sessionService';

export const usePermissions = () => {
  const dispatch = useDispatch();
  const permissions = useSelector((state) => state.permissions.data);
  const loading = useSelector((state) => state.permissions.loading);
  const permissionsSuccess = useSelector((state) => state.permissions.success);

  const fetchPermissions = () => {
    dispatch(permissionsStart());
  };

  useEffect(() => {
    if (permissionsSuccess) {
      setSessionClientCreatedAt(permissions?.created_at);
      setSessionClientPlanId(permissions?.plan_id);
      setSessionClientSkinId(permissions?.skin_id);
      setSessionScopeId(permissions?.scope_id);
      setSessionProfileId(permissions?.profile_id);
      setSessionFirstName(permissions?.user_first_name);
      setSessionSurname(permissions?.user_surname);
    }
    // eslint-disable-next-line
  }, [permissions]);

  return {
    loading,
    permissions,
    fetchPermissions,
  };
};
