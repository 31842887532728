import { Button } from 'antd';
import styled from 'styled-components';
import { colors } from 'styles/colors';

const assetsBaseURL = process.env.REACT_APP_AWS_V4_ASSETS;
const shineOutlinedWhite = `${assetsBaseURL}/svg/shineOutlinedWhite.svg`;

export const PriceCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${colors.gray1};
  padding: 24px;
  border-radius: 4px;
  border: 1px solid
    ${({ currentPlan, color }) => (currentPlan ? color : 'none')};
  border-top: 8px solid ${({ color }) => color};
  position: relative;
  ${({ mostChosen, currentPlan, color }) =>
    mostChosen &&
    `::after {
        content: url(${shineOutlinedWhite}) " O mais escolhido";
        position: absolute;
        top: -42px;
        left: ${currentPlan ? '-1px' : '0px'};
        width: 310px;
        background-color: ${color};
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        border-radius: 4px 4px 0 0;
        padding: 10px;
        font-weight: 500;

        path {
          fill: red !important;
        }
      }
    `};
`;

export const PlanNameContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;

export const PlanName = styled.h2`
  font-size: 20px;
  margin: 0 0 0 5px;
  color: ${({ color }) => color};
`;

export const PlanDescription = styled.p`
  margin: 20px 0;
  min-height: 100px;
  font-size: 14px;
  text-align: left;
  color: ${colors.gray8};
  word-break: normal;
`;

export const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 56px;
  min-height: 56px;
  margin: 24px 0;
`;

export const PriceTag = styled.span`
  font-size: 14px;
  min-height: 22px;
  word-break: normal;
  color: ${colors.gray9};
`;

export const Price = styled.span`
  font-size: 24px;
  word-break: normal;
  color: ${colors.gray9};
  font-weight: 500;
`;

export const StButton = styled(Button)`
  width: 100%;

  &&&:disabled {
    background-color: ${colors.gray1};
    color: ${colors.primary6};
    font-size: 14px;
    font-weight: 500;
    cursor: default;
  }
`;

export const Line = styled.hr`
  margin: 30px 0;
  width: 100%;
  background-color: ${colors.gray5};
  height: 1px;
  border: 0;
`;

export const Features = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`;

export const FeatureIcon = styled.div`
  margin-top: 0;
  color: ${colors.primary6};
`;

export const FeatureDescription = styled.p`
  margin-left: 5px;
  word-break: normal;
  font-weight: ${({ bold }) => bold && 500};
`;
