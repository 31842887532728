import { action } from 'typesafe-actions';
import { actionTypes } from './types';

export const reconciledPaymentsDetailsFiltersStart = (data) =>
  action(actionTypes.RECONCILED_PAYMENTS_DETAILS_FILTERS_START, data);
export const reconciledPaymentsDetailsFiltersSuccess = (data) =>
  action(actionTypes.RECONCILED_PAYMENTS_DETAILS_FILTERS_SUCCESS, data);
export const reconciledPaymentsDetailsFiltersError = (errorMessage, error) =>
  action(
    actionTypes.RECONCILED_PAYMENTS_DETAILS_FILTERS_ERROR,
    { errorMessage },
    undefined,
    { error }
  );
export const reconciledPaymentsDetailsFiltersZeroLength = () =>
  action(actionTypes.RECONCILED_PAYMENTS_DETAILS_FILTERS_ZERO_LENGTH);
export const updateReconciledPaymentsDetailsFilters = (data) =>
  action(actionTypes.UPDATE_RECONCILED_PAYMENTS_DETAILS_FILTERS, data);
