import React from 'react';

import PropTypes from 'prop-types';

import { Row, Card, Col, Typography } from 'antd';
import {
  ArrowRightOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  OrderedListOutlined,
} from '@ant-design/icons';
import { colors } from 'styles/colors';

const CardButton = ({
  type,
  onClickAction,
  isSelected,
  mainColor,
  value,
  description,
}) => {
  const renderIcon = () => {
    let returnedData;

    if (type === 'ALL') {
      returnedData = (
        <OrderedListOutlined
          style={{ textAlign: 'center', color: mainColor, width: '15px' }}
        />
      );
    } else if (type === 'IDENTIFIED') {
      returnedData = (
        <CheckCircleOutlined
          style={{ textAlign: 'center', color: mainColor, width: '15px' }}
        />
      );
    } else if (type === 'IGNORED') {
      returnedData = (
        <CloseCircleOutlined
          style={{ textAlign: 'center', color: mainColor, width: '15px' }}
        />
      );
    } else {
      returnedData = (
        <ExclamationCircleOutlined
          style={{ textAlign: 'center', color: mainColor, width: '15px' }}
        />
      );
    }

    return returnedData;
  };

  return (
    <Card
      style={{ width: 300, cursor: 'pointer' }}
      onClick={onClickAction}
      bordered={false}
      bodyStyle={{
        borderStyle: 'solid',
        borderRadius: 5,
        borderWidth: 1,
        borderColor: isSelected ? mainColor : colors.gray4,
        boxShadow: '0px 0px 6px #00000052',
      }}
    >
      <Row>
        <Col
          lg={4}
          style={{
            display: 'flex',
            textAlign: 'center',
            alignItems: 'center',
            fontSize: '23px',
          }}
        >
          {renderIcon()}
        </Col>
        <Col lg={18}>
          <Row>
            <Typography.Text style={{ fontSize: 22, fontWeight: 'bold' }}>
              {value}
            </Typography.Text>
          </Row>
          <Row>
            <Typography.Text style={{ fontSize: 14 }}>
              {description}
            </Typography.Text>
          </Row>
        </Col>
        <Col
          lg={2}
          style={{
            display: 'flex',
            textAlign: 'center',
            alignItems: 'center',
            fontSize: '23px',
          }}
        >
          <ArrowRightOutlined
            style={{ color: isSelected ? mainColor : colors.gray11 }}
          />
        </Col>
      </Row>
    </Card>
  );
};

CardButton.defaultProps = {};
CardButton.propTypes = {
  type: PropTypes.string.isRequired,
  onClickAction: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  mainColor: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
};

export default CardButton;
