import { useCallback, useEffect, useState } from 'react';
import { useDebounce } from 'utils/hooks/useDebounce';
import { getRadarClients } from 'services/radar/getRadarClients';
import errorHandler from 'helpers/errorHandler';

export const useRadarClientsList = (searchFilter, page, perPage = 50) => {
  const queryWithDebounce = useDebounce(searchFilter, 500);
  const [clients, setClients] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [hasMoreClients, setHasMoreClients] = useState(false);
  const [clientsData, setClientsData] = useState([]);

  const clientsParser = (listOfClients = []) => {
    const clientsParsed = listOfClients.map(
      ({ client_id: value, client_code_short_name: label }) => ({
        value,
        label,
      })
    );
    return clientsParsed.filter(({ label }) => label);
  };

  const fetchClients = useCallback(() => {
    setLoading(true);
    return getRadarClients({
      filter: queryWithDebounce,
      page,
      perPage,
    })
      .then(({ clients: data = [], meta = {} }) => {
        setClientsData(clientsParser(data));
        setHasMoreClients(meta?.has_next);
      })
      .catch((error) => {
        const errorMessage =
          error?.response?.data?.error?.message ||
          'Ocorreu um erro ao buscar os PFJ, por favor, tente novamente';
        return errorHandler(errorMessage, error);
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, [page, queryWithDebounce]);

  useEffect(() => {
    setClients([]);
    fetchClients();
    // eslint-disable-next-line
  }, [queryWithDebounce]);

  useEffect(() => {
    if (page > 1) {
      fetchClients();
    }
    // eslint-disable-next-line
  }, [page]);

  useEffect(() => {
    setClients((prev) => {
      return [...new Set([...prev, ...clientsData])];
    });
  }, [clientsData]);

  return {
    isLoading,
    clients,
    hasMoreClients,
  };
};
