import styled, { css } from 'styled-components';
import { Input, Spin, Typography } from 'antd';
import {
  CloseCircleFilled,
  DownOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { colors } from 'styles/colors';

export const CustomInput = styled(Input)`
  &&& {
    position: relative;
    width: 100%;
  }
`;

export const Overlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 3;
`;

export const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: ${colors.gray1};
  top: ${({ labelText, isOpenToTop }) =>
    !labelText && !isOpenToTop
      ? '35px'
      : !labelText && isOpenToTop
      ? 'auto'
      : '60px'};

  bottom: ${({ isOpenToTop }) => (isOpenToTop ? '35px' : 'auto')};

  left: 0px;
  width: 100%;
  max-height: 265px;
  overflow-y: auto;
  z-index: 4;
  padding: 4px 0px;
  border-radius: 2px;
  box-shadow:
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
`;

const customIconStyle = css`
  color: rgba(0, 0, 0, 0.25);
`;

export const CustomSearchOutlinedIcon = styled(SearchOutlined)`
  ${customIconStyle};
`;

export const CustomDownOutlinedIcon = styled(DownOutlined)`
  ${customIconStyle};
`;

export const CustomCloseCircleFilledIcon = styled(CloseCircleFilled)`
  ${customIconStyle};
  z-index: 5;
`;

export const OptionContainer = styled.div``;

export const Option = styled.span`
  padding: 5px 12px;
  color: ${colors.gray11};
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  transition: background 0.3s ease;

  &:hover {
    background-color: ${colors.gray3};
  }
`;

export const CustomSpin = styled(Spin)`
  line-height: 1;
`;

export const StTypographyTextLabel = styled(Typography.Text)`
  &&& {
    font-size: ${({ fontSize }) => fontSize};
    color: ${({ labelColor }) => labelColor};
  }
`;
