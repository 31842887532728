import {
  currentDay,
  currentDayMinusOne,
  currentDayPlusOne,
  eighteenMonthsAheadOfCurrentDayPlusOne,
  oneYearAheadOfCurrentDay,
  startOfCurrentMonth,
  startOfPreviousMonth,
  startOfPreviousYear,
  thirtyDaysAgo,
} from 'helpers/dates';
import { actionTypes } from 'store/ducks/updateScreenToScreenDate/types';

const INITIAL_STATE = {
  error: undefined,
  errorMessage: undefined,
  loading: true,
  isError: false,
  startDate: startOfCurrentMonth,
  endDate: currentDayMinusOne,
  forecastStartDate: currentDayPlusOne,
  forecastEndDate: eighteenMonthsAheadOfCurrentDayPlusOne,
  // Added in order to ease development
  reconciliationStartDate:
    process.env.REACT_APP_BUILD_ENV === 'development'
      ? startOfPreviousYear
      : thirtyDaysAgo,
  reconciliationEndDate: currentDay,
  dateHasBeenUpdated: false,
};
// console.log(
//   'data venda inicial state: ' +
//     INITIAL_STATE.startDate.toDate('dd/mm/yyyy') +
//     INITIAL_STATE.startDate.toDate('dd/mm/yyyy')
// );

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_SCREEN_TO_SCREEN_DATE_START:
      return {
        ...state,
        loading: true,
        error: undefined,
        errorMessage: undefined,
        isError: false,
        dateHasBeenUpdated: false,
      };
    case actionTypes.UPDATE_SCREEN_TO_SCREEN_DATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        isError: false,
        startDate: action.payload?.startDate,
        endDate: action.payload?.endDate,
        forecastStartDate: action.payload?.startDate,
        forecastEndDate: action.payload?.endDate,
        reconciliationStartDate: action.payload?.startDate,
        reconciliationEndDate: action.payload?.endDate,
        dateHasBeenUpdated: true,
        errorMessage: undefined,
      };
    case actionTypes.UPDATE_SCREEN_TO_SCREEN_DATE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error.error,
        errorMessage: action.payload,
        startDate: thirtyDaysAgo,
        endDate: currentDay,
        forecastStartDate: currentDay,
        forecastEndDate: oneYearAheadOfCurrentDay,
        reconciliationStartDate: startOfPreviousMonth,
        reconciliationEndDate: currentDay,
        dateHasBeenUpdated: false,
        isError: true,
      };
    default:
      return state;
  }
};

export default reducer;
