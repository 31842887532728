import { action } from 'typesafe-actions';
import { actionTypes } from './types';

export const deleteFeesStart = (data) => action(actionTypes.DELETE_FEES_START, { data });
export const deleteFeesSuccess = (data) => action(actionTypes.DELETE_FEES_SUCCESS, data);
export const deleteFeesError = (errorMessage, error) =>
  action(actionTypes.DELETE_FEES_ERROR, { errorMessage }, undefined, { error });

export const putFeesStart = (data) => action(actionTypes.PUT_FEES_START, { data });
export const putFeesSuccess = (data) => action(actionTypes.PUT_FEES_SUCCESS, data);
export const putFeesError = (errorMessage, error) =>
  action(actionTypes.PUT_FEES_ERROR, { errorMessage }, undefined, { error });

export const listFeesVencidasStart = (data) => action(actionTypes.LIST_FEES_VENCIDAS_START, data);
export const listFeesVencidasAlter = (data) => action(actionTypes.LIST_FEES_VENCIDAS_ALTER, data);

export const listFeesVencidasSuccess = (data) => action(actionTypes.LIST_FEES_VENCIDAS_SUCCESS, data);
export const listFeesVencidasError = (errorMessage, error) =>
  action(actionTypes.LIST_FEES_VENCIDAS_ERROR, { errorMessage }, undefined, { error });

export const listFeesVigenteStart = (data) => action(actionTypes.LIST_FEES_VIGENTES_START, data);
export const listFeesVigenteAlter = (data) => action(actionTypes.LIST_FEES_VIGENTES_ALTER, data);
export const listFeesVigenteSuccess = (data) => action(actionTypes.LIST_FEES_VIGENTES_SUCCESS, data);
export const listFeesVigenteError = (errorMessage, error) =>
  action(actionTypes.LIST_FEES_VIGENTES_ERROR, { errorMessage }, undefined, { error });
