import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Grid, Row, Col, Card } from 'antd';
import { ErrorCard } from 'commons/ErrorCard';

import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import { StTable } from 'commons/Table/styled';
import { StContainer, StLimiter } from './styled';
import { TableHeader } from '../Table/components/TableHeader';
import { StTableContainer } from 'commons/Table/components/TableHeader/styled';
import ResizeableTitle from 'commons/Table/components/TableHeader/ResizableTitle';

const Tables = ({
  dataSource,
  toParamsFilters,
  columns,
  loading,
  onChange,
  pagination,
  error,
  defaultSelectedColumns,
  dropdownOption,
  setDropdownOption,
  notificationConfig,
  createNotificationAction,
}) => {
  const { useBreakpoint } = Grid;
  const breakpoint = useBreakpoint();
  const modelStatusRef = useRef(null);

  const selectedColumns = useSelector(
    (state) => state.reportsCustomColumns.columns
  );

  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const [updatedColumns, setUpdatedColumns] = useState(columns);

  useEffect(() => {
    modelStatusRef.current = updatedColumns;
  }, [updatedColumns]);

  const handleResize =
    (index) =>
    (e, { size }) => {
      const nextColumns = [...modelStatusRef.current];
      if (size.width > 120) {
        nextColumns[index] = {
          ...nextColumns[index],
          width: size.width,
        };
        setUpdatedColumns(nextColumns);
      }
    };

  useEffect(() => {
    forceUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource]);

  useEffect(() => {
    const _updatedColumns = selectedColumns.map((col, index) => ({
      ...col,
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: handleResize(index),
      }),
    }));
    setUpdatedColumns(_updatedColumns);
  }, [selectedColumns, toParamsFilters]);

  if (breakpoint?.md && error) {
    return (
      <Row style={{ justifyContent: 'center' }}>
        <Col lg={10} md={18}>
          <Card style={{ borderRadius: 4 }} loading={loading}>
            <ErrorCard
              title="Dados indisponíveis"
              reason="Não conseguimos buscar os dados, mas já estamos trabalhando para normalizar a situação."
              recommendation="Tente atualizar a página em alguns instantes."
            />
          </Card>
        </Col>
      </Row>
    );
  }

  if (breakpoint?.md) {
    return (
      <StTableContainer style={{ marginTop: 0 }}>
        <TableHeader
          columns={columns}
          defaultSelectedColumns={defaultSelectedColumns}
          createNotificationAction={createNotificationAction}
          notificationConfig={notificationConfig}
          dropdownOption={dropdownOption}
          setDropdownOption={setDropdownOption}
          pagination={pagination}
        />
        <StContainer>
          <StLimiter $breakpoint={breakpoint}>
            <StTable
              onChange={onChange}
              components={{
                header: {
                  cell: ResizeableTitle,
                },
              }}
              pagination={pagination}
              loading={loading}
              dataSource={dataSource}
              columns={updatedColumns}
              scroll={{
                x: '100%',
                y: window.innerHeight - 400,
              }}
              showSorterTooltip={false}
              disableSizeChanger={Boolean(pagination?.total < 10)}
            />
          </StLimiter>
        </StContainer>
      </StTableContainer>
    );
  }
  return '';
};

Tables.defaultProps = {
  pagination: {
    pageSize: 10,
    total: 1,
    current: 1,
    pageSizeOptions: ['10', '100', '200', '300', '400', '500', '1000'],
    showTotal: () => '',
  },
};

Tables.propTypes = {
  dataSource: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  loading: PropTypes.bool.isRequired,
  pagination: PropTypes.shape({
    pageSize: PropTypes.number,
    total: PropTypes.number,
    current: PropTypes.number,
  }),
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
  defaultSelectedColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
  dropdownOption: PropTypes.string.isRequired,
};

export default Tables;
