import { actionTypes } from './types';

const INITIAL_STATE = {
  error: undefined,
  errorMessage: undefined,
  loading: false,
  isError: false,
  uploadedLetterUrls: {},
  deletionData: {},
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case actionTypes.GET_UPLOADED_LETTER_URL_START:
    return {
      ...state,
      loading: true,
      error: undefined,
      errorMessage: undefined,
      isError: false,
    };
  case actionTypes.GET_UPLOADED_LETTER_URL_SUCCESS:
    return {
      ...state,
      loading: false,
      uploadedLetterUrls: { ...state.uploadedLetterUrls, ...action.payload },
    };
  case actionTypes.GET_UPLOADED_LETTER_URL_ERROR:
    return {
      ...state,
      loading: false,
      error: action.error.error,
      errorMessage: action.payload,
      isError: true,
    };
  case actionTypes.DELETE_UPLOADED_LETTER_URL_START:
    return {
      ...state,
      loading: true,
      error: undefined,
      errorMessage: undefined,
      isError: false,
    };
  case actionTypes.DELETE_UPLOADED_LETTER_URL_SUCCESS:
    return {
      ...state,
      loading: false,
      deletionData: action.payload,
    };
  case actionTypes.DELETE_UPLOADED_LETTER_URL_ERROR:
    return {
      ...state,
      loading: false,
      error: action.error.error,
      errorMessage: action.payload,
      isError: true,
    };
  default:
    return state;
  }
};

export default reducer;
