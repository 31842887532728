import { action } from 'typesafe-actions';
import { actionTypes } from './types';

export const listOfClientsFiltersStart = (data) =>
  action(actionTypes.LIST_OF_CLIENTS_FILTERS_START, data);
export const listOfClientsFiltersSuccess = (data) =>
  action(actionTypes.LIST_OF_CLIENTS_FILTERS_SUCCESS, data);
export const listOfClientsFiltersZeroLength = () =>
  action(actionTypes.LIST_OF_CLIENTS_FILTERS_ZERO_LENGTH);
export const listOfClientsFiltersError = (errorMessage, error) =>
  action(
    actionTypes.LIST_OF_CLIENTS_FILTERS_ERROR,
    { errorMessage },
    undefined,
    {
      error,
    }
  );
export const updatelistOfClientsFilters = (data) =>
  action(actionTypes.UPDATE_LIST_OF_CLIENTS_FILTERS, data);
