import { getSessionClientId } from 'helpers/sessionService';
import { bankingApi } from '../api';

const transformer = (apiResponse) => {
  return {
    data: apiResponse?.payload,
  };
};

const postPartnerOptoutRequest = async ({ creditPartnerId }) => {
  const config = {
    creditPartnerId,
    clientId: getSessionClientId(),
  };
  const response = await bankingApi().post('/credit/partner/optout', config);
  return transformer(response.data);
};

export default postPartnerOptoutRequest;
