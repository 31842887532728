/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-undef */
/* eslint-disable react/no-danger */
/* eslint-disable no-unused-expressions */
import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { HeaderRegisters } from '../../../../commons/HeaderRegisters';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Row, Spin, Tabs, Typography } from 'antd';
import {
  CheckCircleTwoTone,
  DeleteOutlined,
  DownloadOutlined,
  ExclamationCircleTwoTone,
  UploadOutlined,
} from '@ant-design/icons';
import {
  StImagePreview,
  StDragger,
  StDownloadLetterButton,
  StTabs,
} from './styled';
import { getClientAcquirerStart } from '../../../../store/ducks/clientAcquirer/actions';
import {
  downloadClientAcquirerLetter,
  uploadClientAcquirerLetterUrl,
} from '../../../../services/clientAcquirers';
import axios from 'axios';
import errorHandler from '../../../../helpers/errorHandler';
import { Loadable } from '../../../../commons/Loadable';
import { toTitleCase } from '../../../../helpers/generalService';
import { BriefingOptinStone } from './BriefingOptinStone';
import { BriefingOptinRede } from './BriefingOptinRede';
import {
  deleteUploadedLetterUrlStart,
  getUploadedLetterUrlStart,
} from 'store/ducks/uploadedLetterUrl/actions';
import successHandler from 'helpers/successHandler';
import { StBriefingTab } from 'components/Briefing/styled';
import { getSessionClientId } from 'helpers/sessionService';
import { colors } from 'styles/colors';
import { GetnetLetterInstruction } from './GetnetLetterInstruction';
import { BriefingOptinCielo } from './BriefingOptinCielo';

let acquirers;

// eslint-disable-next-line no-unused-vars
const BriefingLettersAndOptins = forwardRef((props, ref) => {
  const { TabPane } = Tabs;
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.clientAcquirer.loading);
  // const acquirers = useSelector((state) => state.acquirer.acquirers);
  const clientAcquirers = useSelector(
    (state) => state.clientAcquirer.clientAcquirers
  );
  const uploadedLetterUrls = useSelector(
    (state) => state.uploadedLetterUrl.uploadedLetterUrls
  );
  const deletionData = useSelector(
    (state) => state.uploadedLetterUrl.deletionData
  );
  const uploadedLetterUrlsLoading = useSelector(
    (state) => state.uploadedLetterUrl.loading
  );
  const clientId = getSessionClientId();
  const [isDownloadingLetter, setIsDownloadingLetter] = useState(false);
  const [isLoadingUpload, setIsLoadingUpload] = useState('none');
  const [isWatingUpload, setIsWaitingUpload] = useState('block');
  // eslint-disable-next-line no-unused-vars
  const [uploadUrl, setUploadUrl] = useState('');
  const [isAcquirerOk, setIsAcquirerOk] = useState({});
  const [fileList, setFileList] = useState({});
  const { isLocked, isCNPJMaintenance } = props;

  const loadingAcquirers = useSelector((state) => state.acquirer.loading);
  const loadingStores = useSelector((state) => state.store.loading);
  const optinDataLoading = useSelector((state) => state.optin?.loading);
  // eslint-disable-next-line no-unused-vars
  const [currentAcquirer, setCurrentAcquirer] = useState(undefined);

  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  let s3UploadUrl;
  // eslint-disable-next-line no-unused-vars
  let currentAcquirerId;
  // eslint-disable-next-line no-unused-vars
  let finalFileUrl;

  const loadClientAcquirers = () => {
    dispatch(getClientAcquirerStart({}));
  };

  useEffect(() => {
    loadClientAcquirers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // The useEffect below makes sure that the correct amount of tabs gets displayed
  // when adding/removing more acquirers/vouchers at previous pages.
  useEffect(() => {
    if (clientAcquirers && !acquirers) {
      acquirers = clientAcquirers?.acquirers;
    }
    acquirers = clientAcquirers?.acquirers;
  }, [clientAcquirers]);

  useEffect(() => {
    clientAcquirers?.acquirers?.forEach((acquirer) => {
      dispatch(
        getUploadedLetterUrlStart({
          acquirerName: acquirer?.name,
        })
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientAcquirers]);

  const beforeUploadFile = async (file, files, acquirerId, acquirerName) => {
    setIsWaitingUpload('none');
    setIsLoadingUpload('block');
    currentAcquirerId = acquirerId;

    return uploadClientAcquirerLetterUrl({
      data: {
        acquirer_id: acquirerId,
        client_id: clientId,
        filename: file.name,
        mimetype: file.type,
        extension: file.name.split('.').pop(),
        size: file.size,
      },
    })
      .then(async (response) => {
        if (response.code === 200) {
          s3UploadUrl = response.uploadUrl;
          setUploadUrl(s3UploadUrl);

          await axios
            .put(s3UploadUrl, file, {
              headers: {
                'Content-Type': file.type,
              },
            })
            .then((_response) => {
              successHandler(
                `Upload do arquivo ${_response?.config?.data?.name} realizado com sucesso!`
              );
              setTimeout(() => {
                dispatch(
                  getUploadedLetterUrlStart({
                    acquirerName,
                  })
                );
              }, 1000);
            });
        } else {
          errorHandler(
            'Problemas no envio do arquivo, por favor, tente novamente'
          );
        }
        setIsLoadingUpload('none');
        setIsWaitingUpload('block');
      })
      .catch((err) => {
        errorHandler(err?.response?.data?.error?.message, err);
        setIsLoadingUpload('none');
        setIsWaitingUpload('block');
      });
  };

  const onClickDownloadLetter = (acquirer) => {
    setIsDownloadingLetter(true);

    downloadClientAcquirerLetter({
      client_id: clientId,
      acquirer_id: acquirer.acquirer_id,
    })
      .then((response) => {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('display', 'none');
        a.href =
          'data:' +
          acquirer.letter_download_mimetype +
          ';base64,' +
          response.data;
        a.download = acquirer.letter_download_filename;
        a.click();
        setIsDownloadingLetter(false);
      })
      .catch((err) => {
        setIsDownloadingLetter(false);
        errorHandler('Ocorreu um erro no download da carta', err);
      });
  };

  const getUrlKeyByFilename = (acquirer, filename) => {
    let urlKey = '';
    if (uploadedLetterUrls) {
      const urls = uploadedLetterUrls[acquirer]?.urls;
      if (urls) {
        urls.forEach((url) => {
          if (url?.filename === filename) {
            urlKey = url?.urlKey;
          }
        });
      }
    }
    return urlKey;
  };

  useEffect(() => {
    const _isAcquirerOk = {};
    clientAcquirers?.acquirers?.forEach((acquirer) => {
      if (
        acquirer?.acquirer_id !== 1 && // Cielo
        acquirer?.acquirer_id !== 2 && // Rede
        acquirer?.acquirer_id !== 17 // Stone
      ) {
        _isAcquirerOk[acquirer?.acquirer_id] = false;
        if (uploadedLetterUrls[acquirer?.name]?.isImported) {
          _isAcquirerOk[acquirer?.acquirer_id] = true;
        } else if (
          !acquirer.is_letter_required &&
          !acquirer.is_optin_required
        ) {
          _isAcquirerOk[acquirer?.acquirer_id] = true;
        } else if (acquirer.is_letter_required) {
          if (
            (acquirer.is_letter_only_download_required &&
              acquirer.letter_was_downloaded) ||
            (!acquirer.is_letter_only_download_required &&
              acquirer.letter_s3_uploaded_file)
          ) {
            _isAcquirerOk[acquirer?.acquirer_id] = true;
          }
        }
      }
    });
    setIsAcquirerOk({
      ...isAcquirerOk,
      ..._isAcquirerOk,
    });
    if (uploadedLetterUrls) {
      /*
        Note for the future:
        The "temporaryFileList" variable below was created in an attempt to avoid rendering non-updated data to the screen. The previous code had setFileList updating fileList's state within the forEach, so I created this variable and just updated its value in the same structure as the original fileList state, and then AFTER the loop is over, I update fileList with its setFileList function passing this temporary variable as its new value.
      */
      let temporaryFileList = {};

      Object.keys(uploadedLetterUrls).forEach((key) => {
        const urls = uploadedLetterUrls[key]?.urls;
        const uploadedFileUrls = [];
        urls.forEach((url, i) => {
          uploadedFileUrls.push({
            uid: i,
            name: url?.filename,
            status: 'done',
            url: url?.urlDownload,
            thumbUrl:
              'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
          });
        });
        temporaryFileList = {
          ...temporaryFileList,
          [key]: uploadedFileUrls,
        };
      });

      setFileList(temporaryFileList);
    }
    forceUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedLetterUrls]);

  useEffect(() => {
    const acquirerName = Object.keys(deletionData)[0];
    if (acquirerName) {
      dispatch(
        getUploadedLetterUrlStart({
          acquirerName,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletionData]);

  useEffect(() => {
    const areAllLettersOk = Object.values(isAcquirerOk).every(
      (item) => item === true
    );
    props.setIsNextButtonLocked(true);
    if (areAllLettersOk) {
      props.setIsNextButtonLocked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAcquirerOk]);

  const renderAcquirerTabStatusIcon = (acquirer) => {
    if (isAcquirerOk[acquirer?.acquirer_id]) {
      return (
        <CheckCircleTwoTone
          twoToneColor={colors.green6}
          style={{ fontSize: '12.25px' }}
        />
      );
    }

    return (
      <>
        {optinDataLoading || uploadedLetterUrlsLoading ? (
          <Spin size="small" />
        ) : (
          <ExclamationCircleTwoTone
            twoToneColor={colors.gold6}
            style={{ fontSize: '12.25px' }}
          />
        )}
      </>
    );
  };

  const onChangeTab = (key) => {
    const newAcquirer = acquirers.find(
      // eslint-disable-next-line radix
      (a) => a.acquirer_id === parseInt(key, 0)
    );

    setCurrentAcquirer(newAcquirer);
  };

  const renderAcquirerTabStatusMessage = (acquirer) => {
    let isOk = false;
    let statusMessage = 'Com pendências';

    if (isAcquirerOk[acquirer?.acquirer_id]) {
      isOk = true;
    }

    if (isOk) {
      statusMessage = 'Sem pendências';
    }

    return (
      <Typography.Text
        style={{
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '22px',
          color: colors.gray7,
        }}
      >
        {statusMessage}
      </Typography.Text>
    );
  };

  return (
    <HeaderRegisters
      title="Cartas de autorização"
      subTitle="Verifique as cartas e siga as instruções de cada adquirente. A próxima etapa estará disponível apenas quando quando todas as cartas ou optins estiverem concluídos."
    >
      {/* The "loading" variable below (after loadingStores) makes sure that the tabs only appears when its value is false. This is useful so it displays the correct amount of tabs when adding/removing vouchers and going back to this page. */}
      <Loadable loading={loadingAcquirers || loadingStores || loading}>
        {isCNPJMaintenance ? (
          <StTabs type="card" onChange={onChangeTab}>
            {acquirers?.map((acquirer) => (
              <TabPane
                key={acquirer.acquirer_id}
                tab={
                  <StBriefingTab>
                    <Row
                      style={{
                        flexFlow: 'nowrap',
                        alignItems: 'center',
                        gap: 8,
                      }}
                    >
                      <Col>
                        <img
                          src={acquirer.acquirer_icon}
                          alt={`Ícone da ${toTitleCase(acquirer.name)}`}
                          height={16}
                          width={16}
                        />
                      </Col>
                      <Col>{toTitleCase(acquirer.name)}</Col>
                      <Col>{renderAcquirerTabStatusIcon(acquirer)}</Col>
                    </Row>
                  </StBriefingTab>
                }
                style={{
                  overflowX: 'hidden',
                  overflowY: 'auto',
                  maxHeight: '515px',
                }}
                forceRender
              >
                <Col span={24} style={{ margin: '16px 0' }}>
                  <Row
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      textAlign: 'center',
                      gap: 8,
                    }}
                  >
                    <img
                      src={acquirer.acquirer_icon}
                      alt={toTitleCase(acquirer.name)}
                      height={32}
                      width={32}
                    />

                    <Typography.Text
                      style={{
                        fontSize: '24px',
                        fontWeight: 500,
                        lineHeight: '32px',
                        color: colors.gray9,
                      }}
                    >
                      {toTitleCase(acquirer.name)}
                    </Typography.Text>
                    <Col style={{ marginLeft: 25 }}>
                      {renderAcquirerTabStatusIcon(acquirer)}
                    </Col>
                    <Col style={{ marginLeft: 3 }}>
                      {renderAcquirerTabStatusMessage(acquirer)}
                    </Col>
                  </Row>
                </Col>
                <div
                  style={{
                    maxHeight: '445px',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                  }}
                >
                  {acquirer.is_letter_required &&
                    !acquirer.is_letter_only_download_required && (
                      <Row
                        style={{
                          paddingTop: '16px',
                          paddingBottom: '16px',
                          overflowY: 'auto',
                        }}
                        gutter={[8, 0]}
                      >
                        <Col span={4}>
                          <StImagePreview src={acquirer.letter_preview} />
                        </Col>
                        <Col span={props.throughModal ? 13 : 14}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: acquirer.letter_instructions,
                            }}
                          />

                          <StDownloadLetterButton
                            disabled={isLocked}
                            icon={<DownloadOutlined />}
                            ghost
                            onClick={() => onClickDownloadLetter(acquirer)}
                            loading={isDownloadingLetter}
                          >
                            Baixar carta
                          </StDownloadLetterButton>
                        </Col>
                        <Col
                          span={props.throughModal ? 7 : 6}
                          style={{ paddingRight: '64px' }}
                        >
                          <StDragger
                            disabled={isLocked || uploadedLetterUrlsLoading}
                            multiple={false}
                            beforeUpload={(f, fL) =>
                              beforeUploadFile(
                                f,
                                fL,
                                acquirer?.acquirer_id,
                                acquirer?.name
                              )
                            }
                            fileList={fileList[acquirer?.name]}
                            listType="text"
                            showUploadList={{
                              showDownloadIcon: !uploadedLetterUrlsLoading,
                              showRemoveIcon: !uploadedLetterUrlsLoading,
                              removeIcon: (clickedFile) => {
                                const filename = clickedFile?.name;
                                return (
                                  <DeleteOutlined
                                    onClick={() => {
                                      const urlKey = getUrlKeyByFilename(
                                        acquirer?.name,
                                        filename
                                      );
                                      dispatch(
                                        deleteUploadedLetterUrlStart({
                                          acquirerName: acquirer?.name,
                                          urlKey: urlKey,
                                        })
                                      );
                                    }}
                                  />
                                );
                              },
                            }}
                          >
                            <p className="ant-upload-drag-icon">
                              {uploadedLetterUrlsLoading ? (
                                <Spin />
                              ) : (
                                <UploadOutlined
                                  id="iconUpload"
                                  style={{
                                    color: colors.gray7,
                                    display: isWatingUpload,
                                    fontSize: '32px',
                                  }}
                                />
                              )}

                              <Spin
                                id="loadUpload"
                                style={{
                                  display: isLoadingUpload,
                                  color: colors.gray7,
                                }}
                              />
                            </p>
                            <div
                              style={{
                                maxWidth: '200px',
                                margin: '0 auto',
                              }}
                            >
                              <p className="ant-upload-hint">
                                Arraste ou clique para importar a carta assinada
                              </p>
                            </div>
                          </StDragger>
                        </Col>
                      </Row>
                    )}
                  {acquirer.is_letter_required &&
                    acquirer.is_letter_only_download_required && (
                      <Row>
                        <Col span={4}>
                          <StImagePreview src={acquirer.letter_preview} />
                        </Col>
                        <Col span={20}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: acquirer.letter_instructions,
                            }}
                          />
                          <Button
                            disabled={isLocked}
                            type="primary"
                            shape="round"
                            icon={<DownloadOutlined />}
                            ghost
                            onClick={() => onClickDownloadLetter(acquirer)}
                          >
                            Baixar carta
                          </Button>
                        </Col>
                      </Row>
                    )}
                  {!acquirer.is_letter_required &&
                    !acquirer.is_optin_required &&
                    acquirer.acquirer_id !== 56 &&
                    !acquirer.is_letter_instruction_required && (
                      <Row>
                        <Col span={24}>
                          Tudo pronto!
                          <br />
                          Não é necessário uma carta de autorização para a{' '}
                          {acquirer.name}.
                          <br />
                          <br />
                          Agora vamos entrar em contato com a {acquirer.name} e
                          solicitar os dados necessários para dar sequência em
                          seu processo de implantação.
                        </Col>
                      </Row>
                    )}
                  {acquirer.is_letter_instruction_required &&
                    acquirer.acquirer_id !== 9 && (
                      <Row>
                        <Col span={24}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: acquirer.letter_instructions,
                            }}
                          />
                        </Col>
                      </Row>
                    )}
                  {acquirer.acquirer_id === 56 && (
                    <Row>
                      <Col span={24}>
                        Tudo pronto!
                        <br />
                        Não é necessário uma carta de autorização para a{' '}
                        {acquirer.name}.
                      </Col>
                    </Row>
                  )}
                  {acquirer.acquirer_id === 1 && acquirer.is_optin_required && (
                    <BriefingOptinCielo
                      isLocked={isLocked}
                      acquirer={acquirer}
                      isAcquirerOk={isAcquirerOk}
                      setIsAcquirerOk={setIsAcquirerOk}
                    />
                  )}
                  {acquirer.acquirer_id === 2 && (
                    <BriefingOptinRede
                      isLocked={isLocked}
                      acquirer={acquirer}
                      isAcquirerOk={isAcquirerOk}
                      setIsAcquirerOk={setIsAcquirerOk}
                    />
                  )}
                  {acquirer.acquirer_id === 17 && (
                    <BriefingOptinStone
                      isLocked={isLocked}
                      acquirer={acquirer}
                      isAcquirerOk={isAcquirerOk}
                      setIsAcquirerOk={setIsAcquirerOk}
                    />
                  )}

                  {acquirer.acquirer_id === 9 && <GetnetLetterInstruction />}
                </div>
              </TabPane>
            ))}
          </StTabs>
        ) : (
          <Tabs type="card" onChange={onChangeTab}>
            {acquirers?.map((acquirer) => (
              <TabPane
                key={acquirer.acquirer_id}
                tab={
                  <StBriefingTab>
                    <Row align="center" gutter={[8, 0]}>
                      <Col>
                        <img
                          src={acquirer.acquirer_icon}
                          alt=""
                          height={16}
                          width={16}
                          style={{ marginBottom: '6px' }}
                        />
                      </Col>
                      <Col>
                        <p style={{ marginBottom: '0px', marginRight: '8px' }}>
                          {toTitleCase(acquirer.name)}
                        </p>
                      </Col>
                      <Col>{renderAcquirerTabStatusIcon(acquirer)}</Col>
                    </Row>
                  </StBriefingTab>
                }
                style={{
                  overflowX: 'hidden',
                  overflowY: 'auto',
                  maxHeight: '300px',
                }}
                forceRender
              >
                {acquirer.is_letter_required &&
                  !acquirer.is_letter_only_download_required && (
                    <Row
                      style={{
                        paddingTop: '16px',
                        paddingBottom: '16px',
                        overflowY: 'auto',
                      }}
                    >
                      <Col span={4}>
                        <StImagePreview src={acquirer.letter_preview} />
                      </Col>
                      <Col span={props.throughModal ? 13 : 14}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: acquirer.letter_instructions,
                          }}
                        />

                        <StDownloadLetterButton
                          disabled={isLocked}
                          icon={<DownloadOutlined />}
                          ghost
                          onClick={() => onClickDownloadLetter(acquirer)}
                          loading={isDownloadingLetter}
                        >
                          Baixar carta
                        </StDownloadLetterButton>
                      </Col>
                      <Col
                        span={props.throughModal ? 7 : 6}
                        style={{ paddingRight: '64px' }}
                      >
                        <StDragger
                          disabled={isLocked || uploadedLetterUrlsLoading}
                          multiple={false}
                          beforeUpload={(f, fL) =>
                            beforeUploadFile(
                              f,
                              fL,
                              acquirer?.acquirer_id,
                              acquirer?.name
                            )
                          }
                          fileList={fileList[acquirer?.name]}
                          listType="text"
                          showUploadList={{
                            showDownloadIcon: !uploadedLetterUrlsLoading,
                            showRemoveIcon: !uploadedLetterUrlsLoading,
                            removeIcon: (clickedFile) => {
                              const filename = clickedFile?.name;
                              return (
                                <DeleteOutlined
                                  onClick={() => {
                                    const urlKey = getUrlKeyByFilename(
                                      acquirer?.name,
                                      filename
                                    );
                                    dispatch(
                                      deleteUploadedLetterUrlStart({
                                        acquirerName: acquirer?.name,
                                        urlKey: urlKey,
                                      })
                                    );
                                  }}
                                />
                              );
                            },
                          }}
                        >
                          <p className="ant-upload-drag-icon">
                            {uploadedLetterUrlsLoading ? (
                              <Spin />
                            ) : (
                              <UploadOutlined
                                id="iconUpload"
                                style={{
                                  color: colors.gray7,
                                  display: isWatingUpload,
                                  fontSize: '32px',
                                }}
                              />
                            )}

                            <Spin
                              id="loadUpload"
                              style={{
                                display: isLoadingUpload,
                                color: colors.gray7,
                              }}
                            />
                          </p>
                          <div
                            style={{
                              maxWidth: '200px',
                              margin: '0 auto',
                            }}
                          >
                            <p className="ant-upload-hint">
                              Arraste ou clique para importar a carta assinada
                            </p>
                          </div>
                        </StDragger>
                      </Col>
                    </Row>
                  )}
                {acquirer.is_letter_required &&
                  acquirer.is_letter_only_download_required && (
                    <Row>
                      <Col span={4}>
                        <StImagePreview src={acquirer.letter_preview} />
                      </Col>
                      <Col span={20}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: acquirer.letter_instructions,
                          }}
                        />
                        <Button
                          disabled={isLocked}
                          type="primary"
                          shape="round"
                          icon={<DownloadOutlined />}
                          ghost
                          onClick={() => onClickDownloadLetter(acquirer)}
                        >
                          Baixar carta
                        </Button>
                      </Col>
                    </Row>
                  )}
                {!acquirer.is_letter_required &&
                  !acquirer.is_optin_required &&
                  acquirer.acquirer_id !== 56 &&
                  !acquirer.is_letter_instruction_required && (
                    <Row>
                      <Col span={24}>
                        Tudo pronto!
                        <br />
                        Não é necessário uma carta de autorização para a{' '}
                        {acquirer.name}.
                        <br />
                        <br />
                        Agora vamos entrar em contato com a {acquirer.name} e
                        solicitar os dados necessários para dar sequência em seu
                        processo de implantação.
                      </Col>
                    </Row>
                  )}
                {acquirer.is_letter_instruction_required &&
                  acquirer.acquirer_id !== 9 && (
                    <Row>
                      <Col span={24}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: acquirer.letter_instructions,
                          }}
                        />
                      </Col>
                    </Row>
                  )}
                {acquirer.acquirer_id === 56 && (
                  <Row>
                    <Col span={24}>
                      Tudo pronto!
                      <br />
                      Não é necessário uma carta de autorização para a{' '}
                      {acquirer.name}.
                    </Col>
                  </Row>
                )}
                {acquirer.acquirer_id === 1 && acquirer.is_optin_required && (
                  <BriefingOptinCielo
                    isLocked={isLocked}
                    acquirer={acquirer}
                    isAcquirerOk={isAcquirerOk}
                    setIsAcquirerOk={setIsAcquirerOk}
                  />
                )}
                {acquirer.acquirer_id === 2 && (
                  <BriefingOptinRede
                    isLocked={isLocked}
                    acquirer={acquirer}
                    isAcquirerOk={isAcquirerOk}
                    setIsAcquirerOk={setIsAcquirerOk}
                  />
                )}
                {acquirer.acquirer_id === 17 && (
                  <BriefingOptinStone
                    isLocked={isLocked}
                    acquirer={acquirer}
                    isAcquirerOk={isAcquirerOk}
                    setIsAcquirerOk={setIsAcquirerOk}
                  />
                )}
                {acquirer.acquirer_id === 9 && <GetnetLetterInstruction />}
              </TabPane>
            ))}
          </Tabs>
        )}
      </Loadable>
    </HeaderRegisters>
  );
});

export default BriefingLettersAndOptins;
