/* eslint-disable no-plusplus */
import { lambdaApi } from 'services/api';

const transform = ({ data, meta }, columnName) => {
  const filters = {
    results: [
      {
        text: columnName,
        values: data,
      },
    ],
    hasMore: meta?.has_next,
    offset: meta?.offset,
    limit: meta?.limit,
    column: meta?.column,
  };
  return filters;
};

const getOfxParametersFiltersFromAPI = async ({
  columnName,
  offset,
  q,
  filterBy,
  searchBy,
}) => {
  const config = {
    params: {
      column: columnName,
      limit: 10,
      offset,
      filter_by: filterBy,
      search_by: searchBy,
      q: JSON.stringify(q),
    },
  };
  const response = await lambdaApi().get('/ofx/rules/filters', config);
  const transformedResponse = transform(response?.data, columnName);
  return transformedResponse;
};

export default getOfxParametersFiltersFromAPI;
