import styled from 'styled-components';
import { Row } from 'antd';

export const StContainer = styled.div`
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props?.$breakpoint?.lg ? '#f4f4f4' : '#c7ddd0'};
`;

export const StRow = styled(Row)`
  border-radius: 10px;
  justify-content: center;
`;

export const StTitleRow = styled(Row)`
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 16px;
  font-weight: 500;

  &&& {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
  }
`;
