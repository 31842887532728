import { api } from './api';

const removeDuplicateAcquirers = (response) => {
  const filteredResponse = { code: response?.code };
  const filteredAcquirers = [
    ...new Map(
      response?.acquirers.map((item) => [
        ((_item) => _item?.acquirer_id)(item),
        item,
      ])
    ).values(),
  ];
  return { ...filteredResponse, acquirers: filteredAcquirers };
};

export const getClientAcquirer = async () => {
  const config = {
    params: {
      client_id: localStorage.getItem('concil-card-clientId'),
    },
  };
  const response = await api().get('/client_acquirer', config);
  return removeDuplicateAcquirers(response.data);
};

export const postClientAcquirer = async (payload) => {
  const response = await api().post('/client_acquirer', payload);
  return response.data;
};

export const deleteClientAcquirer = async (payload) => {
  const response = await api().delete('/client_acquirer/delete', payload);
  return response.data;
};

export const downloadClientAcquirerLetter = (payload) => {
  return api().post('/client_acquirer/letter/download', payload);
};

export const uploadClientAcquirerLetter = (payload) => {
  return api().post('/client_acquirer/letter/upload', payload);
};

export const uploadClientAcquirerLetterUrl = async ({ data }) => {
  const formData = new FormData();
  formData.append('client_id', data.client_id);
  formData.append('acquirer_id', data.acquirer_id);
  formData.append('filename', data.filename);
  formData.append('extension', data.extension);
  formData.append('mimetype', data.mimetype);
  formData.append('size', data.size);

  // Get S3 Presigned URL to Upload File/Post
  const response = await api().post(
    '/client_acquirer/letter/upload/url',
    formData
  );
  return response.data.data;
};
