import { actionTypes } from 'store/ducks/credit/sharedPartnerData/types';

const INITIAL_STATE = {
  error: undefined,
  errorMessage: undefined,
  loading: false,
  isError: false,
  data: {},
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case actionTypes.UPDATE_SHARED_PARTNER_DATA_START:
    return {
      ...state,
      loading: true,
      error: undefined,
      errorMessage: undefined,
      isError: false,
    };
  case actionTypes.UPDATE_SHARED_PARTNER_DATA_SUCCESS:
    return {
      ...state,
      loading: false,
      error: undefined,
      data: action.payload,
      isError: false,
      errorMessage: undefined,
    };
  case actionTypes.UPDATE_SHARED_PARTNER_DATA_ERROR:
    return {
      ...state,
      loading: false,
      error: action.error.error,
      errorMessage: action.payload,
      isError: true,
    };
  default:
    return state;
  }
};

export default reducer;
