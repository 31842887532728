/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Button, Typography, notification, Form, Row, Col } from 'antd';
import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';

import { recoveryPasswordStart } from 'store/ducks/recoveryPassword/actions';

import {
  StAlignContent,
  StCreatePasswordTitle,
  StCustomLi,
  StCustomUl,
  StErrorCol,
  StLiContainer,
  StPasswordTextInfo,
  StTitleRow,
} from './styled';
import {
  hasLowercase,
  hasOneNumber,
  hasSpecialChar,
  hasUppercase,
} from './utils';
import OtpInput from 'react-otp-input';
import { forgotPasswordRequest } from 'store/ducks/forgotPassword/actions';
import { colors } from 'styles/colors';
import { PasswordChanged } from '../PasswordChanged';

const useQuery = () => new URLSearchParams(useLocation().search);
const PasswordForm = ({
  passwordWasChanged,
  setPasswordWasChanged,
  isTokenPasswordScreen,
  setIsTokenPasswordScreen,
}) => {
  const query = useQuery();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});

  // To disable submit button at the beginning.
  useEffect(() => {
    forceUpdate({});
  }, []);

  const [passwordInput, setPasswordInput] = useState('');
  const [confirmPasswordInput, setConfirmPasswordInput] = useState('');
  const [confirmationCodeInput, setConfirmationCodeInput] = useState(
    query.get('code_parameter') || ''
  );
  const [currentPasswordStatus, setCurrentPasswordStatus] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [currentConfirmationCodeStatus, setCurrentConfirmationCodeStatus] =
    useState('');

  const [email, setEmail] = useState(query.get('username') || '');
  const [newPassword, setNewPassword] = useState('');
  const [otpValue, setOtpValue] = useState({
    otp: '',
  });

  const [isOtpError, setIsOtpError] = useState(false);
  const [isFirstTime, setIsFirstTime] = useState(true);

  const recoveryPasswordError = useSelector(
    (state) => state.recoveryPassword.error
  );

  const isChangingPassword = useSelector(
    (state) => state.createPassword.loading
  );

  const recoveryPasswordSuccess = useSelector(
    (state) => state.recoveryPassword?.success
  );

  const recoveryPasswordErrorMessage = useSelector(
    (state) => state.recoveryPassword?.errorMessage
  );

  const TOKEN_INPUTS = 6;

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };
  const tailLayout = {
    wrapperCol: { span: 24 },
  };

  useEffect(() => {
    if (recoveryPasswordError === true) {
      setIsOtpError(true);
      setIsFirstTime(false);
    }
  }, [recoveryPasswordError]);

  useEffect(() => {
    if (recoveryPasswordSuccess === true) {
      setIsOtpError(false);
      setPasswordWasChanged(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recoveryPasswordSuccess]);

  const onPasswordFinish = (values) => {
    setEmail(query.get('username'));
    const password = values?.password;
    setNewPassword(password);
    setIsTokenPasswordScreen(true);
  };

  const onTokenFinish = () => {
    const token = otpValue?.otp;
    dispatch(
      recoveryPasswordStart({
        username: email,
        code: token,
        newPassword,
      })
    );
  };

  useEffect(() => {
    const queryCodeParameter = query.get('code_parameter');
    setConfirmationCodeInput(queryCodeParameter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinishFailed = (formErrors) => {
    const errorFields = formErrors.errorFields.map((item) => item);
    const errors = errorFields.flatMap((item) => item.errors);
    errors.map((item, index) =>
      notification.warning({ key: index, message: item })
    );
  };

  const sendNewToken = () => {
    dispatch(forgotPasswordRequest({ email }));
    notification.success({
      message: 'Código enviado',
      description:
        'Um novo código de verificação foi enviado ao seu e-mail. Verifique sua caixa de entrada ou spam.',
    });
    setIsOtpError(false);
    setOtpValue({
      otp: '',
    });
  };

  useEffect(() => {
    if (
      passwordInput?.length === 0 ||
      !passwordInput ||
      passwordInput === '' ||
      passwordInput === ' '
    ) {
      setCurrentPasswordStatus('');
    } else {
      // eslint-disable-next-line no-lonely-if
      if (
        passwordInput?.length >= 8 &&
        passwordInput?.length > 0 &&
        hasUppercase(passwordInput) &&
        passwordInput?.length > 0 &&
        hasLowercase(passwordInput) &&
        passwordInput?.length > 0 &&
        hasOneNumber(passwordInput) &&
        passwordInput?.length > 0 &&
        hasSpecialChar(passwordInput)
      ) {
        setCurrentPasswordStatus('success');
      } else {
        setCurrentPasswordStatus('error');
      }
    }
  }, [passwordInput]);

  useEffect(() => {
    if (confirmationCodeInput?.length !== 6) {
      setCurrentConfirmationCodeStatus('warning');
    } else {
      setCurrentConfirmationCodeStatus('success');
    }
  }, [confirmationCodeInput]);

  return (
    <>
      {isTokenPasswordScreen ? (
        <StAlignContent>
          {passwordWasChanged ? (
            <PasswordChanged />
          ) : (
            <Row align="middle">
              <StTitleRow align="middle">
                <Col>
                  <p style={{ fontWeight: 600 }}>Código de verificação</p>
                  <p style={{ fontSize: 16, fontWeight: 400 }}>
                    Insira o código recebido no campo abaixo:
                  </p>
                </Col>
              </StTitleRow>
              <Row align="middle" justify="center">
                <Col span={24} justify="center">
                  <OtpInput
                    inputStyle={
                      isOtpError
                        ? {
                            width: '40px',
                            height: '40px',
                            margin: '16px',
                            border: `1px solid ${colors.gray5}`,
                            borderRadius: '2px',
                            borderColor: colors.red6,
                          }
                        : {
                            width: '40px',
                            height: '40px',
                            margin: '16px',
                            border: `1px solid ${colors.gray5}`,
                            borderRadius: '2px',
                          }
                    }
                    value={otpValue?.otp}
                    onChange={(otp) => {
                      setOtpValue({ otp });
                    }}
                    numInputs={TOKEN_INPUTS}
                    shouldAutoFocus
                    isInputNum
                  />
                </Col>
                {isOtpError ? (
                  <StErrorCol span={24} justify="center">
                    <Typography.Text type="danger">
                      {recoveryPasswordErrorMessage}
                    </Typography.Text>
                  </StErrorCol>
                ) : (
                  <></>
                )}
              </Row>
              <Col span={24}>
                <Row gutter={[0, 24]}>
                  <Col span={24}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ width: '100%' }}
                      // icon={isChangingPassword && <LoadingOutlined />}
                      disabled={otpValue?.otp.length !== 6}
                      onClick={() => {
                        onTokenFinish();
                      }}
                    >
                      {isFirstTime ? 'Concluir' : 'Tentar novamente'}
                    </Button>
                  </Col>
                  {!isFirstTime && isOtpError && (
                    <>
                      <Col span={24}>
                        <Button
                          type="link"
                          htmlType="submit"
                          style={{ width: '100%' }}
                          onClick={() => {
                            sendNewToken();
                          }}
                        >
                          Solicitar novo código
                        </Button>
                      </Col>
                    </>
                  )}
                </Row>
              </Col>
            </Row>
          )}
        </StAlignContent>
      ) : (
        <StAlignContent>
          <>
            <StCreatePasswordTitle>Redefinir senha</StCreatePasswordTitle>
            <StPasswordTextInfo>
              Sua senha deve conter ao menos:
            </StPasswordTextInfo>
            <StCustomUl>
              <StLiContainer>
                <StCustomLi
                  status={currentPasswordStatus}
                  validNumOfChars={passwordInput}
                />
                <Typography.Text>8 caracteres</Typography.Text>
              </StLiContainer>
              <StLiContainer>
                <StCustomLi
                  status={currentPasswordStatus}
                  validUppercase={passwordInput}
                />
                <Typography.Text>1 letra maiúscula</Typography.Text>
              </StLiContainer>
              <StLiContainer>
                <StCustomLi
                  status={currentPasswordStatus}
                  validLowercase={passwordInput}
                />
                <Typography.Text>1 letra minúscula</Typography.Text>
              </StLiContainer>
              <StLiContainer>
                <StCustomLi
                  status={currentPasswordStatus}
                  validOneNumber={passwordInput}
                />
                <Typography.Text>1 número</Typography.Text>
              </StLiContainer>
              <StLiContainer>
                <StCustomLi
                  status={currentPasswordStatus}
                  validSpecialChar={passwordInput}
                />
                <Typography.Text>
                  1 caractere especial, por exemplo: ! @ # $ & *
                </Typography.Text>
              </StLiContainer>
            </StCustomUl>
            <Form
              form={form}
              // style={{ marginTop: '3.75rem' }}
              hideRequiredMark
              name="recovery_password"
              {...layout}
              onFinish={onPasswordFinish}
              onFinishFailed={onFinishFailed}
              initialValues={{
                password: passwordInput,
                confirm_password: confirmPasswordInput,
                confirmation_code: confirmationCodeInput,
              }}
              onFieldsChange={(_, allFields) => {
                setPasswordInput(allFields[0]?.value);
                setConfirmPasswordInput(allFields[1]?.value);
                setConfirmationCodeInput(allFields[2]?.value);
              }}
            >
              <Form.Item
                style={{ maxWidth: '100%', marginBottom: 16 }}
                hasFeedback
                validateStatus={currentPasswordStatus}
                initialValue={passwordInput}
                label="Senha::"
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Por favor, insira a nova senha!',
                  },
                ]}
              >
                <Input.Password
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                  placeholder="Inserir senha"
                />
              </Form.Item>
              <Form.Item
                style={{ maxWidth: '100%', marginBottom: 16 }}
                hasFeedback
                dependencies={['password']}
                label="Confirmar senha::"
                name="confirm_password"
                validateTrigger={['onChange']}
                rules={[
                  { required: true, message: 'Por favor, confirme a senha.' },
                  // eslint-disable-next-line no-unused-vars
                  ({ getFieldValue }) => ({
                    // eslint-disable-next-line no-unused-vars
                    validator(rule, value) {
                      if (
                        confirmPasswordInput !== '' &&
                        passwordInput === confirmPasswordInput
                      ) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error('As senhas digitadas não conferem.')
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                  placeholder="Confirmar senha"
                />
              </Form.Item>
              <Form.Item {...tailLayout} style={{ margin: 0 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: '100%' }}
                  disabled={
                    !form.isFieldsTouched(true) ||
                    !!form
                      .getFieldsError()
                      .filter(({ errors }) => errors.length).length
                  }
                >
                  {isChangingPassword ? 'Carregando' : 'Avançar'}
                </Button>
              </Form.Item>
            </Form>
          </>
        </StAlignContent>
      )}
    </>
  );
};

export default PasswordForm;
