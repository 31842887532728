import { call, put } from 'redux-saga/effects';

import { getCep } from 'services/viaCep';
import { getCepSuccess, getCepError } from './actions';
import errorHandler from '../../../helpers/errorHandler';

export function* getCepSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(getCep, payload);
    yield put(getCepSuccess(apiData));
  } catch (err) {
    yield put(getCepError('Ocorreu um erro:', err));
    errorHandler('Ocorreu um erro:', err);
  }
}
