import { call, put } from 'redux-saga/effects';

import errorHandler from 'helpers/errorHandler';

import {
  radarAnyRunningSuccess,
  radarAnyRunningError,
} from 'store/ducks/radar/radarAnyRunning/actions';

import { anyRunning } from 'services/radar/anyRunning';

export function* radarAnyRunningSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(anyRunning, payload);
    yield put(radarAnyRunningSuccess(apiData));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        radarAnyRunningError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(radarAnyRunningError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}
