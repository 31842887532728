import React from 'react';
import PropTypes from 'prop-types';
import { ShopOutlined } from '@ant-design/icons';
import { Grid, Drawer, Button, List } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import {
  StSearch,
  StButton,
  StCompanyListContainer,
  StSearchWrapper,
  StListItem,
  StListWrapper,
  StListItemButtonDifferent,
  StListItemButton,
} from './styled';
import { colors } from 'styles/colors';
import { shortId } from 'helpers/shortId';

const CompaniesListDrawer = ({
  onSearch,
  clientCode,
  visible,
  onClose,
  openDrawer,
  listOfCompanies,
  loadingCompanies,
  changeStore,
  loadingListOfClients,
  onSearchChange,
  loadMoreItens,
  hasMore,
}) => {
  const { useBreakpoint } = Grid;
  const breakpoint = useBreakpoint();

  let drawerWidth = '100%';
  if (breakpoint?.md) {
    drawerWidth = '50%';
  }
  if (breakpoint?.lg) {
    drawerWidth = '40%';
  }
  if (breakpoint?.xl) {
    drawerWidth = '40%';
  }
  if (breakpoint?.xxl) {
    drawerWidth = '30%';
  }

  return (
    <>
      <StButton
        icon={<ShopOutlined style={{ color: colors.secondary6 }} />}
        onClick={openDrawer}
      >
        Trocar empresa
      </StButton>
      <Drawer
        width={drawerWidth}
        open={visible}
        onClose={onClose}
        closable={false}
        footerStyle={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
        footer={
          <Button style={{ color: colors.red6 }} onClick={onClose}>
            Cancelar
          </Button>
        }
      >
        <StCompanyListContainer $breakpoint={breakpoint}>
          <div
            style={{
              backgroundColor: colors.gray2,
            }}
          >
            <StSearchWrapper>
              <StSearch
                onChange={(e) => onSearchChange(e.target.value)}
                onSearch={(value) => onSearch(value)}
                loading={loadingListOfClients}
              />
            </StSearchWrapper>
          </div>
          <StListWrapper>
            <InfiniteScroll
              initialLoad={loadingListOfClients}
              pageStart={1}
              loadMore={loadMoreItens}
              hasMore={hasMore && !loadingListOfClients}
              useWindow={false}
            >
              <List
                dataSource={listOfCompanies}
                loading={loadingCompanies}
                style={{ overflow: 'auto' }}
                renderItem={(item) => {
                  return item.client_code === clientCode ? (
                    <StListItem key={shortId()}>
                      <StListItemButton
                        key={shortId()}
                        type="link"
                        onClick={() => changeStore(item.client_id)}
                      >
                        {`${item.client_code} ${item.name}`}
                      </StListItemButton>
                    </StListItem>
                  ) : (
                    <StListItem key={shortId()}>
                      <StListItemButtonDifferent
                        key={shortId()}
                        type="link"
                        onClick={() => changeStore(item.client_id)}
                      >
                        {`${item.client_code} ${item.name}`}
                      </StListItemButtonDifferent>
                    </StListItem>
                  );
                }}
              />
            </InfiniteScroll>
          </StListWrapper>
        </StCompanyListContainer>
      </Drawer>
    </>
  );
};

CompaniesListDrawer.defaultProps = {
  listOfCompanies: [],
  loadingCompanies: false,
};

CompaniesListDrawer.propTypes = {
  clientCode: PropTypes.string.isRequired,
  changeStore: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  openDrawer: PropTypes.func.isRequired,
  loadingCompanies: PropTypes.bool,
  listOfCompanies: PropTypes.arrayOf(
    PropTypes.shape({
      client_code: PropTypes.string,
      client_id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  onSearch: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  loadingListOfClients: PropTypes.bool.isRequired,
  hasMore: PropTypes.bool.isRequired,
  loadMoreItens: PropTypes.func.isRequired,
};

export default CompaniesListDrawer;
