import styled from 'styled-components';
import { Collapse, Table, Typography } from 'antd';
import { colors } from 'styles/colors';

export const StTitleItem = styled(Typography.Title)`
  &&& {
    font-size: 20px;
    font-weight: 500;
    margin: 0;
    line-height: 28px;
  }
`;

export const StSubTitleItem = styled(Typography.Text)`
  &&& {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: ${colors.gray8};
  }
`;

export const StTable = styled(Table)`
  &&& {
    .ant-table-thead > tr > th {
      color: ${colors.gray9};
      background: ${colors.gray1} !important;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      border-top: 1px solid #ebebeb;
      padding: 16px 0;
    }
    .ant-table-cell {
      border: none;
      padding: 16px 0;
      color: ${colors.gray9};
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
    }
  }
`;

export const StCollapse = styled(Collapse)`
  &&& {
    .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
      padding: 0;
    }
    .ant-collapse-item:last-child {
      margin-bottom: 0 !important;
    }
  }
`;
