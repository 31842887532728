import { actionTypes } from './types';

const INITIAL_STATE = {
  error: undefined,
  errorMessage: undefined,
  loading: false,
  loadingPutFees: false,
  isError: false,
  vigentes: [],
  vencidas: [],
  countVigents: 0,
  countVencidas: 0,
  hasMoreVigentes: true,
  hasMoreVencidas: true,
  altered: false,
  updated: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case actionTypes.LIST_FEES_VENCIDAS_START:
  case actionTypes.LIST_FEES_VIGENTES_START:
    return {
      ...state,
      loading: true,
      error: undefined,
      errorMessage: undefined,
      isError: false,
      altered: false,
      updated: false,
    };
  case actionTypes.LIST_FEES_VIGENTES_SUCCESS:
    return {
      ...state,
      loading: false,
      error: undefined,
      vigentes: action.payload.vigentes,
      countVigents: action.payload.count,
      perPage: action.payload.perPage,
      page: action.payload.page,
      hasMoreVigentes: action.payload.hasMore,
      isError: false,
      errorMessage: undefined,
    };
  case actionTypes.LIST_FEES_VENCIDAS_SUCCESS:
    return {
      ...state,
      loading: false,
      error: undefined,
      vencidas: action.payload.vencidas,
      countVencidas: action.payload.count,
      perPage: action.payload.perPage,
      page: action.payload.page,
      hasMoreVencidas: action.payload.hasMore,
      isError: false,
      errorMessage: undefined,
    };
  case actionTypes.PUT_FEES_START:
    return {
      ...state,
      loadingPutFees: true,
    };
  case actionTypes.LIST_FEES_VIGENTES_ERROR:
  case actionTypes.PUT_FEES_ERROR:
  case actionTypes.DELETE_FEES_ERROR:
  case actionTypes.LIST_FEES_VENCIDAS_ERROR:
    return {
      ...state,
      loading: false,
      error: action.error.error,
      errorMessage: action.payload,
      isError: true,
      vencidas: [],
      vigentes: [],
    };
  case actionTypes.LIST_FEES_VENCIDAS_ALTER:
    return {
      ...state,
      vencidas: action.payload,
      altered: true,
    };
  case actionTypes.LIST_FEES_VIGENTES_ALTER:
    return {
      ...state,
      vigentes: action.payload,
      altered: true,
    };
  case actionTypes.PUT_FEES_SUCCESS:
    return {
      ...state,
      loading: false,
      loadingPutFees: false,
      altered: false,
      updated: true,
      isError: false,
    };
  case actionTypes.DELETE_FEES_SUCCESS:
    return {
      ...state,
      loading: false,
      isError: false,
    };
  default:
    return state;
  }
};

export default reducer;
