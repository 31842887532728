import { call, put } from 'redux-saga/effects';

import errorHandler from 'helpers/errorHandler';

import {
  reconciledPaymentsDetailsSuccess,
  reconciledPaymentsDetailsError,
} from 'store/ducks/details/reconciledPaymentsDetails/actions';
import getReconciledPaymentsDetailsFromAPI from 'services/reconciled/details/payments';

export function* reconciledPaymentsDetailsSaga(action) {
  try {
    const { payload } = action;

    const apiData = yield call(getReconciledPaymentsDetailsFromAPI, payload);

    yield put(reconciledPaymentsDetailsSuccess(apiData));
  } catch (err) {
    if (err?.response?.data?.message) {
      yield put(
        reconciledPaymentsDetailsError(
          err.response.data.message,
          err.response.data.error
        )
      );
      errorHandler(err.response.data.message, err.response.data.error);
    }
  }
}
