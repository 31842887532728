import { api } from '../api';

const transformer = (apiResponse) => {
  const data = {
    filters: apiResponse.data.names.sort(),
  };
  return data;
};

const getAdvancementInfoFiltersFromAPI = async ({
  type,
  startDate,
  endDate,
}) => {
  const config = {
    params: {
      start_date: startDate.format('YYYY-MM-DD'),
      end_date: endDate.format('YYYY-MM-DD'),
      type,
    },
  };
  const response = await api().get('/advancement/filters/intermediate', config);

  return transformer(response.data);
};

export default getAdvancementInfoFiltersFromAPI;
