import { call, put } from 'redux-saga/effects';
import errorHandler from 'helpers/errorHandler';
import createPassword from 'services/createPassword';
import { notification } from 'antd';
import { createPasswordSuccess, createPasswordFailure } from './actions';

export function* createPasswordSaga(action) {
  const { userData } = action.payload;
  try {
    const apiData = yield call(createPassword, userData);
    yield put(createPasswordSuccess(apiData));
    notification.success({ message: apiData });
  } catch (err) {
    if (
      err?.response?.data?.error?.message &&
      err?.response?.data?.error?.message?.indexOf('possui acesso') === -1
    ) {
      yield put(
        createPasswordFailure(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    }
  }
}
