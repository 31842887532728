import { call, put } from 'redux-saga/effects';

import errorHandler from 'helpers/errorHandler';

import {
  paymentDivergencesDetailsSuccess,
  paymentDivergencesDetailsError,
} from 'store/ducks/details/paymentDivergencesDetails/actions';
import getPaymentDivergencesDetailsFromAPI from 'services/details/paymentDivergencesDetails';

export function* paymentDivergencesDetailsSaga(action) {
  try {
    const { payload } = action;

    const apiData = yield call(getPaymentDivergencesDetailsFromAPI, payload);

    yield put(paymentDivergencesDetailsSuccess(apiData));
  } catch (err) {
    if (err?.response?.data?.message) {
      yield put(
        paymentDivergencesDetailsError(
          err.response.data.message,
          err.response.data.error
        )
      );
      errorHandler(err.response.data.message, err.response.data.error);
    }
  }
}
