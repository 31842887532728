import styled from 'styled-components';
import { Typography } from 'antd';

export const StContainer = styled.div`
  text-align: ${({ textAlign }) => textAlign};
`;

export const StTypographyTextLabel = styled(Typography.Text)`
  &&& {
    font-size: ${({ fontSize }) => fontSize};
    color: ${({ $labelColor }) => $labelColor};
    overflow-wrap: break-word;
    word-break: unset;
  }
`;

export const StTypographyTextValue = styled(Typography.Text)`
  &&& {
    font-size: ${({ fontSize }) => fontSize};
    color: ${({ $valueColor }) => $valueColor};
    overflow-wrap: break-word;
    word-break: unset;
  }
`;
