import React from 'react';
import { Tooltip } from 'antd';
import { colors } from 'styles/colors';
import {
  StCheckOutlined,
  StContainer,
  StInfoCircleOutlined,
  StLabel,
} from './styled';

const PlanFeature = ({ label, tooltipText }) => {
  if (!label) return null;

  return (
    <StContainer>
      <StCheckOutlined />
      <StLabel>{label}</StLabel>
      {tooltipText && (
        <Tooltip
          title={tooltipText}
          placement="right"
          color={colors.gray1}
          overlayInnerStyle={{
            color: colors.gray9,
            padding: '16px',
            width: '320px',
          }}
        >
          <StInfoCircleOutlined />
        </Tooltip>
      )}
    </StContainer>
  );
};

export default PlanFeature;
