import { action } from 'typesafe-actions';
import { actionTypes } from 'store/ducks/reconciled/summary/payments/types';

export const paymentsSummaryReconciledStart = (data) =>
  action(actionTypes.PAYMENTS_SUMMARY_RECONCILED_START, data);
export const paymentsSummaryReconciledSuccess = (data) =>
  action(actionTypes.PAYMENTS_SUMMARY_RECONCILED_SUCCESS, data);
export const paymentsSummaryReconciledError = (errorMessage, error) =>
  action(
    actionTypes.PAYMENTS_SUMMARY_RECONCILED_ERROR,
    { errorMessage },
    undefined,
    { error }
  );
