import { api } from './api';

const transformer = (apiResponse) => {
  const data = [];
  apiResponse.data.forecast.graph.forEach((prevision) => {
    data.push({
      value: prevision.value,
      month: prevision.month,
    });
  });

  const transformedData = {
    forecast: data,
    total_net_value: apiResponse.data.forecast.value,
  };

  return transformedData;
};

const getAmountsReceivableSummaryFromAPI = async ({ startDate, endDate }) => {
  const config = {
    params: {
      start_date: startDate.format('YYYY-MM-DD'),
      end_date: endDate.format('YYYY-MM-DD'),
    },
  };
  const response = await api().get('/summary/forecast', config);
  return transformer(response.data);
};

export default getAmountsReceivableSummaryFromAPI;
