import { Typography } from 'antd';
import styled from 'styled-components';
import { colors } from 'styles/colors';

const { Text } = Typography;

export const StText = styled(Text)`
  font-size: 14px;
  font-weight: 400;
  color: ${colors.gray9};
  text-decoration: underline;
  cursor: pointer;
`;
