import React from 'react';
import PropTypes from 'prop-types';
import { StContainer, StTypographyText, StImg } from './styled';
import { dataTestIdHandler } from 'helpers/dataTestIdHandler';

const TitleAndIcon = ({
  iconUri,
  iconAlt,
  titleText,
  titleFontSize,
  justifyContent,
  children,
  dataTestPrefix,
}) => (
  <StContainer style={{ alignItems: 'center' }} justifyContent={justifyContent}>
    {iconUri && (
      <StImg
        src={iconUri}
        alt={iconAlt}
        {...dataTestIdHandler(dataTestPrefix, 'icon')}
      />
    )}
    {children}
    <StTypographyText
      style={{ marginBottom: '0px' }}
      $titleFontSize={titleFontSize}
      $iconUri={iconUri}
      {...dataTestIdHandler(dataTestPrefix, 'title')}
    >
      {titleText}
    </StTypographyText>
  </StContainer>
);

TitleAndIcon.defaultProps = {
  iconUri: '',
  iconAlt: '',
  titleFontSize: '2rem',
  justifyContent: 'space-between',
  titleText: '',
  children: '',
  dataTestPrefix: '',
};

TitleAndIcon.propTypes = {
  iconAlt: PropTypes.string,
  iconUri: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  titleText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  titleFontSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  dataTestPrefix: PropTypes.string,
  justifyContent: PropTypes.oneOf([
    'start',
    'center',
    'space-between',
    'space-around',
    'space-evenly',
  ]),
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.bool,
  ]),
};

export default TitleAndIcon;
