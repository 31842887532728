import styled from 'styled-components';

import { Button } from 'antd';
import { colors } from 'styles/colors';

export const StContainer = styled.div`
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  background-color: ${colors.gray1};
  border-radius: 4px;
  cursor: pointer;
`;

export const StLimiter = styled.div`
  padding: 16px;
`;

export const StButton = styled(Button)`
  color: #1790ff !important;
  height: 2.75rem;
  margin-top: 1.125rem;
  padding-right: 0px;
  font-family: Roboto;
  font-size: 1.5rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  text-align: right;
`;

export const Title = styled.div`
  height: 6.875rem;
  margin: 0 2rem;
  font-family: Roboto;
  font-size: 1.75rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 3rem;
  letter-spacing: normal;
  text-align: left;
  color: rgba(0, 0, 0, 0.8);
  padding-top: 1.8925rem;
`;

export const TxDoughnut = styled.div`
  font-family: Roboto;
  font-size: 1.5rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  text-align: left;
  color: ${colors.gray11};
  padding-left: 2rem;
`;

export const PeDoughnut = styled.div`
  width: 4.75rem;
  font-family: Roboto;
  font-size: 2.5rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: left;
  color: ${colors.red6};
`;

export const PeBellow = styled.div`
  width: 4.75rem;
  font-family: Roboto;
  font-size: 2.5rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: left;
  color: ${colors.red6};
  padding-left: 2rem;
`;

export const PeAbove = styled.div`
  width: 4.75rem;
  font-family: Roboto;
  font-size: 2.5rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: left;
  color: #f5ba22;
  padding-left: 2rem;
`;

export const PeEquals = styled.div`
  width: 4.75rem;
  font-family: Roboto;
  font-size: 2.5rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: left;
  color: #4caa55;
  padding-left: 2rem;
`;

export const PeNotRegistered = styled.div`
  width: 4.75rem;
  font-family: Roboto;
  font-size: 2.5rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: left;
  color: #8e8e8e;
  padding-left: 2rem;
`;
