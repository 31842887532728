const baseURL = process.env.REACT_APP_AWS_V4_ASSETS;

export const plansLogo = {
  1: `${baseURL}/plans/menu_plano_controle.svg`,
  2: `${baseURL}/plans/menu_plano_gerencie.svg`,
  4: `${baseURL}/plans/menu_plano_plus.svg`,
  6: `${baseURL}/plans/menu_plano_pag_gratis.svg`,
  7: `${baseURL}/plans/menu_plano_freemium.svg`,
  8: `${baseURL}/plans/menu_plano_pro.svg`,
};
