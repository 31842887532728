import React from 'react';

import PropTypes from 'prop-types';

import { Grid, Typography } from 'antd';
import { StLimiter, StContainer } from './styled';
import { colors } from 'styles/colors';

// eslint-disable-next-line react/prop-types
const HeaderRegisters = ({
  title,
  subTitle,
  customSubTitle,
  children,
  renderFilterButton,
  isCNPJMaintenance,
}) => {
  const { useBreakpoint } = Grid;
  const breakpoint = useBreakpoint();

  return (
    <StContainer breakpoint={breakpoint} isCNPJMaintenance={isCNPJMaintenance}>
      <StLimiter breakpoint={breakpoint} isCNPJMaintenance={isCNPJMaintenance}>
        {breakpoint?.lg ? (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography.Text
                strong
                style={{
                  marginTop: isCNPJMaintenance ? '0px' : '2rem',
                  fontSize: 24,
                }}
              >
                {title}
              </Typography.Text>
            </div>
            <div>
              {subTitle && (
                <Typography.Text style={{ fontSize: 14, color: colors.gray11 }}>
                  {subTitle}
                </Typography.Text>
              )}
            </div>
            {customSubTitle && customSubTitle}
            <div style={{ marginTop: '1.75rem' }}>{children}</div>
          </>
        ) : (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography.Text strong style={{ margin: 0, fontSize: 20 }}>
                  {title}
                </Typography.Text>
                {subTitle && (
                  <Typography.Text
                    style={{ marginLeft: '1.2rem', fontSize: 12 }}
                  >
                    {subTitle}
                  </Typography.Text>
                )}
                {customSubTitle && customSubTitle}
              </div>
              <div>{renderFilterButton()}</div>
            </div>
            <div style={{ marginTop: '1rem' }}>{children}</div>
          </>
        )}
      </StLimiter>
    </StContainer>
  );
};

HeaderRegisters.defaultProps = {
  subTitle: '',
  customSubTitle: undefined,
  renderFilterButton: () => {},
};

HeaderRegisters.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  customSubTitle: PropTypes.node,
  renderFilterButton: PropTypes.func,
};

export default HeaderRegisters;
