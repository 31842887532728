import { action } from 'typesafe-actions';
import { actionTypes } from 'store/ducks/details/reconciledReceiptDetails/types';

export const reconciledReceiptDetailsStart = (data) =>
  action(actionTypes.RECONCILED_RECEIPT_DETAILS_START, data);
export const reconciledReceiptDetailsSuccess = (data) =>
  action(actionTypes.RECONCILED_RECEIPT_DETAILS_SUCCESS, data);
export const reconciledReceiptDetailsError = (errorMessage, error) =>
  action(
    actionTypes.RECONCILED_RECEIPT_DETAILS_ERROR,
    { errorMessage },
    undefined,
    { error }
  );
