import React from 'react';
import dayjs from 'dayjs';
import { Typography } from 'antd';

const CreatedAt = ({ created_at: createdAt }) => {
  const formattedDateAndTime = dayjs(createdAt).format('DD/MM/YYYY [às] HH:mm');

  const { Text } = Typography;

  return <Text>{formattedDateAndTime}</Text>;
};

export default CreatedAt;
