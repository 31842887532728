import React, { useEffect, useState } from 'react';
import { Checkbox, Col, Row } from 'antd';
import { updateLocalStorage } from './ColumnList';
import { isAcquirerIgnored } from 'components/BankConciliationTransactions/helpers/acquirerStatus';

const Actions = ({ currentRow, changeTransaction, counters }) => {
  const uniqueId = currentRow?.unique_id;
  const { setUpdateLocal } = counters;

  const localTransactions = JSON.parse(
    localStorage.getItem('classified_transactions')
  );

  const [isIgnored, setIsIgnored] = useState(false);

  useEffect(() => {
    if (localTransactions?.client?.id) {
      if (localTransactions?.client?.files) {
        localTransactions.client.files.forEach((file) => {
          if (file?.id) {
            if (file?.id === currentRow?.id_file_tracking) {
              if (file?.transactions) {
                file.transactions.forEach((transaction) => {
                  if (transaction?.id) {
                    if (transaction?.id === uniqueId) {
                      if (transaction?.is_ignored) {
                        setIsIgnored(transaction?.is_ignored);
                      }
                    }
                  }
                });
              }
            }
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row>
      <Col>
        <Checkbox
          checked={isAcquirerIgnored(currentRow.acquirer_name) || isIgnored}
          onChange={(e) => {
            if (e.target.checked) {
              changeTransaction(
                uniqueId,
                'IGNORAR',
                currentRow.id_file_tracking,
                currentRow
              );
              updateLocalStorage(currentRow, 'IGNORAR', true);
              setIsIgnored(true);
            } else {
              setIsIgnored(false);
              changeTransaction(uniqueId, '', currentRow.id_file_tracking);
              updateLocalStorage(currentRow, '', false);
              setUpdateLocal((prevState) => prevState + 1);
            }
          }}
        >
          Ignorar lançamento
        </Checkbox>
      </Col>
    </Row>
  );
};

Actions.propTypes = {};

export default Actions;
