import errorHandler from 'helpers/errorHandler';
import { useState, useEffect, useCallback } from 'react';
import getAcquirerListFromAPI from 'services/cnpjMaintenance/listAcquirers';

function useAcquirerList(searchFilter) {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [acquirers, setAcquirers] = useState([]);
  const [acquirersData, setAcquirersData] = useState([]);
  const [firstAcquirerClient, setFirstAcquirerClient] = useState(null);
  const [metaAcquirerListData, setMetaAcquirerListData] = useState({});
  const [hasLoadedAcquirers, setHasLoadedAcquirers] = useState(false);

  const fetchAcquirers = useCallback(
    () => {
      setIsLoading(true);
      setIsError(false);
      return getAcquirerListFromAPI({
        merchantCode: searchFilter,
      })
        .then(({ acquirers: data = [], meta = {} }) => {
          setFirstAcquirerClient(data[0]?.detail?.acquirer_id);
          setAcquirersData(data);
          setMetaAcquirerListData(meta);
        })
        .catch((err) => {
          if (err) {
            errorHandler('Ocorreu um erro:', err);
          }
          setIsError(true);
          setIsLoading(false);
        })
        .finally(() => {
          setIsLoading(false);
          setHasLoadedAcquirers(true);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchFilter]
  );

  useEffect(() => {
    setAcquirers([]);
    fetchAcquirers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFilter]);

  useEffect(() => {
    setAcquirers(() => {
      return [...new Set([...acquirersData])];
    });
  }, [acquirersData]);

  const refreshAcquirers = () => {
    setAcquirers([]);
    fetchAcquirers();
  };

  return {
    isLoading,
    isError,
    acquirers,
    refreshAcquirers,
    firstAcquirerClient,
    metaAcquirerListData,
    hasLoadedAcquirers,
  };
}

export default useAcquirerList;
