/* eslint-disable no-param-reassign */
import { useDispatch, useSelector } from 'react-redux';
import { Loadable } from 'commons/Loadable';
import { HeaderRegisters } from 'commons/HeaderRegisters';
import { Checkbox, List, notification, Typography } from 'antd';
import { AcquirerCheckCard } from '../AcquirerCheckCard';
import { getClientStart, putClientStart } from 'store/ducks/client/actions';
import { getSessionClientId } from 'helpers/sessionService';
import { usePlansFeaturesPermissions } from 'utils/hooks/usePlansFeaturesPermissions';
import {
  acquirerStart,
  acquirerVoucherStart,
} from 'store/ducks/acquirer/actions';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import {
  deleteClientAcquirerStart,
  postClientAcquirerStart,
} from 'store/ducks/clientAcquirer/actions';

const BriefingAcquirersVoucher = forwardRef(
  (
    {
      isLocked,
      isCNPJMaintenance = false,
      acquirers,
      setAcquirers,
      throughModal,
      isVoucherNotAccepted,
      setIsVoucherNotAccepted,
      setIsNextButtonLocked,
    },
    ref
  ) => {
    const { isFeatureAvailable } = usePlansFeaturesPermissions();
    const dispatch = useDispatch();
    const loadingAcquirer = useSelector((state) => state.acquirer.loading);
    const clientAcquirerLoading = useSelector(
      (state) => state.clientAcquirer.loading
    );
    const clientAcquirerSuccess = useSelector(
      (state) => state.clientAcquirer.success
    );
    const acquirerData = useSelector(
      (state) => state.acquirer.acquirersVouchers
    );
    const loadingVouchers = useSelector(
      (state) => state.acquirer.loadingVouchers
    );
    const clientData = useSelector((state) => state.client.client);
    const [isVoucherNotAcceptedBriefing, setIsVoucherNotAcceptedBriefing] =
      useState(false);

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    useImperativeHandle(ref, () => ({
      validateBeforeNextPage(afterValidate) {
        if (
          acquirers.some((a) => a.checked) ||
          (throughModal && isVoucherNotAccepted) ||
          isVoucherNotAcceptedBriefing
        ) {
          afterValidate();
        } else {
          notification.warn({
            message:
              'É necessário selecionar ao menos 1 (um) benefício/voucher ou marque a opção "Não aceito vouchers ou benefícios".',
          });
        }
      },
    }));

    useEffect(() => {
      dispatch(getClientStart({}));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (!clientAcquirerLoading) {
        dispatch(acquirerVoucherStart());
      }
      if (!clientAcquirerLoading && clientAcquirerSuccess) {
        dispatch(acquirerStart({}));
      }
      setIsNextButtonLocked(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, clientAcquirerLoading, clientAcquirerSuccess]);

    useEffect(() => {
      setAcquirers(acquirerData?.acquirers);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, acquirerData]);

    useEffect(() => {
      const client = clientData?.client;
      if (throughModal) {
        setIsVoucherNotAccepted(client?.is_vouchers_not_accepted ?? false);
      } else {
        setIsVoucherNotAcceptedBriefing(
          client?.is_vouchers_not_accepted ?? false
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientData]);

    const onAcquirerCheckClick = (acquirer) => {
      const data = {
        acquirer_id: acquirer.acquirer_id,
        client_id: getSessionClientId(),
      };
      if (acquirer.checked) {
        dispatch(deleteClientAcquirerStart(data));
      } else {
        dispatch(postClientAcquirerStart(data));
      }
      forceUpdate();
    };

    const onChangeCheckVouchers = (e) => {
      const { checked } = e.target;

      if (checked) {
        if (acquirers.some((a) => a.checked)) {
          notification.warn({
            message:
              'É necessário desmarcar todos os vouchers/benefícios para marcar esta opção.',
          });
          return;
        }
      }

      const data = {
        client_id: getSessionClientId(),
        is_vouchers_not_accepted: checked,
      };
      dispatch(putClientStart(data));
      if (throughModal) {
        setIsVoucherNotAccepted(checked);
      } else {
        setIsVoucherNotAcceptedBriefing(checked);
      }
    };

    return (
      <HeaderRegisters
        title="Selecionar benefícios"
        subTitle={
          isCNPJMaintenance
            ? 'Selecione todos os benefícios ou vouchers que deseja adicionar'
            : 'Selecione todos os benefícios e vouchers que aceita nos CNPJs cadastrados'
        }
      >
        <Loadable
          loading={
            loadingAcquirer ||
            loadingVouchers ||
            clientAcquirerLoading ||
            !acquirers
          }
        >
          <>
            <Checkbox
              disabled={
                isFeatureAvailable({ key: 'briefing-viewAllAcquirers' })
                  ? isLocked
                  : true
              }
              checked={
                isFeatureAvailable({ key: 'briefing-viewAllAcquirers' })
                  ? throughModal
                    ? isVoucherNotAccepted
                    : isVoucherNotAcceptedBriefing
                  : true
              }
              onChange={onChangeCheckVouchers}
            >
              <Typography.Text style={{ fontSize: 12 }}>
                Não aceito vouchers ou benefícios
              </Typography.Text>
            </Checkbox>
            <br />
            <br />

            <List
              grid={
                throughModal
                  ? { xs: 1, sm: 2, md: 3, lg: 3, xl: 6, xxl: 6 }
                  : { xs: 1, sm: 2, md: 3, lg: 5, xl: 7, xxl: 9 }
              }
              dataSource={acquirers}
              renderItem={(acquirer) => (
                <AcquirerCheckCard
                  isCNPJMaintenance={isCNPJMaintenance}
                  acquirer={acquirer}
                  onClick={() => onAcquirerCheckClick(acquirer)}
                  disabled={
                    isLocked ||
                    (throughModal
                      ? isVoucherNotAccepted
                      : isVoucherNotAcceptedBriefing)
                  }
                />
              )}
            />
          </>
        </Loadable>
      </HeaderRegisters>
    );
  }
);

export default BriefingAcquirersVoucher;
