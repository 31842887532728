import styled from 'styled-components';
import { Modal, Row } from 'antd';
import { colors } from 'styles/colors';
import { CheckCircleOutlined, WarningOutlined } from '@ant-design/icons';

export const StModal = styled(Modal)`
  .ant-modal-header,
  .ant-modal-footer {
    border: none;
  }
`;

export const StModalHeaderRow = styled(Row)`
  display: flex;
  align-items: center;
  gap: 16px;
  padding-top: 16px;
`;

export const StModalBodyRow = styled(Row)`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const StModalFooterRow = styled(Row)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 8px;
`;

export const StCheckCircleOutlined = styled(CheckCircleOutlined)`
  color: ${colors.primary6};
  font-size: 21px;
`;

export const StWarningOutlined = styled(WarningOutlined)`
  color: ${colors.gold6};
  font-size: 21px;
`;
