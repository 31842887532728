import { lambdaApi } from 'services/api';

const transformer = (apiResponse) => {
  const merchants = {
    merchants: apiResponse.data.merchants,
    meta: apiResponse.meta,
  };
  return merchants;
};

const getFeeMerchants = async ({ page, perPage, filter }) => {
  const config = {
    params: {
      page,
      per_page: perPage,
      q: JSON.stringify({ filter }),
    },
  };
  const response = await lambdaApi().get('/fees/merchants', config);
  return transformer(response.data);
};

export default getFeeMerchants;
