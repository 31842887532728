import { call, put } from 'redux-saga/effects';
import errorHandler from 'helpers/errorHandler';
import customHistory from 'helpers/history';
import authSignIn, {
  authLoginSource,
  validateTokenFromAPI,
} from 'services/auth';
import {
  authSuccess,
  authFailure,
  validateTokenSuccess,
  validateTokenFailure,
} from './actions';
import {
  removeLocalStorage,
  setSessionAccessToken,
  setSessionClientCode,
  setSessionClientCreatedAt,
  setSessionClientId,
  setSessionClientName,
  setSessionClientPlanId,
  setSessionClientSkinId,
  setSessionFirstName,
  setSessionIsImplanted,
  setSessionProfileId,
  setSessionScopeId,
  setSessionSurname,
  setSessionUsername,
  setSessionUsernameId,
} from 'helpers/sessionService';

export function* authSaga(action) {
  const { formData } = action.payload;
  const { source } = formData;
  try {
    const cognitoData = yield call(authSignIn, formData);
    yield removeLocalStorage();
    yield setSessionAccessToken(cognitoData?.accessToken);
    yield setSessionUsername(cognitoData?.username);
    yield setSessionClientName(cognitoData?.clientName);
    yield setSessionClientId(cognitoData?.clientId);
    yield setSessionClientCode(cognitoData?.clientCode);
    yield setSessionClientPlanId(cognitoData?.clientPlanId);
    yield setSessionClientCreatedAt(cognitoData?.clientCreatedAt);
    yield setSessionClientSkinId(cognitoData?.clientSkinId);
    yield setSessionScopeId(cognitoData?.userScopeId);
    yield setSessionProfileId(cognitoData?.userProfileId);
    yield setSessionIsImplanted(cognitoData?.isImplanted);
    yield setSessionFirstName(cognitoData?.userFirstName);
    yield setSessionSurname(cognitoData?.userSurname);
    yield setSessionUsernameId(cognitoData?.usernameId);
    yield call(authLoginSource, source);
    yield put(authSuccess(cognitoData));
    customHistory.push('/dashboard');
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(authFailure(err.response.data.error, err.response.data.error));
      errorHandler(err?.response?.data?.error?.message, err);
    } else if (err?.response?.data?.error) {
      yield put(authFailure(err.response.data.error, err.response.data.error));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}

export function* validateTokenSaga() {
  try {
    const apiData = yield call(validateTokenFromAPI);
    yield put(validateTokenSuccess(apiData));
  } catch (err) {
    if (
      window?.location?.pathname !== '/' &&
      window?.location?.pathname !== '/redefinir-a-senha' &&
      window?.location?.pathname !== '/esqueci-a-senha'
    ) {
      customHistory.push('/');
      errorHandler(
        'Sua sessão expirou, por favor, entre novamente com seu login e senha',
        err
      );
    }
    // In case of any api-related error.
    if (err?.response?.data?.error?.message) {
      errorHandler(err?.response?.data?.error?.message, err);
    } else if (err?.response?.data?.error) {
      errorHandler('Ocorreu um erro:', err);
    }
    yield put(
      validateTokenFailure(
        err?.response?.data?.error,
        err?.response?.data?.error
      )
    );
  }
}
