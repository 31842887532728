import { bankingApi } from '../api';

const transformer = (apiResponse) => {
  return {
    data: apiResponse?.payload,
  };
};

const postInteractAccessRequest = async ({ clientId, userId }) => {
  const config = {
    clientId,
    userId,
  };
  const response = await bankingApi().post('/credit/interact/access', config);
  return transformer(response.data);
};

export default postInteractAccessRequest;
