import React, { createContext, useEffect } from 'react';
import { Col, Row, Spin } from 'antd';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { getSessionAccessToken } from 'helpers/sessionService';
import { Header } from 'commons/Header';
import { useUserRoutes } from 'utils/hooks/useUserRoutes';
import { NotFound } from 'components/NotFound/NotFound';
import { usePermissions } from 'utils/hooks/usePermissions';

export const PermissionsContext = createContext({});

export const PrivateRoute = () => {
  const { pathname } = useLocation();
  const { permissions, fetchPermissions } = usePermissions();
  const planId = permissions?.plan_id;
  const scopeId = permissions?.scope_id;
  const skinId = permissions?.skin_id;

  const { defaultRoute, hasPermission } = useUserRoutes({
    planId,
    scopeId,
    skinId,
  });
  const isAuthenticated = getSessionAccessToken();

  useEffect(() => {
    if (isAuthenticated) {
      fetchPermissions();
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  if (!planId) {
    return (
      <Row justify="center" align="middle" style={{ height: '100%' }}>
        <Col>
          <Spin size="large" />
        </Col>
      </Row>
    );
  }

  if (Boolean(isAuthenticated) && !hasPermission({ pathname })) {
    return <NotFound redirectRoute={defaultRoute} />;
  }

  return (
    <PermissionsContext.Provider value={{ permissions }}>
      <Header />
      <Outlet />
    </PermissionsContext.Provider>
  );
};
