/* eslint-disable no-unused-expressions */
// TODO: Remove camelcase eslint rule
/* eslint-disable camelcase */
import { isAcquirerIgnored } from 'components/BankConciliationTransactions/helpers/acquirerStatus';
import { getSessionClientId } from 'helpers/sessionService';
import { api, lambdaApi } from './api';
import dayjs from 'dayjs';
import { shortId } from 'helpers/shortId';

const formatValue = (value) => {
  if (typeof value === 'string') {
    return Number(
      parseFloat(value.replace(/[^0-9,-]*/g, '').replace(',', '.')).toFixed(2)
    );
  }
  return value;
};

const transformBankConciliation = ({ meta, data }) => {
  return {
    count: meta.total,
    hasNext: meta.has_next,
    perPage: meta.per_page,
    startDate: meta.start_date,
    endDate: meta.end_date,
    order: meta.order,
    orderBy: meta.order_by,
    filters: meta.filters,
    data: data.conciliation,
    dataBeingProcessed: meta.is_processing,
  };
};
const transformBankConciliationTransactions = ({ meta, data }) => {
  const modifiedData = data.transactions.map((t) => {
    return { ...t, key: shortId() };
  });

  return {
    accountId: meta.bank_info.account_id,
    bank: meta.bank,
    bankCode: meta.bank_code,
    bankBranch: meta.bank_branch,
    bankAccount: meta.bank_account,
    startDate: meta.start_date,
    endDate: meta.end_date,
    data: modifiedData,
    count: meta.total,
    countersIdentified: meta.counters.identified,
    countersIgnored: meta.counters.ignored,
    countersNotIdentified: meta.counters.not_identified,
    countersTotal: meta.counters.total,
    hasNoTransactions: !data.transactions.length,
    totalAcquirer: formatValue(data.total_acquirer),
    difference: formatValue(data.difference),
    ignoredTransactions: data?.transactions?.filter((tr) =>
      isAcquirerIgnored(tr.acquirer_name)
    ),
  };
};

export const listBankConciliation = async ({
  startDate,
  endDate,
  sortFields,
  page,
  perPage,
  q,
}) => {
  const config = {
    params: {
      start_date: startDate.format('YYYY-MM-DD'),
      end_date: endDate.format('YYYY-MM-DD'),
      page,
      per_page: perPage,
      sort_fields: JSON.stringify(sortFields),
      q: JSON.stringify(q),
    },
  };
  const response = await lambdaApi().get(
    '/intermediate/bank/reconciled',
    config
  );
  return transformBankConciliation(response.data);
};

export const listBankConciliationTransactions = async ({
  bank,
  bank_branch,
  bank_account,
  start_date,
  end_date,
  account_id,
  search_by,
  filter,
  acquirer_name,
  totalizersNotRequired,
}) => {
  const config = {
    params: {
      client: getSessionClientId(),
      bank,
      bank_branch,
      bank_account,
      start_date: dayjs(start_date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      end_date: dayjs(end_date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      account_id,
      search_by:
        search_by && search_by.length > 0 ? search_by.toUpperCase() : '',
      filter,
      acquirer_name,
      totalizer_required: !totalizersNotRequired,
    },
  };
  (bank !== undefined || bank !== '') &&
    sessionStorage.setItem('tr_report_bank', bank);
  (bank_branch !== undefined || bank_branch !== '') &&
    sessionStorage.setItem('tr_report_bankBranch', bank_branch);
  (bank_account !== undefined || bank_account !== '') &&
    sessionStorage.setItem('tr_report_bankAccount', bank_account);
  (start_date !== undefined || start_date !== '') &&
    sessionStorage.setItem('tr_report_startDate', start_date);
  (end_date !== undefined || end_date !== '') &&
    sessionStorage.setItem('tr_report_endDate', end_date);
  (search_by !== undefined || search_by !== '') &&
    sessionStorage.setItem('tr_report_filterText', search_by);
  // Seta como vazio quando vier null
  search_by === null && sessionStorage.setItem('tr_report_filterText', '');
  (filter !== undefined || filter !== '') &&
    sessionStorage.setItem('tr_report_filter', filter);
  // Seta como vazio quando vier null
  filter === null && sessionStorage.setItem('tr_report_filter', '');
  const response = await api().get('/bank/conciliation/transactions', config);
  return transformBankConciliationTransactions(response.data);
};
export const updateBankConciliationTransactions = async ({
  transactions,
  startDate,
  endDate,
  perPage,
  page,
}) => {
  await api().post('/bank/conciliation/transactions', {
    client_id: getSessionClientId(),
    transactions: transactions,
    start_date: dayjs(startDate, 'DD/MM/YYYY').toISOString(),
    end_date: dayjs(endDate, 'DD/MM/YYYY').toISOString(),
    per_page: perPage,
    page: page,
  });
};
