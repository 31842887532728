import React, { useContext, useEffect, useState } from 'react';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { colors } from 'styles/colors';
import { StCollapse, StContainer, StPanel, StSpin } from './styled';
import { Header } from './components/Header';
import useAcquirerList from 'components/Fees/hooks/useAcquirerList';
import { FeesContext } from 'components/Fees/Fees';
import { Card, Col, Row } from 'antd';
import { ErrorCard } from 'commons/ErrorCard';
import { Empty } from 'commons/Empty';
import { MAX_CSV_ROWS, MAX_EXCEL_ROWS } from 'constants/general';

const Collapse = ({ children }) => {
  const {
    selectedStore,
    currentActivePanel,
    setCurrentActivePanel,
    activeTab,
    activeDetailTab,
    setDisableExcel,
    setDisableCSV,
  } = useContext(FeesContext);

  const { value: selectedStoreId } = selectedStore;
  const [page, setPage] = useState(1);
  const perPage = 100;

  const handlePanelChange = (key) => {
    if (key) {
      setCurrentActivePanel(key);
    } else {
      setCurrentActivePanel('');
    }
  };

  useEffect(() => {
    if (currentActivePanel) {
      setCurrentActivePanel(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStore]);

  const { isLoading, isError, acquirers, metaData } = useAcquirerList(
    selectedStoreId,
    page,
    setPage,
    perPage
  );

  useEffect(() => {
    if (activeTab === 'fees-consolidated') {
      if (metaData?.totalConsolidatedFees > MAX_EXCEL_ROWS) {
        setDisableExcel(true);
      } else {
        setDisableExcel(false);
      }
      if (metaData?.totalConsolidatedFees > MAX_CSV_ROWS) {
        setDisableCSV(true);
      } else {
        setDisableCSV(false);
      }
      return;
    }

    if (activeTab === 'fees-details') {
      if (activeDetailTab === 'vigentes') {
        if (metaData?.totalCurrentFees > MAX_EXCEL_ROWS) {
          setDisableExcel(true);
        } else {
          setDisableExcel(false);
        }
        if (metaData?.totalCurrentFees > MAX_CSV_ROWS) {
          setDisableCSV(true);
        } else {
          setDisableCSV(false);
        }
      } else {
        if (metaData?.totalExpiredFees > MAX_EXCEL_ROWS) {
          setDisableExcel(true);
        } else {
          setDisableExcel(false);
        }
        if (metaData?.totalExpiredFees > MAX_CSV_ROWS) {
          setDisableCSV(true);
        } else {
          setDisableCSV(false);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metaData, activeTab, activeDetailTab]);

  // Render Error Card
  if (isError) {
    return (
      <Row style={{ marginTop: '192px', justifyContent: 'center' }}>
        <Col lg={10} md={18}>
          <Card style={{ borderRadius: 4 }} loading={isLoading}>
            <ErrorCard
              title="Dados indisponíveis"
              reason="Não conseguimos buscar os dados, mas já estamos trabalhando para normalizar a situação."
              recommendation="Tente atualizar a página em alguns instantes."
            />
          </Card>
        </Col>
      </Row>
    );
  }

  if (!isLoading && acquirers?.length <= 0) {
    return (
      <Row
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Empty
          title="Nenhum registro encontrado"
          reason="Verifique sua pesquisa e tente novamente."
        />
      </Row>
    );
  }

  return (
    <StContainer>
      {acquirers && acquirers?.length > 0 && (
        <StCollapse
          accordion
          destroyInactivePanel
          ghost
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
          onChange={handlePanelChange}
        >
          {acquirers?.map((acquirerInfo) => {
            const { acquirer, detail } = acquirerInfo;
            return (
              <StPanel
                className="custom-collapse-panel"
                key={detail?.acquirer_id}
                style={{
                  width: '100%',
                  background: colors.gray1,
                  borderRadius: '4px 4px 0 0',
                  marginBottom: 24,
                }}
                showArrow={false}
                collapsible="header"
                extra={
                  <Header
                    className="panel-header"
                    acquirer={acquirerInfo}
                    key={String(acquirer)}
                  />
                }
                header={
                  detail?.has_fees && (
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 16,
                        padding: '8px 0',
                        backgroundColor: colors.gray2,
                      }}
                    >
                      {Number(currentActivePanel) ===
                      Number(detail?.acquirer_id) ? (
                        <>
                          <span>Ocultar taxas</span>
                          <UpOutlined />
                        </>
                      ) : (
                        <>
                          <span>Mostrar taxas</span>
                          <DownOutlined />
                        </>
                      )}
                    </div>
                  )
                }
              >
                {Number(currentActivePanel) === Number(detail?.acquirer_id) &&
                  children}
              </StPanel>
            );
          })}
        </StCollapse>
      )}
      {isLoading && <StSpin />}
    </StContainer>
  );
};

export default Collapse;
