import store from 'store';
import { call, put } from 'redux-saga/effects';

import errorHandler from 'helpers/errorHandler';

import {
  tokenProdPostSuccess,
  tokenProdDeleteSuccess,
  tokenProdGetSuccess,
  tokenProdPutSuccess,
  tokenProdPostError,
  tokenProdDeleteError,
  tokenProdGetError,
  tokenProdPutError,
  tokenProdGetStart,
  tokenProdDisableTokenSuccess,
  tokenProdDisableTokenStart,
  tokenProdDisableTokenError,
  rowClientIdProdSuccess,
  rowClientIdProdError,
} from 'store/ducks/tokenProd/actions';
import {
  postToken,
  deleteToken,
  getToken,
  putToken,
} from 'services/token/token';
import { notification } from 'antd';

export function* tokenProdPostSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(postToken, payload);

    yield put(tokenProdPostSuccess(apiData));
    if (apiData?.message.includes('somente para')) {
      notification.info({ message: apiData?.message });
    } else {
      notification.success({ message: apiData?.message });
    }
    store.dispatch(
      tokenProdGetStart({
        type: 'PRODUCTION',
        clientId: store.getState().tokenSandbox?.rowClientId,
        hidden: false,
      })
    );
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        tokenProdPostError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      errorHandler(String(err));
      yield put(tokenProdPostError('Ocorreu um erro:', err));
    }
  }
}
export function* tokenProdDeleteSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(deleteToken, payload);

    yield put(tokenProdDeleteSuccess(apiData));
    notification.success({
      message: apiData?.message,
    });
    store.dispatch(tokenProdDisableTokenStart({ value: false }));
    store.dispatch(
      tokenProdGetStart({
        type: 'PRODUCTION',
        clientId: store.getState().tokenSandbox?.rowClientId,
        hidden: false,
      })
    );
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        tokenProdDeleteError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      errorHandler(String(err));
      yield put(tokenProdDeleteError('Ocorreu um erro:', err));
    }
  }
}
export function* tokenProdGetSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(getToken, payload);

    yield put(tokenProdGetSuccess(apiData));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        tokenProdGetError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      errorHandler(`Erro no processo de autenticação: ${String(err)}`);
      yield put(tokenProdGetError('Ocorreu um erro:', err));
    }
  }
}
export function* tokenProdPutSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(putToken, payload);

    yield put(tokenProdPutSuccess(apiData));
    notification.success({ message: apiData?.message });
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        tokenProdPutError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      errorHandler(String(err));
      yield put(tokenProdPutError('Ocorreu um erro:', err));
    }
  }
}
export function* tokenProdDisableTokenSaga(action) {
  try {
    const { payload } = action;

    yield put(tokenProdDisableTokenSuccess(payload));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        tokenProdDisableTokenError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    }
  }
}
export function* rowClientIdProdSaga(action) {
  try {
    const { payload } = action;

    yield put(rowClientIdProdSuccess(payload));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        rowClientIdProdError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    }
  }
}
