import React from 'react';
import { StContainer, StCol, StRow } from './styled';

const FeeHeader = ({ children }) => {
  return (
    <StContainer>
      <StCol span={24}>
        <h1>Cadastro de taxas</h1>

        <StRow>
          <span>
            Gerencie suas taxas, através das opções de cadastro, edição ou
            exclusão
          </span>
          {children}
        </StRow>
      </StCol>
    </StContainer>
  );
};

export default FeeHeader;
