import { action } from 'typesafe-actions';
import { actionTypes } from './types';

// Listing
export const listFilesStart = (data) =>
  action(actionTypes.LIST_FILES_START, data);
export const listFilesAlter = (data) =>
  action(actionTypes.LIST_FILES_ALTER, data);
export const listFilesSuccess = (data) =>
  action(actionTypes.LIST_FILES_SUCCESS, data);
export const listFilesError = (errorMessage, error) =>
  action(actionTypes.LIST_FILES_ERROR, { errorMessage }, undefined, { error });

// Remove
export const removeFileStart = (data) =>
  action(actionTypes.REMOVE_FILES_START, data);
export const removeFileSuccess = (data) =>
  action(actionTypes.REMOVE_FILES_SUCCESS, data);
export const removeFileError = (errorMessage, error) =>
  action(actionTypes.REMOVE_FILES_ERROR, { errorMessage }, undefined, {
    error,
  });

// Upload
export const uploadFileStart = (data) =>
  action(actionTypes.UPLOAD_FILE_START, data);
export const uploadFileSuccess = (data) =>
  action(actionTypes.UPLOAD_FILE_SUCCESS, data);
export const uploadFileError = (errorMessage, error) =>
  action(actionTypes.UPLOAD_FILE_ERROR, { errorMessage }, undefined, { error });

// Update File Date
export const updateFileDateStart = (data) =>
  action(actionTypes.UPDATE_FILE_DATE_START, data);
export const updateFileDateSuccess = (data) =>
  action(actionTypes.UPDATE_FILE_DATE_SUCCESS, data);
export const updateFileDateError = (errorMessage, error) =>
  action(actionTypes.UPDATE_FILE_DATE_ERROR, { errorMessage }, undefined, {
    error,
  });

export const updateCurrentPageStart = (data) =>
  action(actionTypes.UPDATE_CURRENT_PAGE_START, data);
export const updateCurrentPageSuccess = (data) =>
  action(actionTypes.UPDATE_CURRENT_PAGE_SUCCESS, data);
export const updateCurrentPageError = (errorMessage, error) =>
  action(actionTypes.UPDATE_CURRENT_PAGE_ERROR, { errorMessage }, undefined, {
    error,
  });
