import React, { useContext } from 'react';
import { OnboardingManagementContext } from 'components/OnboardingManagement/OnboardingManagement';
import { StatusContainer, StatusLabel } from './styled';

export function Status({ currentRow = {} }) {
  const { tableStatusParser } = useContext(OnboardingManagementContext);
  const { bgColor, border, width, icon, labelColor, label } =
    tableStatusParser[currentRow?.status];

  return (
    <StatusContainer bgColor={bgColor} border={border} width={width}>
      {icon}
      <StatusLabel $labelColor={labelColor}>{label}</StatusLabel>
    </StatusContainer>
  );
}
