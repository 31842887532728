import React, { forwardRef } from 'react';
import { Input } from 'antd';

const InputInteger = forwardRef(({ onChange, ...props }, ref) => {
  const handleChange = (e) => {
    const integerNumber = parseInt(e.target.value, 10);

    onChange(Number.isNaN(integerNumber) ? '' : integerNumber);
  };

  return (
    <Input
      ref={ref}
      type="number"
      step={1}
      onChange={handleChange}
      {...props}
    />
  );
});

export default InputInteger;
