import { lambdaApi } from '../api';

const transformer = (apiResponse) => {
  const { data, meta } = apiResponse;

  return {
    fees: data,
    meta,
  };
};

const getFeesConsolidated = async ({
  acquirerId,
  page,
  perPage,
  selectedStore,
}) => {
  const config = {
    params: {
      acquirer_id: acquirerId,
      page,
      per_page: perPage,
      store_id: selectedStore?.value,
    },
  };

  const response = await lambdaApi().get('/fees/consolidated/detail', config);

  return transformer(response.data);
};
export default getFeesConsolidated;
