import styled from 'styled-components';
import { Button, Table } from 'antd';

export const StTable = styled(Table)`
  margin-top: 20px;

  &&& {
    .ant-table-body {
      min-height: initial !important;
      overflow: auto !important;
    }
  }
`;

export const StHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 36px;
`;

export const StHeaderTitle = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StButton = styled(Button)`
  margin-right: 10px;
`;
