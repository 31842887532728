import { lambdaApi } from '../api';

const transformer = (apiResponse) => {
  const radar = {
    data: apiResponse?.data?.results,
    meta: apiResponse?.meta,
  };

  return radar;
};

// Lista todos os Radares
export const list = async ({ page, perPage, sortFields, q }) => {
  const config = {
    params: {
      page,
      per_page: perPage || 10,
      sort_fields: JSON.stringify(sortFields),
      q: JSON.stringify(q),
    },
  };
  const response = await lambdaApi().get('/radar/process', config);
  return transformer(response.data);
};
