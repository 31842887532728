import { action } from 'typesafe-actions';
import { actionTypes } from 'store/ducks/credit/creditListAvailable/types';

export const creditListAvailableStart = (data) =>
  action(actionTypes.CREDIT_LIST_AVAILABLE_START, data);
export const creditListAvailableSuccess = (data) =>
  action(actionTypes.CREDIT_LIST_AVAILABLE_SUCCESS, data);
export const creditListAvailableError = (errorMessage, error) =>
  action(actionTypes.CREDIT_LIST_AVAILABLE_ERROR, { errorMessage }, undefined, {
    error,
  });
