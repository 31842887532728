import { call, put } from 'redux-saga/effects';
import {
  getMerchants,
  postMerchant,
  deleteMerchant,
} from '../../../services/merchant';
import {
  getMerchantsError,
  getMerchantsSuccess,
  postMerchantError,
  postMerchantSuccess,
  deleteMerchantError,
  deleteMerchantSuccess,
} from './actions';
import errorHandlerNotification from '../../../helpers/errorHandler';
import { notification } from 'antd';

export function* getMerchantsSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(getMerchants, payload);
    yield put(getMerchantsSuccess(apiData));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        getMerchantsError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandlerNotification(
        err.response.data.error.message,
        err.response.data.error
      );
    } else {
      yield put(getMerchantsError('Ocorreu um erro:', err));
      errorHandlerNotification('Ocorreu um erro:', err);
    }
  }
}

export function* postMerchantSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(postMerchant, payload);
    yield put(postMerchantSuccess(apiData));
    notification.success({ message: 'Estabelecimento incluído com sucesso !' });
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        postMerchantError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandlerNotification(
        err.response.data.error.message,
        err.response.data.error
      );
    } else {
      yield put(postMerchantError('Ocorreu um erro:', err));
      errorHandlerNotification('Ocorreu um erro:', err);
    }
  }
}

export function* deleteMerchantSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(deleteMerchant, payload.data);
    yield put(deleteMerchantSuccess(apiData));
    notification.success({ message: 'Estabelecimento removido com sucesso !' });
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        deleteMerchantError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandlerNotification(
        err.response.data.error.message,
        err.response.data.error
      );
    } else {
      yield put(deleteMerchantError('Ocorreu um erro:', err));
      errorHandlerNotification('Ocorreu um erro:', err);
    }
  }
}
