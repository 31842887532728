/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';
import { LabelAndValue } from 'commons/LabelAndValue';
import { StSeeDetailsButton } from 'components/Intermediate/styled';
import { TitleAndIcon } from 'commons/TitleAndIcon';
import { EllipsisText } from 'commons/EllipsisText';
import { StIntermediateCard } from 'commons/Intermediate/styled';
import { shortId } from 'helpers/shortId';

const CardItem = ({ titleCard, dataCard, goToReports }) => {
  return (
    <StIntermediateCard
      bordered={false}
      headStyle={{ minHeight: '56px' }}
      title={
        <TitleAndIcon
          key={shortId()}
          titleText={
            <EllipsisText text={titleCard.toUpperCase()} length={20} />
          }
          titleFontSize="1.75rem"
        />
      }
      actions={[
        <div style={{ textAlign: 'end' }}>
          <StSeeDetailsButton
            type="link"
            size="small"
            fontSize="1.5rem"
            onClick={goToReports}
          >
            Ver relatório
          </StSeeDetailsButton>
        </div>,
      ]}
    >
      <Row>
        <Col>
          <LabelAndValue
            label={dataCard[0]?.title}
            labelColor="rgba(0,0,0,0.45)"
            value={dataCard[0]?.value}
            valueTextSize="medium"
          />
        </Col>
      </Row>
      <Row style={{ marginTop: '1rem' }}>
        <Col>
          <LabelAndValue
            label={dataCard[1]?.title}
            labelColor="rgba(0,0,0,0.45)"
            value={dataCard[1]?.value}
            valueTextSize="medium"
          />
        </Col>
      </Row>

      {dataCard.length >= 4 ? (
        <Row style={{ marginTop: '1rem' }}>
          <Col>
            <LabelAndValue
              label={dataCard[3]?.title}
              labelColor="rgba(0,0,0,0.45)"
              value={dataCard[3]?.value}
              valueTextSize="medium"
            />
          </Col>
        </Row>
      ) : (
        <Row style={{ marginTop: '7rem' }} />
      )}
      {dataCard.length >= 5 ? (
        <Row style={{ marginTop: '1rem' }}>
          <Col>
            <LabelAndValue
              label={dataCard[4]?.title}
              labelColor="rgba(0,0,0,0.45)"
              value={dataCard[4]?.value}
              valueTextSize="medium"
            />
          </Col>
        </Row>
      ) : dataCard.length <= 5 ? (
        <></>
      ) : (
        <Row style={{ marginTop: '7rem' }} />
      )}
      {dataCard.length === 6 ? (
        <Row style={{ marginTop: '1rem' }}>
          <Col>
            <LabelAndValue
              label={dataCard[5]?.title}
              labelColor="rgba(0,0,0,0.45)"
              value={dataCard[5]?.value}
              valueTextSize="medium"
            />
          </Col>
        </Row>
      ) : dataCard.length <= 5 ? (
        <></>
      ) : (
        <Row style={{ marginTop: '7rem' }} />
      )}
      <Row style={{ marginTop: '1rem' }}>
        <Col>
          <LabelAndValue
            label={dataCard[2]?.title}
            labelColor="rgba(0,0,0,0.45)"
            value={dataCard[2]?.value}
            valueTextSize="large"
          />
        </Col>
      </Row>
    </StIntermediateCard>
  );
};
CardItem.defaultProps = {
  dataCard: [],
};
CardItem.propTypes = {
  titleCard: PropTypes.string.isRequired,
  dataCard: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  goToReports: PropTypes.func.isRequired,
};

export default CardItem;
