import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Skeleton, Row, Col, Tooltip } from 'antd';
import {
  StTypographyTextLabel,
  StTypographyTextValue,
} from '../../../../../commons/LabelAndValue/styled';
import { Doughnut } from 'react-chartjs-3';

import { acquirerStart } from '../../../../../store/ducks/acquirer/actions';

import customHistory from 'helpers/history';
import PropTypes from 'prop-types';

import {
  StContainer,
  StLimiter,
  StButton,
  Title,
  TxDoughnut,
  PeBellow,
  PeAbove,
  PeEquals,
  PeNotRegistered,
} from './styled';
import { colors } from 'styles/colors';

const ContentRatesResume = ({ item }) => {
  const dispatch = useDispatch();
  const loadingAcquirer = useSelector((state) => state.acquirer.loading);

  useEffect(() => {
    dispatch(acquirerStart({}));
  }, [dispatch]);

  const goToRatesIndicators = () => {
    customHistory.push('/taxas-indicadores');
  };

  return (
    <Col span={6} Style="margin-bottom: 20px;">
      <Skeleton loading={loadingAcquirer} active>
        <StContainer>
          <StLimiter>
            <Row
              align="middle"
              justify="space-between"
              Style="border-bottom: 1px solid #f2f2f2;"
            >
              <Col>
                <Title>{item.title}</Title>
              </Col>
            </Row>
            <Row
              align="middle"
              justify="space-between"
              style={{ marginTop: '2rem' }}
              gutter={[32, 8]}
            >
              <Col span={24}>
                <div style={{ textAlign: 'right' }}>
                  <StTypographyTextLabel fontSize="1.5rem">
                    Diferenças estimadas
                  </StTypographyTextLabel>
                  <br />
                  <StTypographyTextValue
                    fontSize="2.5rem"
                    labelColor={
                      item.difference < 0 ? colors.red6 : colors.gray11
                    }
                    strong
                  >
                    {new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(item.difference)}
                  </StTypographyTextValue>
                </div>
              </Col>
            </Row>
            <Row
              align="middle"
              justify="space-between"
              Style="margin-top: 10px; margin-bottom: 10px;"
            >
              <Col span={4} align="middle" Style="margin-top: 8px;">
                <Doughnut
                  width={10}
                  height={10}
                  options={{
                    responsive: true,
                    maintainAspectRatio: true,
                    cutoutPercentage: 70,
                    tooltips: {
                      callbacks: {
                        label: '',
                      },
                    },
                  }}
                  data={{
                    datasets: [
                      {
                        data: [item.bellow, 100 - item.bellow],
                        backgroundColor: [colors.red6, colors.gray3],
                        borderWidth: 0,
                      },
                    ],
                  }}
                />
              </Col>
              <Col span={20}>
                <Tooltip
                  placement="bottom"
                  title={
                    item.bellow +
                    '% das transações do período possuem taxas cobradas maiores que as taxas cadastradas'
                  }
                >
                  <TxDoughnut>Cobrada maior que cadastrada</TxDoughnut>
                  <PeBellow>{item.bellow}%</PeBellow>
                </Tooltip>
              </Col>
            </Row>
            <Row
              align="middle"
              justify="space-between"
              Style="margin-top: 10px; margin-bottom: 10px;"
            >
              <Col span={4} align="middle" Style="margin-top: 8px;">
                <Doughnut
                  width={10}
                  height={10}
                  options={{
                    responsive: true,
                    maintainAspectRatio: true,
                    cutoutPercentage: 70,
                    tooltips: {
                      callbacks: {
                        label: '',
                      },
                    },
                  }}
                  data={{
                    datasets: [
                      {
                        data: [item.above, 100 - item.above],
                        backgroundColor: ['#f5ba22', colors.gray3],
                        borderWidth: 0,
                      },
                    ],
                  }}
                />
              </Col>
              <Col span={20}>
                <Tooltip
                  placement="bottom"
                  title={
                    item.above +
                    '% das transações do período possuem taxas cobradas menores que as taxas cadastradas'
                  }
                >
                  <TxDoughnut>Cobrada menor que cadastrada</TxDoughnut>
                  <PeAbove>{item.above}%</PeAbove>
                </Tooltip>
              </Col>
            </Row>
            <Row
              align="middle"
              justify="space-between"
              Style="margin-top: 10px; margin-bottom: 10px;"
            >
              <Col span={4} align="middle" Style="margin-top: 8px;">
                <Doughnut
                  width={10}
                  height={10}
                  options={{
                    responsive: true,
                    maintainAspectRatio: true,
                    cutoutPercentage: 70,
                    tooltips: {
                      callbacks: {
                        label: '',
                      },
                    },
                  }}
                  data={{
                    datasets: [
                      {
                        data: [item.equals, 100 - item.equals],
                        backgroundColor: ['#4caa55', colors.gray3],
                        borderWidth: 0,
                      },
                    ],
                  }}
                />
              </Col>
              <Col span={20}>
                <Tooltip
                  placement="bottom"
                  title={
                    item.equals +
                    '% das transações do período possuem taxas cobradas iguais às taxas cadastradas'
                  }
                >
                  <TxDoughnut>Cobrada igual a cadastrada</TxDoughnut>
                  <PeEquals>{item.equals}%</PeEquals>
                </Tooltip>
              </Col>
            </Row>
            <Row
              align="middle"
              justify="space-between"
              Style="margin-top: 10px; margin-bottom: 10px;"
            >
              <Col span={4} align="middle" Style="margin-top: 8px;">
                <Doughnut
                  width={10}
                  height={10}
                  options={{
                    responsive: true,
                    maintainAspectRatio: true,
                    cutoutPercentage: 70,
                    tooltips: {
                      callbacks: {
                        label: '',
                      },
                    },
                  }}
                  data={{
                    datasets: [
                      {
                        data: [item.notregistered, 100 - item.notregistered],
                        backgroundColor: ['#8e8e8e', colors.gray3],
                        borderWidth: 0,
                      },
                    ],
                  }}
                />
              </Col>
              <Col span={20}>
                <Tooltip
                  placement="bottom"
                  title={
                    item.notregistered +
                    '% das transações do período possuem taxas cobradas maiores que as taxas cadastradas'
                  }
                >
                  <TxDoughnut>Taxa não cadastrada</TxDoughnut>
                  <PeNotRegistered>{item.notregistered}%</PeNotRegistered>
                </Tooltip>
              </Col>
            </Row>
            <Row
              align="middle"
              justify="space-between"
              Style="border-top: 1px solid #f2f2f2;"
            >
              <Col span={24} style={{ textAlign: 'right' }}>
                <StButton
                  type="link"
                  onClick={goToRatesIndicators}
                  style={{ paddingRight: '0px' }}
                >
                  Ver taxas detalhadas
                </StButton>
              </Col>
            </Row>
          </StLimiter>
        </StContainer>
      </Skeleton>
    </Col>
  );
};

ContentRatesResume.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    difference: PropTypes.number.isRequired,
    bellow: PropTypes.number.isRequired,
    above: PropTypes.number.isRequired,
    equals: PropTypes.number.isRequired,
    notregistered: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
};

export default ContentRatesResume;
