import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
  useRef,
  useCallback,
  useContext,
} from 'react';
import { Button, Card, Col, Form, Input, Row, Tooltip } from 'antd';
import { HeaderRegisters } from '../../../../commons/HeaderRegisters';
import { Loadable } from '../../../../commons/Loadable';
import { useDispatch, useSelector } from 'react-redux';
import {
  getStoresStart,
  postStoreStart,
  deleteStoreStart,
} from '../../../../store/ducks/store/actions';
import LabelAndInputs from '../../../../commons/LabelAndInputs/LabelAndInputs';
import ptBR from 'antd/es/date-picker/locale/pt_BR';
import MaskedInput from 'antd-mask-input';
import { StCpfCnpj, StNewCnpjButton, StSpin, StWarning } from './styled';
import LabelAndSelect from '../../../../commons/LabelAndSelect/LabelAndSelect';
import { getCepStart } from '../../../../store/ducks/viaCep/actions';
import { PlusOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { StoreAddForm } from './StoreAddForm';
import { getCnpjStart } from '../../../../store/ducks/receitaWebservice/actions';
import {
  generateStoreShortName,
  states,
} from '../../../../helpers/briefingService';
import { validateStoreName } from '../../../../services/briefing/validateStoreName';
import { DataChangedFilter } from 'components/Briefing/utils/DataChangedFilter';
import useStoreList from 'components/CnpjMaintenance/hooks/useStoreList';
import { StoreListItem } from './StoreListItem';
import { ErrorCard } from 'commons/ErrorCard';
import { shortId } from 'helpers/shortId';
import { InputMaskCep } from 'commons/InputMaskCep';
import { debounce } from 'helpers/debounce/debounce';
import { PermissionsContext } from 'routes/PrivateRoute';
import scopes from 'constants/scopes';
import { isOperational } from 'helpers/authorizationService';

let viaCepWasCalled = false;
let receitaWsWasCalled = false;
let isCnpjValid = false;

const BriefingStoresRegister = forwardRef(
  (
    {
      isLocked,
      setIsNextButtonLocked,
      afterValidateNextPage,
      isCNPJMaintenance = false,
    },
    ref
  ) => {
    const {
      permissions: { scope_id: scopeId },
    } = useContext(PermissionsContext);
    const dispatch = useDispatch();
    const loadingStores = useSelector((state) => state.store.loading);
    const storeData = useSelector((state) => state.store.stores);
    const deleted = useSelector((state) => state.store.deleted);
    const loadingViaCep = useSelector((state) => state.viaCep.loading);
    const viaCepData = useSelector((state) => state.viaCep.data);
    const receitaWsData = useSelector((state) => state.receitaWebservice.data);
    const [stores, setStores] = useState(storeData?.stores);
    const storeHasBeenPosted = useSelector(
      (state) => state.store.storeHasBeenPosted
    );
    const [headOfficeStore, setHeadOfficeStore] = useState({});

    // eslint-disable-next-line no-unused-vars
    const [cpfCnpj, setCpfCnpj] = useState('');
    // eslint-disable-next-line no-unused-vars
    const [cpfCnpjMask, setCpfCnpjMask] = useState('');

    const [form] = Form.useForm();
    const formRef = useRef(null);

    const [isAddingStore, setIsAddingStore] = useState(false);
    const [shortNameStatus, setShortNameStatus] = useState('');
    const [showWarning, setShowWarning] = useState(false);

    const [cnpjMaintenanceAllStores, setCnpjMaintenanceAllStores] = useState(
      []
    );
    const [cnpjMaintenanceHeadOfficeStore, setCnpjMaintenanceHeadOfficeStore] =
      useState([]);
    const [cnpjMaintenanceOthersStores, setCnpjMaintenanceOthersStores] =
      useState([]);

    const [page, setPage] = useState(1);
    const perPage = 10;
    // eslint-disable-next-line no-unused-vars
    const searchFilter = null;

    const {
      isLoading,
      isError,
      stores: cnpjMaintenanceStores,
      metaData,
      refreshStores,
    } = useStoreList(searchFilter, page, setPage, perPage);
    const { has_next: hasMoreStores } = metaData;

    // eslint-disable-next-line no-unused-vars
    let callAfterValidate;

    useImperativeHandle(ref, () => ({
      validateBeforeNextPage(afterValidate) {
        callAfterValidate = afterValidate;
        setIsAddingStore(false);
        form.submit();
      },
    }));

    useEffect(() => {
      if (!isCNPJMaintenance) {
        dispatch(getStoresStart({}));
        setIsNextButtonLocked(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const mergeAndSetFieldsValue = ({
      brCnpj,
      name,
      shortName,
      zipCode,
      street,
      number,
      complement,
      district,
      state,
      city,
    }) => {
      form.setFieldsValue({
        br_cnpj: (brCnpj ?? form.getFieldValue('br_cnpj') ?? '').toUpperCase(),
        name: (name ?? form.getFieldValue('name') ?? '').toUpperCase(),
        short_name: (
          shortName ??
          form.getFieldValue('short_name') ??
          ''
        ).toUpperCase(),
        zip_code: (
          zipCode ??
          form.getFieldValue('zip_code') ??
          ''
        ).toUpperCase(),
        street: (street ?? form.getFieldValue('street') ?? '').toUpperCase(),
        number: (number ?? form.getFieldValue('number') ?? '').toUpperCase(),
        complement: (
          complement ??
          form.getFieldValue('complement') ??
          ''
        ).toUpperCase(),
        district: (
          district ??
          form.getFieldValue('district') ??
          ''
        ).toUpperCase(),
        state: (state ?? form.getFieldValue('state') ?? '').toUpperCase(),
        city: (city ?? form.getFieldValue('city') ?? '').toUpperCase(),
      });
    };

    useEffect(() => {
      if (!isCNPJMaintenance) {
        setStores(storeData?.stores);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storeData]);

    useEffect(() => {
      if (!isCNPJMaintenance) {
        if (stores) {
          setHeadOfficeStore(stores.find((s) => s.head_office === true));
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stores]);

    useEffect(() => {
      if (headOfficeStore && !isCNPJMaintenance) {
        mergeAndSetFieldsValue({
          brCnpj: headOfficeStore?.br_cnpj,
          name: headOfficeStore?.name,
          shortName: headOfficeStore?.short_name,
          zipCode: headOfficeStore?.address?.zip_code,
          street: headOfficeStore?.address?.street,
          number: headOfficeStore?.address?.number,
          complement: headOfficeStore?.address?.complement,
          district: headOfficeStore?.address?.district,
          state: headOfficeStore?.address?.state,
          city: headOfficeStore?.address?.city,
        });
        isCnpjValid = true;

        if (viaCepData && viaCepWasCalled) {
          mergeAndSetFieldsValue({
            zipCode: viaCepData?.cep?.replace('.', ''),
            street: viaCepData?.logradouro,
            complement: viaCepData?.complemento,
            district: viaCepData?.bairro,
            state: viaCepData?.uf,
            city: viaCepData?.localidade,
          });

          viaCepWasCalled = false;
        }
      } else {
        isCnpjValid = true;
      }

      if (cnpjMaintenanceHeadOfficeStore && isCNPJMaintenance) {
        mergeAndSetFieldsValue({
          brCnpj: cnpjMaintenanceHeadOfficeStore?.br_cnpj,
          name: cnpjMaintenanceHeadOfficeStore?.name,
          shortName: cnpjMaintenanceHeadOfficeStore?.short_name,
          zipCode: cnpjMaintenanceHeadOfficeStore?.address?.zip_code,
          street: cnpjMaintenanceHeadOfficeStore?.address?.street,
          number: cnpjMaintenanceHeadOfficeStore?.address?.number,
          complement: cnpjMaintenanceHeadOfficeStore?.address?.complement,
          district: cnpjMaintenanceHeadOfficeStore?.address?.district,
          state: cnpjMaintenanceHeadOfficeStore?.address?.state,
          city: cnpjMaintenanceHeadOfficeStore?.address?.city,
        });
        isCnpjValid = true;

        if (viaCepData && viaCepWasCalled) {
          mergeAndSetFieldsValue({
            zipCode: viaCepData?.cep?.replace('.', ''),
            street: viaCepData?.logradouro,
            complement: viaCepData?.complemento,
            district: viaCepData?.bairro,
            state: viaCepData?.uf,
            city: viaCepData?.localidade,
          });

          viaCepWasCalled = false;
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [headOfficeStore, viaCepData, cnpjMaintenanceHeadOfficeStore]);

    useEffect(() => {
      if (receitaWsData && receitaWsWasCalled && !isCNPJMaintenance) {
        let generatedShortName;
        if (receitaWsData.status === 'OK') {
          isCnpjValid = true;
          generatedShortName = generateStoreShortName(
            receitaWsData?.nome,
            receitaWsData?.fantasia,
            receitaWsData?.cnpj
          );
        } else {
          isCnpjValid = false;
        }

        mergeAndSetFieldsValue({
          brCnpj: receitaWsData?.cnpj,
          name: receitaWsData?.nome ?? receitaWsData?.fantasia,
          shortName: form.getFieldValue('short_name') || generatedShortName,
          zipCode: receitaWsData?.cep?.replace('.', ''),
          street: receitaWsData?.logradouro,
          number: receitaWsData?.number,
          complement: receitaWsData?.complemento,
          district: receitaWsData?.bairro,
          state: receitaWsData?.uf,
          city: receitaWsData?.municipio,
        });

        if (headOfficeStore && !isCNPJMaintenance) {
          isCnpjValid = true;
        }

        receitaWsWasCalled = false;
      }

      if (receitaWsData && receitaWsWasCalled && isCNPJMaintenance) {
        let generatedShortName;
        if (receitaWsData.status === 'OK') {
          isCnpjValid = true;
          generatedShortName = generateStoreShortName(
            receitaWsData?.nome,
            receitaWsData?.fantasia,
            receitaWsData?.cnpj
          );
        } else {
          isCnpjValid = false;
        }

        mergeAndSetFieldsValue({
          brCnpj: receitaWsData?.cnpj,
          name: receitaWsData?.nome ?? receitaWsData?.fantasia,
          shortName: form.getFieldValue('short_name') || generatedShortName,
          zipCode: receitaWsData?.cep?.replace('.', ''),
          street: receitaWsData?.logradouro,
          number: receitaWsData?.number,
          complement: receitaWsData?.complemento,
          district: receitaWsData?.bairro,
          state: receitaWsData?.uf,
          city: receitaWsData?.municipio,
        });

        receitaWsWasCalled = false;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [receitaWsData]);

    useEffect(() => {
      if (deleted) {
        setIsAddingStore(false);
        dispatch(getStoresStart({}));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleted]);

    const onRemoveStore = async (store) => {
      store.address_id = store.address?.address_id; // eslint-disable-line no-param-reassign
      dispatch(deleteStoreStart(store?.store_id));
    };

    useEffect(() => {
      // eslint-disable-next-line no-unused-expressions
      stores?.forEach((store) => {
        if (store?.head_office === false) {
          if (store?.br_cnpj) {
            form.setFieldsValue({
              [`br_cnpj_${store.store_id}`]: store.br_cnpj,
              [`name_${store.store_id}`]: store.name,
              [`short_name_${store.store_id}`]: store.short_name,
              [`store_code_${store.store_id}`]: store.store_code,
            });
          }
        }
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stores]);

    const [storeIdChangedCNPJ, setStoreIdChangedCNPJ] = useState('');

    const renderStoresList = () => {
      const storesList = [];
      // eslint-disable-next-line no-unused-expressions
      stores?.forEach((store) => {
        if (store?.head_office === false) {
          if (store?.br_cnpj) {
            storesList.push(
              <StoreListItem
                key={shortId()}
                isLocked={isLocked}
                onClickRemove={() => onRemoveStore(store)}
                storeId={store.store_id}
                setFieldsValue={formRef?.current?.setFieldsValue}
                isCNPJMaintenance={isCNPJMaintenance}
                canEditShortName={store?.can_edit}
                style={{ maxHeight: 370 }}
                scopeId={scopeId}
                hasTransactions={store?.has_transactions}
                storeIdChangedCNPJ={storeIdChangedCNPJ}
                setStoreIdChangedCNPJ={setStoreIdChangedCNPJ}
              />
            );
          }
        }
      });
      return storesList;
    };

    const observer = useRef();
    const lastStoreElementRef = useCallback(
      (node) => {
        if (isLoading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver((entries) => {
          if (entries[0].isIntersecting && hasMoreStores) {
            setPage((prev) => prev + 1);
          }
        });
        if (node) observer.current.observe(node);
      },
      [isLoading, hasMoreStores]
    );

    useEffect(() => {
      if (isCNPJMaintenance) {
        setCnpjMaintenanceAllStores(cnpjMaintenanceStores);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cnpjMaintenanceStores]);

    useEffect(() => {
      if (isCNPJMaintenance) {
        const newCnpjMaintenanceStores = [...cnpjMaintenanceAllStores];
        const cnpjMaintenanceHeadOffice = newCnpjMaintenanceStores.shift();

        setCnpjMaintenanceHeadOfficeStore(cnpjMaintenanceHeadOffice); // head office
        setCnpjMaintenanceOthersStores(newCnpjMaintenanceStores); // others stores
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cnpjMaintenanceAllStores]);

    useEffect(() => {
      // eslint-disable-next-line no-unused-expressions
      cnpjMaintenanceOthersStores?.forEach((store) => {
        if (store?.br_cnpj) {
          form.setFieldsValue({
            [`br_cnpj_${store.store_id}`]: store.br_cnpj,
            [`name_${store.store_id}`]: store.name,
            [`short_name_${store.store_id}`]: store.short_name,
            [`store_code_${store.store_id}`]: store.store_code,
          });
        }
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cnpjMaintenanceOthersStores]);

    const scrollToBottomStoreAddForm = (id) => {
      const element = document.getElementById(id);
      element.scrollIntoView({ behavior: 'smooth' });
      const brCnpjAddInput = document.getElementById('br_cnpj_add');
      brCnpjAddInput.focus({ preventScroll: true });
    };

    const addStore = () => {
      form
        .validateFields()
        .then(() => {
          try {
            setIsAddingStore(true);
            if (!isCNPJMaintenance) {
              scrollToBottomStoreAddForm('addForm');
            }
            // form.submit();
          } catch (error) {
            // eslint-disable-next-line no-console
            console.log('error:', error);
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log('error do catch:', error);
        });
    };

    const onAfterAddStore = () => {};

    useEffect(() => {
      if (storeHasBeenPosted) {
        dispatch(getStoresStart({}));
        setIsAddingStore(false);

        if (isCNPJMaintenance) {
          setPage(1);
          refreshStores();
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storeHasBeenPosted]);

    const handleValidateStoreName = async (storeName) => {
      setShortNameStatus('validating');
      const response = await validateStoreName(storeName);

      if (!response) {
        setShowWarning(false);
        setShortNameStatus('');
        return response;
      }
      setShowWarning(true);
      setShortNameStatus('warning');
      return response;
    };

    // eslint-disable-next-line consistent-return
    const onFinish = async (formValues) => {
      const data = {
        address: {
          city: formValues?.city,
          state: formValues?.state,
          region: headOfficeStore?.region,
          street: formValues?.street,
          number: formValues?.number,
          complement: formValues?.complement,
          district: formValues?.district,
          zip_code: formValues?.zip_code,
        },
        stores: [
          {
            name: formValues?.name,
            short_name: formValues?.short_name,
            head_office: true,
            store_code:
              headOfficeStore?.store_code ??
              formValues.br_cnpj.replace(/[^\w\s]/gi, ''),
            store_id: isCNPJMaintenance
              ? cnpjMaintenanceHeadOfficeStore?.store_id
              : headOfficeStore?.store_id,
            br_cnpj: formValues?.br_cnpj,
          },
        ],
      };

      const theLoop = () => {
        Object.keys(formValues)
          .filter((key) => key.startsWith('br_cnpj_'))
          .map((val) => val.replace('br_cnpj_', ''))
          .forEach((value) =>
            data.stores.push({
              name: formValues[`name_${value}`],
              short_name: formValues[`short_name_${value}`],
              head_office: false,
              store_id: Number(value),
              br_cnpj: formValues[`br_cnpj_${value}`],
              store_code:
                formValues[`store_code_${value}`] ||
                formValues[`br_cnpj_${value}`].replace(/[^\w\s]/gi, ''),
            })
          );

        const _stores = isCNPJMaintenance
          ? cnpjMaintenanceStores
          : storeData?.stores;
        const dataChanged = DataChangedFilter(_stores, data);

        if (dataChanged?.stores?.length > 0) {
          return dispatch(postStoreStart(dataChanged));
        }
        return false;
      };

      theLoop();

      if (!isAddingStore) {
        try {
          form
            .validateFields()
            .then(() => {
              afterValidateNextPage();
            })
            .catch((e) => {
              // eslint-disable-next-line no-console
              return console.log('e do catch1:', e);
            });
        } catch (error) {
          // eslint-disable-next-line no-console
          return console.log('error do catch2:', error);
        }
      }
    };

    const onChangeCnpj = (e, type) => {
      setCpfCnpj(e.target.value);
      setCpfCnpjMask(type === 'CPF');
      const cnpj = e.target.value.replace(/\D/g, '');
      if (cnpj?.length === 14) {
        receitaWsWasCalled = true;
        dispatch(getCnpjStart({ cnpj }));
      }
    };

    const onChangeCepBriefing = (e) => {
      const cep = e.target.value.replace(/\D/g, '');

      const debounceSearch = debounce(async (criteria) => {
        if (!viaCepWasCalled) {
          viaCepWasCalled = true;
          dispatch(getCepStart(criteria));
        }
      }, 100);

      if (cep?.length === 8) {
        debounceSearch({ cep });
      }
    };

    const onChangeCep = (e) => {
      const cep = e.target.value.replace(/\D/g, '');
      if (cep?.length === 8) {
        viaCepWasCalled = true;
        dispatch(getCepStart({ cep }));
      }
    };

    // Render Error Card
    if (isError) {
      return (
        <Row style={{ marginTop: '192px', justifyContent: 'center' }}>
          <Col lg={10} md={18}>
            <Card style={{ borderRadius: 4 }} loading={isLoading}>
              <ErrorCard
                title="Dados indisponíveis"
                reason="Não conseguimos buscar os dados, mas já estamos trabalhando para normalizar a situação."
                recommendation="Tente atualizar a página em alguns instantes."
              />
            </Card>
          </Col>
        </Row>
      );
    }

    return (
      <>
        {isCNPJMaintenance ? (
          <HeaderRegisters
            title="Cadastrar CNPJs"
            subTitle="Cadastre todos CNPJs que deseja adicionar"
            isCNPJMaintenance={isCNPJMaintenance}
          >
            <Loadable loading={isLoading && !cnpjMaintenanceStores?.length}>
              <>
                <Form form={form} ref={formRef} onFinish={onFinish}>
                  <fieldset disabled={isLocked}>
                    <Row gutter={[8, 8]}>
                      <Col lg={6}>
                        <LabelAndInputs label="CNPJ principal">
                          <Form.Item
                            name="br_cnpj"
                            rules={[
                              { required: true, message: 'Campo obrigatório!' },
                            ]}
                            style={{ marginBottom: 0 }}
                          >
                            <StCpfCnpj
                              placeholder="00.000.000/0000-00"
                              type="tel"
                              name="br_cnpj"
                              value={cnpjMaintenanceHeadOfficeStore?.br_cnpj}
                              onChange={(e, type) => {
                                onChangeCnpj(e, type);
                              }}
                              disabled
                            />
                          </Form.Item>
                        </LabelAndInputs>
                      </Col>
                      <Col lg={9}>
                        <LabelAndInputs label="Razão social">
                          <Form.Item
                            name="name"
                            rules={[
                              { required: true, message: 'Campo obrigatório!' },
                            ]}
                            style={{ marginBottom: 0 }}
                          >
                            <Input
                              placeholder="Razão social"
                              value={cnpjMaintenanceHeadOfficeStore?.name}
                              name="name"
                              locale={ptBR}
                              style={{ width: '100%' }}
                              disabled
                            />
                          </Form.Item>
                        </LabelAndInputs>
                      </Col>
                      <Col lg={9}>
                        Apelido da loja
                        <Tooltip title="Este é o nome pelo qual deseja identificar sua loja. Escolha um apelido fácil de lembrar para cada CNPJ e evite utilizar o mesmo apelido para lojas diferentes.">
                          <Button
                            type="link"
                            icon={<ExclamationCircleFilled />}
                            Style="color: #504e4e; width: 20px; height: 20px; padding: 0 0 0 5px; font-size: 13px; border-radius: 0px; vertical-align: 0px;"
                            // eslint-disable-next-line react/jsx-closing-tag-location
                          ></Button>
                        </Tooltip>
                        <br />
                        <Form.Item
                          name="short_name"
                          rules={[
                            { required: true, message: 'Campo obrigatório!' },
                          ]}
                          style={{ marginBottom: 0 }}
                        >
                          <Input
                            placeholder="Como quer chamar este CNPJ?"
                            value={cnpjMaintenanceHeadOfficeStore?.short_name}
                            name="short_name"
                            locale={ptBR}
                            style={{ width: '100%' }}
                            disabled={isCNPJMaintenance || !isCnpjValid}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={[8, 8]}>
                      <Col lg={4}>
                        <LabelAndInputs label="CEP">
                          <Form.Item
                            name="zip_code"
                            style={{ marginBottom: 0 }}
                          >
                            <MaskedInput
                              value={
                                cnpjMaintenanceHeadOfficeStore?.address
                                  ?.zip_code
                              }
                              onChange={onChangeCep}
                              placeholder="Insira o CEP"
                              maskOptions={{
                                mask: '00000-000',
                                lazy: true,
                              }}
                              name="zip_code"
                              disabled={
                                isCNPJMaintenance ||
                                !isCnpjValid ||
                                loadingViaCep ||
                                isLocked
                              }
                            />
                          </Form.Item>
                        </LabelAndInputs>
                      </Col>
                      <Col lg={10}>
                        <LabelAndInputs label="Endereço">
                          <Form.Item name="street" style={{ marginBottom: 0 }}>
                            <Input
                              placeholder="Insira o endereço"
                              value={
                                cnpjMaintenanceHeadOfficeStore?.address?.street
                              }
                              name="street"
                              locale={ptBR}
                              style={{ width: '100%' }}
                              disabled={
                                isCNPJMaintenance ||
                                !isCnpjValid ||
                                loadingViaCep ||
                                isLocked
                              }
                            />
                          </Form.Item>
                        </LabelAndInputs>
                      </Col>
                      <Col lg={4}>
                        <LabelAndInputs label="Número">
                          <Form.Item name="number" style={{ marginBottom: 0 }}>
                            <Input
                              placeholder="Insira o número"
                              value={
                                cnpjMaintenanceHeadOfficeStore?.address?.number
                              }
                              name="number"
                              locale={ptBR}
                              style={{ width: '100%' }}
                              disabled={
                                isCNPJMaintenance ||
                                !isCnpjValid ||
                                loadingViaCep ||
                                isLocked
                              }
                            />
                          </Form.Item>
                        </LabelAndInputs>
                      </Col>
                      <Col lg={6}>
                        <LabelAndInputs label="Complemento (opcional)">
                          <Form.Item
                            name="complement"
                            style={{ marginBottom: 0 }}
                          >
                            <Input
                              placeholder="Insira o complemento"
                              value={
                                cnpjMaintenanceHeadOfficeStore?.address
                                  ?.complement
                              }
                              name="complement"
                              locale={ptBR}
                              style={{ width: '100%' }}
                              disabled={
                                isCNPJMaintenance ||
                                !isCnpjValid ||
                                loadingViaCep ||
                                isLocked
                              }
                            />
                          </Form.Item>
                        </LabelAndInputs>
                      </Col>
                    </Row>
                    <Row gutter={[8, 8]}>
                      <Col lg={12}>
                        <LabelAndInputs label="Bairro">
                          <Form.Item
                            name="district"
                            style={{ marginBottom: 0 }}
                          >
                            <Input
                              placeholder="Insira o bairro"
                              value={
                                cnpjMaintenanceHeadOfficeStore?.address
                                  ?.district
                              }
                              name="district"
                              locale={ptBR}
                              style={{ width: '100%' }}
                              disabled={
                                isCNPJMaintenance ||
                                !isCnpjValid ||
                                loadingViaCep ||
                                isLocked
                              }
                            />
                          </Form.Item>
                        </LabelAndInputs>
                      </Col>
                      <Col lg={6}>
                        <LabelAndInputs label="Cidade">
                          <Form.Item name="city" style={{ marginBottom: 0 }}>
                            <Input
                              placeholder="Insira a cidade"
                              value={
                                cnpjMaintenanceHeadOfficeStore?.address?.city
                              }
                              name="city"
                              locale={ptBR}
                              style={{ width: '100%' }}
                              disabled={
                                isCNPJMaintenance ||
                                !isCnpjValid ||
                                loadingViaCep ||
                                isLocked
                              }
                            />
                          </Form.Item>
                        </LabelAndInputs>
                      </Col>
                      <Col lg={6}>
                        <Form.Item name="state" style={{ marginBottom: 0 }}>
                          <LabelAndSelect
                            label="Estado"
                            placeHolder="Selecione o estado"
                            labelOption="name"
                            valueOption="initials"
                            options={states}
                            loading={false}
                            value={
                              cnpjMaintenanceHeadOfficeStore?.address?.state
                            }
                            disabled={
                              isCNPJMaintenance ||
                              isLocked ||
                              !isCnpjValid ||
                              loadingViaCep
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <div
                      style={{
                        marginTop: '16px',
                        maxHeight: '350px',
                        overflowY: 'initial',
                        overflowX: 'hidden',
                      }}
                    >
                      {cnpjMaintenanceOthersStores &&
                        cnpjMaintenanceOthersStores?.map((store, index) => {
                          return (
                            <div
                              key={shortId()}
                              ref={
                                cnpjMaintenanceOthersStores?.length ===
                                index + 1
                                  ? lastStoreElementRef
                                  : null
                              }
                            >
                              <StoreListItem
                                key={shortId()}
                                isLocked={isLocked}
                                onClickRemove={() => {}}
                                storeId={Number(store?.store_id)}
                                setFieldsValue={
                                  formRef?.current?.setFieldsValue
                                }
                                isCNPJMaintenance={isCNPJMaintenance}
                              />
                            </div>
                          );
                        })}
                    </div>
                    {isAddingStore && !isLoading && (
                      <StoreAddForm
                        clientId={cnpjMaintenanceHeadOfficeStore?.client_id}
                        onAfterAdd={onAfterAddStore}
                        onCancel={() => setIsAddingStore(false)}
                        isCNPJMaintenance={isCNPJMaintenance}
                      />
                    )}
                  </fieldset>
                </Form>
              </>
            </Loadable>

            {!isAddingStore && !isLoading ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: 16,
                }}
              >
                <StNewCnpjButton
                  style={{ marginTop: 0 }}
                  type="primary"
                  shape="round"
                  icon={<PlusOutlined />}
                  ghost
                  onClick={() => addStore()}
                  disabled={isLocked || isLoading}
                >
                  Incluir outro CNPJ
                </StNewCnpjButton>
              </div>
            ) : isLoading && cnpjMaintenanceStores.length ? (
              <StSpin />
            ) : (
              <></>
            )}
          </HeaderRegisters>
        ) : (
          // registro de implantação
          <HeaderRegisters
            title={isCNPJMaintenance ? 'Cadastrar CNPJs' : 'Cadastro de CNPJs'}
            subTitle={
              isCNPJMaintenance
                ? 'Cadastre todos CNPJs que deseja adicionar'
                : 'Cadastre todos CNPJs que deseja ver na plataforma'
            }
            isCNPJMaintenance={isCNPJMaintenance}
          >
            <Loadable loading={loadingStores}>
              <>
                <Form form={form} ref={formRef} onFinish={onFinish}>
                  <fieldset disabled={isLocked}>
                    <Row gutter={[8, 8]}>
                      <Col lg={6}>
                        <LabelAndInputs label="CNPJ principal">
                          <Form.Item
                            name="br_cnpj"
                            rules={[
                              { required: true, message: 'Campo obrigatório!' },
                            ]}
                            style={{ marginBottom: isCNPJMaintenance ? 0 : 24 }}
                          >
                            <StCpfCnpj
                              placeholder="00.000.000/0000-00"
                              type="tel"
                              name="br_cnpj"
                              value={headOfficeStore?.br_cnpj}
                              onChange={(e, type) => {
                                onChangeCnpj(e, type);
                              }}
                              disabled={
                                isLocked ||
                                scopeId !== scopes.CONCIL ||
                                (stores?.length > 0 &&
                                  headOfficeStore &&
                                  headOfficeStore?.has_transactions) ||
                                (stores?.length > 0 &&
                                  headOfficeStore &&
                                  !headOfficeStore?.can_edit)
                              }
                            />
                          </Form.Item>
                        </LabelAndInputs>
                      </Col>
                      <Col lg={9}>
                        <LabelAndInputs label="Razão social">
                          <Form.Item
                            name="name"
                            rules={[
                              { required: true, message: 'Campo obrigatório!' },
                            ]}
                            style={{ marginBottom: isCNPJMaintenance ? 0 : 24 }}
                          >
                            <Input
                              placeholder="Razão social"
                              value={headOfficeStore?.name}
                              name="name"
                              locale={ptBR}
                              style={{ width: '100%' }}
                              disabled={
                                isCNPJMaintenance ||
                                isLocked ||
                                scopeId !== scopes.CONCIL
                              }
                            />
                          </Form.Item>
                        </LabelAndInputs>
                      </Col>
                      <Col lg={9}>
                        Apelido da loja
                        <Tooltip title="Este é o nome pelo qual deseja identificar sua loja. Escolha um apelido fácil de lembrar para cada CNPJ e evite utilizar o mesmo apelido para lojas diferentes.">
                          <Button
                            type="link"
                            icon={<ExclamationCircleFilled />}
                            Style="color: #504e4e; width: 20px; height: 20px; padding: 0 0 0 5px; font-size: 13px; border-radius: 0px; vertical-align: 0px;"
                            // eslint-disable-next-line react/jsx-closing-tag-location
                          ></Button>
                        </Tooltip>
                        <br />
                        <Form.Item
                          name="short_name"
                          hasFeedback
                          validateStatus={shortNameStatus}
                          validateTrigger={['onBlur']}
                          rules={[
                            {
                              async validator(_, value) {
                                await handleValidateStoreName(value);
                              },
                            },
                          ]}
                          style={{ marginBottom: isCNPJMaintenance ? 0 : 24 }}
                        >
                          <Input
                            placeholder="Como quer chamar este CNPJ?"
                            value={headOfficeStore?.short_name}
                            name="short_name"
                            locale={ptBR}
                            style={{ width: '100%' }}
                            disabled={
                              !isCnpjValid ||
                              isLocked ||
                              isOperational() ||
                              (stores?.length > 0 &&
                                headOfficeStore &&
                                !headOfficeStore?.can_edit)
                            }
                          />
                        </Form.Item>
                        {showWarning ? (
                          <StWarning>Este apelido já está em uso.</StWarning>
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row>
                    <Row gutter={[8, 8]}>
                      <Col lg={4}>
                        <LabelAndInputs label="CEP">
                          <Form.Item
                            name="zip_code"
                            style={{ marginBottom: isCNPJMaintenance ? 0 : 24 }}
                          >
                            <InputMaskCep
                              name="zip_code"
                              placeholder="Insira o CEP"
                              value={headOfficeStore?.address?.zip_code}
                              onChange={onChangeCepBriefing}
                              disabled={
                                isCNPJMaintenance ||
                                !isCnpjValid ||
                                loadingViaCep ||
                                isLocked
                              }
                            />
                          </Form.Item>
                        </LabelAndInputs>
                      </Col>
                      <Col lg={10}>
                        <LabelAndInputs label="Endereço">
                          <Form.Item
                            name="street"
                            style={{ marginBottom: isCNPJMaintenance ? 0 : 24 }}
                          >
                            <Input
                              placeholder="Insira o endereço"
                              value={headOfficeStore?.address?.street}
                              name="street"
                              locale={ptBR}
                              style={{ width: '100%' }}
                              disabled={
                                isCNPJMaintenance ||
                                !isCnpjValid ||
                                loadingViaCep ||
                                isLocked
                              }
                            />
                          </Form.Item>
                        </LabelAndInputs>
                      </Col>
                      <Col lg={4}>
                        <LabelAndInputs label="Número">
                          <Form.Item
                            name="number"
                            style={{ marginBottom: isCNPJMaintenance ? 0 : 24 }}
                          >
                            <Input
                              placeholder="Insira o número"
                              value={headOfficeStore?.address?.number}
                              name="number"
                              locale={ptBR}
                              style={{ width: '100%' }}
                              disabled={
                                isCNPJMaintenance ||
                                !isCnpjValid ||
                                loadingViaCep ||
                                isLocked
                              }
                            />
                          </Form.Item>
                        </LabelAndInputs>
                      </Col>
                      <Col lg={6}>
                        <LabelAndInputs label="Complemento (opcional)">
                          <Form.Item
                            name="complement"
                            style={{ marginBottom: isCNPJMaintenance ? 0 : 24 }}
                          >
                            <Input
                              placeholder="Insira o complemento"
                              value={headOfficeStore?.address?.complement}
                              name="complement"
                              locale={ptBR}
                              style={{ width: '100%' }}
                              disabled={
                                isCNPJMaintenance ||
                                !isCnpjValid ||
                                loadingViaCep ||
                                isLocked
                              }
                            />
                          </Form.Item>
                        </LabelAndInputs>
                      </Col>
                    </Row>
                    <Row gutter={[8, 8]}>
                      <Col lg={12}>
                        <LabelAndInputs label="Bairro">
                          <Form.Item
                            name="district"
                            style={{ marginBottom: isCNPJMaintenance ? 0 : 24 }}
                          >
                            <Input
                              placeholder="Insira o bairro"
                              value={headOfficeStore?.address?.district}
                              name="district"
                              locale={ptBR}
                              style={{ width: '100%' }}
                              disabled={
                                isCNPJMaintenance ||
                                !isCnpjValid ||
                                loadingViaCep ||
                                isLocked
                              }
                            />
                          </Form.Item>
                        </LabelAndInputs>
                      </Col>
                      <Col lg={6}>
                        <LabelAndInputs label="Cidade">
                          <Form.Item
                            name="city"
                            style={{ marginBottom: isCNPJMaintenance ? 0 : 24 }}
                          >
                            <Input
                              placeholder="Insira a cidade"
                              value={headOfficeStore?.address?.city}
                              name="city"
                              locale={ptBR}
                              style={{ width: '100%' }}
                              disabled={
                                isCNPJMaintenance ||
                                !isCnpjValid ||
                                loadingViaCep ||
                                isLocked
                              }
                            />
                          </Form.Item>
                        </LabelAndInputs>
                      </Col>
                      <Col lg={6}>
                        <Form.Item
                          name="state"
                          style={{ marginBottom: isCNPJMaintenance ? 0 : 24 }}
                        >
                          <LabelAndSelect
                            label="Estado"
                            placeHolder="Selecione o estado"
                            labelOption="name"
                            valueOption="initials"
                            options={states}
                            loading={false}
                            value={headOfficeStore?.address?.state}
                            disabled={
                              isCNPJMaintenance ||
                              isLocked ||
                              !isCnpjValid ||
                              loadingViaCep
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <div
                      style={{
                        marginTop: isCNPJMaintenance ? '32px' : '-5px',
                        maxHeight: isCNPJMaintenance ? '350px' : '100px',
                        overflowY: 'initial',
                        overflowX: 'hidden',
                      }}
                    >
                      {stores?.length > 0 ? renderStoresList() : <></>}
                      {isAddingStore && (
                        <StoreAddForm
                          clientId={headOfficeStore?.client_id}
                          onAfterAdd={onAfterAddStore}
                          onCancel={() => setIsAddingStore(false)}
                          isCNPJMaintenance={isCNPJMaintenance}
                        />
                      )}
                    </div>
                  </fieldset>
                </Form>
              </>
            </Loadable>
            {!isAddingStore && !loadingStores && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: isCNPJMaintenance ? 'flex-end' : 'start',
                  marginTop: isCNPJMaintenance ? 16 : 0,
                }}
              >
                <StNewCnpjButton
                  style={{ marginTop: isCNPJMaintenance ? 0 : 16 }}
                  type="primary"
                  shape="round"
                  icon={<PlusOutlined />}
                  ghost
                  onClick={() => addStore()}
                  disabled={isLocked}
                >
                  Incluir outro CNPJ
                </StNewCnpjButton>
              </div>
            )}
          </HeaderRegisters>
        )}
      </>
    );
  }
);

export default BriefingStoresRegister;
