import { call, put } from 'redux-saga/effects';
import errorHandler from 'helpers/errorHandler';

import getAmountsReceivableInfoFiltersFromAPI from 'services/filters/amountsReceivableInfoFilters';

import {
  amountsReceivableInfoFiltersSuccess,
  amountsReceivableInfoFiltersError,
} from './actions';

export function* amountsReceivableInfoFiltersSaga(action) {
  const { payload } = action;

  try {
    const apiData = yield call(getAmountsReceivableInfoFiltersFromAPI, payload);
    yield put(amountsReceivableInfoFiltersSuccess(apiData));
  } catch (err) {
    if (
      err?.response?.data?.error &&
      err?.response?.data?.error?.type &&
      err?.response?.data?.error?.message
    ) {
      yield put(
        amountsReceivableInfoFiltersError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    }
  }
}
