import { action } from 'typesafe-actions';
import { actionTypes } from 'store/ducks/credit/creditInteractPreregister/types';

export const creditInteractPreregisterStart = (data) =>
  action(actionTypes.CREDIT_INTERACT_PREREGISTER_START, data);
export const creditInteractPreregisterSuccess = (data) =>
  action(actionTypes.CREDIT_INTERACT_PREREGISTER_SUCCESS, data);
export const creditInteractPreregisterError = (errorMessage, error) =>
  action(actionTypes.CREDIT_INTERACT_PREREGISTER_ERROR, { errorMessage }, undefined, {
    error,
  });
