import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from 'antd';
import customHistory from 'helpers/history';

import { LabelAndValue } from 'commons/LabelAndValue';
import { TitleAndIcon } from '../../../../../commons/TitleAndIcon';
import { StDivider } from './styled';
import {
  StTypographyTextLabel,
  StTypographyTextValue,
} from '../../../../../commons/LabelAndValue/styled';
import { StSeeDetailsButton } from 'components/Intermediate/styled';
import { EllipsisText } from 'commons/EllipsisText';
import { StIntermediateCard } from 'commons/Intermediate/styled';
import { updatePaymentDivergencesDetailsFilters } from 'store/ducks/filters/details/paymentDivergencesDetailsFilters/actions';
import { useDispatch } from 'react-redux';
import { colors } from 'styles/colors';

const CardListItem = ({ data, activeTab }) => {
  const dispatch = useDispatch();

  const transformItemToFilter = (item) => {
    const filter = [];
    if (activeTab === 'branches') {
      filter.push({
        name: 'store_name',
        val: [item],
      });
    } else if (activeTab === 'acquirers') {
      filter.push({
        name: 'acquirer_name',
        val: [item],
      });
    } else if (activeTab === 'products') {
      filter.push({
        name: 'product_code',
        val: [item],
      });
    } else if (activeTab === 'brands') {
      filter.push({
        name: 'brand_code',
        val: [item],
      });
    }
    return filter;
  };

  const goToReports = (item) => {
    dispatch(
      updatePaymentDivergencesDetailsFilters(transformItemToFilter(item))
    );
    customHistory.push('/auditoria-pagamentos-relatorios');
  };

  return (
    <StIntermediateCard
      bordered={false}
      title={
        <TitleAndIcon
          titleText={
            <EllipsisText text={data.name.toUpperCase()} length={20} />
          }
          titleFontSize="14px"
        />
      }
      actions={[
        <div style={{ textAlign: 'end' }}>
          <StSeeDetailsButton
            type="link"
            size="small"
            fontSize="1.5rem"
            onClick={() => {
              goToReports(data.name);
            }}
          >
            Ver relatório
          </StSeeDetailsButton>
        </div>,
      ]}
    >
      <Row align="middle" style={{ paddingBottom: 16 }}>
        <Col>
          <LabelAndValue
            label="Pagamentos previstos"
            value={new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(data?.forecast_net_amout || '0')}
            valueTextSize="medium"
            labelColor="rgba(0,0,0,0.45)"
            strong
          />
        </Col>
      </Row>

      <Row align="middle" style={{ paddingBottom: 16 }}>
        <Col>
          <LabelAndValue
            label="Pagamentos conciliados"
            value={new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(data?.reconciled_net_amout || '0')}
            valueTextSize="medium"
            labelColor="rgba(0,0,0,0.45)"
            strong
          />
        </Col>
      </Row>
      <Row align="middle">
        <Col>
          <StTypographyTextLabel fontSize="12px" $labelColor="rgba(0,0,0,0.45)">
            Diferença de valor
          </StTypographyTextLabel>
          <br />
          <StTypographyTextValue
            fontSize="16px"
            $valueColor={
              data?.net_amount_difference_reconciled !== 0
                ? colors.red5
                : colors.gray11
            }
            strong
          >
            {new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(data?.net_amount_difference_reconciled || '0')}
          </StTypographyTextValue>
        </Col>
      </Row>

      <Row align="middle">
        <StDivider />
        <Col>
          <LabelAndValue
            label="Diferença de data"
            labelColor="rgba(0,0,0,0.45)"
            value={data?.late_payments_count}
            valueTextSize="medium_14"
            strong
          />
        </Col>
      </Row>

      <Row align="middle">
        <StDivider />
        <Col>
          <StTypographyTextLabel
            fontSize="1.5rem"
            $labelColor="rgba(0,0,0,0.45)"
          >
            Previsões em aberto
          </StTypographyTextLabel>
          <br />
          <StTypographyTextValue
            fontSize="14px"
            $valueColor={
              data?.pending_net_amout !== 0 ? colors.red5 : colors.gray11
            }
            strong
          >
            {new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(data?.pending_net_amout || '0')}
          </StTypographyTextValue>
        </Col>
      </Row>
    </StIntermediateCard>
  );
};

CardListItem.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    client_id: PropTypes.number,
    net_amount_difference_reconciled: PropTypes.number,
    late_payments_count: PropTypes.number,
    pending_net_amout: PropTypes.number,
    reconciled_net_amout: PropTypes.number,
    forecast_net_amout: PropTypes.number,
  }).isRequired,
  activeTab: PropTypes.string.isRequired,
};

export default CardListItem;
