import { action } from 'typesafe-actions';
import { actionTypes } from 'store/ducks/updateScreenToScreenDate/types';

export const updateScreenToScreenDateStart = (data) =>
  action(actionTypes.UPDATE_SCREEN_TO_SCREEN_DATE_START, data);
export const updateScreenToScreenDateSuccess = (data) =>
  action(actionTypes.UPDATE_SCREEN_TO_SCREEN_DATE_SUCCESS, data);
export const updateScreenToScreenDateError = (errorMessage, error) =>
  action(
    actionTypes.UPDATE_SCREEN_TO_SCREEN_DATE_ERROR,
    { errorMessage },
    undefined,
    { error }
  );
