import React from 'react';
import { Col, Grid, Row } from 'antd';
import { TransactionsReport } from './components/TransactionsReport';
import { StContainer, StTitle } from '../../commons/Content/styled';

const BankConciliationTransactions = () => {
  const { useBreakpoint } = Grid;
  const breakpoint = useBreakpoint();

  return (
    <>
      <StContainer $breakpoint={breakpoint}>
        <Row
          align="middle"
          justify="space-between"
          style={{ marginTop: '4rem' }}
        >
          <Col>
            <StTitle style={{ fontSize: '30px', fontWeight: 500 }}>
              Análise de extrato OFX
            </StTitle>
          </Col>
        </Row>

        <TransactionsReport />
      </StContainer>
    </>
  );
};

export default BankConciliationTransactions;
