import styled from 'styled-components';
import { colors } from 'styles/colors';

export const StNotificationsWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 8px;
`;

export const StNotificationsContent = styled.div`
  overflow-y: auto;
  height: calc(100vh - 235px);
  margin-top: 22px;
`;

export const StNotificationsContainer = styled.div`
  margin-right: 10px;
  margin-bottom: 40px;
`;

export const StNotificationsTitle = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  color: ${colors.gray9};
  background-color: ${colors.gray1};
  display: flex;
  align-items: center;
  padding: 10px 38px;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 1px solid rgba(240, 240, 240);
`;

export const StNotificationsBottom = styled.span`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  cursor: pointer;
  color: ${colors.primary6};
  background-color: ${colors.gray1};
  border-top: 1px solid rgba(230, 230, 230);
`;
