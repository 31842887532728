import React from 'react';
import PropTypes from 'prop-types';
import { EllipsisText } from 'commons/EllipsisText';
import { Select, Typography } from 'antd';

const LabelAndSelect = ({
  label,
  align,
  options,
  labelOption,
  loading,
  placeHolder,
  valueOption,
  logoValue,
  disabled,
  onChange,
  value,
  name,
  groupLabel,
  isTaxCnpj,
  customWidth,
}) => {
  const { Option, OptGroup } = Select;
  let optionsSelect;
  if (options && options.length > 0) {
    optionsSelect = options.map((element) => {
      let req;
      if (logoValue) {
        try {
          // eslint-disable-next-line global-require,import/no-dynamic-require
          if (logoValue !== null && logoValue !== '') {
            req = element[logoValue];
          }
        } catch (error) {
          req = undefined;
        }
      }
      return (
        <Option
          value={element[valueOption]}
          label={element[labelOption]}
          key={element[valueOption]}
        >
          {isTaxCnpj ? (
            <div className="demo-option-label-item">
              {req && (
                <span role="img" style={{ marginRight: '10px' }}>
                  <img src={req} width="20px" height="20px" alt="" />
                </span>
              )}
              <EllipsisText
                text={`${element[valueOption]} | ${element[labelOption]}`}
                length="33"
              />
            </div>
          ) : (
            <div className="demo-option-label-item">
              {req && (
                <span role="img" style={{ marginRight: '10px' }}>
                  <img src={req} width="20px" height="20px" alt="" />
                </span>
              )}
              {element[labelOption]}
            </div>
          )}
        </Option>
      );
    });
  }
  return (
    <div style={{ textAlign: align }}>
      {label !== '' ? (
        <>
          <Typography.Text style={{ fontSize: 12 }}>{label}</Typography.Text>
          <br />
        </>
      ) : (
        <></>
      )}

      <Select
        style={{ width: '100%' }}
        loading={loading}
        placeholder={placeHolder}
        disabled={disabled}
        onChange={onChange}
        value={value}
        name={name}
        dropdownMatchSelectWidth={customWidth && customWidth}
      >
        {groupLabel !== '' && optionsSelect ? (
          <OptGroup label={groupLabel}>{optionsSelect}</OptGroup>
        ) : (
          <>{optionsSelect}</>
        )}
      </Select>
    </div>
  );
};

LabelAndSelect.defaultProps = {
  label: '',
  align: 'start',
  placeHolder: '',
  logoValue: '',
  disabled: false,
  onChange: () => {},
  value: undefined,
  name: '',
  groupLabel: '',
  isTaxCnpj: false,
};

LabelAndSelect.propTypes = {
  label: PropTypes.string,
  align: PropTypes.string,
  placeHolder: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  labelOption: PropTypes.string.isRequired,
  valueOption: PropTypes.string.isRequired,
  logoValue: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  groupLabel: PropTypes.string,
  isTaxCnpj: PropTypes.bool,
};

export default LabelAndSelect;
