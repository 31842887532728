import { Typography } from 'antd';
import styled from 'styled-components';
import { colors } from 'styles/colors';

export const StTitleItem = styled(Typography.Title)`
  &&& {
    font-size: 20px;
    font-weight: 500;
    margin: 0;
    line-height: 28px;
  }
`;

export const StSubTitleItem = styled(Typography.Text)`
  &&& {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: ${colors.gray8};
  }
`;
