import { call, put, delay } from 'redux-saga/effects';
import errorHandler from 'helpers/errorHandler';
import getSearchTransactionDetailsFiltersFromAPI from 'services/filters/details/searchTransactionDetailsFilters';
import { errorToShow } from 'store/ducks/errorToShow/actions';

import {
  searchTransactionDetailsFiltersSuccess,
  searchTransactionDetailsFiltersError,
} from './actions';

export function* searchTransactionDetailsFiltersSaga(action) {
  const { payload } = action;
  // console.log('PAYLOAD', payload);
  try {
    const apiData = yield call(
      getSearchTransactionDetailsFiltersFromAPI,
      payload
    );
    yield delay(500);
    yield put(searchTransactionDetailsFiltersSuccess(apiData));
  } catch (err) {
    if (
      err?.response?.data?.message
      // err?.response?.data?.message &&
      // err?.response?.data?.error?.type &&
      // err?.response?.data?.error?.message
    ) {
      yield put(
        errorToShow({
          message: err.response.data.error.message,
          type: err.response.data.error.type,
        })
      );
      yield put(
        searchTransactionDetailsFiltersError(
          err.response.data.message,
          err.response.data.error
        )
      );
      errorHandler(err.response.data.message, err.response.data.error);
    }
  }
}
