import styled from 'styled-components';

import { Typography } from 'antd';

const { Title } = Typography;

export const StContainer = styled.div`
  &&& {
    display: flex;
    justify-content: ${(props) => props?.$justifyContent};
    align-items: center;
    align-items: end;
  }
`;

export const StTypographyText = styled(Title)`
  &&& {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: ${(props) => props?.$titleFontSize || '20px'};
    line-height: 28px;
    margin-left: ${(props) => (props?.$iconUri ? '1.6rem' : '0')};
    white-space: normal;
    word-break: break-word;
  }
`;

export const StImg = styled.img`
  &&& {
    width: 24px;
    height: 24px;
  }
`;
