/* eslint-disable */
/* eslint-disable no-console */
const hjid = 2393865;
const hjsv = 6;

export const Hotjar = () => {
  const script = (h, o, t, j, a, r) => {
    h.hj =
      h.hj ||
      function () {
        (h.hj.q = h.hj.q || []).push(arguments);
      };
    h._hjSettings = { hjid, hjsv };
    a = o.getElementsByTagName('head')[0];
    r = o.createElement('script');
    r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
    console.log('Hotjar init');
  };

  script(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
};
