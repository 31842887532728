import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  StContainer,
  StDescription,
  StImage,
  StLink,
  StTitle,
  StWrapper,
} from './styled';

export const NotFound = ({ redirectRoute = '/' }) => {
  const navigate = useNavigate();
  const [secondsRemaining, setSecondsRemaining] = useState(5);

  const assetsBaseURL = process.env.REACT_APP_AWS_V4_ASSETS;
  const notFoundImg = `${assetsBaseURL}/svg/notFoundImg.svg`;

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate(redirectRoute);
    }, secondsRemaining * 1000);

    return () => clearTimeout(timer);
  }, [navigate, secondsRemaining, redirectRoute]);

  useEffect(() => {
    const timer = setInterval(() => {
      setSecondsRemaining((prevSeconds) => prevSeconds - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <StContainer>
      <StWrapper>
        <StImage src={notFoundImg} />
        <StTitle>Parece que algo aconteceu!</StTitle>
        <StDescription>
          Você será redirecionado para a página inicial em breve ou{' '}
          <StLink to={redirectRoute}>volte agora clicando aqui</StLink>!
        </StDescription>
      </StWrapper>
    </StContainer>
  );
};
