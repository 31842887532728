import React from 'react';
import { Row, Col, Grid } from 'antd';

import PropTypes from 'prop-types';
import customHistory from 'helpers/history';

import { LabelAndValue } from 'commons/LabelAndValue';
import { TitleAndIcon } from 'commons/TitleAndIcon';
import { ErrorCard } from 'commons/ErrorCard';
import { useDispatch } from 'react-redux';
import { setBanksFilter } from '../../../../../store/ducks/banks/listBanksIntermediate/actions';
import { StSeeDetailsButton } from 'components/Intermediate/styled';
import { StIntermediateCard } from 'commons/Intermediate/styled';

const SummaryCard = ({ isError, loading, banksGrossAmount }) => {
  const { useBreakpoint } = Grid;
  const breakpoint = useBreakpoint();
  const dispatch = useDispatch();
  const dataTestPrefix = 'summary-card';
  const goToReports = () => {
    dispatch(
      setBanksFilter({
        filter: undefined,
      })
    );
    customHistory.push('/bancos-relatorios');
  };

  if (isError) {
    return (
      <Row style={{ marginTop: '2rem' }}>
        <Col lg={10} md={18}>
          <StIntermediateCard loading={loading}>
            <ErrorCard
              title="Dados indisponíveis"
              reason="Não conseguimos buscar os dados, mas já estamos trabalhando para normalizar a situação."
              recommendation="Tente atualizar a página em alguns instantes."
            />
          </StIntermediateCard>
        </Col>
      </Row>
    );
  }
  return (
    <Row style={{ marginTop: '2rem' }}>
      <Col xxl={8} xl={12} lg={12} md={18} sm={24} xs={24}>
        <StIntermediateCard
          style={{ borderRadius: 4, width: '43.75rem' }}
          loading={loading}
          actions={[
            <div style={{ textAlign: 'end' }}>
              <StSeeDetailsButton
                type="link"
                size="small"
                fontSize="1.75rem"
                onClick={goToReports}
                data-testid={`${dataTestPrefix}-go-to-reports`}
              >
                Ver relatório
              </StSeeDetailsButton>
            </div>,
          ]}
        >
          <Row>
            <Col>
              <TitleAndIcon
                titleFontSize="2rem"
                titleText="Resumo total"
                dataTestPrefix={dataTestPrefix}
              />
            </Col>
          </Row>
          <Row align="end" style={{ marginTop: '1.5rem' }}>
            <Col
              style={{
                marginTop: breakpoint?.lg ? 0 : '2rem',
              }}
            >
              <LabelAndValue
                label="Total"
                labelColor="rgba(0,0,0,0.45)"
                value={new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }).format(banksGrossAmount)}
                valueTextSize="large"
                strong
                textAlign={breakpoint?.lg ? 'end' : 'start'}
                dataTestPrefix={dataTestPrefix}
              />
            </Col>
          </Row>
        </StIntermediateCard>
      </Col>
    </Row>
  );
};

SummaryCard.defaultProps = {
  banksGrossAmount: '0',
};

SummaryCard.propTypes = {
  banksGrossAmount: PropTypes.string,
  isError: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default SummaryCard;
