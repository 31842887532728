import { call, put } from 'redux-saga/effects';

import errorHandler from 'helpers/errorHandler';

import {
  exportRefundDetailsSuccess,
  exportRefundDetailsError,
} from 'store/ducks/details/export/refundDetails/actions';
import getExportRefundDetailsFromAPI from 'services/details/export/refundDetails';

export function* exportRefundDetailsSaga(action) {
  try {
    const { payload } = action;

    const apiData = yield call(getExportRefundDetailsFromAPI, payload);

    yield put(exportRefundDetailsSuccess(apiData));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        exportRefundDetailsError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    }
  }
}
