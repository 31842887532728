import { lambdaApi } from './api';

const transformer = (apiResponse) => {
  const data = {
    details: apiResponse.data.transactions,
    meta: apiResponse.meta,
  };
  return data;
};

const getSearchTransactionFromAPI = async ({
  searchOption,
  searchText,
  sortFields,
  page,
  perPage,
  q,
}) => {
  const config = {
    params: {
      field: searchOption,
      value: searchText,
      sort_fields: sortFields,
      page: page,
      per_page: perPage,
      q,
    },
  };

  const response = await lambdaApi().get('/search/transactions', config);
  return transformer(response.data);
};

export default getSearchTransactionFromAPI;
