import { notification } from 'antd';

const successHandler = (successMessage) => {
  notification.success({
    duration: 10,
    placement: 'topRight',
    message: successMessage,
  });
};

export default successHandler;
