import styled from 'styled-components';

import { marginMainContainer } from 'helpers/style';
import { Row, Typography } from 'antd';
import { colors } from 'styles/colors';
import { ExclamationCircleFilled } from '@ant-design/icons';

const { Text } = Typography;

export const StContainer = styled.div`
  margin-top: 3rem;
`;

export const StLimiter = styled.div`
  margin: ${(props) => marginMainContainer(props.$breakpoint)};
`;

export const StWarningRow = styled(Row)`
  &&& {
    padding: 0px 17px;
    margin-top: 16px;
    display: flex;
    align-items: center;
  }
`;

export const StWarningText = styled(Text)`
  &&& {
    margin-left: 11px;
    margin-top: 1px;
    color: ${colors.gray9};
  }
`;

export const StWarningIcon = styled(ExclamationCircleFilled)`
  &&& {
    color: ${colors.gold6};
    font-size: 14px;
  }
`;
