import styled from 'styled-components';
import { marginMainContainer } from '../../helpers/style';
import { Tabs, Typography } from 'antd';
import { colors } from 'styles/colors';

const { Title } = Typography;
const { TabPane } = Tabs;

export const StContainer = styled.div`
  margin: ${(props) => marginMainContainer(props?.$breakpoint)};
  padding-top: 40px;
`;

export const StLimiter = styled.div`
  margin: ${(props) => marginMainContainer(props.$breakpoint)};
`;

export const StTitle = styled(Title)`
  &&& {
    font-size: 30px;
    font-weight: 500;
    color: ${colors.gray11};
  }
`;

export const StTabs = styled(TabPane)`
  &&& {
    padding-left: 32px;
    padding-right: 32px;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background-color: ${colors.gray1};
    max-height: 600px;
    overflow-y: auto;
  }
`;

export const StListSkeletonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 12px 0;
`;
