import { bankingApi } from '../api';

const transformer = (apiResponse) => {
  return {
    data: apiResponse?.payload,
  };
};

const getCreditListPartnersOptinFromAPI = async ({ clientId }) => {
  const config = {
    params: {
      clientId,
    },
  };
  const response = await bankingApi().get(
    '/credit/list/partners/optin',
    config
  );
  return transformer(response.data);
};

export default getCreditListPartnersOptinFromAPI;
