import { call, put } from 'redux-saga/effects';

import errorHandler from 'helpers/errorHandler';

import {
  listBankConciliation,
  listBankConciliationTransactions,
  updateBankConciliationTransactions,
} from 'services/bankConciliation';
import {
  listBankConciliationSuccess,
  listBankConciliationError,
  listBankConciliationTransactionsSuccess,
  listBankConciliationTransactionsError,
  setTransactionsSelectedExecuteSuccess,
  updateTransactionsSuccess,
  updateTransactionsError,
  exportBankConciliationSuccess,
  exportBankConciliationError,
  listBankConciliationTransactionsClearDone,
  updateCounterTransactionsSuccess,
  updateTransactionDateSuccess,
  updateTransactionDateError,
  updateThroughImportFilesSuccess,
  updateThroughImportFilesError,
  resetHasNoTransactionsSuccess,
  updateDifferenceValueSuccess,
  updateDifferenceValueError,
  updateAcquirerNameSuccess,
  updateAcquirerNameError,
  updateCurrentPageSuccess,
  updateCurrentPageError,
  updateAfterSave,
  updateAfterSaveSuccess,
} from './actions';
import getExportBankConciliationFromAPI from 'services/details/export/bankConciliation';
import customHistory from '../../../helpers/history';
import store from 'store';
import { notification } from 'antd';

export function* listBankConciliationSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(listBankConciliation, payload);
    yield put(listBankConciliationSuccess(apiData));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        listBankConciliationError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(listBankConciliationError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}

export function* exportBankConciliationSaga(action) {
  const { payload } = action;

  const apiData = yield call(getExportBankConciliationFromAPI, payload);
  try {
    yield put(exportBankConciliationSuccess(apiData));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        exportBankConciliationError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    }
  }
}

export function* listBankConciliationTransactionsSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(listBankConciliationTransactions, payload);
    yield put(listBankConciliationTransactionsSuccess(apiData));
    if (!store.getState().bankConciliation?.hasNoTransactions) {
      customHistory.push('/analise-de-extrato-ofx-transacoes');
    }
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        listBankConciliationTransactionsError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(listBankConciliationTransactionsError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}

export function* listBankConciliationTransactionsClearSaga() {
  yield put(listBankConciliationTransactionsClearDone());
}

export function* resetHasNoTransactionsSaga() {
  yield put(resetHasNoTransactionsSuccess());
}

export function* setTransactionsSelectedSaga(action) {
  const { payload } = action;
  yield put(setTransactionsSelectedExecuteSuccess(payload));
  customHistory.push(
    `/analise-de-extrato-ofx-transacoes?page=${payload?.currentPage}`
  );
}

export function* updateBankConciliationTransactionsSaga(action) {
  const throughImportFiles =
    store.getState().bankConciliation?.throughImportFiles;

  const commonReturn = (bool, route) => {
    notification.success({ message: 'Alterações salvas com sucesso.' });
    store.dispatch(updateAfterSave(bool));
    customHistory.push(route);
    return localStorage.removeItem('classified_transactions');
  };

  try {
    const { payload } = action;
    const apiData = yield call(updateBankConciliationTransactions, payload);

    if (throughImportFiles) {
      commonReturn(false, '/importacao-arquivos');
      yield put(updateTransactionsSuccess(apiData));
    } else {
      commonReturn(true, '/conciliacao-bancaria');
      yield put(updateTransactionsSuccess(apiData));
    }
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        updateTransactionsError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(updateTransactionsError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}

export function* updateCounterTransactionsSaga(action) {
  const { payload } = action;
  yield put(updateCounterTransactionsSuccess(payload));
}

export function* updateThroughImportFilesSaga(action) {
  try {
    const { payload } = action;
    yield put(updateThroughImportFilesSuccess(payload));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        updateThroughImportFilesError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(updateThroughImportFilesError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}

export function* updateTransactionDateSaga(action) {
  try {
    const { payload } = action;
    yield put(updateTransactionDateSuccess(payload));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        updateTransactionDateError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(updateTransactionDateError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}

export function* updateDifferenceValueSaga(action) {
  try {
    const { payload } = action;
    yield put(updateDifferenceValueSuccess(payload));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        updateDifferenceValueError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(updateDifferenceValueError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}

export function* updateAcquirerNameSaga(action) {
  try {
    const { payload } = action;
    yield put(updateAcquirerNameSuccess(payload));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        updateAcquirerNameError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(updateAcquirerNameError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}

export function* updateCurrentPageSaga(action) {
  try {
    const { payload } = action;
    yield put(updateCurrentPageSuccess(payload));
  } catch (err) {
    yield put(updateCurrentPageError('Ocorreu um erro:', err));
    errorHandler('Ocorreu um erro:', err);
  }
}

export function* updateAfterSaveSaga(action) {
  const { payload } = action;
  yield put(updateAfterSaveSuccess(payload));
}
