import { actionTypes } from 'store/ducks/details/reconciledPaymentsDetails/types';

const INITIAL_STATE = {
  error: undefined,
  errorMessage: undefined,
  loading: false,
  isError: false,
  details: [],
  grossAmount: 0,
  acquirerReconciledAmount: 0,
  acquirerPendentAmount: 0,
  customerReconciledAmount: 0,
  customerPendentAmount: 0,
  meta: {
    current_page: 1,
    num_results: 1000,
    results_per_page: 10,
    total_pages: 100,
    total_items: 1000,
  },
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case actionTypes.RECONCILED_PAYMENTS_DETAILS_START:
    return {
      ...state,
      loading: true,
      error: undefined,
      errorMessage: undefined,
      isError: false,
    };
  case actionTypes.RECONCILED_PAYMENTS_DETAILS_SUCCESS:
    return {
      ...state,
      loading: false,
      error: undefined,
      details: action.payload.details,
      grossAmount: action.payload.grossAmount,
      forecastReconciledAmount: action.payload.forecastReconciledAmount,
      forecastPendentAmount: action.payload.forecastPendentAmount,
      confirmationReconciledAmount:
          action.payload.confirmationReconciledAmount,
      confirmationPendentAmount: action.payload.confirmationPendentAmount,
      meta: action.payload.meta,
      isError: false,
      errorMessage: undefined,
    };
  case actionTypes.RECONCILED_PAYMENTS_DETAILS_ERROR:
    return {
      ...state,
      loading: false,
      error: action.error.error,
      errorMessage: action.payload,
      grossAmount: 0,
      acquirerReconciledAmount: 0,
      acquirerPendentAmount: 0,
      customerReconciledAmount: 0,
      customerPendentAmount: 0,
      isError: true,
      summary: undefined,
    };
  default:
    return state;
  }
};

export default reducer;
