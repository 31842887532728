import { action } from 'typesafe-actions';
import { actionTypes } from './types';

// Listing
export const listOFXParametersStart = (data) => action(actionTypes.LIST_OFX_PARAMETERS_START, data);
export const listOFXParametersOFXAlter = (data) => action(actionTypes.LIST_OFX_PARAMETERS_ALTER, data);
export const listOFXParametersOFXSuccess = (data) => action(actionTypes.LIST_OFX_PARAMETERS_SUCCESS, data);
export const listOFXParametersOFXError = (errorMessage, error) => action(actionTypes.LIST_OFX_PARAMETERS_ERROR, { errorMessage }, undefined, { error });

// Add
export const addOFXParameterStart = (data) => action(actionTypes.ADD_OFX_PARAMETER_START, data);
export const addOFXParameterSuccess = (data) => action(actionTypes.ADD_OFX_PARAMETER_SUCCESS, data);
export const addOFXParameterError = (data) => action(actionTypes.ADD_OFX_PARAMETER_ERROR, data);

// Edit
export const editOFXParameterStart = (data) => action(actionTypes.EDIT_OFX_PARAMETER_START, data);
export const editOFXParameterSuccess = (data) => action(actionTypes.EDIT_OFX_PARAMETER_SUCCESS, data);
export const editOFXParameterError = (data) => action(actionTypes.EDIT_OFX_PARAMETER_ERROR, data);

// Remove
export const removeOFXParameterStart = (data) => action(actionTypes.REMOVE_OFX_PARAMETER_START, data);
export const removeOFXParameterSuccess = (data) => action(actionTypes.REMOVE_OFX_PARAMETER_SUCCESS, data);
export const removeOFXParameterError = (data) => action(actionTypes.REMOVE_OFX_PARAMETER_ERROR, data);
