import React from 'react';
import PropTypes from 'prop-types';
import { Grid, List, Card, Typography, Pagination, Row, Col } from 'antd';
import { ErrorCard } from 'commons/ErrorCard';
import { StContainer, StLimiter } from './styled';
import { StGeneralReportsTitle } from 'components/Reports/styled';

const MobileCards = ({
  data,
  loading,
  pagination,
  onPageChange,
  onPageSizeChange,
  error,
}) => {
  const { useBreakpoint } = Grid;
  const breakpoint = useBreakpoint();

  if (error) {
    return (
      <StContainer>
        <StLimiter $breakpoint={breakpoint}>
          <Row style={{ marginTop: '2rem' }}>
            <Col lg={10} md={18}>
              <Card style={{ borderRadius: 4 }} loading={loading}>
                <ErrorCard
                  title="Dados indisponíveis"
                  reason="Não conseguimos buscar os dados, mas já estamos trabalhando para normalizar a situação."
                  recommendation="Tente atualizar a página em alguns instantes."
                />
              </Card>
            </Col>
          </Row>
        </StLimiter>
      </StContainer>
    );
  }

  if (
    !breakpoint?.md &&
    !breakpoint.lg &&
    !breakpoint.xl &&
    !breakpoint.xxl &&
    !error
  ) {
    return (
      <StContainer>
        <StLimiter $breakpoint={breakpoint}>
          <StGeneralReportsTitle>Relatório Geral</StGeneralReportsTitle>
          <List
            dataSource={data}
            grid={{ column: 1 }}
            loading={loading}
            renderItem={(item) => (
              <List.Item>
                <Card loading={loading}>
                  <div style={{ marginTop: '2rem' }}>
                    <Typography.Text>Filial: </Typography.Text>
                    <br />
                    <Typography.Text>{item.store_name}</Typography.Text>
                  </div>
                  <div style={{ marginTop: '2rem' }}>
                    <Typography.Text>Data do pagamento: </Typography.Text>
                    <br />
                    <Typography.Text>{item.transaction_date}</Typography.Text>
                  </div>
                  <div style={{ marginTop: '2rem' }}>
                    <Typography.Text>Adquirente: </Typography.Text>
                    <br />
                    <Typography.Text>{item.acquirer_name}</Typography.Text>
                  </div>
                  <div style={{ marginTop: '2rem' }}>
                    <Typography.Text>Produto: </Typography.Text>
                    <br />
                    <Typography.Text>{item.product_code}</Typography.Text>
                  </div>
                  <div style={{ marginTop: '2rem' }}>
                    <Typography.Text>Bandeira: </Typography.Text>
                    <br />
                    <Typography.Text>{item.brand_code}</Typography.Text>
                  </div>
                  <div style={{ marginTop: '2rem' }}>
                    <Typography.Text>Valor bruto (R$): </Typography.Text>
                    <br />
                    <Typography.Text>
                      {item.sum_inst_gross_amount}
                    </Typography.Text>
                  </div>
                  <div style={{ marginTop: '2rem' }}>
                    <Typography.Text>
                      Taxa administrativa (R$):{' '}
                    </Typography.Text>
                    <br />
                    <Typography.Text>
                      {item.sum_inst_adm_fee_amount}
                    </Typography.Text>
                  </div>
                  <div style={{ marginTop: '2rem' }}>
                    <Typography.Text>Desconto tarifa (R$): </Typography.Text>
                    <br />
                    <Typography.Text>
                      {item.sum_inst_split_adq_fare_amount}
                    </Typography.Text>
                  </div>
                  <div style={{ marginTop: '2rem' }}>
                    <Typography.Text>Repasse (R$): </Typography.Text>
                    <br />
                    <Typography.Text>
                      {item.sum_inst_transfer_amount}
                    </Typography.Text>
                  </div>
                  <div style={{ marginTop: '2rem' }}>
                    <Typography.Text>
                      Valor da taxa de antecipação (R$):{' '}
                    </Typography.Text>
                    <br />
                    <Typography.Text>
                      {item.sum_inst_advance_fee_amount}
                    </Typography.Text>
                  </div>
                  <div style={{ marginTop: '2rem' }}>
                    <Typography.Text>
                      Valor líquido antecipado (R$):{' '}
                    </Typography.Text>
                    <br />
                    <Typography.Text>
                      {item.sum_inst_advance_net_amount}
                    </Typography.Text>
                  </div>
                </Card>
              </List.Item>
            )}
          />
          <Pagination
            pageSize={pagination.pageSize}
            pageSizeOptions={pagination.pageSizeOptions}
            onChange={onPageChange}
            onShowSizeChange={onPageSizeChange}
            total={pagination.total}
            current={pagination.current}
            showTotal={pagination.showTotal}
            showSizeChanger
            responsive
          />
        </StLimiter>
      </StContainer>
    );
  }
  return '';
};

MobileCards.propTypes = {
  loading: PropTypes.bool.isRequired,
  onPageChange: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
};
export default MobileCards;
