import { actionTypes } from './types';

const INITIAL_STATE = {
  acquirers: [],
  acquirersPointOfSale: undefined,
  acquirersVouchers: undefined,
  acquirerMerchants: [],
  loading: false,
  loadingPointOfSale: false,
  loadingVouchers: false,
  loadingAcquirerMerchants: false,
  error: undefined,
  errorMessage: undefined,
  isError: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case actionTypes.ACQUIRER_START:
    return {
      ...state,
      loading: true,
      error: undefined,
      isError: false,
    };
  case actionTypes.ACQUIRER_ALL_START:
    return {
      ...state,
      loading: true,
      loadingPointOfSale: true,
      loadingVouchers: true,
      loadingAcquirerMerchants: true,
      error: undefined,
      isError: false,
    };
  case actionTypes.ACQUIRER_POINTOFSALE_START:
    return {
      ...state,
      loadingPointOfSale: true,
      error: undefined,
      isError: false,
    };
  case actionTypes.ACQUIRER_VOUCHER_START:
    return {
      ...state,
      loadingVouchers: true,
      error: undefined,
      isError: false,
    };
  case actionTypes.ACQUIRER_MERCHANTS_START:
    return {
      ...state,
      loadingAcquirerMerchants: true,
      error: undefined,
      isError: false,
    };
  case actionTypes.ACQUIRER_SUCCESS:
    return {
      ...state,
      loading: false,
      error: false,
      acquirers: action.payload.acquirers,
      isError: false,
    };
  case actionTypes.ACQUIRER_POINTOFSALE_SUCCESS:
    return {
      ...state,
      loadingPointOfSale: false,
      error: false,
      acquirersPointOfSale: action.payload,
      isError: false,
    };
  case actionTypes.ACQUIRER_VOUCHER_SUCCESS:
    return {
      ...state,
      loadingVouchers: false,
      error: false,
      acquirersVouchers: action.payload,
      isError: false,
    };
  case actionTypes.ACQUIRER_MERCHANTS_SUCCESS:
    return {
      ...state,
      loadingAcquirerMerchants: false,
      error: false,
      acquirerMerchants: [...state.acquirerMerchants, ...action.payload],
      isError: false,
    };
  case actionTypes.ACQUIRER_ERROR:
    return {
      ...state,
      loading: false,
      error: action.error.error,
      errorMessage: action.payload,
      isError: true,
    };
  case actionTypes.ACQUIRER_POINTOFSALE_ERROR:
    return {
      ...state,
      loadingPointOfSale: false,
      error: action.error.error,
      errorMessage: action.payload,
      isError: true,
    };
  case actionTypes.ACQUIRER_VOUCHER_ERROR:
    return {
      ...state,
      loadingVouchers: false,
      error: action.error.error,
      errorMessage: action.payload,
      isError: true,
    };
  case actionTypes.ACQUIRER_MERCHANTS_ERROR:
    return {
      ...state,
      loadingAcquirerMerchants: false,
      error: action.error.error,
      errorMessage: action.payload,
      isError: true,
    };
  default:
    return state;
  }
};

export default reducer;
