import { lambdaApi } from '../../api';

const transformer = (apiResponse) => {
  const data = {
    details: apiResponse.data?.reconciled,
    meta: apiResponse?.meta,
    grossAmount: apiResponse.data.total_gross_amount,
    forecastReconciledAmount:
      apiResponse.data?.reconciled_summary?.forecast_reconciled_amount,
    forecastPendentAmount:
      apiResponse.data?.reconciled_summary?.forecast_pendent_amount,
    confirmationReconciledAmount:
      apiResponse.data?.reconciled_summary?.confirmation_reconciled_amount,
    confirmationPendentAmount:
      apiResponse.data?.reconciled_summary?.confirmation_pendent_amount,
  };
  return data;
};

const getReconciledPaymentsDetailsFromAPI = async ({
  startDate,
  endDate,
  sortFields,
  page,
  perPage,
  q,
}) => {
  const config = {
    params: {
      start_date: startDate.format('YYYY-MM-DD'),
      end_date: endDate.format('YYYY-MM-DD'),
      page,
      per_page: perPage,
      sort_fields: JSON.stringify(sortFields),
      q: JSON.stringify(q),
    },
  };
  const response = await lambdaApi().get('/detail/payment/reconciled', config);
  return transformer(response.data);
};

export default getReconciledPaymentsDetailsFromAPI;
