import React from 'react';
import { Row, Col, Spin } from 'antd';

import PropTypes from 'prop-types';
import customHistory from 'helpers/history';
import { LabelAndValue } from 'commons/LabelAndValue';
import { TitleAndIcon } from 'commons/TitleAndIcon';
import { ErrorCard } from 'commons/ErrorCard';
import { StSeeDetailsButton } from 'components/Intermediate/styled';
import { StIntermediateCard } from 'commons/Intermediate/styled';
import { dataTestIdHandler } from 'helpers/dataTestIdHandler';

const SummaryCard = ({ isError, loading, summary }) => {
  const goToReports = () => {
    customHistory.push('/valores-a-receber-relatorios');
  };
  const dataTestPrefix = 'summary-card';

  if (isError) {
    return (
      <Row style={{ marginTop: '2rem' }}>
        <Col lg={10} md={18}>
          <StIntermediateCard style={{ borderRadius: 4 }} loading={loading}>
            <ErrorCard
              title="Dados indisponíveis"
              reason="Não conseguimos buscar os dados, mas já estamos trabalhando para normalizar a situação."
              recommendation="Tente atualizar a página em alguns instantes."
            />
          </StIntermediateCard>
        </Col>
      </Row>
    );
  }
  return (
    <Row style={{ marginTop: '2rem' }}>
      <Col xxl={12} xl={12} lg={12} md={18} sm={24} xs={24}>
        <StIntermediateCard
          style={{ borderRadius: 4 }}
          loading={loading}
          actions={[
            <div style={{ textAlign: 'end' }}>
              <StSeeDetailsButton
                type="link"
                size="small"
                fontSize="1.75rem"
                onClick={goToReports}
                {...dataTestIdHandler(dataTestPrefix, 'go-to-reports')}
              >
                Ver relatório
              </StSeeDetailsButton>
            </div>,
          ]}
        >
          <Row>
            <Col>
              <TitleAndIcon
                titleText="Resumo total"
                titleFontSize="18px"
                dataTestPrefix={dataTestPrefix}
              />
            </Col>
          </Row>
          <Row justify="end" style={{ marginTop: '1.5rem' }}>
            <Col>
              {summary.value === undefined ? (
                <Spin size="small" />
              ) : (
                <LabelAndValue
                  label="Total a receber no período"
                  labelColor="rgba(0,0,0,0.45)"
                  value={new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(summary?.value)}
                  valueTextSize="large"
                  textAlign="end"
                  dataTestPrefix={`${dataTestPrefix}-amounts-receivable-amount`}
                />
              )}
            </Col>
          </Row>
        </StIntermediateCard>
      </Col>
    </Row>
  );
};

SummaryCard.propTypes = {
  summary: PropTypes.shape({
    value: PropTypes.number,
  }).isRequired,
  isError: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default SummaryCard;
