import dayjs from 'dayjs';
import { getSessionClientId } from 'helpers/sessionService';
import { api } from './api';

const today = new Date();
const _today = dayjs(today).format('YYYYMMDD');

const transformInput = (value) => {
  const { listProducts } = value;
  if (
    listProducts.length > 0 &&
    (listProducts[listProducts.length - 1].product === null ||
      listProducts[listProducts.length - 1].product === undefined)
  ) {
    listProducts.pop();
  }
  const fees = {
    merchant_id: value.merchant_id,
    start_date: value.start_date.format('YYYY-MM-DD') || _today,
    end_date:
      value.end_date === ''
        ? '2100-12-31'
        : value.end_date.format('YYYY-MM-DD'),
    acquirer_id: value.acquirer_id,
    client_id: Number(getSessionClientId()),
    brand_id: value.brands,
    product_id: value.product,
    plan_start: value.plan_start,
    plan_end: value.plan_end,
    fee_percent: parseFloat(value.taxa.replace(',', '.')),
    amount_start: 0,
    amount_end: 0,
  };

  return fees;
};

export const registerFees = async (payload) => {
  if (payload.data.end_date === undefined || payload.data.end_date === null) {
    const modifiedPayload = {
      ...payload.data,
      end_date: '',
    };
    const _response = await api().post(
      '/fees',
      transformInput(modifiedPayload)
    );
    if (_response.data.code === 200) {
      return { success: true };
    }
  } else {
    const response = await api().post('/fees', transformInput(payload.data));
    if (response.data.code === 200) {
      return { success: true };
    }
  }
  return false;
};
