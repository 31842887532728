import React, { createContext, useState } from 'react';
import { Grid } from 'antd';
import { CnpjModal } from './Components/CnpjModal';
import { ContentTabs } from './Components/ContentTabs';
import { Filters } from './Components/Filters';
import { StContainer } from './styled';

export const CnpjMaintenanceContext = createContext({});

const CnpjMaintenance = () => {
  const [merchantHasPosted, setMerchantHasPosted] = useState(false);
  const [currentStoreToUpdate, setCurrentStoreToUpdate] = useState({});

  const [addModalVisibilityAdquirente, setAddModalVisibilityAdquirente] =
    useState(false);
  const [addModalVisibilityCnpj, setAddModalVisibilityCnpj] = useState(false);
  const [isNextButtonLocked, setIsNextButtonLocked] = useState(false);

  const [activeTab, setActiveTab] = useState('stores');
  const [searchFilter, setSearchFilter] = useState(null);

  const openAddModal = (screen) => {
    if (screen === 0) {
      setAddModalVisibilityCnpj(true);
    } else if (screen === 1) {
      setAddModalVisibilityAdquirente(true);
    }
  };
  const closeAddModal = () => {
    setAddModalVisibilityAdquirente(false);
    setAddModalVisibilityCnpj(false);
  };

  const onChangeSelectFilter = (e) => {
    if (
      e === 'Buscar CNPJ ou apelido da loja' ||
      e === 'Buscar estabelecimento' ||
      e === undefined ||
      e === ''
    ) {
      setSearchFilter(null);
    } else {
      setSearchFilter(e);
    }
  };

  const onTabClick = (key) => {
    setActiveTab(key);
    onChangeSelectFilter('');
  };

  const { useBreakpoint } = Grid;
  const breakpoint = useBreakpoint();

  return (
    <CnpjMaintenanceContext.Provider
      value={{
        merchantHasPosted,
        setMerchantHasPosted,
        currentStoreToUpdate,
        setCurrentStoreToUpdate,
      }}
    >
      <StContainer $breakpoint={breakpoint}>
        <Filters
          title="CNPJs e Códigos de Estabelecimento"
          onChangeSelectFilter={onChangeSelectFilter}
          onTabClick={onTabClick}
          activeTab={activeTab}
          openAddModal={openAddModal}
          searchFilter={searchFilter}
        />
        <ContentTabs
          onTabClick={onTabClick}
          activeTab={activeTab}
          closeModal={closeAddModal}
          isNextButtonLocked={isNextButtonLocked}
          setIsNextButtonLocked={setIsNextButtonLocked}
          searchFilter={searchFilter}
          setSearchFilter={setSearchFilter}
        />

        <CnpjModal
          visibility={addModalVisibilityCnpj}
          screen={0}
          closeModal={closeAddModal}
          isNextButtonLocked={isNextButtonLocked}
          setIsNextButtonLocked={setIsNextButtonLocked}
        />
        <CnpjModal
          visibility={addModalVisibilityAdquirente}
          screen={1}
          closeModal={closeAddModal}
          isNextButtonLocked={isNextButtonLocked}
          setIsNextButtonLocked={setIsNextButtonLocked}
        />
      </StContainer>
    </CnpjMaintenanceContext.Provider>
  );
};

export default CnpjMaintenance;
