import styled from 'styled-components';
import { Select, Tabs } from 'antd';
import { colors } from 'styles/colors';

export const StContainer = styled.div`
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-more,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more {
    background: ${colors.primary6} !important;
    color: ${colors.gray1} !important;
    font-weight: bold !important;
    font-size: 16px !important;
  }
`;

export const StSelect = styled(Select)`
  margin-top: 6px;
`;

export const StTabs = styled(Tabs)`
  &&& .ant-tabs-nav .ant-tabs-tab {
    border-bottom-color: ${colors.primary6};
  }

  &&& .ant-tabs-nav::before {
    border-color: ${colors.primary6};
  }

  &&& .ant-tabs-nav {
    margin-bottom: 0;
    box-shadow: none !important;
  }

  &&& .ant-tabs-tab-active {
    border-color: ${colors.primary6};
    border-bottom: transparent !important;
  }

  &&& .ant-tabs-content-holder {
    padding: 15px;
    border: 1px solid ${colors.primary6};
    border-top: transparent !important;
  }
`;
