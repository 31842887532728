import { call, put } from 'redux-saga/effects';

import errorHandler from 'helpers/errorHandler';

import getAmountsReceivableSummaryFromAPI from 'services/amountsReceivableSummary';
import {
  amountsReceivableSummarySuccess,
  amountsReceivableSummaryError,
} from 'store/ducks/amountsReceivableSummary/actions';

export function* amountsReceivableSummarySaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(getAmountsReceivableSummaryFromAPI, payload);

    yield put(amountsReceivableSummarySuccess(apiData));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        amountsReceivableSummaryError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    }
  }
}
