import { action } from 'typesafe-actions';
import { actionTypes } from 'store/ducks/details/export/allClientsDetails/types';

export const exportAllClientsDetailsStart = (data) =>
  action(actionTypes.EXPORT_ALL_CLIENTS_DETAILS_START, data);
export const exportAllClientsDetailsSuccess = (data) =>
  action(actionTypes.EXPORT_ALL_CLIENTS_DETAILS_SUCCESS, data);
export const exportAllClientsDetailsError = (errorMessage, error) =>
  action(
    actionTypes.EXPORT_ALL_CLIENTS_DETAILS_ERROR,
    { errorMessage },
    undefined,
    { error }
  );
