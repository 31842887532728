import React from 'react';
import { Col, Row } from 'antd';
import {
  setTransactionsSelectedExecute,
  updateAcquirerNameStart,
  updateThroughImportFilesStart,
  updateTransactionDateStart,
} from '../../../../../store/ducks/bankConciliation/actions';
import { useDispatch } from 'react-redux';
import * as St from '../styled';

const Actions = ({ currentRow, currentPage }) => {
  const dispatch = useDispatch();

  const openTransactionsReport = () => {
    dispatch(
      updateTransactionDateStart({
        transactionsStartDate: currentRow?.transaction_date,
        transactionsEndDate: currentRow?.transaction_date,
      })
    );

    dispatch(updateThroughImportFilesStart({ throughImportFiles: false }));

    dispatch(
      updateAcquirerNameStart({
        acquirerName: currentRow?.acquirer_name,
      })
    );

    dispatch(
      setTransactionsSelectedExecute(
        currentRow.bank_name,
        currentRow.bank_branch,
        currentRow.bank_account,
        currentRow.transaction_date,
        currentRow.account_id,
        currentPage
      )
    );
  };

  return (
    <Row>
      <Col lg={24}>
        <St.AnalyzeLink
          disabled={currentRow?.disable}
          onClick={openTransactionsReport}
        >
          Analisar extrato
        </St.AnalyzeLink>
      </Col>
    </Row>
  );
};

Actions.propTypes = {};

export default Actions;
