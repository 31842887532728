import { colors } from 'styles/colors';

export const feeStatusTypes = {
  'Taxas sem cadastro': {
    fontColor: colors.gray9,
    borderColor: colors.gray5,
    backgroundColor: colors.gray2,
  },
  'Taxas incompletas': {
    fontColor: colors.gold6,
    borderColor: colors.gold3,
    backgroundColor: colors.gold1,
  },
  'Taxas em atualização': {
    fontColor: colors.primary6,
    borderColor: colors.primary3,
    backgroundColor: colors.primary1,
  },
  'Taxas cadastradas': {
    fontColor: colors.green6,
    borderColor: colors.green3,
    backgroundColor: colors.green1,
  },
  'Taxas vencidas': {
    fontColor: colors.red6,
    borderColor: colors.red3,
    backgroundColor: colors.red1,
  },
};
