import React from 'react';
import PropTypes from 'prop-types';
import { setFontSizeAcordingWithProps } from 'helpers/style';
import {
  StContainer,
  StTypographyTextLabel,
  StTypographyTextValue,
} from './styled';
import { colors } from 'styles/colors';
import { dataTestIdHandler } from 'helpers/dataTestIdHandler';

const LabelAndValue = ({
  label,
  labelColor,
  value,
  valueColor,
  valueTextSize,
  textAlign,
  labelBottom,
  dataTestPrefix,
}) => {
  return (
    <StContainer textAlign={textAlign}>
      {labelBottom ? (
        <>
          <StTypographyTextValue
            {...dataTestIdHandler(dataTestPrefix, 'value')}
            fontSize={setFontSizeAcordingWithProps(valueTextSize)}
            $valueColor={valueColor}
            strong
          >
            {value}
          </StTypographyTextValue>
          <br />
          <StTypographyTextLabel
            {...dataTestIdHandler(dataTestPrefix, 'label')}
            fontSize="1.5rem"
            $labelColor={labelColor}
          >
            {label}
          </StTypographyTextLabel>
        </>
      ) : (
        <>
          <StTypographyTextLabel
            {...dataTestIdHandler(dataTestPrefix, 'label')}
            fontSize="1.5rem"
            $labelColor={labelColor}
          >
            {label}
          </StTypographyTextLabel>
          <br />
          <StTypographyTextValue
            {...dataTestIdHandler(dataTestPrefix, 'value')}
            fontSize={setFontSizeAcordingWithProps(valueTextSize)}
            $valueColor={valueColor}
            strong
          >
            {value}
          </StTypographyTextValue>
        </>
      )}
    </StContainer>
  );
};

LabelAndValue.defaultProps = {
  value: '',
  labelColor: colors.gray11,
  valueTextSize: 'medium',
  textAlign: 'start',
  labelBottom: false,
  dataTestPrefix: '',
};

LabelAndValue.propTypes = {
  label: PropTypes.string.isRequired,
  labelColor: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  valueTextSize: PropTypes.oneOf(['small', 'medium', 'large', 'medium_14']),
  textAlign: PropTypes.oneOf([
    'left',
    'right',
    'center',
    'justify',
    'justify-all',
    'start',
    'end',
    'match-parent',
  ]),
  labelBottom: PropTypes.bool,
  dataTestPrefix: PropTypes.string,
};

export default LabelAndValue;
