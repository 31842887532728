import React, { useContext } from 'react';
import { Button } from 'antd';
import { StContainer, StText, StWarningOutlinedIcon } from './styled';
import customHistory from 'helpers/history';
import { PermissionsContext } from 'routes/PrivateRoute';

export const PlanExpireInfoCard = ({ ...rest }) => {
  const { permissions } = useContext(PermissionsContext);

  const qtdDaysEndPlan = permissions?.qtd_days_end_plan;
  const endPlanAt = permissions?.end_plan_at;

  if (qtdDaysEndPlan < 0 || qtdDaysEndPlan === '' || endPlanAt === '') {
    return null;
  }

  const planExpireMessage = () => {
    if (qtdDaysEndPlan === 0) {
      return 'Seu período de teste grátis termina hoje. ';
    }
    return `Seu período de teste grátis termina em ${qtdDaysEndPlan} ${
      qtdDaysEndPlan === 1 ? 'dia' : 'dias'
    } (${endPlanAt}). `;
  };

  const onClickSeePlans = () => {
    customHistory.push('/planos');
  };

  return (
    <StContainer {...rest}>
      <StWarningOutlinedIcon />
      <StText>
        {planExpireMessage()}
        <span>
          Consulte as opções de planos para continuar a crescer o seu negócio
        </span>
      </StText>

      <Button type="link" onClick={onClickSeePlans} style={{ padding: 0 }}>
        Consultar planos
      </Button>
    </StContainer>
  );
};
