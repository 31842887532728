/* eslint-disable */
import React from 'react';
import { Grid, Tabs } from 'antd';
import { StLimiter } from './styled';
import { ContentRatesAcquirer } from '../ContentRatesAcquirer';
import { ContentRatesBranch } from '../ContentRatesBranch';
import { ContentRatesProduct } from '../ContentRatesProduct';

const ContentRates = () => {
  const { useBreakpoint } = Grid;
  const breakpoint = useBreakpoint();

  const items = [
    {
      label: 'Filial',
      key: 'branch',
      children: <ContentRatesBranch />,
    },
    {
      label: 'Adquirente',
      key: 'acquirer',
      children: <ContentRatesAcquirer />,
    },
    {
      label: 'Produto',
      key: 'product',
      children: <ContentRatesProduct />,
    },
  ];

  return (
    <div>
      <StLimiter $breakpoint={breakpoint}>
        <Tabs items={items} style={{ width: '100%' }} />
      </StLimiter>
    </div>
  );
};

export default ContentRates;
