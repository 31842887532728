import { lambdaApi } from '../api';

const deleteMerchant = async ({ merchantId }) => {
  const config = {
    params: {
      merchant_id: merchantId,
    },
  };
  const response = await lambdaApi().delete(
    'settings/maintenance/inactivate/merchants',
    config
  );
  return response.data;
};
export default deleteMerchant;
