import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from 'antd';

const LabelAndInputs = ({ label, align, children }) => (
  <div style={{ textAlign: align }}>
    <Typography.Text style={{ fontSize: 12 }}>{label}</Typography.Text>
    <br />
    {children}
  </div>
);

LabelAndInputs.defaultProps = {
  label: '',
  align: 'start',
};

LabelAndInputs.propTypes = {
  label: PropTypes.string,
  align: PropTypes.string,
  children: PropTypes.element.isRequired,
};

export default LabelAndInputs;
