import { actionTypes } from 'store/ducks/amountsReceivableInfo/types';

const INITIAL_STATE = {
  error: {},
  errorMessage: undefined,
  loading: true,
  isError: false,
  forecast: [],
  meta: { query_count: 0 },
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.AMOUNTS_RECEIVABLE_INFO_START:
      return {
        ...state,
        loading: true,
        error: {},
        errorMessage: undefined,
        isError: false,
      };
    case actionTypes.AMOUNTS_RECEIVABLE_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        error: {},
        forecast: action.payload.forecast,
        meta: action.payload.meta,
        isError: false,
        errorMessage: undefined,
      };
    case actionTypes.AMOUNTS_RECEIVABLE_INFO_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error.error,
        errorMessage: action.payload,
        isError: true,
        forecast: undefined,
      };
    default:
      return state;
  }
};

export default reducer;
