import { Col, Row } from 'antd';
import styled from 'styled-components';
import { colors } from 'styles/colors';

export const StCol = styled(Col)`
  width: 100%;
  height: 226px;
  background-color: ${colors.gray1};
`;

export const StRow = styled(Row)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const StLogoContainer = styled.div`
  width: 320px;
  height: 100%;
  position: absolute;
  left: 0;
  overflow: hidden;
`;

export const StLogo = styled.img`
  width: 100%;
  opacity: 10%;
  position: absolute;
  top: -50px;
`;

export const StDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 390px;

  > span {
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    margin-top: 12px;
    color: ${colors.gray13};
  }
`;

export const StPlansLogo = styled.img`
  width: 100%;
  height: 120px;
`;
