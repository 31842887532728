import { lambdaApi } from '../api';

const transformer = (apiResponse) => {
  return {
    data: apiResponse, // confirmar
  };
};

// Change notification status
export const changeNotificationStatus = async ({
  userId,
  clientId,
  scopeId,
}) => {
  const config = {
    user_id: userId,
    client_id: clientId,
    scope_id: scopeId,
  };
  const response = await lambdaApi().post('/export/change_status/post', config);
  return transformer(response.data);
};
