import { call, put } from 'redux-saga/effects';
import errorHandler from 'helpers/errorHandler';

import { errorToShow } from 'store/ducks/errorToShow/actions';

import { radarListFiltersSuccess, radarListFiltersError } from './actions';
import getRadarListFiltersFromAPI from 'services/radar/radarListFilters';

export function* radarListFiltersSaga(action) {
  const { payload } = action;
  try {
    const apiData = yield call(getRadarListFiltersFromAPI, payload);
    yield put(radarListFiltersSuccess(apiData));
  } catch (err) {
    if (
      err?.response?.data?.error &&
      err?.response?.data?.error?.type &&
      err?.response?.data?.error?.message
    ) {
      yield put(
        errorToShow({
          message: err.response.data.error.message,
          type: err.response.data.error.type,
        })
      );
      yield put(
        radarListFiltersError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    }
  }
}
