import { action } from 'typesafe-actions';
import { actionTypes } from 'store/ducks/radar/radarExpire/types';

export const radarExpireStart = (data) =>
  action(actionTypes.RADAR_EXPIRE_START, data);
export const radarExpireSuccess = (data) =>
  action(actionTypes.RADAR_EXPIRE_SUCCESS, data);
export const radarExpireError = (errorMessage, error) =>
  action(actionTypes.RADAR_EXPIRE_ERROR, { errorMessage }, undefined, {
    error,
  });
