import errorHandler from 'helpers/errorHandler';
import { call, put } from 'redux-saga/effects';
import getAcquirersByClientIdFromAPI from 'services/reconciliationRules/getAcquirersByClientId';
import {
  getAcquirersByClientIdError,
  getAcquirersByClientIdSuccess,
} from 'store/ducks/reconciliationRules/getAcquirersByClientId/actions';

export function* getAcquirersByClientIdSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(getAcquirersByClientIdFromAPI, payload);
    yield put(getAcquirersByClientIdSuccess(apiData));
  } catch (err) {
    yield put(getAcquirersByClientIdError(err?.response?.data?.message));
    errorHandler(`Erro: ${err?.response?.data?.message}`);
  }
}
