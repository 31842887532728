import React from 'react';
import { feeStatusTypes } from './feeStatusTypes';
import { Tag } from './styled';

const MerchantFeesStatus = ({ status = '', ...others }) => {
  if (!status) return null;

  const feeStatusType = feeStatusTypes[status] || {};
  const { fontColor, borderColor, backgroundColor } = feeStatusType;

  if (!fontColor || !borderColor || !backgroundColor) return null;

  return (
    <Tag
      fontColor={fontColor}
      borderColor={borderColor}
      backgroundColor={backgroundColor}
      {...others}
    >
      {status}
    </Tag>
  );
};

export default MerchantFeesStatus;
