import styled from 'styled-components';

export const Container = styled.div`
  display: inline;
  padding: 5px 8px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border: ${({ borderColor }) => `1px solid ${borderColor}`};
  border-radius: 2px;
  font-size: 12px;
  color: ${({ textColor }) => textColor};
`;
