import React from 'react';
import { Grid, Row } from 'antd';

import { StLimiter, StContainer } from './styled';

import { ContentRatesResume } from '../ContentRatesResume';

const ContentRatesBranch = () => {
  const { useBreakpoint } = Grid;
  const breakpoint = useBreakpoint();

  const data = [
    {
      id: 1,
      title: 'Concil Inteligência em Conciliação',
      difference: -98.45,
      bellow: 27,
      above: 12,
      equals: 56,
      notregistered: 5,
      type: 'branch'
    },
    {
      id: 2,
      title: 'Bolos e Doces Ltda',
      difference: 3.05,
      bellow: 0,
      above: 5,
      equals: 87,
      notregistered: 8,
      type: 'branch'
    },
    {
      id: 3,
      title: 'Auto Posto 7 Estrelas',
      difference: -0.94,
      bellow: 0,
      above: 0,
      equals: 100,
      notregistered: 0,
      type: 'branch'
    },
    {
      id: 4,
      title: 'Auma Comércio de Confecções Ltda',
      difference: -12.06,
      bellow: 54,
      above: 0,
      equals: 1,
      notregistered: 45,
      type: 'branch'
    },
    {
      id: 5,
      title: 'Papelaria Largo da Batata',
      difference: -98.45,
      bellow: 27,
      above: 12,
      equals: 56,
      notregistered: 5,
      type: 'branch'
    },
  ];

  return (
    <StContainer>
      <StLimiter $breakpoint={breakpoint}>
        <Row gutter={8} justify="space-between">
          {
            data.map((item) => {
              return <ContentRatesResume
                key={item.id}
                item={item}
              />;
            })
          }
        </Row>
      </StLimiter>
    </StContainer>
  );
};

export default ContentRatesBranch;
