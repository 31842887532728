/* Possíveis status:
    - IDENTIFICADO
    - NAO_IDENTIFICADO
    - IGNORADO
*/

export const acquirerStatus = (acquirerValue) => {
  if (acquirerValue === 'IGNORAR') return 'IGNORADO';
  if (acquirerValue === '' || acquirerValue === 'NAO_IDENTIFICADO') {
    return 'NAO_IDENTIFICADO';
  }
  return 'IDENTIFICADO';
};

export const isAcquirerIdentified = (acquirerValue) =>
  acquirerStatus(acquirerValue) === 'IDENTIFICADO';

export const isAcquirerIgnored = (acquirerValue) =>
  acquirerStatus(acquirerValue) === 'IGNORADO';

export const isAcquirerNotIdentified = (acquirerValue) =>
  acquirerStatus(acquirerValue) === 'NAO_IDENTIFICADO';
