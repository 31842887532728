import { actionTypes } from './types';

const INITIAL_STATE = {
  error: false,
  loading: true,
  users: [],
  allUsers: [],
  errorMessage: '',
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case actionTypes.LIST_USERS_START:
    return { ...state, loading: true, error: false };
  case actionTypes.LIST_USERS_SUCCESS:
    return {
      ...state,
      loading: false,
      error: false,
      users: action.payload.users,
      allUsers: action.payload.allUsers,
      errorMessage: null,
    };
  case actionTypes.LIST_USERS_ERROR:
    return {
      ...state,
      loading: false,
      users: [],
      allUsers: [],
      error: action.error.error,
      errorMessage: action.payload,
    };
  default:
    return state;
  }
};

export default reducer;
