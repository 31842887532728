/* eslint-disable no-param-reassign */
import React from 'react';

const TYPES = {
  CPF: '999.999.999-999',
  CNPJ: '99.999.999/9999-99',
};

const InputMaskCpfCnpj = (props) => {
  const { onChange } = props;

  const clear = (value) => value && value.replace(/[^0-9]/g, '');

  const getMask = (value) => (value.length > 11 ? 'CNPJ' : 'CPF');

  const applyMask = (value, mask) => {
    let result = '';
    let inc = 0;

    Array.from(value).forEach((letter, index) => {
      if (!mask[index + inc].match(/[0-9]/)) {
        result += mask[index + inc];
        inc += 1;
      }

      result += letter;
    });

    return result;
  };

  const MAX_LENGTH = clear(TYPES.CNPJ).length;

  let value = clear(props.value);

  if (value) {
    value = applyMask(value, TYPES[getMask(value)]);
  }

  const onLocalChange = (ev) => {
    let targetValue = clear(ev.target.value);
    const mask = getMask(targetValue);
    const nextLength = targetValue.length;

    if (nextLength > MAX_LENGTH) return;

    targetValue = applyMask(targetValue, TYPES[mask]);
    ev.target.value = targetValue;
    onChange(ev, mask);
  };

  return <input {...props} value={value} onChange={onLocalChange} />;
};

export default InputMaskCpfCnpj;
