import { call, put } from 'redux-saga/effects';
import errorHandler from 'helpers/errorHandler';
import getDuedateInfoFiltersFromAPI from 'services/filters/duedateInfoFilters';
import { duedateInfoFiltersSuccess, duedateInfoFiltersError } from './actions';

export function* duedateInfoFiltersSaga(action) {
  const { payload } = action;

  try {
    const apiData = yield call(getDuedateInfoFiltersFromAPI, payload);
    yield put(duedateInfoFiltersSuccess(apiData));
  } catch (err) {
    if (
      err?.response?.data?.error &&
      err?.response?.data?.error?.type &&
      err?.response?.data?.error?.message
    ) {
      yield put(
        duedateInfoFiltersError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(
        err.response.data.error.message,
        err.response.data.error
      );
    }
  }
}
