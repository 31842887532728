import { getSessionClientId } from 'helpers/sessionService';
import { api } from './api';

const transformResponse = (response) => {
  // console.log('RESPONSE', response);
  return response?.data?.data;
};

const getListOfClientsSelect = async ({ acquirerId }) => {
  // console.log('acquirerId:', acquirerId);
  const clientId = getSessionClientId();
  const response = await api().get(
    `/acquirer/merchants?acquirer_id=${acquirerId}&client_id=${clientId}`
  );
  return transformResponse(response);
};
export default getListOfClientsSelect;

// {
//   "acquirer_id": merchant.acquirer_id,
//   "store_id": merchant.store_id,
//   "store_br_cnpj": merchant.store.br_cnpj,
//   "store_name": merchant.store.name,
//   "store_short_name": merchant.store.short_name,
//   "merchant_id": merchant.merchant_id,
//   "merchant_code": merchant.merchant_code,
// }
