import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { StRow } from './styled';
import { Col, Select, Button } from 'antd';
import { FiltersContainer } from 'commons/FiltersContainer';
import { LabelAndFilter } from 'commons/LabelAndFilter';
import DatePicker from 'commons/DatePicker/DatePicker';
import { shortId } from 'helpers/shortId';
import {
  SortAscendingOutlined,
  SortDescendingOutlined,
} from '@ant-design/icons';
import { dataTestIdHandler } from 'helpers/dataTestIdHandler';
import { PermissionsContext } from 'routes/PrivateRoute';

const Filters = ({
  title,
  subtitle,
  onRangePickerOpenOrCloseChange,
  hasRangePicker,
  hasSelect,
  hasSorter,
  dateDefaultValue,
  onRangePickerChange,
  onChangeSelectFilter,
  loading,
  sorter,
  sort,
  filterName,
  sorterButtonText,
  infoFiltersToSelect,
  activeTab,
  disabledDate,
  calendarIsOpen,
  setCalendarIsOpen,
}) => {
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const rangePickerRef = useRef(null);
  const [tabPlaceholder, setTabPlaceholder] = useState(activeTab);
  const [dates, setDates] = useState([null]);
  const [hackValue, setHackValue] = useState(null);
  const hasInfoFiltersToSelect = infoFiltersToSelect.length > 0;
  const dataTestPrefix = 'filters';
  const { permissions } = useContext(PermissionsContext);
  const planId = permissions?.plan_id;
  const [startDate, endDate] = dateDefaultValue;
  const antInputClearBtn =
    document.getElementsByClassName('ant-picker-clear')[0];
  const onOpenChange = (open) => {
    onRangePickerOpenOrCloseChange(open);

    if (open) {
      setHackValue([startDate, null]);
    } else {
      setHackValue(null);
    }
  };

  const clearDates = () => {
    setDates([null, null]);
  };

  useEffect(() => {
    switch (activeTab) {
      case 'branches':
        setTabPlaceholder('Filial');
        break;
      case 'acquirers':
        setTabPlaceholder('Adquirente');
        break;
      case 'products':
        setTabPlaceholder('Produto');
        break;
      case 'brands':
        setTabPlaceholder('Bandeira');
        break;
      default:
        setTabPlaceholder('Filial');
    }
  }, [activeTab]);

  useEffect(() => {
    const antInput = document.getElementsByClassName('ant-picker-input');

    antInput[0]?.addEventListener('click', clearDates);

    return () => {
      antInput[0]?.removeEventListener('onclick', clearDates);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const _antInputClearBtn =
        document.getElementsByClassName('ant-picker-clear')[0];

      // eslint-disable-next-line no-unused-expressions
      _antInputClearBtn?.addEventListener('click', (e) => {
        clearDates();

        e.stopPropagation();
      });
    }, 100);
  }, [antInputClearBtn]);

  useEffect(() => {
    const antInput = document.getElementsByClassName('ant-picker-input');
    const handleDates = () => setDates([startDate, null]);
    antInput[1]?.addEventListener('click', handleDates);

    return () => {
      antInput[1]?.removeEventListener('onclick', handleDates);
    };
  }, [startDate]);

  return (
    <FiltersContainer title={title} subTitle={subtitle}>
      <StRow gutter={8}>
        <Col
          xxl={hasRangePicker ? 4 : 0}
          xl={hasRangePicker ? 5 : 0}
          lg={hasRangePicker ? 6 : 0}
          md={hasRangePicker ? 6 : 0}
          sm={hasRangePicker ? 12 : 0}
          xs={hasRangePicker ? 24 : 0}
        >
          <LabelAndFilter label="Calendário">
            <RangePicker
              {...dataTestIdHandler(dataTestPrefix, 'range-picker')}
              ref={rangePickerRef}
              format="DD/MM/YYYY"
              onOpenChange={onOpenChange}
              onCalendarChange={(val) => {
                setHackValue(val);
                setDates(val);
                if (val?.every((v) => v)) {
                  setCalendarIsOpen(!calendarIsOpen);
                }
              }}
              disabled={loading}
              disabledDate={(current) => {
                if (dates) {
                  return disabledDate({ current, planId, ...dates });
                }

                return disabledDate({ current, startDate, endDate });
              }}
              open={calendarIsOpen}
              value={hackValue || dateDefaultValue}
              onChange={onRangePickerChange}
              style={{ width: '100%' }}
            />
          </LabelAndFilter>
        </Col>
        <Col
          xxl={hasSelect ? 4 : 0}
          xl={hasSelect ? 4 : 0}
          lg={hasSelect ? 6 : 0}
          md={hasSelect ? 6 : 0}
          sm={hasSelect ? 12 : 0}
          xs={hasSelect ? 24 : 0}
        >
          <LabelAndFilter label={tabPlaceholder}>
            <Select
              showSearch
              allowClear
              onChange={(val) => onChangeSelectFilter(val)}
              value={filterName}
              disabled={loading}
              style={{ width: '100%' }}
              placeholder="Buscar"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {hasInfoFiltersToSelect &&
                infoFiltersToSelect.map(
                  (item) =>
                    item && (
                      <Option key={shortId()} value={item}>
                        {item}
                      </Option>
                    )
                )}
            </Select>
          </LabelAndFilter>
        </Col>
        <Col xxl={12} xl={11} lg={6} md={2} />
        <Col
          xxl={hasSorter ? 4 : 0}
          xl={hasSorter ? 4 : 0}
          lg={hasSorter ? 6 : 0}
          md={hasSorter ? 6 : 0}
        >
          <LabelAndFilter
            label="Ordenar por"
            align="end"
            style={{ alignItems: 'end' }}
          >
            <Button
              disabled={loading}
              type="dashed"
              icon={
                sort === 'ASC' ? (
                  <SortAscendingOutlined />
                ) : (
                  <SortDescendingOutlined />
                )
              }
              size="small"
              onClick={sorter}
            >
              {sorterButtonText}
            </Button>
          </LabelAndFilter>
        </Col>
      </StRow>
    </FiltersContainer>
  );
};

Filters.defaultProps = {
  infoFiltersToSelect: [],
  disabledDate: () => false,
  onChangeSelectFilter: () => {},
  sorter: () => {},
  sort: '',
  activeTab: '',
};

Filters.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  onRangePickerChange: PropTypes.func.isRequired,
  onChangeSelectFilter: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  sorter: PropTypes.func,
  sort: PropTypes.string,
  hasRangePicker: PropTypes.bool.isRequired,
  hasSelect: PropTypes.bool.isRequired,
  hasSorter: PropTypes.bool.isRequired,
  infoFiltersToSelect: PropTypes.arrayOf(PropTypes.string.isRequired),
  onRangePickerOpenOrCloseChange: PropTypes.func.isRequired,
  activeTab: PropTypes.string,
  disabledDate: PropTypes.func,
};

export default Filters;
