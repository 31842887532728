import { action } from 'typesafe-actions';
import { actionTypes } from 'store/ducks/radar/radarList/types';

export const radarListStart = (data) =>
  action(actionTypes.RADAR_LIST_START, data);
export const radarListSuccess = (data) =>
  action(actionTypes.RADAR_LIST_SUCCESS, data);
export const radarListError = (errorMessage, error) =>
  action(actionTypes.RADAR_LIST_ERROR, { errorMessage }, undefined, {
    error,
  });
