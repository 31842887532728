/* eslint-disable no-param-reassign */
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Loadable } from '../../../../commons/Loadable';
import { useDispatch, useSelector } from 'react-redux';
import {
  acquirerPointOfSaleStart,
  acquirerStart,
} from '../../../../store/ducks/acquirer/actions';
import { List, notification } from 'antd';
import { AcquirerCheckCard } from '../AcquirerCheckCard';
import { HeaderRegisters } from '../../../../commons/HeaderRegisters';
import {
  deleteClientAcquirerStart,
  postClientAcquirerStart,
} from '../../../../store/ducks/clientAcquirer/actions';
import { getSessionClientId } from 'helpers/sessionService';

const BriefingAcquirersPointOfSale = forwardRef(
  (
    {
      acquirers,
      setAcquirers,
      setIsNextButtonLocked,
      throughModal,
      isLocked,
      isCNPJMaintenance = false,
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const clientAcquirerLoading = useSelector(
      (state) => state.clientAcquirer.loading
    );
    const clientAcquirerSuccess = useSelector(
      (state) => state.clientAcquirer.success
    );
    const acquirerData = useSelector(
      (state) => state.acquirer.acquirersPointOfSale
    );
    const loadingPointOfSale = useSelector(
      (state) => state.acquirer.loadingPointOfSale
    );

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    useImperativeHandle(ref, () => ({
      validateBeforeNextPage(afterValidate) {
        if (acquirers.some((a) => a.checked)) {
          afterValidate();
        } else {
          notification.warn({
            message: 'É necessário selecionar ao menos 1 (uma) adquirente.',
          });
        }
      },
    }));
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (!clientAcquirerLoading) {
        dispatch(acquirerPointOfSaleStart());
      }
      if (!clientAcquirerLoading && clientAcquirerSuccess) {
        dispatch(acquirerStart({}));
      }
      setIsNextButtonLocked(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, clientAcquirerLoading, clientAcquirerSuccess]);

    useEffect(() => {
      setAcquirers(acquirerData?.acquirers);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, acquirerData]);

    const onAcquirerCheckClick = (acquirer) => {
      const data = {
        acquirer_id: acquirer.acquirer_id,
        client_id: getSessionClientId(),
      };
      if (acquirer.checked) {
        dispatch(deleteClientAcquirerStart(data));
      } else {
        dispatch(postClientAcquirerStart(data));
      }
      forceUpdate();
    };

    return (
      <HeaderRegisters
        title="Selecionar máquinas"
        subTitle={
          isCNPJMaintenance
            ? 'Selecione todas as máquinas que deseja adicionar'
            : 'Selecione todas as máquinas que você aceita nos CNPJs cadastrados'
        }
      >
        <Loadable
          loading={loadingPointOfSale || clientAcquirerLoading || !acquirers}
        >
          <List
            grid={
              throughModal
                ? { xs: 1, sm: 2, md: 3, lg: 3, xl: 6, xxl: 8 }
                : { xs: 1, sm: 2, md: 3, lg: 5, xl: 7, xxl: 9 }
            }
            dataSource={acquirers}
            renderItem={(acquirer) => (
              <AcquirerCheckCard
                isCNPJMaintenance={isCNPJMaintenance}
                isEmpty={acquirer.is_empty}
                disabled={isLocked}
                acquirer={acquirer}
                onClick={() => onAcquirerCheckClick(acquirer)}
              />
            )}
          />
        </Loadable>
      </HeaderRegisters>
    );
  }
);

export default BriefingAcquirersPointOfSale;
