import { bankingApi } from '../api';

const transformer = (apiResponse) => {
  return {
    data: apiResponse?.payload,
  };
};

const getCreditListPartnersFromAPI = async () => {
  const response = await bankingApi().get('/credit/list/partners');
  return transformer(response.data);
};

export default getCreditListPartnersFromAPI;
