import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import { Filters, ContentTabs } from '../components';
import { listAdjustmentPaymentsDash } from 'store/ducks/paymentsDash/adjustmentPayments/actions';
import { adjustmentPaymentsIntermediate } from 'store/ducks/paymentsIntermediate/adjustmentPaymentsIntermediate/actions';
import { adjustmentInfoFiltersStart } from 'store/ducks/filters/adjustmentInfoFilters/actions';
import { updateAdjustmentDetailsFilters } from 'store/ducks/filters/details/adjustmentDetailsFilters/actions';
import { disableLastMonth } from 'helpers/disableDates';
import { useResetFilters } from 'utils/hooks/useResetFilters';
import { onSorter } from 'helpers/sorter';
import { useRangePickerChange } from 'utils/hooks/useRangePickerChange';

const AdjustmentPaymentsIntermediate = () => {
  const dispatch = useDispatch();
  const error = {
    has: false,
  };

  const startDate = useSelector(
    (state) => state.updateScreenToScreenDate.startDate
  );
  const endDate = useSelector(
    (state) => state.updateScreenToScreenDate.endDate
  );

  const [sort, setSort] = useState('DESC');

  const [filtersDrawer, toggleFiltersDrawer] = useState(false);

  const [activeTab, setActiveTab] = useState('branches');

  const [filterName, setFilterName] = useState(null);

  const adjustmentPaymentsData = useSelector(
    (store) => store.adjustmentPaymentsDash.payments
  );
  const adjustmentPaymentsLoading = useSelector(
    (store) => store.adjustmentPaymentsDash.loading
  );
  const isSummaryError = useSelector(
    (state) => state.adjustmentPaymentsDash.isError
  );

  const payments = useSelector(
    (state) => state.adjustmentPaymentsIntermediate.payments
  );
  const paymentsLoading = useSelector(
    (state) => state.adjustmentPaymentsIntermediate.loading
  );
  const paymentsError = useSelector(
    (state) => state.adjustmentPaymentsIntermediate.isError
  );

  if (isSummaryError || paymentsError) {
    error.has = true;
    error.title = 'Dados indisponíveis';
    error.reason =
      'Não conseguimos buscar os dados, mas já estamos trabalhando para normalizar a situação.';
    error.recommendation = 'Tente atualizar a página em alguns instantes.';
  }

  const adjustmentInfoFilters = useSelector(
    (state) => state.adjustmentInfoFilters.filters
  );

  const loading = paymentsLoading || adjustmentPaymentsLoading;

  const [calendarIsOpen, setCalendarIsOpen] = useState(false);

  const { onRangePickerChange } = useRangePickerChange();

  useEffect(() => {
    if (!calendarIsOpen) {
      dispatch(
        adjustmentPaymentsIntermediate({
          startDate,
          endDate,
          page: 1,
          type: activeTab,
          perPage: 100,
          sort,
          filter: filterName,
        })
      );
      dispatch(
        adjustmentInfoFiltersStart({ startDate, endDate, type: activeTab })
      );
      dispatch(listAdjustmentPaymentsDash({ startDate, endDate }));
    }
  }, [
    activeTab,
    dispatch,
    endDate,
    filterName,
    sort,
    startDate,
    calendarIsOpen,
  ]);

  const onRangePickerOpenOrCloseChange = (open) => {
    setCalendarIsOpen(open);
  };

  const onChangeSelectFilter = (val) => {
    if (val === 'Todos' || val === 'Buscar' || val === '') {
      setFilterName(null);
    } else {
      setFilterName(val);
    }
  };

  const onTabClick = (key) => {
    setActiveTab(key);
    onChangeSelectFilter(null);
  };

  const { resetFilters } = useResetFilters();

  useEffect(() => {
    resetFilters(updateAdjustmentDetailsFilters);
  }, [resetFilters]);

  return (
    <>
      <Filters
        onRangePickerOpenOrCloseChange={onRangePickerOpenOrCloseChange}
        hasRangePicker
        hasSelect
        hasSorter
        dateDefaultValue={[startDate, endDate]}
        onRangePickerChange={(dates) =>
          onRangePickerChange(dates, calendarIsOpen)
        }
        onChangeSelectFilter={onChangeSelectFilter}
        loading={loading}
        sort={sort}
        sorter={onSorter(sort, setSort)}
        filtersDrawer={filtersDrawer}
        filterName={filterName}
        renderFilterButton={() => (
          <Button
            style={{ borderRadius: 4 }}
            onClick={() => toggleFiltersDrawer(!filtersDrawer)}
          >
            Filtros
          </Button>
        )}
        onCloseFiltersDrawer={() => toggleFiltersDrawer(false)}
        sorterButtonText="Total de ajustes"
        activeTab={activeTab}
        infoFiltersToSelect={adjustmentInfoFilters}
        titleComponent="Ajustes"
        subtitleComponent="Total de ajustes e aluguel de POS"
        disabledDate={disableLastMonth}
        calendarIsOpen={calendarIsOpen}
        setCalendarIsOpen={setCalendarIsOpen}
      />
      <ContentTabs
        infoData={payments}
        infoLoading={paymentsLoading}
        summaryData={adjustmentPaymentsData}
        summaryLoading={adjustmentPaymentsLoading}
        detailRoute="ajustes-relatorios"
        isSummaryError={error}
        onTabClick={onTabClick}
        activeTab={activeTab}
        updateAction={updateAdjustmentDetailsFilters}
        titleSummaryCard="Resumo total"
        screen="adjustment-payments"
      />
    </>
  );
};

export default AdjustmentPaymentsIntermediate;
