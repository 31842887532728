// TODO: Remove camelcase eslint rule
/* eslint-disable camelcase,array-callback-return,no-param-reassign */
import { api } from '../api';

const transformer = (apiResponse) => {
  const { data } = apiResponse;
  const payments = data.payments.map((item) => {
    return {
      title: item.name,
      data: [
        {
          title: 'Valores recebidos sem desconto',
          value: new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(item.gross_amount),
        },
        {
          title: 'Taxa administrativa',
          value: new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(item.adm_fee_amount),
        },
        {
          title: 'Total',
          value: new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(item.net_amount),
        },
        {
          title: 'Tarifa',
          value: new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(item.adq_fare_amount),
        },
        {
          title: 'Repasse',
          value: new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(item.transfer_amount),
        },
      ],
    };
  });
  return { payments };
};

const getDuedatePaymentsIntermediate = async ({
  startDate,
  endDate,
  type,
  page,
  perPage,
  sort,
  filter,
}) => {
  const config = {
    params: {
      start_date: startDate.format('YYYY-MM-DD'),
      end_date: endDate.format('YYYY-MM-DD'),
      type,
      page,
      per_page: perPage,
      order_by: sort,
      filter,
    },
  };
  const response = await api().get('/duedate/payments', config);
  return transformer(response.data);
};

export default getDuedatePaymentsIntermediate;
