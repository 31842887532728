/* Color palette Ant Design based
  https://www.figma.com/file/c8jJKFn6Lw1ApoldXS4QFi/%5BConcil-Web%5D-Ant-Design-System-for-Figma-2.0-(Light-Theme)?node-id=449%3A5721
*/

const grays = {
  gray1: '#FFFFFF', // Light background
  gray2: '#FAFAFA', // Table header
  gray3: '#F5F5F5', // Background
  gray4: '#F0F0F0', // Divider
  gray5: '#D9D9D9', // Border
  gray6: '#BFBFBF', // Disable
  gray7: '#8C8C8C', // Secondary text
  gray8: '#595959', // Primary text
  gray9: '#262626', // Title / UI text
  gray10: '#000000',
  gray11: '#4A4A4A', // extra
  gray12: '#484C54',
  gray13: '#818181',
};

const primaries = {
  primary1: '#E1FAF7', // Selected background color
  primary2: '#ADEDE9',
  primary3: '#80E0DD', // Border
  primary4: '#57D4D4',
  primary5: '#32C2C7', // Hover
  primary6: '#10AEBA', // Normal
  primary7: '#068694', // Click
  primary8: '#005F6E',
  primary9: '#003B47',
  primary10: '#001B21',
};

const secondaries = {
  secondary1: '#F0FFF8', // Selected background color
  secondary2: '#EBFAF3',
  secondary3: '#DFEDE7', // Border
  secondary4: '#D1E0DA',
  secondary5: '#A3D4C3', // Hover
  secondary6: '#79C6AD', // Normal
  secondary7: '#5AA18D', // Click
  secondary8: '#3E7A6B',
  secondary9: '#27544A',
  secondary10: '#152E29',
  secondary11: '#EDFCDD', // extra
};

const blues = {
  blue1: '#E6F7FF', // Colorful tag background
  blue2: '#BAE7FF',
  blue3: '#91D5FF', // Colorful tag border
  blue4: '#69C0FF',
  blue5: '#40A9FF',
  blue6: '#1890FF', // Colorful tag normal / link
  blue7: '#096DD9',
  blue8: '#0050B3',
  blue9: '#003A8C',
  blue10: '#002766',
};

const reds = {
  red1: '#FFF1F0', // Error background
  red2: '#FFCCC7',
  red3: '#FFA39E', // Error border
  red4: '#FF7875', // Error hover
  red5: '#FF4D4F', // Error normal
  red6: '#F5222D', // Error click
  red7: '#CF1322',
  red8: '#A8071A',
  red9: '#820014',
  red10: '#5C0011',
  red11: '#E6A3A3',
};

const volcanos = {
  volcano1: '#FFF2E8', // Colorful tag background
  volcano2: '#FFD8BF',
  volcano3: '#FFBB96', // Colorful tag border
  volcano4: '#FF9C6E',
  volcano5: '#FF7A45',
  volcano6: '#FA541C', // Colorful tag normal
  volcano7: '#D4380D',
  volcano8: '#AD2102',
  volcano9: '#871400',
  volcano10: '#610B00',
};

const oranges = {
  orange1: '#FFF7E6', // Colorful tag background
  orange2: '#FFE7BA',
  orange3: '#FFD591', // Colorful tag border
  orange4: '#FFC069',
  orange5: '#FA8C16',
  orange6: '#FA8C16', // Colorful tag normal
  orange7: '#D46B08',
  orange8: '#AD4E00',
  orange9: '#873800',
  orange10: '#612500',
};

const golds = {
  gold1: '#FFFBE6', // Warning background
  gold2: '#FFF1B8',
  gold3: '#FFE58F', // Warning border
  gold4: '#FFD666',
  gold5: '#FFC53D', // Warning hover
  gold6: '#FAAD14', // Warning normal
  gold7: '#D48806', // Warning click
  gold8: '#AD6800',
  gold9: '#874D00',
  gold10: '#613400',
  gold11: '#B79C44', // extra
  gold12: '#DDC13C', // extra
};

const yellows = {
  yellow1: '#FEFFE6', // Colorful tag background
  yellow2: '#FFFFB8',
  yellow3: '#FFFB8F', // Colorful tag border
  yellow4: '#FFF566',
  yellow5: '#FFEC3D',
  yellow6: '#FADB14', // Colorful tag normal
  yellow7: '#D4B106',
  yellow8: '#AD8B00',
  yellow9: '#876800',
  yellow10: '#614700',
};

const limes = {
  lime1: '#FCFFE6', // Colorful tag background
  lime2: '#F4FFB8',
  lime3: '#EAFF8F', // Colorful tag border
  lime4: '#D3F261',
  lime5: '#BAE637',
  lime6: '#A0D911', // Colorful tag normal
  lime7: '#7CB305',
  lime8: '#5B8C00',
  lime9: '#3F6600',
  lime10: '#254000',
};

const greens = {
  green1: '#F6FFED', // Success background
  green2: '#D9F7BE',
  green3: '#B7EB8F', // Success border
  green4: '#95DE64',
  green5: '#73D13D', // Success hover
  green6: '#52C41A', // Success normal
  green7: '#389E0D', // Success click
  green8: '#237804',
  green9: '#135200',
  green10: '#092B00',
  green11: '#E0FC79', // extra
  green12: '#FBFFE6', // extra
  green13: '#93D500', // extra
  green14: '#96C651', // extra
};

const cyans = {
  cyan1: '#E6FFFB', // Colorful tag background
  cyan2: '#B5F5EC',
  cyan3: '#87E8DE', // Colorful tag border
  cyan4: '#5CDBD3',
  cyan5: '#36CFC9',
  cyan6: '#13C2C2', // Colorful tag normal
  cyan7: '#08979C',
  cyan8: '#006D75',
  cyan9: '#00474F',
  cyan10: '#002329',
  cyan11: '#1A859E', // extra
};

const geekBlues = {
  geekBlue1: '#F0F5FF', // Colorful tag background
  geekBlue2: '#D6E4FF',
  geekBlue3: '#ADC6FF', // Colorful tag border
  geekBlue4: '#85A5FF',
  geekBlue5: '#597EF7',
  geekBlue6: '#2F54EB', // Colorful tag normal
  geekBlue7: '#1D39C4',
  geekBlue8: '#10239E',
  geekBlue9: '#061178',
  geekBlue10: '#030852',
};

const purples = {
  purple1: '#F9F0FF', // Colorful tag background
  purple2: '#EFDBFF',
  purple3: '#D3ADF7', // Colorful tag border
  purple4: '#B37FEB',
  purple5: '#9254DE',
  purple6: '#722ED1', // Colorful tag normal
  purple7: '#531DAB',
  purple8: '#391085',
  purple9: '#22075E',
  purple10: '#120338',
};

const magentas = {
  magenta1: '#FFF0F6', // Colorful tag background
  magenta2: '#FFD6E7',
  magenta3: '#FFADD2', // Colorful tag border
  magenta4: '#FF85C0',
  magenta5: '#F759AB',
  magenta6: '#EB2F96', // Colorful tag normal
  magenta7: '#C41D7F',
  magenta8: '#9E1068',
  magenta9: '#780650',
  magenta10: '#520339',
};

const extra = {
  background1: '#F0F2F5',
  background2: '#10AEBA',
  logoConcil1: '#007FA1',
  logoConcil2: '#93D500',
  leftMenuBackground: '#134761',
  leftMenuBackground2: '#195d80',
  boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.04)',
};

export const colors = {
  ...grays,
  ...primaries,
  ...secondaries,
  ...blues,
  ...reds,
  ...volcanos,
  ...oranges,
  ...golds,
  ...yellows,
  ...limes,
  ...greens,
  ...cyans,
  ...geekBlues,
  ...purples,
  ...magentas,
  ...extra,
};
