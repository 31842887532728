import React from 'react';
import BlockedFlipCard from 'components/BlockedFlipCard/BlockedFlipCard';
import {
  StBar,
  StChart,
  StColumnLeft,
  StColumnLeftLabel,
  StColumnLeftValue,
  StColumnRight,
  StConcilBackground,
  StContainer,
  StContent,
  StHeader,
  StIllustrativeValues,
  StLeftChartLabel,
  StLeftChartValue,
  StLockOutlined,
  StOverlay,
  StRightChartLabel,
  StRightChartValue,
  StTitle,
} from './styled';
import { colors } from 'styles/colors';
import plans from 'constants/plans';

const assetsBaseURL = process.env.REACT_APP_AWS_V4_ASSETS;
const iconConcil = `${assetsBaseURL}/img/iconConcil.png`;

const Cover = () => {
  return (
    <StContainer>
      <StHeader>
        <StLockOutlined />
        <StTitle>Conciliação de pagamentos</StTitle>
      </StHeader>
      <StIllustrativeValues>*Valores ilustrativos</StIllustrativeValues>
      <StContent>
        <StColumnLeft>
          <StColumnLeftLabel>Conciliado (0%)</StColumnLeftLabel>
          <StColumnLeftValue>R$ 1.000.000,00</StColumnLeftValue>
          <StColumnLeftLabel>Não-conciliado (0%)</StColumnLeftLabel>
          <StColumnLeftValue color={colors.red5}>
            R$ 1.000.000,00
          </StColumnLeftValue>
        </StColumnLeft>
        <StColumnRight>
          <StChart>
            <StLeftChartLabel>Previsto </StLeftChartLabel>
            <div>
              <StLeftChartValue>R$ 1.000.000,00</StLeftChartValue>
              <StBar height={85} color={colors.primary6} />
            </div>
          </StChart>
          <StChart>
            <StRightChartLabel>Realizado </StRightChartLabel>
            <div>
              <StRightChartValue>R$ 1.000.000,00</StRightChartValue>
              <StBar height={85} color={colors.secondary6} />
            </div>
          </StChart>
        </StColumnRight>
      </StContent>
      <StConcilBackground src={iconConcil} alt="Icone Concil Card" />
      <StOverlay />
    </StContainer>
  );
};

const PaymentReconciliationCardBlocked = ({ ...others }) => {
  return (
    <BlockedFlipCard
      title="Conciliação de pagamento"
      backDescription={
        <>
          <span>
            Tenha acesso a essa funcionalidade a partir do plano Controle.
          </span>
          <br />
          <span>
            Você poderá fazer a conferência entre tudo que estava previsto e
            tudo que teve confirmação de pagamento pelas adquirentes, sabendo se
            há algum tipo de diferença entre o previsto e o realizado.
          </span>
        </>
      }
      planAvailable={plans.CONTROLE}
      {...others}
    >
      <Cover />
    </BlockedFlipCard>
  );
};

export default PaymentReconciliationCardBlocked;
