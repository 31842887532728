import styled from 'styled-components';

import { Divider, Typography } from 'antd';
import { colors } from 'styles/colors';

export const StDivider = styled(Divider)`
  &&& {
    width: 0.125rem;
    height: 20px;
    color: ${colors.gray9};
  }
`;

export const StTotalText = styled(Typography.Text)`
  &&& {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: ${colors.gray9};
  }
`;
