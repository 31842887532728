import styled, { css } from 'styled-components';
import { parseReconStyle } from '../utils/parser';
import { colors } from 'styles/colors';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  height: ${({ reconType, highestTotal, lowestTotal }) =>
    parseReconStyle(reconType, highestTotal, lowestTotal).height || '100%'};
`;

export const BorderColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: ${({ textAlign }) => textAlign && textAlign};
  height: ${({ reconType, highestTotal, lowestTotal }) =>
    parseReconStyle(reconType, highestTotal, lowestTotal).height};
`;

export const MiddleColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: ${({ alignItems }) => alignItems && alignItems};
  margin-left: ${({ marginLeft }) => marginLeft && marginLeft}px;
  margin-right: ${({ marginRight }) => marginRight && marginRight}px;
`;

export const DescriptionTitle = styled.span`
  color: ${colors.gray7};
  font-size: 12px;
`;

export const ReconciledAmountText = styled.span`
  color: ${colors.gray8};
  font-weight: 500;
  margin-bottom: 4px;
  font-size: 2rem;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const PendentAmountText = styled.span`
  color: ${colors.red5};
  font-weight: 500;
  font-size: 2rem;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const ReconColDescription = styled.span`
  margin-top: 16px;
  color: rgba(0, 0, 0, 0.85);

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const Chart = css`
  /* height: 100%; */
  width: 72px;
  &:hover {
    opacity: 0.8;
  }
`;

export const ReconciliationBar = styled.div`
  height: ${({ height }) => height}px;
  background-color: ${({ color }) =>
    color === 'primary' ? colors.primary6 : colors.secondary6};
  border-radius: 4px 4px 0px 0px;
  ${Chart};

  :hover {
    cursor: pointer;
  }
`;
