import { action } from 'typesafe-actions';
import { actionTypes } from './types';

export const reconciledSalesInfoFiltersStart = (data) =>
  action(actionTypes.RECONCILED_SALES_INFO_FILTERS_START, data);
export const reconciledSalesInfoFiltersSuccess = (data) =>
  action(actionTypes.RECONCILED_SALES_INFO_FILTERS_SUCCESS, data);
export const reconciledSalesInfoFiltersError = (errorMessage, error) =>
  action(
    actionTypes.RECONCILED_SALES_INFO_FILTERS_ERROR,
    { errorMessage },
    undefined,
    { error }
  );
