import { action } from 'typesafe-actions';
import { actionTypes } from './types';

export const deleteStoresStart = (data) =>
  action(actionTypes.DELETE_STORES_START, data);
export const deleteStoresSuccess = (data) =>
  action(actionTypes.DELETE_STORES_SUCCESS, data);
export const deleteStoresError = (errorMessage, error) =>
  action(actionTypes.DELETE_STORES_ERROR, { errorMessage }, undefined, {
    error,
  });
