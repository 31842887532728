import React from 'react';
import { Button, Col, Row, Grid, Typography } from 'antd';
import { StSpace, StRow } from './styled';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { colors } from 'styles/colors';

const SendMessageDesktop = () => {
  const navigate = useNavigate();
  const { useBreakpoint } = Grid;
  const breakpoint = useBreakpoint();
  const baseURL = process.env.REACT_APP_AWS_V4_ASSETS;
  const logo = `${baseURL}/login/logo.png`;

  const goToLogin = () => {
    navigate('/');
    window.location.reload();
  };

  return (
    <StRow align="top" justify="center" $breakpoint={breakpoint}>
      <StSpace $breakpoint={breakpoint}>
        <Row>
          <Col style={{ marginTop: '11rem' }}>
            <img
              src={logo}
              alt="Logo Concil Card"
              style={{ marginTop: '1rem' }}
            />
          </Col>
        </Row>

        <Row style={{ marginTop: '12rem' }}>
          <Col>
            <Typography.Text
              style={{
                fontSize: '16px',
                fontWeight: 'bold',
                color: colors.gray9,
              }}
            >
              E-mail enviado!
            </Typography.Text>
          </Col>
        </Row>
        <Row style={{ width: '100%', marginTop: 16 }}>
          <Col style={{ textAlign: 'center', maxWidth: 265 }}>
            <Typography.Text
              style={{
                textAlign: 'center',
                fontSize: '14px',
                color: colors.gray8,
                lineHeight: '22px',
              }}
            >
              Siga as instruções informadas no e-mail para redefinir sua senha.
            </Typography.Text>
          </Col>
        </Row>
        <Row style={{ width: '100%', marginTop: '10rem' }}>
          <Col style={{ width: '100%' }}>
            <Button
              type="primary"
              style={{ width: '100%' }}
              onClick={goToLogin}
            >
              <ArrowLeftOutlined /> Voltar à tela inicial
            </Button>
          </Col>
        </Row>
      </StSpace>
    </StRow>
  );
};

export default SendMessageDesktop;
