/* eslint-disable consistent-return */
import React from 'react';
import PropTypes from 'prop-types';
import { StRect } from './styled';
import { colors } from 'styles/colors';

const redValues = [
  'Não conciliado',
  'Previsão em aberto',
  'Diferença de valor e com atraso',
  'Diferença de valor',
  'Pago com atraso',
  'Diferença',
];

const greenValues = ['Conciliado', 'Pagamento conciliado'];

const ReasonCell = ({ reason }) => {
  if (redValues.find((val) => val === reason)) {
    return (
      <StRect color={colors.red6} border={colors.red3} background={colors.red1}>
        {reason}
      </StRect>
    );
  }
  if (greenValues.find((val) => val === reason)) {
    return (
      <StRect
        color={colors.green6}
        border={colors.green3}
        background={colors.green1}
      >
        {reason}
      </StRect>
    );
  }

  // Default
  if (
    redValues.find((val) => val !== reason) &&
    greenValues.find((val) => val !== reason)
  ) {
    return (
      <StRect color={colors.gray6} border={colors.gray5} background="#f0eded">
        {reason}
      </StRect>
    );
  }
};

ReasonCell.propTypes = {
  reason: PropTypes.string.isRequired,
};

export default ReasonCell;
