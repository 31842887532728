import { action } from 'typesafe-actions';
import { actionTypes } from './types';

export const updateStoreStart = (data) =>
  action(actionTypes.UPDATE_STORE_START, data);
export const updateStoreSuccess = (data) =>
  action(actionTypes.UPDATE_STORE_SUCCESS, data);
export const updateStoreError = (errorMessage, error) =>
  action(actionTypes.UPDATE_STORE_ERROR, { errorMessage }, undefined, {
    error,
  });
