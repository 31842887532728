import { lambdaApi } from '../api';

const transformer = (apiResponse) => {
  const radar = {
    data: apiResponse?.data?.results,
    meta: apiResponse?.meta,
  };

  return radar;
};

export const listProcessDetail = async ({
  radarId,
  page = 1,
  perPage = 10,
  toParamsFilters,
  sortFields,
}) => {
  const config = {
    params: {
      idt_radar: radarId,
      page,
      per_page: perPage,
      sort_fields: JSON.stringify(sortFields),
      q: JSON.stringify({ filter: toParamsFilters }),
    },
  };
  const response = await lambdaApi().get('/radar/process/detail', config);
  return transformer(response.data);
};
