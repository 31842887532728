import React from 'react';
import store from 'store';
import Actions from './Actions';

import {
  bankConciliationFiltersStart,
  bankConciliationFiltersZeroLength,
} from 'store/ducks/filters/details/bankConciliationFilters/actions';
import {
  columnFilters,
  getDefaultColumns,
  onColumnFilter,
} from 'helpers/generalService';

import FilterDropdown from 'commons/Table/components/FilterDropdown';

let filteredColumns = store.getState().updateFilteredColumns?.filteredColumns;

const columnList = (apiFilters, toParamsFilters, currentPage) => [
  {
    title: 'Dados dos pagamentos',
    className: 'cr-right-title',
    children: [
      {
        title: 'Data',
        dataIndex: 'transaction_date',
        filteredValue: getDefaultColumns(toParamsFilters, 'transaction_date'),
        filters:
          apiFilters.length > 0
            ? columnFilters(apiFilters, 'transaction_date')
            : [],
        onFilter: (value, record) =>
          onColumnFilter(value, record, 'transaction_date'),
        onFilterDropdownVisibleChange: (visible) => {
          filteredColumns =
            store.getState().updateFilteredColumns?.filteredColumns;
          if (visible) {
            // This "zero length" dispatch is utterly important,
            // in order to keep each column state independent (Not passing data from one column to the next)
            store.dispatch(bankConciliationFiltersZeroLength());
            store.dispatch(
              bankConciliationFiltersStart({
                startDate:
                  store.getState().updateScreenToScreenDate
                    ?.reconciliationStartDate,
                endDate:
                  store.getState().updateScreenToScreenDate
                    ?.reconciliationEndDate,
                columnName: 'transaction_date',
                offset: 0,
                limit: store.getState().bankConciliationFilters?.offsetShift,
                q:
                  filteredColumns.length !== 0
                    ? {
                        filter: filteredColumns,
                      }
                    : [],
              })
            );
          }
        },
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }) => {
          return (
            <FilterDropdown
              apiFilters={apiFilters}
              setSelectedKeys={setSelectedKeys}
              selectedKeys={selectedKeys}
              confirm={confirm}
              clearFilters={clearFilters}
              filterAction={bankConciliationFiltersStart}
              clearAction={bankConciliationFiltersZeroLength}
              columnName="transaction_date"
              storeName="bankConciliationFilters"
            />
          );
        },
        filterMultiple: true,
        sorter: {
          multiple: 1,
        },
        width: 100,
        align: 'left',
      },
      {
        title: 'Adquirente',
        dataIndex: 'acquirer_name',
        sorter: {
          multiple: 1,
        },
        filteredValue: getDefaultColumns(toParamsFilters, 'acquirer_name'),
        filters:
          apiFilters.length > 0
            ? columnFilters(apiFilters, 'acquirer_name')
            : [],
        onFilter: (value, record) =>
          onColumnFilter(value, record, 'acquirer_name'),
        onFilterDropdownVisibleChange: (visible) => {
          filteredColumns =
            store.getState().updateFilteredColumns?.filteredColumns;
          if (visible) {
            // This "zero length" dispatch is utterly important,
            // in order to keep each column state independent (Not passing data from one column to the next)
            store.dispatch(bankConciliationFiltersZeroLength());
            store.dispatch(
              bankConciliationFiltersStart({
                startDate:
                  store.getState().updateScreenToScreenDate
                    ?.reconciliationStartDate,
                endDate:
                  store.getState().updateScreenToScreenDate
                    ?.reconciliationEndDate,
                columnName: 'acquirer_name',
                offset: 0,
                limit: store.getState().bankConciliationFilters?.offsetShift,
                q:
                  filteredColumns.length !== 0
                    ? {
                        filter: filteredColumns,
                      }
                    : [],
              })
            );
          }
        },
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }) => {
          return (
            <FilterDropdown
              apiFilters={apiFilters}
              setSelectedKeys={setSelectedKeys}
              selectedKeys={selectedKeys}
              confirm={confirm}
              clearFilters={clearFilters}
              filterAction={bankConciliationFiltersStart}
              clearAction={bankConciliationFiltersZeroLength}
              columnName="acquirer_name"
              storeName="bankConciliationFilters"
            />
          );
        },
        filterMultiple: true,
        width: 150,
        align: 'left',
        // fixed: 'left',
      },
      {
        title: 'Banco',
        dataIndex: 'bank_name',
        sorter: {
          multiple: 1,
        },
        filteredValue: getDefaultColumns(toParamsFilters, 'bank_name'),
        filters:
          apiFilters.length > 0 ? columnFilters(apiFilters, 'bank_name') : [],
        onFilter: (value, record) => onColumnFilter(value, record, 'bank_name'),
        onFilterDropdownVisibleChange: (visible) => {
          filteredColumns =
            store.getState().updateFilteredColumns?.filteredColumns;
          if (visible) {
            // This "zero length" dispatch is utterly important,
            // in order to keep each column state independent (Not passing data from one column to the next)
            store.dispatch(bankConciliationFiltersZeroLength());
            store.dispatch(
              bankConciliationFiltersStart({
                startDate:
                  store.getState().updateScreenToScreenDate
                    ?.reconciliationStartDate,
                endDate:
                  store.getState().updateScreenToScreenDate
                    ?.reconciliationEndDate,
                columnName: 'bank_name',
                offset: 0,
                limit: store.getState().bankConciliationFilters?.offsetShift,
                q:
                  filteredColumns.length !== 0
                    ? {
                        filter: filteredColumns,
                      }
                    : [],
              })
            );
          }
        },
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }) => {
          return (
            <FilterDropdown
              apiFilters={apiFilters}
              setSelectedKeys={setSelectedKeys}
              selectedKeys={selectedKeys}
              confirm={confirm}
              clearFilters={clearFilters}
              filterAction={bankConciliationFiltersStart}
              clearAction={bankConciliationFiltersZeroLength}
              columnName="bank_name"
              storeName="bankConciliationFilters"
            />
          );
        },
        filterMultiple: true,
        width: 140,
        align: 'left',
      },
      {
        title: 'Agência',
        dataIndex: 'bank_branch',
        sorter: {
          multiple: 1,
        },
        filteredValue: getDefaultColumns(toParamsFilters, 'bank_branch'),
        filters:
          apiFilters.length > 0 ? columnFilters(apiFilters, 'bank_branch') : [],
        onFilter: (value, record) =>
          onColumnFilter(value, record, 'bank_branch'),
        onFilterDropdownVisibleChange: (visible) => {
          filteredColumns =
            store.getState().updateFilteredColumns?.filteredColumns;
          if (visible) {
            // This "zero length" dispatch is utterly important,
            // in order to keep each column state independent (Not passing data from one column to the next)
            store.dispatch(bankConciliationFiltersZeroLength());
            store.dispatch(
              bankConciliationFiltersStart({
                startDate:
                  store.getState().updateScreenToScreenDate
                    ?.reconciliationStartDate,
                endDate:
                  store.getState().updateScreenToScreenDate
                    ?.reconciliationEndDate,
                columnName: 'bank_branch',
                offset: 0,
                limit: store.getState().bankConciliationFilters?.offsetShift,
                q:
                  filteredColumns.length !== 0
                    ? {
                        filter: filteredColumns,
                      }
                    : [],
              })
            );
          }
        },
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }) => {
          return (
            <FilterDropdown
              apiFilters={apiFilters}
              setSelectedKeys={setSelectedKeys}
              selectedKeys={selectedKeys}
              confirm={confirm}
              clearFilters={clearFilters}
              filterAction={bankConciliationFiltersStart}
              clearAction={bankConciliationFiltersZeroLength}
              columnName="bank_branch"
              storeName="bankConciliationFilters"
            />
          );
        },
        filterMultiple: true,
        width: 100,
        align: 'left',
      },
      {
        title: 'Conta',
        dataIndex: 'bank_account',
        sorter: {
          multiple: 1,
        },
        filteredValue: getDefaultColumns(toParamsFilters, 'bank_account'),
        filters:
          apiFilters.length > 0
            ? columnFilters(apiFilters, 'bank_account')
            : [],
        onFilter: (value, record) =>
          onColumnFilter(value, record, 'bank_account'),
        onFilterDropdownVisibleChange: (visible) => {
          filteredColumns =
            store.getState().updateFilteredColumns?.filteredColumns;
          if (visible) {
            // This "zero length" dispatch is utterly important,
            // in order to keep each column state independent (Not passing data from one column to the next)
            store.dispatch(bankConciliationFiltersZeroLength());
            store.dispatch(
              bankConciliationFiltersStart({
                startDate:
                  store.getState().updateScreenToScreenDate
                    ?.reconciliationStartDate,
                endDate:
                  store.getState().updateScreenToScreenDate
                    ?.reconciliationEndDate,
                columnName: 'bank_account',
                offset: 0,
                limit: store.getState().bankConciliationFilters?.offsetShift,
                q:
                  filteredColumns.length !== 0
                    ? {
                        filter: filteredColumns,
                      }
                    : [],
              })
            );
          }
        },
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }) => {
          return (
            <FilterDropdown
              apiFilters={apiFilters}
              setSelectedKeys={setSelectedKeys}
              selectedKeys={selectedKeys}
              confirm={confirm}
              clearFilters={clearFilters}
              filterAction={bankConciliationFiltersStart}
              clearAction={bankConciliationFiltersZeroLength}
              columnName="bank_account"
              storeName="bankConciliationFilters"
            />
          );
        },
        filterMultiple: true,
        width: 140,
        align: 'left',
        // fixed: 'left',
      },
    ],
  },
  {
    title: 'Comparativo',
    className: 'cr-left-border-title',
    width: 710,
    children: [
      {
        title: 'Adquirente',
        dataIndex: 'receipt_amount',
        className: 'cr-left-border',
        sorter: {
          multiple: 1,
        },
        width: 140,
        align: 'left',
      },
      {
        title: 'Extrato',
        dataIndex: 'transaction_amount',
        sorter: {
          multiple: 1,
        },
        width: 140,
        align: 'left',
      },
      {
        title: 'Diferença',
        dataIndex: 'value_diff',
        sorter: {
          multiple: 1,
        },
        width: 140,
        align: 'left',
      },
      {
        title: 'Ações',
        sorter: false,
        dataIndex: 'actions',
        width: 150,
        render: (_, record) => {
          return <Actions currentRow={record} currentPage={currentPage} />;
        },
        align: 'left',
        fixed: 'right',
      },
    ],
  },
];

export default columnList;
