import { action } from 'typesafe-actions';
import { actionTypes } from 'store/ducks/credit/creditList/types';

export const creditListStart = (data) =>
  action(actionTypes.CREDIT_LIST_START, data);
export const creditListSuccess = (data) =>
  action(actionTypes.CREDIT_LIST_SUCCESS, data);
export const creditListError = (errorMessage, error) =>
  action(actionTypes.CREDIT_LIST_ERROR, { errorMessage }, undefined, {
    error,
  });
