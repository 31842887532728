import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import { ContentTabs } from 'components/Intermediate/PaymentsDivergences/components';
import { Filters } from 'commons/Intermediate/Filters';
import { useDispatch, useSelector } from 'react-redux';
import { paymentsDivergenceDash } from '../../../store/ducks/paymentsDash/paymentsDivergences/actions';
import { paymentsDivergencesIntermediate } from '../../../store/ducks/paymentsIntermediate/paymentsDivergencesIntermediate/actions';
import { divergencesInfoFiltersStart } from 'store/ducks/filters/divergencesInfoFilters/actions';
import { disableLastMonth } from 'helpers/disableDates';
import { useResetFilters } from 'utils/hooks/useResetFilters';
import { updatePaymentDivergencesDetailsFilters } from 'store/ducks/filters/details/paymentDivergencesDetailsFilters/actions';
import { onSorter } from 'helpers/sorter';
import { useRangePickerChange } from 'utils/hooks/useRangePickerChange';

const PaymentsDivergencesIntermediate = () => {
  const dispatch = useDispatch();

  const startDate = useSelector(
    (state) => state.updateScreenToScreenDate.startDate
  );
  const endDate = useSelector(
    (state) => state.updateScreenToScreenDate.endDate
  );

  const [sort, setSort] = useState('DESC');
  const [filterName, setFilterName] = useState(null);
  const [filtersDrawer, toggleFiltersDrawer] = useState(false);
  const [activeTab, setActiveTab] = useState('branches');

  const paymentsSummary = useSelector(
    (store) => store.paymentsDivergencesDash.payments
  );
  const paymentsSummaryIsError = useSelector(
    (store) => store.paymentsDivergencesDash.isError
  );
  const paymentsSummaryLoading = useSelector(
    (store) => store.paymentsDivergencesDash.loading
  );

  const paymentsIntermediate = useSelector(
    (state) => state.paymentsDivergencesIntermediate.payments
  );
  const paymentsIntermediateLoading = useSelector(
    (state) => state.paymentsDivergencesIntermediate.loading
  );
  const paymentsIntermediateError = useSelector(
    (state) => state.paymentsDivergencesIntermediate.error
  );

  const divergencesInfoFilters = useSelector(
    (state) => state.divergencesInfoFilters.filters
  );

  const loading = paymentsIntermediateLoading;

  const [calendarIsOpen, setCalendarIsOpen] = useState(false);

  const { onRangePickerChange } = useRangePickerChange();

  useEffect(() => {
    dispatch(
      paymentsDivergencesIntermediate({
        startDate,
        endDate,
        page: 1,
        type: activeTab,
        perPage: 100,
        sort,
        filter: filterName,
      })
    );
    dispatch(
      divergencesInfoFiltersStart({
        startDate,
        endDate,
        type: activeTab,
      })
    );
    dispatch(paymentsDivergenceDash({ startDate, endDate }));
  }, [activeTab, dispatch, endDate, filterName, sort, startDate]);

  const onRangePickerOpenOrCloseChange = (open) => {
    setCalendarIsOpen(open);
  };

  const onChangeSelectFilter = (val) => {
    if (val === 'Todos' || val === 'Buscar' || val === '') {
      setFilterName(null);
    } else {
      setFilterName(val);
    }
  };

  const onTabClick = (key) => {
    setActiveTab(key);
    onChangeSelectFilter(null);
  };

  const { resetFilters } = useResetFilters();

  useEffect(() => {
    resetFilters(updatePaymentDivergencesDetailsFilters);
  }, [resetFilters]);

  return (
    <>
      <Filters
        title="Auditoria de pagamentos"
        subtitle="Verifique previsões de pagamento em aberto e valores pagos com diferença"
        onRangePickerOpenOrCloseChange={onRangePickerOpenOrCloseChange}
        hasRangePicker
        hasSelect
        hasSorter
        dateDefaultValue={[startDate, endDate]}
        onRangePickerChange={(dates) =>
          onRangePickerChange(dates, calendarIsOpen)
        }
        onChangeSelectFilter={onChangeSelectFilter}
        loading={loading}
        sorter={onSorter(sort, setSort)}
        sort={sort}
        renderFilterButton={() => (
          <Button
            style={{ borderRadius: 4 }}
            onClick={() => toggleFiltersDrawer(!filtersDrawer)}
          >
            Filtros
          </Button>
        )}
        filtersDrawer={filtersDrawer}
        filterName={filterName}
        onCloseFiltersDrawer={() => toggleFiltersDrawer(false)}
        sorterButtonText="Divergências"
        infoFiltersToSelect={divergencesInfoFilters}
        activeTab={activeTab}
        disabledDate={disableLastMonth}
        calendarIsOpen={calendarIsOpen}
        setCalendarIsOpen={setCalendarIsOpen}
      />
      <ContentTabs
        startDate={startDate}
        endDate={endDate}
        summary={paymentsSummary}
        infoData={paymentsIntermediate}
        infoLoading={paymentsIntermediateLoading}
        isInfoError={paymentsIntermediateError}
        onTabClick={onTabClick}
        activeTab={activeTab}
        infoFilter={filterName}
        paymentsSummaryIsError={paymentsSummaryIsError}
        paymentsSummaryLoading={paymentsSummaryLoading}
      />
    </>
  );
};

export default PaymentsDivergencesIntermediate;
