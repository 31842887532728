import { Table, Typography } from 'antd';
import { EditOutlined, FilterOutlined } from '@ant-design/icons';
import { colors } from 'styles/colors';
import styled from 'styled-components';

export const Container = styled.div`
  margin: 32px 32px 55px 32px;
  background-color: ${colors.gray1};
`;

export const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border-bottom: ${({ hasData }) => !hasData && `1px solid ${colors.gray5}`};
`;

export const TableHeaderTitle = styled(Typography.Title)`
  &&& {
    font-size: 20px;
    font-weight: 500;
    color: ${colors.gray9};
  }
`;

export const OnboardingManagementTable = styled(Table)`
  &&& {
    padding: 0px 16px;

    .ant-pagination-options::before {
      content: 'Itens por página';
      margin-right: 10px;
    }

    .ant-pagination-disabled .ant-pagination-item-link {
      background-color: ${colors.gray3};
      color: ${colors.gray6};
      border-color: ${colors.gray5};
      cursor: not-allowed;
    }

    .ant-table-cell {
      padding: 16px;
      min-width: 0;
    }

    .ant-table-row,
    .ant-table-cell > td {
      height: 75.21px;
    }

    .ant-table-thead
      > tr
      > th:not(:last-child):not(.ant-table-selection-column):not(
        .ant-table-row-expand-icon-cell
      ):not([colspan])::before {
      background-color: transparent !important;
      width: 0px;
    }

    .ant-table-thead > tr > th {
      border-bottom: 1px solid ${colors.gray5};
    }

    .ant-table-tbody > tr > td {
      border-bottom: 1px solid ${colors.gray5};
    }

    .ant-table-column-sorters {
      display: inline-flex;
    }

    .ant-table-column-sorter {
      margin-left: 8px;
    }

    .row-even {
      background-color: ${colors.gray1};
    }

    .row-odd {
      background-color: ${colors.gray3};
    }
  }
`;

export const EmptyTable = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 561px;
  background-color: ${colors.gray1};
  border-bottom: 1px solid ${colors.gray5};
`;

export const FilterCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 96px;
  height: 96px;
  border-radius: 50%;
  margin-bottom: 16px;
  background-color: ${colors.primary1};
`;

export const FilterIcon = styled(FilterOutlined)`
  &&& {
    font-size: 37px;
    color: ${colors.primary4};
  }
`;

export const EmptyTableTitle = styled(Typography.Title)`
  &&& {
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    color: #222222;
  }
`;

export const EmptyTableText = styled(Typography.Text)`
  &&& {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: ${colors.gray8};
  }
`;

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

export const EditIcon = styled(EditOutlined)`
  &&& {
    width: 16px;
    color: ${colors.primary6};
  }
`;

export const ActionText = styled(Typography.Text)`
  &&& {
    font-size: 14px;
    font-weight: 400;
    color: ${colors.primary6};
    line-height: 22px;
    margin-top: 1px;
  }
`;

export const StatusContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 0 8px;
  background-color: ${({ bgColor }) => bgColor};
  border: ${({ border }) => `1px solid ${border}`};
  border-radius: 2px;
  width: ${({ width }) => width};
`;

export const StatusLabel = styled(Typography.Text)`
  &&& {
    font-size: 12px;
    font-weight: 400;
    color: ${({ $labelColor }) => $labelColor};
    line-height: 20px;
  }
`;
