import { actionTypes } from 'store/ducks/radar/radarRun/types';

const INITIAL_STATE = {
  error: undefined,
  errorMessage: undefined,
  loading: false,
  isError: false,
  data: [],
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case actionTypes.RADAR_RUN_START:
    return {
      ...state,
      loading: true,
      error: undefined,
      errorMessage: undefined,
      isError: false,
    };
  case actionTypes.RADAR_RUN_SUCCESS:
    return {
      ...state,
      loading: false,
      error: undefined,
      data: action.payload?.data,
      isError: false,
      errorMessage: undefined,
    };
  case actionTypes.RADAR_RUN_ERROR:
    return {
      ...state,
      loading: false,
      error: action.error.error,
      errorMessage: action.payload,
      isError: true,
    };
  default:
    return state;
  }
};

export default reducer;
