import styled, { css } from 'styled-components';
import { marginMainContainer } from 'helpers/style';
import { colors } from 'styles/colors';

export const StContainer = styled.div``;

export const StLimiter = styled.div`
  margin: ${(props) => marginMainContainer(props.$breakpoint)};
  padding-top: 206px;
`;

export const TabBarStyle = css`
    background-color: ${colors.gray1};
    padding: {(props) => marginMainContainer(props.$breakpoint)};
    -webkit-box-shadow: -35px 32px 14px rgba(29, 140, 231, 0.45);
    -moz-box-shadow:    -35px 32px 14px rgba(29, 140, 231, 0.45);
    box-shadow:         -35px 32px 14px rgba(29, 140, 231, 0.45);
`;
