import { api } from './api';

const transform = (response) => {
  const { data } = response;
  const payload = {
    message: data.message,
  };
  return payload;
};

const createPassword = async (payload) => {
  const response = await api().post('/accept/invitation', payload);
  return transform(response);
};

export default createPassword;
