import { action } from 'typesafe-actions';
import { actionTypes } from './types';

export const getNextOrderByClientIdStart = (data) =>
  action(actionTypes.GET_NEXT_ORDER_BY_CLIENT_ID_START, data);
export const getNextOrderByClientIdSuccess = (data) =>
  action(actionTypes.GET_NEXT_ORDER_BY_CLIENT_ID_SUCCESS, data);
export const getNextOrderByClientIdError = (errorMessage, error) =>
  action(
    actionTypes.GET_NEXT_ORDER_BY_CLIENT_ID_ERROR,
    { errorMessage },
    undefined,
    {
      error,
    }
  );

export const resetNextOrderByClientIdStart = (data) =>
  action(actionTypes.RESET_NEXT_ORDER_BY_CLIENT_ID_START, data);
export const resetNextOrderByClientIdSuccess = (data) =>
  action(actionTypes.RESET_NEXT_ORDER_BY_CLIENT_ID_SUCCESS, data);
export const resetNextOrderByClientIdError = (errorMessage, error) =>
  action(
    actionTypes.RESET_NEXT_ORDER_BY_CLIENT_ID_ERROR,
    { errorMessage },
    undefined,
    {
      error,
    }
  );
