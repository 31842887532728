import { Auth } from 'aws-amplify';
import errorHandler from 'helpers/errorHandler';

const postForgotPasswordFromApi = async (data) => {
  const credentials = {
    username: data.email,
  };

  const clientMetaData = {
    isWeb: 'True',
  };

  // eslint-disable-next-line no-unused-vars
  const authData = Auth.forgotPassword(
    credentials?.username,
    clientMetaData
  ).catch((err) => errorHandler(err));
};

export default postForgotPasswordFromApi;
