import { action } from 'typesafe-actions';
import { actionTypes } from './types';

export const deleteReconciliationRulesStart = (data) =>
  action(actionTypes.DELETE_RECONCILIATION_RULES_START, data);
export const deleteReconciliationRulesSuccess = (data) =>
  action(actionTypes.DELETE_RECONCILIATION_RULES_SUCCESS, data);
export const deleteReconciliationRulesError = (errorMessage, error) =>
  action(
    actionTypes.DELETE_RECONCILIATION_RULES_ERROR,
    { errorMessage },
    undefined,
    {
      error,
    }
  );
