import React from 'react';
import { Action } from './Action';
import { Status } from './Status';

const ColumnList = () => [
  {
    key: 'client',
    title: 'Código e nome da empresa',
    dataIndex: 'cod_client',
    sorter: {
      multiple: 1,
    },
    width: 264,
    align: 'left',
  },
  {
    key: 'stage',
    title: 'Estágio',
    dataIndex: 'stage',
    sorter: {
      multiple: 1,
    },
    width: 148,
    align: 'left',
  },
  {
    key: 'dat_created_at',
    title: 'Data de criação',
    dataIndex: 'dat_created_at',
    sorter: {
      multiple: 1,
    },
    width: 213,
    align: 'left',
  },
  {
    key: 'skin',
    title: 'Skin',
    dataIndex: 'skin',
    sorter: {
      multiple: 1,
    },
    width: 180,
    align: 'left',
  },
  {
    key: 'plan',
    title: 'Plano',
    dataIndex: 'plan',
    sorter: {
      multiple: 1,
    },
    width: 136,
    align: 'left',
  },
  {
    key: 'status',
    title: 'Status',
    dataIndex: 'status',
    sorter: {
      multiple: 1,
    },
    width: 150,
    align: 'left',
    render: (_, record) => <Status currentRow={record} />,
  },
  {
    key: 'action',
    title: '',
    align: 'right',
    render: () => <Action />,
  },
];

export default ColumnList;
