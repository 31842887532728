import React from 'react';
import { Grid, List, Card, Typography, Pagination, Row, Col } from 'antd';
import PropTypes from 'prop-types';

import { ErrorCard } from 'commons/ErrorCard';
import { StContainer, StLimiter } from './styled';

const MobileCards = ({
  data,
  loading,
  pagination,
  onPageChange,
  onPageSizeChange,
  error,
}) => {
  const { useBreakpoint } = Grid;
  const breakpoint = useBreakpoint();

  if (error) {
    return (
      <StContainer>
        <StLimiter $breakpoint={breakpoint}>
          <Row style={{ marginTop: '2rem' }}>
            <Col lg={10} md={18}>
              <Card style={{ borderRadius: 4 }} loading={loading}>
                <ErrorCard
                  title="Dados indisponíveis"
                  reason="Não conseguimos buscar os dados, mas já estamos trabalhando para normalizar a situação."
                  recommendation="Tente atualizar a página em alguns instantes."
                />
              </Card>
            </Col>
          </Row>
        </StLimiter>
      </StContainer>
    );
  }

  if (
    !breakpoint?.md &&
    !breakpoint.lg &&
    !breakpoint.xl &&
    !breakpoint.xxl &&
    !error
  ) {
    return (
      <StContainer>
        <StLimiter $breakpoint={breakpoint}>
          <Typography.Text style={{ fontSize: '2rem' }} strong>
            Relatório Geral
          </Typography.Text>
          <List
            grid={{ column: 1 }}
            dataSource={data}
            loading={loading}
            renderItem={(item) => (
              <List.Item>
                <Card loading={loading}>
                  <div style={{ marginTop: '2rem' }}>
                    <Typography.Text>Filial: </Typography.Text>
                    <br />
                    <Typography.Text>{item.store_name}</Typography.Text>
                  </div>
                  <div style={{ marginTop: '2rem' }}>
                    <Typography.Text>Data do pagamento: </Typography.Text>
                    <br />
                    <Typography.Text>{item.transaction_date}</Typography.Text>
                  </div>
                  <div style={{ marginTop: '2rem' }}>
                    <Typography.Text>Adquirente: </Typography.Text>
                    <br />
                    <Typography.Text>{item.acquirer_name}</Typography.Text>
                  </div>
                  <div style={{ marginTop: '2rem' }}>
                    <Typography.Text>Código do produto: </Typography.Text>
                    <br />
                    <Typography.Text>{item.product_code}</Typography.Text>
                  </div>
                  <div style={{ marginTop: '2rem' }}>
                    <Typography.Text>Bandeira: </Typography.Text>
                    <br />
                    <Typography.Text>{item.brand_code}</Typography.Text>
                  </div>
                  <div style={{ marginTop: '2rem' }}>
                    <Typography.Text>Valor bruto (R$): </Typography.Text>
                    <br />
                    <Typography.Text>
                      {item.sum_inst_gross_amount}
                    </Typography.Text>
                  </div>
                  <div style={{ marginTop: '2rem' }}>
                    <Typography.Text>Taxa administrativa (R$):</Typography.Text>
                    <br />
                    <Typography.Text>
                      {item.sum_inst_adm_fee_amount}
                    </Typography.Text>
                  </div>
                  <div style={{ marginTop: '2rem' }}>
                    <Typography.Text>Desconto tarifa (R$):</Typography.Text>
                    <br />
                    <Typography.Text>
                      {item.sum_inst_split_adq_fare_amount}
                    </Typography.Text>
                  </div>
                  <div style={{ marginTop: '2rem' }}>
                    <Typography.Text>Repasse (R$):</Typography.Text>
                    <br />
                    <Typography.Text>
                      {item.sum_inst_transfer_amount}
                    </Typography.Text>
                  </div>

                  <div style={{ marginTop: '2rem' }}>
                    <Typography.Text>Valor líquido (R$):</Typography.Text>
                    <br />
                    <Typography.Text>
                      {item.sum_inst_net_amount}
                    </Typography.Text>
                  </div>
                </Card>
              </List.Item>
            )}
          />
          <Pagination
            pageSize={pagination.pageSize}
            pageSizeOptions={pagination.pageSizeOptions}
            onChange={onPageChange}
            onShowSizeChange={onPageSizeChange}
            total={pagination.total}
            current={pagination.current}
            showTotal={pagination.showTotal}
            showSizeChanger
            responsive
          />
        </StLimiter>
      </StContainer>
    );
  }
  return '';
};

MobileCards.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      acquirer_name: PropTypes.string.isRequired,
      administration_fee: PropTypes.number.isRequired,
      authorization: PropTypes.string.isRequired,
      bank_account: PropTypes.string.isRequired,
      bank_agency: PropTypes.string.isRequired,
      bank_code: PropTypes.string.isRequired,
      branch: PropTypes.string.isRequired,
      brand_code: PropTypes.string.isRequired,
      capture_channel: PropTypes.string.isRequired,
      card_number: PropTypes.string.isRequired,
      classification: PropTypes.string.isRequired,
      client_pk: PropTypes.string.isRequired,
      discount: PropTypes.number.isRequired,
      expected_clearing_date: PropTypes.string.isRequired,
      gross_amount: PropTypes.number.isRequired,
      installment_amount: PropTypes.number.isRequired,
      installment_count: PropTypes.number.isRequired,
      installment_index: PropTypes.number.isRequired,
      invoice: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      merchant: PropTypes.string.isRequired,
      net_amount: PropTypes.number.isRequired,
      nsu: PropTypes.string.isRequired,
      product_code: PropTypes.string.isRequired,
      sale_date: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      terminal: PropTypes.string.isRequired,
      tid: PropTypes.string.isRequired,
      transaction_type: PropTypes.string.isRequired,
    })
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  onPageChange: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
};
export default MobileCards;
