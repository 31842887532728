import React from 'react';
import { Row, Col, Grid } from 'antd';
import { useSelector } from 'react-redux';

import { LoginImage } from 'commons/LoginImage';

import { EmailForm, SentMessage } from './components';
import { StContainer } from './styled';

const ForgotPassword = ({ ...others }) => {
  const { useBreakpoint } = Grid;
  const breakpoint = useBreakpoint();

  const reponseSuccess = useSelector((state) => state.forgotPassword.ok);

  if (reponseSuccess) {
    return (
      <StContainer $breakpoint={breakpoint}>
        <SentMessage />
      </StContainer>
    );
  }
  return (
    <StContainer $breakpoint={breakpoint} {...others}>
      <Row
        align="middle"
        justify="center"
        style={{
          height: '100%',
          width: breakpoint?.lg ? '100%' : '80%',
          margin: '0 auto',
        }}
      >
        <Col span={24}>
          <Row>
            <Col xs={0} sm={0} md={0} lg={5} xl={5} xxl={5} />
            <Col xs={24} sm={24} md={24} lg={7} xl={7} xxl={7}>
              <EmailForm />
            </Col>
            <Col xs={0} sm={0} md={0} lg={8} xl={8} xxl={8}>
              <LoginImage />
            </Col>
            <Col xs={0} sm={0} md={0} lg={4} xl={4} xxl={4} />
          </Row>
        </Col>
      </Row>
    </StContainer>
  );
};

export default ForgotPassword;
