/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable no-plusplus */
import { api } from '../api';

const transform = (response, columnName) => {
  const filters = {
    results: [
      {
        text: columnName,
        values: response?.data?.results,
      },
    ],
    hasMore: response?.meta?.has_next,
    offset: response?.meta?.offset,
    limit: response?.meta?.limit,
    column: response?.meta?.column,
  };
  return filters;
};

const getContractFeeFiltersFromAPI = async ({
  startDate,
  endDate,
  columnName,
  offset,
  limit,
  searchBy,
  q,
  filter,
}) => {
  const formattedColumnName =
    columnName === 'fee_percent_fmt' ? 'fee_percent' : columnName;

  if (q?.filter?.length > 0) {
    // eslint-disable-next-line consistent-return
    Object.values(q?.filter).forEach((value) => {
      if (value?.name === 'fee_percent_fmt') {
        return (value.name = 'fee_percent');
      }
    });
  }

  const config = {
    params: {
      start_date: startDate.format('YYYY-MM-DD'),
      end_date: endDate.format('YYYY-MM-DD'),
      column: formattedColumnName,
      limit,
      offset,
      type: 'detail',
      search_by: searchBy,
      q,
      filter,
    },
  };
  const response = await api().get('/fees/filters', config);
  const transformedResponse = transform(response?.data, columnName);
  return transformedResponse;
};

export default getContractFeeFiltersFromAPI;
