import styled from 'styled-components';
import { Button, List, Input } from 'antd';
import { colors } from 'styles/colors';

export const StButton = styled(Button)`
  width: 100%;
  font-size: 1.5rem;
  text-align: start;
  padding: 1.5rem 14px;
  border-bottom: none;
  border-left: none;
  border-right: none;
  height: inherit;
  border-radius: 0;
  :first-of-type {
    border-top: none;
  }
`;

export const StSearchWrapper = styled.div`
  padding: 0 15px;
`;

export const StSearch = styled(Input.Search)`
  width: 100%;
  margin-top: 2rem;
`;

export const StCompanyListContainer = styled.div`
  background-color: ${colors.gray2};
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  max-height: ${(props) => (props?.$breakpoint?.lg ? '80vh' : 'auto')};
`;

export const StListWrapper = styled.div`
  width: 100%;
  margin-top: 2rem;
  overflow-y: scroll;
`;

export const StListItem = styled(List.Item)`
  padding: 0;
  cursor: pointer;
`;

export const StListItemButton = styled(Button)`
  width: 100%;
  height: 100%;
  padding: 1rem 15px;
  text-align: start;
  font-size: 1.5rem;
  background-color: ${colors.secondary6};
  color: ${colors.gray1};
  :hover {
    color: ${colors.gray11} !important;
    background-color: ${colors.gray5};
  }
`;

export const StListItemButtonDifferent = styled(Button)`
  width: 100%;
  height: 100%;
  padding: 1rem 15px;
  text-align: start;
  font-size: 1.5rem;
  color: ${colors.gray11};
  :hover {
    color: ${colors.gray11} !important;
    background-color: ${colors.gray5};
  }
`;
