import styled from 'styled-components';
import { Typography } from 'antd';
import { colors } from 'styles/colors';

const { Text, Title } = Typography;

export const StMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 387px;
`;

export const StTitle = styled(Title)`
  &&& {
    color: ${colors.gray8};
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
    padding-bottom: 16px;
  }
`;

export const StText = styled(Text)`
  &&& {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    padding-bottom: 32px;
  }
`;

export const StCustomLink = styled.span`
  &&& {
    cursor: pointer;
    color: #2eb8c2;
    text-decoration: underline;
  }
`;

export const StImagesContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;
`;

export const StTextImages = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
`;

export const StImage = styled.img`
  width: 120px;
  height: 40px;
  margin-top: 16px;
  cursor: pointer;
`;
