/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-children-prop */
import React, { useEffect } from 'react';
import { Row, Col, Skeleton, Dropdown } from 'antd';
import { TitleAndIcon } from 'commons/TitleAndIcon';
import CardContainer from 'commons/CardContainer';
import {
  StDashboardFooterCardUpperContainer,
  StDashboardTag,
  StDropdownContent,
  StHoverClickTitle,
} from 'components/Dashboard/styled';
import { CalendarOutlined } from '@ant-design/icons';
import customHistory from 'helpers/history';
import { useDispatch, useSelector } from 'react-redux';
import { updateScreenToScreenDateStart } from 'store/ducks/updateScreenToScreenDate/actions';
import { CardContent } from './CardContent';
import { Footer } from './Footer';
import {
  currentDayMinusOne,
  endOfPreviousMonth,
  startOfCurrentMonth,
  startOfPreviousMonth,
} from 'helpers/dates';
import { dataTestIdHandler } from 'helpers/dataTestIdHandler';

const ReconciliationCard = ({
  height,
  enforceMaxHeight,
  reconciliationType,
  isFlipped,
  setIsFlipped,
  dropdownProps,
  context,
  hasFooter,
  reconProps,
  ...rest
}) => {
  const dispatch = useDispatch();

  const {
    reconUrl,
    reconPeriodStore,
    reconPeriodAction,
    reconSummaryAction,
    reconSummaryStore,
    reconTitle,
    reconIcon,
    reconLeftColDescription,
    reconRightColDescription,
    dropdownKey,
  } = reconProps;

  const {
    startDateReconciliation,
    endDateReconciliation,
    setStartDateReconciliation,
    setEndDateReconciliation,
  } = dropdownProps;

  const dropdownKeyValue = useSelector(
    (state) => state.updateScreenToScreenPeriod[reconPeriodStore]
  );

  const summaryReconciledLoading = useSelector(
    (state) => state[reconSummaryStore]?.loading
  );

  const summaryReconciledData = useSelector(
    (state) => state[reconSummaryStore]?.summary
  );

  const dataTestPrefix = `${reconciliationType}-reconciliation-card`;

  useEffect(() => {
    switch (dropdownKeyValue) {
      case 'neste_mes':
        dispatch(
          reconSummaryAction({
            startDate: startOfCurrentMonth,
            endDate: currentDayMinusOne,
          })
        );
        break;
      case 'ultimo_dia':
        dispatch(
          reconSummaryAction({
            startDate: currentDayMinusOne,
            endDate: currentDayMinusOne,
          })
        );
        break;
      case 'ultimo_mes':
        dispatch(
          reconSummaryAction({
            startDate: startOfPreviousMonth,
            endDate: endOfPreviousMonth,
          })
        );
        break;
      default:
        break;
    }
  }, [
    // eslint-disable-next-line
    dispatch,
    startDateReconciliation,
    endDateReconciliation,
  ]);

  const handleDropdownMenu = (key) => {
    switch (key) {
      case 'neste_mes':
        setStartDateReconciliation(startOfCurrentMonth);
        setEndDateReconciliation(currentDayMinusOne);
        dispatch(reconPeriodAction('neste_mes'));
        break;
      case 'ultimo_dia':
        setStartDateReconciliation(currentDayMinusOne);
        setEndDateReconciliation(currentDayMinusOne);
        dispatch(reconPeriodAction('ultimo_dia'));
        break;
      case 'ultimo_mes':
        setStartDateReconciliation(startOfPreviousMonth);
        setEndDateReconciliation(endOfPreviousMonth);
        dispatch(reconPeriodAction('ultimo_mes'));
        break;
      default:
        break;
    }
  };

  const items = [
    {
      label: 'Neste mês',
      key: 'neste_mes',
      onClick: () => handleDropdownMenu('neste_mes'),
    },
    {
      label: 'Último dia',
      key: 'ultimo_dia',
      onClick: () => handleDropdownMenu('ultimo_dia'),
    },
    {
      label: 'Último mês',
      key: 'ultimo_mes',
      onClick: () => handleDropdownMenu('ultimo_mes'),
    },
  ];

  const chooseWhichScreenToGo = () => {
    customHistory.push(reconUrl);
    dispatch(
      updateScreenToScreenDateStart({
        startDate: startDateReconciliation,
        endDate: endDateReconciliation,
      })
    );
  };

  return (
    <CardContainer
      height={height}
      enforceMaxHeight={enforceMaxHeight}
      hasFooter={hasFooter}
      reconciliationType={reconciliationType}
      {...dataTestIdHandler(dataTestPrefix, 'container')}
      {...rest}
    >
      <Skeleton loading={summaryReconciledLoading} active>
        <StDashboardFooterCardUpperContainer>
          <Row align="middle" justify="space-between" gutter={[0, 0]}>
            <StHoverClickTitle onClick={chooseWhichScreenToGo}>
              <Col>
                <TitleAndIcon
                  titleText={reconTitle}
                  children={reconciliationType === 'bank' && reconIcon}
                  iconUri={reconciliationType !== 'bank' && reconIcon}
                  iconAlt="Ícone de conciliação"
                  titleFontSize="20px"
                  dataTestPrefix={dataTestPrefix}
                />
              </Col>
            </StHoverClickTitle>

            <Col style={{ height: '22px' }}>
              <Dropdown
                menu={{ items }}
                trigger={['click']}
                dropdownRender={(menu) => (
                  <StDropdownContent>{menu}</StDropdownContent>
                )}
              >
                <StDashboardTag icon={<CalendarOutlined />}>
                  {dropdownKey[dropdownKeyValue]}
                </StDashboardTag>
              </Dropdown>
            </Col>
          </Row>
          <Row justify="center" gutter={[24, 0]} style={{ height: '90%' }}>
            <CardContent
              context={context}
              reconciliationType={reconciliationType}
              summaryReconciledData={summaryReconciledData}
              chooseWhichScreenToGo={chooseWhichScreenToGo}
              reconLeftColDescription={reconLeftColDescription}
              reconRightColDescription={reconRightColDescription}
            />
          </Row>
        </StDashboardFooterCardUpperContainer>

        {hasFooter && (
          <Footer
            isFlipped={isFlipped}
            setIsFlipped={setIsFlipped}
            reconciliationType={reconciliationType}
            dataTestPrefix={dataTestPrefix}
          />
        )}
      </Skeleton>
    </CardContainer>
  );
};

export default ReconciliationCard;
