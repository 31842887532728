import styled from 'styled-components';
import { colors } from 'styles/colors';

export const StContainer = styled.div`
  .ant-pagination-item a {
    color: ${colors.gray11} !important;
  }

  .ant-pagination-item-active {
    background-color: ${colors.primary6} !important;
    border-color: ${colors.primary6} !important;
  }

  .ant-pagination-item-active a {
    color: ${colors.gray1} !important;
  }

  .ant-spin-container {
    padding-bottom: 1px !important;
  }

  .ant-table-pagination-right {
    justify-content: center !important;
  }

  .ant-table-thead .ant-table-cell {
    font-size: 2rem;
    background-color: ${colors.gray3} !important;
    height: 7rem;
    margin: 1.5rem 0 0;
  }
`;
