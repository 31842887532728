import { action } from 'typesafe-actions';
import { actionTypes } from './types';

export const banksDetailsFiltersStart = (data) => action(actionTypes.BANKS_DETAILS_FILTERS_START, data);
export const banksDetailsFiltersSuccess = (data) =>
  action(actionTypes.BANKS_DETAILS_FILTERS_SUCCESS, data);
export const banksDetailsFiltersError = (errorMessage, error) =>
  action(actionTypes.BANKS_DETAILS_FILTERS_ERROR, { errorMessage }, undefined, { error });
export const banksDetailsFiltersZeroLength = () =>
  action(actionTypes.BANKS_DETAILS_FILTERS_ZERO_LENGTH);
export const updateBanksDetailsFilters = (data) =>
  action(actionTypes.UPDATE_BANKS_DETAILS_FILTERS, data);
