/* eslint-disable */
import dayjs from 'dayjs';
import store from '../store';

export const columnFilters = (apiFilters, dataString) => {
  const filteredColumnFilters = apiFilters.filter(
    (item) => item.text === dataString
  );
  const values = filteredColumnFilters
    .flatMap((item) => item?.values)
    .filter((item) => item?.length > 0);
  const structuredFilters = values.reduce((acc, currentValue) => {
    const entry = {
      text: currentValue,
      value: currentValue,
    };
    let newArray = [...acc];
    newArray = [...acc, entry];
    return newArray;
  }, []);
  return structuredFilters;
};
export const onColumnFilter = (value, record, dataString) =>
  record[dataString] === value;
export const columnSorter = (a, b, dataString) => {
  if (a[dataString] > b[dataString]) {
    return 1;
  }
  if (a[dataString] < b[dataString]) {
    return -1;
  }
  return 0;
};

export const toTitleCase = (str) => {
  return str?.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );
};

export const getDefaultColumns = (toParamsFilters, dataIndex) => {
  const defaultValues = [];
  if (toParamsFilters) {
    toParamsFilters.forEach((defaultValue) => {
      if (defaultValue?.name === dataIndex) {
        if (Array.isArray(defaultValue.val)) {
          defaultValue.val.forEach((columnValue) => {
            defaultValues.push(columnValue);
          });
        } else {
          defaultValues.push(defaultValue.val);
        }
      }
    });
  }
  if (defaultValues.length > 0) {
    return defaultValues;
  }
  return undefined;
};

export const isCpfOrCnpj = (val) => {
  const cpfPattern = /^[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2}|$/;
  const cnpjPattern = /^[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}\-?[0-9]{2}$/;

  if (val.length === 14) {
    if (cpfPattern.test(val)) return true;
  }

  if (val.length === 18) {
    if (cnpjPattern.test(val)) return true;
  }

  return false;
};

export const tryParsingJsonString = (jsonString) => {
  try {
    const o = JSON.parse(jsonString);
    if (o && typeof o === 'object') {
      return o;
    }
  } catch (e) {}

  return false;
};

export const convertRechartsLabelToPeriod = (activeLabel) => {
  let startDate =
    store?.getState()?.updateScreenToScreenDate?.forecastStartDate;
  let endDate = store?.getState()?.updateScreenToScreenDate?.forecastEndDate;
  if (activeLabel !== undefined) {
    startDate = dayjs(activeLabel, 'MMM/YY').startOf('month');
    endDate = dayjs(activeLabel, 'MMM/YY').endOf('month');
  }
  /* Utilizamos o mês atual formatado (formattedCurrentMonth) para comparar se é igual
  ao mês clicado no componente (activeLabel). Se for igual, definimos o startDate para D+1
  caso contrário, definimos startDate começando do início do mês.
  Detalhes no chamado PS2-11922
  */
  const currentMonth = dayjs().format('MMM/YY');
  const formattedCurrentMonth =
    currentMonth.charAt(0).toUpperCase() + currentMonth.slice(1);

  if (activeLabel === formattedCurrentMonth) {
    startDate = dayjs().add(1, 'day');
    endDate = dayjs(activeLabel, 'MMM/YY').endOf('month');
  } else {
    startDate = dayjs(activeLabel, 'MMM/YY').startOf('month');
    endDate = dayjs(activeLabel, 'MMM/YY').endOf('month');
  }
  return { startDate, endDate };
};

export const isMiddleMouseDown = (event) => {
  if (event?.type === 'mousedown' && event?.button === 1) {
    return true;
  }
  return false;
};

export function* splitNParts(num, parts) {
  let sumParts = 0;
  for (let i = 0; i < parts - 1; i += 1) {
    const pn = Math.ceil(Math.random() * (num - sumParts));
    yield pn;
    sumParts += pn;
  }
  yield num - sumParts;
}

export const transformStringToFloat = (value) => {
  if (typeof value === 'string') {
    return parseFloat(value);
  }
  return value;
};

export const heightBarCalculator = (value) => {
  if (value <= 3) {
    return 4;
  }
  if (value >= 97) {
    return 96;
  }
  return value;
};

export const paginate = (items, currentPage, itemsPerPage) => {
  let page = currentPage || 1,
    perPage = itemsPerPage || 10,
    offset = (page - 1) * perPage,
    paginatedItems = items.slice(offset).slice(0, itemsPerPage),
    totalPages = Math.ceil(items.length / perPage);

  return {
    page,
    perPage,
    prePage: page - 1 ? page - 1 : null,
    nextPage: totalPages > page ? page + 1 : null,
    total: items.length,
    totalPages: totalPages,
    data: paginatedItems,
  };
};
