import { PercentageOutlined } from '@ant-design/icons';
import React from 'react';
import { StInput } from './styled';

const InputMaskPercent = ({ onChange, ...rest }) => {
  const formatValue = (valueNumber, formatPattern) => {
    const formattedValue = valueNumber.replace(formatPattern, '$1,$2');

    return formattedValue;
  };

  const onChangeHandler = (e) => {
    const pattern = /^[\d,.]*$/;
    const { value } = e.target;
    const valueNumber = value.replace(/^0+/, '').replace(/[,.]/g, '');
    let formattedValue = '';

    if (value === '') {
      onChange('');
      return;
    }

    if (!pattern.test(value)) return;

    if (valueNumber.length > 0 && valueNumber.length <= 3) {
      formattedValue = formatValue(
        valueNumber.padStart(3, '0'),
        /^(\d{1})(\d{2}).*/
      );
    } else if (valueNumber.length === 4) {
      formattedValue = formatValue(valueNumber, /^(\d{2})(\d{2}).*/);
    } else if (valueNumber.length >= 5) {
      formattedValue = formatValue(valueNumber, /^(\d{3})(\d{2}).*/);
    }
    const InputValueNumber = Number(formattedValue.replace(',', '.'));

    if (InputValueNumber > 100) return;
    onChange(formattedValue);
  };

  return (
    <StInput
      {...rest}
      type="text"
      suffix={<PercentageOutlined />}
      placeholder="0,00"
      maxLength={6}
      onChange={onChangeHandler}
    />
  );
};

export default InputMaskPercent;
