import { call, put } from 'redux-saga/effects';

import postForgotPasswordFromApi from 'services/forgotPassword';
import errorHandler from 'helpers/errorHandler';
import { forgotPasswordSuccess, forgotPasswordFailure } from './actions';

export function* forgotPasswordSaga(action) {
  const { formData } = action.payload;
  try {
    yield call(postForgotPasswordFromApi, formData);
    yield put(forgotPasswordSuccess());
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        forgotPasswordFailure(err.response.data.error, err.response.data.error)
      );
      errorHandler(err?.response?.data?.error?.message, err);
    }
  }
}
