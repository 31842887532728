export const actionTypes = {
  GET_MERCHANTS_START: '@store/GET_MERCHANTS_START',
  GET_MERCHANTS_SUCCESS: '@store/GET_MERCHANTS_SUCCESS',
  GET_MERCHANTS_ERROR: '@store/GET_MERCHANTS_ERROR',
  POST_MERCHANT_START: '@store/POST_MERCHANT_START',
  POST_MERCHANT_SUCCESS: '@store/POST_MERCHANT_SUCCESS',
  POST_MERCHANT_ERROR: '@store/POST_MERCHANT_ERROR',
  DELETE_MERCHANT_START: '@store/DELETE_MERCHANT_START',
  DELETE_MERCHANT_SUCCESS: '@store/DELETE_MERCHANT_SUCCESS',
  DELETE_MERCHANT_ERROR: '@store/DELETE_MERCHANT_ERROR',
};
