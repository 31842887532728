export const actionTypes = {
  TOKEN_SANDBOX_POST_START: '@tokenSandbox/TOKEN_SANDBOX_POST_START',
  TOKEN_SANDBOX_POST_SUCCESS: '@tokenSandbox/TOKEN_SANDBOX_POST_SUCCESS',
  TOKEN_SANDBOX_POST_ERROR: '@tokenSandbox/TOKEN_SANDBOX_POST_ERROR',
  TOKEN_SANDBOX_DELETE_START: '@tokenSandbox/TOKEN_SANDBOX_DELETE_START',
  TOKEN_SANDBOX_DELETE_SUCCESS: '@tokenSandbox/TOKEN_SANDBOX_DELETE_SUCCESS',
  TOKEN_SANDBOX_DELETE_ERROR: '@tokenSandbox/TOKEN_SANDBOX_DELETE_ERROR',
  TOKEN_SANDBOX_GET_START: '@tokenSandbox/TOKEN_SANDBOX_GET_START',
  TOKEN_SANDBOX_GET_SUCCESS: '@tokenSandbox/TOKEN_SANDBOX_GET_SUCCESS',
  TOKEN_SANDBOX_GET_ERROR: '@tokenSandbox/TOKEN_SANDBOX_GET_ERROR',
  TOKEN_SANDBOX_PUT_START: '@tokenSandbox/TOKEN_SANDBOX_PUT_START',
  TOKEN_SANDBOX_PUT_SUCCESS: '@tokenSandbox/TOKEN_SANDBOX_PUT_SUCCESS',
  TOKEN_SANDBOX_PUT_ERROR: '@tokenSandbox/TOKEN_SANDBOX_PUT_ERROR',
  TOKEN_SANDBOX_DISABLE_TOKEN_START:
    '@tokenSandbox/TOKEN_SANDBOX_DISABLE_TOKEN_START',
  TOKEN_SANDBOX_DISABLE_TOKEN_SUCCESS:
    '@tokenSandbox/TOKEN_SANDBOX_DISABLE_TOKEN_SUCCESS',
  TOKEN_SANDBOX_DISABLE_TOKEN_ERROR:
    '@tokenSandbox/TOKEN_SANDBOX_DISABLE_TOKEN_ERROR',
  ROW_CLIENT_ID_SANDBOX_START: '@tokenSandbox/ROW_CLIENT_ID_SANDBOX_START',
  ROW_CLIENT_ID_SANDBOX_SUCCESS: '@tokenSandbox/ROW_CLIENT_ID_SANDBOX_SUCCESS',
  ROW_CLIENT_ID_SANDBOX_ERROR: '@tokenSandbox/ROW_CLIENT_ID_SANDBOX_ERROR',
};
