import { actionTypes } from './types';

const INITIAL_STATE = {
  payments: [],
  startDate: undefined,
  endDate: undefined,
  loading: false,
  error: undefined,
  errorMessage: undefined,
  isError: false,
  filter: [],
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case actionTypes.LIST_ADVANCEMENT_PAYMENTS_INTERMEDIATE_START:
    return {
      ...state,
      loading: true,
      error: undefined,
      isError: false,
    };
  case actionTypes.LIST_ADVANCEMENT_PAYMENTS_INTERMEDIATE_SUCCESS:
    return {
      ...state,
      loading: false,
      error: false,
      payments: action.payload.payments,
      startDate: action.payload.start_date,
      endDate: action.payload.end_date,
      isError: false,
    };
  case actionTypes.LIST_ADVANCEMENT_PAYMENTS_INTERMEDIATE_ERROR:
    return {
      ...state,
      loading: false,
      error: action.error.error,
      errorMessage: action.payload,
      isError: true,
    };
  default:
    return state;
  }
};

export default reducer;
