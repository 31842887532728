import { action } from 'typesafe-actions';
import { actionTypes } from './types';

export const authRequest = (formData) =>
  action(actionTypes.AUTH_START, { formData });
export const authSuccess = (data) => action(actionTypes.AUTH_SUCCESS, { data });
export const authFailure = (errorMessage, error) =>
  action(actionTypes.AUTH_ERROR, { errorMessage }, undefined, { error });

export const validateTokenStart = (tokenData) =>
  action(actionTypes.VALIDATE_TOKEN_START, { tokenData });
export const validateTokenSuccess = (data) =>
  action(actionTypes.VALIDATE_TOKEN_SUCCESS, { data });
export const validateTokenFailure = (errorMessage, error) =>
  action(actionTypes.VALIDATE_TOKEN_ERROR, { errorMessage }, undefined, {
    error,
  });
