import React from 'react';
import { dataTestIdHandler } from 'helpers/dataTestIdHandler';
import BlockedFeaturePopover from 'commons/BlockedFeaturePopover/BlockedFeaturePopover';
import {
  StContainerBlocked,
  StItemBlocked,
  StItemContainer,
  StItemLink,
  StLockOutlined,
} from './styled';

const DrawerItem = ({
  icon,
  label,
  path,
  blocked = false,
  blockedTooltipText = '',
  onClick = () => {},
  onClickPlans = () => {},
  ...others
}) => {
  const dataTestPrefix = 'drawer-item';

  return (
    <BlockedFeaturePopover
      visible={blocked}
      placement="rightTop"
      tooltipText={blockedTooltipText}
      onClick={onClickPlans}
    >
      <StItemContainer onClick={blocked ? null : onClick} {...others}>
        {icon}
        {blocked ? (
          <StContainerBlocked>
            <StItemBlocked>{label}</StItemBlocked>
            <StLockOutlined
              {...dataTestIdHandler(dataTestPrefix, 'lock-icon')}
            />
          </StContainerBlocked>
        ) : (
          <StItemLink to={path} target="_self">
            {label}
          </StItemLink>
        )}
      </StItemContainer>
    </BlockedFeaturePopover>
  );
};

export default DrawerItem;
