import { bankingApi } from '../api';

const transformer = (apiResponse) => {
  return {
    payload: apiResponse?.payload,
  };
};

// Verifica se há algum Radar em execução
export const anyRunning = async () => {
  const response = await bankingApi().get('/radar/any/running');
  return transformer(response.data);
};
