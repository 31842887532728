import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { shortId } from 'helpers/shortId';
import { StTableColumnTitle } from 'commons/Table/styled';
import { getDefaultColumnWidth } from 'helpers/tableService';
import { columnSorter } from 'helpers/generalService';
import Actions from '../GeneralUserManagementHeader/components/Actions';
import DeletionModal from '../GeneralUserManagementHeader/components/DeletionModal';
import { perPageOptions, showTotalPage } from 'constants/perPageOptions';
import { StTable } from './styled';
import dayjs from 'dayjs';
import { DefaultModal } from './components/DefaultModal';
import { useModals } from './hooks/useModals';

const UserTable = ({
  generalListOfClients,
  generalListOfClientsLoading,
  setSelectedRowCount,
  isRemoveModalVisible,
  selectedRows,
  setSelectedRows,
  setRemoveModalVisible,
}) => {
  const [dataSource, setDataSource] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const {
    isModalVisible,
    setIsModalVisible,
    setListOfClients,
    listOfClients,
    showDefaultModal,
    currentRowData,
  } = useModals();

  const handleUserInvitationStatus = (status) => {
    if (status === 'ACCEPTED') return 'Ativo';
    if (status === 'FAILED') return 'Falha no convite';
    if (status === 'SENT') return 'Convidado';
    if (status === 'RESENT') return 'Convidado';
    if (status === 'Null') return 'Sem usuário padrão';
    return 'Status inválido';
  };

  useEffect(() => {
    setSelectedRowCount(selectedRowKeys.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowKeys]);

  useEffect(() => {
    const transformedDataSource = [];
    if (generalListOfClients !== undefined) {
      generalListOfClients.forEach((client, index) => {
        // Código da empresa,
        // Nome da empresa,
        // ID Parceiro,
        // Skin,
        // Produto,
        // E-mail do usuário,
        // Escopo,
        // Permissionamento,
        // Acesso web,
        // Acesso app,
        // Nome do usuário,
        // Status,
        // Data de convite,
        // Contagem de acessos
        transformedDataSource.push({
          key: index,
          clientCode: client.client_code,
          clientName: client.client_name,
          partnerId: client.partner_id,
          clientSkin: client.skin_id,
          clientSkinName: client.skin_name,
          clientPlan: client.plan_id,
          clientPlanName: client.plan_name,
          userEmail: client.email,
          userLastLogin:
            !client.user_last_login || client.user_last_login === ''
              ? ''
              : dayjs(client.user_last_login).format('DD/MM/YYYY'),
          userLastLoginApp:
            !client.user_last_login_app || client.user_last_login_app === ''
              ? ''
              : dayjs(client.user_last_login_app).format('DD/MM/YYYY'),
          userScope: client.user_scope_name,
          userProfile: client.user_profile_name,
          isApiConnected: client.is_api_connected,
          userName: client.user_name,
          userInvitationStatus: handleUserInvitationStatus(
            client.invitation_status
          ),
          invitationDate: client.created_at,
          actions: client,
        });
      });

      setDataSource(transformedDataSource);
    }
  }, [generalListOfClients]);

  const columns = [
    {
      title: () => {
        return <StTableColumnTitle>Código da empresa</StTableColumnTitle>;
      },
      dataIndex: 'clientCode',
      key: shortId(),
      sorter: (a, b) => columnSorter(a, b, 'clientCode'),
      width: 195,
      align: 'left',
    },
    {
      title: () => {
        return <StTableColumnTitle>Nome da empresa</StTableColumnTitle>;
      },
      dataIndex: 'clientName',
      key: shortId(),
      sorter: (a, b) => columnSorter(a, b, 'clientName'),
      width: getDefaultColumnWidth('store_name'),
      align: 'left',
    },
    {
      title: () => {
        return <StTableColumnTitle>ID Parceiro</StTableColumnTitle>;
      },
      dataIndex: 'partnerId',
      key: shortId(),
      sorter: (a, b) => columnSorter(a, b, 'partnerId'),
      width: 150,
      align: 'left',
    },
    {
      title: () => {
        return <StTableColumnTitle>Skin</StTableColumnTitle>;
      },
      dataIndex: 'clientSkinName',
      key: shortId(),
      sorter: (a, b) => columnSorter(a, b, 'clientSkinName'),
      width: 140,
      align: 'left',
    },
    {
      title: () => {
        return <StTableColumnTitle>Produto</StTableColumnTitle>;
      },
      dataIndex: 'clientPlanName',
      key: shortId(),
      sorter: (a, b) => columnSorter(a, b, 'clientPlanName'),
      width: 170,
      align: 'left',
    },
    {
      title: () => {
        return <StTableColumnTitle>E-mail do usuário</StTableColumnTitle>;
      },
      dataIndex: 'userEmail',
      key: shortId(),
      sorter: (a, b) => columnSorter(a, b, 'userEmail'),
      width: getDefaultColumnWidth('store_name'),
      align: 'left',
    },
    {
      title: () => {
        return <StTableColumnTitle>Escopo</StTableColumnTitle>;
      },
      dataIndex: 'userScope',
      key: shortId(),
      sorter: (a, b) => columnSorter(a, b, 'userScope'),
      width: 150,
      align: 'left',
    },
    {
      title: () => {
        return <StTableColumnTitle>Permissionamento</StTableColumnTitle>;
      },
      dataIndex: 'userProfile',
      key: shortId(),
      sorter: (a, b) => columnSorter(a, b, 'userProfile'),
      width: 200,
      align: 'left',
    },
    {
      title: () => {
        return <StTableColumnTitle>Acesso web</StTableColumnTitle>;
      },
      dataIndex: 'userLastLogin',
      key: shortId(),
      sorter: (a, b) => columnSorter(a, b, 'userLastLogin'),
      width: 200,
      align: 'left',
    },
    {
      title: () => {
        return <StTableColumnTitle>Acesso App</StTableColumnTitle>;
      },
      dataIndex: 'userLastLoginApp',
      key: shortId(),
      sorter: (a, b) => columnSorter(a, b, 'userLastLoginApp'),
      width: 200,
      align: 'left',
    },
    {
      title: () => {
        return <StTableColumnTitle>Nome do usuário</StTableColumnTitle>;
      },
      dataIndex: 'userName',
      key: shortId(),
      sorter: (a, b) => columnSorter(a, b, 'userName'),
      width: 200,
      align: 'left',
    },
    {
      title: () => {
        return <StTableColumnTitle>Status</StTableColumnTitle>;
      },
      dataIndex: 'userInvitationStatus',
      key: shortId(),
      sorter: (a, b) => columnSorter(a, b, 'userInvitationStatus'),
      width: 250,
      align: 'left',
    },
    {
      title: () => {
        return <StTableColumnTitle>Data de convite</StTableColumnTitle>;
      },
      dataIndex: 'invitationDate',
      key: shortId(),
      sorter: (a, b) => columnSorter(a, b, 'invitationDate'),
      width: 180,
      align: 'left',
    },
    {
      title: () => {
        return <StTableColumnTitle>Ações</StTableColumnTitle>;
      },
      dataIndex: 'actions',
      key: shortId(),
      width: 200,
      render: (_selectedRowData) => {
        return (
          <Actions
            selectedRows={[_selectedRowData]}
            showDefaultModal={showDefaultModal}
          />
        );
      },
      fixed: 'right',
    },
  ];

  const onSelectedRowKeysChange = (_selectedRowKeys, _selectedRows) => {
    setSelectedRowKeys(_selectedRowKeys);
    setSelectedRows(_selectedRows);
  };

  return (
    <>
      <StTable
        dataSource={dataSource}
        rowSelection={{
          selectedRowKeys,
          onChange: onSelectedRowKeysChange,
        }}
        columns={columns}
        loading={generalListOfClientsLoading}
        bordered
        rowClassName="table-row-line"
        pagination={{
          total: dataSource?.length,
          pageSizeOptions: perPageOptions,
          showTotal: showTotalPage,
          showSizeChanger: Boolean(dataSource?.length >= 10),
        }}
        scroll={{
          x: '100%',
          y: window.innerHeight - 400,
        }}
      />
      <DefaultModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        generalListOfClients={generalListOfClients}
        generalListOfClientsLoading={generalListOfClientsLoading}
        listOfClients={listOfClients}
        setListOfClients={setListOfClients}
        currentRowData={currentRowData}
      />
      <DeletionModal
        isRemoveModalVisible={isRemoveModalVisible}
        setRemoveModalVisible={setRemoveModalVisible}
        selectedRows={selectedRows}
        multiple
      />
    </>
  );
};

UserTable.propTypes = {
  generalListOfClients: PropTypes.arrayOf(PropTypes.object.isRequired)
    .isRequired,
  generalListOfClientsLoading: PropTypes.bool.isRequired,
  isRemoveModalVisible: PropTypes.bool.isRequired,
  setRemoveModalVisible: PropTypes.func.isRequired,
};

export default UserTable;
