export const actionTypes = {
  GET_LEGAL_REPRESENTATIVE_START:
    '@legalRepresentative/GET_LEGAL_REPRESENTATIVE_START',
  GET_LEGAL_REPRESENTATIVE_SUCCESS:
    '@legalRepresentative/GET_LEGAL_REPRESENTATIVE_SUCCESS',
  GET_LEGAL_REPRESENTATIVE_ERROR:
    '@legalRepresentative/GET_LEGAL_REPRESENTATIVE_ERROR',
  POST_LEGAL_REPRESENTATIVE_START:
    '@legalRepresentative/POST_LEGAL_REPRESENTATIVE_START',
  POST_LEGAL_REPRESENTATIVE_SUCCESS:
    '@legalRepresentative/POST_LEGAL_REPRESENTATIVE_SUCCESS',
  POST_LEGAL_REPRESENTATIVE_ERROR:
    '@legalRepresentative/POST_LEGAL_REPRESENTATIVE_ERROR',
};
