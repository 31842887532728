import React from 'react';
import PropTypes from 'prop-types';
import { List, Card, Row, Col, Spin } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch } from 'react-redux';
import customHistory from 'helpers/history';
import { ErrorCard } from 'commons/ErrorCard';
import { CardItemList } from '../CardItemList';
import { shortId } from 'helpers/shortId';

const ContentCards = ({
  data,
  loading,
  error,
  detailRoute,
  activeTab,
  updateAction,
}) => {
  const dispatch = useDispatch();

  const transformItemToFilter = (item) => {
    const filter = [];
    if (activeTab === 'branches') {
      filter.push({
        name: 'store_name',
        val: [item.title],
      });
    } else if (activeTab === 'acquirers') {
      filter.push({
        name: 'acquirer_name',
        val: [item.title],
      });
    } else if (activeTab === 'products') {
      filter.push({
        name: 'product_code',
        val: [item.title],
      });
    } else if (activeTab === 'brands') {
      filter.push({
        name: 'brand_code',
        val: [item.title],
      });
    }
    return filter;
  };

  const goToReports = (item) => {
    dispatch(updateAction(transformItemToFilter(item)));
    customHistory.push(detailRoute);
  };

  if (error.has) {
    return (
      <Row style={{ marginTop: '2rem' }}>
        <Col lg={10} md={18}>
          <Card style={{ borderRadius: 4 }} loading={loading}>
            <ErrorCard
              title={error.titleError}
              reason={error.reason}
              recommendation={error.recommendation}
            />
          </Card>
        </Col>
      </Row>
    );
  }

  return (
    // TODO: remove this library cause payments page doesn't need it
    <InfiniteScroll
      loadMore={() => {}}
      key={shortId()}
      pageStart={1}
      hasMore={false}
      useWindow
      loader={
        <Row
          justify="center"
          align="middle"
          style={{ height: '100%' }}
          key={shortId()}
        >
          <Col key={shortId()}>
            <Spin key={shortId()} />
          </Col>
        </Row>
      }
    >
      <List
        key={shortId()}
        grid={{
          xxl: 6,
          xl: 5,
          lg: 4,
          md: 3,
          sm: 2,
          xs: 1,
          gutter: 16,
        }}
        dataSource={data}
        loading={loading}
        renderItem={(item) => (
          <List.Item key={shortId()}>
            <CardItemList
              titleCard={item.title || ''}
              dataCard={item.data}
              goToReports={() => {
                goToReports(item);
              }}
            />
          </List.Item>
        )}
      />
    </InfiniteScroll>
  );
};
ContentCards.defaultProps = {
  error: {
    has: false,
    titleError: '',
    reason: '',
    recommendation: '',
  },
};
ContentCards.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          value: PropTypes.string,
        })
      ),
    }).isRequired
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.shape({
    has: PropTypes.bool,
    titleError: PropTypes.string,
    reason: PropTypes.string,
    recommendation: PropTypes.string,
  }),
  detailRoute: PropTypes.string.isRequired,
  activeTab: PropTypes.string.isRequired,
  updateAction: PropTypes.func.isRequired,
};

export default ContentCards;
