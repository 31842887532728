import React, { useContext } from 'react';
import { OnboardingManagementContext } from 'components/OnboardingManagement/OnboardingManagement';
import { ExportDropdown } from 'commons/ExportDropdown';
import { useReport } from 'utils/hooks/useReport';
import { getSessionClientName } from 'helpers/sessionService';
import * as St from './styled';

export function Table() {
  const {
    loading,
    columnList,
    data,
    rowClassName,
    pagination,
    onTablePaginationOrFiltersOrSorterChange,
    form,
    sortFields,
  } = useContext(OnboardingManagementContext);

  const clientName = getSessionClientName();
  const { codClient, stage, plan, status } = form.getFieldsValue(true);

  let startDate;
  let endDate;

  if (Object.keys(form.getFieldsValue(true)).length > 0) {
    startDate = form?.getFieldValue('date')[0];
    endDate = form?.getFieldValue('date')[1];
  }

  const { setDropdownOption, loading: notificationsLoading } = useReport({
    fileName: 'relatorio_de_implantacao',
    table: 'onboarding-summary',
    startDate,
    endDate,
    clientName,
    extraFilters: { codClient, stage, plan, status },
    sortFields,
  });
  const hasData = Object.keys(data).length > 0;

  return (
    <St.Container>
      <St.TableHeader hasData={hasData}>
        <St.TableHeaderTitle>Relatório de implantações</St.TableHeaderTitle>
        <ExportDropdown
          loading={loading || notificationsLoading}
          disabled={loading || notificationsLoading || !hasData}
          setDropdownOption={setDropdownOption}
          visibleCSV={false}
        />
      </St.TableHeader>

      {hasData ? (
        <St.OnboardingManagementTable
          loading={loading}
          dataSource={data}
          columns={columnList}
          pagination={pagination}
          rowClassName={rowClassName}
          onChange={onTablePaginationOrFiltersOrSorterChange}
        />
      ) : (
        <St.EmptyTable>
          <St.FilterCircle>
            <St.FilterIcon />
          </St.FilterCircle>
          <St.EmptyTableTitle>Utilize os filtros</St.EmptyTableTitle>
          <St.EmptyTableText>
            Para visualizar o resultado, selecione o tipo de identificação.
          </St.EmptyTableText>
        </St.EmptyTable>
      )}
    </St.Container>
  );
}
