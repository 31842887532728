import styled from 'styled-components';
import { colors } from 'styles/colors';

export const Tag = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 1px 8px;
  border-radius: 2px;
  color: ${({ fontColor = colors.gray10 }) => fontColor};
  border: ${({ borderColor = colors.gray10 }) => `1px solid ${borderColor}`};
  background-color: ${({ backgroundColor = colors.gray1 }) => backgroundColor};
`;

export const IconContainer = styled.div`
  margin-right: 5px;
  color: ${({ iconColor = colors.gray10 }) => iconColor};
`;
