import dayjs from 'dayjs';
import { lambdaApi } from '../../api';
import { parseCurrency } from 'helpers/currency';

const transformer = ({ data: { acquirer, customer } }) => {
  const transformedResponse = {
    acquirer: {
      pendentAmount: parseCurrency(acquirer?.pendent_amount),
      rawPendentAmount: acquirer?.pendent_amount,
      percentPendent: acquirer?.percent_pendent,
      percentReconciled: acquirer?.percent_reconciled,
      reconciledAmount: parseCurrency(acquirer?.reconciled_amount),
      totalAmount: parseCurrency(acquirer?.total_amount),
      rawTotalAmount: acquirer?.total_amount,
    },
    customer: {
      pendentAmount: parseCurrency(customer?.pendent_amount),
      rawPendentAmount: customer?.pendent_amount,
      percentPendent: customer?.percent_pendent,
      percentReconciled: customer?.percent_reconciled,
      reconciledAmount: parseCurrency(customer?.reconciled_amount),
      totalAmount: parseCurrency(customer?.total_amount),
      rawTotalAmount: customer?.total_amount,
    },
  };
  return transformedResponse;
};

const getSalesSummary = async ({ startDate, endDate }) => {
  const config = {
    params: {
      start_date: dayjs(startDate).format('YYYY-MM-DD'),
      end_date: dayjs(endDate).format('YYYY-MM-DD'),
    },
  };
  const response = await lambdaApi().get('/summary/sales/reconciled', config);
  return transformer(response.data);
};

export default getSalesSummary;
