import React, { useRef } from 'react';
import { Tooltip, List, Grid, Divider } from 'antd';
import PropTypes from 'prop-types';
import {
  StCompanyListContainer,
  StSearch,
  StSearchWrapper,
  StListWrapper,
  StListItem,
  StListItemButtonDifferent,
} from './styled';
import { shortId } from 'helpers/shortId';

const ListOfCompanies = ({
  listOfClients,
  loadingListOfClients,
  changeStore,
  onSearch,
  onSearchChange,
  loadMoreItens,
  hasMore,
}) => {
  const { useBreakpoint } = Grid;
  const breakpoint = useBreakpoint();
  const listRef = useRef(null);

  const handleScroll = () => {
    if (listRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        if (hasMore && !loadingListOfClients) {
          loadMoreItens();
        }
      }
    }
  };

  const noMoreResultsStyle = ({ name }) => {
    if (name === 'Não há mais resultados.') {
      return {
        cursor: 'initial',
        textAlign: 'center',
        display: 'flex !important',
        justifyContent: 'center',
      };
    }
    return {};
  };

  return (
    <StCompanyListContainer $breakpoint={breakpoint}>
      <div style={{}}>
        <StSearchWrapper>
          <StSearch
            onChange={(e) => onSearchChange(e.target.value)}
            onSearch={(value) => onSearch(value)}
            loading={loadingListOfClients}
            placeholder="Buscar empresa"
          />
        </StSearchWrapper>
      </div>
      <Divider style={{ height: 3 }} />
      {listOfClients?.length > 0 && (
        <StListWrapper>
          <div
            style={{ height: '250px', overflowY: 'auto' }}
            onScroll={handleScroll}
            ref={listRef}
          >
            <List
              dataSource={listOfClients}
              loading={loadingListOfClients}
              style={{ overflow: 'auto' }}
              renderItem={(item) => {
                let branchName = item.name;
                if (item.client_code) {
                  branchName = `${item.client_code} ${item.name}`;
                }

                return (
                  <StListItem key={shortId()}>
                    <Tooltip placement="top" title={item.name}>
                      <StListItemButtonDifferent
                        key={shortId()}
                        type="link"
                        style={noMoreResultsStyle(item)}
                        item={item}
                        onClick={() => {
                          if (item.name !== 'Não há mais resultados.') {
                            changeStore(item.client_id);
                          }
                        }}
                      >
                        {branchName}
                      </StListItemButtonDifferent>
                    </Tooltip>
                  </StListItem>
                );
              }}
            />
          </div>
        </StListWrapper>
      )}
    </StCompanyListContainer>
  );
};

ListOfCompanies.defaultProps = {
  listOfClients: [],
  hasMore: false,
};

ListOfCompanies.propTypes = {
  listOfClients: PropTypes.arrayOf(
    PropTypes.shape({
      client_code: PropTypes.string,
      client_id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  loadingListOfClients: PropTypes.bool.isRequired,
  changeStore: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  hasMore: PropTypes.bool,
  loadMoreItens: PropTypes.func.isRequired,
};

export default ListOfCompanies;
