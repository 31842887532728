import React, { useEffect, useState } from 'react';
import {
  Row,
  Grid,
  Button,
  Col,
  Form,
  Input,
  Checkbox,
  Typography,
  message,
  Spin,
} from 'antd';
import {
  StOffersTitle,
  StOffersTitleWrapper,
  StText,
} from 'components/Offers/styled';
import { StPartnerTag } from '../PartnerOffers/components/PartnerCard/styled';

import { MaskedInput } from 'antd-mask-input';
import customHistory from 'helpers/history';
import { useDispatch, useSelector } from 'react-redux';
import {
  getLegalRepresentativeStart,
  postLegalRepresentativeStart,
} from 'store/ducks/credit/legalRepresentative/actions';
import { creditPartnerOptinStart } from 'store/ducks/credit/creditPartnerOptin/actions';
import { colors } from 'styles/colors';

const AuthPartner = () => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.permissions?.data?.user_id);
  const lastLegalRepresentative = useSelector(
    (state) => state.legalRepresentative?.data?.lastLegalRepresentative
  );
  const creditPartnerOptinLoading = useSelector(
    (state) => state.creditPartnerOptin?.loading
  );

  const sharedPartnerData = useSelector(
    (state) => state.sharedPartnerData?.data
  );

  const authSuccess = (msg) => {
    message.success(msg);
  };

  useEffect(() => {
    dispatch(getLegalRepresentativeStart());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { useBreakpoint } = Grid;
  const breakpoint = useBreakpoint();

  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});

  const goBack = () => {
    customHistory.go(-1);
    authSuccess(
      'Suas informações foram compartilhadas para nosso parceiro com sucesso.'
    );
  };

  const activate = () => {
    dispatch(
      postLegalRepresentativeStart({
        name: form.getFieldValue('contact_name'),
        phone: form.getFieldValue('contact_phone'),
      })
    );
    dispatch(
      creditPartnerOptinStart({
        creditPartnerId: sharedPartnerData?.creditPartner?.id,
        userId,
      })
    );
    goBack();
  };

  // To disable submit button at the beginning.
  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    let contactPhone = lastLegalRepresentative?.phone;
    if (contactPhone?.includes('+')) {
      contactPhone = contactPhone.substring(3); // remove the first 3 chars (+55)
    }
    form.setFieldsValue({
      contact_name: lastLegalRepresentative?.name,
      contact_phone: contactPhone,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastLegalRepresentative]);

  return (
    <StOffersTitleWrapper $breakpoint={breakpoint}>
      <Row align="middle" justify="space-between" style={{ marginTop: '4rem' }}>
        <StOffersTitle>
          {/* Necessário vincular a uma variável para demais parceiros */}
          Tomático
        </StOffersTitle>
      </Row>
      <Row
        align="middle"
        justify="space-between"
        style={{
          marginTop: '8px',
        }}
      >
        <Col span={24}>
          <StText>
            {/* Necessário vincular a uma variável para demais parceiros */}
            A Tomático é uma empresa de soluções financeiras presente no mercado
            há mais de 25 anos. Oferecendo empréstimos inteligentes, tem ajudado
            empresas a alavancar suas vendas.
            <br />
            <br />
            Serviços oferecidos:
          </StText>
        </Col>

        <Col span={24} style={{ paddingTop: 8 }}>
          {/* Necessário vincular a uma variável para demais parceiros */}
          <StPartnerTag>Empréstimo inteligente</StPartnerTag>
        </Col>
      </Row>

      <Row
        align="middle"
        justify="space-between"
        style={{
          marginTop: '2rem',
          color: colors.gray9,
          fontWeight: 400,
          fontSize: 14,
        }}
      >
        <Col span={24}>
          {sharedPartnerData?.status !== 'OPTIN' ? (
            <Typography.Text style={{ color: colors.gray9 }}>
              {/* Necessário vincular a uma variável para demais parceiros */}
              Para ver as ofertas que a Tomático tem para você, preencha os
              campos abaixo e aceite os{' '}
              <a rel="noopener noreferrer" href="#">
                Termos de Uso
              </a>
              .
            </Typography.Text>
          ) : (
            <Typography.Text style={{ color: colors.gray9 }}>
              {/* Necessário vincular a uma variável para demais parceiros */}
              Informações compartilhadas com a empresa:
            </Typography.Text>
          )}
        </Col>

        <Col
          span={24}
          style={{
            marginTop: '24px',
          }}
        >
          <Form form={form} autoComplete="off">
            <Row span={24}>
              <Col lg={8}>
                <Form.Item
                  required
                  tooltip="Este campo é obrigatório!"
                  label="Nome completo:"
                  name="contact_name"
                  rules={[{ required: true }]}
                >
                  <Input
                    placeholder="Insira seu nome completo"
                    name="contact_name"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row span={24}>
              <Col lg={8}>
                <Form.Item
                  required
                  tooltip="Este campo é obrigatório!"
                  label="Telefone:"
                  name="contact_phone"
                  rules={[{ required: true }]}
                >
                  <MaskedInput
                    maskOptions={{
                      mask: '(00) 00000-0000',
                      lazy: true,
                    }}
                    name="contact_phone"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row span={24}>
              <Col>
                {sharedPartnerData?.status === 'OPTIN' ? (
                  <StText>
                    Estou ciente e de acordo em fornecer meu{' '}
                    <strong>nome, telefone, email</strong> e{' '}
                    <strong>CNPJ</strong> das empresas que possuo cadastradas no
                    Concil Card, bem como informações sobre o{' '}
                    <strong>fluxo de recebimento das adquirentes</strong> dos
                    últimos 12 meses, com a finalidade de ter acesso a soluções
                    financeiras deste parceiro.
                  </StText>
                ) : (
                  <Typography.Text style={{ color: colors.gray9 }}>
                    {/* Necessário vincular a uma variável para demais parceiros */}
                    Consulte aqui os{' '}
                    <a target="_blank" rel="noopener noreferrer" href="#">
                      Termos de Uso
                    </a>
                    .
                  </Typography.Text>
                )}
              </Col>
            </Row>
            <Row span={24} style={{ paddingTop: 16 }}>
              <Col>
                <Form.Item
                  name="agreement"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject(
                              new Error('Obrigatório aceitar o termo.')
                            ),
                    },
                  ]}
                >
                  {sharedPartnerData?.status === 'OPTIN' ? (
                    <Checkbox
                      style={{
                        fontSize: 14,
                        fontWeight: 400,
                        color: colors.gray9,
                      }}
                    >
                      Permito o compartilhamento dos dados acima com a empresa
                      parceira.
                    </Checkbox>
                  ) : (
                    <></>
                  )}
                </Form.Item>
              </Col>
              <Col span={24}>
                {sharedPartnerData?.status === 'OPTIN' ||
                sharedPartnerData?.status === 'NEW' ? (
                  <Form.Item shouldUpdate>
                    {() => (
                      <Button
                        onClick={activate}
                        type="primary"
                        htmlType="submit"
                      >
                        {creditPartnerOptinLoading ? <Spin /> : 'Compartilhar'}
                      </Button>
                    )}
                  </Form.Item>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </StOffersTitleWrapper>
  );
};

export default AuthPartner;
