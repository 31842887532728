export const actionTypes = {
  LIST_FILES_START: '@importFiles/LIST_FILES_START',
  LIST_FILES_SUCCESS: '@importFiles/LIST_FILES_SUCCESS',
  LIST_FILES_ERROR: '@importFiles/LIST_FILES_ERROR',
  LIST_FILES_ALTER: '@importFiles/LIST_FILES_ALTER',
  REMOVE_FILES_START: '@importFiles/REMOVE_FILES_START',
  REMOVE_FILES_SUCCESS: '@importFiles/REMOVE_FILES_SUCCESS',
  REMOVE_FILES_ERROR: '@importFiles/REMOVE_FILES_ERROR',
  UPLOAD_FILE_START: '@importFiles/UPLOAD_FILE_START',
  UPLOAD_FILE_SUCCESS: '@importFiles/UPLOAD_FILE_SUCCESS',
  UPLOAD_FILE_ERROR: '@importFiles/UPLOAD_FILE_ERROR',
  UPDATE_FILE_DATE_START: '@importFiles/UPDATE_FILE_DATE_START',
  UPDATE_FILE_DATE_SUCCESS: '@importFiles/UPDATE_FILE_DATE_SUCCESS',
  UPDATE_FILE_DATE_ERROR: '@importFiles/UPDATE_FILE_DATE_ERROR',
  UPDATE_CURRENT_PAGE_START: '@importFiles/UPDATE_CURRENT_PAGE_START',
  UPDATE_CURRENT_PAGE_SUCCESS: '@importFiles/UPDATE_CURRENT_PAGE_SUCCESS',
  UPDATE_CURRENT_PAGE_ERROR: '@importFiles/UPDATE_CURRENT_PAGE_ERROR',
};
