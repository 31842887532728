import styled, { css, keyframes } from 'styled-components';
import {
  Row,
  Col,
  Button,
  Menu,
  Typography,
  Drawer,
  Divider,
  Spin,
} from 'antd';

import { headerHeight, marginMainContainer } from 'helpers/style';
import { colors } from 'styles/colors';
import { Link } from 'react-router-dom';
import {
  DoubleLeftOutlined,
  FormOutlined,
  HomeOutlined,
  NotificationOutlined,
  PercentageOutlined,
  ToolOutlined,
  UploadOutlined,
} from '@ant-design/icons';

const { Title, Text } = Typography;
const { Item, ItemGroup } = Menu;

const opacityAnimation = keyframes`
  0% { opacity: 0; }
  100% { opacity: 0.45; }
`;

const leftMenuBaseStyle = css`
  &&& {
    font-size: 24px;
  }
`;

export const StNotificationsMask = styled.div`
  opacity: ${({ notificationsAreVisible }) =>
    notificationsAreVisible ? 0.45 : 0};
  background-color: ${colors.gray10};
  position: absolute;
  width: 100%;
  height: 100vh;
  animation-name: ${opacityAnimation};
  animation-duration: 0.3s;
  animation-iteration-count: 1;
`;

export const StNotificationDot = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${colors.red5};
  border: 1px solid ${colors.gray1};
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: 39px;
  cursor: pointer;
`;

export const StNotificationsModal = styled.div`
  top: 40px;
  right: 56px;
  height: calc(100vh - 200px);
  width: 528px;
  position: absolute;
  background-color: ${colors.gray1};
`;

export const StUnreadNotificationsContainer = styled.div`
  position: absolute;
  top: 50px;
  right: 10px;
  width: 408px;
  height: 166px;
  background-color: ${colors.gray1};
`;

export const StContainer = styled.div`
  background-color: ${colors.primary6};
  z-index: 500;
  width: 100%;
  position: fixed;
  top: 0;
`;

export const StRow = styled(Row)`
  margin: ${(props) => marginMainContainer(props?.$breakpoint)};
  height: ${(props) => headerHeight(props?.$breakpoint)};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StCol = styled(Col)`
  &&& {
    height: 100%;
    display: flex;
    align-items: center;
  }
`;

export const StBranchButton = styled(Button)`
  &&& {
    display: flex;
    align-items: center;
    background-color: transparent;
    color: ${colors.gray1};
  }
`;

export const StLogoUpgradeWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
`;

export const StLeftMenuItem = styled(Item)`
  &&& {
    :hover {
      background: #195d80 !important;
      border-right: 4px solid ${colors.gray1};
    }
    background-color: ${colors.leftMenuBackground};
    display: flex;
    align-items: center;
    font-size: 14px;
    color: ${colors.gray1};
    margin: 0;
    padding: 0 24px;
  }
`;

export const StBottomContainer = styled.div`
  position: absolute;
  bottom: 6rem;
  width: 100%;
  display: flex;
`;

export const StLinkUserMenuItem = styled(Link)`
  color: ${colors.gray8} !important;
  margin-left: 8px;
`;

export const StLeftMenuFirstContainer = styled.div`
  background-color: ${colors.leftMenuBackground};
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px;
`;

export const StLeftMenuTitle = styled(Title)`
  &&& {
    font-size: 16px;
    color: ${colors.gray1};
  }
`;

export const StLeftMenuEmailText = styled(Text)`
  &&& {
    font-size: 12px;
    color: ${colors.gray1};
    margin-top: -6px;
  }
`;

export const StLeftMenuStoreText = styled(Text)`
  &&& {
    font-size: 12px;
    font-weight: 500;
    margin: 3px 0 19px 0;
    color: ${colors.gray1};
    line-height: 20px;
  }
`;

export const StItemGroup = styled(ItemGroup)`
  .ant-menu-item-group-title {
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    color: ${colors.gray8};
  }
`;

export const leftDrawerBodyStyle = {
  backgroundColor: colors.leftMenuBackground,
};

export const StLeftMenuDrawer = styled(Drawer)`
  &&& {
    position: fixed;
    padding-top: 40px;
    z-index: 499;
  }
`;

export const StLeftMenu = styled(Menu)`
  &&& {
    background-color: transparent;
    border: none;

    li {
      margin: 16px 0 0 0;
      padding: 0;

      :first-child {
        margin-top: 24px;
      }

      :last-child {
        position: absolute;
        bottom: 50px;
        width: 100%;
      }
    }

    .ant-menu-submenu {
      :hover,
      ::after {
        color: ${colors.gray1};
        border: none;
        border-bottom: none;
      }
    }
  }
`;

export const StSpin = styled(Spin)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 24px 0;
  width: 100%;
`;

export const StDivider = styled(Divider)`
  &&& {
    background-color: rgba(240, 240, 240, 0.4);
    margin-top: 4px;
    margin-bottom: 11px;
  }
`;

export const StMenu = styled(Menu)`
  &&& {
    background-color: transparent;
    border: none;

    li {
      margin: 0;
      padding: 0;

      :last-child {
        position: absolute;
        bottom: 50px;
        width: 100%;
      }
    }

    .ant-menu-submenu {
      :hover,
      ::after {
        color: ${colors.gray1};
        border: none;
        border-bottom: none;
      }
    }
  }
`;

export const StHomeOutlined = styled(HomeOutlined)`
  ${leftMenuBaseStyle}
`;

export const StFormOutlined = styled(FormOutlined)`
  ${leftMenuBaseStyle}
`;

export const StToolOutlined = styled(ToolOutlined)`
  ${leftMenuBaseStyle}
`;

export const StPercentageOutlined = styled(PercentageOutlined)`
  ${leftMenuBaseStyle}
`;

export const StUploadOutlined = styled(UploadOutlined)`
  ${leftMenuBaseStyle}
`;

export const StDoubleLeftOutlined = styled(DoubleLeftOutlined)`
  ${leftMenuBaseStyle}
`;

export const StNotificationOutlined = styled(NotificationOutlined)`
  ${leftMenuBaseStyle}
`;
