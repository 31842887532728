import { lambdaApi } from '../api';

const transformer = (response) => {
  let result = {};
  if (response) {
    response.forEach(({ plan_id: planId, plan_name: planName }) => {
      result = {
        ...result,
        [planName]: planId,
      };
    });
  }

  return {
    plans: result,
  };
};

export const getPlansFromAPI = async () => {
  const response = await lambdaApi().get('/plans');
  const data = response?.data?.data || response?.data;
  return transformer(data);
};

export default getPlansFromAPI;
