import { call, put } from 'redux-saga/effects';

import errorHandler from 'helpers/errorHandler';

import {
  advancementDetailsSuccess,
  advancementDetailsError,
} from 'store/ducks/details/advancementDetails/actions';
import getAdvancementDetailsFromAPI from 'services/paymentsDetails/advancementDetails';

export function* advancementDetailsSaga(action) {
  try {
    const { payload } = action;

    const apiData = yield call(getAdvancementDetailsFromAPI, payload);

    yield put(advancementDetailsSuccess(apiData));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        advancementDetailsError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    }
  }
}
