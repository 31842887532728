import { lambdaApi } from '../api';

const transformer = (response) => {
  let result = {};
  if (response) {
    response.forEach(({ skin_id: skinId, skin_name: skinName }) => {
      result = {
        ...result,
        [skinName]: skinId,
      };
    });
  }

  return {
    skins: result,
  };
};

export const getSkinsFromAPI = async () => {
  const response = await lambdaApi().get('/skins');
  return transformer(response.data);
};

export default getSkinsFromAPI;
