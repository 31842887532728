import { call, put } from 'redux-saga/effects';

import errorHandler from 'helpers/errorHandler';

import {
  exportAmountsReceivableDetailsSuccess,
  exportAmountsReceivableDetailsError,
} from 'store/ducks/details/export/amountsReceivableDetails/actions';
import getExportAmountsReceivableDetailsFromAPI from 'services/details/export/amountsReceivableDetails';

export function* exportAmountsReceivableDetailsSaga(action) {
  try {
    const { payload } = action;

    const apiData = yield call(
      getExportAmountsReceivableDetailsFromAPI,
      payload
    );

    yield put(exportAmountsReceivableDetailsSuccess(apiData));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        exportAmountsReceivableDetailsError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    }
  }
}
