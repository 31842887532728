import { call, put, delay } from 'redux-saga/effects';
import errorHandler from 'helpers/errorHandler';
import getSalesDetailsFiltersFromAPI from 'services/filters/details/salesDetailsFilters';
import { errorToShow } from 'store/ducks/errorToShow/actions';

import {
  salesDetailsFiltersSuccess,
  salesDetailsFiltersError,
} from './actions';

export function* salesDetailsFiltersSaga(action) {
  const { payload } = action;
  try {
    const apiData = yield call(getSalesDetailsFiltersFromAPI, payload);
    yield delay(500);
    yield put(salesDetailsFiltersSuccess(apiData));
  } catch (err) {
    if (
      err?.response?.data?.error &&
      err?.response?.data?.error?.type &&
      err?.response?.data?.error?.message
    ) {
      yield put(
        errorToShow({
          message: err.response.data.error.message,
          type: err.response.data.error.type,
        })
      );
      yield put(
        salesDetailsFiltersError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    }
  }
}
