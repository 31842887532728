import { call, put } from 'redux-saga/effects';

import errorHandler from 'helpers/errorHandler';

import {
  listOFXParameters,
  addOFXParameter,
  editOFXParameter,
  removeOFXParameter,
} from 'services/ofxParameters';
import {
  listOFXParametersOFXSuccess,
  listOFXParametersOFXError,
  removeOFXParameterSuccess,
  removeOFXParameterError,
  editOFXParameterSuccess,
  editOFXParameterError,
  addOFXParameterSuccess,
  addOFXParameterError,
} from './actions';
import { notification } from 'antd';

export function* listOFXParametersOFXSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(listOFXParameters, payload);
    yield put(listOFXParametersOFXSuccess(apiData));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        listOFXParametersOFXError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(listOFXParametersOFXError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}

export function* addOFXParameterSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(addOFXParameter, payload);
    yield put(addOFXParameterSuccess(apiData));
    notification.success({
      message: 'A nova regra foi adicionada com sucesso!',
    });
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        addOFXParameterError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(addOFXParameterError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}

export function* editOFXParameterSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(editOFXParameter, payload);
    yield put(editOFXParameterSuccess(apiData));
    notification.success({
      message: 'A regra selecionada foi editada com sucesso!',
    });
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        editOFXParameterError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(editOFXParameterError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}

export function* removeOFXParameterSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(removeOFXParameter, payload);
    yield put(removeOFXParameterSuccess(apiData));
    notification.success({
      message: 'A regra selecionada foi excluída com sucesso!',
    });
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        removeOFXParameterError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(removeOFXParameterError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}
