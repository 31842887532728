import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Drawer,
  Typography,
  Form,
  Input,
  Radio,
  Button,
  Spin,
  Col,
  Row,
  Modal,
} from 'antd';
import scopes from 'constants/scopes';
import profiles from 'constants/profiles';
import { useDispatch, useSelector } from 'react-redux';
import { checkInvitationEmailStart } from 'store/ducks/checkInvitationEmail/actions';
import { sendInvitationRequest } from 'store/ducks/sendInvitation/actions';
import {
  StButtonContainer,
  StContainer,
  StInvitationButton,
  StModalBodyWrapper,
  StModalBoldText,
  StModalContent,
  StModalHeader,
  StModalHeaderIcon,
  StModalHeaderTitle,
  StNameContainer,
} from './styled';
import {
  isAdmin,
  isClient,
  isConciler,
  isManagerial,
  isOperational,
  isPagSeguro,
  isPartner,
  isSuperAdmin,
} from 'helpers/authorizationService';
import warningHandler from 'helpers/warningHandler';
import {
  getSessionClientCode,
  getSessionClientName,
} from 'helpers/sessionService';
import { MaskedInput } from 'antd-mask-input';
import { colors } from 'styles/colors';
import errorHandler from 'helpers/errorHandler';
import { dataTestIdHandler } from 'helpers/dataTestIdHandler';

const UserInviteDrawer = ({ visible, onClose, onColappsed, ...others }) => {
  const { useBreakpoint } = Grid;
  const { Title, Text } = Typography;
  const breakpoint = useBreakpoint();
  const [form] = Form.useForm();
  const store = getSessionClientName();
  const clientCode = getSessionClientCode();
  const [concilScope, setConcilScope] = useState('0');
  const [concilPermission, setConcilPermission] = useState('1');
  const [disableFields, setDisableFields] = useState(false);
  const [invitationEmail, setInvitationEmail] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const dispatch = useDispatch();
  const sendInvitationLoading = useSelector(
    (state) => state.sendInvitation.loading
  );
  const checkInvitationEmailLoading = useSelector(
    (state) => state.checkInvitationEmail.loading
  );
  const invitationWasSent = useSelector(
    (state) => state.sendInvitation.invitationWasSent
  );
  // eslint-disable-next-line no-unused-vars
  const [doesUserHaveNullData, setUserHasNullData] = useState(false);
  const userData = useSelector((state) => state.checkInvitationEmail.userData);
  const [isAssociatingAllClients, setIsAssociatingAllClients] = useState(false);

  const [disableInviteButton, setDisableInviteButton] = useState(true);

  const inputRef = useRef(null);

  const formReset = () => {
    form.resetFields();
  };

  const dataTestPrefix = 'user-invite-form';

  useEffect(() => {
    const _doesUserHaveNullData = Object.entries(userData).some(
      (x) =>
        (x[1] === null || x[1] === '') && x[0] !== 'phone' && x[0] !== 'surname'
    );
    setDisableFields(true);
    setUserHasNullData(_doesUserHaveNullData);
    if (_doesUserHaveNullData || Object.keys(userData).length === 0) {
      setDisableFields(false);
    }
  }, [userData]);

  useEffect(() => {
    if (!sendInvitationLoading) {
      setIsAssociatingAllClients(false);
    }
  }, [sendInvitationLoading]);

  useEffect(() => {
    if (isAssociatingAllClients) {
      warningHandler(
        'Todas as empresas estão sendo associadas, por favor não atualize a página.',
        15
      );
    }
  }, [isAssociatingAllClients]);

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  };

  const onFinish = (values) => {
    dispatch(sendInvitationRequest(values));
    if (values?.to.includes('@concil.com.br')) {
      setIsAssociatingAllClients(true);
    }
    if (!sendInvitationLoading && invitationWasSent) {
      onClose();
      onColappsed();
      formReset();
      setDisableFields(false);
    }
  };

  const onBlur = () => {
    let _invitationEmail = ' ';
    if (invitationEmail) {
      _invitationEmail = invitationEmail;
    }

    form.setFieldsValue({
      to: _invitationEmail,
    });

    dispatch(
      checkInvitationEmailStart({
        to: _invitationEmail,
      })
    );
    inputRef.current.focus();
  };

  const onFinishFailed = (errorInfo) =>
    errorHandler(errorInfo?.errorFields[0]?.errors[0]);

  useEffect(() => {
    form.setFieldsValue({
      name: userData.name,
      surname: userData.surname,
      scope_id: userData.scopeId,
      profile_id: userData.profileId,
      phone: userData.phone,
    });
    if (userData.name && userData.scopeId && userData.profileId) {
      setDisableInviteButton(false);
    } else {
      setDisableInviteButton(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  useEffect(() => {
    form.setFieldsValue({
      scope_id: scopes.CLIENTE,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let drawerWidth = '100%';
  if (breakpoint?.md) {
    drawerWidth = '75%';
  }
  if (breakpoint?.lg) {
    drawerWidth = '50%';
  }
  if (breakpoint?.xl) {
    drawerWidth = '50%';
  }
  if (breakpoint?.xxl) {
    drawerWidth = '35%';
  }

  const enableInviteButton = () => {
    const fields = form.getFieldsValue([
      'to',
      'name',
      'surname',
      'scope_id',
      'profile_id',
    ]);
    if (fields?.to && fields?.name && fields?.scope_id && fields?.profile_id) {
      setDisableInviteButton(false);
    } else {
      setDisableInviteButton(true);
    }
  };

  const checkScopeAndProfileBeforeWarning = () => {
    const { scope_id: scopeId, profile_id: profileId } =
      form.getFieldsValue(true);

    return !!(
      (scopeId === scopes.PAGSEGURO && profileId === profiles.ADMINISTRADOR) ||
      (scopeId === scopes.CONCIL &&
        (profileId === profiles.ADMINISTRADOR ||
          profileId === profiles.SUPERADMIN))
    );
  };

  const scopesToBeWarned = {
    3: 'do ConcilCard',
    4: 'PagSeguro',
  };

  const warningModal = (
    <Modal
      open={isModalVisible}
      okText="Sim"
      cancelText="Cancelar"
      closable={false}
      maskClosable={!sendInvitationLoading}
      centered
      onCancel={() => setIsModalVisible(false)}
      onOk={() => form.submit()}
      okButtonProps={{
        ...dataTestIdHandler(dataTestPrefix, 'associated-confirm-button'),
      }}
      cancelButtonProps={{
        disabled: sendInvitationLoading,
        ...dataTestIdHandler(dataTestPrefix, 'associated-cancel-button'),
      }}
      width={400}
      confirmLoading={sendInvitationLoading}
    >
      <StModalBodyWrapper>
        <StModalHeader>
          <StModalHeaderIcon />
          <StModalHeaderTitle>Associar empresas</StModalHeaderTitle>
        </StModalHeader>
        <StModalContent>
          <Text>
            Tem certeza de que deseja associar{' '}
            <StModalBoldText>
              todas as empresas{' '}
              {scopesToBeWarned[form.getFieldValue('scope_id')]}
            </StModalBoldText>{' '}
            para este usuário?
          </Text>
        </StModalContent>
      </StModalBodyWrapper>
    </Modal>
  );

  return (
    <>
      <Drawer
        destroyOnClose
        open={visible}
        onClose={onClose}
        closable={false}
        width={drawerWidth}
        drawerStyle={{
          display: 'absolute',
          paddingTop: '40px',
        }}
        footerStyle={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
        {...others}
      >
        <StContainer>
          <Title style={{ color: colors.gray11, fontSize: '3rem' }}>
            Convidar usuário
          </Title>
          <Text
            style={{
              color: colors.gray11,
              fontSize: '1.75rem',
              marginTop: '1rem',
            }}
          >
            {`${clientCode} ${store}`}
          </Text>
          <Form
            id="userInviteForm"
            requiredMark={false}
            layout="vertical"
            style={{
              maxWidth: '100%',
              wordBreak: 'break-all',
              overflowWrap: 'break-word',
            }}
            name="invite_user"
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              required
              name="to"
              label="Para qual e-mail deseja enviar o convite?"
              rules={[
                {
                  required: true,
                  type: 'email',
                  message: 'Não é um e-mail válido',
                  min: 1,
                },
              ]}
            >
              <>
                <Input
                  {...dataTestIdHandler(dataTestPrefix, 'input-email')}
                  placeholder="Insira o e-mail"
                  type="email"
                  onBlur={onBlur}
                  style={{
                    marginBottom: 16,
                  }}
                  onChange={(e) => {
                    setInvitationEmail(e.target.value);
                    enableInviteButton();
                  }}
                />
                {checkInvitationEmailLoading && <Spin size="small" />}
              </>
            </Form.Item>
            <StNameContainer>
              <Row gutter={[8, 8]}>
                <Col span={12}>
                  <Form.Item
                    required
                    name="name"
                    label="Primeiro nome"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: 'Campo obrigatório',
                        min: 1,
                      },
                    ]}
                    style={{ display: 'inline-block', width: '100%' }}
                  >
                    <Input
                      {...dataTestIdHandler(dataTestPrefix, 'input-name')}
                      ref={inputRef}
                      placeholder="Insira o nome"
                      disabled={disableFields}
                      onChange={() => {
                        enableInviteButton();
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    required
                    name="surname"
                    label="Sobrenome"
                    style={{ display: 'inline-block', width: '100%' }}
                  >
                    <Input
                      {...dataTestIdHandler(dataTestPrefix, 'input-surname')}
                      placeholder="Insira o sobrenome"
                      disabled={disableFields}
                      onChange={() => {
                        enableInviteButton();
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </StNameContainer>
            <Row gutter={[8, 8]}>
              <Col span={12}>
                <Form.Item
                  name="phone"
                  label={
                    <Text
                      style={{
                        color: colors.gray11,
                        fontSize: '1.75rem',
                      }}
                    >
                      Telefone{' '}
                      <Text
                        style={{
                          color: colors.gray7,
                          fontSize: '14px',
                        }}
                      >
                        (Opcional)
                      </Text>
                    </Text>
                  }
                  style={{ display: 'inline-block', width: '100%' }}
                >
                  <MaskedInput
                    {...dataTestIdHandler(dataTestPrefix, 'input-phone')}
                    placeholder="Insira o telefone"
                    disabled={disableFields}
                    maskOptions={{
                      mask: '(00) 00000-0000',
                      lazy: true,
                    }}
                    onChange={() => {
                      enableInviteButton();
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label="Qual o tipo do novo usuário?"
              name="scope_id"
              rules={[
                { required: true, message: 'Por favor selecione uma opção' },
              ]}
            >
              <Radio.Group
                onChange={() => {
                  form.setFieldsValue({
                    profile_id: undefined,
                  });
                  enableInviteButton();
                }}
              >
                {(isClient() && isOperational()) ||
                isManagerial() ||
                isAdmin() ||
                isSuperAdmin() ? (
                  <Radio.Button
                    {...dataTestIdHandler(dataTestPrefix, 'radio-client')}
                    value={scopes.CLIENTE}
                    onClick={(e) => setConcilScope(e.target.value)}
                    disabled={disableFields}
                  >
                    CLIENTE
                  </Radio.Button>
                ) : (
                  <></>
                )}
                {isPagSeguro() || isAdmin() || isSuperAdmin() ? (
                  <Radio.Button
                    {...dataTestIdHandler(dataTestPrefix, 'radio-pagseguro')}
                    value={scopes.PAGSEGURO}
                    onClick={(e) => setConcilScope(e.target.value)}
                    disabled={disableFields}
                  >
                    PAGSEGURO
                  </Radio.Button>
                ) : (
                  <></>
                )}
                {isPartner() || isAdmin() || isSuperAdmin() ? (
                  <Radio.Button
                    {...dataTestIdHandler(dataTestPrefix, 'radio-parceiro')}
                    value={scopes.PARCEIRO}
                    onClick={(e) => setConcilScope(e.target.value)}
                    disabled={disableFields}
                  >
                    PARCEIRO
                  </Radio.Button>
                ) : (
                  <></>
                )}
                {isConciler() || isAdmin() || isSuperAdmin() ? (
                  <Radio.Button
                    {...dataTestIdHandler(dataTestPrefix, 'radio-concil')}
                    value={scopes.CONCIL}
                    onClick={(e) => setConcilScope(e.target.value)}
                    disabled={disableFields}
                  >
                    CONCIL
                  </Radio.Button>
                ) : (
                  <></>
                )}
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="Qual o nível de permissionamento do conciler ?"
              name="profile_id"
              hidden={concilScope !== '3'}
            >
              <Radio.Group
                onChange={() => {
                  enableInviteButton();
                }}
              >
                {isConciler() && (isAdmin() || isSuperAdmin()) ? (
                  <Radio
                    {...dataTestIdHandler(dataTestPrefix, 'radio-concil-admin')}
                    style={radioStyle}
                    value={profiles.ADMINISTRADOR}
                    disabled={disableFields}
                  >
                    Administrador: Acessa todas as funcionalidades e convida
                    usuários.
                  </Radio>
                ) : (
                  <></>
                )}
                {isConciler() && isSuperAdmin() ? (
                  <Radio
                    {...dataTestIdHandler(
                      dataTestPrefix,
                      'radio-concil-superadmin'
                    )}
                    style={radioStyle}
                    value={profiles.SUPERADMIN}
                    disabled={disableFields}
                  >
                    Superadmin: Acessa configurações do sistema, todas as
                    funcionalidades e convida usuários.
                  </Radio>
                ) : (
                  <></>
                )}
              </Radio.Group>
            </Form.Item>
            {isConciler() || isPagSeguro() ? (
              <Form.Item
                label="Qual o nível de permissionamento do usuário PagSeguro ?"
                name="profile_id"
                hidden={concilScope !== '4'}
              >
                <Radio.Group
                  onChange={() => {
                    enableInviteButton();
                  }}
                >
                  <Radio
                    {...dataTestIdHandler(
                      dataTestPrefix,
                      'radio-pagseguro-operacional'
                    )}
                    style={radioStyle}
                    value={profiles.OPERACIONAL}
                    disabled={disableFields}
                  >
                    Operacional: Acessa funcionalidades básicas e não convida
                    usuários.
                  </Radio>
                  {isAdmin() || isSuperAdmin() ? (
                    <Radio
                      {...dataTestIdHandler(
                        dataTestPrefix,
                        'radio-pagseguro-admin'
                      )}
                      style={radioStyle}
                      value={profiles.ADMINISTRADOR}
                      disabled={disableFields}
                    >
                      Administrador: Acessa todas as funcionalidades e convida
                      usuários.
                    </Radio>
                  ) : (
                    <></>
                  )}
                </Radio.Group>
              </Form.Item>
            ) : (
              <></>
            )}
            <Form.Item
              label="Qual o nível de permissionamento do parceiro ?"
              name="profile_id"
              hidden={concilScope !== '2'}
            >
              <Radio.Group
                value={concilPermission}
                onChange={(e) => {
                  setConcilPermission(e.target.value);
                  enableInviteButton();
                }}
              >
                <Radio
                  {...dataTestIdHandler(
                    dataTestPrefix,
                    'radio-parceiro-gerencial'
                  )}
                  style={radioStyle}
                  value={profiles.GERENCIAL}
                  disabled={disableFields}
                >
                  Gerencial: Acessa funcionalidades completas e convida
                  usuários.
                </Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="Qual o nível de permissionamento do cliente ?"
              name="profile_id"
              hidden={concilScope !== '1' && concilScope !== '0'}
            >
              <Radio.Group
                value={concilPermission}
                onChange={(e) => {
                  setConcilPermission(e.target.value);
                  enableInviteButton();
                }}
              >
                <Radio
                  {...dataTestIdHandler(
                    dataTestPrefix,
                    'radio-client-operacional'
                  )}
                  style={radioStyle}
                  value={profiles.OPERACIONAL}
                  disabled={disableFields}
                >
                  Operacional: Acessa funcionalidades básicas e não convida
                  usuários.
                </Radio>
                <Radio
                  {...dataTestIdHandler(
                    dataTestPrefix,
                    'radio-client-gerencial'
                  )}
                  style={radioStyle}
                  value={profiles.GERENCIAL}
                  disabled={disableFields}
                >
                  Gerencial: Acessa funcionalidades completas e convida
                  usuários.
                </Radio>
              </Radio.Group>
            </Form.Item>
            <StButtonContainer>
              <Button
                {...dataTestIdHandler(dataTestPrefix, 'cancel-button')}
                style={{ color: colors.red6 }}
                onClick={() => {
                  setDisableFields(false);
                  formReset();
                  onClose();
                }}
              >
                Cancelar
              </Button>
              <Form.Item>
                <StInvitationButton
                  {...dataTestIdHandler(dataTestPrefix, 'submit-button')}
                  type="primary"
                  style={{
                    marginRight: 16,
                  }}
                  disabled={disableInviteButton}
                  onClick={() => {
                    if (checkScopeAndProfileBeforeWarning()) {
                      setIsModalVisible(true);
                    } else {
                      form.submit();
                    }
                  }}
                >
                  Convidar usuário
                </StInvitationButton>
                {sendInvitationLoading || isAssociatingAllClients ? (
                  <Spin size="small" />
                ) : (
                  <></>
                )}
              </Form.Item>
            </StButtonContainer>
          </Form>
        </StContainer>
      </Drawer>
      {isModalVisible && !invitationWasSent && warningModal}
    </>
  );
};

UserInviteDrawer.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default UserInviteDrawer;
