import { lambdaApi } from 'services/api';

const transform = (results) => {
  const newResults = results?.data?.map((result) => {
    return {
      key: result?.idt_onboarding_summary,
      cod_client: result?.client,
      ...result,
    };
  });

  return {
    results: newResults,
    meta: results?.meta,
  };
};

export const getSummary = async ({
  page = 1,
  perPage = 10,
  startDate,
  endDate,
  sortFields = undefined,
  q = { filter: [] },
}) => {
  const config = {
    params: {
      page,
      per_page: perPage,
      start_date: startDate,
      end_date: endDate,
      sort_fields: JSON.stringify(sortFields),
      q: JSON.stringify(q),
    },
  };

  const result = await lambdaApi().get('/onboarding/summary', config);

  return transform(result.data);
};
