import { Resizable } from 'react-resizable';
import React, { useState } from 'react';

const ResizeableTitle = (props) => {
  const { onResize, width, ...restProps } = props;
  const [offset, setOffset] = useState(0);
  if (!width) {
    return <th {...restProps} />;
  }
  return (
    <Resizable
      // Recalculate the width of the result, the header should add an offset, so that the result can be calculated when the drag ends;
      // Of course the calculation should be the same in the stop event, I haven't tried it (laughs)
      width={width + offset}
      height={0}
      handle={
        <span
          className={
            'react-resizable-handle' + (offset === 0 ? ' ' : ' active')
          }
          style={{ transform: `translateX(${offset}px)` }}
          role="button"
          aria-label="column"
          tabIndex={0}
          onClick={(e) => {
            // Cancel bubbling, not canceling seems to trigger sorting events
            e.stopPropagation();
            e.preventDefault();
          }}
          onKeyDown={(e) => {
            // Cancel bubbling, not canceling seems to trigger sorting events
            e.stopPropagation();
            e.preventDefault();
          }}
        />
      }
      // Real-time update of drag events
      onResize={(e, { size }) => {
        // Only the offset is updated here, the data list does not actually scale
        setOffset(size.width - width);
      }}
      // Drag and drop to end the update
      onResizeStop={(...argu) => {
        // The offset is reset to zero after the end of the drag
        setOffset(0);
        // Here is the event passed in by props. Externally, it is the event provided by the onHeaderCell method in the column data. Please study the official case by yourself
        onResize(...argu);
      }}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

export default ResizeableTitle;
