import styled from 'styled-components';
import { Button, Typography } from 'antd';
import { colors } from 'styles/colors';

const { Title } = Typography;

export const StContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 72px;
  background-color: ${colors.gray3};
`;

export const StTitle = styled(Title)`
  &&& {
    font-size: 20px;
    font-weight: 500;
    color: ${colors.gray11};
  }
`;

export const StWrapper = styled.div`
  margin-top: 3rem;
  background-color: ${colors.gray1};
  padding: 4rem;
`;

export const StButton = styled(Button)`
  &&& {
    width: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 12px;
  }
`;
