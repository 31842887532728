import { put } from 'redux-saga/effects';

import errorHandler from 'helpers/errorHandler';

import {
  updateScreenToScreenDateError,
  updateScreenToScreenDateSuccess,
} from 'store/ducks/updateScreenToScreenDate/actions';

export function* updateScreenToScreenDateSaga(action) {
  try {
    const { payload } = action;
    yield put(updateScreenToScreenDateSuccess(payload));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        updateScreenToScreenDateError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(updateScreenToScreenDateError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}
