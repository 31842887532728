import styled from 'styled-components';
import { colors } from 'styles/colors';

export const StContainer = styled.div`
  &&& {
    height: ${({ height }) => height && `${height}px`};
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.04);
    background-color: ${colors.gray1};
    border-radius: 4px;
    cursor: default;
    ${({ hasFooter }) =>
      !hasFooter &&
      `
      padding: 16px 16px 16px 16px;
      `}
    ${({ reconciliationType }) =>
      reconciliationType === 'payments' &&
      `
      padding: 0px 0px 16px 0px;
      `}
    ${({ height, enforceMaxHeight }) =>
      enforceMaxHeight &&
      `
        max-height: ${height}px;
      `}
  }
`;
