import { lambdaApi } from '../api';

const transformer = ({ data: { acquirers } }) => {
  let formattedObject = {};

  acquirers.forEach((acquirer) => {
    formattedObject = {
      ...formattedObject,
      [acquirer[0]]: acquirer[1],
    };
  });
  const _data = {
    acquirers: formattedObject,
  };
  return _data;
};

const getAcquirersByClientIdFromAPI = async ({ clientId, reconType }) => {
  const reconTypeTransform = {
    Vendas: 'sale',
    Pagamentos: 'payment',
  };

  const config = {
    params: {
      client_id: clientId !== '' ? clientId : undefined,
      recon_type: reconTypeTransform[reconType],
    },
  };

  const response = await lambdaApi().get(
    '/conciliation/rules/client_acquirers',
    config
  );
  return transformer(response.data);
};

export default getAcquirersByClientIdFromAPI;
