/* eslint-disable no-else-return */
import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Grid,
  Input,
  Spin,
  Empty,
} from 'antd';
import {
  StOffersTitle,
  StOffersTitleWrapper,
  StContainer,
  StShowCase,
} from './styled';
import { SideMenu } from './components/SideMenu';
import { PartnerShowCase } from './components/PartnerShowcase';
import { PartnerOffers } from './components/PartnerOffers';
import { useDispatch, useSelector } from 'react-redux';
import { creditListPartnersAvailableStart } from 'store/ducks/credit/creditListPartnersAvailable/actions';
import { getSessionClientId } from 'helpers/sessionService';
import { partnerTypes } from 'constants/offers';
import { creditListStart } from 'store/ducks/credit/creditList/actions';
import {
  isAdmin,
  isConciler,
  isSuperAdmin,
} from 'helpers/authorizationService';
import { colors } from 'styles/colors';

const Offers = () => {
  const { useBreakpoint } = Grid;
  const dispatch = useDispatch();
  const breakpoint = useBreakpoint();
  const { Search } = Input;
  const [searchedValue, setSearchedValue] = useState('');
  const availablePartners = useSelector(
    (state) => state.creditListPartnersAvailable.data
  );
  const availablePartnersLoading = useSelector(
    (state) => state.creditListPartnersAvailable.loading
  );
  const availableOffers = useSelector((state) => state.creditList.data);
  const availableOffersLoading = useSelector(
    (state) => state.creditList.loading
  );
  const [onlyActiveOffers, setOnlyActiveOffers] = useState(true);
  const [filteredOffers, setFilteredOffers] = useState({
    Todos: true,
  });
  const [filteredPartners, setFilteredPartners] = useState({
    Todos: true,
  });
  const [hasTomaticoOffer, setHasTomaticoOffer] = useState(false);

  const TOMATICO_ID = 2;

  useEffect(() => {
    dispatch(creditListPartnersAvailableStart());
    dispatch(
      creditListStart({
        clientId: getSessionClientId(),
        page: 1,
        perPage: 100,
      })
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    availableOffers.forEach((offer) => {
      if (offer?.creditPartner?.id === TOMATICO_ID) {
        setHasTomaticoOffer(true);
      }
    });
  }, [availableOffers]);

  useEffect(() => {
    if (availablePartners?.length > 0) {
      const offers = {};
      const partners = {};
      availablePartners.forEach((partner) => {
        offers[partnerTypes[partner.type]] = true;
        partners[partner.name] = true;
      });
      setFilteredOffers({
        ...filteredOffers,
        ...offers,
      });
      setFilteredPartners({
        ...filteredPartners,
        ...partners,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availablePartners]);

  const interpretStatus = (status) => {
    let convertedStatus = 'DESCONHECIDO';
    switch (status) {
    case 'NEW':
    case 'OPTIN':
      convertedStatus = 'ELEGÍVEL';
      break;
    case 'PREREGISTER':
      convertedStatus = 'PRÉ CADASTRO';
      break;
    case 'PREREGISTERED':
      convertedStatus = 'PRÉ CADASTRADO';
      break;
    case 'REGISTER':
    case 'REGISTERING':
      convertedStatus = 'REGISTRANDO';
      break;
    case 'REGISTERED':
      convertedStatus = 'REGISTRADO';
      break;
    case 'PENDING_APPROVAL':
    case 'PENDING_FLUX':
      convertedStatus = 'PRÉ-ANÁLISE';
      break;
    case 'APPROVED':
      convertedStatus = 'PRÉ-APROVADO';
      break;
    case 'NOT_APPROVED':
      convertedStatus = 'NÃO APROVADO';
      break;
    case 'CONTRACTED':
      convertedStatus = 'CONTRATADO';
      break;
    default:
      convertedStatus = 'DESCONHECIDO';
      break;
    }
    return convertedStatus;
  };

  const renderAvailableOffers = () => {
    if (availableOffers?.length > 0 && hasTomaticoOffer === true) {
      return availableOffers?.map((offer) => {
        const normalizedPartnerName = offer?.creditPartner?.name
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase();
        const normalizedSearchedValue = searchedValue
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase();
        if (
          filteredOffers[partnerTypes[offer?.creditPartner?.type]] === true &&
          filteredPartners[offer?.creditPartner?.name] === true &&
          offer?.creditPartner?.active === onlyActiveOffers &&
          normalizedPartnerName.includes(normalizedSearchedValue) &&
          !normalizedPartnerName.includes('btg')
        ) {
          return (
            <PartnerOffers
              offerData={offer}
              interpretStatus={interpretStatus}
              hasTomaticoOffer={hasTomaticoOffer}
            />
          );
        }
        return <></>;
      });
    } else {
      return (
        <div
          style={{
            width: '550px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0 auto',
          }}
        >
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Você ainda não possui ofertas disponíveis. Este parceiro exige alguns requisitos: histórico de faturamento de pelo menos 6 meses, R$10.000/mês de recebimento líquido nas adquirentes BIN, REDE, GETNET, CIELO ou STONE cadastradas na Concil."
          />
        </div>
      );
    }
  };

  return (
    <StOffersTitleWrapper $breakpoint={breakpoint}>
      {(isAdmin() || isSuperAdmin()) && isConciler() && (
        <>
          <Row
            align="middle"
            justify="space-between"
            style={{ marginTop: '4rem' }}
          >
            <StOffersTitle>
              <span>Banking</span>
            </StOffersTitle>
          </Row>
          <Row
            align="middle"
            justify="space-between"
            style={{ margin: '8px 0px', color: colors.gray8 }}
          >
            Através de parceiros, o Concil Banking concentra diversos serviços
            financeiros, disponibilizando as melhores condições e soluções
            financeiras para você e seu negócio. Encontre aqui ofertas
            personalizadas para impulsionar o seu negócio.
          </Row>
          <StContainer>
            <Row gutter={[16, 0]}>
              <Col span={4}>
                <SideMenu
                  availablePartners={availablePartners}
                  availablePartnersLoading={availablePartnersLoading}
                  onlyActiveOffers={onlyActiveOffers}
                  setOnlyActiveOffers={setOnlyActiveOffers}
                  filteredOffers={filteredOffers}
                  setFilteredOffers={setFilteredOffers}
                  filteredPartners={filteredPartners}
                  setFilteredPartners={setFilteredPartners}
                />
              </Col>
              <Col span={20}>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <PartnerShowCase
                      onlyActiveOffers={onlyActiveOffers}
                      availablePartners={availablePartners}
                      availablePartnersLoading={availablePartnersLoading}
                      filteredOffers={filteredOffers}
                      filteredPartners={filteredPartners}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <StShowCase
                      title="Produtos"
                      headStyle={{ fontSize: '20px' }}
                      extra={
                        <Search
                          placeholder="Pesquisa"
                          onSearch={(value) => {
                            setSearchedValue(value);
                          }}
                          style={{ width: 385 }}
                        />
                      }
                    >
                      {availableOffersLoading ? (
                        <Spin />
                      ) : (
                        renderAvailableOffers()
                      )}
                    </StShowCase>
                  </Col>
                </Row>
              </Col>
            </Row>
          </StContainer>
        </>
      )}
    </StOffersTitleWrapper>
  );
};

export default Offers;
