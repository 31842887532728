import { call, put } from 'redux-saga/effects';
import { validateAccess } from 'services/auth';
import errorHandler from 'helpers/errorHandler';
import { validateAccessSuccess, validateAccessFailure } from './actions';

export function* validateAccessSaga({ payload }) {
  const { route } = payload;
  try {
    const validateAccessData = yield call(validateAccess, route);
    yield put(validateAccessSuccess(validateAccessData));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        validateAccessFailure(err.response.data.error, err.response.data.error)
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else if (err?.response?.data?.error) {
      yield put(
        validateAccessFailure(err.response.data.error, err.response.data.error)
      );
      errorHandler('Ocorreu um erro:', err);
    }
  }
}
