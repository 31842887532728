import styled from 'styled-components';
import { Typography } from 'antd';
import ResizableAntdTable from 'resizable-antd-table';
import { colors } from 'styles/colors';

const { Text } = Typography;

export const StTableColumnTitle = styled(Text)`
  font-size: ${({ fontSize }) => fontSize || '2rem'};
  font-weight: ${({ fontWeight }) => fontWeight || 700};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  text-align: left;
  color: ${colors.gray11};
`;

export const StContainer = styled.div`
  margin-top: 3rem;
`;

export const StTable = styled(ResizableAntdTable)`
  &&& {
    .ant-pagination-options::before {
      content: 'Itens por página';
      margin-right: 10px;
    }

    .ant-table-thead .ant-table-cell {
      background-color: ${colors.gray2} !important;
    }
    .ant-table-column-sorters {
      display: inline-flex;
      padding: 0 32px;
    }
    .ant-select-selector {
      background: ${({ disableSizeChanger }) =>
        disableSizeChanger ? 'rgba(255, 255, 255, 0.08)' : colors.gray1};
      color: ${({ disableSizeChanger }) =>
        disableSizeChanger ? '#00000040' : colors.gray11};
      cursor: ${({ disableSizeChanger }) =>
        disableSizeChanger ? 'not-allowed' : 'pointer'};
    }
    .ant-select-dropdown {
      display: ${({ disableSizeChanger }) => disableSizeChanger && 'none'};
    }
    tbody {
      tr {
        td {
        }
      }
      .ant-table-cell {
        padding: 5px 32px !important;
        margin: 0px !important;
      }
    }
  }
`;
