import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { updateScreenToScreenDateStart } from 'store/ducks/updateScreenToScreenDate/actions';

export const useRangePickerChange = () => {
  const dispatch = useDispatch();

  const onRangePickerChange = (dates, calendarIsOpen) => {
    if (dates?.length) {
      if (
        calendarIsOpen &&
        (!dates[1] || dates[1]?.diff(dates[0], 'days') > 31)
      ) {
        dispatch(
          updateScreenToScreenDateStart({
            startDate: dates[0],
            endDate: dayjs(dates[0]).add(31, 'days'),
          })
        );
      } else if (
        calendarIsOpen &&
        (!dates[1] || dates[1]?.diff(dates[0], 'days') <= 31)
      ) {
        dispatch(
          updateScreenToScreenDateStart({
            startDate: dates[0],
            endDate: dates[1],
          })
        );
      }
    } else {
      dispatch(
        updateScreenToScreenDateStart({
          startDate: dayjs().subtract(32, 'days'),
          endDate: dayjs().subtract(1, 'day'),
        })
      );
    }
  };

  return {
    onRangePickerChange,
  };
};
