import { actionTypes } from './types';

const INITIAL_STATE = {
  loading: false,
  isError: false,
  data: undefined,
  cnpjStatus: '',
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case actionTypes.UPDATE_CNPJ_STATUS:
    return {
      ...state,
      cnpjStatus: action.payload.cnpjStatus,
    };
  case actionTypes.GET_CNPJ_START:
    return {
      ...state,
      loading: true,
      isError: false,
      cnpjStatus: '',
    };
  case actionTypes.GET_CNPJ_ERROR:
    return {
      ...state,
      loading: false,
      isError: true,
      cnpjStatus: action.payload.status,
    };
  case actionTypes.GET_CNPJ_SUCCESS:
    return {
      ...state,
      loading: false,
      data: action.payload,
      cnpjStatus: action.payload.status,
    };
  case actionTypes.RESET_CNPJ_DATA:
    return {
      ...state,
      loading: false,
      data: undefined,
    };
  default:
    return state;
  }
};

export default reducer;
