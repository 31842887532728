import { lambdaApi } from '../api';

const transformer = (apiResponse) => {
  return {
    merchants: apiResponse.data,
    meta: apiResponse.meta,
    acquirer_id: apiResponse.meta.acquirer_id,
  };
};
const listMerchantByAcquirer = async ({
  acquirerId,
  merchantCode,
  page,
  perPage,
}) => {
  const config = {
    params: {
      acquirer_id: acquirerId,
      merchant_code: merchantCode,
      page,
      per_page: perPage,
    },
  };
  const response = await lambdaApi().get(
    '/settings/maintenance/merchants/detail',
    config
  );
  return transformer(response.data);
};
export default listMerchantByAcquirer;
