import { Auth } from 'aws-amplify';
import errorHandler from 'helpers/errorHandler';
import {
  api,
  apiGateway,
  getAuthBaseUrl,
  lambdaApi,
  openAcquirerApi,
} from './api';

const transformSignIn = (authData, userData, permissionsData) => {
  const transformedData = {
    accessToken: authData?.signInUserSession?.accessToken?.jwtToken,
    username: authData?.attributes?.email,
    clientName: userData?.client_name,
    clientId: userData?.client_id,
    clientCode: userData?.client_code,
    isImplanted: userData?.client_is_implanted,
    userFirstName: userData?.user_first_name,
    userSurname: userData?.user_surname,
    authData: authData,
    usernameId: authData?.username,
    clientPlanId: permissionsData?.data?.plan_id,
    clientSkinId: permissionsData?.data?.skin_id,
    clientCreatedAt: permissionsData?.data?.created_at,
    userProfileId: permissionsData?.data?.profile_id,
    userScopeId: permissionsData?.data?.scope_id,
  };
  return transformedData;
};

const transformCompleteNewPassword = (authData) => {
  const transformedData = {
    accessToken: authData?.signInUserSession?.accessToken?.jwtToken,
    username: authData?.signInUserSession?.idToken?.payload?.email,
    authData: authData,
  };
  return transformedData;
};

const transformPermissions = (permissions) => {
  const transformedData = {
    code: permissions?.code,
    ...permissions?.data,
  };
  return transformedData;
};

const transformValidationResponse = (validationResponse) => {
  return validationResponse;
};

const authSignIn = async (data) => {
  const credentials = {
    username: data.email,
    password: data.password,
  };

  const authData = await Auth.signIn(
    credentials?.username,
    credentials?.password
  )
    .then((signInResponse) => {
      return signInResponse;
    })
    .catch((err) => {
      const errorMessage = err?.message || '';
      if (
        errorMessage.includes('username') &&
        errorMessage.includes('password')
      ) {
        errorHandler('Usuário ou senha incorretos, tente novamente');
        return err;
      }
      errorHandler(err);
      return err;
    });
  const accessToken = authData?.signInUserSession?.accessToken?.jwtToken;
  if (accessToken) {
    const userData = await apiGateway(accessToken)
      .post('/default-client/post')
      .then((response) => {
        return response;
      })
      .catch((err) => {
        errorHandler(
          'Erro ao buscar dados do usuário, houve uma falha no processo de autenticação'
        );
        return err;
      });
    return transformSignIn(authData, userData?.data?.data);
  }

  if (
    accessToken === undefined &&
    authData?.challengeName !== 'NEW_PASSWORD_REQUIRED'
  ) {
    return {
      error: 'Ocorreu um erro ao tentar fazer login',
    };
  }

  return transformSignIn(authData);
};

export const authCompleteNewPassword = async ({ authData, password }) => {
  const user = authData;
  const newPassword = password;
  const completeNewPasswordAuthData = await Auth.completeNewPassword(
    user, // the Cognito User Object
    newPassword // the new password
  )
    .then((completeNewPasswordResponse) => {
      // at this time the user is logged in if no MFA required
      return completeNewPasswordResponse;
    })
    .catch((err) => {
      errorHandler(err);
      return err;
    });

  const accessToken =
    completeNewPasswordAuthData?.signInUserSession?.accessToken?.jwtToken;
  if (accessToken) {
    await openAcquirerApi(accessToken)
      .post('/user/notify-password', {
        email:
          completeNewPasswordAuthData?.signInUserSession?.idToken?.payload
            ?.email,
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err;
      });

    return transformCompleteNewPassword(completeNewPasswordAuthData);
  }
  return transformCompleteNewPassword(completeNewPasswordAuthData);
};

export const getPermissions = async () => {
  const permissions = await apiGateway().post('/profile/post');
  return transformPermissions(permissions?.data);
};

export const validateAccess = async (route) => {
  // Work in progress, not yet implemented on the backend.
  const validationResponse = await apiGateway().post(
    `/validate/access/${route}`
  );
  return transformValidationResponse(validationResponse?.data);
};

export const validateTokenFromAPI = async () => {
  // Legacy token validation code
  const authBaseUrl = getAuthBaseUrl();
  const response = await api().get(`${authBaseUrl}/validate/token`);
  const transformedData = transformSignIn(response.data);
  return transformedData;
};

export const authLoginSource = async (source) => {
  const config = {
    params: {
      login_source: source,
    },
  };

  const {
    defaults: {
      headers: { Authorization },
    },
  } = lambdaApi();

  if (Authorization !== 'Bearer undefined') {
    const response = await lambdaApi().get('post/authentication', config);
    return response?.data;
  }

  return true;
};

export default authSignIn;
