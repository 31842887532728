import React from 'react';
import {
  onColumnFilter,
  columnFilters,
  columnSorter,
  getDefaultColumns,
} from 'helpers/generalService';
import { getDefaultColumnWidth } from 'helpers/tableService';
import store from 'store';
import {
  reconciledBankDetailsFiltersStart,
  reconciledBankDetailsFiltersZeroLength,
} from 'store/ducks/filters/details/reconciledBankDetailsFilters/actions';
import FilterDropdown from 'commons/Table/components/FilterDropdown';
import { clearFiltersFunctions } from 'helpers/clearFiltersFunctions';

let filteredColumns = store.getState().updateFilteredColumns?.filteredColumns;

const columns = (apiFilters, toParamsFilters) => [
  // Adquirente
  {
    title: 'Adquirente',
    dataIndex: 'acquirer_name',
    filteredValue: getDefaultColumns(toParamsFilters, 'acquirer_name'),
    filters:
      apiFilters.length > 0 ? columnFilters(apiFilters, 'acquirer_name') : [],
    onFilter: (value, record) => onColumnFilter(value, record, 'acquirer_name'),
    onFilterDropdownVisibleChange: (visible) => {
      filteredColumns = store.getState().updateFilteredColumns?.filteredColumns;
      if (visible) {
        // This "zero length" dispatch is utterly important,
        // in order to keep each column state independent (Not passing data from one column to the next)
        store.dispatch(reconciledBankDetailsFiltersZeroLength());
        store.dispatch(
          reconciledBankDetailsFiltersStart({
            startDate: store.getState().updateScreenToScreenDate?.startDate,
            endDate: store.getState().updateScreenToScreenDate?.endDate,
            columnName: 'acquirer_name',
            offset: 0,
            q:
              filteredColumns.length !== 0
                ? {
                    filter: filteredColumns,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.acquirerName = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <FilterDropdown
          key={filteredColumns}
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={reconciledBankDetailsFiltersStart}
          clearAction={reconciledBankDetailsFiltersZeroLength}
          columnName="acquirer_name"
          storeName="reconciledBankDetailsFilters"
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: getDefaultColumnWidth('acquirer_name'),
    align: 'left',
  },
  // Data do Pagamento
  {
    title: 'Data do Pagamento',
    dataIndex: 'transaction_date',
    filteredValue: getDefaultColumns(toParamsFilters, 'transaction_date'),
    filters: apiFilters
      ? columnFilters(apiFilters, 'transaction_date')
      : undefined,
    onFilter: (value, record) =>
      onColumnFilter(value, record, 'transaction_date'),
    onFilterDropdownVisibleChange: (visible) => {
      filteredColumns = store.getState().updateFilteredColumns?.filteredColumns;
      if (visible) {
        store.dispatch(reconciledBankDetailsFiltersZeroLength());
        store.dispatch(
          reconciledBankDetailsFiltersStart({
            startDate: store.getState().updateScreenToScreenDate?.startDate,
            endDate: store.getState().updateScreenToScreenDate?.endDate,
            columnName: 'transaction_date',
            offset: 0,
            q:
              filteredColumns.length !== 0
                ? {
                    filter: filteredColumns,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.transactionDate = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <FilterDropdown
          key={filteredColumns}
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={reconciledBankDetailsFiltersStart}
          clearAction={reconciledBankDetailsFiltersZeroLength}
          columnName="transaction_date"
          storeName="reconciledBankDetailsFilters"
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: getDefaultColumnWidth('transaction_date'),
    align: 'left',
  },
  // Origem
  {
    title: 'Origem',
    dataIndex: 'source',
    filteredValue: getDefaultColumns(toParamsFilters, 'source'),
    filters: apiFilters ? columnFilters(apiFilters, 'source') : undefined,
    onFilter: (value, record) => onColumnFilter(value, record, 'source'),
    onFilterDropdownVisibleChange: (visible) => {
      filteredColumns = store.getState().updateFilteredColumns?.filteredColumns;
      if (visible) {
        store.dispatch(reconciledBankDetailsFiltersZeroLength());
        store.dispatch(
          reconciledBankDetailsFiltersStart({
            startDate: store.getState().updateScreenToScreenDate?.startDate,
            endDate: store.getState().updateScreenToScreenDate?.endDate,
            columnName: 'source',
            offset: 0,
            q:
              filteredColumns.length !== 0
                ? {
                    filter: filteredColumns,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.source = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <FilterDropdown
          key={filteredColumns}
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={reconciledBankDetailsFiltersStart}
          clearAction={reconciledBankDetailsFiltersZeroLength}
          columnName="source"
          storeName="reconciledBankDetailsFilters"
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: getDefaultColumnWidth('source'),
    align: 'left',
  },
  // Valor liquido previsto
  {
    title: 'Valor liquido previsto (R$)',
    dataIndex: 'forecast_net_amount',
    filteredValue: getDefaultColumns(toParamsFilters, 'forecast_net_amount'),
    filters: apiFilters
      ? columnFilters(apiFilters, 'forecast_net_amount')
      : undefined,
    onFilter: (value, record) =>
      onColumnFilter(value, record, 'forecast_net_amount'),
    onFilterDropdownVisibleChange: (visible) => {
      filteredColumns = store.getState().updateFilteredColumns?.filteredColumns;
      if (visible) {
        store.dispatch(reconciledBankDetailsFiltersZeroLength());
        store.dispatch(
          reconciledBankDetailsFiltersStart({
            startDate: store.getState().updateScreenToScreenDate?.startDate,
            endDate: store.getState().updateScreenToScreenDate?.endDate,
            columnName: 'forecast_net_amount',
            offset: 0,
            q:
              filteredColumns.length !== 0
                ? {
                    filter: filteredColumns,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.forecastNetAmount = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <FilterDropdown
          key={filteredColumns}
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={reconciledBankDetailsFiltersStart}
          clearAction={reconciledBankDetailsFiltersZeroLength}
          columnName="forecast_net_amount"
          storeName="reconciledBankDetailsFilters"
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: getDefaultColumnWidth('forecast_net_amount'),
    align: 'left',
  },
  // Valor liquido pago
  {
    title: 'Valor liquido pago (R$)',
    dataIndex: 'payment_net_amount',
    filteredValue: getDefaultColumns(toParamsFilters, 'payment_net_amount'),
    filters: apiFilters
      ? columnFilters(apiFilters, 'payment_net_amount')
      : undefined,
    onFilter: (value, record) =>
      onColumnFilter(value, record, 'payment_net_amount'),
    onFilterDropdownVisibleChange: (visible) => {
      filteredColumns = store.getState().updateFilteredColumns?.filteredColumns;
      if (visible) {
        store.dispatch(reconciledBankDetailsFiltersZeroLength());
        store.dispatch(
          reconciledBankDetailsFiltersStart({
            startDate: store.getState().updateScreenToScreenDate?.startDate,
            endDate: store.getState().updateScreenToScreenDate?.endDate,
            columnName: 'payment_net_amount',
            offset: 0,
            q:
              filteredColumns.length !== 0
                ? {
                    filter: filteredColumns,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.paymentNetAmount = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <FilterDropdown
          key={filteredColumns}
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={reconciledBankDetailsFiltersStart}
          clearAction={reconciledBankDetailsFiltersZeroLength}
          columnName="payment_net_amount"
          storeName="reconciledBankDetailsFilters"
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: getDefaultColumnWidth('payment_net_amount'),
    align: 'left',
  },
  // Banco
  {
    title: 'Banco',
    dataIndex: 'bank_code',
    filteredValue: getDefaultColumns(toParamsFilters, 'bank_code'),
    filters: apiFilters ? columnFilters(apiFilters, 'bank_code') : undefined,
    onFilter: (value, record) => onColumnFilter(value, record, 'bank_code'),
    onFilterDropdownVisibleChange: (visible) => {
      filteredColumns = store.getState().updateFilteredColumns?.filteredColumns;

      if (visible) {
        store.dispatch(reconciledBankDetailsFiltersZeroLength());
        store.dispatch(
          reconciledBankDetailsFiltersStart({
            startDate: store.getState().updateScreenToScreenDate?.startDate,
            endDate: store.getState().updateScreenToScreenDate?.endDate,
            columnName: 'bank_code',
            offset: 0,
            q:
              filteredColumns.length !== 0
                ? {
                    filter: filteredColumns,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.bankCode = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <FilterDropdown
          key={filteredColumns}
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={reconciledBankDetailsFiltersStart}
          filteredColumns={
            store.getState().updateFilteredColumns?.filteredColumns
          }
          clearAction={reconciledBankDetailsFiltersZeroLength}
          columnName="bank_code"
          storeName="reconciledBankDetailsFilters"
        />
      );
    },
    filterMultiple: true,
    sorter: (a, b) => columnSorter(a, b, 'bank_code'),
    width: getDefaultColumnWidth('bank_code'),
    align: 'left',
  },
  // Agência
  {
    title: 'Agência',
    dataIndex: 'bank_branch',
    filteredValue: getDefaultColumns(toParamsFilters, 'bank_branch'),
    filters: apiFilters ? columnFilters(apiFilters, 'bank_branch') : undefined,
    onFilter: (value, record) => onColumnFilter(value, record, 'bank_branch'),
    onFilterDropdownVisibleChange: (visible) => {
      filteredColumns = store.getState().updateFilteredColumns?.filteredColumns;
      if (visible) {
        store.dispatch(reconciledBankDetailsFiltersZeroLength());
        store.dispatch(
          reconciledBankDetailsFiltersStart({
            startDate: store.getState().updateScreenToScreenDate?.startDate,
            endDate: store.getState().updateScreenToScreenDate?.endDate,
            columnName: 'bank_branch',
            offset: 0,
            q:
              filteredColumns.length !== 0
                ? {
                    filter: filteredColumns,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.bankBranch = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <FilterDropdown
          key={filteredColumns}
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={reconciledBankDetailsFiltersStart}
          filteredColumns={
            store.getState().updateFilteredColumns?.filteredColumns
          }
          clearAction={reconciledBankDetailsFiltersZeroLength}
          columnName="bank_branch"
          storeName="reconciledBankDetailsFilters"
        />
      );
    },
    filterMultiple: true,
    sorter: (a, b) => columnSorter(a, b, 'bank_branch'),
    width: getDefaultColumnWidth('bank_branch'),
    align: 'left',
  },
  // Conta
  {
    title: 'Conta',
    dataIndex: 'bank_account',
    filteredValue: getDefaultColumns(toParamsFilters, 'bank_account'),
    filters: apiFilters ? columnFilters(apiFilters, 'bank_account') : undefined,
    onFilter: (value, record) => onColumnFilter(value, record, 'bank_account'),
    onFilterDropdownVisibleChange: (visible) => {
      filteredColumns = store.getState().updateFilteredColumns?.filteredColumns;

      if (visible) {
        store.dispatch(reconciledBankDetailsFiltersZeroLength());
        store.dispatch(
          reconciledBankDetailsFiltersStart({
            startDate: store.getState().updateScreenToScreenDate?.startDate,
            endDate: store.getState().updateScreenToScreenDate?.endDate,
            columnName: 'bank_account',
            offset: 0,
            q:
              filteredColumns.length !== 0
                ? {
                    filter: filteredColumns,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.bankAccount = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <FilterDropdown
          key={filteredColumns}
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={reconciledBankDetailsFiltersStart}
          filteredColumns={
            store.getState().updateFilteredColumns?.filteredColumns
          }
          clearAction={reconciledBankDetailsFiltersZeroLength}
          columnName="bank_account"
          storeName="reconciledBankDetailsFilters"
        />
      );
    },
    filterMultiple: true,
    sorter: (a, b) => columnSorter(a, b, 'bank_account'),
    width: getDefaultColumnWidth('bank_account'),
    align: 'left',
  },
];

export default columns;
