import { api } from '../api';

const transformer = (apiResponse) => {
  // apiResponse?.meta?.acquirer
  return {
    data: {
      [apiResponse?.meta?.acquirer]: apiResponse.data,
    },
  };
};

export const getOptinSchedulerInfo = async ({
  page,
  perPage,
  clientId,
  acquirer,
}) => {
  const config = {
    params: {
      page,
      perPage,
      clientId,
    },
  };
  const response = await api().get(`/integrations/optin/${acquirer}`, config);
  return transformer(response.data);
};
