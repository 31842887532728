import { actionTypes } from 'store/ducks/cnpjMaintenance/updateStore/types';

const INITIAL_STATE = {
  error: undefined,
  errorMessage: undefined,
  loading: false,
  isError: false,
  success: false,
};
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case actionTypes.UPDATE_STORE_START:
    return {
      ...state,
      loading: true,
      error: undefined,
      errorMessage: undefined,
      success: false,
      isError: false,
    };
  case actionTypes.UPDATE_STORE_SUCCESS:
    return {
      ...state,
      loading: false,
      error: undefined,
      isError: false,
      success: true,
      errorMessage: undefined,
    };
  case actionTypes.UPDATE_STORE_ERROR:
    return {
      ...state,
      loading: false,
      error: action.error.error,
      errorMessage: action.payload,
      isError: true,
      success: false,
    };
  default:
    return state;
  }
};
export default reducer;
