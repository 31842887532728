import { notification } from 'antd';
import customHistory from 'helpers/history';
import { removeLocalStorage } from './sessionService';
import { dataTestIdHandler } from './dataTestIdHandler';

let serverErrorCounter = 0;
let notAuthorizedCounter = 0;

const dataTestPrefix = 'error';

const errorNotification = (message) =>
  notification.error({
    message,
    props: dataTestIdHandler(dataTestPrefix, 'notification'),
  });

const handleServerError = (error) => {
  if (error?.response?.status === 500) {
    serverErrorCounter += 1;
  } else {
    serverErrorCounter = 0;
  }
};

const handleRequestNotAuthorized = (error) => {
  const errorMessage = error?.response?.data?.message || '';
  if (
    errorMessage.includes('not authorized') ||
    errorMessage.includes('deny')
  ) {
    notAuthorizedCounter += 1;
    removeLocalStorage();
    customHistory.push('/');
  } else {
    notAuthorizedCounter = 0;
  }
};

const handleApiErrorStructure = (err) => {
  // Non standard Lambda structure
  if (err?.response?.data?.message) return err?.response?.data?.message;
  // Standard Lambda structure
  if (err?.response?.data?.data?.detail) {
    return err?.response?.data?.data?.detail;
  }
  // Flask structure
  if (err?.response?.data?.error?.message) {
    return err?.response?.data?.error?.message;
  }
  // Default message
  return 'Erro na conexão com o servidor, por favor, atualize a página';
};

const errorHandler = (errorMessage, err) => {
  handleServerError(err);
  handleRequestNotAuthorized(err);

  if (serverErrorCounter === 1) {
    return errorNotification(
      'Ocorreu um erro na conexão com o servidor, por favor, atualize a página'
    );
  }

  if (notAuthorizedCounter === 1) {
    return errorNotification(
      'Sua sessão expirou, por favor, entre novamente com seu login e senha'
    );
  }

  if (notAuthorizedCounter > 0 || serverErrorCounter > 0) return null;

  if (errorMessage === 'Ocorreu um erro:') {
    return notification.error({
      message: 'Ocorreu um erro:',
      description: handleApiErrorStructure(err),
    });
  }

  return errorMessage && errorNotification(String(errorMessage));
};

export default errorHandler;
