import styled from 'styled-components';

import { List, Typography } from 'antd';

const { Item } = List;

export const StGeneralReportsTitle = styled(Typography.Title)`
  &&& {
    font-size: 20px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
  }
`;

export const StColumnFilterDropdownSpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  position: absolute;
  bottom: 20px;
  width: 100%;
`;

export const StList = styled(List)`
  &&& {
    position: relative;
  }
`;

export const StListItem = styled(Item)`
  &&& {
    margin-bottom: ${({ $extraMarginBottom }) =>
      $extraMarginBottom ? '51px' : null};
  }
`;
