import { actionTypes } from 'store/ducks/cnpjMaintenance/listStores/types';

const INITIAL_STATE = {
  error: undefined,
  errorMessage: undefined,
  loading: false,
  isError: false,
  success: false,
  stores: [],
  meta: {
    page: 1,
    per_page: 10,
    total: 0,
    total_pages: 1,
    has_next: true,
  },
};
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.LIST_STORES_START:
      return {
        ...state,
        loading: true,
        isError: false,
        error: undefined,
        errorMessage: undefined,
        success: false,
      };
    case actionTypes.LIST_STORES_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
        error: undefined,
        errorMessage: undefined,
        success: true,
        stores: action.payload.stores,
        meta: action.payload.meta,
      };
    case actionTypes.LIST_STORES_ERROR:
      return {
        ...state,
        loading: false,
        isError: true,
        error: action.error.error,
        errorMessage: action.payload,
        success: false,
      };
    default:
      return state;
  }
};
export default reducer;
