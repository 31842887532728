export const actionTypes = {
  GET_BRIEFING_START: '@briefing/GET_BRIEFING_START',
  GET_BRIEFING_SUCCESS: '@briefing/GET_BRIEFING_SUCCESS',
  GET_BRIEFING_ERROR: '@briefing/GET_BRIEFING_ERROR',
  POST_BRIEFING_START: '@briefing/POST_BRIEFING_START',
  POST_BRIEFING_SUCCESS: '@briefing/POST_BRIEFING_SUCCESS',
  POST_BRIEFING_ERROR: '@briefing/POST_BRIEFING_ERROR',
  PUT_BRIEFING_START: '@briefing/PUT_BRIEFING_START',
  PUT_BRIEFING_SUCCESS: '@briefing/PUT_BRIEFING_SUCCESS',
  PUT_BRIEFING_ERROR: '@briefing/PUT_BRIEFING_ERROR',
};
