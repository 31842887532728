import { call, put } from 'redux-saga/effects';

import errorHandler from 'helpers/errorHandler';

import {
  deleteFees,
  listFeesVencida,
  listFeesVigente,
  putFees,
} from 'services/listFees';
import { notification } from 'antd';
import {
  listFeesVencidasSuccess,
  listFeesVencidasError,
  listFeesVigenteError,
  listFeesVigenteSuccess,
  putFeesSuccess,
  putFeesError,
  deleteFeesSuccess,
  deleteFeesError,
} from './actions';

export function* listFeesVencidaSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(listFeesVencida, payload);
    yield put(listFeesVencidasSuccess(apiData));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        listFeesVencidasError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(listFeesVencidasError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}
export function* listFeesVigenteSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(listFeesVigente, payload);
    yield put(listFeesVigenteSuccess(apiData));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        listFeesVigenteError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(listFeesVigenteError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}

export function* putFeesSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(putFees, payload);
    yield put(putFeesSuccess(apiData));
    notification.success({ message: 'Taxa atualizada com sucesso !' });
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        putFeesError(err.response.data.error.message, err.response.data.error)
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(putFeesError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}

export function* deleteFeesSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(deleteFees, payload);
    yield put(deleteFeesSuccess(apiData));
    notification.success({ message: 'Taxa excluída com sucesso !' });
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        deleteFeesError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(deleteFeesError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}
