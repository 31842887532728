import { apiGateway } from './api';
import errorHandler from 'helpers/errorHandler';
import successHandler from 'helpers/successHandler';

const updateUserScopeProfile = async (payload) => {
  const config = {
    email: payload.email,
    name: payload.name,
    surname: payload.surname,
    profile_id: payload.profile_id,
    scope_id: payload.scope_id,
    phone: payload.phone,
  };

  const permissionResponse = await apiGateway()
    .post('/permission/post', config)
    .then((response) => {
      successHandler('Dados atualizados com sucesso');
      return response;
    })
    .catch((error) => {
      return errorHandler('Erro ao atualizar os dados', error);
    });
  return permissionResponse;
};

export default updateUserScopeProfile;
