import { action } from 'typesafe-actions';
import { actionTypes } from './types';

export const reconciliationRulesDetailsFiltersStart = (data) =>
  action(actionTypes.RECONCILIATION_RULES_DETAILS_FILTERS_START, data);
export const reconciliationRulesDetailsFiltersSuccess = (data) =>
  action(actionTypes.RECONCILIATION_RULES_DETAILS_FILTERS_SUCCESS, data);
export const reconciliationRulesDetailsFiltersError = (errorMessage, error) =>
  action(
    actionTypes.RECONCILIATION_RULES_DETAILS_FILTERS_ERROR,
    { errorMessage },
    undefined,
    { error }
  );
export const reconciliationRulesDetailsFiltersZeroLength = () =>
  action(actionTypes.RECONCILIATION_RULES_DETAILS_FILTERS_ZERO_LENGTH);
export const updateReconciliationRulesDetailsFilters = (data) =>
  action(actionTypes.UPDATE_RECONCILIATION_RULES_DETAILS_FILTERS, data);
