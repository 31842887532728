/* eslint-disable react/no-children-prop */
import React from 'react';
import { Row, Col, Divider } from 'antd';
import PropTypes from 'prop-types';
import customHistory from 'helpers/history';

import { LabelAndValue } from 'commons/LabelAndValue';
import { TitleAndIcon } from 'commons/TitleAndIcon';
import { ErrorCard } from 'commons/ErrorCard';
import {
  StTypographyTextLabel,
  StTypographyTextValue,
} from '../../../../../commons/LabelAndValue/styled';
import { StContainer, StCol } from './styled';
import { StSeeDetailsButton } from 'components/Intermediate/styled';
import { StIntermediateCard } from 'commons/Intermediate/styled';
import { colors } from 'styles/colors';

const SummaryCard = ({ isError, loading, summary }) => {
  const goToReports = () => {
    customHistory.push('/auditoria-pagamentos-relatorios');
  };
  const dataTestPrefix = 'summary-card';

  if (isError) {
    return (
      <Row style={{ marginTop: '2rem' }}>
        <Col lg={10} md={18}>
          <StIntermediateCard loading={loading}>
            <ErrorCard
              title="Dados indisponíveis"
              reason="Não conseguimos buscar os dados, mas já estamos trabalhando para normalizar a situação."
              recommendation="Tente atualizar a página em alguns instantes."
            />
          </StIntermediateCard>
        </Col>
      </Row>
    );
  }
  return (
    <StContainer
      loading={loading}
      actions={[
        <div style={{ textAlign: 'end', margin: 0 }}>
          <StSeeDetailsButton
            type="link"
            size="small"
            fontSize="1.75rem"
            onClick={goToReports}
            data-testid={`${dataTestPrefix}-go-to-reports`}
          >
            Ver relatório
          </StSeeDetailsButton>
        </div>,
      ]}
    >
      <Row>
        <Col>
          <TitleAndIcon
            titleFontSize="2rem"
            titleText="Resumo total"
            dataTestPrefix={dataTestPrefix}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: '2rem' }} align="middle" justify="space-between">
        <StCol $marginRight="36px">
          <LabelAndValue
            label="Pagamentos previstos"
            labelColor="rgba(0,0,0,0.45)"
            value={new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(summary?.forecast_net_amout)}
            valueTextSize="medium"
            strong
            dataTestPrefix={`${dataTestPrefix}-forecast-net-amount`}
          />
        </StCol>
        <StCol $marginRight="22px">
          <LabelAndValue
            label="Pagamentos conciliados"
            labelColor="rgba(0,0,0,0.45)"
            value={new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(summary?.reconciled_net_amout)}
            valueTextSize="medium"
            strong
            dataTestPrefix={`${dataTestPrefix}-reconciled-net-amount`}
          />
        </StCol>
        <StCol>
          <StTypographyTextLabel
            fontSize="1.5rem"
            $labelColor="rgba(0,0,0,0.45)"
            data-testid={`${dataTestPrefix}-net-amount-difference-reconciled-label`}
          >
            Diferença de valor
          </StTypographyTextLabel>
          <br />
          <StTypographyTextValue
            fontSize="16px"
            $valueColor={
              summary?.net_amount_difference_reconciled !== 0
                ? colors.red5
                : colors.gray11
            }
            strong
            data-testid={`${dataTestPrefix}-net-amount-difference-reconciled-value`}
          >
            {new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(summary?.net_amount_difference_reconciled)}
          </StTypographyTextValue>
        </StCol>
        <Divider
          type="vertical"
          style={{ width: '0.125rem', height: '5rem' }}
        />

        <StCol>
          <div style={{ textAlign: 'center' }}>
            <StTypographyTextLabel
              fontSize="1.5rem"
              $labelColor="rgba(0,0,0,0.45)"
              data-testid={`${dataTestPrefix}-late-payments-count-label`}
            >
              Diferença de data
            </StTypographyTextLabel>
            <br />
            <StTypographyTextValue
              fontSize="14px"
              $valueColor={
                summary?.late_payments_count !== 0 ? colors.red5 : colors.gray11
              }
              strong
              data-testid={`${dataTestPrefix}-late-payments-count-value`}
            >
              {summary.late_payments_count}
            </StTypographyTextValue>
          </div>
        </StCol>

        <Divider
          type="vertical"
          style={{ width: '0.125rem', height: '5rem' }}
        />

        <StCol>
          <div style={{ textAlign: 'right' }}>
            <StTypographyTextLabel
              fontSize="1.5rem"
              $labelColor="rgba(0,0,0,0.45)"
              data-testid={`${dataTestPrefix}-pending-net-amount-label`}
            >
              Previsões em aberto
            </StTypographyTextLabel>
            <br />
            <StTypographyTextValue
              fontSize="14px"
              $valueColor={
                summary?.pending_net_amout !== 0 ? colors.red5 : colors.gray11
              }
              strong
              data-testid={`${dataTestPrefix}-pending-net-amount-value`}
            >
              {new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              }).format(summary?.pending_net_amout)}
            </StTypographyTextValue>
          </div>
        </StCol>
      </Row>
    </StContainer>
  );
};

SummaryCard.propTypes = {
  summary: PropTypes.shape({
    net_amount_difference_reconciled: PropTypes.number,
    late_payments_count: PropTypes.number,
    pending_net_amout: PropTypes.number,
    reconciled_net_amout: PropTypes.number,
    forecast_net_amout: PropTypes.number,
  }).isRequired,
  isError: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default SummaryCard;
