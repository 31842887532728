import { actionTypes } from 'store/ducks/updateScreenToScreenPeriod/types';

const INITIAL_STATE = {
  error: undefined,
  errorMessage: undefined,
  loading: true,
  isError: false,
  salesPeriod: 'neste_mes',
  paymentDivergencesPeriod: 'neste_mes',
  amountsReceivablePeriod: 'proximos_18_meses',
  banksPeriod: 'neste_mes',
  duedatePeriod: 'neste_mes',
  advancementsPeriod: 'neste_mes',
  adjustmentsPeriod: 'neste_mes',
  refundsPeriod: 'neste_mes',
  salesReconciliationPeriod: 'neste_mes',
  paymentsReconciliationPeriod: 'neste_mes',
  bankReconciliationPeriod: 'neste_mes',
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_SCREEN_TO_SCREEN_SALES_PERIOD_START:
      return {
        ...state,
        loading: true,
        error: undefined,
        errorMessage: undefined,
        isError: false,
      };
    case actionTypes.UPDATE_SCREEN_TO_SCREEN_PAYMENT_DIVERGENCES_PERIOD_START:
      return {
        ...state,
        loading: true,
        error: undefined,
        errorMessage: undefined,
        isError: false,
      };
    case actionTypes.UPDATE_SCREEN_TO_SCREEN_AMOUNTS_RECEIVABLE_PERIOD_START:
      return {
        ...state,
        loading: true,
        error: undefined,
        errorMessage: undefined,
        isError: false,
      };
    case actionTypes.UPDATE_SCREEN_TO_SCREEN_BANKS_PERIOD_START:
      return {
        ...state,
        loading: true,
        error: undefined,
        errorMessage: undefined,
        isError: false,
      };
    case actionTypes.UPDATE_SCREEN_TO_SCREEN_DUEDATE_PERIOD_START:
      return {
        ...state,
        loading: true,
        error: undefined,
        errorMessage: undefined,
        isError: false,
      };
    case actionTypes.UPDATE_SCREEN_TO_SCREEN_ADVANCEMENTS_PERIOD_START:
      return {
        ...state,
        loading: true,
        error: undefined,
        errorMessage: undefined,
        isError: false,
      };
    case actionTypes.UPDATE_SCREEN_TO_SCREEN_ADJUSTMENTS_PERIOD_START:
      return {
        ...state,
        loading: true,
        error: undefined,
        errorMessage: undefined,
        isError: false,
      };
    case actionTypes.UPDATE_SCREEN_TO_SCREEN_REFUNDS_PERIOD_START:
      return {
        ...state,
        loading: true,
        error: undefined,
        errorMessage: undefined,
        isError: false,
      };

    case actionTypes.UPDATE_SCREEN_TO_SCREEN_SALES_RECONCILIATION_PERIOD_START:
      return {
        ...state,
        loading: true,
        error: undefined,
        errorMessage: undefined,
        isError: false,
      };
    case actionTypes.UPDATE_SCREEN_TO_SCREEN_PAYMENTS_RECONCILIATION_PERIOD_START:
      return {
        ...state,
        loading: true,
        error: undefined,
        errorMessage: undefined,
        isError: false,
      };
    case actionTypes.UPDATE_SCREEN_TO_SCREEN_BANK_RECONCILIATION_PERIOD_START:
      return {
        ...state,
        loading: true,
        error: undefined,
        errorMessage: undefined,
        isError: false,
      };

    case actionTypes.UPDATE_SCREEN_TO_SCREEN_SALES_PERIOD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        isError: false,
        salesPeriod: action?.payload,
        errorMessage: undefined,
      };
    case actionTypes.UPDATE_SCREEN_TO_SCREEN_PAYMENT_DIVERGENCES_PERIOD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        isError: false,
        paymentDivergencesPeriod: action?.payload,
        errorMessage: undefined,
      };
    case actionTypes.UPDATE_SCREEN_TO_SCREEN_AMOUNTS_RECEIVABLE_PERIOD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        isError: false,
        amountsReceivablePeriod: action?.payload,
        errorMessage: undefined,
      };
    case actionTypes.UPDATE_SCREEN_TO_SCREEN_BANKS_PERIOD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        isError: false,
        banksPeriod: action?.payload,
        errorMessage: undefined,
      };
    case actionTypes.UPDATE_SCREEN_TO_SCREEN_DUEDATE_PERIOD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        isError: false,
        duedatePeriod: action?.payload,
        errorMessage: undefined,
      };
    case actionTypes.UPDATE_SCREEN_TO_SCREEN_ADVANCEMENTS_PERIOD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        isError: false,
        advancementsPeriod: action?.payload,
        errorMessage: undefined,
      };
    case actionTypes.UPDATE_SCREEN_TO_SCREEN_ADJUSTMENTS_PERIOD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        isError: false,
        adjustmentsPeriod: action?.payload,
        errorMessage: undefined,
      };
    case actionTypes.UPDATE_SCREEN_TO_SCREEN_REFUNDS_PERIOD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        isError: false,
        refundsPeriod: action?.payload,
        errorMessage: undefined,
      };
    case actionTypes.UPDATE_SCREEN_TO_SCREEN_SALES_RECONCILIATION_PERIOD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        isError: false,
        salesReconciliationPeriod: action?.payload,
        errorMessage: undefined,
      };
    case actionTypes.UPDATE_SCREEN_TO_SCREEN_PAYMENTS_RECONCILIATION_PERIOD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        isError: false,
        paymentsReconciliationPeriod: action?.payload,
        errorMessage: undefined,
      };
    case actionTypes.UPDATE_SCREEN_TO_SCREEN_BANK_RECONCILIATION_PERIOD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        isError: false,
        bankReconciliationPeriod: action?.payload,
        errorMessage: undefined,
      };

    case actionTypes.UPDATE_SCREEN_TO_SCREEN_SALES_PERIOD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error.error,
        errorMessage: action.payload,
        salesPeriod: 'neste_mes',
        isError: true,
      };
    case actionTypes.UPDATE_SCREEN_TO_SCREEN_PAYMENT_DIVERGENCES_PERIOD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error.error,
        errorMessage: action.payload,
        paymentDivergencesPeriod: 'neste_mes',
        isError: true,
      };
    case actionTypes.UPDATE_SCREEN_TO_SCREEN_AMOUNTS_RECEIVABLE_PERIOD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error.error,
        errorMessage: action.payload,
        amountsReceivablePeriod: 'neste_mes',
        isError: true,
      };
    case actionTypes.UPDATE_SCREEN_TO_SCREEN_BANKS_PERIOD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error.error,
        errorMessage: action.payload,
        banksPeriod: 'neste_mes',
        isError: true,
      };
    case actionTypes.UPDATE_SCREEN_TO_SCREEN_DUEDATE_PERIOD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error.error,
        errorMessage: action.payload,
        duedatePeriod: 'neste_mes',
        isError: true,
      };
    case actionTypes.UPDATE_SCREEN_TO_SCREEN_ADVANCEMENTS_PERIOD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error.error,
        errorMessage: action.payload,
        advancementsPeriod: 'neste_mes',
        isError: true,
      };
    case actionTypes.UPDATE_SCREEN_TO_SCREEN_ADJUSTMENTS_PERIOD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error.error,
        errorMessage: action.payload,
        adjustmentsPeriod: 'neste_mes',
        isError: true,
      };
    case actionTypes.UPDATE_SCREEN_TO_SCREEN_REFUNDS_PERIOD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error.error,
        errorMessage: action.payload,
        refundsPeriod: 'neste_mes',
        isError: true,
      };
    case actionTypes.UPDATE_SCREEN_TO_SCREEN_SALES_RECONCILIATION_PERIOD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error.error,
        errorMessage: action.payload,
        salesReconciliationPeriod: 'neste_mes',
        isError: true,
      };

    case actionTypes.UPDATE_SCREEN_TO_SCREEN_PAYMENTS_RECONCILIATION_PERIOD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error.error,
        errorMessage: action.payload,
        paymentsReconciliationPeriod: 'neste_mes',
        isError: true,
      };
    case actionTypes.UPDATE_SCREEN_TO_SCREEN_BANK_RECONCILIATION_PERIOD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error.error,
        errorMessage: action.payload,
        bankReconciliationPeriod: 'neste_mes',
        isError: true,
      };

    default:
      return state;
  }
};

export default reducer;
