// TODO: Remove camelcase eslint rule
/* eslint-disable camelcase,array-callback-return,no-param-reassign */
import { api } from '../api';

const transformer = (apiResponse) => {
  const { data } = apiResponse;
  const payments = data.divergences;
  return { payments };
};

const getPaymentsDivergencesIntermediate = async ({
  startDate,
  endDate,
  type,
  page,
  perPage,
  sort,
  filter,
}) => {
  const config = {
    params: {
      start_date: startDate.format('YYYY-MM-DD'),
      end_date: endDate.format('YYYY-MM-DD'),
      type,
      page,
      per_page: perPage,
      order_by: sort,
      filter,
    },
  };
  const response = await api().get('/payment/divergences', config);
  return transformer(response.data);
};

export default getPaymentsDivergencesIntermediate;
