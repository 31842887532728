import { api } from './api';

const transformer = ({ data }) => {
  return { data };
};

const putChangeStoreFromAPI = async ({
  user_id: userId,
  client_id: clientId,
}) => {
  const response = await api().put('/default', {
    user_id: userId,
    client_id: clientId,
  });
  return transformer(response.data);
};

export default putChangeStoreFromAPI;
