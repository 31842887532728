export const useMenu = ({ setSkinInput, setAccessType, setPlanInput }) => {
  const handleSkinMenu = (key, value) => {
    setSkinInput({
      id: Number(key),
      name: value,
    });
  };

  const handleTypeSelection = (value) => {
    setAccessType((prevState) => {
      if (prevState.includes(value)) {
        return prevState.filter((v) => v !== value);
      }

      return [...prevState, value];
    });
  };

  const handlePlanMenu = (key, value) => {
    setPlanInput({
      id: Number(key),
      name: value,
    });
  };

  return {
    handleSkinMenu,
    handleTypeSelection,
    handlePlanMenu,
  };
};
