export const actionTypes = {
  GET_CLIENT_START: '@client/GET_CLIENT_START',
  GET_CLIENT_SUCCESS: '@client/GET_CLIENT_SUCCESS',
  GET_CLIENT_ERROR: '@client/GET_CLIENT_ERROR',
  POST_CLIENT_START: '@client/POST_CLIENT_START',
  POST_CLIENT_SUCCESS: '@client/POST_CLIENT_SUCCESS',
  POST_CLIENT_ERROR: '@client/POST_CLIENT_ERROR',
  FILE_IDENTIFICATION_START: '@client/FILE_IDENTIFICATION_START',
  FILE_IDENTIFICATION_SUCCESS: '@client/FILE_IDENTIFICATION_SUCCESS',
  FILE_IDENTIFICATION_ERROR: '@client/FILE_IDENTIFICATION_ERROR',
  PUT_CLIENT_START: '@client/PUT_CLIENT_START',
  PUT_CLIENT_SUCCESS: '@client/PUT_CLIENT_SUCCESS',
  PUT_CLIENT_ERROR: '@client/PUT_CLIENT_ERROR',
};
