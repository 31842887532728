// TODO: Remove camelcase eslint rule
/* eslint-disable camelcase */
import { lambdaApi } from '../api';

const updateStore = async ({ store_id, name, short_name, br_cnpj }) => {
  const config = {
    store_id,
    name,
    short_name,
    br_cnpj,
  };
  const response = await lambdaApi().put(
    '/settings/maintenance/stores',
    config
  );
  return response.data;
};
export default updateStore;
