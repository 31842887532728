import { action } from 'typesafe-actions';
import { actionTypes } from './types';

export const getCnpjStart = (data) =>
  action(actionTypes.GET_CNPJ_START, { data });
export const getCnpjSuccess = (data) =>
  action(actionTypes.GET_CNPJ_SUCCESS, data);
export const getCnpjError = (errorMessage, error) =>
  action(actionTypes.GET_CNPJ_ERROR, { errorMessage }, undefined, { error });

export const resetCnpjData = (data) =>
  action(actionTypes.RESET_CNPJ_DATA, data);

export const updateCnpjStatus = (data) =>
  action(actionTypes.UPDATE_CNPJ_STATUS, data);
