import React, { createContext } from 'react';
import BranchTable from './components/BranchTable';
import BranchManagementHeader from './components/BranchManagementHeader';

import { StContainer, StWrapper, StHeaderWrapper } from './styled';
import BranchDetailsDrawer from 'commons/Header/components/BranchDetailsDrawer';
import { useBranchManagement } from 'utils/hooks/useBranchManagement';

export const BranchManagementContext = createContext({});

const BranchManagement = () => {
  const hookValues = useBranchManagement();

  return (
    <BranchManagementContext.Provider value={hookValues}>
      <StContainer>
        <StHeaderWrapper>
          <BranchManagementHeader />
        </StHeaderWrapper>

        <StWrapper>
          <BranchTable />
        </StWrapper>

        <BranchDetailsDrawer />
      </StContainer>
    </BranchManagementContext.Provider>
  );
};

export default BranchManagement;
