import styled from 'styled-components';
import { marginMainContainer, filtersTopPosition } from 'helpers/style';
import { colors } from 'styles/colors';

export const StContainer = styled.div`
  background-color: ${colors.gray1};
  position: fixed;
  top: ${(props) => filtersTopPosition(props?.$breakpoint)};
  z-index: 100;
  width: 100%;
  overflow-y: hidden;
  padding: 0 32px;
`;

export const StLimiter = styled.div`
  margin: ${(props) => marginMainContainer(props?.$breakpoint)};
  padding: 3rem 0;
`;
