import { getSessionClientId } from 'helpers/sessionService';
import { lambdaApi } from './api';

const transformer = (apiResponse) => {
  return {
    briefing: apiResponse.data.briefing,
  };
};

export const getBriefing = async () => {
  const config = {
    params: {
      client: getSessionClientId(),
    },
  };
  const response = await lambdaApi().get('/briefing', config);
  return transformer(response.data);
};

export const postBriefing = async ({ data }) => {
  const json = { ...data };
  return lambdaApi().post('/briefing', json);
};

export const putBriefing = async ({ data }) => {
  const json = { ...data };
  return lambdaApi().put('/briefing', json);
};

export const closeBriefing = async (payload) => {
  return lambdaApi().post('/briefing/close', payload);
};

export const openBriefing = async (payload) => {
  return lambdaApi().post('/briefing/open', payload);
};
