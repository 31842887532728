import { api } from './api';

const transformer = ({ meta }) => {
  if (meta) {
    return {
      client_id: meta.client_id,
    };
  }

  return true;
};

const removeBranch = async ({ clientId }) => {
  const response = await api().delete(`/client?del_client_id=${clientId}`);
  return transformer(response);
};

export default removeBranch;
