import { useCallback, useEffect, useState } from 'react';
import { useDebounce } from 'utils/hooks/useDebounce';

import getCnpjMaintenanceInfoFiltersFromAPI from 'services/cnpjMaintenance/filters/getCnpjMaintenanceInfoFiltersFromAPI';
import errorHandler from 'helpers/errorHandler';

export const useStoreOptions = (searchFilterInput, offset) => {
  const limit = 10;
  const [isError, setIsError] = useState(false);
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [storeFilterOptions, setStoreFilterOptions] = useState([]);
  const [storeFilterOptionsData, setStoreFilterOptionsData] = useState([]);
  const [storeFilterMeta, setStoreFilterMeta] = useState({});
  const queryWithDebounce = useDebounce(searchFilterInput, 500);

  const transformSearchFilter = (search) => {
    const query = {
      filter: [
        {
          name: 'store_identifier',
          val: search || '',
        },
      ],
    };

    return query;
  };

  const fetchStoreFilter = useCallback(
    (offsetNumber) => {
      setIsLoadingSearch(true);
      setIsError(false);
      return getCnpjMaintenanceInfoFiltersFromAPI({
        filter: transformSearchFilter(queryWithDebounce),
        offset: offsetNumber === 0 ? 0 : offset,
        limit,
      })
        .then(({ stores: data = [], meta = {} }) => {
          setStoreFilterOptionsData(data);
          setStoreFilterMeta(meta);
        })
        .catch((err) => {
          if (err) {
            errorHandler('Ocorreu um erro:', err);
          }
          setIsError(true);
          setIsLoadingSearch(false);
        })
        .finally(() => setIsLoadingSearch(false));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryWithDebounce, offset]
  );

  useEffect(() => {
    setStoreFilterOptions([]);
    fetchStoreFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryWithDebounce]);

  useEffect(() => {
    if (offset > 1) {
      fetchStoreFilter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset]);

  useEffect(() => {
    setStoreFilterOptions((prev) => {
      return [...new Set([...prev, ...storeFilterOptionsData])];
    });
  }, [storeFilterOptionsData]);

  return {
    isLoadingSearch,
    isError,
    storeFilterOptions,
    storeFilterMeta,
  };
};
