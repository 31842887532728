import { action } from 'typesafe-actions';
import { actionTypes } from './types';

export const getStoresStart = (data) => action(actionTypes.GET_STORES_START, { data });
export const getStoresSuccess = (data) => action(actionTypes.GET_STORES_SUCCESS, data);
export const getStoresError = (errorMessage, error) =>
  action(actionTypes.GET_STORES_ERROR, { errorMessage }, undefined, { error });

export const postStoreStart = (data) => action(actionTypes.POST_STORE_START, data);
export const postStoreSuccess = (data) => action(actionTypes.POST_STORE_SUCCESS, data);
export const postStoreError = (errorMessage, error) =>
  action(actionTypes.POST_STORE_ERROR, { errorMessage }, undefined, { error });

export const putStoreStart = (data) => action(actionTypes.PUT_STORE_START, data);
export const putStoreSuccess = (data) => action(actionTypes.PUT_STORE_SUCCESS, data);
export const putStoreError = (errorMessage, error) =>
  action(actionTypes.PUT_STORE_ERROR, { errorMessage }, undefined, { error });

export const deleteStoreStart = (data) => action(actionTypes.DELETE_STORE_START, { data });
export const deleteStoreSuccess = (data) => action(actionTypes.DELETE_STORE_SUCCESS, data);
export const deleteStoreError = (errorMessage, error) =>
  action(actionTypes.DELETE_STORE_ERROR, { errorMessage }, undefined, { error });
