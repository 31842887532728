/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Skeleton, Dropdown } from 'antd';
import PropTypes from 'prop-types';
import { salesSummaryStart } from 'store/ducks/salesSummary/actions';
import customHistory from 'helpers/history';
import { LabelAndValue } from 'commons/LabelAndValue';
import { TitleAndIcon } from 'commons/TitleAndIcon';
import CardContainer from 'commons/CardContainer';
import { ArrowRightOutlined, CalendarOutlined } from '@ant-design/icons';
import {
  StDashboardFooterCol,
  StDashboardFooterRow,
  StDashboardTag,
  StDashboardFooterCardUpperContainer,
  StHoverClickTitle,
  StHoverClickValue,
  StDropdownContent,
} from 'components/Dashboard/styled';
import { updateScreenToScreenDateStart } from 'store/ducks/updateScreenToScreenDate/actions';
import { updateScreenToScreenSalesPeriodStart } from 'store/ducks/updateScreenToScreenPeriod/actions';
import {
  currentDayMinusOne,
  endOfPreviousMonth,
  startOfCurrentMonth,
  startOfPreviousMonth,
} from 'helpers/dates';
import dayjs from 'dayjs';
import { usePlansFeaturesPermissions } from 'utils/hooks/usePlansFeaturesPermissions';
import { dataTestIdHandler } from 'helpers/dataTestIdHandler';

const assetsBaseURL = process.env.REACT_APP_AWS_V4_ASSETS;
const salesSummaryIconUri = `${assetsBaseURL}/svg/salesSummaryIcon.svg`;

const SalesCard = ({
  isFlipped,
  setIsFlipped,
  startDateSales,
  endDateSales,
  setStartDateSales,
  setEndDateSales,
  ...rest
}) => {
  const { isFeatureAvailable } = usePlansFeaturesPermissions();
  const dispatch = useDispatch();
  const dropdownKeyValue = useSelector(
    (store) => store.updateScreenToScreenPeriod?.salesPeriod
  );
  const summaryData = useSelector((store) => store.salesSummary.summary);
  const salesSummaryLoading = useSelector(
    (store) => store.salesSummary.loading
  );
  const permissionsLoading = useSelector(
    (state) => state?.permissions?.loading
  );
  const dataTestPrefix = 'sales-card';

  useEffect(() => {
    switch (dropdownKeyValue) {
      case 'neste_mes':
        dispatch(
          salesSummaryStart({
            startDate: startOfCurrentMonth,
            endDate: currentDayMinusOne,
          })
        );
        break;
      case 'ultimo_dia':
        dispatch(
          salesSummaryStart({
            startDate: currentDayMinusOne,
            endDate: currentDayMinusOne,
          })
        );
        break;
      case 'ultimo_mes':
        dispatch(
          salesSummaryStart({
            startDate: startOfPreviousMonth,
            endDate: endOfPreviousMonth,
          })
        );
        break;
      default:
        break;
    }
  }, [dispatch, startDateSales, endDateSales]);

  const handleDropdownMenu = (key) => {
    switch (key) {
      case 'neste_mes':
        setStartDateSales(startOfCurrentMonth);
        setEndDateSales(currentDayMinusOne);
        dispatch(updateScreenToScreenSalesPeriodStart('neste_mes'));
        break;
      case 'ultimo_dia':
        setStartDateSales(currentDayMinusOne);
        setEndDateSales(currentDayMinusOne);
        dispatch(updateScreenToScreenSalesPeriodStart('ultimo_dia'));
        break;
      case 'ultimo_mes':
        setStartDateSales(startOfPreviousMonth);
        setEndDateSales(endOfPreviousMonth);
        dispatch(updateScreenToScreenSalesPeriodStart('ultimo_mes'));
        break;
      default:
        break;
    }
  };

  const goToSales = () => {
    customHistory.push('/vendas-intermediaria');
    dispatch(
      updateScreenToScreenDateStart({
        startDate: startDateSales,
        endDate: endDateSales,
      })
    );
  };

  useEffect(() => {
    handleDropdownMenu(dropdownKeyValue);
  }, [dispatch]);

  const convertSalesQty = (salesQty) => {
    return salesQty.toLocaleString('pt-BR');
  };

  const items = [
    {
      label: 'Neste mês',
      key: 'neste_mes',
      onClick: () => handleDropdownMenu('neste_mes'),
    },
    {
      label: 'Último dia',
      key: 'ultimo_dia',
      onClick: () => handleDropdownMenu('ultimo_dia'),
    },
    {
      label: 'Último mês',
      key: 'ultimo_mes',
      onClick: () => handleDropdownMenu('ultimo_mes'),
    },
  ];

  return (
    <CardContainer
      height={230}
      enforceMaxHeight
      hasFooter
      {...dataTestIdHandler(dataTestPrefix, 'container')}
      {...rest}
    >
      <Skeleton loading={salesSummaryLoading || permissionsLoading} active>
        <StDashboardFooterCardUpperContainer>
          <Row align="middle" justify="space-between">
            <StHoverClickTitle onClick={goToSales}>
              <Col style={{ padding: '0 4px' }}>
                <TitleAndIcon
                  iconUri={salesSummaryIconUri}
                  iconAlt="Icone de um cartão de crédito"
                  titleText="Vendas"
                  titleFontSize="20px"
                  dataTestPrefix={dataTestPrefix}
                />
              </Col>
            </StHoverClickTitle>
            <Col style={{ height: '22px' }}>
              <Dropdown
                menu={{ items }}
                trigger={['click']}
                dropdownRender={(menu) => (
                  <StDropdownContent>{menu}</StDropdownContent>
                )}
              >
                <StDashboardTag icon={<CalendarOutlined />}>
                  {dropdownKeyValue === 'neste_mes' && 'Neste mês'}
                  {dropdownKeyValue === 'ultimo_dia' && 'Último dia'}
                  {dropdownKeyValue === 'ultimo_mes' && 'Último mês'}
                </StDashboardTag>
              </Dropdown>
            </Col>
          </Row>
          <Row style={{ marginTop: '2rem' }}>
            <StHoverClickValue onClick={goToSales} disableHoverColor>
              <Col>
                <LabelAndValue
                  label="Quantidade de vendas"
                  labelColor="rgba(0,0,0,0.45)"
                  value={convertSalesQty(summaryData?.sales_quantity || 0)}
                  valueTextSize="medium"
                  dataTestPrefix={`${dataTestPrefix}-quantity`}
                />
              </Col>
            </StHoverClickValue>
          </Row>
          <Row justify="space-between" align="middle">
            <StHoverClickValue onClick={goToSales} disableHoverColor>
              <Col>
                <LabelAndValue
                  label="Ticket médio"
                  labelColor="rgba(0,0,0,0.45)"
                  value={summaryData?.ticket_avarage || 0}
                  valueTextSize="medium"
                  dataTestPrefix={`${dataTestPrefix}-ticket-average`}
                />
              </Col>
            </StHoverClickValue>
            <StHoverClickValue onClick={goToSales} disableHoverColor>
              <Col>
                <LabelAndValue
                  label="Total de vendas"
                  labelColor="rgba(0,0,0,0.45)"
                  value={summaryData?.sales_amount || 0}
                  valueTextSize="large"
                  textAlign="end"
                  dataTestPrefix={`${dataTestPrefix}-amount`}
                />
              </Col>
            </StHoverClickValue>
          </Row>
        </StDashboardFooterCardUpperContainer>
        {isFeatureAvailable({
          key: 'dashboard-salesReconciliationCard',
        }) ? (
          <StDashboardFooterRow
            justify="end"
            {...dataTestIdHandler(dataTestPrefix, 'footer')}
          >
            <StDashboardFooterCol>
              <span
                onClick={() => {
                  setIsFlipped({
                    ...isFlipped,
                    sales: !isFlipped?.sales,
                  });
                }}
              >
                Ver conciliação
                <ArrowRightOutlined style={{ marginLeft: '8px' }} />
              </span>{' '}
            </StDashboardFooterCol>
          </StDashboardFooterRow>
        ) : (
          <></>
        )}
      </Skeleton>
    </CardContainer>
  );
};

SalesCard.propTypes = {
  startDateSales: PropTypes.instanceOf(dayjs).isRequired,
  endDateSales: PropTypes.instanceOf(dayjs).isRequired,
  setStartDateSales: PropTypes.func.isRequired,
  setEndDateSales: PropTypes.func.isRequired,
};

export default SalesCard;
