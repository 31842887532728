import { action } from 'typesafe-actions';
import { actionTypes } from './types';

export const deleteFeeStart = (data) =>
  action(actionTypes.DELETE_FEE_START, data);
export const deleteFeeSuccess = (data) =>
  action(actionTypes.DELETE_FEE_SUCCESS, data);
export const deleteFeeError = (errorMessage, error) =>
  action(actionTypes.DELETE_FEE_ERROR, { errorMessage }, undefined, {
    error,
  });
export const resetDeleteFeeStatus = () =>
  action(actionTypes.RESET_DELETE_FEE_STATUS);
