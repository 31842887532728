import React from 'react';
import { Container, StTitle } from './styled';
import { Col, Row, Typography } from 'antd';

function GetnetLetterInstruction() {
  return (
    <Container>
      <Row>
        <Col span={24}>
          <StTitle>Autorização Getnet</StTitle>
          <Typography.Paragraph>
            Para autorizar o acesso da Concil aos seus dados da Getnet, siga as
            instruções abaixo para cada estabelecimento cadastrado.
            <br />O prazo para disponibilização dos arquivos para a Concil é de
            até 7 dias úteis.
          </Typography.Paragraph>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <StTitle style={{ marginBottom: '16px' }}>Instruções</StTitle>

          <Row gutter={[10, 10]} style={{ wordBreak: 'keep-all' }}>
            <Col span={8}>
              1. Acesse o{' '}
              <a
                href="https://minhaconta.getnet.com.br/login"
                target="_blank"
                rel="noopener noreferrer"
              >
                portal da Getnet
              </a>{' '}
              com o seu login e senha e acesse a opção{' '}
              <strong>Habilitar extrato</strong> na aba{' '}
              <strong>Produtos e serviços</strong>
            </Col>

            <Col span={8}>
              2. Selecione a opção <strong>Habilitar extrato eletrônico</strong>
              , aceite os termos e em seguida preencha os dados solicitados.
            </Col>

            <Col span={8}>
              3. Selecione a opção <strong>Conciliador de mercado</strong> e
              escolha a <strong>Concil</strong>.
              <br />
              Toda interação será feita pela Getnet por e-mail informando o
              status da sua solicitação.
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default GetnetLetterInstruction;
