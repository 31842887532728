import { actionTypes } from './types';

const INITIAL_STATE = {
  loading: false,
  listData: [],
  count: 0,
  hasMore: true,
  isError: false,
  errorMessage: undefined,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.LIST_OFX_PARAMETERS_START:
      return {
        ...state,
        loading: true,
        errorMessage: undefined,
        isError: false,
      };
    case actionTypes.LIST_OFX_PARAMETERS_SUCCESS:
      return {
        ...state,
        loading: false,
        listData: action.payload.listData,
        count: action.payload.count,
        hasMore: action.payload.hasNext,
        perPage: action.payload.perPage,
        page: action.payload.page,
        isError: false,
        errorMessage: undefined,
      };
    case actionTypes.LIST_OFX_PARAMETERS_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: JSON.stringify(action.payload),
        isError: true,
        listData: [],
      };
    case actionTypes.LIST_OFX_PARAMETERS_ALTER:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.ADD_OFX_PARAMETER_START:
      return {
        ...state,
        loading: true,
        errorMessage: undefined,
        isError: false,
      };
    case actionTypes.ADD_OFX_PARAMETER_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: undefined,
        isError: false,
      };
    case actionTypes.ADD_OFX_PARAMETER_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        isError: true,
      };
    case actionTypes.EDIT_OFX_PARAMETER_START:
      return {
        ...state,
        loading: true,
        errorMessage: undefined,
        isError: false,
      };
    case actionTypes.EDIT_OFX_PARAMETER_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: undefined,
        isError: false,
      };
    case actionTypes.EDIT_OFX_PARAMETER_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        isError: true,
      };
    case actionTypes.REMOVE_OFX_PARAMETER_START:
      return {
        ...state,
        loading: true,
        errorMessage: undefined,
        isError: false,
      };
    case actionTypes.REMOVE_OFX_PARAMETER_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: undefined,
        isError: false,
      };
    case actionTypes.REMOVE_OFX_PARAMETER_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        isError: true,
      };
    default:
      return state;
  }
};

export default reducer;
