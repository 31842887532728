import { WarningOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import styled from 'styled-components';
import { colors } from 'styles/colors';

export const StContainer = styled.div`
  width: 100%;
  height: 56px;
  background-color: ${colors.gray2};
  box-shadow: ${colors.boxShadow};
  padding: 16px 24px;
  border-radius: 4px;
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
  position: relative;

  > button {
    position: absolute;
    right: 24px;
  }
`;

export const StText = styled(Typography.Text)`
  color: ${colors.gray8};
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;

  span {
    font-weight: 400;
  }
`;

export const StWarningOutlinedIcon = styled(WarningOutlined)`
  &&& {
    font-size: 24px;
    color: ${colors.primary6};
  }
`;
