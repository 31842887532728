export const handleBrNames = (value) => {
  const result = {
    sale: 'Vendas',
    payment: 'Pagamentos',
    '1_1': '1-1',
    N_N: 'N-N',
    true: 'Ativo',
    false: 'Inativo',
    default: 'Padrão',
    custom: 'Personalizada',
  };

  return result[value] || '';
};

export const handleModalTitle = (_modalType, isDuplicating) => {
  const result = {
    addRule: 'Adicionar regra',
    editRule: 'Editar regra',
  };

  if (!isDuplicating) return result[_modalType];
  return 'Duplicar regra';
};

export const handleButtonText = (modalType, isDuplicating) => {
  if (modalType === 'addRule') return 'Adicionar';
  if (modalType === 'editRule' && !isDuplicating) return 'Salvar';
  if (modalType === 'editRule' && isDuplicating) return 'Duplicar';
  return '';
};
