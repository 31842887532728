import React from 'react';
import { RadarTypeTag } from '../components/RadarTypeTag';
import { RadarStatusTag } from '../components/RadarStatusTag';
import { RadarIdText } from '../components/RadarIdText';
import { clearFiltersFunctions } from 'helpers/clearFiltersFunctions';
import store from 'store';
import {
  radarListFiltersStart,
  radarListFiltersZeroLength,
} from 'store/ducks/radar/radarListFilters/actions';
import {
  columnFilters,
  getDefaultColumns,
  onColumnFilter,
} from 'helpers/generalService';
import { CustomFilterDropdown } from 'commons/Table/components/CustomFilterDropdown';
import { customUpdateFilteredColumnsStart } from 'store/ducks/customUpdateFilteredColumns/actions';

let filteredColumns =
  store.getState().customUpdateFilteredColumns?.filteredColumns;

export const columns = ({
  handleOpenSpecificRadar,
  apiFilters,
  toParamsFilters,
}) => [
  {
    title: 'ID da execução',
    dataIndex: 'idt_radar',
    filteredValue: getDefaultColumns(toParamsFilters, 'idt_radar'),
    filters:
      apiFilters?.length > 0 ? columnFilters(apiFilters, 'idt_radar') : [],
    onFilter: (value, record) => onColumnFilter(value, record, 'idt_radar'),
    onFilterDropdownVisibleChange: (visible) => {
      filteredColumns =
        store.getState().customUpdateFilteredColumns?.filteredColumns;
      if (visible) {
        // This "zero length" dispatch is utterly important,
        // in order to keep each column state independent (Not passing data from one column to the next)
        store.dispatch(radarListFiltersZeroLength());
        store.dispatch(
          radarListFiltersStart({
            columnName: 'idt_radar',
            offset: 0,
            limit: store.getState().radarListFilters?.offsetShift,
            q:
              filteredColumns.length !== 0
                ? {
                    filter: filteredColumns,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.idtRadar = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <CustomFilterDropdown
          key={filteredColumns}
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={radarListFiltersStart}
          filteredColumns={
            store.getState().customUpdateFilteredColumns?.filteredColumns
          }
          clearAction={radarListFiltersZeroLength}
          columnName="idt_radar"
          storeName="radarListFilters"
          updatedFilteredColumns={customUpdateFilteredColumnsStart}
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: 200,
    align: 'left',
    render: (_, record) => {
      return (
        <RadarIdText
          currentRow={record}
          handleOpenModal={handleOpenSpecificRadar}
        />
      );
    },
  },
  {
    title: 'PFJ',
    dataIndex: 'client_code',
    filteredValue: getDefaultColumns(toParamsFilters, 'client_code'),
    filters:
      apiFilters?.length > 0 ? columnFilters(apiFilters, 'client_code') : [],
    onFilter: (value, record) => onColumnFilter(value, record, 'client_code'),
    onFilterDropdownVisibleChange: (visible) => {
      filteredColumns =
        store.getState().customUpdateFilteredColumns?.filteredColumns;
      if (visible) {
        // This "zero length" dispatch is utterly important,
        // in order to keep each column state independent (Not passing data from one column to the next)
        store.dispatch(radarListFiltersZeroLength());
        store.dispatch(
          radarListFiltersStart({
            columnName: 'client_code',
            offset: 0,
            limit: store.getState().radarListFilters?.offsetShift,
            q:
              filteredColumns.length !== 0
                ? {
                    filter: filteredColumns,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.clientCode = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <CustomFilterDropdown
          key={filteredColumns}
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={radarListFiltersStart}
          filteredColumns={
            store.getState().customUpdateFilteredColumns?.filteredColumns
          }
          clearAction={radarListFiltersZeroLength}
          columnName="client_code"
          storeName="radarListFilters"
          updatedFilteredColumns={customUpdateFilteredColumnsStart}
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: 200,
    align: 'left',
  },
  {
    title: 'Data da solicitação',
    dataIndex: 'created_at',
    filteredValue: getDefaultColumns(toParamsFilters, 'created_at'),
    filters:
      apiFilters?.length > 0 ? columnFilters(apiFilters, 'created_at') : [],
    onFilter: (value, record) => onColumnFilter(value, record, 'created_at'),
    onFilterDropdownVisibleChange: (visible) => {
      filteredColumns =
        store.getState().customUpdateFilteredColumns?.filteredColumns;
      if (visible) {
        // This "zero length" dispatch is utterly important,
        // in order to keep each column state independent (Not passing data from one column to the next)
        store.dispatch(radarListFiltersZeroLength());
        store.dispatch(
          radarListFiltersStart({
            columnName: 'created_at',
            offset: 0,
            limit: store.getState().radarListFilters?.offsetShift,
            q:
              filteredColumns.length !== 0
                ? {
                    filter: filteredColumns,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.startedAt = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <CustomFilterDropdown
          key={filteredColumns}
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={radarListFiltersStart}
          filteredColumns={
            store.getState().customUpdateFilteredColumns?.filteredColumns
          }
          clearAction={radarListFiltersZeroLength}
          columnName="created_at"
          storeName="radarListFilters"
          updatedFilteredColumns={customUpdateFilteredColumnsStart}
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: 230,
    align: 'left',
  },
  {
    title: 'Data de início',
    dataIndex: 'started_at',
    filteredValue: getDefaultColumns(toParamsFilters, 'started_at'),
    filters:
      apiFilters?.length > 0 ? columnFilters(apiFilters, 'started_at') : [],
    onFilter: (value, record) => onColumnFilter(value, record, 'started_at'),
    onFilterDropdownVisibleChange: (visible) => {
      filteredColumns =
        store.getState().customUpdateFilteredColumns?.filteredColumns;
      if (visible) {
        // This "zero length" dispatch is utterly important,
        // in order to keep each column state independent (Not passing data from one column to the next)
        store.dispatch(radarListFiltersZeroLength());
        store.dispatch(
          radarListFiltersStart({
            columnName: 'started_at',
            offset: 0,
            limit: store.getState().radarListFilters?.offsetShift,
            q:
              filteredColumns.length !== 0
                ? {
                    filter: filteredColumns,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.startedAt = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <CustomFilterDropdown
          key={filteredColumns}
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={radarListFiltersStart}
          filteredColumns={
            store.getState().customUpdateFilteredColumns?.filteredColumns
          }
          clearAction={radarListFiltersZeroLength}
          columnName="started_at"
          storeName="radarListFilters"
          updatedFilteredColumns={customUpdateFilteredColumnsStart}
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: 200,
    align: 'left',
  },
  {
    title: 'Data de finalização',
    dataIndex: 'ended_at',
    filteredValue: getDefaultColumns(toParamsFilters, 'ended_at'),
    filters:
      apiFilters?.length > 0 ? columnFilters(apiFilters, 'ended_at') : [],
    onFilter: (value, record) => onColumnFilter(value, record, 'ended_at'),
    onFilterDropdownVisibleChange: (visible) => {
      filteredColumns =
        store.getState().customUpdateFilteredColumns?.filteredColumns;
      if (visible) {
        // This "zero length" dispatch is utterly important,
        // in order to keep each column state independent (Not passing data from one column to the next)
        store.dispatch(radarListFiltersZeroLength());
        store.dispatch(
          radarListFiltersStart({
            columnName: 'ended_at',
            offset: 0,
            limit: store.getState().radarListFilters?.offsetShift,
            q:
              filteredColumns.length !== 0
                ? {
                    filter: filteredColumns,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.endedAt = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <CustomFilterDropdown
          key={filteredColumns}
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={radarListFiltersStart}
          filteredColumns={
            store.getState().customUpdateFilteredColumns?.filteredColumns
          }
          clearAction={radarListFiltersZeroLength}
          columnName="ended_at"
          storeName="radarListFilters"
          updatedFilteredColumns={customUpdateFilteredColumnsStart}
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: 250,
    align: 'left',
  },
  {
    title: 'Tipo',
    dataIndex: 'type',
    filteredValue: getDefaultColumns(toParamsFilters, 'type'),
    filters: apiFilters?.length > 0 ? columnFilters(apiFilters, 'type') : [],
    onFilter: (value, record) => onColumnFilter(value, record, 'type'),
    onFilterDropdownVisibleChange: (visible) => {
      filteredColumns =
        store.getState().customUpdateFilteredColumns?.filteredColumns;
      if (visible) {
        // This "zero length" dispatch is utterly important,
        // in order to keep each column state independent (Not passing data from one column to the next)
        store.dispatch(radarListFiltersZeroLength());
        store.dispatch(
          radarListFiltersStart({
            columnName: 'type',
            offset: 0,
            limit: store.getState().radarListFilters?.offsetShift,
            q:
              filteredColumns.length !== 0
                ? {
                    filter: filteredColumns,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.type = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <CustomFilterDropdown
          key={filteredColumns}
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={radarListFiltersStart}
          filteredColumns={
            store.getState().customUpdateFilteredColumns?.filteredColumns
          }
          clearAction={radarListFiltersZeroLength}
          columnName="type"
          storeName="radarListFilters"
          updatedFilteredColumns={customUpdateFilteredColumnsStart}
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: 150,
    align: 'left',
    render: (text) => <RadarTypeTag type={text} />,
  },
  {
    title: 'É teste?',
    dataIndex: 'is_test',
    filteredValue: getDefaultColumns(toParamsFilters, 'is_test'),
    filters: apiFilters?.length > 0 ? columnFilters(apiFilters, 'is_test') : [],
    onFilter: (value, record) => onColumnFilter(value, record, 'is_test'),
    onFilterDropdownVisibleChange: (visible) => {
      filteredColumns =
        store.getState().customUpdateFilteredColumns?.filteredColumns;
      if (visible) {
        // This "zero length" dispatch is utterly important,
        // in order to keep each column state independent (Not passing data from one column to the next)
        store.dispatch(radarListFiltersZeroLength());
        store.dispatch(
          radarListFiltersStart({
            columnName: 'is_test',
            offset: 0,
            limit: store.getState().radarListFilters?.offsetShift,
            q:
              filteredColumns.length !== 0
                ? {
                    filter: filteredColumns,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.isTest = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <CustomFilterDropdown
          key={filteredColumns}
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={radarListFiltersStart}
          filteredColumns={
            store.getState().customUpdateFilteredColumns?.filteredColumns
          }
          clearAction={radarListFiltersZeroLength}
          columnName="is_test"
          storeName="radarListFilters"
          updatedFilteredColumns={customUpdateFilteredColumnsStart}
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: 150,
    align: 'left',
  },
  {
    title: 'Status do envio',
    dataIndex: 'status',
    filteredValue: getDefaultColumns(toParamsFilters, 'status'),
    filters: apiFilters?.length > 0 ? columnFilters(apiFilters, 'status') : [],
    onFilter: (value, record) => onColumnFilter(value, record, 'status'),
    onFilterDropdownVisibleChange: (visible) => {
      filteredColumns =
        store.getState().customUpdateFilteredColumns?.filteredColumns;
      if (visible) {
        // This "zero length" dispatch is utterly important,
        // in order to keep each column state independent (Not passing data from one column to the next)
        store.dispatch(radarListFiltersZeroLength());
        store.dispatch(
          radarListFiltersStart({
            columnName: 'status',
            offset: 0,
            limit: store.getState().radarListFilters?.offsetShift,
            q:
              filteredColumns.length !== 0
                ? {
                    filter: filteredColumns,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.status = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <CustomFilterDropdown
          key={filteredColumns}
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={radarListFiltersStart}
          filteredColumns={
            store.getState().customUpdateFilteredColumns?.filteredColumns
          }
          clearAction={radarListFiltersZeroLength}
          columnName="status"
          storeName="radarListFilters"
          updatedFilteredColumns={customUpdateFilteredColumnsStart}
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: 200,
    align: 'left',
    render: (text) => <RadarStatusTag status={text} />,
  },
  {
    title: 'E-mail do solicitante',
    dataIndex: 'email_requester',
    filteredValue: getDefaultColumns(toParamsFilters, 'email_requester'),
    filters:
      apiFilters?.length > 0
        ? columnFilters(apiFilters, 'email_requester')
        : [],
    onFilter: (value, record) =>
      onColumnFilter(value, record, 'email_requester'),
    onFilterDropdownVisibleChange: (visible) => {
      filteredColumns =
        store.getState().customUpdateFilteredColumns?.filteredColumns;
      if (visible) {
        // This "zero length" dispatch is utterly important,
        // in order to keep each column state independent (Not passing data from one column to the next)
        store.dispatch(radarListFiltersZeroLength());
        store.dispatch(
          radarListFiltersStart({
            columnName: 'email_requester',
            offset: 0,
            limit: store.getState().radarListFilters?.offsetShift,
            q:
              filteredColumns.length !== 0
                ? {
                    filter: filteredColumns,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.emailRequester = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <CustomFilterDropdown
          key={filteredColumns}
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={radarListFiltersStart}
          filteredColumns={
            store.getState().customUpdateFilteredColumns?.filteredColumns
          }
          clearAction={radarListFiltersZeroLength}
          columnName="email_requester"
          storeName="radarListFilters"
          updatedFilteredColumns={customUpdateFilteredColumnsStart}
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: 250,
    align: 'left',
  },
  {
    title: 'Detalhe do status',
    dataIndex: 'status_message',
    filteredValue: getDefaultColumns(toParamsFilters, 'status_message'),
    filters:
      apiFilters?.length > 0 ? columnFilters(apiFilters, 'status_message') : [],
    onFilter: (value, record) =>
      onColumnFilter(value, record, 'status_message'),
    onFilterDropdownVisibleChange: (visible) => {
      filteredColumns =
        store.getState().customUpdateFilteredColumns?.filteredColumns;
      if (visible) {
        // This "zero length" dispatch is utterly important,
        // in order to keep each column state independent (Not passing data from one column to the next)
        store.dispatch(radarListFiltersZeroLength());
        store.dispatch(
          radarListFiltersStart({
            columnName: 'status_message',
            offset: 0,
            limit: store.getState().radarListFilters?.offsetShift,
            q:
              filteredColumns.length !== 0
                ? {
                    filter: filteredColumns,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.statusMessage = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <CustomFilterDropdown
          key={filteredColumns}
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={radarListFiltersStart}
          filteredColumns={
            store.getState().customUpdateFilteredColumns?.filteredColumns
          }
          clearAction={radarListFiltersZeroLength}
          columnName="status_message"
          storeName="radarListFilters"
          updatedFilteredColumns={customUpdateFilteredColumnsStart}
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: 280,
    align: 'left',
  },
];
