import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listOFXParameters } from 'services/ofxParameters';
import { useAcquirer } from 'utils/hooks/useAcquirer';
import { perPageOptions, showTotalPage } from 'constants/perPageOptions';
import ColumnList from '../table/ColumnList';
import { onColumnSort } from 'helpers/sorter';
import { generateFilterDataStructure } from 'helpers/tableService';
import {
  ofxParametersFiltersZeroLength,
  updateOfxParametersFilters,
} from 'store/ducks/filters/ofxParameters/actions';
import { resetUpdateFilteredColumnsStart } from 'store/ducks/updateFilteredColumns/actions';

export const useOfxParameters = () => {
  const {
    acquirers,
    loading: loadingAcquirer,
    getAllAcquirers,
  } = useAcquirer();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState('');
  const [listData, setListData] = useState([]);
  const [total, setTotal] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [perPage, setPerPage] = useState(10);
  const [pageCurrent, setPageCurrent] = useState(1);
  const [columnList, setColumnList] = useState([]);
  const [sortFields, setSortFields] = useState({});
  const [addModalVisibility, setAddModalVisibility] = useState(false);
  const [editModalVisibility, setEditModalVisibility] = useState(false);
  const [editingRecord, setEditingRecord] = useState(undefined);

  const toParamsFilters = useSelector(
    (state) => state.ofxParametersFilters.toParamsFilters
  );
  const filtersData = useSelector(
    (state) => state.ofxParametersFilters.filters
  );
  const dispatch = useDispatch();

  const pagination = {
    pageSize: perPage,
    pageSizeOptions: perPageOptions,
    total,
    current: pageCurrent,
    showTotal: showTotalPage,
    showSizeChanger: Boolean(total >= 10),
  };

  const handleAddModal = () => {
    setAddModalVisibility(!addModalVisibility);
  };

  const handleEditModal = (record) => {
    if (editModalVisibility) {
      setEditModalVisibility(false);
      setEditingRecord(undefined);
    } else {
      setEditModalVisibility(true);
      setEditingRecord(record);
    }
  };

  const onTablePaginationOrFiltersOrSorterChange = async (
    paginationHandler,
    tableFilters,
    sorter
  ) => {
    const filterDataToDispatch = generateFilterDataStructure(tableFilters);
    dispatch(updateOfxParametersFilters(filterDataToDispatch));

    onColumnSort(sorter, setSortFields);
    setPerPage(paginationHandler.pageSize);
    setPageCurrent(paginationHandler.current);
  };

  // Fetch acquirers
  useEffect(() => {
    getAllAcquirers(true, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fetch Ofx Parameters
  const getOfxParameters = async () => {
    try {
      setLoading(true);
      setError(undefined);

      const { listData: ofxParamsListData, count } = await listOFXParameters({
        page: pageCurrent,
        perPage,
        sortFields,
        q: {
          filter: toParamsFilters,
        },
        filterBy: searchText,
      });

      setListData(ofxParamsListData);
      setTotal(count);
      setLoading(false);
      setError(undefined);
      setErrorMessage('');
    } catch (e) {
      setListData([]);
      setLoading(false);
      setError(e);
      setErrorMessage(e?.message);
    }
  };

  const onSearch = () => {
    getOfxParameters();
  };

  const onChangeSearch = (e) => {
    const { value } = e.target;
    setSearchText(value);
  };

  // Calls function to fetch ofx parameters
  useEffect(() => {
    if (acquirers.length !== 0 || toParamsFilters.length > 1) {
      getOfxParameters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acquirers, pageCurrent, perPage, sortFields, toParamsFilters]);

  // Refreshes ColumnList
  useEffect(() => {
    setColumnList(
      ColumnList(handleEditModal, searchText, toParamsFilters, filtersData)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    listData,
    searchText,
    pageCurrent,
    total,
    perPage,
    acquirers,
    filtersData,
  ]);

  const handleClearFilters = () => {
    dispatch(resetUpdateFilteredColumnsStart());
    dispatch(updateOfxParametersFilters([]));
    dispatch(ofxParametersFiltersZeroLength());
    setSearchText('');
  };

  return {
    error,
    errorMessage,
    searchText,
    onSearch,
    onChangeSearch,
    loadingAcquirer,
    loading,
    handleAddModal,
    total,
    onTablePaginationOrFiltersOrSorterChange,
    pagination,
    listData,
    columnList,
    acquirers,
    addModalVisibility,
    editModalVisibility,
    editingRecord,
    handleEditModal,
    handleClearFilters,
  };
};
