import styled from 'styled-components';
import { colors } from 'styles/colors';
import { Form, Tabs, Typography } from 'antd';
import DatePicker from 'commons/DatePicker/DatePicker';

const { Title, Text } = Typography;

export const Container = styled.div`
  background-color: ${colors.gray1};
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 70px 63px 0 63px;
`;

export const HeaderTitle = styled(Title)`
  &&& {
    font-weight: 500;
    font-size: 20px;
  }
`;

export const HeaderText = styled(Text)`
  &&& {
    margin-left: 16px;
    font-size: 12px;
    font-weight: 400;
  }
`;

export const AgingForm = styled(Form)`
  display: flex;
  width: 100%;
  padding: 0 63px;
`;

export const AgingFormItem = styled(Form.Item)`
  .ant-form-item-label > label {
    font-size: 1.5rem;
  }
`;

export const CustomTabs = styled(Tabs)`
  .ant-tabs-nav {
    box-shadow: none !important;
    padding: 0 63px;
  }
`;

export const Overlay = styled.div`
  background-color: ${colors.gray3};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
`;

export const CustomDatePicker = styled(DatePicker)`
  width: 100%;

  input {
    text-transform: capitalize;
  }
`;

export const ContentContainer = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vh - 220px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${colors.gray3};
  z-index: 1;
`;

export const ContentImage = styled.img`
  max-width: 350px;
`;

export const ContentText = styled(Text)`
  font-size: 14px;
  text-align: center;
  margin-top: 26px;
`;
