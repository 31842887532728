import { useCallback, useEffect, useState } from 'react';
import { Form } from 'antd';
import { getStatus } from 'services/onboardingManagement/getStatus';
import { getSummary } from 'services/onboardingManagement/getSummary';
import {
  rowClassName,
  stageOptions,
  tableStatusParser,
} from '../constants/constants';
import getListOfPlansFromAPI from 'services/listOfPlans';
import errorHandler from 'helpers/errorHandler';
import ColumnList from '../components/Table/ColumnsList';
import { perPageOptions, showTotalPage } from 'constants/perPageOptions';
import { onColumnSort } from 'helpers/sorter';

export const useOnboardingManagement = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [changeCalendarIconColor, setChangeCalendarIconColor] = useState(false);
  const [plans, setPlans] = useState([{}]);
  const [status, setStatus] = useState([]);
  const [data, setData] = useState([]);
  const [columnList, setColumnList] = useState([]);
  const [pageCurrent, setPageCurrent] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [sortFields, setSortFields] = useState({});

  const initialValues = useCallback(() => {
    return {
      date: ['', ''],
      codClient: '',
      stage: '',
      plan: '',
      status: '',
    };
  }, []);

  const rules = {
    date: {
      required: true,
      async validator(_, value) {
        await new Promise((resolve, reject) => {
          if (value === null || value === undefined) {
            setChangeCalendarIconColor(true);
            reject(new Error('Campo obrigatório'));
          } else {
            setChangeCalendarIconColor(false);
            resolve();
          }
        });
      },
    },
    codClient: {
      async validator() {
        await new Promise((resolve, reject) => {
          if (form.getFieldError('codClient').length > 0) {
            reject(new Error(form.getFieldError('codClient')[0]));
          } else {
            resolve();
          }
        });
      },
    },
  };

  const pagination = {
    pageSize: perPage,
    pageSizeOptions: perPageOptions,
    total,
    current: pageCurrent,
    showTotal: showTotalPage,
    showSizeChanger: Boolean(total >= 10),
  };

  const onTablePaginationOrFiltersOrSorterChange = async (
    paginationHandler,
    tableFilters,
    sorter
  ) => {
    onColumnSort(sorter, setSortFields);
    setPerPage(paginationHandler.pageSize);
    setPageCurrent(paginationHandler.current);
  };

  const getPlans = useCallback(async () => {
    try {
      const { plans: apiPlans } = await getListOfPlansFromAPI();
      setPlans(apiPlans);
    } catch (error) {
      setPlans([{}]);
      errorHandler('Ocorreu um erro:', error);
    }
  }, []);

  const getOnboardingStatus = useCallback(async () => {
    try {
      const values = await getStatus();
      setStatus(values);
    } catch (error) {
      setStatus([]);
      errorHandler('Ocorreu um erro:', error);
    }
  }, []);

  const handleFormValues = (obj) => {
    let result = {};

    Object.entries(obj).forEach(([key, value]) => {
      if (value && value !== '') {
        result = {
          ...result,
          [key]: value,
        };
      }

      if (key === 'stage' && value === false) {
        result = {
          ...result,
          [key]: value,
        };
      }
    });

    return result;
  };

  const handleFilterValues = useCallback(
    ({ codClient = '', plan = '', status: _status = '', stage = '' }) => {
      const result = [];

      if (codClient) {
        result.push({
          name: 'cod_client',
          val: [codClient],
        });
      }
      if (plan) {
        result.push({ name: 'plan', val: [plan] });
      }
      if (_status) {
        result.push({ name: 'status', val: [_status] });
      }
      if (stage !== undefined && stage !== '') {
        result.push({ name: 'stage', val: [stage] });
      }

      return result;
    },
    []
  );

  const handleSortFields = useCallback((fields) => {
    let result = {};

    Object.entries(fields).forEach(([key, value]) => {
      if (value === 'ascend') {
        result = {
          ...result,
          [key]: 'asc',
        };
      }

      if (value === 'descend') {
        result = {
          ...result,
          [key]: 'desc',
        };
      }
    });

    if (Object.keys(result).length === 0) {
      return undefined;
    }

    return result;
  }, []);

  const getOnboardingSummary = useCallback(async () => {
    const startDate = form.getFieldValue('date')[0].format('YYYY-MM-DD');
    const endDate = form.getFieldValue('date')[1].format('YYYY-MM-DD');
    const filtersValues = handleFilterValues(form.getFieldsValue(true));
    const parsedSortFields = handleSortFields(sortFields);

    setLoading(true);

    try {
      const { results, meta } = await getSummary({
        page: pageCurrent,
        perPage,
        sortFields: parsedSortFields,
        startDate,
        endDate,
        q: { filter: filtersValues },
      });
      setData(results);
      setTotal(meta?.total);
    } catch (error) {
      setData([]);

      if (error?.response?.data?.data?.detail === 'Número inválido') {
        form.setFields([
          {
            name: 'codClient',
            errors: [error?.response?.data?.data?.detail],
          },
        ]);
      } else {
        errorHandler('Ocorreu um erro:', error);
      }
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, handleFilterValues, pageCurrent, perPage, sortFields]);

  const onFinish = async () => {
    await getOnboardingSummary(handleFormValues(form.getFieldsValue(true)));
  };

  const resetForm = () => {
    setData([]);
    setPageCurrent(1);
    setPerPage(10);
    setSortFields({});
    return form.resetFields();
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        await Promise.all([getPlans(), getOnboardingStatus()]);
      } catch (error) {
        errorHandler('Ocorreu um erro:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [getPlans, getOnboardingStatus]);

  useEffect(() => {
    if (form.getFieldValue('date') && form.getFieldValue('date').length > 0) {
      getOnboardingSummary();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageCurrent, perPage, sortFields]);

  useEffect(() => {
    setColumnList(ColumnList());
  }, [data]);

  return {
    form,
    rules,
    stageOptions,
    initialValues,
    resetForm,
    loading,
    setLoading,
    changeCalendarIconColor,
    setChangeCalendarIconColor,
    onFinish,
    plans,
    status,
    data,
    columnList,
    rowClassName,
    tableStatusParser,
    pagination,
    onTablePaginationOrFiltersOrSorterChange,
    sortFields,
  };
};
