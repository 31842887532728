import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { amountsReceivableDetailsStart } from 'store/ducks/details/amountsReceivableDetails/actions';
import {
  amountsReceivableDetailsFiltersZeroLength,
  updateAmountsReceivableDetailsFilters,
} from 'store/ducks/filters/details/amountsReceivableDetailsFilters/actions';
import { columns } from 'components/Reports/AmountsReceivableReports/utils';
import { MobileCards } from 'components/Reports/AmountsReceivableReports/components';
import { Tables } from 'commons/Tables';
import { createNotificationStart } from 'store/ducks/notifications/actions';
import { updateScreenToScreenDateStart } from 'store/ducks/updateScreenToScreenDate/actions';
import { generateFilterDataStructure } from 'helpers/tableService';
import {
  getSessionClientId,
  getSessionClientName,
} from 'helpers/sessionService';
import { MAX_CSV_ROWS, MAX_EXCEL_ROWS } from 'constants/general';
import { exportFileParser } from 'utils/parsers/exportFileParser';
import { useAcquirer } from 'utils/hooks/useAcquirer';
import { Button, Col, Dropdown, Form, Row, Select, Tooltip } from 'antd';
import { LabelAndFilter } from 'commons/LabelAndFilter';
import * as St from './styled';
import { colors } from 'styles/colors';
import { FileDoneOutlined } from '@ant-design/icons';
import { perPageOptions, showTotalPage } from 'constants/perPageOptions';
import { onColumnSort } from 'helpers/sorter';
import {
  eighteenMonthsAgoFromCurrentDay,
  eighteenMonthsAheadOfCurrentDayPlusOne,
} from 'helpers/dates';
import dayjs from 'dayjs';
import DatePicker from 'commons/DatePicker/DatePicker';
import { shortId } from 'helpers/shortId';
import { resetUpdateFilteredColumnsStart } from 'store/ducks/updateFilteredColumns/actions';
import { setReportsCustomColumns } from 'store/ducks/reportsCustomColumns/actions';
import { dataTestIdHandler } from 'helpers/dataTestIdHandler';
import { usePlansFeaturesPermissions } from 'utils/hooks/usePlansFeaturesPermissions';

const assetsBaseURL = process.env.REACT_APP_AWS_V4_ASSETS;
const notFound = `${assetsBaseURL}/img/notFound.png`;

const AmountsReceivableReports = () => {
  const { isFeatureAvailable } = usePlansFeaturesPermissions();
  const dispatch = useDispatch();
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const rangePickerRef = useRef(null);
  const { acquirers, getAllAcquirers } = useAcquirer();
  const [agingForm] = Form.useForm();

  const [hackValue, setHackValue] = useState(null);
  const [dates, setDates] = useState([null]);

  const loadingCreateNotification = useSelector(
    (state) => state.notifications.loadingCreateNotification
  );
  const permissionsData = useSelector((state) => state.permissions.data);
  const clientId = getSessionClientId();
  const clientName = getSessionClientName();
  const {
    scope_id: scopeId,
    profile_id: profileId,
    user_id: userId,
  } = permissionsData;

  const [dropdownOption, setDropdownOption] = useState('');
  const [agingDropdownOption, setAgingDropdownOption] = useState('');
  const [localFilters, setLocalFilters] = useState([]);

  const startDate = useSelector(
    (state) => state.updateScreenToScreenDate.forecastStartDate
  );
  const endDate = useSelector(
    (state) => state.updateScreenToScreenDate.forecastEndDate
  );

  const [sortFields, setSortFields] = useState({});

  const [perPage, setPerPage] = useState(10);
  const [pageTotal, setPageTotal] = useState(100);
  const [pageCurrent, setPageCurrent] = useState(1);
  const [disableExcel, setDisableExcel] = useState(false);
  const [disableCSV, setDisableCSV] = useState(false);

  const toParamsFilters = useSelector(
    (state) => state.amountsReceivableDetailsFilters.toParamsFilters
  );

  const amountsReceivableLoading = useSelector(
    (state) => state.amountsReceivableDetails.loading
  );
  const amountsReceivableDetails = useSelector(
    (state) => state.amountsReceivableDetails.details
  );
  const amountsReceivableMeta = useSelector(
    (state) => state.amountsReceivableDetails.meta
  );
  const amountsReceivableError = useSelector(
    (state) => state.amountsReceivableDetails.isError
  );
  const amountsReceivableNetAmount = useSelector(
    (state) => state.amountsReceivableDetails.netAmount
  );
  const filtersData = useSelector(
    (state) => state.amountsReceivableDetailsFilters.filters
  );

  const [calendarIsOpen, setCalendarIsOpen] = useState(false);

  const columnNames = [
    'Filial',
    'Data prevista do pagamento',
    'Adquirente',
    'Produto',
    'Bandeira',
    'Valor bruto (R$)',
    'Valor líquido (R$)',
  ];

  const dataSource = amountsReceivableDetails.map((obj) => ({
    ...obj,
    key: shortId(),
  }));

  const dataTestPrefix = 'amounts-receivable-reports';

  useEffect(() => {
    getAllAcquirers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetUpdateFilteredColumnsStart());
      dispatch(updateAmountsReceivableDetailsFilters([]));
      dispatch(setReportsCustomColumns([]));
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      amountsReceivableDetailsStart({
        startDate,
        endDate,
        sortFields,
        page: pageCurrent,
        perPage,
        q: {
          filter: toParamsFilters,
        },
      })
    );
  }, [
    dispatch,
    endDate,
    pageCurrent,
    perPage,
    sortFields,
    startDate,
    toParamsFilters,
  ]);

  useEffect(() => {
    setPageTotal(amountsReceivableMeta.num_results);
    if (amountsReceivableMeta.total_items > MAX_EXCEL_ROWS) {
      setDisableExcel(true);
    } else {
      setDisableExcel(false);
    }
    if (amountsReceivableMeta.total_items > MAX_CSV_ROWS) {
      setDisableCSV(true);
    } else {
      setDisableCSV(false);
    }
  }, [amountsReceivableMeta]);

  const onRangePickerChange = (_dates) => {
    if (_dates?.length) {
      dispatch(
        updateScreenToScreenDateStart({
          startDate: _dates[0],
          endDate: _dates[1],
        })
      );
      dispatch(amountsReceivableDetailsFiltersZeroLength());
    }
  };

  const onRangePickerOpenOrCloseChange = (open) => {
    setCalendarIsOpen(open);
  };

  const onTablePaginationOrFiltersOrSorterChange = (
    pagination,
    tableFilters,
    sorter
  ) => {
    const filterDataToDispatch = generateFilterDataStructure(tableFilters);

    onColumnSort(sorter, setSortFields);
    setLocalFilters(tableFilters);
    dispatch(updateAmountsReceivableDetailsFilters(filterDataToDispatch));
    setPerPage(pagination.pageSize);
    setPageTotal(amountsReceivableMeta.num_results);
    setPageCurrent(pagination.current);
  };

  const onPageMobileChange = (page, size) => {
    setPageCurrent(page);
    setPerPage(size);
  };

  const onPageSizeMobileChange = (current, size) => {
    setPageCurrent(current);
    setPerPage(size);
  };

  const onOpenChange = (open) => {
    onRangePickerOpenOrCloseChange(open);

    if (open) {
      setHackValue([startDate, null]);
    } else {
      setHackValue(null);
    }
  };

  const disabledDate = (current) => {
    return (
      (current && current < dayjs().subtract(1, 'day')) ||
      (current && current > eighteenMonthsAheadOfCurrentDayPlusOne)
    );
  };

  const disableDatePicker = (currentDate) => {
    const eighteenMonthsAgo = eighteenMonthsAgoFromCurrentDay;
    const dateLimitToSelect = dayjs().startOf('month').subtract(1, 'days');

    if (!currentDate) return true;

    if (
      eighteenMonthsAgo.isAfter(currentDate, 'month') ||
      currentDate.isAfter(dateLimitToSelect, 'month')
    ) {
      return true;
    }

    return false;
  };

  const onChangeDate = (currentDate) => {
    agingForm.setFieldsValue({
      date: currentDate,
    });
  };

  const onChangeAcquirer = (currentAcquirer) => {
    agingForm.setFieldsValue({ acquirer: currentAcquirer });
  };

  const fileName =
    'relatorio_valores_a_receber.' +
    (dropdownOption === 'excel' ? 'xlsx' : 'csv');

  const agingFileName =
    'relatorio_aging.' + (agingDropdownOption === 'excel' ? 'xlsx' : 'csv');

  const format = 'YYYY-MM-DD';

  const notificationConfig = {
    clientId,
    userId,
    status: 'PREPARANDO',
    title: 'Preparando arquivo',
    isRead: false,
    isSendEmail: false,
    fileName,
    isExpired: false,
    createdBy: userId,
    updatedBy: userId,
    message: `Estamos preparando seu arquivo ${fileName} para o download.`,
    table: 'forecast',
    fileType: dropdownOption === 'excel' ? 'xlsx' : 'csv',
    scopeId,
    profileId,
    startDate: dayjs(startDate).format(format),
    endDate: dayjs(endDate).format(format),
    q: {
      filter: exportFileParser(toParamsFilters),
    },
    sortFields: '',
    clientName,
  };
  const agingNotificationConfig = {
    message: `Estamos preparando seu arquivo ${agingFileName} para o download.`,
    fileType: agingDropdownOption === 'excel' ? 'xlsx' : 'csv',
    table: 'aging',
    startDate: dayjs(agingForm.getFieldValue('date'))
      .startOf('month')
      .format('YYYY-MM-DD'),
    acquirerId: agingForm.getFieldValue('acquirer'),
  };

  const exportOptions = [
    {
      key: 'excel',
      label: 'Excel',
      onClick: () => {
        setAgingDropdownOption('excel');
      },
      'data-testid': `${dataTestPrefix}-generate-report-btn-excel`,
    },
    {
      key: 'csv',
      label: 'CSV',
      onClick: () => {
        setAgingDropdownOption('csv');
      },
      'data-testid': `${dataTestPrefix}-generate-report-btn-csv`,
    },
  ];

  const [activeKey, setActiveKey] = useState('amountsReceivable');

  useEffect(() => {
    const validateForm = async () => {
      try {
        if (agingDropdownOption === '') return;
        await agingForm.validateFields();
        if (agingDropdownOption === 'excel' || agingDropdownOption === 'csv') {
          dispatch(createNotificationStart(agingNotificationConfig));
          setAgingDropdownOption('');
        }
      } catch (error) {
        setAgingDropdownOption('');
      }
    };

    validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agingDropdownOption]);

  const handleTabChange = (key) => {
    if (key) {
      setActiveKey(key);
    }
  };

  const [showTable, setShowTable] = useState(true);

  const resetFiltersStates = () => {
    dispatch(amountsReceivableDetailsFiltersZeroLength());
    dispatch(updateAmountsReceivableDetailsFilters([]));
    dispatch(resetUpdateFilteredColumnsStart());
    dispatch(setReportsCustomColumns([]));
    setSortFields({});
    setPageCurrent(1);
  };

  const handleResetFilters = () => {
    setShowTable(false);
    resetFiltersStates();
  };

  useEffect(() => {
    setShowTable(true);
  }, [showTable]);

  const items = [
    {
      label: (
        <Tooltip
          title="É a visão do saldo a receber, a partir de amanhã"
          placement="right"
        >
          <span>Valores a receber</span>
        </Tooltip>
      ),
      key: 'amountsReceivable',
      children: (
        <>
          <MobileCards
            data={dataSource}
            loading={amountsReceivableLoading}
            pagination={{
              current: pageCurrent,
              pageSize: perPage,
              total: pageTotal,
              pageSizeOptions: perPageOptions,
              showTotal: showTotalPage,
            }}
            error={amountsReceivableError}
            onPageSizeChange={onPageSizeMobileChange}
            onPageChange={onPageMobileChange}
          />

          <LabelAndFilter label="Calendário" width="450px" padding="0 63px">
            <RangePicker
              ref={rangePickerRef}
              format="DD/MM/YYYY"
              onOpenChange={onOpenChange}
              onCalendarChange={(val) => {
                setHackValue(val);
                setDates(val);
                if (val?.every((v) => v)) {
                  setCalendarIsOpen(!calendarIsOpen);
                }
              }}
              disabled={amountsReceivableLoading}
              disabledDate={(current) => {
                if (dates) {
                  return disabledDate(current, ...dates);
                }
                return disabledDate(current, startDate, endDate);
              }}
              open={calendarIsOpen}
              value={hackValue || [startDate, endDate]}
              onChange={onRangePickerChange}
              style={{ width: '100%' }}
            />
          </LabelAndFilter>

          <div style={{ position: 'relative' }}>
            {showTable && (
              <Tables
                dataSource={dataSource}
                totalText="Total líquido a receber:"
                columns={columns(filtersData, localFilters, toParamsFilters)}
                loading={amountsReceivableLoading}
                error={amountsReceivableError}
                headerNetAmount={amountsReceivableNetAmount}
                createNotificationAction={createNotificationStart}
                notificationConfig={notificationConfig}
                dropdownOption={dropdownOption}
                setDropdownOption={setDropdownOption}
                pagination={{
                  current: pageCurrent,
                  pageSize: perPage,
                  total: pageTotal,
                  pageSizeOptions: perPageOptions,
                  showTotal: showTotalPage,
                }}
                onChange={onTablePaginationOrFiltersOrSorterChange}
                defaultSelectedColumns={columnNames}
                disableExcel={disableExcel}
                disableCSV={disableCSV}
                containerStyle={{
                  marginTop: '20px',
                  backgroundColor: colors.gray3,
                  position: 'relative',
                  zIndex: 1,
                  padding: '5px 22px 0',
                }}
                handleResetFilters={handleResetFilters}
              />
            )}

            <St.Overlay />
          </div>
        </>
      ),
    },

    isFeatureAvailable({
      key: 'reports-aging-tab',
    }) && {
      label: (
        <Tooltip
          title="É a visão do saldo a receber de períodos retroativos"
          placement="right"
        >
          <span>Aging</span>
        </Tooltip>
      ),
      key: 'aging',
      children: (
        <>
          <Row gutter={[16, 0]}>
            <St.AgingForm
              form={agingForm}
              name="agingForm"
              layout="vertical"
              requiredMark={false}
              initialValues={{
                date: '',
                acquirer: '',
              }}
            >
              <Col span={4}>
                <St.AgingFormItem
                  label="Calendário"
                  colon={false}
                  name="date"
                  rules={[
                    () => ({
                      validator(_, value) {
                        if (disableDatePicker(value)) {
                          return Promise.reject(
                            new Error('Informe uma data válida')
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Row>
                    <St.CustomDatePicker
                      picker="month"
                      format="MMMM YYYY"
                      allowClear={false}
                      onChange={onChangeDate}
                      disabledDate={disableDatePicker}
                      {...dataTestIdHandler(dataTestPrefix, 'date-picker')}
                    />
                  </Row>
                </St.AgingFormItem>
              </Col>
              <Col span={4}>
                <St.AgingFormItem
                  label="Adquirente"
                  colon={false}
                  name="acquirer"
                  rules={[{ required: true, message: 'Campo obrigatório' }]}
                >
                  <Row>
                    <Select
                      style={{ width: '100%' }}
                      placeholder="Selecione"
                      onChange={onChangeAcquirer}
                      {...dataTestIdHandler(dataTestPrefix, 'acquirer-select')}
                    >
                      {acquirers.map(({ acquirer_id: acquirerId, name }) => (
                        <Option
                          key={acquirerId}
                          value={acquirerId}
                          {...dataTestIdHandler(dataTestPrefix, name)}
                        >
                          {name}
                        </Option>
                      ))}
                    </Select>
                  </Row>
                </St.AgingFormItem>
              </Col>
            </St.AgingForm>
          </Row>

          <St.ContentContainer>
            <St.ContentImage src={notFound} alt="not found img" />
            <St.ContentText>
              Para gerar sua visão de Aging, selecione o mês da posição do saldo
              em aberto que é informado na carta de circularização e clique em
              “Gerar relatório”. <br /> Assim que o resultado estiver
              disponível, você será notificado pela central de notificações e
              poderá baixar seu relatório.
            </St.ContentText>

            <Dropdown
              menu={{ items: exportOptions }}
              disabled={loadingCreateNotification}
              {...dataTestIdHandler(
                dataTestPrefix,
                'generate-report-btn-dropdown'
              )}
            >
              <Button
                type="primary"
                icon={<FileDoneOutlined />}
                style={{ marginTop: '46px' }}
                {...dataTestIdHandler(dataTestPrefix, 'generate-report-btn')}
              >
                Gerar relatório
              </Button>
            </Dropdown>
          </St.ContentContainer>
        </>
      ),
    },
  ];

  return (
    <St.Container>
      <St.HeaderContainer>
        <St.HeaderTitle>Valores a receber</St.HeaderTitle>
        <St.HeaderText>
          Visualize os valores previstos a receber no período selecionado
        </St.HeaderText>
      </St.HeaderContainer>

      <St.CustomTabs
        onChange={handleTabChange}
        activeKey={activeKey}
        items={items}
      />
    </St.Container>
  );
};

export default AmountsReceivableReports;
