import { call, put } from 'redux-saga/effects';
import errorHandler from 'helpers/errorHandler';
import {
  deleteFeeSuccess,
  deleteFeeError,
} from 'store/ducks/fees/deleteFee/actions';

import { notification } from 'antd';
import deleteFee from 'services/fees/deleteFee';

export function* deleteFeeSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(deleteFee, payload);
    yield put(deleteFeeSuccess(apiData));
    notification.success({
      message: 'Solicitação de exclusão realizada com sucesso.',
    });
  } catch (err) {
    yield put(deleteFeeError('Ocorreu um erro:', err));
    if (err?.response?.data?.message) {
      yield put(
        deleteFeeError(err.response.data.message, err.response.data.error)
      );
      errorHandler(err.response.data.message, err.response.data.error);
    } else {
      yield put(deleteFeeError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
      errorHandler(err.response.data.message, err.response.data.error);
    }
  }
}
