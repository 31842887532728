import { action } from 'typesafe-actions';
import { actionTypes } from 'store/ducks/credit/creditInteractRetryApproval/types';

export const creditInteractRetryApprovalStart = (data) =>
  action(actionTypes.CREDIT_INTERACT_RETRY_APPROVAL_START, data);
export const creditInteractRetryApprovalSuccess = (data) =>
  action(actionTypes.CREDIT_INTERACT_RETRY_APPROVAL_SUCCESS, data);
export const creditInteractRetryApprovalError = (errorMessage, error) =>
  action(actionTypes.CREDIT_INTERACT_RETRY_APPROVAL_ERROR, { errorMessage }, undefined, {
    error,
  });
