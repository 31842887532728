import { actionTypes } from 'store/ducks/reconciliationRules/getAcquirersByClientId/types';

const INITIAL_STATE = {
  error: undefined,
  errorMessage: undefined,
  loading: false,
  isError: false,
  success: false,
  acquirers: {},
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case actionTypes.GET_ACQUIRERS_BY_CLIENT_ID_START:
    return {
      ...state,
      loading: true,
      error: undefined,
      errorMessage: undefined,
      success: false,
      isError: false,
    };
  case actionTypes.GET_ACQUIRERS_BY_CLIENT_ID_SUCCESS:
    return {
      ...state,
      acquirers: action?.payload?.acquirers,
      loading: false,
      error: undefined,
      isError: false,
      success: true,
      errorMessage: undefined,
    };
  case actionTypes.GET_ACQUIRERS_BY_CLIENT_ID_ERROR:
    return {
      ...state,
      acquirers: {},
      error: action.error.error,
      errorMessage: action.payload,
      loading: false,
      isError: true,
      success: false,
    };
  default:
    return state;
  }
};

export default reducer;
