import { lambdaApi } from '../api';

const transformer = (apiResponse) => {
  const { data, meta } = apiResponse;
  const transformedAcquirers = [];
  Object.entries(data).forEach((entry) => {
    transformedAcquirers.push({
      acquirer: entry[0],
      detail: entry[1],
    });
  });

  return {
    acquirers: transformedAcquirers,
    meta: meta,
  };
};
const getAcquirerListFromAPI = async ({ merchantCode }) => {
  const config = {
    params: {
      merchant_code: merchantCode,
    },
  };
  const response = await lambdaApi().get(
    '/settings/maintenance/merchants/summary',
    config
  );
  return transformer(response.data);
};
export default getAcquirerListFromAPI;
