import { call, put } from 'redux-saga/effects';

import { getPlansFromAPI } from 'services/plans';

import { plansSuccess, plansError } from 'store/ducks/plans/actions';
import errorHandler from 'helpers/errorHandler';

export function* plansSaga() {
  try {
    const { plans } = yield call(getPlansFromAPI);
    yield put(plansSuccess(plans));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        plansError(err.response.data.error.message, err.response.data.error)
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(plansError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}
