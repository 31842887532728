import React from 'react';
import PropTypes from 'prop-types';
import { StSearchBarContainer } from './styled';
import { Col, Input } from 'antd';

const SearchBar = ({ searchTopic, onSearch, onChange, value }) => {
  let placeholder = '';
  switch (searchTopic) {
  case 'empresa':
    placeholder = 'Insira o código ou nome da empresa';
    break;
  case 'parceiro':
    placeholder = 'Insira o ID parceiro';
    break;
  case 'usuário':
    placeholder = 'E-mail do usuário';
    break;
  default:
    placeholder = '';
    break;
  }

  return (
    <StSearchBarContainer>
      <Col span={24}>{`Pesquisar ${searchTopic}`}</Col>
      <Col span={24} style={{ marginTop: '4px' }}>
        <Input.Search
          placeholder={placeholder}
          enterButton
          onSearch={onSearch}
          onChange={onChange}
          value={value}
        />
      </Col>
    </StSearchBarContainer>
  );
};

SearchBar.propTypes = {
  searchTopic: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default SearchBar;
