import { action } from 'typesafe-actions';
import { actionTypes } from './types';

export const getAcquirersByClientIdStart = (data) =>
  action(actionTypes.GET_ACQUIRERS_BY_CLIENT_ID_START, data);
export const getAcquirersByClientIdSuccess = (data) =>
  action(actionTypes.GET_ACQUIRERS_BY_CLIENT_ID_SUCCESS, data);
export const getAcquirersByClientIdError = (errorMessage, error) =>
  action(
    actionTypes.GET_ACQUIRERS_BY_CLIENT_ID_ERROR,
    { errorMessage },
    undefined,
    {
      error,
    }
  );
