/* eslint-disable */
/* eslint-disable no-console */

const userGuidingId = process.env.REACT_APP_USERGUIDING_KEY;

export const UserGuiding = () => {
  if (!userGuidingId) {
    console.log('User Guiding key not found');
    return;
  }

  const script = (g, u, i, d, e, s) => {
    g[e] = g[e] || [];
    var f = u.getElementsByTagName(i)[0];
    var k = u.createElement(i);
    k.async = true;
    k.src =
      'https://static.userguiding.com/media/user-guiding-' + s + '-embedded.js';
    f.parentNode.insertBefore(k, f);
    if (g[d]) return;
    var ug = (g[d] = { q: [] });
    ug.c = function (n) {
      return function () {
        ug.q.push([n, arguments]);
      };
    };
    var m = [
      'previewGuide',
      'finishPreview',
      'track',
      'identify',
      'triggerNps',
      'hideChecklist',
      'launchChecklist',
    ];
    for (var j = 0; j < m.length; j += 1) {
      ug[m[j]] = ug.c(m[j]);
    }
    console.log(`User Guiding ${process.env.REACT_APP_BUILD_ENV} init`);
  };

  script(
    window,
    document,
    'script',
    'userGuiding',
    'userGuidingLayer',
    userGuidingId
  );
};
