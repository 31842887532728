/* eslint-disable no-irregular-whitespace */
/* eslint-disable no-mixed-operators */
/* eslint-disable consistent-return */
import React from 'react';
import { Modal, Typography } from 'antd';

const { Paragraph, Link } = Typography;

const assetsBaseURL = process.env.REACT_APP_AWS_V4_ASSETS;
const binImg = `${assetsBaseURL}/briefing/modal/bin.png`;
const getnetImg = `${assetsBaseURL}/briefing/modal/getnet.png`;

export const modal = (
  acquirerId,
  acquirerName,
  isModalVisible,
  setIsModalVisible,
  currentAcquirerId
) => {
  return (
    <Modal
      title={`Número de estabelecimento ${acquirerName}`}
      open={isModalVisible}
      onOk={() => setIsModalVisible(!isModalVisible)}
      cancelButtonProps={{ style: { display: 'none' } }}
      closable={false}
      centered
      destroyOnClose
      afterClose={Modal.destroyAll()}
      maskClosable
    >
      {(acquirerId === 20 ||
        acquirerId === 44 ||
        acquirerId === 42 ||
        acquirerId === 50 ||
        acquirerId === 36 ||
        acquirerId === 63 ||
        acquirerId === 31 ||
        acquirerId === 58 ||
        acquirerId === 60 ||
        acquirerId === 38 ||
        acquirerId === 40 ||
        acquirerId === 61 ||
        acquirerId === 59 ||
        acquirerId === 42 ||
        acquirerId === 41 ||
        acquirerId === 15 ||
        acquirerId === 102) &&
        acquirerId === currentAcquirerId && (
          <p>{`O número de estabelecimento da ${acquirerName} é o seu CNPJ.`}</p>
        )}
      {acquirerId === 32 && acquirerId === currentAcquirerId && (
        <Paragraph>
          O número de estabelecimento da Adyen é identificado como{' '}
          <strong>Merchant Account</strong> e pode ser obtido através do
          telefone 11 3197-4315 ou pelo{' '}
          <Link
            href="https://www.adyen.com/pt_BR/"
            target="_blank"
            rel="noopener noreferrer"
          >
            portal do cliente.
          </Link>
        </Paragraph>
      )}
      {acquirerId === 65 && acquirerId === currentAcquirerId && (
        <Paragraph>
          O número de estabelecimento da <strong>Alelo</strong> pode ser obtido
          através dos telefones 11 4002-5011 (capitais) e 0800-570-011 (demais
          localidades) ou pelo  
          <Link
            href="https://login-meuestabelecimento.alelo.com.br/mga/sps/auth"
            target="_blank"
            rel="noopener noreferrer"
          >
            portal do cliente.
          </Link>
          <br />
          <br />
          <strong>ATENÇÃO:</strong> O número de estabelecimento Alelo possui 10
          dígitos, por isso despreze os 2 primeiros dígitos a esquerda e os 4
          últimos dígitos a direita.
        </Paragraph>
      )}
      {acquirerId === 18 && acquirerId === currentAcquirerId && (
        <Paragraph>
          O número de estabelecimento da <strong>BIN</strong> pode ser
          encontrado no <strong>comprovante de vendas</strong>, diretamente na
          sua máquina. Você também pode conseguir o número de estabelecimento
          através dos telefones 3004-2017 (capitais) e 0800-757-1017 (demais
          localidades) ou pelo 
          <Link
            href="https://www.bin.com.br"
            target="_blank"
            rel="noopener noreferrer"
          >
            portal do cliente.
          </Link>
          <br />
          <br />
          <div style={{ textAlign: 'center' }}>
            <img src={binImg} alt="bin" />
          </div>
        </Paragraph>
      )}
      {acquirerId === 1 && acquirerId === currentAcquirerId && (
        <Paragraph>
          O número de estabelecimento da <strong>CIELO</strong> pode ser
          encontrado no <strong>comprovante de vendas</strong>, diretamente na
          sua máquina. Você também pode conseguir o número de estabelecimento
          através dos telefones 4002-5472 (capitais) e 0800-570-8472 (demais
          localidades) ou pelo 
          <Link
            href="https://www.cielo.com.br/"
            target="_blank"
            rel="noopener noreferrer"
          >
            portal do cliente.
          </Link>
          <br />
          <br />
          <strong>ATENÇÃO:</strong> O número de estabelecimento Cielo possui 10
          dígitos, por isso despreze os 2 primeiros dígitos a esquerda e os 4
          últimos dígitos a direita.
        </Paragraph>
      )}
      {acquirerId === 13 && acquirerId === currentAcquirerId && (
        <Paragraph>
          O número de estabelecimento da <strong>CREDSYSTEM</strong> pode ser
          obtido através dos telefones 11 3350-1901 (capitais) e 0800-770-1380
          (demais localidades) ou pelo 
          <Link
            href="http://portalcliente.credsystem.com.br/"
            target="_blank"
            rel="noopener noreferrer"
          >
            portal do cliente.
          </Link>
        </Paragraph>
      )}
      {acquirerId === 11 && acquirerId === currentAcquirerId && (
        <Paragraph>
          O número de estabelecimento da <strong>DACASA</strong> pode ser obtido
          através dos telefones 4002-3123 (capitais) e 0800-283-9123 (demais
          localidades) ou pelo 
          <Link
            href="https://www.cartoesdacasa.com.br"
            target="_blank"
            rel="noopener noreferrer"
          >
            portal do cliente.
          </Link>
        </Paragraph>
      )}
      {acquirerId === 19 && acquirerId === currentAcquirerId && (
        <Paragraph>
          O número de estabelecimento da <strong>DMCARD</strong> pode ser obtido
          através dos telefones (12) 2136-0100 e 0800-702-5004 (demais
          localidades) ou pelo 
          <Link
            href="https://www.dmcard.com.br/portal/"
            target="_blank"
            rel="noopener noreferrer"
          >
            portal do cliente.
          </Link>
        </Paragraph>
      )}
      {acquirerId === 9 && acquirerId === currentAcquirerId && (
        <Paragraph>
          O número de estabelecimento da <strong>GETNET</strong> pode ser
          encontrado no <strong>comprovante de vendas</strong>, diretamente na
          sua máquina. Você também pode conseguir o número de estabelecimento
          através dos telefones 4002-4000 (capitais e regiões metropolitanas) e
          0800-648-8000 (demais localidades) ou{' '}
          <Link
            href="https://site.getnet.com.br/portal-do-cliente/"
            target="_blank"
            rel="noopener noreferrer"
          >
            portal do cliente.
          </Link>
          <br />
          <br />
          <div style={{ textAlign: 'center' }}>
            <img src={getnetImg} alt="getnet" />
          </div>
        </Paragraph>
      )}
      {acquirerId === 34 && acquirerId === currentAcquirerId && (
        <Paragraph>
          O número de estabelecimento da <strong>GOODCARD</strong> pode ser
          obtido através do{' '}
          <Link
            href="https://www.goodcard.com.br/EstabelecimentoSSL/Login/Home.cfm?cd_manutencao_oficina=&fl_tipo=&cd_cotacao_oficina="
            target="_blank"
            rel="noopener noreferrer"
          >
            portal do cliente.
          </Link>
        </Paragraph>
      )}
      {acquirerId === 56 && acquirerId === currentAcquirerId && (
        <Paragraph>
          O número de estabelecimento do <strong>Ifood</strong> é identificado
          como <strong>ID do Restaurante</strong> e pode ser obtido pelo 
          <Link
            href="https://portal.ifood.com.br/login"
            target="_blank"
            rel="noopener noreferrer"
          >
            portal do cliente.
          </Link>
        </Paragraph>
      )}
      {acquirerId === 10 && acquirerId === currentAcquirerId && (
        <Paragraph>
          O número de estabelecimento da <strong>LOSANGO</strong> pode ser
          obtido através do{' '}
          <Link
            href="https://www2.losango.com.br/cartao-de-credito"
            target="_blank"
            rel="noopener noreferrer"
          >
            portal do cliente.
          </Link>
        </Paragraph>
      )}
      {acquirerId === 39 && acquirerId === currentAcquirerId && (
        <Paragraph>
          O número do estabelecimento da PagSeguro é chamado de{' '}
          <strong>ID</strong> e pode ser obtido na central da PagSeguro ou com o
          executivo da conta. Central de atendimento capital e região
          metropolitana: 11 4003-1775 (escolha as opções 2 | 2 | 1 | 4 do menu).{' '}
          <br />
          Para demais localidades: 0800-728-2174
        </Paragraph>
      )}
      {acquirerId === 49 && acquirerId === currentAcquirerId && (
        <Paragraph>
          O número de estabelecimento da <strong>PLANVALE</strong> pode ser
          obtido através do{' '}
          <Link
            href="https://www.upbrasil.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            portal do cliente.
          </Link>
        </Paragraph>
      )}
      {acquirerId === 45 && acquirerId === currentAcquirerId && (
        <Paragraph>
          O número de estabelecimento da <strong>POLICARD</strong> pode ser
          obtido através do{' '}
          <Link
            href="https://www.upbrasil.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            portal do cliente.
          </Link>
        </Paragraph>
      )}
      {acquirerId === 2 && acquirerId === currentAcquirerId && (
        <Paragraph>
          O número de estabelecimento da <strong>REDECARD</strong> pode ser
          encontrado no <strong>comprovante de vendas</strong>, diretamente na
          sua máquina. Você também pode conseguir o número de estabelecimento
          através dos telefones 4001-4433 (capitais) e 0800 728 4433 (demais
          localidades) ou pelo 
          <Link
            href="https://www.userede.com.br/"
            target="_blank"
            rel="noopener noreferrer"
          >
            portal do cliente.
          </Link>
        </Paragraph>
      )}
      {acquirerId === 54 && acquirerId === currentAcquirerId && (
        <Paragraph>
          O número de estabelecimento da <strong>SICREDI</strong> pode ser
          obtido através dos telefones 3003-7828 (capitais) e 0800-728-7828
          (demais localidades) ou pelo 
          <Link
            href="https://www.sicredi.merchantportal.com.br/cf9/merchant_portal/signon.cfm"
            target="_blank"
            rel="noopener noreferrer"
          >
            portal do cliente.
          </Link>
        </Paragraph>
      )}
      {acquirerId === 35 && acquirerId === currentAcquirerId && (
        <>
          <Paragraph>
            O número de estabelecimento da <strong>SIPAG</strong> pode ser
            obtido através dos telefones 3004-2013 (capitais) e 0800-757-1013
            (demais localidades) ou pelo 
            <Link
              href="https://www.sipag.com.br/"
              target="_blank"
              rel="noopener noreferrer"
            >
              portal do cliente.
            </Link>
          </Paragraph>
          <Paragraph>
            Vendas realizadas pela Sipaguinha não possuem dados estruturados no
            formato necessário para conciliação (extrato{' '}
            <Link
              href="https://atendimento.concil.com.br/kb/article/198858/o-que-e-extrato-eletronico-edi-novo?ticketId=&q=edi"
              target="_blank"
              rel="noopener noreferrer"
            >
              EDI
            </Link>
            ).
          </Paragraph>
        </>
      )}
      {acquirerId === 7 && acquirerId === currentAcquirerId && (
        <Paragraph>
          O número de estabelecimento da <strong>SODEXO</strong> pode ser obtido
          através do{' '}
          <Link
            href="https://www.sodexobeneficios.com.br/estabelecimentos/login/?accessError=2"
            target="_blank"
            rel="noopener noreferrer"
          >
            portal do cliente.
          </Link>
        </Paragraph>
      )}
      {acquirerId === 17 && acquirerId === currentAcquirerId && (
        <>
          <Paragraph>
            O número de estabelecimento da <strong>STONE</strong> é identificado
            como <strong>Stone Code</strong> e pode ser obtido através dos
            telefones 3004-9680 (capitais) e 0800-326-0506 (demais localidades)
            ou pelo 
            <Link
              href="https://portal.stone.com.br/login"
              target="_blank"
              rel="noopener noreferrer"
            >
              portal do cliente.
            </Link>
          </Paragraph>
          <Paragraph>
            Vendas realizadas pela Ton não possuem dados estruturados no formato
            necessário para conciliação (extrato{' '}
            <Link
              href="https://atendimento.concil.com.br/kb/article/198858/o-que-e-extrato-eletronico-edi-novo"
              target="_blank"
              rel="noopener noreferrer"
            >
              EDI
            </Link>
            ).
          </Paragraph>
        </>
      )}
      {acquirerId === 8 && acquirerId === currentAcquirerId && (
        <Paragraph>
          O número de estabelecimento da <strong>TICKET</strong> é identificado
          <strong>Número de Contrato</strong> e pode ser pelo 
          <Link
            href="https://www.ticket.com.br/portal-estabelecimento/login"
            target="_blank"
            rel="noopener noreferrer"
          >
            portal do cliente.
          </Link>
        </Paragraph>
      )}
      {acquirerId === 55 && acquirerId === currentAcquirerId && (
        <Paragraph>
          O número de estabelecimento da <strong>TRICARD</strong> pode ser
          obtido através dos telefones (34) 3303-5506 (capitais) e 0800-701-5506
          (demais localidades) ou pelo 
          <Link
            href="https://websec.tricard.com.br/Tricard.Site.WebUI/para-sua-loja-resolva-online.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            portal do cliente.
          </Link>
        </Paragraph>
      )}
      {acquirerId === 57 && acquirerId === currentAcquirerId && (
        <Paragraph>
          O número de estabelecimento da <strong>TRIPAG</strong> pode ser obtido
          através dos telefones (34) 3003-5506 (capitais) e 0800-701-5506
          (demais localidades) ou pelo 
          <Link
            href="https://portal.tripag.com.br/#/login"
            target="_blank"
            rel="noopener noreferrer"
          >
            portal do cliente.
          </Link>
        </Paragraph>
      )}
      {acquirerId === 101 && acquirerId === currentAcquirerId && (
        <Paragraph>
          O número de estabelecimento da <strong>Caixa pagamentos</strong> pode
          ser obtido através dos telefones 3003-5365 (capital e Grande São
          Paulo) e 0800-729-5365 (demais localidades) ou pelo{' '}
          <Link
            href="https://www.portalazulzinhadacaixa.com.br/cefMerchantUI/#/"
            target="_blank"
            rel="noopener noreferrer"
          >
            portal do cliente.
          </Link>
        </Paragraph>
      )}
      {acquirerId === 104 && acquirerId === currentAcquirerId && (
        <Paragraph>
          O número de estabelecimento da <strong>POP Credicard</strong> pode ser
          obtido através do telefone 3003-1004 (Capitais e regiões
          metropolitanas) e 0800-757-1004 (Demais localidades) ou pelo{' '}
          <Link
            href="https://redepop.oneportal24.com/credMerchantUI/#/"
            target="_blank"
            rel="noopener noreferrer"
          >
            portal do cliente.
          </Link>
        </Paragraph>
      )}
      {acquirerId === 103 && acquirerId === currentAcquirerId && (
        <Paragraph>
          O número de estabelecimento da <strong>Afinz</strong> pode ser obtido
          através do telefone 0800-000-0200 (Segunda à Sexta das 08h às 22h,
          Sábado das 08h às 20h, Domingo e Feriado das 09h às 19h) ou pelo{' '}
          <Link
            href="https://afinz.oneportal24.com/afzMerchantUI/#/"
            target="_blank"
            rel="noopener noreferrer"
          >
            portal do cliente.
          </Link>
        </Paragraph>
      )}
      {acquirerId === 69 && acquirerId === currentAcquirerId && (
        <Paragraph>
          O número de estabelecimento da <strong>UP Brasil</strong> pode ser
          obtido através do telefone 0800 940 4300 ou pelo{' '}
          <Link
            href="https://www.upbrasil.com/atendimento-estabelecimentos/"
            target="_blank"
            rel="noopener noreferrer"
          >
            portal do cliente.
          </Link>
        </Paragraph>
      )}
    </Modal>
  );
};
