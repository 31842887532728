import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Divider,
  Popconfirm,
  Row,
  Spin,
  Switch,
  Tooltip,
} from 'antd';
import { isOperational, isSuperAdmin } from 'helpers/authorizationService';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { colors } from 'styles/colors';
import {
  ActionsContainer,
  StAguardando,
  StEditOutlined,
  StLoadingOutlined,
  StReprovado,
  StStatusText,
  StTextItem,
  StTexto,
  StTitleItem,
  StValueItem,
} from './styled';
import { CnpjMaintenanceContext } from 'components/CnpjMaintenance/CnpjMaintenance';

function StoreListItem({
  storeDetails,
  openAddModal,
  openUpdateStoreModal,
  onClickRemove,
  onClickActive,
}) {
  const { setCurrentStoreToUpdate } = useContext(CnpjMaintenanceContext);
  const resendStore = () => {};

  function info(store) {
    let dados = {};

    switch (store) {
      case 1:
        dados = {
          status: <StAguardando>Aguardando aprovação</StAguardando>,
          texto: (
            <StTexto>
              Assim que o CNPJ for liberado, você poderá adicionar adquirentes
            </StTexto>
          ),
        };
        break;
      case 2:
        dados = {
          exclusao: <></>,
          texto: (
            <StTexto>
              Seu CNPJ foi cadastrado com sucesso. Deseja{' '}
              <Button
                onClick={() => openAddModal()}
                style={{
                  padding: '0px',
                  border: '0px',
                  fontSize: '11px',
                  fontWeight: '400',
                  color: colors.blue6,
                }}
              >
                incluir adquirente?
              </Button>
            </StTexto>
          ),
        };
        break;
      case 3:
        dados = {
          status: <StReprovado>CNPJ reprovado</StReprovado>,
          reenvio: <Button onClick={() => resendStore()}>Reenviar CNPJ</Button>,
          texto: (
            <StTexto>
              Você atingiu o limite de CNPJs cadastrados. Caso seja um erro,
              clique em Reenviar CNPJ ou entre em contato com nosso time de
              relacionamento.
            </StTexto>
          ),
        };
        break;
      default:
        dados = {};
    }

    return dados;
  }

  const deleteStoresLoading = useSelector(
    (state) => state.deleteStores.storesLoading
  );

  const putStoresLoading = useSelector(
    (state) => state.putStores.storesLoading
  );

  const actionsStore = () => {
    const {
      store_id: storeId,
      is_active: isStoreActive,
      is_empty: isEmpty,
    } = storeDetails;

    let tooltipTitle = '';
    let popConfirmTitle = '';
    let editTooltipTitle = 'Editar a loja';

    const canEditStore = storeDetails?.can_edit;
    const canEditShortName = !isOperational() && canEditStore;
    const storeCanBeDeactivated = !isOperational() && canEditStore && isEmpty;
    const canIActivateAStore = isSuperAdmin() && canEditStore && !isStoreActive;

    if (!isEmpty) {
      tooltipTitle =
        'Há estabelecimentos ativos para esse CNPJ e ele não pode ser inativado';
    }
    if (isStoreActive && storeCanBeDeactivated) {
      tooltipTitle = 'Inativar CNPJ';
      popConfirmTitle = 'Tem certeza que deseja inativar o CNPJ?';
    }
    if (!isStoreActive && canIActivateAStore) {
      tooltipTitle = 'Ativar CNPJ';
      popConfirmTitle = 'Tem certeza que deseja ativar o CNPJ?';
    }
    if (isStoreActive && isOperational()) {
      tooltipTitle = 'Este perfil de usuário não pode inativar um CNPJ.';
    }

    if (!canEditStore) {
      editTooltipTitle =
        'A atualização do cadastro da loja está em andamento. A conclusão será informada na área de notificações.';
      tooltipTitle =
        'A atualização do cadastro da loja está em andamento. A conclusão será informada na área de notificações.';
    }

    const isStoreLoading = (storeLoadingArray, _storeId) =>
      storeLoadingArray.includes(_storeId);

    return (
      <Row
        style={{
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'space-evenly',
        }}
      >
        <Col
          span={2}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {!canEditStore && (
            <Tooltip title="A atualização do cadastro da loja está em andamento. A conclusão será informada na área de notificações.">
              <Spin indicator={<StLoadingOutlined />} />
            </Tooltip>
          )}
        </Col>
        <Col span={8}>
          <ActionsContainer>
            <StTitleItem style={{ fontSize: 12 }}>Ações</StTitleItem>
            <Row style={{ display: 'flex', alignItems: 'center' }}>
              {!isOperational() && (
                <Tooltip title={editTooltipTitle}>
                  <StEditOutlined
                    disabled={!canEditShortName}
                    onClick={() => {
                      if (canEditShortName) {
                        openUpdateStoreModal();
                        setCurrentStoreToUpdate(storeDetails);
                      }
                    }}
                  />
                </Tooltip>
              )}
              {isStoreActive ? (
                <Popconfirm
                  disabled={!storeCanBeDeactivated || isOperational()}
                  icon={<ExclamationCircleOutlined />}
                  title={popConfirmTitle}
                  onConfirm={() => {
                    onClickRemove();
                  }}
                  onCancel={() => {}}
                  okText="Sim"
                  cancelText="Não"
                >
                  <Tooltip
                    autoAdjustOverflow
                    placement="bottom"
                    title={tooltipTitle}
                  >
                    <Switch
                      size="small"
                      checked={isStoreActive}
                      disabled={!storeCanBeDeactivated || isOperational()}
                      loading={isStoreLoading(deleteStoresLoading, storeId)}
                    />
                  </Tooltip>
                </Popconfirm>
              ) : (
                <Popconfirm
                  disabled={!canIActivateAStore && isSuperAdmin()}
                  icon={<ExclamationCircleOutlined />}
                  title={popConfirmTitle}
                  onConfirm={() => {
                    onClickActive();
                  }}
                  onCancel={() => {}}
                  okText="Sim"
                  cancelText="Não"
                >
                  <Tooltip
                    autoAdjustOverflow
                    placement="bottom"
                    title={tooltipTitle}
                  >
                    <Switch
                      size="small"
                      checked={isStoreActive}
                      disabled={!canIActivateAStore && isSuperAdmin()}
                      loading={isStoreLoading(putStoresLoading, storeId)}
                    />
                  </Tooltip>
                </Popconfirm>
              )}
            </Row>
          </ActionsContainer>
        </Col>
        <Col span={2}>
          {!canEditStore && (
            <Tooltip title="A atualização do cadastro da loja está em andamento.">
              <ExclamationCircleOutlined
                style={{ color: `${colors.gold6}`, fontSize: 20 }}
              />
            </Tooltip>
          )}
        </Col>
      </Row>
    );
  };

  return (
    <Row style={{ margin: '0px 24px 24px' }}>
      <Divider style={{ margin: '16px 0px' }} />
      <Col span={24}>
        <Row gutter={[20, 4]}>
          <Col span={7}>
            <StTitleItem>{storeDetails.br_cnpj}</StTitleItem>
          </Col>
          <Col span={5}>
            <StStatusText>{storeDetails.authorized_status}</StStatusText>
          </Col>
          <Col span={4}>{info(storeDetails.head_office).reenvio}</Col>
        </Row>
        <Row gutter={[20, 4]}>
          <Col style={{ display: 'flex', flexDirection: 'column' }} span={6}>
            <StTextItem>Razão social</StTextItem>
            <StValueItem>{storeDetails.name}</StValueItem>
          </Col>
          <Col
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
            span={6}
          >
            <StTextItem>Apelido da loja</StTextItem>
            <StValueItem>{storeDetails.short_name}</StValueItem>
          </Col>
          <Col span={4}>{actionsStore()}</Col>
        </Row>
        <Row gutter={[20, 4]}>
          <Col>
            <div style={{ display: 'flex' }}>
              {info(storeDetails.head_office).texto}
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default StoreListItem;
