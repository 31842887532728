import { actionTypes } from './types';

const INITIAL_STATE = {
  data: {},
  error: undefined,
  errorMessage: undefined,
  loading: false,
  isError: false,
  invitationWasSent: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.SEND_INVITATION_START:
      return {
        ...state,
        loading: true,
        error: undefined,
        isError: false,
        invitationWasSent: false,
      };
    case actionTypes.SEND_INVITATION_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: undefined,
        error: undefined,
        data: action.payload,
        isError: false,
        invitationWasSent: true,
      };
    case actionTypes.SEND_INVITATION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error.error,
        errorMessage: action.payload,
        isError: true,
        invitationWasSent: false,
      };
    default:
      return state;
  }
};

export default reducer;
