export const marginMainContainer = (breakpoint) => {
  if (
    breakpoint.sm &&
    breakpoint.md &&
    breakpoint.lg &&
    breakpoint.xl &&
    breakpoint.xxl
  ) {
    return '0 1rem';
  }
  if (
    breakpoint.sm &&
    breakpoint.md &&
    breakpoint.lg &&
    breakpoint.xl &&
    !breakpoint.xxl
  ) {
    return '0 1rem';
  }
  if (
    breakpoint.sm &&
    breakpoint.md &&
    breakpoint.lg &&
    !breakpoint.xl &&
    !breakpoint.xxl
  ) {
    return '0 1rem';
  }
  if (
    breakpoint.sm &&
    breakpoint.md &&
    !breakpoint.lg &&
    !breakpoint.xl &&
    !breakpoint.xxl
  ) {
    return '0 1rem';
  }
  if (
    breakpoint.sm &&
    !breakpoint.md &&
    !breakpoint.lg &&
    !breakpoint.xl &&
    !breakpoint.xxl
  ) {
    return '0 1rem';
  }
  return '0 1rem';
};

export const headerHeight = (breakpoint) => {
  if (
    breakpoint.sm &&
    breakpoint.md &&
    breakpoint.lg &&
    breakpoint.xl &&
    breakpoint.xxl
  ) {
    return '40px';
  }
  if (
    breakpoint.sm &&
    breakpoint.md &&
    breakpoint.lg &&
    breakpoint.xl &&
    !breakpoint.xxl
  ) {
    return '40px';
  }
  if (
    breakpoint.sm &&
    breakpoint.md &&
    breakpoint.lg &&
    !breakpoint.xl &&
    !breakpoint.xxl
  ) {
    return '40px';
  }
  return '60px';
};

export const filtersTopPosition = (breakpoint) => {
  if (
    breakpoint.sm &&
    breakpoint.md &&
    breakpoint.lg &&
    breakpoint.xl &&
    breakpoint.xxl
  ) {
    return '40px';
  }
  if (
    breakpoint.sm &&
    breakpoint.md &&
    breakpoint.lg &&
    breakpoint.xl &&
    !breakpoint.xxl
  ) {
    return '40px';
  }
  if (
    breakpoint.sm &&
    breakpoint.md &&
    breakpoint.lg &&
    !breakpoint.xl &&
    !breakpoint.xxl
  ) {
    return '40px';
  }
  return '60px';
};

export const setFontSizeAcordingWithProps = (textSize) => {
  if (textSize === 'small') {
    return '1.5rem';
  }
  if (textSize === 'medium') {
    return '2rem';
  }
  if (textSize === 'large') {
    return '2.5rem';
  }
  if (textSize === 'medium_14') {
    return '14px';
  }
  return '1.5rem';
};
