import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import { Filters, ContentTabs } from '../components';
import { refundsPaymentsIntermediate } from 'store/ducks/paymentsIntermediate/refundsPaymentsIntermediate/actions';
import { refundsPaymentsDash } from 'store/ducks/paymentsDash/refundsPayments/actions';
import { refundInfoFiltersStart } from 'store/ducks/filters/refundInfoFilters/actions';
import { updateRefundDetailsFilters } from 'store/ducks/filters/details/refundDetailsFilters/actions';
import { disableLastMonth } from 'helpers/disableDates';
import { useResetFilters } from 'utils/hooks/useResetFilters';
import { onSorter } from 'helpers/sorter';
import { useRangePickerChange } from 'utils/hooks/useRangePickerChange';

const RefundsPaymentsIntermediate = () => {
  const dispatch = useDispatch();
  const error = {
    has: false,
  };

  const startDate = useSelector(
    (state) => state.updateScreenToScreenDate.startDate
  );
  const endDate = useSelector(
    (state) => state.updateScreenToScreenDate.endDate
  );

  const [sort, setSort] = useState('DESC');

  const [filtersDrawer, toggleFiltersDrawer] = useState(false);

  const [activeTab, setActiveTab] = useState('branches');

  const [filterName, setFilterName] = useState(null);

  const refundsPaymentsData = useSelector(
    (store) => store.refundsPaymentsDash.payments
  );
  const refundsPaymentsLoading = useSelector(
    (store) => store.refundsPaymentsDash.loading
  );
  const isSummaryError = useSelector(
    (state) => state.refundsPaymentsDash.isError
  );

  const payments = useSelector(
    (state) => state.refundsPaymentsIntermediate.payments
  );
  const paymentsLoading = useSelector(
    (state) => state.refundsPaymentsIntermediate.loading
  );
  const paymentsError = useSelector(
    (state) => state.refundsPaymentsIntermediate.isError
  );

  if (isSummaryError || paymentsError) {
    error.has = true;
    error.title = 'Dados indisponíveis';
    error.reason =
      'Não conseguimos buscar os dados, mas já estamos trabalhando para normalizar a situação.';
    error.recommendation = 'Tente atualizar a página em alguns instantes.';
  }

  const refundInfoFilters = useSelector(
    (state) => state.refundInfoFilters.filters
  );

  const loading = paymentsLoading || refundsPaymentsLoading;

  const [calendarIsOpen, setCalendarIsOpen] = useState(false);

  const { onRangePickerChange } = useRangePickerChange();

  useEffect(() => {
    if (!calendarIsOpen) {
      dispatch(
        refundsPaymentsIntermediate({
          startDate,
          endDate,
          page: 1,
          type: activeTab,
          perPage: 100,
          sort,
          filter: filterName,
        })
      );
      dispatch(refundInfoFiltersStart({ startDate, endDate, type: activeTab }));
      dispatch(refundsPaymentsDash({ startDate, endDate }));
    }
  }, [
    endDate,
    dispatch,
    startDate,
    activeTab,
    sort,
    filterName,
    calendarIsOpen,
  ]);

  const onRangePickerOpenOrCloseChange = (open) => {
    setCalendarIsOpen(open);
  };

  const onChangeSelectFilter = (val) => {
    if (val === 'Todos' || val === 'Buscar' || val === '') {
      setFilterName(null);
    } else {
      setFilterName(val);
    }
  };

  const onTabClick = (key) => {
    setActiveTab(key);
    onChangeSelectFilter(null);
  };

  const { resetFilters } = useResetFilters();

  useEffect(() => {
    resetFilters(updateRefundDetailsFilters);
  }, [resetFilters]);

  return (
    <>
      <Filters
        onRangePickerOpenOrCloseChange={onRangePickerOpenOrCloseChange}
        hasRangePicker
        hasSelect
        hasSorter
        dateDefaultValue={[startDate, endDate]}
        onRangePickerChange={(dates) =>
          onRangePickerChange(dates, calendarIsOpen)
        }
        onChangeSelectFilter={onChangeSelectFilter}
        loading={loading}
        sort={sort}
        sorter={onSorter(sort, setSort)}
        filtersDrawer={filtersDrawer}
        filterName={filterName}
        renderFilterButton={() => (
          <Button
            style={{ borderRadius: 4 }}
            onClick={() => toggleFiltersDrawer(!filtersDrawer)}
          >
            Filtros
          </Button>
        )}
        onCloseFiltersDrawer={() => toggleFiltersDrawer(false)}
        sorterButtonText="Total de canc. / chbk"
        activeTab={activeTab}
        infoFiltersToSelect={refundInfoFilters}
        titleComponent="Cancelamentos e Chargebacks"
        subtitleComponent="Total de valores cancelados e com chargeback"
        disabledDate={disableLastMonth}
        calendarIsOpen={calendarIsOpen}
        setCalendarIsOpen={setCalendarIsOpen}
      />
      <ContentTabs
        infoData={payments}
        infoLoading={paymentsLoading}
        summaryData={refundsPaymentsData}
        summaryLoading={refundsPaymentsLoading}
        isSummaryError={error}
        detailRoute="cancchbk-relatorios"
        onTabClick={onTabClick}
        activeTab={activeTab}
        updateAction={updateRefundDetailsFilters}
        titleSummaryCard="Resumo total"
        screen="refunds-payments"
      />
    </>
  );
};

export default RefundsPaymentsIntermediate;
