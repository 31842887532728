import { Divider, Modal, Tooltip, Typography } from 'antd';
import React, { useCallback, useRef, useState } from 'react';
import { colors } from 'styles/colors';
import { useClientList } from './hooks/useClientList';
import {
  ItemButton,
  ItemsContainer,
  ItemsList,
  StDividerItems,
  StListItem,
  StListItemButton,
  StSearch,
  StSpin,
  Title,
} from './styled';
import {
  getSessionClientCode,
  getSessionClientName,
} from 'helpers/sessionService';
import customHistory from 'helpers/history';
import { shortId } from 'helpers/shortId';
import { dataTestIdHandler } from 'helpers/dataTestIdHandler';

export const ClientListModal = ({ visible, setVisible, ...others }) => {
  const { pathname } = window.location;
  const store = getSessionClientName();
  const clientCode = getSessionClientCode();
  const branchName = clientCode ? `${clientCode} ${store}` : store;
  const [searchFilter, setSearchFilter] = useState('');
  const [page, setPage] = useState(1);
  const { isLoading, clients, hasMoreClients, onChangeClient } = useClientList(
    searchFilter,
    page
  );
  const dataTestPrefix = 'client-list-modal';

  const observer = useRef();
  const lastClientElementRef = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMoreClients) {
          setPage((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, hasMoreClients]
  );

  const goToDashboard = () => {
    if (pathname !== '/dashboard') {
      customHistory.push('/dashboard');
    }
  };

  const handleChange = (e) => {
    setSearchFilter(e.target.value);
    setPage(1);
  };

  const onCancelModal = () => {
    setVisible(false);
  };

  const onClickClient = (clientId) => {
    onChangeClient(clientId);
    setVisible(false);
    goToDashboard();
  };

  return (
    <Modal
      title={<Title>Trocar empresa</Title>}
      open={visible}
      footer={null}
      onCancel={onCancelModal}
      {...others}
    >
      <div style={{ paddingBottom: 4 }}>
        <Typography.Text style={{ color: colors.gray7 }}>
          Seu usuário está ativo na empresa:
        </Typography.Text>
      </div>
      <StListItem key={shortId()}>
        <Tooltip placement="top" title={branchName}>
          <StListItemButton key={shortId()} type="link" block>
            <ItemButton>{branchName}</ItemButton>
          </StListItemButton>
        </Tooltip>
      </StListItem>

      <StSearch
        value={searchFilter}
        onChange={handleChange}
        loading={isLoading}
        placeholder="Buscar empresa"
        allowClear
        {...dataTestIdHandler(dataTestPrefix, 'search')}
      />

      <Divider />

      <ItemsContainer {...dataTestIdHandler(dataTestPrefix, 'clients')}>
        {clients?.map((client, index) => {
          return (
            <Tooltip placement="top" title={client.name} key={client.client_id}>
              <ItemsList
                {...dataTestIdHandler(dataTestPrefix, 'client-row')}
                ref={clients.length === index + 1 ? lastClientElementRef : null}
                onClick={() => onClickClient(client.client_id)}
              >
                {client.name
                  ? `${client.client_code} ${client.name}`
                  : client.name}
              </ItemsList>
              <StDividerItems />
            </Tooltip>
          );
        })}
      </ItemsContainer>

      {isLoading && <StSpin />}
    </Modal>
  );
};
