// TODO: Remove camelcase eslint rule
/* eslint-disable camelcase */
import { action } from 'typesafe-actions';
import { actionTypes } from './types';

// Listing Conciliation
export const listBankConciliationStart = (data) =>
  action(actionTypes.LIST_BANK_CONCILIATION_START, data);
export const listBankConciliationSuccess = (data) =>
  action(actionTypes.LIST_BANK_CONCILIATION_SUCCESS, data);
export const listBankConciliationError = (errorMessage, error) =>
  action(
    actionTypes.LIST_BANK_CONCILIATION_ERROR,
    { errorMessage },
    undefined,
    { error }
  );

// Export Bank Conciliation
export const exportBankConciliationStart = (data) =>
  action(actionTypes.EXPORT_BANK_CONCILIATION_START, data);
export const exportBankConciliationSuccess = (data) =>
  action(actionTypes.EXPORT_BANK_CONCILIATION_SUCCESS, data);
export const exportBankConciliationError = (errorMessage, error) =>
  action(
    actionTypes.EXPORT_BANK_CONCILIATION_ERROR,
    { errorMessage },
    undefined,
    { error }
  );

// Listing Transactions
export const setTransactionsSelectedExecute = (
  bank,
  // bankCode,
  bank_branch,
  bank_account,
  payment_date,
  // endDate,
  account_id,
  currentPage,
  per_page,
  page,
  search_by,
  filter
) =>
  action(actionTypes.SET_TRANSACTIONS_SELECTED, {
    bank,
    // bankCode,
    bank_branch,
    bank_account,
    payment_date,
    // endDate,
    account_id,
    currentPage,
    per_page,
    page,
    search_by,
    filter,
  });
export const setTransactionsSelectedExecuteSuccess = (data) =>
  action(actionTypes.SET_TRANSACTIONS_SELECTED_SUCCESS, data);
export const listBankConciliationTransactionsStart = (data) =>
  action(actionTypes.LIST_BANK_CONCILIATION_TRANSACTIONS_START, data);
export const listBankConciliationTransactionsSuccess = (data) =>
  action(actionTypes.LIST_BANK_CONCILIATION_TRANSACTIONS_SUCCESS, data);
export const listBankConciliationTransactionsClear = () =>
  action(actionTypes.LIST_BANK_CONCILIATION_TRANSACTIONS_CLEAR);
export const listBankConciliationTransactionsClearDone = () =>
  action(actionTypes.LIST_BANK_CONCILIATION_TRANSACTIONS_CLEAR_DONE);
export const listBankConciliationTransactionsError = (errorMessage, error) =>
  action(
    actionTypes.LIST_BANK_CONCILIATION_TRANSACTIONS_ERROR,
    { errorMessage },
    undefined,
    { error }
  );

// Update Transactions
export const updateTransactionsStart = (data) =>
  action(actionTypes.UPDATE_BANK_CONCILIATION_TRANSACTIONS_START, data);
export const updateTransactionsSuccess = (data) =>
  action(actionTypes.UPDATE_BANK_CONCILIATION_TRANSACTIONS_SUCCESS, data);
export const updateTransactionsError = (errorMessage, error) =>
  action(
    actionTypes.UPDATE_BANK_CONCILIATION_TRANSACTIONS_ERROR,
    { errorMessage },
    undefined,
    { error }
  );

// Update Counter Transactions
export const updateCounterTransactionsStart = (data) =>
  action(actionTypes.UPDATE_COUNTER_TRANSACTIONS_START, data);
export const updateCounterTransactionsSuccess = (data) =>
  action(actionTypes.UPDATE_COUNTER_TRANSACTIONS_SUCCESS, data);
export const updateCounterTransactionsError = (errorMessage, error) =>
  action(
    actionTypes.UPDATE_COUNTER_TRANSACTIONS_ERROR,
    { errorMessage },
    undefined,
    { error }
  );

// Update Transaction Date
export const updateTransactionDateStart = (data) =>
  action(actionTypes.UPDATE_TRANSACTION_DATE_START, data);
export const updateTransactionDateSuccess = (data) =>
  action(actionTypes.UPDATE_TRANSACTION_DATE_SUCCESS, data);
export const updateTransactionDateError = (errorMessage, error) =>
  action(
    actionTypes.UPDATE_TRANSACTION_DATE_ERROR,
    { errorMessage },
    undefined,
    {
      error,
    }
  );

// Update Through Import Files
export const updateThroughImportFilesStart = (data) =>
  action(actionTypes.UPDATE_THROUGH_IMPORT_FILES_START, data);
export const updateThroughImportFilesSuccess = (data) =>
  action(actionTypes.UPDATE_THROUGH_IMPORT_FILES_SUCCESS, data);
export const updateThroughImportFilesError = (errorMessage, error) =>
  action(
    actionTypes.UPDATE_THROUGH_IMPORT_FILES_ERROR,
    { errorMessage },
    undefined,
    {
      error,
    }
  );

export const resetHasNoTransactionsStart = (data) =>
  action(actionTypes.RESET_HAS_NO_TRANSACTIONS_START, data);
export const resetHasNoTransactionsSuccess = (data) =>
  action(actionTypes.RESET_HAS_NO_TRANSACTIONS_SUCCESS, data);
export const resetHasNoTransactionsError = (errorMessage, error) =>
  action(
    actionTypes.RESET_HAS_NO_TRANSACTIONS_ERROR,
    { errorMessage },
    undefined,
    {
      error,
    }
  );

export const updateDifferenceValueStart = (data) =>
  action(actionTypes.UPDATE_DIFFERENCE_VALUE_START, data);
export const updateDifferenceValueSuccess = (data) =>
  action(actionTypes.UPDATE_DIFFERENCE_VALUE_SUCCESS, data);
export const updateDifferenceValueError = (errorMessage, error) =>
  action(
    actionTypes.UPDATE_DIFFERENCE_VALUE_ERROR,
    { errorMessage },
    undefined,
    {
      error,
    }
  );

export const updateAcquirerNameStart = (data) =>
  action(actionTypes.UPDATE_ACQUIRER_NAME_START, data);
export const updateAcquirerNameSuccess = (data) =>
  action(actionTypes.UPDATE_ACQUIRER_NAME_SUCCESS, data);
export const updateAcquirerNameError = (errorMessage, error) =>
  action(actionTypes.UPDATE_ACQUIRER_NAME_ERROR, { errorMessage }, undefined, {
    error,
  });

export const updateCurrentPageStart = (data) =>
  action(actionTypes.UPDATE_CURRENT_PAGE_START, data);
export const updateCurrentPageSuccess = (data) =>
  action(actionTypes.UPDATE_CURRENT_PAGE_SUCCESS, data);
export const updateCurrentPageError = (errorMessage, error) =>
  action(actionTypes.UPDATE_CURRENT_PAGE_ERROR, { errorMessage }, undefined, {
    error,
  });

export const updateAfterSave = (afterSave) =>
  action(actionTypes.UPDATE_AFTER_SAVE, { afterSave });
export const updateAfterSaveSuccess = (data) =>
  action(actionTypes.UPDATE_AFTER_SAVE_SUCCESS, data);
