/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import customHistory from 'helpers/history';
import { CardResume } from '../../../../commons/CardResume';
import { updateScreenToScreenDateStart } from 'store/ducks/updateScreenToScreenDate/actions';
import dayjs from 'dayjs';

const AdjustmentsCard = ({
  startDateAdjustments,
  endDateAdjustments,
  setStartDateAdjustments,
  setEndDateAdjustments,
  isDuedate,
  isAdvancements,
  isAdjustments,
  isRefunds,
}) => {
  const dispatch = useDispatch();
  const adjustmentsCardProps = {
    startDateAdjustments,
    endDateAdjustments,
    setStartDateAdjustments,
    setEndDateAdjustments,
    isDuedate,
    isAdvancements,
    isAdjustments,
    isRefunds,
  };

  const adjustmentData = useSelector(
    (store) => store.adjustmentPaymentsDash.payments
  );
  const adjustmentLoading = useSelector(
    (store) => store.adjustmentPaymentsDash.loading
  );

  const dataTestPrefix = 'adjustments-card';

  const goToAdjustments = () => {
    customHistory.push('/ajustes-intermediario');
    dispatch(
      updateScreenToScreenDateStart({
        startDate: startDateAdjustments,
        endDate: endDateAdjustments,
      })
    );
  };

  return (
    <CardResume
      titleCard="Ajustes"
      goToUrl={goToAdjustments}
      dataCard={adjustmentData}
      loading={adjustmentLoading}
      dataTestPrefix={dataTestPrefix}
      {...adjustmentsCardProps}
    />
  );
};

AdjustmentsCard.propTypes = {
  startDateAdjustments: PropTypes.instanceOf(dayjs).isRequired,
  endDateAdjustments: PropTypes.instanceOf(dayjs).isRequired,
  setStartDateAdjustments: PropTypes.func.isRequired,
  setEndDateAdjustments: PropTypes.func.isRequired,
};

export default AdjustmentsCard;
