/* eslint-disable no-else-return */
import styled from 'styled-components';
import { Table } from 'antd';
import { colors } from 'styles/colors';

export const Wrapper = styled.div`
  background: ${colors.background1};
`;

export const Container = styled.div`
  margin-top: ${({ isBottom }) => (!isBottom ? '72px' : '24px')};

  &:nth-child(2) {
    position: absolute;
    margin-top: 313px;
    width: 96.7%;
  }

  margin-left: 24px;
  margin-right: 24px;
  padding: 24px 24px 1px 24px;
  background-color: ${colors.gray1};
  border-radius: 2px;
  position: ${({ isFixed }) => isFixed && 'fixed'};
  z-index: ${({ isFixed }) => isFixed && 2};
  width: ${({ isFixed }) => isFixed && '96.7%'};
`;

export const StTable = styled(Table)`
  &&& {
    .ant-pagination-options::before {
      content: 'Itens por página';
      margin-right: 10px;
    }

    .ant-select-selector {
      background: ${({ disableSizeChanger }) =>
        disableSizeChanger ? 'rgba(255, 255, 255, 0.08)' : colors.gray1};
      color: ${({ disableSizeChanger }) =>
        disableSizeChanger ? '#00000040' : colors.gray11};
      cursor: ${({ disableSizeChanger }) =>
        disableSizeChanger ? 'not-allowed' : 'pointer'};
    }
    .ant-select-dropdown {
      display: ${({ disableSizeChanger }) => disableSizeChanger && 'none'};
    }
  }
`;
