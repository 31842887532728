export const states = [
  { initials: 'AC', name: 'Acre' },
  { initials: 'AL', name: 'Alagoas' },
  { initials: 'AM', name: 'Amazonas' },
  { initials: 'AP', name: 'Amapá' },
  { initials: 'BA', name: 'Bahia' },
  { initials: 'CE', name: 'Ceará' },
  { initials: 'DF', name: 'Distrito Federal' },
  { initials: 'ES', name: 'Espírito Santo' },
  { initials: 'GO', name: 'Goiás' },
  { initials: 'MA', name: 'Maranhão' },
  { initials: 'MT', name: 'Mato Grosso' },
  { initials: 'MS', name: 'Mato Grosso do Sul' },
  { initials: 'MG', name: 'Minas Gerais' },
  { initials: 'PA', name: 'Pará' },
  { initials: 'PB', name: 'Paraiba' },
  { initials: 'PR', name: 'Paraná' },
  { initials: 'PE', name: 'Pernambuco' },
  { initials: 'PI', name: 'Piauí' },
  { initials: 'RJ', name: 'Rio de Janeiro' },
  { initials: 'RO', name: 'Rondônia' },
  { initials: 'RS', name: 'Rio Grande do Sul' },
  { initials: 'RR', name: 'Roraima' },
  { initials: 'SC', name: 'Santa Catarina' },
  { initials: 'SE', name: 'Sergipe' },
  { initials: 'SP', name: 'São Paulo' },
  { initials: 'TO', name: 'Tocantins' },
];

export const generateStoreShortName = (razaoSocial, nomeFantasia, cnpj) => {
  // Gera um nome curto para a loja de acordo com regra já existente no V3
  // 9 = 7 ultimos caracteres do cnpj + espaço entre CNPJ e nome, e espaço entre parte anterior do nome
  if (razaoSocial && cnpj) {
    const fantasia = nomeFantasia === '' ? null : nomeFantasia;
    let generatedShortName = (fantasia ?? razaoSocial ?? '').toUpperCase();

    const shortNameLength = 30;
    const shortNameArray = generatedShortName?.split(' ');
    for (let i = 0; i < shortNameArray?.length; i += 1) {
      if (
        (generatedShortName + shortNameArray[i]).length + 9 <=
        shortNameLength
      ) {
        generatedShortName += ' ' + shortNameArray[i];
        generatedShortName = generatedShortName.trim();
      } else {
        break;
      }
    }

    return generatedShortName + ' ' + cnpj?.substring(11, 18);
  }
  return '';
};
