import { action } from 'typesafe-actions';
import { actionTypes } from './types';

export const getBriefingStart = (data) => action(actionTypes.GET_BRIEFING_START, { data });
export const getBriefingSuccess = (data) => action(actionTypes.GET_BRIEFING_SUCCESS, data);
export const getBriefingError = (errorMessage, error) =>
  action(actionTypes.GET_BRIEFING_ERROR, { errorMessage }, undefined, { error });

export const postBriefingStart = (data) => action(actionTypes.POST_BRIEFING_START, { data });
export const postBriefingSuccess = (data) => action(actionTypes.POST_BRIEFING_SUCCESS, data);
export const postBriefingError = (errorMessage, error) =>
  action(actionTypes.POST_BRIEFING_ERROR, { errorMessage }, undefined, { error });

export const putBriefingStart = (data) => action(actionTypes.PUT_BRIEFING_START, { data });
export const putBriefingSuccess = (data) => action(actionTypes.PUT_BRIEFING_SUCCESS, data);
export const putBriefingError = (errorMessage, error) =>
  action(actionTypes.PUT_BRIEFING_ERROR, { errorMessage }, undefined, { error });
