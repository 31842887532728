// plans restrictions
const planFreeRestrictions = [
  'menu-fees',
  'menu-briefing',
  'dashboard-paymentsReconciliationCard',
  'dashboard-paymentsDivergencesCard',
  'dashboard-banksCard',
  'dashboard-duedateCard',
  'dashboard-advancementsCard',
  'dashboard-adjustmentsCard',
  'dashboard-refundsCard',
  'userMenu-accessManagement',
  'general-fileExport',
  'reports-aging-tab',
  'briefing-fees-redirect',
  'cnpjMaintenence-fees-redirect',
];

const planPagGratisRestrictions = [
  'plan-upgrade',
  'briefing-viewAllAcquirers',
  'cnpjMaintenance-viewAllAcquirers',
];

const planProRestrictions = [
  'general-fileExport',
  'reports-aging-tab',
  'dashboard-paymentsReconciliationCard',
];
const planControleRestrictions = [];
const planGerencieRestrictions = [];
const planAdministrePlusRestrictions = ['plan-upgrade'];

const planControle = [
  'menu-dashboard',
  'menu-briefing',
  'menu-cnpjMaintenance',
  'menu-fees',
  'menu-banking',
  'briefing-fees-redirect',
  'cnpjMaintenence-fees-redirect',
  'plan-upgrade',
  'briefing-viewAllAcquirers',
  'cnpjMaintenance-viewAllAcquirers',
  'dashboard-paymentsReconciliationCard',
  'dashboard-paymentsDivergencesCard',
  'dashboard-banksCard',
  'dashboard-duedateCard',
  'dashboard-advancementsCard',
  'dashboard-adjustmentsCard',
  'dashboard-refundsCard',
  'userMenu-accessManagement',
  'general-fileExport',
  'reports-aging-tab',
].filter((item) => !planControleRestrictions.includes(item));

const planPagGratis = [
  ...planControle,
  'dashboard-plan-expire-info-card',
].filter((item) => !planPagGratisRestrictions.includes(item));

const planGerencie = [
  ...planControle,
  'menu-importFiles',
  'dashboard-bankReconciliationCard',
].filter((item) => !planGerencieRestrictions.includes(item));

const planAdministrePlus = [
  ...planGerencie,
  'dashboard-salesReconciliationCard',
].filter((item) => !planAdministrePlusRestrictions.includes(item));

const planFree = [
  ...planControle,
  'dashboard-planUpgradeCard',
  'plans-free-plan-column',
].filter((item) => !planFreeRestrictions.includes(item));

const planPro = [
  ...planControle,
  'dashboard-paymentsReconciliationCardBlocked',
].filter((item) => !planProRestrictions.includes(item));

export const plansFeaturesPermissions = {
  // CONTROLE
  1: [...planControle],
  // GERENCIE
  2: [...planGerencie],
  // ADMINISTRE_PLUS
  4: [...planAdministrePlus],
  // PAG_GRATIS
  6: [...planPagGratis],
  // FREE
  7: [...planFree],
  // PRO
  8: [...planPro],
};
