import { call, put } from 'redux-saga/effects';

import errorHandler from 'helpers/errorHandler';

import {
  amountsReceivableDetailsSuccess,
  amountsReceivableDetailsError,
} from 'store/ducks/details/amountsReceivableDetails/actions';
import getAmountsReceivableDetailsFromAPI from 'services/amountsReceivableDetails';

export function* amountsReceivableDetailsSaga(action) {
  try {
    const { payload } = action;

    const apiData = yield call(getAmountsReceivableDetailsFromAPI, payload);

    yield put(amountsReceivableDetailsSuccess(apiData));
  } catch (err) {
    if (err?.response?.data?.message) {
      yield put(
        amountsReceivableDetailsError(
          err.response.data.message,
          err.response.data.error
        )
      );
      errorHandler(err.response.data.message, err.response.data.error);
    }
  }
}
