import { lambdaApi } from '../api';
import scopes from 'constants/scopes';

const types = { weekly: 'RadarWeekly', monthly: 'RadarMonthly' };

const transformer = (apiResponse) => {
  return {
    payload: apiResponse?.payload,
  };
};

export const run = async ({ type }) => {
  const isProduction = process.env.REACT_APP_BUILD_ENV === 'production';

  const config = {
    is_test: !isProduction,
    type: types[type],
    scope_id: [scopes.CLIENTE],
  };
  const response = await lambdaApi().post('/radar/process', config);
  return transformer(response.data);
};
