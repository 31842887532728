import { call, put } from 'redux-saga/effects';

import errorHandler from 'helpers/errorHandler';

import {
  getLegalRepresentativeSuccess,
  postLegalRepresentativeSuccess,
  getLegalRepresentativeError,
  postLegalRepresentativeError,
} from 'store/ducks/credit/legalRepresentative/actions';

import {
  getLegalRepresentative,
  postLegalRepresentative,
} from 'services/credit/legalRepresentative';

export function* getLegalRepresentativeSaga() {
  try {
    const apiData = yield call(getLegalRepresentative);
    yield put(getLegalRepresentativeSuccess(apiData));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        getLegalRepresentativeError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(getLegalRepresentativeError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}

export function* postLegalRepresentativeSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(postLegalRepresentative, payload);
    yield put(postLegalRepresentativeSuccess(apiData));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        postLegalRepresentativeError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(postLegalRepresentativeError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}
