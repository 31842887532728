import styled from 'styled-components';

import { marginMainContainer } from 'helpers/style';

export const StContainer = styled.div``;

export const StLimiter = styled.div`
  margin: ${(props) => marginMainContainer(props.$breakpoint)};
  padding-top: 192px;
`;
