import { put } from 'redux-saga/effects';

import errorHandler from 'helpers/errorHandler';

import {
  feesUpdateFilteredColumnsSuccess,
  feesUpdateFilteredColumnsError,
  feesResetUpdateFilteredColumnsSuccess,
  feesResetUpdateFilteredColumnsError,
} from 'store/ducks/feesUpdateFilteredColumns/actions';

export function* feesUpdateFilteredColumnsSaga(action) {
  try {
    const { payload } = action;
    yield put(feesUpdateFilteredColumnsSuccess(payload));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        feesUpdateFilteredColumnsError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(feesUpdateFilteredColumnsError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}

export function* feesResetUpdateFilteredColumnsSaga(action) {
  try {
    const { payload } = action;
    yield put(feesResetUpdateFilteredColumnsSuccess(payload));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        feesResetUpdateFilteredColumnsError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(feesResetUpdateFilteredColumnsError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}
