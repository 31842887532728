import PropTypes from 'prop-types';
import { Button, Col, Form, Input, Row } from 'antd';
import React, { useEffect } from 'react';
import LabelAndInputs from '../../../../../commons/LabelAndInputs/LabelAndInputs';
import ptBR from 'antd/es/date-picker/locale/pt_BR';
import { StCpfCnpj } from '../styled';
import { isOperational } from 'helpers/authorizationService';
import { shortId } from 'helpers/shortId';
import scopes from 'constants/scopes';
import { useDispatch, useSelector } from 'react-redux';
import { getCnpjStart } from 'store/ducks/receitaWebservice/actions';

const StoreListItem = ({
  storeId,
  onClickRemove,
  isLocked,
  setFieldsValue,
  isCNPJMaintenance,
  canEditShortName,
  scopeId,
  hasTransactions,
  storeIdChangedCNPJ,
  setStoreIdChangedCNPJ,
}) => {
  const dispatch = useDispatch();
  const receitaWsData =
    useSelector((state) => state.receitaWebservice.data) || {};
  const receitaWsLoading = useSelector(
    (state) => state.receitaWebservice.loading
  );
  const receitaWsCnpjStatus = useSelector(
    (state) => state.receitaWebservice.cnpjStatus
  );

  const onChangeCnpj = (e) => {
    setFieldsValue({ [`br_cnpj_${storeId}`]: e.target.value });
    const cnpjOrCpf = e.target.value.replace(/\D/g, '');
    if (cnpjOrCpf.length === 14) {
      setStoreIdChangedCNPJ(storeId);
      dispatch(getCnpjStart({ cnpj: cnpjOrCpf }));
    }
  };

  useEffect(() => {
    if (receitaWsData && storeId === storeIdChangedCNPJ) {
      if (receitaWsCnpjStatus === 'OK') {
        setFieldsValue({
          [`name_${storeId}`]: (
            receitaWsData?.nome ?? receitaWsData?.fantasia
          ).toUpperCase(),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receitaWsData, receitaWsCnpjStatus]);

  return (
    <fieldset>
      <Row key={shortId()} gutter={[8, 8]}>
        {/* Do not remove the empty store_code below */}
        <Col lg={0}>
          <LabelAndInputs label="store_code">
            <Form.Item name={`store_code_${storeId}`}>
              <></>
            </Form.Item>
          </LabelAndInputs>
        </Col>
        <Col lg={5}>
          <LabelAndInputs label="CNPJ (outros)">
            <Form.Item
              name={`br_cnpj_${storeId}`}
              style={{ marginBottom: isCNPJMaintenance ? 0 : 24 }}
            >
              <StCpfCnpj
                placeholder="00.000.000/0000-00"
                name={`br_cnpj_${storeId}`}
                disabled={
                  isCNPJMaintenance ||
                  isLocked ||
                  scopeId !== scopes.CONCIL ||
                  hasTransactions ||
                  !canEditShortName
                }
                onChange={(e) => onChangeCnpj(e)}
              />
            </Form.Item>
          </LabelAndInputs>
        </Col>
        <Col lg={7}>
          <LabelAndInputs label="Razão social">
            <Form.Item
              name={`name_${storeId}`}
              style={{ marginBottom: isCNPJMaintenance ? 0 : 24 }}
            >
              <Input
                placeholder="Razão social"
                name={`name_${storeId}`}
                locale={ptBR}
                style={{ width: '100%', cursor: 'default' }}
                disabled={
                  isCNPJMaintenance ||
                  isLocked ||
                  scopeId !== scopes.CONCIL ||
                  hasTransactions ||
                  receitaWsLoading
                }
                onChange={(e) =>
                  setFieldsValue({
                    [`name_${storeId}`]: e.target.value,
                  })
                }
              />
            </Form.Item>
          </LabelAndInputs>
        </Col>
        <Col lg={8}>
          <LabelAndInputs label="Apelido da loja">
            <Form.Item
              name={`short_name_${storeId}`}
              style={{ marginBottom: isCNPJMaintenance ? 0 : 24 }}
            >
              <Input
                placeholder="Como quer chamar este CNPJ?"
                name={`short_name_${storeId}`}
                locale={ptBR}
                style={{ width: '100%' }}
                disabled={
                  isCNPJMaintenance ||
                  isLocked ||
                  !canEditShortName ||
                  isOperational()
                }
                onChange={(e) =>
                  setFieldsValue({
                    [`short_name_${storeId}`]: e.target.value,
                  })
                }
              />
            </Form.Item>
          </LabelAndInputs>
        </Col>
        {isCNPJMaintenance ? (
          <></>
        ) : (
          <Col lg={4}>
            <br />
            <Button disabled={isLocked} danger onClick={() => onClickRemove()}>
              Remover
            </Button>
          </Col>
        )}
      </Row>
    </fieldset>
  );
};

StoreListItem.defaultProps = {
  isLocked: false,
};
StoreListItem.propTypes = {
  onClickRemove: PropTypes.func.isRequired,
  storeId: PropTypes.number.isRequired,
  isLocked: PropTypes.bool,
};

export default StoreListItem;
