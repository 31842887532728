import { apiXLSXDownload } from '../../api';

const getExportAllClientsDetailsFromAPI = async ({
  filterType,
  filter,
  page,
  perPage,
}) => {
  const config = {
    params: {
      filter_type: filterType,
      filter,
      page,
      per_page: perPage,
    },
  };
  const response = await apiXLSXDownload().get(
    '/export/allclientsreports/detail',
    config
  );
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'Relatório de usuários.xlsx');
  document.body.appendChild(link);
  link.click();
  return response;
};

export default getExportAllClientsDetailsFromAPI;
