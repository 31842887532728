export const actionTypes = {
  AMOUNTS_RECEIVABLE_DETAILS_FILTERS_START:
    '@amountsReceivableDetailsFilters/AMOUNTS_RECEIVABLE_DETAILS_FILTERS_START',
  AMOUNTS_RECEIVABLE_DETAILS_FILTERS_SUCCESS:
    '@amountsReceivableDetailsFilters/AMOUNTS_RECEIVABLE_DETAILS_FILTERS_SUCCESS',
  AMOUNTS_RECEIVABLE_DETAILS_FILTERS_ERROR:
    '@amountsReceivableDetailsFilters/AMOUNTS_RECEIVABLE_DETAILS_FILTERS_ERROR',
  AMOUNTS_RECEIVABLE_DETAILS_FILTERS_ZERO_LENGTH:
    '@amountsReceivableDetailsFilters/AMOUNTS_RECEIVABLE_DETAILS_FILTERS_ZERO_LENGTH',
  UPDATE_AMOUNTS_RECEIVABLE_DETAILS_FILTERS:
    '@amountsReceivableDetailsFilters/UPDATE_AMOUNTS_RECEIVABLE_DETAILS_FILTERS',
  CLEAR_AMOUNTS_RECEIVABLE_DETAILS_FILTERS:
    '@amountsReceivableDetailsFilters/CLEAR_AMOUNTS_RECEIVABLE_DETAILS_FILTERS',
};
