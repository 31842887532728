import React from 'react';
import * as St from './styled';
import { useDispatch } from 'react-redux';
import { getDownloadUrlStart } from 'store/ducks/notifications/actions';
import { getSessionUsername } from 'helpers/sessionService';
import { dataTestIdHandler } from 'helpers/dataTestIdHandler';

const ReportNotification = ({
  isResume,
  title,
  status,
  email,
  fileName,
  message,
  notificationId,
  isExpired,
  dateTime,
}) => {
  const dispatch = useDispatch();
  const clientEmail = getSessionUsername();
  const dataTestPrefix = 'notification-card';

  return (
    <St.NotificationCardContainer>
      <St.FirstRowContainer>
        <St.AlignCenterRow>
          {status === 'PREPARANDO' && <St.SpinnerIcon />}
          {status === 'PRONTO' && <St.CheckIcon />}
          {(status === 'EXPIRADO' || status === 'ERRO' || status === '') && (
            <St.CloseIcon />
          )}
          <St.FirstRowText status={status}>{title}</St.FirstRowText>
        </St.AlignCenterRow>
        <St.TimeText>{dateTime}</St.TimeText>
      </St.FirstRowContainer>

      <St.SecondRowContainer>
        <St.SecondRowText>
          {status !== 'PRONTO' && status !== 'EXPIRADO' && message}
          {status === 'PRONTO' && message}
          {(status === 'EXPIRADO' || isExpired) &&
            'O prazo de 7 dias para o download expirou.'}
        </St.SecondRowText>
      </St.SecondRowContainer>

      <St.ThirdRowContainer $isResume={isResume}>
        <St.UserContainer>
          <St.UserIcon />
          <St.ThirdRowText>{email || clientEmail}</St.ThirdRowText>
        </St.UserContainer>
        {status !== 'PREPARANDO' && status !== 'ERRO' && fileName && (
          <St.FileContainer isResume={isResume} status={status}>
            <St.FileIcon />
            <St.FileText
              {...dataTestIdHandler(dataTestPrefix, 'file-name')}
              $isExpired={isExpired}
              onClick={() =>
                !isExpired && dispatch(getDownloadUrlStart({ notificationId }))
              }
            >
              {fileName}
            </St.FileText>
          </St.FileContainer>
        )}
      </St.ThirdRowContainer>
    </St.NotificationCardContainer>
  );
};

const SimpleNotification = ({
  isResume,
  title,
  message,
  status,
  dateTime,
  goToNotifications,
}) => {
  const messageLength = message.length;

  return (
    <St.NotificationCardContainer>
      <St.FirstRowContainer>
        <St.AlignCenterRow>
          {status === 'PENDENTE' && <St.ExclamationIcon />}
          {status === 'EXECUTANDO' && <St.SpinnerIcon />}
          {status === 'ALERTA' && <St.WarningIcon />}
          {status === 'TERMINADO' && <St.CheckIcon />}
          {!status && <St.WithoutStatus />}
          <St.FirstRowText>{title}</St.FirstRowText>
        </St.AlignCenterRow>
        <St.TimeText>{dateTime}</St.TimeText>
      </St.FirstRowContainer>

      <St.SecondRowContainer>
        {isResume ? (
          <St.SecondRowResumeText>{message}</St.SecondRowResumeText>
        ) : (
          <St.SecondRowText>{message}</St.SecondRowText>
        )}
        {isResume && messageLength > 200 && (
          <St.SeeDetails onClick={() => goToNotifications()}>
            Ver detalhes
          </St.SeeDetails>
        )}
      </St.SecondRowContainer>
    </St.NotificationCardContainer>
  );
};

const NotificationCard = ({
  isResume = false,
  title,
  status,
  notificationTime,
  today,
  email,
  fileName,
  message,
  notificationId,
  isExpired,
  notificationType,
  goToNotifications,
  ...others
}) => {
  const isTypeReport = notificationType === 'REPORT';
  const isTypeSimple = notificationType === 'SIMPLE';
  const isNotificationToday =
    notificationTime &&
    today &&
    notificationTime?.slice(0, 5) === today?.slice(0, 5);
  const slicedTime = notificationTime && notificationTime?.slice(8, 13);
  const dateTime = isNotificationToday ? slicedTime : notificationTime;

  return (
    <div key={notificationId} {...others}>
      {isTypeReport && (
        <ReportNotification
          isResume={isResume}
          title={title}
          status={status}
          notificationTime={notificationTime}
          email={email}
          fileName={fileName}
          message={message}
          notificationId={notificationId}
          isExpired={isExpired}
          dateTime={dateTime}
        />
      )}
      {isTypeSimple && (
        <SimpleNotification
          isResume={isResume}
          title={title}
          message={message}
          status={status}
          dateTime={dateTime}
          goToNotifications={goToNotifications}
        />
      )}
    </div>
  );
};

export default NotificationCard;
