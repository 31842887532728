import { getSessionClientId } from 'helpers/sessionService';
import { api, apiUpload, apiDownload } from './api';

const transform = (apiResponse) => {
  return {
    data: apiResponse.data.imports,
    error: apiResponse.error,
    meta: apiResponse.meta,
  };
};

export const listFiles = async ({
  sortFields,
  filter,
  page,
  perPage,
  sort,
  searchBy,
  q,
}) => {
  const config = {
    params: {
      client: getSessionClientId(),
      page,
      per_page: perPage,
      order_by: sort,
      filter: filter,
      search_by: searchBy,
      sort_fields: JSON.stringify(sortFields),
      q: JSON.stringify(q),
    },
  };
  const response = await api().get('/imported/files', config);
  return transform(response.data);
};

export const removeFile = async (data) => {
  await api().delete(`/imported/file/${data.identifier}`);
};

export const downloadFile = (identifier) => {
  const config = {
    params: {
      client_id: getSessionClientId(),
      identifier: identifier,
    },
  };
  return apiDownload().get('/download/file', config);
};

export const uploadFile = async ({ fileName, fileType, file }) => {
  const formData = new FormData();
  formData.append('client', getSessionClientId());
  formData.append('type', fileType);
  formData.append('name', fileName);
  formData.append('file', file);

  // Upload to backend
  return apiUpload().post('/import/file', formData);
};
