import { useCallback, useEffect, useState } from 'react';
import errorHandler from 'helpers/errorHandler';
import getFeesConsolidated from 'services/fees/getFeesConsolidated';

function useFeesConsolidatedDetail({
  page,
  setPage,
  perPage = 10,
  currentActivePanel,
  selectedStore = null,
}) {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [feesConsolidated, setFeesConsolidated] = useState([]);
  const [feesConsolidatedData, setFeesConsolidatedData] = useState([]);
  const [metaData, setMetaData] = useState({});

  const fetchFeesConsolidated = useCallback(
    (pageNumber) => {
      if (currentActivePanel) {
        setIsLoading(true);
        setIsError(false);

        return getFeesConsolidated({
          acquirerId: currentActivePanel,
          page: pageNumber || page,
          perPage,
          selectedStore,
        })
          .then(({ fees: data = [], meta = {} }) => {
            setFeesConsolidatedData(data);
            setMetaData(meta);
          })
          .catch((err) => {
            if (err) {
              errorHandler('Ocorreu um erro:', err);
            }
            setIsError(true);
            setIsLoading(false);
          })
          .finally(() => setIsLoading(false));
      }
      return true;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page]
  );

  // open/change panel acquirer
  useEffect(() => {
    setFeesConsolidated([]);
    fetchFeesConsolidated(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentActivePanel]);

  useEffect(() => {
    setFeesConsolidated((prev) => {
      return [...new Set([...prev, ...feesConsolidatedData])];
    });
  }, [feesConsolidatedData]);

  useEffect(() => {
    if (page > 1) {
      fetchFeesConsolidated();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const refreshFeesConsolidated = () => {
    setPage(1);
    setFeesConsolidated([]);
    fetchFeesConsolidated(1);
  };

  return {
    isError,
    isLoading,
    feesConsolidated,
    metaData,
    refreshFeesConsolidated,
  };
}

export default useFeesConsolidatedDetail;
