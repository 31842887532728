const objectifyArray = (storeArray) => {
  return storeArray.reduce((acc, cur) => {
    return { ...acc, [cur.store_id]: cur };
  }, {});
};

export const DataChangedFilter = (data, dataChanged) => {
  const storesRedux = data || [];
  const changedStoresForm = dataChanged?.stores || [];
  const storesObj = objectifyArray(storesRedux);

  const filteredStoresChanged = changedStoresForm.filter((store) => {
    const reduxStore = storesObj[store.store_id];
    if (
      store?.short_name !== reduxStore?.short_name ||
      store?.name !== reduxStore?.name ||
      store?.br_cnpj !== reduxStore?.br_cnpj
    ) {
      return true;
    }
    return false;
  });

  const filteredDataChanged = {
    address: dataChanged?.address,
    stores: filteredStoresChanged,
  };

  return filteredDataChanged || {};
};
