import { Button } from 'antd';
import styled from 'styled-components';
import { colors } from 'styles/colors';

export const StNotificationContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  background-color: ${({ notificationsAreVisible }) =>
    notificationsAreVisible ? colors.primary7 : 'transparent'};
  padding: 0 4px;
`;

export const StNotificationButton = styled(Button)`
  position: relative;
  height: 100%;

  &&& {
    display: flex;
    align-items: start;
    color: ${colors.gray1};
  }
`;

export const StNotificationDot = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${colors.red5};
  border: 1px solid ${colors.gray1};
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: 50px;
  cursor: pointer;
`;
