import { call, put } from 'redux-saga/effects';
import errorHandler from 'helpers/errorHandler';
import getAmountsReceivableDetailsFiltersFromAPI from 'services/filters/details/amountsReceivableDetailsFilters';
import { errorToShow } from 'store/ducks/errorToShow/actions';

import {
  amountsReceivableDetailsFiltersSuccess,
  amountsReceivableDetailsFiltersError,
} from './actions';

export function* amountsReceivableDetailsFiltersSaga(action) {
  const { payload } = action;
  try {
    const apiData = yield call(
      getAmountsReceivableDetailsFiltersFromAPI,
      payload
    );
    yield put(amountsReceivableDetailsFiltersSuccess(apiData));
  } catch (err) {
    if (
      err?.response?.data?.error &&
      err?.response?.data?.error?.type &&
      err?.response?.data?.error?.message
    ) {
      yield put(
        errorToShow({
          message: err.response.data.error.message,
          type: err.response.data.error.type,
        })
      );
      yield put(
        amountsReceivableDetailsFiltersError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(
        err.response.data.error.message,
        err.response.data.error
      );
    }
  }
}
