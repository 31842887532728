import styled from 'styled-components';
import { Input, Spin } from 'antd';
import { colors } from 'styles/colors';

export const StContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 25px;
  border-radius: 5px;
  background-color: ${colors.gray1};
`;

export const StAcquirerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StAcquirerNameLabel = styled.span`
  margin: 0 16px;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
`;

export const StSelectedStoresLabel = styled.span`
  color: ${colors.gray8};
`;

export const StModalContent = styled.div`
  margin-top: 25px;
  color: ${colors.gray9};
`;

export const StStoreContainer = styled.div`
  max-height: 350px;
  overflow-y: auto;
`;

export const StStoreItem = styled.p`
  margin: 0;
  padding: 5px;
  border-bottom: 1px solid ${colors.gray4};
  line-height: 32px;

  &:last-child {
    border: none;
  }
`;

export const StSpinContainer = styled.div`
  height: 20px;
  margin-top: 10px;
`;

export const StSpin = styled(Spin)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const StSearch = styled(Input.Search)`
  margin: 20px 0;
`;
