import { call, put } from 'redux-saga/effects';

import errorHandler from 'helpers/errorHandler';

import {
  exportAllClientsDetailsSuccess,
  exportAllClientsDetailsError,
} from 'store/ducks/details/export/allClientsDetails/actions';

import getExportAllClientsDetailsFromAPI from 'services/details/export/allClientsDetails';

export function* exportAllClientsDetailsSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(getExportAllClientsDetailsFromAPI, payload);
    yield put(exportAllClientsDetailsSuccess(apiData));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        exportAllClientsDetailsError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(exportAllClientsDetailsError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}
