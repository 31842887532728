import { call, put, delay } from 'redux-saga/effects';
import errorHandler from 'helpers/errorHandler';
import { errorToShow } from 'store/ducks/errorToShow/actions';
import getRadarDetailsFiltersFromAPI from 'services/filters/details/radarDetailsFilters';
import {
  radarDetailsFiltersSuccess,
  radarDetailsFiltersError,
} from './actions';

export function* radarDetailsFiltersSaga(action) {
  const { payload } = action;
  try {
    const apiData = yield call(getRadarDetailsFiltersFromAPI, payload);
    yield delay(500);
    yield put(radarDetailsFiltersSuccess(apiData));
  } catch (err) {
    if (
      err?.response?.data?.error &&
      err?.response?.data?.error?.type &&
      err?.response?.data?.error?.message
    ) {
      yield put(
        errorToShow({
          message: err.response.data.error.message,
          type: err.response.data.error.type,
        })
      );
      yield put(
        radarDetailsFiltersError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    }
  }
}
