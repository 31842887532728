import React, { useState, useEffect, forwardRef, useContext } from 'react';
import { Button, Form, Input, Modal } from 'antd';
import { isOperational } from 'helpers/authorizationService';
import { updateStoreStart } from 'store/ducks/cnpjMaintenance/updateStore/actions';
import { useDispatch, useSelector } from 'react-redux';
import { InfoContainer, InfoIcon, StCpfCnpj } from './styled';
import { shortId } from 'helpers/shortId';
import { PermissionsContext } from 'routes/PrivateRoute';
import scopes from 'constants/scopes';
import { CnpjMaintenanceContext } from 'components/CnpjMaintenance/CnpjMaintenance';
import { getCnpjStart } from 'store/ducks/receitaWebservice/actions';

const UpdateStoreModal = forwardRef(
  // eslint-disable-next-line no-unused-vars
  ({ visibility, closeModal }, ref) => {
    const { currentStoreToUpdate } = useContext(CnpjMaintenanceContext);

    const dispatch = useDispatch();
    const {
      br_cnpj: currentBrCnpj,
      short_name: currentShortName,
      store_id: currentStoreId,
      name: currentName,
      has_transactions: hasTransactions,
    } = currentStoreToUpdate;

    const {
      permissions: { scope_id: scopeId },
    } = useContext(PermissionsContext);

    const [updateForm] = Form.useForm();
    const [saveButtonIsDisabled, setSaveButtonIsDisabled] = useState(true);
    const updateStoreLoading = useSelector(
      (state) => state.updateStore?.loading
    );
    const updateStoreSuccess = useSelector(
      (state) => state.updateStore?.success
    );
    const receitaWsData = useSelector((state) => state.receitaWebservice.data);
    const receitaWsLoading = useSelector(
      (state) => state.receitaWebservice.loading
    );
    const [hasError, setError] = useState(false);

    const handleFormFields = () => {
      const { brCnpj, storeName, shortName } = updateForm.getFieldsValue();

      if (
        shortName === currentShortName &&
        brCnpj === currentBrCnpj &&
        storeName === currentName
      ) {
        setSaveButtonIsDisabled(true);
        return;
      }

      setSaveButtonIsDisabled(false);
    };

    const onCancelModal = () => {
      setSaveButtonIsDisabled(true);
      closeModal();
    };

    const handleOnFinish = () => {
      const { brCnpj, storeName, shortName } = updateForm.getFieldsValue();

      dispatch(
        updateStoreStart({
          store_id: currentStoreId,
          br_cnpj: brCnpj || currentBrCnpj,
          name: storeName || currentName,
          short_name: shortName || currentShortName,
        })
      );
    };

    const onChangeCnpj = (e) => {
      const cnpj = e.target.value.replace(/\D/g, '');
      if (cnpj?.length === 14) {
        dispatch(getCnpjStart({ cnpj }));
      }
    };

    useEffect(() => {
      updateForm.setFields([{ name: 'brCnpj', errors: [] }]);
      if (!receitaWsData) return;
      if (receitaWsData?.status === 'ERROR') {
        setError(true);
        updateForm.setFields([
          {
            name: 'brCnpj',
            errors: [receitaWsData?.message],
          },
        ]);
        return;
      }
      const { brCnpj } = updateForm.getFieldsValue();
      const { nome = '', fantasia = '' } = receitaWsData;
      if (brCnpj !== currentBrCnpj && (nome || fantasia)) {
        updateForm.setFieldsValue({
          storeName: nome ?? fantasia,
        });
      }
      setError(false);
      updateForm.setFields([{ name: 'brCnpj', errors: [] }]);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [receitaWsData]);

    useEffect(() => {
      if (updateStoreSuccess) {
        setSaveButtonIsDisabled(true);
        closeModal();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateStoreSuccess]);

    return (
      <>
        <Modal
          title="Editar a loja"
          open={visibility}
          onCancel={closeModal}
          preserve={false}
          destroyOnClose
          centered
          width={575}
          maskClosable={false}
          footer={[
            <Button
              key={shortId()}
              disabled={updateStoreLoading}
              onClick={() => {
                onCancelModal();
              }}
            >
              Cancelar
            </Button>,
            <Button
              key={shortId()}
              type="primary"
              loading={updateStoreLoading || receitaWsLoading}
              disabled={
                hasError ||
                saveButtonIsDisabled ||
                updateStoreLoading ||
                receitaWsLoading
              }
              onClick={handleOnFinish}
            >
              Salvar
            </Button>,
          ]}
        >
          <Form
            onFieldsChange={handleFormFields}
            name="updateStoreCnpjMaintenance"
            form={updateForm}
            layout="vertical"
            style={{ width: '100%' }}
            preserve={false}
          >
            <Form.Item
              name="brCnpj"
              label="CNPJ"
              preserve={false}
              initialValue={currentBrCnpj}
            >
              <StCpfCnpj
                defaultValue={currentBrCnpj}
                placeholder="00.000.000/0000-00"
                name="br_cnpj"
                onChange={(e) => onChangeCnpj(e)}
                disabled={
                  scopeId !== scopes.CONCIL ||
                  updateStoreLoading ||
                  hasTransactions
                }
              />
            </Form.Item>
            <Form.Item
              name="storeName"
              label="Razão social"
              initialValue={currentName}
              preserve={false}
            >
              <Input
                placeholder="Razão social"
                disabled={
                  scopeId !== scopes.CONCIL ||
                  hasTransactions ||
                  updateStoreLoading ||
                  receitaWsLoading
                }
                defaultValue={currentName}
              />
            </Form.Item>
            <Form.Item
              name="shortName"
              label="Apelido da loja"
              preserve={false}
              initialValue={currentShortName}
            >
              <Input
                disabled={isOperational() || updateStoreLoading}
                placeholder="Apelido da loja"
                defaultValue={currentShortName}
                onChange={(e) => {
                  updateForm.setFieldsValue({
                    shortName: e.target.value?.toUpperCase(),
                  });
                }}
              />
            </Form.Item>
            <InfoContainer>
              <InfoIcon />
              <span>
                Dependendo do volume de transações, essa alteração poderá
                impactar temporariamente a atualização dos seus dados.
              </span>
            </InfoContainer>
          </Form>
        </Modal>
      </>
    );
  }
);
export default UpdateStoreModal;
