export const actionTypes = {
  ACQUIRER_START: '@acquirer/ACQUIRER_START',
  ACQUIRER_ALL_START: '@acquirer/ACQUIRER_ALL_START',
  ACQUIRER_SUCCESS: '@acquirer/ACQUIRER_SUCCESS',
  ACQUIRER_ERROR: '@acquirer/ACQUIRER_ERROR',
  ACQUIRER_POINTOFSALE_START: '@acquirer/ACQUIRER_POINTOFSALE_START',
  ACQUIRER_POINTOFSALE_SUCCESS: '@acquirer/ACQUIRER_POINTOFSALE_SUCCESS',
  ACQUIRER_POINTOFSALE_ERROR: '@acquirer/ACQUIRER_POINTOFSALE_ERROR',
  ACQUIRER_VOUCHER_START: '@acquirer/ACQUIRER_VOUCHER_START',
  ACQUIRER_VOUCHER_SUCCESS: '@acquirer/ACQUIRER_VOUCHER_SUCCESS',
  ACQUIRER_VOUCHER_ERROR: '@acquirer/ACQUIRER_VOUCHER_ERROR',
  ACQUIRER_MERCHANTS_START: '@acquirer/ACQUIRER_MERCHANTS_START',
  ACQUIRER_MERCHANTS_SUCCESS: '@acquirer/ACQUIRER_MERCHANTS_SUCCESS',
  ACQUIRER_MERCHANTS_ERROR: '@acquirer/ACQUIRER_MERCHANTS_ERROR',
};
