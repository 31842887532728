export const actionTypes = {
  FEES_UPDATE_FILTERED_COLUMNS_START:
    '@feesUpdateFilteredColumns/FEES_UPDATE_FILTERED_COLUMNS_START',
  FEES_UPDATE_FILTERED_COLUMNS_SUCCESS:
    '@feesUpdateFilteredColumns/FEES_UPDATE_FILTERED_COLUMNS_SUCCESS',
  FEES_UPDATE_FILTERED_COLUMNS_ERROR:
    '@feesUpdateFilteredColumns/FEES_UPDATE_FILTERED_COLUMNS_ERROR',
  FEES_RESET_UPDATE_FILTERED_COLUMNS_START:
    '@feesUpdateFilteredColumns/FEES_RESET_UPDATE_FILTERED_COLUMNS_START',
  FEES_RESET_UPDATE_FILTERED_COLUMNS_SUCCESS:
    '@feesUpdateFilteredColumns/FEES_RESET_UPDATE_FILTERED_COLUMNS_SUCCESS',
  FEES_RESET_UPDATE_FILTERED_COLUMNS_ERROR:
    '@feesUpdateFilteredColumns/FEES_RESET_UPDATE_FILTERED_COLUMNS_ERROR',
};
