/* eslint-disable no-unused-expressions */
import dayjs from 'dayjs';
import { splitNParts } from 'helpers/generalService';
import { api } from '../api';

const transformer = (apiResponse) => {
  const apiResults = apiResponse?.data?.results;
  const transformedResults = [];
  const debug = process.env.REACT_APP_DEBUG === 'true';

  apiResults.forEach((item) => {
    let transformedItem;
    if (debug) {
      const generatedNumbers = [];

      const split = splitNParts(100, 3);
      for (let i = 0; i < 3; i += 1) {
        generatedNumbers?.push(split.next().value);
      }

      transformedItem = {
        acquirer: {
          percentPendent: generatedNumbers[0],
          percentDifference: generatedNumbers[1],
          percentReconciled: generatedNumbers[2],
          totalAmount: new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(23457),
          rawTotalAmount: 23457,
          name: item?.acquirer?.name,
        },
        customer: {
          percentPendent: generatedNumbers[0],
          percentDifference: generatedNumbers[1],
          percentReconciled: generatedNumbers[2],
          totalAmount: new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(543534),
          rawTotalAmount: 543534,
          name: item?.customer?.name,
        },
      };
      transformedResults.push(transformedItem);
    } else {
      transformedItem = {
        acquirer: {
          percentPendent: item?.acquirer?.percent_pendent,
          percentDifference: item?.acquirer?.percent_difference,
          percentReconciled: item?.acquirer?.percent_reconciled,
          totalAmount: new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(item?.acquirer?.total_amount),
          rawTotalAmount: item?.acquirer?.total_amount,
          name: item?.acquirer?.name,
        },
        customer: {
          percentPendent: item?.customer?.percent_pendent,
          percentDifference: item?.customer?.percent_difference,
          percentReconciled: item?.customer?.percent_reconciled,
          totalAmount: new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(item?.customer?.total_amount),
          rawTotalAmount: item?.customer?.total_amount,
          name: item?.customer?.name,
        },
      };
      transformedResults.push(transformedItem);
    }
  });

  return transformedResults;
};

const getReconciledSales = async ({
  type,
  orderBy,
  filter,
  startDate,
  endDate,
}) => {
  const config = {
    params: {
      type,
      order_by: orderBy,
      filter,
      start_date: dayjs(startDate).format('YYYY-MM-DD'),
      end_date: dayjs(endDate).format('YYYY-MM-DD'),
    },
  };
  const response = await api().get('/sales/reconciled', config);
  return transformer(response.data);
};

export default getReconciledSales;
