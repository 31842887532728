import React from 'react';
import {
  Row,
  Col,
  Switch,
  Spin,
} from 'antd';
import {
  StSideBarMenu,
  StTextSideMenu,
  StContainer,
  StText,
  StCheckbox,
  StSpinContainer,
  StButton,
} from './styled';
import Checkbox from 'antd/es/checkbox/Checkbox';

import { partnerTypes } from 'constants/offers';
import { colors } from 'styles/colors';

const SideMenu = ({
  availablePartners,
  availablePartnersLoading,
  onlyActiveOffers,
  setOnlyActiveOffers,
  filteredOffers,
  setFilteredOffers,
  filteredPartners,
  setFilteredPartners,
}) => {
  const renderAvailablePartnerTypes = () => {
    return availablePartners?.map((partner) => {
      if (!partner?.name?.toLowerCase()?.includes('btg')) {
        const partnerType = partnerTypes[partner?.type];
        return (
          <Col span={24}>
            <StCheckbox
              value={partnerType}
              onChange={(checkbox) => {
                setFilteredOffers({
                  ...filteredOffers,
                  [checkbox?.target?.value]: checkbox?.target?.checked,
                });
              }}
            >
              {partnerType}
            </StCheckbox>
          </Col>
        );
      }
      return <></>;
    });
  };

  const renderAvailablePartners = () => {
    return availablePartners?.map((partner) => {
      if (!partner?.name?.toLowerCase()?.includes('btg')) {
        return (
          <Col span={24}>
            <StCheckbox
              value={partner?.name}
              onChange={(checkbox) => {
                setFilteredPartners({
                  ...filteredPartners,
                  [checkbox?.target?.value]: checkbox?.target?.checked,
                });
              }}
            >
              {partner?.name}
            </StCheckbox>
          </Col>
        );
      }
      return <></>;
    });
  };

  return (
    <StSideBarMenu>
      <StContainer>
        <StTextSideMenu>Filtro</StTextSideMenu>

        <StButton
          onClick={() => {
            const toBeClearedOffers = { ...filteredOffers };
            Object.keys(filteredOffers).forEach((key) => {
              toBeClearedOffers[key] = false;
            });
            setFilteredOffers(toBeClearedOffers);
            const toBeClearedPartners = { ...filteredPartners };
            Object.keys(filteredPartners).forEach((key) => {
              toBeClearedPartners[key] = false;
            });
            setFilteredPartners(toBeClearedPartners);
            setOnlyActiveOffers(false);
          }}
        >
          Limpar tudo
        </StButton>
      </StContainer>
      <Row style={{ marginTop: 24 }} align="middle" justify="center">
        <Col
          align="middle"
          span={24}
          style={{
            padding: 12,
            border: `1px solid ${colors.gray5}`,
            borderRadius: 2,
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
          }}
        >
          <StText>Somente ofertas ativas</StText>
          <Switch
            checked={onlyActiveOffers}
            onChange={(checked) => {
              setOnlyActiveOffers(checked);
            }}
          />
        </Col>
      </Row>

      <Row justify="start" style={{ marginTop: 24 }}>
        <Col span="24">
          <Row>
            <StText style={{ fontWeight: 500 }}>Tipos de ofertas</StText>
          </Row>
          <Row>
            <Checkbox.Group
              value={() => {
                /**
                 * It's important to use value instead of defaultValues
                 * in order to be able to control the checkbox group state,
                 * otherwise the checkbox group will always keep the initial state.
                 */
                const checkedValues = [];
                Object.entries(filteredOffers).forEach((value) => {
                  if (value[1]) {
                    checkedValues.push(value[0]);
                  }
                });
                return checkedValues;
              }}
            >
              {availablePartnersLoading ? (
                <StSpinContainer>
                  <Spin size="small" />{' '}
                </StSpinContainer>
              ) : (
                <Row>
                  <Col span={24}>
                    <StCheckbox
                      value="Todos"
                      checked
                      onChange={(checkbox) => {
                        /**
                         * Todo: Refactor, maybe?
                         */
                        if (checkbox?.target?.checked === false) {
                          setFilteredOffers({
                            ...filteredOffers,
                            [checkbox?.target?.value]: false,
                          });
                        } else {
                          const allTrue = {
                            ...filteredOffers,
                          };
                          Object.keys(allTrue).forEach((key) => {
                            allTrue[key] = true;
                          });
                          setFilteredOffers(allTrue);
                        }
                      }}
                    >
                      Todos
                    </StCheckbox>
                  </Col>
                  {renderAvailablePartnerTypes()}
                </Row>
              )}
            </Checkbox.Group>
          </Row>
        </Col>
      </Row>

      <Row justify="start" style={{ marginTop: 24 }}>
        <Col span="24">
          <Row>
            <StText style={{ fontWeight: 500 }}>Parceiros</StText>
          </Row>
          <Row>
            <Checkbox.Group
              value={() => {
                const checkedValues = [];
                Object.entries(filteredPartners).forEach((value) => {
                  if (value[1]) {
                    checkedValues.push(value[0]);
                  }
                });
                return checkedValues;
              }}
            >
              {availablePartnersLoading ? (
                <StSpinContainer>
                  <Spin size="small" />{' '}
                </StSpinContainer>
              ) : (
                <Row>
                  <Col span={24}>
                    <StCheckbox
                      value="Todos"
                      checked
                      onChange={(checkbox) => {
                        /**
                         * Todo: Refactor, maybe?
                         */
                        if (checkbox?.target?.checked === false) {
                          setFilteredPartners({
                            ...filteredPartners,
                            [checkbox?.target?.value]: false,
                          });
                        } else {
                          const allTrue = {
                            ...filteredPartners,
                          };
                          Object.keys(allTrue).forEach((key) => {
                            allTrue[key] = true;
                          });
                          setFilteredPartners(allTrue);
                        }
                      }}
                    >
                      Todos
                    </StCheckbox>
                  </Col>
                  {renderAvailablePartners()}
                </Row>
              )}
            </Checkbox.Group>
          </Row>
        </Col>
      </Row>
    </StSideBarMenu>
  );
};

export default SideMenu;
