import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StContainer, StTitle, StWrapper, StButton } from './styled';
import { Col, Row, Dropdown, Table, Button } from 'antd';
import { DownOutlined, ReloadOutlined } from '@ant-design/icons';
import { columns } from './utils/columns';
import { radarListStart } from 'store/ducks/radar/radarList/actions';
import {
  radarRunTestStart,
  updateRadarHasBeenSentStart,
} from 'store/ducks/radar/radarRunTest/actions';
import { radarRunStart } from 'store/ducks/radar/radarRun/actions';
import {
  isAdmin,
  isConciler,
  isSuperAdmin,
} from 'helpers/authorizationService';
import { TestRadarModal } from './components/TestRadarModal';
import { SendRadarConfirm } from './components/SendRadarConfirm';
import scopes from 'constants/scopes';
import { generateFilterDataStructure } from 'helpers/tableService';
import {
  radarListFiltersZeroLength,
  updateRadarListFilters,
} from 'store/ducks/radar/radarListFilters/actions';
import { onColumnSort } from 'helpers/sorter';
import { perPageOptions, showTotalPage } from 'constants/perPageOptions';
import { customResetUpdateFilteredColumnsStart } from 'store/ducks/customUpdateFilteredColumns/actions';
import { RadarDetailModal } from './components/RadarDetailModal';

const Radar = () => {
  const dispatch = useDispatch();
  const [isTestRadarModalVisible, setIsTestRadarModalVisible] = useState(false);
  const [isRadarModalVisible, setIsRadarModalVisible] = useState(false);
  const [typeRadarConfirm, setTypeRadarConfirm] = useState('');
  const listRadarLoading = useSelector((state) => state.radarList?.loading);
  const listRadarData = useSelector((state) => state.radarList?.data);
  const listRadarMeta = useSelector((state) => state.radarList?.meta);
  const runRadarTestLoading = useSelector(
    (state) => state.radarRunTest.loading
  );
  const testRadarHasBeenSent = useSelector(
    (state) => state.radarRunTest.radarHasBeenSent
  );

  const permissionsLoading = useSelector((state) => state.permissions.loading);

  const [radarId, setRadarId] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [pageTotal, setPageTotal] = useState(100);

  const [sortFields, setSortFields] = useState({});

  const filtersData = useSelector((state) => state.radarListFilters?.filters);
  const toParamsFilters = useSelector(
    (state) => state.radarListFilters?.toParamsFilters
  );

  const [showTable, setShowTable] = useState(true);
  const isDisableClearButton = toParamsFilters?.length === 0;

  const onTablePaginationOrFiltersOrSorterChange = (
    pagination,
    tableFilters,
    sorter
  ) => {
    const filterDataToDispatch = generateFilterDataStructure(tableFilters);

    onColumnSort(sorter, setSortFields);
    dispatch(updateRadarListFilters(filterDataToDispatch));
    setPerPage(pagination.pageSize);
    setPageTotal(listRadarMeta.total);
    setCurrentPage(pagination.current);
  };

  const showTestRadarModal = () => {
    setIsTestRadarModalVisible(!isTestRadarModalVisible);
  };

  const handleOk = ({
    clientId,
    type,
    selectedClientsEmails = [],
    selectedConcilersEmails = [],
  }) => {
    const scopeId = [];

    if (selectedClientsEmails.length > 0) scopeId.push(scopes.CLIENTE);
    if (selectedConcilersEmails.length > 0) scopeId.push(scopes.CONCIL);

    dispatch(
      radarRunTestStart({
        clientId,
        type,
        scopeId,
        emails: [...selectedClientsEmails, ...selectedConcilersEmails],
      })
    );
  };

  const onSendRadarConfirm = (type) => {
    setTypeRadarConfirm(type);
  };

  const onResetTypeRadarConfirm = () => {
    setTypeRadarConfirm('');
  };

  const handleRunRadar = (type) => {
    dispatch(radarRunStart({ type }));
  };

  const closeRadarModal = () => {
    setRadarId();
    setIsRadarModalVisible(false);
  };

  const radarModalProps = {
    radarId,
    closeRadarModal,
    isRadarModalVisible,
  };

  const testRadarModalProps = {
    isTestRadarModalVisible,
    handleOk,
    setIsTestRadarModalVisible,
    runRadarTestLoading,
    testRadarHasBeenSent,
  };

  const handleOpenSpecificRadar = ({ id }) => {
    setIsRadarModalVisible(true);
    setRadarId(id);
  };

  const resetFiltersStates = () => {
    dispatch(radarListFiltersZeroLength());
    dispatch(updateRadarListFilters([]));
    dispatch(customResetUpdateFilteredColumnsStart());
    setSortFields({});
  };

  const handleResetFilters = () => {
    setShowTable(false);
    resetFiltersStates();
  };

  const fetchRadarList = () => {
    dispatch(
      radarListStart({
        page: currentPage,
        perPage,
        sortFields,
        q: {
          filter: toParamsFilters,
        },
      })
    );
  };

  const dataSource = listRadarData.map((item) => ({
    ...item,
    key: item.idt_radar,
  }));

  const items = [
    (isConciler() || isAdmin() || isSuperAdmin()) && {
      label: 'Gerar teste de envio',
      key: '1',
      disabled: !(isSuperAdmin() || isAdmin()),
      onClick: showTestRadarModal,
    },
    {
      label: 'Executar envio semanal',
      key: '2',
      disabled: !isSuperAdmin(),
      onClick: () => isSuperAdmin() && onSendRadarConfirm('weekly'),
    },
    {
      label: 'Executar envio mensal',
      key: '3',
      disabled: !isSuperAdmin(),
      onClick: () => isSuperAdmin() && onSendRadarConfirm('monthly'),
    },
  ];

  useEffect(() => {
    setShowTable(true);
  }, [showTable]);

  useEffect(() => {
    if (testRadarHasBeenSent) {
      dispatch(
        updateRadarHasBeenSentStart({
          value: false,
        })
      );
      setIsTestRadarModalVisible(false);
      dispatch(
        radarListStart({
          page: currentPage,
          perPage,
        })
      );
    }
    // eslint-disable-next-line
  }, [testRadarHasBeenSent]);

  useEffect(() => {
    if (currentPage && perPage && toParamsFilters && sortFields) {
      fetchRadarList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, perPage, toParamsFilters, sortFields]);

  useEffect(() => {
    setCurrentPage(listRadarMeta.page);
    setPerPage(listRadarMeta.per_page);
    setPageTotal(listRadarMeta.total);
  }, [listRadarMeta]);

  return (
    <>
      {(isSuperAdmin() || isAdmin()) && !permissionsLoading && (
        <StContainer>
          <Row gutter={[24, 24]}>
            <Col lg={24} xl={24} xxl={24}>
              <StTitle style={{ fontSize: '30px', margin: 0 }}>Radar</StTitle>
            </Col>
          </Row>
          <StWrapper>
            <Row
              justify="space-between"
              align="middle"
              style={{ paddingBottom: '10px' }}
            >
              <Col>
                <StTitle>Relatório de execuções</StTitle>
              </Col>
              <Col>
                <Row gutter={[16, 16]}>
                  <Col>
                    <Button icon={<ReloadOutlined />} onClick={fetchRadarList}>
                      Atualizar
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      onClick={handleResetFilters}
                      disabled={isDisableClearButton}
                    >
                      Limpar filtros
                    </Button>
                  </Col>
                  <Col>
                    <Dropdown
                      destroyPopupOnHide
                      overlayStyle={{ zIndex: 999 }}
                      menu={{ items }}
                    >
                      <StButton>
                        Ações <DownOutlined />
                      </StButton>
                    </Dropdown>
                  </Col>
                </Row>
              </Col>
            </Row>
            {showTable && (
              <Table
                columns={columns({
                  handleOpenSpecificRadar,
                  apiFilters: filtersData,
                  toParamsFilters,
                })}
                dataSource={dataSource}
                showSorterTooltip={false}
                loading={listRadarLoading}
                onChange={onTablePaginationOrFiltersOrSorterChange}
                pagination={{
                  current: currentPage,
                  pageSize: perPage,
                  total: pageTotal,
                  pageSizeOptions: perPageOptions,
                  showTotal: showTotalPage,
                  showSizeChanger: true,
                }}
                scroll={{ x: '100%' }}
              />
            )}
          </StWrapper>
          {isRadarModalVisible && <RadarDetailModal {...radarModalProps} />}
          {isTestRadarModalVisible && (
            <TestRadarModal {...testRadarModalProps} />
          )}
          <SendRadarConfirm
            typeRadarConfirm={typeRadarConfirm}
            onResetTypeRadarConfirm={onResetTypeRadarConfirm}
            handleRunRadar={handleRunRadar}
          />
        </StContainer>
      )}
    </>
  );
};

export default Radar;
