import { actionTypes } from './types';

const INITIAL_STATE = {
  error: false,
  success: false,
  loading: true,
  previous: {},
  errorMessage: '',
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case actionTypes.ALTER_PROFILE_SCOPE_START:
  case actionTypes.SET_LOADING_SCOPE:
    return { ...state, loading: true, success: false, error: false };
  case actionTypes.ALTER_PROFILE_SCOPE_SUCCESS:
    return {
      ...state,
      loading: false,
      error: false,
      success: true,
      previous: action.payload,
      errorMessage: null,
    };
  case actionTypes.ALTER_PROFILE_SCOPE_ERROR:
    return {
      ...state,
      loading: false,
      success: false,
      error: action.error.error,
      errorMessage: action.payload,
    };
  default:
    return state;
  }
};

export default reducer;
