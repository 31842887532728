import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Tabs, Row, Col, Skeleton } from 'antd';
import {
  ContentCards,
  SummaryCard,
} from 'components/Intermediate/PaymentsDivergences/components';
import { StLimiter } from './styled';
import { marginMainContainer } from 'helpers/style';
import { colors } from 'styles/colors';
import dayjs from 'dayjs';

const ContentTab = ({
  breakpoint,
  startDate,
  endDate,
  infoData,
  isInfoError,
  infoLoading,
  activeTab,
  infoFilter,
  summary,
  paymentsSummaryIsError,
  paymentsSummaryLoading,
}) => {
  return (
    <StLimiter $breakpoint={breakpoint}>
      <Row style={{ marginTop: '2rem' }}>
        <Col xxl={8} xl={16} lg={16} md={18} sm={24} xs={24}>
          <Skeleton loading={infoLoading} active paragraph={{ rows: 6 }}>
            <SummaryCard
              summary={summary}
              startDate={startDate}
              endDate={endDate}
              isError={paymentsSummaryIsError}
              loading={paymentsSummaryLoading}
            />
          </Skeleton>
        </Col>
      </Row>
      <br />
      <Skeleton loading={infoLoading} active paragraph={{ rows: 10 }}>
        <ContentCards
          data={infoData}
          loading={infoLoading}
          error={isInfoError}
          startDate={startDate}
          endDate={endDate}
          activeTab={activeTab}
          infoFilter={infoFilter}
        />
      </Skeleton>
    </StLimiter>
  );
};

const ContentTabs = ({
  startDate,
  endDate,
  infoData,
  isInfoError,
  infoLoading,
  onTabClick,
  activeTab,
  infoFilter,
  summary,
  paymentsSummaryIsError,
  paymentsSummaryLoading,
}) => {
  const { useBreakpoint } = Grid;
  const breakpoint = useBreakpoint();

  const items = [
    {
      label: 'Filiais',
      key: 'branches',
      children: activeTab === 'branches' && (
        <ContentTab
          breakpoint={breakpoint}
          startDate={startDate}
          endDate={endDate}
          infoData={infoData}
          isInfoError={isInfoError}
          infoLoading={infoLoading}
          activeTab={activeTab}
          infoFilter={infoFilter}
          summary={summary}
          paymentsSummaryIsError={paymentsSummaryIsError}
          paymentsSummaryLoading={paymentsSummaryLoading}
        />
      ),
    },
    {
      label: 'Adquirentes',
      key: 'acquirers',
      children: activeTab === 'acquirers' && (
        <ContentTab
          breakpoint={breakpoint}
          startDate={startDate}
          endDate={endDate}
          infoData={infoData}
          isInfoError={isInfoError}
          infoLoading={infoLoading}
          activeTab={activeTab}
          infoFilter={infoFilter}
          summary={summary}
          paymentsSummaryIsError={paymentsSummaryIsError}
          paymentsSummaryLoading={paymentsSummaryLoading}
        />
      ),
    },
    {
      label: 'Produtos',
      key: 'products',
      children: activeTab === 'products' && (
        <ContentTab
          breakpoint={breakpoint}
          startDate={startDate}
          endDate={endDate}
          infoData={infoData}
          isInfoError={isInfoError}
          infoLoading={infoLoading}
          activeTab={activeTab}
          infoFilter={infoFilter}
          summary={summary}
          paymentsSummaryIsError={paymentsSummaryIsError}
          paymentsSummaryLoading={paymentsSummaryLoading}
        />
      ),
    },
    {
      label: 'Bandeiras',
      key: 'brands',
      children: activeTab === 'brands' && (
        <ContentTab
          breakpoint={breakpoint}
          startDate={startDate}
          endDate={endDate}
          infoData={infoData}
          isInfoError={isInfoError}
          infoLoading={infoLoading}
          activeTab={activeTab}
          infoFilter={infoFilter}
          summary={summary}
          paymentsSummaryIsError={paymentsSummaryIsError}
          paymentsSummaryLoading={paymentsSummaryLoading}
        />
      ),
    },
  ];

  return (
    <Tabs
      items={items}
      onTabClick={onTabClick}
      tabBarStyle={{
        backgroundColor: colors.gray1,
        marginTop: '160px',
        padding: marginMainContainer(breakpoint),
        fontSize: '12px',
        position: 'fixed',
        zIndex: '90',
        width: '100%',
      }}
    />
  );
};

ContentTabs.propTypes = {
  infoData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      client_id: PropTypes.number,
      net_amount_difference_reconciled: PropTypes.number,
      late_payments_count: PropTypes.number,
      pending_net_amout: PropTypes.number,
      reconciled_net_amout: PropTypes.number,
      forecast_net_amout: PropTypes.number,
    })
  ).isRequired,
  onTabClick: PropTypes.func.isRequired,
  infoLoading: PropTypes.bool.isRequired,
  activeTab: PropTypes.string.isRequired,
  isInfoError: PropTypes.shape({
    type: PropTypes.string,
    message: PropTypes.string,
    code: PropTypes.number,
    field: PropTypes.string,
  }).isRequired,
  startDate: PropTypes.instanceOf(dayjs).isRequired,
  endDate: PropTypes.instanceOf(dayjs).isRequired,
  summary: PropTypes.shape({
    net_amount_difference_reconciled: PropTypes.number,
    late_payments_count: PropTypes.number,
    pending_net_amout: PropTypes.number,
    reconciled_net_amout: PropTypes.number,
    forecast_net_amout: PropTypes.number,
  }).isRequired,
};

export default ContentTabs;
