import React from 'react';
import { Grid } from 'antd';

import PropTypes from 'prop-types';
import { StContainer, StTable } from './styled';

const Tables = ({
  customColumns,
  dataSource,
  loading,
  onChange,
  pagination,
}) => {
  const { useBreakpoint } = Grid;
  const breakpoint = useBreakpoint();

  if (breakpoint?.md) {
    return (
      <>
        <StContainer>
          <StTable
            onChange={onChange}
            pagination={pagination}
            loading={loading}
            dataSource={dataSource}
            columns={customColumns}
            scroll={{
              x: '100%',
              y: window.innerHeight - 400,
            }}
          />
        </StContainer>
      </>
    );
  }
  return '';
};

Tables.defaultProps = {
  pagination: {
    pageSize: 10,
    total: 100,
    current: 1,
    pageSizeOptions: ['10', '100', '200', '300', '400', '500', '1000'],
    showTotal: () => '',
  },
};

Tables.propTypes = {
  dataSource: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  loading: PropTypes.bool.isRequired,
  pagination: PropTypes.shape({
    pageSize: PropTypes.number,
    total: PropTypes.number,
    current: PropTypes.number,
  }),
  onChange: PropTypes.func.isRequired,
};

export default Tables;
