import { action } from 'typesafe-actions';
import { actionTypes } from './types';

export const amountsReceivableDetailsFiltersStart = (data) =>
  action(actionTypes.AMOUNTS_RECEIVABLE_DETAILS_FILTERS_START, data);
export const amountsReceivableDetailsFiltersSuccess = (data) =>
  action(actionTypes.AMOUNTS_RECEIVABLE_DETAILS_FILTERS_SUCCESS, data);
export const amountsReceivableDetailsFiltersError = (errorMessage, error) =>
  action(
    actionTypes.AMOUNTS_RECEIVABLE_DETAILS_FILTERS_ERROR,
    { errorMessage },
    undefined,
    { error }
  );
export const amountsReceivableDetailsFiltersZeroLength = () =>
  action(actionTypes.AMOUNTS_RECEIVABLE_DETAILS_FILTERS_ZERO_LENGTH);
export const updateAmountsReceivableDetailsFilters = (data) =>
  action(actionTypes.UPDATE_AMOUNTS_RECEIVABLE_DETAILS_FILTERS, data);
export const clearAmountsReceivableDetailsFilters = (data) =>
  action(actionTypes.CLEAR_AMOUNTS_RECEIVABLE_DETAILS_FILTERS, data);
