import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FeesContext } from 'components/Fees/Fees';
import { ExportDropdown } from 'commons/ExportDropdown';
import {
  getSessionClientId,
  getSessionClientName,
} from 'helpers/sessionService';
import {
  createNotificationStart,
  getNotificationStart,
} from 'store/ducks/notifications/actions';
import { usePlansFeaturesPermissions } from 'utils/hooks/usePlansFeaturesPermissions';
import { dataTestIdHandler } from 'helpers/dataTestIdHandler';

const ExportButton = () => {
  const dispatch = useDispatch();
  const { isFeatureAvailable } = usePlansFeaturesPermissions();
  const dataTestPrefix = 'fee-header';

  const { activeTab, activeDetailTab, disableExcel, disableCSV } =
    useContext(FeesContext);

  const [dropdownOption, setDropdownOption] = useState('');

  const fileType = dropdownOption === 'excel' ? 'xlsx' : 'csv';

  const fileName =
    activeTab === 'fees-consolidated'
      ? `relatorio_resumo_taxas.${fileType}`
      : `relatorio_taxas_${activeDetailTab}.${fileType}`;

  const permissionsData = useSelector((state) => state.permissions.data);
  const clientId = getSessionClientId();
  const clientName = getSessionClientName();

  const {
    user_id: userId,
    scope_id: scopeId,
    profile_id: profileId,
  } = permissionsData;

  const createNotificationSuccess = useSelector(
    (state) => state.notifications.success
  );
  const createNotificationLoading = useSelector(
    (state) => state.notifications.loading
  );

  const table =
    activeTab === 'fees-consolidated'
      ? 'fees-summary'
      : activeDetailTab === 'vigentes'
        ? 'fees-current'
        : 'fees-expired';

  const notificationConfig = {
    clientId,
    userId,
    status: 'PREPARANDO',
    title: 'Preparando arquivo',
    fileName,
    createdBy: userId,
    updatedBy: userId,
    message: `Estamos preparando seu arquivo ${fileName} para o download.`,
    table,
    fileType,
    scopeId,
    profileId,
    sortFields: '',
    clientName,
  };

  useEffect(() => {
    if (dropdownOption === 'excel' || dropdownOption === 'csv') {
      dispatch(createNotificationStart(notificationConfig));
      setDropdownOption('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownOption]);

  useEffect(() => {
    if (createNotificationSuccess) {
      dispatch(
        getNotificationStart({
          userId,
          scopeId,
          clientId,
          page: 1,
          perPage: 10,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createNotificationSuccess]);

  return (
    <ExportDropdown
      blocked={!isFeatureAvailable({ key: 'general-fileExport' })}
      loading={createNotificationLoading}
      setDropdownOption={setDropdownOption}
      disableExcel={disableExcel}
      disableCSV={disableCSV}
      dataTestPrefix={dataTestPrefix}
      {...dataTestIdHandler(dataTestPrefix, 'export-button')}
    />
  );
};

export default ExportButton;
