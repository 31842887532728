import React, { useEffect, useState } from 'react';
import { Button, Tag, Dropdown, notification, Modal, Checkbox } from 'antd';
import { DownOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { StTableColumnTitle } from 'commons/Table/styled';
import { columnSorter } from 'helpers/generalService';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { forgotPasswordRequest } from '../../../../store/ducks/forgotPassword/actions';
import { removeUser } from '../../../../store/ducks/removeUser/actions';
import { isAdmin, isSuperAdmin } from 'helpers/authorizationService';
import { getSessionClientName } from 'helpers/sessionService';
import { StTable } from './styled';
import { perPageOptions, showTotalPage } from 'constants/perPageOptions';
import { shortId } from 'helpers/shortId';

const UsersTable = ({ data, alterDrawerUser }) => {
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [deletionItem, setDeletionItem] = useState({});
  const [definiteDeletion, setDefiniteDeletion] = useState(false);
  const loadingRemoveUser = useSelector((state) => state.removeUser.success);

  useEffect(() => {
    if (loadingRemoveUser === true) {
      window.location.reload();
      setIsModalVisible(false);
    }
  }, [loadingRemoveUser]);

  const resetPassword = (item) => {
    const args = {
      email: item.email,
    };
    dispatch(forgotPasswordRequest(args));
    notification.success({
      message: `Enviamos as instruções para resetar a senha para ${item.email}`,
    });
  };

  const onCheckDeletion = (e) => {
    setDefiniteDeletion(e.target.checked);
  };

  const deleteUser = () => {
    dispatch(
      removeUser({
        email: deletionItem.email,
        client_id: deletionItem.client_id,
        definitely: definiteDeletion,
      })
    );
  };

  const renderStatusText = (text) => {
    switch (text) {
      case 'ACCEPTED':
        return 'Ativo';
      case 'SENT':
        return 'Convidado';
      case 'RESENT':
        return 'Convidado';
      case 'FAILED':
        return 'Falha no convite';
      default:
        return 'Pendente';
    }
  };

  const changeStatusColor = (status) => {
    switch (status) {
      case 'ACCEPTED':
        return 'blue';
      case 'INACTIVE':
        return 'warning';
      case 'FAILED':
        return 'red';
      default:
        return '';
    }
  };

  const columns = [
    {
      title: () => {
        return <StTableColumnTitle>Nome</StTableColumnTitle>;
      },
      dataIndex: 'name',
      key: shortId(),
      sorter: (a, b) => columnSorter(a, b, 'name'),
    },
    {
      title: () => {
        return <StTableColumnTitle>Sobrenome</StTableColumnTitle>;
      },
      dataIndex: 'surname',
      key: shortId(),
      sorter: (a, b) => columnSorter(a, b, 'name'),
    },
    {
      title: () => {
        return <StTableColumnTitle>Email</StTableColumnTitle>;
      },
      dataIndex: 'email',
      key: shortId(),
      sorter: (a, b) => columnSorter(a, b, 'email'),
      width: 280,
    },
    {
      title: () => {
        return <StTableColumnTitle>Escopo</StTableColumnTitle>;
      },
      dataIndex: 'scope',
      key: shortId(),
      sorter: (a, b) => columnSorter(a, b, 'scope'),
    },
    {
      title: () => {
        return <StTableColumnTitle>Permissão</StTableColumnTitle>;
      },
      dataIndex: 'profile',
      key: shortId(),
      sorter: (a, b) => columnSorter(a, b, 'profile'),
    },
    {
      title: () => {
        return <StTableColumnTitle>Último login web</StTableColumnTitle>;
      },
      dataIndex: 'web_last_login',
      key: shortId(),
      sorter: (a, b) => columnSorter(a, b, 'web_last_login'),
    },
    {
      title: () => {
        return <StTableColumnTitle>Último login app</StTableColumnTitle>;
      },
      dataIndex: 'app_last_login',
      key: shortId(),
      sorter: (a, b) => columnSorter(a, b, 'app_last_login'),
    },
    {
      title: () => {
        return <StTableColumnTitle>Status</StTableColumnTitle>;
      },
      dataIndex: 'status',
      key: shortId(),
      sorter: (a, b) => columnSorter(a, b, 'status'),
      render: (item) => (
        <Tag color={changeStatusColor(item)}>{renderStatusText(item)}</Tag>
      ),
    },
    {
      title: () => {
        return <StTableColumnTitle>Ações</StTableColumnTitle>;
      },
      key: shortId(),
      fixed: 'right',
      render: (item) => {
        const items = [
          {
            label: 'Editar permissões',
            key: 'edit',
            onClick: () => {
              alterDrawerUser(item);
            },
          },
          {
            label: 'Resetar senha',
            key: 'changePassword',
            onClick: () => {
              resetPassword(item);
            },
          },
          {
            label: 'Remover acesso',
            key: 'reset',
            danger: true,
            onClick: () => {
              setDeletionItem(item);
              setIsModalVisible(true);
            },
          },
        ];
        return (
          <Dropdown menu={{ items }}>
            <Button icon={<DownOutlined />}>Ações</Button>
          </Dropdown>
        );
      },
    },
  ];

  const dataSource = data.map((item) => {
    return {
      ...item,
      key: shortId(),
    };
  });

  return (
    <>
      <StTable
        dataSource={dataSource}
        columns={columns}
        bordered
        rowClassName="table-row-line"
        showSorterTooltip={false}
        pagination={{
          total: data?.length,
          pageSizeOptions: perPageOptions,
          showTotal: showTotalPage,
          showSizeChanger: Boolean(data?.length >= 10),
        }}
        scroll={{
          x: '100%',
          y: window.innerHeight - 400,
        }}
      />
      <Modal
        title="Remover acesso da empresa?"
        icon={<ExclamationCircleOutlined />}
        open={isModalVisible}
        onOk={deleteUser}
        onCancel={() => {
          setIsModalVisible(false);
        }}
        okText="Remover"
        cancelText="Cancelar"
      >
        <>
          {`O usuário ${
            deletionItem.email
          } será removido da empresa ${getSessionClientName()}.`}
          <br />
          {isSuperAdmin() || isAdmin() ? (
            <Checkbox onChange={onCheckDeletion} style={{ marginTop: '16px' }}>
              Excluir o usuário definitivamente do sistema
            </Checkbox>
          ) : (
            <></>
          )}
        </>
      </Modal>
    </>
  );
};

UsersTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  alterDrawerUser: PropTypes.func.isRequired,
};

export default UsersTable;
