import { action } from 'typesafe-actions';
import { actionTypes } from './types';

export const adjustmentDetailsFiltersStart = (data) => action(actionTypes.ADJUSTMENT_DETAILS_FILTERS_START, data);
export const adjustmentDetailsFiltersSuccess = (data) =>
  action(actionTypes.ADJUSTMENT_DETAILS_FILTERS_SUCCESS, data);
export const adjustmentDetailsFiltersError = (errorMessage, error) =>
  action(actionTypes.ADJUSTMENT_DETAILS_FILTERS_ERROR, { errorMessage }, undefined, { error });
export const adjustmentDetailsFiltersZeroLength = () =>
  action(actionTypes.ADJUSTMENT_DETAILS_FILTERS_ZERO_LENGTH);
export const updateAdjustmentDetailsFilters = (data) =>
  action(actionTypes.UPDATE_ADJUSTMENT_DETAILS_FILTERS, data);
