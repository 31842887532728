import React from 'react';
import { UserOutlined } from '@ant-design/icons';
import { StMenuButton, StUserMenuButtonContainer } from './styled';
import { dataTestIdHandler } from 'helpers/dataTestIdHandler';

export function UserMenuButton({
  changeToggleRightMenu,
  toggleMenuRight,
  dataTestPrefix,
  ...rest
}) {
  return (
    <StUserMenuButtonContainer toggleMenuRight={toggleMenuRight} {...rest}>
      <StMenuButton
        {...dataTestIdHandler(dataTestPrefix, 'user-button')}
        icon={
          <UserOutlined
            style={{
              fontSize: '2rem',
            }}
          />
        }
        type="link"
        onClick={changeToggleRightMenu}
      />
    </StUserMenuButtonContainer>
  );
}
