import { action } from 'typesafe-actions';
import { actionTypes } from './types';

export const reconciledSalesDetailsFiltersStart = (data) =>
  action(actionTypes.RECONCILED_SALES_DETAILS_FILTERS_START, data);
export const reconciledSalesDetailsFiltersSuccess = (data) =>
  action(actionTypes.RECONCILED_SALES_DETAILS_FILTERS_SUCCESS, data);
export const reconciledSalesDetailsFiltersError = (errorMessage, error) =>
  action(
    actionTypes.RECONCILED_SALES_DETAILS_FILTERS_ERROR,
    { errorMessage },
    undefined,
    { error }
  );
export const reconciledSalesDetailsFiltersZeroLength = () =>
  action(actionTypes.RECONCILED_SALES_DETAILS_FILTERS_ZERO_LENGTH);
export const updateReconciledSalesDetailsFilters = (data) =>
  action(actionTypes.UPDATE_RECONCILED_SALES_DETAILS_FILTERS, data);
