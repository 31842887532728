export const actionTypes = {
  GET_NEXT_ORDER_BY_CLIENT_ID_START:
    '@getNextOrderByClientId/GET_NEXT_ORDER_BY_CLIENT_ID_START',
  GET_NEXT_ORDER_BY_CLIENT_ID_SUCCESS:
    '@getNextOrderByClientId/GET_NEXT_ORDER_BY_CLIENT_ID_SUCCESS',
  GET_NEXT_ORDER_BY_CLIENT_ID_ERROR:
    '@getNextOrderByClientId/GET_NEXT_ORDER_BY_CLIENT_ID_ERROR',
  RESET_NEXT_ORDER_BY_CLIENT_ID_START:
    '@getNextOrderByClientId/RESET_NEXT_ORDER_BY_CLIENT_ID_START',
  RESET_NEXT_ORDER_BY_CLIENT_ID_SUCCESS:
    '@getNextOrderByClientId/RESET_NEXT_ORDER_BY_CLIENT_ID_SUCCESS',
  RESET_NEXT_ORDER_BY_CLIENT_ID_ERROR:
    '@getNextOrderByClientId/RESET_NEXT_ORDER_BY_CLIENT_ID_ERROR',
};
