import styled from 'styled-components';

import { marginMainContainer } from 'helpers/style';
import { Button } from 'antd';
import { colors } from 'styles/colors';

export const StContainer = styled.div`
  /* margin-top: 3rem; */
`;

export const StLimiter = styled.div`
  margin: ${(props) => marginMainContainer(props.$breakpoint)};
`;

export const StTableContainer = styled.div`
  /* margin-top: 192px; */
`;

export const StExportButton = styled(Button)`
  &&& {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    background-color: ${colors.gray1};
    border-radius: 2px;
    border: 1px solid ${colors.gray5};
    box-sizing: border-box;
  }
`;
