import React, { useState } from 'react';
import { ConciliationReport } from './components/ConciliationReport';
import { Filters } from 'commons/Intermediate/Filters';
import { useDispatch, useSelector } from 'react-redux';
import { updateScreenToScreenDateStart } from 'store/ducks/updateScreenToScreenDate/actions';
import { bankConciliationFiltersZeroLength } from 'store/ducks/filters/details/bankConciliationFilters/actions';
import { disableLastMonth } from 'helpers/disableDates';
import dayjs from 'dayjs';

// Functions
const BankConciliation = () => {
  const dispatch = useDispatch();

  const startDate = useSelector(
    (state) => state.updateScreenToScreenDate.startDate
  );
  const endDate = useSelector(
    (state) => state.updateScreenToScreenDate.endDate
  );
  const loadingList = useSelector((state) => state.bankConciliation.loading);

  const [calendarIsOpen, setCalendarIsOpen] = useState(false);

  const onRangePickerChange = (dates) => {
    if (dates?.length) {
      if (
        calendarIsOpen &&
        (!dates[1] || dates[1]?.diff(dates[0], 'days') > 31)
      ) {
        dispatch(
          updateScreenToScreenDateStart({
            startDate: dates[0],
            endDate: dayjs(dates[0]).add(31, 'days'),
          })
        );
        dispatch(bankConciliationFiltersZeroLength());
      } else if (
        calendarIsOpen &&
        (!dates[1] || dates[1]?.diff(dates[0], 'days') <= 31)
      ) {
        dispatch(
          updateScreenToScreenDateStart({
            startDate: dates[0],
            endDate: dates[1],
          })
        );
        dispatch(bankConciliationFiltersZeroLength());
      }
    } else {
      dispatch(
        updateScreenToScreenDateStart({
          startDate: dayjs().subtract(32, 'days'),
          endDate: dayjs().subtract(1, 'day'),
        })
      );
      dispatch(bankConciliationFiltersZeroLength());
    }
  };

  const onCalendarChange = (open) => {
    setCalendarIsOpen(open);
  };

  return (
    <>
      <Filters
        title="Conciliação bancária"
        subtitle="Verifique seus recebimentos conciliados e não conciliados nas adquirentes e nos extratos"
        onRangePickerOpenOrCloseChange={onCalendarChange}
        hasRangePicker
        dateDefaultValue={[startDate, endDate]}
        onRangePickerChange={onRangePickerChange}
        loading={loadingList}
        disabledDate={disableLastMonth}
        calendarIsOpen={calendarIsOpen}
        setCalendarIsOpen={setCalendarIsOpen}
        hasSorter={false}
        hasSelect={false}
      />

      <ConciliationReport />
    </>
  );
};

export default BankConciliation;
