import { actionTypes } from 'store/ducks/radar/radarList/types';

const INITIAL_STATE = {
  error: undefined,
  errorMessage: undefined,
  loading: false,
  isError: false,
  data: [],
  meta: {
    page: 1,
    per_page: 10,
    total: 100,
    has_next: true,
    filters: [],
    sort_fields: {},
  },
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.RADAR_LIST_START:
      return {
        ...state,
        data: [],
        loading: true,
        error: undefined,
        errorMessage: undefined,
        isError: false,
      };
    case actionTypes.RADAR_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        data: action.payload?.data,
        meta: action.payload?.meta,
        isError: false,
        errorMessage: undefined,
      };
    case actionTypes.RADAR_LIST_ERROR:
      return {
        ...state,
        data: [],
        loading: false,
        error: action.error.error,
        errorMessage: action.payload,
        isError: true,
      };
    default:
      return state;
  }
};

export default reducer;
