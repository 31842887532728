import styled from 'styled-components';
import { Button, Input, Table as AntTable, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { colors } from 'styles/colors';

const { Text } = Typography;
const { Search } = Input;

export const Container = styled.div`
  padding: 24px;
  background-color: #f4f4f4;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.gray1};
`;

export const TableHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 24px 24px 16px 24px;
  gap: 16px;
`;

export const Title = styled(Text)`
  &&& {
    margin: 2rem 23rem 1.5rem 4.125rem;
    font-size: 2.5rem;
    line-height: 1.6;
  }
`;

export const Table = styled(AntTable)`
  &&& {
    .ant-pagination-options::before {
      content: 'Itens por página';
      margin-right: 10px;
    }

    .ant-table-thead .ant-table-cell {
      font-size: 14px;
      font-weight: 500;
    }

    margin: 0 24px;
  }
`;

export const AddButton = styled(Button)`
  margin: 0 8px;
  display: flex;
  align-items: center;
  margin: 0;
`;

export const AddIcon = styled(PlusOutlined)`
  &&& {
    font-size: 15px;
  }
`;

export const SearchInput = styled(Search)`
  &&& {
    width: 320px;
    .ant-input {
      padding: 4px 12px;
    }
  }
`;

export const ClearFiltersButton = styled(Button)`
  &&& {
    margin: 0;
    padding: 5px 16px;
  }
`;
