/* eslint-disable react/jsx-closing-tag-location */
import React, { useState } from 'react';
import { Col, Grid } from 'antd';
import { PasswordForm } from './components';
import { StContainer, StRow, StTitleRow } from './styled';
import { colors } from 'styles/colors';

const RecoveryPassword = () => {
  const { useBreakpoint } = Grid;
  const breakpoint = useBreakpoint();
  const [isTokenPasswordScreen, setIsTokenPasswordScreen] = useState(false);
  const [passwordWasChanged, setPasswordWasChanged] = useState(false);

  return (
    <StContainer $breakpoint={breakpoint}>
      <div style={{ display: 'block' }}>
        {passwordWasChanged ? (
          <></>
        ) : (
          <StTitleRow align="middle">
            <Col span={24}>
              {!isTokenPasswordScreen ? (
                <div>
                  <p style={{ color: colors.gray8, fontWeight: 600 }}>
                    Boas vindas ao Concil Card!
                  </p>
                  <p style={{ fontSize: 14 }}>
                    Redefina sua senha para voltar a acessar a plataforma
                  </p>
                </div>
              ) : (
                <div>
                  <p style={{ color: colors.gray8, fontWeight: 600 }}>Quase lá!</p>
                  <p style={{ fontSize: 14 }}>
                    Para redefinir a sua senha, insira o código de verificação
                    que recebeu em seu e-mail
                  </p>
                </div>
              )}
            </Col>
          </StTitleRow>
        )}

        <StRow align="middle">
          <Col>
            <PasswordForm
              isTokenPasswordScreen={isTokenPasswordScreen}
              setIsTokenPasswordScreen={setIsTokenPasswordScreen}
              passwordWasChanged={passwordWasChanged}
              setPasswordWasChanged={setPasswordWasChanged}
            />
          </Col>
        </StRow>
      </div>
    </StContainer>
  );
};

export default RecoveryPassword;
