import { put } from 'redux-saga/effects';

import errorHandler from 'helpers/errorHandler';

import {
  updateScreenToScreenSalesPeriodSuccess,
  updateScreenToScreenSalesPeriodError,
  updateScreenToScreenPaymentDivergencesPeriodSuccess,
  updateScreenToScreenPaymentDivergencesPeriodError,
  updateScreenToScreenAmountsReceivablePeriodSuccess,
  updateScreenToScreenAmountsReceivablePeriodError,
  updateScreenToScreenBanksPeriodSuccess,
  updateScreenToScreenBanksPeriodError,
  updateScreenToScreenDuedatePeriodSuccess,
  updateScreenToScreenDuedatePeriodError,
  updateScreenToScreenAdvancementsPeriodSuccess,
  updateScreenToScreenAdvancementsPeriodError,
  updateScreenToScreenAdjustmentsPeriodSuccess,
  updateScreenToScreenAdjustmentsPeriodError,
  updateScreenToScreenRefundsPeriodSuccess,
  updateScreenToScreenRefundsPeriodError,
  updateScreenToScreenSalesReconciliationPeriodSuccess,
  updateScreenToScreenSalesReconciliationPeriodError,
  updateScreenToScreenPaymentsReconciliationPeriodSuccess,
  updateScreenToScreenPaymentsReconciliationPeriodError,
  updateScreenToScreenBankReconciliationPeriodSuccess,
  updateScreenToScreenBankReconciliationPeriodError,
} from 'store/ducks/updateScreenToScreenPeriod/actions';

export function* updateScreenToScreenSalesPeriodSaga(action) {
  try {
    const { payload } = action;
    yield put(updateScreenToScreenSalesPeriodSuccess(payload));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        updateScreenToScreenSalesPeriodError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(updateScreenToScreenSalesPeriodError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}

export function* updateScreenToScreenPaymentDivergencesPeriodSaga(action) {
  try {
    const { payload } = action;
    yield put(updateScreenToScreenPaymentDivergencesPeriodSuccess(payload));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        updateScreenToScreenPaymentDivergencesPeriodError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(
        updateScreenToScreenPaymentDivergencesPeriodError(
          'Ocorreu um erro:',
          err
        )
      );
      errorHandler('Ocorreu um erro:', err);
    }
  }
}

export function* updateScreenToScreenAmountsReceivablePeriodSaga(action) {
  try {
    const { payload } = action;
    yield put(updateScreenToScreenAmountsReceivablePeriodSuccess(payload));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        updateScreenToScreenAmountsReceivablePeriodError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(
        updateScreenToScreenAmountsReceivablePeriodError(
          'Ocorreu um erro:',
          err
        )
      );
      errorHandler('Ocorreu um erro:', err);
    }
  }
}

export function* updateScreenToScreenBanksPeriodSaga(action) {
  try {
    const { payload } = action;
    yield put(updateScreenToScreenBanksPeriodSuccess(payload));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        updateScreenToScreenBanksPeriodError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(updateScreenToScreenBanksPeriodError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}

export function* updateScreenToScreenDuedatePeriodSaga(action) {
  try {
    const { payload } = action;
    yield put(updateScreenToScreenDuedatePeriodSuccess(payload));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        updateScreenToScreenDuedatePeriodError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(
        updateScreenToScreenDuedatePeriodError('Ocorreu um erro:', err)
      );
      errorHandler('Ocorreu um erro:', err);
    }
  }
}

export function* updateScreenToScreenAdvancementsPeriodSaga(action) {
  try {
    const { payload } = action;
    yield put(updateScreenToScreenAdvancementsPeriodSuccess(payload));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        updateScreenToScreenAdvancementsPeriodError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(
        updateScreenToScreenAdvancementsPeriodError('Ocorreu um erro:', err)
      );
      errorHandler('Ocorreu um erro:', err);
    }
  }
}

export function* updateScreenToScreenAdjustmentsPeriodSaga(action) {
  try {
    const { payload } = action;
    yield put(updateScreenToScreenAdjustmentsPeriodSuccess(payload));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        updateScreenToScreenAdjustmentsPeriodError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(
        updateScreenToScreenAdjustmentsPeriodError('Ocorreu um erro:', err)
      );
      errorHandler('Ocorreu um erro:', err);
    }
  }
}

export function* updateScreenToScreenRefundsPeriodSaga(action) {
  try {
    const { payload } = action;
    yield put(updateScreenToScreenRefundsPeriodSuccess(payload));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        updateScreenToScreenRefundsPeriodError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(
        updateScreenToScreenRefundsPeriodError('Ocorreu um erro:', err)
      );
      errorHandler('Ocorreu um erro:', err);
    }
  }
}

export function* updateScreenToScreenSalesReconciliationPeriodSaga(action) {
  try {
    const { payload } = action;

    yield put(updateScreenToScreenSalesReconciliationPeriodSuccess(payload));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        updateScreenToScreenSalesReconciliationPeriodError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(
        updateScreenToScreenSalesReconciliationPeriodError(
          'Ocorreu um erro:',
          err
        )
      );
      errorHandler('Ocorreu um erro:', err);
    }
  }
}

export function* updateScreenToScreenPaymentsReconciliationPeriodSaga(action) {
  try {
    const { payload } = action;

    yield put(updateScreenToScreenPaymentsReconciliationPeriodSuccess(payload));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        updateScreenToScreenPaymentsReconciliationPeriodError(
          err.response.data.error.message,
          err.response.data.error
        )
      );

      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(
        updateScreenToScreenPaymentsReconciliationPeriodError(
          'Ocorreu um erro:',
          err
        )
      );
      errorHandler('Ocorreu um erro:', err);
    }
  }
}

export function* updateScreenToScreenBankReconciliationPeriodSaga(action) {
  try {
    const { payload } = action;

    yield put(updateScreenToScreenBankReconciliationPeriodSuccess(payload));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        updateScreenToScreenBankReconciliationPeriodError(
          err.response.data.error.message,
          err.response.data.error
        )
      );

      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(
        updateScreenToScreenBankReconciliationPeriodError(
          'Ocorreu um erro:',
          err
        )
      );
      errorHandler('Ocorreu um erro:', err);
    }
  }
}
