import { actionTypes } from './types';

const INITIAL_STATE = {
  error: undefined,
  errorMessage: undefined,
  loading: false,
  loadingActions: false,
  isError: false,
  briefing: undefined,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case actionTypes.GET_BRIEFING_START:
    return {
      ...state,
      loading: true,
      error: undefined,
      errorMessage: undefined,
      isError: false,
    };
  case actionTypes.POST_BRIEFING_START:
    return {
      ...state,
      loadingActions: true,
      error: undefined,
      errorMessage: undefined,
      isError: false,
    };
  case actionTypes.PUT_BRIEFING_START:
    return {
      ...state,
      loadingActions: true,
      error: undefined,
      errorMessage: undefined,
      isError: false,
    };
  case actionTypes.POST_BRIEFING_ERROR:
  case actionTypes.GET_BRIEFING_ERROR:
  case actionTypes.PUT_BRIEFING_ERROR:
    return {
      ...state,
      loading: false,
      error: action.error.error,
      loadingActions: false,
      errorMessage: action.payload,
      isError: true,
    };
  case actionTypes.GET_BRIEFING_SUCCESS:
    return {
      ...state,
      loading: false,
      briefing: action.payload,
    };
  case actionTypes.PUT_BRIEFING_SUCCESS:
    return {
      ...state,
      loading: false,
      loadingActions: false,
      isError: false,
    };
  case actionTypes.POST_BRIEFING_SUCCESS:
    return {
      ...state,
      loading: false,
      loadingActions: false,
      isError: false,
    };
  default:
    return state;
  }
};

export default reducer;
