import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Button, Form, Typography, notification } from 'antd';
import {
  EyeTwoTone,
  EyeInvisibleOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { PasswordCreated } from '../PasswordCreated';
import {
  hasLowercase,
  hasOneNumber,
  hasSpecialChar,
  hasUppercase,
} from './utils';
import {
  StAlignContent,
  StCreatePasswordTitle,
  StPasswordTextInfo,
} from './styled';
import { completeNewPasswordRequest } from 'store/ducks/completeNewPassword/actions';
import {
  StLiContainer,
  StCustomLi,
  StCustomUl,
} from 'components/RecoveryPassword/components/passwordForm/styled';

const PasswordForm = ({
  authData,
  passwordWasCreated,
  setPasswordWasCreated,
}) => {
  const dispatch = useDispatch();

  const [passwordInput, setPasswordInput] = useState('');
  const [confirmPasswordInput, setConfirmPasswordInput] = useState('');
  const [currentStatus, setCurrentStatus] = useState('');
  const isCreatingPassword = useSelector(
    (state) => state.createPassword.loading
  );

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };
  const tailLayout = {
    wrapperCol: { span: 24 },
  };

  const onFinish = (values) => {
    const { password } = values;
    dispatch(
      completeNewPasswordRequest({
        authData,
        password,
      })
    );
    setPasswordWasCreated(true);
  };

  const onFinishFailed = (formErrors) => {
    const errorFields = formErrors.errorFields.map((item) => item);
    const errors = errorFields.flatMap((item) => item.errors);
    errors.map((item, index) =>
      notification.warning({ key: index, message: item, duration: 5 })
    );
  };

  useEffect(() => {
    if (
      passwordInput?.length === 0 ||
      !passwordInput ||
      passwordInput === '' ||
      passwordInput === ' '
    ) {
      setCurrentStatus('');
    } else {
      // eslint-disable-next-line no-lonely-if
      if (
        passwordInput?.length >= 8 &&
        passwordInput?.length > 0 &&
        hasUppercase(passwordInput) &&
        passwordInput?.length > 0 &&
        hasLowercase(passwordInput) &&
        passwordInput?.length > 0 &&
        hasOneNumber(passwordInput) &&
        passwordInput?.length > 0 &&
        hasSpecialChar(passwordInput)
      ) {
        setCurrentStatus('success');
      } else {
        setCurrentStatus('error');
      }
    }
  }, [passwordInput]);

  return (
    <StAlignContent>
      {passwordWasCreated === true ? (
        <PasswordCreated />
      ) : (
        <>
          <StCreatePasswordTitle>Criar senha</StCreatePasswordTitle>
          <StPasswordTextInfo>
            Sua senha deve conter ao menos:
          </StPasswordTextInfo>
          <StCustomUl>
            <StLiContainer>
              <StCustomLi
                status={currentStatus}
                validNumOfChars={passwordInput}
              />
              <Typography.Text>8 caracteres</Typography.Text>
            </StLiContainer>
            <StLiContainer>
              <StCustomLi
                status={currentStatus}
                validUppercase={passwordInput}
              />
              <Typography.Text>1 letra maiúscula</Typography.Text>
            </StLiContainer>
            <StLiContainer>
              <StCustomLi
                status={currentStatus}
                validLowercase={passwordInput}
              />
              <Typography.Text>1 letra minúscula</Typography.Text>
            </StLiContainer>
            <StLiContainer>
              <StCustomLi
                status={currentStatus}
                validOneNumber={passwordInput}
              />
              <Typography.Text>1 número</Typography.Text>
            </StLiContainer>
            <StLiContainer>
              <StCustomLi
                status={currentStatus}
                validSpecialChar={passwordInput}
              />
              <Typography.Text>
                1 caractere especial, por exemplo: ! @ # $ & *
              </Typography.Text>
            </StLiContainer>
          </StCustomUl>
          <Form
            style={{ marginTop: '3.75rem' }}
            hideRequiredMark
            name="recovery_password"
            {...layout}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={{
              password: passwordInput,
              confirm_password: confirmPasswordInput,
            }}
            onFieldsChange={(_, allFields) => {
              setPasswordInput(allFields[0]?.value);
              setConfirmPasswordInput(allFields[1]?.value);
            }}
          >
            <Form.Item
              style={{ maxWidth: '100%' }}
              hasFeedback
              validateStatus={currentStatus}
              initialValue={passwordInput}
              label="Senha::"
              name="password"
            >
              <Input.Password
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
                placeholder="Inserir senha"
              />
            </Form.Item>
            <Form.Item
              style={{ maxWidth: '100%' }}
              hasFeedback
              dependencies={['password']}
              label="Confirmar senha::"
              name="confirm_password"
              validateTrigger={['onChange']}
              rules={[
                { required: true, message: 'Por favor, confirme a senha.' },
                // eslint-disable-next-line no-unused-vars
                ({ getFieldValue }) => ({
                  // eslint-disable-next-line no-unused-vars
                  validator(rule, value) {
                    if (
                      confirmPasswordInput !== '' &&
                      passwordInput === confirmPasswordInput
                    ) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error('As senhas digitadas não conferem.')
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
                placeholder="Confirmar senha"
              />
            </Form.Item>
            <Form.Item {...tailLayout} style={{ margin: 0 }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: '100%' }}
                icon={isCreatingPassword && <LoadingOutlined />}
                disabled={isCreatingPassword}
              >
                {isCreatingPassword ? 'Criando senha' : 'Criar senha'}
              </Button>
            </Form.Item>
          </Form>
        </>
      )}
    </StAlignContent>
  );
};

export default PasswordForm;
