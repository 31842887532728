import { call, put } from 'redux-saga/effects';

import errorHandler from 'helpers/errorHandler';

import {
  duedateDetailsSuccess,
  duedateDetailsError,
} from 'store/ducks/details/duedateDetails/actions';
import getDuedateDetailsFromAPI from 'services/duedateDetails';

export function* duedateDetailsSaga(action) {
  try {
    const { payload } = action;

    const apiData = yield call(getDuedateDetailsFromAPI, payload);

    yield put(duedateDetailsSuccess(apiData));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        duedateDetailsError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    }
  }
}
