import styled from 'styled-components';
import { Tabs } from 'antd';

export const StContainer = styled.div`
  width: 100%;
  padding: 0 36px 16px;
`;
export const StLimiter = styled.div`
  margin: 0 5rem;
  padding-top: 230px;
`;
export const StTabs = styled(Tabs)`
  &&& {
    overflow-y: hidden;
  }
`;
