import errorHandler from 'helpers/errorHandler';
import { call, put } from 'redux-saga/effects';
import getNextOrderByClientIdFromAPI from 'services/reconciliationRules/getNextOrderByClientId';
import {
  getNextOrderByClientIdError,
  getNextOrderByClientIdSuccess,
  resetNextOrderByClientIdError,
  resetNextOrderByClientIdSuccess,
} from 'store/ducks/reconciliationRules/getNextOrderByClientId/actions';

export function* getNextOrderByClientIdSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(getNextOrderByClientIdFromAPI, payload);
    yield put(getNextOrderByClientIdSuccess(apiData));
  } catch (err) {
    yield put(getNextOrderByClientIdError(err?.response?.data?.message));
    errorHandler(`Erro: ${err?.response?.data?.message}`);
  }
}

export function* resetNextOrderByClientIdSaga(action) {
  try {
    const { payload } = action;
    yield put(resetNextOrderByClientIdSuccess(payload));
  } catch (err) {
    yield put(resetNextOrderByClientIdError(err?.response?.data?.message));
    errorHandler(`Erro: ${err?.response?.data?.message}`);
  }
}
