import { actionTypes } from './types';

const INITIAL_STATE = {
  filters: [],
  meta: {
    offset: 0,
    limit: 10,
    has_next: true,
    q: [],
  },
  loading: false,
  error: undefined,
  errorMessage: undefined,
  isError: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.CNPJ_MAINTENANCE_INFO_FILTERS_START:
      return {
        ...state,
        loading: true,
        error: undefined,
        errorMessage: undefined,
        isError: false,
      };
    case actionTypes.CNPJ_MAINTENANCE_INFO_FILTERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        filters: action.payload.stores,
        meta: action.payload.meta,
        isError: false,
      };
    case actionTypes.CNPJ_MAINTENANCE_INFO_FILTERS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error.error,
        errorMessage: action.payload,
        isError: true,
        filters: [],
      };
    default:
      return state;
  }
};

export default reducer;
