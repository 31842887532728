import React from 'react';
import { ReconciliationCard } from '..';
import { updateScreenToScreenSalesReconciliationPeriodStart } from 'store/ducks/updateScreenToScreenPeriod/actions';
import { salesSummaryReconciledStart } from 'store/ducks/reconciled/summary/sales/actions';
import { parsedDropdownKeys, reconIconUrl } from '../utils/parser';

const SalesReconciliationCard = ({
  isFlipped,
  setIsFlipped,
  dropdownProps,
  ...rest
}) => {
  const reconProps = {
    reconUrl: '/conciliacao-vendas',
    reconPeriodStore: 'salesReconciliationPeriod',
    reconPeriodAction: updateScreenToScreenSalesReconciliationPeriodStart,
    reconSummaryAction: salesSummaryReconciledStart,
    reconSummaryStore: 'salesSummaryReconciled',
    reconTitle: 'Conciliação de vendas',
    reconIcon: reconIconUrl,
    reconLeftColDescription: 'Estabelecimentos',
    reconRightColDescription: 'Adquirentes',
    dropdownKey: parsedDropdownKeys,
  };

  return (
    <ReconciliationCard
      hasFooter
      height={230}
      enforceMaxHeight
      reconciliationType="sales"
      isFlipped={isFlipped}
      setIsFlipped={setIsFlipped}
      dropdownProps={dropdownProps}
      reconProps={reconProps}
      context={{
        left: 'customer',
        right: 'acquirer',
      }}
      {...rest}
    />
  );
};

export default SalesReconciliationCard;
