import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Input, Modal, Button, Form } from 'antd';
import { editOFXParameterStart } from '../../../../store/ducks/ofxParameters/actions';
import LabelAndSelect from '../../../../commons/LabelAndSelect/LabelAndSelect';
import PropTypes from 'prop-types';
import { getSessionClientId } from 'helpers/sessionService';
import { shortId } from 'helpers/shortId';

// Functions
const EditOFXParameter = ({
  acquirers,
  visibility,
  loading,
  loadingAcquirers,
  editingRecord,
  closeModal,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const onCancel = () => {
    form.resetFields();
    closeModal();
  };

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibility]);

  return (
    <Modal
      open={visibility}
      title="Editar regra"
      closable={false}
      width={290}
      footer={[
        <Button key={shortId()} onClick={onCancel}>
          Cancelar
        </Button>,
        <Button
          key={shortId()}
          type="primary"
          loading={loading}
          onClick={() => {
            form.submit();
          }}
        >
          Salvar
        </Button>,
      ]}
    >
      <Form
        requiredMark={false}
        layout="vertical"
        style={{ overflowWrap: 'break-word' }}
        name="edit_ofx_parameter"
        form={form}
        onFinish={(value) => {
          dispatch(
            editOFXParameterStart({
              client: getSessionClientId(),
              id: editingRecord.id,
              ...value,
            })
          );
          form.resetFields();
        }}
      >
        <Form.Item
          key={shortId()}
          style={{ maxWidth: 269 }}
          label="Regra"
          name="rule"
          rules={[{ required: true, message: 'Por favor insira a regra!' }]}
          initialValue={editingRecord?.rule}
        >
          <Input
            type="text"
            name="rule"
            placeholder="Insira a regra"
            value={editingRecord?.rule}
          />
        </Form.Item>
        <Form.Item
          key={shortId()}
          style={{ maxWidth: 269 }}
          label="Peso"
          name="weight"
          rules={[{ required: true, message: 'Por favor insira o peso!' }]}
          initialValue={editingRecord?.weight}
        >
          <Input
            type="number"
            name="weight"
            placeholder="Insira o peso"
            value={editingRecord?.weight}
          />
        </Form.Item>
        <Form.Item
          name="acquirer_id"
          initialValue={editingRecord?.acquirer_id}
          label="Adquirente"
          rules={[{ required: true, message: 'Campo obrigatório !' }]}
          style={{ maxWidth: 269 }}
        >
          <LabelAndSelect
            key={shortId()}
            name="acquirer_id"
            labelOption="short_name"
            valueOption="acquirer_id"
            options={[
              {
                short_name: 'IGNORAR',
                acquirer_id: -1,
              },
              ...acquirers,
            ]}
            loading={loadingAcquirers}
            value={editingRecord?.acquirer_id}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

EditOFXParameter.defaultProps = {};

EditOFXParameter.propTypes = {
  visibility: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  loadingAcquirers: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default EditOFXParameter;
