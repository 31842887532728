import { action } from 'typesafe-actions';
import { actionTypes } from './types';

export const deleteMerchantStart = (data) =>
  action(actionTypes.DELETE_MERCHANT_START, data);
export const deleteMerchantSuccess = (data) =>
  action(actionTypes.DELETE_MERCHANT_SUCCESS, data);
export const deleteMerchantError = (errorMessage, error) =>
  action(actionTypes.DELETE_MERCHANT_ERROR, { errorMessage }, undefined, {
    error,
  });
