import React from 'react';

import {
  StMainContainer,
  StTitle,
  StText,
  StCustomLink,
  StImage,
  StImagesContainer,
  StTextImages,
} from './styled';
import { useNavigate } from 'react-router-dom';

const assetsBaseURL = process.env.REACT_APP_AWS_V4_ASSETS;
const appStore = `${assetsBaseURL}/img/disponivel-na-app-store-botao.png`;
const playStore = `${assetsBaseURL}/img/disponivel-google-play-badge.png`;

const PasswordCreated = () => {
  const navigate = useNavigate();

  const goToLogin = () => {
    navigate('/');
    window.location.reload();
  };

  const goToStore = (store) => {
    if (store === 'ios') {
      window.open(
        'https://apps.apple.com/br/app/novo-concil-card/id1579933854',
        '_blank'
      );
    }
    if (store === 'android') {
      window.open(
        'https://play.google.com/store/apps/details?id=br.com.concil.mobileconcilv4flutter',
        '_blank'
      );
    }
  };
  return (
    <StMainContainer>
      <StTitle>Sua senha definitiva foi criada com sucesso!</StTitle>
      <StText>
        <StCustomLink onClick={goToLogin}>
          Siga para a tela de login
        </StCustomLink>{' '}
        e entre na plataforma Concil Card utilizando a senha que acabou de
        criar.
      </StText>
      <StTextImages>
        Você também pode baixar nosso aplicativo em seu celular:
      </StTextImages>

      <StImagesContainer>
        <StImage src={appStore} onClick={() => goToStore('ios')}></StImage>
        <StImage src={playStore} onClick={() => goToStore('android')}></StImage>
      </StImagesContainer>
    </StMainContainer>
  );
};

export default PasswordCreated;
