/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Skeleton, Dropdown } from 'antd';
import PropTypes from 'prop-types';
import { LabelAndValue } from 'commons/LabelAndValue';
import { TitleAndIcon } from 'commons/TitleAndIcon';
import { StContainer, StLimiter } from './styled';
import { ErrorCard } from '../ErrorCard';
import {
  StDashboardTag,
  StDropdownContent,
  StHoverClickValue,
} from 'components/Dashboard/styled';
import { CalendarOutlined } from '@ant-design/icons';
import {
  updateScreenToScreenAdjustmentsPeriodStart,
  updateScreenToScreenAdvancementsPeriodStart,
  updateScreenToScreenDuedatePeriodStart,
  updateScreenToScreenRefundsPeriodStart,
} from 'store/ducks/updateScreenToScreenPeriod/actions';
import { duedatePaymentsDash } from 'store/ducks/paymentsDash/duedatePayments/actions';
import { advancementPaymentsDash } from 'store/ducks/paymentsDash/advancementPayments/actions';
import { listAdjustmentPaymentsDash } from 'store/ducks/paymentsDash/adjustmentPayments/actions';
import { refundsPaymentsDash } from 'store/ducks/paymentsDash/refundsPayments/actions';
import {
  currentDayMinusOne,
  endOfPreviousMonth,
  startOfCurrentMonth,
  startOfPreviousMonth,
} from 'helpers/dates';
import { dataTestIdHandler } from 'helpers/dataTestIdHandler';

const CardResume = ({
  titleCard,
  error,
  goToUrl,
  dataCard,
  loading,
  startDateDuedate,
  endDateDuedate,
  setStartDateDuedate,
  setEndDateDuedate,
  startDateAdvancements,
  endDateAdvancements,
  setStartDateAdvancements,
  setEndDateAdvancements,
  startDateAdjustments,
  endDateAdjustments,
  setStartDateAdjustments,
  setEndDateAdjustments,
  startDateRefunds,
  endDateRefunds,
  setStartDateRefunds,
  setEndDateRefunds,
  isDuedate,
  isAdvancements,
  isAdjustments,
  isRefunds,
  dataTestPrefix,
}) => {
  const dispatch = useDispatch();
  const dropdownKeyValue = useSelector((store) => {
    if (isDuedate) {
      return store.updateScreenToScreenPeriod?.duedatePeriod;
    }
    if (isAdvancements) {
      return store.updateScreenToScreenPeriod?.advancementsPeriod;
    }
    if (isAdjustments) {
      return store.updateScreenToScreenPeriod?.adjustmentsPeriod;
    }
    if (isRefunds) {
      return store.updateScreenToScreenPeriod?.refundsPeriod;
    }
    return store.updateScreenToScreenPeriod?.duedatePeriod;
  });

  useEffect(() => {
    switch (dropdownKeyValue) {
      case 'neste_mes':
        if (isDuedate) {
          dispatch(
            duedatePaymentsDash({
              startDate: startOfCurrentMonth,
              endDate: currentDayMinusOne,
            })
          );
        }
        if (isAdvancements) {
          dispatch(
            advancementPaymentsDash({
              startDate: startOfCurrentMonth,
              endDate: currentDayMinusOne,
            })
          );
        }
        if (isAdjustments) {
          dispatch(
            listAdjustmentPaymentsDash({
              startDate: startOfCurrentMonth,
              endDate: currentDayMinusOne,
            })
          );
        }
        if (isRefunds) {
          dispatch(
            refundsPaymentsDash({
              startDate: startOfCurrentMonth,
              endDate: currentDayMinusOne,
            })
          );
        }
        break;
      case 'ultimo_dia':
        if (isDuedate) {
          dispatch(
            duedatePaymentsDash({
              startDate: currentDayMinusOne,
              endDate: currentDayMinusOne,
            })
          );
        }
        if (isAdvancements) {
          dispatch(
            advancementPaymentsDash({
              startDate: currentDayMinusOne,
              endDate: currentDayMinusOne,
            })
          );
        }
        if (isAdjustments) {
          dispatch(
            listAdjustmentPaymentsDash({
              startDate: currentDayMinusOne,
              endDate: currentDayMinusOne,
            })
          );
        }
        if (isRefunds) {
          dispatch(
            refundsPaymentsDash({
              startDate: currentDayMinusOne,
              endDate: currentDayMinusOne,
            })
          );
        }
        break;
      case 'ultimo_mes':
        if (isDuedate) {
          dispatch(
            duedatePaymentsDash({
              startDate: startOfPreviousMonth,
              endDate: endOfPreviousMonth,
            })
          );
        }
        if (isAdvancements) {
          dispatch(
            advancementPaymentsDash({
              startDate: startOfPreviousMonth,
              endDate: endOfPreviousMonth,
            })
          );
        }
        if (isAdjustments) {
          dispatch(
            listAdjustmentPaymentsDash({
              startDate: startOfPreviousMonth,
              endDate: endOfPreviousMonth,
            })
          );
        }
        if (isRefunds) {
          dispatch(
            refundsPaymentsDash({
              startDate: startOfPreviousMonth,
              endDate: endOfPreviousMonth,
            })
          );
        }
        break;
      default:
        break;
    }
  }, [
    dispatch,
    endDateDuedate,
    startDateDuedate,
    startDateAdvancements,
    endDateAdvancements,
    startDateAdjustments,
    endDateAdjustments,
    startDateRefunds,
    endDateRefunds,
  ]);

  const handleDropdownMenu = (key) => {
    switch (key) {
      case 'neste_mes':
        if (isDuedate) {
          setStartDateDuedate(startOfCurrentMonth);
          setEndDateDuedate(currentDayMinusOne);
          dispatch(updateScreenToScreenDuedatePeriodStart('neste_mes'));
        }
        if (isAdvancements) {
          setStartDateAdvancements(startOfCurrentMonth);
          setEndDateAdvancements(currentDayMinusOne);
          dispatch(updateScreenToScreenAdvancementsPeriodStart('neste_mes'));
        }
        if (isAdjustments) {
          setStartDateAdjustments(startOfCurrentMonth);
          setEndDateAdjustments(currentDayMinusOne);
          dispatch(updateScreenToScreenAdjustmentsPeriodStart('neste_mes'));
        }
        if (isRefunds) {
          setStartDateRefunds(startOfCurrentMonth);
          setEndDateRefunds(currentDayMinusOne);
          dispatch(updateScreenToScreenRefundsPeriodStart('neste_mes'));
        }
        break;
      case 'ultimo_dia':
        if (isDuedate) {
          setStartDateDuedate(currentDayMinusOne);
          setEndDateDuedate(currentDayMinusOne);
          dispatch(updateScreenToScreenDuedatePeriodStart('ultimo_dia'));
        }
        if (isAdvancements) {
          setStartDateAdvancements(currentDayMinusOne);
          setEndDateAdvancements(currentDayMinusOne);
          dispatch(updateScreenToScreenAdvancementsPeriodStart('ultimo_dia'));
        }
        if (isAdjustments) {
          setStartDateAdjustments(currentDayMinusOne);
          setEndDateAdjustments(currentDayMinusOne);
          dispatch(updateScreenToScreenAdjustmentsPeriodStart('ultimo_dia'));
        }
        if (isRefunds) {
          setStartDateRefunds(currentDayMinusOne);
          setEndDateRefunds(currentDayMinusOne);
          dispatch(updateScreenToScreenRefundsPeriodStart('ultimo_dia'));
        }
        break;
      case 'ultimo_mes':
        if (isDuedate) {
          setStartDateDuedate(startOfPreviousMonth);
          setEndDateDuedate(endOfPreviousMonth);
          dispatch(updateScreenToScreenDuedatePeriodStart('ultimo_mes'));
        }
        if (isAdvancements) {
          setStartDateAdvancements(startOfPreviousMonth);
          setEndDateAdvancements(endOfPreviousMonth);
          dispatch(updateScreenToScreenAdvancementsPeriodStart('ultimo_mes'));
        }
        if (isAdjustments) {
          setStartDateAdjustments(startOfPreviousMonth);
          setEndDateAdjustments(endOfPreviousMonth);
          dispatch(updateScreenToScreenAdjustmentsPeriodStart('ultimo_mes'));
        }
        if (isRefunds) {
          setStartDateRefunds(startOfPreviousMonth);
          setEndDateRefunds(endOfPreviousMonth);
          dispatch(updateScreenToScreenRefundsPeriodStart('ultimo_mes'));
        }
        break;
      default:
        break;
    }
  };

  const handleTestSuffix = (str) => {
    return str
      .toLowerCase()
      .replace(/[^a-zA-ZÀ-ÿ]+/g, '-')
      .replace(/\s+/g, '-')
      .replace(/_/g, '-');
  };

  useEffect(() => {
    handleDropdownMenu(dropdownKeyValue);
  }, [dispatch]);

  const items = [
    {
      label: 'Neste mês',
      key: 'neste_mes',
      onClick: () => handleDropdownMenu('neste_mes'),
      'data-testid': `${dataTestPrefix}-neste-mes`,
    },
    {
      label: 'Último dia',
      key: 'ultimo_dia',
      onClick: () => handleDropdownMenu('ultimo_dia'),
      'data-testid': `${dataTestPrefix}-ultimo-dia`,
    },
    {
      label: 'Último mês',
      key: 'ultimo_mes',
      onClick: () => handleDropdownMenu('ultimo_mes'),
      'data-testid': `${dataTestPrefix}-ultimo-mes`,
    },
  ];

  if (error.has) {
    return (
      <StContainer>
        <StLimiter>
          <Row align="middle" justify="center">
            <Col>
              <ErrorCard
                title={error.title}
                reason={error.reason}
                recommendation={error.recommendation}
              />
            </Col>
          </Row>
        </StLimiter>
      </StContainer>
    );
  }
  return (
    <StContainer {...dataTestIdHandler(dataTestPrefix, 'container')}>
      <StLimiter>
        {dataCard?.length === 0 && <Skeleton loading={loading} active />}
        {dataCard?.length > 0 && (
          <>
            <Row align="middle" justify="space-between">
              <Col>
                <StHoverClickValue onClick={goToUrl}>
                  <TitleAndIcon
                    titleFontSize="2rem"
                    titleText={titleCard}
                    dataTestPrefix={dataTestPrefix}
                  />
                </StHoverClickValue>
              </Col>
              <Col {...dataTestIdHandler(dataTestPrefix, 'dropdown')}>
                <Dropdown
                  menu={{ items }}
                  trigger={['click']}
                  dropdownRender={(menu) => (
                    <StDropdownContent>{menu}</StDropdownContent>
                  )}
                >
                  <StDashboardTag icon={<CalendarOutlined />}>
                    {dropdownKeyValue === 'neste_mes' && 'Neste mês'}
                    {dropdownKeyValue === 'ultimo_dia' && 'Último dia'}
                    {dropdownKeyValue === 'ultimo_mes' && 'Último mês'}
                  </StDashboardTag>
                </Dropdown>
              </Col>
            </Row>
            <Row style={{ marginTop: '2.85rem' }}>
              <Col>
                <StHoverClickValue onClick={goToUrl} disableHoverColor>
                  <LabelAndValue
                    label={dataCard[0]?.title}
                    labelColor="rgba(0, 0, 0, 0.45)"
                    value={dataCard[0]?.value}
                    valueTextSize="medium"
                    dataTestPrefix={`${dataTestPrefix}-${handleTestSuffix(
                      dataCard[0]?.title
                    )}`}
                  />
                </StHoverClickValue>
              </Col>
            </Row>
            <Row style={{ marginTop: '1rem' }}>
              <Col>
                <StHoverClickValue onClick={goToUrl} disableHoverColor>
                  <LabelAndValue
                    label={dataCard[1]?.title}
                    labelColor="rgba(0, 0, 0, 0.45)"
                    value={dataCard[1]?.value}
                    valueTextSize="medium"
                    dataTestPrefix={`${dataTestPrefix}-${handleTestSuffix(
                      dataCard[1]?.title
                    )}`}
                  />
                </StHoverClickValue>
              </Col>
            </Row>

            {dataCard.length >= 4 ? (
              <Row style={{ marginTop: '0.6rem' }}>
                <Col>
                  <StHoverClickValue onClick={goToUrl} disableHoverColor>
                    <LabelAndValue
                      label={dataCard[3]?.title}
                      labelColor="rgba(0, 0, 0, 0.45)"
                      value={dataCard[3]?.value}
                      valueTextSize="medium"
                      dataTestPrefix={`${dataTestPrefix}-${handleTestSuffix(
                        dataCard[3]?.title
                      )}`}
                    />
                  </StHoverClickValue>
                </Col>
              </Row>
            ) : (
              <Row style={{ marginTop: '6.5rem' }} />
            )}
            {dataCard.length >= 5 ? (
              <Row style={{ marginTop: '0.6rem' }}>
                <Col>
                  <StHoverClickValue onClick={goToUrl} disableHoverColor>
                    <LabelAndValue
                      label={dataCard[4]?.title}
                      labelColor="rgba(0, 0, 0, 0.45)"
                      value={dataCard[4]?.value}
                      valueTextSize="medium"
                      dataTestPrefix={`${dataTestPrefix}-${handleTestSuffix(
                        dataCard[4]?.title
                      )}`}
                    />
                  </StHoverClickValue>
                </Col>
              </Row>
            ) : dataCard.length <= 4 ? (
              <></>
            ) : (
              <Row style={{ marginTop: '6.5rem' }} />
            )}
            {dataCard.length >= 6 ? (
              <Row style={{ marginTop: '0.6rem' }}>
                <Col>
                  <StHoverClickValue onClick={goToUrl} disableHoverColor>
                    <LabelAndValue
                      label={dataCard[5]?.title}
                      labelColor="rgba(0, 0, 0, 0.45)"
                      value={dataCard[5]?.value}
                      valueTextSize="medium"
                      dataTestPrefix={`${dataTestPrefix}-${handleTestSuffix(
                        dataCard[5]?.title
                      )}`}
                    />
                  </StHoverClickValue>
                </Col>
              </Row>
            ) : dataCard.length <= 4 ? (
              <></>
            ) : (
              <Row style={{ marginTop: '6.5rem' }} />
            )}
            <Row style={{ marginTop: '1rem' }}>
              <Col>
                <StHoverClickValue onClick={goToUrl} disableHoverColor>
                  <LabelAndValue
                    label={dataCard[2]?.title}
                    labelColor="rgba(0, 0, 0, 0.45)"
                    value={dataCard[2]?.value}
                    valueTextSize="large"
                    dataTestPrefix={`${dataTestPrefix}-${handleTestSuffix(
                      dataCard[2]?.title
                    )}`}
                  />
                </StHoverClickValue>
              </Col>
            </Row>
          </>
        )}
      </StLimiter>
    </StContainer>
  );
};
CardResume.defaultProps = {
  error: {
    has: false,
    title: '',
    reason: '',
    recomendation: '',
  },
  dataCard: [],
  loading: false,
};
CardResume.propTypes = {
  titleCard: PropTypes.string.isRequired,
  error: PropTypes.shape({
    has: PropTypes.bool.isRequired,
    title: PropTypes.string,
    reason: PropTypes.string,
    recommendation: PropTypes.string,
  }),
  dataCard: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  goToUrl: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default CardResume;
