import { call, put } from 'redux-saga/effects';
import errorHandler from 'helpers/errorHandler';
import getRefundInfoFiltersFromAPI from 'services/filters/refundInfoFilters';
import { refundInfoFiltersSuccess, refundInfoFiltersError } from './actions';

export function* refundInfoFiltersSaga(action) {
  const { payload } = action;

  try {
    const apiData = yield call(getRefundInfoFiltersFromAPI, payload);
    yield put(refundInfoFiltersSuccess(apiData));
  } catch (err) {
    if (
      err?.response?.data?.error &&
      err?.response?.data?.error?.type &&
      err?.response?.data?.error?.message
    ) {
      yield put(
        refundInfoFiltersError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(
        err.response.data.error.message,
        err.response.data.error
      );
    }
  }
}
