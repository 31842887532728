import { lambdaApi } from 'services/api';

const transform = (response, columnName) => {
  const filters = {
    results: [
      {
        text: columnName,
        values: response?.data?.results,
      },
    ],
    hasMore: response?.meta?.has_next,
    offset: response?.meta?.offset,
    limit: response?.meta?.limit,
    column: response?.meta?.column,
  };
  return filters;
};

const getRadarListFiltersFromAPI = async ({
  columnName,
  offset,
  limit,
  searchBy,
  q,
}) => {
  const isFiltered = q.length !== 0 && q.filter.length > 0;
  const filters = isFiltered ? { q: JSON.stringify(q) } : undefined;
  const config = {
    params: {
      column: columnName,
      limit,
      offset,
      search_by: searchBy,
      ...filters,
    },
  };
  const response = await lambdaApi().get('/radar/process/filter', config);
  const transformedResponse = transform(response?.data, columnName);
  return transformedResponse;
};

export default getRadarListFiltersFromAPI;
