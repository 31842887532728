import { call, put } from 'redux-saga/effects';

import errorHandler from 'helpers/errorHandler';

import { optinSuccess, optinError } from 'store/ducks/optin/optin/actions';

import { getOptinSchedulerInfo } from 'services/optin/optin';

export function* optinSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(getOptinSchedulerInfo, payload);
    yield put(optinSuccess(apiData));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        optinError(err.response.data.error.message, err.response.data.error)
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(optinError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}
