import { apiXLSXDownload, apiCSVDownload } from '../../api';

const getExportBankConciliationFromAPI = async (payload) => {
  const config = {
    params: {
      client_id: payload?.client_id,
      start_date: payload?.start_date.format('YYYY-MM-DD'),
      end_date: payload?.end_date.format('YYYY-MM-DD'),
      download_type: payload?.download_type,
    },
  };

  const isExcel = config.params.download_type === 'excel';

  const response = isExcel
    ? await apiXLSXDownload().get('/export/bank-conciliation/detail', config)
    : await apiCSVDownload().get('/export/bank-conciliation/detail', config);
  let url = '';
  if (isExcel) {
    url = window.URL.createObjectURL(new Blob([response.data]));
  } else {
    url = window.URL.createObjectURL(new Blob([response.data]), {
      type: 'text/csv',
    });
  }
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute(
    'download',
    `Relatório de conciliação bancária${isExcel ? '.xlsx' : '.csv'}`
  );
  document.body.appendChild(link);
  link.click();
  return response;
};

export default getExportBankConciliationFromAPI;
