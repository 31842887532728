import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { Row, Col, Input, Button } from 'antd';
import ptBR from 'antd/es/date-picker/locale/pt_BR';

import {
  SortAscendingOutlined,
  SortDescendingOutlined,
} from '@ant-design/icons';

import { FiltersContainer } from 'commons/FiltersContainer';
import { LabelAndFilter } from 'commons/LabelAndFilter';
import LabelAndInputs from '../../../../../commons/LabelAndInputs/LabelAndInputs';
import DatePicker from 'commons/DatePicker/DatePicker';

const ContentFilters = ({
  dateDefaultValue,
  onRangePickerChange,
  disabledDate,
  loading,
  filterByBank,
  filterByBranch,
  filterByAccount,
  filterByAcquirer,
  onRangePickerOpenOrCloseChange,
  calendarIsOpen,
  setCalendarIsOpen,
  sort,
  setSort,
}) => {
  const { RangePicker } = DatePicker;
  const [dates, setDates] = useState([null]);
  const [hackValue, setHackValue] = useState(null);
  const rangePickerRef = useRef(null);

  const clearDates = () => {
    setDates([null, null]);
  };

  const [startDate, endDate] = dateDefaultValue;
  const antInputClearBtn =
    document.getElementsByClassName('ant-picker-clear')[0];

  useEffect(() => {
    const antInput = document.getElementsByClassName('ant-picker-input');

    antInput[0]?.addEventListener('click', clearDates);

    return () => {
      antInput[0]?.removeEventListener('onclick', clearDates);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const _antInputClearBtn =
        document.getElementsByClassName('ant-picker-clear')[0];
      // eslint-disable-next-line no-unused-expressions
      _antInputClearBtn?.addEventListener('click', (e) => {
        clearDates();
        e.stopPropagation();
      });
    }, 100);
  }, [antInputClearBtn]);

  useEffect(() => {
    const antInput = document.getElementsByClassName('ant-picker-input');
    const handleDates = () => setDates([startDate, null]);
    antInput[1]?.addEventListener('click', handleDates);

    return () => {
      antInput[1]?.removeEventListener('onclick', handleDates);
    };
  }, [startDate]);

  const onOpenChange = (open) => {
    onRangePickerOpenOrCloseChange(open);

    if (open) {
      setHackValue([startDate, null]);
    } else {
      setHackValue(null);
    }
  };

  const handleSort = () => {
    if (sort === 'DESC') setSort('ASC');
    else setSort('DESC');
  };

  return (
    <FiltersContainer
      title="Bancos"
      subTitle="Total recebido por banco, agência e conta"
    >
      <Row gutter={8} justify="space-between">
        <Col span={4}>
          <LabelAndFilter label="Calendário">
            <RangePicker
              ref={rangePickerRef}
              format="DD/MM/YYYY"
              onOpenChange={onOpenChange}
              onCalendarChange={(val) => {
                setHackValue(val);
                setDates(val);
                if (val?.every((v) => v)) {
                  setCalendarIsOpen(!calendarIsOpen);
                }
              }}
              disabled={loading}
              disabledDate={(current) => {
                if (dates) {
                  return disabledDate(current, ...dates);
                }
                return disabledDate(current, startDate, endDate);
              }}
              // defaultValue={dateDefaultValue}
              open={calendarIsOpen}
              value={hackValue || dateDefaultValue}
              onChange={onRangePickerChange}
              style={{ width: '100%' }}
              locale={ptBR}
            />
          </LabelAndFilter>
        </Col>
        <Col span={16}>
          <Row gutter={8}>
            <Col>
              <LabelAndInputs label="Banco">
                <Input placeholder="Digite sua busca" onChange={filterByBank} />
              </LabelAndInputs>
            </Col>
            <Col>
              <LabelAndInputs label="Agência">
                <Input
                  placeholder="Digite sua busca"
                  onChange={filterByBranch}
                />
              </LabelAndInputs>
            </Col>
            <Col>
              <LabelAndInputs label="Conta">
                <Input
                  placeholder="Digite sua busca"
                  onChange={filterByAccount}
                />
              </LabelAndInputs>
            </Col>
            <Col>
              <LabelAndInputs label="Adquirente">
                <Input
                  placeholder="Digite sua busca"
                  onChange={filterByAcquirer}
                />
              </LabelAndInputs>
            </Col>
          </Row>
        </Col>
        <Col span={4}>
          <Row justify="end">
            <Col>
              <LabelAndFilter label="Ordenado por" align="end">
                <Button
                  disabled={loading}
                  type="dashed"
                  icon={
                    sort === 'DESC' ? (
                      <SortDescendingOutlined />
                    ) : (
                      <SortAscendingOutlined />
                    )
                  }
                  size="small"
                  style={{ marginRight: '8px' }}
                  onClick={handleSort}
                >
                  Total de recebimentos
                </Button>
              </LabelAndFilter>
            </Col>
          </Row>
        </Col>
      </Row>
    </FiltersContainer>
  );
};

ContentFilters.propTypes = {
  onRangePickerChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  filterByBank: PropTypes.func.isRequired,
  filterByBranch: PropTypes.func.isRequired,
  filterByAccount: PropTypes.func.isRequired,
  filterByAcquirer: PropTypes.func.isRequired,
};

export default ContentFilters;
