// TODO: Remove camelcase eslint rule
/* eslint-disable camelcase,array-callback-return,no-param-reassign */
import { api } from '../api';

const transformer = (apiResponse) => {
  const { data } = apiResponse;
  return {
    payments: data,
    start_date: apiResponse.meta.start_date,
    end_date: apiResponse.meta.end_date,
  };
};

const getPaymentsDivergences = async ({ startDate, endDate }) => {
  const config = {
    params: {
      start_date: startDate.format('YYYY-MM-DD'),
      end_date: endDate.format('YYYY-MM-DD'),
      client_id: localStorage.getItem('concil-card-clientId'),
    },
  };
  const response = await api().get('/summary/payment/divergences', config);
  return transformer(response.data);
};

export default getPaymentsDivergences;
