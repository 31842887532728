import styled from 'styled-components';
import { Typography } from 'antd';
import { colors } from 'styles/colors';
import { EditOutlined, LoadingOutlined } from '@ant-design/icons';

export const StTitleItem = styled(Typography.Title)`
  &&& {
    font-size: 20px;
    font-weight: 500;
    text-align: left;
  }
`;

export const StStatusText = styled(Typography.Text)`
  display: flex;
  height: 100%;
  align-items: center;
`;

export const StTextItem = styled(Typography.Text)`
  &&& {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: ${colors.gray8};
  }
`;
export const StValueItem = styled(Typography.Text)`
  &&& {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: ${colors.gray8};
  }
`;

export const StAguardando = styled(Typography.Text)`
  &&& {
    font-size: 12px;
    font-weight: 400;
    line-height: 34px;
    color: ${colors.gold5};
  }
`;

export const StReprovado = styled(Typography.Text)`
  &&& {
    font-size: 12px;
    font-weight: 400;
    line-height: 34px;
    color: ${colors.red5};
  }
`;

export const StTexto = styled(Typography.Text)`
  &&& {
    font-size: 11px;
    font-weight: 400;
    line-height: 34px;
  }
`;

export const StEditOutlined = styled(EditOutlined)`
  font-size: 18px;
  margin-right: 8px;
  color: ${({ disabled }) =>
    disabled ? `${colors.gray6}` : `${colors.primary6}`};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'cursor')} !important;
`;

export const StLoadingOutlined = styled(LoadingOutlined)`
  font-size: 24px;
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
