import { Button } from 'antd';
import styled from 'styled-components';
import { colors } from 'styles/colors';

export const StContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: ${({ height = '584px' }) => height};
  min-height: 575px;
  max-height: 625px;
  border-radius: 4px;
  z-index: 0;
  background: linear-gradient(
    166.8deg,
    ${colors.primary1} 21.71%,
    ${colors.gray1} 49.57%
  );
`;

export const StLogoContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 40px 40px 0;
  font-size: 28px;
  font-weight: 400;
  color: ${colors.primary6};
`;

export const StConcilLogo = styled.img`
  width: 110px;
  margin-right: 5px;
`;

export const StPhraseContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 70px;
  line-height: 40px;
`;

export const StPhrase = styled.div`
  font-size: 30px;
  font-weight: 500;
  color: ${colors.primary3};
`;

export const StPhraseHighlight = styled.span`
  font-size: 30px;
  font-weight: 700;
  color: ${colors.primary6};
`;

export const StPlanFeatures = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 70px;
`;

export const StButton = styled(Button)`
  margin: 30px 70px 10px;
`;

export const StImage = styled.img`
  width: 100%;
`;

export const StConcilBackground = styled.img`
  position: absolute;
  bottom: -100px;
  right: -80px;
  width: 350px;
  z-index: -1;
  opacity: 8%;
`;
