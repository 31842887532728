import styled from 'styled-components';
import { Card, Typography } from 'antd';
import IntlCurrencyInput from 'react-intl-currency-input';
import { colors } from 'styles/colors';

export const StForm = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  margin: 6rem 1rem 0 1rem;
  padding: 4rem;
  background-color: ${colors.gray1};
`;

export const StRecRulesTable = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  margin-top: 37rem;
`;

export const StSearchTitle = styled(Typography.Title)`
  &&& {
    font-family: 'Roboto';
    margin-bottom: 0;
    color: ${colors.gray9};
    font-size: 30px;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-style: normal;
    line-height: 38px;
  }
`;

export const StContainer = styled.div`
  &&& {
    height: 100%;
    margin-top: 3rem;
  }
`;

export const StShowCase = styled(Card)`
  &&& {
    border-radius: 4px;
  }
`;

export const StText = styled(Typography.Text)`
  &&& {
    font-size: 14px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    line-height: 22px;
  }
`;

export const StWrapperNoResult = styled.div`
  margin-top: 3rem;
  padding: 4rem;
`;

export const StInputMasked = styled(IntlCurrencyInput)`
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  --antd-wave-shadow-color: ${colors.primary6};
  --scroll-bar: 0;
  outline: 0;
  font-family: inherit;
  overflow: visible;
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum', 'tnum';
  position: relative;
  display: inline-block;
  min-width: 0;
  padding: 4px 11px;
  color: ${colors.gray11};
  font-size: 14px;
  line-height: 1.5715;
  background-color: ${colors.gray1};
  background-image: none;
  border: 1px solid ${colors.gray5};
  border-radius: 2px;
  transition: all 0.3s;
  -webkit-appearance: none;
  touch-action: manipulation;
  text-overflow: ellipsis;
  width: 98%;
  max: 100;
  &:hover {
    --antd-wave-shadow-color: ${colors.primary6};
    transition: all 0.3s;
    border-color: ${colors.primary5};
  }
  &:focus {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    --antd-wave-shadow-color: ${colors.primary6};
    color: ${colors.gray11};
    transition: all 0.3s;
    border-color: ${colors.primary5};
    mso-border-shadow: yes;
  }
`;
