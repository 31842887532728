import errorHandler from 'helpers/errorHandler';
import { call, put } from 'redux-saga/effects';
import { runTest } from 'services/radar/runTest';
import { notification } from 'antd';
import {
  radarRunTestSuccess,
  radarRunTestError,
} from 'store/ducks/radar/radarRunTest/actions';

export function* radarRunTestSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(runTest, payload);
    yield put(radarRunTestSuccess(apiData));
    notification.success({
      message: 'Solicitação de envio de radar realizada com sucesso',
    });
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        radarRunTestError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(radarRunTestError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}
