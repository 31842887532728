import { action } from 'typesafe-actions';
import { actionTypes } from 'store/ducks/feesUpdateFilteredColumns/types';

export const feesUpdateFilteredColumnsStart = (data) =>
  action(actionTypes.FEES_UPDATE_FILTERED_COLUMNS_START, data);
export const feesUpdateFilteredColumnsSuccess = (data) =>
  action(actionTypes.FEES_UPDATE_FILTERED_COLUMNS_SUCCESS, data);
export const feesUpdateFilteredColumnsError = (errorMessage, error) =>
  action(
    actionTypes.FEES_UPDATE_FILTERED_COLUMNS_ERROR,
    { errorMessage },
    undefined,
    { error }
  );

export const feesResetUpdateFilteredColumnsStart = (data) =>
  action(actionTypes.FEES_RESET_UPDATE_FILTERED_COLUMNS_START, data);
export const feesResetUpdateFilteredColumnsSuccess = (data) =>
  action(actionTypes.FEES_RESET_UPDATE_FILTERED_COLUMNS_SUCCESS, data);
export const feesResetUpdateFilteredColumnsError = (errorMessage, error) =>
  action(
    actionTypes.FEES_RESET_UPDATE_FILTERED_COLUMNS_ERROR,
    { errorMessage },
    undefined,
    {
      error,
    }
  );
