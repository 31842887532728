import styled from 'styled-components';
import { Modal } from 'antd';

export const StModal = styled(Modal)`
  &&& {
    .ant-steps-item-process
      > .ant-steps-item-container
      > .ant-steps-item-tail::after,
    .ant-steps-item-wait
      > .ant-steps-item-container
      > .ant-steps-item-tail::after {
      background-color: #d0d0d0 !important;
    }
    .ant-steps-item-title {
      font-size: 14px !important;
    }
    p {
      word-break: normal !important;
    }

    .ant-modal-footer {
      height: 70px;
    }
  }
`;
