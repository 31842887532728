// TODO: Remove camelcase eslint rule
/* eslint-disable camelcase,array-callback-return,no-param-reassign */
import { api } from '../api';

const transformer = (apiResponse) => {
  const { payments_by_bank } = apiResponse.data;
  let totalAmount = 0;
  const banks = payments_by_bank;

  const colorsBank = banks.map((item) => {
    return item.bank.bank_color;
  });

  const dataBanks = banks.map((item) => {
    totalAmount += item.total_receipt_amount;
    return Number(item.total_receipt_amount.toFixed(2));
  });

  const nameBanks = banks.map((item) => {
    return item.bank.name;
  });

  const data = {
    colorsBank,
    dataBanks,
    nameBanks,
    totalAmount: Number(totalAmount.toFixed(2)),
  };
  return data;
};

const getPaymentsByBanksAPI = async ({ startDate, endDate }) => {
  const config = {
    params: {
      start_date: startDate.format('YYYY-MM-DD'),
      end_date: endDate.format('YYYY-MM-DD'),
      client_id: localStorage.getItem('concil-card-clientId'),
      top: 6,
    },
  };
  const response = await api().get('/summary/bank/payments', config);
  return transformer(response.data);
};

export default getPaymentsByBanksAPI;
