import React from 'react';
import * as St from './styled';

const baseURL = process.env.REACT_APP_AWS_V4_ASSETS;
const paths = {
  login: `${baseURL}/login/login.png`,
};

const LoginImage = () => (
  <St.Container>
    <img src={paths.login} alt="Imagem de Login do Concil Card" />

    <St.StoresContainer>
      <St.StoreLink
        href="https://apps.apple.com/br/app/concil-card-app/id1579933854"
        target="_blank"
        rel="noopener noreferrer"
      >
        <></>
      </St.StoreLink>
      <St.StoreLink
        href="https://play.google.com/store/apps/details?id=br.com.concil.mobileconcilv4flutter&hl=pt"
        target="_blank"
        rel="noopener noreferrer"
      >
        <></>
      </St.StoreLink>
    </St.StoresContainer>
  </St.Container>
);

export default LoginImage;
