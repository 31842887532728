import { Button, Col, Row } from 'antd';
import React, { useState } from 'react';
import { colors } from 'styles/colors';
import { StSubTitleItem, StTitleItem } from './styled';
import { FeeModal } from 'components/Fees/components/FeeModal';
import { capitalizeFirstLetter } from 'helpers/capitalizeFirstLetter';
import { InfoCircleOutlined } from '@ant-design/icons';

const Header = ({ acquirer, ...rest }) => {
  const acquirerName = acquirer?.acquirer;
  const acquirerDetails = acquirer?.detail;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentActiveAcquirer, setCurrentActiveAcquirer] = useState({});

  const showModal = () => {
    setIsModalOpen(true);
    setCurrentActiveAcquirer(acquirer);
  };

  return (
    <>
      <Row
        {...rest}
        style={{
          padding: '24px',
          backgroundColor: colors.gray1,
          borderRadius: '4px 4px 0px 0px',
        }}
      >
        <Col
          span={18}
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 16,
          }}
        >
          <img
            src={acquirerDetails?.icon}
            width="32px"
            height="32px"
            alt={`Ícone da adquirente ${acquirerName}`}
          />
          <StTitleItem>{capitalizeFirstLetter(acquirerName)}</StTitleItem>

          {acquirerDetails?.has_fees &&
            acquirerDetails?.merchant_with_fee_count >= 0 && (
              <StSubTitleItem>
                {`${acquirerDetails?.merchant_with_fee_count} de ${acquirerDetails?.merchant_count} estabelecimento(s) com cadastro completo de taxas.`}
              </StSubTitleItem>
            )}

          {!acquirerDetails?.has_fees && (
            <StSubTitleItem>
              <InfoCircleOutlined style={{ marginRight: 9 }} />
              Você não possui taxas cadastradas para esta adquirente.
            </StSubTitleItem>
          )}
        </Col>

        <Col
          span={6}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end ',
          }}
        >
          <Button
            type="primary"
            style={{ borderRadius: '4px' }}
            onClick={(e) => {
              e.stopPropagation();
              showModal();
            }}
          >
            Cadastrar taxas
          </Button>
        </Col>
      </Row>
      <FeeModal
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        currentActiveAcquirer={currentActiveAcquirer}
      />
    </>
  );
};

export default Header;
