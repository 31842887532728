import styled from 'styled-components';
import { Typography, Button } from 'antd';
import { marginMainContainer } from 'helpers/style';
import { colors } from 'styles/colors';

export const StContainer = styled.div`
  margin: ${(props) => marginMainContainer(props?.$breakpoint)};
  padding-top: 40px;
`;

export const StWrapper = styled.div`
  margin-top: 3rem;
  background-color: ${colors.gray1};
  padding: 4rem;
`;

export const StTitle = styled(Typography.Title)`
  margin-bottom: 0 !important;
  color: ${colors.gray11} !important;
  font-size: 3.75rem !important;
`;

export const StClientTitle = styled(Typography.Title)`
  color: ${colors.gray11} !important;
  font-size: 2.5rem !important;
  display: inline-block !important;
`;

export const StButton = styled(Button)`
  display: inline-block !important;
`;

export const StLabel = styled.span`
  color: #8e8e8e;
  font-size: 1.75rem;
  display: block;
`;
