import { action } from 'typesafe-actions';
import { actionTypes } from './types';

export const getMerchantsStart = (data) => action(actionTypes.GET_MERCHANTS_START, { data });
export const getMerchantsSuccess = (data) => action(actionTypes.GET_MERCHANTS_SUCCESS, data);
export const getMerchantsError = (errorMessage, error) =>
  action(actionTypes.GET_MERCHANTS_ERROR, { errorMessage }, undefined, { error });

export const postMerchantStart = (data) => action(actionTypes.POST_MERCHANT_START, data);
export const postMerchantSuccess = (data) => action(actionTypes.POST_MERCHANT_SUCCESS, data);
export const postMerchantError = (errorMessage, error) =>
  action(actionTypes.POST_MERCHANT_ERROR, { errorMessage }, undefined, { error });

export const deleteMerchantStart = (data) => action(actionTypes.DELETE_MERCHANT_START, { data });
export const deleteMerchantSuccess = (data) => action(actionTypes.DELETE_MERCHANT_SUCCESS, data);
export const deleteMerchantError = (errorMessage, error) =>
  action(actionTypes.DELETE_MERCHANT_ERROR, { errorMessage }, undefined, { error });
