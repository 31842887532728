export const actionTypes = {
  LIST_RETROACTIVE_CONCILIATION_START:
    '@retroactiveConciliation/LIST_RETROACTIVE_CONCILIATION_START',
  LIST_RETROACTIVE_CONCILIATION_SUCCESS:
    '@retroactiveConciliation/LIST_RETROACTIVE_CONCILIATION_SUCCESS',
  LIST_RETROACTIVE_CONCILIATION_ERROR:
    '@retroactiveConciliation/LIST_RETROACTIVE_CONCILIATION_ERROR',
  POST_RETROACTIVE_CONCILIATION_START:
    '@retroactiveConciliation/POST_RETROACTIVE_CONCILIATION_START',
  POST_RETROACTIVE_CONCILIATION_SUCCESS:
    '@retroactiveConciliation/POST_RETROACTIVE_CONCILIATION_SUCCESS',
  POST_RETROACTIVE_CONCILIATION_ERROR:
    '@retroactiveConciliation/POST_RETROACTIVE_CONCILIATION_ERROR',
};
