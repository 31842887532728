import { lambdaApi } from 'services/api';

const transformer = (apiResponse) => {
  const filters = {
    merchants: apiResponse.data.merchants,
    meta: apiResponse.meta,
  };

  return filters;
};

const getCnpjMaintenanceMerchantInfoFilters = async ({
  filter,
  offset,
  limit,
}) => {
  const config = {
    params: {
      offset,
      limit,
      q: JSON.stringify(filter),
    },
  };
  const response = await lambdaApi().get(
    '/settings/maintenance/merchants/filter',
    config
  );

  return transformer(response.data);
};

export default getCnpjMaintenanceMerchantInfoFilters;
