import { call, put } from 'redux-saga/effects';

import errorHandler from 'helpers/errorHandler';

import removeBranch from '../../../services/removeBranch';
import { removeBranchError, removeBranchSuccess } from './actions';

export function* removeBranchSaga(action) {
  try {
    const { payload } = action;

    const apiData = yield call(removeBranch, payload);

    yield put(removeBranchSuccess(apiData));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        removeBranchError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    }
  }
}
