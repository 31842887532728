import { lambdaApi } from '../api';
import dayjs from 'dayjs';

const transformer = ({ data, meta }) => {
  return {
    data: data.transactions,
    meta: meta,
  };
};

export const listRetroactiveConciliation = async ({
  page,
  perPage,
  q,
  sortFields,
}) => {
  const config = {
    params: {
      page,
      per_page: perPage,
      sort_fields: JSON.stringify(sortFields),
      q: JSON.stringify(q),
    },
  };
  const response = await lambdaApi().get(
    '/detail/retroactive/reconciled',
    config
  );
  return transformer(response.data);
};

export const postRetroactiveConciliation = async ({
  client,
  conciliationType,
  acquirerId,
  period,
}) => {
  const config = {
    client_id: client?.id,
    recon_type: conciliationType,
    acquirer_id: acquirerId,
    start_date: dayjs(period[0]).format('YYYY-MM-DD'),
    end_date: dayjs(period[1]).format('YYYY-MM-DD'),
    custom_flag: 'N',
    flag_processed: 'N',
  };

  await lambdaApi().post('/detail/retroactive/post', config);
};
