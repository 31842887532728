import styled from 'styled-components';
import { Col } from 'antd';
import { StContainer } from 'commons/Content/styled';
import { colors } from 'styles/colors';

export const StPlansContainer = styled(StContainer)`
  margin: 0;
  background-color: ${colors.background1};
`;

export const StCol = styled(Col)`
  padding: 32px;
  background-color: ${colors.gray1};
`;

export const PlansTitle = styled.h1`
  margin: 0;
  font-size: 30px;
  color: ${colors.gray9};
`;
