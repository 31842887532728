export const actionTypes = {
  GET_CLIENT_ACQUIRER_START: '@store/GET_CLIENT_ACQUIRER_START',
  GET_CLIENT_ACQUIRER_SUCCESS: '@store/GET_CLIENT_ACQUIRER_SUCCESS',
  GET_CLIENT_ACQUIRER_ERROR: '@store/GET_CLIENT_ACQUIRER_ERROR',
  POST_CLIENT_ACQUIRER_START: '@store/POST_CLIENT_ACQUIRER_START',
  POST_CLIENT_ACQUIRER_SUCCESS: '@store/POST_CLIENT_ACQUIRER_SUCCESS',
  POST_CLIENT_ACQUIRER_ERROR: '@store/POST_CLIENT_ACQUIRER_ERROR',
  DELETE_CLIENT_ACQUIRER_START: '@store/DELETE_CLIENT_ACQUIRER_START',
  DELETE_CLIENT_ACQUIRER_SUCCESS: '@store/DELETE_CLIENT_ACQUIRER_SUCCESS',
  DELETE_CLIENT_ACQUIRER_ERROR: '@store/DELETE_CLIENT_ACQUIRER_ERROR',
};
