import React from 'react';
import PropTypes from 'prop-types';

const EllipsisText = ({
  text = '',
  length = 100,
  tail = '...',
  tailClassName,
  showTitle = true,
  ...others
}) => {
  if (text.length <= length || length < 0) {
    return <span {...others}>{text}</span>;
  }

  const displayText = text.slice(0, length - tail.length);

  return (
    <span {...others} title={showTitle ? text : ''}>
      {displayText}
      {text.length > length - tail.length && (
        <span style={{ cursor: 'auto' }} className={tailClassName}>
          {tail}
        </span>
      )}
    </span>
  );
};

EllipsisText.propTypes = {
  text: PropTypes.string.isRequired,
  length: PropTypes.number.isRequired,
  tail: PropTypes.string,
  tailClassName: PropTypes.string,
  showTitle: PropTypes.bool,
};

EllipsisText.defaultProps = {
  tail: '...',
  tailClassName: 'more',
  showTitle: true,
};

export default EllipsisText;
