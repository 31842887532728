import { action } from 'typesafe-actions';
import { actionTypes } from 'store/ducks/updateBranchData/types';

export const updateBranchDataStart = (data) =>
  action(actionTypes.UPDATE_BRANCH_DATA_START, data);
export const updateBranchDataSuccess = (data) =>
  action(actionTypes.UPDATE_BRANCH_DATA_SUCCESS, data);
export const updateBranchDataError = (errorMessage, error) =>
  action(actionTypes.UPDATE_BRANCH_DATA_ERROR, { errorMessage }, undefined, {
    error,
  });
export const updateBranchDataHasBeenUpdated = (data) =>
  action(actionTypes.UPDATE_BRANCH_DATA_HAS_BEEN_UPDATED, data);
