import styled from 'styled-components';

import { marginMainContainer } from 'helpers/style';

export const StContainer = styled.div`
  margin-top: 34rem;
`;

export const StLimiter = styled.div`
  margin: ${(props) => marginMainContainer(props.$breakpoint)};
`;
