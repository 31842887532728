import { call, put } from 'redux-saga/effects';

import errorHandler from 'helpers/errorHandler';
import getExportPaymentDivergencesDetailsFromAPI from 'services/details/export/paymentDivergencesDetails';
import {
  exportPaymentDivergencesDetailsSuccess,
  exportPaymentDivergencesDetailsError,
} from 'store/ducks/details/export/paymentDivergencesDetails/actions';

export function* exportPaymentDivergencesDetailsSaga(action) {
  try {
    const { payload } = action;

    const apiData = yield call(
      getExportPaymentDivergencesDetailsFromAPI,
      payload
    );

    yield put(exportPaymentDivergencesDetailsSuccess(apiData));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        exportPaymentDivergencesDetailsError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    }
  }
}
