import { call, put } from 'redux-saga/effects';

import { getBriefing, postBriefing, putBriefing } from 'services/briefing';
import { notification } from 'antd';
import {
  getBriefingSuccess,
  getBriefingError,
  postBriefingSuccess,
  postBriefingError,
  putBriefingSuccess,
  putBriefingError,
} from './actions';
import errorHandler from '../../../helpers/errorHandler';

export function* getBriefingSaga() {
  try {
    const apiData = yield call(getBriefing);
    yield put(getBriefingSuccess(apiData));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        getBriefingError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(getBriefingError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}

export function* postBriefingSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(postBriefing, payload);
    yield put(postBriefingSuccess(apiData));
    notification.success({
      message: 'Registro de implantação criado com sucesso !',
    });
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        postBriefingError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(postBriefingError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}

export function* putBriefingSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(putBriefing, payload);
    yield put(putBriefingSuccess(apiData));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        putBriefingError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(putBriefingError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}
