import { Typography } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const StTitle = styled(Typography.Text)`
  display: block;
  font-weight: 600;
`;
