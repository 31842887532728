import { bankingApi } from '../api';

const transformer = (apiResponse) => {
  return {
    data: apiResponse?.payload,
  };
};

const postInteractViewRequest = async ({ creditId, userId }) => {
  const config = {
    creditId,
    userId,
  };
  const response = await bankingApi().post('/credit/interact/view', config);
  return transformer(response.data);
};

export default postInteractViewRequest;
