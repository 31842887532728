import React from 'react';
import { Input } from 'antd';

export const InputMaskCep = ({
  placeholder = 'Insira o CEP',
  onChange,
  value,
  ...rest
}) => {
  const formatCEP = (cep) => {
    if (!cep) return '';

    let formattedValue = cep.replace(/\D/g, '');

    if (formattedValue.length > 8) {
      formattedValue = formattedValue.slice(0, 8);
    }

    formattedValue = formattedValue.replace(/(\d{5})(\d)/, '$1-$2');
    return formattedValue;
  };

  const onLocalChange = (e) => {
    const targetValue = e.target.value;
    const formattedCep = formatCEP(targetValue);

    e.target.value = formattedCep;

    onChange(e);
  };

  return (
    <Input
      value={value}
      onChange={onLocalChange}
      placeholder={placeholder}
      {...rest}
    />
  );
};
