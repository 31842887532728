/* eslint-disable no-unused-expressions */
/* eslint-disable consistent-return */
import {
  CheckCircleTwoTone,
  ExclamationCircleTwoTone,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  Form,
  Input,
  notification,
  Row,
  Select,
  Tabs,
  Typography,
} from 'antd';
import ptBR from 'antd/es/date-picker/locale/pt_BR';
import { SelectInfiniteScroll } from 'commons/SelectInfiniteScroll';
import { StBriefingTab } from 'components/Briefing/styled';
import { useStoreOptions } from 'components/CnpjMaintenance/hooks/useStoreOptions';
import errorHandler from 'helpers/errorHandler';
import { toTitleCase } from 'helpers/generalService';
import { getSessionClientId } from 'helpers/sessionService';
import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { colors } from 'styles/colors';
import { HeaderRegisters } from '../../../../commons/HeaderRegisters';
import { Loadable } from '../../../../commons/Loadable';
import { deleteMerchant, postMerchant } from '../../../../services/merchant';
import { acquirerStart } from '../../../../store/ducks/acquirer/actions';
import { getMerchantsStart } from '../../../../store/ducks/merchant/actions';
import { getStoresStart } from '../../../../store/ducks/store/actions';
import { modal } from './modal';
import { StContainer, StSelect, StTabs } from './styled';
import { CnpjMaintenanceContext } from 'components/CnpjMaintenance/CnpjMaintenance';
import { shortId } from 'helpers/shortId';
import { useInfiniteScrollPageChange } from 'utils/hooks/useInfiniteScrollPageChange';
import { handleAcquirerMerchant } from 'helpers/handleAcquirerMerchant/handleAcquirerMerchant';

let merchantReload = false;

const BriefingMerchantsRegister = forwardRef(
  (
    {
      isLocked,
      isCNPJMaintenance = false,
      setIsNextButtonLocked,
      acquirerSelected,
    },
    ref
  ) => {
    const { setMerchantHasPosted } = useContext(CnpjMaintenanceContext);
    const { Option } = StSelect;
    const { TabPane } = Tabs;
    const dispatch = useDispatch();
    const loadingAcquirers = useSelector((state) => state.acquirer.loading);
    const acquirers = useSelector((state) => state.acquirer.acquirers);
    const loadingStores = useSelector((state) => state.store.loading);
    const storeData = useSelector((state) => state.store.stores);
    const loadingMerchants = useSelector((state) => state.merchant.loading);
    const merchantsData = useSelector((state) => state.merchant.merchants);
    const clientId = getSessionClientId();
    const [isAddingMerchant, setIsAddingMerchant] = useState(false);
    const [isSavingOrRemovingMerchant, setIsSavingOrRemovingMerchant] =
      useState(false);
    const [currentAcquirer, setCurrentAcquirer] = useState(undefined);
    const [currentAcquirerMerchants, setCurrentAcquirerMerchants] =
      useState(undefined);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [addForm] = Form.useForm();

    const handleDisabled = (acquirer) =>
      acquirer?.is_replicate_merchant_br_cnpj;

    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);

    useImperativeHandle(ref, () => ({
      validateBeforeNextPage(afterValidate) {
        if (
          acquirers.every(
            (a) =>
              merchantsData?.data?.filter(
                (m) => m.acquirer_id === a.acquirer_id
              )?.length > 0
          )
        ) {
          afterValidate();
        } else {
          notification.warn({
            message:
              'É necessário adicionar ao menos 1 (um) estabelecimento para cada adquirente.',
          });
        }
      },
    }));

    useEffect(() => {
      dispatch(acquirerStart({}));
      if (isCNPJMaintenance) {
        dispatch(getStoresStart({ sort: { store_code: 'asc' } }));
      } else {
        dispatch(getStoresStart({}));
      }

      setIsNextButtonLocked(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const loadMerchants = useCallback(() => {
      dispatch(getMerchantsStart({ data: { client_id: clientId } }));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientId]);

    function getCurrentMerchants(merchants, currentAcquirerId) {
      const { data } = merchants;
      const currentMerchants = [];
      data.forEach((m) => {
        if (m.acquirer_id === currentAcquirerId?.acquirer_id) {
          currentMerchants.push(m);
        }
      });
      return currentMerchants;
    }

    const [currentPage, setCurrentPage] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [totalItemsCounter, setTotalItemsCounter] = useState(0);
    const [allItems, setAllItems] = useState([]);

    const [renderKey, setRenderKey] = useState('');

    const [activeTabKey, setActiveTabKey] = useState('');

    const [offset, setOffset] = useState(0);
    const [page, setPage] = useState(1);
    const offsetShift = 10;
    const [searchFilterInput, setSearchFilterInput] = useState(null);

    const { isLoadingSearch, storeFilterOptions, storeFilterMeta } =
      useStoreOptions(searchFilterInput, offset);

    const { has_next: hasMoreStores } = storeFilterMeta;

    const onStoreSelected = ({ value, label }) => {
      if (!value || value === undefined || value === '') {
        setOffset(0);
        setPage(1);
        setSearchFilterInput(null);
        addForm.setFieldsValue({
          store_id_add: null,
        });
      }

      if (typeof value === 'number') {
        addForm.setFieldsValue({
          store_id_add: value,
        });
      }

      setSearchFilterInput(label);
    };

    const onCancelOrResetAddMerchantForm = () => {
      setSearchFilterInput(null);
    };

    const transformStoreOptions = (option = []) =>
      option.map(({ id, value }) => ({
        value: id,
        label: value,
      }));

    function resetInfos() {
      setCurrentPage(1);
      setHasMore(false);
      setCurrentAcquirerMerchants([]);
      setAllItems([]);
      setCurrentAcquirer(undefined);
      setTotalItemsCounter(0);
      setRenderKey(shortId()); // necessário para reconstruir o div de scroll infinito
    }

    const onChangeTab = (key) => {
      resetInfos();

      const newAcquirer = acquirers.find(
        // eslint-disable-next-line radix
        (a) => a.acquirer_id === parseInt(key, 0)
      );

      if (isCNPJMaintenance) {
        onCancelOrResetAddMerchantForm();
      }
      setIsAddingMerchant(false);
      setActiveTabKey(key);
      setCurrentAcquirer(newAcquirer);
      let merchants = [];

      if (merchantsData) {
        merchants = getCurrentMerchants(merchantsData, newAcquirer);
      }

      if (merchants) {
        setCurrentAcquirerMerchants(merchants);
      } else {
        setCurrentAcquirerMerchants([]);
      }
    };

    if (
      currentAcquirer &&
      merchantsData &&
      (merchantReload || !currentAcquirerMerchants)
    ) {
      setCurrentAcquirerMerchants(
        merchantsData.data.filter(
          (m) => m.acquirer_id === currentAcquirer.acquirer_id
        )
      );
      merchantReload = false;
    }

    useEffect(() => {
      if (acquirers && !currentAcquirer) {
        if (acquirerSelected) {
          const acquirerSelectedIndex = acquirers?.findIndex(
            (a) => a.acquirer_id === Number(acquirerSelected)
          );

          setCurrentAcquirer(acquirers[acquirerSelectedIndex]);
          setActiveTabKey(acquirers[acquirerSelectedIndex]?.acquirer_id);
        } else {
          setCurrentAcquirer(acquirers[0]); // First Acquirer
          setActiveTabKey(acquirers[0]?.acquirer_id);
        }

        // Get Merchants
        loadMerchants();
      }
    }, [acquirers, currentAcquirer, loadMerchants, acquirerSelected]);

    const onFormAddFinish = async (formValues) => {
      const isReplicateMerchantBrCnpj =
        currentAcquirer?.is_replicate_merchant_br_cnpj;

      const merchantCodeWithoutLeadingZero = (merchantCode) =>
        merchantCode.replace(/^0+/g, '');

      const isMerchantCodeValid = (merchantCode) => {
        if (isReplicateMerchantBrCnpj) return true;
        const merchantCodeLength =
          merchantCodeWithoutLeadingZero(merchantCode).length;
        if (
          merchantCodeLength < currentAcquirer?.merchant_code_min_size &&
          merchantCode.startsWith('0')
        ) {
          notification.error({
            message: 'Esse estabelecimento não pode ter 0 à esquerda',
          });
          return false;
        }
        return true;
      };

      const formatMerchantCode = (merchantCode) => {
        if (isReplicateMerchantBrCnpj) {
          return merchantCode;
        }
        return merchantCodeWithoutLeadingZero(merchantCode);
      };

      addForm.validateFields().then(async () => {
        const merchantCode = formValues?.merchant_code_add;

        if (!isMerchantCodeValid(merchantCode)) return;

        const data = {
          client_id: clientId,
          acquirer_id: currentAcquirer.acquirer_id,
          store_id: formValues.store_id_add,
          merchant_code: formatMerchantCode(merchantCode),
        };
        setIsAddingMerchant(false);
        setIsSavingOrRemovingMerchant(true);
        setIsSaveButtonDisabled(true);
        postMerchant(data)
          .then(() => {
            notification.success({
              message: 'Estabelecimento incluído com sucesso!',
            });
            setIsSavingOrRemovingMerchant(false);
            merchantReload = true;
            loadMerchants();
            setIsSaveButtonDisabled(true);
            onChangeTab(currentAcquirer.acquirer_id);
            if (isCNPJMaintenance) {
              setMerchantHasPosted(true);
            }
          })
          .catch((error) => {
            if (error !== undefined) {
              Object.entries(error).forEach(([key, value]) => {
                if (key === 'response') {
                  const message =
                    value?.data?.data?.message ||
                    value?.data?.error?.message ||
                    'Ocorreu um erro ao incluir o estabelecimento';
                  setIsSavingOrRemovingMerchant(false);
                  setIsSaveButtonDisabled(true);
                  return errorHandler(message);
                }
              });
            }
            setIsSavingOrRemovingMerchant(false);
            setIsSaveButtonDisabled(true);
            if (isCNPJMaintenance) {
              setMerchantHasPosted(false);
            }
          });
      });
    };

    const addMerchant = () => {
      addForm.setFieldsValue({
        store_id_add: undefined,
        merchant_code_add: '',
      });
      setIsAddingMerchant(true);

      if (isCNPJMaintenance) {
        setMerchantHasPosted(false);
      }
    };

    const onRemoveMerchant = async (merchant) => {
      setIsSavingOrRemovingMerchant(true);
      deleteMerchant({ merchantId: merchant.merchant_id })
        .then(() => {
          notification.success({
            message: 'Estabelecimento removido com sucesso !',
          });
          setIsSavingOrRemovingMerchant(false);
          merchantReload = true;
          loadMerchants();
        })
        .catch((error) => {
          if (error !== undefined) {
            Object.entries(error).forEach(([key, value]) => {
              if (key === 'response') {
                const message =
                  value?.data?.data?.message ||
                  value?.data?.error?.message ||
                  'Ocorreu um erro ao remover o estabelecimento';
                setIsSavingOrRemovingMerchant(false);
                setIsSaveButtonDisabled(true);
                return errorHandler(message);
              }
            });
          }
          setIsSavingOrRemovingMerchant(false);
          setIsSaveButtonDisabled(true);
        });
    };

    const merchantCodeValidation = [
      {
        required: true,
        message: 'Campo obrigatório!',
      },
      {
        min: currentAcquirer?.merchant_code_min_size,
        message: `O estabelecimento precisa ter no mínimo ${currentAcquirer?.merchant_code_min_size} caracteres.`,
      },
      {
        max: currentAcquirer?.merchant_code_max_size,
        message: `O estabelecimento precisa ter no máximo ${currentAcquirer?.merchant_code_max_size} caracteres.`,
      },
      currentAcquirer?.merchant_code_regex
        ? {
            pattern: new RegExp(currentAcquirer?.merchant_code_regex),
            message:
              currentAcquirer?.merchant_code_regex_error_message ||
              'Informe um código de estabelecimento válido',
          }
        : {},
    ];

    const renderAcquirerTabStatusIcon = (acquirer) => {
      let isOk = false;

      merchantsData?.data.forEach((merchant) => {
        if (merchant?.acquirer_id === acquirer?.acquirer_id) {
          isOk = true;
        }
      });

      if (isOk) {
        return (
          <CheckCircleTwoTone
            twoToneColor={colors.green6}
            style={{ fontSize: '12.25px' }}
          />
        );
      }

      return (
        <ExclamationCircleTwoTone
          twoToneColor={colors.gold6}
          style={{ fontSize: '12.25px' }}
        />
      );
    };

    const handleAcquirerReplicateMerchantBrCNPJ = () => {
      const { store_id_add: storeIdAdd } = addForm.getFieldsValue();

      const merchantFormatted = storeData?.stores
        ?.find((store) => {
          return store?.store_id === storeIdAdd;
        })
        ?.br_cnpj?.replaceAll(/[^\d]/g, '');

      if (currentAcquirer?.is_replicate_merchant_br_cnpj) {
        addForm.setFieldsValue({
          merchant_code_add: merchantFormatted,
        });
      }
    };

    const handleAddFormFields = () => {
      const formFieldValues = addForm.getFieldsValue();

      if (!isCNPJMaintenance) {
        if (formFieldValues) {
          const validateForm =
            formFieldValues.merchant_code_add && formFieldValues.store_id_add;

          const addFormHasError = addForm
            .getFieldsError()
            .filter(({ errors }) => errors.length).length;

          if (validateForm && addFormHasError === 0) {
            setIsSaveButtonDisabled(false);
          } else {
            setIsSaveButtonDisabled(true);
          }
        }
      }

      if (isCNPJMaintenance) {
        const { merchant_code_add: merchantCodeAdd, store_id_add: storeIdAdd } =
          formFieldValues;
        if (formFieldValues) {
          const validateForm =
            merchantCodeAdd && typeof storeIdAdd === 'number';

          const addFormHasError = addForm
            .getFieldsError()
            .filter(({ errors }) => errors.length).length;

          if (validateForm && addFormHasError === 0) {
            setIsSaveButtonDisabled(false);
          } else {
            setIsSaveButtonDisabled(true);
          }
        }
      }
    };

    useEffect(() => {
      if (isCNPJMaintenance) {
        handleAcquirerReplicateMerchantBrCNPJ();
        handleAddFormFields();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchFilterInput]);

    const renderAcquirerTabStatusMessage = (acquirer) => {
      let isOk = false;
      let statusMessage = 'Com pendências';

      merchantsData?.data.forEach((merchant) => {
        if (merchant?.acquirer_id === acquirer?.acquirer_id) {
          isOk = true;
        }
      });

      if (isOk) {
        statusMessage = 'Sem pendências';
      }

      return (
        <Typography.Text
          style={{
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '22px',
            color: colors.gray7,
          }}
        >
          {statusMessage}
        </Typography.Text>
      );
    };

    const itemsPerPage = 10;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentItems = currentAcquirerMerchants?.slice(startIndex, endIndex);
    const itemsCounter = allItems?.length;
    const [loadingItems, setLoadingItems] = useState(false);

    const { lastElementRef } = useInfiniteScrollPageChange({
      loading: loadingItems,
      hasMore,
      setPage: setCurrentPage,
    });

    useEffect(() => {
      if (endIndex < totalItemsCounter) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    }, [
      currentAcquirerMerchants,
      endIndex,
      currentAcquirer,
      totalItemsCounter,
    ]);

    useEffect(() => {
      if (currentAcquirerMerchants) {
        setAllItems(currentItems);
        setTotalItemsCounter(currentAcquirerMerchants?.length);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentAcquirer, currentAcquirerMerchants]);

    useEffect(() => {
      if (currentPage > 1) {
        setLoadingItems(true);
        setTimeout(() => {
          setAllItems((prevState) => {
            return [...new Set([...prevState, ...currentItems])];
          });
          setLoadingItems(false);
        }, 1000);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

    useEffect(() => {
      if (page > 1) {
        setOffset((prevState) => prevState + offsetShift);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    useEffect(() => {
      if (searchFilterInput) {
        setOffset(0);
        setPage(1);
        handleAddFormFields();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchFilterInput]);

    useEffect(() => {
      if (isCNPJMaintenance) {
        onCancelOrResetAddMerchantForm();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSavingOrRemovingMerchant]);

    const MerchantItem = ({ item }) => {
      return (
        <Row key={shortId()} gutter={[10, 10]}>
          <Col span={11}>
            <Select value={item.store_id} disabled style={{ width: '100%' }}>
              <Option key={shortId()} value={item.store_id}>
                {`${item?.store_identifier}`}
              </Option>
            </Select>
          </Col>
          <Col span={7}>
            <Input value={item?.merchant_code} disabled />
          </Col>
        </Row>
      );
    };

    return (
      <StContainer>
        <HeaderRegisters
          title="Cadastrar estabelecimentos"
          subTitle="Cadastre os estabelecimentos de cada adquirente e benefício selecionado"
        >
          <Loadable loading={loadingAcquirers || loadingStores}>
            {isCNPJMaintenance ? (
              // MANUTENÇÃO DE CNPJ

              <StTabs
                type="card"
                onChange={onChangeTab}
                activeKey={String(activeTabKey)}
              >
                {acquirers.map((acquirer) => (
                  <>
                    <TabPane
                      destroyInactiveTabPane
                      key={String(acquirer?.acquirer_id)}
                      tab={
                        <StBriefingTab>
                          <Row
                            style={{
                              flexFlow: 'nowrap',
                              alignItems: 'center',
                              gap: 8,
                            }}
                          >
                            <Col>
                              <img
                                src={acquirer.acquirer_icon}
                                alt={`Ícone da ${toTitleCase(acquirer.name)}`}
                                height={16}
                                width={16}
                              />
                            </Col>
                            <Col>{toTitleCase(acquirer.name)}</Col>
                            <Col>{renderAcquirerTabStatusIcon(acquirer)}</Col>
                          </Row>
                        </StBriefingTab>
                      }
                      style={{
                        overflowX: 'hidden',
                        overflowY: 'auto',
                        height: '515px',
                      }}
                    >
                      <Col span={24} style={{ margin: '16px 0' }}>
                        <Row
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            textAlign: 'center',
                            gap: 8,
                          }}
                        >
                          <img
                            src={acquirer.acquirer_icon}
                            alt={toTitleCase(acquirer.name)}
                            height={32}
                            width={32}
                          />

                          <Typography.Text
                            style={{
                              fontSize: '24px',
                              fontWeight: 500,
                              lineHeight: '32px',
                              color: colors.gray9,
                            }}
                          >
                            {toTitleCase(acquirer.name)}
                          </Typography.Text>
                          <Col style={{ marginLeft: 25 }}>
                            {renderAcquirerTabStatusIcon(acquirer)}
                          </Col>
                          <Col style={{ marginLeft: 3 }}>
                            {renderAcquirerTabStatusMessage(acquirer)}
                          </Col>
                        </Row>
                      </Col>

                      <Typography.Text style={{ fontSize: '1.8rem' }}>
                        Selecione os CNPJs que transacionam com a{' '}
                        {toTitleCase(acquirer.name)} e adicione o código do
                        estabelecimento.&nbsp;
                        <Typography.Text
                          style={{ color: colors.blue6, cursor: 'pointer' }}
                          onClick={() => setIsModalVisible(!isModalVisible)}
                        >
                          Onde encontro o código?
                        </Typography.Text>
                        {isModalVisible &&
                          currentAcquirer?.acquirer_id ===
                            acquirer.acquirer_id &&
                          modal(
                            acquirer.acquirer_id,
                            currentAcquirer?.name,
                            isModalVisible,
                            setIsModalVisible,
                            currentAcquirer?.acquirer_id
                          )}
                      </Typography.Text>
                      <br />
                      <br />
                      <Row>
                        <Col span={12}>
                          <Typography.Text style={{ fontSize: '1.75rem' }}>
                            CNPJ | Nome da Loja
                          </Typography.Text>
                        </Col>
                        <Col span={12}>
                          <Typography.Text style={{ fontSize: '1.75rem' }}>
                            Código de estabelecimento
                          </Typography.Text>
                        </Col>
                      </Row>
                      <Row
                        style={{
                          height: '300px',
                          overflowX: 'hidden',
                          overflowY: 'auto',
                        }}
                      >
                        <Col span={24}>
                          <Loadable
                            loading={
                              loadingMerchants || isSavingOrRemovingMerchant
                            }
                          >
                            <>
                              {/* ESTABELECIMENTOS*/}
                              {currentAcquirerMerchants &&
                              currentAcquirerMerchants[0]?.acquirer_id ===
                                acquirer.acquirer_id ? (
                                <>
                                  <div
                                    style={{
                                      width: '100%',
                                      height: '250px',
                                      overflowX: 'hidden',
                                      overflowY: 'auto',
                                    }}
                                    key={renderKey} // necessário para reconstruir o scroll infinito
                                  >
                                    {allItems?.map((item, index) => {
                                      return (
                                        <div
                                          key={shortId()}
                                          ref={
                                            allItems.length === index + 1
                                              ? lastElementRef
                                              : null
                                          }
                                        >
                                          <MerchantItem
                                            item={item}
                                            key={shortId()}
                                          />
                                        </div>
                                      );
                                    })}
                                  </div>
                                  {loadingItems ? (
                                    <Typography.Text
                                      style={{ fontSize: '1.75rem' }}
                                    >
                                      Carregando mais itens...
                                    </Typography.Text>
                                  ) : (
                                    <Typography.Text
                                      style={{ fontSize: '1.75rem' }}
                                    >
                                      {`Exibindo ${itemsCounter} de ${totalItemsCounter}`}
                                    </Typography.Text>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          </Loadable>
                        </Col>
                      </Row>
                      {isAddingMerchant && (
                        <Form
                          form={addForm}
                          onFinish={onFormAddFinish}
                          key="addForm"
                          id="addForm"
                          onFieldsChange={handleAddFormFields}
                        >
                          <Row gutter={[10, 10]}>
                            <Col span={12}>
                              {storeData.stores && (
                                <Form.Item
                                  name="store_id_add"
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Campo obrigatório!',
                                    },
                                  ]}
                                >
                                  <Row style={{ width: '100%' }}>
                                    <SelectInfiniteScroll
                                      isOpenToTop
                                      placeholder="Buscar CNPJ ou apelido da loja"
                                      hasMoreItems={hasMoreStores}
                                      loadingItems={isLoadingSearch}
                                      setSearchFilter={setSearchFilterInput}
                                      options={transformStoreOptions(
                                        storeFilterOptions
                                      )}
                                      onOptionClick={onStoreSelected}
                                      setPage={setPage}
                                    />
                                  </Row>
                                </Form.Item>
                              )}
                            </Col>
                            <Col lg={7}>
                              <Form.Item
                                name="merchant_code_add"
                                rules={merchantCodeValidation}
                              >
                                <Input
                                  placeholder="Código de estabelecimento"
                                  name="merchant_code_add"
                                  locale={ptBR}
                                  style={{ width: '100%' }}
                                  minLength={
                                    currentAcquirer.merchant_code_min_size
                                  }
                                  maxLength={
                                    currentAcquirer.merchant_code_max_size
                                  }
                                  disabled={handleDisabled(currentAcquirer)}
                                />
                              </Form.Item>
                            </Col>
                            <Col
                              lg={5}
                              style={{ float: 'right', whiteSpace: 'nowrap' }}
                            >
                              <Button
                                type="primary"
                                ghost
                                onClick={() => addForm.submit()}
                                disabled={isSaveButtonDisabled}
                              >
                                Salvar
                              </Button>
                              &nbsp;
                              <Button
                                type="default"
                                onClick={() => {
                                  setIsAddingMerchant(false);
                                  onCancelOrResetAddMerchantForm();
                                }}
                              >
                                Cancelar
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      )}
                      <Row>
                        {!isAddingMerchant &&
                          !loadingMerchants &&
                          !isSavingOrRemovingMerchant &&
                          handleAcquirerMerchant(acquirer.acquirer_id) && (
                            <Button
                              disabled={isLocked}
                              type="primary"
                              shape="round"
                              icon={<PlusOutlined />}
                              ghost
                              onClick={() => {
                                addMerchant();
                              }}
                              style={{ marginTop: 16 }}
                            >
                              Incluir outro Estabelecimento
                            </Button>
                          )}
                      </Row>
                    </TabPane>
                  </>
                ))}
              </StTabs>
            ) : (
              // REGISTRO DE IMPLANTAÇÃO
              <Tabs type="card" onChange={onChangeTab}>
                {acquirers.map((acquirer) => (
                  <TabPane
                    key={acquirer.acquirer_id}
                    tab={
                      <StBriefingTab>
                        <Row align="center" gutter={[8, 0]}>
                          <Col>
                            <img
                              src={acquirer.acquirer_icon}
                              alt=""
                              height={16}
                              width={16}
                              style={{ marginBottom: '6px' }}
                            />
                          </Col>
                          <Col>
                            <p
                              style={{
                                marginBottom: '0px',
                                marginRight: '8px',
                              }}
                            >
                              {toTitleCase(acquirer.name)}
                            </p>
                          </Col>
                          <Col>{renderAcquirerTabStatusIcon(acquirer)}</Col>
                        </Row>
                      </StBriefingTab>
                    }
                    style={{
                      overflowX: 'hidden',
                      overflowY: 'auto',
                      maxHeight: '300px',
                    }}
                  >
                    <Typography.Text style={{ fontSize: '1.8rem' }}>
                      Selecione os CNPJs que transacionam com a{' '}
                      {toTitleCase(acquirer.name)} e adicione o código do
                      estabelecimento.&nbsp;
                      <Typography.Text
                        style={{ color: colors.blue6, cursor: 'pointer' }}
                        onClick={() => setIsModalVisible(!isModalVisible)}
                      >
                        Onde encontro o código?
                      </Typography.Text>
                      {isModalVisible &&
                        currentAcquirer?.acquirer_id === acquirer.acquirer_id &&
                        modal(
                          acquirer.acquirer_id,
                          currentAcquirer?.name,
                          isModalVisible,
                          setIsModalVisible,
                          currentAcquirer?.acquirer_id
                        )}
                    </Typography.Text>
                    <br />
                    <br />
                    <Row>
                      <Col span={12}>
                        <Typography.Text style={{ fontSize: '1.75rem' }}>
                          CNPJ | Nome da Loja
                        </Typography.Text>
                      </Col>
                      <Col span={12}>
                        <Typography.Text style={{ fontSize: '1.75rem' }}>
                          Código de estabelecimento
                        </Typography.Text>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Loadable
                          loading={
                            loadingMerchants || isSavingOrRemovingMerchant
                          }
                        >
                          <>
                            {/* ESTABELECIMENTOS */}
                            {currentAcquirerMerchants &&
                              currentAcquirerMerchants.map((merchant) => (
                                <fieldset key={shortId()} disabled={isLocked}>
                                  <Row key={shortId()} gutter={[10, 10]}>
                                    <Col span={12}>
                                      {storeData.stores && (
                                        <Select
                                          value={merchant.store_id}
                                          disabled
                                          style={{ width: '100%' }}
                                        >
                                          {storeData.stores.map((store) => (
                                            <Option
                                              key={shortId()}
                                              value={store.store_id}
                                            >
                                              {`${store.br_cnpj} | ${store.name}`}
                                            </Option>
                                          ))}
                                        </Select>
                                      )}
                                    </Col>
                                    <Col span={7}>
                                      <Input
                                        value={merchant.merchant_code}
                                        disabled
                                      />
                                    </Col>
                                    <Col span={5}>
                                      {!isAddingMerchant && (
                                        <Button
                                          disabled={isLocked}
                                          danger
                                          onClick={() =>
                                            onRemoveMerchant(merchant)
                                          }
                                        >
                                          Remover
                                        </Button>
                                      )}
                                    </Col>
                                  </Row>
                                </fieldset>
                              ))}
                          </>
                        </Loadable>
                      </Col>
                    </Row>
                    {!isAddingMerchant &&
                      !loadingMerchants &&
                      !isSavingOrRemovingMerchant &&
                      handleAcquirerMerchant(acquirer.acquirer_id) && (
                        <Button
                          disabled={isLocked}
                          type="primary"
                          shape="round"
                          icon={<PlusOutlined />}
                          ghost
                          onClick={() => addMerchant()}
                        >
                          Incluir outro Estabelecimento
                        </Button>
                      )}
                    {/* TODO: necessário adicionar um scroll de elementos ao abrir a
                  lista de lojas para add um novo merchant */}
                    {isAddingMerchant && (
                      <Form
                        form={addForm}
                        onFinish={onFormAddFinish}
                        key="addForm"
                        id="addForm"
                        onFieldsChange={handleAddFormFields}
                      >
                        <Row gutter={[10, 10]}>
                          <Col span={12}>
                            {storeData.stores && (
                              <Form.Item
                                name="store_id_add"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Campo obrigatório!',
                                  },
                                ]}
                              >
                                <Select
                                  style={{ width: '100%' }}
                                  onChange={(value) => {
                                    if (
                                      acquirer.is_replicate_merchant_br_cnpj
                                    ) {
                                      addForm.setFieldsValue({
                                        merchant_code_add: storeData.stores
                                          .find((store) => {
                                            return store.store_id === value;
                                          })
                                          ?.br_cnpj?.replaceAll(/[^\d]/g, ''),
                                      });
                                    }
                                  }}
                                >
                                  {storeData.stores.map((store) => (
                                    <Option
                                      key={shortId()}
                                      value={store.store_id}
                                    >
                                      {`${store.br_cnpj} | ${store.name}`}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            )}
                          </Col>
                          <Col lg={7}>
                            <Form.Item
                              name="merchant_code_add"
                              rules={merchantCodeValidation}
                            >
                              <Input
                                placeholder="Código de estabelecimento"
                                name="merchant_code_add"
                                locale={ptBR}
                                style={{ width: '100%' }}
                                minLength={
                                  currentAcquirer.merchant_code_min_size
                                }
                                maxLength={
                                  currentAcquirer.merchant_code_max_size
                                }
                                disabled={handleDisabled(currentAcquirer)}
                              />
                            </Form.Item>
                          </Col>
                          <Col
                            lg={5}
                            style={{ float: 'right', whiteSpace: 'nowrap' }}
                          >
                            <Button
                              type="primary"
                              ghost
                              onClick={() => addForm.submit()}
                              disabled={isSaveButtonDisabled}
                            >
                              Salvar
                            </Button>
                            &nbsp;
                            <Button
                              type="default"
                              onClick={() => setIsAddingMerchant(false)}
                            >
                              Cancelar
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </TabPane>
                ))}
              </Tabs>
            )}
          </Loadable>
        </HeaderRegisters>
      </StContainer>
    );
  }
);

export default BriefingMerchantsRegister;
