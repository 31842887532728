import { action } from 'typesafe-actions';
import { actionTypes } from 'store/ducks/credit/creditInteractView/types';

export const creditInteractViewStart = (data) =>
  action(actionTypes.CREDIT_INTERACT_VIEW_START, data);
export const creditInteractViewSuccess = (data) =>
  action(actionTypes.CREDIT_INTERACT_VIEW_SUCCESS, data);
export const creditInteractViewError = (errorMessage, error) =>
  action(actionTypes.CREDIT_INTERACT_VIEW_ERROR, { errorMessage }, undefined, {
    error,
  });
