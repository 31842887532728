import { api } from './api';

const transformer = (apiResponse) => {
  const transformedResponse = {
    forecast: apiResponse?.data?.forecast,
    meta: apiResponse?.meta,
  };
  return transformedResponse;
};

const getAmountsReceivableInfoFromAPI = async ({
  startDate,
  endDate,
  type,
  page,
  perPage,
  sort,
  filter,
}) => {
  const config = {
    params: {
      start_date: startDate.format('YYYY-MM-DD'),
      end_date: endDate.format('YYYY-MM-DD'),
      type,
      page,
      per_page: perPage,
      order_by: sort,
      filter,
    },
  };
  const response = await api().get('/forecast', config);
  return transformer(response.data);
};

export default getAmountsReceivableInfoFromAPI;
