import { useDispatch } from 'react-redux';
import { Input, Modal, Button, Form } from 'antd';
import { addOFXParameterStart } from '../../../../store/ducks/ofxParameters/actions';
import LabelAndSelect from '../../../../commons/LabelAndSelect/LabelAndSelect';
import PropTypes from 'prop-types';
import React from 'react';
import { getSessionClientId } from 'helpers/sessionService';
import { shortId } from 'helpers/shortId';

// Functions
const AddOFXParameter = ({
  acquirers,
  visibility,
  loading,
  loadingAcquirers,
  closeModal,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  // Functions
  return (
    <Modal
      open={visibility}
      title="Adicionar nova regra"
      closable={false}
      width={290}
      footer={[
        <Button key={shortId()} onClick={closeModal}>
          Cancelar
        </Button>,
        <Button
          key={shortId()}
          type="primary"
          loading={loading}
          onClick={() => {
            form.submit();
          }}
        >
          Adicionar
        </Button>,
      ]}
    >
      <Form
        requiredMark={false}
        layout="vertical"
        style={{ overflowWrap: 'break-word' }}
        name="add_ofx_parameter"
        form={form}
        onFinish={(value) => {
          dispatch(
            addOFXParameterStart({
              client: getSessionClientId(),
              ...value,
            })
          );
          form.resetFields();
        }}
      >
        <Form.Item
          key={shortId()}
          style={{ maxWidth: 269 }}
          label="Regra"
          name="rule"
          rules={[{ required: true, message: 'Por favor insira a regra!' }]}
        >
          <Input type="text" name="rule" placeholder="Insira a regra" />
        </Form.Item>
        <Form.Item
          key={shortId()}
          style={{ maxWidth: 269 }}
          label="Peso"
          name="weight"
          rules={[{ required: true, message: 'Por favor insira o peso!' }]}
        >
          <Input type="number" name="weight" placeholder="Insira o peso" />
        </Form.Item>
        <Form.Item
          name="acquirer_id"
          label="Adquirente"
          rules={[{ required: true, message: 'Campo obrigatório !' }]}
          style={{ maxWidth: 269 }}
        >
          <LabelAndSelect
            key={shortId()}
            name="acquirer_id"
            labelOption="short_name"
            valueOption="acquirer_id"
            options={[
              {
                short_name: 'IGNORAR',
                acquirer_id: -1,
              },
              ...acquirers,
            ]}
            loading={loadingAcquirers}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

AddOFXParameter.defaultProps = {};

AddOFXParameter.propTypes = {
  visibility: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  loadingAcquirers: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default AddOFXParameter;
