import React from 'react';
import { updateScreenToScreenBankReconciliationPeriodStart } from 'store/ducks/updateScreenToScreenPeriod/actions';
import { bankSummaryReconciledStart } from 'store/ducks/reconciled/summary/bank/actions';
import { bankReconIcon, parsedDropdownKeys } from '../utils/parser';
import { ReconciliationCard } from '..';

const BankReconciliationCard = ({ isFlipped, setIsFlipped, dropdownProps }) => {
  const reconProps = {
    reconUrl: '/conciliacao-bancaria',
    reconPeriodStore: 'bankReconciliationPeriod',
    reconPeriodAction: updateScreenToScreenBankReconciliationPeriodStart,
    reconSummaryAction: bankSummaryReconciledStart,
    reconSummaryStore: 'bankSummaryReconciled',
    reconTitle: 'Conciliação bancária',
    reconIcon: bankReconIcon,
    reconLeftColDescription: 'Adquirentes',
    reconRightColDescription: 'Extratos',
    dropdownKey: parsedDropdownKeys,
  };

  return (
    <ReconciliationCard
      hasFooter
      height={360}
      reconciliationType="bank"
      isFlipped={isFlipped}
      setIsFlipped={setIsFlipped}
      dropdownProps={dropdownProps}
      reconProps={reconProps}
      context={{
        left: 'acquirer',
        right: 'customer',
        difference: 'difference',
      }}
    />
  );
};

export default BankReconciliationCard;
