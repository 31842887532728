import styled from 'styled-components';

import { Col, Input, Button } from 'antd';
import { colors } from 'styles/colors';

const { Search } = Input;

export const StContainer = styled.div`
  &&& {
    padding: 8px;
  }
`;

export const StContentContainer = styled.div`
  &&& {
    max-height: 256px;
    overflow-y: auto;
    margin-bottom: 8px;
  }
`;

export const StFilterCol = styled(Col)`
  padding: 2px 0px;
  /* ${({ index }) =>
  index !== 0 &&
    `
    border-top: 1px solid rgba(0, 0, 0, 0.05);
  `} */
`;

export const StSearchInput = styled(Search)`
  &&& {
    height: 32px;
  }
`;

export const StClearButton = styled(Button)`
  &&& {
    color: ${colors.blue6};
    background-color: ${colors.gray1};
    border: none;
    &:disabled {
      cursor: not-allowed;
      color: rgba(0, 0, 0, 0.25);
    }
  }
`;
