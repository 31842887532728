import styled from 'styled-components';
import { Typography, Checkbox, Button } from 'antd';
import { colors } from 'styles/colors';

export const StSideBarMenu = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  background: ${colors.gray1};
  border-radius: 4px;
  height: auto;
`;

export const StContainer = styled.div`
  &&& {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const StTextSideMenu = styled(Typography.Text)`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: ${colors.gray8};
`;

export const StText = styled(Typography.Text)`
  &&& {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    margin-right: 8px;
  }
`;

export const StCheckbox = styled(Checkbox)`
  &&& {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: ${colors.gray9};
    margin-top: 8px;
  }
`;

export const StSpinContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
`;

export const StButton = styled(Button)`
  &&& {
    padding: 0;
    margin: 0;
    font-size: 13px;
    border: 0;
  }
`;
