import { Col } from 'antd';
import styled from 'styled-components';

export const PriceCards = styled.div`
  display: grid;
  grid-gap: 60px 32px;
  grid-template-columns: repeat(auto-fit, 310px);
  justify-content: center;

  > * {
    min-height: 630px;
  }
`;

export const StCol = styled(Col)`
  padding: ${({ $tag = false }) => ($tag ? '64px 32px 56px' : '32px')};
`;
