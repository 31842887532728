import { colors } from 'styles/colors';

export const currentPlanCardDetails = {
  1: {
    name: 'Controle',
    usernameColor: colors.secondary9,
    textColor: colors.secondary1,
    color: colors.lime7,
    backgroundColor: colors.green14,
    hasPlansCompareLink: true,
  },
  2: {
    name: 'Gerencie',
    usernameColor: colors.primary2,
    textColor: colors.secondary1,
    color: colors.cyan11,
    backgroundColor: colors.cyan11,
    hasPlansCompareLink: true,
  },
  4: {
    name: 'Plus',
    usernameColor: colors.gold10,
    textColor: colors.gold9,
    color: colors.gold11,
    backgroundColor: colors.gold12,
    hasPlansCompareLink: false,
  },
  6: {
    name: 'Pag Grátis',
    usernameColor: colors.secondary8,
    textColor: colors.secondary8,
    color: colors.green14,
    backgroundColor: colors.secondary11,
    hasPlansCompareLink: true,
  },
  7: {
    name: 'Free',
    usernameColor: colors.primary6,
    textColor: colors.primary8,
    color: colors.primary6,
    backgroundColor: colors.secondary1,
    hasPlansCompareLink: true,
  },
  8: {
    name: 'Pro',
    usernameColor: colors.primary1,
    textColor: colors.primary1,
    color: colors.primary6,
    backgroundColor: colors.primary6,
    hasPlansCompareLink: true,
  },
};
