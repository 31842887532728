import styled from 'styled-components';
import { Spin, Typography } from 'antd';
import { colors } from 'styles/colors';

export const StTitleItem = styled(Typography.Title)`
  &&& {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
  }
`;

export const StAguardando = styled(Typography.Text)`
  &&& {
    font-size: 12px;
    font-weight: 400;
    line-height: 34px;
    color: #fbbc40;
  }
`;

export const StReprovado = styled(Typography.Text)`
  &&& {
    font-size: 12px;
    font-weight: 400;
    line-height: 34px;
    color: #ff0000;
  }
`;

export const StTexto = styled(Typography.Text)`
  &&& {
    font-size: 11px;
    font-weight: 400;
    line-height: 34px;
  }
`;

export const StStatusText = styled(Typography.Text)`
  display: flex;
  height: 100%;
  align-items: center;
`;

export const StCountStore = styled(Typography.Text)`
  &&& {
    font-size: 14px;
    font-weight: 400;
    color: ${colors.gray8};
    line-height: 22px;
  }
`;
export const StTextItem = styled(Typography.Text)`
  &&& {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: ${colors.gray8};
  }
`;
export const StValueItem = styled(Typography.Text)`
  &&& {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: ${colors.gray8};
  }
`;

export const StContainer = styled.div`
  background-color: ${colors.gray1};
`;

export const StSpin = styled(Spin)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.gray1};
  padding: 16px 0;
`;
