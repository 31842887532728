import { action } from 'typesafe-actions';
import { actionTypes } from './types';

export const duedateDetailsFiltersStart = (data) => action(actionTypes.DUEDATE_DETAILS_FILTERS_START, data);
export const duedateDetailsFiltersSuccess = (data) =>
  action(actionTypes.DUEDATE_DETAILS_FILTERS_SUCCESS, data);
export const duedateDetailsFiltersError = (errorMessage, error) =>
  action(actionTypes.DUEDATE_DETAILS_FILTERS_ERROR, { errorMessage }, undefined, { error });
export const duedateDetailsFiltersZeroLength = () =>
  action(actionTypes.DUEDATE_DETAILS_FILTERS_ZERO_LENGTH);
export const updateDuedateDetailsFilters = (data) =>
  action(actionTypes.UPDATE_DUEDATE_DETAILS_FILTERS, data);
