import { call, put } from 'redux-saga/effects';

import { getCnpj } from 'services/receitaWebservice';
import { getCnpjSuccess, getCnpjError, updateCnpjStatus } from './actions';
import errorHandler from '../../../helpers/errorHandler';

export function* getCnpjSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(getCnpj, payload);
    yield put(getCnpjSuccess(apiData));
  } catch (err) {
    yield put(getCnpjError(err));
    errorHandler('CNPJ Inválido', err);
  }
}

export function* updateCnpjStatusSaga(action) {
  const { payload } = action;
  yield put(updateCnpjStatus(payload));
}
