/* eslint-disable consistent-return */
/* eslint-disable no-else-return */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Col, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { TableCustomColumnsSelect } from 'commons/TableCustomColumnsSelect';
import { StContainer, StLimiter } from './styled';
import { getNotificationStart } from 'store/ducks/notifications/actions';
import { getSessionClientId } from 'helpers/sessionService';
import { ModalForm } from 'components/ReconciliationRules/components/ModalForm';
import { colors } from 'styles/colors';
import { PlusOutlined } from '@ant-design/icons';
import { ExportDropdown } from 'commons/ExportDropdown';

const TableHeader = ({
  columns,
  defaultSelectedColumns,
  dropdownOption,
  pagination,
  createNotificationAction,
  notificationConfig,
  setDropdownOption,
}) => {
  const { useBreakpoint } = Grid;
  const breakpoint = useBreakpoint();
  const dispatch = useDispatch();
  const createNotificationSuccess = useSelector(
    (state) => state.notifications.success
  );
  const createNotificationLoading = useSelector(
    (state) => state.notifications.loading
  );
  const permissionsData = useSelector((state) => state.permissions.data);
  const ruleHasBeenAdded = useSelector(
    (state) => state.postReconciliationRules.ruleHasBeenAdded
  );
  const { user_id: userId, scope_id: scopeId } = permissionsData;
  const clientId = getSessionClientId();

  useEffect(() => {
    if (dropdownOption === 'excel' || dropdownOption === 'csv') {
      dispatch(createNotificationAction(notificationConfig));
      setDropdownOption('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownOption]);

  useEffect(() => {
    if (createNotificationSuccess) {
      dispatch(
        getNotificationStart({
          userId,
          scopeId,
          clientId,
          page: 1,
          perPage: 10,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createNotificationSuccess]);

  const [modalFormVisibility, setModalFormVisibility] = useState(false);

  useEffect(() => {
    if (ruleHasBeenAdded) setModalFormVisibility(false);
  }, [ruleHasBeenAdded]);

  const openModalForm = () => {
    setModalFormVisibility(true);
  };

  const closeModalForm = () => {
    setModalFormVisibility(false);
  };

  return (
    <StContainer>
      <StLimiter $breakpoint={breakpoint}>
        <Row
          gutter={[8, 0]}
          style={{
            alignItems: 'center',
            justifyContent: 'end',
            backgroundColor: colors.gray1,
            padding: '3rem',
          }}
        >
          <Col>
            <Row>
              <Col span={24}>
                <Button type="primary" onClick={openModalForm}>
                  <>
                    <PlusOutlined /> Adicionar regra
                  </>
                </Button>
              </Col>
            </Row>
          </Col>
          <Col>
            <TableCustomColumnsSelect
              columns={columns}
              defaultSelectedColumns={defaultSelectedColumns}
              pagination={pagination}
            />
          </Col>

          <Col>
            <ExportDropdown
              loading={createNotificationLoading}
              setDropdownOption={setDropdownOption}
              buttonType="default"
            />
          </Col>
          <ModalForm
            visibility={modalFormVisibility}
            closeModal={closeModalForm}
            modalType="addRule"
            currentRow={{}}
          />
        </Row>
      </StLimiter>
    </StContainer>
  );
};

TableHeader.propTypes = {
  defaultSelectedColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
  dropdownOption: PropTypes.string.isRequired,
  pagination: PropTypes.shape({
    pageSize: PropTypes.number,
    total: PropTypes.number,
    current: PropTypes.number,
  }).isRequired,
};

export default TableHeader;
