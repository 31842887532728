import { call, put, delay } from 'redux-saga/effects';
import { notification } from 'antd';

import errorHandler from 'helpers/errorHandler';

import removeUser from '../../../services/removeUser';
import { removeUserError, removeUserSuccess } from './actions';

export function* removeUserSaga(action) {
  try {
    const { payload } = action;

    const apiData = yield call(removeUser, payload);

    notification.success({
      message: 'Usuário removido com sucesso !',
    });

    yield delay(2000);
    yield put(removeUserSuccess(apiData));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        removeUserError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    }
  }
}
