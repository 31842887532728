import { call, put } from 'redux-saga/effects';

import {
  deleteUploadedLetterUrl,
  getUploadedLetterUrl,
} from 'services/briefing/uploadedLetterUrl';
import {
  getUploadedLetterUrlSuccess,
  getUploadedLetterUrlError,
  deleteUploadedLetterUrlSuccess,
  deleteUploadedLetterUrlError,
} from './actions';
import errorHandler from '../../../helpers/errorHandler';
import successHandler from 'helpers/successHandler';

export function* getUploadedLetterUrlSaga(payload) {
  try {
    const apiData = yield call(getUploadedLetterUrl, payload);
    yield put(getUploadedLetterUrlSuccess(apiData));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        getUploadedLetterUrlError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(getUploadedLetterUrlError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}

export function* deleteUploadedLetterUrlSaga(payload) {
  try {
    const apiData = yield call(deleteUploadedLetterUrl, payload);
    yield put(deleteUploadedLetterUrlSuccess(apiData));
    successHandler('Arquivo deletado com sucesso!');
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        deleteUploadedLetterUrlError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(deleteUploadedLetterUrlError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}
