import styled from 'styled-components';

export const StContainer = styled.div`
  margin: 0 auto;
  height: 100%;
  max-width: 1360px;
  background-color: ${(props) => (props?.$breakpoint?.lg ? '#f4f4f4' : '#c7ddd0')};
`;

export const StSendMessage = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  height: 100%;
  width: 100%;
`;
