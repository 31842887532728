const skins = {
  A4STI: 1,
  AUDITTO: 2,
  CONCIL: 3,
  CONCIL_REVENDA: 4,
  FINAXIS: 5,
  PROSEGUR: 6,
  RECEBA_CERTO: 7,
  VR_SOFTWARE: 8,
  PAGSEGURO: 9,
  PAGSEGURO_APP: 10,
  PAGSEGURO_WEB: 11,
};

export default skins;
