import styled from 'styled-components';
import { Row } from 'antd';
import { colors } from 'styles/colors';

export const StRow = styled(Row)`
  width: 132px;
  object-fit: contain;
  border-radius: 2px;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.32);
  padding: 8px;
`;

export const StTooltipTitle = styled.span`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  color: ${colors.gray1};
`;

export const StTooltipSubtitle = styled.span`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  color: ${colors.gray1};
`;

export const StTooltipValue = styled.span`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  color: ${colors.gray1};
`;
