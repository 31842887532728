import errorHandler from 'helpers/errorHandler';
import { call, put } from 'redux-saga/effects';
import { run } from 'services/radar/run';
import { notification } from 'antd';
import {
  radarRunSuccess,
  radarRunError,
} from 'store/ducks/radar/radarRun/actions';

export function* radarRunSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(run, payload);
    yield put(radarRunSuccess(apiData));
    notification.success({
      message: 'Solicitação de envio de radar realizada com sucesso',
    });
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        radarRunError(err.response.data.error.message, err.response.data.error)
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(radarRunError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}
