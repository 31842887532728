/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
import React from 'react';
import { columnSorter } from 'helpers/generalService';
import { StTableColumnTitle } from '../../../../../commons/Table/styled';
import Actions from './Actions';
import AcquirerAction from './AcquirerAction';
import { getSessionClientId } from 'helpers/sessionService';
import { shortId } from 'helpers/shortId';
import {
  isAcquirerIdentified,
  isAcquirerIgnored,
} from 'components/BankConciliationTransactions/helpers/acquirerStatus';

export const isIgnoredFromAPI = (ignoredFromAPI, transactionID) => {
  const match = ignoredFromAPI?.find((tr) => tr.unique_id === transactionID);
  return !!match;
};

export const updateLocalStorage = (
  currentRow,
  currentAcquirerValue,
  isIgnored
) => {
  const formattedTransactionAmount = Number(
    currentRow?.transaction_amount
      .replace('R$ ', '')
      .replace(/[.]/g, '')
      .replace(',', '.')
  );

  let classificationObject = {
    client: {
      id: getSessionClientId(),
      files: [
        {
          countersIgnored: 1,
          id: currentRow?.id_file_tracking,
          transactions: [
            {
              id: currentRow?.unique_id,
              acquirer_name: currentAcquirerValue,
              amount: formattedTransactionAmount,
              is_identified: isAcquirerIdentified(currentAcquirerValue),
              is_ignored: isAcquirerIgnored(currentAcquirerValue),
            },
          ],
        },
      ],
    },
  };

  // Se não existe nada no localStorage, ele cria o item inicial aqui
  if (!localStorage.getItem('classified_transactions')) {
    localStorage.setItem(
      'classified_transactions',
      JSON.stringify(classificationObject)
    );
    // Se JÁ EXISTE o item no localStorage, a lógica começa aqui
  } else {
    classificationObject = JSON.parse(
      localStorage.getItem('classified_transactions')
    );
    if (classificationObject?.client?.id === getSessionClientId()) {
      const fileIsInLocal = classificationObject?.client?.files.some(
        (file) => file?.id === currentRow?.id_file_tracking
      );

      // Se o arquivo em questão JÁ ESTÁ no localStorage do usuário
      if (fileIsInLocal) {
        // Para cada arquivo do localStorage
        classificationObject?.client?.files.forEach((file) => {
          // Se o ID do arquivo do localStorage der match com o ID da linha que será atualizada
          if (file?.id === currentRow?.id_file_tracking) {
            let anotherTransaction = false;
            // Se TEM transações dentro desse arquivo do localStorage
            if (file?.transactions !== undefined) {
              // Para cada uma dessas transações
              file.transactions.forEach((transaction) => {
                // Se o ID da transação do localStorage der match com o ID da linha que será atualizada
                // Seta o acquirerName, isIgnored e isIdentified dessa transação com o que vier das props
                if (transaction?.id === currentRow?.unique_id) {
                  transaction.acquirer_name = currentAcquirerValue;
                  transaction.is_ignored = isIgnored;
                  transaction.is_identified =
                    isAcquirerIdentified(currentAcquirerValue);
                  // CASO CONTRÁRIO, é outra transação, e aí seta anotherTransaction como true
                } else {
                  anotherTransaction = true;
                }
              });
              if (anotherTransaction) {
                let isRepeatedTransaction = false;
                file.transactions.forEach((transaction) => {
                  if (transaction?.id === currentRow?.unique_id) {
                    isRepeatedTransaction = true;
                  }
                });
                if (!isRepeatedTransaction) {
                  file.transactions = [
                    ...file.transactions,
                    {
                      id: currentRow?.unique_id,
                      acquirer_name: currentAcquirerValue,
                      amount: formattedTransactionAmount,
                      is_identified: isAcquirerIdentified(currentAcquirerValue),
                      is_ignored: isIgnored,
                    },
                  ];
                }
              }
            }
          }
        });
      } else {
        classificationObject?.client?.files.push({
          id: currentRow?.id_file_tracking,
          transactions: [
            {
              id: currentRow?.unique_id,
              acquirer_name: currentAcquirerValue,
              amount: formattedTransactionAmount,
              is_identified: isAcquirerIdentified(currentAcquirerValue),
              is_ignored: isIgnored,
            },
          ],
        });
      }
    }

    localStorage.setItem(
      'classified_transactions',
      JSON.stringify(classificationObject)
    );
  }
};

const ColumnList = (acquirers, changeTransaction, counters) => [
  {
    title: 'Data',
    dataIndex: 'payment_date',
    filterMultiple: true,
    key: shortId(),
    sorter: (a, b) => columnSorter(a, b, 'payment_date'),
    width: 140,
    align: 'center',
  },
  {
    title: 'Descrição',
    dataIndex: 'description',
    key: shortId(),
    filterMultiple: true,
    sorter: (a, b) => columnSorter(a, b, 'description'),
    width: 200,
    align: 'center',
    className: 'hide',
  },
  {
    title: 'Tipo',
    dataIndex: 'transaction_type',
    key: shortId(),
    filterMultiple: true,
    sorter: (a, b) => columnSorter(a, b, 'transaction_type'),
    width: 100,
    align: 'center',
  },
  {
    title: 'Valor',
    dataIndex: 'transaction_amount',
    key: shortId(),
    filterMultiple: true,
    sorter: (a, b) => columnSorter(a, b, 'raw_transaction_amount'),
    width: 200,
    align: 'center',
  },
  {
    title: 'Adquirente responsável',
    dataIndex: 'acquirer_name',
    fixed: 'right',
    width: 200,
    align: 'center',
    render: (item, record) => {
      return (
        <AcquirerAction
          currentItem={item}
          currentRow={record}
          acquirers={acquirers}
          changeTransaction={changeTransaction}
          setUpdateLocal={counters.setUpdateLocal}
        />
      );
    },
  },
  {
    title: () => {
      return <StTableColumnTitle>Ações</StTableColumnTitle>;
    },
    dataIndex: 'actions',
    key: shortId(),
    width: 150,
    render: (_, record) => {
      return (
        <Actions
          currentRow={record}
          changeTransaction={changeTransaction}
          counters={counters}
        />
      );
    },
    align: 'center',
    fixed: 'right',
  },
];

export default ColumnList;
