import React from 'react';
import { Grid, Row, Col, Card } from 'antd';
import { ErrorCard } from 'commons/ErrorCard';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { StTableColumnTitle, StTable } from 'commons/Table/styled';
import { TableHeader } from 'components/Reports/SalesReconciliationReports/components';
import { StContainer, StLimiter } from './styled';
import { StTableContainer } from 'commons/Table/components/TableHeader/styled';

const Tables = ({
  dataSource,
  columns,
  loading,
  onChange,
  pagination,
  error,
  headerGrossAmount,
  defaultSelectedColumns,
}) => {
  const { useBreakpoint } = Grid;
  const breakpoint = useBreakpoint();
  const customColumns = useSelector((state) => {
    const transformedColumns = [];
    if (Array.isArray(state.reportsCustomColumns.columns)) {
      state.reportsCustomColumns.columns.forEach((column) => {
        const transformedColumn = {
          ...column,
          title: () => <StTableColumnTitle>{column.title}</StTableColumnTitle>,
        };
        transformedColumns.push(transformedColumn);
      });
    }
    return transformedColumns;
  });

  if (breakpoint?.md && error) {
    return (
      <Row style={{ marginTop: '192px' }}>
        <Col lg={10} md={18}>
          <Card style={{ borderRadius: 4 }} loading={loading}>
            <ErrorCard
              title="Dados indisponíveis"
              reason="Não conseguimos buscar os dados, mas já estamos trabalhando para normalizar a situação."
              recommendation="Tente atualizar a página em alguns instantes."
            />
          </Card>
        </Col>
      </Row>
    );
  }

  if (breakpoint?.md) {
    return (
      <StTableContainer>
        <TableHeader
          title="Relatório Geral"
          totalText="Total bruto divergente: "
          totalValue={new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(headerGrossAmount)}
          columns={columns}
          defaultSelectedColumns={defaultSelectedColumns}
        />
        <StContainer>
          <StLimiter $breakpoint={breakpoint}>
            {customColumns?.length && (
              <StTable
                onChange={onChange}
                pagination={pagination}
                loading={loading}
                dataSource={dataSource}
                columns={customColumns}
                scroll={{
                  x: '100%',
                  y: window.innerHeight - 400,
                }}
                showSorterTooltip={false}
              />
            )}
          </StLimiter>
        </StContainer>
      </StTableContainer>
    );
  }
  return '';
};

Tables.defaultProps = {
  pagination: {
    pageSize: 10,
    total: 100,
    current: 1,
    pageSizeOptions: ['10', '100', '200', '300', '400', '500', '1000'],
    showTotal: () => '',
  },
};

Tables.propTypes = {
  dataSource: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  loading: PropTypes.bool.isRequired,
  pagination: PropTypes.shape({
    pageSize: PropTypes.number,
    total: PropTypes.number,
    current: PropTypes.number,
  }),
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
  headerGrossAmount: PropTypes.number.isRequired,
  defaultSelectedColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Tables;
