import { call, put } from 'redux-saga/effects';

import errorHandler from 'helpers/errorHandler';

import {
  searchTransactionSuccess,
  searchTransactionError,
} from 'store/ducks/searchTransaction/actions';
import getSearchTransactionFromAPI from 'services/searchTransaction';

export function* searchTransactionSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(getSearchTransactionFromAPI, payload);

    yield put(searchTransactionSuccess(apiData));
  } catch (err) {
    if (err?.response?.data?.message) {
      yield put(
        searchTransactionError(
          err.response.data.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(searchTransactionError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
      errorHandler(err.response.data.message, err.response.data.error);
    }
  }
}
