import React, { useContext, useEffect, useState } from 'react';
import { Tooltip } from 'antd';
import { MerchantFeesStatus } from '../MerchantFeesStatus';
import { StQuestionCircleOutlined, StTable } from './styled';
import useFeesConsolidatedDetail from 'components/Fees/hooks/useFeesConsolidatedDetail';
import { TableFooter } from '../TableFooter';
import { FeesContext } from 'components/Fees/Fees';
import { useInfiniteScrollPageChange } from 'utils/hooks/useInfiniteScrollPageChange';

const columns = [
  {
    title: 'CNPJ',
    dataIndex: 'brCnpj',
    key: 'brCnpj',
    width: 200,
    align: 'left',
  },
  {
    title: 'Razão Social',
    dataIndex: 'name',
    key: 'name',
    width: 280,
    align: 'left',
  },
  {
    title: (
      <>
        <span>Apelido da loja</span>
        <Tooltip
          title="Este é o apelido dado para se referir à loja"
          placement="top"
        >
          <StQuestionCircleOutlined />
        </Tooltip>
      </>
    ),
    dataIndex: 'shortName',
    key: 'shortName',
    width: 280,
    align: 'left',
  },
  {
    title: (
      <>
        <span>Código de estabelecimento</span>
        <Tooltip title="Este é o código da sua maquininha" placement="top">
          <StQuestionCircleOutlined />
        </Tooltip>
      </>
    ),
    dataIndex: 'merchantCode',
    key: 'merchantCode',
    width: 180,
    align: 'left',
  },
  {
    title: 'Status das taxas',
    key: 'status',
    dataIndex: 'status',
    width: 200,
    align: 'left',
    render: (status) => <MerchantFeesStatus status={status} />,
  },
];

const transform = (data = []) => {
  return data.map(
    ({
      br_cnpj: brCnpj,
      name,
      short_name: shortName,
      merchant_code: merchantCode,
      fee_status: status,
      store_id: storeId,
    }) => {
      return {
        key: merchantCode,
        brCnpj,
        name,
        shortName,
        merchantCode,
        status,
        storeId,
      };
    }
  );
};

const FeesConsolidatedTable = () => {
  const { currentActivePanel, selectedStore } = useContext(FeesContext);

  const [page, setPage] = useState(1);
  const perPage = 10;
  const [feesConsolidatedCount, setFeesCount] = useState(0);
  const [data, setData] = useState([]);

  const { isLoading, metaData, feesConsolidated } = useFeesConsolidatedDetail({
    page,
    setPage,
    perPage,
    currentActivePanel,
    selectedStore,
  });
  const { total: feesConsolidatedTotal, has_next: hasMoreFees } = metaData;

  const { lastElementRef } = useInfiniteScrollPageChange({
    loading: isLoading,
    hasMore: hasMoreFees,
    setPage,
  });

  useEffect(() => {
    setData(feesConsolidated);
    setFeesCount(feesConsolidated?.length);
  }, [feesConsolidated]);

  return (
    <>
      <StTable
        columns={columns}
        dataSource={transform(data) || []}
        pagination={false}
        loading={isLoading}
        scroll={data?.length >= 10 ? { x: true, y: 350 } : { x: true }}
        onRow={(_, index) => {
          return {
            ref: data?.length === index + 1 ? lastElementRef : null,
          };
        }}
      />
      <TableFooter
        itemCounter={feesConsolidatedCount}
        totalCount={feesConsolidatedTotal}
        isLoadingItems={isLoading}
      />
    </>
  );
};

export default FeesConsolidatedTable;
