import { action } from 'typesafe-actions';
import { actionTypes } from 'store/ducks/details/reconciledPaymentsDetails/types';

export const reconciledPaymentsDetailsStart = (data) =>
  action(actionTypes.RECONCILED_PAYMENTS_DETAILS_START, data);
export const reconciledPaymentsDetailsSuccess = (data) =>
  action(actionTypes.RECONCILED_PAYMENTS_DETAILS_SUCCESS, data);
export const reconciledPaymentsDetailsError = (errorMessage, error) =>
  action(
    actionTypes.RECONCILED_PAYMENTS_DETAILS_ERROR,
    { errorMessage },
    undefined,
    { error }
  );
