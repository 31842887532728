/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import { getSessionClientId } from 'helpers/sessionService';
import { api } from './api';

const transformVencida = (data) => {
  return {
    count: data.meta.num_results,
    hasNext: data.meta.has_next,
    perPage: data.meta.per_page,
    vencidas: data.data.fees,
  };
};

export const listFeesVencida = async ({ page, perPage, sort, q }) => {
  if (q?.filter?.length > 0) {
    // eslint-disable-next-line consistent-return
    Object.values(q?.filter).forEach((value) => {
      if (value?.name === 'fee_percent_fmt') {
        return (value.name = 'fee_percent');
      }
    });
  }

  const config = {
    params: {
      client_id: getSessionClientId(),
      page,
      per_page: perPage,
      sort_fields: sort,
      filter: 'VENCIDAS',
      q,
    },
  };
  const response = await api().get('/fees', config);
  return transformVencida(response.data);
};

const transformVigente = (data) => {
  return {
    count: data.meta.num_results,
    hasNext: data.meta.has_next,
    perPage: data.meta.per_page,
    vigentes: data.data.fees,
  };
};

export const listFeesVigente = async ({ page, perPage, sort, q }) => {
  if (q?.filter?.length > 0) {
    // eslint-disable-next-line consistent-return
    Object.values(q?.filter).forEach((value) => {
      if (value?.name === 'fee_percent_fmt') {
        return (value.name = 'fee_percent');
      }
    });
  }

  const config = {
    params: {
      client_id: getSessionClientId(),
      page,
      per_page: perPage,
      sort_fields: sort,
      filter: 'VIGENTES',
      q,
    },
  };
  const response = await api().get('/fees', config);
  return transformVigente(response.data);
};

export const putFees = async ({ data }) => {
  const json = {
    fee_id: data.fee_id,
    new_fee: data.new_fee,
    email: data.email,
    acquirer_name: data.acquirer_name,
    client_code: data.client_code,
    start_date: data.start_date,
    end_date: data.new_end_date,
  };
  const response = await api().post('/fees/reprocessing_status', json);
  return response;
};

export const deleteFees = async ({ data }) => {
  // eslint-disable-next-line no-return-await
  return await api().delete(`/fees?fee_id=${data.id}`);
};
