import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { RoundedCheckbox } from 'commons/RoundedCheckbox';
import {
  AcquirerCard,
  AcquirerImage,
  AcquirerNameLabel,
  AcquirerCheckbox,
} from './styled';
import { usePlansFeaturesPermissions } from 'utils/hooks/usePlansFeaturesPermissions';

const AcquirerCheckCard = ({
  acquirer,
  onClick,
  disabled,
  isCNPJMaintenance,
}) => {
  const { isFeatureAvailable } = usePlansFeaturesPermissions();
  const { acquirer_id: acquirerId = undefined } = acquirer;
  const isPagSeguro = acquirerId === 39;
  const isAcquirerRestriction =
    !isFeatureAvailable({ key: 'cnpjMaintenance-viewAllAcquirers' }) &&
    !isPagSeguro;
  const isAcquirerCardDisabled = disabled || isAcquirerRestriction;
  const isAcquirerCardEmpty = isAcquirerRestriction ? false : acquirer.is_empty;

  let tooltipMessage = '';

  if (
    isCNPJMaintenance &&
    !acquirer.is_empty &&
    acquirer.checked &&
    !isAcquirerRestriction
  ) {
    tooltipMessage =
      'Há estabelecimento(s) cadastrado(s) para essa adquirente e ela não pode ser desativada';
  } else if (isAcquirerRestriction) {
    tooltipMessage =
      'Para adicionar essa adquirente, é necessário fazer um upgrade de plano. Consulte os planos disponíveis';
  }

  const handleOnClick = () => {
    if (!isAcquirerCardDisabled) {
      onClick();
    }
  };

  return (
    <Tooltip title={tooltipMessage}>
      <AcquirerCard
        disabled={isAcquirerCardDisabled}
        checked={acquirer.checked}
        onClick={handleOnClick}
      >
        <AcquirerCheckbox>
          <RoundedCheckbox
            checked={acquirer.checked}
            isCNPJMaintenance={isCNPJMaintenance}
            disabled={isAcquirerCardDisabled}
            isEmpty={isAcquirerCardEmpty}
            acquirerDetails={acquirer}
          />
        </AcquirerCheckbox>
        <AcquirerImage src={acquirer.acquirer_logo} />
        <AcquirerNameLabel>{acquirer.name.toLowerCase()}</AcquirerNameLabel>
      </AcquirerCard>
    </Tooltip>
  );
};

AcquirerCheckCard.defaultProps = {
  disabled: false,
};
AcquirerCheckCard.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  acquirer: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default AcquirerCheckCard;
