import React from 'react';
import PropTypes from 'prop-types';
import { StStageRect } from 'components/BranchManagement/styled';

const BranchStage = ({ isImplanted }) => {
  return (
    <StStageRect isImplanted={isImplanted === 'Produção'}>
      {isImplanted}
    </StStageRect>
  );
};

BranchStage.propTypes = {
  isImplanted: PropTypes.string.isRequired,
};

export default BranchStage;
