import styled, { css } from 'styled-components';
import {
  Button as AntButton,
  Col as AntCol,
  Divider as AntDivider,
  Form as AntForm,
  Input as AntInput,
  Row,
  Select as AntSelect,
} from 'antd';
import { colors } from 'styles/colors';
import { DownOutlined, ShopOutlined } from '@ant-design/icons';

const { Item } = AntForm;

export const Form = styled(AntForm)`
  &&& {
    max-width: 100%;
    overflow-wrap: break-word;
  }
`;

export const ShopOutlinedIcon = styled(ShopOutlined)`
  &&& {
    font-size: 18px;
  }
`;

export const Divider = styled(AntDivider)`
  &&& {
    position: absolute;
    left: 0;
    margin: -10px 0;
    border-top: 2px solid rgba(0, 0, 0, 0.06);
  }
`;

export const FormItemDefault = styled(Item)`
  &&& {
    width: 100%;
  }
`;

export const FormItemButtons = styled(Item)`
  &&& {
    width: 100%;
    position: absolute;
    z-index: 1;
    margin-top: 25px;
  }
`;

export const IsImplantedContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const IsImplantedParagraph = styled.p`
  &&& {
    margin: 0 !important;
    font-size: 16px;
    font-weight: 500;
    cursor: ${({ $disableFields }) =>
      $disableFields ? 'not-allowed' : 'pointer'};
    color: ${({ $disableFields }) =>
      $disableFields ? 'rgba(0, 0, 0, 0.25)' : colors.primary6};
  }
`;

export const DownOutlinedIcon = styled(DownOutlined)`
  font-size: 12px;
  margin-left: 4px;
  color: ${({ $disableFields }) =>
    $disableFields ? 'rgba(0, 0, 0, 0.25)' : colors.primary6};
`;

export const SkinContainer = styled(IsImplantedContainer)`
  ${IsImplantedContainer}
`;

export const SkinParagraph = styled.p`
  &&& {
    margin: 0 !important;
    font-size: 16px;
    font-weight: 500;
    cursor: ${({ $boolValue }) => ($boolValue ? 'not-allowed' : 'pointer')};
    color: ${({ $boolValue }) =>
      $boolValue ? 'rgba(0, 0, 0, 0.25)' : colors.primary6};
  }
`;

export const PlanContainer = styled(IsImplantedContainer)`
  ${IsImplantedContainer}
`;

export const PlanParagraph = styled(SkinParagraph)`
  ${SkinParagraph}
`;

export const HeaderRow = styled(Row)`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

export const Col = styled(AntCol)`
  ${({ $spaceBetween, $extraMargin }) => css`
    &&& {
      display: flex;
      justify-content: ${$spaceBetween ? 'space-between' : 'flex-start'};
      align-items: center;
      width: 100%;
      margin-top: ${$extraMargin ? '30px' : 0};
      margin-bottom: ${$extraMargin ? '10px' : 0};
    }
  `}
`;

export const MainText = styled.p`
  ${({ noMargin }) => css`
    color: ${colors.gray9};
    font-weight: 500;
    font-size: 16px;
    margin: ${noMargin ? '0' : '0 0 0 18px'};
  `}
`;

export const Button = styled(AntButton)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px 12px;
  width: 85px;
  height: 32px;
  background: ${colors.primary6};
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: ${colors.gray1};
`;

export const CancelButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 12px;
  position: static;
  width: 79px;
  height: 32px;
  right: 71px;
  top: 0px;
  background: ${colors.gray1};
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: ${colors.gray9};
  margin-right: 8px;
`;

export const Title = styled.h3`
  color: ${colors.gray11};
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: center;
`;

export const Subtitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: ${(props) => (props?.breakpoint?.sm ? '12px' : '14px')};
  line-height: 22px;
  display: flex;
  align-items: center;
  color: ${colors.gray11};
  margin-bottom: 28px;
`;

export const SwitchRow = styled(Row)`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

export const FieldTitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: ${colors.gray11};
`;

export const FieldSubtitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #8e8e8e;
`;

export const InputCol = styled(AntCol)`
  width: 100%;
`;

export const Input = styled(AntInput)`
  margin-top: 8px;
`;

export const Select = styled(AntSelect)`
  margin-top: 6px;
`;

export const ExtraTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: ${colors.gray10};
  margin-top: 16px;
  margin-bottom: 16px;
`;
