import styled from 'styled-components';
import { Typography } from 'antd';

export const StContainer = styled.div`
  text-align: ${({ textAlign }) => textAlign};
`;

export const StTypographyTextValue = styled(Typography.Text)`
  &&& {
    font-size: ${({ fontSize }) => fontSize};
    color: ${({ color }) => color};
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
  }
`;
