/* eslint-disable no-case-declarations */
/* eslint-disable no-else-return */
/* eslint-disable no-unsafe-negation */
import { actionTypes } from 'store/ducks/feesUpdateFilteredColumns/types';

const INITIAL_STATE = {
  error: undefined,
  errorMessage: undefined,
  loading: true,
  isError: false,
  filteredColumns: [],
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.FEES_UPDATE_FILTERED_COLUMNS_START:
      return {
        ...state,
        loading: true,
        error: undefined,
        errorMessage: undefined,
        isError: false,
      };
    case actionTypes.FEES_UPDATE_FILTERED_COLUMNS_SUCCESS:
      let filteredColumnsList = [...state.filteredColumns];

      const incomeColumnFilter = {
        name: action?.payload?.name,
        val: action?.payload?.val,
      };

      const incomeColumnExists = filteredColumnsList.some((column) => {
        return column.name === incomeColumnFilter.name;
      });

      const incomeColumnIsEmpty = incomeColumnFilter.val.length === 0;

      if (!incomeColumnExists) {
        filteredColumnsList.push(incomeColumnFilter);
      } else if (incomeColumnExists && !incomeColumnIsEmpty) {
        const updatedList = filteredColumnsList.map((column) => {
          if (column.name === incomeColumnFilter.name) {
            return incomeColumnFilter;
          }
          return column;
        });
        filteredColumnsList = updatedList;
      } else {
        filteredColumnsList = filteredColumnsList.filter((column) => {
          return column.name !== incomeColumnFilter.name;
        });
      }
      return {
        ...state,
        filteredColumns: filteredColumnsList,
        loading: false,
        error: undefined,
        isError: false,
        errorMessage: undefined,
      };
    case actionTypes.FEES_UPDATE_FILTERED_COLUMNS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error.error,
        errorMessage: action.payload,
        isError: true,
      };
    case actionTypes.FEES_RESET_UPDATE_FILTERED_COLUMNS_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.FEES_RESET_UPDATE_FILTERED_COLUMNS_SUCCESS:
      return {
        ...state,
        loading: false,
        filteredColumns: [],
      };
    case actionTypes.FEES_RESET_UPDATE_FILTERED_COLUMNS_ERROR:
      return {
        ...state,
        errorMessage: JSON.stringify(action.payload),
        isError: true,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
