/* eslint-disable no-param-reassign */
import { actionTypes } from './types';

const INITIAL_STATE = {
  filters: [],
  hasMore: true,
  offset: 0,
  column: '',
  limit: 10,
  offsetShift: 10,
  loading: false,
  error: undefined,
  errorMessage: undefined,
  toParamsFilters: [],
  isError: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.FEES_DETAILS_FILTERS_START:
      return {
        ...state,
        loading: true,
        error: undefined,
        isError: false,
      };
    case actionTypes.FEES_DETAILS_FILTERS_SUCCESS:
      if (state?.hasMore) {
        let previousFilters = state?.filters;
        const incomingFilters = action?.payload?.results;
        if (previousFilters.length > 0) {
          previousFilters.forEach((previousFilter) => {
            incomingFilters.forEach((incomingFilter) => {
              if (incomingFilter.text === previousFilter?.text) {
                previousFilter.text = incomingFilter.text;
                previousFilter.values = [
                  ...new Set(
                    previousFilter.values.concat(incomingFilter?.values)
                  ),
                ].sort();
              }
            });
          });
        } else {
          previousFilters = incomingFilters;
        }
        return {
          ...state,
          loading: false,
          error: false,
          filters: previousFilters,
          offset: action.payload?.offset + state?.offsetShift,
          limit: action.payload?.limit + state?.offsetShift,
          hasMore: action.payload?.hasMore,
          isError: false,
        };
      }
      return {
        ...state,
        loading: false,
        error: false,
        filters: state?.filters,
        hasMore: action.payload.hasMore,
        isError: false,
      };
    case actionTypes.FEES_DETAILS_FILTERS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error.error,
        errorMessage: action.payload,
        isError: true,
      };
    case actionTypes.FEES_DETAILS_FILTERS_ZERO_LENGTH:
      return {
        ...state,
        loading: false,
        error: false,
        filters: [],
        hasMore: true,
        isError: false,
      };
    case actionTypes.UPDATE_FEES_DETAILS_FILTERS:
      return {
        ...state,
        loading: false,
        error: false,
        toParamsFilters: action.payload,
        isError: false,
      };
    default:
      return state;
  }
};

export default reducer;
