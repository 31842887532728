import React from 'react';
import { Row, Col } from 'antd';
import { StPartnerMsg, StTextMsg } from './styled';
import { InfoCircleFilled } from '@ant-design/icons';
import { PartnerCard } from './components/PartnerCard';
import { PartnerHeader } from './components/PartnerHeader';
import { partnerTypes } from 'constants/offers';
import { colors } from 'styles/colors';

const PartnerOffers = ({ offerData, interpretStatus, hasTomaticoOffer }) => {
  const BTG_ID = 1;
  const hardcodedTomaticoData = {
    id: 583,
    creditPartner: {
      id: 2,
      codename: 'TOMATICO',
      name: 'Tomático',
      description:
        'Crédito simples e sem burocracia, com custo fixo e parcelas flexíveis.',
      terms:
        'Estou ciente e de acordo em fornecer para a Captalys Companhia de Crédito Ltda, meu nome, telefone, e-mail, CNPJ e histórico de recebimento das adquirentes dos últimos 12 meses, com a finalidade de ter acesso a crédito e soluções financeiras.',
      type: 'CREDIT',
      interestRate: 1.5,
      s3LogoPath:
        'https://concil-dev-v4-assets.s3.sa-east-1.amazonaws.com/banking/logo_tomatico.png',
      createTimestamp: '11/12/2021 17:26:52',
      scheduled: true,
      active: true,
      parameters: {
        PRODUCT: 'Tomatico',
        API_TOKEN: process.env.REACT_APP_TOKEN_TOMATICO,
        URL_CADASTRAR_USUARIO:
          'https://tomatico.captalys.io/api/cadastrarUsuario',
        URL_CONSULTAR_FLUXO:
          'https://acompanhamento.captalys.io/operacao/consultar/limite/Concil',
        URL_CADASTRAR_FLUXO:
          'https://acompanhamento.captalys.io/operacao/enviar/fluxo/Concil',
        URL_STATUS_OPERACAO: 'https://tomatico.captalys.io/api/cadastro/status',
        URL_MINHA_CONTA: 'https://app.tomatico.com/minhaConta.html',
        ACQUIRER_SUPPORTED_LIST: 'BIN,REDECARD,GETNET,STONE,CIELO',
        MONTHS_HISTORY: '12',
      },
      rules: {},
    },
    clientId: 3326,
    nationalRegistration: '00.000.000/0000-00',
    status: 'NEW',
    createTimestamp: '13/02/2022 22:01:14',
    closed: false,
    active: false,
  };

  return (
    <>
      <Col xs={4} sm={8} md={12} lg={24} xl={24} xxl={24}>
        {offerData?.creditPartner?.id !== BTG_ID ? (
          <Row>
            <Col span={24}>
              <PartnerHeader offerData={offerData} />
            </Col>
          </Row>
        ) : (
          <Row>
            <Col span={24}>
              <PartnerHeader offerData={hardcodedTomaticoData} />
            </Col>
          </Row>
        )}

        {offerData?.active === false ? (
          <Row
            align="middle"
            justify="space-between"
            style={{ margin: '0px 0px 16px 0px' }}
          >
            <Col align="middle" span={24}>
              <StPartnerMsg
                bgColor={offerData?.active ? colors.gold1 : colors.primary1}
              >
                <InfoCircleFilled
                  style={
                    offerData?.active
                      ? { color: colors.gold6 }
                      : { color: colors.primary6 }
                  }
                />
                {offerData?.active ? (
                  <StTextMsg>
                    Seus dados foram compartilhados com este parceiro e já estão
                    em avaliação. Ofertas personalizadas ficam disponíveis após
                    a análise.
                  </StTextMsg>
                ) : (
                  <StTextMsg>
                    Ofertas sujeitas à avaliação deste parceiro. Autorize o
                    compartilhamento de seus dados com o parceiro para ter
                    acesso aos valores reais.
                  </StTextMsg>
                )}
              </StPartnerMsg>
            </Col>
          </Row>
        ) : (
          <></>
        )}

        <Row>
          {hasTomaticoOffer ? (
            <PartnerCard
              partnerService={partnerTypes[offerData?.creditPartner?.type]}
              partnerTag={partnerTypes[offerData?.creditPartner?.type]}
              isPartnerActive={offerData?.creditPartner?.active}
              isOfferActive={offerData?.active}
              approvedValue={offerData?.approvedValue || 0}
              creditId={offerData?.id}
              offerStatus={offerData?.status}
              interpretStatus={interpretStatus}
              cnpj={offerData?.nationalRegistration}
            />
          ) : (
            <PartnerCard
              partnerService="Crédito"
              partnerTag="Crédito"
              isPartnerActive
              isOfferActive
              noStatus
              approvedValue={0}
              creditId={2}
              offerStatus="?"
              interpretStatus={() => {}}
              cnpj={offerData?.nationalRegistration}
            />
          )}
        </Row>
      </Col>
    </>
  );
};

export default PartnerOffers;
