import { bankingApi } from '../api';

const transformer = (apiResponse) => {
  return {
    payload: apiResponse?.payload,
  };
};

// Resume um Radar expirado ou com erro (Também pode ser considerando um Retry)
export const runResume = async ({ radarId }) => {
  const config = {
    params: {
      radarId,
    },
  };
  const response = await bankingApi().get('/radar/run/resume', config);
  return transformer(response.data);
};
