import { actionTypes } from './types';

const initialState = {
  message: '',
  type: '',
  error: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case actionTypes.ERROR_TO_SHOW:
    return {
      ...state,
      error: true,
      message: action.payload.message,
      type: action.payload.type,
    };
  case actionTypes.CLEAN_ERROR:
    return {
      ...state,
      error: false,
      message: '',
      type: '',
    };
  default:
    return state;
  }
};

export default reducer;
