import React, { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';
import { StColumnFilterDropdownSpinnerWrapper } from 'components/Reports/styled';
import {
  Button,
  Space,
  Spin,
  Row,
  Col,
  Checkbox,
  Typography,
  List,
  Empty,
} from 'antd';
import {
  StClearButton,
  StContainer,
  StContentContainer,
  StFilterCol,
  StSearchInput,
} from './styled';

const CustomFilterDropdown = ({
  apiFilters,
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  filterAction,
  filteredColumns,
  clearAction,
  columnName,
  storeName,
  disableSearchInput,
  updatedFilteredColumns,
  otherParams = {},
}) => {
  const { Text } = Typography;
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state[storeName]?.loading);
  const hasMore = useSelector((state) => state[storeName]?.hasMore);
  const offset = useSelector((state) => state[storeName]?.offset);
  const updatedState = useSelector((state) => state[storeName]);
  const initialOffset = 0;
  const [filteredApiFilters, setFilteredApiFilters] = useState([]);
  const [customSelectedKeys, setCustomSelectedKeys] = useState([]);
  const [clearDisabled, setClearDisabled] = useState(true);
  const [filterDisabled, setFilterDisabled] = useState(true);
  const [searchedValue, setSearchedValue] = useState('');
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(() => {
    if (apiFilters?.length > 0) {
      setFilteredApiFilters(updatedState?.filters[0]?.values?.filter((e) => e));
    } else {
      setFilteredApiFilters([]);
    }
    forceUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiFilters, updatedState]);

  useEffect(() => {
    forceUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedState]);

  useEffect(() => {
    if (customSelectedKeys.length > 0) {
      setClearDisabled(false);
      setFilterDisabled(false);
    } else {
      if (selectedKeys.length === 0) {
        setClearDisabled(true);
      }
      setFilterDisabled(true);
    }
    setSelectedKeys(customSelectedKeys);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customSelectedKeys]);

  useEffect(() => {
    /**
     * When the filter dropdown is reopened,
     * the customSelectedKeys must be filled with the selectedKeys data,
     * so that the checkboxes get checked "by default".
     */

    setCustomSelectedKeys(selectedKeys);
    if (selectedKeys.length > 0) {
      setClearDisabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadMoreFilters = () => {
    if (hasMore && !isLoading) {
      dispatch(
        filterAction({
          otherParams,
          columnName: columnName,
          offset,
          limit: 10,
          searchBy: searchedValue,
          q: {
            filter: filteredColumns,
          },
        })
      );
    }
  };

  const onSearch = (typedValue) => {
    let _typedValue = typedValue;
    if (typedValue === '') {
      _typedValue = undefined;
    }
    setSearchedValue(_typedValue);
    dispatch(clearAction());

    dispatch(
      filterAction({
        otherParams,
        columnName: columnName,
        offset: initialOffset,
        limit: 10,
        searchBy: _typedValue,
        q: {
          filter: filteredColumns,
        },
      })
    );
  };

  return (
    <StContainer>
      {!disableSearchInput && (
        <StSearchInput
          placeholder="Procurar"
          onSearch={(e) => {
            onSearch(e);
          }}
          style={{ marginBottom: '8px', height: '32px' }}
        />
      )}
      <StContentContainer>
        <InfiniteScroll
          pageStart={0}
          initialLoad={false}
          loadMore={loadMoreFilters}
          hasMore={isLoading || hasMore}
          useWindow={false}
          loader={
            isLoading && (
              <StColumnFilterDropdownSpinnerWrapper key={0}>
                <Spin />
              </StColumnFilterDropdownSpinnerWrapper>
            )
          }
        >
          <List
            dataSource={filteredApiFilters}
            renderItem={(item, i) => {
              return (
                <List.Item>
                  <Row>
                    <StFilterCol span={24} index={i}>
                      <Checkbox
                        checked={selectedKeys.includes(item)}
                        onChange={(e) => {
                          const checked = e?.target?.checked;
                          if (checked) {
                            setCustomSelectedKeys([
                              ...customSelectedKeys,
                              item,
                            ]);
                          } else {
                            const clonedCustomSelectedKeys = JSON.parse(
                              JSON.stringify(customSelectedKeys)
                            );
                            const index =
                              clonedCustomSelectedKeys.indexOf(item);
                            if (index > -1) {
                              clonedCustomSelectedKeys.splice(index, 1);
                            }
                            setCustomSelectedKeys(clonedCustomSelectedKeys);
                          }
                        }}
                      >
                        <Text>{!!item && item}</Text>
                      </Checkbox>
                    </StFilterCol>
                  </Row>
                </List.Item>
              );
            }}
          >
            {/*
              This fragment is here because otherwise the list will show an useless
              "There's no data" message. The loading spinner is rendered by the parent component.
            */}
            {filteredApiFilters?.length === 0 && !isLoading && (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="Nenhum filtro encontrado"
              />
            )}
            <></>
          </List>
        </InfiniteScroll>
      </StContentContainer>
      <Space>
        <Row gutter={[8, 0]} style={{ marginTop: '20px' }}>
          <Col span={12}>
            <StClearButton
              onClick={() => {
                clearFilters();
                setCustomSelectedKeys([]);
                dispatch(
                  updatedFilteredColumns({
                    name: columnName,
                    val: [],
                  })
                );
                confirm();
              }}
              size="small"
              disabled={clearDisabled}
            >
              Limpar
            </StClearButton>
          </Col>
          <Col span={12}>
            <Button
              onClick={() => {
                dispatch(
                  updatedFilteredColumns({
                    name: columnName,
                    val: selectedKeys,
                  })
                );
                confirm();
              }}
              size="small"
              type="primary"
              disabled={filterDisabled}
            >
              Filtrar
            </Button>
          </Col>
        </Row>
      </Space>
    </StContainer>
  );
};

export default CustomFilterDropdown;
