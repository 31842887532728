/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import { ContentFilters } from './components/ContentFilters';
import { ContentRates } from './components/ContentRates';
import { currentDay, thirtyDaysAgo } from 'helpers/dates';

const RatesIntermediate = () => {
  const [startDate, setStartDate] = useState(thirtyDaysAgo);
  const [endDate, setEndDate] = useState(currentDay);

  const loading = useSelector((state) => state.registerFee.loading);

  const onRangePickerChange = (dates) => {
    if (dates?.length) {
      setStartDate(dates[0]);
      setEndDate(dates[1]);
    }
  };

  return (
    <>
      <ContentFilters
        onRangePickerChange={onRangePickerChange}
        dateDefaultValue={[startDate, endDate]}
        loading={loading}
      />
      <ContentRates />
    </>
  );
};

export default RatesIntermediate;
