import { call, put } from 'redux-saga/effects';
import errorHandler from 'helpers/errorHandler';

import getPaymentDivergencesDetailsFiltersFromAPI from 'services/filters/details/paymentDivergencesDetailsFilters';
import { errorToShow } from 'store/ducks/errorToShow/actions';

import {
  paymentDivergencesDetailsFiltersSuccess,
  paymentDivergencesDetailsFiltersError,
} from './actions';

export function* paymentDivergencesDetailsFiltersSaga(action) {
  const { payload } = action;
  try {
    const apiData = yield call(
      getPaymentDivergencesDetailsFiltersFromAPI,
      payload
    );
    yield put(paymentDivergencesDetailsFiltersSuccess(apiData));
  } catch (err) {
    if (
      err?.response?.data?.error &&
      err?.response?.data?.error?.type &&
      err?.response?.data?.error?.message
    ) {
      yield put(
        errorToShow({
          message: err.response.data.error.message,
          type: err.response.data.error.type,
        })
      );
      yield put(
        paymentDivergencesDetailsFiltersError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    }
  }
}
