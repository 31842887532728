import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import locale from 'antd/es/locale/pt_BR';
import { ConfigProvider } from 'antd';
import { GlobalHotKeys } from 'react-hotkeys';
import { GlobalStyle } from 'styles/globalStyle';
import { globalHotKeys } from 'constants/globalHotKeys';
import customHistory from './helpers/history';
import { AppRoutes } from 'routes';
import store from './store';
import 'antd/dist/antd.less';
import Amplify from 'aws-amplify';
import ReactGA from 'react-ga4';
import { Hotjar, NewRelic, UserGuiding } from 'externalScripts';

import { ptAbbreviatedMonths } from 'constants/abbreviatedMonths';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import 'dayjs/locale/pt-br';

dayjs.locale('pt-br');
dayjs.extend(updateLocale);
dayjs.updateLocale('pt-br', { monthsShort: ptAbbreviatedMonths });

const awsConfig = {
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id:
    process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  aws_appsync_authenticationType:
    process.env.REACT_APP_AWS_APPSYNC_AUTHENTICATION_TYPE,
};

Amplify.configure(awsConfig);

const handlers = {
  OPEN_DASHBOARD_ACTION: () => {
    customHistory.push('/dashboard');
  },
  OPEN_USER_MANAGEMENT_ACTION: () => {
    customHistory.push('/gerenciar-usuarios');
  },
  OPEN_BRANCH_MANAGEMENT_ACTION: () => {
    customHistory.push('/gerenciar-empresas');
  },
  OPEN_TAXES_ACTION: () => {
    customHistory.push('/taxas');
  },
  OPEN_SALES_ACTION: () => {
    customHistory.push('/vendas-intermediaria');
  },
  OPEN_AMOUNTS_RECEIVABLE_ACTION: () => {
    customHistory.push('/valores-a-receber');
  },
};

const App = () => {
  const gaMeasurementId = process.env.REACT_APP_GA_MEASUREMENT_ID;

  const isProduction = process.env.REACT_APP_BUILD_ENV === 'production';
  const isUserGuidingEnabled =
    process.env.REACT_APP_USERGUIDING_ENABLED === 'true';
  const isHotjarEnabled = process.env.REACT_APP_HOTJAR_ENABLED === 'true';
  const isGA4Enabled = process.env.REACT_APP_GA4_ENABLED === 'true';
  const isNewRelicEnabled = process.env.REACT_APP_NEW_RELIC_ENABLED === 'true';

  useEffect(() => {
    if (isProduction && gaMeasurementId && isGA4Enabled) {
      ReactGA.initialize(gaMeasurementId);
      // eslint-disable-next-line no-console
      console.log('GA init');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProduction, gaMeasurementId]);

  useEffect(() => {
    // External scripts
    if (isHotjarEnabled) {
      Hotjar();
    }

    if (isUserGuidingEnabled) {
      UserGuiding();
    }

    if (isNewRelicEnabled) {
      NewRelic();
    }
  }, [isHotjarEnabled, isUserGuidingEnabled, isNewRelicEnabled]);
  // redeploy

  return (
    <Provider store={store}>
      <ConfigProvider locale={locale}>
        <GlobalHotKeys keyMap={globalHotKeys} handlers={handlers}>
          <GlobalStyle />
          <AppRoutes />
        </GlobalHotKeys>
      </ConfigProvider>
    </Provider>
  );
};

export default App;
