import { Auth } from 'aws-amplify';

async function authLogout() {
  try {
    await Auth.signOut();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('error signing out: ', error);
  }
}

export default authLogout;
