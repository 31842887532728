import { api } from './api';

export const getMerchants = async ({ data }) => {
  const config = {
    params: { ...data },
  };
  const response = await api().get('/merchant', config);
  return response.data;
};

export const postMerchant = async (payload) => {
  const response = await api().post('/merchant', payload);
  return response.data;
};

export const deleteMerchant = async (payload) => {
  const merchantId = payload?.merchantId;
  const response = await api().delete(`/merchant/${merchantId}`);
  return response.data;
};
