import dayjs from 'dayjs';
import { actionTypes } from './types';

const INITIAL_STATE = {
  loading: true,
  loadingUpdate: false,
  loadingExport: false,
  listConciliationData: [],
  listTransactionsData: [],
  countConciliation: 0,
  countersTotal: 0,
  countersIdentified: 0,
  countersIgnored: 0,
  countersNotIdentified: 0,
  conciliationDataListed: false,
  transactionsDataListed: false,
  filters: [],
  hasMore: true,
  isError: false,
  isErrorUpdate: false,
  errorMessage: undefined,
  startDate: dayjs().format('YYYY-MM-DD'),
  endDate: dayjs().format('YYYY-MM-DD'),
  bank: '',
  bankCode: '',
  bankBranch: '',
  bankAccount: '',
  transactionsStartDate: dayjs().format('YYYY-MM-DD'),
  transactionsEndDate: dayjs().format('YYYY-MM-DD'),
  accountId: '',
  order: 'ASC',
  orderBy: '',
  throughImportFiles: false,
  loadingThroughImportFiles: false,
  loadingHasNoTransactions: false,
  hasNoTransactions: false,
  totalAcquirer: 0,
  difference: 0,
  differenceLocalStorage: 0,
  acquirerName: '',
  fileName: '',
  transactionsHasBeenUpdated: false,
  ignoredTransactions: [],
  dataBeingProcessed: false,
  currentPage: 1,
  afterSave: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.LIST_BANK_CONCILIATION_START:
      return {
        ...state,
        loading: true,
        errorMessage: undefined,
        isError: false,
      };
    case actionTypes.LIST_BANK_CONCILIATION_SUCCESS:
      return {
        ...state,
        loading: false,
        listConciliationData: action.payload.data,
        countConciliation: action.payload.count,
        conciliationDataListed: true,
        filters: action.payload.filters,
        hasMore: action.payload.hasMore,
        perPage: action.payload.perPage,
        page: action.payload.page,
        isError: false,
        errorMessage: undefined,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        order: action.payload.order,
        orderBy: action.payload.orderBy,
        dataBeingProcessed: action.payload.dataBeingProcessed,
      };
    case actionTypes.LIST_BANK_CONCILIATION_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: JSON.stringify(action.payload),
        isError: true,
        listConciliationData: [],
      };
    case actionTypes.EXPORT_BANK_CONCILIATION_START:
      return {
        ...state,
        loading: true,
        loadingExport: true,
        error: undefined,
        errorMessage: undefined,
        isError: false,
      };
    case actionTypes.EXPORT_BANK_CONCILIATION_SUCCESS:
      return {
        ...state,
        loading: false,
        loadingExport: false,
        error: undefined,
        meta: action.payload.meta,
        isError: false,
        errorMessage: undefined,
      };
    case actionTypes.EXPORT_BANK_CONCILIATION_ERROR:
      return {
        ...state,
        loading: false,
        loadingExport: false,
        error: action.error.error,
        errorMessage: action.payload,
        isError: true,
        summary: undefined,
      };
    case actionTypes.SET_TRANSACTIONS_SELECTED:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.SET_TRANSACTIONS_SELECTED_SUCCESS:
      return {
        ...state,
        loading: false,
        bank: action.payload.bank,
        bankCode: action.payload.bank_code,
        bankBranch: action.payload.bank_branch,
        bankAccount: action.payload.bank_account,
        transactionsStartDate: action.payload.payment_date,
        transactionsEndDate: action.payload.payment_date,
        accountId: action.payload.account_id,
      };
    case actionTypes.LIST_BANK_CONCILIATION_TRANSACTIONS_START:
      return {
        ...state,
        loading: true,
        errorMessage: undefined,
        isError: false,
        ignoredTransactions: [],
        listTransactionsData: [],
      };
    case actionTypes.LIST_BANK_CONCILIATION_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        listTransactionsData: action.payload.data,
        countTransactions: action.payload.count,
        countersTotal: action.payload.countersTotal,
        countersIdentified: action.payload.countersIdentified,
        countersIgnored: action.payload.countersIgnored,
        countersNotIdentified: action.payload.countersNotIdentified,
        transactionsDataListed: true,
        hasNoTransactions: action.payload.hasNoTransactions,
        totalAcquirer: action.payload.totalAcquirer,
        difference: action.payload.difference,
        ignoredTransactions: action.payload.ignoredTransactions,
        accountId: action.payload.accountId,
        isError: false,
        errorMessage: undefined,
        // bank: action.payload.bank,
        // bankCode: action.payload.bank_code,
        // bankBranch: action.payload.bank_branch,
        // bankAccount: action.payload.bank_account,
        // transactionsStartDate: action.payload.startDate,
        // transactionsEndDate: action.payload.endDate,
      };
    case actionTypes.LIST_BANK_CONCILIATION_TRANSACTIONS_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: JSON.stringify(action.payload),
        isError: true,
        listTransactionsData: [],
        ignoredTransactions: [],
      };
    case actionTypes.LIST_BANK_CONCILIATION_TRANSACTIONS_CLEAR_DONE:
      return {
        ...state,
        loading: false,
        errorMessage: undefined,
        isError: false,
        listTransactionsData: [],
      };
    case actionTypes.UPDATE_BANK_CONCILIATION_TRANSACTIONS_START:
      return {
        ...state,
        loadingUpdate: true,
        errorMessage: undefined,
        isErrorUpdate: false,
        transactionsHasBeenUpdated: false,
      };
    case actionTypes.UPDATE_BANK_CONCILIATION_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loadingUpdate: false,
        isErrorUpdate: false,
        transactionsHasBeenUpdated: true,
      };
    case actionTypes.UPDATE_BANK_CONCILIATION_TRANSACTIONS_ERROR:
      return {
        ...state,
        loadingUpdate: false,
        errorMessage: JSON.stringify(action.payload),
        isErrorUpdate: true,
        transactionsHasBeenUpdated: false,
      };
    case actionTypes.UPDATE_COUNTER_TRANSACTIONS_START:
      return {
        ...state,
        loadingUpdate: true,
        errorMessage: undefined,
        isErrorUpdate: false,
      };
    case actionTypes.UPDATE_COUNTER_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        countersIdentified: action.payload.countersIdentified,
        countersIgnored: action.payload.countersIgnored,
        countersNotIdentified: action.payload.countersNotIdentified,
        countersTotal: action.payload.countersTotal,
        loadingUpdate: false,
        isErrorUpdate: false,
      };
    case actionTypes.UPDATE_COUNTER_TRANSACTIONS_ERROR:
      return {
        ...state,
        loadingUpdate: false,
        errorMessage: JSON.stringify(action.payload),
        isErrorUpdate: true,
      };
    case actionTypes.UPDATE_TRANSACTION_DATE_START:
      return {
        ...state,
        loadingUpdate: true,
        errorMessage: undefined,
        isErrorUpdate: false,
      };
    case actionTypes.UPDATE_TRANSACTION_DATE_SUCCESS:
      return {
        ...state,
        transactionsStartDate: action?.payload?.transactionsStartDate,
        transactionsEndDate: action?.payload?.transactionsEndDate,
        loadingUpdate: false,
        isErrorUpdate: false,
      };
    case actionTypes.UPDATE_TRANSACTION_DATE_ERROR:
      return {
        ...state,
        loadingUpdate: false,
        errorMessage: JSON.stringify(action.payload),
        isErrorUpdate: true,
      };
    case actionTypes.UPDATE_THROUGH_IMPORT_FILES_START:
      return {
        ...state,
        loadingThroughImportFiles: true,
      };
    case actionTypes.UPDATE_THROUGH_IMPORT_FILES_SUCCESS:
      return {
        ...state,
        throughImportFiles: action?.payload?.throughImportFiles,
        fileName: action?.payload?.fileName,
        loadingThroughImportFiles: false,
        loading: false,
        loadingUpdate: false,
        loadingExport: false,
      };
    case actionTypes.UPDATE_THROUGH_IMPORT_FILES_ERROR:
      return {
        ...state,
        loadingThroughImportFiles: false,
        fileName: '',
        loadingUpdate: false,
        errorMessage: JSON.stringify(action.payload),
        isErrorUpdate: true,
      };
    case actionTypes.RESET_HAS_NO_TRANSACTIONS_START:
      return {
        ...state,
        loadingHasNoTransactions: true,
      };
    case actionTypes.RESET_HAS_NO_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        // hasNoTransactions: action.payload.resetHasNoTransactions,
        hasNoTransactions: false,
        loadingHasNoTransactions: false,
      };
    case actionTypes.RESET_HAS_NO_TRANSACTIONS_ERROR:
      return {
        ...state,
        loadingHasNoTransactions: false,
        loadingUpdate: false,
        errorMessage: JSON.stringify(action.payload),
        isErrorUpdate: true,
      };
    case actionTypes.UPDATE_DIFFERENCE_VALUE_START:
      return {
        ...state,
      };
    case actionTypes.UPDATE_DIFFERENCE_VALUE_SUCCESS:
      return {
        ...state,
        differenceLocalStorage: action.payload.differenceLocalStorage,
      };
    case actionTypes.UPDATE_DIFFERENCE_VALUE_ERROR:
      return {
        ...state,
        loadingUpdate: false,
        errorMessage: JSON.stringify(action.payload),
        isErrorUpdate: true,
      };
    case actionTypes.UPDATE_ACQUIRER_NAME_START:
      return {
        ...state,
      };
    case actionTypes.UPDATE_ACQUIRER_NAME_SUCCESS:
      return {
        ...state,
        acquirerName: action.payload.acquirerName,
      };
    case actionTypes.UPDATE_ACQUIRER_NAME_ERROR:
      return {
        ...state,
        loadingUpdate: false,
        errorMessage: JSON.stringify(action.payload),
        isErrorUpdate: true,
      };
    case actionTypes.UPDATE_CURRENT_PAGE_START:
      return {
        ...state,
      };
    case actionTypes.UPDATE_CURRENT_PAGE_SUCCESS:
      return {
        ...state,
        currentPage: action.payload.currentPage,
      };
    case actionTypes.UPDATE_CURRENT_PAGE_ERROR:
      return {
        ...state,
        loadingUpdate: false,
        errorMessage: JSON.stringify(action.payload),
        isErrorUpdate: true,
        currentPage: 1,
      };
    case actionTypes.UPDATE_AFTER_SAVE:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_AFTER_SAVE_SUCCESS:
      return {
        ...state,
        afterSave: action.payload.afterSave,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
