import React from 'react';
import { StContainer, StListAgroup, StTitleAling } from './styled';
import { Badge, Typography } from 'antd';
import PropTypes from 'prop-types';
import { ItemCardList } from '../ItemCardList';

const ListComponent = ({
  name,
  bank_color: bankColor,
  payments,
  total_per_bank: totalPerBank,
  bank_code: bankCode,
}) => {
  return (
    <StListAgroup>
      <StTitleAling>
        <Badge color={bankColor} />
        <Typography.Text style={{ fontSize: '2rem', fontWeight: 'bold' }}>
          {name}
        </Typography.Text>
      </StTitleAling>
      <Typography.Text style={{ fontSize: '1.75rem' }}>
        {new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(totalPerBank)}
      </Typography.Text>
      <StContainer style={{ overflowX: 'auto' }}>
        {payments.map((item) => {
          return (
            <ItemCardList
              key={String(
                `${item.bank_code}-${item.bank_agency}-${item.bank_account}`
              )}
              name={name}
              item={item}
              bank_code={bankCode}
            />
          );
        })}
      </StContainer>
    </StListAgroup>
  );
};

ListComponent.defaultProps = {
  total_per_bank: '0',
};

ListComponent.propTypes = {
  name: PropTypes.string.isRequired,
  bank_color: PropTypes.string.isRequired,
  total_per_bank: PropTypes.string,
  bank_code: PropTypes.string.isRequired,
  payments: PropTypes.arrayOf(
    PropTypes.shape({
      bank_agency: PropTypes.string,
      bank_account: PropTypes.string,
      total_receipt_amount: PropTypes.number,
    })
  ).isRequired,
};

export default ListComponent;
