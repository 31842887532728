import React, { useCallback, useEffect, useRef, useState } from 'react';
import { debounce } from 'helpers/debounce/debounce';
import * as St from './styled';
import { Empty } from 'antd';

const SelectInfiniteScroll = ({
  placeholder,
  hasMoreItems,
  loadingItems,
  options,
  setPage,
  setSearchFilter,
  onOptionClick: emitOptionClick,
  labelText,
  isClear,
  searchFilterInput,
  isOpenToTop,
  ...others
}) => {
  const [option, setOption] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const hasOptions = options.length > 0;

  const observer = useRef();
  const lastClientElementRef = useCallback(
    (node) => {
      if (loadingItems) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMoreItems) {
          setPage((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loadingItems, hasMoreItems, setPage]
  );

  const onClickCustomInput = () => setOption(!option);
  const onClickOverlay = () => setOption(!option);
  const onOptionClick = (value, label) => () => {
    if (!value || !label) return;
    setOption(false);
    emitOptionClick({ value, label });
    setSearchValue(label);
  };

  const debouncedSearch = debounce(async (criteria) => {
    setSearchFilter(criteria);
  }, 800);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceRequest = useCallback((value) => debouncedSearch(value), []);

  async function handleChange(e) {
    debounceRequest(e.target.value);
    setSearchValue(e.target.value);
  }

  let suffixIcon;

  const clearSearch = () => {
    emitOptionClick({});
    setSearchFilter('');
    setSearchValue('');
    setPage(1);
  };

  if (searchValue?.length > 0 && !loadingItems) {
    suffixIcon = (
      <St.CustomCloseCircleFilledIcon onClick={() => clearSearch()} />
    );
  } else if (loadingItems) {
    suffixIcon = <St.CustomSpin size="small" />;
  } else if (!option) {
    suffixIcon = <St.CustomDownOutlinedIcon />;
  } else {
    suffixIcon = <St.CustomSearchOutlinedIcon />;
  }

  useEffect(() => {
    if (isClear) {
      clearSearch();
    }
    // eslint-disable-next-line
  }, [isClear]);

  return (
    <>
      {labelText && (
        <St.StTypographyTextLabel>{labelText}</St.StTypographyTextLabel>
      )}
      <St.CustomInput
        value={searchValue}
        placeholder={placeholder}
        onClick={onClickCustomInput}
        onChange={handleChange}
        suffix={suffixIcon}
        autoComplete="off"
        {...others}
      />
      {option && (
        <>
          <St.Overlay onClick={onClickOverlay} />
          <St.DropdownContainer
            className="options-container"
            labelText={labelText}
            isOpenToTop={isOpenToTop}
          >
            {hasOptions ? (
              options?.map(({ value, label }, index) => {
                return (
                  <St.Option
                    key={value}
                    ref={
                      options.length === index + 1 ? lastClientElementRef : null
                    }
                    onClick={onOptionClick(value, label)}
                  >
                    {label}
                  </St.Option>
                );
              })
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </St.DropdownContainer>
        </>
      )}
    </>
  );
};

export default SelectInfiniteScroll;
