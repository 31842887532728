import React from 'react';
import {
  StCol,
  StLogo,
  StRow,
  StLogoContainer,
  StDetailContainer,
  StPlansLogo,
} from './styled';
import { dataTestIdHandler } from 'helpers/dataTestIdHandler';

export const PlansHeader = ({ dataTestPrefix }) => {
  const assetsBaseURL = process.env.REACT_APP_AWS_V4_ASSETS;
  const iconConcil = `${assetsBaseURL}/img/iconConcil.png`;
  const iconConcilCardPlans = `${assetsBaseURL}/img/iconConcilCardPlans.png`;

  return (
    <StCol {...dataTestIdHandler(dataTestPrefix, 'header-container')}>
      <StRow>
        <StLogoContainer>
          <StLogo src={iconConcil} alt="Marca d'agua Concil" />
        </StLogoContainer>

        <StDetailContainer>
          <StPlansLogo
            src={iconConcilCardPlans}
            alt="Logo planos Concil Card"
          />
          <span>Escolha o melhor plano para o seu negócio</span>
        </StDetailContainer>
      </StRow>
    </StCol>
  );
};
