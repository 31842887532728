export const actionTypes = {
  UPDATE_SCREEN_TO_SCREEN_SALES_PERIOD_START:
    '@updateScreenToScreenPeriod/UPDATE_SCREEN_TO_SCREEN_SALES_PERIOD_START',
  UPDATE_SCREEN_TO_SCREEN_SALES_PERIOD_SUCCESS:
    '@updateScreenToScreenPeriod/UPDATE_SCREEN_TO_SCREEN_SALES_PERIOD_SUCCESS',
  UPDATE_SCREEN_TO_SCREEN_SALES_PERIOD_ERROR:
    '@updateScreenToScreenPeriod/UPDATE_SCREEN_TO_SCREEN_SALES_PERIOD_ERROR',
  UPDATE_SCREEN_TO_SCREEN_PAYMENT_DIVERGENCES_PERIOD_START:
    '@updateScreenToScreenPeriod/UPDATE_SCREEN_TO_SCREEN_PAYMENT_DIVERGENCES_PERIOD_START',
  UPDATE_SCREEN_TO_SCREEN_PAYMENT_DIVERGENCES_PERIOD_SUCCESS:
    '@updateScreenToScreenPeriod/UPDATE_SCREEN_TO_SCREEN_PAYMENT_DIVERGENCES_PERIOD_SUCCESS',
  UPDATE_SCREEN_TO_SCREEN_PAYMENT_DIVERGENCES_PERIOD_ERROR:
    '@updateScreenToScreenPeriod/UPDATE_SCREEN_TO_SCREEN_PAYMENT_DIVERGENCES_PERIOD_ERROR',
  UPDATE_SCREEN_TO_SCREEN_AMOUNTS_RECEIVABLE_PERIOD_START:
    '@updateScreenToScreenPeriod/UPDATE_SCREEN_TO_SCREEN_AMOUNTS_RECEIVABLE_PERIOD_START',
  UPDATE_SCREEN_TO_SCREEN_AMOUNTS_RECEIVABLE_PERIOD_SUCCESS:
    '@updateScreenToScreenPeriod/UPDATE_SCREEN_TO_SCREEN_AMOUNTS_RECEIVABLE_PERIOD_SUCCESS',
  UPDATE_SCREEN_TO_SCREEN_AMOUNTS_RECEIVABLE_PERIOD_ERROR:
    '@updateScreenToScreenPeriod/UPDATE_SCREEN_TO_SCREEN_AMOUNTS_RECEIVABLE_PERIOD_ERROR',
  UPDATE_SCREEN_TO_SCREEN_BANKS_PERIOD_START:
    '@updateScreenToScreenPeriod/UPDATE_SCREEN_TO_SCREEN_BANKS_PERIOD_START',
  UPDATE_SCREEN_TO_SCREEN_BANKS_PERIOD_SUCCESS:
    '@updateScreenToScreenPeriod/UPDATE_SCREEN_TO_SCREEN_BANKS_PERIOD_SUCCESS',
  UPDATE_SCREEN_TO_SCREEN_BANKS_PERIOD_ERROR:
    '@updateScreenToScreenPeriod/UPDATE_SCREEN_TO_SCREEN_BANKS_PERIOD_ERROR',
  UPDATE_SCREEN_TO_SCREEN_DUEDATE_PERIOD_START:
    '@updateScreenToScreenPeriod/UPDATE_SCREEN_TO_SCREEN_DUEDATE_PERIOD_START',
  UPDATE_SCREEN_TO_SCREEN_DUEDATE_PERIOD_SUCCESS:
    '@updateScreenToScreenPeriod/UPDATE_SCREEN_TO_SCREEN_DUEDATE_PERIOD_SUCCESS',
  UPDATE_SCREEN_TO_SCREEN_DUEDATE_PERIOD_ERROR:
    '@updateScreenToScreenPeriod/UPDATE_SCREEN_TO_SCREEN_DUEDATE_PERIOD_ERROR',
  UPDATE_SCREEN_TO_SCREEN_ADVANCEMENTS_PERIOD_START:
    '@updateScreenToScreenPeriod/UPDATE_SCREEN_TO_SCREEN_ADVANCEMENTS_PERIOD_START',
  UPDATE_SCREEN_TO_SCREEN_ADVANCEMENTS_PERIOD_SUCCESS:
    '@updateScreenToScreenPeriod/UPDATE_SCREEN_TO_SCREEN_ADVANCEMENTS_PERIOD_SUCCESS',
  UPDATE_SCREEN_TO_SCREEN_ADVANCEMENTS_PERIOD_ERROR:
    '@updateScreenToScreenPeriod/UPDATE_SCREEN_TO_SCREEN_ADVANCEMENTS_PERIOD_ERROR',
  UPDATE_SCREEN_TO_SCREEN_ADJUSTMENTS_PERIOD_START:
    '@updateScreenToScreenPeriod/UPDATE_SCREEN_TO_SCREEN_ADJUSTMENTS_PERIOD_START',
  UPDATE_SCREEN_TO_SCREEN_ADJUSTMENTS_PERIOD_SUCCESS:
    '@updateScreenToScreenPeriod/UPDATE_SCREEN_TO_SCREEN_ADJUSTMENTS_PERIOD_SUCCESS',
  UPDATE_SCREEN_TO_SCREEN_ADJUSTMENTS_PERIOD_ERROR:
    '@updateScreenToScreenPeriod/UPDATE_SCREEN_TO_SCREEN_ADJUSTMENTS_PERIOD_ERROR',
  UPDATE_SCREEN_TO_SCREEN_REFUNDS_PERIOD_START:
    '@updateScreenToScreenPeriod/UPDATE_SCREEN_TO_SCREEN_REFUNDS_PERIOD_START',
  UPDATE_SCREEN_TO_SCREEN_REFUNDS_PERIOD_SUCCESS:
    '@updateScreenToScreenPeriod/UPDATE_SCREEN_TO_SCREEN_REFUNDS_PERIOD_SUCCESS',
  UPDATE_SCREEN_TO_SCREEN_REFUNDS_PERIOD_ERROR:
    '@updateScreenToScreenPeriod/UPDATE_SCREEN_TO_SCREEN_REFUNDS_PERIOD_ERROR',
  UPDATE_SCREEN_TO_SCREEN_SALES_RECONCILIATION_PERIOD_START:
    '@updateScreenToScreenPeriod/UPDATE_SCREEN_TO_SCREEN_SALES_RECONCILIATION_PERIOD_START',
  UPDATE_SCREEN_TO_SCREEN_SALES_RECONCILIATION_PERIOD_SUCCESS:
    '@updateScreenToScreenPeriod/UPDATE_SCREEN_TO_SCREEN_SALES_RECONCILIATION_PERIOD_SUCCESS',
  UPDATE_SCREEN_TO_SCREEN_SALES_RECONCILIATION_PERIOD_ERROR:
    '@updateScreenToScreenPeriod/UPDATE_SCREEN_TO_SCREEN_SALES_RECONCILIATION_PERIOD_ERROR',
  UPDATE_SCREEN_TO_SCREEN_PAYMENTS_RECONCILIATION_PERIOD_START:
    '@updateScreenToScreenPeriod/UPDATE_SCREEN_TO_SCREEN_PAYMENTS_RECONCILIATION_PERIOD_START',
  UPDATE_SCREEN_TO_SCREEN_PAYMENTS_RECONCILIATION_PERIOD_SUCCESS:
    '@updateScreenToScreenPeriod/UPDATE_SCREEN_TO_SCREEN_PAYMENTS_RECONCILIATION_PERIOD_SUCCESS',
  UPDATE_SCREEN_TO_SCREEN_PAYMENTS_RECONCILIATION_PERIOD_ERROR:
    '@updateScreenToScreenPeriod/UPDATE_SCREEN_TO_SCREEN_PAYMENTS_RECONCILIATION_PERIOD_ERROR',
  UPDATE_SCREEN_TO_SCREEN_BANK_RECONCILIATION_PERIOD_START:
    '@updateScreenToScreenPeriod/UPDATE_SCREEN_TO_SCREEN_BANK_RECONCILIATION_PERIOD_START',
  UPDATE_SCREEN_TO_SCREEN_BANK_RECONCILIATION_PERIOD_SUCCESS:
    '@updateScreenToScreenPeriod/UPDATE_SCREEN_TO_SCREEN_BANK_RECONCILIATION_PERIOD_SUCCESS',
  UPDATE_SCREEN_TO_SCREEN_BANK_RECONCILIATION_PERIOD_ERROR:
    '@updateScreenToScreenPeriod/UPDATE_SCREEN_TO_SCREEN_BANK_RECONCILIATION_PERIOD_ERROR',
};
