import { actionTypes } from 'store/ducks/cnpjMaintenance/merchants/listMerchants/types';

const INITIAL_STATE = {
  error: undefined,
  errorMessage: undefined,
  loading: false,
  isError: false,
  success: false,
  hasMore: false,
  merchants: [],
  meta: undefined,
  total: 0,
  acquirerId: null,
};
const reducer = (state = INITIAL_STATE, action) => {
  let merchants = [];

  switch (action.type) {
    case actionTypes.LIST_MERCHANTS_START:
      if (Number(state?.acquirerId) === Number(action?.payload?.acquirerId)) {
        return {
          ...state,
          loading: true,
          error: undefined,
          errorMessage: undefined,
          success: false,
          isError: false,
          merchants: [...state.merchants],
        };
      }
      return {
        ...state,
        loading: true,
        error: undefined,
        errorMessage: undefined,
        success: false,
        isError: false,
        merchants: [],
      };
    case actionTypes.LIST_MERCHANTS_SUCCESS:
      if (state?.hasMore) {
        const previousAcquirerId = Number(state?.acquirerId);
        const incomingAcquirerId = Number(action?.payload?.acquirer_id);

        if (previousAcquirerId === incomingAcquirerId) {
          merchants = [
            ...new Set([...state.merchants, ...action.payload.merchants]),
          ];
        } else {
          merchants = action.payload.merchants;
        }

        return {
          ...state,
          loading: false,
          error: undefined,
          merchants,
          meta: action.payload.meta,
          total: action.payload.meta.total,
          hasMore: action.payload.meta.has_next,
          acquirerId: action.payload.meta.acquirer_id,
          isError: false,
          success: true,
          errorMessage: undefined,
        };
      }
      return {
        ...state,
        loading: false,
        error: undefined,
        merchants: action.payload.merchants,
        meta: action.payload.meta,
        total: action.payload.meta.total,
        hasMore: action.payload.meta.has_next,
        acquirerId: action.payload.meta.acquirer_id,
        isError: false,
        success: true,
        errorMessage: undefined,
      };
    case actionTypes.LIST_MERCHANTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error.error,
        errorMessage: action.payload,
        isError: true,
        success: false,
        merchants: undefined,
        meta: undefined,
      };

    case actionTypes.RESET_LIST_MERCHANTS_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.RESET_LIST_MERCHANTS_SUCCESS:
      return {
        ...state,
        loading: false,
        merchants: [],
      };
    case actionTypes.RESET_LIST_MERCHANTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error.error,
        errorMessage: action.payload,
        isError: true,
      };
    default:
      return state;
  }
};
export default reducer;
