export const actionTypes = {
  CONTRACT_FEE_FILTERS_START: '@contractFeeFilters/CONTRACT_FEE_FILTERS_START',
  CONTRACT_FEE_FILTERS_SUCCESS:
    '@contractFeeFilters/CONTRACT_FEE_FILTERS_SUCCESS',
  CONTRACT_FEE_FILTERS_ERROR: '@contractFeeFilters/CONTRACT_FEE_FILTERS_ERROR',
  CONTRACT_FEE_FILTERS_ZERO_LENGTH:
    '@contractFeeFilters/CONTRACT_FEE_FILTERS_ZERO_LENGTH',
  UPDATE_CONTRACT_FEE_FILTERS:
    '@contractFeeFilters/UPDATE_CONTRACT_FEE_FILTERS',
};
