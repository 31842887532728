import plans from './plans';

export const privateRoutes = [
  {
    path: '/dashboard',
    isActive: true,
    planPermission: [
      plans.FREE,
      plans.PRO,
      plans.PAG_GRATIS,
      plans.CONTROLE,
      plans.GERENCIE,
      plans.ADMINISTRE_PLUS,
    ],
  },
  {
    path: '/notificacoes',
    isActive: true,
    planPermission: [
      plans.FREE,
      plans.PRO,
      plans.PAG_GRATIS,
      plans.CONTROLE,
      plans.GERENCIE,
      plans.ADMINISTRE_PLUS,
    ],
  },
  {
    path: '/vendas-intermediaria',
    isActive: true,
    planPermission: [
      plans.FREE,
      plans.PRO,
      plans.PAG_GRATIS,
      plans.CONTROLE,
      plans.GERENCIE,
      plans.ADMINISTRE_PLUS,
    ],
  },
  {
    path: '/vendas-relatorios',
    isActive: true,
    planPermission: [
      plans.FREE,
      plans.PRO,
      plans.PAG_GRATIS,
      plans.CONTROLE,
      plans.GERENCIE,
      plans.ADMINISTRE_PLUS,
    ],
  },

  {
    path: '/valores-a-receber',
    isActive: true,
    planPermission: [
      plans.FREE,
      plans.PRO,
      plans.PAG_GRATIS,
      plans.CONTROLE,
      plans.GERENCIE,
      plans.ADMINISTRE_PLUS,
    ],
  },
  {
    path: '/valores-a-receber-relatorios',
    isActive: true,
    planPermission: [
      plans.FREE,
      plans.PRO,
      plans.PAG_GRATIS,
      plans.CONTROLE,
      plans.GERENCIE,
      plans.ADMINISTRE_PLUS,
    ],
  },
  {
    path: '/registro-de-implantacao',
    isActive: true,
    planPermission: [
      plans.PRO,
      plans.PAG_GRATIS,
      plans.CONTROLE,
      plans.GERENCIE,
      plans.ADMINISTRE_PLUS,
    ],
  },
  {
    path: '/manutencao-cnpj',
    isActive: true,
    planPermission: [
      plans.FREE,
      plans.PRO,
      plans.PAG_GRATIS,
      plans.CONTROLE,
      plans.GERENCIE,
      plans.ADMINISTRE_PLUS,
    ],
  },
  {
    path: '/planos',
    isActive: true,
    planPermission: [
      plans.FREE,
      plans.PRO,
      plans.PAG_GRATIS,
      plans.CONTROLE,
      plans.GERENCIE,
      plans.ADMINISTRE_PLUS,
    ],
  },
  {
    path: '/gerenciar-usuarios',
    isActive: true,
    planPermission: [
      plans.FREE,
      plans.PRO,
      plans.PAG_GRATIS,
      plans.CONTROLE,
      plans.GERENCIE,
      plans.ADMINISTRE_PLUS,
    ],
  },
  {
    path: '/gerenciar-empresas',
    isActive: true,
    planPermission: [
      plans.FREE,
      plans.PRO,
      plans.PAG_GRATIS,
      plans.CONTROLE,
      plans.GERENCIE,
      plans.ADMINISTRE_PLUS,
    ],
  },
  {
    path: '/consultar-acessos',
    isActive: true,
    planPermission: [
      plans.FREE,
      plans.PRO,
      plans.PAG_GRATIS,
      plans.CONTROLE,
      plans.GERENCIE,
      plans.ADMINISTRE_PLUS,
    ],
  },
  {
    path: '/gerenciar-implantacao',
    isActive: true,
    planPermission: [
      plans.FREE,
      plans.PRO,
      plans.PAG_GRATIS,
      plans.CONTROLE,
      plans.GERENCIE,
      plans.ADMINISTRE_PLUS,
    ],
  },
  {
    path: '/conciliacao-retroativa',
    isActive: true,
    planPermission: [
      plans.FREE,
      plans.PRO,
      plans.PAG_GRATIS,
      plans.CONTROLE,
      plans.GERENCIE,
      plans.ADMINISTRE_PLUS,
    ],
  },
  {
    path: '/radar',
    isActive: true,
    planPermission: [
      plans.FREE,
      plans.PRO,
      plans.PAG_GRATIS,
      plans.CONTROLE,
      plans.GERENCIE,
      plans.ADMINISTRE_PLUS,
    ],
  },
  {
    path: '/regras-conciliacao',
    isActive: true,
    planPermission: [
      plans.FREE,
      plans.PRO,
      plans.PAG_GRATIS,
      plans.CONTROLE,
      plans.GERENCIE,
      plans.ADMINISTRE_PLUS,
    ],
  },
  {
    path: '/regras-ofx',
    isActive: true,
    planPermission: [
      plans.FREE,
      plans.PRO,
      plans.PAG_GRATIS,
      plans.CONTROLE,
      plans.GERENCIE,
      plans.ADMINISTRE_PLUS,
    ],
  },
  {
    path: '/auditoria-pagamentos-intermediario',
    isActive: true,
    planPermission: [
      plans.PRO,
      plans.PAG_GRATIS,
      plans.CONTROLE,
      plans.GERENCIE,
      plans.ADMINISTRE_PLUS,
    ],
  },
  {
    path: '/auditoria-pagamentos-relatorios',
    isActive: true,
    planPermission: [
      plans.PRO,
      plans.PAG_GRATIS,
      plans.CONTROLE,
      plans.GERENCIE,
      plans.ADMINISTRE_PLUS,
    ],
  },
  {
    path: '/bancos-intermediario',
    isActive: true,
    planPermission: [
      plans.PRO,
      plans.PAG_GRATIS,
      plans.CONTROLE,
      plans.GERENCIE,
      plans.ADMINISTRE_PLUS,
    ],
  },
  {
    path: '/bancos-relatorios',
    isActive: true,
    planPermission: [
      plans.PRO,
      plans.PAG_GRATIS,
      plans.CONTROLE,
      plans.GERENCIE,
      plans.ADMINISTRE_PLUS,
    ],
  },

  {
    path: '/pagamentos-intermediario',
    isActive: true,
    planPermission: [
      plans.PRO,
      plans.PAG_GRATIS,
      plans.CONTROLE,
      plans.GERENCIE,
      plans.ADMINISTRE_PLUS,
    ],
  },
  {
    path: '/pagamentos-relatorios',
    isActive: true,
    planPermission: [
      plans.PRO,
      plans.PAG_GRATIS,
      plans.CONTROLE,
      plans.GERENCIE,
      plans.ADMINISTRE_PLUS,
    ],
  },
  {
    path: '/antecipacao-intermediario',
    isActive: true,
    planPermission: [
      plans.PRO,
      plans.PAG_GRATIS,
      plans.CONTROLE,
      plans.GERENCIE,
      plans.ADMINISTRE_PLUS,
    ],
  },
  {
    path: '/antecipacao-relatorios',
    isActive: true,
    planPermission: [
      plans.PRO,
      plans.PAG_GRATIS,
      plans.CONTROLE,
      plans.GERENCIE,
      plans.ADMINISTRE_PLUS,
    ],
  },
  {
    path: '/ajustes-intermediario',
    isActive: true,
    planPermission: [
      plans.PRO,
      plans.PAG_GRATIS,
      plans.CONTROLE,
      plans.GERENCIE,
      plans.ADMINISTRE_PLUS,
    ],
  },
  {
    path: '/ajustes-relatorios',
    isActive: true,
    planPermission: [
      plans.PRO,
      plans.PAG_GRATIS,
      plans.CONTROLE,
      plans.GERENCIE,
      plans.ADMINISTRE_PLUS,
    ],
  },
  {
    path: '/cancchbk-intermediario',
    isActive: true,
    planPermission: [
      plans.PRO,
      plans.PAG_GRATIS,
      plans.CONTROLE,
      plans.GERENCIE,
      plans.ADMINISTRE_PLUS,
    ],
  },
  {
    path: '/cancchbk-relatorios',
    isActive: true,
    planPermission: [
      plans.PRO,
      plans.PAG_GRATIS,
      plans.CONTROLE,
      plans.GERENCIE,
      plans.ADMINISTRE_PLUS,
    ],
  },

  {
    path: '/taxas',
    isActive: true,
    planPermission: [
      plans.PRO,
      plans.PAG_GRATIS,
      plans.CONTROLE,
      plans.GERENCIE,
      plans.ADMINISTRE_PLUS,
    ],
  },
  {
    path: '/cadastro-taxas',
    isActive: true,
    planPermission: [
      plans.PRO,
      plans.PAG_GRATIS,
      plans.CONTROLE,
      plans.GERENCIE,
      plans.ADMINISTRE_PLUS,
    ],
  },
  {
    path: '/conciliacao-pagamentos',
    isActive: true,
    planPermission: [
      plans.CONTROLE,
      plans.PAG_GRATIS,
      plans.GERENCIE,
      plans.ADMINISTRE_PLUS,
    ],
  },
  {
    path: '/conciliacao-pagamentos-relatorios',
    isActive: true,
    planPermission: [
      plans.CONTROLE,
      plans.PAG_GRATIS,
      plans.GERENCIE,
      plans.ADMINISTRE_PLUS,
    ],
  },
  {
    path: '/conciliacao-bancaria',
    isActive: true,
    planPermission: [plans.GERENCIE, plans.ADMINISTRE_PLUS],
  },
  {
    path: '/conciliacao-bancaria-relatorios',
    isActive: true,
    planPermission: [plans.GERENCIE, plans.ADMINISTRE_PLUS],
  },
  {
    path: '/importacao-arquivos',
    isActive: true,
    planPermission: [plans.GERENCIE, plans.ADMINISTRE_PLUS],
  },
  {
    path: '/analise-de-extrato-ofx-transacoes',
    isActive: true,
    planPermission: [plans.GERENCIE, plans.ADMINISTRE_PLUS],
  },
  {
    path: '/conciliacao-vendas',
    isActive: true,
    planPermission: [plans.ADMINISTRE_PLUS],
  },
  {
    path: '/conciliacao-vendas-relatorios',
    isActive: true,
    planPermission: [plans.ADMINISTRE_PLUS],
  },

  { path: '/taxas-indicadores', isActive: false, planPermission: [] }, // indicadores de taxas (inativo)
  { path: '/banking', isActive: false, planPermission: [] }, // ver ofertas (inativo)
  { path: '/autorizar-parceiro', isActive: false, planPermission: [] }, // autorizar parceiro (inativo)
];
