import styled from 'styled-components';
import { Card, Divider } from 'antd';
import { colors } from 'styles/colors';

export const StWrapper = styled.div`
  background-color: ${colors.gray1};
  border-radius: 4px;
  padding: 1rem;
  width: 100%;
`;

export const CardStyle = styled(Card)`
  &&& { 
    width: 100%;
    padding: 16px;
    border-radius: 4px;
    background-color: ${colors.gray1};
  }
`;

export const StDivider = styled(Divider)`
  &&& {
    width: '100%'
    margin-top: 2px;
    margin-bottom: 2px;
    padding: 0;
  }
`;
