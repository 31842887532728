import { notification } from 'antd';
import { call, put } from 'redux-saga/effects';
import deleteReconciliationRules from 'services/reconciliationRules/deleteReconciliationRules';
import store from 'store';
import {
  deleteReconciliationRulesError,
  deleteReconciliationRulesSuccess,
} from 'store/ducks/reconciliationRules/deleteReconciliationRules/actions';
import { listReconciliationRulesStart } from 'store/ducks/reconciliationRules/listReconciliationRules/actions';

export function* deleteReconciliationRulesSaga(action) {
  try {
    const { payload } = action;
    const { ruleId } = payload;
    const apiData = yield call(deleteReconciliationRules, payload);

    yield put(deleteReconciliationRulesSuccess(apiData));
    notification.success({ message: `Regra ${ruleId} deletada com sucesso!` });
    store.dispatch(
      listReconciliationRulesStart({
        sortFields: {},
        page: 1,
        perPage: 10,
        q: {
          filter: [],
        },
      })
    );
  } catch (err) {
    yield put(deleteReconciliationRulesError('Ocorreu um erro:', err));
  }
}
