import { action } from 'typesafe-actions';
import { actionTypes } from './types';

export const customUpdateFilteredColumnsStart = (data) =>
  action(actionTypes.CUSTOM_UPDATE_FILTERED_COLUMNS_START, data);
export const customUpdateFilteredColumnsSuccess = (data) =>
  action(actionTypes.CUSTOM_UPDATE_FILTERED_COLUMNS_SUCCESS, data);
export const customUpdateFilteredColumnsError = (errorMessage, error) =>
  action(
    actionTypes.CUSTOM_UPDATE_FILTERED_COLUMNS_ERROR,
    { errorMessage },
    undefined,
    { error }
  );

export const customResetUpdateFilteredColumnsStart = (data) =>
  action(actionTypes.CUSTOM_RESET_UPDATE_FILTERED_COLUMNS_START, data);
export const customResetUpdateFilteredColumnsSuccess = (data) =>
  action(actionTypes.CUSTOM_RESET_UPDATE_FILTERED_COLUMNS_SUCCESS, data);
export const customResetUpdateFilteredColumnsError = (errorMessage, error) =>
  action(
    actionTypes.CUSTOM_RESET_UPDATE_FILTERED_COLUMNS_ERROR,
    { errorMessage },
    undefined,
    {
      error,
    }
  );
