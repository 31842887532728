import { actionTypes } from 'store/ducks/details/feesDetails/types';

const INITIAL_STATE = {
  error: undefined,
  errorMessage: undefined,
  isLoading: false,
  isError: false,
  fees: [],
  meta: {
    page: 1,
    per_page: 10,
    total: 0,
    num_results: 0,
    filters: [],
    fee_status: '',
    has_next: false,
    sort_fields: {},
    br_cnpj_short_name: null,
  },
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.FEES_DETAILS_START:
      return {
        ...state,
        isLoading: true,
        error: undefined,
        isError: false,
        fees: [],
      };

    case actionTypes.FEES_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        fees: action.payload.fees,

        meta: action.payload.meta,
        success: true,
        isError: false,
        errorMessage: undefined,
      };

    case actionTypes.FEES_DETAILS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error.error,
        errorMessage: action.payload,
        isError: true,
        success: false,
        fees: [],
      };
    default:
      return state;
  }
};

export default reducer;
