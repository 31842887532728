import { call, put } from 'redux-saga/effects';
import errorHandler from 'helpers/errorHandler';
import getSalesInfoFiltersFromAPI from 'services/filters/reconciledSalesInfoFilters';
import {
  reconciledSalesInfoFiltersSuccess,
  reconciledSalesInfoFiltersError,
} from './actions';

export function* reconciledSalesInfoFiltersSaga(action) {
  const { payload } = action;

  try {
    const apiData = yield call(getSalesInfoFiltersFromAPI, payload);
    yield put(reconciledSalesInfoFiltersSuccess(apiData));
  } catch (err) {
    if (
      err?.response?.data?.error &&
      err?.response?.data?.error?.type &&
      err?.response?.data?.error?.message
    ) {
      yield put(
        reconciledSalesInfoFiltersError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    }
  }
}
