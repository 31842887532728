import { api } from './api';

const transformer = (apiResponse) => {
  const responseUserData = apiResponse.data.user_data;
  const userData = {};
  if (responseUserData !== undefined) {
    if (
      Object.keys(responseUserData).length !== 0 &&
      responseUserData.constructor === Object
    ) {
      userData.name = responseUserData.name;
      userData.surname = responseUserData.surname;
      userData.scopeId = responseUserData.scope_id;
      userData.profileId = responseUserData.profile_id;
      userData.phone = responseUserData.phone;
    }
  }
  return {
    hasUserClientAssociation: apiResponse.data.has_user_client_association,
    userData,
  };
};

const checkInvitationEmailFromAPI = async ({ to }) => {
  const config = {
    params: {
      to,
    },
  };
  const response = await api().get('/invitation', config);
  return transformer(response.data);
};

export default checkInvitationEmailFromAPI;
