export const actionTypes = {
  LIST_OF_CLIENTS_FILTERS_START:
    '@listOfClientsFilters/LIST_OF_CLIENTS_FILTERS_START',
  LIST_OF_CLIENTS_FILTERS_SUCCESS:
    '@listOfClientsFilters/LIST_OF_CLIENTS_FILTERS_SUCCESS',
  LIST_OF_CLIENTS_FILTERS_ZERO_LENGTH:
    '@listOfClientsFilters/LIST_OF_CLIENTS_FILTERS_ZERO_LENGTH',
  LIST_OF_CLIENTS_FILTERS_ERROR:
    '@listOfClientsFilters/LIST_OF_CLIENTS_FILTERS_ERROR',
  UPDATE_LIST_OF_CLIENTS_FILTERS:
    '@listOfClientsFilters/UPDATE_LIST_OF_CLIENTS_FILTERS',
};
