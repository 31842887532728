import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'antd';
import { StContainer, StTabs } from './styled';
import { CnpjStoresList } from '../CnpjStoresList';
import { CnpjAcquirersList } from '../CnpjAcquirersList';

const ContentTabs = ({
  onTabClick,
  activeTab,
  isNextButtonLocked,
  setIsNextButtonLocked,
  searchFilter,
  setSearchFilter,
}) => {
  const { useBreakpoint } = Grid;
  const breakpoint = useBreakpoint();

  const items = [
    {
      label: 'Lojas',
      key: 'stores',
      children: activeTab === 'stores' && (
        <CnpjStoresList
          searchFilter={searchFilter}
          setSearchFilter={setSearchFilter}
        />
      ),
    },
    {
      label: 'Adquirentes',
      key: 'acquirers',
      children: activeTab === 'acquirers' && (
        <CnpjAcquirersList
          searchFilter={searchFilter}
          setSearchFilter={setSearchFilter}
          isNextButtonLocked={isNextButtonLocked}
          setIsNextButtonLocked={setIsNextButtonLocked}
        />
      ),
    },
  ];

  return (
    <StContainer $breakpoint={breakpoint}>
      <StTabs
        items={items}
        onTabClick={onTabClick}
        tabBarStyle={{
          fontSize: '12px',
          width: '100%',
        }}
      />
    </StContainer>
  );
};

ContentTabs.propTypes = {
  onTabClick: PropTypes.func.isRequired,
  activeTab: PropTypes.string.isRequired,
  setIsNextButtonLocked: PropTypes.func.isRequired,
};

export default ContentTabs;
