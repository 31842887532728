/* eslint-disable dot-notation */
import { isCpfOrCnpj } from 'helpers/generalService';
import { api, lambdaApi } from './api';

const transformer = (apiResponse) => {
  const stores = apiResponse.data?.stores;
  stores.forEach((store) => {
    if (store?.br_cnpj) {
      if (!isCpfOrCnpj(store?.br_cnpj)) {
        // eslint-disable-next-line no-param-reassign
        store.br_cnpj = '';
      }
    }
  });
  return {
    stores: apiResponse.data.stores,
  };
};

export const getStores = async ({ data }) => {
  const { sort } = data;
  const config = {
    params: {
      sort_fields: sort,
    },
  };

  const response = await lambdaApi().get('/briefing/store', config);
  return transformer(response.data);
};

export const postStore = async (payload) => {
  const response = await lambdaApi().post('/cnpj/create/post', payload);
  return response.data;
};

export const putStore = async () => {};

export const deleteStore = async ({ data }) => {
  const response = await api().delete(`/store?store_id=${data}`);
  return response.data;
};
