import styled from 'styled-components';
import { Card } from 'antd';

export const CardStyle = styled(Card)`
  display: inline-block;
  float: none;
  width: 26rem;
  margin: 0 2rem 0 2rem;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.04);
`;
