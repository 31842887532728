import styled from 'styled-components';
import { colors } from 'styles/colors';
import { InputInteger } from 'commons/InputInteger';
import { Button, Col, Divider, Row, Space } from 'antd';

export const StWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 25px;
  border-radius: 5px;
  color: ${colors.gray9};
  background-color: ${colors.gray1};
`;

export const StProductName = styled.span`
  font-size: 20px;
  font-weight: 500;
  ${colors.gray9};
`;

export const StProductDescription = styled.span`
  margin-bottom: ${({ $canInstallments }) => ($canInstallments ? '25px' : '0')};
`;

export const StButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-end;
  margin: 25px 0 50px;
`;

export const StCancelButton = styled(Button)`
  margin-right: 15px;
`;

export const StRowHeader = styled(Row)`
  margin-top: 32px;
`;

export const StColumnHeader = styled(Col)`
  font-size: 14px;
  font-weight: 500;
  color: ${colors.gray9};
`;

export const StCheckboxColumn = styled(Col)`
  display: flex;
  justify-content: center;
`;

export const StBrandName = styled.input`
  padding: 0;
  color: ${colors.gray9};
  background: none;
  border: none;
  cursor: pointer;
`;

export const StDivider = styled(Divider)`
  margin: 20px 0;
`;

export const StPlanSpace = styled(Space)`
  display: ${({ $canInstallments }) => ($canInstallments ? 'flex' : 'none')};
  flex-direction: column;
  padding: 12px 16px;
  background-color: ${colors.primary1};
`;

export const StFieldContainer = styled.div`
  ${({ $canInstallments }) =>
    $canInstallments &&
    `
      margin-bottom: 25px;
      border: 1px solid ${colors.primary4};
      border-radius: 2px;
    `}
`;

export const StBrandContainer = styled.div`
  margin-bottom: 30px;
`;

export const StPlansContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StPlanLabel = styled.span`
  font-weight: 500;
  font-size: 14px;
  margin: 0 8px;
`;

export const StIntervalLabel = styled.span`
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 1.5px;
  color: ${colors.primary7};
`;

export const StPlanInput = styled(InputInteger)`
  width: 60px;
`;

export const StDeleteIntervalButton = styled(Button)`
  margin-left: 8px;
`;
