import { action } from 'typesafe-actions';
import { actionTypes } from './types';

export const feesDetailsFiltersStart = (data) =>
  action(actionTypes.FEES_DETAILS_FILTERS_START, data);
export const feesDetailsFiltersSuccess = (data) =>
  action(actionTypes.FEES_DETAILS_FILTERS_SUCCESS, data);
export const feesDetailsFiltersError = (errorMessage, error) =>
  action(actionTypes.FEES_DETAILS_FILTERS_ERROR, { errorMessage }, undefined, {
    error,
  });
export const feesDetailsFiltersZeroLength = () =>
  action(actionTypes.FEES_DETAILS_FILTERS_ZERO_LENGTH);
export const updateFeesDetailsFilters = (data) =>
  action(actionTypes.UPDATE_FEES_DETAILS_FILTERS, data);
