import { Modal, Row } from 'antd';
import styled from 'styled-components';
import { colors } from 'styles/colors';

export const StModal = styled(Modal)`
  .ant-modal-header {
    border: none;
  }

  .ant-modal-body {
    padding: 0;
  }
`;

export const ModalContent = styled.div`
  width: 100%;
  height: 210px;
  padding: 32px 32px 24px;
`;

export const StContentContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
`;

export const HeaderContainer = styled(Row)`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;

  span {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: ${colors.gray9};
  }
`;

export const StIconContainer = styled.div`
  svg {
    font-size: 21px;
    color: ${({ $modalType }) =>
      $modalType === 'success'
        ? colors.green6
        : $modalType === 'warning'
          ? colors.gold6
          : $modalType === 'error'
            ? colors.red5
            : colors.gray9};
  }
`;

export const StDescriptionContainer = styled(Row)`
  margin-left: 36px;

  span {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: ${colors.gray9};
  }
`;

export const StButtonContainer = styled(Row)`
  position: absolute;
  bottom: 0px;
  right: 0px;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;

export const StSpinContainer = styled(Row)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
