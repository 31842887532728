import { Button, Col, Form, Row, Select } from 'antd';
import { perPageOptions, showTotalPage } from 'constants/perPageOptions';
import {
  getSessionClientId,
  getSessionClientName,
} from 'helpers/sessionService';
import { onColumnSort } from 'helpers/sorter';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateReconciliationRulesDetailsFilters } from 'store/ducks/filters/details/reconciliationRulesDetailsFilters/actions';
import { listOfClientsStart } from 'store/ducks/listOfClients/actions';
import { createNotificationStart } from 'store/ducks/notifications/actions';
import { listReconciliationRulesStart } from 'store/ducks/reconciliationRules/listReconciliationRules/actions';
import { colors } from 'styles/colors';
import { useResetFilters } from 'utils/hooks/useResetFilters';
import { exportFileParser } from 'utils/parsers/exportFileParser';
import { Tables } from './components/Tables';
import { StForm, StRecRulesTable, StSearchTitle, StText } from './styled';
import { columns } from './utils';
import { shortId } from 'helpers/shortId';
import {
  handleReconGroupType,
  handleStatusBr,
  translateGroupOfRules,
} from './utils/handleColumnContent';

// Functions
const ReconciliationRules = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const permissionsData = useSelector((state) => state.permissions.data);
  const clientId = getSessionClientId();
  const clientName = getSessionClientName();
  const {
    user_id: userId,
    scope_id: scopeId,
    profile_id: profileId,
  } = permissionsData;
  const [localFilters, setLocalFilters] = useState([]);
  const [dropdownOption, setDropdownOption] = useState('');
  const [sortFields, setSortFields] = useState({});
  const [perPage, setPerPage] = useState(10);
  const [pageTotal, setPageTotal] = useState(100);
  const [pageCurrent, setPageCurrent] = useState(1);

  const toParamsFilters = useSelector(
    (state) => state.reconciliationRulesDetailsFilters.toParamsFilters
  );

  const _filterQ = [];
  const handleSearch = (value, inputColumnName) => {
    // Quando é válido e não existe
    if (
      value !== undefined &&
      !_filterQ.some((el) => el.name === inputColumnName)
    ) {
      _filterQ.push({
        name: inputColumnName,
        val: [value],
      });
    }

    // Quando é válido e já existe (p/ atualizar)
    if (
      value !== undefined &&
      _filterQ.some((el) => el.name === inputColumnName)
    ) {
      // eslint-disable-next-line array-callback-return
      _filterQ.find((el, index) => {
        if (el.name === inputColumnName) {
          _filterQ.splice(index, 1, {
            name: inputColumnName,
            val: [value],
          });
        }
      });
    }

    // Quando é inválido ou vazio (p/ remover)
    if (value === undefined || value === '') {
      // eslint-disable-next-line consistent-return
      _filterQ.forEach((el, index) => {
        if (el.name === inputColumnName) {
          return _filterQ.splice(index, 1);
        }
      });
    }

    return _filterQ;
  };

  const { resetFilters } = useResetFilters();

  useEffect(() => {
    resetFilters(updateReconciliationRulesDetailsFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = (values) => {
    Object.entries(values).forEach(([key, value]) => {
      if (key === 'groupOfRules') {
        handleSearch(translateGroupOfRules(value), 'recon_type');
      }
      if (key === 'groupOfStatus') {
        handleSearch(handleStatusBr(value), 'is_active');
      }
      if (key === 'groupTypeOfRules') {
        handleSearch(handleReconGroupType(value), 'group_type');
      }
      if (key === 'searchText') {
        handleSearch(value, 'client_name_code');
      }
    });

    dispatch(updateReconciliationRulesDetailsFilters(_filterQ));

    dispatch(
      listReconciliationRulesStart({
        sortFields,
        page: 1,
        perPage: 10,
        q: {
          filter: _filterQ,
        },
      })
    );
  };

  const listReconciliationRulesLoading = useSelector(
    (state) => state.listReconciliationRules?.loading
  );
  const listReconciliationRulesDetails = useSelector(
    (state) => state.listReconciliationRules?.details
  );

  const listReconciliationRulesMeta = useSelector(
    (state) => state.listReconciliationRules?.meta
  );

  const listReconciliationRulesError = useSelector(
    (state) => state.listReconciliationRules?.isError
  );
  const deleteReconciliationRulesLoading = useSelector(
    (state) => state.deleteReconciliationRules.loading
  );
  const listOfClients = useSelector((state) => state.listOfClients?.allClients);
  const listOfClientsConverted = useMemo(
    () =>
      listOfClients?.map((client) => {
        return `${client.client_code} - ${client.name}`;
      }),
    [listOfClients]
  );
  const { Option } = Select;
  const groupOfRulesFiltersToSelect = ['Vendas', 'Pagamentos'];
  const groupTypeOfRulesFiltersToSelect = ['1-1', 'N-N'];
  const groupOfStatusFiltersToSelect = ['Ativo', 'Inativo'];

  const filtersData = useSelector(
    (state) => state.reconciliationRulesDetailsFilters.filters
  );

  const [columnNames] = useState([
    'ID',
    'Grupo',
    'Tipo',
    'Status',
    'Categoria',
    'Código e nome da empresa',
    'Ordem',
    'Descrição',
    'Ações',
  ]);

  const dataSource = listReconciliationRulesDetails?.map((obj) => ({
    ...obj,
    key: shortId(),
  }));

  useEffect(() => {
    if (pageCurrent && perPage) {
      dispatch(
        listReconciliationRulesStart({
          sortFields,
          page: pageCurrent,
          perPage,
          q: {
            filter: toParamsFilters,
          },
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, pageCurrent, perPage, sortFields]);

  useEffect(() => {
    setPerPage(listReconciliationRulesMeta.per_page);
    setPageTotal(listReconciliationRulesMeta.total);
    setPageCurrent(listReconciliationRulesMeta.page);
  }, [listReconciliationRulesMeta]);

  useEffect(() => {
    dispatch(
      listOfClientsStart({
        page: 1,
        perPage,
        isReconciliationRules: true,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onTablePaginationOrFiltersOrSorterChange = (
    pagination,
    tableFilters,
    sorter
  ) => {
    onColumnSort(sorter, setSortFields);
    setLocalFilters(tableFilters);
    setPerPage(pagination.pageSize);
    setPageTotal(listReconciliationRulesMeta.total);
    setPageCurrent(pagination.current);
  };

  const fileName =
    'relatorio_regras_conciliacao.' +
    (dropdownOption === 'excel' ? 'xlsx' : 'csv');

  const notificationConfig = {
    clientId,
    userId,
    status: 'PREPARANDO',
    title: 'Preparando arquivo',
    isRead: false,
    isSendEmail: false,
    fileName,
    isExpired: false,
    createdBy: userId,
    updatedBy: userId,
    message: `Estamos preparando seu arquivo ${fileName} para o download.`,
    table: 'conciliation-rule',
    fileType: dropdownOption === 'excel' ? 'xlsx' : 'csv',
    scopeId,
    profileId,
    q: {
      filter: exportFileParser(toParamsFilters),
    },
    sortFields: '',
    clientName,
  };

  return (
    <>
      <StForm>
        <Row align="middle" justify="space-between">
          <Col>
            <StSearchTitle>Regras de conciliação</StSearchTitle>
          </Col>
        </Row>

        <Row>
          <Col>
            <StText style={{ color: colors.gray7 }}>
              Adicione, edite e pesquise regras de conciliação de vendas e
              pagamento que serão aplicadas na plataforma
            </StText>
          </Col>
        </Row>

        <Row
          gutter={[16, 0]}
          style={{
            marginTop: '24px',
            alignItems: 'end',
            width: '100%',
          }}
        >
          <Form
            name="reconciliationRulesForm"
            form={form}
            onFinish={onFinish}
            layout="vertical"
            style={{ display: 'flex', width: '100%' }}
            initialValues={{
              searchText: '',
              groupOfRules: '',
              groupTypeOfRules: '',
              groupOfStatus: '',
            }}
          >
            <Col span={6}>
              <Form.Item label="Código ou nome da empresa" name="searchText">
                <Row>
                  <Select
                    showSearch
                    allowClear
                    onChange={(e) => {
                      handleSearch(e, 'client_name_code');
                      form.setFieldsValue({
                        searchText: e,
                      });
                    }}
                    placeholder="Digite o código ou nome da empresa"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {listOfClientsConverted.map((item) => (
                      <Option key={shortId()} value={item}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                </Row>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Grupo de regras" name="groupOfRules">
                <Row>
                  <Select
                    showSearch
                    allowClear
                    onChange={(e) => {
                      handleSearch(translateGroupOfRules(e), 'recon_type');
                      form.setFieldsValue({
                        groupOfRules: e,
                      });
                    }}
                    style={{ width: '100%' }}
                    placeholder="Selecione o grupo"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {groupOfRulesFiltersToSelect.map((item) => (
                      <Option key={shortId()} value={item}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                </Row>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Tipo de regra" name="groupTypeOfRules">
                <Row>
                  <Select
                    showSearch
                    allowClear
                    onChange={(e) => {
                      handleSearch(handleReconGroupType(e), 'group_type');
                      form.setFieldsValue({
                        groupTypeOfRules: e,
                      });
                    }}
                    style={{ width: '100%' }}
                    placeholder="Selecione o tipo"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {groupTypeOfRulesFiltersToSelect.map((item) => (
                      <Option key={shortId()} value={item}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                </Row>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Status" name="groupOfStatus">
                <Row>
                  <Select
                    showSearch
                    allowClear
                    onChange={(e) => {
                      handleSearch(handleStatusBr(e), 'is_active');
                      form.setFieldsValue({
                        groupOfStatus: e,
                      });
                    }}
                    style={{ width: '100%' }}
                    placeholder="Selecione o status"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {groupOfStatusFiltersToSelect.map((item) => (
                      <Option key={shortId()} value={item}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                </Row>
              </Form.Item>
            </Col>
            <Col
              span={6}
              style={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'end',
              }}
            >
              <Form.Item>
                <Row>
                  <Button type="primary" htmlType="submit">
                    Pesquisar
                  </Button>
                  <Button
                    type="ghost"
                    htmlType="button"
                    onClick={() => {
                      form.resetFields();
                      dispatch(updateReconciliationRulesDetailsFilters([]));
                      dispatch(
                        listReconciliationRulesStart({
                          sortFields,
                          page: 1,
                          perPage: 10,
                          q: {
                            filter: [],
                          },
                        })
                      );
                    }}
                    style={{ marginLeft: '8px' }}
                  >
                    Limpar filtros
                  </Button>
                </Row>
              </Form.Item>
            </Col>
          </Form>
        </Row>
      </StForm>
      <StRecRulesTable>
        <Tables
          dataSource={dataSource}
          columns={columns(
            filtersData,
            localFilters,
            toParamsFilters,
            sortFields,
            pageCurrent,
            perPage
          )}
          toParamsFilters={toParamsFilters}
          localFilters={localFilters}
          loading={
            listReconciliationRulesLoading || deleteReconciliationRulesLoading
          }
          error={listReconciliationRulesError}
          createNotificationAction={createNotificationStart}
          notificationConfig={notificationConfig}
          dropdownOption={dropdownOption}
          setDropdownOption={setDropdownOption}
          pagination={{
            current: pageCurrent,
            pageSize: perPage,
            total: pageTotal,
            pageSizeOptions: perPageOptions,
            showTotal: showTotalPage,
            showSizeChanger: pageTotal,
          }}
          onChange={onTablePaginationOrFiltersOrSorterChange}
          defaultSelectedColumns={columnNames}
        />
      </StRecRulesTable>
    </>
  );
};

export default ReconciliationRules;
