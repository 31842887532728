import React from 'react';
import { Grid, Row } from 'antd';
import { StPlansContainer } from './styled';
import { PlansContainer } from './components/PlansContainer/PlansContainer';
import { PlanComparison } from './components/PlanComparison/PlanComparison';
import { PlansHeader } from './components/PlansHeader/PlansHeader';

export const Plans = () => {
  const { useBreakpoint } = Grid;
  const breakpoint = useBreakpoint();
  const dataTestPrefix = 'plans';

  return (
    <StPlansContainer $breakpoint={breakpoint}>
      <Row>
        <PlansHeader dataTestPrefix={dataTestPrefix} />
      </Row>
      <Row>
        <PlansContainer dataTestPrefix={dataTestPrefix} />
      </Row>
      <Row>
        <PlanComparison dataTestPrefix={dataTestPrefix} />
      </Row>
    </StPlansContainer>
  );
};
