import { call, put } from 'redux-saga/effects';
import errorHandler from 'helpers/errorHandler';
import getBankConciliationFiltersFromAPI from 'services/filters/details/bankConciliationFilters';

import {
  bankConciliationFiltersSuccess,
  bankConciliationFiltersError,
} from './actions';

export function* bankConciliationFiltersSaga(action) {
  const { payload } = action;
  try {
    const apiData = yield call(getBankConciliationFiltersFromAPI, payload);
    yield put(bankConciliationFiltersSuccess(apiData));
  } catch (err) {
    yield put(bankConciliationFiltersError('Ocorreu um erro:', err));
    errorHandler('Ocorreu um erro:', err);
  }
}
