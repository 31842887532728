import styled from 'styled-components';
import { Button, Col, Row } from 'antd';
import { colors } from 'styles/colors';

export const StContainer = styled(Row)`
  &&& {
  }
`;

export const StCol = styled(Col)`
  margin-right: 0.9rem;
`;

export const StButton = styled(Button)`
  width: 14rem;
  height: 4rem;
  margin: 0 0 0 0.867rem;
  padding: 0.625rem 1.5rem;
  border-radius: 4px;
`;

export const StTitle = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
`;

export const StMainTitle = styled(StTitle)`
  margin-left: 3.8rem;
  font-size: 3.75rem;
  font-weight: 600;
  color: ${colors.gray9};
`;

export const StSubtitle = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
`;
