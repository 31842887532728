import styled from 'styled-components';

import { Typography } from 'antd';
import { colors } from 'styles/colors';

export const StPartnerIcon = styled.img`
  &&& {
    width: 24px;
    height: 24px;
  }
`;

export const StPartnerName = styled(Typography.Text)`
  &&& {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: ${colors.gray9};
  }
`;
