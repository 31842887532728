import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { acquirerStart } from 'store/ducks/acquirer/actions';

export const useAcquirer = () => {
  const dispatch = useDispatch();
  const acquirers = useSelector((state) => state.acquirer.acquirers);
  const loading = useSelector((state) => state.acquirer.loading);

  const findAcquirerNameById = useCallback(
    (acquirerId = '') => {
      if (acquirers) {
        const acquirer = acquirers?.find(
          (acq) => acq.acquirer_id === acquirerId
        );
        return acquirer?.name || '';
      }
      return '';
    },
    [acquirers]
  );

  const getAllAcquirers = (all = false, isPixRequired = false, clientId) =>
    dispatch(acquirerStart({ all, clientId, isPixRequired }));

  return { acquirers, findAcquirerNameById, loading, getAllAcquirers };
};
