import { call, put, delay } from 'redux-saga/effects';
import errorHandler from 'helpers/errorHandler';
import getImportFilesFiltersFromAPI from 'services/filters/importFilesFilters';
import { importFilesFiltersSuccess, importFilesFiltersError } from './actions';
import { errorToShow } from 'store/ducks/errorToShow/actions';

export function* importFilesFiltersSaga(action) {
  const { payload } = action;
  try {
    const apiData = yield call(getImportFilesFiltersFromAPI, payload);
    yield delay(500);
    yield put(importFilesFiltersSuccess(apiData));
  } catch (err) {
    if (
      err?.response?.data?.error &&
      err?.response?.data?.error?.type &&
      err?.response?.data?.error?.message
    ) {
      yield put(
        errorToShow({
          message: err.response.data.error.message,
          type: err.response.data.error.type,
        })
      );
      yield put(
        importFilesFiltersError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    }
  }
}
