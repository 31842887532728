import { action } from 'typesafe-actions';
import { actionTypes } from './types';

export const searchTransactionDetailsFiltersStart = (data) =>
  action(actionTypes.SEARCH_TRANSACTION_DETAILS_FILTERS_START, data);
export const searchTransactionDetailsFiltersSuccess = (data) =>
  action(actionTypes.SEARCH_TRANSACTION_DETAILS_FILTERS_SUCCESS, data);
export const searchTransactionDetailsFiltersError = (errorMessage, error) =>
  action(
    actionTypes.SEARCH_TRANSACTION_DETAILS_FILTERS_ERROR,
    { errorMessage },
    undefined,
    { error }
  );
export const searchTransactionDetailsFiltersZeroLength = () =>
  action(actionTypes.SEARCH_TRANSACTION_DETAILS_FILTERS_ZERO_LENGTH);
export const updateSearchTransactionDetailsFilters = (data) =>
  action(actionTypes.UPDATE_SEARCH_TRANSACTION_DETAILS_FILTERS, data);
