import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';

export const Chart = css`
  max-height: 100px;
  width: 5px;
  &:hover {
    opacity: 0.8;
  }
`;

export const StReconciliationBar = styled.div`
  /* min-height: 75px; */

  height: ${({ height }) => height + 'px'};
  background-color: ${({ color }) => color};

  border-radius: 0px;
  ${Chart};
`;

export const StTextLabel = styled.span`
  color: ${colors.gray8};
  font-size: 12px;
`;
