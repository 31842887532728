export const actionTypes = {
  RADAR_DETAILS_FILTERS_START:
    '@radarDetailsFilters/RADAR_DETAILS_FILTERS_START',
  RADAR_DETAILS_FILTERS_SUCCESS:
    '@radarDetailsFilters/RADAR_DETAILS_FILTERS_SUCCESS',
  RADAR_DETAILS_FILTERS_ERROR:
    '@radarDetailsFilters/RADAR_DETAILS_FILTERS_ERROR',
  RADAR_DETAILS_FILTERS_ZERO_LENGTH:
    '@radarDetailsFilters/RADAR_DETAILS_FILTERS_ZERO_LENGTH',
  UPDATE_RADAR_DETAILS_FILTERS:
    '@radarDetailsFilters/UPDATE_RADAR_DETAILS_FILTERS',
};
