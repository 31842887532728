import { actionTypes } from 'store/ducks/listOfClients/types';

const INITIAL_STATE = {
  error: undefined,
  errorMessage: undefined,
  loading: false,
  isError: false,
  allClients: [], // used for ReconciliationRules page
  clients: [],
  clientSelect: [],
  hasMore: true,
  currentClient: {},
  total: 0,
  perPage: 0,
  page: 0,
};

const reducer = (state = INITIAL_STATE, action) => {
  let clients = [];
  const foundNoMoreResults = state.clients.find(
    (c) => c.name === 'Não há mais resultados.'
  );
  switch (action.type) {
    case actionTypes.LIST_OF_CLIENTS_START:
      return {
        ...state,
        loading: true,
        error: undefined,
        errorMessage: undefined,
        isError: false,
      };
    case actionTypes.LIST_OF_CLIENTS_SUCCESS:
      if (action.payload.isReconciliationRules) {
        clients = action.payload.clients.slice(0, 50);
      } else {
        clients =
          action.payload.filter === '' &&
          !foundNoMoreResults &&
          !action.payload.shouldReplace
            ? [...state.clients, ...action.payload.clients]
            : action.payload.clients;
      }
      return {
        ...state,
        loading: false,
        error: undefined,
        currentClient: action.payload.currentClient,
        clients,
        allClients: action.payload.isReconciliationRules
          ? action.payload.clients
          : state.allClients,
        perPage: action.payload.perPage,
        page: action.payload.page,
        hasMore: action.payload.hasMore,
        total: action.payload.total,
        isError: false,
        errorMessage: undefined,
      };
    case actionTypes.LIST_OF_CLIENTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error.error,
        errorMessage: action.payload,
        isError: true,
        clients: [],
      };
    default:
      return state;
  }
};

export default reducer;
