import { lambdaApi } from 'services/api';

const transformer = (apiResponse) => {
  const filters = {
    stores: apiResponse.data.stores,
    meta: apiResponse.meta,
  };

  return filters;
};

const getCnpjMaintenanceInfoFiltersFromAPI = async ({
  filter,
  offset,
  limit,
}) => {
  const config = {
    params: {
      offset,
      limit,
      q: JSON.stringify(filter),
    },
  };
  const response = await lambdaApi().get(
    '/settings/maintenance/stores/filter',
    config
  );

  return transformer(response.data);
};

export default getCnpjMaintenanceInfoFiltersFromAPI;
