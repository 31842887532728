import store from 'store';
import { api } from '../api';

const transformer = (apiResponse) => {
  const data = {
    details: apiResponse.data.payments,
    meta: apiResponse.meta,
    netAmount: apiResponse.data.total_net_amount,
  };

  return data;
};

const getRefundsDetailsFromAPI = async ({ sortFields, page, perPage, q }) => {
  const config = {
    params: {
      start_date: store
        .getState()
        .updateScreenToScreenDate?.startDate.format('YYYY-MM-DD'),
      end_date: store
        .getState()
        .updateScreenToScreenDate?.endDate.format('YYYY-MM-DD'),
      page,
      per_page: perPage,
      sort_fields: JSON.stringify(sortFields),
      q: JSON.stringify(q),
    },
  };
  const response = await api().get('/detail/payments/refunds', config);
  return transformer(response.data);
};

export default getRefundsDetailsFromAPI;
