import React from 'react';
import { Grid } from 'antd';
import { StContainer } from 'commons/Content/styled';
import { colors } from 'styles/colors';
import { SelectedStores } from './components/SelectedStores';
import { FeeForm } from './components/FeeForm';
import { Navigate, useLocation } from 'react-router-dom';
import FeeHeader from './components/FeeHeader/FeeHeader';

export const CreateFees = () => {
  const { useBreakpoint } = Grid;
  const breakpoint = useBreakpoint();
  const { state: data = {} } = useLocation();
  const hasData = !!data && Object.keys(data).length > 0;

  if (!hasData) {
    return <Navigate to="/taxas" />;
  }

  return (
    hasData && (
      <>
        <StContainer
          $breakpoint={breakpoint}
          style={{
            margin: 0,
            backgroundColor: colors.gray1,
            padding: '72px 64px 12px',
          }}
        >
          <FeeHeader />
        </StContainer>
        <StContainer
          $breakpoint={breakpoint}
          style={{
            marginTop: 20,
            padding: '0 24px 0',
          }}
        >
          <SelectedStores
            acquirerId={data?.acquirerId}
            acquirerName={data?.acquirerName}
            acquirerIcon={data?.acquirerIcon}
            stores={data?.selectedMerchants}
            isAllStores={data?.isAllMerchant}
          />
        </StContainer>
        <StContainer
          $breakpoint={breakpoint}
          style={{
            marginTop: 20,
            padding: '0 24px 0',
          }}
        >
          <FeeForm data={data} />
        </StContainer>
      </>
    )
  );
};
