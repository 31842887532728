import { actionTypes } from 'store/ducks/searchTransaction/types';

const INITIAL_STATE = {
  error: undefined,
  errorMessage: undefined,
  loading: false,
  isError: false,
  details: [],
  searchOption: '',
  searchText: '',
  success: false,
  meta: {
    current_page: 1,
    num_results: 1000,
    results_per_page: 10,
    total_pages: 100,
  },
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case actionTypes.SEARCH_TRANSACTION_START:
    return {
      ...state,
      loading: true,
      searchOption: action.payload.searchOption,
      searchText: action.payload.searchText,
      error: undefined,
      errorMessage: undefined,
      success: false,
      isError: false,
    };
  case actionTypes.SEARCH_TRANSACTION_SUCCESS:
    return {
      ...state,
      loading: false,
      error: undefined,
      details: action.payload.details,
      meta: action.payload.meta,
      isError: false,
      success: true,
      errorMessage: undefined,
    };
  case actionTypes.SEARCH_TRANSACTION_ERROR:
    return {
      ...state,
      loading: false,
      error: action.error.error,
      errorMessage: action.payload,
      isError: true,
      success: false,
      details: undefined,
    };
  default:
    return state;
  }
};

export default reducer;
