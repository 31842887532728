import { actionTypes } from 'store/ducks/unfilteredGeneralListOfClients/types';

const INITIAL_STATE = {
  error: undefined,
  errorMessage: undefined,
  loading: true,
  isError: false,
  clients: [],
  meta: {},
  hasMore: true,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case actionTypes.UNFILTERED_GENERAL_LIST_OF_CLIENTS_START:
    return {
      ...state,
      loading: true,
      error: undefined,
      errorMessage: undefined,
      isError: false,
    };
  case actionTypes.UNFILTERED_GENERAL_LIST_OF_CLIENTS_SUCCESS:
    return {
      ...state,
      loading: false,
      error: undefined,
      clients: action.payload.clients,
      meta: action.payload.meta,
      isError: false,
      errorMessage: undefined,
    };
  case actionTypes.UNFILTERED_GENERAL_LIST_OF_CLIENTS_ERROR:
    return {
      ...state,
      loading: false,
      error: action.error.error,
      errorMessage: action.payload,
      isError: true,
      clients: [],
    };
  default:
    return state;
  }
};

export default reducer;
