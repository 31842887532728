import { CheckOutlined, InfoCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { colors } from 'styles/colors';

export const StContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StLabel = styled.span`
  margin: 0 10px;
  font-size: 14px;
  font-weight: 500;
  color: ${colors.secondary8};
`;

export const StCheckOutlined = styled(CheckOutlined)`
  color: ${colors.green13};
`;

export const StInfoCircleOutlined = styled(InfoCircleOutlined)`
  color: ${colors.primary6};
  cursor: pointer;
`;
