import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Select, Button, Grid, Drawer, Space, Divider } from 'antd';
import {
  SortAscendingOutlined,
  SortDescendingOutlined,
} from '@ant-design/icons';
import { FiltersContainer } from 'commons/FiltersContainer';
import { LabelAndFilter } from 'commons/LabelAndFilter';
import DatePicker from 'commons/DatePicker/DatePicker';
import { shortId } from 'helpers/shortId';

const Filters = ({
  titleComponent,
  subtitleComponent,
  dateDefaultValue,
  onRangePickerChange,
  onChangeSelectFilter,
  loading,
  sorter,
  sort,
  renderFilterButton,
  filtersDrawer,
  filterName,
  onCloseFiltersDrawer,
  sorterButtonText,
  hasRangePicker,
  hasSelect,
  hasSorter,
  onRangePickerOpenOrCloseChange,
  infoFiltersToSelect,
  activeTab,
  disabledDate,
  calendarIsOpen,
  setCalendarIsOpen,
}) => {
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const rangePickerRef = useRef(null);
  const { useBreakpoint } = Grid;
  const breakpoint = useBreakpoint();
  const [tabPlaceholder, setTabPlaceholder] = useState(activeTab);
  const [dates, setDates] = useState([null]);
  const [hackValue, setHackValue] = useState(null);
  const hasInfoFiltersToSelect = infoFiltersToSelect.length > 0;

  useEffect(() => {
    switch (activeTab) {
      case 'branches':
        setTabPlaceholder('Filial');
        break;
      case 'acquirers':
        setTabPlaceholder('Adquirente');
        break;
      case 'products':
        setTabPlaceholder('Produto');
        break;
      case 'brands':
        setTabPlaceholder('Bandeira');
        break;
      default:
        setTabPlaceholder('Filial');
    }
  }, [activeTab]);

  const clearDates = () => {
    setDates([null, null]);
  };

  const [startDate, endDate] = dateDefaultValue;
  const antInputClearBtn =
    document.getElementsByClassName('ant-picker-clear')[0];

  useEffect(() => {
    const antInput = document.getElementsByClassName('ant-picker-input');

    antInput[0]?.addEventListener('click', clearDates);

    return () => {
      antInput[0]?.removeEventListener('onclick', clearDates);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const _antInputClearBtn =
        document.getElementsByClassName('ant-picker-clear')[0];
      // eslint-disable-next-line no-unused-expressions
      _antInputClearBtn?.addEventListener('click', (e) => {
        clearDates();
        e.stopPropagation();
      });
    }, 100);
  }, [antInputClearBtn]);

  useEffect(() => {
    const antInput = document.getElementsByClassName('ant-picker-input');
    const handleDates = () => setDates([startDate, null]);
    antInput[1]?.addEventListener('click', handleDates);

    return () => {
      antInput[1]?.removeEventListener('onclick', handleDates);
    };
  }, [startDate]);

  const onOpenChange = (open) => {
    onRangePickerOpenOrCloseChange(open);

    if (open) {
      setHackValue([startDate, null]);
    } else {
      setHackValue(null);
    }
  };

  if (breakpoint && !breakpoint.md) {
    return (
      <FiltersContainer
        title={titleComponent}
        renderFilterButton={renderFilterButton}
      >
        <>
          <Row gutter={8}>
            <Col span={hasRangePicker ? 24 : 0}>
              <LabelAndFilter label="Calendário">
                <RangePicker
                  ref={rangePickerRef}
                  format="DD/MM/YYYY"
                  onOpenChange={onOpenChange}
                  onCalendarChange={(val) => {
                    setHackValue(val);
                    setDates(val);
                    if (val?.every((v) => v)) {
                      setCalendarIsOpen(!calendarIsOpen);
                    }
                  }}
                  disabled={loading}
                  disabledDate={(current) => {
                    if (dates) {
                      return disabledDate(current, ...dates);
                    }
                    return disabledDate(current, startDate, endDate);
                  }}
                  open={calendarIsOpen}
                  value={hackValue || dateDefaultValue}
                  onChange={onRangePickerChange}
                  style={{ width: '100%' }}
                />
              </LabelAndFilter>
            </Col>
          </Row>
          <Drawer
            open={filtersDrawer}
            onClose={onCloseFiltersDrawer}
            title="Filtros"
            width="100%"
          >
            <div
              style={{
                height: '100%',
                justifyContent: 'space-between',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Space direction="vertical">
                <Row>
                  <Col span={hasSelect ? 24 : 0}>
                    <Select
                      showSearch
                      allowClear
                      onChange={(val) => onChangeSelectFilter(val)}
                      value={filterName}
                      disabled={loading}
                      style={{ width: '100%' }}
                      placeholder="Buscar"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {hasInfoFiltersToSelect &&
                        infoFiltersToSelect.map(
                          (item) =>
                            item && (
                              <Option key={shortId()} value={item}>
                                {item}
                              </Option>
                            )
                        )}
                    </Select>
                  </Col>
                </Row>
                <Row>
                  <Col span={hasSorter ? 10 : 0}>
                    <LabelAndFilter label="Ordenar por" align="left">
                      <Button
                        style={{ marginTop: '1rem' }}
                        disabled={loading}
                        type="dashed"
                        icon={
                          sort === 'ASC' ? (
                            <SortAscendingOutlined />
                          ) : (
                            <SortDescendingOutlined />
                          )
                        }
                        size="small"
                        onClick={sorter}
                      >
                        {sorterButtonText}
                      </Button>
                    </LabelAndFilter>
                  </Col>
                </Row>
              </Space>
              <div>
                <Divider />
                <div
                  style={{ display: 'flex', justifyContent: 'space-around' }}
                >
                  <Button onClick={onCloseFiltersDrawer}>Limpar filtros</Button>
                  <Button onClick={onCloseFiltersDrawer} type="primary">
                    Aplicar filtros
                  </Button>
                </div>
              </div>
            </div>
          </Drawer>
        </>
      </FiltersContainer>
    );
  }
  return (
    <FiltersContainer title={titleComponent} subTitle={subtitleComponent}>
      <Row gutter={8}>
        <Col
          xxl={hasRangePicker ? 4 : 0}
          xl={hasRangePicker ? 5 : 0}
          lg={hasRangePicker ? 6 : 0}
          md={hasRangePicker ? 10 : 0}
          sm={hasRangePicker ? 12 : 0}
          xs={hasRangePicker ? 24 : 0}
        >
          <LabelAndFilter label="Calendário">
            <RangePicker
              format="DD/MM/YYYY"
              allowEmpty={[true, true]}
              onOpenChange={onOpenChange}
              onCalendarChange={(val) => {
                setDates(val);
              }}
              disabled={loading}
              disabledDate={(current) => {
                if (dates) {
                  return disabledDate(current, ...dates);
                }
                return disabledDate(current, startDate, endDate);
              }}
              value={hackValue || dateDefaultValue}
              onChange={onRangePickerChange}
              style={{ width: '100%' }}
            />
          </LabelAndFilter>
        </Col>
        <Col
          xxl={hasSelect ? 4 : 0}
          xl={hasSelect ? 4 : 0}
          lg={hasSelect ? 5 : 0}
          md={hasSelect ? 6 : 0}
          sm={hasSelect ? 12 : 0}
          xs={hasSelect ? 24 : 0}
        >
          <LabelAndFilter label={tabPlaceholder}>
            <Select
              showSearch
              allowClear
              onChange={(val) => onChangeSelectFilter(val)}
              value={filterName}
              disabled={loading}
              style={{ width: '100%' }}
              placeholder="Buscar"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {hasInfoFiltersToSelect &&
                infoFiltersToSelect.map(
                  (item) =>
                    item && (
                      <Option key={shortId()} value={item}>
                        {item}
                      </Option>
                    )
                )}
            </Select>
          </LabelAndFilter>
        </Col>
        <Col md={4} lg={10} xl={12} xxl={12} />
        <Col
          xxl={hasSorter ? 2 : 0}
          xl={hasSorter ? 2 : 0}
          lg={hasSorter ? 2 : 0}
          md={hasSorter ? 4 : 0}
        >
          <LabelAndFilter label="Ordenado por" align="end">
            <Button
              disabled={loading}
              type="dashed"
              icon={
                sort === 'ASC' ? (
                  <SortAscendingOutlined />
                ) : (
                  <SortDescendingOutlined />
                )
              }
              size="small"
              onClick={sorter}
              style={{ fontSize: '12px' }}
            >
              {sorterButtonText}
            </Button>
          </LabelAndFilter>
        </Col>
      </Row>
    </FiltersContainer>
  );
};

Filters.defaultProps = {
  renderFilterButton: () => {},
  infoFiltersToSelect: [],
  onChangeSelectFilter: () => {},
  disabledDate: () => false,
};

Filters.propTypes = {
  onRangePickerChange: PropTypes.func.isRequired,
  onChangeSelectFilter: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  sorter: PropTypes.func.isRequired,
  sort: PropTypes.string.isRequired,
  renderFilterButton: PropTypes.func,
  filtersDrawer: PropTypes.bool.isRequired,
  onCloseFiltersDrawer: PropTypes.func.isRequired,
  sorterButtonText: PropTypes.string.isRequired,
  hasRangePicker: PropTypes.bool.isRequired,
  hasSelect: PropTypes.bool.isRequired,
  hasSorter: PropTypes.bool.isRequired,
  infoFiltersToSelect: PropTypes.arrayOf(PropTypes.string.isRequired),
  onRangePickerOpenOrCloseChange: PropTypes.func.isRequired,
  activeTab: PropTypes.string.isRequired,
  titleComponent: PropTypes.string.isRequired,
  subtitleComponent: PropTypes.string.isRequired,
  disabledDate: PropTypes.func,
};

export default Filters;
