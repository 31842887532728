import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ExportDropdown } from 'commons/ExportDropdown';
import { getSessionClientId } from 'helpers/sessionService';
import {
  createNotificationStart,
  getNotificationStart,
} from 'store/ducks/notifications/actions';
import { MAX_CSV_ROWS, MAX_EXCEL_ROWS } from 'constants/general';

const ExportButton = ({ radarId, listRadarDetailsMeta }) => {
  const dispatch = useDispatch();
  const [dropdownOption, setDropdownOption] = useState('');
  const fileType = dropdownOption === 'excel' ? 'xlsx' : 'csv';
  const fileName = `relatorio_radar.${fileType}`;
  const permissionsData = useSelector((state) => state.permissions.data);
  const clientId = getSessionClientId();

  const {
    user_id: userId,
    scope_id: scopeId,
    profile_id: profileId,
  } = permissionsData;

  const createNotificationSuccess = useSelector(
    (state) => state.notifications.success
  );
  const createNotificationLoading = useSelector(
    (state) => state.notifications.loading
  );

  const notificationConfig = {
    table: 'radar',
    radarId,
    fileType,
    scopeId,
    profileId,
    message: `Estamos preparando seu arquivo ${fileName} para o download.`,
  };

  useEffect(() => {
    if (dropdownOption === 'excel' || dropdownOption === 'csv') {
      dispatch(createNotificationStart(notificationConfig));
      setDropdownOption('');
    }
    // eslint-disable-next-line
  }, [dropdownOption]);

  useEffect(() => {
    if (createNotificationSuccess) {
      dispatch(
        getNotificationStart({
          userId,
          scopeId,
          clientId,
          page: 1,
          perPage: 10,
        })
      );
    }
    // eslint-disable-next-line
  }, [createNotificationSuccess]);

  return (
    <ExportDropdown
      loading={createNotificationLoading}
      setDropdownOption={setDropdownOption}
      disableExcel={listRadarDetailsMeta?.num_results > MAX_EXCEL_ROWS}
      disableCSV={listRadarDetailsMeta?.num_results > MAX_CSV_ROWS}
    />
  );
};

export default ExportButton;
