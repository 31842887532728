import styled from 'styled-components';
import { Typography } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { colors } from 'styles/colors';

const { Title, Text } = Typography;

export const ModalWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
`;

export const ModalTitle = styled(Title)`
  &&& {
    font-weight: 500;
    font-size: 16px;
    color: ${colors.gray9};
  }
`;

export const ModalSecondWrapper = styled(Text)`
  margin-left: 17.5px;
`;

export const InfoIcon = styled(InfoCircleOutlined)`
  font-size: 24px;
  color: ${colors.gold6};
`;
