import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TopForm } from './components/TopForm';
import { listRetroactiveConciliationStart } from 'store/ducks/retroactiveConciliation/actions';
import { columns } from './components/utils/columns';
import { onColumnSort } from 'helpers/sorter';
import { perPageOptions, showTotalPage } from 'constants/perPageOptions';
import * as St from './styled';

const RetroactiveConciliation = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sortFields, setSortFields] = useState({});
  const retroactiveConciliationLoading = useSelector(
    (state) => state.retroactiveConciliation.loading
  );
  const retroactiveConciliationData = useSelector(
    (state) => state.retroactiveConciliation.data
  );
  const { total_items: totalItems } = useSelector(
    (state) => state.retroactiveConciliation.meta
  );
  const dataHasBeenPosted = useSelector(
    (state) => state.retroactiveConciliation.dataHasBeenPosted
  );

  useEffect(() => {
    if (page) {
      dispatch(
        listRetroactiveConciliationStart({
          sortFields,
          page,
          perPage,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataHasBeenPosted, page, perPage, sortFields]);

  const onTableChange = ({ current, pageSize }, _, sorter) => {
    onColumnSort(sorter, setSortFields);
    setPage(current);
    setPerPage(pageSize);
  };

  const dataSource = retroactiveConciliationData.map((item) => ({
    ...item,
    key: item.retroactive_id,
  }));

  return (
    <St.Wrapper>
      <TopForm />

      <St.Container isBottom>
        <St.StTable
          columns={columns}
          dataSource={dataSource}
          loading={retroactiveConciliationLoading}
          onChange={onTableChange}
          pagination={{
            current: page,
            pageSize: perPage,
            pageSizeOptions: perPageOptions,
            total: totalItems,
            showTotal: showTotalPage,
            showSizeChanger: totalItems,
          }}
          scroll={{
            x: '100%',
            y: window.innerHeight - 400,
          }}
          disableSizeChanger={Boolean(totalItems < 10)}
        />
      </St.Container>
    </St.Wrapper>
  );
};

export default RetroactiveConciliation;
