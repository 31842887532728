import { combineReducers } from 'redux';

import auth from 'store/ducks/auth';
import completeNewPassword from 'store/ducks/completeNewPassword';
import logout from 'store/ducks/logout';
import createPassword from 'store/ducks/createPassword';
import forgotPassword from 'store/ducks/forgotPassword';
import recoveryPassword from 'store/ducks/recoveryPassword';
import permissions from 'store/ducks/permissions';
import validateAccess from 'store/ducks/validateAccess';
import errorToShow from 'store/ducks/errorToShow';

import salesSummary from 'store/ducks/salesSummary';
import salesSummaryReconciled from 'store/ducks/reconciled/summary/sales';
import paymentsSummaryReconciled from 'store/ducks/reconciled/summary/payments';
import bankSummaryReconciled from 'store/ducks/reconciled/summary/bank';
import salesReconciled from 'store/ducks/reconciled/sales';
import paymentsReconciled from 'store/ducks/reconciled/payments';
import salesInfo from 'store/ducks/salesInfo';
import salesDetails from 'store/ducks/details/salesDetails';
import reconciledSalesDetails from 'store/ducks/details/reconciledSalesDetails';
import reconciledReceiptDetails from 'store/ducks/details/reconciledReceiptDetails';
import reconciledPaymentsDetails from 'store/ducks/details/reconciledPaymentsDetails';

import amountsReceivableSummary from 'store/ducks/amountsReceivableSummary';
import amountsReceivableInfo from 'store/ducks/amountsReceivableInfo';
import amountsReceivableDetails from 'store/ducks/details/amountsReceivableDetails';
import duedateDetails from 'store/ducks/details/duedateDetails';
import advancementDetails from 'store/ducks/details/advancementDetails';
import adjustmentDetails from 'store/ducks/details/adjustmentDetails';
import refundsDetails from 'store/ducks/details/refundsDetails';
import paymentDivergencesDetails from 'store/ducks/details/paymentDivergencesDetails';

import ofxParametersFilters from 'store/ducks/filters/ofxParameters';
import salesInfoFilters from 'store/ducks/filters/salesInfoFilters';
import reconciledSalesInfoFilters from 'store/ducks/filters/reconciledSalesInfoFilters';
import divergencesInfoFilters from 'store/ducks/filters/divergencesInfoFilters';
import amountsReceivableInfoFilters from 'store/ducks/filters/amountsReceivableInfoFilters';
import duedateInfoFilters from 'store/ducks/filters/duedateInfoFilters';
import advancementInfoFilters from 'store/ducks/filters/advancementInfoFilters';
import adjustmentInfoFilters from 'store/ducks/filters/adjustmentInfoFilters';
import refundInfoFilters from 'store/ducks/filters/refundInfoFilters';
import salesDetailsFilters from 'store/ducks/filters/details/salesDetailsFilters';
import reconciliationRulesDetailsFilters from 'store/ducks/filters/details/reconciliationRulesDetailsFilters';
import searchTransactionDetailsFilters from 'store/ducks/filters/details/searchTransactionDetailsFilters';
import reconciledSalesDetailsFilters from 'store/ducks/filters/details/reconciledSalesDetailsFilters';
import reconciledPaymentsDetailsFilters from 'store/ducks/filters/details/reconciledPaymentsDetailsFilters';
import reconciledBankDetailsFilters from 'store/ducks/filters/details/reconciledBankDetailsFilters';
import paymentDivergencesDetailsFilters from 'store/ducks/filters/details/paymentDivergencesDetailsFilters';
import amountsReceivableDetailsFilters from 'store/ducks/filters/details/amountsReceivableDetailsFilters';
import banksDetailsFilters from 'store/ducks/filters/details/banksDetailsFilters';
import duedateDetailsFilters from 'store/ducks/filters/details/duedateDetailsFilters';
import advancementDetailsFilters from 'store/ducks/filters/details/advancementDetailsFilters';
import adjustmentDetailsFilters from 'store/ducks/filters/details/adjustmentDetailsFilters';
import refundDetailsFilters from 'store/ducks/filters/details/refundDetailsFilters';
import contractFeeFilters from 'store/ducks/filters/contractFeeFilters';
import bankConciliationFilters from 'store/ducks/filters/details/bankConciliationFilters';
import importFilesFilters from 'store/ducks/filters/importFiles';
import reportsCustomColumns from 'store/ducks/reportsCustomColumns';
import listOfClients from 'store/ducks/listOfClients';
import listOfClientsFilters from 'store/ducks/filters/listOfClientsFilters';
import listOfPlans from 'store/ducks/listOfPlans';
import generalListOfClients from 'store/ducks/generalListOfClients';
import unfilteredGeneralListOfClients from 'store/ducks/unfilteredGeneralListOfClients';
import listUsers from 'store/ducks/listUsersClient';
import registerFee from 'store/ducks/registerFees';
import changeStore from 'store/ducks/changeStore';
import optin from 'store/ducks/optin/optin';
import optinRequest from 'store/ducks/optin/optinRequest';
import optinUrls from 'store/ducks/optin/optinUrls';

import acquirer from 'store/ducks/acquirer';

import checkInvitationEmail from 'store/ducks/checkInvitationEmail';
import sendInvitation from 'store/ducks/sendInvitation';
import listClientSelect from 'store/ducks/listClientsSelect';
import alterProfileScope from 'store/ducks/alterProfileScope';
import removeUser from 'store/ducks/removeUser';
import deleteUser from 'store/ducks/deleteUser';
import removeBranch from 'store/ducks/removeBranch';
import listFees from 'store/ducks/listFees';

import updateBranchData from 'store/ducks/updateBranchData';
import createBranch from 'store/ducks/createBranch';

import importFiles from 'store/ducks/importFiles';
import ofxParameters from 'store/ducks/ofxParameters';
import banksDash from 'store/ducks/banks/listBanksDash';
import banksIntermediate from 'store/ducks/banks/listBanksIntermediate';
import banksReports from 'store/ducks/banks/listBanksReports';

import bankConciliation from 'store/ducks/bankConciliation';

import briefing from 'store/ducks/briefing';
import uploadedLetterUrl from 'store/ducks/uploadedLetterUrl';
import client from 'store/ducks/client';
import store from 'store/ducks/store';
import viaCep from 'store/ducks/viaCep';
import receitaWebservice from 'store/ducks/receitaWebservice';
import merchant from 'store/ducks/merchant';
import notifications from 'store/ducks/notifications';
import clientAcquirer from 'store/ducks/clientAcquirer';

import adjustmentPaymentsDash from 'store/ducks/paymentsDash/adjustmentPayments';
import duedatePaymentsDash from 'store/ducks/paymentsDash/duedatePayments';
import advancementPaymentsDash from 'store/ducks/paymentsDash/advancementPayments';
import refundsPaymentsDash from 'store/ducks/paymentsDash/refundsPayments';
import advancementPaymentsIntermediate from 'store/ducks/paymentsIntermediate/advancementPaymentsIntermediate';
import duedatePaymentsIntermediate from 'store/ducks/paymentsIntermediate/duedatePaymentsIntermediate';
import adjustmentPaymentsIntermediate from 'store/ducks/paymentsIntermediate/adjustmentPaymentsIntermediate';
import refundsPaymentsIntermediate from 'store/ducks/paymentsIntermediate/refundsPaymentsIntermediate';
import paymentsDivergencesIntermediate from 'store/ducks/paymentsIntermediate/paymentsDivergencesIntermediate';
import paymentsDivergencesDash from 'store/ducks/paymentsDash/paymentsDivergences';

import visibleDropdownFilters from 'store/ducks/filters/details/visibleDropdownFilters';

import exportSalesDetails from 'store/ducks/details/export/salesDetails';
import exportPaymentDivergencesDetails from 'store/ducks/details/export/paymentDivergencesDetails';
import exportAmountsReceivableDetails from 'store/ducks/details/export/amountsReceivableDetails';
import exportBankDetails from 'store/ducks/details/export/bankDetails';
import exportDuedateDetails from 'store/ducks/details/export/duedateDetails';
import exportAdvancementDetails from 'store/ducks/details/export/advancementDetails';
import exportAdjustmentDetails from 'store/ducks/details/export/adjustmentDetails';
import exportRefundDetails from 'store/ducks/details/export/refundDetails';
import exportAllClientsDetails from 'store/ducks/details/export/allClientsDetails';

import updateBriefingStep from 'store/ducks/updateBriefingStep';
import updateScreenToScreenDate from 'store/ducks/updateScreenToScreenDate';
import updateScreenToScreenPeriod from 'store/ducks/updateScreenToScreenPeriod';
import updateIsImplanted from 'store/ducks/updateIsImplanted';
import updateFilteredColumns from 'store/ducks/updateFilteredColumns';
import feesUpdateFilteredColumns from 'store/ducks/feesUpdateFilteredColumns';
import customUpdateFilteredColumns from 'store/ducks/customUpdateFilteredColumns';

// credit

import creditList from 'store/ducks/credit/creditList';
import creditListAvailable from 'store/ducks/credit/creditListAvailable';
import creditListPartners from 'store/ducks/credit/creditListPartners';
import creditListPartnersAvailable from 'store/ducks/credit/creditListPartnersAvailable';
import creditListPartnersOptin from 'store/ducks/credit/creditListPartnersOptin';
import creditPartnerOptin from 'store/ducks/credit/creditPartnerOptin';
import creditInteractView from 'store/ducks/credit/creditInteractView';
import creditInteractPreregister from 'store/ducks/credit/creditInteractPreregister';
import creditInteractAccess from 'store/ducks/credit/creditInteractAccess';
import creditInteractContract from 'store/ducks/credit/creditInteractContract';
import creditInteractRetryApproval from 'store/ducks/credit/creditInteractRetryApproval';
import creditInteractClose from 'store/ducks/credit/creditInteractClose';
import legalRepresentative from 'store/ducks/credit/legalRepresentative';
import sharedPartnerData from 'store/ducks/credit/sharedPartnerData';
import creditTomaticoStatus from 'store/ducks/credit/creditTomaticoStatus';

// Radar
import radarAnyRunning from 'store/ducks/radar/radarAnyRunning';
import radarExpire from 'store/ducks/radar/radarExpire';
import radarGet from 'store/ducks/radar/radarGet';
import radarList from 'store/ducks/radar/radarList';
import radarRun from 'store/ducks/radar/radarRun';
import radarRunResume from 'store/ducks/radar/radarRunResume';
import radarRunTest from 'store/ducks/radar/radarRunTest';
import radarDetailsFilters from 'store/ducks/filters/radar/details';
import radarListFilters from 'store/ducks/radar/radarListFilters';

import searchTransaction from 'store/ducks/searchTransaction';

// Token Sandbox
import tokenSandbox from 'store/ducks/tokenSandbox';

// Token Prod
import tokenProd from 'store/ducks/tokenProd';

// Plans
import plans from 'store/ducks/plans';

// Skins
import skins from 'store/ducks/skins';

// Reconciliation Rules
import listReconciliationRules from 'store/ducks/reconciliationRules/listReconciliationRules';
import deleteReconciliationRules from 'store/ducks/reconciliationRules/deleteReconciliationRules';
import postReconciliationRules from 'store/ducks/reconciliationRules/postReconciliationRules';
import putReconciliationRules from 'store/ducks/reconciliationRules/putReconciliationRules';
import getAcquirersByClientId from 'store/ducks/reconciliationRules/getAcquirersByClientId';
import getNextOrderByClientId from 'store/ducks/reconciliationRules/getNextOrderByClientId';

// RetroactiveConciliation
import retroactiveConciliation from 'store/ducks/retroactiveConciliation';

// Cnpj Maintenance Stores
import listStores from 'store/ducks/cnpjMaintenance/listStores';
import deleteStores from 'store/ducks/cnpjMaintenance/deleteStores';
import putStores from 'store/ducks/cnpjMaintenance/putStores';
import updateStore from 'store/ducks/cnpjMaintenance/updateStore';
import cnpjMaintenanceInfoFilters from 'store/ducks/filters/cnpjMaintenanceInfoFilters';

// Cnpj Maintenance Merchants
import listMerchants from 'store/ducks/cnpjMaintenance/merchants/listMerchants';
import deleteMerchant from 'store/ducks/cnpjMaintenance/merchants/deleteMerchant';

// Fees
import feesDetails from 'store/ducks/details/feesDetails';
import feesDetailsFilters from 'store/ducks/filters/fees';
import deleteFee from 'store/ducks/fees/deleteFee';

const appReducer = combineReducers({
  auth,
  completeNewPassword,
  logout,
  createPassword,
  forgotPassword,
  recoveryPassword,
  permissions,
  validateAccess,
  errorToShow,
  salesSummary,
  salesSummaryReconciled,
  salesReconciled,
  paymentsReconciled,
  salesInfo,
  salesDetails,
  reconciledSalesDetails,
  reconciledReceiptDetails,
  reconciledPaymentsDetails,
  amountsReceivableSummary,
  amountsReceivableInfo,
  amountsReceivableDetails,
  duedateDetails,
  advancementDetails,
  adjustmentDetails,
  refundsDetails,
  salesInfoFilters,
  ofxParametersFilters,
  reconciledSalesInfoFilters,
  importFilesFilters,
  bankConciliationFilters,
  divergencesInfoFilters,
  amountsReceivableInfoFilters,
  duedateInfoFilters,
  advancementInfoFilters,
  adjustmentInfoFilters,
  refundInfoFilters,
  salesDetailsFilters,
  reconciliationRulesDetailsFilters,
  reconciledSalesDetailsFilters,
  reconciledPaymentsDetailsFilters,
  reconciledBankDetailsFilters,
  amountsReceivableDetailsFilters,
  banksDetailsFilters,
  duedateDetailsFilters,
  advancementDetailsFilters,
  adjustmentDetailsFilters,
  refundDetailsFilters,
  contractFeeFilters,
  reportsCustomColumns,
  acquirer,
  listOfClients,
  listOfClientsFilters,
  listOfPlans,
  generalListOfClients,
  unfilteredGeneralListOfClients,
  changeStore,
  listUsers,
  registerFee,
  checkInvitationEmail,
  sendInvitation,
  listClientSelect,
  alterProfileScope,
  removeUser,
  deleteUser,
  removeBranch,
  listFees,
  updateBranchData,
  createBranch,
  importFiles,
  ofxParameters,
  banksDash,
  banksIntermediate,
  banksReports,
  bankConciliation,
  briefing,
  uploadedLetterUrl,
  client,
  store,
  viaCep,
  receitaWebservice,
  merchant,
  notifications,
  clientAcquirer,
  adjustmentPaymentsDash,
  duedatePaymentsDash,
  advancementPaymentsDash,
  refundsPaymentsDash,
  paymentsSummaryReconciled,
  bankSummaryReconciled,
  advancementPaymentsIntermediate,
  duedatePaymentsIntermediate,
  adjustmentPaymentsIntermediate,
  refundsPaymentsIntermediate,
  paymentsDivergencesDash,
  paymentsDivergencesIntermediate,
  paymentDivergencesDetails,
  paymentDivergencesDetailsFilters,
  visibleDropdownFilters,
  optin,
  optinRequest,
  optinUrls,
  exportSalesDetails,
  exportPaymentDivergencesDetails,
  exportAmountsReceivableDetails,
  exportBankDetails,
  exportDuedateDetails,
  exportAdvancementDetails,
  exportAdjustmentDetails,
  exportRefundDetails,
  exportAllClientsDetails,
  updateBriefingStep,
  updateScreenToScreenDate,
  updateScreenToScreenPeriod,
  updateIsImplanted,
  updateFilteredColumns,
  feesUpdateFilteredColumns,
  customUpdateFilteredColumns,
  creditList,
  creditListAvailable,
  creditListPartners,
  creditListPartnersAvailable,
  creditListPartnersOptin,
  creditPartnerOptin,
  creditInteractView,
  creditInteractPreregister,
  creditInteractAccess,
  creditInteractContract,
  creditInteractRetryApproval,
  creditInteractClose,
  legalRepresentative,
  sharedPartnerData,
  creditTomaticoStatus,
  radarAnyRunning,
  radarExpire,
  radarGet,
  radarList,
  radarRun,
  radarRunResume,
  radarRunTest,
  radarDetailsFilters,
  radarListFilters,
  searchTransaction,
  searchTransactionDetailsFilters,
  tokenSandbox,
  tokenProd,
  plans,
  skins,
  listReconciliationRules,
  deleteReconciliationRules,
  postReconciliationRules,
  putReconciliationRules,
  getAcquirersByClientId,
  getNextOrderByClientId,
  listStores,
  deleteStores,
  putStores,
  updateStore,
  listMerchants,
  deleteMerchant,
  retroactiveConciliation,
  cnpjMaintenanceInfoFilters,
  feesDetails,
  feesDetailsFilters,
  deleteFee,
});

export const rootReducer = (state, action) => {
  if (action.type === '@logout/LOGOUT_SUCCESS') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};
