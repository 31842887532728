import successHandler from 'helpers/successHandler';
import { call, put } from 'redux-saga/effects';
import { authForgotPasswordSubmit } from 'services/recoveryPassword';
import { recoveryPasswordFailure, recoveryPasswordSuccess } from './actions';

export function* recoveryPasswordSaga(action) {
  const { userData } = action.payload;

  try {
    const apiData = yield call(authForgotPasswordSubmit, userData);
    if (apiData === 'SUCCESS') {
      yield put(recoveryPasswordSuccess(apiData));
      successHandler('Senha redefinida com sucesso!');
    } else if ('error' in apiData) {
      throw Object.assign(new Error(apiData?.error), { code: 403 });
    }
  } catch (err) {
    if (
      err?.toString().includes('Expired') ||
      err?.toString().includes('CodeMismatch')
    ) {
      yield put(
        recoveryPasswordFailure(
          'O código de verificação foi digitado incorretamente ou expirou. Tente novamente ou solicite um novo código.'
        )
      );
    } else if (err?.toString().includes('LimitExceededException')) {
      yield put(
        recoveryPasswordFailure(
          'O limite de tentativas foi excedido. Tente novamente mais tarde.'
        )
      );
    } else {
      yield put(recoveryPasswordFailure(err?.toString()));
    }
  }
}
