import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from 'styles/colors';

export const StContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export const StWrapper = styled.div`
  text-align: center;
`;

export const StImage = styled.img`
  width: 768px;
  height: 626px;
`;

export const StTitle = styled.h1`
  font-family: 'Roboto';
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: ${colors.gray9};
`;

export const StDescription = styled.p`
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  color: ${colors.gray9};
`;

export const StLink = styled(Link)`
  color: ${colors.primary6} !important;
`;
