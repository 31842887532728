import styled from 'styled-components';
import { Button, Row } from 'antd';
import { colors } from 'styles/colors';

export const StSearchHeaderContainer = styled(Row)`
  &&& {
    margin: 32px 0px;
    padding: 16px 32px 32px 32px;
    background-color: ${colors.gray1};
    box-shadow: none;
    border-radius: 4px;
  }
`;

export const StSearchBarContainer = styled(Row)`
  &&& {
    margin-top: 16px;
  }
`;

export const StUsernameSubtitle = styled.span`
  color: #8e8e8e;
`;

export const StModalCancelButton = styled(Button)`
  background-color: ${colors.gray1};
  color: ${colors.gray11};
`;

export const StModalRemoveButton = styled(Button)`
  background-color: ${colors.gray1};
  color: ${colors.gray11};
`;
