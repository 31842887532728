/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { StContainer, StRow, StTitle } from './styled';
import { Row, Col, Button, Grid } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getSessionClientId } from 'helpers/sessionService';
import { useStoreFilter } from 'components/CnpjMaintenance/hooks/useStoreFilter';
import { SelectInfiniteScroll } from 'commons/SelectInfiniteScroll';
import { useMerchantFilter } from 'components/CnpjMaintenance/hooks/useMerchantFilter';
import { ExportDropdown } from 'commons/ExportDropdown';
import { dataTestIdHandler } from 'helpers/dataTestIdHandler';
import { usePlansFeaturesPermissions } from 'utils/hooks/usePlansFeaturesPermissions';
import {
  createNotificationStart,
  getNotificationStart,
} from 'store/ducks/notifications/actions';

const Filters = ({ title, onChangeSelectFilter, activeTab, openAddModal }) => {
  const dispatch = useDispatch();
  const { useBreakpoint } = Grid;
  const breakpoint = useBreakpoint();
  const permissionsData = useSelector((state) => state.permissions?.data);
  const clientId = getSessionClientId();
  const dataTestPrefix = 'cnpjMaintenance';
  const { isFeatureAvailable } = usePlansFeaturesPermissions();
  const { user_id: userId, scope_id: scopeId } = permissionsData;
  const [dropdownOption, setDropdownOption] = useState('');

  const createNotificationLoading = useSelector(
    (state) => state.notifications?.loading
  );
  const createNotificationSuccess = useSelector(
    (state) => state.notifications?.success
  );
  const [tabPlaceholder, setTabPlaceholder] = useState(activeTab);
  const [selectPlaceholder, setSelectPlaceholder] = useState('');
  const [searchStoreFilterInput, setSearchStoreFilterInput] = useState('');
  const [searchMerchantFilterInput, setSearchMerchantFilterInput] =
    useState('');
  const [offset, setOffset] = useState(0);
  const [offsetMerchant, setOffsetMerchant] = useState(0);
  const [page, setPage] = useState(1);
  const limit = 10;
  const offsetShift = 10;

  const deleteStoresSuccess = useSelector(
    (state) => state.deleteStores.success
  );
  const activeStoresSuccess = useSelector((state) => state.putStores.success);
  const updateStoreSuccess = useSelector((state) => state.updateStore?.success);

  const {
    isLoadingSearch,
    storeFilterOptions,
    storeFilterMeta,
    refreshFilters,
  } = useStoreFilter(searchStoreFilterInput, offset, limit);

  const { has_next: hasMoreStores } = storeFilterMeta;

  const {
    isLoadingSearch: isLoadingMerchantSearch,
    merchantFilterOptions,
    merchantFilterMeta,
  } = useMerchantFilter(searchMerchantFilterInput, offsetMerchant, limit);

  const { has_next: hasMoreMerchants } = merchantFilterMeta;

  const onStoreSelected = ({ value, label }) => {
    if (!value || value === undefined || value === '') {
      setSearchStoreFilterInput('');
      onChangeSelectFilter('');
      return;
    }
    onChangeSelectFilter(value);
    setSearchStoreFilterInput(label);
    setOffset(0);
    setPage(1);
  };

  const onMerchantSelected = ({ value, label }) => {
    if (!value || value === undefined || value === '') {
      onChangeSelectFilter('');
      setSearchMerchantFilterInput('');
      return;
    }
    onChangeSelectFilter(label);
    setSearchMerchantFilterInput(label);
    setOffsetMerchant(0);
    setPage(1);
  };

  const transformStoreOptions = (option = []) =>
    option.map(({ id, value }) => ({
      value: id,
      label: value,
    }));

  const transformMerchantOptions = (option = []) =>
    option.map(({ id, value }) => ({
      value: id,
      label: value,
    }));

  const fileName =
    (activeTab === 'stores'
      ? 'relatorioDeLojasCadastradas.'
      : 'relatorioDeEstabelecimentosCadastrados.') +
    (dropdownOption === 'excel' ? 'xlsx' : 'csv');
  const notificationConfig = {
    message: `Estamos preparando seu arquivo ${fileName} para o download.`,
    table:
      activeTab === 'stores' ? 'stores-maintenance' : 'merchants-maintenance',
    fileType: dropdownOption === 'excel' ? 'xlsx' : 'csv',
  };

  useEffect(() => {
    if (dropdownOption === 'excel' || dropdownOption === 'csv') {
      dispatch(createNotificationStart(notificationConfig));
      setDropdownOption('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownOption]);

  useEffect(() => {
    if (createNotificationSuccess) {
      dispatch(
        getNotificationStart({
          userId,
          scopeId,
          clientId,
          page: 1,
          perPage: 10,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createNotificationSuccess]);

  const [isClearFilterStore, setIsClearFilterStore] = useState(false);
  const [isClearFilterMerchant, setIsClearFilterMerchant] = useState(false);

  useEffect(() => {
    if (activeTab === 'stores') {
      setTabPlaceholder('Lojas');
      setSelectPlaceholder('Buscar CNPJ ou apelido da loja');
      setSearchStoreFilterInput('');
      setSearchMerchantFilterInput('');
      setIsClearFilterMerchant(true);
    }

    if (activeTab === 'acquirers') {
      setTabPlaceholder('Estabelecimentos');
      setSelectPlaceholder('Buscar estabelecimento');
      setSearchStoreFilterInput('');
      setSearchMerchantFilterInput('');
      setIsClearFilterStore(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  useEffect(() => {
    if (page > 1 && activeTab === 'stores') {
      setOffset((prevState) => prevState + offsetShift);
    }
    if (page > 1 && activeTab === 'acquirers') {
      setOffsetMerchant((prevState) => prevState + offsetShift);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    setOffset(0);
    setPage(1);
  }, [searchStoreFilterInput]);

  useEffect(() => {
    setOffsetMerchant(0);
    setPage(1);
  }, [searchMerchantFilterInput]);

  useEffect(() => {
    if (deleteStoresSuccess) {
      setOffset(0);
      setPage(1);
      refreshFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStoresSuccess]);

  useEffect(() => {
    if (activeStoresSuccess) {
      setOffset(0);
      setPage(1);
      refreshFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStoresSuccess]);

  useEffect(() => {
    if (updateStoreSuccess) {
      setOffset(0);
      setPage(1);
      refreshFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStoreSuccess]);

  return (
    <StContainer $breakpoint={breakpoint}>
      <StTitle>{title}</StTitle>
      <StRow gutter={8}>
        <Col span={8}>
          {activeTab === 'stores' && (
            <SelectInfiniteScroll
              labelText={tabPlaceholder}
              hasMoreItems={hasMoreStores}
              loadingItems={isLoadingSearch}
              options={transformStoreOptions(storeFilterOptions)}
              placeholder={selectPlaceholder}
              searchFilterInput={searchStoreFilterInput}
              setSearchFilter={setSearchStoreFilterInput}
              onOptionClick={onStoreSelected}
              setPage={setPage}
              disabled={isLoadingSearch}
              isClear={isClearFilterStore}
            />
          )}
          {activeTab === 'acquirers' && (
            <SelectInfiniteScroll
              labelText={tabPlaceholder}
              hasMoreItems={hasMoreMerchants}
              loadingItems={isLoadingMerchantSearch}
              options={transformMerchantOptions(merchantFilterOptions)}
              placeholder={selectPlaceholder}
              searchFilterInput={searchMerchantFilterInput}
              setSearchFilter={setSearchMerchantFilterInput}
              onOptionClick={onMerchantSelected}
              setPage={setPage}
              disabled={isLoadingMerchantSearch}
              isClear={isClearFilterMerchant}
            />
          )}
        </Col>
        <Col span={8}>
          <Row
            gutter={[16]}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
            }}
          >
            <Col>
              <Button
                onClick={() => openAddModal(1)}
                style={{ borderRadius: '4px' }}
              >
                Incluir adquirente
              </Button>
            </Col>
            <Col>
              <Button
                onClick={() => openAddModal(0)}
                style={{ borderRadius: '4px' }}
              >
                Incluir CNPJ
              </Button>
            </Col>
            <Col>
              <ExportDropdown
                blocked={!isFeatureAvailable({ key: 'general-fileExport' })}
                loading={createNotificationLoading}
                setDropdownOption={setDropdownOption}
                dataTestPrefix={dataTestPrefix}
                {...dataTestIdHandler(dataTestPrefix, 'export-button')}
              />
            </Col>
          </Row>
        </Col>
      </StRow>
    </StContainer>
  );
};
Filters.propTypes = {
  title: PropTypes.string.isRequired,
  // loading: PropTypes.bool.isRequired,
  activeTab: PropTypes.string.isRequired,
};
export default Filters;
