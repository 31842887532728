import styled from 'styled-components';
import { InfoCircleFilled } from '@ant-design/icons';
import { InputMaskCpfCnpj } from 'commons/InputMaskCpfCnpj';
import { colors } from 'styles/colors';

export const InfoContainer = styled.div`
  display: flex;
`;

export const InfoIcon = styled(InfoCircleFilled)`
  color: ${colors.primary6};
  margin: 5px 10px 0 0;
`;

export const StCpfCnpj = styled(InputMaskCpfCnpj)`
  width: 100%;
  padding: 4px 11px;
  color: ${(props) => (props.disabled ? 'rgba(0, 0, 0, 0.25)' : colors.gray11)};
  border: 1px solid ${colors.gray5};
  border-radius: 2px;
  background-color: ${(props) => (props.disabled ? colors.gray3 : 'default')};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'default')};
  &:hover {
    border-color: ${(props) => (props.disabled ? 'default' : colors.primary5)};
  }
  &:focus {
    border-color: ${colors.primary5};
  }
`;
