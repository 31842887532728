import { lambdaApi } from '../api';

const types = { weekly: 'RadarWeekly', monthly: 'RadarMonthly' };

const transformer = (apiResponse) => {
  return {
    payload: apiResponse?.payload,
  };
};

export const runTest = async (data) => {
  const { clientId, type, scopeId, emails } = data;

  const config = {
    is_test: true,
    client_id: [clientId],
    type: types[type],
    scope_id: scopeId,
    emails,
  };

  const response = await lambdaApi().post('/radar/process', config);
  return transformer(response.data);
};
