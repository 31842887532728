import { Tabs } from 'antd';
import styled from 'styled-components';

export const StContainer = styled.div`
  overflow-y: hidden;
`;

export const StTabs = styled(Tabs)`
  width: 100%;

  .ant-tabs-nav {
    box-shadow: none !important;
  }

  &&& {
    overflow: hidden;
  }
`;

export const StSubTabs = styled(Tabs)`
  width: 100%;

  .ant-tabs-nav {
    box-shadow: none !important;

    .ant-tabs-nav-wrap {
      padding: 0;
    }
  }

  &&& {
    overflow: hidden;
  }
`;
