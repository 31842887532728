import styled from 'styled-components';
import { Typography, Button, Row, Col } from 'antd';
import { marginMainContainer } from 'helpers/style';
import { colors } from 'styles/colors';

export const StContainer = styled(Row)`
  &&& {
    margin: ${(props) => marginMainContainer(props?.$breakpoint)};
    padding-top: 72px;
    padding-bottom: 72px;
  }
`;

export const StHeaderContainer = styled(Col)`
  &&& {
  }
`;

export const StTableContainer = styled(Col)`
  &&& {
    margin-top: 3rem;
    background-color: ${colors.gray1};
    padding: 4rem;
    box-shadow: none;
    border-radius: 4px;
  }
`;

export const StTitle = styled(Typography.Title)`
  margin-bottom: 0 !important;
  color: ${colors.gray11} !important;
  font-size: 3.75rem !important;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
`;

export const StHeaderTitle = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
`;

export const StHeaderSubtitle = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
`;

export const StClientTitle = styled(Typography.Title)`
  color: ${colors.gray11} !important;
  font-size: 2.5rem !important;
  display: inline-block !important;
`;

export const StButton = styled(Button)`
  &&& {
  }
`;

export const StClearFiltersButton = styled(Button)`
  &&& {
  }
`;

export const StLabel = styled.span`
  color: #8e8e8e;
  font-size: 1.75rem;
  display: block;
`;

export const StTableHeadenButtonCol = styled(Col)`
  &&& {
    display: flex;
    justify-content: space-between;
  }
`;
