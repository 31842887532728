import { SearchOutlined } from '@ant-design/icons';
import { Button, Card, Col, notification, Row, Typography } from 'antd';
import Search from 'antd/es/input/Search';
import { ErrorCard } from 'commons/ErrorCard';
import { perPageOptions, showTotalPage } from 'constants/perPageOptions';
import { onColumnSort } from 'helpers/sorter';
import { generateFilterDataStructure } from 'helpers/tableService';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Tables from '../../../../commons/Table/Tables';
import { updateImportFilesFilters } from '../../../../store/ducks/filters/importFiles/actions';
import {
  listFilesStart,
  updateCurrentPageStart,
} from '../../../../store/ducks/importFiles/actions';
import columns from './table/columns';
import { shortId } from 'helpers/shortId';

const ExtratosBancariosOFX = () => {
  const dispatch = useDispatch();

  const loadingList = useSelector((state) => state.importFiles.loadingList);
  const loadingUpload = useSelector((state) => state.importFiles.loadingUpload);
  const loadingRemove = useSelector((state) => state.importFiles.loadingRemove);
  const errorList = useSelector((state) => state.importFiles.isErrorList);
  const errorMessage = useSelector((state) => state.importFiles.errorMessage);
  const listData = useSelector((state) => state.importFiles.listData);
  const currentPageFromStore = useSelector(
    (state) => state.importFiles.currentPage
  );
  const [perPage, setPerPage] = useState(10);
  const [pageCurrent, setPageCurrent] = useState(currentPageFromStore);
  const [pageTotal, setPageTotal] = useState(100);

  const [localFilters, setLocalFilters] = useState([]);
  const [sortFields, setSortFields] = useState({});
  const [searchText, setSearchText] = useState('');
  const [shouldUpdate, setShouldUpdate] = useState(false);

  const importFilesMeta = useSelector((state) => state.importFiles.meta);
  const importFilesError = useSelector(
    (state) => state.importFiles.isErrorList
  );

  const filtersData = useSelector((state) => state.importFilesFilters.filters);
  const toParamsFilters = useSelector(
    (state) => state.importFilesFilters.toParamsFilters
  );

  const hasNoTransactions = useSelector(
    (state) => state.bankConciliation.hasNoTransactions
  );
  const loadingHasNoTransactions = useSelector(
    (state) => state.bankConciliation.loadingHasNoTransactions
  );
  useEffect(() => {
    if (hasNoTransactions && !loadingHasNoTransactions) {
      notification.warning({
        message: 'O arquivo selecionado não possui transações.',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasNoTransactions]);

  useEffect(() => {
    setPerPage(importFilesMeta.per_page);
    setPageTotal(importFilesMeta.total);
    setPageCurrent(importFilesMeta.page);
  }, [importFilesMeta]);

  // Startup
  useEffect(() => {
    dispatch(
      listFilesStart({
        sortFields,
        page: pageCurrent,
        perPage,
        asc: 'asc',
        filter: 'OFX_EXTRATOS',
        searchBy: searchText,
        q: {
          filter: toParamsFilters,
        },
      })
    );
  }, [
    dispatch,
    pageCurrent,
    perPage,
    sortFields,
    toParamsFilters,
    searchText,
    loadingRemove,
  ]);

  useEffect(() => {
    listData.forEach((row) => {
      const rowStatus = row?.status;
      if (rowStatus === 'IMPORTANDO' || rowStatus === 'REMOVENDO') {
        setShouldUpdate(true);
      }
    });
  }, [listData]);

  useEffect(() => {
    if (shouldUpdate) {
      setInterval(() => {
        dispatch(
          listFilesStart({
            sortFields,
            page: pageCurrent,
            perPage,
            asc: 'asc',
            filter: 'OFX_EXTRATOS',
            searchBy: searchText,
            q: {
              filter: toParamsFilters,
            },
          })
        );
      }, 60000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldUpdate]);

  useEffect(() => {
    if (loadingUpload === false) {
      dispatch(
        listFilesStart({
          sortFields,
          page: pageCurrent,
          perPage,
          asc: 'asc',
          filter: 'OFX_EXTRATOS',
          searchBy: searchText,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingUpload]);

  const searchFiles = (_searchText) => {
    setPageCurrent(1);
    setSearchText(_searchText);
  };

  // Functions
  const onTablePaginationOrFiltersOrSorterChange = (
    _pagination,
    tableFilters,
    sorter
  ) => {
    const filterDataToDispatch = generateFilterDataStructure(tableFilters);

    onColumnSort(sorter, setSortFields);
    setLocalFilters(tableFilters);
    dispatch(updateImportFilesFilters(filterDataToDispatch));
    setPerPage(_pagination.pageSize);
    setPageTotal(importFilesMeta.total);
    setPageCurrent(_pagination.current);
    dispatch(
      updateCurrentPageStart({
        currentPage: _pagination?.current,
      })
    );
  };

  const dataSource = listData.map((obj) => ({
    ...obj,
    key: shortId(),
  }));

  // Render Page
  if (errorList) {
    return (
      <Row style={{ marginTop: '2rem' }}>
        <Col lg={10} md={18}>
          <Card style={{ borderRadius: 4 }} loading={loadingList}>
            <ErrorCard
              title="Erro na listagem de extratos!"
              reason={errorMessage}
              recommendation=""
            />
          </Card>
        </Col>
      </Row>
    );
  }

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography.Text strong style={{ fontSize: 20 }}>
          Extratos importados
        </Typography.Text>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <Typography.Text style={{ fontSize: 14, color: '#8E8E8E' }}>
            Visualize seus <b>extratos OFX</b> importados na plataforma. Após
            completar a importação,{' '}
            <Link to="/conciliacao-bancaria">
              siga para a Conciliação bancária.
            </Link>
          </Typography.Text>
        </div>
        <Search
          style={{ width: '20%' }}
          placeholder="Procurar"
          onSearch={searchFiles}
          allowClear
          enterButton={
            <Button
              type="default"
              onClick={searchFiles}
              icon={<SearchOutlined />}
            />
          }
        />
      </div>
      <div style={{ marginTop: '1.75rem' }}>
        <Tables
          dataSource={dataSource}
          customColumns={columns(filtersData, toParamsFilters)}
          columns={columns(filtersData, toParamsFilters, pageCurrent)}
          toParamsFilters={toParamsFilters}
          localFilters={localFilters}
          loading={loadingList}
          error={importFilesError}
          pagination={{
            pageSize: perPage,
            total: pageTotal,
            current: pageCurrent,
            pageSizeOptions: perPageOptions,
            showSizeChanger: pageTotal,
            showTotal: showTotalPage,
          }}
          onChange={onTablePaginationOrFiltersOrSorterChange}
          defaultSelectedColumns={[]}
        />
      </div>
    </div>
  );
};

ExtratosBancariosOFX.defaultProps = {};
ExtratosBancariosOFX.propTypes = {};

export default ExtratosBancariosOFX;
