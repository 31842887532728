import { api } from '../api';
import store from 'store';

const transformer = ({ data, meta }) => {
  return {
    details: data.sales,
    feeAmount: data.total_fee_amount,
    grossAmount: data.total_gross_amount,
    netAmount: data.total_net_amount,
    meta,
  };
};

const getSalesDetailsFromAPI = async ({ sortFields, page, perPage, q }) => {
  const config = {
    params: {
      start_date: store
        .getState()
        .updateScreenToScreenDate?.startDate.format('YYYY-MM-DD'),
      end_date: store
        .getState()
        .updateScreenToScreenDate?.endDate.format('YYYY-MM-DD'),
      page,
      per_page: perPage,
      sort_fields: JSON.stringify(sortFields),
      q: JSON.stringify(q),
    },
  };
  const response = await api().get('/detail/sales', config);
  return transformer(response.data);
};

export default getSalesDetailsFromAPI;
