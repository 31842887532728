// TODO: Remove camelcase eslint rule
/* eslint-disable camelcase */
import { lambdaApi } from '../api';

const transformer = (apiResponse) => {
  const {
    data,
    meta,
    count_fees_total,
    count_fees_current,
    count_fees_expired,
  } = apiResponse;
  const transformedAcquirers = [];
  Object.entries(data).forEach((entry) => {
    transformedAcquirers.push({
      acquirer: entry[0],
      detail: entry[1],
    });
  });

  return {
    acquirers: transformedAcquirers,
    meta: {
      ...meta,
      totalConsolidatedFees: count_fees_total,
      totalCurrentFees: count_fees_current,
      totalExpiredFees: count_fees_expired,
    },
  };
};

const getFeeAcquirerList = async ({ storeId, page, perPage }) => {
  const config = {
    params: {
      page,
      per_page: perPage,
      store_id: storeId,
    },
  };
  const response = await lambdaApi().get('/fees/consolidated/summary', config);

  return transformer(response.data);
};
export default getFeeAcquirerList;
