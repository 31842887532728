import { actionTypes } from 'store/ducks/fees/deleteFee/types';

const INITIAL_STATE = {
  loading: false,
  error: undefined,
  errorMessage: undefined,
  success: false,
  isError: false,
  feeLoading: [],
};
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.DELETE_FEE_START:
      return {
        ...state,
        loading: true,
        error: undefined,
        errorMessage: undefined,
        success: false,
        isError: false,
        feeLoading: [action.payload.feeId],
      };

    case actionTypes.DELETE_FEE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        errorMessage: undefined,
        success: true,
        isError: false,
        feeLoading: [],
      };
    case actionTypes.DELETE_FEE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error.error,
        errorMessage: action.payload,
        success: false,
        isError: true,
        feeLoading: [],
      };
    case actionTypes.RESET_DELETE_FEE_STATUS:
      return {
        ...state,
        success: false,
      };
    default:
      return state;
  }
};
export default reducer;
