import React, { useEffect } from 'react';
import Modal from 'antd/lib/modal/Modal';
import { StModalCancelButton, StModalRemoveButton } from './styled';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Row, Col } from 'antd';
import { deleteUser, resetSuccess } from 'store/ducks/deleteUser/actions';
import { useDispatch, useSelector } from 'react-redux';
import { generalListOfClientsStart } from 'store/ducks/generalListOfClients/actions';

const DeletionModal = ({
  isRemoveModalVisible,
  setRemoveModalVisible,
  selectedRows,
  multiple,
}) => {
  const deleteSuccess = useSelector((state) => state.deleteUser.success);
  const dispatch = useDispatch();
  const deleteSelectedUsers = () => {
    const userIds = [];
    if (multiple) {
      selectedRows.forEach((selectedRow) => {
        userIds.push(selectedRow?.actions?.user_id);
      });
    } else {
      selectedRows.forEach((selectedRow) => {
        userIds.push(selectedRow?.user_id);
      });
    }
    dispatch(deleteUser({ users: userIds }));
    setRemoveModalVisible(false);
  };

  useEffect(() => {
    if (deleteSuccess) {
      dispatch(resetSuccess({}));
      setTimeout(() => {
        dispatch(
          generalListOfClientsStart({
            filterType: 'client',
            filter: '',
            page: 1,
            perPage: 10000,
          })
        );
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteSuccess]);

  return (
    <Modal
      open={isRemoveModalVisible}
      style={{ maxWidth: '322px' }}
      onCancel={() => setRemoveModalVisible(false)}
      footer={[
        <StModalCancelButton
          onClick={() => {
            setRemoveModalVisible(false);
          }}
        >
          Cancelar
        </StModalCancelButton>,
        <StModalRemoveButton danger onClick={deleteSelectedUsers}>
          Excluir
        </StModalRemoveButton>,
      ]}
      maskClosable
    >
      <Row align="top">
        <Col span={2}>
          <InfoCircleOutlined style={{ marginTop: '4px' }} />
        </Col>
        <Col span={22}>
          <p style={{ marginRight: '22px' }}>
            Tem certeza que deseja excluir o(s) usuário(s) selecionado(s)?
          </p>
          {selectedRows !== undefined ? (
            <>
              {selectedRows.map((selectedRow) => {
                return (
                  <strong key={Math.random()}>
                    {multiple ? (
                      <p>- {selectedRow?.userEmail}</p>
                    ) : (
                      <p>- {selectedRow?.email}</p>
                    )}
                  </strong>
                );
              })}
            </>
          ) : (
            <></>
          )}
        </Col>
      </Row>
    </Modal>
  );
};

DeletionModal.propTypes = {};

export default DeletionModal;
