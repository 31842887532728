import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Row, Col, Typography } from 'antd';

import { TableCustomColumnsSelect } from 'components/Reports/SalesReconciliationReports/components';
import { StContainer, StLimiter } from './styled';

const TableHeader = ({
  title,
  totalText,
  totalValue,
  columns,
  defaultSelectedColumns,
}) => {
  const { useBreakpoint } = Grid;
  const breakpoint = useBreakpoint();

  return (
    <StContainer>
      <StLimiter $breakpoint={breakpoint}>
        <Row justify="space-between" align="middle">
          <Col>
            <Typography.Text style={{ fontSize: '20px' }} strong>
              {title}
            </Typography.Text>
          </Col>
          <Col>
            <Typography.Text>{`${totalText} ${totalValue}`}</Typography.Text>
          </Col>
          <Col>
            <TableCustomColumnsSelect
              columns={columns}
              defaultSelectedColumns={defaultSelectedColumns}
            />
          </Col>
        </Row>
      </StLimiter>
    </StContainer>
  );
};

TableHeader.propTypes = {
  title: PropTypes.string.isRequired,
  totalText: PropTypes.string.isRequired,
  totalValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  defaultSelectedColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default TableHeader;
