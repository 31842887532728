/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'antd';

import { SummaryCard } from '../SummaryCard';

import { StLimiter, StContainer } from './styled';
import { ListComponent } from '../ListComponent';
import { shortId } from 'helpers/shortId';

const ContentListBanks = ({
  isBanksError,
  banksLoading,
  banksGrossAmount,
  banks,
}) => {
  const { useBreakpoint } = Grid;
  const breakpoint = useBreakpoint();
  return (
    <StContainer>
      <StLimiter $breakpoint={breakpoint}>
        <SummaryCard
          banksGrossAmount={banksGrossAmount}
          isError={isBanksError}
          loading={banksLoading}
        />
        <br />
        {banks.map((item) => {
          return (
            <ListComponent
              bank_color={item.bank_color}
              name={item.name}
              payments={item.payment_data}
              total_per_bank={item.total_per_bank.toString()}
              key={shortId()}
              bank_code={item.bank_code}
            />
          );
        })}
      </StLimiter>
    </StContainer>
  );
};
ContentListBanks.defaultProps = {
  banksGrossAmount: '0',
};

ContentListBanks.propTypes = {
  banksGrossAmount: PropTypes.string,
  isBanksError: PropTypes.bool.isRequired,
  banksLoading: PropTypes.bool.isRequired,
  banks: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ContentListBanks;
