import styled from 'styled-components';
import { marginMainContainer } from 'helpers/style';
import { Card, Typography } from 'antd';
import { colors } from 'styles/colors';

export const StOffersTitleWrapper = styled.div`
  margin: ${(props) => marginMainContainer(props?.$breakpoint)};
  padding-top: 40px;
  margin-left: 3.9375rem;
  margin-right: 3.9375rem;
`;

export const StOffersTitle = styled(Typography.Title)`
  &&& {
    margin-bottom: 0;
    color: ${colors.gray9};
    font-size: 30px;
    display: flex;
    align-items: center;
    font-weight: 500;
  }
`;
export const StContainer = styled.div`
  &&& {
    height: 100%;
    margin-top: 3rem;
  }
`;

export const StShowCase = styled(Card)`
  &&& {
    border-radius: 4px;
  }
`;

export const StText = styled(Typography.Text)`
  &&& {
    font-weight: 400;
    color: ${colors.gray8};
    font-size: 14px;
  }
`;
