import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Select } from 'antd';
import { Container } from '../../styled';
import { ConfirmationModal } from '../ConfirmationModal';
import { useDispatch, useSelector } from 'react-redux';
import { listOfClientsStart } from 'store/ducks/listOfClients/actions';
import { SelectInfiniteScroll } from 'commons/SelectInfiniteScroll';
import { acquirerStart } from 'store/ducks/acquirer/actions';
import { useAcquirer } from 'utils/hooks/useAcquirer';
import { conciliationTypes } from './conciliationTypes';
import * as St from './styled';
import { fortyFiveDaysAgo } from 'helpers/dates';
import DatePicker from 'commons/DatePicker/DatePicker';
import dayjs from 'dayjs';

const TopForm = () => {
  const dispatch = useDispatch();
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const [topForm] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [page, setPage] = useState(1);
  const [searchFilter, setSearchFilter] = useState('');
  const [isAcquirerDisabled, setIsAcquirerDisabled] = useState(true);
  const hasMoreClients = useSelector((state) => state.listOfClients.hasMore);
  const listOfClients = useSelector((state) => state.listOfClients.clients);
  const loadingListOfClients = useSelector(
    (state) => state.listOfClients.loading
  );
  const dataHasBeenPosted = useSelector(
    (state) => state.retroactiveConciliation.dataHasBeenPosted
  );
  const { period, conciliationType, client, acquirer } =
    topForm.getFieldsValue(true);
  const {
    acquirers,
    loading: loadingAcquirers,
    getAllAcquirers,
  } = useAcquirer(true);

  useEffect(() => {
    getAllAcquirers(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(
      listOfClientsStart({
        filter: searchFilter,
        page: searchFilter !== '' ? 1 : page,
        isReconciliationRules: false,
      })
    );
  }, [page, searchFilter, dispatch]);

  useEffect(() => {
    if (dataHasBeenPosted) {
      setIsModalVisible(!isModalVisible);
      setSearchFilter('');
      topForm.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataHasBeenPosted]);

  const transformListOfClients = (_listOfClients = []) =>
    _listOfClients.map(
      ({ client_id: clientId, client_code: clientCode, name }) => ({
        value: clientId,
        label: `${clientCode} ${name}`,
      })
    );

  const onClientSelected = ({ value, label }) => {
    topForm.resetFields(['acquirer']);
    if (value) {
      dispatch(
        acquirerStart({
          clientId: value,
          isPixRequired: true,
        })
      );
      topForm.setFieldsValue({
        client: {
          id: value || '',
          name: label || '',
        },
      });
      setIsAcquirerDisabled(false);
    } else {
      topForm.resetFields(['client']);
      setIsAcquirerDisabled(true);
    }
  };

  const onChangePeriod = (dates) => {
    if (dates) {
      return topForm.setFieldsValue({
        period: [dates[0], dates[1]],
      });
    }
    return topForm.setFieldsValue({
      period: [],
    });
  };

  const onChangeConciliationType = (value) =>
    topForm.setFieldsValue({
      conciliationType: value || '',
    });

  const onChangeAcquirer = (value) =>
    topForm.setFieldsValue({
      acquirer: value || '',
    });

  const onCheck = async () => {
    try {
      await topForm.validateFields();
      setIsModalVisible(!isModalVisible);
    } catch (error) {
      return error;
    }
    return false;
  };

  // TODO: refactor this function to use a helper constant
  // 18 meses atrás a partir da data atual
  const eighteenMonthsAgoFromCurrentDay = dayjs().subtract(18, 'month');

  const disableRangePickerDate = (current) => {
    if (
      eighteenMonthsAgoFromCurrentDay > current ||
      fortyFiveDaysAgo <= current
    ) {
      return true;
    }
    return false;
  };

  return (
    <Container isBottom={false} isFixed>
      <St.TopTitle>Conciliação</St.TopTitle>
      <St.TopText>
        Dispare o processo de conciliação manualmente. Selecione o período, tipo
        de conciliação, cliente e adquirente.
      </St.TopText>

      <Row gutter={[16, 0]}>
        <St.TopForm
          name="topForm"
          layout="vertical"
          requiredMark={false}
          form={topForm}
          initialValues={{
            period: [],
            conciliationType: '',
            client: {},
            acquirer: '',
          }}
        >
          <Col span={5}>
            <Form.Item
              label="Período"
              colon={false}
              name="period"
              rules={[{ required: true, message: 'Campo obrigatório!' }]}
            >
              <Row>
                <RangePicker
                  style={{ width: '100%' }}
                  onChange={onChangePeriod}
                  format={['DD/MM/YYYY', 'DD/MM/YYYY']}
                  inputReadOnly
                  disabledDate={disableRangePickerDate}
                />
              </Row>
            </Form.Item>
          </Col>
          <Col lg={5}>
            <Form.Item
              label="Tipo de conciliação"
              colon={false}
              name="conciliationType"
              rules={[{ required: true, message: 'Campo obrigatório!' }]}
            >
              <Row style={{ width: '100%' }}>
                <Select
                  style={{ width: '100%' }}
                  allowClear
                  placeholder="Selecione o tipo de Conciliação"
                  onChange={onChangeConciliationType}
                >
                  {Object.entries(conciliationTypes).map(([key, value]) => (
                    <Option value={key} key={key}>
                      {value}
                    </Option>
                  ))}
                </Select>
              </Row>
            </Form.Item>
          </Col>
          <Col lg={5}>
            <Form.Item
              label="Cliente"
              colon={false}
              name="client"
              rules={[
                {
                  required: true,
                },
                () => ({
                  validator(_, value) {
                    if (!value?.id) {
                      return Promise.reject(new Error('Campo obrigatório!'));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Row style={{ width: '100%' }}>
                <SelectInfiniteScroll
                  hasMoreItems={hasMoreClients}
                  loadingItems={loadingListOfClients}
                  options={transformListOfClients(listOfClients)}
                  placeholder="Selecione o cliente"
                  setPage={setPage}
                  setSearchFilter={setSearchFilter}
                  onOptionClick={onClientSelected}
                />
              </Row>
            </Form.Item>
          </Col>
          <Col lg={5}>
            <Form.Item
              label="Adquirente"
              colon={false}
              name="acquirer"
              rules={[{ required: true, message: 'Campo obrigatório!' }]}
            >
              <Row style={{ width: '100%' }}>
                <Select
                  style={{ width: '100%' }}
                  allowClear
                  placeholder="Selecione a Adquirente"
                  onChange={onChangeAcquirer}
                  loading={loadingAcquirers}
                  disabled={isAcquirerDisabled}
                >
                  {acquirers.map(({ acquirer_id: acquirerId, name }) => (
                    <Option key={acquirerId} value={acquirerId}>
                      {name}
                    </Option>
                  ))}
                </Select>
              </Row>
            </Form.Item>
          </Col>
          <Col lg={4}>
            <Form.Item
              label=""
              colon={false}
              style={{ position: 'absolute', bottom: 0, width: '100%' }}
            >
              <Row>
                <Button
                  style={{ width: '100%' }}
                  type="primary"
                  onClick={onCheck}
                >
                  Solicitar conciliação
                </Button>
              </Row>
            </Form.Item>
          </Col>
        </St.TopForm>
      </Row>
      {isModalVisible && (
        <ConfirmationModal
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          type={conciliationType}
          client={client}
          startDate={period[0]}
          endDate={period[1]}
          acquirer={acquirer}
        />
      )}
    </Container>
  );
};

export default TopForm;
