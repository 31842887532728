import styled from 'styled-components';
import { Col, Row, Spin } from 'antd';
import { colors } from 'styles/colors';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

export const ActionsRow = styled(Row)`
  &&& {
    justify-content: center;
    gap: 24px;
  }
`;

export const EditColumn = styled(Col)`
  &&& {
    color: ${colors.primary6};
  }
`;

export const EditIcon = styled(EditOutlined)`
  &&& {
    font-size: 16px;
  }
`;

export const DeleteIcon = styled(DeleteOutlined)`
  &&& {
    font-size: 16px;
  }
`;
export const SpinIcon = styled(Spin)`
  &&& {
    font-size: 16px;
  }
`;

export const DeleteColumn = styled(Col)`
  &&& {
    color: ${colors.red5};
  }
`;
