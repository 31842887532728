import React, { useState } from 'react';
import { Col, Grid, Row, Tabs } from 'antd';
import { ImportFile } from './components/ImportFile';
import { ExtratosBancariosOFX } from './components/ExtratosBancariosOFX';
import { StContainer, StTitle, StWrapper } from '../../commons/Content/styled';

const ImportFiles = () => {
  const { useBreakpoint } = Grid;
  const [selectedTab, setSelectedTab] = useState('extratosofx');
  const breakpoint = useBreakpoint();

  const items = [
    {
      label: 'Extratos OFX',
      key: 'extratosofx',
      children: <ImportFile fileType="OFX" />,
    },
  ];

  return (
    <StContainer $breakpoint={breakpoint}>
      <Row align="middle" justify="space-between" style={{ marginTop: '4rem' }}>
        <Col>
          <StTitle>Importação de Arquivos</StTitle>
        </Col>
      </Row>
      <StWrapper>
        <Tabs
          items={items}
          defaultActiveKey={selectedTab}
          onTabClick={(key) => setSelectedTab(key)}
        />
      </StWrapper>
      <StWrapper>
        {selectedTab === 'extratosofx' && <ExtratosBancariosOFX />}
      </StWrapper>
    </StContainer>
  );
};

export default ImportFiles;
