import React, { forwardRef } from 'react';
import { StInputMasked } from '../../../styled';

const currencyConfig = {
  locale: 'pt-BR',
  formats: {
    number: {
      BRL: {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

const InputAmount = forwardRef(({ onChange, ...rest }, ref) => {
  return (
    <StInputMasked
      currency="BRL"
      config={currencyConfig}
      ref={ref}
      onChange={onChange}
      {...rest}
    />
  );
});

export default InputAmount;
