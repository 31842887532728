import { action } from 'typesafe-actions';
import { actionTypes } from 'store/ducks/reconciled/summary/sales/types';

export const salesSummaryReconciledStart = (data) =>
  action(actionTypes.SALES_SUMMARY_RECONCILED_START, data);
export const salesSummaryReconciledSuccess = (data) =>
  action(actionTypes.SALES_SUMMARY_RECONCILED_SUCCESS, data);
export const salesSummaryReconciledError = (errorMessage, error) =>
  action(
    actionTypes.SALES_SUMMARY_RECONCILED_ERROR,
    { errorMessage },
    undefined,
    { error }
  );
