import { action } from 'typesafe-actions';
import { actionTypes } from 'store/ducks/reconciled/summary/bank/types';

export const bankSummaryReconciledStart = (data) =>
  action(actionTypes.BANK_SUMMARY_RECONCILED_START, data);
export const bankSummaryReconciledSuccess = (data) =>
  action(actionTypes.BANK_SUMMARY_RECONCILED_SUCCESS, data);
export const bankSummaryReconciledError = (errorMessage, error) =>
  action(
    actionTypes.BANK_SUMMARY_RECONCILED_ERROR,
    { errorMessage },
    undefined,
    { error }
  );
