import { lambdaApi } from '../api';

const deleteStores = async ({ storeId }) => {
  const config = {
    params: {
      store_id: storeId,
    },
  };
  const response = await lambdaApi().delete(
    '/settings/maintenance/inactivate/stores',
    config
  );
  return response.data;
};
export default deleteStores;
