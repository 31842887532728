import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import {
  StBankColor,
  StBankLegendGridCol,
  StBankLegendGridRow,
} from './styled';
import { EllipsisText } from 'commons/EllipsisText';
import { Value } from 'commons/Value';
import { shortId } from 'helpers/shortId';

const BankLegendGrid = ({ banksData }) => {
  const colorsBank = banksData?.colorsBank;
  const dataBanks = banksData?.dataBanks;
  const nameBanks = banksData?.nameBanks;

  const banks = dataBanks?.map((bank, index) => {
    return (
      <StBankLegendGridCol span={8} key={shortId()}>
        <Row align="middle" justify="space-between">
          <Col span={2}>
            <StBankColor color={colorsBank[index]} />
          </Col>
          <Col span={22}>
            <EllipsisText text={nameBanks[index]} length={20} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Value
              value={new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              }).format(
                !dataBanks[index] || dataBanks[index] === 0
                  ? '0'
                  : dataBanks[index]
              )}
              valueTextSize="medium"
              textAlign="start"
            />
          </Col>
        </Row>
      </StBankLegendGridCol>
    );
  });

  return (
    <StBankLegendGridRow justify="start" gutter={[16, 16]}>
      {banks}
    </StBankLegendGridRow>
  );
};

BankLegendGrid.propTypes = {
  banksData: PropTypes.shape({}).isRequired,
};

export default BankLegendGrid;
