import errorHandler from 'helpers/errorHandler';
import { useState, useEffect, useCallback } from 'react';
import getListStoresFromAPI from 'services/cnpjMaintenance/listStores';

function useStoreList(searchFilter, page, setPage, perPage = 10) {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [stores, setStores] = useState([]);
  const [storesData, setStoresData] = useState([]);
  const [metaData, setMetaData] = useState({});

  const fetchStores = useCallback(
    (pageNumber) => {
      setIsLoading(true);
      setIsError(false);
      return getListStoresFromAPI({
        storeId: searchFilter,
        page: pageNumber || page,
        perPage,
      })
        .then(({ stores: data = [], meta = {} }) => {
          setStoresData(data);
          setMetaData(meta);
        })
        .catch((err) => {
          if (err) {
            errorHandler('Ocorreu um erro:', err);
          }
          setIsError(true);
          setIsLoading(false);
        })
        .finally(() => setIsLoading(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchFilter, page]
  );

  useEffect(() => {
    setStores([]);
    fetchStores(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFilter]);

  // limpar filtro e com scroll de lojas sequencial
  useEffect(() => {
    if (!searchFilter && page > 1) {
      setPage(1);
      setStores([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFilter]);

  useEffect(() => {
    if (page > 1) {
      fetchStores();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    setStores((prev) => {
      const uniqueStores = [...prev, ...storesData].reduce((acc, current) => {
        if (!acc.find((store) => store.store_id === current.store_id)) {
          acc.push(current);
        }
        return acc;
      }, []);

      return uniqueStores;
    });
  }, [storesData]);

  const refreshStores = () => {
    setStores([]);
    fetchStores(1);
  };

  return {
    isLoading,
    isError,
    stores,
    metaData,
    refreshStores,
  };
}

export default useStoreList;
