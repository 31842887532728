import { lambdaApi } from '../api';

const deleteReconciliationRules = async ({ ruleId }) => {
  const config = {
    params: {
      rule_id: ruleId,
    },
  };

  const response = await lambdaApi().delete('/conciliation/rules', config);
  return response.data;
};

export default deleteReconciliationRules;
