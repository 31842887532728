import React, { PureComponent } from 'react';

class CustomizedAxisTick extends PureComponent {
  render() {
    const { x, y, payload, fontSize } = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={16}
          y={0}
          dy={16}
          textAnchor="end"
          fontSize={fontSize}
          fill="#333"
          transform="rotate(-35)"
        >
          {payload.value}
        </text>
      </g>
    );
  }
}

export default CustomizedAxisTick;
