import { action } from 'typesafe-actions';
import { actionTypes } from 'store/ducks/radar/radarRunResume/types';

export const radarRunResumeStart = (data) =>
  action(actionTypes.RADAR_RUN_RESUME_START, data);
export const radarRunResumeSuccess = (data) =>
  action(actionTypes.RADAR_RUN_RESUME_SUCCESS, data);
export const radarRunResumeError = (errorMessage, error) =>
  action(actionTypes.RADAR_RUN_RESUME_ERROR, { errorMessage }, undefined, {
    error,
  });
