import { actionTypes } from 'store/ducks/amountsReceivableSummary/types';

const INITIAL_STATE = {
  error: undefined,
  errorMessage: undefined,
  loading: false,
  isError: false,
  summary: {},
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.AMOUNTS_RECEIVABLE_SUMMARY_START:
      return {
        ...state,
        loading: true,
        error: undefined,
        errorMessage: undefined,
        isError: false,
      };
    case actionTypes.AMOUNTS_RECEIVABLE_SUMMARY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        summary: action.payload,
        isError: false,
        errorMessage: undefined,
      };
    case actionTypes.AMOUNTS_RECEIVABLE_SUMMARY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error.error,
        errorMessage: action.payload,
        isError: true,
        summary: undefined,
      };
    default:
      return state;
  }
};

export default reducer;
