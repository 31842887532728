import styled from 'styled-components';

import { marginMainContainer } from 'helpers/style';
import { Button, Col, Divider, Typography } from 'antd';
import { colors } from 'styles/colors';

export const StContainer = styled.div`
  margin-top: 3rem;
  padding: 0 32px;
`;

export const StLimiter = styled.div`
  margin: ${(props) => marginMainContainer(props.$breakpoint)};
`;

export const StTableContainer = styled.div`
  margin-top: 170px;
  position: absolute;
  width: 100%;
`;

export const StExportButton = styled(Button)`
  &&& {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    background-color: ${colors.primary6};
    color: ${colors.gray1};
    border-radius: 2px;
  }
`;

export const StDivider = styled(Divider)`
  &&& {
    width: 0.125rem;
    height: 20px;
    color: ${colors.gray9};
  }
`;

export const StTotalText = styled(Typography.Text)`
  &&& {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: ${colors.gray9};
  }
`;

export const StTotalizersCol = styled(Col)`
  &&& {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 1000px;
  }
`;
