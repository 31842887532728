import { getSessionClientId } from 'helpers/sessionService';
import { apiGateway } from './api';

const transform = (response) => {
  const { data } = response;
  return data;
};

const postSendInvitationRequestFromAPI = async (data) => {
  const invitationData = {
    to: data.to,
    name: data.name,
    surname: data.surname,
    scope_id: data.scope_id,
    profile_id: data.profile_id,
    client_id: getSessionClientId(),
    phone: data.phone,
  };
  const response = await apiGateway().post('/invitation/post', invitationData);
  return transform(response.data);
};

export default postSendInvitationRequestFromAPI;
