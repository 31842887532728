import React from 'react';
import PropTypes from 'prop-types';
import { setFontSizeAcordingWithProps } from 'helpers/style';
import { StContainer, StTypographyTextValue } from './styled';
import { colors } from 'styles/colors';

const Value = ({ color, value, valueTextSize, textAlign, strong }) => {
  return (
    <StContainer textAlign={textAlign}>
      <StTypographyTextValue
        fontSize={setFontSizeAcordingWithProps(valueTextSize)}
        color={color}
        strong={strong}
      >
        {value}
      </StTypographyTextValue>
    </StContainer>
  );
};

Value.defaultProps = {
  value: '',
  color: colors.gray11,
  valueTextSize: 'medium',
  textAlign: 'start',
  strong: false,
};

Value.propTypes = {
  color: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  valueTextSize: PropTypes.oneOf(['small', 'medium', 'large', 'medium_14']),
  textAlign: PropTypes.oneOf([
    'left',
    'right',
    'center',
    'justify',
    'justify-all',
    'start',
    'end',
    'match-parent',
  ]),
  strong: PropTypes.bool,
};

export default Value;
