import { action } from 'typesafe-actions';
import { actionTypes } from './types';

export const cnpjMaintenanceInfoFiltersStart = (data) =>
  action(actionTypes.CNPJ_MAINTENANCE_INFO_FILTERS_START, data);
export const cnpjMaintenanceInfoFiltersSuccess = (data) =>
  action(actionTypes.CNPJ_MAINTENANCE_INFO_FILTERS_SUCCESS, data);
export const cnpjMaintenanceInfoFiltersError = (errorMessage, error) =>
  action(
    actionTypes.CNPJ_MAINTENANCE_INFO_FILTERS_ERROR,
    { errorMessage },
    undefined,
    { error }
  );
