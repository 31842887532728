export const actionTypes = {
  GET_NOTIFICATION_START: '@notifications/GET_NOTIFICATION_START',
  GET_NOTIFICATION_SUCCESS: '@notifications/GET_NOTIFICATION_SUCCESS',
  GET_NOTIFICATION_ERROR: '@notifications/GET_NOTIFICATION_ERROR',
  CREATE_NOTIFICATION_START: '@notifications/CREATE_NOTIFICATION_START',
  CREATE_NOTIFICATION_SUCCESS: '@notifications/CREATE_NOTIFICATION_SUCCESS',
  CREATE_NOTIFICATION_ERROR: '@notifications/CREATE_NOTIFICATION_ERROR',
  CHANGE_NOTIFICATION_STATUS_START:
    '@notifications/CHANGE_NOTIFICATION_STATUS_START',
  CHANGE_NOTIFICATION_STATUS_SUCCESS:
    '@notifications/CHANGE_NOTIFICATION_STATUS_SUCCESS',
  CHANGE_NOTIFICATION_STATUS_ERROR:
    '@notifications/CHANGE_NOTIFICATION_STATUS_ERROR',
  GET_DOWNLOAD_URL_START: '@notifications/GET_DOWNLOAD_URL_START',
  GET_DOWNLOAD_URL_SUCCESS: '@notifications/GET_DOWNLOAD_URL_SUCCESS',
  GET_DOWNLOAD_URL_ERROR: '@notifications/GET_DOWNLOAD_URL_ERROR',
  MARK_ALERT_AS_SENT: '@notifications/MARK_ALERT_AS_SENT',
  UPDATE_NOTIFICATION_PAGE: '@notifications/UPDATE_NOTIFICATION_PAGE',
  UPDATE_NOTIFICATION_PAGE_SUCCESS:
    '@notifications/UPDATE_NOTIFICATION_PAGE_SUCCESS',
  UPDATE_NOTIFICATION_PER_PAGE: '@notifications/UPDATE_NOTIFICATION_PER_PAGE',
  UPDATE_NOTIFICATION_PER_PAGE_SUCCESS:
    '@notifications/UPDATE_NOTIFICATION_PER_PAGE_SUCCESS',
};
