import styled from 'styled-components';
import { Row, Typography } from 'antd';
import { colors } from 'styles/colors';

const { Title, Text } = Typography;

export const Container = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${(props) =>
    props?.$breakpoint?.lg ? '#f4f4f4' : '#c7ddd0'};
  margin-top: 72px;
`;

export const MainTitle = styled(Title)`
  font-size: 30px !important;
  font-weight: 500;
  margin: 0 0 24px 24px;
`;

export const MainColumn = styled.div`
  background-color: ${colors.gray1};
  padding: 16px;
  margin: 0 24px 24px 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const FilesText = styled(Text)`
  font-size: 20px !important;
  color: ${colors.gray9};
  font-weight: 500;
`;

export const FileDescText = styled(Text)`
  font-size: 14px !important;
  color: ${colors.gray8};
  font-weight: 400;
  margin: 4px 0 16px 0;
`;

export const TodayRow = styled(Row)`
  background-color: ${colors.secondary1};
  width: 100%;
`;

export const DayText = styled(Text)`
  font-size: 14px !important;
  margin-left: 30px;
`;

export const FilesRow = styled(Row)`
  display: flex;
  flex-direction: column;
`;

export const OtherDaysRow = styled(Row)`
  background-color: ${colors.secondary1};
  width: 100%;
  margin-top: 16px;
`;

export const PaginationContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
`;
