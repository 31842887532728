import styled from 'styled-components';
import { filtersTopPosition, marginMainContainer } from 'helpers/style';
import { Typography } from 'antd';
import { colors } from 'styles/colors';

export const StContainer = styled.div`
  background-color: ${colors.gray1};
  position: sticky;
  top: ${({ breakpoint }) => filtersTopPosition(breakpoint)};
  z-index: 100;
  margin-left: 3.9375rem;
  margin-right: 3.9375rem;
  border-radius: 3px;
  height: 80rem;

  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-tail::after,
  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    background-color: #d0d0d0 !important;
  }
  .ant-steps-item-title {
    font-size: 14px !important;
  }
  p {
    word-break: normal !important;
  }
`;

export const StBriefingTitleWrapper = styled.div`
  margin: ${(props) => marginMainContainer(props?.$breakpoint)};
  padding-top: 40px;
  margin-left: 3.9375rem;
  margin-right: 3.9375rem;
`;

export const StLockWrapper = styled.div`
  display: flex;
  justify-content: end;
`;

export const StBriefingTitle = styled(Typography.Title)`
  &&& {
    margin-bottom: 0;
    color: ${colors.gray11};
    font-size: 3.75rem;
    display: flex;
    align-items: center;
  }
`;

export const StBriefingTab = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 6px;
`;
