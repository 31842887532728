export const actionTypes = {
  BANK_CONCILIATION_FILTERS_START:
    '@bankConciliationFilters/BANK_CONCILIATION_FILTERS_START',
  BANK_CONCILIATION_FILTERS_SUCCESS:
    '@bankConciliationFilters/BANK_CONCILIATION_FILTERS_SUCCESS',
  BANK_CONCILIATION_FILTERS_ERROR:
    '@bankConciliationFilters/BANK_CONCILIATION_FILTERS_ERROR',
  BANK_CONCILIATION_FILTERS_ZERO_LENGTH:
    '@bankConciliationFilters/BANK_CONCILIATION_FILTERS_ZERO_LENGTH',
  UPDATE_BANK_CONCILIATION_FILTERS:
    '@bankConciliationFilters/UPDATE_BANK_CONCILIATION_FILTERS',
};
