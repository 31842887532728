import { call, put } from 'redux-saga/effects';

import errorHandler from 'helpers/errorHandler';

import {
  salesInfoSuccess,
  salesInfoError,
} from 'store/ducks/salesInfo/actions';

import getSalesInfoFromAPI from 'services/salesInfo';

export function* salesInfoSaga(action) {
  try {
    const { payload } = action;

    const apiData = yield call(getSalesInfoFromAPI, payload);

    yield put(salesInfoSuccess(apiData));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        salesInfoError(err.response.data.error, err.response.data.error)
      );
      errorHandler(err?.response?.data?.error?.message, err);
    }
  }
}
