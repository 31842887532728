import { action } from 'typesafe-actions';
import { actionTypes } from './types';

export const putReconciliationRulesStart = (data) =>
  action(actionTypes.PUT_RECONCILIATION_RULES_START, data);
export const putReconciliationRulesSuccess = (data) =>
  action(actionTypes.PUT_RECONCILIATION_RULES_SUCCESS, data);
export const putReconciliationRulesError = (errorMessage, error) =>
  action(
    actionTypes.PUT_RECONCILIATION_RULES_ERROR,
    { errorMessage },
    undefined,
    {
      error,
    }
  );
