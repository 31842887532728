import React from 'react';
import { StText } from './styled';

const RadarIdText = ({ currentRow, handleOpenModal, ...rest }) => {
  const { idt_radar: radarId } = currentRow;
  if (!currentRow) return null;
  if (!radarId) return null;

  return (
    <StText onClick={() => handleOpenModal({ id: radarId })} {...rest}>
      {radarId}
    </StText>
  );
};

export default RadarIdText;
