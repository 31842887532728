import { call, put, delay } from 'redux-saga/effects';
import errorHandler from 'helpers/errorHandler';
import getListOfClientsFiltersFromAPI from 'services/filters/listOfClientsFilters';
import {
  listOfClientsFiltersSuccess,
  listOfClientsFiltersError,
} from './actions';

export function* listOfClientsFiltersSaga(action) {
  const { payload } = action;
  try {
    const apiData = yield call(getListOfClientsFiltersFromAPI, payload);
    yield delay(500);
    yield put(listOfClientsFiltersSuccess(apiData));
  } catch (err) {
    yield put(listOfClientsFiltersError(err));
    errorHandler(err?.response?.data?.data?.message, err);
  }
}
