/* eslint-disable no-unneeded-ternary */
import React, { useState, useEffect, useContext } from 'react';
import {
  Checkbox,
  Col,
  Divider,
  Dropdown,
  Form,
  Modal,
  notification,
  Row,
  Select,
} from 'antd';
import { DownOutlined, EditOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { updateBranchDataHasBeenUpdated } from 'store/ducks/updateBranchData/actions';
import { listOfClientsStart } from 'store/ducks/listOfClients/actions';
import {
  isConciler,
  isSuperAdmin,
  isAdmin,
} from 'helpers/authorizationService';
import {
  getSessionClientCode,
  getSessionClientName,
} from 'helpers/sessionService';
import { tokenSandboxGetStart } from 'store/ducks/tokenSandbox/actions';
import { tokenProdGetStart } from 'store/ducks/tokenProd/actions';
import { TokenSandboxCard } from './components/TokenSandboxCard';
import { TokenProdCard } from './components/TokenProdCard';
import { plansStart } from 'store/ducks/plans/actions';
import { skinsStart } from 'store/ducks/skins/actions';
import { currentDay } from 'helpers/dates';
import { colors } from 'styles/colors';
import { useBranchDetailsForm } from './hooks/useBranchDetailsForm';
import { useMenu } from './hooks/useMenu';
import { updateIsImplantedStart } from 'store/ducks/updateIsImplanted/actions';
import * as St from './styled';
import { BranchManagementContext } from 'components/BranchManagement/BranchManagement';

const BranchDetailsDrawer = () => {
  const { visible, setVisible, closeDrawer, branchData, getListOfClients } =
    useContext(BranchManagementContext);

  const dispatch = useDispatch();

  const { Option } = Select;

  const [form] = Form.useForm();
  const isImplanted = useSelector((state) => state.updateIsImplanted.status);
  const updateBranchDataLoading = useSelector(
    (state) => state.updateBranchData.loading
  );
  const [userHasCopied, setUserHasCopied] = useState(false);
  const branchHasBeenCreated = useSelector(
    (state) => state.createBranch.success
  );
  const createBranchLoading = useSelector(
    (state) => state.createBranch.loading
  );
  const sandboxTokenHasBeenUpdated = useSelector(
    (state) => state.tokenSandbox.tokenHasBeenUpdated
  );
  const prodTokenHasBeenUpdated = useSelector(
    (state) => state.tokenProd.tokenHasBeenUpdated
  );

  // Sandbox
  const sandboxAppKey = useSelector((state) => state.tokenSandbox.appKey);
  const sandboxIsActive = useSelector((state) => state.tokenSandbox.isActive);
  const sandboxEndDate = useSelector((state) => state.tokenSandbox.endDate);
  const sandboxRequestDeleteAt = useSelector(
    (state) => state.tokenSandbox.requestDeleteAt
  );
  const sandboxPostLoading = useSelector(
    (state) => state.tokenSandbox.loadingPost
  );
  const sandboxGetLoading = useSelector(
    (state) => state.tokenSandbox.loadingGet
  );
  const sandboxDeleteLoading = useSelector(
    (state) => state.tokenSandbox.loadingDelete
  );
  const sandboxPutLoading = useSelector(
    (state) => state.tokenSandbox.loadingPut
  );
  const rowClientId = useSelector((state) => state.tokenSandbox.rowClientId);

  // Prod
  const prodAppKey = useSelector((state) => state.tokenProd.appKey);
  const prodIsActive = useSelector((state) => state.tokenProd.isActive);
  const prodEndDate = useSelector((state) => state.tokenProd.endDate);
  const prodRequestDeleteAt = useSelector(
    (state) => state.tokenProd.requestDeleteAt
  );
  const prodPostLoading = useSelector((state) => state.tokenProd.loadingPost);
  const prodGetLoading = useSelector((state) => state.tokenProd.loadingGet);
  const prodDeleteLoading = useSelector(
    (state) => state.tokenProd.loadingDelete
  );
  const prodPutLoading = useSelector((state) => state.tokenProd.loadingPut);

  const today = currentDay.format('YYYY-MM-DD');

  const branchDataHasBeenUpdated = useSelector(
    (state) => state.updateBranchData.branchDataHasBeenUpdated
  );

  const {
    isApiConnected,
    setIsApiConnected,
    name,
    setName,
    isUpdating,
    clientCode,
    skinInput,
    setSkinInput,
    planInput,
    setPlanInput,
    accessType,
    setAccessType,
    disableFields,
    setDisableFields,
    resetForm,
    onFinish,
    onFinishFailed,
    skins,
    plans,
  } = useBranchDetailsForm({ branchData, closeDrawer, isImplanted });

  const { handleSkinMenu, handlePlanMenu, handleTypeSelection } = useMenu({
    setSkinInput,
    setAccessType,
    setPlanInput,
  });

  const tokenSandboxCardProps = {
    sandboxRequestDeleteAt,
    sandboxDeleteLoading,
    sandboxPostLoading,
    sandboxIsActive,
    rowClientId,
    sandboxGetLoading,
    sandboxAppKey,
    setUserHasCopied,
    sandboxPutLoading,
    sandboxEndDate,
    today,
    isApiConnected,
    updateBranchDataLoading,
    getListOfClients,
  };

  const tokenProdCardProps = {
    prodRequestDeleteAt,
    prodDeleteLoading,
    prodPostLoading,
    prodIsActive,
    rowClientId,
    prodGetLoading,
    prodAppKey,
    setUserHasCopied,
    prodPutLoading,
    prodEndDate,
    today,
    isApiConnected,
    updateBranchDataLoading,
    getListOfClients,
  };

  let drawerTitle = 'Criar nova empresa';

  if (isUpdating) {
    drawerTitle = 'Gerenciar empresa';
  }

  const sessionClientCode = getSessionClientCode();
  const sessionClientName = getSessionClientName();

  useEffect(() => {
    dispatch(plansStart());
    dispatch(skinsStart());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userHasCopied) {
      notification.success({ message: 'Token copiado com sucesso!' });
      setUserHasCopied(false);
    }
  }, [userHasCopied]);

  useEffect(() => {
    if (rowClientId) {
      dispatch(
        tokenSandboxGetStart({
          type: 'SANDBOX',
          clientId: rowClientId,
          hidden: false,
        })
      );
      dispatch(
        tokenProdGetStart({
          type: 'PRODUCTION',
          clientId: rowClientId,
          hidden: false,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (sandboxTokenHasBeenUpdated) {
      dispatch(
        tokenSandboxGetStart({
          type: 'SANDBOX',
          clientId: rowClientId,
          hidden: false,
        })
      );
    }
    if (prodTokenHasBeenUpdated) {
      dispatch(
        tokenProdGetStart({
          type: 'PRODUCTION',
          clientId: rowClientId,
          hidden: false,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sandboxTokenHasBeenUpdated, prodTokenHasBeenUpdated]);

  useEffect(() => {
    dispatch(listOfClientsStart({ filter: '', page: 1, shouldReplace: true }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateBranchDataLoading]);

  useEffect(() => {
    if (branchDataHasBeenUpdated) {
      closeDrawer();
      dispatch(
        updateBranchDataHasBeenUpdated({
          status: false,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchDataHasBeenUpdated]);

  useEffect(() => {
    if (branchHasBeenCreated) {
      closeDrawer();
      getListOfClients();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchHasBeenCreated]);

  const items = [
    {
      label: 'Implantação',
      key: 'implantacao',
      onClick: () => {
        dispatch(updateIsImplantedStart(false));
      },
    },
    {
      label: 'Produção',
      key: 'producao',
      onClick: () => {
        dispatch(updateIsImplantedStart(true));
      },
    },
  ];

  const selectPlaceholder = (
    <div style={{ width: '100%' }}>
      <span>Selecionar</span>
      {!disableFields && (
        <DownOutlined style={{ fontSize: '12px', color: colors.primary6 }} />
      )}
    </div>
  );

  const accessTypeMenu = (
    <Select
      value={accessType.map((type) => type)}
      disabled={isSuperAdmin() ? disableFields : true}
      bordered={false}
      mode="multiple"
      placeholder={selectPlaceholder}
      style={{ width: '100%', color: colors.primary6 }}
      onDeselect={(value) => handleTypeSelection(value)}
      onSelect={(value) => handleTypeSelection(value)}
    >
      <Option value="Web">Web</Option>
      <Option value="App">App</Option>
    </Select>
  );

  const skinMenu = [
    ...Object.entries(skins).map(([value, key]) => {
      return {
        label: value,
        key,
        onClick: () => handleSkinMenu(key, value),
      };
    }),
  ];

  const planMenu = [
    ...Object.entries(plans).map(([value, key]) => {
      return {
        label: value,
        key,
        onClick: () => handlePlanMenu(key, value),
      };
    }),
  ];

  return (
    <Modal
      onCancel={() => setVisible(false)}
      destroyOnClose
      afterClose={() => resetForm()}
      open={visible}
      onClose={() => {
        resetForm();
      }}
      closable={!createBranchLoading && !updateBranchDataLoading}
      maskClosable={!createBranchLoading && !updateBranchDataLoading}
      width={1120}
      footer={null}
    >
      <>
        <St.Form
          key="branchDetailsDrawer"
          preserve={false}
          requiredMark={false}
          layout="vertical"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item>
            <St.HeaderRow>
              <St.Col>
                <St.ShopOutlinedIcon />
                <St.MainText>{drawerTitle}</St.MainText>
              </St.Col>
            </St.HeaderRow>
          </Form.Item>

          <St.Divider />

          <St.Col $spaceBetween $extraMargin>
            <St.MainText noMargin>Sobre a empresa</St.MainText>
            {!disableFields ? (
              <St.FormItemButtons>
                <Row justify="end">
                  <Col>
                    <St.CancelButton
                      onClick={() => {
                        if (isUpdating) {
                          resetForm();
                        } else {
                          closeDrawer();
                        }
                      }}
                      disabled={updateBranchDataLoading || createBranchLoading}
                    >
                      Cancelar
                    </St.CancelButton>
                  </Col>
                  <Col>
                    <St.Button
                      htmlType="submit"
                      type="primary"
                      disabled={updateBranchDataLoading || createBranchLoading}
                    >
                      Salvar
                    </St.Button>
                  </Col>
                </Row>
              </St.FormItemButtons>
            ) : (
              <></>
            )}
            <Col>
              {disableFields && (
                <>
                  {isConciler() && (isSuperAdmin() || isAdmin()) ? (
                    <St.Button
                      icon={<EditOutlined />}
                      onClick={() => {
                        setDisableFields(false);
                      }}
                      disabled={createBranchLoading || updateBranchDataLoading}
                    >
                      Editar
                    </St.Button>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </Col>
          </St.Col>
          {/* PRIMEIRA LINHA */}
          <Row gutter={[8, 8]}>
            <Col lg={4}>
              <St.FormItemDefault required wrapperCol={{ sm: 24 }}>
                <Row>
                  <Col>
                    <St.FieldTitle>Código da empresa</St.FieldTitle>
                    <St.Input
                      value={clientCode}
                      placeholder="Código da empresa"
                      disabled
                    />
                  </Col>
                </Row>
              </St.FormItemDefault>
            </Col>
            <Col lg={20}>
              <St.FormItemDefault required wrapperCol={{ sm: 24 }}>
                <St.FieldTitle>Nome da empresa</St.FieldTitle>
                <St.Input
                  placeholder="Nome da empresa"
                  onChange={(e) => {
                    const targetValue = e.target.value;
                    setName(targetValue);
                  }}
                  disabled={isSuperAdmin() ? disableFields : true}
                  value={name}
                />
              </St.FormItemDefault>
            </Col>
          </Row>

          {/* SEGUNDA LINHA */}
          <Row gutter={[8, 8]}>
            <Col lg={5}>
              <St.FormItemDefault required>
                <Col>
                  <St.FieldTitle>Estágio</St.FieldTitle>
                </Col>
                <Col>
                  <Dropdown menu={{ items }} disabled={disableFields}>
                    <St.IsImplantedContainer>
                      <St.IsImplantedParagraph $disableFields={disableFields}>
                        {isImplanted ? 'Produção' : 'Implantação'}
                      </St.IsImplantedParagraph>
                      {!disableFields && (
                        <St.DownOutlinedIcon $disableFields={disableFields} />
                      )}
                    </St.IsImplantedContainer>
                  </Dropdown>
                </Col>
              </St.FormItemDefault>
            </Col>
            <Col lg={5}>
              <St.FormItemDefault required wrapperCol={{ sm: 24 }}>
                <Row>
                  <Col>
                    <St.FieldTitle>Tipos de acesso</St.FieldTitle>
                    {accessTypeMenu}
                  </Col>
                </Row>
              </St.FormItemDefault>
            </Col>
            <Col lg={5}>
              <St.FormItemDefault required wrapperCol={{ sm: 24 }}>
                <Row>
                  <Col>
                    <St.FieldTitle>Skin</St.FieldTitle>
                    <Dropdown
                      menu={{ items: skinMenu }}
                      disabled={isSuperAdmin() ? disableFields : true}
                    >
                      <St.SkinContainer>
                        <St.SkinParagraph
                          $boolValue={isSuperAdmin() ? disableFields : true}
                        >
                          {skinInput?.name}
                        </St.SkinParagraph>
                        {!(isSuperAdmin() ? disableFields : true) && (
                          <St.DownOutlinedIcon />
                        )}
                      </St.SkinContainer>
                    </Dropdown>
                  </Col>
                </Row>
              </St.FormItemDefault>
            </Col>
            <Col lg={5}>
              <St.FormItemDefault required wrapperCol={{ sm: 24 }}>
                <St.FieldTitle>Plano</St.FieldTitle>
                <Dropdown
                  menu={{ items: planMenu }}
                  disabled={isSuperAdmin() ? disableFields : true}
                >
                  <St.PlanContainer>
                    <St.PlanParagraph
                      $boolValue={isSuperAdmin() ? disableFields : true}
                    >
                      {planInput?.name}
                    </St.PlanParagraph>
                    {!(isSuperAdmin() ? disableFields : true) && (
                      <St.DownOutlinedIcon />
                    )}
                  </St.PlanContainer>
                </Dropdown>
              </St.FormItemDefault>
            </Col>
            <Col lg={4} style={{ display: 'flex', alignItems: 'end' }}>
              <Form.Item required>
                <Checkbox
                  checked={isApiConnected}
                  onChange={() => {
                    setIsApiConnected(!isApiConnected);
                  }}
                  disabled={isSuperAdmin() ? disableFields : true}
                  value={isApiConnected}
                >
                  Possui API 2.1
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>

          {/* TERCEIRA LINHA */}
          <Row gutter={[8, 8]}>
            <Col lg={6}>
              <St.FormItemDefault required wrapperCol={{ sm: 24 }}>
                <St.FieldTitle>ID Parceiro</St.FieldTitle>
                <St.Input placeholder="ID Parceiro" disabled />
              </St.FormItemDefault>
            </Col>
            <Col lg={6}>
              <St.FormItemDefault required wrapperCol={{ sm: 24 }}>
                <St.FieldTitle>Tipo de parceria</St.FieldTitle>
                <St.Input placeholder="Tipo de parceria" disabled />
              </St.FormItemDefault>
            </Col>
            <Col lg={6}>
              <St.FormItemDefault required wrapperCol={{ sm: 24 }}>
                <St.FieldTitle>Retroativo</St.FieldTitle>
                <St.Input placeholder="Sem retroativo" disabled />
              </St.FormItemDefault>
            </Col>
            <Col lg={6}>
              <St.FormItemDefault required wrapperCol={{ sm: 24 }}>
                <St.FieldTitle>
                  Volumetria (quantidade de transações)
                </St.FieldTitle>
                <St.Input placeholder="Volumetria" disabled />
              </St.FormItemDefault>
            </Col>
          </Row>

          {/* QUARTA LINHA */}
          <Row gutter={[8, 8]}>
            <Col lg={6}>
              <St.FormItemDefault required wrapperCol={{ sm: 24 }}>
                <St.FieldTitle>Tipo de cobrança</St.FieldTitle>
                <St.Input placeholder="Mensalidade" disabled />
              </St.FormItemDefault>
            </Col>
            <Col lg={6}>
              <St.FormItemDefault required wrapperCol={{ sm: 24 }}>
                <St.FieldTitle>Valor de mensalidade</St.FieldTitle>
                <St.Input placeholder="Valor de mensalidade" disabled />
              </St.FormItemDefault>
            </Col>
            <Col lg={6}>
              <St.FormItemDefault required wrapperCol={{ sm: 24 }}>
                <St.FieldTitle>Quantidade de CNPJs cadastrados</St.FieldTitle>
                <St.Input placeholder="Quantidade" disabled />
              </St.FormItemDefault>
            </Col>
            <Col lg={6}>
              <St.FormItemDefault required wrapperCol={{ sm: 24 }}>
                <St.FieldTitle>Valor por CNPJ</St.FieldTitle>
                <St.Input placeholder="Valor por CNPJ" disabled />
              </St.FormItemDefault>
            </Col>
          </Row>

          {/* QUINTA LINHA */}
          {isConciler() &&
            isUpdating &&
            !sessionClientCode.includes('SANDBOX') &&
            !sessionClientName.includes('SANDBOX') && (
              <Row gutter={[8, 8]}>
                <Divider />
                <St.Col>
                  <St.MainText noMargin>Controle da API</St.MainText>
                </St.Col>
              </Row>
            )}

          {/* Cards */}
          {isConciler() &&
            isUpdating &&
            !sessionClientCode.includes('SANDBOX') &&
            !sessionClientName.includes('SANDBOX') && (
              <Row gutter={[8, 8]}>
                <Col lg={12}>
                  <TokenSandboxCard {...tokenSandboxCardProps} />
                </Col>
                <Col lg={12}>
                  <TokenProdCard {...tokenProdCardProps} />
                </Col>
              </Row>
            )}
        </St.Form>
      </>
    </Modal>
  );
};

export default BranchDetailsDrawer;
