import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Skeleton } from 'antd';
import { SummaryCard } from 'components/Intermediate/SalesReconciliation/components';
import { ContentCards } from 'components/Intermediate/SalesReconciliation/components/ContentCards';
import { StLimiter, StContainer, StTabs } from './styled';
import { marginMainContainer } from 'helpers/style';
import { colors } from 'styles/colors';
import dayjs from 'dayjs';

const ContentTab = ({
  startDate,
  endDate,
  summaryData,
  isSummaryError,
  summaryLoading,
  infoData,
  isInfoError,
  infoLoading,
  activeTab,
  infoCount,
  infoFilter,
}) => {
  return (
    <>
      <Skeleton loading={summaryLoading} active>
        <SummaryCard
          data={summaryData}
          isError={isSummaryError}
          loading={summaryLoading}
        />
      </Skeleton>
      <br />
      <Skeleton loading={infoLoading} active paragraph={{ rows: 10 }}>
        <ContentCards
          data={infoData}
          loading={infoLoading}
          error={isInfoError}
          startDate={startDate}
          endDate={endDate}
          activeTab={activeTab}
          infoCount={infoCount}
          infoFilter={infoFilter}
        />
      </Skeleton>
    </>
  );
};

const ContentTabs = ({
  startDate,
  endDate,
  summaryData,
  isSummaryError,
  summaryLoading,
  infoData,
  isInfoError,
  infoLoading,
  onTabClick,
  activeTab,
  infoCount,
  infoFilter,
}) => {
  const { useBreakpoint } = Grid;
  const breakpoint = useBreakpoint();

  const items = [
    {
      label: 'Filiais',
      key: 'branches',
      children: (
        <StLimiter $breakpoint={breakpoint}>
          {activeTab === 'branches' && (
            <ContentTab
              startDate={startDate}
              endDate={endDate}
              summaryData={summaryData}
              isSummaryError={isSummaryError}
              summaryLoading={summaryLoading}
              infoData={infoData}
              isInfoError={isInfoError}
              infoLoading={infoLoading}
              activeTab={activeTab}
              infoCount={infoCount}
              infoFilter={infoFilter}
            />
          )}
        </StLimiter>
      ),
    },
    {
      label: 'Adquirentes',
      key: 'acquirers',
      children: (
        <StLimiter $breakpoint={breakpoint}>
          {activeTab === 'acquirers' && (
            <ContentTab
              startDate={startDate}
              endDate={endDate}
              summaryData={summaryData}
              isSummaryError={isSummaryError}
              summaryLoading={summaryLoading}
              infoData={infoData}
              isInfoError={isInfoError}
              infoLoading={infoLoading}
              activeTab={activeTab}
              infoCount={infoCount}
              infoFilter={infoFilter}
            />
          )}
        </StLimiter>
      ),
    },
    {
      label: 'Produtos',
      key: 'products',
      children: (
        <StLimiter $breakpoint={breakpoint}>
          {activeTab === 'products' && (
            <ContentTab
              startDate={startDate}
              endDate={endDate}
              summaryData={summaryData}
              isSummaryError={isSummaryError}
              summaryLoading={summaryLoading}
              infoData={infoData}
              isInfoError={isInfoError}
              infoLoading={infoLoading}
              activeTab={activeTab}
              infoCount={infoCount}
              infoFilter={infoFilter}
            />
          )}
        </StLimiter>
      ),
    },
    {
      label: 'Bandeiras',
      key: 'brands',
      children: (
        <StLimiter $breakpoint={breakpoint}>
          {activeTab === 'brands' && (
            <ContentTab
              startDate={startDate}
              endDate={endDate}
              summaryData={summaryData}
              isSummaryError={isSummaryError}
              summaryLoading={summaryLoading}
              infoData={infoData}
              isInfoError={isInfoError}
              infoLoading={infoLoading}
              activeTab={activeTab}
              infoCount={infoCount}
              infoFilter={infoFilter}
            />
          )}
        </StLimiter>
      ),
    },
  ];

  return (
    <StContainer>
      <StTabs
        items={items}
        onTabClick={onTabClick}
        tabBarStyle={{
          backgroundColor: colors.gray1,
          marginTop: '160px',
          padding: marginMainContainer(breakpoint),
          fontSize: '12px',
          position: 'fixed',
          zIndex: '90',
          width: '100%',
        }}
      />
    </StContainer>
  );
};

ContentTabs.propTypes = {
  infoData: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        branch_code: PropTypes.string,
        branch_id: PropTypes.number,
        branch_name: PropTypes.string,
        client_id: PropTypes.number,
        created_at: PropTypes.string,
        fee_amount: PropTypes.number,
        net_amount: PropTypes.number,
        quantity: PropTypes.number,
        sales_amount: PropTypes.number,
        sales_date: PropTypes.string,
        sales_average: PropTypes.number,
      }).isRequired,
      PropTypes.shape({
        acquirer_id: PropTypes.number,
        acquirer_name: PropTypes.string,
        client_id: PropTypes.number,
        created_at: PropTypes.string,
        fee_amount: PropTypes.number,
        net_amount: PropTypes.number,
        quantity: PropTypes.number,
        sales_amount: PropTypes.number,
        sales_date: PropTypes.string,
        sales_average: PropTypes.number,
      }).isRequired,
      PropTypes.shape({
        product_id: PropTypes.number,
        product_name: PropTypes.string,
        product_type: PropTypes.string,
        client_id: PropTypes.number,
        created_at: PropTypes.string,
        fee_amount: PropTypes.number,
        net_amount: PropTypes.number,
        quantity: PropTypes.number,
        sales_amount: PropTypes.number,
        sales_date: PropTypes.string,
        sales_average: PropTypes.number,
      }).isRequired,
    ]).isRequired
  ).isRequired,
  summaryData: PropTypes.shape({
    sales_quantity: PropTypes.number,
    ticket_avarage: PropTypes.string,
    sales_amount: PropTypes.string,
  }).isRequired,
  onTabClick: PropTypes.func.isRequired,
  infoLoading: PropTypes.bool.isRequired,
  activeTab: PropTypes.string.isRequired,
  isSummaryError: PropTypes.bool.isRequired,
  summaryLoading: PropTypes.bool.isRequired,
  isInfoError: PropTypes.bool.isRequired,
  startDate: PropTypes.instanceOf(dayjs).isRequired,
  endDate: PropTypes.instanceOf(dayjs).isRequired,
  infoCount: PropTypes.number.isRequired,
};

export default ContentTabs;
