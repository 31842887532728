import { action } from 'typesafe-actions';
import { actionTypes } from 'store/ducks/tokenProd/types';

export const tokenProdPostStart = (data) =>
  action(actionTypes.TOKEN_PROD_POST_START, data);
export const tokenProdPostSuccess = (data) =>
  action(actionTypes.TOKEN_PROD_POST_SUCCESS, data);
export const tokenProdPostError = (errorMessage, error) =>
  action(actionTypes.TOKEN_PROD_POST_ERROR, { errorMessage }, undefined, {
    error,
  });

export const tokenProdDeleteStart = (data) =>
  action(actionTypes.TOKEN_PROD_DELETE_START, data);
export const tokenProdDeleteSuccess = (data) =>
  action(actionTypes.TOKEN_PROD_DELETE_SUCCESS, data);
export const tokenProdDeleteError = (errorMessage, error) =>
  action(actionTypes.TOKEN_PROD_DELETE_ERROR, { errorMessage }, undefined, {
    error,
  });

export const tokenProdGetStart = (data) =>
  action(actionTypes.TOKEN_PROD_GET_START, data);
export const tokenProdGetSuccess = (data) =>
  action(actionTypes.TOKEN_PROD_GET_SUCCESS, data);
export const tokenProdGetError = (errorMessage, error) =>
  action(actionTypes.TOKEN_PROD_GET_ERROR, { errorMessage }, undefined, {
    error,
  });

export const tokenProdPutStart = (data) =>
  action(actionTypes.TOKEN_PROD_PUT_START, data);
export const tokenProdPutSuccess = (data) =>
  action(actionTypes.TOKEN_PROD_PUT_SUCCESS, data);
export const tokenProdPutError = (errorMessage, error) =>
  action(actionTypes.TOKEN_PROD_PUT_ERROR, { errorMessage }, undefined, {
    error,
  });

export const tokenProdDisableTokenStart = (data) =>
  action(actionTypes.TOKEN_PROD_DISABLE_TOKEN_START, data);
export const tokenProdDisableTokenSuccess = (data) =>
  action(actionTypes.TOKEN_PROD_DISABLE_TOKEN_SUCCESS, data);
export const tokenProdDisableTokenError = (errorMessage, error) =>
  action(
    actionTypes.TOKEN_PROD_DISABLE_TOKEN_ERROR,
    { errorMessage },
    undefined,
    {
      error,
    }
  );

export const rowClientIdProdStart = (data) =>
  action(actionTypes.ROW_CLIENT_ID_PROD_START, data);
export const rowClientIdProdSuccess = (data) =>
  action(actionTypes.ROW_CLIENT_ID_PROD_SUCCESS, data);
export const rowClientIdProdError = (errorMessage, error) =>
  action(actionTypes.ROW_CLIENT_ID_PROD_ERROR, { errorMessage }, undefined, {
    error,
  });
