import { action } from 'typesafe-actions';
import { actionTypes } from './types';

export const radarListFiltersStart = (data) =>
  action(actionTypes.RADAR_LIST_FILTERS_START, data);
export const radarListFiltersSuccess = (data) =>
  action(actionTypes.RADAR_LIST_FILTERS_SUCCESS, data);
export const radarListFiltersError = (errorMessage, error) =>
  action(actionTypes.RADAR_LIST_FILTERS_ERROR, { errorMessage }, undefined, {
    error,
  });
export const radarListFiltersZeroLength = () =>
  action(actionTypes.RADAR_LIST_FILTERS_ZERO_LENGTH);
export const updateRadarListFilters = (data) =>
  action(actionTypes.UPDATE_RADAR_LIST_FILTERS, data);
