import { Form, notification } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import errorHandler from 'helpers/errorHandler';
import createFees from 'services/fees/createFees';
import dayjs from 'dayjs';

export const useFeeForm = ({ data = {}, brandsData = [] }) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [checkedAll, setCheckedAll] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [endDateEnabledFields, setEndDateEnabledFields] = useState([]);
  const [isSaveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [modalType, setModalType] = useState('');
  const [isModalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    isAllMerchant,
    selectedMerchants,
    acquirerId,
    productId,
    canInstallments,
  } = data;

  const acquirerProductBrands = useMemo(
    () =>
      brandsData.map(({ id: brandId, value: brandName }) => {
        return {
          brandId,
          brandName,
          feePercent: '',
          startDate: '',
          endDate: '',
        };
      }),
    [brandsData]
  );

  const initialValues = useCallback(() => {
    return {
      plans: [
        {
          planStart: 1,
          planEnd: canInstallments ? 2 : 1,
          brands: [...acquirerProductBrands],
        },
      ],
    };
    // eslint-disable-next-line
  }, [acquirerProductBrands, canInstallments]);

  const indexConstructor = (planIndex, brandIndex) => {
    return `${planIndex}${brandIndex}`;
  };

  const isEndDateEnabled = (planIndex, brandIndex) => {
    const index = indexConstructor(planIndex, brandIndex);
    return endDateEnabledFields.includes(index);
  };

  const onEnableEndDate = (planIndex, brandIndex) => {
    if (!isEndDateEnabled(planIndex, brandIndex)) {
      const index = indexConstructor(planIndex, brandIndex);
      setEndDateEnabledFields([...endDateEnabledFields, index]);
    }
  };

  const onDisableEndDate = (planIndex, brandIndex) => {
    const index = indexConstructor(planIndex, brandIndex);
    const endDateEnabledFieldsFiltered = endDateEnabledFields.filter(
      (item) => item !== index
    );
    setEndDateEnabledFields([...endDateEnabledFieldsFiltered]);
  };

  const disabledEndDate = (current, planIndex, brandIndex) => {
    const { plans = [] } = form.getFieldsValue();
    const startDateField = plans[planIndex]?.brands[brandIndex]?.startDate;
    if (current) {
      return current.isBefore(startDateField);
    }
    return true;
  };

  const disabledStartDate = (current) => {
    return current && current < dayjs().subtract(2, 'year');
  };

  const onChangeStartDate = (value, planIndex, brandIndex) => {
    if (value) {
      onEnableEndDate(planIndex, brandIndex);
    } else {
      onDisableEndDate(planIndex, brandIndex);
    }
    form.setFieldsValue({ startDate: value });
  };

  const isCheckedAll = (planIndex) => {
    return checkedAll.includes(planIndex);
  };

  const uncheckedAll = (planIndex) => {
    const checkedAllFiltered = checkedAll.filter((item) => item !== planIndex);
    setCheckedAll([...checkedAllFiltered]);
  };

  const unselectedBrandsByPlanIndex = (planIndex) => {
    const selectedBrandsFiltered = selectedBrands.filter(
      (item) => item[0] !== String(planIndex)
    );
    setSelectedBrands(selectedBrandsFiltered);
  };

  const onChangeCheckAll = (e, planIndex) => {
    if (e.target.checked) {
      if (!isCheckedAll(planIndex)) {
        setCheckedAll([...checkedAll, planIndex]);
      }
      const acquirerProductBrandsLength = acquirerProductBrands.length;
      const selectedBrandsIndexes = Array(acquirerProductBrandsLength)
        .fill(null)
        .map((_, i) => `${planIndex}${i}`);
      setSelectedBrands([...selectedBrands, ...selectedBrandsIndexes]);
    } else {
      uncheckedAll(planIndex);
      unselectedBrandsByPlanIndex(planIndex);
    }
  };

  const isBrandSelected = useCallback(
    (planIndex, brandIndex) => {
      const index = indexConstructor(planIndex, brandIndex);
      return selectedBrands.includes(index);
    },
    [selectedBrands]
  );

  const onSelectBrand = (planIndex, brandIndex) => {
    const index = indexConstructor(planIndex, brandIndex);
    if (isBrandSelected(planIndex, brandIndex)) {
      const selectedBrandsFiltered = selectedBrands.filter(
        (item) => item !== index
      );
      uncheckedAll(planIndex);
      setSelectedBrands([...selectedBrandsFiltered]);
    } else {
      setSelectedBrands([...selectedBrands, index]);
    }
  };

  const onAddInterval = (add) => {
    add({
      planStart: '',
      planEnd: '',
      brands: [...acquirerProductBrands],
    });
  };

  const onRemoveInterval = (planIndex) => {
    const { plans = [] } = form.getFieldsValue();
    Object.assign(plans[planIndex], {
      disabled: true,
    });
    form.setFieldsValue({ plans });
    unselectedBrandsByPlanIndex(planIndex);
  };

  const isIntervalDisabled = (planIndex) => {
    const { plans = [] } = form.getFieldsValue();
    return plans[planIndex]?.disabled;
  };

  const rules = (planIndex, brandIndex) => {
    if (isBrandSelected(planIndex, brandIndex)) {
      return [{ required: true, message: 'Campo obrigatório' }];
    }
    return false;
  };

  const rulesFeePercent = (planIndex, brandIndex) => {
    if (isBrandSelected(planIndex, brandIndex)) {
      return [
        {
          validator(_, value) {
            const parsedValue = parseFloat(value.replace(/,/g, '.'));
            if (value && parsedValue <= 100.0 && parsedValue >= 0.01) {
              return Promise.resolve();
            }
            return Promise.reject(
              new Error('Digite um percentual entre 0,01 e 100')
            );
          },
        },
      ];
    }
    return false;
  };

  const rulesEndDate = (planIndex, brandIndex) => {
    return [
      {
        validator: async (_, value) => {
          if (
            isBrandSelected(planIndex, brandIndex) &&
            disabledEndDate(value, planIndex, brandIndex)
          ) {
            return Promise.reject(
              new Error(
                'O término da vigência deve ser maior ou igual o início da vigência'
              )
            );
          }
          return true;
        },
      },
    ];
  };

  const rulesPlanStart = () => {
    return [
      () => ({
        validator(_, value) {
          if (!value) {
            return Promise.reject(new Error('Obrigatório'));
          }

          if (value < 1) {
            return Promise.reject(
              new Error('A parcela inicial deve ser maior ou igual a 1')
            );
          }

          if (value > 24) {
            return Promise.reject(
              new Error('A parcela inicial deve ser menor ou igual a 24')
            );
          }

          return Promise.resolve();
        },
      }),
    ];
  };

  const rulesPlanEnd = (planIndex, hasInstallments) => {
    return [
      (formInstance) => ({
        validator(_, value) {
          if (!value) {
            return Promise.reject(new Error('Obrigatório'));
          }

          if (hasInstallments && value < 2) {
            return Promise.reject(
              new Error('A parcela final deve ser maior ou igual a 2')
            );
          }

          if (value > 24) {
            return Promise.reject(
              new Error('A parcela final deve ser menor ou igual a 24')
            );
          }

          const planData = formInstance.getFieldValue('plans') || [];
          const planStartField = planData[planIndex]?.planStart;
          if (value < planStartField) {
            return Promise.reject(
              new Error(
                'A parcela final deve ser maior ou igual à parcela inicial'
              )
            );
          }

          return Promise.resolve();
        },
      }),
    ];
  };

  const plansValidation = (plans) => {
    const planRange = (start, end) => {
      return Array(end - start + 1)
        .fill()
        .map((_, index) => start + index);
    };

    const hasDuplicates = (array) => {
      return new Set(array).size !== array.length;
    };

    const plansRange = plans.reduce((acc, { planStart, planEnd }) => {
      const range = planRange(Number(planStart), Number(planEnd));
      return [...acc, ...range];
    }, []);

    if (hasDuplicates(plansRange)) {
      errorHandler(
        'Há conflito nos intervalos de parcelas, por favor verifique'
      );
      return false;
    }

    return true;
  };

  const enabledPlansFilter = (plans) => plans.filter((plan) => !plan?.disabled);

  const onFeesRedirect = () => navigate({ pathname: '/taxas' });

  const onConfirm = () => form.submit();

  const onClickCancel = () => {
    setModalType('cancel');
    setModalOpen(true);
  };

  const onClickSave = () => {
    form.validateFields().then(() => {
      const { plans = [] } = form.getFieldsValue();
      const enabledPlans = enabledPlansFilter(plans);

      if (!plansValidation(enabledPlans)) return;

      setModalType('finish');
      setModalOpen(true);
    });
  };

  const feesRegister = (payloadData) => {
    setLoading(true);
    createFees(payloadData)
      .then((response) => {
        const {
          message = 'Sua solicitação está em andamento. Assim que for concluída, você será notificado na área de notificações',
        } = response?.data;

        notification.success({
          message: 'Cadastro de taxas',
          description: message,
        });
        onFeesRedirect();
      })
      .catch(() => {
        errorHandler(
          'Houve um problema na solicitação de cadastro de taxas, por favor, tente novamente'
        );
      })
      .finally(() => setLoading(false));
  };

  const onFinish = (values) => {
    const brands = values?.plans.reduce((accPlan, plan, planIndex) => {
      const brandsFiltered = plan?.brands.reduce((acc, brand, brandIndex) => {
        if (isBrandSelected(planIndex, brandIndex)) {
          const { planStart, planEnd, disabled } = plan;
          return disabled
            ? [...acc]
            : [...acc, { planStart, planEnd, ...brand }];
        }
        return acc;
      }, []);
      return [...accPlan, ...brandsFiltered];
    }, []);

    const merchantIds = selectedMerchants.map(({ merchant_id: id }) => id);

    const brandsParsed = brands.map(
      ({ brandId, feePercent, startDate, endDate, planStart, planEnd }) => {
        const feePercentFormatted = Number(feePercent.replace(',', '.'));
        return {
          brand_id: brandId,
          fee_percent: feePercentFormatted,
          start_date: dayjs(startDate).format('YYYY-MM-DD'),
          end_date: dayjs(endDate).format('YYYY-MM-DD'),
          plan_start: planStart,
          plan_end: planEnd,
        };
      }
    );

    const payload = {
      is_all_merchant: isAllMerchant,
      merchant_ids: merchantIds,
      acquirer_id: acquirerId,
      product_id: productId,
      brands: brandsParsed,
    };

    feesRegister(payload);
  };

  useEffect(() => {
    setSaveButtonDisabled(selectedBrands.length === 0);
  }, [selectedBrands]);

  return {
    form,
    initialValues,
    isSaveButtonDisabled,
    rules,
    rulesFeePercent,
    rulesEndDate,
    rulesPlanStart,
    rulesPlanEnd,
    isCheckedAll,
    uncheckedAll,
    onChangeCheckAll,
    isBrandSelected,
    onSelectBrand,
    onChangeStartDate,
    isEndDateEnabled,
    disabledEndDate,
    disabledStartDate,
    onAddInterval,
    onRemoveInterval,
    isIntervalDisabled,
    isModalOpen,
    setModalOpen,
    modalType,
    onFeesRedirect,
    onConfirm,
    onClickCancel,
    onClickSave,
    onFinish,
    loading,
  };
};
