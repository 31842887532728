/* eslint-disable no-plusplus */

import { lambdaApi } from 'services/api';

const transform = (response, columnName) => {
  const filters = {
    results: [
      {
        text: columnName,
        values: response?.data?.results,
      },
    ],
    hasMore: response?.meta?.has_next,
    offset: response?.meta?.offset,
    limit: response?.meta?.limit,
    column: response?.meta?.column,
    fee_status: response?.meta?.fee_status,
    br_cnpj_short_name: response?.meta?.br_cnpj_short_name,
  };
  return filters;
};

const getFeesDetailsFiltersFromAPI = async ({
  otherParams,
  columnName,
  limit,
  offset,
  searchBy,
  q,
}) => {
  const isFiltered = q.length !== 0 && q.filter.length > 0;
  const filters = isFiltered ? { q: JSON.stringify(q) } : undefined;
  const config = {
    params: {
      acquirer_id: otherParams?.acquirerId,
      fee_status: otherParams?.type,
      br_cnpj_short_name: otherParams?.selectedStore?.label,
      column: columnName,
      limit,
      offset,
      search_by: searchBy,
      ...filters,
    },
  };
  const response = await lambdaApi().get('/fees/detail/filter', config);
  const transformedResponse = transform(response?.data, columnName);
  return transformedResponse;
};

export default getFeesDetailsFiltersFromAPI;
