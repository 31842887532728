import { actionTypes } from './types';

const INITIAL_STATE = {
  filters: [],
  loading: false,
  error: undefined,
  errorMessage: undefined,
  isError: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case actionTypes.SALES_INFO_FILTERS_START:
    return {
      ...state,
      loading: true,
      error: undefined,
      errorMessage: undefined,
      isError: false,
    };
  case actionTypes.SALES_INFO_FILTERS_SUCCESS:
    return {
      ...state,
      loading: false,
      error: false,
      filters: action.payload.filters,
      isError: false,
    };
  case actionTypes.SALES_INFO_FILTERS_ERROR:
    return {
      ...state,
      loading: false,
      error: action.error.error,
      errorMessage: action.payload,
      isError: true,
      filters: [],
    };
  default:
    return state;
  }
};

export default reducer;
