import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col, Input, Button } from 'antd';

import ptBR from 'antd/es/date-picker/locale/pt_BR';

import { SortAscendingOutlined } from '@ant-design/icons';

import { FiltersContainer } from 'commons/FiltersContainer';
import { LabelAndFilter } from 'commons/LabelAndFilter';

import LabelAndInputs from '../../../../../commons/LabelAndInputs/LabelAndInputs';
import DatePicker from 'commons/DatePicker/DatePicker';

const ContentFilters = ({ dateDefaultValue, onRangePickerChange, loading }) => {
  const { RangePicker } = DatePicker;

  return (
    <FiltersContainer
      title="Divergência de taxas"
      subTitle="Verifique divergências entre as taxas cobradas e cadastradas na plataforma"
    >
      <Row gutter={[8, 8]} justify="space-between">
        <Col span={4}>
          <LabelAndFilter label="Calendário">
            <RangePicker
              format="DD/MM/YYYY"
              disabled={loading}
              defaultValue={dateDefaultValue}
              onChange={onRangePickerChange}
              locale={ptBR}
            />
          </LabelAndFilter>
        </Col>
        <Col span={10}>
          <Row gutter={[8, 8]}>
            <Col>
              <LabelAndInputs label="Filial">
                <Input placeholder="Buscar" />
              </LabelAndInputs>
            </Col>
          </Row>
        </Col>
        <Col span={10}>
          <Row justify="end">
            <Col>
              <LabelAndFilter label="Ordenado por" align="end">
                <Button
                  disabled={loading}
                  type="dashed"
                  icon={<SortAscendingOutlined />}
                  size="small"
                  style={{ marginRight: '8px' }}
                />
                Divergência
              </LabelAndFilter>
            </Col>
          </Row>
        </Col>
      </Row>
    </FiltersContainer>
  );
};

ContentFilters.propTypes = {
  onRangePickerChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ContentFilters;
