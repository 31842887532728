import { call, put } from 'redux-saga/effects';
import errorHandler from 'helpers/errorHandler';

import {
  cnpjMaintenanceInfoFiltersSuccess,
  cnpjMaintenanceInfoFiltersError,
} from './actions';
import getCnpjMaintenanceInfoFiltersFromAPI from 'services/cnpjMaintenance/filters/getCnpjMaintenanceInfoFiltersFromAPI';

export function* cnpjMaintenanceInfoFiltersSaga(action) {
  const { payload } = action;

  try {
    const apiData = yield call(getCnpjMaintenanceInfoFiltersFromAPI, payload);
    yield put(cnpjMaintenanceInfoFiltersSuccess(apiData));
  } catch (err) {
    if (
      err?.response?.data?.error &&
      err?.response?.data?.error?.type &&
      err?.response?.data?.error?.message
    ) {
      yield put(
        cnpjMaintenanceInfoFiltersError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err.response.data.error.message, err.response.data.error);
    }
  }
}
