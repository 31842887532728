import styled from 'styled-components';
import { marginMainContainer } from 'helpers/style';

export const StContainer = styled.div`
  width: 100%;
  white-space: nowrap;
  position: relative;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  margin-top: 20px;
`;

export const StLimiter = styled.div`
  margin: ${(props) => marginMainContainer(props.$breakpoint)};
`;

export const StTitleAling = styled.div`
  align-items: center;
`;

export const StListAgroup = styled.div`
  margin-top: 10px;
`;
