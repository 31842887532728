import axios from 'axios';

export const getCep = async ({ data }) => {
  const network = axios.create({
    baseURL: 'https://viacep.com.br/ws',
    timeout: 999990000,
    headers: { 'Content-Type': 'application/json' },
  });
  const response = await network.get(`/${data.cep}/json/`);
  return response.data;
};
