import { call, put } from 'redux-saga/effects';

import errorHandler from 'helpers/errorHandler';

import {
  creditTomaticoStatusSuccess,
  creditTomaticoStatusError,
} from './actions';

import tomaticoStatus from 'services/credit/tomaticoStatus';

export function* creditTomaticoStatusSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(tomaticoStatus, payload);
    yield put(creditTomaticoStatusSuccess(apiData));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        creditTomaticoStatusError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(creditTomaticoStatusError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}
