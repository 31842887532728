import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { colors } from 'styles/colors';
import { LockOutlined } from '@ant-design/icons';

export const StItemContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0 24px;
  font-size: 14px;
  color: ${colors.gray1};
  background-color: ${colors.leftMenuBackground};

  :hover {
    background: ${colors.leftMenuBackground2} !important;
  }

  :hover {
    ::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      border-right: 4px solid ${colors.gray1};
    }
  }
`;

export const StItemLink = styled(Link)`
  color: ${colors.gray1} !important;
  margin: 10px;
`;

export const StContainerBlocked = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const StItemBlocked = styled.span`
  color: ${colors.gray1} !important;
  margin: 10px;
`;

export const StLockOutlined = styled(LockOutlined)`
  &&& {
    font-size: 24px;
  }
`;
