import { actionTypes } from 'store/ducks/salesInfo/types';

const INITIAL_STATE = {
  error: undefined,
  errorMessage: undefined,
  loading: true,
  isError: false,
  sales: [],
  meta: { query_count: 0 },
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case actionTypes.SALES_INFO_START:
    return {
      ...state,
      loading: true,
      error: undefined,
      errorMessage: undefined,
      isError: false,
    };
  case actionTypes.SALES_INFO_SUCCESS:
    return {
      ...state,
      loading: false,
      error: undefined,
      sales: action.payload.sales,
      meta: action.payload.meta,
      isError: false,
      errorMessage: undefined,
    };
  case actionTypes.SALES_INFO_ERROR:
    return {
      ...state,
      loading: false,
      error: action.error.error,
      errorMessage: action.payload,
      isError: true,
      sales: [],
      meta: { query_count: 0 },
    };
  default:
    return state;
  }
};

export default reducer;
