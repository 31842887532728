import { useState } from 'react';
import errorHandler from 'helpers/errorHandler';
import getFeeMerchants from 'services/fees/getFeeMerchants';

export const useMerchants = () => {
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState({});
  const [merchants, setMerchants] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchMerchants = ({ acquirerId, query }) => {
    setLoading(true);

    const filter = [
      {
        name: 'acquirer_id',
        val: acquirerId,
      },
      {
        name: 'search_by',
        val: query || '',
      },
    ];

    return getFeeMerchants({ page, perPage: 10, filter })
      .then(({ merchants: merchantsData = [], meta: metaData = {} }) => {
        if (metaData?.page === 1) {
          setMerchants(merchantsData);
        } else {
          setMerchants([...merchants, ...merchantsData]);
        }
        setMeta(metaData);
      })
      .catch(() =>
        errorHandler(
          'Ocorreu um erro ao buscar os estabelecimentos, por favor, atualize a página'
        )
      )
      .finally(() => setLoading(false));
  };

  return {
    meta,
    page,
    loading,
    merchants,
    setPage,
    setMerchants,
    fetchMerchants,
  };
};
