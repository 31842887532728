import { actionTypes } from './types';

const INITIAL_STATE = {
  error: false,
  errorMessage: undefined,
  loading: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case actionTypes.FORGOT_PASSWORD_START:
    return { ...state, loading: true, error: false };
  case actionTypes.FORGOT_PASSWORD_SUCCESS:
    return {
      ...state,
      loading: false,
      error: false,
      ok: true,
    };
  case actionTypes.FORGOT_PASSWORD_ERROR:
    return {
      ...state,
      loading: false,
      error: action.error.error,
      errorMessage: action.payload,
    };
  default:
    return state;
  }
};

export default reducer;
