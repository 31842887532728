const plans = {
  CONTROLE: 1,
  GERENCIE: 2,
  ADMINISTRE: 3,
  ADMINISTRE_PLUS: 4,
  PAG_GRATIS: 6,
  FREE: 7,
  PRO: 8,
};

export default plans;
