import { call, put } from 'redux-saga/effects';

import errorHandler from 'helpers/errorHandler';

import {
  listRetroactiveConciliationSuccess,
  listRetroactiveConciliationError,
  postRetroactiveConciliationSuccess,
  postRetroactiveConciliationError,
} from 'store/ducks/retroactiveConciliation/actions';

import {
  listRetroactiveConciliation,
  postRetroactiveConciliation,
} from 'services/retroactiveConciliation/retroactiveConciliation';
import { notification } from 'antd';

export function* listRetroactiveConciliationSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(listRetroactiveConciliation, payload);
    yield put(listRetroactiveConciliationSuccess(apiData));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        listRetroactiveConciliationError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(listRetroactiveConciliationError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}

export function* postRetroactiveConciliationSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(postRetroactiveConciliation, payload);
    yield put(postRetroactiveConciliationSuccess(apiData));
    notification.success({
      message: 'Solicitação de conciliação realizada com sucesso!',
      placement: 'topRight',
    });
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        postRetroactiveConciliationError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(postRetroactiveConciliationError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}
