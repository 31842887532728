import { Auth } from 'aws-amplify';

export const authForgotPasswordSubmit = async ({ username, code, newPassword }) => {
  const forgotPasswordSubmitData = Auth.forgotPasswordSubmit(
    username,
    code,
    newPassword
  )
    .then((forgotPasswordSubmitResponse) => {
      return forgotPasswordSubmitResponse;
    })
    .catch((err) => {
      return {
        error: err.toString(),
      };
    });
  return forgotPasswordSubmitData;
};
