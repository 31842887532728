import {
  parseListOfClientsQToAPI,
  parseListOfClientsToWeb,
} from 'utils/parsers/listOfClientsParser';
import { lambdaApi } from './api';

const transformer = (
  apiResponse,
  filter,
  isReconciliationRules,
  shouldReplace
) => {
  return {
    clients: parseListOfClientsToWeb(apiResponse.data.clients),
    currentClient: apiResponse.data.current_client,
    perPage: apiResponse.meta.per_page,
    page: apiResponse.meta.page,
    hasMore: apiResponse.meta.has_next,
    total: apiResponse.meta.total,
    filter,
    isReconciliationRules,
    shouldReplace,
  };
};

const getListOfClientsFromAPI = async ({
  filter,
  page,
  perPage = 10,
  isReconciliationRules,
  q,
  shouldReplace,
  sortFields,
}) => {
  const parsedQ = parseListOfClientsQToAPI(q);
  const config = {
    params: {
      filter_by: filter || undefined,
      page,
      per_page: isReconciliationRules ? 10000 : perPage,
      sort_fields: JSON.stringify(sortFields),
      q:
        JSON.stringify({
          filter: parsedQ,
        }) || undefined,
    },
  };
  const response = await lambdaApi().get('/clients', config);
  return transformer(
    response.data,
    filter,
    isReconciliationRules,
    shouldReplace
  );
};

export default getListOfClientsFromAPI;
