import React from 'react';
import { Row, Col, Spin } from 'antd';
import PropTypes from 'prop-types';

const Loadable = ({ loading, children }) => (
  <>
    {loading ? (
      <Row justify="center" align="middle" style={{ height: '100%' }}>
        <Col>
          <Spin />
        </Col>
      </Row>
    ) : (
      children
    )}
  </>
);

Loadable.propTypes = {
  loading: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
};

export default Loadable;
