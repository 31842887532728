import React from 'react';
import * as St from './styled';
import { colors } from 'styles/colors';

const TableStatus = ({
  children,
  backgroundColor = colors.gray2,
  borderColor = colors.gray5,
  textColor = colors.gray9,
}) => {
  return (
    <St.Container
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      textColor={textColor}
    >
      {children}
    </St.Container>
  );
};

export default TableStatus;
