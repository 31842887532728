import React from 'react';
import { radarStatusTypes } from './radarStatusTypes';
import { IconContainer, Tag } from './styled';

const RadarStatusTag = ({ status = '', ...others }) => {
  if (!status) return null;

  const radarType = radarStatusTypes[status] || {};
  const { fontColor, borderColor, backgroundColor, icon, iconColor } =
    radarType;

  if (!fontColor || !borderColor || !backgroundColor) return null;

  return (
    <Tag
      fontColor={fontColor}
      borderColor={borderColor}
      backgroundColor={backgroundColor}
      {...others}
    >
      <IconContainer iconColor={iconColor}>{icon}</IconContainer>
      <span>{status}</span>
    </Tag>
  );
};

export default RadarStatusTag;
