// TODO: Remove camelcase eslint rule
/* eslint-disable camelcase */
import { api } from '../api';

export const validateStoreName = async (_short_name) => {
  const config = {
    params: {
      store_name: _short_name,
    },
  };

  const response = await api().get('/validate_store', config);
  return response.data.data.condition;
};
