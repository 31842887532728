import styled, { css } from 'styled-components';
import { Typography, Button } from 'antd';
import Col from 'antd/es/grid/col';
import { colors } from 'styles/colors';

export const StContainer = styled.div`
  margin: 0px 63px;
  padding-top: 40px;
`;

export const StWrapper = styled.div`
  margin-top: 20rem;
  background-color: ${colors.gray1};
  padding: 4rem;
`;

export const StHeaderWrapper = styled(StWrapper)`
  position: fixed;
  width: 100%;
  margin-top: 0;
  left: 0;
  padding-left: 8rem;
  padding-right: 8rem;
  z-index: 1;
`;

export const StCol = styled(Col)`
  margin-bottom: 16px;
  display: flex;
  justify-content: flex-end;
`;

export const StButton2 = styled(Button)`
  height: 4rem;
  margin: 0 0 0 0.867rem;
  padding: 0.625rem 1.5rem;
  border-radius: 4px;
`;

export const StTitle = styled(Typography.Title)`
  margin-bottom: 0 !important;
  color: ${colors.gray11} !important;
  font-size: 3.75rem !important;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
`;

export const StClientTitle = styled(Typography.Title)`
  color: ${colors.gray11} !important;
  font-size: 2.5rem !important;
  display: inline-block !important;
`;

export const StButton = styled(Button)`
  display: inline-block !important;
`;

export const StLabel = styled.span`
  color: #8e8e8e;
  font-size: 1.75rem;
  display: block;
`;

export const StStageRect = styled.div`
  ${({ isImplanted }) => css`
    color: ${isImplanted ? colors.primary6 : colors.green6};
    background: ${isImplanted ? colors.primary1 : colors.green1};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 11.5rem;
    height: 2.875rem;
    padding: 0.063rem 0.875rem;
    border-radius: 2px;
    border: solid 1px;
    border-color: ${isImplanted ? colors.primary3 : colors.green3};
    font-size: 1.5rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
  `}
`;

export const StActionCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StActionLink = styled.a`
  height: 2.75rem;
  font-size: 1.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: center;
  /* margin-right: 3.05rem; */
`;

export const StActionRect = styled.div`
  background: ${colors.gray1};
  color: ${colors.red6};
  text-align: center;
  width: 10rem;
  height: 4rem;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  padding: 0.063rem 0.875rem;
  border-radius: 4px;
  border: solid 1px;
  border-color: ${colors.red6};
  font-size: 1.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  cursor: pointer;
`;
