import { call, put } from 'redux-saga/effects';

import errorHandler from 'helpers/errorHandler';

import getPaymentsQuery from '../../../../services/banks/banksReports';
import { banksReportsError, banksReportsSuccess } from './actions';

export function* banksReportSaga(action) {
  try {
    const { payload } = action;

    const apiData = yield call(getPaymentsQuery, payload);

    yield put(banksReportsSuccess(apiData));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        banksReportsError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(banksReportsError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}
