import React from 'react';
import {
  onColumnFilter,
  columnFilters,
  getDefaultColumns,
} from 'helpers/generalService';

import store from 'store';
import {
  amountsReceivableDetailsFiltersStart,
  amountsReceivableDetailsFiltersZeroLength,
} from 'store/ducks/filters/details/amountsReceivableDetailsFilters/actions';
import { getDefaultColumnWidth } from 'helpers/tableService';
import FilterDropdown from 'commons/Table/components/FilterDropdown';
import { clearFiltersFunctions } from 'helpers/clearFiltersFunctions';

let filteredColumns = store.getState().updateFilteredColumns?.filteredColumns;

const columns = (apiFilters, localFilters, toParamsFilters) => [
  {
    title: 'Filial',
    dataIndex: 'store_name',
    filteredValue: getDefaultColumns(toParamsFilters, 'store_name'),
    filters: apiFilters ? columnFilters(apiFilters, 'store_name') : undefined,
    onFilter: (value, record) => onColumnFilter(value, record, 'store_name'),
    onFilterDropdownVisibleChange: (visible) => {
      filteredColumns = store.getState().updateFilteredColumns?.filteredColumns;
      if (visible) {
        // This "zero length" dispatch is utterly important,
        // in order to keep each column state independent (Not passing data from one column to the next)
        store.dispatch(amountsReceivableDetailsFiltersZeroLength());
        store.dispatch(
          amountsReceivableDetailsFiltersStart({
            startDate: store.getState().updateScreenToScreenDate?.startDate,
            endDate: store.getState().updateScreenToScreenDate?.endDate,
            columnName: 'store_name',
            offset: 0,
            q:
              filteredColumns.length !== 0
                ? {
                    filter: filteredColumns,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.storeName = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <FilterDropdown
          key={filteredColumns}
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={amountsReceivableDetailsFiltersStart}
          filteredColumns={
            store.getState().updateFilteredColumns?.filteredColumns
          }
          clearAction={amountsReceivableDetailsFiltersZeroLength}
          columnName="store_name"
          storeName="amountsReceivableDetailsFilters"
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: getDefaultColumnWidth('store_name'),
    align: 'left',
  },
  {
    title: 'Data prevista do pagamento',
    dataIndex: 'transaction_date',
    filteredValue: getDefaultColumns(toParamsFilters, 'transaction_date'),
    filters: apiFilters
      ? columnFilters(apiFilters, 'transaction_date')
      : undefined,
    onFilter: (value, record) =>
      onColumnFilter(value, record, 'transaction_date'),
    onFilterDropdownVisibleChange: (visible) => {
      filteredColumns = store.getState().updateFilteredColumns?.filteredColumns;
      if (visible) {
        store.dispatch(amountsReceivableDetailsFiltersZeroLength());
        store.dispatch(
          amountsReceivableDetailsFiltersStart({
            startDate: store.getState().updateScreenToScreenDate?.startDate,
            endDate: store.getState().updateScreenToScreenDate?.endDate,
            columnName: 'transaction_date',
            offset: 0,
            q:
              filteredColumns.length !== 0
                ? {
                    filter: filteredColumns,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.transactionDate = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <FilterDropdown
          key={filteredColumns}
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={amountsReceivableDetailsFiltersStart}
          filteredColumns={
            store.getState().updateFilteredColumns?.filteredColumns
          }
          clearAction={amountsReceivableDetailsFiltersZeroLength}
          columnName="transaction_date"
          storeName="amountsReceivableDetailsFilters"
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: getDefaultColumnWidth('transaction_date'),
    align: 'left',
  },
  {
    title: 'Adquirente',
    dataIndex: 'acquirer_name',
    filteredValue: getDefaultColumns(toParamsFilters, 'acquirer_name'),
    filters: apiFilters
      ? columnFilters(apiFilters, 'acquirer_name')
      : undefined,
    onFilter: (value, record) => onColumnFilter(value, record, 'acquirer_name'),
    onFilterDropdownVisibleChange: (visible) => {
      filteredColumns = store.getState().updateFilteredColumns?.filteredColumns;
      if (visible) {
        store.dispatch(amountsReceivableDetailsFiltersZeroLength());
        store.dispatch(
          amountsReceivableDetailsFiltersStart({
            startDate: store.getState().updateScreenToScreenDate?.startDate,
            endDate: store.getState().updateScreenToScreenDate?.endDate,
            columnName: 'acquirer_name',
            offset: 0,
            q:
              filteredColumns.length !== 0
                ? {
                    filter: filteredColumns,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.acquirerName = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <FilterDropdown
          key={filteredColumns}
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={amountsReceivableDetailsFiltersStart}
          filteredColumns={
            store.getState().updateFilteredColumns?.filteredColumns
          }
          clearAction={amountsReceivableDetailsFiltersZeroLength}
          columnName="acquirer_name"
          storeName="amountsReceivableDetailsFilters"
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: getDefaultColumnWidth('acquirer_name'),
    align: 'left',
  },
  {
    title: 'Produto',
    dataIndex: 'product_code',
    filteredValue: getDefaultColumns(toParamsFilters, 'product_code'),
    filters: apiFilters ? columnFilters(apiFilters, 'product_code') : undefined,
    onFilter: (value, record) => onColumnFilter(value, record, 'product_code'),
    onFilterDropdownVisibleChange: (visible) => {
      filteredColumns = store.getState().updateFilteredColumns?.filteredColumns;
      if (visible) {
        store.dispatch(amountsReceivableDetailsFiltersZeroLength());
        store.dispatch(
          amountsReceivableDetailsFiltersStart({
            startDate: store.getState().updateScreenToScreenDate?.startDate,
            endDate: store.getState().updateScreenToScreenDate?.endDate,
            columnName: 'product_code',
            offset: 0,
            q:
              filteredColumns.length !== 0
                ? {
                    filter: filteredColumns,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.productCode = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <FilterDropdown
          key={filteredColumns}
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={amountsReceivableDetailsFiltersStart}
          filteredColumns={
            store.getState().updateFilteredColumns?.filteredColumns
          }
          clearAction={amountsReceivableDetailsFiltersZeroLength}
          columnName="product_code"
          storeName="amountsReceivableDetailsFilters"
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: getDefaultColumnWidth('product_code'),
    align: 'left',
  },
  {
    title: 'Bandeira',
    dataIndex: 'brand_code',
    filteredValue: getDefaultColumns(toParamsFilters, 'brand_code'),
    filters: apiFilters ? columnFilters(apiFilters, 'brand_code') : undefined,
    onFilter: (value, record) => onColumnFilter(value, record, 'brand_code'),
    onFilterDropdownVisibleChange: (visible) => {
      filteredColumns = store.getState().updateFilteredColumns?.filteredColumns;
      if (visible) {
        store.dispatch(amountsReceivableDetailsFiltersZeroLength());
        store.dispatch(
          amountsReceivableDetailsFiltersStart({
            startDate: store.getState().updateScreenToScreenDate?.startDate,
            endDate: store.getState().updateScreenToScreenDate?.endDate,
            columnName: 'brand_code',
            offset: 0,
            q:
              filteredColumns.length !== 0
                ? {
                    filter: filteredColumns,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.brandCode = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <FilterDropdown
          key={filteredColumns}
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={amountsReceivableDetailsFiltersStart}
          filteredColumns={
            store.getState().updateFilteredColumns?.filteredColumns
          }
          clearAction={amountsReceivableDetailsFiltersZeroLength}
          columnName="brand_code"
          storeName="amountsReceivableDetailsFilters"
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: getDefaultColumnWidth('brand_code'),
    align: 'left',
  },

  {
    title: 'Valor bruto (R$)',
    dataIndex: 'sum_inst_gross_amount',
    filteredValue: getDefaultColumns(toParamsFilters, 'sum_inst_gross_amount'),
    filters: apiFilters
      ? columnFilters(apiFilters, 'sum_inst_gross_amount')
      : undefined,
    onFilter: (value, record) =>
      onColumnFilter(value, record, 'sum_inst_gross_amount'),
    onFilterDropdownVisibleChange: (visible) => {
      filteredColumns = store.getState().updateFilteredColumns?.filteredColumns;
      if (visible) {
        store.dispatch(amountsReceivableDetailsFiltersZeroLength());
        store.dispatch(
          amountsReceivableDetailsFiltersStart({
            startDate: store.getState().updateScreenToScreenDate?.startDate,
            endDate: store.getState().updateScreenToScreenDate?.endDate,
            columnName: 'sum_inst_gross_amount',
            offset: 0,
            q:
              filteredColumns.length !== 0
                ? {
                    filter: filteredColumns,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.sumInstGrossAmount = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <FilterDropdown
          key={filteredColumns}
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={amountsReceivableDetailsFiltersStart}
          filteredColumns={
            store.getState().updateFilteredColumns?.filteredColumns
          }
          clearAction={amountsReceivableDetailsFiltersZeroLength}
          columnName="sum_inst_gross_amount"
          storeName="amountsReceivableDetailsFilters"
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: getDefaultColumnWidth('sum_inst_gross_amount'),
    align: 'left',
  },
  {
    title: 'Valor líquido (R$)',
    dataIndex: 'sum_inst_net_amount',
    filteredValue: getDefaultColumns(toParamsFilters, 'sum_inst_net_amount'),
    filters: apiFilters
      ? columnFilters(apiFilters, 'sum_inst_net_amount')
      : undefined,
    onFilter: (value, record) =>
      onColumnFilter(value, record, 'sum_inst_net_amount'),
    onFilterDropdownVisibleChange: (visible) => {
      filteredColumns = store.getState().updateFilteredColumns?.filteredColumns;
      if (visible) {
        store.dispatch(amountsReceivableDetailsFiltersZeroLength());
        store.dispatch(
          amountsReceivableDetailsFiltersStart({
            startDate: store.getState().updateScreenToScreenDate?.startDate,
            endDate: store.getState().updateScreenToScreenDate?.endDate,
            columnName: 'sum_inst_net_amount',
            offset: 0,
            q:
              filteredColumns.length !== 0
                ? {
                    filter: filteredColumns,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      clearFiltersFunctions.sumInstNetAmount = {
        selectedKeys,
        clearFilters,
        confirm,
      };
      return (
        <FilterDropdown
          key={filteredColumns}
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={amountsReceivableDetailsFiltersStart}
          filteredColumns={
            store.getState().updateFilteredColumns?.filteredColumns
          }
          clearAction={amountsReceivableDetailsFiltersZeroLength}
          columnName="sum_inst_net_amount"
          storeName="amountsReceivableDetailsFilters"
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: getDefaultColumnWidth('sum_inst_net_amount'),
    align: 'left',
  },
];

export default columns;
