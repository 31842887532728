import { action } from 'typesafe-actions';
import { actionTypes } from 'store/ducks/reconciled/sales/types';

export const salesReconciledStart = (data) =>
  action(actionTypes.SALES_RECONCILED_START, data);
export const salesReconciledSuccess = (data) =>
  action(actionTypes.SALES_RECONCILED_SUCCESS, data);
export const salesReconciledError = (errorMessage, error) =>
  action(actionTypes.SALES_RECONCILED_ERROR, { errorMessage }, undefined, {
    error,
  });
