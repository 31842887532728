import React from 'react';
import {
  Row,
  Col,
  Card,
  Badge,
  Spin,
} from 'antd';
import { StShowCase } from './styled';
import { partnerTypes } from 'constants/offers';
import { colors } from 'styles/colors';

const PartnerShowCase = ({
  onlyActiveOffers,
  availablePartners,
  availablePartnersLoading,
  filteredOffers,
  filteredPartners,
}) => {
  const tomaticoLogoS3 =
    'https://concil-prod-v4-assets.s3.amazonaws.com/banking/tomaticofull.png';

  const renderAvailablePartners = () => {
    return Array(1)
      .fill(availablePartners)
      .flat()
      .map((partner) => {
        if (
          filteredOffers[partnerTypes[partner?.type]] === true &&
          filteredPartners[partner?.name] === true &&
          partner?.active === onlyActiveOffers &&
          !partner.name.toLowerCase().includes('btg')
        ) {
          return (
            <Col>
              <Card
                align="middle"
                style={{
                  border: `1px solid ${colors.gray5}`,
                  borderRadius: 4,
                  padding: '16px 0px',
                  width: 150,
                  height: 100,
                }}
                bodyStyle={{
                  width: '100%',
                }}
                type="inner"
                cover={
                  <img
                    alt="LogoParceiro"
                    src={tomaticoLogoS3}
                    style={{
                      maxWidth: 112,
                      maxHeight: 30,
                    }}
                  />
                }
              >
                <Badge
                  status={partner?.active ? 'success' : 'error'}
                  text={partner?.active ? 'Ativo' : 'Inativo'}
                  style={{
                    color: colors.gray7,
                    fontWeight: '400',
                    fontSize: '14px',
                    width: '100%',
                    height: '100%',
                  }}
                />
              </Card>
            </Col>
          );
        }
        return <></>;
      });
  };

  return (
    <>
      <StShowCase title="Vitrine de parceiros" headStyle={{ fontSize: '20px' }}>
        <Row align="middle" justify="start" gutter={16}>
          {availablePartnersLoading ? <Spin /> : renderAvailablePartners()}
        </Row>
      </StShowCase>
    </>
  );
};

export default PartnerShowCase;
