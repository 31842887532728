import { call, put } from 'redux-saga/effects';
import {
  fileIdentificationService,
  getClient,
  postClient,
  putClient,
} from '../../../services/client';
import {
  getClientError,
  getClientSuccess,
  postClientError,
  postClientSuccess,
  putClientError,
  putClientSuccess,
  fileIdentificationError,
  fileIdentificationSuccess,
} from './actions';
import errorHandler from '../../../helpers/errorHandler';

export function* getClientSaga() {
  try {
    const apiData = yield call(getClient);
    yield put(getClientSuccess(apiData));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        getClientError(err.response.data.error.message, err.response.data.error)
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(getClientError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}

export function* postClientSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(postClient, payload);
    yield put(postClientSuccess(apiData));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        postClientError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(postClientError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}

export function* fileIdentificationSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(fileIdentificationService, payload);
    yield put(fileIdentificationSuccess(apiData));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        fileIdentificationError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(fileIdentificationError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}

export function* putClientSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(putClient, payload);
    yield put(putClientSuccess(apiData));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        putClientError(err.response.data.error.message, err.response.data.error)
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(putClientError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}
