import { action } from 'typesafe-actions';
import { actionTypes } from './types';

export const importFilesFiltersStart = (data) =>
  action(actionTypes.IMPORT_FILES_FILTERS_START, data);
export const importFilesFiltersSuccess = (data) =>
  action(actionTypes.IMPORT_FILES_FILTERS_SUCCESS, data);
export const importFilesFiltersZeroLength = () =>
  action(actionTypes.IMPORT_FILES_FILTERS_ZERO_LENGTH);
export const importFilesFiltersError = (errorMessage, error) =>
  action(actionTypes.IMPORT_FILES_FILTERS_ERROR, { errorMessage }, undefined, {
    error,
  });
export const updateImportFilesFilters = (data) =>
  action(actionTypes.UPDATE_IMPORT_FILES_FILTERS, data);
