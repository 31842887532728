import styled from 'styled-components';
import { Table } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { colors } from 'styles/colors';

export const StTable = styled(Table)`
  &&& {
    .ant-table-body {
      min-height: inherit;
    }

    .ant-table-thead .ant-table-cell {
      font-size: 14px;
      color: ${colors.gray9};
      background-color: ${colors.gray1} !important;
    }

    .ant-table-cell.ant-table-cell.ant-table-cell {
      border: none;
    }
  }
`;

export const StQuestionCircleOutlined = styled(QuestionCircleOutlined)`
  margin-left: 10px;
  font-size: 14px;
  color: ${colors.gray7};
`;
