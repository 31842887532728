import { action } from 'typesafe-actions';
import { actionTypes } from 'store/ducks/updateScreenToScreenPeriod/types';

export const updateScreenToScreenSalesPeriodStart = (data) =>
  action(actionTypes.UPDATE_SCREEN_TO_SCREEN_SALES_PERIOD_START, data);
export const updateScreenToScreenSalesPeriodSuccess = (data) =>
  action(actionTypes.UPDATE_SCREEN_TO_SCREEN_SALES_PERIOD_SUCCESS, data);
export const updateScreenToScreenSalesPeriodError = (errorMessage, error) =>
  action(
    actionTypes.UPDATE_SCREEN_TO_SCREEN_SALES_PERIOD_ERROR,
    { errorMessage },
    undefined,
    { error }
  );

export const updateScreenToScreenPaymentDivergencesPeriodStart = (data) =>
  action(
    actionTypes.UPDATE_SCREEN_TO_SCREEN_PAYMENT_DIVERGENCES_PERIOD_START,
    data
  );
export const updateScreenToScreenPaymentDivergencesPeriodSuccess = (data) =>
  action(
    actionTypes.UPDATE_SCREEN_TO_SCREEN_PAYMENT_DIVERGENCES_PERIOD_SUCCESS,
    data
  );
export const updateScreenToScreenPaymentDivergencesPeriodError = (
  errorMessage,
  error
) =>
  action(
    actionTypes.UPDATE_SCREEN_TO_SCREEN_PAYMENT_DIVERGENCES_PERIOD_ERROR,
    { errorMessage },
    undefined,
    { error }
  );

export const updateScreenToScreenAmountsReceivablePeriodStart = (data) =>
  action(
    actionTypes.UPDATE_SCREEN_TO_SCREEN_AMOUNTS_RECEIVABLE_PERIOD_START,
    data
  );
export const updateScreenToScreenAmountsReceivablePeriodSuccess = (data) =>
  action(
    actionTypes.UPDATE_SCREEN_TO_SCREEN_AMOUNTS_RECEIVABLE_PERIOD_SUCCESS,
    data
  );
export const updateScreenToScreenAmountsReceivablePeriodError = (
  errorMessage,
  error
) =>
  action(
    actionTypes.UPDATE_SCREEN_TO_SCREEN_AMOUNTS_RECEIVABLE_PERIOD_ERROR,
    { errorMessage },
    undefined,
    { error }
  );

export const updateScreenToScreenBanksPeriodStart = (data) =>
  action(actionTypes.UPDATE_SCREEN_TO_SCREEN_BANKS_PERIOD_START, data);
export const updateScreenToScreenBanksPeriodSuccess = (data) =>
  action(actionTypes.UPDATE_SCREEN_TO_SCREEN_BANKS_PERIOD_SUCCESS, data);
export const updateScreenToScreenBanksPeriodError = (errorMessage, error) =>
  action(
    actionTypes.UPDATE_SCREEN_TO_SCREEN_BANKS_PERIOD_ERROR,
    { errorMessage },
    undefined,
    { error }
  );

export const updateScreenToScreenDuedatePeriodStart = (data) =>
  action(actionTypes.UPDATE_SCREEN_TO_SCREEN_DUEDATE_PERIOD_START, data);
export const updateScreenToScreenDuedatePeriodSuccess = (data) =>
  action(actionTypes.UPDATE_SCREEN_TO_SCREEN_DUEDATE_PERIOD_SUCCESS, data);
export const updateScreenToScreenDuedatePeriodError = (errorMessage, error) =>
  action(
    actionTypes.UPDATE_SCREEN_TO_SCREEN_DUEDATE_PERIOD_ERROR,
    { errorMessage },
    undefined,
    { error }
  );

export const updateScreenToScreenAdvancementsPeriodStart = (data) =>
  action(actionTypes.UPDATE_SCREEN_TO_SCREEN_ADVANCEMENTS_PERIOD_START, data);
export const updateScreenToScreenAdvancementsPeriodSuccess = (data) =>
  action(actionTypes.UPDATE_SCREEN_TO_SCREEN_ADVANCEMENTS_PERIOD_SUCCESS, data);
export const updateScreenToScreenAdvancementsPeriodError = (
  errorMessage,
  error
) =>
  action(
    actionTypes.UPDATE_SCREEN_TO_SCREEN_ADVANCEMENTS_PERIOD_ERROR,
    { errorMessage },
    undefined,
    { error }
  );

export const updateScreenToScreenAdjustmentsPeriodStart = (data) =>
  action(actionTypes.UPDATE_SCREEN_TO_SCREEN_ADJUSTMENTS_PERIOD_START, data);
export const updateScreenToScreenAdjustmentsPeriodSuccess = (data) =>
  action(actionTypes.UPDATE_SCREEN_TO_SCREEN_ADJUSTMENTS_PERIOD_SUCCESS, data);
export const updateScreenToScreenAdjustmentsPeriodError = (
  errorMessage,
  error
) =>
  action(
    actionTypes.UPDATE_SCREEN_TO_SCREEN_ADJUSTMENTS_PERIOD_ERROR,
    { errorMessage },
    undefined,
    { error }
  );

export const updateScreenToScreenRefundsPeriodStart = (data) =>
  action(actionTypes.UPDATE_SCREEN_TO_SCREEN_REFUNDS_PERIOD_START, data);
export const updateScreenToScreenRefundsPeriodSuccess = (data) =>
  action(actionTypes.UPDATE_SCREEN_TO_SCREEN_REFUNDS_PERIOD_SUCCESS, data);
export const updateScreenToScreenRefundsPeriodError = (errorMessage, error) =>
  action(
    actionTypes.UPDATE_SCREEN_TO_SCREEN_REFUNDS_PERIOD_ERROR,
    { errorMessage },
    undefined,
    { error }
  );

export const updateScreenToScreenSalesReconciliationPeriodStart = (data) =>
  action(
    actionTypes.UPDATE_SCREEN_TO_SCREEN_SALES_RECONCILIATION_PERIOD_START,
    data
  );
export const updateScreenToScreenSalesReconciliationPeriodSuccess = (data) =>
  action(
    actionTypes.UPDATE_SCREEN_TO_SCREEN_SALES_RECONCILIATION_PERIOD_SUCCESS,
    data
  );
export const updateScreenToScreenSalesReconciliationPeriodError = (
  errorMessage,
  error
) =>
  action(
    actionTypes.UPDATE_SCREEN_TO_SCREEN_SALES_RECONCILIATION_PERIOD_ERROR,
    { errorMessage },
    undefined,
    { error }
  );

export const updateScreenToScreenPaymentsReconciliationPeriodStart = (data) =>
  action(
    actionTypes.UPDATE_SCREEN_TO_SCREEN_PAYMENTS_RECONCILIATION_PERIOD_START,
    data
  );

export const updateScreenToScreenPaymentsReconciliationPeriodSuccess = (data) =>
  action(
    actionTypes.UPDATE_SCREEN_TO_SCREEN_PAYMENTS_RECONCILIATION_PERIOD_SUCCESS,
    data
  );

export const updateScreenToScreenPaymentsReconciliationPeriodError = (
  errorMessage,
  error
) =>
  action(
    actionTypes.UPDATE_SCREEN_TO_SCREEN_PAYMENTS_RECONCILIATION_PERIOD_ERROR,
    { errorMessage },
    undefined,
    { error }
  );

export const updateScreenToScreenBankReconciliationPeriodStart = (data) =>
  action(
    actionTypes.UPDATE_SCREEN_TO_SCREEN_BANK_RECONCILIATION_PERIOD_START,
    data
  );

export const updateScreenToScreenBankReconciliationPeriodSuccess = (data) =>
  action(
    actionTypes.UPDATE_SCREEN_TO_SCREEN_BANK_RECONCILIATION_PERIOD_SUCCESS,
    data
  );

export const updateScreenToScreenBankReconciliationPeriodError = (
  errorMessage,
  error
) =>
  action(
    actionTypes.UPDATE_SCREEN_TO_SCREEN_BANK_RECONCILIATION_PERIOD_ERROR,
    { errorMessage },
    undefined,
    { error }
  );
