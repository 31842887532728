import errorHandler from 'helpers/errorHandler';
import { lambdaApi } from '../api';

export const getDownloadUrl = async ({ notificationId }) => {
  const config = {
    notification_id: notificationId,
  };

  const notificationResponse = await lambdaApi()
    .post('export/get_download_url/post', config)
    .catch((error) => {
      errorHandler(
        `Houve um erro ao tentar obter o link de download da notificação: ${error?.message}`
      );
    });

  const downloadUrl = notificationResponse?.data?.data?.download_url;

  if (downloadUrl) window.open(downloadUrl, '_self');

  return notificationResponse.data;
};
