import { actionTypes } from 'store/ducks/tokenProd/types';

const INITIAL_STATE = {
  error: undefined,
  errorMessage: undefined,
  isError: false,
  loadingPost: false,
  loadingDelete: false,
  loadingGet: false,
  loadingPut: false,
  appKey: '',
  isActive: false,
  startDate: '',
  endDate: '',
  tokenHasBeenUpdated: false,
  requestDeleteAt: '',
  toggleDisableToken: false,
  rowClientId: '',
  tokenProdHasBeenPosted: false,
  tokenProdHasBeenDeleted: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.TOKEN_PROD_POST_START:
      return {
        ...state,
        tokenProdHasBeenPosted: false,
        loadingPost: true,
        error: undefined,
        errorMessage: undefined,
        isError: false,
      };
    case actionTypes.TOKEN_PROD_POST_SUCCESS:
      return {
        ...state,
        tokenProdHasBeenPosted: true,
        loadingPost: false,
        error: undefined,
        isError: false,
        errorMessage: undefined,
      };
    case actionTypes.TOKEN_PROD_POST_ERROR:
      return {
        ...state,
        tokenProdHasBeenPosted: false,
        loadingPost: false,
        error: action.error.error,
        errorMessage: action.payload,
        isError: true,
      };
    case actionTypes.TOKEN_PROD_DELETE_START:
      return {
        ...state,
        tokenProdHasBeenDeleted: false,
        loadingDelete: true,
        error: undefined,
        errorMessage: undefined,
        isError: false,
      };
    case actionTypes.TOKEN_PROD_DELETE_SUCCESS:
      return {
        ...state,
        tokenProdHasBeenDeleted: true,
        loadingDelete: false,
        error: undefined,
        isError: false,
        errorMessage: undefined,
      };
    case actionTypes.TOKEN_PROD_DELETE_ERROR:
      return {
        ...state,
        tokenProdHasBeenDeleted: false,
        loadingDelete: false,
        error: action.error.error,
        errorMessage: action.payload,
        isError: true,
      };
    case actionTypes.TOKEN_PROD_GET_START:
      return {
        ...state,
        loadingGet: true,
        error: undefined,
        errorMessage: undefined,
        isError: false,
      };
    case actionTypes.TOKEN_PROD_GET_SUCCESS:
      return {
        ...state,
        loadingGet: false,
        appKey: action.payload.appKey,
        isActive: action.payload.isActive,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        requestDeleteAt: action.payload.requestDeleteAt,
        error: undefined,
        isError: false,
        errorMessage: undefined,
      };
    case actionTypes.TOKEN_PROD_GET_ERROR:
      return {
        ...state,
        loadingGet: false,
        error: action.error.error,
        errorMessage: action.payload,
        isError: true,
      };
    case actionTypes.TOKEN_PROD_PUT_START:
      return {
        ...state,
        loadingPut: true,
        tokenHasBeenUpdated: false,
        error: undefined,
        errorMessage: undefined,
        isError: false,
      };
    case actionTypes.TOKEN_PROD_PUT_SUCCESS:
      return {
        ...state,
        loadingPut: false,
        tokenHasBeenUpdated: true,
        error: undefined,
        isError: false,
        errorMessage: undefined,
      };
    case actionTypes.TOKEN_PROD_PUT_ERROR:
      return {
        ...state,
        loadingPut: false,
        error: action.error.error,
        errorMessage: action.payload,
        isError: true,
      };
    case actionTypes.TOKEN_PROD_DISABLE_TOKEN_START:
      return {
        ...state,
        error: undefined,
        errorMessage: undefined,
        isError: false,
      };
    case actionTypes.TOKEN_PROD_DISABLE_TOKEN_SUCCESS:
      return {
        ...state,
        toggleDisableToken: action.payload.value,
        error: undefined,
        isError: false,
        errorMessage: undefined,
      };
    case actionTypes.TOKEN_PROD_DISABLE_TOKEN_ERROR:
      return {
        ...state,
        error: action.error.error,
        errorMessage: action.payload,
        isError: true,
      };
    case actionTypes.ROW_CLIENT_ID_PROD_START:
      return {
        ...state,
        error: undefined,
        errorMessage: undefined,
        isError: false,
      };
    case actionTypes.ROW_CLIENT_ID_PROD_SUCCESS:
      return {
        ...state,
        rowClientId: action.payload.value,
        error: undefined,
        isError: false,
        errorMessage: undefined,
      };
    case actionTypes.ROW_CLIENT_ID_PROD_ERROR:
      return {
        ...state,
        error: action.error.error,
        errorMessage: action.payload,
        isError: true,
      };
    default:
      return state;
  }
};

export default reducer;
