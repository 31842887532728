/* eslint-disable no-restricted-globals */
import store from 'store';
import { call, put } from 'redux-saga/effects';
import errorHandler from 'helpers/errorHandler';
import putChangeStoreFromAPI from 'services/changeStore';
import {
  changeStoreSuccess,
  changeStoreError,
} from 'store/ducks/changeStore/actions';
import {
  getSessionUsername,
  setSessionClientCode,
  setSessionClientId,
  setSessionClientName,
  setSessionIsImplanted,
} from 'helpers/sessionService';
import { authSuccess } from '../auth/actions';
import { permissionsStart } from '../permissions/actions';

export function* changeStoreSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(putChangeStoreFromAPI, payload);

    if (
      payload?.updateLocalStorage ||
      getSessionUsername() === apiData?.data.user_email
    ) {
      yield setSessionClientName(apiData?.data.client_name);
      yield setSessionClientId(apiData?.data.client_id);
      yield setSessionClientCode(apiData?.data.client_code);
      yield setSessionIsImplanted(apiData?.data.is_implanted);
    }
    store.dispatch(permissionsStart());
    yield put(changeStoreSuccess(apiData?.data));
    yield put(
      authSuccess({
        isImplanted: apiData?.data.is_implanted,
      })
    );
    window.location.reload();
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        changeStoreError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    }
  }
}
