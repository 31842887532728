import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Modal,
  notification,
  Row,
  Spin,
  Typography,
  Table,
} from 'antd';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { isConciler, isSuperAdmin } from 'helpers/authorizationService';
import { optinRequestStart } from 'store/ducks/optin/optinRequest/actions';
import { optinStart } from 'store/ducks/optin/optin/actions';
import { tryParsingJsonString } from 'helpers/generalService';
import { CopyBlock, monokai } from 'react-code-blocks';
import { getSessionClientId } from 'helpers/sessionService';
import { colors } from 'styles/colors';
import dayjs from 'dayjs';
import { StCustomInput } from '../styled';

const assetsBaseURL = process.env.REACT_APP_AWS_V4_ASSETS;
const instruction1 = `${assetsBaseURL}/briefing/rede_optin_instruction.png`;
const instruction2 = `${assetsBaseURL}/briefing/rede_optin_instruction2.png`;
const instruction3 = `${assetsBaseURL}/briefing/rede_optin_instruction3.png`;

const BriefingOptinRede = ({
  acquirer,
  isLocked,
  isAcquirerOk,
  setIsAcquirerOk,
}) => {
  const dispatch = useDispatch();
  const optinData = useSelector((state) => state.optin?.data);
  const optinRequestData = useSelector((state) => state.optinRequest?.data);
  const requestingMerchant = useSelector(
    (state) => state.optinRequest?.requestingMerchant
  );
  const requestingMerchantLoading = useSelector(
    (state) => state.optinRequest?.requestingMerchantLoading
  );
  // eslint-disable-next-line no-unused-vars
  const [clickedMerchant, setClickedMerchant] = useState({});
  const [merchantStatus, setMerchantStatus] = useState({});
  const [merchantStatusDate, setMerchantStatusDate] = useState({});
  const [merchantDescription, setMerchantDescription] = useState({});
  const [merchantLogsParameters, setMerchantLogsParameters] = useState({});

  const [isLogModalVisible, setIsLogModalVisible] = useState({});

  const showLogModal = (merchantCode) => {
    setIsLogModalVisible({
      ...isLogModalVisible,
      [merchantCode]: true,
    });
  };

  const hideLogModal = (merchantCode) => {
    setIsLogModalVisible({
      ...isLogModalVisible,
      [merchantCode]: false,
    });
  };

  const handleOptinAuth = (merchant) => {
    dispatch(
      optinRequestStart({
        merchant: merchant?.merchant_code,
        acquirer: acquirer?.name,
      })
    );
    setClickedMerchant(merchant);
  };

  useEffect(() => {
    const clientId = getSessionClientId();
    dispatch(
      optinStart({
        page: 1,
        perPage: 100,
        clientId: clientId,
        acquirer: acquirer?.name,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const _merchantStatus = {};
    const _merchantStatusDate = {};
    const _merchantDescription = {};
    const _merchantLogsParameters = {};
    if (optinData[acquirer?.name]) {
      optinData[acquirer?.name].forEach((_optin) => {
        // eslint-disable-next-line no-unused-expressions
        acquirer?.merchants.forEach((_merchant) => {
          if (_merchant?.merchant_code === _optin?.merchant_code) {
            _merchantStatus[_merchant?.merchant_code] = _optin?.status;
            _merchantDescription[_merchant?.merchant_code] =
              _optin?.description;
            _merchantStatusDate[_merchant?.merchant_code] =
              _optin?.update_datetime;
            _merchantLogsParameters[_merchant?.merchant_code] =
              _optin?.logs_parameters;
          }
        });
      });

      setMerchantStatus(_merchantStatus);
      setMerchantStatusDate(_merchantStatusDate);
      setMerchantDescription(_merchantDescription);
      setMerchantLogsParameters(_merchantLogsParameters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optinData]);

  useEffect(() => {
    const optinMerchantCodes = Object.keys(merchantStatus);
    const allMerchantCodes = [];
    acquirer.merchants.forEach((merchant) => {
      allMerchantCodes.push(merchant?.merchant_code);
    });
    if (
      optinMerchantCodes.sort().join(',') === allMerchantCodes.sort().join(',')
    ) {
      setIsAcquirerOk({
        ...isAcquirerOk,
        [acquirer?.acquirer_id]: true,
      });
    } else {
      setIsAcquirerOk({
        ...isAcquirerOk,
        [acquirer?.acquirer_id]: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantStatus]);

  useEffect(() => {
    if (optinRequestData?.code === 201 && acquirer === 'REDE') {
      notification.success({
        message: 'Requisição de autorização realizada com sucesso!',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optinRequestData]);

  const translateStatus = (status) => {
    let translatedStatus = 'Pendente';
    if (status === 'UNAVAILABLE') {
      translatedStatus = 'Indisponível';
    } else if (status === 'DUPLICATED') {
      translatedStatus = 'Duplicação de arquivo';
    } else if (status === 'PROCESSING' || status === 'WAITING') {
      translatedStatus = 'Aguardando';
    } else if (status === 'COMPLETED') {
      translatedStatus = 'Autorizado';
    }
    return translatedStatus;
  };

  const transformMerchantData = (merchant) => {
    const dataSource = [
      {
        key: 0,
        date: merchantStatusDate[merchant?.merchant_code],
        status: merchantStatus[merchant?.merchant_code],
        msg: merchantDescription[merchant?.merchant_code],
      },
    ];

    return dataSource;
  };

  const columns = [
    {
      title: 'Data',
      dataIndex: 'date',
      key: 'date',
      render: (_, record) => {
        return <span>{record?.date ? record?.date : '--/--/--'}</span>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_, record) => {
        return <span>{record?.status ? record?.status : '???'}</span>;
      },
    },
    {
      title: 'Mensagem',
      dataIndex: 'msg',
      key: 'msg',
      render: (_, record) => {
        return (
          <span>
            {record?.msg ? record?.msg : 'Não há mensagem disponível.'}
          </span>
        );
      },
    },
  ];

  const renderLogModal = (merchant) => {
    const merchantCode = merchant?.merchant_code;
    return (
      <Modal
        title={`Logs - Número de estabelecimento: ${merchantCode}`}
        open={isLogModalVisible[merchant?.merchant_code]}
        onOk={() => {
          hideLogModal(merchant?.merchant_code);
        }}
        onCancel={() => {
          hideLogModal(merchant?.merchant_code);
        }}
        okText="Ok"
        cancelText="Cancelar"
        style={{
          minWidth: '800px',
        }}
        bodyStyle={{ maxHeight: '500px', overflowY: 'auto' }}
        key={merchant?.merchant_code}
      >
        <Row style={{ overFlowY: 'auto' }}>
          <Col span={24}>
            <Table
              dataSource={transformMerchantData(merchant)}
              columns={columns}
              pagination={false}
            />
          </Col>
          <Col span={24}>
            {merchantLogsParameters[merchant?.merchant_code]?.map(
              (merchantLogParameters) => {
                const updateDatetime = dayjs(
                  merchantLogParameters?.update_datetime
                ).format('DD-MM-YYYY HH:mm:ss');
                let parameterValue = merchantLogParameters?.parameter_value;
                if (tryParsingJsonString(parameterValue)) {
                  parameterValue = JSON.stringify(
                    JSON.parse(parameterValue),
                    null,
                    4
                  );
                }
                return (
                  <Row style={{ marginTop: '8px', marginBottom: '8px' }}>
                    <Col span={24}>
                      <h4>Entrada em {updateDatetime}:</h4>
                    </Col>
                    <Col span={24}>
                      <CopyBlock
                        language="javascript"
                        text={parameterValue}
                        showLineNumbers
                        theme={monokai}
                        codeBlock
                      />
                    </Col>
                  </Row>
                );
              }
            )}
          </Col>
        </Row>
      </Modal>
    );
  };

  return (
    <div style={{ paddingBottom: '64px' }}>
      {!isLocked && (
        <Row>
          <Col span={24}>
            <Typography.Text style={{ fontWeight: 'bold' }}>
              Autorização Rede
            </Typography.Text>
            <Typography.Text>
              <br />
              Para autorizar nosso acesso aos seus dados da Rede, siga as
              instruções abaixo para cada estabelecimento cadastrado.
              <br />
              <br />
            </Typography.Text>
          </Col>
        </Row>
      )}
      {!isLocked && (
        <Row gutter={[10, 10]} style={{ wordBreak: 'keep-all' }}>
          <Col span={24}>
            <Typography.Text style={{ fontWeight: 'bold' }}>
              Instruções
            </Typography.Text>
          </Col>
          <Col span={8}>
            <p style={{ display: 'block' }}>
              1. Clique em <b>Solicitar acesso</b> na lista abaixo, para
              pedirmos a liberação de acesso ao CNPJ para a Rede.
            </p>
            <img width="300px" alt="" src={instruction1} />
          </Col>
          <Col span={8}>
            <p style={{ display: 'block' }}>
              2. Acesse o{' '}
              <a
                href="https://meu.userede.com.br/login"
                target="_blank"
                rel="noopener noreferrer"
              >
                portal da Rede
              </a>{' '}
              com seu login e senha e acesse a aba <b>Conciliação</b> nos{' '}
              <b>Dados Cadastrais</b> da filial.
            </p>
            <img width="200px" alt="" src={instruction2} />
          </Col>
          <Col span={8}>
            <p style={{ display: 'block' }}>
              3. Clique em <b>Compartilhar</b> para permitir o compartilhamento
              dos dados da filial para a Concil Inteligência em Conciliação.
              Caso não esteja visualizando a mensagem, tente aguardar alguns
              minutos e atualize a página.
            </p>
            <br />
            <img width="400px" alt="" src={instruction3} />
            <br />
            <p style={{ display: 'block' }}>
              O acesso tem validade de 30 dias, caso esteja vencido, será
              necessário repetir as instruções.
            </p>
          </Col>
        </Row>
      )}
      <Row gutter={[5, 5]}>
        <Col xxl={8} xl={6}>
          <Typography.Text style={{ fontSize: '1.75rem' }}>
            CNPJ | Nome da loja
          </Typography.Text>
        </Col>
        <Col xxl={4} xl={4}>
          <Typography.Text style={{ fontSize: '1.75rem' }}>
            Código de estabelecimento
          </Typography.Text>
        </Col>
        <Col xxl={3} xl={3}>
          <Typography.Text style={{ fontSize: '1.75rem' }}></Typography.Text>
        </Col>
        <Col xxl={3} xl={3}>
          <Typography.Text style={{ fontSize: '1.75rem' }}>
            Status
          </Typography.Text>
        </Col>
        {isConciler() || isSuperAdmin() ? (
          <Col xxl={6} xl={8}>
            <Typography.Text style={{ fontSize: '1.75rem' }}>
              Autorização via API
            </Typography.Text>
          </Col>
        ) : (
          <></>
        )}
      </Row>
      {acquirer.merchants.map((merchant) => (
        <fieldset>
          <Row gutter={[5, 5]} align="middle">
            <Col xxl={8} xl={6}>
              <Typography.Text style={{ fontSize: '1.75rem' }}>
                <StCustomInput
                  value={merchant.store_br_cnpj + ' | ' + merchant.store_name}
                  disabled
                />
              </Typography.Text>
            </Col>
            <Col xxl={4} xl={4}>
              <Typography.Text style={{ fontSize: '1.75rem' }}>
                <StCustomInput value={merchant.merchant_code} disabled />
              </Typography.Text>
            </Col>
            <Col xxl={3} xl={3}>
              <Button
                onClick={() => {
                  handleOptinAuth(merchant);
                }}
                style={{ border: `1px solid ${colors.primary5}` }}
              >
                {merchant.merchant_code === requestingMerchant &&
                requestingMerchantLoading ? (
                  <Spin />
                ) : (
                  'Autorizar'
                )}
              </Button>
            </Col>
            <Col xxl={3} xl={3}>
              <Typography.Text style={{ fontSize: '1.75rem' }}>
                {merchant.merchant_code === requestingMerchant &&
                requestingMerchantLoading ? (
                  <Spin />
                ) : (
                  <>
                    {`${translateStatus(
                      merchantStatus[merchant?.merchant_code]
                    )} ${
                      translateStatus(
                        merchantStatus[merchant?.merchant_code]
                      ) === 'Autorizado'
                        ? 'em ' + merchantStatusDate[merchant?.merchant_code] ||
                          '--/--/--'
                        : ''
                    }` || 'Pendente'}
                  </>
                )}
              </Typography.Text>
            </Col>
            {isConciler() || isSuperAdmin() ? (
              <Col xxl={6} xl={8}>
                <Button onClick={() => showLogModal(merchant?.merchant_code)}>
                  Ver logs
                </Button>
              </Col>
            ) : (
              <></>
            )}
            <Col>{isLogModalVisible && renderLogModal(merchant)}</Col>
          </Row>
        </fieldset>
      ))}
    </div>
  );
};

BriefingOptinRede.defaultProps = {
  isLocked: false,
};
BriefingOptinRede.propTypes = {
  isLocked: PropTypes.bool,
};

export default BriefingOptinRede;
