import { call, put } from 'redux-saga/effects';
import errorHandler from 'helpers/errorHandler';
import {
  putStoresSuccess,
  putStoresError,
} from 'store/ducks/cnpjMaintenance/putStores/actions';
import putStores from 'services/cnpjMaintenance/putStores';
import { notification } from 'antd';

export function* putStoresSaga(action) {
  try {
    const { payload } = action;
    const { storeId } = payload;
    const apiData = yield call(putStores, payload);
    yield put(putStoresSuccess(apiData));
    notification.success({ message: `Loja ${storeId} ativada com sucesso!` });
  } catch (err) {
    yield put(putStoresError('Ocorreu um erro:', err));
    if (err?.response?.data?.message) {
      yield put(
        putStoresError(err.response.data.message, err.response.data.error)
      );
      errorHandler(err.response.data.message, err.response.data.error);
    } else {
      yield put(putStoresError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
      errorHandler(err.response.data.message, err.response.data.error);
    }
  }
}
