/* eslint-disable no-unused-expressions */
import dayjs from 'dayjs';
import { splitNParts } from 'helpers/generalService';
import { lambdaApi } from '../api';

const transformer = (apiResponse) => {
  const apiResults = apiResponse?.data?.reconciled;
  const transformedResults = [];
  const debug = process.env.REACT_APP_DEBUG === 'true';

  apiResults.forEach((item) => {
    let transformedItem;
    if (debug) {
      const generatedNumbers = [];

      const split = splitNParts(100, 3);
      for (let i = 0; i < 3; i += 1) {
        generatedNumbers?.push(split.next().value);
      }

      transformedItem = {
        forecast: {
          percentPendent: generatedNumbers[0],
          percentDifference: generatedNumbers[1],
          percentReconciled: generatedNumbers[2],
          totalAmount: new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(23457),
          rawTotalAmount: 23457,
          name: item?.name,
        },
        confirmation: {
          percentPendent: generatedNumbers[0],
          percentDifference: generatedNumbers[1],
          percentReconciled: generatedNumbers[2],
          totalAmount: new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(543534),
          rawTotalAmount: 543534,
          name: item?.name,
        },
      };
      transformedResults.push(transformedItem);
    } else {
      transformedItem = {
        forecast: {
          percentAmountPendent: item?.forecast?.percent_amount_pendent,
          percentAmountDifference: item?.forecast?.percent_amount_diffence, // Wrong english from API
          percentAmountReconciled: item?.forecast?.percent_amount_reconciled,
          totalAmount: new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(item?.forecast?.total_amount),
          rawTotalAmount: item?.forecast?.total_amount,
          name: item?.name,
        },
        confirmation: {
          percentAmountPendent: item?.confirmation?.percent_amount_pendent,
          percentAmountDifference: item?.confirmation?.percent_amount_diffence, // Wrong english from API
          percentAmountReconciled:
            item?.confirmation?.percent_amount_reconciled,
          totalAmount: new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(item?.confirmation?.total_amount),
          rawTotalAmount: item?.confirmation?.total_amount,
          name: item?.name,
        },
      };
      transformedResults.push(transformedItem);
    }
  });

  return transformedResults;
};

const getReconciledPayments = async ({
  type,
  orderBy,
  filter,
  startDate,
  endDate,
}) => {
  const config = {
    params: {
      type,
      order_by: orderBy || 'DESC',
      filter,
      page: 1,
      per_page: 100,
      start_date: dayjs(startDate).format('YYYY-MM-DD'),
      end_date: dayjs(endDate).format('YYYY-MM-DD'),
    },
  };

  const response = await lambdaApi().get(
    'intermediate/payment/reconciled',
    config
  );

  return transformer(response.data);
};

export default getReconciledPayments;
