import { action } from 'typesafe-actions';
import { actionTypes } from './types';

export const getClientAcquirerStart = (data) => action(actionTypes.GET_CLIENT_ACQUIRER_START, data);
export const getClientAcquirerSuccess = (data) => action(actionTypes.GET_CLIENT_ACQUIRER_SUCCESS, data);
export const getClientAcquirerError = (errorMessage, error) =>
  action(actionTypes.GET_CLIENT_ACQUIRER_ERROR, { errorMessage }, undefined, { error });

export const postClientAcquirerStart = (data) => action(actionTypes.POST_CLIENT_ACQUIRER_START, data);
export const postClientAcquirerSuccess = (data) => action(actionTypes.POST_CLIENT_ACQUIRER_SUCCESS, data);
export const postClientAcquirerError = (errorMessage, error) =>
  action(actionTypes.POST_CLIENT_ACQUIRER_ERROR, { errorMessage }, undefined, { error });

export const deleteClientAcquirerStart = (data) => action(actionTypes.DELETE_CLIENT_ACQUIRER_START, { data });
export const deleteClientAcquirerSuccess = (data) => action(actionTypes.DELETE_CLIENT_ACQUIRER_SUCCESS, data);
export const deleteClientAcquirerError = (errorMessage, error) =>
  action(actionTypes.DELETE_CLIENT_ACQUIRER_ERROR, { errorMessage }, undefined, { error });
