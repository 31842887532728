import { call, put } from 'redux-saga/effects';
import { authCompleteNewPassword } from 'services/auth';
import errorHandler from 'helpers/errorHandler';
import {
  completeNewPasswordSuccess,
  completeNewPasswordFailure,
} from './actions';
import { removeLocalStorage } from 'helpers/sessionService';

export function* completeNewPasswordSaga(action) {
  const { formData } = action.payload;
  try {
    const cognitoData = yield call(authCompleteNewPassword, formData);
    yield removeLocalStorage();
    yield put(completeNewPasswordSuccess(cognitoData));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        completeNewPasswordFailure(
          err.response.data.error,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else if (err?.response?.data?.error) {
      yield put(
        completeNewPasswordFailure(
          err.response.data.error,
          err.response.data.error
        )
      );
      errorHandler('Ocorreu um erro:', err);
    }
  }
}
