/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
import React from 'react';
import { Col, Input, Row, Select } from 'antd';
import { updateLocalStorage } from './ColumnList';
import { isAcquirerIgnored } from 'components/BankConciliationTransactions/helpers/acquirerStatus';
import { shortId } from 'helpers/shortId';

const AcquirerAction = ({
  currentItem,
  currentRow,
  acquirers,
  changeTransaction,
  setUpdateLocal,
}) => {
  const { Option } = Select;
  const sortedAcquirers = acquirers.sort((a, b) => (a.name > b.name ? 1 : -1));

  return (
    <Row>
      <Col>
        <Input.Group compact>
          <Select
            className="select-acquirer"
            onChange={(value) => {
              updateLocalStorage(currentRow, value, false);
              changeTransaction(
                currentRow.unique_id,
                value,
                currentRow?.id_file_tracking
              );
              setUpdateLocal((prevState) => prevState + 1);
            }}
            style={{ width: '150px' }}
            value={currentItem}
            disabled={isAcquirerIgnored(currentItem)}
          >
            {sortedAcquirers.map((acquirerObj) => (
              <Option key={shortId()} value={acquirerObj.name}>
                {acquirerObj.name}
              </Option>
            ))}
          </Select>
        </Input.Group>
      </Col>
    </Row>
  );
};

AcquirerAction.propTypes = {};

export default AcquirerAction;
