import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Spin, Typography } from 'antd';
import {
  changeNotificationStatusStart,
  getNotificationStart,
} from 'store/ducks/notifications/actions';
import { getSessionClientId } from 'helpers/sessionService';
import NotificationCard from '../NotificationCard/NotificationCard';
import { LoadingContainer } from '../../styled';
import { colors } from 'styles/colors';
import { Empty } from 'commons/Empty';
import dayjs from 'dayjs';
import {
  StNotificationsBottom,
  StNotificationsContainer,
  StNotificationsContent,
  StNotificationsTitle,
  StNotificationsWrapper,
} from './styled';
import { dataTestIdHandler } from 'helpers/dataTestIdHandler';

export const HeaderNotifications = ({ goToNotifications }) => {
  const dispatch = useDispatch();
  const { Text } = Typography;

  const today = new Date();
  const formattedToday = dayjs(today).format('DD/MM - HH:mm');

  const notifications = useSelector(
    (state) => state.notifications.notifications
  );
  const resumeNotifications = useSelector(
    (state) => state.notifications.resumeNotifications
  );
  const notificationsLoading = useSelector(
    (state) => state.notifications.loading
  );
  const hasUnreadNotifications = useSelector(
    (state) => state.notifications.hasUnreadNotifications
  );
  const notificationPageFromStore = useSelector(
    (state) => state.notifications.notificationPage
  );
  const permissionsData = useSelector((state) => state.permissions.data);
  const clientId = getSessionClientId();
  const { user_id: userId, scope_id: scopeId } = permissionsData;
  const dataTestPrefix = 'notifications-modal';

  useEffect(() => {
    dispatch(
      getNotificationStart({
        userId,
        scopeId,
        clientId,
        page: notificationPageFromStore,
        perPage: 10,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (hasUnreadNotifications) {
      dispatch(
        changeNotificationStatusStart({
          userId,
          clientId: getSessionClientId(),
          scopeId,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasUnreadNotifications]);

  return (
    <StNotificationsWrapper>
      <StNotificationsTitle>Notificações</StNotificationsTitle>
      <StNotificationsContent>
        {!notifications && !notificationsLoading ? (
          <Empty reason="Não há notificações" />
        ) : (
          <StNotificationsContainer>
            {!notificationsLoading ? (
              <>
                {/* Today's Resume Notifications */}
                {resumeNotifications?.resumeToday !== undefined &&
                  resumeNotifications?.resumeToday.length > 0 && (
                    <>
                      <Row
                        style={{
                          backgroundColor: colors.secondary1,
                          width: '100%',
                          marginTop: '16px',
                        }}
                      >
                        <Text style={{ fontSize: '14px', marginLeft: '30px' }}>
                          Hoje
                        </Text>
                      </Row>

                      <Row style={{ display: 'flex', flexDirection: 'column' }}>
                        {notifications !== undefined &&
                          resumeNotifications !== undefined &&
                          resumeNotifications?.resumeToday.map(
                            ({
                              title,
                              status,
                              created_at: createdAt,
                              email,
                              file_name: fileName,
                              message,
                              notification_id: notificationId,
                              is_expired: isExpired,
                              notification_type: notificationType,
                            }) => {
                              const formattedDate =
                                dayjs(createdAt).format('DD/MM - HH:mm');
                              return (
                                <NotificationCard
                                  key={notificationId}
                                  isResume
                                  title={title}
                                  status={status.trim()}
                                  notificationTime={formattedDate}
                                  today={formattedToday}
                                  email={email}
                                  fileName={fileName}
                                  message={message}
                                  notificationId={notificationId}
                                  isExpired={isExpired}
                                  notificationType={notificationType}
                                  goToNotifications={goToNotifications}
                                />
                              );
                            }
                          )}
                      </Row>
                    </>
                  )}

                {/* Yesterday's Resume Notifications */}
                {resumeNotifications?.resumeYesterday !== undefined &&
                  resumeNotifications?.resumeYesterday.length > 0 && (
                    <>
                      <Row
                        style={{
                          backgroundColor: colors.secondary1,
                          width: '100%',
                          marginTop: '16px',
                        }}
                      >
                        <Text style={{ fontSize: '14px', marginLeft: '30px' }}>
                          Ontem
                        </Text>
                      </Row>

                      <Row style={{ display: 'flex', flexDirection: 'column' }}>
                        {notifications !== undefined &&
                          resumeNotifications !== undefined &&
                          resumeNotifications?.resumeYesterday.map(
                            ({
                              title,
                              status,
                              created_at: createdAt,
                              email,
                              file_name: fileName,
                              message,
                              notification_id: notificationId,
                              is_expired: isExpired,
                              notification_type: notificationType,
                            }) => {
                              const formattedDate =
                                dayjs(createdAt).format('DD/MM - HH:mm');
                              return (
                                <NotificationCard
                                  key={notificationId}
                                  isResume
                                  title={title}
                                  status={status.trim()}
                                  notificationTime={formattedDate}
                                  today={formattedToday}
                                  email={email}
                                  fileName={fileName}
                                  message={message}
                                  notificationId={notificationId}
                                  isExpired={isExpired}
                                  notificationType={notificationType}
                                  goToNotifications={goToNotifications}
                                />
                              );
                            }
                          )}
                      </Row>
                    </>
                  )}

                {/* Old Resume Notifications */}
                {resumeNotifications?.resumeOld !== undefined &&
                  resumeNotifications?.resumeOld.length > 0 && (
                    <>
                      <Row
                        style={{
                          backgroundColor: colors.secondary1,
                          width: '100%',
                          marginTop: '16px',
                        }}
                      >
                        <Text style={{ fontSize: '14px', marginLeft: '30px' }}>
                          Mensagens antigas
                        </Text>
                      </Row>

                      <Row style={{ display: 'flex', flexDirection: 'column' }}>
                        {notifications !== undefined &&
                          resumeNotifications !== undefined &&
                          resumeNotifications?.resumeOld.map(
                            ({
                              title,
                              status,
                              created_at: createdAt,
                              email,
                              file_name: fileName,
                              message,
                              notification_id: notificationId,
                              is_expired: isExpired,
                              notification_type: notificationType,
                            }) => {
                              const formattedDate =
                                dayjs(createdAt).format('DD/MM - HH:mm');
                              return (
                                <NotificationCard
                                  key={notificationId}
                                  isResume
                                  title={title}
                                  status={status.trim()}
                                  notificationTime={formattedDate}
                                  today={formattedToday}
                                  email={email}
                                  fileName={fileName}
                                  message={message}
                                  notificationId={notificationId}
                                  isExpired={isExpired}
                                  notificationType={notificationType}
                                  goToNotifications={goToNotifications}
                                />
                              );
                            }
                          )}
                      </Row>
                    </>
                  )}
              </>
            ) : (
              <LoadingContainer>
                <Spin />
              </LoadingContainer>
            )}
          </StNotificationsContainer>
        )}
      </StNotificationsContent>
      <StNotificationsBottom
        onClick={() => goToNotifications()}
        {...dataTestIdHandler(dataTestPrefix, 'all-notifications-button')}
      >
        Ver todas as notificações
      </StNotificationsBottom>
    </StNotificationsWrapper>
  );
};

export default HeaderNotifications;
