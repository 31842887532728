/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import customHistory from 'helpers/history';
import { CardResume } from '../../../../commons/CardResume';
import { updateScreenToScreenDateStart } from 'store/ducks/updateScreenToScreenDate/actions';
import dayjs from 'dayjs';

const RefundsCard = ({
  startDateRefunds,
  endDateRefunds,
  setStartDateRefunds,
  setEndDateRefunds,
  isDuedate,
  isAdvancements,
  isAdjustments,
  isRefunds,
}) => {
  const dispatch = useDispatch();
  const refundsCardProps = {
    startDateRefunds,
    endDateRefunds,
    setStartDateRefunds,
    setEndDateRefunds,
    isDuedate,
    isAdvancements,
    isAdjustments,
    isRefunds,
  };

  const refundsPaymentsData = useSelector(
    (store) => store.refundsPaymentsDash.payments
  );
  const refundsPaymentsLoading = useSelector(
    (store) => store.refundsPaymentsDash.loading
  );

  const dataTestPrefix = 'refunds-card';

  const goToRefunds = () => {
    customHistory.push('/cancchbk-intermediario');
    dispatch(
      updateScreenToScreenDateStart({
        startDate: startDateRefunds,
        endDate: endDateRefunds,
      })
    );
  };

  return (
    <CardResume
      titleCard="Canc./Chbk."
      goToUrl={goToRefunds}
      dataCard={refundsPaymentsData}
      loading={refundsPaymentsLoading}
      dataTestPrefix={dataTestPrefix}
      {...refundsCardProps}
    />
  );
};

RefundsCard.propTypes = {
  startDateRefunds: PropTypes.instanceOf(dayjs).isRequired,
  endDateRefunds: PropTypes.instanceOf(dayjs).isRequired,
  setStartDateRefunds: PropTypes.func.isRequired,
  setEndDateRefunds: PropTypes.func.isRequired,
};

export default RefundsCard;
