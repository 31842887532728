import React, { useEffect } from 'react';
import { Form, Button, DatePicker, Checkbox, Row, Col, Spin } from 'antd';
import { useBrands } from 'components/Fees/hooks/useBrands';
import { useFeeForm } from 'components/Fees/hooks/useFeeForm';
import { CardModal } from 'components/Fees/components/CardModal';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import {
  StBrandContainer,
  StBrandName,
  StButtonsContainer,
  StCancelButton,
  StCheckboxColumn,
  StColumnHeader,
  StContainer,
  StDeleteIntervalButton,
  StDivider,
  StFieldContainer,
  StIntervalLabel,
  StPlanInput,
  StPlanLabel,
  StPlanSpace,
  StPlansContainer,
  StProductDescription,
  StProductName,
  StRowHeader,
  StWrapper,
} from './styled';
import { InputMaskPercent } from 'commons/InputMaskPercent';

const FeeForm = ({ data = {} }) => {
  let counter = 0;
  const {
    acquirerId,
    productId,
    productName = '',
    canInstallments = false,
  } = data;
  const {
    brands: brandsData,
    loading: loadingBrands,
    fetchBrands,
  } = useBrands();
  const {
    form,
    loading,
    initialValues,
    rules,
    rulesFeePercent,
    rulesEndDate,
    rulesPlanStart,
    rulesPlanEnd,
    onFinish,
    isModalOpen,
    setModalOpen,
    modalType,
    onFeesRedirect,
    onConfirm,
    onClickCancel,
    onClickSave,
    isCheckedAll,
    onChangeCheckAll,
    isBrandSelected,
    onSelectBrand,
    onChangeStartDate,
    isEndDateEnabled,
    disabledEndDate,
    disabledStartDate,
    onAddInterval,
    onRemoveInterval,
    isIntervalDisabled,
    isSaveButtonDisabled,
  } = useFeeForm({ data, brandsData });

  useEffect(() => {
    if (acquirerId && productId) {
      fetchBrands({ acquirerId, productId });
    }
    // eslint-disable-next-line
  }, []);

  if (loadingBrands) {
    return (
      <StContainer>
        <Spin />
      </StContainer>
    );
  }

  if (brandsData.length === 0) return null;

  return (
    <>
      <Form
        form={form}
        initialValues={initialValues()}
        onFinish={onFinish}
        autoComplete="off"
      >
        <StWrapper>
          <StContainer>
            <StProductName>{productName}</StProductName>
            <StProductDescription $canInstallments={canInstallments}>
              {canInstallments
                ? 'Adicione o intervalo de parcelas e selecione as bandeiras para as quais deseja cadastrar valor e vigência das taxas'
                : 'Selecione as bandeiras para as quais deseja cadastrar valor e vigência das taxas'}
            </StProductDescription>
            <Form.List name="plans">
              {(fields, { add }) => {
                return (
                  <>
                    {fields.map((field, planIndex) => {
                      if (planIndex === 0) counter = 0;
                      if (isIntervalDisabled(planIndex)) return null;
                      const planKey = `plan${planIndex}`;
                      counter += 1;
                      return (
                        <StFieldContainer
                          key={planKey}
                          $canInstallments={canInstallments}
                        >
                          {canInstallments && (
                            <StPlanSpace
                              $canInstallments={canInstallments}
                              key={field.key}
                              align="start"
                            >
                              <StIntervalLabel>
                                {`Intervalo ${counter}`}
                              </StIntervalLabel>
                              <StPlansContainer>
                                <StPlanLabel>
                                  Cadastrar taxas para parcelas de
                                </StPlanLabel>
                                <Form.Item
                                  style={{ margin: 0 }}
                                  name={[field.name, 'planStart']}
                                  fieldKey={[field.fieldKey, 'planStart']}
                                  rules={rulesPlanStart()}
                                >
                                  <StPlanInput min={1} max={24} />
                                </Form.Item>
                                <StPlanLabel>a</StPlanLabel>
                                <Form.Item
                                  style={{ margin: 0 }}
                                  name={[field.name, 'planEnd']}
                                  fieldKey={[field.fieldKey, 'planEnd']}
                                  rules={rulesPlanEnd(
                                    planIndex,
                                    canInstallments
                                  )}
                                >
                                  <StPlanInput min={2} max={24} />
                                </Form.Item>

                                <StDeleteIntervalButton
                                  icon={<DeleteOutlined />}
                                  onClick={() => onRemoveInterval(planIndex)}
                                >
                                  Excluir intervalo
                                </StDeleteIntervalButton>
                              </StPlansContainer>
                            </StPlanSpace>
                          )}

                          <Form.List name={[field.name, 'brands']}>
                            {(brands) => {
                              return (
                                <StBrandContainer>
                                  <StRowHeader>
                                    <StCheckboxColumn span={2}>
                                      <Checkbox
                                        checked={isCheckedAll(planIndex)}
                                        onChange={(e) =>
                                          onChangeCheckAll(e, planIndex)
                                        }
                                      />
                                    </StCheckboxColumn>
                                    <StColumnHeader span={4}>
                                      Bandeira
                                    </StColumnHeader>
                                    <StColumnHeader span={4}>
                                      Taxa
                                    </StColumnHeader>
                                    <StColumnHeader span={4}>
                                      Início da vigência
                                    </StColumnHeader>
                                    <StColumnHeader span={4}>
                                      Término da vigência
                                    </StColumnHeader>
                                  </StRowHeader>
                                  {brands.map((brand, brandIndex) => {
                                    const brandKey = `brand${planIndex}${brandIndex}`;
                                    return (
                                      <div key={brandKey}>
                                        <StDivider />
                                        <Row key={brand.key}>
                                          <StCheckboxColumn span={2}>
                                            <Form.Item style={{ margin: 0 }}>
                                              <Checkbox
                                                checked={isBrandSelected(
                                                  planIndex,
                                                  brandIndex
                                                )}
                                                onChange={() =>
                                                  onSelectBrand(
                                                    planIndex,
                                                    brandIndex
                                                  )
                                                }
                                              />
                                            </Form.Item>
                                          </StCheckboxColumn>
                                          <Col span={4}>
                                            <Form.Item
                                              style={{ margin: 0 }}
                                              name={[brand.name, 'brandName']}
                                              fieldKey={[
                                                brand.fieldKey,
                                                'brandName',
                                              ]}
                                            >
                                              <StBrandName
                                                placeholder="Bandeira"
                                                onClick={() =>
                                                  onSelectBrand(
                                                    planIndex,
                                                    brandIndex
                                                  )
                                                }
                                                readOnly
                                              />
                                            </Form.Item>
                                          </Col>
                                          <Col span={4}>
                                            <Form.Item
                                              style={{
                                                margin: 0,
                                              }}
                                              name={[brand.name, 'feePercent']}
                                              fieldKey={[
                                                brand.fieldKey,
                                                'feePercent',
                                              ]}
                                              rules={rulesFeePercent(
                                                planIndex,
                                                brandIndex
                                              )}
                                            >
                                              <InputMaskPercent
                                                disabled={
                                                  !isBrandSelected(
                                                    planIndex,
                                                    brandIndex
                                                  )
                                                }
                                              />
                                            </Form.Item>
                                          </Col>
                                          <Col span={4}>
                                            <Form.Item
                                              style={{ margin: 0 }}
                                              name={[brand.name, 'startDate']}
                                              fieldKey={[
                                                brand.fieldKey,
                                                'startDate',
                                              ]}
                                              rules={rules(
                                                planIndex,
                                                brandIndex
                                              )}
                                            >
                                              <DatePicker
                                                placeholder="Selecione a data"
                                                format={['DD/MM/YYYY']}
                                                disabled={
                                                  !isBrandSelected(
                                                    planIndex,
                                                    brandIndex
                                                  )
                                                }
                                                disabledDate={disabledStartDate}
                                                onChange={(value) =>
                                                  onChangeStartDate(
                                                    value,
                                                    planIndex,
                                                    brandIndex
                                                  )
                                                }
                                              />
                                            </Form.Item>
                                          </Col>
                                          <Col span={4}>
                                            <Form.Item
                                              style={{ margin: 0 }}
                                              name={[brand.name, 'endDate']}
                                              fieldKey={[
                                                brand.fieldKey,
                                                'endDate',
                                              ]}
                                              rules={rulesEndDate(
                                                planIndex,
                                                brandIndex
                                              )}
                                            >
                                              <DatePicker
                                                placeholder="Selecione a data"
                                                format={['DD/MM/YYYY']}
                                                disabled={
                                                  !isBrandSelected(
                                                    planIndex,
                                                    brandIndex
                                                  ) ||
                                                  !isEndDateEnabled(
                                                    planIndex,
                                                    brandIndex
                                                  )
                                                }
                                                disabledDate={(value) =>
                                                  disabledEndDate(
                                                    value,
                                                    planIndex,
                                                    brandIndex
                                                  )
                                                }
                                              />
                                            </Form.Item>
                                          </Col>
                                        </Row>
                                      </div>
                                    );
                                  })}
                                </StBrandContainer>
                              );
                            }}
                          </Form.List>
                        </StFieldContainer>
                      );
                    })}

                    {canInstallments && (
                      <Form.Item>
                        <Button
                          type="primary"
                          icon={<PlusOutlined />}
                          onClick={() => onAddInterval(add)}
                        >
                          Adicionar intervalo
                        </Button>
                      </Form.Item>
                    )}
                  </>
                );
              }}
            </Form.List>
          </StContainer>
          <StButtonsContainer>
            <StCancelButton onClick={onClickCancel}>Cancelar</StCancelButton>
            <Button
              type="primary"
              disabled={isSaveButtonDisabled}
              onClick={onClickSave}
            >
              Concluir
            </Button>
          </StButtonsContainer>
        </StWrapper>
      </Form>
      <CardModal
        loading={loading}
        cardType={modalType}
        isModalOpen={isModalOpen}
        setIsModalOpen={setModalOpen}
        onConfirm={modalType === 'finish' ? onConfirm : onFeesRedirect}
      />
    </>
  );
};

export default FeeForm;
