import { actionTypes } from './types';

const INITIAL_STATE = {
  error: false,
  loading: false,
  data: undefined,
  success: false,
  errorMessage: '',
  message: '',
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case actionTypes.RECOVERY_PASSWORD_START:
    return { ...state, loading: true, error: false, success: false };
  case actionTypes.RECOVERY_PASSWORD_SUCCESS:
    return {
      ...state,
      loading: false,
      error: false,
      success: true,
      message: action.payload.message,
      data: action.payload,
    };
  case actionTypes.RECOVERY_PASSWORD_ERROR:
    return {
      ...state,
      loading: false,
      error: true,
      success: false,
      errorMessage: action?.payload?.errorMessage,
    };
  default:
    return state;
  }
};

export default reducer;
