import React, { useEffect } from 'react';
import {
  Row,
  Col,
  Typography,
  Card,
  Tooltip,
} from 'antd';
import { StLink, StPartnerTag, StPartnerText } from './styled';
import { QuestionCircleOutlined } from '@ant-design/icons';
import Meta from 'antd/lib/card/Meta';
import { useDispatch, useSelector } from 'react-redux';
import { creditInteractRetryApprovalStart } from 'store/ducks/credit/creditInteractRetryApproval/actions';
import { creditTomaticoStatusStart } from 'store/ducks/credit/creditTomaticoStatus/actions';
import { colors } from 'styles/colors';

const PartnerCard = ({
  partnerService,
  partnerTag,
  isPartnerActive,
  isOfferActive,
  noStatus,
  approvedValue,
  creditId,
  offerStatus,
  interpretStatus,
  cnpj,
}) => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.permissions.data.user_id);
  const cadastro = useSelector((state) => state.creditTomaticoStatus.cadastro);
  const senha = useSelector((state) => state.creditTomaticoStatus.senha);
  const formattedCnpj = cnpj
    .replace('.', '')
    .replace('.', '')
    .replace('/', '')
    .replace('-', '');

  const { Link } = Typography;

  useEffect(() => {
    dispatch(
      creditTomaticoStatusStart({
        cnpj: formattedCnpj,
      })
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const chooseAction = () => {
    const statusAction = {
      action: () => {},
      text: '',
    };
    switch (offerStatus) {
    case 'NEW':
    case 'OPTIN':
      statusAction.action = () => {};
      statusAction.text = '';
      break;
    case 'PREREGISTER':
      statusAction.action = () => {};
      statusAction.text = '';
      break;
    case 'PREREGISTERED':
      statusAction.action = () => {};
      statusAction.text = '';
      break;
    case 'REGISTER':
    case 'REGISTERING':
      statusAction.action = () => {};
      statusAction.text = '';
      break;
    case 'REGISTERED':
      statusAction.action = () => {};
      statusAction.text = '';
      break;
    case 'PENDING_APPROVAL':
      statusAction.action = () => {};
      statusAction.text = '';
      break;
    case 'PENDINGFLUX':
      statusAction.action = () => {};
      statusAction.text = '';
      break;
    case 'APPROVED':
      statusAction.action = () => {
      };
      statusAction.text = (
        <Link
          href={
            cadastro === 1 && senha === 0
              ? 'https://app.tomatico.com/criarConta.html'
              : cadastro === 1 && senha === 1
                ? 'https://app.tomatico.com/minhaConta.html'
                : // TODO: validar o que ocorre em caso de cadastro e senha não retornam o "esperado acima"
                'https://www.concil.com.br'
          }
          target="_blank"
        >
          Ver oferta
        </Link>
      );
      break;
    case 'NOTAPPROVED':
      statusAction.action = () => {
        dispatch(
          creditInteractRetryApprovalStart({
            creditId: creditId,
            userId: userId,
          })
        );
      };
      statusAction.text = 'Tentar novamente';
      break;
    case 'CONTRACTED':
      statusAction.action = () => {};
      statusAction.text = '';
      break;
    default:
      statusAction.action = () => {};
      statusAction.text = '';
      break;
    }
    return statusAction;
  };

  return (
    <>
      <Row align="middle" gutter={[16, 16]}>
        <Col align="middle" span={24} style={{ display: 'flex' }}>
          <Card
            title={
              <Row align="middle" justify="space-between">
                <StPartnerText>{partnerService}</StPartnerText>
                <StPartnerTag
                  labelColor={isPartnerActive ? colors.geekBlue6 : colors.gray9}
                  bdColor={isPartnerActive ? colors.geekBlue3 : colors.gray5}
                  bgColor={isPartnerActive ? colors.geekBlue1 : colors.gray1}
                >
                  {partnerTag}
                </StPartnerTag>
              </Row>
            }
            bodyStyle={{ backgroundColor: colors.gray4, textAlign: 'left' }}
            style={{
              width: 400,
              border: `1px solid ${colors.gray5}`,
              marginRight: 16,
            }}
            actions={
              isPartnerActive && !noStatus ? (
                [
                  <Row
                    // span={24}
                    align="middle"
                    justify="space-between"
                    style={{ padding: '0 24px ', cursor: 'default' }}
                  >
                    <Col align="middle">
                      <Row>
                        <Typography.Text
                          style={{
                            fontSize: 12,
                            color: colors.gray7,
                            paddingRight: '5px',
                          }}
                        >
                          Crédito pré-aprovado
                        </Typography.Text>
                        <Tooltip
                          title="Crédito pré-aprovado com base em seu histórico de vendas"
                          placement="right"
                        >
                          <QuestionCircleOutlined
                            style={{ color: colors.gray7, cursor: 'pointer' }}
                          />
                        </Tooltip>
                      </Row>

                      <Row>
                        <Typography.Text
                          style={{
                            fontSize: 20,
                            color: colors.gray9,
                            fontWeight: 500,
                          }}
                        >
                          {offerStatus === 'APPROVED'
                            ? new Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                            }).format(approvedValue)
                            : '-'}
                        </Typography.Text>
                      </Row>
                    </Col>

                    <Col align="middle">
                      <Row>
                        {offerStatus === 'APPROVED' ? (
                          <StLink onClick={chooseAction()?.action}>
                            {chooseAction()?.text}
                          </StLink>
                        ) : (
                          interpretStatus(offerStatus)
                        )}
                      </Row>
                    </Col>
                  </Row>,
                ]
              ) : (
                <></>
              )
            }
          >
            {isPartnerActive && isOfferActive && !noStatus ? (
              <Meta
                description={
                  <Row align="middle" justify="space-between">
                    <Typography.Text style={{ fontSize: 14, color: colors.gray9 }}>
                      {/* {interpretStatus(offerStatus)} */}O empréstimo
                      inteligente é uma linha de crédito 100% digital e
                      descomplicada. O pagamento é uma porcentagem pré-definida
                      e o custo total é fixo, para que você saiba o quanto vai
                      pagar.
                    </Typography.Text>
                  </Row>
                }
              />
            ) : (
              <StPartnerText
                style={{
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: '14px',
                  lineHeight: '22px',
                  color: colors.gray7,
                }}
              >
                {interpretStatus(offerStatus)}
              </StPartnerText>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PartnerCard;
