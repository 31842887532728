import store from 'store';
import { lambdaApi } from '../../api';

const transformer = (apiResponse) => {
  return {
    details: apiResponse?.data?.transactions,
    totalizers: apiResponse?.data?.totalizers,
    meta: apiResponse?.meta,
  };
};

const getReconciledReceiptDetailsFromAPI = async ({
  sortFields,
  page,
  perPage,
  q,
}) => {
  const config = {
    params: {
      start_date: store
        .getState()
        .updateScreenToScreenDate?.startDate.format('YYYY-MM-DD'),
      end_date: store
        .getState()
        .updateScreenToScreenDate?.endDate.format('YYYY-MM-DD'),
      page,
      per_page: perPage,
      sort_fields: JSON.stringify(sortFields),
      q: JSON.stringify(q),
    },
  };
  const response = await lambdaApi().get('/detail/bank/reconciled', config);

  return transformer(response.data);
};

export default getReconciledReceiptDetailsFromAPI;
