import { action } from 'typesafe-actions';
import { actionTypes } from 'store/ducks/radar/radarRun/types';

export const radarRunStart = (data) =>
  action(actionTypes.RADAR_RUN_START, data);
export const radarRunSuccess = (data) =>
  action(actionTypes.RADAR_RUN_SUCCESS, data);
export const radarRunError = (errorMessage, error) =>
  action(actionTypes.RADAR_RUN_ERROR, { errorMessage }, undefined, {
    error,
  });
