import styled from 'styled-components';
import { Row, Input, Select, Divider, Typography } from 'antd';
import { colors } from 'styles/colors';

export const StRow = styled(Row)`
  &&& {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
`;

export const StSearch = styled(Input.Search)`
  width: 100%;
`;

export const StOption = styled(Select.Option)`
  background-color: red;
`;

export const ItemsList = styled.div`
  padding: 1rem 15px;
  font-size: 12px;
  cursor: pointer;

  :hover {
    color: ${colors.gray8} !important;
    background-color: ${colors.primary1};
  }
`;

export const StDividerItems = styled(Divider)`
  margin: 0;
`;

export const ItemsContainer = styled.div`
  max-height: 250px;
  overflow-y: auto;
  margin-bottom: 20px;
`;

export const StContentContainer = styled.div`
  &&& {
    max-height: 256px;
    overflow-y: auto;
    margin-bottom: 8px;
  }
`;

export const StColumnFilterDropdownSpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
`;

export const StContainer = styled.div`
  width: 100%;
  padding: 30px 36px 0;
`;

export const StTitle = styled(Typography.Title)`
  margin: 0;
  font-size: 30px;
  font-weight: 500;
  color: ${colors.gray11};
`;

export const StLabel = styled(Typography.Text)`
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  color: ${colors.gray11};
`;
