export const actionTypes = {
  GET_STORES_START: '@store/GET_STORES_START',
  GET_STORES_SUCCESS: '@store/GET_STORES_SUCCESS',
  GET_STORES_ERROR: '@store/GET_STORES_ERROR',
  POST_STORE_START: '@store/POST_STORE_START',
  POST_STORE_SUCCESS: '@store/POST_STORE_SUCCESS',
  POST_STORE_ERROR: '@store/POST_STORE_ERROR',
  PUT_STORE_START: '@store/PUT_STORE_START',
  PUT_STORE_SUCCESS: '@store/PUT_STORE_SUCCESS',
  PUT_STORE_ERROR: '@store/PUT_STORE_ERROR',
  DELETE_STORE_START: '@store/DELETE_STORE_START',
  DELETE_STORE_SUCCESS: '@store/DELETE_STORE_SUCCESS',
  DELETE_STORE_ERROR: '@store/DELETE_STORE_ERROR',
};
