import styled from 'styled-components';
import { colors } from 'styles/colors';

export const StContainer = styled.div`
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  background-color: ${colors.gold1};
  border-radius: 4px;
`;

export const StLimiter = styled.div`
  padding: 16px;
`;
