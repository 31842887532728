import { call, put } from 'redux-saga/effects';

import {
  listReconciliationRulesSuccess,
  listReconciliationRulesError,
} from 'store/ducks/reconciliationRules/listReconciliationRules/actions';
import getListReconciliationRulesFromAPI from 'services/reconciliationRules/listReconciliationRules';

export function* listReconciliationRulesSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(getListReconciliationRulesFromAPI, payload);

    yield put(listReconciliationRulesSuccess(apiData));
  } catch (err) {
    yield put(listReconciliationRulesError('Ocorreu um erro:', err));
  }
}
