import { actionTypes } from 'store/ducks/details/amountsReceivableDetails/types';

const INITIAL_STATE = {
  error: undefined,
  errorMessage: undefined,
  loading: false,
  isError: false,
  details: [],
  netAmount: 0,
  meta: {
    current_page: 1,
    num_results: 0,
    results_per_page: 10,
    total_pages: 100,
    total_items: 1000,
  },
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.AMOUNTS_RECEIVABLE_DETAILS_START:
      return {
        ...state,
        loading: true,
        error: undefined,
        errorMessage: undefined,
        isError: false,
      };
    case actionTypes.AMOUNTS_RECEIVABLE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        details: action.payload.details,
        netAmount: action.payload.netAmount,
        meta: action.payload.meta,
        isError: false,
        errorMessage: undefined,
      };
    case actionTypes.AMOUNTS_RECEIVABLE_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error.error,
        errorMessage: action.payload,
        isError: true,
        summary: undefined,
      };
    default:
      return state;
  }
};

export default reducer;
