import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { changeStoreStart } from 'store/ducks/changeStore/actions';
import { useDebounce } from 'utils/hooks/useDebounce';
import getListOfClientsFromAPI from 'services/listOfClients';
import errorHandler from 'helpers/errorHandler';

export const useClientList = (searchFilter, page, perPage = 50) => {
  const dispatch = useDispatch();
  const queryWithDebounce = useDebounce(searchFilter, 500);
  const [clients, setClients] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [hasMoreClients, setHasMoreClients] = useState(false);
  const [clientsData, setClientsData] = useState([]);

  const fetchClients = useCallback(() => {
    setLoading(true);
    return getListOfClientsFromAPI({
      filter: queryWithDebounce,
      page,
      perPage,
    })
      .then(({ clients: data = [], hasMore = false }) => {
        setClientsData(data);
        setHasMoreClients(hasMore);
      })
      .catch((error) => {
        const errorMessage =
          error?.response?.data?.error?.message ||
          'Ocorreu um erro ao buscar as empresas, por favor, atualize a página';
        return errorHandler(errorMessage, error);
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, queryWithDebounce]);

  const onChangeClient = (clientId) => {
    dispatch(
      changeStoreStart({
        client_id: clientId,
        updateLocalStorage: true,
      })
    );
  };

  useEffect(() => {
    setClients([]);
    fetchClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryWithDebounce]);

  useEffect(() => {
    if (page > 1) {
      fetchClients();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    setClients((prev) => {
      return [...new Set([...prev, ...clientsData])];
    });
  }, [clientsData]);

  return {
    isLoading,
    clients,
    hasMoreClients,
    onChangeClient,
  };
};
