/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useState } from 'react';
import { EllipsisText } from 'commons/EllipsisText';
import PropTypes from 'prop-types';
import { List, Row, Col, Spin } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch } from 'react-redux';
import customHistory from 'helpers/history';
import { TitleAndIcon } from 'commons/TitleAndIcon';
import { LabelAndValue } from 'commons/LabelAndValue';
import { ErrorCard } from 'commons/ErrorCard';
import { salesInfoStart } from 'store/ducks/salesInfo/actions';
import { StSeeDetailsButton } from 'components/Intermediate/styled';
import { StIntermediateCard } from 'commons/Intermediate/styled';
import { StTypographyTextLabel } from 'commons/LabelAndValue/styled';
import { StReconciliationBar, StTextLabel } from './styled';
import { updateReconciledSalesDetailsFilters } from 'store/ducks/filters/details/reconciledSalesDetailsFilters/actions';
import {
  heightBarCalculator,
  transformStringToFloat,
} from 'helpers/generalService';
import { colors } from 'styles/colors';
import dayjs from 'dayjs';
import { shortId } from 'helpers/shortId';

const ContentCards = ({
  data,
  loading,
  startDate,
  endDate,
  error,
  activeTab,
  infoFilter,
}) => {
  const dispatch = useDispatch();
  const [perPageQuantity, setPerPageQuantity] = useState(100);

  // eslint-disable-next-line no-unused-vars
  const [hasMoreData, setHasMoreData] = useState(false);

  const handleData = () => {
    setPerPageQuantity(perPageQuantity + 50);
    dispatch(
      salesInfoStart({
        startDate,
        endDate,
        page: 1,
        type: activeTab,
        perPage: perPageQuantity,
        filter: infoFilter,
      })
    );
  };

  const transformItemToFilter = (item) => {
    const filter = [];
    // There are two filtering options: acquirer and customer,
    // Since both of them have the same store name,
    // I choose to use the acquirer name as the filter name arbitrarily.
    Object.entries(item?.acquirer).forEach(([key, value]) => {
      if (activeTab === 'branches' && key === 'name') {
        filter.push({
          name: 'store_name',
          val: [value],
        });
      } else if (activeTab === 'acquirers' && key === 'name') {
        filter.push({
          name: 'acquirer_name',
          val: [value],
        });
      } else if (activeTab === 'products' && key === 'name') {
        filter.push({
          name: 'product_code',
          val: [value],
        });
      } else if (activeTab === 'brands' && key === 'name') {
        filter.push({
          name: 'brand_code',
          val: [value],
        });
      }
    });

    return filter;
  };

  const goToReports = (item) => {
    dispatch(updateReconciledSalesDetailsFilters(transformItemToFilter(item)));
    customHistory.push('/conciliacao-vendas-relatorios');
  };

  if (error) {
    return (
      <Row style={{ marginTop: '2rem' }}>
        <Col lg={10} md={18}>
          <StIntermediateCard loading={loading}>
            <ErrorCard
              title="Dados indisponíveis"
              reason="Não conseguimos buscar os dados, mas já estamos trabalhando para normalizar a situação."
              recommendation="Tente atualizar a página em alguns instantes."
            />
          </StIntermediateCard>
        </Col>
      </Row>
    );
  }

  const acquirerName = (item) => String(item?.acquirer?.name).toUpperCase();

  return (
    <InfiniteScroll
      key={shortId()}
      pageStart={1}
      loadMore={handleData}
      hasMore={hasMoreData}
      useWindow
      loader={
        <Row
          justify="center"
          align="middle"
          style={{ height: '100%' }}
          key={shortId()}
        >
          <Col key={shortId()}>
            <Spin key={shortId()} />
          </Col>
        </Row>
      }
    >
      <List
        key={shortId()}
        grid={{
          xxl: 4,
          xl: 3,
          lg: 2,
          md: 2,
          sm: 1,
          xs: 1,
          gutter: 16,
        }}
        dataSource={data}
        loading={loading}
        renderItem={(item) => (
          <List.Item key={shortId()}>
            <StIntermediateCard
              style={{ borderRadius: 4 }}
              key={shortId()}
              title={
                <TitleAndIcon
                  key={shortId()}
                  titleText={
                    <EllipsisText text={acquirerName(item)} length={30} />
                  }
                  titleFontSize="1.75rem"
                  justifyContent="start"
                />
              }
              actions={[
                <div key={shortId()} style={{ textAlign: 'end' }}>
                  <StSeeDetailsButton
                    type="link"
                    size="small"
                    fontSize="1.5rem"
                    onClick={() => {
                      goToReports(item);
                    }}
                  >
                    Ver relatório
                  </StSeeDetailsButton>
                </div>,
              ]}
              bordered={false}
              loading={loading}
            >
              <Row>
                {/* vendas realizadas estabelecimento*/}
                <Col span={12}>
                  <Row>
                    <LabelAndValue
                      label="Vendas nos estabelecimentos"
                      labelColor={colors.gray8}
                      value={item?.customer?.totalAmount}
                      valueColor={colors.gray8}
                      valueTextSize="large"
                      key={shortId()}
                    />
                  </Row>
                  <br />
                  <Row>
                    {transformStringToFloat(item?.customer?.percentPendent) >
                      0 ||
                    transformStringToFloat(item?.customer?.percentReconciled) >
                      0 ? (
                      <Col
                        span={2}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '2px',
                          alignItems: 'center',
                        }}
                      >
                        {transformStringToFloat(
                          item?.customer?.percentPendent
                        ) > 0 ? (
                          <StReconciliationBar
                            style={{
                              borderRadius:
                                transformStringToFloat(
                                  item?.customer?.percentPendent
                                ) > 0 &&
                                transformStringToFloat(
                                  item?.customer?.percentReconciled
                                ) > 0
                                  ? '4px 4px 0 0'
                                  : '4px',
                            }}
                            height={
                              transformStringToFloat(
                                item?.customer?.percentReconciled
                              ) !== 0
                                ? heightBarCalculator(
                                    transformStringToFloat(
                                      item?.customer?.percentPendent
                                    )
                                  )
                                : transformStringToFloat(
                                    item?.customer?.percentPendent
                                  )
                            }
                            color={colors.red5}
                          />
                        ) : (
                          <></>
                        )}

                        {transformStringToFloat(
                          item?.customer?.percentReconciled
                        ) > 0 ? (
                          <StReconciliationBar
                            style={{
                              borderRadius:
                                transformStringToFloat(
                                  item?.customer?.percentPendent
                                ) > 0 &&
                                transformStringToFloat(
                                  item?.customer?.percentReconciled
                                ) > 0
                                  ? '0 0 4px 4px'
                                  : '4px',
                            }}
                            height={
                              transformStringToFloat(
                                item?.customer?.percentPendent
                              ) !== 0
                                ? heightBarCalculator(
                                    transformStringToFloat(
                                      item?.customer?.percentReconciled
                                    )
                                  )
                                : transformStringToFloat(
                                    item?.customer?.percentReconciled
                                  )
                            }
                            color={colors.green6}
                          />
                        ) : (
                          <></>
                        )}
                      </Col>
                    ) : (
                      <Col
                        span={2}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '2px',
                          alignItems: 'center',
                        }}
                      >
                        <StReconciliationBar
                          style={{
                            borderRadius: '4px',
                          }}
                          height={100}
                          color="#DDD"
                        />
                      </Col>
                    )}
                    <Col span={20} style={{ margin: 'auto 0' }}>
                      <Row align="top" style={{ paddingBottom: 4 }}>
                        <StTypographyTextLabel $labelColor={colors.red5}>
                          <strong>{item?.customer?.percentPendent}%</strong>{' '}
                          <StTextLabel>Não conciliadas</StTextLabel>
                        </StTypographyTextLabel>
                      </Row>
                      <Row align="bottom" style={{ paddingBottom: 4 }}>
                        <StTypographyTextLabel $labelColor={colors.green6}>
                          <strong>{item?.customer?.percentReconciled}%</strong>{' '}
                          <StTextLabel>Conciliadas</StTextLabel>
                        </StTypographyTextLabel>
                      </Row>
                    </Col>
                  </Row>
                </Col>

                <Col span={12}>
                  <Row>
                    <LabelAndValue
                      label="Vendas nas adquirentes"
                      labelColor={colors.gray8}
                      value={item?.acquirer?.totalAmount}
                      valueColor={colors.gray8}
                      valueTextSize="large"
                      key={shortId()}
                    />
                  </Row>
                  <br />
                  <Row>
                    {transformStringToFloat(item?.acquirer?.percentPendent) >
                      0 ||
                    transformStringToFloat(item?.acquirer?.percentReconciled) >
                      0 ? (
                      <Col
                        span={2}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '2px',
                          alignItems: 'center',
                        }}
                      >
                        {transformStringToFloat(
                          item?.acquirer?.percentPendent
                        ) > 0 ? (
                          <StReconciliationBar
                            style={{
                              borderRadius:
                                transformStringToFloat(
                                  item?.acquirer?.percentPendent
                                ) > 0 &&
                                transformStringToFloat(
                                  item?.acquirer?.percentReconciled
                                ) > 0
                                  ? '4px 4px 0 0'
                                  : '4px',
                            }}
                            height={
                              transformStringToFloat(
                                item?.acquirer?.percentReconciled
                              ) !== 0
                                ? heightBarCalculator(
                                    transformStringToFloat(
                                      item?.acquirer?.percentPendent
                                    )
                                  )
                                : transformStringToFloat(
                                    item?.acquirer?.percentPendent
                                  )
                            }
                            color={colors.red5}
                          />
                        ) : (
                          <></>
                        )}

                        {transformStringToFloat(
                          item?.acquirer?.percentReconciled
                        ) > 0 ? (
                          <StReconciliationBar
                            style={{
                              borderRadius:
                                transformStringToFloat(
                                  item?.acquirer?.percentPendent
                                ) > 0 &&
                                transformStringToFloat(
                                  item?.acquirer?.percentReconciled
                                ) > 0
                                  ? '0 0 4px 4px'
                                  : '4px',
                            }}
                            height={
                              transformStringToFloat(
                                item?.acquirer?.percentPendent
                              ) !== 0
                                ? heightBarCalculator(
                                    transformStringToFloat(
                                      item?.acquirer?.percentReconciled
                                    )
                                  )
                                : transformStringToFloat(
                                    item?.acquirer?.percentReconciled
                                  )
                            }
                            color={colors.green6}
                          />
                        ) : (
                          <></>
                        )}
                      </Col>
                    ) : (
                      <Col
                        span={2}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '2px',
                          alignItems: 'center',
                        }}
                      >
                        <StReconciliationBar
                          style={{
                            borderRadius: '4px',
                          }}
                          height={100}
                          color="#DDD"
                        />
                      </Col>
                    )}
                    <Col
                      span={20}
                      style={{
                        margin: 'auto 0',
                      }}
                    >
                      <Row align="top" style={{ paddingBottom: 4 }}>
                        <StTypographyTextLabel
                          $labelColor={colors.red5}
                          fontSize="14px"
                        >
                          <strong>{item?.acquirer?.percentPendent}%</strong>{' '}
                          <StTextLabel>Não conciliadas</StTextLabel>
                        </StTypographyTextLabel>
                      </Row>
                      <Row align="bottom" style={{ paddingBottom: 4 }}>
                        <StTypographyTextLabel
                          $labelColor={colors.green6}
                          fontSize="14px"
                        >
                          <strong>{item?.acquirer?.percentReconciled}%</strong>{' '}
                          <StTextLabel>Conciliadas</StTextLabel>
                        </StTypographyTextLabel>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </StIntermediateCard>
          </List.Item>
        )}
      />
    </InfiniteScroll>
  );
};

ContentCards.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        branch_code: PropTypes.string,
        branch_id: PropTypes.number,
        branch_name: PropTypes.string,
        client_id: PropTypes.number,
        created_at: PropTypes.string,
        fee_amount: PropTypes.number,
        net_amount: PropTypes.number,
        quantity: PropTypes.number,
        sales_amount: PropTypes.number,
        sales_date: PropTypes.string,
        sales_average: PropTypes.number,
      }).isRequired,
      PropTypes.shape({
        acquirer_id: PropTypes.number,
        acquirer_name: PropTypes.string,
        client_id: PropTypes.number,
        created_at: PropTypes.string,
        fee_amount: PropTypes.number,
        net_amount: PropTypes.number,
        quantity: PropTypes.number,
        sales_amount: PropTypes.number,
        sales_date: PropTypes.string,
        sales_average: PropTypes.number,
      }).isRequired,
      PropTypes.shape({
        product_id: PropTypes.number,
        product_name: PropTypes.string,
        product_type: PropTypes.string,
        client_id: PropTypes.number,
        created_at: PropTypes.string,
        fee_amount: PropTypes.number,
        net_amount: PropTypes.number,
        quantity: PropTypes.number,
        sales_amount: PropTypes.number,
        sales_date: PropTypes.string,
        sales_average: PropTypes.number,
      }).isRequired,
    ]).isRequired
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  startDate: PropTypes.instanceOf(dayjs).isRequired,
  endDate: PropTypes.instanceOf(dayjs).isRequired,
  error: PropTypes.bool.isRequired,
  activeTab: PropTypes.string.isRequired,
};

export default ContentCards;
