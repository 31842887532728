import { notification } from 'antd';

const warningHandler = (warningMessage, duration = 5) => {
  notification.warning({
    duration: duration,
    placement: 'topRight',
    message: warningMessage,
  });
};

export default warningHandler;
