import React, { useEffect, useMemo, useState } from 'react';
import { Button, Modal } from 'antd';
import { capitalizeFirstLetter } from 'helpers/capitalizeFirstLetter';
import { useDebounce } from 'utils/hooks/useDebounce';
import { useMerchants } from 'components/Fees/hooks/useMerchants';
import { useInfiniteScrollPageChange } from 'utils/hooks/useInfiniteScrollPageChange';
import { shortId } from 'helpers/shortId';
import {
  StAcquirerContainer,
  StAcquirerNameLabel,
  StContainer,
  StModalContent,
  StSearch,
  StSelectedStoresLabel,
  StSpin,
  StSpinContainer,
  StStoreContainer,
  StStoreItem,
} from './styled';

const SelectedStores = ({
  acquirerId = '',
  acquirerName = '',
  acquirerIcon = '',
  isAllStores = false,
  stores = [],
}) => {
  const storesLength = stores.length;
  const {
    merchants,
    meta,
    page,
    loading,
    setPage,
    setMerchants,
    fetchMerchants,
  } = useMerchants();
  const { lastElementRef } = useInfiniteScrollPageChange({
    loading,
    hasMore: meta?.has_next,
    setPage,
  });
  const [modalVisible, setModalVisible] = useState(false);
  const [query, setQuery] = useState('');
  const queryWithDebounce = useDebounce(query, 500);

  const storesFiltered = useMemo(() => {
    if (isAllStores) return merchants;

    if (queryWithDebounce) {
      return stores.filter(({ value }) => {
        const queryLowerCase = queryWithDebounce.toLowerCase();
        const storeLowerCase = value.toLowerCase();
        const hasQueryInStore = storeLowerCase.includes(queryLowerCase);
        return hasQueryInStore;
      });
    }
    return stores;
  }, [stores, merchants, isAllStores, queryWithDebounce]);

  const storesLabel = () => {
    if (isAllStores) return 'Todas as lojas selecionadas para cadastrar taxas';
    if (storesLength === 1) return '1 loja selecionada para cadastrar taxas';
    return `${storesLength} lojas selecionadas para cadastrar taxas`;
  };

  const onOpenModal = () => setModalVisible(true);

  const onCloseModal = () => setModalVisible(false);

  const onSearchChange = (e) => {
    setQuery(e.target.value);
    if (isAllStores) setPage(1);
  };

  useEffect(() => {
    if (isAllStores) {
      setMerchants([]);
      fetchMerchants({ acquirerId, query: queryWithDebounce });
    }
    // eslint-disable-next-line
  }, [queryWithDebounce]);

  useEffect(() => {
    if (isAllStores && page > 1) {
      fetchMerchants({ acquirerId, query: queryWithDebounce });
    }
    // eslint-disable-next-line
  }, [page]);

  return (
    <>
      <StContainer>
        <StAcquirerContainer>
          {acquirerIcon && (
            <img
              src={acquirerIcon}
              width="22px"
              height="22px"
              alt={`Ícone da adquirente ${acquirerName}`}
            />
          )}
          <StAcquirerNameLabel>
            {capitalizeFirstLetter(acquirerName)}
          </StAcquirerNameLabel>
          <StSelectedStoresLabel>{storesLabel()}</StSelectedStoresLabel>
        </StAcquirerContainer>
        <Button onClick={onOpenModal}>
          Exibir todas as lojas selecionadas
        </Button>
      </StContainer>

      <Modal
        title={null}
        footer={null}
        open={modalVisible}
        onCancel={onCloseModal}
      >
        <StAcquirerContainer>
          {acquirerIcon && (
            <img
              src={acquirerIcon}
              width="22px"
              height="22px"
              alt={`Ícone da adquirente ${acquirerName}`}
            />
          )}
          <StAcquirerNameLabel>{acquirerName}</StAcquirerNameLabel>
        </StAcquirerContainer>
        <StModalContent>
          <span>Lojas selecionadas para as quais deseja cadastrar taxas:</span>
          <StSearch
            value={query}
            onChange={onSearchChange}
            placeholder="Buscar loja"
            disabled={loading}
            allowClear
          />

          <StStoreContainer>
            {storesFiltered.map(({ value }, index) => {
              return (
                <StStoreItem
                  key={shortId()}
                  ref={
                    storesFiltered.length === index + 1 ? lastElementRef : null
                  }
                >
                  {value}
                </StStoreItem>
              );
            })}
          </StStoreContainer>
          <StSpinContainer>{loading && <StSpin />}</StSpinContainer>
        </StModalContent>
      </Modal>
    </>
  );
};

export default SelectedStores;
