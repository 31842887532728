import { call, put } from 'redux-saga/effects';
import { notification } from 'antd';
import postSendInvitationRequestFromAPI from 'services/sendInvitation';

import errorHandler from 'helpers/errorHandler';

import { sendInvitationSuccess, sendInvitationFailure } from './actions';
import customHistory from 'helpers/history';
import { dataTestIdHandler } from 'helpers/dataTestIdHandler';

const dataTestPrefix = 'send-invitation';

export function* sendInvitationSaga(action) {
  const { formData } = action.payload;
  try {
    const apiData = yield call(postSendInvitationRequestFromAPI, formData);
    yield put(sendInvitationSuccess(apiData));
    const responseMessage = apiData?.message;
    if (responseMessage?.includes('associated')) {
      notification.success({
        message: 'Acesso à empresa concedido com sucesso!',
        props: dataTestIdHandler(dataTestPrefix, 'success-notification'),
      });
    } else {
      notification.success({
        message: 'Convite enviado com sucesso!',
        props: dataTestIdHandler(dataTestPrefix, 'success-notification'),
      });
    }
    const locationUrl = new URL(window.location.href);
    const pathname = locationUrl?.pathname;
    if (pathname === '/gerenciar-usuarios') {
      setTimeout(() => {
        customHistory.go(0);
      }, 2000);
    }
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        sendInvitationFailure(err.response.data.error, err.response.data.error)
      );
      errorHandler(err?.response?.data?.error?.message, err);
    }
  }
}
