import { actionTypes } from './types';

const INITIAL_STATE = {
  banksPayment: {},
  loading: false,
  error: undefined,
  errorMessage: undefined,
  isError: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.LIST_BANKS_DASH_START:
      return {
        ...state,
        loading: true,
        error: undefined,
        isError: false,
      };
    case actionTypes.LIST_BANKS_DASH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        banksPayment: action.payload,
        isError: false,
      };
    case actionTypes.LIST_BANKS_DASH_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error.error,
        errorMessage: action.payload,
        isError: true,
      };
    default:
      return state;
  }
};

export default reducer;
