import { Col, Row, Spin, Typography } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { colors } from 'styles/colors';
import { CardButton } from '../../../../commons/CardButton';

const TransactionsReportBar = ({
  loading,
  date,
  bank,
  branch,
  account,
  total,
  identified,
  ignored,
  notIdentified,
  filter,
  setFilter,
}) => {
  // Functions
  const onFilterChange = async (newFilter) => {
    setFilter(newFilter);
  };

  // Render Page
  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Spin />
      </div>
    );
  }

  return (
    <div style={{ marginTop: '14px' }}>
      <div>
        <span>
          <Typography.Text style={{ fontSize: 12, fontWeight: 'bold' }}>
            Período:
          </Typography.Text>
          <Typography.Text style={{ fontSize: 12, paddingLeft: '5px' }}>
            {`${date[0]} a ${date[1]}`}
          </Typography.Text>
        </span>
        <span style={{ paddingLeft: '10px' }}>
          <Typography.Text style={{ fontSize: 12, fontWeight: 'bold' }}>
            Banco:
          </Typography.Text>
          <Typography.Text style={{ fontSize: 12, paddingLeft: '5px' }}>
            {bank}
          </Typography.Text>
        </span>
        <span style={{ paddingLeft: '10px' }}>
          <Typography.Text style={{ fontSize: 12, fontWeight: 'bold' }}>
            Agência:
          </Typography.Text>
          <Typography.Text style={{ fontSize: 12, paddingLeft: '5px' }}>
            {branch}
          </Typography.Text>
        </span>
        <span style={{ paddingLeft: '10px' }}>
          <Typography.Text style={{ fontSize: 12, fontWeight: 'bold' }}>
            Conta:
          </Typography.Text>
          <Typography.Text style={{ fontSize: 12, paddingLeft: '5px' }}>
            {account}
          </Typography.Text>
        </span>
      </div>
      <div style={{ marginTop: '4rem' }}>
        <Row>
          <Col>
            <CardButton
              type="ALL"
              isSelected={filter === 'RELEASES'}
              onClickAction={() => {
                onFilterChange('RELEASES');
              }}
              value={total}
              description="Lançamentos"
              mainColor={colors.blue6}
            />
          </Col>
          <Col style={{ paddingLeft: '15px' }}>
            <CardButton
              type="IDENTIFIED"
              isSelected={filter === 'IDENTIFIED'}
              onClickAction={() => {
                onFilterChange('IDENTIFIED');
              }}
              value={identified}
              description="Identificados"
              mainColor="#4CAA55"
            />
          </Col>
          <Col style={{ paddingLeft: '15px' }}>
            <CardButton
              type="IGNORED"
              isSelected={filter === 'IGNORED'}
              onClickAction={() => {
                onFilterChange('IGNORED');
              }}
              value={ignored}
              description="Ignorados"
              mainColor={colors.red6}
            />
          </Col>
          <Col style={{ paddingLeft: '15px' }}>
            <CardButton
              type="NOTIDENTIFIED"
              isSelected={filter === 'NOTIDENTIFIED'}
              onClickAction={() => {
                onFilterChange('NOTIDENTIFIED');
              }}
              value={notIdentified}
              description="Não identificados"
              mainColor={colors.gold6}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

TransactionsReportBar.defaultProps = {
  filter: '',
  date: '',
};
TransactionsReportBar.propTypes = {
  loading: PropTypes.bool.isRequired,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  bank: PropTypes.string.isRequired,
  branch: PropTypes.string.isRequired,
  account: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  identified: PropTypes.number.isRequired,
  ignored: PropTypes.number.isRequired,
  notIdentified: PropTypes.number.isRequired,
  filter: PropTypes.string,
  setFilter: PropTypes.func.isRequired,
};

export default TransactionsReportBar;
