/* eslint-disable react/no-children-prop */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Skeleton, Divider, Tooltip, Dropdown } from 'antd';
import PropTypes from 'prop-types';
import customHistory from 'helpers/history';
import { LabelAndValue } from 'commons/LabelAndValue';
import { TitleAndIcon } from 'commons/TitleAndIcon';
import { CalendarOutlined } from '@ant-design/icons';
import {
  StTypographyTextLabel,
  StTypographyTextValue,
} from 'commons/LabelAndValue/styled';
import { paymentsDivergenceDash } from 'store/ducks/paymentsDash/paymentsDivergences/actions';
import CardContainer from 'commons/CardContainer';
import {
  StDashboardTag,
  StDropdownContent,
  StHoverClickTitle,
  StHoverClickValue,
} from 'components/Dashboard/styled';
import { updateScreenToScreenDateStart } from 'store/ducks/updateScreenToScreenDate/actions';
import { updateScreenToScreenPaymentDivergencesPeriodStart } from 'store/ducks/updateScreenToScreenPeriod/actions';
import { colors } from 'styles/colors';
import {
  currentDayMinusOne,
  endOfPreviousMonth,
  startOfCurrentMonth,
  startOfPreviousMonth,
} from 'helpers/dates';
import dayjs from 'dayjs';
import { TooltipWithCustomRef } from 'commons/TooltipWithCustomRef';
import { dataTestIdHandler } from 'helpers/dataTestIdHandler';

const assetsBaseURL = process.env.REACT_APP_AWS_V4_ASSETS;
const paymentsAuditIcon = `${assetsBaseURL}/svg/paymentsAuditIcon.svg`;

const PaymentsDivergencesCard = ({
  startDatePaymentsDivergences,
  endDatePaymentsDivergences,
  setStartDatePaymentsDivergences,
  setEndDatePaymentsDivergences,
}) => {
  const dispatch = useDispatch();
  const dropdownKeyValue = useSelector(
    (store) => store.updateScreenToScreenPeriod?.paymentDivergencesPeriod
  );
  const payments = useSelector(
    (store) => store.paymentsDivergencesDash.payments
  );
  const paymentsLoading = useSelector(
    (store) => store.paymentsDivergencesDash.loading
  );

  const dataTestPrefix = 'payments-divergences-card';

  useEffect(() => {
    switch (dropdownKeyValue) {
      case 'neste_mes':
        dispatch(
          paymentsDivergenceDash({
            startDate: startOfCurrentMonth,
            endDate: currentDayMinusOne,
          })
        );
        break;
      case 'ultimo_dia':
        dispatch(
          paymentsDivergenceDash({
            startDate: currentDayMinusOne,
            endDate: currentDayMinusOne,
          })
        );
        break;
      case 'ultimo_mes':
        dispatch(
          paymentsDivergenceDash({
            startDate: startOfPreviousMonth,
            endDate: endOfPreviousMonth,
          })
        );
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, endDatePaymentsDivergences, startDatePaymentsDivergences]);

  const handleDropdownMenu = (key) => {
    switch (key) {
      case 'neste_mes':
        setStartDatePaymentsDivergences(startOfCurrentMonth);
        setEndDatePaymentsDivergences(currentDayMinusOne);

        dispatch(
          updateScreenToScreenPaymentDivergencesPeriodStart('neste_mes')
        );
        break;
      case 'ultimo_dia':
        setStartDatePaymentsDivergences(currentDayMinusOne);
        setEndDatePaymentsDivergences(currentDayMinusOne);

        dispatch(
          updateScreenToScreenPaymentDivergencesPeriodStart('ultimo_dia')
        );
        break;
      case 'ultimo_mes':
        setStartDatePaymentsDivergences(startOfPreviousMonth);
        setEndDatePaymentsDivergences(endOfPreviousMonth);

        dispatch(
          updateScreenToScreenPaymentDivergencesPeriodStart('ultimo_mes')
        );
        break;
      default:
        break;
    }
  };

  const goToPaymentDivergences = () => {
    customHistory.push('/auditoria-pagamentos-intermediario');
    dispatch(
      updateScreenToScreenDateStart({
        startDate: startDatePaymentsDivergences,
        endDate: endDatePaymentsDivergences,
      })
    );
  };

  useEffect(() => {
    handleDropdownMenu(dropdownKeyValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const items = [
    {
      label: 'Neste mês',
      key: 'neste_mes',
      onClick: () => handleDropdownMenu('neste_mes'),
    },
    {
      label: 'Último dia',
      key: 'ultimo_dia',
      onClick: () => handleDropdownMenu('ultimo_dia'),
    },
    {
      label: 'Último mês',
      key: 'ultimo_mes',
      onClick: () => handleDropdownMenu('ultimo_mes'),
    },
  ];

  return (
    <CardContainer {...dataTestIdHandler(dataTestPrefix, 'container')}>
      <Skeleton loading={paymentsLoading} active>
        <Row align="middle" justify="space-between">
          <StHoverClickTitle onClick={goToPaymentDivergences}>
            <Col>
              <TitleAndIcon
                iconUri={paymentsAuditIcon}
                iconAlt="Icone de auditoria de pagamentos"
                titleFontSize="20px"
                titleText="Auditoria de pagamentos"
                dataTestPrefix={dataTestPrefix}
              />
            </Col>
          </StHoverClickTitle>
          <Col>
            <Dropdown
              menu={{ items }}
              trigger={['click']}
              dropdownRender={(menu) => (
                <StDropdownContent>{menu}</StDropdownContent>
              )}
            >
              <StDashboardTag icon={<CalendarOutlined />}>
                {dropdownKeyValue === 'neste_mes' && 'Neste mês'}
                {dropdownKeyValue === 'ultimo_dia' && 'Último dia'}
                {dropdownKeyValue === 'ultimo_mes' && 'Último mês'}
              </StDashboardTag>
            </Dropdown>
          </Col>
        </Row>
        <Row
          align="middle"
          justify="space-between"
          style={{ marginTop: '2rem' }}
          gutter={[32, 8]}
        >
          <Col>
            <TooltipWithCustomRef
              title="São os pagamentos que foram conciliados, mas que a taxa aplicada pela adquirente foi diferente da taxa informada por você. O valor exibido é a diferença entre eles."
              placement="topRight"
              overlayInnerStyle={{ fontSize: '11px' }}
            >
              <StTypographyTextLabel
                fontSize="1.5rem"
                $labelColor="rgba(0,0,0,0.45)"
              >
                Diferença de pagamentos
              </StTypographyTextLabel>
            </TooltipWithCustomRef>
            <br />
            <StHoverClickValue
              onClick={goToPaymentDivergences}
              disableHoverColor
            >
              <StTypographyTextValue
                fontSize="2rem"
                strong
                $valueColor={
                  payments?.net_amount_difference_reconciled !== 0
                    ? colors.red5
                    : colors.gray11
                }
              >
                {new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }).format(
                  !payments?.net_amount_difference_reconciled ||
                    payments?.net_amount_difference_reconciled === 0
                    ? '0'
                    : payments?.net_amount_difference_reconciled
                )}
              </StTypographyTextValue>
            </StHoverClickValue>
          </Col>

          <Divider
            type="vertical"
            style={{
              width: '0.125rem',
              height: '5rem',
              color: '#E3E3E3',
              marginTop: '1.5rem',
            }}
          />
          <Col>
            <Tooltip
              title="São as transações pagas após a data prevista."
              placement="topRight"
              overlayInnerStyle={{ fontSize: '11px' }}
            >
              {/* Div is here because otherwise Tooltip won't appear */}

              <div>
                <StHoverClickValue
                  onClick={goToPaymentDivergences}
                  disableHoverColor
                >
                  <LabelAndValue
                    label="Diferença de data"
                    textAlign="center"
                    value={payments?.late_payments_count}
                    valueTextSize="medium"
                    labelColor="rgba(0, 0, 0, 0.45)"
                    valueColor={
                      payments?.late_payments_count !== 0
                        ? colors.red5
                        : colors.gray11
                    }
                  />
                </StHoverClickValue>
              </div>
            </Tooltip>
          </Col>

          <Divider
            type="vertical"
            style={{
              width: '0.125rem',
              height: '5rem',
              color: '#E3E3E3',
              marginTop: '1.5rem',
            }}
          />

          <Col>
            <div style={{ textAlign: 'right' }}>
              <TooltipWithCustomRef
                title="São os valores que ainda estão em aberto, aguardando a confirmação de pagamento da adquirente ou aguardando que a informação já recebida seja processada na plataforma."
                placement="topLeft"
                overlayInnerStyle={{
                  marginTop: '30px',
                  fontSize: '11px',
                  wordSpacing: 'normal',
                }}
                titleFontSize={{ fontSize: '50px' }}
              >
                <StTypographyTextLabel
                  fontSize="1.5rem"
                  $labelColor="rgba(0,0,0,0.45)"
                >
                  Previsões em aberto
                </StTypographyTextLabel>
              </TooltipWithCustomRef>
              <br />
              <StHoverClickValue
                onClick={goToPaymentDivergences}
                disableHoverColor
              >
                <StTypographyTextValue
                  fontSize="2rem"
                  $valueColor={
                    payments?.pending_net_amout !== 0
                      ? colors.red5
                      : colors.gray11
                  }
                  strong
                >
                  {new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(payments?.pending_net_amout || '0')}
                </StTypographyTextValue>
              </StHoverClickValue>
            </div>
          </Col>
        </Row>
      </Skeleton>
    </CardContainer>
  );
};

PaymentsDivergencesCard.propTypes = {
  startDatePaymentsDivergences: PropTypes.instanceOf(dayjs).isRequired,
  endDatePaymentsDivergences: PropTypes.instanceOf(dayjs).isRequired,
  setStartDatePaymentsDivergences: PropTypes.func.isRequired,
  setEndDatePaymentsDivergences: PropTypes.func.isRequired,
};

export default PaymentsDivergencesCard;
