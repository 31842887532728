/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-globals */
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Input,
  Modal,
  notification,
  Row,
  Skeleton,
  Table,
  Typography,
} from 'antd';
import { ErrorCard } from 'commons/ErrorCard';
import {
  isAcquirerIdentified,
  isAcquirerIgnored,
  isAcquirerNotIdentified,
} from 'components/BankConciliationTransactions/helpers/acquirerStatus';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StWrapper } from '../../../../commons/Content/styled';
import customHistory from '../../../../helpers/history';
import { acquirerStart } from '../../../../store/ducks/acquirer/actions';
import {
  listBankConciliationTransactionsStart,
  updateTransactionsStart,
} from '../../../../store/ducks/bankConciliation/actions';
import { TransactionsReportBar } from '../TransactionsReportBar';
import { StFooter } from './styled';
import ColumnList, {
  isIgnoredFromAPI,
  updateLocalStorage,
} from './table/ColumnList';
import { useLocation } from 'react-router-dom';

const TransactionsReport = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const [pageToBeSentBack, setPageToBeSentBack] = useState(0);
  const pageParam = parseInt(searchParams.get('page', 10), 10);

  const dispatch = useDispatch();

  const { Search } = Input;

  const acquirers = useSelector((state) => state.acquirer.acquirers);
  const loadingAcquirers = useSelector((state) => state.acquirer.loading);
  const loadingList = useSelector((state) => state.bankConciliation.loading);
  const loadingUpdate = useSelector(
    (state) => state.bankConciliation.loadingUpdate
  );
  const errorList = useSelector((state) => state.bankConciliation.isError);
  const errorMessage = useSelector(
    (state) => state.bankConciliation.errorMessage
  );
  const bank = useSelector((state) => state.bankConciliation.bank);
  const bankBranch = useSelector((state) => state.bankConciliation.bankBranch);
  const bankAccount = useSelector(
    (state) => state.bankConciliation.bankAccount
  );
  const accountId = useSelector((state) => state.bankConciliation.accountId);
  const loadingThroughImportFiles = useSelector(
    (state) => state.bankConciliation.loadingThroughImportFiles
  );
  const throughImportFiles = useSelector(
    (state) => state.bankConciliation.throughImportFiles
  );
  const startDate = useSelector((state) => {
    if (!loadingThroughImportFiles && throughImportFiles) {
      return state.importFiles.fileStartDate;
    }
    return state.bankConciliation.transactionsStartDate;
  });
  const endDate = useSelector((state) => {
    if (!loadingThroughImportFiles && throughImportFiles) {
      return state.importFiles.fileEndDate;
    }
    return state.bankConciliation.transactionsEndDate;
  });

  const backendTransactions = useSelector(
    (state) => state.bankConciliation.listTransactionsData
  );
  const countersTotal = useSelector(
    (state) => state.bankConciliation.countersTotal
  );
  const countersIdentified = useSelector(
    (state) => state.bankConciliation.countersIdentified
  );
  const countersIgnored = useSelector(
    (state) => state.bankConciliation.countersIgnored
  );
  const countersNotIdentified = useSelector(
    (state) => state.bankConciliation.countersNotIdentified
  );
  const countTransactions = useSelector(
    (state) => state.bankConciliation.countTransactions
  );
  const hasNoTransactions = useSelector(
    (state) => state.bankConciliation.hasNoTransactions
  );
  const loadingHasNoTransactions = useSelector(
    (state) => state.bankConciliation.loadingHasNoTransactions
  );
  const totalAcquirer = useSelector(
    (state) => state.bankConciliation.totalAcquirer
  );
  const difference = useSelector((state) => state.bankConciliation.difference);
  const currentRowAcquirerName = useSelector(
    (state) => state.bankConciliation.acquirerName
  );
  const fileName = useSelector((state) => state.bankConciliation.fileName);
  const transactionsHasBeenUpdated = useSelector(
    (state) => state.bankConciliation.transactionsHasBeenUpdated
  );

  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const [restoredTransactions, setRestoredTransactions] = useState([]);
  const [shownTransactions, setShownTransactions] = useState([]);
  const [updatedCounters, setUpdatedCounters] = useState({
    countersIgnored: 0,
    countersIdentified: 0,
  });
  const [currentTotal, setCurrentTotal] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [pageCurrent, setPageCurrent] = useState(1);
  const [filter, setFilter] = useState('');
  const [columnList, setColumnList] = useState([]);
  const [updatedAcquirers, setUpdatedAcquirers] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const acquirerNameState = useSelector(
    (state) => state.bankConciliation.acquirerName
  );
  const afterSave = useSelector((state) => state.bankConciliation.afterSave);
  const [updateLocal, setUpdateLocal] = useState(0);
  const characterCountGeneralizationRule = 20;

  const localTransactions = JSON.parse(
    localStorage.getItem('classified_transactions')
  );

  const differenceLocalStorage = useMemo(() => {
    const matchingIds = localTransactions?.client?.files?.filter(
      (file) => file?.id === backendTransactions[0]?.id_file_tracking
    );
    if (matchingIds && matchingIds.length !== 0) {
      const transactionIds = backendTransactions.map((tr) => tr?.unique_id);
      const transactionsMapping = backendTransactions.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.unique_id]: curr,
        }),
        {}
      );
      const localDayTransactions = matchingIds[0]?.transactions.filter((tr) =>
        transactionIds.includes(tr?.id)
      );

      const localAcknowledgedValue = localDayTransactions.reduce((acc, tr) => {
        // Caso 1 e 2
        if (
          tr?.acquirer_name !== acquirerNameState &&
          transactionsMapping[tr?.id]?.acquirer_name !== acquirerNameState
        ) {
          return acc;
        }
        // Caso 3 - subtração
        if (
          tr?.acquirer_name !== acquirerNameState &&
          transactionsMapping[tr?.id].acquirer_name === acquirerNameState
        ) {
          return acc - tr?.amount;
        }
        // Caso 4 e 5 - adição
        if (
          tr?.acquirer_name === acquirerNameState &&
          transactionsMapping[tr?.id].acquirer_name !== acquirerNameState
        ) {
          return acc + tr?.amount;
        }
        return acc;
      }, 0);

      return difference - localAcknowledgedValue;
    }

    return difference;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backendTransactions, updateLocal]);

  const ignoredFromAPI = useMemo(
    () =>
      backendTransactions?.filter((tr) => isAcquirerIgnored(tr.acquirer_name)),
    [backendTransactions]
  );

  const backendTransactionsIds = useMemo(
    () => backendTransactions.map((tr) => tr.unique_id),
    [backendTransactions]
  );

  useEffect(() => {
    if (backendTransactions) {
      const fileId = backendTransactions[0]?.id_file_tracking;

      const localFile = localTransactions?.client?.files?.find(
        (file) => file.id === fileId
      );

      const localCounters = {
        countersIgnored:
          localFile?.transactions?.reduce((prev, cur) => {
            if (!backendTransactionsIds.includes(cur.id)) return prev;
            if (cur.is_ignored) {
              if (isIgnoredFromAPI(ignoredFromAPI, cur.id)) {
                return prev;
              }
              return prev + 1;
            }
            if (isIgnoredFromAPI(ignoredFromAPI, cur.id)) {
              return prev - 1;
            }
            return prev;
          }, 0) || 0,
        countersIdentified:
          localFile?.transactions?.reduce((prev, cur) => {
            const foundTransaction = backendTransactions?.find(
              (tr) => tr.unique_id === cur.id
            );

            if (!foundTransaction) return prev;

            const isTransactionIdentifiedOnAPI = isAcquirerIdentified(
              foundTransaction?.acquirer_name
            );

            // SE a transação está IDENTIFICADA localmente
            if (cur.is_identified) {
              // SE a transação está IDENTIFICADA na API, não muda o contador (retorna o previous value)
              if (isTransactionIdentifiedOnAPI) {
                return prev;
              }
              // SE a transação não está identificada na API, retorna o previous value + 1
              return prev + 1;
            }

            // SE a transação tá IDENTIFICADA na API
            if (isTransactionIdentifiedOnAPI) {
              return prev - 1;
            }

            // SE a transação não tá identificada na API e LOCAL, retorna prev
            return prev;
          }, 0) || 0,
      };
      setUpdatedCounters(localCounters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateLocal, backendTransactions]);

  useEffect(() => {
    if (!loadingHasNoTransactions && hasNoTransactions && !filter) {
      notification.warning({
        message: 'O arquivo selecionado não possui transações.',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasNoTransactions]);

  useEffect(() => {
    setCurrentTotal(countTransactions);
  }, [countTransactions]);

  const updateTransactionAcquirer = (uniqueId, acquirerName) => {
    // Edit locally

    // This function also needs to be refactored due to direct object updates
    // without using "useState".
    if (restoredTransactions.length > 0) {
      setRestoredTransactions((prevState) =>
        prevState.map((tr) => {
          if (tr.unique_id !== uniqueId) return tr;
          return {
            ...tr,
            acquirer_name: acquirerName,
          };
        })
      );
    }
  };
  // Setup Data
  useEffect(() => {
    if (
      backendTransactions.length > 0 ||
      (backendTransactions.length === 0 && filter === 'IGNORED')
    ) {
      setShownTransactions(backendTransactions);
    }
    if (backendTransactions.length === 0) {
      setShownTransactions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backendTransactions]);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const modifiedTransactions = structuredClone(backendTransactions);

    const localStorageTransactions = JSON.parse(
      localStorage.getItem('classified_transactions')
    );

    if (localStorageTransactions) {
      localStorageTransactions?.client?.files.forEach((file) => {
        if (file?.transactions !== undefined) {
          file?.transactions.forEach((localStorageTransaction) => {
            modifiedTransactions.forEach((transaction) => {
              if (transaction?.unique_id === localStorageTransaction?.id) {
                transaction.acquirer_name =
                  localStorageTransaction.acquirer_name;
              }
            });
          });
        }
      });
    }
    setRestoredTransactions(modifiedTransactions);
    setShownTransactions(modifiedTransactions);
  }, [backendTransactions]);

  const onChangeFilter = (filterNewValue) => {
    setFilter(filterNewValue);
    setPageCurrent(1);
  };

  const onSearch = (filterNewText) => {
    setSearchValue(filterNewText.replace(/\./g, '').replace(',', '.'));
    const payload = {
      bank: bank || sessionStorage.getItem('tr_report_bank'),
      bank_branch: bankBranch || sessionStorage.getItem('tr_report_bankBranch'),
      bank_account:
        bankAccount || sessionStorage.getItem('tr_report_bankAccount'),
      start_date: startDate || sessionStorage.getItem('tr_report_startDate'),
      end_date: endDate || sessionStorage.getItem('tr_report_endDate'),
      account_id:
        accountId || Number(sessionStorage.getItem('tr_report_accountId')),
      acquirer_name:
        !loadingThroughImportFiles && !throughImportFiles
          ? currentRowAcquirerName
          : undefined,
      search_by: filterNewText.replace(/\./g, '').replace(',', '.'),
      full_file_name: throughImportFiles ? fileName : undefined,
      totalizersNotRequired: throughImportFiles,
    };

    dispatch(listBankConciliationTransactionsStart(payload));

    setPageCurrent(1);
  };

  /*
  Função que roda quando ocorre alguma alteração em uma transação (ignorar ou trocar adquirente)
  */
  const onChangeTransaction = (uid, acquirerName, idFileTracking) => {
    /*
     Função que atualiza a adquirente de uma transação
     */
    updateTransactionAcquirer(uid, acquirerName, idFileTracking);

    if (isAcquirerIgnored(acquirerName)) {
      // Verify if there are items with similar names
      const transactionsToBeIgnored = [];
      let similarCount = 0;
      let foundTransaction = null;
      const transaction = restoredTransactions.find(
        (tr) => tr.unique_id === uid
      );

      if (transaction?.description.length > 7) {
        foundTransaction = transaction.description.substring(
          0,
          characterCountGeneralizationRule
        );

        restoredTransactions.forEach((tr) => {
          if (tr.description.startsWith(foundTransaction)) {
            transactionsToBeIgnored.push(tr);
            similarCount += 1;
          }
        });
      }

      if (similarCount > 1) {
        Modal.confirm({
          title:
            'Foram identificados itens com nomes similares, você deseja ignora-los também?',
          icon: <ExclamationCircleOutlined />,
          content:
            'Ao confirmar você estará marcando: ' +
            similarCount +
            ' transações com nomes iniciando em: ' +
            foundTransaction +
            ' como ignoradas.',
          okText: 'Sim',
          cancelText: 'Não',
          width: '50.875rem',
          onOk: () => {
            transactionsToBeIgnored.forEach((tr) => {
              updateLocalStorage(tr, 'IGNORAR', true);
              updateTransactionAcquirer(
                tr.unique_id,
                acquirerName,
                tr.id_file_tracking,
                tr.is_ignored
              );
            });
            setUpdateLocal((prevState) => prevState + 1);
          },
          onCancel: () => {
            setUpdateLocal((prevState) => prevState + 1);
          },
        });
      } else {
        setUpdateLocal((prevState) => prevState + 1);
      }
    }
  };

  const onTablePaginationOrFiltersOrSorterChange = async (
    paginationHandler
  ) => {
    setPerPage(paginationHandler.pageSize);
    setPageCurrent(paginationHandler.current);
    setCurrentTotal(countTransactions);
  };
  const onSave = () => {
    const toBeSentTransactions = [];
    const localStorageTransactions = JSON.parse(
      localStorage.getItem('classified_transactions')
    );

    if (localStorageTransactions) {
      localStorageTransactions?.client?.files.forEach((file) => {
        if (file?.transactions !== undefined) {
          file?.transactions.forEach((localStorageTransaction) => {
            restoredTransactions.forEach((transaction) => {
              if (transaction?.unique_id === localStorageTransaction?.id) {
                toBeSentTransactions.push({
                  acquirer_name: transaction.acquirer_name,
                  file_trace_identifier: transaction.id_file_tracking,
                  unique_id: transaction.unique_id,
                });
              }
            });
          });
        }
      });
    }

    dispatch(
      updateTransactionsStart({
        transactions: toBeSentTransactions,
        perPage: perPage,
        pageCurrent: pageCurrent,
        startDate: startDate,
        endDate: endDate,
      })
    );
  };

  // Pagination trigger
  useEffect(() => {
    const payload = {
      bank: bank || sessionStorage.getItem('tr_report_bank'),
      bank_branch: bankBranch || sessionStorage.getItem('tr_report_bankBranch'),
      bank_account:
        bankAccount || sessionStorage.getItem('tr_report_bankAccount'),
      start_date: startDate || sessionStorage.getItem('tr_report_startDate'),
      end_date: endDate || sessionStorage.getItem('tr_report_endDate'),
      account_id:
        accountId || Number(sessionStorage.getItem('tr_report_accountId')),
      acquirer_name: currentRowAcquirerName,
      search_by: searchValue || undefined,
    };

    if (!loadingThroughImportFiles && !throughImportFiles && !afterSave) {
      dispatch(listBankConciliationTransactionsStart(payload));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionsHasBeenUpdated === true]);

  useEffect(() => {
    const operator = {
      RELEASES: () => {
        // eslint-disable-next-line no-undef
        return structuredClone(restoredTransactions);
      },
      IDENTIFIED: () => {
        // eslint-disable-next-line no-undef
        return structuredClone(
          restoredTransactions.filter((tr) =>
            isAcquirerIdentified(tr?.acquirer_name)
          )
        );
      },
      IGNORED: () => {
        // eslint-disable-next-line no-undef
        return structuredClone(
          restoredTransactions.filter((tr) =>
            isAcquirerIgnored(tr?.acquirer_name)
          )
        );
      },
      NOTIDENTIFIED: () => {
        // eslint-disable-next-line no-undef
        return structuredClone(
          restoredTransactions.filter((tr) =>
            isAcquirerNotIdentified(tr?.acquirer_name)
          )
        );
      },
    };

    setShownTransactions(operator[filter || 'RELEASES']());
  }, [filter, restoredTransactions]);

  // Startup
  useEffect(() => {
    if (acquirers.length === 0) {
      dispatch(acquirerStart({}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (!acquirers.some((acq) => acq.name === 'PIX' || acq.name === 'Pix')) {
      acquirers.push({ name: 'PIX' });
    }

    setUpdatedAcquirers(acquirers);
  }, [acquirers]);

  useEffect(() => {
    forceUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedAcquirers]);

  // Refreshing
  useEffect(() => {
    setColumnList(
      ColumnList(updatedAcquirers, onChangeTransaction, {
        setUpdateLocal,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    restoredTransactions,
    filter,
    pageCurrent,
    currentTotal,
    countersTotal,
    countersIdentified,
    countersNotIdentified,
    countersIgnored,
    perPage,
    updatedAcquirers,
  ]);

  useEffect(() => {
    if (!isNaN(pageParam)) {
      setPageToBeSentBack(pageParam);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Render Page
  if (errorList) {
    return (
      <Row style={{ marginTop: '2rem' }}>
        <Col lg={10} md={18}>
          <Card style={{ borderRadius: 4 }} loading={loadingList}>
            <ErrorCard
              title="Erro ao exibir dados!"
              reason={errorMessage}
              recommendation=""
            />
          </Card>
        </Col>
      </Row>
    );
  }

  return (
    <div>
      <StWrapper>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography.Text strong style={{ fontSize: 18 }}>
            Identificação de lançamentos
          </Typography.Text>
        </div>
        <div>
          <Typography.Text style={{ fontSize: 12, color: '#8E8E8E' }}>
            Identifique as adquirentes que realizaram os lançamentos abaixo ou
            informe se estes devem ser ignorados
          </Typography.Text>
        </div>

        <TransactionsReportBar
          loading={loadingList}
          bank={bank || sessionStorage.getItem('tr_report_bank')}
          branch={bankBranch || sessionStorage.getItem('tr_report_bankBranch')}
          account={
            bankAccount || sessionStorage.getItem('tr_report_bankAccount')
          }
          date={
            [startDate, endDate] ||
            sessionStorage.getItem('tr_report_startDate')
          }
          ignored={countersIgnored + updatedCounters.countersIgnored}
          notIdentified={
            countersNotIdentified -
            (updatedCounters.countersIdentified +
              updatedCounters.countersIgnored)
          }
          identified={countersIdentified + updatedCounters.countersIdentified}
          total={countersTotal}
          filter={filter}
          setFilter={onChangeFilter}
        />

        <div style={{ marginTop: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography.Text strong style={{ marginTop: '2rem', fontSize: 18 }}>
              Lançamento bancários
            </Typography.Text>
          </div>
          <Row>
            <Col
              lg={24}
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <Search
                style={{ width: '20%' }}
                placeholder="Buscar adquirente, descrição ou valor"
                onSearch={onSearch}
                allowClear
              />
            </Col>
          </Row>
          <div style={{ marginTop: '1.75rem' }}>
            <Table
              loading={loadingList || loadingAcquirers}
              onChange={onTablePaginationOrFiltersOrSorterChange}
              dataSource={!hasNoTransactions ? shownTransactions : []}
              customColumns={columnList}
              columns={columnList}
              defaultSelectedColumns={[]}
            />
          </div>
        </div>
      </StWrapper>

      <StFooter>
        <Row>
          {!throughImportFiles && (
            <div style={{ display: 'flex' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography.Text
                  style={{ fontWeight: '500', fontSize: '20px' }}
                >
                  Total adquirente
                </Typography.Text>
                {loadingList ? (
                  <Skeleton.Input size="default" />
                ) : (
                  <Typography.Text>
                    {totalAcquirer?.toLocaleString('pt-br', {
                      currency: 'BRL',
                      style: 'currency',
                    })}
                  </Typography.Text>
                )}
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginLeft: '116px',
                }}
              >
                <Typography.Text
                  style={{ fontWeight: '500', fontSize: '20px' }}
                >
                  Diferença
                </Typography.Text>
                {loadingList ? (
                  <Skeleton.Input size="default" />
                ) : (
                  <Typography.Text>
                    {differenceLocalStorage?.toLocaleString('pt-br', {
                      currency: 'BRL',
                      style: 'currency',
                    })}
                  </Typography.Text>
                )}
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginLeft: '116px',
                }}
              >
                <Typography.Text
                  style={{ fontWeight: '500', fontSize: '20px' }}
                >
                  Adquirente
                </Typography.Text>
                {loadingList ? (
                  <Skeleton.Input size="default" />
                ) : (
                  <Typography.Text>{acquirerNameState}</Typography.Text>
                )}
              </div>
            </div>
          )}
          <Col lg={24} style={{ textAlign: 'right' }}>
            <Button
              onClick={() => {
                if (localTransactions) {
                  const ids = restoredTransactions.map((tr) => tr?.unique_id);
                  const filtered = localTransactions?.client?.files
                    .map((file) => {
                      if (
                        file?.id === restoredTransactions[0].id_file_tracking
                      ) {
                        return {
                          ...file,
                          transactions: file.transactions.filter(
                            (tr) => !ids.includes(tr?.id)
                          ),
                        };
                      }
                      return file;
                    })
                    .filter((file) => file.transactions.length > 0);
                  localTransactions.client.files = filtered;
                  if (filtered.length > 0) {
                    localStorage.setItem(
                      'classified_transactions',
                      JSON.stringify(localTransactions)
                    );
                  } else {
                    localStorage.removeItem('classified_transactions');
                  }
                }
                !throughImportFiles
                  ? customHistory.push(
                      `/conciliacao-bancaria?page=${pageToBeSentBack}`
                    )
                  : customHistory.push('/importacao-arquivos');
              }}
            >
              Cancelar
            </Button>
            <Button
              style={{ marginLeft: '8px' }}
              onClick={onSave}
              loading={loadingUpdate}
              type="primary"
            >
              Salvar
            </Button>
          </Col>
        </Row>
      </StFooter>
    </div>
  );
};

export default TransactionsReport;
//
