import { action } from 'typesafe-actions';
import { actionTypes } from 'store/ducks/credit/legalRepresentative/types';

export const getLegalRepresentativeStart = () =>
  action(actionTypes.GET_LEGAL_REPRESENTATIVE_START);
export const getLegalRepresentativeSuccess = (data) =>
  action(actionTypes.GET_LEGAL_REPRESENTATIVE_SUCCESS, data);
export const getLegalRepresentativeError = (errorMessage, error) =>
  action(
    actionTypes.GET_LEGAL_REPRESENTATIVE_ERROR,
    { errorMessage },
    undefined,
    {
      error,
    }
  );

export const postLegalRepresentativeStart = (data) =>
  action(actionTypes.POST_LEGAL_REPRESENTATIVE_START, data);
export const postLegalRepresentativeSuccess = (data) =>
  action(actionTypes.POST_LEGAL_REPRESENTATIVE_SUCCESS, data);
export const postLegalRepresentativeError = (errorMessage, error) =>
  action(
    actionTypes.POST_LEGAL_REPRESENTATIVE_ERROR,
    { errorMessage },
    undefined,
    {
      error,
    }
  );
