import { lambdaApi } from 'services/api';

const transformer = (apiResponse) => {
  const productsParser = apiResponse?.data?.products.map((product) => {
    if (product.id === 1 && product.can_installments) {
      return {
        ...product,
        id: '1P',
      };
    }

    return product;
  });

  const products = {
    products: productsParser,
    meta: apiResponse.meta,
  };
  return products;
};

const getFeeProducts = async ({ acquirerId }) => {
  const response = await lambdaApi().get(
    `/fees/products?acquirer_id=${acquirerId}`
  );
  return transformer(response.data);
};

export default getFeeProducts;
