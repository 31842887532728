import { Col, Row } from 'antd';
import styled from 'styled-components';
import { colors } from 'styles/colors';

export const StContainer = styled(Row)`
  line-height: 40px;
`;

export const StCol = styled(Col)`
  > h1 {
    color: ${colors.gray9};
    font-size: 30px;
    font-weight: 500;
    line-height: 38px;
    margin-bottom: 8px;
  }
`;

export const StRow = styled(Row)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  > span {
    color: ${colors.gray8};
    font-size: 14px;
    font-weight: 400;
  }
`;
