import React from 'react';
import Actions from './Actions';
import {
  columnFilters,
  getDefaultColumns,
  onColumnFilter,
} from 'helpers/generalService';
import FilterDropdown from 'commons/Table/components/FilterDropdown';
import {
  ofxParametersFiltersStart,
  ofxParametersFiltersZeroLength,
} from 'store/ducks/filters/ofxParameters/actions';
import store from 'store';

let filteredColumns = store.getState().updateFilteredColumns?.filteredColumns;

const ColumnList = (
  onOpenEditModal,
  filterText,
  toParamsFilters,
  apiFilters
) => [
  {
    title: 'ID',
    dataIndex: 'id',
    filteredValue: getDefaultColumns(toParamsFilters, 'id'),
    filters: apiFilters.length > 0 ? columnFilters(apiFilters, 'id') : [],
    onFilter: (value, record) => onColumnFilter(value, record, 'id'),
    onFilterDropdownVisibleChange: (visible) => {
      filteredColumns = store.getState().updateFilteredColumns?.filteredColumns;
      if (visible) {
        store.dispatch(ofxParametersFiltersZeroLength());
        store.dispatch(
          ofxParametersFiltersStart({
            columnName: 'id',
            offset: 0,
            filterBy: filterText,
            q:
              filteredColumns.length !== 0
                ? {
                    filter: filteredColumns,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <FilterDropdown
          key={filteredColumns}
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={ofxParametersFiltersStart}
          filteredColumns={
            store.getState().updateFilteredColumns?.filteredColumns
          }
          clearAction={ofxParametersFiltersZeroLength}
          columnName="id"
          storeName="ofxParametersFilters"
          filterBy={filterText}
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: 100,
    align: 'left',
  },
  {
    title: 'Peso',
    dataIndex: 'weight',
    filteredValue: getDefaultColumns(toParamsFilters, 'weight'),
    filters: apiFilters.length > 0 ? columnFilters(apiFilters, 'weight') : [],
    onFilter: (value, record) => onColumnFilter(value, record, 'weight'),
    onFilterDropdownVisibleChange: (visible) => {
      filteredColumns = store.getState().updateFilteredColumns?.filteredColumns;
      if (visible) {
        store.dispatch(ofxParametersFiltersZeroLength());
        store.dispatch(
          ofxParametersFiltersStart({
            columnName: 'weight',
            offset: 0,
            filterBy: filterText,
            q:
              filteredColumns.length !== 0
                ? {
                    filter: filteredColumns,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <FilterDropdown
          key={filteredColumns}
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={ofxParametersFiltersStart}
          filteredColumns={
            store.getState().updateFilteredColumns?.filteredColumns
          }
          clearAction={ofxParametersFiltersZeroLength}
          columnName="weight"
          storeName="ofxParametersFilters"
          filterBy={filterText}
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: 100,
    align: 'left',
  },
  {
    title: 'Descrição',
    dataIndex: 'rule',
    filteredValue: getDefaultColumns(toParamsFilters, 'rule'),
    filters: apiFilters.length > 0 ? columnFilters(apiFilters, 'rule') : [],
    onFilter: (value, record) => onColumnFilter(value, record, 'rule'),
    onFilterDropdownVisibleChange: (visible) => {
      filteredColumns = store.getState().updateFilteredColumns?.filteredColumns;
      if (visible) {
        store.dispatch(ofxParametersFiltersZeroLength());
        store.dispatch(
          ofxParametersFiltersStart({
            columnName: 'rule',
            offset: 0,
            filterBy: filterText,
            q:
              filteredColumns.length !== 0
                ? {
                    filter: filteredColumns,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <FilterDropdown
          key={filteredColumns}
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={ofxParametersFiltersStart}
          filteredColumns={
            store.getState().updateFilteredColumns?.filteredColumns
          }
          clearAction={ofxParametersFiltersZeroLength}
          columnName="rule"
          storeName="ofxParametersFilters"
          filterBy={filterText}
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: 456,
    align: 'left',
  },
  {
    title: 'Adquirente',
    dataIndex: 'acquirer',
    filteredValue: getDefaultColumns(toParamsFilters, 'acquirer'),
    filters: apiFilters.length > 0 ? columnFilters(apiFilters, 'acquirer') : [],
    onFilter: (value, record) => onColumnFilter(value, record, 'acquirer'),
    onFilterDropdownVisibleChange: (visible) => {
      filteredColumns = store.getState().updateFilteredColumns?.filteredColumns;
      if (visible) {
        store.dispatch(ofxParametersFiltersZeroLength());
        store.dispatch(
          ofxParametersFiltersStart({
            columnName: 'acquirer',
            offset: 0,
            filterBy: filterText,
            q:
              filteredColumns.length !== 0
                ? {
                    filter: filteredColumns,
                  }
                : [],
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <FilterDropdown
          key={filteredColumns}
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={ofxParametersFiltersStart}
          filteredColumns={
            store.getState().updateFilteredColumns?.filteredColumns
          }
          clearAction={ofxParametersFiltersZeroLength}
          columnName="acquirer"
          storeName="ofxParametersFilters"
          filterBy={filterText}
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: 265,
    align: 'left',
  },
  {
    title: 'Ações',
    dataIndex: 'action',
    align: 'center',
    width: 164,
    render: (_, record) => {
      return <Actions currentRow={record} openEditModal={onOpenEditModal} />;
    },
  },
];

export default ColumnList;
