import { api } from './api';

const transformer = (apiResponse) => {
  const data = {
    sales_quantity: apiResponse.data.quantity,
    sales_amount: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(
      apiResponse.data.sales_amount
    ),
    ticket_avarage: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(
      apiResponse.data.sales_average
    ),
  };
  return data;
};

const getSalesSummaryFromAPI = async ({ startDate, endDate }) => {
  const config = {
    params: {
      start_date: startDate.format('YYYY-MM-DD'),
      end_date: endDate.format('YYYY-MM-DD'),
    },
  };
  const response = await api().get('/summary/sales', config);
  return transformer(response.data);
};

export default getSalesSummaryFromAPI;
