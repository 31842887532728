import styled from 'styled-components';
import { WarningOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { colors } from 'styles/colors';

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 10px;
`;

export const Icon = styled(WarningOutlined)`
  margin-right: 16px;
  font-size: 22px;
  color: ${colors.gold6};
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
`;

export const Content = styled.p`
  margin-left: 48px;
  font-size: 14px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
`;

export const Loading = styled(Spin)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 110px;
`;
