/**
 * Debounces a function, grouping sequential function calls by waiting for it to not be called again for a period before executing it.
 * @param { function } func function to be debounced
 * @param { number } wait time to wait (in ms)
 * @returns { function } debounced function
 */
export const debounce = (func, wait) => {
  let timeout;

  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};
