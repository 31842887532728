import { action } from 'typesafe-actions';
import { actionTypes } from 'store/ducks/updateFilteredColumns/types';

export const updateFilteredColumnsStart = (data) =>
  action(actionTypes.UPDATE_FILTERED_COLUMNS_START, data);
export const updateFilteredColumnsSuccess = (data) =>
  action(actionTypes.UPDATE_FILTERED_COLUMNS_SUCCESS, data);
export const updateFilteredColumnsError = (errorMessage, error) =>
  action(
    actionTypes.UPDATE_FILTERED_COLUMNS_ERROR,
    { errorMessage },
    undefined,
    { error }
  );

export const resetUpdateFilteredColumnsStart = (data) =>
  action(actionTypes.RESET_UPDATE_FILTERED_COLUMNS_START, data);
export const resetUpdateFilteredColumnsSuccess = (data) =>
  action(actionTypes.RESET_UPDATE_FILTERED_COLUMNS_SUCCESS, data);
export const resetUpdateFilteredColumnsError = (errorMessage, error) =>
  action(
    actionTypes.RESET_UPDATE_FILTERED_COLUMNS_ERROR,
    { errorMessage },
    undefined,
    {
      error,
    }
  );
