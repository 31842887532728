import React, { createContext } from 'react';
import { ErrorCard } from '../../commons/ErrorCard';
import { OfxParametersHeader } from './components/OfxParamsHeader';
import { AddOFXParameter } from './components/AddParameter';
import { EditOFXParameter } from './components/EditParameter';
import { useOfxParameters } from './hooks/useOfxParameters';
import { OfxParametersTable } from './components/OfxParametersTable';
import { Card, Col, Row } from 'antd';
import { StContainer } from './styled';

export const OfxParametersContext = createContext({});

const OfxParameters = () => {
  const hookValues = useOfxParameters();

  const {
    error,
    loadingAcquirer,
    loading,
    handleAddModal,
    acquirers,
    addModalVisibility,
    editModalVisibility,
    editingRecord,
    handleEditModal,
  } = hookValues;

  // Render
  if (error) {
    return (
      <Row
        style={{
          marginTop: '192px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Col lg={10} md={18}>
          <Card style={{ borderRadius: 4 }} loading={loading}>
            <ErrorCard
              title="Dados indisponíveis"
              reason="Não conseguimos buscar os dados, mas já estamos trabalhando para normalizar a situação."
              recommendation="Tente atualizar a página em alguns instantes."
            />
          </Card>
        </Col>
      </Row>
    );
  }

  return (
    <StContainer>
      <OfxParametersContext.Provider value={hookValues}>
        <OfxParametersHeader />

        <OfxParametersTable />

        <AddOFXParameter
          acquirers={acquirers}
          visibility={addModalVisibility}
          loading={loading}
          loadingAcquirers={loadingAcquirer}
          closeModal={handleAddModal}
        />
        <EditOFXParameter
          acquirers={acquirers}
          visibility={editModalVisibility}
          loading={loading}
          loadingAcquirers={loadingAcquirer}
          editingRecord={editingRecord}
          closeModal={handleEditModal}
        />
      </OfxParametersContext.Provider>
    </StContainer>
  );
};

export default OfxParameters;
