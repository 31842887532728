import dayjs from 'dayjs';
import { actionTypes } from './types';

const INITIAL_STATE = {
  loadingList: true,
  loadingUpload: false,
  loadingRemove: false,
  listData: [],
  count: 0,
  hasMore: true,
  isErrorList: false,
  isErrorUpload: false,
  isErrorRemove: false,
  errorMessage: '',
  meta: {
    per_page: 10,
    page: 1,
    has_next: true,
    total: 18,
    filter: 'OFX_EXTRATOS',
    search_by: '',
  },
  fileStartDate: dayjs().format('YYYY-MM-DD'),
  fileEndDate: dayjs().format('YYYY-MM-DD'),
  currentPage: 1,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.LIST_FILES_START:
      return {
        ...state,
        loadingList: true,
        errorMessage: '',
        isErrorList: false,
      };
    case actionTypes.LIST_FILES_SUCCESS:
      return {
        ...state,
        loadingList: false,
        listData: action.payload.data,
        isErrorList: false,
        errorMessage: '',
        meta: action.payload.meta,
      };
    case actionTypes.LIST_FILES_ERROR:
      return {
        ...state,
        loadingList: false,
        errorMessage: action.payload.error,
        isErrorList: true,
        listData: [],
      };
    case actionTypes.LIST_FILES_ALTER:
      return {
        ...state,
        loadingList: true,
      };
    case actionTypes.REMOVE_FILES_START:
      return {
        ...state,
        loadingRemove: true,
        errorMessage: '',
        isErrorRemove: false,
      };
    case actionTypes.REMOVE_FILES_SUCCESS:
      return {
        ...state,
        loadingRemove: false,
        isErrorRemove: false,
        errorMessage: '',
      };
    case actionTypes.REMOVE_FILES_ERROR:
      return {
        ...state,
        loadingRemove: false,
        errorMessage: action.payload.error,
        isErrorRemove: true,
      };
    case actionTypes.UPLOAD_FILE_START:
      return {
        ...state,
        loadingUpload: true,
        errorMessage: '',
        isErrorUpload: false,
      };
    case actionTypes.UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        loadingUpload: false,
        errorMessage: '',
        isErrorUpload: false,
      };
    case actionTypes.UPLOAD_FILE_ERROR:
      return {
        ...state,
        loadingUpload: false,
        errorMessage: action.payload.error,
        isErrorUpload: true,
      };
    case actionTypes.UPDATE_FILE_DATE_START:
      return {
        ...state,
        loadingUpload: true,
        errorMessage: '',
        isErrorUpload: false,
      };
    case actionTypes.UPDATE_FILE_DATE_SUCCESS:
      return {
        ...state,
        fileStartDate: action?.payload?.fileStartDate,
        fileEndDate: action?.payload?.fileEndDate,
        loadingUpload: false,
        errorMessage: '',
        isErrorUpload: false,
      };
    case actionTypes.UPDATE_FILE_DATE_ERROR:
      return {
        ...state,
        loadingUpload: false,
        errorMessage: action.payload.error,
        isErrorUpload: true,
      };
    case actionTypes.UPDATE_CURRENT_PAGE_START:
      return {
        ...state,
        errorMessage: '',
        isErrorUpload: false,
      };
    case actionTypes.UPDATE_CURRENT_PAGE_SUCCESS:
      return {
        ...state,
        currentPage: action.payload.currentPage,
        loadingUpload: false,
        errorMessage: '',
        isErrorUpload: false,
      };
    case actionTypes.UPDATE_CURRENT_PAGE_ERROR:
      return {
        ...state,
        currentPage: 1,
        errorMessage: action.payload.error,
        isErrorUpload: true,
      };
    default:
      return state;
  }
};

export default reducer;
