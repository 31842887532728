import { put } from 'redux-saga/effects';

import errorHandler from 'helpers/errorHandler';

import {
  updateBriefingStepSuccess,
  updateBriefingStepError,
} from 'store/ducks/updateBriefingStep/actions';

export function* updateBriefingStepSaga(action) {
  try {
    const { payload } = action;
    yield put(updateBriefingStepSuccess(payload));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        updateBriefingStepError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(updateBriefingStepError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}
