import { action } from 'typesafe-actions';
import { actionTypes } from './types';

export const getUploadedLetterUrlStart = (data) =>
  action(actionTypes.GET_UPLOADED_LETTER_URL_START, { data });
export const getUploadedLetterUrlSuccess = (data) =>
  action(actionTypes.GET_UPLOADED_LETTER_URL_SUCCESS, data);
export const getUploadedLetterUrlError = (errorMessage, error) =>
  action(
    actionTypes.GET_UPLOADED_LETTER_URL_ERROR,
    { errorMessage },
    undefined,
    { error }
  );

export const deleteUploadedLetterUrlStart = (data) =>
  action(actionTypes.DELETE_UPLOADED_LETTER_URL_START, { data });
export const deleteUploadedLetterUrlSuccess = (data) =>
  action(actionTypes.DELETE_UPLOADED_LETTER_URL_SUCCESS, data);
export const deleteUploadedLetterUrlError = (errorMessage, error) =>
  action(
    actionTypes.DELETE_UPLOADED_LETTER_URL_ERROR,
    { errorMessage },
    undefined,
    { error }
  );
