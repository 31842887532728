import { call, put } from 'redux-saga/effects';
import {
  getStoresError,
  getStoresSuccess,
  postStoreError,
  postStoreSuccess,
  putStoreError,
  putStoreSuccess,
  deleteStoreError,
  deleteStoreSuccess,
} from './actions';
import errorHandler from '../../../helpers/errorHandler';
import { notification } from 'antd';
import { deleteStore, getStores, postStore, putStore } from 'services/store';

export function* getStoresSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(getStores, payload);
    yield put(getStoresSuccess(apiData));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        getStoresError(err.response.data.error.message, err.response.data.error)
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(getStoresError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}

export function* postStoreSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(postStore, payload);
    yield put(postStoreSuccess(apiData));
    notification.success({ message: 'CNPJ incluído com sucesso !' });
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        postStoreError(err.response.data.error.message, err.response.data.error)
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(postStoreError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}

export function* putStoreSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(putStore, payload);
    const isHeadOffice = apiData?.meta?.head_office;
    if (isHeadOffice) {
      notification.success({ message: 'Cadastro atualizado com sucesso!' });
    }
    yield put(putStoreSuccess(apiData));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        putStoreError(err.response.data.error.message, err.response.data.error)
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(putStoreError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}

export function* deleteStoreSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(deleteStore, payload);
    yield put(deleteStoreSuccess(apiData));
    notification.success({ message: 'CNPJ removido com sucesso !' });
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        deleteStoreError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(deleteStoreError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}
