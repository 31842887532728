import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unfilteredGeneralListOfClientsStart } from 'store/ducks/unfilteredGeneralListOfClients/actions';

export const useModals = () => {
  const dispatch = useDispatch();
  const generalListOfClients = useSelector(
    (state) => state.unfilteredGeneralListOfClients?.clients
  );
  const generalListOfClientsLoading = useSelector(
    (state) => state.unfilteredGeneralListOfClients?.loading
  );
  const [listOfClients, setListOfClients] = useState([]);
  const [currentRowData, setCurrentRowData] = useState({});
  const [isModalVisible, setIsModalVisible] = useState({
    default: false,
    reset: false,
    delete: false,
  });

  const showDefaultModal = (clientData) => {
    setIsModalVisible({ default: !isModalVisible.default });
    setCurrentRowData(clientData);

    dispatch(
      unfilteredGeneralListOfClientsStart({
        filterType: 'client',
        filter: '',
        page: 1,
        perPage: 10000,
      })
    );
  };

  useEffect(() => {
    const list = generalListOfClients.filter(
      (client, index, self) =>
        index === self.findIndex((c) => c.client_id === client.client_id)
    );
    setListOfClients(list);
  }, [generalListOfClients]);

  return {
    isModalVisible,
    setIsModalVisible,
    showDefaultModal,
    generalListOfClients,
    generalListOfClientsLoading,
    listOfClients,
    setListOfClients,
    currentRowData,
    setCurrentRowData,
  };
};
