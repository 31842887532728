import { lambdaApi } from './api';

const transform = (response) => {
  const { data } = response;
  return data;
};

export const postSpecialistContact = async ({ planIdRequested }) => {
  const config = {
    plan_id_requested: planIdRequested,
  };

  const response = await lambdaApi().post('/plan_request', config);

  return transform(response.data);
};
