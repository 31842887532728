import { api } from './api';

const transformer = (apiResponse) => {
  return {
    clients: apiResponse.data.clients,
    meta: apiResponse.meta
  };
};

const getGeneralListOfClientsFromAPI = async ({ filterType, filter, page, perPage }) => {
  const config = {
    params: {
      filter_type: filterType,
      filter,
      page,
      per_page: perPage,
    },
  };
  const response = await api().get('/all_clients', config);
  return transformer(response.data);
};

export default getGeneralListOfClientsFromAPI;
