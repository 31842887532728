import React from 'react';
import { ActionContainer, ActionText, EditIcon } from './styled';

export function Action() {
  return (
    <ActionContainer>
      <EditIcon />
      <ActionText>Conferir e editar</ActionText>
    </ActionContainer>
  );
}
