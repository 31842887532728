import { api } from './api';

export const getCnpj = async ({ data }) => {
  return api().get('/integrations/find/cnpj', {
    params: {
      cnpj: data.cnpj
    }
  }).then((resp) => {
    return resp.data;
  })
    .catch((ex) => {
      throw ex;
    });
};
