import styled from 'styled-components';
import Col from 'antd/es/grid/col';
import { Button } from 'antd';

export const StCol = styled(Col)`
  margin-right: 0.9rem;
`;

export const StButton = styled(Button)`
  width: 14rem;
  height: 4rem;
  margin: 0 0 0 0.867rem;
  padding: 0.625rem 1.5rem;
  border-radius: 4px;
`;

export const StColWrapper = styled.div`
  display: flex;
`;
