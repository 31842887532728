import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Spin, Grid } from 'antd';

import { LoginImage } from 'commons/LoginImage';

import { AuthForm } from './components';

import { StContainer } from './styled';
import {
  StContainer as StPasswordContainer,
  StRow,
  StTitleRow,
} from 'components/CreatePassword/styled';
import { PasswordForm } from 'components/CreatePassword/components';
import { colors } from 'styles/colors';

const Login = () => {
  const { useBreakpoint } = Grid;
  const breakpoint = useBreakpoint();
  const loginLoading = useSelector((state) => state.auth.loading);
  const tokenValidationLoading = useSelector(
    (state) => state.auth?.tokenValidationLoading
  );
  const authData = useSelector((state) => state.auth?.authData);
  const [isNewPasswordRequired, setIsNewPasswordRequired] = useState(false);
  const [passwordWasCreated, setPasswordWasCreated] = useState(false);

  useEffect(() => {
    if (authData?.challengeName === 'NEW_PASSWORD_REQUIRED') {
      setIsNewPasswordRequired(true);
    }
  }, [authData]);

  if (loginLoading || tokenValidationLoading) {
    return (
      <StContainer $breakpoint={breakpoint}>
        <Row align="middle" justify="center" style={{ height: '100%' }}>
          <Col>
            <Spin size="large" />
          </Col>
        </Row>
      </StContainer>
    );
  }

  return (
    <>
      {isNewPasswordRequired ? (
        <StPasswordContainer $breakpoint={breakpoint}>
          <StRow align="middle">
            <Col>
              <div style={{ display: 'block' }}>
                {passwordWasCreated === false ? (
                  <StTitleRow align="middle">
                    <Col span={24}>
                      <p style={{ color: colors.gray8, fontWeight: 600 }}>
                        Boas vindas ao Concil Card!
                      </p>
                      <p style={{ fontSize: 14 }}>
                        Para acessar a plataforma é necessário que você crie a
                        sua senha definitiva
                      </p>
                    </Col>
                  </StTitleRow>
                ) : (
                  <></>
                )}

                <StRow align="middle">
                  <Col>
                    <PasswordForm
                      authData={authData}
                      passwordWasCreated={passwordWasCreated}
                      setPasswordWasCreated={setPasswordWasCreated}
                    />
                  </Col>
                </StRow>
              </div>
            </Col>
          </StRow>
        </StPasswordContainer>
      ) : (
        <StContainer $breakpoint={breakpoint}>
          <Row
            align="middle"
            justify="center"
            style={{
              height: '100%',
              width: breakpoint?.lg ? '100%' : '80%',
              margin: '0 auto',
            }}
          >
            <Col span={24}>
              <Row>
                <Col xs={0} sm={0} md={0} lg={5} xl={5} xxl={5} />
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={7}
                  xl={7}
                  xxl={7}
                  style={{ boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)' }}
                >
                  <AuthForm />
                </Col>
                <Col xs={0} sm={0} md={0} lg={8} xl={8} xxl={8}>
                  <LoginImage />
                </Col>
                <Col xs={0} sm={0} md={0} lg={4} xl={4} xxl={4} />
              </Row>
            </Col>
          </Row>
        </StContainer>
      )}
    </>
  );
};

export default Login;
