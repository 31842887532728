// TODO: Remove camelcase eslint rule
/* eslint-disable camelcase */
import { api } from '../api';

const transform = (response, columnName) => {
  const filters = {
    results: [
      {
        text: columnName,
        values: response?.data?.results,
      },
    ],
    hasMore: response?.meta?.has_next,
    offset: response?.meta?.offset,
    limit: response?.meta?.limit,
    column: response?.meta?.column,
  };
  return filters;
};

const getImportFilesFiltersFromAPI = async ({
  startDate,
  endDate,
  columnName,
  offset,
  limit,
  searchBy,
}) => {
  const config = {
    params: {
      start_date: startDate.format('YYYY-MM-DD'),
      end_date: endDate.format('YYYY-MM-DD'),
      column: columnName,
      limit,
      offset,
      type: 'detail',
      search_by: searchBy,
    },
  };
  const response = await api().get(
    '/bank/imported-files/detail/filters',
    config
  );
  const transformedResponse = transform(response?.data, columnName);
  return transformedResponse;
};

export default getImportFilesFiltersFromAPI;
