import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Button, Col, Row, Form, Grid, Typography } from 'antd';
import { forgotPasswordRequest } from 'store/ducks/forgotPassword/actions';
import { StSpace, StRow } from './styled';
import { setSessionUsername } from 'helpers/sessionService';
import { LoadingOutlined } from '@ant-design/icons';
import { colors } from 'styles/colors';
import { dataTestIdHandler } from 'helpers/dataTestIdHandler';

const EmailForm = () => {
  const { useBreakpoint } = Grid;
  const breakpoint = useBreakpoint();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.forgotPassword.loading);
  const dataTestPrefix = 'forgot-password';

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };
  const tailLayout = {
    wrapperCol: { span: 24 },
  };

  const baseURL = process.env.REACT_APP_AWS_V4_ASSETS;
  const logo = `${baseURL}/login/logo.png`;

  const onFinish = (values) => {
    const { email } = values;
    setSessionUsername(email);
    dispatch(forgotPasswordRequest({ email }));
  };

  return (
    <StRow align="middle" justify="center" $breakpoint={breakpoint}>
      <Col style={{ maxWidth: 265 }}>
        <StSpace $breakpoint={breakpoint}>
          <Row>
            <Col>
              <img
                src={logo}
                alt="Logo Concil Card"
                style={{ marginTop: '1rem' }}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: '4rem' }}>
            <Col>
              <Typography.Text
                style={{
                  fontSize: '16px',
                  marginTop: '3rem',
                  fontWeight: 'bold',
                  color: colors.gray9,
                }}
              >
                Esqueceu a senha?
              </Typography.Text>
            </Col>
          </Row>
          <Row style={{ width: '100%', marginTop: 5 }}>
            <Col style={{ textAlign: 'center' }}>
              <Typography.Text
                style={{
                  textAlign: 'center',
                  fontSize: '14px',
                  color: colors.gray8,
                  lineHeight: '22px',
                }}
              >
                Não se preocupe! Vamos enviar um e-mail com instruções para
                redefinir sua senha
              </Typography.Text>
            </Col>
          </Row>
          <Row style={{ width: '100%' }}>
            <Col style={{ width: '100%' }}>
              <Form
                style={{ marginTop: 32 }}
                name="login_form"
                {...layout}
                onFinish={onFinish}
              >
                <Form.Item
                  style={{ color: colors.gray9 }}
                  labelAlign="left"
                  label="E-mail::"
                  name="email"
                  rules={[
                    { required: true, message: 'Por favor insira o e-mail!' },
                  ]}
                >
                  <Input
                    type="email"
                    placeholder="Insira seu e-mail"
                    {...dataTestIdHandler(dataTestPrefix, 'email')}
                  />
                </Form.Item>
                <Form.Item
                  {...tailLayout}
                  dependencies={['email']}
                  name="submit_button"
                >
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: '100%', marginTop: '1rem' }}
                    loading={loading}
                    icon={loading && <LoadingOutlined />}
                    disabled={loading}
                    {...dataTestIdHandler(dataTestPrefix, 'submit')}
                  >
                    {loading ? 'Carregando' : 'Avançar '}
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </StSpace>
      </Col>
    </StRow>
  );
};

export default EmailForm;
