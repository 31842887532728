import { call, put } from 'redux-saga/effects';
import errorHandler from 'helpers/errorHandler';
import getAdjustmentInfoFiltersFromAPI from 'services/filters/adjustmentInfoFilters';
import { adjustmentInfoFiltersSuccess, adjustmentInfoFiltersError } from './actions';

export function* adjustmentInfoFiltersSaga(action) {
  const { payload } = action;

  try {
    const apiData = yield call(getAdjustmentInfoFiltersFromAPI, payload);
    yield put(adjustmentInfoFiltersSuccess(apiData));
  } catch (err) {
    if (
      err?.response?.data?.error &&
      err?.response?.data?.error?.type &&
      err?.response?.data?.error?.message
    ) {
      yield put(
        adjustmentInfoFiltersError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(
        err.response.data.error.message,
        err.response.data.error
      );
    }
  }
}
