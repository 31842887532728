import React, { forwardRef, useEffect } from 'react';
import { HeaderRegisters } from '../../../../commons/HeaderRegisters';
import { Col, Row, Typography } from 'antd';
import { getStoresStart } from '../../../../store/ducks/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Loadable } from '../../../../commons/Loadable';
import { acquirerStart } from '../../../../store/ducks/acquirer/actions';
import { toTitleCase } from '../../../../helpers/generalService';

// eslint-disable-next-line no-unused-vars
const BriefingReview = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const loadingStores = useSelector((state) => state.store.loading);
  const storeData = useSelector((state) => state.store.stores);
  const acquirers = useSelector((state) => state.acquirer.acquirers);
  let headOfficeStore;
  let anotherStores;
  let stores;

  const loadStores = () => {
    stores = undefined;
    headOfficeStore = undefined;
    anotherStores = undefined;
    dispatch(getStoresStart({}));
  };

  useEffect(() => {
    if (acquirers === undefined || acquirers.length === 0) {
      dispatch(acquirerStart({}));
    }
    loadStores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  if (storeData && (!stores || !headOfficeStore)) {
    stores = storeData?.stores;
    if (stores) {
      headOfficeStore = stores.find((s) => s.head_office === true);
      anotherStores = stores.filter((s) => s.head_office !== true);
    }
  }

  const typographyText = (text, fontWeight = 'normal') => {
    return (
      <Typography.Text style={{ fontSize: '1.75rem', fontWeight: fontWeight }}>
        {text}
      </Typography.Text>
    );
  };

  const renderMerchants = (acquirerId) => {
    let merchants = [];
    if (stores) {
      stores.forEach((s) => {
        const newMerchants = s.merchants.filter(
          (m) => m.acquirer_id === acquirerId
        );
        for (let i = 0; i < newMerchants.length; i += 1) {
          newMerchants[i].store_br_cnpj = s.br_cnpj;
          newMerchants[i].store_name = s.name;
          newMerchants[i].store_short_name = s.short_name;
        }
        merchants = merchants.concat(newMerchants);
      });
    }
    return merchants.map((merchant) => (
      <Row>
        <Col span={4}>{typographyText(merchant.store_br_cnpj)}</Col>
        <Col span={8}>{typographyText(merchant.store_name)}</Col>
        <Col span={8}>{typographyText(merchant.store_short_name)}</Col>
        <Col span={4}>{typographyText(merchant.code)}</Col>
      </Row>
    ));
  };

  return (
    <HeaderRegisters
      title="Revisão de dados"
      subTitle="Revise os dados e conclua no botão abaixo"
    >
      <Loadable loading={loadingStores}>
        <br />
        <div
          style={{
            overflowX: 'hidden',
            overflowY: 'auto',
            maxHeight: '405px',
            paddingBottom: '80px',
          }}
        >
          <Typography.Text style={{ fontSize: '2rem', fontWeight: 'bold' }}>
            CNPJs cadastrados
          </Typography.Text>
          <br />
          <br />
          {headOfficeStore && (
            <div>
              <Row>
                <Col span={4}>{typographyText('CNPJ principal', 'bold')}</Col>
                <Col span={10}>{typographyText('Razão Social', 'bold')}</Col>
                <Col span={10}>{typographyText('Nome da loja', 'bold')}</Col>
              </Row>
              <Row>
                <Col span={4}>{typographyText(headOfficeStore.br_cnpj)}</Col>
                <Col span={10}>{typographyText(headOfficeStore.name)}</Col>
                <Col span={10}>
                  {typographyText(headOfficeStore.short_name)}
                </Col>
              </Row>
            </div>
          )}
          <br />
          {anotherStores && (
            <div>
              <Row>
                <Col span={4}>{typographyText('CNPJ (outros)', 'bold')}</Col>
                <Col span={10}>{typographyText('Razão Social', 'bold')}</Col>
                <Col span={10}>{typographyText('Nome da loja', 'bold')}</Col>
              </Row>
            </div>
          )}
          {anotherStores &&
            anotherStores.map((s) => (
              <Row>
                <Col span={4}>{typographyText(s.br_cnpj)}</Col>
                <Col span={10}>{typographyText(s.name)}</Col>
                <Col span={10}>{typographyText(s.short_name)}</Col>
              </Row>
            ))}
          <br />
          <hr />
          <br />
          <Typography.Text style={{ fontSize: '2rem', fontWeight: 'bold' }}>
            Estabelecimentos cadastrados
          </Typography.Text>
          <br />
          {acquirers &&
            acquirers.map((acquirer) => (
              <div>
                <br />
                <img
                  src={acquirer.acquirer_logo}
                  alt={toTitleCase(acquirer.name)}
                  height={35}
                  width={35}
                />
                &nbsp;
                <Typography.Text
                  style={{ fontSize: '3rem', verticalAlign: 'middle' }}
                >
                  {toTitleCase(acquirer.name)}
                </Typography.Text>
                <br />
                <Row>
                  <Col span={4}>{typographyText('CNPJ', 'bold')}</Col>
                  <Col span={8}>{typographyText('Razão Social', 'bold')}</Col>
                  <Col span={8}>{typographyText('Nome da loja', 'bold')}</Col>
                  <Col span={4}>
                    {typographyText('Estabelecimento', 'bold')}
                  </Col>
                </Row>
                {renderMerchants(acquirer.acquirer_id)}
              </div>
            ))}
        </div>
      </Loadable>
    </HeaderRegisters>
  );
});

export default BriefingReview;
