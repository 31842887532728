import { action } from 'typesafe-actions';
import { actionTypes } from 'store/ducks/credit/creditInteractClose/types';

export const creditInteractCloseStart = (data) =>
  action(actionTypes.CREDIT_INTERACT_CLOSE_START, data);
export const creditInteractCloseSuccess = (data) =>
  action(actionTypes.CREDIT_INTERACT_CLOSE_SUCCESS, data);
export const creditInteractCloseError = (errorMessage, error) =>
  action(actionTypes.CREDIT_INTERACT_CLOSE_ERROR, { errorMessage }, undefined, {
    error,
  });
