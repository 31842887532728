import React from 'react';
import { Col, Grid } from 'antd';

import { PasswordForm } from './components';

import { StContainer, StRow, StTitleRow } from './styled';
import { colors } from 'styles/colors';

const CreatePassword = () => {
  const { useBreakpoint } = Grid;
  const breakpoint = useBreakpoint();

  return (
    <StContainer $breakpoint={breakpoint}>
      <div style={{ display: 'block' }}>
        <StTitleRow align="middle">
          <Col span={24}>
            <p style={{ color: colors.gray8, fontWeight: 600 }}>
              Boas vindas ao Concil Card!
            </p>
            <p style={{ fontSize: 14 }}>
              Para acessar a plataforma é necessário que você crie a sua senha
              definitiva
            </p>
          </Col>
        </StTitleRow>
        <StRow align="middle">
          <Col>
            <PasswordForm />
          </Col>
        </StRow>
      </div>
    </StContainer>
  );
};

export default CreatePassword;
