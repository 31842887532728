import { lambdaApi } from 'services/api';

const transformer = (apiResponse) => {
  const clients = {
    clients: apiResponse?.data?.results,
    meta: apiResponse?.meta,
  };

  return clients;
};

export const getRadarClients = async ({ filter, page, perPage }) => {
  const config = {
    params: {
      filter,
      page,
      per_page: perPage || 50,
    },
  };
  const response = await lambdaApi().get('/radar/process/clients', config);
  return transformer(response.data);
};
