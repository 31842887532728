import styled from 'styled-components';
import { Tag, Row, Col } from 'antd';
import { colors } from 'styles/colors';

export const StContainer = styled.div`
  max-width: 1366px;
  margin: 72px auto 72px auto !important;
  height: 100%;
  margin-top: 3rem;
  ${({ disabled }) =>
    disabled &&
    `
    pointer-events: none;
  opacity: 0.7;
  `}
`;

export const StRow = styled(Row)`
  &&& {
    padding-bottom: 45px;
  }
`;

export const StDashboardTag = styled(Tag)`
  &&& {
    margin: 0;
    color: ${colors.primary6};
    background-color: ${colors.primary1};
    border: 1px solid ${colors.primary5};
    border-radius: 2px;
    :hover {
      cursor: pointer;
    }
  }
`;

export const StDropdownContent = styled.div`
  &&& {
    ul {
      background-color: ${colors.primary1};

      li {
        margin: 0;
        color: ${colors.primary6};
        background-color: ${colors.primary1};

        :hover {
          background-color: ${colors.primary2};
        }
      }
    }
  }
`;

export const StDashboardFooterCardUpperContainer = styled.div`
  &&& {
    padding: 16px 16px 0px 16px;
    height: 100%;
  }
`;

export const StDashboardFooterRow = styled(Row)`
  &&& {
    position: absolute;
    width: 100%;
    bottom: 0;
    margin-top: 18px;
    padding: 9px 16px;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
  }
`;

export const StDashboardFooterCol = styled(Col)`
  &&& {
    display: flex;
    align-items: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: ${colors.blue6};
    :hover {
      text-decoration-line: underline;
      cursor: pointer;
    }
  }
`;

export const StHoverClickTitle = styled.div`
  &&& {
    padding: 0 4px;
  }
  &:hover {
    background: rgba(230, 247, 255, 0.05);
    border-radius: 4px;
    text-decoration-line: underline;
    cursor: pointer;
    color: ${colors.blue6};
    filter: invert(46%) sepia(72%) saturate(2675%) hue-rotate(189deg)
      brightness(98%) contrast(105%);
  }
`;

export const StHoverClickValue = styled.div`
  &&& strong,
  h1 {
    :hover {
      text-decoration-line: underline;
      background: rgba(230, 247, 255, 0.05);
      cursor: pointer;
      ${({ disableHoverColor }) =>
        !disableHoverColor && `color: ${colors.blue6};`}
    }
  }
`;
