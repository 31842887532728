import { action } from 'typesafe-actions';
import { actionTypes } from './types';

export const getNotificationStart = (data) =>
  action(actionTypes.GET_NOTIFICATION_START, { data });
export const getNotificationSuccess = (data) =>
  action(actionTypes.GET_NOTIFICATION_SUCCESS, data);
export const getNotificationError = (errorMessage, error) =>
  action(actionTypes.GET_NOTIFICATION_ERROR, { errorMessage }, undefined, {
    error,
  });

export const createNotificationStart = (data) =>
  action(actionTypes.CREATE_NOTIFICATION_START, { data });
export const createNotificationSuccess = (data) =>
  action(actionTypes.CREATE_NOTIFICATION_SUCCESS, data);
export const createNotificationError = (errorMessage, error) =>
  action(actionTypes.CREATE_NOTIFICATION_ERROR, { errorMessage }, undefined, {
    error,
  });

export const changeNotificationStatusStart = (data) =>
  action(actionTypes.CHANGE_NOTIFICATION_STATUS_START, { data });
export const changeNotificationStatusSuccess = (data) =>
  action(actionTypes.CHANGE_NOTIFICATION_STATUS_SUCCESS, data);
export const changeNotificationStatusError = (errorMessage, error) =>
  action(
    actionTypes.CHANGE_NOTIFICATION_STATUS_ERROR,
    { errorMessage },
    undefined,
    {
      error,
    }
  );

export const getDownloadUrlStart = (data) =>
  action(actionTypes.GET_DOWNLOAD_URL_START, { data });
export const getDownloadUrlSuccess = (data) =>
  action(actionTypes.GET_DOWNLOAD_URL_SUCCESS, data);
export const getDownloadUrlError = (errorMessage, error) =>
  action(actionTypes.GET_DOWNLOAD_URL_ERROR, { errorMessage }, undefined, {
    error,
  });

export const markAlertAsSent = (data) =>
  action(actionTypes.MARK_ALERT_AS_SENT, { data });

export const updateNotificationPage = (data) =>
  action(actionTypes.UPDATE_NOTIFICATION_PAGE, { data });

export const updateNotificationPageSuccess = (data) =>
  action(actionTypes.UPDATE_NOTIFICATION_PAGE_SUCCESS, data);

export const updateNotificationPerPage = (data) =>
  action(actionTypes.UPDATE_NOTIFICATION_PER_PAGE, { data });

export const updateNotificationPerPageSuccess = (data) =>
  action(actionTypes.UPDATE_NOTIFICATION_PER_PAGE_SUCCESS, data);
