import React from 'react';
import { Col } from 'antd';
import * as St from './styled';

const OfxParametersHeader = () => {
  return (
    <St.ContainerRow align="middle" justify="space-between">
      <Col span={16}>
        <St.Title>Regras OFX</St.Title>
      </Col>

      <St.MainColumn span={24}>
        <St.SubtitleText>
          Adicione, edite e pesquise regras OFX gerais ou personalizadas que
          serão aplicadas na plataforma
        </St.SubtitleText>
      </St.MainColumn>
    </St.ContainerRow>
  );
};

export default OfxParametersHeader;
