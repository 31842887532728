import styled from 'styled-components';
import { filtersTopPosition } from 'helpers/style';
import { colors } from 'styles/colors';

export const StContainer = styled.div`
  background-color: ${colors.gray1};
  position: sticky;
  top: ${({ breakpoint }) => filtersTopPosition(breakpoint)};
  z-index: ${({ isCNPJMaintenance }) => (isCNPJMaintenance ? 3 : 100)};
  margin-left: 0;
  margin-right: 0;
  border-radius: 3px;
  padding-bottom: 1.65rem;
`;

export const StLimiter = styled.div`
  margin: 40px 50px 0px 50px;
  ${({ isCNPJMaintenance }) =>
    isCNPJMaintenance &&
    `
      margin: 0 24px;
    `}
`;
