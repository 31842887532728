import styled from 'styled-components';
import { Form, Typography } from 'antd';
import { colors } from 'styles/colors';

const { Title, Text } = Typography;

export const TopTitle = styled(Title)`
  font-weight: 500;
  font-size: 30px !important;
  color: ${colors.gray9};
`;

export const TopText = styled(Text)`
  color: ${colors.gray9};
  font-size: 14px;
  font-weight: 400;
`;

export const TopForm = styled(Form)`
  margin-top: 32px;
  display: flex;
  width: 100%;
`;

export const BoldText = styled.span`
  font-weight: bold;
`;
