import { Button, List, Input } from 'antd';
import styled from 'styled-components';
import { colors } from 'styles/colors';

export const StBusinessButton = styled(Button)`
  width: 100%;
  cursor: default;
  background-color: ${colors.gray2};
  border-bottom: none;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  text-align: start;
  font-size: 1.5rem;
  padding: 1.5rem 14px;
  height: inherit;
  :hover {
    background-color: ${colors.gray2};
    color: ${colors.gray11};
    border-top: 1px solid;
    border-top-color: rgba(74, 74, 74, 0.2) !important;
  }
`;

export const StSearchWrapper = styled.div``;

export const StSearch = styled(Input.Search)`
  width: 100%;
  margin-top: 2rem;
`;

export const StCompanyListContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  overflow-y: hidden;
`;

export const StListWrapper = styled.div`
  width: 100%;
  overflow-y: scroll;
`;

export const StListItem = styled(List.Item)`
  width: 100%;
  padding: 0;
  cursor: pointer;
`;

export const StListItemButton = styled(Button)`
  width: 100%;
  height: 100%;
  padding: 1rem 15px;
  text-align: start;
  font-size: 1.5rem;
  background-color: ${colors.secondary6};
  color: ${colors.gray11} !important;
  :hover {
    color: ${colors.gray11} !important;
    background-color: ${colors.gray5};
  }
  display: flex !important;
`;

export const StListItemButtonDifferent = styled(Button)`
  width: 100%;
  height: 100%;
  padding: 1rem 15px;
  text-align: start;
  font-size: 1.5rem;
  color: ${colors.gray8} !important;
  &&& {
    :hover {
      color: ${colors.gray8} !important;
      background-color: ${colors.primary1};
    }
    display: flex !important;
  }
`;
