import { action } from 'typesafe-actions';
import { actionTypes } from './types';

export const paymentDivergencesDetailsFiltersStart = (data) => action(actionTypes.PAYMENT_DIVERGENCES_DETAILS_FILTERS_START, data);
export const paymentDivergencesDetailsFiltersSuccess = (data) =>
  action(actionTypes.PAYMENT_DIVERGENCES_DETAILS_FILTERS_SUCCESS, data);
export const paymentDivergencesDetailsFiltersError = (errorMessage, error) =>
  action(actionTypes.PAYMENT_DIVERGENCES_DETAILS_FILTERS_ERROR, { errorMessage }, undefined, { error });
export const paymentDivergencesDetailsFiltersZeroLength = () =>
  action(actionTypes.PAYMENT_DIVERGENCES_DETAILS_FILTERS_ZERO_LENGTH);
export const updatePaymentDivergencesDetailsFilters = (data) =>
  action(actionTypes.UPDATE_PAYMENT_DIVERGENCES_DETAILS_FILTERS, data);
