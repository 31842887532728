import { put } from 'redux-saga/effects';
import errorHandler from 'helpers/errorHandler';
import {
  customUpdateFilteredColumnsSuccess,
  customUpdateFilteredColumnsError,
  customResetUpdateFilteredColumnsSuccess,
  customResetUpdateFilteredColumnsError,
} from './actions';

export function* customUpdateFilteredColumnsSaga(action) {
  try {
    const { payload } = action;
    yield put(customUpdateFilteredColumnsSuccess(payload));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        customUpdateFilteredColumnsError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(customUpdateFilteredColumnsError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}

export function* customResetUpdateFilteredColumnsSaga(action) {
  try {
    const { payload } = action;
    yield put(customResetUpdateFilteredColumnsSuccess(payload));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        customResetUpdateFilteredColumnsError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(customResetUpdateFilteredColumnsError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}
