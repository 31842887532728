import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(isSameOrBefore);

export const handleStatus = (isActive, requestDeleteDate, endDate, today) => {
  let status;

  // Ativo
  if (isActive && requestDeleteDate === '' && endDate === '') {
    status = 'Ativo';
  }

  // Solicitou p/ desativar
  if (
    !isActive &&
    requestDeleteDate &&
    requestDeleteDate !== null &&
    requestDeleteDate !== '' &&
    dayjs(endDate).isAfter(today)
  ) {
    status = `Solicitação para desativar feita em ${dayjs(
      requestDeleteDate
    ).format('DD/MM/YYYY')}`;
  }

  // Desativado
  if (
    !isActive &&
    requestDeleteDate &&
    requestDeleteDate !== null &&
    requestDeleteDate !== '' &&
    dayjs(endDate).isSameOrBefore(today)
  ) {
    status = `Desativado em ${dayjs(endDate).format('DD/MM/YYYY')}`;
  }

  return status;
};
