import { call, put } from 'redux-saga/effects';

import errorHandler from 'helpers/errorHandler';

import {
  reconciledReceiptDetailsSuccess,
  reconciledReceiptDetailsError,
} from 'store/ducks/details/reconciledReceiptDetails/actions';
import getReconciledReceiptDetailsFromAPI from 'services/reconciled/details/receipt';

export function* reconciledReceiptDetailsSaga(action) {
  try {
    const { payload } = action;

    const apiData = yield call(getReconciledReceiptDetailsFromAPI, payload);

    yield put(reconciledReceiptDetailsSuccess(apiData));
  } catch (err) {
    if (err?.response?.data?.message) {
      yield put(
        reconciledReceiptDetailsError(
          err.response.data.message,
          err.response.data.error
        )
      );
      errorHandler(err.response.data.message, err.response.data.error);
    }
  }
}
