import styled from 'styled-components';
import { Button } from 'antd';
import { colors } from 'styles/colors';

export const StSeeDetailsButton = styled(Button)`
  &&& {
    font-family: Roboto;
    font-size: ${(props) => props?.fontSize};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: end;
    color: ${colors.blue6};
  }
`;
