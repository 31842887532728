import { encryptData, decryptData } from './encryptionService';

const localStorageName = 'concilcard';
const localStorageCriptoKey = process.env.REACT_APP_LOCALSTORAGE_ENCRYPTION_KEY;

export const getSessionData = () => {
  const crypted = localStorage.getItem(localStorageName);
  if (!crypted) return {};
  return decryptData(crypted, localStorageCriptoKey);
};

export const setSessionData = (data) => {
  const dataEncrypt = encryptData(data, localStorageCriptoKey);
  localStorage.setItem(localStorageName, dataEncrypt);
};

export const getSessionAccessToken = () => {
  const localStorageAuthData = getSessionData();
  return localStorageAuthData?.access_token;
};

export const setSessionAccessToken = (accessToken) => {
  const localStorageAuthData = getSessionData();
  localStorageAuthData.access_token = accessToken;
  setSessionData(localStorageAuthData);
};

export const getSessionRefreshToken = () => {
  const localStorageAuthData = getSessionData();
  return localStorageAuthData?.refresh_token;
};

export const removeAccessToken = () => {
  const localStorageAuthData = getSessionData();
  localStorageAuthData.access_token = '';
  setSessionData(localStorageAuthData);
};

export const getSessionClientId = () => {
  const localStorageAuthData = getSessionData();
  return localStorageAuthData?.client_id;
};

export const setSessionClientId = (clientId) => {
  const localStorageAuthData = getSessionData();
  localStorageAuthData.client_id = clientId;
  setSessionData(localStorageAuthData);
};

export const getSessionClientCode = () => {
  const localStorageAuthData = getSessionData();
  return localStorageAuthData?.client_code;
};

export const setSessionClientCode = (clientCode) => {
  const localStorageAuthData = getSessionData();
  localStorageAuthData.client_code = clientCode;
  setSessionData(localStorageAuthData);
};

export const getSessionClientName = () => {
  const localStorageAuthData = getSessionData();
  return localStorageAuthData?.client_name;
};

export const setSessionClientName = (clientName) => {
  const localStorageAuthData = getSessionData();
  localStorageAuthData.client_name = clientName;
  setSessionData(localStorageAuthData);
};

export const getSessionUsername = () => {
  const localStorageAuthData = getSessionData();
  return localStorageAuthData?.username;
};

export const setSessionUsername = async (username) => {
  const localStorageAuthData = getSessionData();
  localStorageAuthData.username = username;
  setSessionData(localStorageAuthData);
};

export const getSessionFirstName = () => {
  const localStorageAuthData = getSessionData();
  return localStorageAuthData?.user_first_name;
};

export const setSessionFirstName = (firstName) => {
  const localStorageAuthData = getSessionData();
  localStorageAuthData.user_first_name = firstName;
  setSessionData(localStorageAuthData);
};

export const getSessionSurname = () => {
  const localStorageAuthData = getSessionData();
  return localStorageAuthData?.user_surname;
};

export const setSessionSurname = (surname) => {
  const localStorageAuthData = getSessionData();
  localStorageAuthData.user_surname = surname;
  setSessionData(localStorageAuthData);
};

export const getSessionFullName = () => {
  const localStorageAuthData = getSessionData();
  return `${localStorageAuthData?.user_first_name} ${localStorageAuthData?.user_surname}`;
};

export const getSessionIsImplanted = () => {
  const localStorageAuthData = getSessionData();
  return localStorageAuthData?.is_implanted;
};

export const setSessionIsImplanted = (isImplanted) => {
  const localStorageAuthData = getSessionData();
  localStorageAuthData.is_implanted = isImplanted;
  setSessionData(localStorageAuthData);
};

export const getSessionIsIntegration = () => {
  const localStorageAuthData = getSessionData();
  return localStorageAuthData?.is_integration;
};

export const setSessionIsIntegration = (isIntegration) => {
  const localStorageAuthData = getSessionData();
  localStorageAuthData.is_integration = isIntegration;
  setSessionData(localStorageAuthData);
};

export const getSessionIsMobile = () => {
  const localStorageAuthData = getSessionData();
  return localStorageAuthData?.is_mobile;
};

export const setSessionIsMobile = (isMobile) => {
  const localStorageAuthData = getSessionData();
  localStorageAuthData.is_mobile = isMobile;
  setSessionData(localStorageAuthData);
};

export const getSessionIsWeb = () => {
  const localStorageAuthData = getSessionData();
  return localStorageAuthData?.is_web;
};

export const setSessionIsWeb = (isWeb) => {
  const localStorageAuthData = getSessionData();
  localStorageAuthData.is_web = isWeb;
  setSessionData(localStorageAuthData);
};

export const getSessionScopeId = () => {
  const localStorageAuthData = getSessionData();
  return localStorageAuthData?.scope_id;
};

export const setSessionScopeId = (scopeId) => {
  const localStorageAuthData = getSessionData();
  localStorageAuthData.scope_id = scopeId;
  setSessionData(localStorageAuthData);
};

export const getSessionProfileId = () => {
  const localStorageAuthData = getSessionData();
  return localStorageAuthData?.profile_id;
};

export const setSessionProfileId = (profileId) => {
  const localStorageAuthData = getSessionData();
  localStorageAuthData.profile_id = profileId;
  setSessionData(localStorageAuthData);
};

export const getSessionUsernameId = () => {
  const localStorageAuthData = getSessionData();
  return localStorageAuthData?.usernameId;
};

export const setSessionUsernameId = (usernameId) => {
  const localStorageAuthData = getSessionData();
  localStorageAuthData.usernameId = usernameId;
  setSessionData(localStorageAuthData);
};

export const setSessionClientPlanId = (planId) => {
  const localStorageAuthData = getSessionData();
  localStorageAuthData.client_plan_id = planId;
  setSessionData(localStorageAuthData);
};

export const setSessionClientSkinId = (skinId) => {
  const localStorageAuthData = getSessionData();
  localStorageAuthData.client_skin_id = skinId;
  setSessionData(localStorageAuthData);
};

export const getSessionClientSkinId = () => {
  const localStorageAuthData = getSessionData();
  return localStorageAuthData?.client_skin_id;
};

export const setSessionClientCreatedAt = (createdAt) => {
  const localStorageAuthData = getSessionData();
  localStorageAuthData.client_created_at = createdAt;
  setSessionData(localStorageAuthData);
};

export const getSessionClientPlanId = () => {
  const localStorageAuthData = getSessionData();
  return localStorageAuthData?.client_plan_id;
};

export const getSessionClientCreatedAt = () => {
  const localStorageAuthData = getSessionData();
  return localStorageAuthData?.client_created_at;
};

export const removeLocalStorage = () => {
  localStorage.removeItem('concil.auth.data'); // Old key
  localStorage.removeItem(localStorageName);
};
