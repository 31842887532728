import { action } from 'typesafe-actions';
import { actionTypes } from 'store/ducks/credit/creditPartnerOptin/types';

export const creditPartnerOptinStart = (data) =>
  action(actionTypes.CREDIT_PARTNER_OPTIN_START, data);
export const creditPartnerOptinSuccess = (data) =>
  action(actionTypes.CREDIT_PARTNER_OPTIN_SUCCESS, data);
export const creditPartnerOptinError = (errorMessage, error) =>
  action(actionTypes.CREDIT_PARTNER_OPTIN_ERROR, { errorMessage }, undefined, {
    error,
  });
