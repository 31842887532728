import { api, lambdaApi } from './api';

const transformOFXParameters = ({ data, meta }) => {
  return {
    count: meta.num_results,
    hasNext: meta.has_next,
    page: meta.page,
    perPage: meta.per_page,
    listData: data.rules,
  };
};

export const listOFXParameters = async ({
  page,
  perPage,
  sortFields,
  q,
  filterBy,
}) => {
  const config = {
    params: {
      page,
      per_page: perPage,
      filter_by: filterBy,
      sort_fields: JSON.stringify(sortFields),
      q: JSON.stringify(q),
    },
  };
  const response = await lambdaApi().get('/ofx/rules', config);
  return transformOFXParameters(response.data);
};
export const addOFXParameter = async (data) => {
  await api().post('/ofx/parameter', data);
};
export const editOFXParameter = async (data) => {
  await api().put('/ofx/parameter', data);
};
export const removeOFXParameter = async (data) => {
  await api().delete(`/ofx/parameter/${data.id}`);
};
