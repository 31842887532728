import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import customHistory from 'helpers/history';
import { CardResume } from '../../../../commons/CardResume';
import { updateScreenToScreenDateStart } from 'store/ducks/updateScreenToScreenDate/actions';
import dayjs from 'dayjs';

const DuedateCard = ({
  startDateDuedate,
  endDateDuedate,
  setStartDateDuedate,
  setEndDateDuedate,
  isDuedate,
  isAdvancements,
  isAdjustments,
  isRefunds,
}) => {
  const dispatch = useDispatch();
  const cardResumeProps = {
    startDateDuedate,
    endDateDuedate,
    setStartDateDuedate,
    setEndDateDuedate,
    isDuedate,
    isAdvancements,
    isAdjustments,
    isRefunds,
  };

  const receiptPaymentsData = useSelector(
    (store) => store.duedatePaymentsDash.payments
  );
  const receiptPaymentsLoading = useSelector(
    (store) => store.duedatePaymentsDash.loading
  );
  const dataTestPrefix = 'duedate-card';

  const goToDuedate = () => {
    customHistory.push('/pagamentos-intermediario');
    dispatch(
      updateScreenToScreenDateStart({
        startDate: startDateDuedate,
        endDate: endDateDuedate,
      })
    );
  };

  return (
    <CardResume
      titleCard="Pagamentos"
      dataCard={receiptPaymentsData}
      goToUrl={goToDuedate}
      loading={receiptPaymentsLoading}
      dataTestPrefix={dataTestPrefix}
      {...cardResumeProps}
    />
  );
};

DuedateCard.propTypes = {
  startDateDuedate: PropTypes.instanceOf(dayjs).isRequired,
  endDateDuedate: PropTypes.instanceOf(dayjs).isRequired,
  setStartDateDuedate: PropTypes.func.isRequired,
  setEndDateDuedate: PropTypes.func.isRequired,
};

export default DuedateCard;
