import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'antd';
import { CardListItem } from '../CardListItem';
import { StWrapper } from './styled';
import { shortId } from 'helpers/shortId';

const ContentCards = ({ data, loading, activeTab }) => {
  return (
    <StWrapper>
      <List
        key={shortId()}
        grid={{
          xxl: 6,
          xl: 5,
          lg: 4,
          md: 3,
          sm: 2,
          xs: 1,
          gutter: 16,
        }}
        itemLayout="horizontal"
        style={{ width: '100%' }}
        dataSource={data}
        renderItem={(item) => (
          <List.Item key={shortId()}>
            <CardListItem data={item} loading={loading} activeTab={activeTab} />
          </List.Item>
        )}
      />
    </StWrapper>
  );
};

ContentCards.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      client_id: PropTypes.number,
      net_amount_difference_reconciled: PropTypes.number,
      late_payments_count: PropTypes.number,
      pending_net_amout: PropTypes.number,
      reconciled_net_amout: PropTypes.number,
      forecast_net_amout: PropTypes.number,
    })
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  activeTab: PropTypes.string.isRequired,
};

export default ContentCards;
