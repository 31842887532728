import React, { useState } from 'react';
import customHistory from 'helpers/history';
import { Button, Popover } from 'antd';
import {
  StBlockedTooltipContent,
  StBlockedTooltipText,
  StBlockedTooltipTitle,
} from './styled';
import skins from 'constants/skins';
import store from 'store';

const BlockedFeaturePopover = ({
  visible = true,
  width = '345px',
  trigger = 'hover',
  placement = 'rightTop',
  tooltipText,
  children,
  onClick = () => customHistory.push('/planos'),
}) => {
  const [open, setOpen] = useState(false);
  const { CONCIL_REVENDA } = skins;
  const { permissions } = store.getState();
  const skinId = permissions?.data?.skin_id;

  const handleClick = () => {
    setOpen(false);
    onClick();
  };

  const content = (
    <StBlockedTooltipContent>
      <StBlockedTooltipTitle>
        Disponível para planos superiores
      </StBlockedTooltipTitle>
      <StBlockedTooltipText>{tooltipText}</StBlockedTooltipText>
      {skinId !== CONCIL_REVENDA && (
        <Button type="primary" onClick={handleClick}>
          Consultar planos
        </Button>
      )}
    </StBlockedTooltipContent>
  );

  if (!visible) return children;

  return (
    <Popover
      overlayInnerStyle={{ width }}
      placement={placement}
      content={tooltipText && content}
      trigger={trigger}
      open={open}
      onOpenChange={() => setOpen(!open)}
    >
      {children}
    </Popover>
  );
};

export default BlockedFeaturePopover;
