import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Modal, Row, Tooltip } from 'antd';
import { CopyOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { deleteReconciliationRulesStart } from 'store/ducks/reconciliationRules/deleteReconciliationRules/actions';
import { ModalForm } from '../ModalForm';
import { isSuperAdmin } from 'helpers/authorizationService';
import { colors } from 'styles/colors';

const Actions = ({ currentRow }) => {
  const dispatch = useDispatch();
  const loadingRemove = useSelector(
    (state) => state.deleteReconciliationRules.loading
  );
  const [deleteModalVisibility, setDeleteModalVisibility] = useState(false);
  const [editModalVisibility, setEditModalVisibility] = useState(false);
  const [duplicateModalVisibility, setDuplicateModalVisibility] =
    useState(false);

  const { rule_id: ruleId } = currentRow;

  const deleteModal = () => {
    if (deleteModalVisibility && !loadingRemove) {
      Modal.confirm({
        visible: deleteModalVisibility,
        title: 'Excluir regra',
        okText: 'Sim',
        cancelText: 'Voltar',
        onOk: () => {
          dispatch(
            deleteReconciliationRulesStart({
              ruleId,
            })
          );
          setDeleteModalVisibility(!deleteModalVisibility);
        },
        onCancel: () => setDeleteModalVisibility(!deleteModalVisibility),
        centered: true,
        content: 'Deseja realmente excluir esta regra de conciliação?',
        maskClosable: true,
      });
    }
  };

  // eslint-disable-next-line consistent-return
  const editModal = () => {
    if (editModalVisibility) {
      return (
        <>
          <ModalForm
            visibility={editModalVisibility}
            closeModal={() => setEditModalVisibility(false)}
            modalType="editRule"
            currentRow={currentRow}
            isDuplicating={false}
          />
        </>
      );
    }
  };

  // eslint-disable-next-line consistent-return
  const duplicateModal = () => {
    let formattedCurrentRow;
    Object.keys(currentRow).forEach((key) => {
      if (key === 'execution_sequence') {
        formattedCurrentRow = {
          ...currentRow,
          [key]: 0,
        };
      }
    });
    if (duplicateModalVisibility) {
      return (
        <>
          <ModalForm
            visibility={duplicateModalVisibility}
            closeModal={() => setDuplicateModalVisibility(false)}
            modalType="editRule"
            currentRow={formattedCurrentRow}
            isDuplicating
          />
        </>
      );
    }
  };

  return (
    <>
      <Row>
        <Col lg={isSuperAdmin() ? 8 : 12}>
          <Tooltip title="Duplicar">
            <CopyOutlined
              onClick={() =>
                setDuplicateModalVisibility(!duplicateModalVisibility)
              }
              style={{ color: colors.primary6, cursor: 'pointer' }}
            />
          </Tooltip>
        </Col>

        <Col lg={isSuperAdmin() ? 8 : 12}>
          <Tooltip title="Editar">
            <EditOutlined
              onClick={() => setEditModalVisibility(!editModalVisibility)}
              style={{ color: colors.primary6 }}
            />
          </Tooltip>
        </Col>
        {isSuperAdmin() && (
          <Col lg={8} style={{ color: colors.red5 }}>
            <Tooltip title="Excluir">
              <DeleteOutlined
                onClick={() => setDeleteModalVisibility(!deleteModalVisibility)}
              />
            </Tooltip>
          </Col>
        )}
      </Row>
      {deleteModalVisibility && deleteModal()}
      {editModalVisibility && editModal()}
      {duplicateModalVisibility && duplicateModal()}
    </>
  );
};

Actions.propTypes = {};

export default Actions;
