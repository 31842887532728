import { Button, Col, Form, Input, Row, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import LabelAndInputs from '../../../../../commons/LabelAndInputs/LabelAndInputs';
import ptBR from 'antd/es/date-picker/locale/pt_BR';
import {
  getCnpjStart,
  resetCnpjData,
  updateCnpjStatus,
} from '../../../../../store/ducks/receitaWebservice/actions';
import { useDispatch, useSelector } from 'react-redux';
import { generateStoreShortName } from '../../../../../helpers/briefingService';
import { validateStoreName } from '../../../../../services/briefing/validateStoreName';
import { postStoreStart } from '../../../../../store/ducks/store/actions';
import { StCpfCnpj, StWarning } from '../styled';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { cpf } from 'helpers/cpf';
import errorHandler from 'helpers/errorHandler';

const StoreAddForm = ({ onAfterAdd, onCancel, isCNPJMaintenance }) => {
  const dispatch = useDispatch();
  const receitaWsData = useSelector((state) => state.receitaWebservice.data);
  const receitaWsCnpjStatus = useSelector(
    (state) => state.receitaWebservice.cnpjStatus
  );
  const receitaWsLoading = useSelector(
    (state) => state.receitaWebservice.loading
  );
  const storesLoading = useSelector((state) => state.store.loading);
  const storeHasBeenPosted = useSelector(
    (state) => state.store.storeHasBeenPosted
  );
  const [receitaWsDataWasCalled, setReceitaWsDataWasCalled] = useState(false);

  const [shortNameStatus, setShortNameStatus] = useState('');
  const [brCnpjStatus, setBrCnpjStatus] = useState('error');
  const [showWarning, setShowWarning] = useState(false);
  const [showCnpjWarning, setShowCnpjWarning] = useState(false);
  const [disableAddBtn, setDisableAddBtn] = useState(false);
  const [disableRazaoSocial, setDisableRazaoSocial] = useState(true);
  const [addForm] = Form.useForm();

  let generatedShortName;

  const {
    br_cnpj_add: brCnpjAddField,
    name_add: nameAddField,
    short_name_add: shortNameAddField,
  } = addForm.getFieldsValue(true);

  if (receitaWsData && receitaWsDataWasCalled) {
    if (receitaWsCnpjStatus === 'OK') {
      generatedShortName = generateStoreShortName(
        receitaWsData?.nome,
        receitaWsData?.fantasia,
        receitaWsData?.cnpj
      );

      addForm.setFieldsValue({
        br_cnpj_add: (
          receitaWsData?.cnpj ??
          brCnpjAddField ??
          ''
        ).toUpperCase(),
        name_add: (receitaWsData?.nome ?? nameAddField ?? '').toUpperCase(),
        short_name_add: (
          generatedShortName ??
          shortNameAddField ??
          ''
        ).toUpperCase(),
      });
    } else {
      addForm.setFieldsValue({
        name_add: '',
        short_name_add: '',
      });
    }

    setReceitaWsDataWasCalled(false);
  }

  const onFormAddFinish = (formValues) => {
    // async
    addForm
      .validateFields()
      .then(async () => {
        const data = {
          address: {
            city: formValues.city_add,
            state: formValues.state_add,
            street: formValues.street_add,
            number: formValues.number_add,
            complement: formValues.complement_add,
            district: formValues.district_add,
            zip_code: formValues.zip_code_add,
          },
          stores: [
            {
              name: formValues?.name_add || ' ',
              short_name: formValues?.short_name_add,
              head_office: false,
              store_code: formValues.br_cnpj_add.replace(/[^\w\s]/gi, ''),
              br_cnpj: formValues?.br_cnpj_add,
            },
          ],
        };
        dispatch(postStoreStart(data));
        dispatch(resetCnpjData({}));
        onAfterAdd();
      })
      .catch((error) => errorHandler('Ocorreu um erro:', error));
  };

  const onChangeAddCnpj = (e) => {
    const cnpjOrCpf = e.target.value.replace(/\D/g, '');

    if (cnpjOrCpf.length === 14) {
      setReceitaWsDataWasCalled(true);
      setShowCnpjWarning(false);
      dispatch(getCnpjStart({ cnpj: cnpjOrCpf }));
    }
    if (cnpjOrCpf.length === 0) {
      addForm.resetFields();
      setReceitaWsDataWasCalled(false);
      setShowCnpjWarning(false);
      setDisableAddBtn(true);
    }
    if (cnpjOrCpf.length > 0 && cnpjOrCpf.length < 11) {
      setShowCnpjWarning(false);
    }
    if (cnpjOrCpf.length === 11) {
      setReceitaWsDataWasCalled(false);
      if (cpf.isValid(cnpjOrCpf)) {
        setShowCnpjWarning(false);
        setDisableRazaoSocial(false);
      } else {
        setShowCnpjWarning(true);
        setDisableRazaoSocial(true);
      }
    } else {
      setDisableRazaoSocial(true);
    }
  };

  const onChangeShortNameAdd = (e) => {
    const shortNameAdd = e.target.value;
    if (shortNameAdd.length === 0) {
      addForm.setFieldsValue({
        short_name_add: '',
      });
      setDisableAddBtn(true);
      setShowWarning(false);
    }
    if (
      brCnpjAddField &&
      nameAddField &&
      receitaWsCnpjStatus === 'OK' &&
      shortNameAdd.length !== 0
    ) {
      setDisableAddBtn(false);
    }
  };

  useEffect(() => {
    if (receitaWsCnpjStatus === 'OK') {
      addForm.setFieldsValue({
        br_cnpj_add: receitaWsData?.cnpj,
        name_add: receitaWsData?.nome,
        short_name_add: generateStoreShortName(
          receitaWsData?.nome,
          receitaWsData?.fantasia,
          receitaWsData?.cnpj
        ),
      });
      setDisableAddBtn(false);
      setShowCnpjWarning(false);
      setBrCnpjStatus('success');
    }
    if (receitaWsCnpjStatus === 'ERROR') {
      setShowCnpjWarning(true);
      setBrCnpjStatus('error');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receitaWsCnpjStatus, receitaWsData]);

  useEffect(() => {
    if (receitaWsLoading) {
      setBrCnpjStatus('validating');
      setShowCnpjWarning(false);
    }
  }, [receitaWsLoading]);

  useEffect(() => {
    if (storeHasBeenPosted) {
      addForm.setFieldsValue({
        br_cnpj_add: '',
        name_add: '',
        short_name_add: '',
      });
      setBrCnpjStatus('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeHasBeenPosted]);

  const handleValidateStoreName = async (storeName) => {
    setShortNameStatus('validating');
    const response = await validateStoreName(storeName);

    if (storeName.length === 0) {
      setShowWarning(false);
      setShortNameStatus('');
    }

    if (!response) {
      setShowWarning(false);
      setShortNameStatus('');
      return response;
      // eslint-disable-next-line no-else-return
    } else {
      setShowWarning(true);
      setShortNameStatus('warning');
      setDisableAddBtn(true);
      return response;
    }
  };

  return (
    <Form
      form={addForm}
      onFinish={onFormAddFinish}
      disabled={receitaWsLoading}
      key="addForm"
      id="addForm"
      style={{ marginTop: '10px' }}
      preserve={false}
      initialValue={{
        br_cnpj_add: '',
        name_add: '',
        short_name_add: '',
      }}
    >
      <Row gutter={[8, 8]}>
        <Col lg={5}>
          <LabelAndInputs label="CNPJ (outros)">
            <Form.Item
              style={{ marginBottom: isCNPJMaintenance ? 0 : 24 }}
              name="br_cnpj_add"
              validateStatus={brCnpjStatus}
              validateTrigger={['onChange', 'onBlur']}
              rules={[
                {
                  required: true,
                  validator: (_, value) => {
                    if (!value) {
                      return Promise.reject(new Error('Insira um CNPJ'));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <StCpfCnpj
                placeholder="00.000.000/0000-00"
                type="tel"
                name="br_cnpj_add"
                onChange={onChangeAddCnpj}
                style={{
                  width: '100%',
                  marginBottom: isCNPJMaintenance ? 0 : 24,
                  cursor: 'text',
                }}
              />
            </Form.Item>
            {showCnpjWarning && (
              <StWarning isCnpj>CNPJ ou CPF Inválido</StWarning>
            )}
          </LabelAndInputs>
        </Col>
        <Col lg={7}>
          <LabelAndInputs label="Razão social">
            <Form.Item
              name="name_add"
              style={{ marginBottom: isCNPJMaintenance ? 0 : 24 }}
            >
              <Input
                placeholder="Razão social"
                name="name_add"
                locale={ptBR}
                style={{
                  width: '100%',
                  marginBottom: isCNPJMaintenance ? 0 : 24,
                  cursor: disableRazaoSocial ? 'default' : 'text',
                }}
                readOnly={disableRazaoSocial}
                disabled={receitaWsLoading || storesLoading}
              />
            </Form.Item>
          </LabelAndInputs>
        </Col>
        <Col lg={isCNPJMaintenance ? 8 : 7}>
          Apelido da loja
          <Tooltip title="Este é o nome pelo qual deseja identificar sua loja. Escolha um apelido fácil de lembrar para cada CNPJ e evite utilizar o mesmo apelido para lojas diferentes.">
            <Button
              type="link"
              icon={<ExclamationCircleFilled />}
              Style="color: #504e4e; width: 20px; height: 20px; padding: 0 0 0 5px; font-size: 13px; border-radius: 0px; vertical-align: 0px;"
              // eslint-disable-next-line react/jsx-closing-tag-location
            ></Button>
          </Tooltip>
          <br />
          <Form.Item
            style={{ marginBottom: isCNPJMaintenance ? 0 : 24 }}
            name="short_name_add"
            hasFeedback
            validateStatus={shortNameStatus}
            validateTrigger={['onBlur']}
            rules={[
              {
                required: true,
                // eslint-disable-next-line consistent-return
                async validator(_, value) {
                  if (value && value.length !== 0) {
                    await handleValidateStoreName(value);
                  }
                  if (!value) {
                    return Promise.reject(new Error('Insira um apelido'));
                  }
                },
              },
            ]}
          >
            <Input
              placeholder="Como quer chamar este CNPJ?"
              name="short_name_add"
              locale={ptBR}
              style={{
                width: '100%',
                marginBottom: isCNPJMaintenance ? 0 : 24,
              }}
              onChange={onChangeShortNameAdd}
              disabled={receitaWsLoading || storesLoading}
            />
          </Form.Item>
          {showWarning && <StWarning>Este apelido já está em uso.</StWarning>}
        </Col>
        <Col
          lg={isCNPJMaintenance ? 4 : 5}
          style={{
            marginBottom: isCNPJMaintenance ? 0 : 24,
          }}
        >
          <br />
          <Button
            type="primary"
            disabled={
              disableAddBtn ||
              receitaWsLoading ||
              receitaWsCnpjStatus === 'ERROR' ||
              !brCnpjAddField ||
              brCnpjAddField === '' ||
              !nameAddField ||
              nameAddField === '' ||
              !shortNameAddField ||
              shortNameAddField === '' ||
              shortNameStatus === 'validating'
            }
            ghost
            onClick={() => {
              addForm.submit();
              setDisableRazaoSocial(true);
              onAfterAdd();
            }}
          >
            Salvar
          </Button>
          &nbsp;
          <Button
            type="default"
            disabled={receitaWsLoading}
            onClick={() => {
              setDisableRazaoSocial(true);
              setBrCnpjStatus('');
              setShowCnpjWarning(false);
              setReceitaWsDataWasCalled(false);
              dispatch(updateCnpjStatus({ status: '' }));
              addForm.setFieldsValue({
                br_cnpj_add: '',
                name_add: '',
                short_name_add: '',
              });
              setTimeout(() => onCancel(), 350);
            }}
          >
            Cancelar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default StoreAddForm;
