import styled from 'styled-components';
import { colors } from 'styles/colors';

export const StContainer = styled.div`
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.04);
  background-color: ${colors.gray1};
  border-radius: 4px;
  cursor: default;
  width: 100%;
`;

export const StLimiter = styled.div`
  padding: 16px;
`;
