/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable quotes */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { salesDetailsStart } from 'store/ducks/details/salesDetails/actions';
import {
  salesDetailsFiltersZeroLength,
  updateSalesDetailsFilters,
} from 'store/ducks/filters/details/salesDetailsFilters/actions';
import { columns } from 'components/Reports/SalesReports/utils';
import { MobileCards } from 'components/Reports/SalesReports/components';

import { Tables } from 'commons/Tables';
import { generateFilterDataStructure } from 'helpers/tableService';
import { createNotificationStart } from 'store/ducks/notifications/actions';
import { updateScreenToScreenDateStart } from 'store/ducks/updateScreenToScreenDate/actions';
import {
  getSessionClientId,
  getSessionClientName,
} from 'helpers/sessionService';
import { MAX_CSV_ROWS, MAX_EXCEL_ROWS } from 'constants/general';
import { disabledDateByPlan } from 'helpers/disableDates';
import { exportFileParser } from 'utils/parsers/exportFileParser';
import { perPageOptions, showTotalPage } from 'constants/perPageOptions';
import { onColumnSort } from 'helpers/sorter';
import dayjs from 'dayjs';
import { shortId } from 'helpers/shortId';
import { resetUpdateFilteredColumnsStart } from 'store/ducks/updateFilteredColumns/actions';
import { setReportsCustomColumns } from 'store/ducks/reportsCustomColumns/actions';
import { Filters } from './components/Filters';

const SalesReports = () => {
  const dispatch = useDispatch();

  const permissionsData = useSelector((state) => state.permissions.data);
  const clientId = getSessionClientId();
  const clientName = getSessionClientName();
  const {
    user_id: userId,
    scope_id: scopeId,
    profile_id: profileId,
  } = permissionsData;

  const [localFilters, setLocalFilters] = useState([]);
  const [dropdownOption, setDropdownOption] = useState('');

  const startDate = useSelector(
    (state) => state.updateScreenToScreenDate.startDate
  );
  const endDate = useSelector(
    (state) => state.updateScreenToScreenDate.endDate
  );

  const [filtersDrawer, toggleFiltersDrawer] = useState(false);

  const [sortFields, setSortFields] = useState({});

  const [perPage, setPerPage] = useState(10);
  const [pageTotal, setPageTotal] = useState(100);
  const [pageCurrent, setPageCurrent] = useState(1);
  const [disableExcel, setDisableExcel] = useState(false);
  const [disableCSV, setDisableCSV] = useState(false);

  const toParamsFilters = useSelector(
    (state) => state.salesDetailsFilters.toParamsFilters
  );

  const salesLoading = useSelector((state) => state.salesDetails.loading);
  const salesDetails = useSelector((state) => state.salesDetails.details);
  const salesMeta = useSelector((state) => state.salesDetails.meta);
  const salesError = useSelector((state) => state.salesDetails.isError);
  const salesFeeAmount = useSelector((state) => state.salesDetails.feeAmount);
  const salesGrossAmount = useSelector(
    (state) => state.salesDetails.grossAmount
  );
  const salesNetAmount = useSelector((state) => state.salesDetails.netAmount);

  const filtersData = useSelector((state) => state.salesDetailsFilters.filters);

  const [calendarIsOpen, setCalendarIsOpen] = useState(false);
  const dateHasBeenUpdated = useSelector(
    (state) => state.updateScreenToScreenDate.dateHasBeenUpdated
  );

  const columnNames = [
    'Filial',
    'Data da venda',
    'Adquirente',
    'Produto',
    'Bandeira',
    'Valor bruto (R$)',
    'Valor líquido (R$)',
  ];

  const dataSource = salesDetails.map((obj) => ({
    ...obj,
    key: shortId(),
  }));

  const totalizers = {
    gross: {
      name: 'gross',
      text: 'Total bruto de vendas: ',
      amount: salesGrossAmount,
    },
    fee: {
      name: 'fee',
      text: 'Total das taxas: ',
      amount: salesFeeAmount,
    },
    net: {
      name: 'net',
      text: 'Total líquido de vendas: ',
      amount: salesNetAmount,
    },
  };

  useEffect(() => {
    return () => {
      dispatch(resetUpdateFilteredColumnsStart());
      dispatch(updateSalesDetailsFilters([]));
      dispatch(setReportsCustomColumns([]));
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      salesDetailsStart({
        startDate,
        endDate,
        sortFields,
        page: pageCurrent,
        perPage,
        q: {
          filter: toParamsFilters,
        },
      })
    );
  }, [
    startDate,
    endDate,
    dispatch,
    pageCurrent,
    perPage,
    sortFields,
    toParamsFilters,
    dateHasBeenUpdated,
  ]);

  useEffect(() => {
    setPageTotal(salesMeta.num_results);
    if (salesMeta.total_items > MAX_EXCEL_ROWS) {
      setDisableExcel(true);
    } else {
      setDisableExcel(false);
    }
    if (salesMeta.total_items > MAX_CSV_ROWS) {
      setDisableCSV(true);
    } else {
      setDisableCSV(false);
    }
  }, [salesMeta]);

  const onRangePickerChange = (dates) => {
    if (dates?.length) {
      if (
        calendarIsOpen &&
        (!dates[1] || dates[1]?.diff(dates[0], 'days') > 31)
      ) {
        dispatch(
          updateScreenToScreenDateStart({
            startDate: dates[0],
            endDate: dayjs(dates[0]).add(31, 'days'),
          })
        );
        dispatch(salesDetailsFiltersZeroLength());
      } else if (
        calendarIsOpen &&
        (!dates[1] || dates[1]?.diff(dates[0], 'days') <= 31)
      ) {
        dispatch(
          updateScreenToScreenDateStart({
            startDate: dates[0],
            endDate: dates[1],
          })
        );
        dispatch(salesDetailsFiltersZeroLength());
      }
    } else {
      dispatch(
        updateScreenToScreenDateStart({
          startDate: dayjs().subtract(32, 'days'),
          endDate: dayjs().subtract(1, 'day'),
        })
      );
      dispatch(salesDetailsFiltersZeroLength());
    }
  };

  const onRangePickerOpenOrCloseChange = (open) => {
    setCalendarIsOpen(open);
  };

  const onTablePaginationOrFiltersOrSorterChange = (
    pagination,
    tableFilters,
    sorter
  ) => {
    const filterDataToDispatch = generateFilterDataStructure(tableFilters);

    onColumnSort(sorter, setSortFields);
    setLocalFilters(tableFilters);
    dispatch(updateSalesDetailsFilters(filterDataToDispatch));
    setPerPage(pagination.pageSize);
    setPageTotal(salesMeta.num_results);
    setPageCurrent(pagination.current);
  };

  const onPageMobileChange = (page, size) => {
    setPageCurrent(page);
    setPerPage(size);
  };

  const onPageSizeMobileChange = (current, size) => {
    setPageCurrent(current);
    setPerPage(size);
  };

  const fileName =
    'relatorio_vendas.' + (dropdownOption === 'excel' ? 'xlsx' : 'csv');

  const format = 'YYYY-MM-DD';

  const notificationConfig = {
    clientId,
    userId,
    status: 'PREPARANDO',
    title: 'Preparando arquivo',
    isRead: false,
    isSendEmail: false,
    fileName,
    isExpired: false,
    createdBy: userId,
    updatedBy: userId,
    message: `Estamos preparando seu arquivo ${fileName} para o download.`,
    table: 'sale',
    fileType: dropdownOption === 'excel' ? 'xlsx' : 'csv',
    scopeId,
    profileId,
    startDate: dayjs(startDate).format(format),
    endDate: dayjs(endDate).format(format),
    q: {
      filter: exportFileParser(toParamsFilters),
    },
    sortFields: '',
    clientName,
  };

  const [showTable, setShowTable] = useState(true);

  const resetFiltersStates = () => {
    dispatch(salesDetailsFiltersZeroLength());
    dispatch(updateSalesDetailsFilters([]));
    dispatch(resetUpdateFilteredColumnsStart());
    dispatch(setReportsCustomColumns([]));
    setSortFields({});
    setPageCurrent(1);
  };

  const handleResetFilters = () => {
    setShowTable(false);
    resetFiltersStates();
  };

  useEffect(() => {
    setShowTable(true);
  }, [showTable]);

  return (
    <>
      <Filters
        title="Vendas"
        subtitle="Total de vendas realizadas no período"
        onRangePickerOpenOrCloseChange={onRangePickerOpenOrCloseChange}
        hasRangePicker
        hasSelect={false}
        hasSorter={false}
        dateDefaultValue={[startDate, endDate]}
        onRangePickerChange={onRangePickerChange}
        loading={salesLoading}
        filtersDrawer={filtersDrawer}
        onCloseFiltersDrawer={() => toggleFiltersDrawer(false)}
        sorterButtonText="Vendas"
        hasMobileFiltersButton={false}
        disabledDate={disabledDateByPlan}
        calendarIsOpen={calendarIsOpen}
        setCalendarIsOpen={setCalendarIsOpen}
      />
      <MobileCards
        data={dataSource}
        loading={salesLoading}
        pagination={{
          current: pageCurrent,
          pageSize: perPage,
          total: pageTotal,
          pageSizeOptions: perPageOptions,
          showTotal: showTotalPage,
        }}
        error={salesError}
        onPageSizeChange={onPageSizeMobileChange}
        onPageChange={onPageMobileChange}
      />
      {showTable && (
        <Tables
          dataSource={dataSource}
          columns={columns(
            filtersData,
            localFilters,
            toParamsFilters,
            sortFields,
            pageCurrent,
            perPage,
            startDate,
            endDate
          )}
          toParamsFilters={toParamsFilters}
          localFilters={localFilters}
          loading={salesLoading}
          error={salesError}
          createNotificationAction={createNotificationStart}
          notificationConfig={notificationConfig}
          dropdownOption={dropdownOption}
          setDropdownOption={setDropdownOption}
          pagination={{
            current: pageCurrent,
            pageSize: perPage,
            total: pageTotal,
            pageSizeOptions: perPageOptions,
            showTotal: showTotalPage,
          }}
          onChange={onTablePaginationOrFiltersOrSorterChange}
          defaultSelectedColumns={columnNames}
          disableExcel={disableExcel}
          disableCSV={disableCSV}
          totalizers={totalizers}
          handleResetFilters={handleResetFilters}
        />
      )}
    </>
  );
};

export default SalesReports;
