import { call, put } from 'redux-saga/effects';
import { notification } from 'antd';
import errorHandler from 'helpers/errorHandler';
import { registerFees } from '../../../services/registerFees';
import { registerFeeSuccess, registerFeeFailure } from './actions';
import { getSessionAccessToken } from 'helpers/sessionService';

export function* registerFeeSaga(action) {
  const fees = action.payload;
  try {
    if (getSessionAccessToken()) {
      yield call(registerFees, fees);
      yield put(registerFeeSuccess());
      notification.success({ message: 'Taxa cadastrada com sucesso !' });
    }
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        registerFeeFailure(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    }
  }
}
