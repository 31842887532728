import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'antd';
import {
  StTooltipTitle,
  StTooltipSubtitle,
  StTooltipValue,
  StRow,
} from './styled';

const MonthlyBarChartTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length > 0) {
    return (
      <StRow>
        <Col span={24}>
          <StTooltipTitle>{label}</StTooltipTitle>
        </Col>
        <Col span={24}>
          <StTooltipSubtitle>A receber no período:</StTooltipSubtitle>
        </Col>
        <Col span={24}>
          <StTooltipValue>
            {`R$ ${payload[0].value.toLocaleString('pt-br')}`}
          </StTooltipValue>
        </Col>
      </StRow>
    );
  }

  return null;
};

MonthlyBarChartTooltip.propTypes = {
  active: PropTypes.bool.isRequired,
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      payload: PropTypes.shape({
        value: PropTypes.number.isRequired,
      }).isRequired,
    })
  ).isRequired,
  label: PropTypes.string.isRequired,
};

export default MonthlyBarChartTooltip;
