import { Table } from 'antd';
import styled from 'styled-components';

export const StTable = styled(Table)`
  &&& {
    .ant-pagination-options::before {
      content: 'Itens por página';
      margin-right: 10px;
  }
`;
