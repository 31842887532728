import { action } from 'typesafe-actions';
import { actionTypes } from 'store/ducks/radar/radarGet/types';

export const radarGetStart = (data) =>
  action(actionTypes.RADAR_GET_START, data);
export const radarGetSuccess = (data) =>
  action(actionTypes.RADAR_GET_SUCCESS, data);
export const radarGetError = (errorMessage, error) =>
  action(actionTypes.RADAR_GET_ERROR, { errorMessage }, undefined, {
    error,
  });
