import { actionTypes } from './types';

const INITIAL_STATE = {
  banks: [],
  startDate: undefined,
  endDate: undefined,
  grossAmount: 0,
  loading: false,
  error: undefined,
  errorMessage: undefined,
  isError: false,
  filter: [],
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case actionTypes.SET_BANKS_FILTER:
    return {
      ...state,
      toParamsFilters: action.payload.toParamsFilters
    };
  case actionTypes.LIST_BANKS_INTERMEDIATE_START:
    return {
      ...state,
      loading: true,
      error: undefined,
      isError: false,
    };
  case actionTypes.LIST_BANKS_INTERMEDIATE_SUCCESS:
    return {
      ...state,
      loading: false,
      error: false,
      banks: action.payload.banks,
      startDate: action.payload.startDate,
      endDate: action.payload.endDate,
      grossAmount: action.payload.totalAmount,
      isError: false,
    };
  case actionTypes.LIST_BANKS_INTERMEDIATE_ERROR:
    return {
      ...state,
      loading: false,
      error: action.error.error,
      errorMessage: action.payload,
      isError: true,
    };
  default:
    return state;
  }
};

export default reducer;
