import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Tooltip, Popconfirm, Spin } from 'antd';
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { deleteMerchantStart } from 'store/ducks/cnpjMaintenance/merchants/deleteMerchant/actions';
import { colors } from 'styles/colors';

const Actions = ({ currentRow }) => {
  const dispatch = useDispatch();

  const currentMerchantDeleting = useSelector(
    (state) => state?.deleteMerchant?.merchantLoading
  );

  const isMerchantLoading = (merchantArray, _merchantId) =>
    merchantArray.includes(_merchantId);

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  return (
    <>
      {isMerchantLoading(currentMerchantDeleting, currentRow.merchant_id) ? (
        <Spin indicator={antIcon} size="small" />
      ) : (
        <Popconfirm
          icon={<ExclamationCircleOutlined />}
          disabled={!currentRow?.is_active || !currentRow?.is_empty}
          title={
            <Typography.Text
              style={{
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '22px',
                color: colors.gray9,
              }}
            >
              Tem certeza que deseja excluir o estabelecimento? <br />
              Isso apagará seus dados da plataforma.
            </Typography.Text>
          }
          onConfirm={() => {
            dispatch(
              deleteMerchantStart({ merchantId: currentRow.merchant_id })
            );
          }}
          okText="Sim"
          cancelText="Não"
          okType="primary"
        >
          <Tooltip
            autoAdjustOverflow
            placement="bottom"
            disabled={!currentRow?.is_empty}
            title={
              currentRow?.is_empty && currentRow?.is_active
                ? 'Excluir estabelecimento'
                : (currentRow?.is_empty || !currentRow?.is_empty) &&
                    !currentRow?.is_active
                  ? 'Estabelecimento inativo'
                  : 'Esse estabelecimento não pode ser excluído porque possui movimentações'
            }
          >
            <DeleteOutlined
              disabled={!currentRow?.is_active && !currentRow?.is_empty}
              style={{
                color:
                  currentRow?.is_active && currentRow?.is_empty
                    ? colors.red6
                    : colors.gray6,
                cursor:
                  currentRow?.is_active && currentRow?.is_empty
                    ? 'pointer'
                    : 'not-allowed',
              }}
            />
          </Tooltip>
        </Popconfirm>
      )}
    </>
  );
};
Actions.propTypes = {};
export default Actions;
