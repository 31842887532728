/* eslint-disable no-plusplus */
import { lambdaApi } from '../../api';

const transform = (response, columnName) => {
  const filters = {
    results: [
      {
        text: columnName,
        values: response?.data?.results,
      },
    ],
    hasMore: response?.meta?.has_next,
    offset: response?.meta?.offset,
    limit: response?.meta?.limit,
    column: response?.meta?.column,
    total: response?.meta?.total,
    totalPages: response?.meta?.total_pages,
    page: response?.meta?.page,
    perPage: response?.meta?.per_page,
  };
  return filters;
};

const getReconciliationRulesDetailsFiltersFromAPI = async ({
  limit,
  offset,
  columnName,
  q,
  searchBy,
}) => {
  const config = {
    params: {
      limit,
      offset,
      column: columnName,
      search_by: searchBy,
      q: JSON.stringify(q),
    },
  };
  const response = await lambdaApi().get('/conciliation/rules/filter', config);
  const transformedResponse = transform(response?.data, columnName);
  return transformedResponse;
};

export default getReconciliationRulesDetailsFiltersFromAPI;
